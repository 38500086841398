import styled from 'styled-components';
import { styled as styledByMui } from '@mui/material/styles';

export interface HeaderContainer {
    position: number;
    focused: boolean;
}

export const HeaderContainer = styledByMui('div')<HeaderContainer>(
    ({ position, focused, ...options }) => ({
        display: 'flex',
        position: 'relative',
        justifyContent: 'start',
        alignItems: 'center',
        height: '4.625rem',
        padding: `0 0.75rem 0 ${position === 0 ? '2.375rem' : '0.75rem'}`,
        backgroundColor: focused
            ? options.theme.palette.hues.neutral[32]
            : options.theme.palette.hues.neutral[21],
        whiteSpace: 'nowrap',
        userSelect: 'none',

        '::after': !focused
            ? {}
            : {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderTop: '1px solid #5065a1',
              },
        '::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: -1,
            boxSizing: 'border-box',
            borderTop: '1px solid #2c344e',
            borderLeft: '1px solid #2c344e',
        },
    })
);

export interface HeaderText {
    focused: boolean;
}

export const HeaderText = styled.span<HeaderText>(({ focused }) => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    color: '#FFFFFF',
    fontFamily: 'proxima-nova, sans-serif',
    opacity: focused ? 1 : 0.8,
}));
