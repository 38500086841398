/*
 * ContextMenu is a component that displays actions depending on the
 * contextMenuPayload in the TakeoffContext. Actions are defined by adding
 * renderOption calls to the return statement of the component. Every action
 * can define the dispatcher types (sheet, geometry, vertex) for which they
 * should be displayed.
 *
 * ContextMenu is positioned absolutely and its position is calculated
 * dynamically.
 */
import React, { FC, MouseEvent, useEffect, useState } from 'react';

import clsx from 'clsx';
import { Point } from 'leaflet';
import OutsideClickHandler from 'react-outside-click-handler';

import { modifierIcon } from '../../common/hot-keys/hotKeys';
import {
    BooleanToolType,
    ContextMenuDispatcherType,
    CopyBuffer,
    GeometryType,
} from '../../common/types';
import './ContextMenu.scss';
import { TakeoffComponentProps } from './context';
import { editedGeometryObservable, subscribeToGeometryCopy } from './observables/helpers';

import { geometryIs } from '@/common/typeGuards';
import { useSelectedGeometries } from '@/components/takeoff/hooks/useSelectedGeometries';

type Action = {
    name: string;
    keyboardShortcut: string;
    callback: () => void;
    availableFor: ContextMenuDispatcherType[];
    disabled?: boolean;
    isEdgeSpecific?: boolean;
};

export const ContextMenu: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const {
        mapRef,
        contextMenuPayload,
        setContextMenuPayload,
        startBooleanOperation,
        addVertex,
        copy,
        cut,
        paste,
        deleteGeometries,
        deleteVertices,
    } = useTakeoff();

    const selectedGeometries = useSelectedGeometries();

    const [copyBuffer, setCopyBuffer] = useState<CopyBuffer>();

    useEffect(() => {
        const subscription = subscribeToGeometryCopy(setCopyBuffer);
        return (): void => subscription.unsubscribe();
    }, []);

    const getDeletionCopy = (): string => {
        // Get the total number of geometries to be deleted, counting each member of a count group as 1.
        const numOnChoppingBlock = selectedGeometries.geometries.reduce((acc, geometry) => {
            if (geometryIs(geometry, GeometryType.COUNT)) {
                return acc + geometry.coordinates.length;
            }
            return acc + 1;
        }, selectedGeometries.points.length);
        if (numOnChoppingBlock <= 1) {
            return 'Delete';
        }
        return `Delete (${numOnChoppingBlock})`;
    };

    const actions: Action[] = [
        {
            name: 'Add Control Point',
            keyboardShortcut: '',
            callback: (): void => addVertex(contextMenuPayload?.coordinates),
            availableFor: [],
            isEdgeSpecific: true,
        },
        {
            name: 'Copy',
            keyboardShortcut: '⌃C',
            callback: (): void => copy(contextMenuPayload?.coordinates),
            availableFor: [
                ContextMenuDispatcherType.LINE,
                ContextMenuDispatcherType.AREA,
                ContextMenuDispatcherType.COUNTMARKER,
            ],
        },
        {
            name: 'Cut',
            keyboardShortcut: '⌃X',
            callback: (): void => cut(contextMenuPayload?.coordinates),
            availableFor: [
                ContextMenuDispatcherType.LINE,
                ContextMenuDispatcherType.AREA,
                ContextMenuDispatcherType.COUNTMARKER,
            ],
        },
        {
            name: 'Paste',
            keyboardShortcut: '⌃V',
            callback: (): void => paste(contextMenuPayload?.coordinates),
            availableFor: [ContextMenuDispatcherType.SHEET],
            disabled: !copyBuffer || copyBuffer.data.length === 0,
        },
        {
            name: 'Edit',
            keyboardShortcut: '⇧Enter',
            callback: (): void => editedGeometryObservable.next(selectedGeometries.geometries[0]),
            availableFor: [ContextMenuDispatcherType.LINE, ContextMenuDispatcherType.AREA],
            disabled: selectedGeometries.geometries.length > 1,
        },
        {
            name: 'Add',
            keyboardShortcut: 'modifierJ',
            callback: (): void => startBooleanOperation(BooleanToolType.ADD),
            availableFor: [ContextMenuDispatcherType.AREA],
        },
        {
            name: 'Subtract',
            keyboardShortcut: 'modifierE',
            callback: (): void => startBooleanOperation(BooleanToolType.SUBTRACT),
            availableFor: [ContextMenuDispatcherType.AREA],
        },
        {
            name: 'Split',
            keyboardShortcut: '^modifierS',
            callback: (): void => startBooleanOperation(BooleanToolType.SPLIT),
            availableFor: [ContextMenuDispatcherType.AREA],
        },
        {
            name: getDeletionCopy(),
            keyboardShortcut: 'Delete',
            callback: deleteGeometries,
            availableFor: [
                ContextMenuDispatcherType.LINE,
                ContextMenuDispatcherType.AREA,
                ContextMenuDispatcherType.COUNTMARKER,
                ContextMenuDispatcherType.COUNTGROUPMEMBER,
            ],
        },
        {
            name: 'Delete',
            keyboardShortcut: 'Delete',
            callback: deleteVertices,
            availableFor: [ContextMenuDispatcherType.VERTEX],
        },
    ];

    // Context menu is brought up from the sheet, so the coordinates are
    // in the sheet's coordinate reference system. We cast it to screen pixel
    // coordinates inside the map container.
    let { x, y } =
        (contextMenuPayload &&
            mapRef?.leafletElement.latLngToContainerPoint(contextMenuPayload.coordinates)) ||
        new Point(0, 0);

    // we offset the coordinates by the map container's top left
    // corner to receive a position with origin point in the page's top
    // left corner.
    if (mapRef) {
        x += mapRef.leafletElement.getContainer().offsetLeft;
        y += mapRef.leafletElement.getContainer().offsetTop;
    }

    const onOutsideClick = (e: MouseEvent): void => {
        if (e.button === 0) setContextMenuPayload(null);
    };

    const executeCallbackAndClose = (callback: () => void) => (): void => {
        callback();
        setContextMenuPayload(null);
    };

    return (
        <>
            {contextMenuPayload && (
                <OutsideClickHandler onOutsideClick={onOutsideClick}>
                    <div className="context-menu" style={{ left: x, top: y }}>
                        {actions.map(
                            (action) =>
                                // the option is rendered only when the dispatcher is
                                // contained in the availableFor array of the action
                                (action.availableFor.includes(contextMenuPayload.dispatcher) ||
                                    (action.isEdgeSpecific && contextMenuPayload.isEdge)) && (
                                    <div
                                        className={clsx({
                                            disabled: action.disabled,
                                        })}
                                        onClick={executeCallbackAndClose(action.callback)}
                                        key={action.name}
                                    >
                                        <span>{action.name}</span>
                                        <span>
                                            {action.keyboardShortcut.replace(
                                                'modifier',
                                                modifierIcon
                                            )}
                                        </span>
                                    </div>
                                )
                        )}
                    </div>
                </OutsideClickHandler>
            )}
        </>
    );
};
