import React, { FC } from 'react';

import { SortingDirection } from './index';
import { TableHeaderCell, TableHeaderText } from './styled';

import { SvgIcon } from '@/components/ui/icons/SvgIcon';

import { ReactComponent as SortArrow } from '@/assets/icons/SortArrow.svg';

export type ColumnProps = {
    name: string;
    label: string;
    sortBy?: string;
    sortable: boolean;
    sortOrder?: SortingDirection;
    active?: boolean;
};

type HeaderCellProps = {
    column: ColumnProps;
    onSort?: (column: ColumnProps) => void;
};

export const HeaderCell: FC<HeaderCellProps> = ({ column, onSort }) => {
    const { sortOrder, sortable, active, label } = column;

    const sortArrowStyle = (): React.CSSProperties | undefined => {
        if (sortOrder === SortingDirection.Ascending) {
            return { transform: 'rotate(180deg)' };
        }
    };

    const sort = (): void => {
        if (onSort) {
            onSort(column);
        }
    };

    return (
        <TableHeaderCell
            onClick={sort}
            sortable={sortable}
            // We're setting this to active on all cells
            // due to sorting breaking.  This should be reintroduced
            // once sorting is working with new paginated system.
            // active={active}
            active
        >
            <TableHeaderText>{label}</TableHeaderText>
            {sortable && active && (
                <SvgIcon src={SortArrow} width="0.5rem" height="0.5rem" style={sortArrowStyle()} />
            )}
        </TableHeaderCell>
    );
};
