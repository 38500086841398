import React, { FC } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Variant } from '@mui/material/styles/createTypography';
import { ReactComponent as FolderAddIcon } from '@/assets/icons/32/folder--add.svg';
import { IFileDirectory } from '@/graphql';

interface FileUploadInputProps {
    handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void[]>;
    text: string;
    directory: IFileDirectory;
    disabled: boolean;
    accept: string;
    variant?: Variant | 'inherit';
}

export const FileUploadInput: FC<FileUploadInputProps> = ({
    handleFileInputChange,
    text,
    directory,
    disabled,
    accept,
    variant,
}) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant={variant}>{text}</Typography>
            <label htmlFor={directory}>
                <IconButton component="span" disabled={disabled}>
                    <FolderAddIcon width={20} height={20} fill="white" />
                </IconButton>
                <input
                    type="file"
                    onChange={handleFileInputChange}
                    id={directory}
                    style={{ display: 'none' }}
                    multiple
                    accept={accept}
                    disabled={disabled}
                />
            </label>
        </Stack>
    );
};
