import React, { FC, useState } from 'react';

import { LocationInput } from '@/components/ui/inputs/Location';
import { Setter } from '@/common/types';
import { inferAddressAttributesFromPlace } from '@/common/utils/googleLocationHelpers';
import { Input } from '@/components/ui/inputs/Input';
import { Dropdown } from '@/components/ui/inputs/Dropdown';
import { IProjectLocationAssignmentInput } from '@/graphql';
import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import './SetupPanel.scss';

const COPY = {
    setupErrorTitle: 'Project setup failed to send',
    setupErrorMsg: 'try again',
    setupSuccessTitle: 'Success',
    setupSuccessMsg: 'Project setup submitted.',
    setupTitle: 'Project Information',
    setupSubtitle: 'Enter project information',
    location: 'Location',
    address: 'Address',
    streetAddress: 'Street address',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    projectType: 'Project type',
    type: 'Type',
    subtype: 'Sub-Type',
    done: 'Done',
};

export type SetupInfo = Omit<IProjectLocationAssignmentInput, 'projectID'> & {
    type: string;
    subtype: string;
};

type SetupPanelProps = {
    setupInfo: SetupInfo;
    setSetupInfo: Setter<SetupInfo>;
    setupErrors: SetupInfo;
    setSetupErrors: Setter<SetupInfo>;
    onClose: () => void;
    onSubmit: () => Promise<void>;
};

const options: { [key: string]: string[] } = {
    'Land Clearing': ['Commercial', 'Residential', 'Industrial', 'Mixed Use'],
    'Assembly Meeting Place': [
        'Club/Lodge',
        'Community/Recreation Center',
        'Convention Center',
        'Reception/Banquet Hall',
        'Religious Facility',
    ],
    'Commercial & Retail': [
        'Automotive',
        'Bank',
        'Convenience Store',
        'Day Care Facility',
        'Garden Center',
        'Laundromat',
        'Restaurant',
        'Showroom',
        'Street Retail',
        'Shopping Center',
        'Tavern/Bar/Nightclub',
    ],
    Energy: [],
    Healthcare: ['Urgent Care', 'Clinical Laboratory', 'Hospital/Medical Center'],
    Residential: ['Single Family', 'Multi Family', 'Senior Housing', 'Student Housing'],
    Industrial: [
        'Business Park',
        'Communication/Data Center',
        'Flex Space',
        'Manufacturing',
        'Processing',
        'Research & Development',
        'Self-Storage/Mini Storage',
        'Warehouse',
    ],
    Infrastructure: [
        'Dam',
        'Dikes & Floodgates',
        'Electrical Storage',
        'Natural Gas Storage',
        'Roads Bridges',
        'Waste Treatment',
    ],
    'Lodging & Hospitality': ['Bed & Breakfast', 'Casino', 'Hotel', 'Resort'],
    'Military Facility': [],
    'Mixed Use': [],
    Office: [],
    'Public Service': [
        'Courthouse',
        'Educational',
        'Embassy',
        'Fire House',
        'Jail/Correctional Facility',
        'Library',
        'Museum/Gallery',
        'Police Station',
        'Post Office',
        'Zoo/Aquarium',
    ],
    Recreation: [
        'Amusement Facility',
        'Cinema/Movie Theatre',
        'Equestrian Center',
        'Fitness Facility',
        'Golf Course',
        'Racetrack',
        'Shooting Range',
        'Skating Facility',
        'Ski Resort',
        'Sports Arena',
        'Performing Arts Theatre',
    ],
    Transportation: ['Airport', 'Bus/Train Terminal', 'Shipyard'],
};

const typeOptions: string[] = Object.keys(options);

export const SetupPanel: FC<SetupPanelProps> = ({
    setSetupInfo,
    setupInfo,
    setupErrors,
    setSetupErrors,
    onClose,
    onSubmit,
}) => {
    const [availableSubtypes, setAvailableSubtypes] = useState<string[]>(
        options[setupInfo.type] || []
    );

    const handleSubtypeChange = (subtype: string): void => {
        setSetupInfo((setupInfo) => ({ ...setupInfo, subtype: subtype }));
    };

    const handleTypeChange = (type: string): void => {
        setSetupInfo((setupInfo) => ({ ...setupInfo, type: type }));
        setAvailableSubtypes(options[type]);
        handleSubtypeChange('');
    };
    const subtypeIndex =
        setupInfo.subtype !== undefined
            ? availableSubtypes.findIndex((subtype) => subtype === setupInfo.subtype)
            : 0;

    const typeIndex =
        setupInfo.type !== undefined ? typeOptions.findIndex((type) => type === setupInfo.type) : 0;

    const handleZipUpate = (v: string): void => {
        /*
            Checks if the entered value is either:
            - 0-5 digits: (eg. 324)
            - 5 digits, '-' and 0-4 digits (eg. 12345-32)
        */
        const isValid = /(^[0-9]{0,5}$)|(^[0-9]{5}-[0-9]{0,4}$)/.test(v);

        if (!isValid) return;

        /* Update zip with new value */
        setSetupInfo((prev) => ({ ...prev, zip: v }));

        /* Reset error message */
        setSetupErrors((prev) => ({ ...prev, zip: '' }));
    };

    return (
        <Box
            sx={{
                padding: '24px',
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack spacing="8px">
                    <Typography variant="h3">{COPY.setupTitle}</Typography>
                    <Typography variant="body1">{COPY.setupSubtitle}</Typography>
                </Stack>
                <div>
                    <IconButton aria-label="close" onClick={onClose} size="small">
                        <SvgIcon viewBox="0 0 32 32">
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </div>
            </Stack>
            <Box
                sx={{
                    marginTop: '16px',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        marginBottom: '16px',
                    }}
                >
                    {COPY.location}
                </Typography>
                <Stack spacing="16px">
                    <Stack spacing="4px">
                        <Typography variant="h5">{COPY.address}</Typography>
                        <Box
                            sx={{
                                height: '36px',
                            }}
                        >
                            <LocationInput
                                address={setupInfo.formattedAddress ?? undefined}
                                setAddress={(address): void => {
                                    if (address) {
                                        setSetupInfo((oldSetupInfo) => ({
                                            ...oldSetupInfo,
                                            ...inferAddressAttributesFromPlace(address),
                                        }));
                                    }
                                }}
                                setCoordinates={(lat, lng): void =>
                                    setSetupInfo((oldSetupInfo) => ({
                                        ...oldSetupInfo,
                                        coordinates: [lng, lat],
                                    }))
                                }
                            />
                        </Box>
                    </Stack>
                    <Stack spacing="8px">
                        <Typography variant="h5">{COPY.city}</Typography>
                        <Input
                            value={setupInfo.city ?? ''}
                            onChange={(v): void => setSetupInfo({ ...setupInfo, city: v })}
                        />
                    </Stack>
                    <Stack spacing="8px">
                        <Typography variant="h5">{COPY.state}</Typography>
                        <Input
                            value={setupInfo.state ?? ''}
                            onChange={(v): void => setSetupInfo({ ...setupInfo, state: v })}
                        />
                    </Stack>
                    <Stack spacing="8px">
                        <Typography variant="h5">{COPY.zip}</Typography>
                        <Input value={setupInfo.zip ?? ''} onChange={handleZipUpate} />
                        {setupErrors.zip && (
                            <label className="error-text body-m">{setupErrors.zip}</label>
                        )}
                    </Stack>
                </Stack>
            </Box>
            <Box
                sx={{
                    marginTop: '16px',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        marginBottom: '16px',
                    }}
                >
                    {COPY.projectType}
                </Typography>
                <Stack spacing="8px">
                    <Typography variant="h5">{COPY.type}</Typography>
                    <Dropdown
                        onChange={(i): void => {
                            if (i === -1) setSetupInfo((setupInfo) => ({ ...setupInfo, type: '' }));
                            else handleTypeChange(typeOptions[i]);
                        }}
                        options={typeOptions}
                        value={typeIndex}
                    />
                </Stack>
            </Box>
            {availableSubtypes.length !== 0 && (
                <Stack
                    spacing="8px"
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <Typography variant="h5">{COPY.subtype}</Typography>
                    <Dropdown
                        onChange={(i): void => {
                            if (i === -1)
                                setSetupInfo((setupInfo) => ({ ...setupInfo, subtype: '' }));
                            else handleSubtypeChange(availableSubtypes[i]);
                        }}
                        options={availableSubtypes}
                        value={subtypeIndex}
                    />
                </Stack>
            )}
            <Button
                fullWidth
                onClick={onSubmit}
                sx={{
                    marginTop: '24px',
                }}
                variant="contained"
            >
                {COPY.done}
            </Button>
        </Box>
    );
};
