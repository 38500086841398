import { AddTakeoffButtons } from './AddTakeoffButtons';
import { BaseListItem } from './BaseListItem';
import { BaseListItemCollapse } from './BaseListItemCollapse';
import { GeometryList } from './GeometryList';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { useGroupIcon } from './useGroupIcon';
import { isNameTooLong, isNameTooShort, toFormattedMeasurement, toUom } from './utils';
import { useEstimationLayout } from '@/components/app/router/EstimationRoute/hooks/useEstimationLayout';
import { EstimationView } from '@/components/app/router/EstimationRoute/types';
import { IMarkupGroupFragment } from '@/graphql';
import { Mode } from '@/theme/Mode';
import Box from '@mui/material/Box';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import React, { FC, useRef, useState } from 'react';
import { Setter } from '@/common/types';

export interface GroupListItemProps extends ListItemButtonProps {
    index?: number;
    itemLabel?: string;
    markupGroup?: IMarkupGroupFragment;
    mode?: string;
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onDeleteMarkupGroup: (markupGroupID: string) => Promise<void>;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onRemoveMarkupsFromGroup: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    selectedMarkupEntryIds?: string[];
    isCollapseOpen: boolean;
    setIsCollapseOpen: Setter<boolean>;
}

export const GroupListItem: FC<GroupListItemProps> = ({
    disabled,
    itemLabel = 'X',
    markupGroup,
    mode,
    onAddMarkupToGroup,
    onDeleteMarkupGroup,
    onListItemClick,
    onListItemNameBlur,
    onRemoveMarkupsFromGroup,
    selectedMarkupEntryIds,
    isCollapseOpen,
    setIsCollapseOpen,
    ...props
}) => {
    const theme = useTheme();
    const estimationLayout = useEstimationLayout();

    const GroupIcon = useGroupIcon({ geometry: markupGroup?.markupGroupType });

    const nameTextFieldInputRef = useRef<HTMLInputElement | undefined>();

    const [name, setName] = useState(markupGroup?.name);
    const [nameOnFocus, setNameOnFocus] = useState<string>();
    const [renaming, setRenaming] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

    const handleDeleteMenuItemClick = async () => {
        if (!markupGroup) {
            return;
        }

        await onDeleteMarkupGroup?.(markupGroup.id);

        setMenuAnchor(null);
    };

    const collapseRightListItemText =
        markupGroup?.markups?.length === 1
            ? `1 ${itemLabel}`
            : `${markupGroup?.markups?.length || 0} ${itemLabel}s`;

    const isNameTextFieldPointerCursor = mode === 'select' || mode === 'insertIntoExpression';

    return (
        <ListItemButton
            divider
            disabled={disabled}
            disableGutters
            onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();

                onListItemClick?.(event, {
                    markupEntry: markupGroup,
                    nameRef: nameTextFieldInputRef,
                });
            }}
            {...props}
            sx={{
                cursor: mode === 'default' ? 'auto' : undefined,
                padding: '8px 0',
                userSelect: mode === 'default' ? 'auto' : undefined,
                ...props.sx,
            }}
        >
            <Box sx={{ width: '100%' }}>
                <BaseListItem
                    icon={
                        <SvgIcon
                            color="primary"
                            sx={{
                                height: '14px',
                                width: '14px',
                            }}
                            viewBox="0 0 32 32"
                        >
                            <GroupIcon />
                        </SvgIcon>
                    }
                    nameTextFieldProps={{
                        inputProps: {
                            disabled: !renaming,
                            sx: {
                                color: theme.palette.primary.main,
                                cursor: isNameTextFieldPointerCursor ? 'pointer' : 'auto',
                            },
                        },
                        InputProps: {
                            sx: {
                                cursor: isNameTextFieldPointerCursor ? 'pointer' : 'auto',
                            },
                        },
                        inputRef: nameTextFieldInputRef,
                        onBlur: (event) => {
                            setRenaming(false);

                            if (isNameTooShort(name) || isNameTooLong(name)) {
                                setName(nameOnFocus);
                                setNameOnFocus(undefined);

                                return;
                            }

                            setNameOnFocus(undefined);

                            onListItemNameBlur?.(event, {
                                markupEntry: markupGroup,
                                name,
                                nameRef: nameTextFieldInputRef,
                            });
                        },
                        onFocus: () => {
                            setNameOnFocus(markupGroup?.name);
                        },
                        onChange: (event) => setName(event.target.value),
                        value: name,
                    }}
                    mode={mode}
                    overflowIconButton
                    overflowIconButtonProps={{
                        color: 'primary',
                        onClick: (event) => {
                            event.stopPropagation();

                            setMenuAnchor(event.currentTarget);
                        },
                    }}
                />
                <BaseListItemCollapse
                    collapseProps={{
                        in: isCollapseOpen,
                        mountOnEnter: true,
                        unmountOnExit: true,
                        timeout: 0,
                    }}
                    collapseDirectionIconProps={{
                        color: 'primary',
                    }}
                    listItemButtonProps={{
                        disabled:
                            estimationLayout.view === EstimationView.Estimate &&
                            !markupGroup?.markups?.length,
                        onMouseDown: (event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            setIsCollapseOpen(!isCollapseOpen);
                        },
                    }}
                    leftListItemTextProps={{
                        primary: markupGroup?.measurement
                            ? `${toFormattedMeasurement(markupGroup)} ${toUom(
                                  markupGroup?.markupGroupType
                              )}`
                            : '---',
                        primaryTypographyProps: {
                            color: 'primary',
                        },
                    }}
                    rightListItemTextProps={{
                        primary: collapseRightListItemText,
                        primaryTypographyProps: {
                            color: 'primary',
                        },
                    }}
                >
                    <Stack sx={{ padding: '12px 16px 0' }}>
                        {estimationLayout.view === EstimationView.Takeoff && (
                            <AddTakeoffButtons
                                markupGroupID={markupGroup?.id}
                                geometry={markupGroup?.markupGroupType}
                            />
                        )}
                        {Boolean(markupGroup?.markups?.length) && isCollapseOpen && (
                            <GeometryList
                                height={(markupGroup?.markups ?? []).length * 80}
                                disabled={disabled}
                                listItems={markupGroup?.markups}
                                markupEntries={markupGroup?.markups}
                                mode={mode}
                                onAddMarkupToGroup={onAddMarkupToGroup}
                                onListItemClick={onListItemClick}
                                onListItemNameBlur={onListItemNameBlur}
                                onDeleteMarkupGroup={onDeleteMarkupGroup}
                                onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                                selectedMarkupEntryIds={selectedMarkupEntryIds}
                            />
                        )}
                    </Stack>
                </BaseListItemCollapse>
                <Mode variant="light">
                    <Menu
                        anchorEl={menuAnchor}
                        MenuListProps={{
                            sx: {
                                minWidth: '130px',
                            },
                        }}
                        onClose={() => {
                            if (disabled) {
                                return;
                            }

                            setMenuAnchor(null);
                        }}
                        open={Boolean(menuAnchor)}
                    >
                        <MenuItem
                            dense
                            disabled={disabled}
                            onClick={() => {
                                setMenuAnchor(null);
                                setRenaming(true);

                                setTimeout(() => {
                                    const node = nameTextFieldInputRef.current;

                                    if (node) {
                                        const selectionEnd = node.selectionEnd || 0;

                                        node.focus();
                                        node.selectionStart = selectionEnd + 1000;
                                    }
                                }, 0);
                            }}
                        >
                            Rename
                        </MenuItem>
                        <MenuItem dense disabled={disabled} onClick={handleDeleteMenuItemClick}>
                            Delete
                        </MenuItem>
                    </Menu>
                </Mode>
            </Box>
        </ListItemButton>
    );
};
