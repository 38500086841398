import MuiAppBar from '@mui/material/AppBar';
import MuiSvgIcon from '@mui/material/SvgIcon';
import MuiToolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';

import { ReactComponent as FullLogoV2Icon } from '@/assets/images/full-logo-v2.svg';

export const BackSvgIcon = styled(MuiSvgIcon)((options) => ({
    height: '100%',
    color: options.theme.palette.hues.neutral[78],
}));

export const AppBar = styled(MuiAppBar)((options) => ({
    position: options.position ?? 'fixed', // default to fixed
    zIndex: options.theme.zIndex.appBar,
}));

export const Toolbar = styled(MuiToolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

export const LogoIcon = styled(FullLogoV2Icon)({
    height: 30,
    verticalAlign: 'middle',
    width: 'auto',
});

export const MobileTrialButtonsWrapper = styled('div')((options) => ({
    borderBottom: `1px solid ${options.theme.palette.hues.neutral[32]}`,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '8px 0',
    boxSizing: 'border-box',
}));

// Pad down contents below fixed MuiAppBar
export const Clearfix = styled('div')({
    minHeight: 64,

    // Account for trial buttons
    ...breakpointsCSSObject.small({
        minHeight: 112,
    }),
});
