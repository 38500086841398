/*
 * assembly.ts provides assembly-data-related functions.
 */
import { AssemblyData } from './types';

import { notNullOrUndefined } from '@/common/utils/helpers';
import { IAssemblyFragment } from '@/graphql';

const COPY = {
    generalConditions: 'General Conditions',
    equipment: 'Equipment',
};

const standaloneAssemblyTrades = [COPY.equipment, COPY.generalConditions];

export const assemblyIsStandalone = (assembly: AssemblyData | IAssemblyFragment): boolean =>
    notNullOrUndefined(assembly.trade) && standaloneAssemblyTrades.includes(assembly.trade.name);
