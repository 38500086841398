import { UseFormElementPayload } from '@/components/Estimate/Table/components/CellDialog/CellForm/useFormElement';
import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const COPY = {
    ratesInfoTitle: 'Calculate unit rate',
    ratesInfoText: 'Unit Rate = Material Rate + (Labor Rate / Production Rate)',
    materialRate: 'Material rate',
    materialRateHelper: 'Material cost of 1 unit',
    laborRate: 'Labor rate',
    laborRateHelper: 'Labor cost per man hour',
    productionRate: 'Production rate',
    productionRateHelper: 'Amount of unit that can be installed per man hour',
};

interface RatesInputProps {
    materialRate: UseFormElementPayload<HTMLInputElement>;
    laborRate: UseFormElementPayload<HTMLInputElement>;
    productionRate: UseFormElementPayload<HTMLInputElement>;
    disabled: boolean;
}

const RatesInput: FC<RatesInputProps> = ({ materialRate, laborRate, productionRate, disabled }) => {
    return (
        <Box sx={{ padding: '0 10px' }}>
            <Stack spacing={2} divider={<Divider flexItem />}>
                <div>
                    <Typography sx={{ color: 'text.primary' }} variant="h4">
                        {COPY.ratesInfoTitle}
                    </Typography>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[48] }}
                        variant="body2"
                    >
                        {COPY.ratesInfoText}
                    </Typography>
                </div>
                <TextField
                    id="rate"
                    label={COPY.materialRate}
                    helperText={
                        <Typography
                            component="span"
                            sx={{ color: (theme) => theme.palette.hues.neutral[48] }}
                            variant="body2"
                        >
                            {COPY.materialRateHelper}
                        </Typography>
                    }
                    variant="filled"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    {...materialRate}
                    disabled={disabled}
                />
                <TextField
                    id="rate"
                    label={COPY.laborRate}
                    helperText={
                        <Typography
                            component="span"
                            sx={{ color: (theme) => theme.palette.hues.neutral[48] }}
                            variant="body2"
                        >
                            {COPY.laborRateHelper}
                        </Typography>
                    }
                    variant="filled"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    {...laborRate}
                    disabled={disabled}
                />
                <TextField
                    id="rate"
                    label={COPY.productionRate}
                    helperText={
                        <Typography
                            component="span"
                            sx={{ color: (theme) => theme.palette.hues.neutral[48] }}
                            variant="body2"
                        >
                            {COPY.productionRateHelper}
                        </Typography>
                    }
                    variant="filled"
                    type="number"
                    {...productionRate}
                    disabled={disabled}
                />
            </Stack>
        </Box>
    );
};

export default RatesInput;
