/*
 * Details displays project price details within the price module.
 */
import React, { FC } from 'react';

import { BalanceCalculator } from './BalanceCalculator';
import { ProjectPrice } from './ProjectPrice';
import { SingleDetail } from './SingleDetail';
import { DetailsContainer } from './styled';

import { usdFormatter } from '.';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { isPriceEstimating } from '@/components/AdminDashboard/ProjectPanel/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    clientBalance: 'CLIENT BALANCE',
    hours: 'hrs',
    totalHours: 'TOTAL HOURS',
};

export const Details: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { project } = useProjectPanel();
    const { builderAcceptance } = useFlags();

    return (
        <DetailsContainer>
            {(!builderAcceptance || !isPriceEstimating(project)) && (
                <ProjectPrice useProjectPanel={useProjectPanel} />
            )}
            <SingleDetail label={COPY.totalHours}>
                {`${project.estimatorHours} ${COPY.hours}`}
            </SingleDetail>
            <SingleDetail label={COPY.clientBalance}>
                {project.team?.balance?.usdCents !== undefined &&
                    usdFormatter.format(project.team.balance.usdCents / 100)}
            </SingleDetail>
            <BalanceCalculator useProjectPanel={useProjectPanel} />
        </DetailsContainer>
    );
};
