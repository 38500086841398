import { IMarkupType } from '@/graphql';

import { ReactComponent as FolderAreaIcon } from '@/assets/icons/32/folder--area.svg';
import { ReactComponent as FolderCountIcon } from '@/assets/icons/32/folder--count.svg';
import { ReactComponent as FolderLinearIcon } from '@/assets/icons/32/folder--linear.svg';
import { ReactComponent as FolderIcon } from '@/assets/icons/32/folder.svg';

interface Options {
    geometry?: IMarkupType | null;
}

export const useGroupIcon = (options?: Options) => {
    let Icon;

    if (options?.geometry === IMarkupType.Linear) {
        Icon = FolderLinearIcon;
    } else if (options?.geometry === IMarkupType.Area) {
        Icon = FolderAreaIcon;
    } else if (options?.geometry === IMarkupType.Count) {
        Icon = FolderCountIcon;
    } else {
        Icon = FolderIcon;
    }

    return Icon;
};
