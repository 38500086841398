import { Element } from 'leyden';
import { KeyboardEventHandler } from 'react';
import { Node, Path, Range } from 'slate';

import { useEstimateEditor } from '../hooks/useEstimateEditor';
import { Transforms } from '../transforms';

export const useHandleKeyDown = (): KeyboardEventHandler => {
    const editor = useEstimateEditor();

    const handleKeyDown: KeyboardEventHandler = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        const { selection } = editor;
        if (
            selection !== null &&
            Range.isCollapsed(selection) &&
            selection.anchor.offset === 0 &&
            selection.anchor.path.length > 2
        ) {
            let expression: [Element<'Expression'>, Path] | null = null;
            for (const [ancestor, ancestorPath] of Node.ancestors(editor, selection.anchor.path, {
                reverse: true,
            })) {
                if (ancestorPath.length <= 2) {
                    break;
                }
                if (Element.isElement(ancestor, { type: 'Expression' })) {
                    expression = [ancestor, ancestorPath];
                    break;
                }
            }
            if (expression !== null) {
                if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
                    const positionInExpression = selection.anchor.path[expression[1].length];
                    const canMove =
                        e.key === 'ArrowLeft'
                            ? positionInExpression > 0
                            : positionInExpression < expression[0].children.length - 1;
                    if (canMove) {
                        const offset = e.key === 'ArrowLeft' ? -1 : 1;
                        for (const [, textPath] of Node.texts(expression[0], {
                            from: [positionInExpression + offset],
                            reverse: e.key === 'ArrowLeft',
                            pass: ([, { length }]) => length === 1,
                        })) {
                            Transforms.select(editor, [...expression[1], ...textPath]);
                            break;
                        }
                    }
                    e.preventDefault();
                }
            }
        }
    };

    return handleKeyDown;
};
