/**
 * FileManager is meant to encapsulate the logic related to the file uploading
 * and link adding of the project creation flow.
 */
import Typography from '@mui/material/Typography';
import './FileManager.scss';
import { getAllFileEntries } from './helpers';
import { ReactComponent as FileIcon } from '@/assets/icons/File.svg';
import { ReactComponent as HyperlinkIcon } from '@/assets/icons/Hyperlink.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/TrashCan.svg';
import { useIsUploadValidFileType } from '@/common/hooks/useIsUploadValidFileType';
import { Estimate, Link, Nil, Svg, Upload } from '@/common/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { FileUpload } from '@/components/ui/inputs/FileUpload';
import { CenterEllipsis } from '@/components/ui/text/CenterEllipsis';
import { useNotifications } from '@/contexts/Notifications';
import { CustomManagedUpload, useStorage } from '@/contexts/Storage';
import { EventFullRecord } from '@/queries/events';
import { ProjectEstimateFileRecord } from '@/queries/projectEstimateFiles';
import { ProjectPlanFileRecord } from '@/queries/projectPlanFiles';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';
import clsx from 'clsx';
import React, { FC, useState } from 'react';

const COPY = {
    project_creation_cancel_upload: 'Cancel',
    project_creation_link_input_placeholder: 'Enter link',
    project_creation_add_files: 'Add files',
    project_creation_file_formats: ' (zip, pdf, jpg or png)',
    project_creation_files_empty: 'The following file(s) have 0 bytes:',
};

export type FileManagerFile =
    | Estimate
    | Upload
    | ProjectUploadFileRecord
    | ProjectPlanFileRecord
    | ProjectEstimateFileRecord;
type FileManagerLink = Link | EventFullRecord;

type FileManagerProps = {
    isSanitized?: boolean;
    accepted?: string;
    className?: string;
    disabled?: boolean;
    files: FileManagerFile[];
    links?: FileManagerLink[];
    displayTrashBin?: boolean;
    projectUuid: string;
    fileUploadRef: React.RefObject<HTMLInputElement>;
    onFileDownload?: (name: string, file: FileManagerFile, projectUuid?: string) => void;
    onFilesAdd: (files: File[]) => void;
    onFileRemove: (file: FileManagerFile) => void;
    handleUploadFilePress?: () => void;
};

export const FileManager: FC<FileManagerProps> = ({
    isSanitized,
    accepted,
    className,
    disabled,
    handleUploadFilePress,
    displayTrashBin,
    projectUuid,
    files,
    links,
    fileUploadRef,
    onFileDownload,
    onFilesAdd,
    onFileRemove,
}) => {
    const { currentUploads } = useStorage();
    const { addNotification } = useNotifications();
    const { getValidFileList } = useIsUploadValidFileType();

    const [shouldHighlight, setShouldHighlight] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleFileInputChange = (fileList?: File[]): void => {
        if (!(fileUploadRef.current || fileList) || disabled) return;
        // Get the list from files from input
        const uploadedFiles = Array.from(fileList || fileUploadRef?.current?.files || []);

        setError(undefined);

        // If this is sanitized, filter out any files without valid extensions
        const { validFiles, error } = isSanitized
            ? getValidFileList(uploadedFiles)
            : { validFiles: uploadedFiles, error: null };

        if (error) {
            setError(error);
        } else {
            // Only get the files that haven't been added before
            const newFiles = validFiles.filter(
                (newFile) => !files.map((file) => file.filename).includes(newFile.name)
            );

            const newFilesWithContent = newFiles.filter((nf) => nf.size > 0);
            const newFilesWithoutContent = newFiles.filter((nf) => nf.size === 0);

            if (newFilesWithoutContent.length > 0) {
                addNotification(
                    {
                        title: COPY.project_creation_files_empty,
                        content: <p>{newFilesWithoutContent.map((nf) => nf.name).join(', ')}</p>,
                    },
                    'error'
                );
            }

            onFilesAdd(newFilesWithContent);
        }
        // Reset the input value so that onChange is called even if the user
        // selects the same file again (useful if they deleted the last file
        // from the list and want to add it again).
        if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
        }
    };

    const handleRemoveFile = (uuid: string) => (): void => {
        const fileToRemove = files.find((file) => file.uuid === uuid);

        if (!fileToRemove) return;

        onFileRemove(fileToRemove);
    };

    const handleDownloadFile = (name: string, id: string): void => {
        const fileToDownload = files.find((item) => Number(item.id) === Number(id));

        if (!fileToDownload || !onFileDownload) return;

        onFileDownload(name, fileToDownload, projectUuid);
    };

    const findUploadByName = (name: string): Nil<CustomManagedUpload | boolean> => {
        const file = files.find((item) => item.filename === name);

        if (!file) return false;

        return currentUploads.find(
            (u) => (u as unknown as { body: File }).body.name === file.filename
        );
    };

    const handleCancelUpload = (uuid: string) => (): void => {
        const uploadToCancel = files.find((file) => file.uuid === uuid);

        if (!uploadToCancel || !uploadToCancel.filename) return;

        const pendingUpload = findUploadByName(uploadToCancel.filename);

        if (!pendingUpload || typeof pendingUpload === 'boolean') return;

        handleRemoveFile(uuid)();
        pendingUpload.abort();
    };

    const isFileLoading = (name: string): boolean => {
        return !!findUploadByName(name);
    };

    const isError = (name: string): boolean => {
        const pendingUpload = findUploadByName(name);
        return !!(typeof pendingUpload === 'object' && pendingUpload && pendingUpload.error);
    };

    const getUploadPercent = (name: string): number => {
        const pendingUpload = findUploadByName(name);
        if (!pendingUpload) return 0;
        return (pendingUpload as unknown as { progressPercent: number }).progressPercent;
    };

    const renderFileOrLink = (
        Icon: Svg,
        name: string,
        key: string,
        id: string,
        isFile = true
    ): JSX.Element => (
        <React.Fragment key={key}>
            <div
                className={clsx('file-row', 'flex-container', {
                    loading: isFile && isFileLoading(name),
                    uploadError: isError(name),
                })}
            >
                <div
                    className="flex-container width100"
                    title={name}
                    onClick={(): void => {
                        if (isFile) {
                            handleDownloadFile(name, id);
                        }
                    }}
                >
                    <SvgIcon src={Icon} noInherit={true} />
                    {isFile ? (
                        <CenterEllipsis
                            className={clsx(isFileLoading(name) && 'color-steel')}
                            text={name}
                            isLoading={isFile && isFileLoading(name)}
                        />
                    ) : (
                        <CenterEllipsis
                            text={name}
                            linkProps={{
                                href: name,
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }}
                        />
                    )}
                </div>
                {displayTrashBin && isFile && (
                    <SvgIcon src={TrashCanIcon} onClick={handleRemoveFile(key)} />
                )}
                {isFileLoading(name) && isFile && (
                    <a className="cursor" onClick={handleCancelUpload(key)}>
                        {COPY.project_creation_cancel_upload}
                    </a>
                )}
            </div>
            {isFile && isFileLoading(name) && !isError(name) && (
                <div className="progress-bar-wrapper">
                    <div className="progress-bar" style={{ width: `${getUploadPercent(name)}%` }} />
                </div>
            )}
        </React.Fragment>
    );

    const renderElements = (): JSX.Element => {
        const fileItems = files.map(({ created, filename, id, uuid }) => ({
            created: created,
            render: renderFileOrLink(
                FileIcon,
                filename ?? '',
                uuid,
                id === undefined ? '' : id.toString()
            ),
        }));

        const linkItems = links
            ? links.map(({ created, message, id }) => ({
                  created: created,
                  render: renderFileOrLink(
                      HyperlinkIcon,
                      message ?? '',
                      `link_${message ?? ''}`,
                      id.toString(),
                      false
                  ),
              }))
            : [];

        /* Mix files and links, and sort them by created date descending */
        const renderItems = [...fileItems, ...linkItems].sort(
            (a, b) => new Date(b.created ?? '').getTime() - new Date(a.created ?? '').getTime()
        );

        return <div>{renderItems.map((item) => item.render)}</div>;
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const newFiles = await getAllFileEntries(e.dataTransfer.items);
            handleFileInputChange(newFiles);
        }
    };

    const setHighlight =
        (on: boolean) =>
        (e: React.DragEvent<HTMLDivElement>): void => {
            e.stopPropagation();
            e.preventDefault();
            setShouldHighlight(on);
        };

    const onDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
        await handleDrop(e);
        setShouldHighlight(false);
    };

    const handleUploadFilePressWrapped = (): void => {
        if (!disabled && handleUploadFilePress) {
            handleUploadFilePress();
        }
    };

    return (
        <div className={clsx('file-manager', className)}>
            {error && <Typography sx={{ color: 'error.main' }}>{error}</Typography>}
            <div
                className="dropzone-wrapper"
                onDrop={onDrop}
                onDragEnter={setHighlight(true)}
                onDragOver={setHighlight(true)}
                onDragLeave={setHighlight(false)}
            >
                <div
                    className={clsx('dropzone', shouldHighlight && 'dropzone-highlight')}
                    onClick={handleUploadFilePressWrapped}
                >
                    <span className="dropzone-add-sign" />
                    <p className="dropzone-text">
                        {COPY.project_creation_add_files}
                        <span className="file-names">{COPY.project_creation_file_formats}</span>
                    </p>
                </div>
            </div>
            <FileUpload
                accept={accepted}
                onChange={() => handleFileInputChange()}
                ref={fileUploadRef}
                multiple
            />
            {renderElements()}
        </div>
    );
};
