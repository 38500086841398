import React, { FC } from 'react';
import { RenderLeafProps } from 'slate-react';

export interface LeafProps extends RenderLeafProps {
    linkify?: boolean;
}

export const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
    if (leaf.bold) children = <strong>{children}</strong>;
    if (leaf.italic) children = <em>{children}</em>;
    if (leaf.underline) children = <u>{children}</u>;
    return <span {...attributes}>{children}</span>;
};
