import { useEffect, useRef, useState } from 'react';

import { SignupForm } from '..';
import { isValidEmail, isValidPhoneNumber } from '@/common/utils/validators';

const COPY = {
    defaultFormError: 'This can’t be left blank.',
    blankSelectError: 'You must choose one of the options.',
    emailAddressInvalidError: 'Email address must be valid. (example@domain.com)',
    phoneNumberInvalidError: 'Phone number must be valid.',
    passwordLengthError: 'Password must be 8+ characters',
};

export type FormValidState = Partial<Record<keyof SignupForm, string | undefined>>;

const initialFormValidState: FormValidState = {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    password: undefined,
    phone: undefined,
    businessType: undefined,
};

/**
 * Handle form error state and generate validation wrapper to surround signup.
 *
 * @param {SignupForm} signupForm
 * @returns {{ validationResults: FormValidState, validateSignup: (signup: () => Promise<void>): Promise<void> }} -
 * form signup validation results and callback to submit form.
 */
export const useFormValidate = (signupForm: SignupForm) => {
    // Previous state of signup form
    const lastSignupForm = useRef(signupForm);

    const [validationResults, setValidationResults] =
        useState<FormValidState>(initialFormValidState);

    // Some more specific checks
    const specificValidationErrors = {
        phone: isValidPhoneNumber(signupForm.phone) ? undefined : COPY.phoneNumberInvalidError,
        role: signupForm.businessType ? undefined : COPY.blankSelectError,
        password: signupForm.password.length >= 8 ? undefined : COPY.passwordLengthError,
        email: isValidEmail(signupForm.email) ? undefined : COPY.emailAddressInvalidError,
    };

    // Check all fields for empty state, filtering out errors that are undefined
    // this prevents spread of undefined over existing empty error states
    const emptyErrors = Object.entries(signupForm).reduce<Partial<FormValidState>>(
        (acc, [key, value]) => {
            if (value) {
                return acc;
            }

            return {
                ...acc,
                [key]: COPY.defaultFormError,
            };
        },
        {}
    );

    const formFieldErrorMessages = {
        ...specificValidationErrors,
        ...emptyErrors,
    };

    const updateValidationResults = () => setValidationResults(formFieldErrorMessages);

    // Overwrite error state on modifying a form field.
    useEffect(() => {
        const overwrittenErrors = Object.entries(lastSignupForm.current).reduce<
            Partial<FormValidState>
        >((acc, [key, value]) => {
            // @michael forgive me 👼
            if (signupForm[key as keyof SignupForm] !== value) {
                return { ...acc, [key]: undefined };
            }

            return acc;
        }, {});

        setValidationResults((oldValidationResults) => ({
            ...oldValidationResults,
            ...overwrittenErrors,
        }));

        lastSignupForm.current = signupForm;
    }, [signupForm]);

    return {
        formFieldErrorMessages,
        updateValidationResults,
        validationResults,
    };
};
