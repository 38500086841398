import { Element } from 'leyden';
import { Editor, Node, Text } from 'slate';

import { lex } from '../expression/lexer';
import { Transforms } from '../transforms';
import { EstimateEditor } from './EstimateEditor';

const IGNORED_CHARACTERS = [
    // This is to prevent the lexer to fail for incomplete decimal numbers
    // e.g '1.', '42.'
    '.',
    ',',
    // This is to allow the lexer to parse decimals with more precision
    '.0',
    ',0',
    '.00',
    ',00',
    '0.0',
    '0,0',
    '0.00',
    '0,00',
];

function textShouldNotBeLexed(text: string): boolean {
    return IGNORED_CHARACTERS.some((char) => text.endsWith(char));
}

export const withExpressions = <T extends EstimateEditor>(editor: T): T => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Element.isElement(node, { type: 'Expression' })) {
            for (const [child, childPath] of Node.children(editor, path)) {
                // Delete the placeholder if it's present and not surrounded by empty text.
                if (Element.isElement(child, { type: 'ExpressionPlaceholder' })) {
                    if (node.children.length > 3 || Node.string(node) !== '') {
                        Transforms.delete(editor, { at: childPath });
                        return;
                    }
                }
                if (Text.isText(child) && child.text.length > 0) {
                    const { text } = child;

                    if (!textShouldNotBeLexed(text)) {
                        const lexed = lex(text);

                        if (lexed.length === 0) {
                            Transforms.delete(editor, { at: childPath });
                            return;
                        }

                        const lastCharIsSpace = text.endsWith(' ');

                        const [lastToken] = lexed.slice(-1);

                        if (
                            !lastCharIsSpace &&
                            (Element.isElement(lastToken, { type: 'ExpressionNumber' }) ||
                                Element.isElement(lastToken, { type: 'ExpressionVariable' }))
                        ) {
                            const newText = lastToken.value.toString();
                            if (newText !== text) {
                                Editor.withoutNormalizing(editor, () => {
                                    Transforms.insertText(editor, newText, { at: childPath });
                                    if (lexed.length > 1) {
                                        Transforms.insertNodes(editor, lexed.slice(0, -1), {
                                            at: childPath,
                                        });
                                    }
                                });
                                return;
                            }
                        } else {
                            Editor.withoutNormalizing(editor, () => {
                                Transforms.insertNodes(editor, lexed, { at: childPath });
                                Transforms.removeNodes(editor, {
                                    at: [
                                        ...childPath.slice(0, -1),
                                        childPath.slice(-1)[0] + lexed.length,
                                    ],
                                });
                            });
                            if (
                                ['ExpressionNumber', 'ExpressionVariable'].includes(lastToken.type)
                            ) {
                                setTimeout(() => {
                                    Transforms.move(editor);
                                });
                            }
                            return;
                        }
                    }
                }
            }
        }

        normalizeNode(entry);
    };

    return editor;
};
