/**
 * ArrowIcon is an arrow icon very similar to the unicode character 🡒. Because
 * this icon is built using a css polygon instead of an SVG, its color can be
 * changed with css.
 */

import clsx from 'clsx';
import React, { FC } from 'react';

import { SvgIcon } from './SvgIcon';
import { Direction, SpanProps } from '@/common/types';
import { ReactComponent as Arrow } from '@/assets/icons/arrow.svg';

import './ArrowIcon.scss';

export interface ArrowIconProps extends SpanProps {
    direction: Direction;
}

export const ArrowIcon: FC<ArrowIconProps> = ({ className, direction, ...shared }) => {
    const arrowIconClass = clsx(className, 'arrow-icon', {
        'arrow-icon-up': direction === Direction.Up,
        'arrow-icon-right': direction === Direction.Right,
        'arrow-icon-down': direction === Direction.Down,
        'arrow-icon-left': direction === Direction.Left,
    });

    return (
        <span className={arrowIconClass} {...shared}>
            <SvgIcon src={Arrow} noInherit={true} />
        </span>
    );
};
