import { asyncScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

import { useObservable } from '@/common/hooks/useObservable';
import {
    editedGeometryObservable,
    optionalGeometriesAreEqual,
} from '@/components/takeoff/observables/helpers';
import { selectedGeometriesObservable } from '@/components/takeoff/observables/interface';

const editedOrDrawnGeometry = combineLatest([
    selectedGeometriesObservable,
    editedGeometryObservable,
]).pipe(
    throttleTime(100, asyncScheduler, { leading: true, trailing: true }),
    map(([selectedGeometries, editedGeometry]) => {
        if (selectedGeometries.geometries.length > 0) {
            return selectedGeometries.geometries[0];
        }
        return editedGeometry ?? undefined;
    }),
    distinctUntilChanged(optionalGeometriesAreEqual)
);

export const useEditedOrDrawnGeometry = () => useObservable(editedOrDrawnGeometry);
