/*
 * withHtml is a Slate plugin which adds support for HTML content pasted into
 * the editor. Only a small subset of html tags are supported - see the cases
 * of `getElementTag` and `getTextTag`.
 */
import { Editor, Element, Node, Path, Range, Text, Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';

import { notNull } from '@/common/utils/helpers';

const htmlFormat = 'text/html';
const domParser = new DOMParser();

const getElementTag = (nodeName: string): { type: string } | undefined => {
    switch (nodeName) {
        case 'BLOCKQUOTE':
            return { type: 'quote' };
        case 'H1':
            return { type: 'heading-one' };
        case 'H2':
            return { type: 'heading-two' };
        case 'H3':
        case 'H4':
        case 'H5':
        case 'H6':
            return { type: 'heading-three' };
        case 'LI':
            return { type: 'list-item' };
        case 'OL':
            return { type: 'numbered-list' };
        case 'P':
            return { type: 'paragraph' };
        case 'UL':
            return { type: 'bulleted-list' };
    }
};

interface TextTag {
    italic?: boolean;
    bold?: boolean;
}
const getTextTag = (nodeName: string): TextTag | undefined => {
    switch (nodeName) {
        case 'EM':
        case 'I':
            return { italic: true };
        case 'STRONG':
            return { bold: true };
    }
};

const deserialize = (el: HTMLElement | ChildNode): null | Element | Text | Node[] => {
    const { nodeName, nodeType } = el;

    if (nodeType === 3) {
        return jsx('text', {}, el.textContent);
    } else if (nodeType !== 1) {
        return null;
    } else if (nodeName === 'BR') {
        return jsx('text', {}, '\n');
    }

    let parent = el;

    if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
        parent = el.childNodes[0];
    }
    const children = Array.from(parent.childNodes).map(deserialize).filter(notNull).flat();

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    const elementAttributes = getElementTag(nodeName);
    if (elementAttributes) {
        return jsx('element', elementAttributes, children);
    }

    const textAttributes = getTextTag(nodeName);
    if (textAttributes) {
        return children.map((child) => jsx('text', textAttributes, child));
    }

    return children;
};

const nodeIsElement = (node: Node): node is Element =>
    Object.prototype.hasOwnProperty.call(node, 'children');

const deserializeHtmlData = (data: string): Node[] | null => {
    const parsed = domParser.parseFromString(data, htmlFormat);
    let deserialized = deserialize(parsed.body);
    if (deserialized === null) {
        return null;
    }
    if (!Array.isArray(deserialized)) {
        deserialized = [deserialized];
    }

    // If there are root-level text nodes, wrap them in paragraphs.
    deserialized = deserialized.reduce<Node[]>((acc, node): Node[] => {
        if (!('text' in node)) {
            return acc.concat(node);
        }
        if (acc.length === 0) {
            return [{ type: 'paragraph', children: [node] }];
        }
        const lastItem = acc[acc.length - 1];
        if (
            Element.isElement(lastItem) &&
            lastItem.type === 'paragraph' &&
            nodeIsElement(lastItem)
        ) {
            return acc.slice(0, -1).concat({
                ...lastItem,
                children: lastItem.children.concat(node),
            });
        }
        return acc.concat([{ type: 'paragraph', children: [node] }]);
    }, []);
    return deserialized;
};

/* eslint-disable-next-line max-len */
/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types */
export const withHtml = (editor: Editor) => {
    const { insertData } = editor;

    editor.insertData = (data): void => {
        const htmlData = data.getData(htmlFormat);
        if (!htmlData) {
            insertData(data);
            return;
        }
        const deserialized = deserializeHtmlData(htmlData);
        if (deserialized === null) {
            return;
        }
        // If the editor contains a single empty paragraph, remove it before pasting.
        if (
            editor.children.length === 1 &&
            Element.isElement(editor.children[0]) &&
            editor.children[0].type === 'paragraph' &&
            Editor.string(editor, [0]) === ''
        ) {
            Transforms.removeNodes(editor, { at: [0] });
        }

        let insertAt: Path | Range | undefined = editor.selection ?? undefined;

        const parentBlockQuote = Editor.above(editor, {
            match: (n) => Editor.isBlock(editor, n) && n.type === 'block-quote',
        });
        if (parentBlockQuote) {
            insertAt = parentBlockQuote[1].concat([0]);
        }

        Transforms.insertNodes(editor, deserialized, { at: insertAt });
    };

    return editor;
};
