import { useCallback, useEffect, useMemo, useState } from 'react';

import { useEstimationProject } from './useEstimationProject';

import { useMarkupCache } from '@/common/hooks/useMarkupCache';
import {
    extractMarkupsFromMarkupEntries,
    markupsChangedBySnapshot,
} from '@/common/utils/geometries/markup';
import { PersistedGeometriesPayload } from '@/common/utils/geometries/save';
import { IMarkupFragment, useProjectWithMarkupEntriesQuery } from '@/graphql';
import { subscribeToPersistedGeometries } from '@/interfaces/geometries';
import { selectedGeometriesObservable } from '@/components/takeoff/observables/interface';
import { fromMarkup } from '@/common/convert/geometry/fromMarkup';

// synchronizedProjectUUIDs is a set of project UUIDs that that are actively being synchronized
// by this hook. It is used to ensure that the hook has at most one active instance per project.
const synchronizedProjectUUIDs = new Set<string>();

type SyncScaleState = 'uninitialized' | number | null;

// useTakeoffMarkupSync updates the estimation route's state when markups are changed within the takeoff.
export const useTakeoffMarkupSync = (): void => {
    const [persistedScale, setPersistedScale] = useState<SyncScaleState>('uninitialized');
    const markupCache = useMarkupCache();
    const { projectUUID, projectID } = useEstimationProject();

    const projectWithMarkupEntriesResult = useProjectWithMarkupEntriesQuery({
        variables: {
            uuid: projectUUID,
        },
        skip: !projectUUID,
    });

    // markups contains all markups in the active project.
    // When markups is null, the markups have not loaded.
    const markups = useMemo<IMarkupFragment[] | null>(() => {
        const markupEntries = projectWithMarkupEntriesResult.data?.project.markupEntries;
        if (!markupEntries) {
            return null;
        }
        return extractMarkupsFromMarkupEntries(markupEntries);
    }, [projectWithMarkupEntriesResult]);

    const selectedMarkup = useMemo<IMarkupFragment | undefined>(
        () => markups?.find((markup) => markup.isSelected),
        [markups]
    );

    const onGeometriesPersisted = useCallback<(payload: PersistedGeometriesPayload) => void>(
        ({ scale, snapshot, projectPlanPageID }) => {
            // If markups haven't loaded yet, we can't diff.
            if (markups === null) {
                return;
            }
            const scaleModified = persistedScale !== 'uninitialized' && persistedScale !== scale;
            setPersistedScale(scale);
            const diff = markupsChangedBySnapshot(
                markups,
                snapshot,
                projectPlanPageID,
                scale,
                scaleModified
            );
            markupCache.addMarkupsToProject(diff.created, projectUUID);
            markupCache.removeMarkups(diff.deleted, projectID);
            markupCache.updateMarkupGeometries(diff.geometryModified);
            markupCache.updateMarkupColors(diff.colorModified);
        },
        [markups, markupCache, persistedScale]
    );

    useEffect(() => {
        const subscription = subscribeToPersistedGeometries(onGeometriesPersisted);
        return () => {
            subscription.unsubscribe();
        };
    }, [onGeometriesPersisted]);

    useEffect(() => {
        if (
            selectedMarkup &&
            !selectedGeometriesObservable.value.geometries.find(
                (geometry) => geometry.uuid === selectedMarkup.id
            )
        ) {
            selectedGeometriesObservable.next({
                geometries: [fromMarkup(selectedMarkup)],
                points: [],
            });
        }
    }, [selectedMarkup]);

    useEffect(() => {
        if (synchronizedProjectUUIDs.has(projectUUID)) {
            throw new Error('useTakeoffMarkupSync called concurrently for project: ' + projectUUID);
        }
        synchronizedProjectUUIDs.add(projectUUID);
        return () => {
            synchronizedProjectUUIDs.delete(projectUUID);
        };
    }, []);
};
