import { IStripeStatus, ITeamBillingFragment, ITeamFragment } from '@/graphql';
import { Plan, PlanName, PlanPeriod } from './types';

type Team = ITeamFragment | ITeamBillingFragment | null;

export const hasPlan = (plan?: Plan, team?: Team) => {
    return (
        plan?.variants.some((variant) => {
            return variant.id === team?.subscription?.priceID;
        }) === true
    );
};

export const hasPaidPlan = (plan?: Plan, team?: Team) => {
    return hasPlan(plan, team) && team?.subscription?.status !== IStripeStatus.Trialing;
};

export const getPlanByName = (plans?: Plan[], name?: PlanName) => {
    return plans?.find((plan) => {
        return plan.name === name;
    });
};

export const getPlanByVariantId = (plans?: Plan[], id?: string | null) => {
    return plans?.find((plan) => {
        return plan.variants.some((variant) => variant.id === id);
    });
};

export const getPlanVariantByPriceId = (plans?: Plan[], id?: string | null) => {
    return plans?.flatMap((plan) => plan.variants).find((variant) => variant.id === id);
};

export const hasSelfStarterSubscription = (plans?: Plan[], team?: Team) => {
    const plan = getPlanByName(plans, PlanName.SelfStarter);

    return hasPaidPlan(plan, team);
};

export const hasEssentialSubscription = (plans?: Plan[], team?: Team) => {
    const plan = getPlanByName(plans, PlanName.Essential);

    return hasPaidPlan(plan, team);
};

export const hasFeb2022EssentialSubscription = (plans: Plan[], team: ITeamFragment | null) => {
    const startDate = new Date('02-11-2022');
    const subscriptionDate = new Date(team?.subscription?.createdAt);

    return hasEssentialSubscription(plans, team) && subscriptionDate >= startDate;
};

export const hasProfessionalSubscription = (plans?: Plan[], team?: Team) => {
    const plan = getPlanByName(plans, PlanName.Professional);

    return hasPaidPlan(plan, team);
};

export const hasMonthlySubscription = (plans?: Plan[], team?: Team) => {
    const variant = getPlanVariantByPriceId(plans, team?.subscription?.priceID);

    return variant?.period === PlanPeriod.Monthly;
};

export const hasSelfCheckoutSubscription = (plans?: Plan[], team?: Team) => {
    return (
        hasProfessionalSubscription(plans, team) ||
        hasEssentialSubscription(plans, team) ||
        hasSelfStarterSubscription(plans, team)
    );
};

export const getVisiblePlans = (plans?: Plan[], team?: ITeamFragment | null) => {
    if (hasProfessionalSubscription(plans, team) && !hasMonthlySubscription(plans, team)) {
        return plans?.slice(2);
    } else if (hasEssentialSubscription(plans, team) || hasMonthlySubscription(plans, team)) {
        return plans?.slice(1);
    } else {
        return plans;
    }
};
