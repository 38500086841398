/*
 * reviewTags.ts provides GraphQL queries used to fetch records from the
 * `reviewTag` table.
 */
import { frogmint } from '../frog';

export interface ReviewTagRecord {
    id: string;
    tag: string;
    isPositive: boolean;
}

export interface ReviewTagRecords {
    reviewTags: {
        nodes: ReviewTagRecord[];
    };
}

export interface ReviewTagCondition {
    id?: number;
}

export const reviewTagFragment = frogmint`
    ${'ReviewTag_reviewTag'} {
        id
        tag
        isPositive
    }
`;
