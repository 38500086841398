/*
 * ContextMenu is the right-click menu of the assembly panel.
 */
import React, { FC, useEffect } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

export type MousePosition = {
    x: number;
    y: number;
};

export enum ContextMenuType {
    NORMAL = 'NORMAL',
    PREVIEW = 'PREVIEW',
}

const COPY = {
    deleteText: 'Delete',
    duplicateText: 'Duplicate',
    importText: 'Add to project',
    favoriteText: 'Add to My Library',
    unfavoriteText: 'Remove from My Library',
    importTextPlural: 'Add to project',
};

type ContextMenuProps = {
    position: MousePosition;
    onClose: () => void;
    onDelete?: () => void;
    onDuplicate?: () => void;
    onFavorite?: () => void;
    type: ContextMenuType;
    favorited: boolean;
};

export const ContextMenu: FC<ContextMenuProps> = ({
    position,
    onClose,
    onDelete,
    onDuplicate,
    onFavorite,
    type,
    favorited,
}) => {
    useEffect(() => {
        window.addEventListener('click', onClose);
        return (): void => window.removeEventListener('click', onClose);
    }, []);

    const menuPositionSyle = {
        left: `${position.x}px`,
        top: `${position.y}px`,
    };

    return (
        <OutsideClickHandler onOutsideClick={onClose}>
            <div style={menuPositionSyle} className="assembly-context-menu">
                {type === ContextMenuType.NORMAL ? (
                    <>
                        {onDuplicate && <span onClick={onDuplicate}>{COPY.duplicateText}</span>}
                        {onFavorite && (
                            <span onClick={onFavorite}>
                                {favorited ? COPY.unfavoriteText : COPY.favoriteText}
                            </span>
                        )}
                        {onDelete && <span onClick={onDelete}>{COPY.deleteText}</span>}
                    </>
                ) : (
                    <>
                        {onFavorite && (
                            <span onClick={onFavorite}>
                                {favorited ? COPY.unfavoriteText : COPY.favoriteText}
                            </span>
                        )}
                    </>
                )}
            </div>
        </OutsideClickHandler>
    );
};
