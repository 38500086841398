/**
 * This component renders the geometries.
 **/
import React, { FC } from 'react';

import { FeatureGroup } from 'react-leaflet';

import { TakeoffComponentProps } from '../context';
import { GeometryTool } from './overlay/GeometryTool';

import { useGeometryIDs } from '@/components/takeoff/hooks/useGeometryIDs';

export const EditableShapeOverlay: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const geometryIDs = useGeometryIDs();

    return (
        <FeatureGroup bubblingMouseEvents={false}>
            {[...geometryIDs].map((geometryID) => (
                <GeometryTool key={geometryID} geometryID={geometryID} useTakeoff={useTakeoff} />
            ))}
        </FeatureGroup>
    );
};
