import { PlanPage } from '@/common/types';
import Fraction from 'fraction.js';

export const getScaleInInches = (scale: number, currentPage: PlanPage): number => {
    return (
        scale * (currentPage.heightIn / currentPage.heightPx) // pixels per inch
    );
};

const getNumberOfDecimalPlaces = (num: number): number => {
    const numberString = num.toString();
    if (numberString.includes('.')) {
        return numberString.split('.')[1].length;
    } else {
        return 0;
    }
};

export const getScaleAsFraction = (scale: number, currentPage: PlanPage) => {
    let decimalPlaces = 1;
    // The rounding we do here acts as a threshold that keeps us within sane fractions (e.g 1/4 vs 6/23) -
    // we can't control the way fraction.js produces the fraction all that well, so that's how we attempt to do it.
    // But if the numbers provided are super well-defined (as it would happen if they came from our "Standard"
    // tab), they'll be pretty short on the decimal side - that's when we can make the rounding less drastic, to
    // be able to produce desirable "insane" fractions like 3/32 instead of 2/21.
    if (getNumberOfDecimalPlaces(scale) < 3) {
        decimalPlaces = 2;
    }
    const inchesToFoot = getScaleInInches(Number(scale.toFixed(decimalPlaces)), currentPage);
    return `${new Fraction(inchesToFoot).simplify(0.001).toFraction(true)}" = 1' 0"`;
};
