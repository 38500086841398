import gql from 'graphql-tag';

export type SubmitRevisionMutationResponse = {
    submitRevision: {
        project: {
            id: number;
        };
    };
};
export const submitRevisionMutation = gql`
    mutation SubmitRevision($projectId: BigInt!, $newDate: String!, $ownerId: BigInt!) {
        submitRevision(input: { projectId: $projectId, newDate: $newDate, ownerId: $ownerId }) {
            project {
                id
            }
        }
    }
`;
