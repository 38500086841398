import { EditablePolyline } from './EditablePolyline';
import { toLeaflet } from '@/common/convert/geometry';
import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import { ContextMenuDispatcherType, Geometry, GeometryType } from '@/common/types';
import { latLngsIs } from '@/common/utils/geometries/leaflet';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useActiveVertices } from '@/components/takeoff/hooks/useActiveVertices';
import {
    booleanToolObservable,
    currentlyAddedCoordinatesObservable,
    subscribeToBoundary,
} from '@/components/takeoff/observables/helpers';
import { colorPrimaryLighter } from '@/variables';
import { DomEvent, LatLngBounds, LeafletMouseEvent } from 'leaflet';
import React, { FC, useEffect, useState } from 'react';
import { FeatureGroup, Polyline } from 'react-leaflet';

export interface LinearToolProps extends TakeoffComponentProps {
    maxVertexCount?: number;
    geometry: Geometry<GeometryType.LINEAR>;
    selected: boolean;
    editable: boolean;
}

export const LinearTool: FC<LinearToolProps> = ({
    maxVertexCount,
    geometry,
    selected,
    editable,
    useTakeoff,
}: LinearToolProps) => {
    /**
     * This component uses the generic EditablePolyline to implement the
     * linear drawing tool. The logic taking place in this component is mostly
     * related to actions that take place when clicking on the geometry.
     **/

    const { select, removeBoundary, addBoundary, edit, setContextMenuPayload } = useTakeoff();
    const activeVertices = useActiveVertices();

    const onContextMenu = (e: LeafletMouseEvent): void => {
        // So we basically do like a ray-traced collision detection in the point in which the
        // right-click event was triggered.
        const clickedElements = document.elementsFromPoint(e.originalEvent.x, e.originalEvent.y);
        let isEdge = false;
        // If the ray went through two paths (the first one being a geometry and the second one being the blueish
        // outline that's displayed when a geometry is selected) it means we hit the edge.
        if (
            clickedElements[0] instanceof SVGPathElement &&
            clickedElements[1] instanceof SVGPathElement
        ) {
            isEdge = true;
        }
        if (!editable && selected) {
            setContextMenuPayload({
                coordinates: e.latlng,
                isEdge,
                dispatcher: ContextMenuDispatcherType.LINE,
            });
        }
        DomEvent.stopPropagation(e);
    };

    const [selectionRef, setSelectionRef] = useState<Polyline>();
    const [selectMultipleGeo, setSelectMultipleGeo] = useState<boolean>(false);

    const [boundary, setBoundary] = useState<LatLngBounds | null>();

    useEffect(() => {
        const subscription = subscribeToBoundary(setBoundary);
        return (): void => subscription.unsubscribe();
    }, []);

    const currentlyAddedCoordinates = currentlyAddedCoordinatesObservable.value;

    useEffect(() => {
        selectionRef?.leafletElement.bringToBack();
    }, [selectionRef]);

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            setSelectMultipleGeo(true);
        }
    };

    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            setSelectMultipleGeo(false);
        }
    };

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    const hoverable = !boundary;

    const leafletLatLngs = (() => {
        const latLngs = toLeaflet(geometry).getLatLngs();
        // The `Polyline` component can't handle 3D geometries
        if (latLngsIs.threeDimensional(latLngs)) {
            return latLngs.flatMap((l) => l);
        }
        return latLngs;
    })();

    // Don't handle events when using boolean tools
    const shouldHandleEvents = !booleanToolObservable.value && !currentlyAddedCoordinates;

    const showSelection =
        selected &&
        !editable &&
        (!currentlyAddedCoordinates || (currentlyAddedCoordinates && booleanToolObservable.value));

    const mouseEvents = {
        onclick: (): void => {
            select(geometry, selectMultipleGeo);
            removeBoundary();
        },
        ondblclick: (): void => edit(geometry.uuid),
        onmouseover: (): void => {
            !selected && !editable && hoverable && addBoundary(geometry.uuid);
        },
        onmouseout: (): void => {
            !selected && !editable && hoverable && removeBoundary();
        },
        oncontextmenu: onContextMenu,
    };

    return (
        <FeatureGroup {...(shouldHandleEvents && mouseEvents)} bubblingMouseEvents={false}>
            {showSelection && leafletLatLngs.length > 0 && (
                <Polyline
                    positions={leafletLatLngs}
                    ref={(newRef: Polyline): void => setSelectionRef(newRef)}
                    weight={4 + (geometry.style.weight ?? 2)}
                    shapeWeight={geometry.style.shapeWeight}
                    color={colorPrimaryLighter}
                    interactive
                    lineType={geometry.style.lineType}
                />
            )}
            <EditablePolyline
                geometry={geometry}
                maxVertexCount={maxVertexCount}
                editable={editable}
                activeVertices={activeVertices}
                useTakeoff={useTakeoff}
            />
        </FeatureGroup>
    );
};
