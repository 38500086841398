import React, { FC, MouseEvent, MouseEventHandler } from 'react';
import { useSlateStatic } from 'slate-react';

import { UomSelectionContainer, UomSelectionOption, UomSelectionWrapper } from './styled';
import { Transforms } from '../../transforms';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { DivProps } from '@/common/types';
import { UomRecord } from '@/queries/unitOfMeasure';

export interface UomSelector extends DivProps {
    uoms: UomRecord[];
    onSelectUom: (uom: UomRecord) => void;
}

export const UomSelector: FC<UomSelector> = ({ uoms, onSelectUom, ...props }) => {
    const editor = useSlateStatic();

    useDocumentListener(
        'keydown',
        (e) => {
            if (!e.defaultPrevented && e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                Transforms.deselect(editor);
            }
        },
        []
    );

    const handleClickOption = (e: MouseEvent, uom: UomRecord): void => {
        if (!e.defaultPrevented) {
            e.preventDefault();
            e.stopPropagation();
            onSelectUom(uom);
        }
    };

    const handleContextMenu: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
    };

    return (
        <UomSelectionWrapper {...props} onContextMenu={handleContextMenu}>
            <UomSelectionContainer>
                {uoms.map((uom) => (
                    <UomSelectionOption
                        key={uom.id}
                        onClick={(e): void => handleClickOption(e, uom)}
                    >
                        <span>{uom.description}</span>
                    </UomSelectionOption>
                ))}
            </UomSelectionContainer>
        </UomSelectionWrapper>
    );
};
