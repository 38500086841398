// The following section is heavily inspired by https://stackoverflow.com/a/53058574. It was ported to Typescript
// and slightly adjusted to make reading the actual File objects easier.

type Entry = File & {
    isDirectory: boolean;
    isFile: boolean;
    file: (callback: (file: File) => void, error: (reason?: string) => void) => void;
};

type DirectoryReader = {
    readEntries: (callback: (entries: Entry[]) => void, error: (reason?: string) => void) => void;
};

// Wrap readEntries in a promise to make working with readEntries easier readEntries will return only some of the
// entries in a directory e.g. Chrome returns at most 100 entries at a time
const readEntriesPromise = async (directoryReader: DirectoryReader): Promise<Entry[]> => {
    return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
    });
};

// Reading the file from entry is also asynchronous, and to solve this we have to wrap it in a Promise too.
const getFileFromEntryPromise = async (fileEntry: Entry): Promise<File> => {
    return await new Promise((resolve, reject) => {
        fileEntry.file(resolve, reject);
    });
};

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
const readAllDirectoryEntries = async (directoryReader: DirectoryReader): Promise<Entry[]> => {
    const entries: Entry[] = [];
    let readEntries = await readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await readEntriesPromise(directoryReader);
    }
    return entries;
};

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
export const getAllFileEntries = async (
    dataTransferItemList: DataTransferItemList
): Promise<File[]> => {
    const fileEntries: Entry[] = [];
    // Use BFS to traverse entire directory/file structure
    const queue = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
        const entry = queue.shift();
        if (entry.isFile) {
            fileEntries.push(entry);
        } else if (entry.isDirectory) {
            queue.push(...(await readAllDirectoryEntries(entry.createReader())));
        }
    }
    const files: File[] = [];
    for (const entry of fileEntries) {
        files.push(await getFileFromEntryPromise(entry));
    }
    return files;
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
/* eslint-enable @typescript-eslint/no-unsafe-call */

// End of inspiration.
