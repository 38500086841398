import { Editor } from 'slate';

import {
    CollapsedCategoriesSubscriber,
    ElementTotals,
    ElementTotalsSubscriberInfo,
    HoveredRowSubscriber,
    SubscriptionNotificationThrottler,
} from '../utils/types';

/**
 * editor-related state storage
 */

export const COLLAPSED_CATEGORIES: WeakMap<Editor, Set<string>> = new WeakMap();
export const COLLAPSED_CATEGORIES_SUBSCRIBERS: WeakMap<
    Editor,
    Set<CollapsedCategoriesSubscriber>
> = new WeakMap();
export const ELEMENT_TOTALS: WeakMap<Editor, ElementTotals> = new WeakMap();
export const ELEMENT_TOTALS_SUBSCRIBERS: WeakMap<
    Editor,
    Set<ElementTotalsSubscriberInfo>
> = new WeakMap();
export const ELEMENT_TOTALS_THROTTLER: WeakMap<Editor, SubscriptionNotificationThrottler> =
    new WeakMap();
export const HOVERED_ROW: WeakMap<Editor, number> = new WeakMap();
export const HOVERED_ROW_SUBSCRIBERS: WeakMap<Editor, Set<HoveredRowSubscriber>> = new WeakMap();
export const HOVERED_ROW_THROTTLER: WeakMap<Editor, SubscriptionNotificationThrottler> =
    new WeakMap();
