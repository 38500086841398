import React, { FC } from 'react';

import { Clients } from '../Clients';
import { Skills } from '../Skills';
import { About } from './About';
import { Header } from './Header';
import { PanelContent, PanelDivider } from './styled';

import { useEstimatorActions } from '@/common/hooks/useEstimatorActions';
import { RightSidePanel } from '@/components/ui/controls/RightSidePanel';
import { Tab, Tabs } from '@/components/ui/controls/Tabs';
import { IClientPreferenceStatus, IUserFragment } from '@/graphql';

export const TAB_KEYS = {
    ABOUT: 'about',
    SKILLS: 'skills',
    CLIENTS: 'clients',
};

export interface EstimatorPanelProps {
    open: boolean;
    close: () => void;
    activeTab: string;
    selectedUser: IUserFragment;
    isLoading: boolean;
}

export const EstimatorPanel: FC<EstimatorPanelProps> = ({
    open,
    close,
    activeTab,
    selectedUser,
    isLoading,
}) => {
    const {
        trades: { updateTradesRating },
        status: { updateEstimatorStatus, scheduleAssignmentLoading },
        clients: { updateClientPreference },
    } = useEstimatorActions({ userId: selectedUser.id });

    const updateRating = (tradeID: string, rating: number): void => {
        updateTradesRating(tradeID, rating);
    };

    const pauseAvailability = (paused: boolean): void => {
        updateEstimatorStatus(paused, selectedUser.schedule?.id);
    };

    const updatePreferredClient = async (
        teamID: string,
        status: IClientPreferenceStatus,
        clientID: string
    ): Promise<void> => {
        await updateClientPreference(teamID, status, clientID);
    };

    const renderHeader = (
        <Header
            estimatorName={`${selectedUser.firstName} ${selectedUser.lastName}`}
            estimatorStatus={selectedUser.status}
        />
    );

    return (
        <RightSidePanel isPanelOpened={open} closePanel={close} panelHeaderChildren={renderHeader}>
            <Tabs activeKey={activeTab}>
                <Tab eventKey={TAB_KEYS.ABOUT} title="About">
                    <PanelDivider />
                    <PanelContent>
                        <About
                            loading={scheduleAssignmentLoading || isLoading}
                            selectedUser={selectedUser}
                            onPause={pauseAvailability}
                        />
                    </PanelContent>
                </Tab>
                <Tab eventKey={TAB_KEYS.SKILLS} title="Skills">
                    <PanelDivider />
                    {selectedUser.tradeRatings && (
                        <PanelContent>
                            <Skills
                                tradeRatings={selectedUser.tradeRatings}
                                updateRating={updateRating}
                            />
                        </PanelContent>
                    )}
                </Tab>
                <Tab eventKey={TAB_KEYS.CLIENTS} title="Clients">
                    <PanelDivider />
                    {selectedUser.preferredClients && (
                        <PanelContent>
                            <Clients
                                clients={selectedUser.preferredClients}
                                updateClient={updatePreferredClient}
                            />
                        </PanelContent>
                    )}
                </Tab>
            </Tabs>
        </RightSidePanel>
    );
};
