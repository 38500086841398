import React, { FC } from 'react';

import { Container, Copy } from './styled';

const COPY = {
    header: 'No location entered',
    content:
        'A location must be entered before using the estimate tab or ' +
        'before completing a project.',
    cancel: 'Cancel',
    submit: 'Enter location',
};

interface LocationPromptProps {
    close: () => void;
    submit: () => void;
}

export const LocationPrompt: FC<LocationPromptProps> = ({ close, submit }) => {
    return (
        <Container
            header={COPY.header}
            cancelCopy={COPY.cancel}
            submitCopy={COPY.submit}
            close={close}
            submit={submit}
        >
            <Copy>{COPY.content}</Copy>
        </Container>
    );
};
