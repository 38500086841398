/*
 * projectEstimateFiles.ts provides GraphQL queries for the project_estimate_file table.
 */
import { frogmint, newQueries } from '../frog';

export interface ProjectEstimateFileRecord {
    created?: string;
    lastModified?: string;
    id: number;
    uuid: string;
    filename: string;
    projectId: number;
    __typename: 'ProjectEstimateFile';
}

export interface ProjectEstimateFileRecords {
    projectEstimateFiles: {
        nodes: ProjectEstimateFileRecord[];
    };
}

export interface ProjectEstimateFileCondition {
    id?: number;
    projectId?: number;
    uuid?: string;
}

export const projectEstimateFileFragment = frogmint`
    ${'ProjectEstimateFile_projectEstimateFile'} {
        created
        lastModified
        id
        uuid
        filename
        projectId
        __typename
    }
`;

export const ProjectEstimateFilesQuery = projectEstimateFileFragment.gql`
    query ProjectEstimateFiles($condition: ProjectEstimateFileCondition) {
        projectEstimateFiles(condition: $condition) {
            nodes {
                ${projectEstimateFileFragment}
            }
        }
    }
`;

export const [executeProjectEstimateFilesQuery, useProjectEstimateFilesQuery] = newQueries<
    ProjectEstimateFileCondition,
    ProjectEstimateFileRecords
>(ProjectEstimateFilesQuery);
