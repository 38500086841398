import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import React, { FC } from 'react';
import { hot } from 'react-hot-loader/root';
import HotKeysModal from './components/app/HotKeysModal';
import { Router } from './components/app/router';
import { GlobalLoader } from './components/ui/loaders/GlobalLoader';
import { GlobalProviders } from './contexts';
import { AppGlobalStyle } from './styled';

import './App.scss';

export const App: FC = () => {
    return (
        <>
            <AppGlobalStyle />
            <CssBaseline />
            <GlobalProviders>
                <Paper elevation={0} square>
                    <GlobalLoader />
                    <Router />
                    <HotKeysModal />
                </Paper>
            </GlobalProviders>
        </>
    );
};

export const HotApp = hot(App);
