import { makeUseSubscription } from '../frog';
import {
    projectFragment,
    ProjectRecord,
    ProjectWithEventsRecord,
    projectWithEventsFragment,
} from '../queries/projects';

export type ProjectMessage = {
    ProjectChanged: {
        projectEntry?: ProjectRecord | null;
    };
};

export const projectSubscription = projectFragment.gql`
    subscription {
        ProjectChanged {
            projectEntry {
                ${projectFragment}
            }
        }
    }
`;

export const useProjectSubscription = makeUseSubscription<ProjectMessage>(projectSubscription);

export type ProjectWithEventsMessage = {
    ProjectChanged: {
        projectEntry?: ProjectWithEventsRecord | null;
    };
};

export const projectWithEventsSubscription = projectWithEventsFragment.gql`
    subscription {
        ProjectChanged {
            projectEntry {
                ${projectWithEventsFragment}
            }
        }
    }
`;

export const useProjectWithEventsSubscription = makeUseSubscription<ProjectWithEventsMessage>(
    projectWithEventsSubscription
);
