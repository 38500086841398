/*
 * Search is a input for searching through estimators for assignment.
 */
import React, { ChangeEvent, FC, useState } from 'react';

import { EstimatorAssignmentSearchContainer, EstimatorAssignmentSearchInput } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';

import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifying-glass-alt-dark.svg';

const COPY = {
    placeholder: 'Search estimators',
};

export const Search: FC<ProjectPanelComponentProps> = () => {
    const [searchString, setSearchString] = useState('');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setSearchString(e.target.value);

    return (
        <EstimatorAssignmentSearchContainer>
            <MagnifyingGlass width="1.25rem" height="1.25rem" />
            <EstimatorAssignmentSearchInput
                type="text"
                value={searchString}
                onChange={handleInputChange}
                placeholder={COPY.placeholder}
            />
        </EstimatorAssignmentSearchContainer>
    );
};
