/**
 * A button to commit actions related to the primary purpose of a UI component,
 * this button may or may not contain dynamic data.
 */
import clsx from 'clsx';
import React, { FC } from 'react';

import { useDisableAfterClick } from './helpers/useDisableAfterClick';
import { ArrowIcon } from '../icons/ArrowIcon';
import { ButtonProps, Direction } from '@/common/types';

import './ArrowButton.scss';

export interface ArrowButtonProps extends ButtonProps {
    text: string;
    variant?: 'solid' | 'outlined';
}

export const ArrowButton: FC<ArrowButtonProps> = ({
    className,
    text,
    variant = 'solid',
    onClick,
    disabled,
    ...shared
}) => {
    const [wrappedOnClick, wrappedDisabled] = useDisableAfterClick(onClick);

    return (
        <button
            className={clsx(className, 'arrow-button-container', {
                solid: variant === 'solid',
                outlined: variant === 'outlined',
            })}
            onClick={wrappedOnClick}
            disabled={disabled || wrappedDisabled}
            {...shared}
        >
            <span className="arrow-button-text">{text}</span>
            <ArrowIcon direction={Direction.Right} />
        </button>
    );
};
