import { OnebuildLibrarySection } from '../../OnebuildLibrarySection';
import { GeometrySection } from './GeometrySection';
import { MyLibrarySection } from './MyLibrarySection';
import { ProjectList } from './ProjectList';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { LibraryCategory } from '@/components/AssemblyPanel/utils/types';
import React, { FC } from 'react';

type AssemblyListProps = {
    currentProjectId: number;
};

export const AssemblyList: FC<AssemblyListProps> = ({ currentProjectId }) => {
    const { openedCategory } = useAssemblyPanel();

    if (openedCategory === LibraryCategory.Geometries) {
        return <GeometrySection />;
    }

    if (openedCategory === LibraryCategory.PastProjects) {
        return <ProjectList projectId={currentProjectId} />;
    }

    if (openedCategory === LibraryCategory.OneBuild) {
        return <OnebuildLibrarySection projectId={currentProjectId} />;
    }

    if (openedCategory === LibraryCategory.Favorites) {
        return <MyLibrarySection projectId={currentProjectId} />;
    }

    return null;
};
