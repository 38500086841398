import Flatten from '@flatten-js/core';

import { fromCoordinate } from '@/common/convert/coordinateData';
import { Point } from '@/common/types';

type FlattenData =
    | Flatten.Face
    | Flatten.Multiline
    | Flatten.Point
    | Flatten.Point[]
    | Flatten.Polygon;

type Convert<T extends FlattenData> = T extends Flatten.Face
    ? Point[]
    : T extends Flatten.Multiline
    ? Point[]
    : T extends Flatten.Point
    ? Point
    : T extends Flatten.Point[]
    ? Point[]
    : T extends Flatten.Polygon
    ? Point[][]
    : never;

type Converter<T extends FlattenData> = (geometry: T) => Convert<T>;

const fromFace: Converter<Flatten.Face> = (face) => {
    return face.toPolygon().vertices.map(fromPoint);
};

const fromMultiline: Converter<Flatten.Multiline> = (multiline) => {
    return multiline.vertices.map(fromPoint);
};

const fromPoint: Converter<Flatten.Point> = (point) => {
    return fromCoordinate({ x: point.y, y: point.x });
};

const fromPoints: Converter<Flatten.Point[]> = (points) => {
    return points.map(fromPoint);
};

const fromPolygon: Converter<Flatten.Polygon> = (polygon) => {
    const rings: Point[][] = [];
    polygon.faces.forEach((face) => rings.push(fromFace(face)));
    return rings;
};

export function fromFlatten<T extends FlattenData>(data: T): Convert<T>;
export function fromFlatten(data: FlattenData) {
    if (data instanceof Flatten.Face) return fromFace(data);
    if (data instanceof Flatten.Multiline) return fromMultiline(data);
    if (data instanceof Flatten.Point) return fromPoint(data);
    if (data instanceof Flatten.Polygon) return fromPolygon(data);
    else return fromPoints(data);
}
