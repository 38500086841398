import { ReactComponent as Area } from '@/assets/icons/Area.svg';
import { ReactComponent as Count } from '@/assets/icons/Count.svg';
import { ReactComponent as Hammer } from '@/assets/icons/Hammer.svg';
import { ReactComponent as Linear } from '@/assets/icons/Linear.svg';
import { GeometryType } from '@/common/types';
import { AssemblyDataRecord, MaterialData } from '@/components/AssemblyPanel/utils/types';
import { IAssemblyFragment, IMaterialFragment } from '@/graphql';
import { UomRecord, UomType } from '@/queries/unitOfMeasure';

export const assemblyPanelAssemblyToDataRecord = (
    assembly: IAssemblyFragment
): AssemblyDataRecord => ({
    // We gotta do this because postgraphile does it this way despite claiming they provide Number
    id: assembly.id as unknown as number,
    description: assembly.description,
    popularity: 0,
    uomId: Number(assembly.unit.id),
    uom: {
        id: Number(assembly.unit.id),
        definition: '',
        description: '',
        name: assembly.unit.name,
        uomType: assembly.unit.unitType as unknown as UomType,
    },
    projectId: Number(assembly.projectID),
    tradeId: Number(assembly.trade?.id),
    trade: {
        id: assembly.trade?.id ?? '',
        name: assembly.trade?.name ?? '',
        nickname: assembly.trade?.nickname ?? '',
        csiDivision: '',
        orderWeight: 0,
        isSelectableAtProjectCreation: assembly.trade?.isLinked ?? false,
    },
    favorited: assembly.favorited,
    // elements: assembly.elements
});

export const materialFragmentToMaterialData = (material: IMaterialFragment): MaterialData => ({
    id: material.id,
    name: material.name,
    takeoffUomId: Number(material.takeoffUnitID),
    takeoffUom: {
        id: Number(material.takeoffUnitID),
        definition: '',
        description: '',
    } as UomRecord,
});

export const getGeometryIcon = (type: GeometryType | 'standalone'): Svg => {
    switch (type) {
        case 'standalone':
            return Hammer;
        case GeometryType.AREA:
            return Area;
        case GeometryType.COUNT:
            return Count;
        case GeometryType.LINEAR:
        default:
            return Linear;
    }
};
