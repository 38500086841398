/*
 * sort.ts implements project sorting logic.
 */
import { AdminDashboardProjectStatus, DatabaseProjectStatus } from '@/common/types';
import { ProjectColumn, TabType } from '@/components/AdminDashboard/types';
import { IProjectStatus } from '@/graphql';
import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';

export type SortingCompareFunction = (
    a: ProjectWithEventsRecord,
    b: ProjectWithEventsRecord
) => number;

export enum SortingDirection {
    Ascending,
    Descending,
}

export interface SortingMethod {
    column: ProjectColumn;
    direction: SortingDirection;
}

type OptionalComparitor<T> = T | undefined;
type OptionalNumber = OptionalComparitor<number>;
type OptionalString = OptionalComparitor<string>;
type OptionalUser = OptionalComparitor<BaseUserRecord>;
type CompoundOptionalString = [OptionalString, OptionalString];

export const defaultSortingMethod: SortingMethod = {
    column: ProjectColumn.SubmittedDate,
    direction: SortingDirection.Descending,
};

const compareOptionals = <T>(a: OptionalComparitor<T>, b: OptionalComparitor<T>): number => {
    if (a === undefined && b !== undefined) {
        return -1;
    }
    if (a !== undefined && b === undefined) {
        return 1;
    }
    return 0;
};

const compareNumbers = (a: OptionalNumber, b: OptionalNumber): number => {
    if (a === undefined || b === undefined) {
        return compareOptionals(a, b);
    }
    return b - a;
};

const statusImportance = (status: AdminDashboardProjectStatus): number => {
    switch (status) {
        case AdminDashboardProjectStatus.NEW_SUBMISSION:
            return 100;
        case AdminDashboardProjectStatus.PENDING_ACCEPTANCE:
            return 99;
        case AdminDashboardProjectStatus.NEEDS_REVIEW:
            return 98;
        case AdminDashboardProjectStatus.IN_PROGRESS:
            return 97;
        case AdminDashboardProjectStatus.PROJECT_CANCELED:
            return 96;
        default:
            return 0;
    }
};

// Legacy, copied from `projects/admin-dashboard/ProjectRow.tsx
export const mapStatus = (
    status?: DatabaseProjectStatus | IProjectStatus | null
): AdminDashboardProjectStatus => {
    const is = (match: DatabaseProjectStatus): boolean => status === match;

    if (is(DatabaseProjectStatus.DRAFT)) return AdminDashboardProjectStatus.DRAFT;
    if (is(DatabaseProjectStatus.NEW)) return AdminDashboardProjectStatus.NEW_SUBMISSION;
    if (is(DatabaseProjectStatus.PENDING_ESTIMATOR))
        return AdminDashboardProjectStatus.PENDING_ACCEPTANCE;
    if (is(DatabaseProjectStatus.REVISION_REQUESTED))
        return AdminDashboardProjectStatus.PENDING_REVISION;
    if (is(DatabaseProjectStatus.REVISION_SUBMITTED))
        return AdminDashboardProjectStatus.UPDATED_SUBMISSION;
    if (is(DatabaseProjectStatus.CANCELED)) return AdminDashboardProjectStatus.PROJECT_CANCELED;
    if (is(DatabaseProjectStatus.ESTIMATED)) return AdminDashboardProjectStatus.NEEDS_REVIEW;
    if (is(DatabaseProjectStatus.ESTIMATING)) return AdminDashboardProjectStatus.IN_PROGRESS;
    if (is(DatabaseProjectStatus.COMPLETE)) return AdminDashboardProjectStatus.COMPLETE;

    return AdminDashboardProjectStatus.NEEDS_PRICE;
};

export const mapProjectStatus = (
    project: ProjectRecord
): AdminDashboardProjectStatus | undefined => {
    if (!project.status) {
        return undefined;
    }
    return mapStatus(project.status);
};

const compareStatus = (a: ProjectRecord, b: ProjectRecord): number => {
    const statusA = mapProjectStatus(a);
    const statusB = mapProjectStatus(b);
    if (statusA === undefined || statusB === undefined) {
        return compareOptionals(statusA, statusB);
    }
    return statusImportance(statusB) - statusImportance(statusA);
};

const compareStrings = (a: OptionalString, b: OptionalString): number => {
    if (a === undefined || b === undefined) {
        return compareOptionals(a, b);
    }
    return a.localeCompare(b);
};

const compareCompoundStrings = (a: CompoundOptionalString, b: CompoundOptionalString): number => {
    let result = compareStrings(a[0], b[0]);
    if (result === 0) {
        result = compareStrings(a[1], b[1]);
    }
    return result;
};

const compareUserNames = (a: OptionalUser, b: OptionalUser): number =>
    compareCompoundStrings([a?.firstName, a?.lastName], [b?.firstName, b?.lastName]);

export const compareDateStrings = (a: OptionalString, b: OptionalString): number => {
    if (a === undefined || b === undefined) {
        return compareOptionals(a, b);
    }
    return new Date(b).getTime() - new Date(a).getTime();
};

export const getSortingCompareFunction = (column: ProjectColumn): SortingCompareFunction | null => {
    switch (column) {
        case ProjectColumn.Project:
            return (a, b): number => compareStrings(a.name, b.name);
        case ProjectColumn.Builder:
            return (a, b): number => compareStrings(a.team?.name, b.team?.name);
        case ProjectColumn.SubmittedDate:
            return (a, b): number => compareDateStrings(a.created, b.created);
        case ProjectColumn.DueDate:
            return (a, b): number => compareDateStrings(a.bidsDueDate, b.bidsDueDate);
        case ProjectColumn.Estimator:
            return (a, b): number =>
                compareUserNames(a.projectUsers?.nodes[0]?.user, b.projectUsers?.nodes[0]?.user);
        case ProjectColumn.Price:
            return (a, b): number => {
                const aPriceUsdCents =
                    a.pricingAssignment &&
                    a.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0]
                        .priceUsdCents;

                const bPriceUsdCents =
                    b.pricingAssignment &&
                    b.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0]
                        .priceUsdCents;

                return compareNumbers(aPriceUsdCents, bPriceUsdCents);
            };
        case ProjectColumn.Status:
            return (a, b): number => compareStatus(a, b);
        default:
            return null;
    }
};

// Sort `projects` non-destructively by `method`.
export const sort = (
    projects: ProjectWithEventsRecord[],
    method: SortingMethod
): ProjectWithEventsRecord[] => {
    const compareFunction = getSortingCompareFunction(method.column);
    if (compareFunction === null) {
        return projects;
    }
    const projectsCopy = [...projects];
    projectsCopy.sort(compareFunction);
    if (method.direction === SortingDirection.Ascending) {
        projectsCopy.reverse();
    }
    return projectsCopy;
};

interface FilterProjectsOptions {
    filterCancelledProjects?: boolean;
}
export const filterProjects = (
    projects: ProjectWithEventsRecord[],
    tabType: TabType,
    method: SortingMethod | null,
    selectedProjectIDs: number[],
    options?: FilterProjectsOptions
): ProjectWithEventsRecord[] => {
    let filteredProjects = [...projects.filter((p) => !p.parentProjectUuid)];
    switch (tabType) {
        case TabType.Current:
            filteredProjects = filteredProjects.filter(
                (project) => project.status !== DatabaseProjectStatus.COMPLETE && !project.isSaas
            );
            break;
        case TabType.SaaS:
            filteredProjects = filteredProjects.filter((project) => project.isSaas);
            break;
        case TabType.Completed:
            filteredProjects = filteredProjects.filter(
                (project) => project.status === DatabaseProjectStatus.COMPLETE && !project.isSaas
            );
            break;
    }

    if (selectedProjectIDs.length > 0) {
        filteredProjects = filteredProjects.filter((project) =>
            selectedProjectIDs.includes(project.id)
        );
    }

    if (method !== null) {
        filteredProjects = sort(filteredProjects, method);
    }

    if (options?.filterCancelledProjects) {
        filteredProjects = filteredProjects.filter(
            (project) => project.status !== DatabaseProjectStatus.CANCELED
        );
    }

    return filteredProjects;
};
