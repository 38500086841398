import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    & > h1 {
        margin: 0;
        margin-top: 10rem;
        margin-left: 10rem;
    }

    & > p {
        color: $color-tertiary;
        margin-top: 1.5rem;
        margin-left: 10rem;
        margin-bottom: 3.5rem;
    }

    & > .continue {
        margin-left: 10rem;
        width: 20rem;
        display: flex;
        height: 2.5rem;
    }
`;

export const CenteredContainer = styled.div`
    display: flex;
    width: 50%;
    margin: auto;
    margin-top: 150px;
    flex-direction: column;
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
`;

export const Content = styled.div`
    margin-top: 20px;
    text-align: justify;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #f6f8f8;
    max-width: 600px;
`;

export const Button = styled.button`
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    width: 141px;
    height: 40px;
    border-radius: 2px;
`;
