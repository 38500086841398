/*
 * uom.ts provides GraphQL queries used to fetch records from the
 * `unit_of_measure` table.
 */
import { frogmint, newQueries } from '../frog';

export enum UomType {
    D1 = 'D1',
    D2 = 'D2',
    D3 = 'D3',
    ITEM = 'ITEM',
}

export type UomRecord = {
    id: number;
    definition: string;
    description: string;
    name: string;
    uomType: UomType;
};

export interface UomRecords {
    unitOfMeasures?: {
        nodes: UomRecord[];
    };
}

export type UomCondition = {
    id?: number;
    name?: string;
};

export const uomFragment = frogmint`
    ${'UnitOfMeasure_unitOfMeasure'} {
        id
        definition
        description
        name
        uomType
    }
`;

export const UomsQuery = uomFragment.gql`
    {
        unitOfMeasures {
            nodes {
                ${uomFragment}
            }
        }
    }
`;

export const [executeUomsQuery, useUomsQuery] = newQueries<UomCondition, UomRecords>(UomsQuery);
