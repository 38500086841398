import { Rules } from './rules';
import { Serialize } from '../serialization';
import { ExpressionElement } from '../utils/types';
import { isOperator, isParenthesis } from '../utils/typeGuards';

export interface Tokenizer {
    number: TokenizerFunc;
    operator: TokenizerFunc;
    parenthesis: TokenizerFunc;
}

export const Tokenizer: Tokenizer = {
    number(expression) {
        const cap = Rules.number.exec(expression);
        if (cap === null) {
            return null;
        }
        const raw = cap[0];
        const value = parseFloat(raw);
        if (isNaN(value)) {
            return null;
        }
        const element = Serialize.Element.ExpressionNumber(value);
        return { element, raw };
    },

    operator(expression) {
        const cap = Rules.operator.exec(expression);
        if (cap === null) {
            return null;
        }
        const raw = cap[0];
        if (!isOperator(raw)) {
            return null;
        }
        const element = Serialize.Element.ExpressionOperator(raw);
        return { element, raw };
    },

    parenthesis(expression) {
        const cap = Rules.parenthesis.exec(expression);
        if (cap === null) {
            return null;
        }
        const raw = cap[0];
        if (!isParenthesis(raw)) {
            return null;
        }
        const element = Serialize.Element.ExpressionParenthesis(raw);
        return { element, raw };
    },
};

export interface TokenizerResponse {
    element: ExpressionElement;
    raw: string;
}

type TokenizerFunc = (expression: string) => TokenizerResponse | null;
