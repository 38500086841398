import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReactComponent as FullLogoV2Icon } from '@/assets/images/full-logo-v2.svg';
import { ResetPassword } from '@/containers/Login/ResetPassword';
import { SignInForm } from '@/containers/Login/SignInForm';

export const Login: FC = () => {
    const { path } = useRouteMatch();

    return (
        <Stack
            sx={{
                background: (theme) => theme.palette.hues.neutral[48],
                height: '100%',
                minHeight: '100vh',
            }}
        >
            <AppBar
                sx={{
                    background: 'transparent',
                }}
            >
                <Toolbar>
                    <FullLogoV2Icon />
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <Switch>
                    <Route path={`${path}/reset-password`}>
                        <ResetPassword />
                    </Route>
                    <Route path={path}>
                        <SignInForm />
                    </Route>
                </Switch>
            </Box>
        </Stack>
    );
};
