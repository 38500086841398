import Stack, { StackProps } from '@mui/material/Stack';
import React, { FC } from 'react';
import { PatternPicker } from './PatternPicker';
import { ColorPicker } from '../LinearPanel/ColorPicker';

const AreaPanel: FC<StackProps> = () => {
    return (
        <Stack direction="row" spacing="8px">
            <PatternPicker />
            <ColorPicker />
        </Stack>
    );
};

export default AreaPanel;
