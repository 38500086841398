import React, { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import { DivProps } from '@/common/types';
import { Bugsnag } from '@/components/app/Bugsnag';
import { Action, makeAction, State } from '@/components/mission-control/UserManager/state';
import {
    UserModalActions,
    UserModalActionButton,
} from '@/components/mission-control/UserManager/UserModal/styled';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { IUserFragment, IUserRole } from '@/graphql';

const COPY = {
    impersonate: 'IMPERSONATE',
    pendingImpersonation: 'IMPERSONATING...',
};

export interface UserActionsProps extends DivProps {
    state: State;
    user: IUserFragment;
}

export const UserActions: FC<UserActionsProps> = ({ state: { commit }, user, ...rest }) => {
    const { addNotification } = useNotifications();
    const [pendingImpersonation, setPendingImpersonation] = useState(false);

    const {
        data: {
            user: { authID: myAuthID, roles: myRoles },
        },
    } = useUser();

    const handleImpersonateUser = useCallback<MouseEventHandler<HTMLButtonElement>>(
        async (e) => {
            if (e.defaultPrevented) {
                return;
            }
            e.preventDefault();
            setPendingImpersonation(true);
            const res = await commit(makeAction(Action.Impersonate, { userAuthID: user.authID }));
            if (res.error !== null) {
                const errorString = `failed to impersonate user: ${res.error}`;
                Bugsnag?.notify(errorString);
                addNotification({ title: 'Error', content: errorString }, 'error');
                setPendingImpersonation(false);
            }
        },
        [user.authID]
    );

    const canImpersonate = useMemo((): boolean => {
        const isAdminIfIAm = user.roles.includes(IUserRole.Admin)
            ? myRoles.includes(IUserRole.Admin)
            : true;
        const isNotMe = user.authID !== myAuthID;
        if (myRoles.includes(IUserRole.Superadmin)) {
            return isNotMe;
        } else if (myRoles.includes(IUserRole.Impersonator)) {
            return isAdminIfIAm && isNotMe;
        }
        return false;
    }, [myRoles, user.authID, user.roles]);

    return (
        <UserModalActions {...rest}>
            {canImpersonate && (
                <UserModalActionButton
                    disabled={pendingImpersonation}
                    onClick={handleImpersonateUser}
                >
                    {pendingImpersonation ? COPY.pendingImpersonation : COPY.impersonate}
                </UserModalActionButton>
            )}
        </UserModalActions>
    );
};
