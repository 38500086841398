import { DatabaseProjectStatus } from '@/common/types';
import { Features } from '@/contexts/Features';
import {
    IEstimatorStatus,
    IStripeStatus,
    ITeamAccountType,
    ITeamFragment,
    IUserFragment,
    IUserRole,
} from '@/graphql';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';
import { EventTypeName } from '@/queries/eventTypes';
import negate from 'lodash/negate';
import {
    hasFeb2022EssentialSubscription,
    hasSelfStarterSubscription,
} from '@/components/pricing/PricingPage/utils';
import { plans } from '@/components/pricing/PricingPage/plans';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { ProjectStatus } from '@/common/types';

export const isAnyTrial = (team?: ITeamFragment | null) => {
    if (team?.subscription) {
        return team.subscription.status === IStripeStatus.Trialing;
    } else if (team?.onboarding) {
        return team.onboarding.accountType === ITeamAccountType.Trial;
    } else {
        return false;
    }
};

export const isExpiredTrial = (team?: ITeamFragment | null) => {
    return isAnyTrial(team) && team?.onboarding?.daysLeftInTrial === 0;
};

/**
 * Check if a user is a "nonsubscriber": not in a Subscribed state.
 *
 * @param {ITeamFragment} team
 * @returns {boolean} - if a user is not in subscriber state.
 */
export const isNonsubscriber = (team?: ITeamFragment | null) => {
    if (team?.onboarding) {
        // We want to count cancelled as a trial state
        return team.onboarding.accountType !== ITeamAccountType.Subscriber;
    } else {
        return false;
    }
};

export const isTeamOnboardingCancelled = (team?: ITeamFragment | null) => {
    return team?.onboarding?.accountType === ITeamAccountType.Cancelled;
};

export const isAdministratorUser = (user?: IUserFragment) => {
    return user?.roles?.includes(IUserRole.Admin) || user?.roles?.includes(IUserRole.Superadmin);
};

export const isAdminUser = (user?: IUserFragment) => {
    return user?.roles.includes(IUserRole.Admin) === true;
};

export const isSuperadminUser = (user?: IUserFragment) => {
    return user?.roles.includes(IUserRole.Superadmin) === true;
};

export const isBuilderUser = (user?: IUserFragment) => {
    return user?.roles.includes(IUserRole.Builder) === true;
};

export const isEstimatorUser = (user?: IUserFragment) => {
    return user?.roles.includes(IUserRole.Estimator) === true;
};

export const isImpersonatorUser = (user?: IUserFragment) => {
    return user?.roles.includes(IUserRole.Impersonator) === true;
};

export const isPureAdminUser = (user?: IUserFragment) => {
    return user?.roles.length === 1 && user.roles[0] === IUserRole.Admin;
};

export const isPureBuilderUser = (user?: IUserFragment) => {
    return user?.roles.length === 1 && user.roles[0] === IUserRole.Builder;
};

export const isPureEstimatorUser = (user?: IUserFragment) => {
    return user?.roles?.length === 1 && user.roles[0] === IUserRole.Estimator;
};

export const hasAvailability = (user?: IUserFragment) => {
    return (user?.schedule?.availability?.length ?? []) > 0;
};

// See: https://1build.slack.com/archives/C030T2L63FY/p1648587069468639
export const isDefaultRolesUser = (user: IUserFragment) => {
    return isBuilderUser(user) && isEstimatorUser(user);
};

export const isProjectApproved = (project?: ProjectRecord) => {
    return project?.status === DatabaseProjectStatus.APPROVED;
};

export const isProjectCancelled = (project?: ProjectWithEventsRecord | ProjectRecord) => {
    return project?.status === DatabaseProjectStatus.CANCELED;
};

export const isProjectComplete = (project?: ProjectRecord) => {
    return project?.status === DatabaseProjectStatus.COMPLETE;
};

export const isProjectEstimating = (project?: ProjectRecord) => {
    return project?.status === DatabaseProjectStatus.ESTIMATING;
};

export const isProjectSetupComplete = (project?: ProjectRecord) => {
    return project?.isSetupCompleted === true;
};

export const isProjectPendingEstimator = (project?: ProjectRecord) => {
    return project?.status === DatabaseProjectStatus.PENDING_ESTIMATOR;
};

export const isProjectPriceFinal = (project?: ProjectRecord) => {
    return project ? Boolean(project.isPriceFinal) : false;
};

export const isSaasProject = (project?: ProjectRecord) => {
    return project ? Boolean(project.isSaas) : false;
};

export const hasTeamProjectInfo = (project?: ProjectRecord) => {
    return project ? Boolean(project.projectInfoId) : false;
};

export const isProjectUsersMember = (project?: ProjectRecord, user?: IUserFragment) => {
    return (
        project?.projectUsers?.nodes.some(
            (projectUser) => String(projectUser.user.id) === user?.id
        ) === true
    );
};

export const getNewMessageProjectEvents = (project: ProjectWithEventsRecord) => {
    return project.events.nodes.filter(
        (node) =>
            node.eventTypeName === EventTypeName.PostMessage ||
            node.eventTypeName === EventTypeName.EditMessage
    );
};

export const getProjectsWithNewMessages = (projects: ProjectWithEventsRecord[]) => {
    return projects.filter((project) => {
        const orderedMessages = getNewMessageProjectEvents(project).sort(
            (item1, item2) => new Date(item1.created).getTime() - new Date(item2.created).getTime()
        );

        if (orderedMessages.length === 0) {
            return false;
        }

        return orderedMessages[0]?.owner?.team?.id !== project.team?.id;
    });
};

export const getProjectById = (projects: ProjectRecord[], id: number) => {
    return projects.find((project) => project.id === id);
};

export const getProjectsByStatus = (
    status: DatabaseProjectStatus | ProjectStatus,
    projects: ProjectWithEventsRecord[]
) => {
    return projects.filter((project) => {
        return project.status === status;
    });
};

export const getProjectsByStatuses = (
    statuses: DatabaseProjectStatus[],
    projects: ProjectWithEventsRecord[]
) => {
    return statuses.reduce<ProjectWithEventsRecord[]>((result, status) => {
        return result.concat(getProjectsByStatus(status, projects));
    }, []);
};

export const getActiveProjects = (projects?: ProjectWithEventsRecord[]) => {
    return projects?.filter(negate(isProjectCancelled)) || [];
};

export const isEstimatorPaused = (user?: IUserFragment) => {
    return user?.status === IEstimatorStatus.Paused;
};

export const hasPaymentMethod = (team?: ITeamFragment | null) => {
    return Boolean(team?.hasPaymentMethod);
};

export const hasPaidSubscription = (user?: IUserFragment) => {
    return Boolean(user?.team?.subscription) && !isAnyTrial(user?.team);
};

export const hasPPUSubscription = (user?: IUserFragment) => {
    return hasPaidSubscription(user) && (user?.team?.subscription?.amount ?? 0) === 0;
};

export const hasBeenWelcomed = (user?: IUserFragment) => {
    // The request that updates team welcomed state does not work on local
    const item = localStorage.getItem(`teamWelcomed--${String(user?.team?.id)}`);

    return user?.team?.onboarding?.welcomed === true || item === 'true';
};

// https://coda.io/d/_d-21lT3iH7u/Frontend-Navigation-Links_su5QB#_luKwB
export const hasProjectFeature = ({
    project,
    user,
}: {
    project: ProjectRecord;
    user: IUserFragment;
}) => {
    if (isSaasProject(project)) {
        return false;
    } else {
        if (isAdministratorUser(user) || isBuilderUser(user)) {
            return true;
        }

        if (isEstimatorUser(user)) {
            return !isProjectCancelled(project);
        }

        return false;
    }
};

// https://coda.io/d/_d-21lT3iH7u/Frontend-Navigation-Links_su5QB#_lu986
export const hasEstimateFeature = ({
    features,
    project,
    user,
}: {
    features: Features;
    project: ProjectRecord;
    user: IUserFragment;
}) => {
    if (isSaasProject(project)) {
        if (isAdministratorUser(user)) {
            return true;
        }

        return isBuilderUser(user) || isEstimatorUser(user);
    } else {
        if (isAdministratorUser(user)) {
            return true;
        }

        if (isBuilderUser(user)) {
            return (
                features.builderEstimateView(project?.created) &&
                !hasPPUSubscription(user) &&
                isProjectComplete(project)
            );
        } else if (isEstimatorUser(user)) {
            return !isProjectCancelled(project);
        }

        return false;
    }
};

// https://coda.io/d/_d-21lT3iH7u/Frontend-Navigation-Links_su5QB#_lu3sO
export const hasTakeoffFeature = ({
    features,
    project,
    user,
}: {
    features: Features;
    project: ProjectRecord;
    user: IUserFragment;
}) => {
    if (isSaasProject(project)) {
        if (isAdministratorUser(user)) {
            return true;
        }

        if (isBuilderUser(user) && isEstimatorUser(user)) {
            if (
                hasSelfStarterSubscription(plans, user.team) ||
                hasFeb2022EssentialSubscription(plans, user.team)
            ) {
                return false;
            }

            if (isAnyTrial(user.team) || hasPaidSubscription(user)) {
                return true;
            }
        }

        return false;
    } else {
        if (isAdministratorUser(user)) {
            return true;
        }

        if (isBuilderUser(user)) {
            return (
                features.builderEstimateView(project?.created) &&
                !hasPPUSubscription(user) &&
                isProjectComplete(project)
            );
        } else if (isEstimatorUser(user)) {
            return !isProjectCancelled(project);
        }

        return false;
    }
};

export const isWaitingForForClientApproval = (
    flags: LDFlagSet,
    project: ProjectRecord,
    user: IUserFragment
): boolean => {
    return (
        !isProjectPriceFinal(project) &&
        flags.builderAcceptance &&
        isProjectEstimating(project) &&
        isEstimatorUser(user) &&
        !isBuilderUser(user) &&
        !isAdministratorUser(user)
    );
};

export const isMissingPaymentMethod = ({
    user,
    flags,
}: {
    user: IUserFragment;
    flags: LDFlagSet;
}): boolean => {
    return (
        flags.collectCreditCardFlow &&
        !isAdministratorUser(user) &&
        isDefaultRolesUser(user) &&
        isAnyTrial(user.team) &&
        !hasPaidSubscription(user) &&
        !hasPaymentMethod(user.team)
    );
};

/**
 * Checks if a user is either a subscriber or a trial user that is:
 * a) has more than 0 days left in trial
 * b) not in a cancelled state
 *
 * @param {IUserFragment} user - current user
 * @returns {boolean} if current user can create a new project.
 */
export const canCreateNewProject = (user: IUserFragment) =>
    !(
        isNonsubscriber(user.team) &&
        (isExpiredTrial(user.team) || isTeamOnboardingCancelled(user.team))
    ) && isBuilderUser(user);
