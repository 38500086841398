import { Cell, CellType } from 'leyden';
import { useCoordinates, useLeydenStatic } from 'leyden-react';
import { useEffect, useState } from 'react';

import { EstimateEditor } from '../editor/EstimateEditor';

export const useCellIsInHoveredRow = (cell: Cell<CellType>): boolean => {
    const coords = useCoordinates(cell);
    const editor = useLeydenStatic();

    const [isInHoveredRow, setIsInHoveredRow] = useState(() => {
        if (coords === null) {
            return false;
        }
        return EstimateEditor.isRowHovered(editor, coords.y);
    });

    useEffect(() => {
        if (coords === null) {
            setIsInHoveredRow(false);
            return;
        }
        const unsubscribe = EstimateEditor.subscribeToHoveredRow(editor, (hov) => {
            setIsInHoveredRow(hov === coords.y);
        });
        return (): void => {
            unsubscribe();
        };
    }, [coords?.y]);

    return isInHoveredRow;
};
