import Stack from '@mui/material/Stack';
import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { LoadingPage } from '../LoadingPage';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ProjectDetails } from '@/components/projects/ProjectDetails';
import {
    makeProjectDetailsContext,
    ProjectDetailsContextType,
} from '@/components/projects/ProjectDetails/context';
import { ProjectToolbar } from '@/components/projects/ProjectDetails/ProjectToolbars/Details';
import { useUser } from '@/contexts/User';

export const Project: FC = () => {
    const {
        data: {
            user: { team },
        },
    } = useUser();
    const {
        fetching: projectsFetching,
        project,
        projectLocation,
        projectNotFound,
    } = useProjectFromParams();

    const [context, setContext] = useState<ProjectDetailsContextType | null>(null);

    // Only initialize the project details context once since it has internal
    // subscription management.
    useEffect(() => {
        if (context === null && project !== undefined && projectLocation !== undefined) {
            setContext(
                makeProjectDetailsContext(project, projectLocation, team?.subscription ?? null)
            );
        }
    }, [project, projectLocation, team]);

    if (projectNotFound) return <Redirect to="/projects" />;
    if (!context || projectsFetching || projectLocation === undefined) return <LoadingPage />;

    return (
        <context.Provider>
            <Stack
                sx={{
                    flex: 1,
                    height: 'calc(100% - 64px)',
                }}
            >
                <ProjectToolbar useProjectDetails={context.useConsumer} />
                <ProjectDetails useProjectDetails={context.useConsumer} />
            </Stack>
        </context.Provider>
    );
};
