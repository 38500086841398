/*
 * trades.ts provides GraphQL queries used to fetch records from the
 * `trade` table.
 */
import { frogmint, newQueries } from '../frog';

export interface TradeRecord {
    id: string;
    name: string;
    nickname: string;
    csiDivision: string;
    orderWeight: number;
    isSelectableAtProjectCreation: boolean;
}

export interface TradeRecords {
    trades: {
        nodes: TradeRecord[];
    };
}

export interface TradeCondition {
    id?: number;
    orderWeight?: number;
}

export const tradeFragment = frogmint`
    ${'Trade_trade'} {
        id
        name
        nickname
        csiDivision
        orderWeight
        isSelectableAtProjectCreation
    }
`;

export const TradesQuery = tradeFragment.gql`
    query Trades($condition: TradeCondition){
        trades(
            condition: $condition
            orderBy: ORDER_WEIGHT_ASC
        ) {
            nodes {
                ${tradeFragment}
            }
        }
    }
`;

export const [executeTradesQuery, useTradesQuery] = newQueries<TradeCondition, TradeRecords>(
    TradesQuery
);
