import { MeasuredQuantityRecord, measuredQuantityFragment } from './measuredQuantities';
import { frogmint } from '../frog';

export enum VariableInputType {
    Direct = 'DIRECT',
    Prompt = 'PROMPT',
    Takeoff = 'TAKEOFF',
}

export interface FormulaVariableRecord {
    id: number;
    uuid: string;
    name: string;
    variableInputType: VariableInputType;
    formulaId: number;
    measuredQuantityId: number;
    measuredQuantity: MeasuredQuantityRecord;
}

export const formulaVariableFragment = frogmint`
    ${'FormulaVariable_formulaVariable'} {
        id
        uuid
        name
        variableInputType
        formulaId
        measuredQuantityId
        measuredQuantity {
            ${measuredQuantityFragment}
        }
    }
`;
