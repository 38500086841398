/**
 * This component is a dispatcher of different geometries that can be
 * drawn on a sheet. The GeometryType is used to establish which component
 * should be used to render the geometry.
 **/

import React, { FC, useEffect, useState } from 'react';

import { EditablePolygon } from './overlay/polygon/EditablePolygon';
import { EditablePolyline } from './overlay/polyline/EditablePolyline';
import {
    booleanGeometryObservable,
    executeBooleanOperationObservable,
    subscribeToBooleanGeometry,
    subscribeToBooleanTool,
} from '../observables/helpers';
import { BooleanToolType, Geometry, GeometryType } from '../../../common/types';
import { TakeoffComponentProps } from '../context';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

export const BooleanGeometry: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const [booleanGeometry, setBooleanGeometryInternal] =
        useState<Geometry<GeometryType.AREA | GeometryType.LINEAR>>();

    useEffect(() => {
        const subscription = subscribeToBooleanGeometry(setBooleanGeometryInternal);
        return (): void => subscription.unsubscribe();
    }, []);

    const [booleanTool, setBooleanTool] = useState<BooleanToolType>();

    useEffect(() => {
        const subscription = subscribeToBooleanTool(setBooleanTool);
        return (): void => subscription.unsubscribe();
    }, []);

    const setBooleanGeometry = (
        newBooleanGeometry: Geometry<GeometryType.AREA | GeometryType.LINEAR>
    ): void => booleanGeometryObservable.next(newBooleanGeometry);

    const updateBooleanGeometry = (
        geometry: Geometry<GeometryType.AREA | GeometryType.LINEAR>
    ): void => {
        if (!booleanGeometry) return;
        setBooleanGeometry(geometry);
    };

    const onFinish = (): void => {
        executeBooleanOperationObservable.next();
    };

    const props = {
        color: 'red',
        selected: false,
        editable: false,
        commit: updateBooleanGeometry,
        onFinish: onFinish,
        id: -200,
        activeVertices: [],
        useTakeoff,
    };

    const renderTool = newGeometryHandler<JSX.Element>({
        [GeometryType.AREA]: (area) => <EditablePolygon {...props} geometry={area} />,
        [GeometryType.COUNT]: () => <></>,
        [GeometryType.LINEAR]: (line) => <EditablePolyline {...props} geometry={line} />,
    });

    return <>{booleanGeometry && booleanTool && renderTool(booleanGeometry)}</>;
};
