import { useEffect } from 'react';

export const useHandleKeyPress = (
    keyDownHandler: (e: KeyboardEvent) => void,
    keyUpHandler?: (e: KeyboardEvent) => void
): void => {
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        if (keyUpHandler) {
            document.addEventListener('keyup', keyUpHandler);
        }
        return function cleanup(): void {
            document.removeEventListener('keydown', keyDownHandler);
            if (keyUpHandler) {
                document.removeEventListener('keyup', keyUpHandler);
            }
        };
    }, [keyDownHandler, keyUpHandler]);
};
