import { styled as styledByMui } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export const Wrapper = styledByMui('div')({
    background: '#FFFFFF',
    height: 'fit-content',
    minHeight: '100%',
});

export const Header = styledByMui(Container)({
    padding: '30px 0',
});

export const InfoContainer = styledByMui(Container)({
    padding: '60px 0',
    textAlign: 'center',
});

export const Title = styledByMui(Typography)({
    color: '#333333',
    marginBottom: '10px',
});

export const Info = styledByMui(Typography)({
    color: '#333333',
    marginBottom: '60px',
});
