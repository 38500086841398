/*
 * RadioRow is a component capable of displaying a single row containing a
 * neatly styled radio button and any component passed as children. Multiple
 * RadioRows can be used whenever there's need to allow for selecting just one
 * option out of a list.
 */

import React, { FC } from 'react';
import clsx from 'clsx';

import { CheckboxProps } from './Checkbox';
import { onClickAndEnterKey } from '@/common/utils/eventHandlers';

interface RadioRowProps extends CheckboxProps {
    tabIndex?: number;
}

export const RadioRow: FC<RadioRowProps> = ({
    isChecked,
    onChange,
    children,
    tabIndex,
    className,
}) => {
    const handleSelect = (): void => {
        onChange(!isChecked);
    };

    return (
        <div
            tabIndex={tabIndex}
            {...onClickAndEnterKey(handleSelect)}
            className={clsx('radio-row-wrapper', className)}
        >
            <div
                className={clsx('radio-wrapper', isChecked && 'radio-wrapper--checked')}
                defaultChecked={isChecked}
            >
                <div
                    className={clsx('radio', isChecked && 'radio--checked')}
                    defaultChecked={isChecked}
                />
            </div>
            <label>{children}</label>
        </div>
    );
};
