import styled from 'styled-components';

import { collapsibleCss, projectCollapsibleCss } from '../../utils/styles';
import { Collapsible } from '@/components/ui/containers/Collapsible';

const bodyGridTemplate = `
" .   . " 63px
" .   . " 63px
/ 1fr 1fr
`;

export const ProjectInfoTitle = styled.h1`
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    color: #fff;
`;

export const ProjectInfoBody = styled.div`
    padding-top: 32px;

    display: grid;
    grid-template: ${bodyGridTemplate};
    column-gap: 16px;
    row-gap: 20px;
`;

export const ProjectInfoContainer = styled(Collapsible)`
    ${collapsibleCss}
    ${projectCollapsibleCss}

    @media print {
        display: none;
    }
`;
