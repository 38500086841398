import { COPY, InputProps } from '../constants';
import { Field, FieldDictionary, FormProps } from '../types';
import { changeDictionaryField, handleRequest, isFormValid } from '../utils';
import { useNotifications } from '@/contexts/Notifications';
import { IExtendTrialInput, useExtendTrialMutation } from '@/graphql';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { FC } from 'react';

export interface ResetTrialFieldsDictionary extends FieldDictionary {
    email: Field & {
        value: string;
    };
}

const Fields: ResetTrialFieldsDictionary = {
    email: {
        value: '',
        touched: false,
    },
};

export const ResetTrial: FC<FormProps> = ({ onSubmit }) => {
    const [currentFields, setCurrentFields] = useState<ResetTrialFieldsDictionary>({ ...Fields });
    const [open, setOpen] = useState(false);

    const { addNotification } = useNotifications();

    const [extendTrialMutation] = useExtendTrialMutation();

    const handleSubmit = async () => {
        setOpen(true);

        await handleRequest(
            extendTrialMutation({
                variables: { input: mapDictToIExtendTrialInput(currentFields) },
            }),
            addNotification
        );

        // return inputs to initial state
        setCurrentFields(Fields);
        setOpen(false);
        onSubmit();
    };

    // reformat field dictionary to object suitable for backend
    const mapDictToIExtendTrialInput = (dict: ResetTrialFieldsDictionary): IExtendTrialInput => {
        return {
            email: dict['email'].value,
        };
    };

    return (
        <>
            <Backdrop open={open}>
                <CircularProgress color="primary" />
            </Backdrop>
            <DialogContent>
                <TextField
                    {...InputProps}
                    required
                    value={currentFields['email'].value}
                    label={COPY.emailAddress}
                    error={currentFields['email'].touched && currentFields['email'].value === ''}
                    helperText={
                        currentFields['email'].touched &&
                        currentFields['email'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'email',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'email',
                            'touched',
                            true
                        );
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!isFormValid(currentFields)}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {COPY.submit}
                </Button>
            </DialogActions>
        </>
    );
};
