import { ProjectDetailsComponentProps } from '../../context';
import { UseActionsPayload } from './useActions';
import { ReactComponent as ExportIcon } from '@/assets/icons/16/export.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/32/checkmark.svg';
import { ReactComponent as PublishIcon } from '@/assets/icons/cloud-upload.svg';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { DatabaseProjectStatus } from '@/common/types';
import { PrimaryButtonProps } from '@/components/ui/buttons/PrimaryButton';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import { ButtonTypeMap } from '@mui/material/Button';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    accept: 'Accept',
    addFeedback: 'Add feedback',
    assignEstimator: 'Assign an estimator',
    attachEstimate: 'Attach estimate',
    cancelProject: 'Cancel Project',
    decline: 'Decline',
    feedbackSent: 'Feedback sent',
    downloadEstimate: 'Download estimate',
    viewEstimate: 'View estimate',
    downloadEstimateOnPC: 'Download estimate (PC only)',
    markAsComplete: 'Mark as complete',
    pendingApproval: 'Pending approval',
    pendingEstimation: 'Pending estimation',
    projectComplete: 'Complete',
    publish: 'Publish',
    publishProject: 'Publish project',
    requestRevision: 'Request revision',
    requestRevisionAgain: 'Request another revision',
    setupButton: 'Start estimation',
    submitProjectForReview: 'Submit for Review',
    takeoffButton: 'Takeoff',
    export: 'Export',
    shareEstimate: 'Share',
};

type DynamicButton = Omit<PrimaryButtonProps, 'color'> & {
    label: string | JSX.Element;
    action?: () => void;
    color?: ButtonTypeMap['props']['color'];
};

// Pass context in
type UseActionButtonsArguments = UseActionsPayload & ProjectDetailsComponentProps;

export const useActionButtons = ({
    useProjectDetails,

    addFeedback,
    assignEstimator,
    downloadEstimate,
    navigateToTakeoff,
    navigateToEstimate,
    publish,
    requestRevision,
    submit,
    toggleModal,
    toggleAcceptanceModal,
    toggleAcceptQuoteModal,
    toggleDeclineModal,
    toggleExportFilesModal,
    togglePublishModal,
    toggleShareEstimateModal,
    unpublish,
}: UseActionButtonsArguments): DynamicButton[] => {
    //////////////
    // Contexts //
    //////////////
    const { assignedTo, estimates, estimateLinks, isDownloading, project } = useProjectDetails();
    const {
        features: { builderEstimateView },
    } = useFeatures();
    const { builderAcceptance } = useFlags();
    const {
        data: {
            user: { id: userId },
        },
        validations: { isUserRoles },
    } = useUser();
    const screenSize = useScreenSize();
    const isMobile = screenSize === ScreenSize.Mobile;
    const isOnlyEstimator =
        isUserRoles(IUserRole.Estimator) &&
        !isUserRoles(IUserRole.Admin) &&
        !isUserRoles(IUserRole.Builder);

    //////////////////////
    // Helper functions //
    //////////////////////
    const projectHasEstimate = estimates.length > 0 || estimateLinks.length > 0;
    const projectIs = (match: DatabaseProjectStatus): boolean => match === project.status;
    const projectIsSelfServiced = project.projectUsers?.nodes.find(
        (pu) => userId === pu.user.id.toString()
    );
    const isEstimatingOrEstimated =
        projectIs(DatabaseProjectStatus.ESTIMATING) || projectIs(DatabaseProjectStatus.ESTIMATED);

    ////////////////////////
    // Button accumulator //
    ////////////////////////
    const getDefaultButtons = (): DynamicButton[] => {
        /* Estimator-Admin - Request revision */
        if (
            isUserRoles([IUserRole.Estimator, IUserRole.Admin]) &&
            ((projectIs(DatabaseProjectStatus.PENDING_ESTIMATOR) &&
                assignedTo?.id.toString() !== userId) ||
                projectIs(DatabaseProjectStatus.NEW) ||
                projectIs(DatabaseProjectStatus.REVISION_REQUESTED) ||
                projectIs(DatabaseProjectStatus.REVISION_SUBMITTED))
        ) {
            const buttons: DynamicButton[] = [];
            if (projectIs(DatabaseProjectStatus.REVISION_REQUESTED)) {
                buttons.push({
                    label: COPY.requestRevisionAgain,
                    action: requestRevision,
                    className: 'request-revision-button',
                });
            } else {
                buttons.push({
                    label: COPY.requestRevision,
                    action: requestRevision,
                    className: 'request-revision-button',
                });
            }

            buttons.push({
                label: COPY.assignEstimator,
                action: assignEstimator,
            });

            if (assignedTo) {
                buttons.unshift({
                    label: COPY.pendingApproval,
                    variant: 'outlined',
                    Icon: CheckmarkIcon,
                });
            }

            return buttons;
        }

        /* 1build admin - Project cancellation */
        if (
            isUserRoles(IUserRole.Builder) &&
            projectIs(DatabaseProjectStatus.NEW) &&
            !builderAcceptance
        ) {
            const buttons: DynamicButton[] = [
                {
                    label: COPY.cancelProject,
                    variant: 'outlined',
                    action: toggleModal,
                },
            ];

            return buttons;
        }

        /* Saas - Project completed */
        if (
            isUserRoles([IUserRole.Estimator, IUserRole.Builder]) &&
            projectIs(DatabaseProjectStatus.COMPLETE) &&
            project.isSaas
        ) {
            return [
                {
                    label: COPY.projectComplete,
                    Icon: CheckmarkIcon,
                    color: 'success',
                    variant: 'outlined',
                    action: unpublish,
                },
            ];
        }

        /* 1build admin - Project complete */
        if (
            (isUserRoles(IUserRole.Admin) ||
                isUserRoles(IUserRole.Estimator) ||
                isUserRoles(IUserRole.Builder)) &&
            projectIs(DatabaseProjectStatus.COMPLETE)
        ) {
            const buttons: DynamicButton[] = [];
            if (!builderEstimateView(project?.created)) {
                if (!isMobile) {
                    buttons.push({
                        action: downloadEstimate,
                        label: isDownloading['estimates'] ? <DotsLoader /> : COPY.downloadEstimate,
                        disabled: estimates.length === 0,
                    });
                } else {
                    buttons.push({
                        label: COPY.downloadEstimateOnPC,
                    });
                }
            }

            if (
                isUserRoles(IUserRole.Builder) &&
                !project.isSaas &&
                builderEstimateView(project?.created)
            ) {
                if (isMobile) {
                    buttons.push({
                        action: navigateToEstimate,
                        label: COPY.viewEstimate,
                    });
                }

                buttons.push({
                    action: toggleShareEstimateModal,
                    label: COPY.shareEstimate,
                    Icon: ExportIcon,
                    disabled: false,
                    variant: 'outlined',
                    color: 'secondary',
                });
            }
            return buttons;
        }

        /* 1build admin - Project sized */
        if (isUserRoles(IUserRole.Admin) && projectIs(DatabaseProjectStatus.SIZED)) {
            return [
                {
                    label: COPY.pendingApproval,
                    variant: 'outlined',
                    Icon: CheckmarkIcon,
                },
            ];
        }

        /* Estimator - Project is pending approval */
        if (
            isUserRoles(IUserRole.Estimator) &&
            projectIs(DatabaseProjectStatus.PENDING_ESTIMATOR) &&
            assignedTo?.id.toString() === userId
        ) {
            return [
                {
                    label: COPY.decline,
                    action: toggleDeclineModal,
                    color: 'error',
                    variant: 'outlined',
                },
                {
                    label: COPY.accept,
                    action: builderAcceptance ? toggleAcceptQuoteModal : toggleAcceptanceModal,
                },
            ];
        }

        /* Estimator - Estimating or Estimate has feedback */
        if (
            !builderEstimateView(project?.created) &&
            isOnlyEstimator &&
            (projectIs(DatabaseProjectStatus.ESTIMATING) ||
                projectIs(DatabaseProjectStatus.REVISION_SUBMITTED) ||
                projectIs(DatabaseProjectStatus.REVISION_REQUESTED) ||
                (projectIs(DatabaseProjectStatus.ESTIMATED) && project.feedback))
        ) {
            return [
                {
                    label: COPY.submitProjectForReview,
                    action: submit,
                    disabled: !projectHasEstimate,
                },
            ];
        }

        /* Estimator-Admin - Estimating */
        if (
            !builderEstimateView(project?.created) &&
            isUserRoles([IUserRole.Estimator, IUserRole.Admin]) &&
            projectIs(DatabaseProjectStatus.ESTIMATING) &&
            project.isPriceFinal
        ) {
            return [
                {
                    label: COPY.submitProjectForReview,
                    action: submit,
                    disabled: !projectHasEstimate,
                },
            ];
        }

        /* Estimator - Estimate submitted */
        if (
            isUserRoles(IUserRole.Estimator) &&
            !isUserRoles(IUserRole.Admin) &&
            projectIs(DatabaseProjectStatus.ESTIMATED) &&
            !project.feedback &&
            !builderEstimateView(project?.created)
        ) {
            return [
                {
                    label: COPY.pendingApproval,
                    variant: 'outlined',
                    Icon: CheckmarkIcon,
                },
            ];
        }

        /* Estimator-Admin - Estimate submitted */
        if (
            isUserRoles([IUserRole.Estimator, IUserRole.Admin]) &&
            projectIs(DatabaseProjectStatus.ESTIMATED) &&
            !builderEstimateView(project?.created)
        ) {
            let buttons: DynamicButton[] = [
                {
                    label: project.feedback ? COPY.feedbackSent : COPY.addFeedback,
                    action: addFeedback,
                    variant: 'outlined',
                    disabled: !!project.feedback,
                },
                {
                    label: COPY.publish,
                    action: publish,
                    color: 'success',
                },
            ];
            // If the Estimator-Admin is the assignee, they should not be able
            // to give themselves feedback.
            if (assignedTo?.id.toString() === userId) {
                buttons = [buttons[1]];
            }
            return buttons;
        }

        /* SaaS - estimation in progress */
        if (
            isUserRoles(IUserRole.Builder) &&
            isUserRoles(IUserRole.Estimator) &&
            project.isSaas &&
            isEstimatingOrEstimated
        ) {
            return [
                {
                    label: COPY.markAsComplete,
                    variant: 'outlined',
                    Icon: CheckmarkIcon,
                    action: publish,
                    disabled: false,
                },
            ];
        }

        /* Builder - Pending estimation */
        if (isUserRoles(IUserRole.Builder) && isEstimatingOrEstimated) {
            return [
                {
                    label: COPY.pendingEstimation,
                    variant: 'outlined',
                    Icon: CheckmarkIcon,
                    disabled: true,
                },
            ];
        }

        return [];
    };

    const getTakeoffButtons = (): DynamicButton[] => {
        if (builderEstimateView(project?.created)) {
            /* Add takeoff button */
            const shouldRenderSetupButton =
                !isMobile &&
                ((isUserRoles(IUserRole.Estimator) && !isUserRoles(IUserRole.Builder)) ||
                    (isUserRoles([IUserRole.Estimator, IUserRole.Builder]) &&
                        projectIsSelfServiced)) &&
                isEstimatingOrEstimated &&
                !project.isSetupCompleted &&
                project.isPriceFinal;

            return shouldRenderSetupButton
                ? [
                      {
                          label: COPY.setupButton,
                          action: navigateToTakeoff,
                          disabled: !project.isPriceFinal && builderAcceptance,
                      },
                  ]
                : [];
        } else {
            const shouldRenderTakeoff =
                !isMobile &&
                ((isUserRoles(IUserRole.Estimator) && !isUserRoles(IUserRole.Builder)) ||
                    (isUserRoles([IUserRole.Estimator, IUserRole.Builder]) &&
                        projectIsSelfServiced)) &&
                (projectIs(DatabaseProjectStatus.ESTIMATING) ||
                    projectIs(DatabaseProjectStatus.ESTIMATED) ||
                    projectIs(DatabaseProjectStatus.COMPLETE)) &&
                project.isPriceFinal;

            return shouldRenderTakeoff
                ? [
                      {
                          label: project.isSetupCompleted ? COPY.takeoffButton : COPY.setupButton,
                          action: navigateToTakeoff,
                          disabled: !project.isPriceFinal && builderAcceptance,
                      },
                  ]
                : [];
        }
    };

    const getEstimatorExportPublishButton = (): DynamicButton[] => {
        if (
            builderEstimateView(project?.created) &&
            (isOnlyEstimator || isUserRoles([IUserRole.Estimator, IUserRole.Admin])) &&
            project.isSetupCompleted &&
            project.isPriceFinal &&
            project.status !== DatabaseProjectStatus.PENDING_ESTIMATOR
        ) {
            const buttons: DynamicButton[] = [
                {
                    label: COPY.export,
                    action: toggleExportFilesModal,
                    disabled: !projectHasEstimate,
                    variant: 'outlined',
                    color: 'secondary',
                    Icon: ExportIcon,
                },
            ];

            if (!(project?.status === DatabaseProjectStatus.COMPLETE)) {
                buttons.unshift({
                    label: COPY.publishProject,
                    action: togglePublishModal,
                    Icon: PublishIcon,
                });
            }

            return buttons;
        } else {
            return [];
        }
    };

    return [...getDefaultButtons(), ...getTakeoffButtons(), ...getEstimatorExportPublishButton()];
};
