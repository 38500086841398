import React, { FC } from 'react';
import { BaseUserRecord } from '@/queries/baseUsers';
import { Container, Name, NameMissing } from './styled';
import { Avatar } from '@/components/ui/icons/Avatar';
import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';

type ProjectOwnerBadgeProps = {
    user?: BaseUserRecord;
    background?: boolean;
    v2?: boolean;
};
export const ProjectOwnerBadge: FC<ProjectOwnerBadgeProps> = ({ user, background = true, v2 }) => {
    if (user) {
        return (
            <Container isBackgroundLess={!background}>
                <Avatar v2={v2} user={mapBaseUserToIUserFragment(user)} diameter={'1.5rem'} />
                <Name>
                    {user.firstName} {user.lastName}
                </Name>
            </Container>
        );
    } else {
        return (
            <Container isBackgroundLess={!background}>
                <Avatar v2={v2} diameter={'1.5rem'} />
                <NameMissing>Unassigned</NameMissing>
            </Container>
        );
    }
};
