import React, { FC } from 'react';

import { Status, StatusVariant } from '@/components/ui/controls/Status';
import { IEstimatorStatus, Maybe } from '@/graphql';
import { colorTypographyDark } from '@/variables';

interface EstimatorProps {
    status?: Maybe<IEstimatorStatus>;
    variant?: StatusVariant;
}

const STATUSES = [
    {
        status: IEstimatorStatus.Available,
        color: '#76BC7D',
    },
    {
        status: IEstimatorStatus.Busy,
        color: '#F59637',
    },
    {
        status: IEstimatorStatus.Away,
        color: '#D7E1E5',
    },
    {
        status: IEstimatorStatus.PendingApproval,
        color: '#8D0DF8',
    },
    {
        status: IEstimatorStatus.Paused,
        color: colorTypographyDark,
    },
];

export const EstimatorStatus: FC<EstimatorProps> = ({ status, variant = StatusVariant.Blocky }) => {
    const estimatorStatus = STATUSES.find((s) => s.status === status);
    return (
        <Status color={estimatorStatus?.color} variant={variant}>
            {estimatorStatus?.status}
        </Status>
    );
};
