/**************************************************
 *                     ~FROG~                     *
 *                      FRagment Oriented Graphql *
 *                _---_      _---_                *
 *               _|0  |_----_|  0|_               *
 *              / \___/      \___/ \              *
 *             /__      ^  ^      __\             *
 *             |  \______________/  |             *
 *             \____            ____/             *
 *                  ------------                  *
 *                                                *
 * Author: Michael Darr <michael@1build.com>      *
 **************************************************/
import { Frogmint, GqlTag, isFrogmint } from './types';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export { newMutations, newQueries } from './requests';
export { makeUseSubscription } from './subscription';

const extractDependencies = (dependencies: Frogmint[]): Frogmint[] =>
    dependencies
        .map((dep) =>
            dep.dependencies.length > 0 ? [dep, ...extractDependencies(dep.dependencies)] : [dep]
        )
        .reduce((acc, val) => acc.concat(val), []);

const uniqueDependencies = (dependencies: Frogmint[]): Frogmint[] => {
    const nonUniqueDependencies = extractDependencies(dependencies);
    return [...new Set(nonUniqueDependencies)];
};

export const frogmint = (
    literals: TemplateStringsArray,
    fragmentName: string,
    ...dependencies: Frogmint[]
): Frogmint => {
    // Initialize fragment string with the fragment name. The fragments are
    // named with the following convention: `GqlTypeName_uniqueIdentifier`
    // `uniqueIdentifier` is usually just `GqlTypeName` with the first letter
    // lowercased. Using this convention, we extract the GraphQL type from the
    // fragment name and use it to construct a fragement definition.
    const fragmentType = fragmentName.split('_')[0];
    let fragmentString = `${literals[0]}fragment ${fragmentName} on ${fragmentType}`;

    // Replace the passed Frogmint dependencies with their fragment names.
    for (let i = 0; i < dependencies.length; i++) {
        fragmentString += literals[i + 1];
        fragmentString += `...${dependencies[i].fragmentName}`;
    }

    // Append the final literal.
    fragmentString += literals[literals.length - 1];
    const fragment = gql`
        ${fragmentString}
    `;

    // Recursively derive a list of all unique dependencies.
    const uniqueDeps = uniqueDependencies(dependencies);

    // Wrap `gql`: append fragment deps, transform `table` to `...fragmentName`
    const gqlWrapped: GqlTag = (literals, ...placeholders) => {
        // Replace frogmints with their fragmentNames.
        const wrappedPlaceholders = placeholders.map((p) =>
            isFrogmint(p) ? `...${p.fragmentName}` : p
        );

        // Call the wrapped `gql` function.
        const fragmentless = gql(literals, ...wrappedPlaceholders);

        // Append *this* frogment's fragment onto the result of the wrapped
        // call to gql. This will be the "starting point" when appending all
        // dependency fragments.
        const initialFragment = gql`
            ${fragmentless}
            ${fragment}
        `;

        // Define function to reduce all dependency fragments into a single gql
        // DocumentNode.
        const collateFragments = (acc: DocumentNode, dep: Frogmint): DocumentNode => gql`
            ${acc}
            ${dep.fragment}
        `;

        // Return value - combine all the bits and peices:
        // 1. Result of wrapped `gql` call
        // 2. This frogment's fragment
        // 3. All dependency fragments
        return [...uniqueDeps].reduce(collateFragments, initialFragment);
    };

    // Build and return the Frog table.
    return {
        dependencies: uniqueDeps,
        fragment,
        fragmentName,
        fragmentType,
        gql: gqlWrapped,
    };
};
