import capitalize from 'lodash/capitalize';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';

import { Geometry, GeometryType, MarkerSize } from '@/common/types';
import { getIcon, getSize } from '@/common/utils/geometries/helpers';
import { styleObservable } from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useState } from 'react';

export const SizePicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const { markerIcon: countMarkerIcon, size: markerSize } = styleObservable.value;
    const geometries = geometriesObservable.value;

    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);

    const setGeometries = (newGeometries: Geometry[]): void => {
        geometriesObservable.next(newGeometries);
    };

    const setMarkerSize = (newSize: MarkerSize): void => {
        styleObservable.next({
            ...styleObservable.value,
            size: newSize,
        });
    };

    const setSize = (size: MarkerSize): void => {
        switch (size) {
            case MarkerSize.S:
                setMarkerSize(MarkerSize.S);
                break;
            case MarkerSize.M:
                setMarkerSize(MarkerSize.M);
                break;
            case MarkerSize.L:
                setMarkerSize(MarkerSize.L);
                break;
        }
    };

    const updateSize = (size: MarkerSize) => {
        setSize(size);

        const needsUpdate = (g: Geometry): boolean =>
            selectedGeometryIds.includes(g.uuid) && g.type === GeometryType.COUNT;

        if (geometries === null) {
            return;
        }

        setGeometries(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style: {
                                  ...g.style,
                                  size: size,
                                  icon: getIcon(
                                      getSize(size),
                                      g.style.markerIcon || countMarkerIcon,
                                      g.style.color
                                  ),
                              },
                          } // update size
                        : g // do nothing
            )
        );
    };

    return (
        <Box {...props}>
            <IconButton
                onClick={(event) => setAnchor(event.currentTarget)}
                size="small"
                sx={{
                    height: '37px',
                    width: '37px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="body1"
                >
                    {markerSize.toUpperCase()[0]}
                </Typography>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    PaperProps={{
                        sx: {
                            padding: '16px 0',
                            width: '150px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            padding: '0 16px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Sizes
                    </Typography>
                    <List dense disablePadding>
                        {Object.values(MarkerSize).map((item) => {
                            return (
                                <ListItemButton
                                    key={item}
                                    onClick={() => {
                                        updateSize(item);

                                        setAnchor(null);
                                    }}
                                    selected={markerSize === item}
                                    sx={{
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                        variant="body2"
                                    >
                                        {capitalize(item)}
                                    </Typography>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Popover>
            </Mode>
        </Box>
    );
};
