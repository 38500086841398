import React, { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface GeometrySectionEmptyStateProps extends BoxProps {
    message: string;
    title: string;
}

export const GeometrySectionEmptyState: FC<GeometrySectionEmptyStateProps> = ({
    message,
    title,
    ...props
}) => {
    return (
        <Box
            {...props}
            sx={{
                padding: '0 12px',
                ...props.sx,
            }}
        >
            <Typography
                color="secondary"
                sx={{
                    marginBottom: '8px',
                }}
                variant="h5"
            >
                {title}
            </Typography>
            <Typography color="secondary" variant="body2">
                {message}
            </Typography>
        </Box>
    );
};
