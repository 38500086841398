import React, { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

import { useUser } from '@/contexts/User';
import { canCreateNewProject } from '@/views/Projects/utils';

export const NewProjectButton: FC<ButtonProps> = ({ ...props }) => {
    const history = useHistory();
    const user = useUser();

    const hasNewProjectButton = canCreateNewProject(user.data.user);

    const handleNewProjectButtonClick = () => {
        history.push('/projects/new');
    };

    return (
        <Button
            onClick={handleNewProjectButtonClick}
            variant="contained"
            disabled={!hasNewProjectButton}
            {...props}
        >
            New Project
        </Button>
    );
};
