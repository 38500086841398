import { createContext, useContext } from 'react';

import { EstimateEditor } from '@/components/Estimate/editor/EstimateEditor';

export const EstimationEditorContext = createContext<[EstimateEditor] | null>(null);

export const useEstimationEditor = (): EstimateEditor => {
    const context = useContext(EstimationEditorContext);

    if (!context) {
        // eslint-disable-next-line max-len
        throw new Error(
            "The `useEstimationEditor` hook must be used inside the <EstimationEditorContext> component's context."
        );
    }

    const [editor] = context;
    return editor;
};
