import { withLeyden } from 'leyden';
import { withReact } from 'leyden-react';
import React, { FC, useMemo, useState } from 'react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';

import {
    EstimationExpressionOpenedState,
    ExpressionOpenedState,
} from './hooks/useEstimationExpressionOpenedState';
import { EstimationEditorContext } from './hooks/useEstimationEditor';
import { EstimationProjectContext } from './hooks/useEstimationProject';
import { useEstimationView } from './hooks/useEstimationView';
import { useProjectInfo } from './hooks/useProjectInfo';
import { EstimationAssemblyPanelProvider } from './EstimationAssemblyPanelProvider';
import { EstimationLayout } from './EstimationLayout';
import { EstimationLayoutContext } from './hooks/useEstimationLayout';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { useWindowListener } from '@/common/hooks/useWindowListener';
import { EstimateEditor } from '@/components/Estimate/editor/EstimateEditor';
import { withExpressions } from '@/components/Estimate/editor/withExpressions';
import { withProtectedNodes } from '@/components/Estimate/editor/withProtectedNodes';
import { useUpdateProjectMutation } from '@/mutations/project';

export const EstimationRoute: FC = () => {
    const [assemblyPanelOpen, setAssemblyPanelOpen] = useState(true);
    const [expressionOpenedState, setExpressionOpenedState] = useState(
        ExpressionOpenedState.Closed
    );
    const [isPrintView, setIsPrintView] = useState(false);
    const [planPageID, setPlanPageID] = useState<string>();

    const { project } = useProjectFromParams();
    const { fetching, projectInfo, setProjectInfo } = useProjectInfo();
    const { EstimationViewComponent, view } = useEstimationView();

    const [, updateProject] = useUpdateProjectMutation();

    useWindowListener(
        'beforeprint',
        () => {
            window.scrollTo({ top: 0 });
            setIsPrintView(true);
        },
        []
    );
    useWindowListener('afterprint', () => setIsPrintView(false), []);

    const editor = useMemo<[EstimateEditor]>(
        () => [
            withProtectedNodes(
                withExpressions(withLeyden({ editor: withHistory(withReact(createEditor())) }))
            ),
        ],
        []
    );

    const setProjectName = async (name: string): Promise<void> => {
        if (project) {
            await updateProject({ input: { id: project.id, patch: { name } } });
        }
    };

    return (
        <EstimationProjectContext.Provider
            value={{
                isProjectFetching: fetching,
                projectID: project?.id ?? -1,
                projectUUID: project ? project.uuid : '',
                projectInfo,
                setProjectInfo,
                projectName: project?.name ?? '',
                setProjectName,
            }}
        >
            <EstimationLayoutContext.Provider
                value={{
                    assemblyPanelOpen,
                    openAssemblyPanel: () => setAssemblyPanelOpen(true),
                    planPageID,
                    toggleAssemblyPanelIsOpen: () => setAssemblyPanelOpen((open) => !open),
                    isPrintView,
                    setPlanPageID: (id?: string) => setPlanPageID(id),
                    view,
                }}
            >
                <EstimationExpressionOpenedState.Provider
                    value={{ expressionOpenedState, setExpressionOpenedState }}
                >
                    <EstimationEditorContext.Provider value={editor}>
                        <EstimationAssemblyPanelProvider>
                            <EstimationLayout
                                render={(layoutProps) => (
                                    <EstimationViewComponent {...layoutProps} />
                                )}
                            />
                        </EstimationAssemblyPanelProvider>
                    </EstimationEditorContext.Provider>
                </EstimationExpressionOpenedState.Provider>
            </EstimationLayoutContext.Provider>
        </EstimationProjectContext.Provider>
    );
};
