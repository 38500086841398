import { toDollarAmount } from '@/common/currency';
import { isArrayOfAssemblies } from '@/common/typeGuards';
import { AssemblyElement, Assembly, SharedEstimatePayload } from '@/common/types';

export const getTotalSF = (data?: SharedEstimatePayload): number => {
    if (!data?.area) return 0;

    const valueSF = data?.area.square_feet_under_roof;
    const value = getGrandTotal(data);

    if (!valueSF || !value) return 0;

    const perValue = valueSF;

    if (perValue === 0) return 0;

    return value / perValue;
};

export const getElementRate = (element: AssemblyElement): number | undefined => {
    const { labor_rate, material_rate, production_rate } = element;

    const laborRateUSD = toDollarAmount(labor_rate);
    const materialRateUSD = toDollarAmount(material_rate);

    let totalRate = materialRateUSD;

    if (production_rate && Number(production_rate) !== 0) {
        totalRate += laborRateUSD / Number(production_rate);
    }

    return totalRate;
};

export const getTotalPerElement = (element: AssemblyElement): number | undefined => {
    const { quantity } = element;

    const rate = getElementRate(element);

    if (!rate || rate === 0 || !quantity) {
        return;
    }

    const totalCost = Number(quantity) * rate;

    return totalCost;
};

export const getTotalPerGroup = (assembly: Assembly | undefined): number | undefined => {
    if (!assembly) return 0;

    let total = 0;

    if (!assembly.total) {
        if (!assembly.elements) return 0;

        for (const element of assembly.elements ?? []) {
            total += getTotalPerElement(element) ?? 0;
        }
    } else {
        total += toDollarAmount(assembly.total);
    }

    return total;
};

export const getGrandTotal = (data?: SharedEstimatePayload): number | undefined => {
    if (!data?.assemblies) return 0;

    const { assemblies } = data;

    let total = 0;

    if (!isArrayOfAssemblies(assemblies)) {
        const expandedAssemblies = assemblies;

        if (!expandedAssemblies) return total;

        // Loop through ungrouped items and calculate total with getTotalPerElement
        if (expandedAssemblies.ungrouped) {
            for (const assembly of expandedAssemblies.ungrouped) {
                total += getTotalPerElement(assembly) ?? 0;
            }
        }

        // Loop through grouped items and calculate with getTotalPerGroup
        if (expandedAssemblies.grouped) {
            for (const assembly of expandedAssemblies.grouped) {
                total += getTotalPerGroup(assembly) ?? 0;
            }
        }
    } else {
        for (const assembly of assemblies ?? []) {
            if (!assembly.total) {
                for (const element of assembly.elements ?? []) {
                    total += getTotalPerElement(element) ?? 0;
                }
            } else {
                total += getTotalPerGroup(assembly) ?? 0;
            }
        }
    }

    return total;
};
