import gql from 'graphql-tag';

export const createUploadMutation = gql`
    mutation CreateUpload(
        $filename: String!
        $uuid: UUID!
        $ownerId: BigInt!
        $projectId: BigInt!
    ) {
        createUpload(
            input: { filename: $filename, uuid: $uuid, ownerId: $ownerId, projectId: $projectId }
        ) {
            newId
        }
    }
`;
