import React, { ChangeEvent, FC, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useAssemblyHandlers } from '@/components/AssemblyPanel/AssemblyPanelContent/AssemblySection/AssemblyList/Assembly/hooks/useAssemblyHandlers';
import { AssemblyWithElements } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { useHandleAssembliesImported } from '@/components/AssemblyPanel/hooks/useHandleAssembliesImported';
import { assemblyIsStandalone } from '@/components/AssemblyPanel/utils/assembly';
import { assemblyPanelAssemblyToDataRecord } from '@/components/AssemblyPanel/utils/converters';
import { persistStandaloneAssemblyInProject } from '@/components/AssemblyPanel/utils/requests';
import { useSelectEstimateAssembly } from '@/components/app/router/EstimationRoute/hooks/useSelectEstimateAssembly';
import { useNotifications } from '@/contexts/Notifications';
import { IProjectLightFragment } from '@/graphql';

interface ItemListItemProps extends ListItemButtonProps {
    assembly: AssemblyWithElements;
    project: IProjectLightFragment;
}

export const ItemListItem: FC<ItemListItemProps> = ({ assembly, project, ...props }) => {
    const { addNotification } = useNotifications();

    const { selectEstimateAssembly } = useSelectEstimateAssembly();

    const { selectAssembly } = useAssemblyHandlers();

    const handleAssembliesImported = useHandleAssembliesImported({ projectId: Number(project.id) });

    const [checked, setChecked] = useState(assembly.usedInProject);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const shouldDisplayCheckbox = assemblyIsStandalone(assembly);

    const showAssemblyErrorNotification = () =>
        addNotification(
            {
                title: 'Error',
                content: 'There was a problem adding the assembly. Please try again',
            },
            'error',
            true
        );

    const withLoading = async (
        callback: () => Promise<void>,
        onError: (e: unknown) => void
    ): Promise<void> => {
        setLoading(true);
        try {
            await callback();
        } catch (e) {
            onError(e);
        } finally {
            setLoading(false);
        }
    };

    const handleListItemButtonClick = () => {
        withLoading(async () => {
            if (shouldDisplayCheckbox) {
                if (selectEstimateAssembly) {
                    const convertedAssembly = assemblyPanelAssemblyToDataRecord(assembly);

                    await selectAssembly(convertedAssembly);
                } else {
                    await handleAssembliesImported([assembly]);
                }
            } else {
                const convertedAssembly = assemblyPanelAssemblyToDataRecord(assembly);

                await selectAssembly(convertedAssembly);
            }
        }, showAssemblyErrorNotification);
    };

    const handleCheckboxChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const newChecked = event.target.checked;

        setChecked(newChecked);

        if (!checkboxDisabled) {
            setCheckboxDisabled(true);

            if (Number(assembly.projectID) !== Number(project.id)) {
                handleAssembliesImported([assembly]).then(async (r) => {
                    if (
                        r.assembliesDuplication.edges &&
                        r.assembliesDuplication.edges.length === 1
                    ) {
                        const newAssembly = r.assembliesDuplication.edges[0].node;
                        await persistStandaloneAssemblyInProject(
                            !checked,
                            Number(newAssembly.id),
                            Number(project.id)
                        );
                    }
                });
            } else {
                await persistStandaloneAssemblyInProject(
                    !checked,
                    Number(assembly.id),
                    Number(project.id)
                );
            }

            setCheckboxDisabled(false);
        }
    };

    return (
        <ListItemButton dense disabled={loading} onClick={handleListItemButtonClick} {...props}>
            <ListItemText
                primaryTypographyProps={{
                    variant: 'body2',
                }}
            >
                {assembly.description}
            </ListItemText>
            {shouldDisplayCheckbox && (
                <Checkbox
                    disabled={checkboxDisabled}
                    edge="start"
                    onChange={handleCheckboxChange}
                    size="small"
                    sx={{
                        height: 17,
                        padding: 0,
                        width: 17,
                    }}
                    value={checked}
                ></Checkbox>
            )}
        </ListItemButton>
    );
};
