import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';

import { Wrapper, Header, StyledModal, HeaderText, CloseButton, Content, Backdrop } from './styled';

export interface ModalProps {
    className?: string;
    isOpen: boolean;
    close: () => void;
    modalContent: JSX.Element;
    headerText: string;
    width?: string;
}

export const Modal: FC<ModalProps> = ({
    className,
    isOpen,
    close,
    modalContent,
    headerText,
    width = '28.875rem',
}) => {
    const screenSize = useScreenSize();
    const rootElement = document.getElementById('portal-root') || document.body;

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);

    const modal = (
        <>
            <Backdrop onClick={close} />
            <Wrapper
                className={className}
                aria-modal
                aria-labelledby={headerText}
                tabIndex={-1}
                role="dialog"
                isMobile={screenSize === ScreenSize.Mobile}
                width={width}
            >
                <StyledModal>
                    <Header>
                        <HeaderText>{headerText}</HeaderText>
                        <CloseButton onClick={close}>x</CloseButton>
                    </Header>
                    <Content>{modalContent}</Content>
                </StyledModal>
            </Wrapper>
        </>
    );

    return isOpen ? ReactDOM.createPortal(modal, rootElement) : null;
};
