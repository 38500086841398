/* Control panel for superadmin, allows for enabling/disabling features */

import React, { FC } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Experiments } from '../components/mission-control/Experiments';
import { FeatureFlags } from '../components/mission-control/FeatureFlags';
import { Onboardings } from '../components/mission-control/Onboardings';
import { UserManager } from '../components/mission-control/UserManager';
import { Actions } from '@/components/mission-control/Actions';
import { useUser } from '../contexts/User';
import { IUserRole } from '@/graphql';
import { Mode } from '@/theme/Mode';

import './MissionControl.scss';

export const MissionControl: FC = () => {
    const {
        data: { user },
    } = useUser();

    const renderFeatureFlags = (): React.PropsWithChildren<JSX.Element> => (
        <AccordionItemPanel>
            <hr />
            <FeatureFlags />
        </AccordionItemPanel>
    );

    const renderExperiments = (): React.PropsWithChildren<JSX.Element> => (
        <AccordionItemPanel>
            <hr />
            <Experiments />
        </AccordionItemPanel>
    );

    const renderOnboardings = (): React.PropsWithChildren<JSX.Element> => (
        <AccordionItemPanel>
            <hr />
            <Onboardings />
        </AccordionItemPanel>
    );

    const renderUsers = (): React.PropsWithChildren<JSX.Element> => (
        <AccordionItemPanel>
            <hr />
            <UserManager />
        </AccordionItemPanel>
    );

    const renderActions = (): React.PropsWithChildren<JSX.Element> => (
        <AccordionItemPanel>
            <hr />
            <Mode variant="light">
                <Actions />
            </Mode>
        </AccordionItemPanel>
    );

    const renderItem = (
        name: string,
        children: React.PropsWithChildren<JSX.Element>
    ): JSX.Element => (
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    <h1>{name}</h1>
                </AccordionItemButton>
            </AccordionItemHeading>
            {children}
        </AccordionItem>
    );

    return (
        <div className="mission-control">
            <Accordion allowZeroExpanded>
                {user.roles.includes(IUserRole.Superadmin) && (
                    <>
                        {renderItem('Feature Flags', renderFeatureFlags())}
                        {renderItem('Experiments', renderExperiments())}
                        {renderItem('Onboardings', renderOnboardings())}
                        {renderItem('Users ', renderUsers())}
                        {renderItem('Actions', renderActions())}
                    </>
                )}
            </Accordion>
        </div>
    );
};
