import { ElementRenderer, useLeydenStatic } from 'leyden-react';
import React, { useEffect } from 'react';
import { useSelected } from 'slate-react';

import { ExpressionCharacterContainer } from './styled';
import { Transforms } from '../../transforms';

export const ExpressionParenthesis: ElementRenderer<'ExpressionParenthesis'> = ({
    attributes,
    children,
    element,
}) => {
    const editor = useLeydenStatic();
    const selected = useSelected();

    useEffect(() => {
        if (selected) {
            Transforms.move(editor);
        }
    }, [selected]);

    return (
        <ExpressionCharacterContainer {...attributes}>
            {element.value}
            {children}
        </ExpressionCharacterContainer>
    );
};
