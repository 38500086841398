import { styled as styledByMui } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Main = styledByMui('main')({
    background: '#fff',
    minHeight: '100vh',
    paddingBottom: 32,
});

export const MainHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.text.primary,
    marginBottom: 32,
    paddingTop: 48,
}));

export const CardGroup = styledByMui('div')((options) => ({
    display: 'grid',
    gap: 24,

    [options.theme.breakpoints.up('md')]: {
        gridTemplateColumns: '2fr 1fr',
        marginBottom: 72,
    },
}));
