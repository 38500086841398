/**
 * Displays an indicator allowing the Users to see that their file is being
 * uploaded in the Discussions Panel and giving them the option to abort the
 * upload.
 */

import React, { FC } from 'react';

import { ReactComponent as FileIcon } from '@/assets/icons/File.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/TrashCan.svg';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { ProjectUploadFileNew } from '@/common/types';

import './UploadedFiles.scss';
import { useStorage } from '@/contexts/Storage';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';

type UploadedFilesProps = {
    projectUuid: string;
    files: ProjectUploadFileNew[];
    onFileRemove?: (file: ProjectUploadFileNew) => void;
};

export const UploadedFiles: FC<UploadedFilesProps> = ({ projectUuid, files, onFileRemove }) => {
    const { getFileUrl } = useStorage();

    const handleFileRemove =
        (file: ProjectUploadFileNew) =>
        (e: React.MouseEvent): void => {
            e.stopPropagation();
            onFileRemove?.(file);
        };

    const handleFileDownload = (file: ProjectUploadFileNew) => (): void => {
        getFileUrl(file as ProjectUploadFileRecord, projectUuid).then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.click();
        });
    };

    const renderFile = (file: ProjectUploadFileNew): JSX.Element => {
        return (
            <div className="file" key={file.filename} onClick={handleFileDownload(file)}>
                <SvgIcon src={FileIcon} />
                <span>{file.filename}</span>
                {onFileRemove && (
                    <SvgIcon src={TrashIcon} className="delete" onClick={handleFileRemove(file)} />
                )}
            </div>
        );
    };

    return (
        <div className="discussions-uploaded-files">{files.map((file) => renderFile(file))}</div>
    );
};
