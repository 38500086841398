import styled from 'styled-components';

import { inputCss } from '../../../utils/styles';
import { Dropdown } from '@/components/ui/inputs/Dropdown';
import { Input } from '@/components/ui/inputs/Input';
import { LocationInput } from '@/components/ui/inputs/Location';

import locationThickSrc from '@/assets/icons/location-thick.svg';

export const containerGridTemplate = `
" label " 24px
" .     " 3px
" input " 36px
`;

///////////////////////////
//// Input label title ////
///////////////////////////
export const ProjectInfoInputLabel = styled.label`
    grid-area: label;

    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    color: #cdd9ea;
`;

///////////////////////////
// Different input types //
///////////////////////////
export const ProjectInfoTextInput = styled(Input)`
    grid-area: input;

    & > input {
        ${inputCss};
    }
`;

export const ProjectInfoDropdown = styled(Dropdown)`
    grid-area: input;

    & .display-input {
        ${inputCss};
    }

    & .options-menu {
        margin-top: 8px;

        background: #fff;

        & > .option {
            ${inputCss};

            transition: none;
            background: #fff;
            color: ${(props): string => props.theme.v1.color.brand};

            &:focus,
            &:hover {
                color: #fff;
                background: #305fff;
            }
        }
    }

    & .caret-icon {
        margin-right: 8px;
        margin-bottom: 1px;

        & > svg {
            fill: #e08568;
        }
    }
`;

export const ProjectLocation = styled(LocationInput)`
    grid-area: input;

    position: relative;

    & input {
        ${inputCss};

        padding-right: 30px;
    }

    &::after {
        content: ' ';
        display: block;
        background-image: url(${locationThickSrc});
        background-repeat: no-repeat;
        background-size: auto;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 10px;
        width: 14px;
        height: 19px;
    }
`;

///////////////////////////
//// Wrapper container ////
///////////////////////////
export const ProjectInfoInputContainer = styled.div`
    display: grid;
    grid-template: ${containerGridTemplate};
`;
