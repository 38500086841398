import gql from 'graphql-tag';

export type UpdateProjectSetupInfoResult = {
    project: {
        id: string;
    };
};

export const updateProjectSetupInfo = gql`
    mutation UpdateProject($projectId: BigInt!, $type: String, $subtype: String) {
        updateProject(
            input: {
                id: $projectId
                patch: { type: $type, subtype: $subtype, isSetupCompleted: true }
            }
        ) {
            project {
                id
            }
        }
    }
`;
