import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const Card = styledByMui(MuiCard)((options) => ({
    borderTop: `2px solid ${options.theme.palette.hues.orange['0']}`,
    borderRadius: 0,
    padding: '24px',

    [options.theme.breakpoints.up('md')]: {
        ':last-of-type': {
            gridColumn: '1 / 3',
        },
    },
}));

export const Section = styledByMui(Typography)({
    marginBottom: 24,
});

export const CardHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const UpgradeHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
    marginBottom: 8,
}));
