import React, { FC } from 'react';
import { Link as RRDLink } from 'react-router-dom';

import {
    IClientPreferenceStatus,
    IReviewTagsQuery,
    IReviewTagFragment,
    useReviewTagsQuery,
} from '@/graphql';

import { ProjectReviewStatus, ProjectReview } from '@/common/types';

import { useTheme } from '@mui/material/styles';

import { Mode } from '@/theme/Mode';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';
import { ReactComponent as ThumbDownAltOutlinedIcon } from '@/assets/icons/thumbs-down.svg';
import { ReactComponent as ThumbUpOutlinedIcon } from '@/assets/icons/thumbs-up.svg';
import { getNodes } from '@/common/utils/helpers';
import { ratingNormalizer } from '@/common/utils/ratings';

const COPY = {
    reviewTitle: 'How was your experience?',
    ratingDescription: (showReviewLastProject: boolean) =>
        `This review will help us match you with a proper estimator on ${
            showReviewLastProject ? 'this and' : ''
        } future projects.`,
    positiveTagTitle: 'Great to hear!',
    negativeTagTitle: 'How can we improve?',

    positiveTagDescription: 'What details made this a 5 star project? ',
    negativeTagDescription: 'What prevented this from being a 5 star project?',
    submit: 'Submit',
    additionalFeedbackTitle: 'Additional feedback',
    submitBtnText: 'Submit',
    editBtnText: 'Edit',
    reviewLastProjectTitle: 'How did we do on your last project: ',
    preferenceInfoMessage: (name: string) =>
        `Would you like to work with ${name} again on future projects?`,
};

export type ProjectReviewModalProps = {
    review: ProjectReview;
    onStarChange: (value: number | null) => void;
    onStatusChange: (status: ProjectReviewStatus) => void;
    onReviewTagChange: (tag: IReviewTagFragment) => void;
    onPreferenceStatusChange: (preference: IClientPreferenceStatus) => void;
    onCommentChange: (comment: string) => void;
    onSubmitReview: () => Promise<void>;
    close: () => void;
    isSubmitDisabled: boolean;
    showReviewLastProject?: boolean;
    readOnly?: boolean;
};

export const ProjectReviewModal: FC<ProjectReviewModalProps> = ({
    review,
    onStatusChange,
    onStarChange,
    onReviewTagChange,
    onCommentChange,
    onPreferenceStatusChange,
    onSubmitReview,
    close,
    isSubmitDisabled,
    showReviewLastProject = false,
    readOnly = false,
}) => {
    const theme = useTheme();

    const reviewTagsQuery = useReviewTagsQuery({});

    const reviewTags = getNodes<IReviewTagsQuery, IReviewTagFragment>(
        reviewTagsQuery,
        'reviewTags'
    );

    const isProjectReviewFinished = review.isFinished;
    const estimatorName = review.projectInfo.estimatorName;

    const renderReviewTagTitle = () => {
        const { status, projectInfo, rating } = review;

        // Handle default status
        if (status === ProjectReviewStatus.NotStarted && rating === 0) {
            // Show default title
            if (!showReviewLastProject) return COPY.reviewTitle;
            // Show title with link to last project
            return (
                <>
                    {COPY.reviewLastProjectTitle}
                    <Link
                        component={RRDLink}
                        to={`/projects/${projectInfo.projectUUID}`}
                        underline="hover"
                        target="_blank"
                    >
                        {projectInfo.projectName}
                    </Link>
                </>
            );
        } else if (status === ProjectReviewStatus.Finished)
            return `Feedback for: ${projectInfo.projectName}`;

        return review.rating === 5 ? COPY.positiveTagTitle : COPY.negativeTagTitle;
    };

    const rendeReviewTagDescription = (): string => {
        const { status, rating } = review;

        if (status === ProjectReviewStatus.NotStarted || rating === 0)
            return COPY.ratingDescription(showReviewLastProject);

        return rating === 5 ? COPY.positiveTagDescription : COPY.negativeTagDescription;
    };

    const filteredReviewTags =
        reviewTags.filter(({ isPositive, id }) => {
            if (!isProjectReviewFinished) {
                return review.rating === 5 ? isPositive : !isPositive;
            }
            return !!review.reviewTags[id];
        }) ?? [];

    return (
        <>
            <Mode variant="light">
                <Dialog open onClose={close} fullWidth>
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'right',
                            }}
                        >
                            <IconButton onClick={close}>
                                <CloseIcon
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        fill: theme.palette.hues.neutral[48],
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography
                                variant="h2"
                                sx={{ color: (theme) => theme.palette.text.primary }}
                            >
                                {renderReviewTagTitle()}
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography
                            variant="h5"
                            sx={{ color: (theme) => theme.palette.text.primary }}
                        >
                            {rendeReviewTagDescription()}
                        </Typography>
                        <Box sx={{ my: '20px' }} onClick={(e) => e.stopPropagation()}>
                            <Rating
                                name="text-rating"
                                value={ratingNormalizer(review.rating)}
                                onChange={(e, value) => onStarChange(value)}
                                readOnly={isProjectReviewFinished}
                            />
                        </Box>
                        {(isProjectReviewFinished || review.rating > 0) && (
                            <>
                                <Box sx={{ my: '20px' }}>
                                    <Stack
                                        sx={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                        spacing={1}
                                    >
                                        {filteredReviewTags.map((reviewTag, idx) => (
                                            <Chip
                                                key={idx}
                                                label={reviewTag.tag}
                                                color="primary"
                                                variant={
                                                    !review.reviewTags[reviewTag.id]
                                                        ? 'outlined'
                                                        : 'filled'
                                                }
                                                onClick={() => onReviewTagChange(reviewTag)}
                                                sx={{
                                                    borderRadius: '4px',
                                                    backgroundColor:
                                                        !!review.reviewTags[reviewTag.id] &&
                                                        isProjectReviewFinished
                                                            ? (theme) => theme.palette.text.primary
                                                            : undefined,

                                                    margin: '0 8px 8px 0 !important',
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </Box>

                                <Typography
                                    variant="h5"
                                    sx={{ color: (theme) => theme.palette.text.primary }}
                                >
                                    {COPY.preferenceInfoMessage(estimatorName)}
                                </Typography>
                                <Box sx={{ my: '20px' }}>
                                    <Stack direction="row" spacing={1}>
                                        <Chip
                                            sx={{ minWidth: '100px' }}
                                            icon={
                                                <ThumbDownAltOutlinedIcon
                                                    style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        fill:
                                                            review.preferenceStatus ===
                                                            IClientPreferenceStatus.Negative
                                                                ? theme.palette.hues.neutral[100]
                                                                : theme.palette.hues.blue[0],
                                                    }}
                                                />
                                            }
                                            label="NO"
                                            variant={
                                                review.preferenceStatus ===
                                                IClientPreferenceStatus.Negative
                                                    ? 'filled'
                                                    : 'outlined'
                                            }
                                            color="primary"
                                            onClick={() =>
                                                onPreferenceStatusChange(
                                                    IClientPreferenceStatus.Negative
                                                )
                                            }
                                        />
                                        <Chip
                                            style={{ minWidth: '100px' }}
                                            icon={
                                                <ThumbUpOutlinedIcon
                                                    style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        fill:
                                                            review.preferenceStatus ===
                                                            IClientPreferenceStatus.Positive
                                                                ? theme.palette.hues.neutral[100]
                                                                : theme.palette.hues.blue[0],
                                                    }}
                                                />
                                            }
                                            label="YES"
                                            variant={
                                                review.preferenceStatus ===
                                                IClientPreferenceStatus.Positive
                                                    ? 'filled'
                                                    : 'outlined'
                                            }
                                            color="primary"
                                            onClick={() =>
                                                onPreferenceStatusChange(
                                                    IClientPreferenceStatus.Positive
                                                )
                                            }
                                        />
                                    </Stack>
                                </Box>

                                <>
                                    <Typography
                                        variant="h5"
                                        sx={{ color: (theme) => theme.palette.text.primary }}
                                    >
                                        {COPY.additionalFeedbackTitle}
                                    </Typography>

                                    <Box sx={{ my: '20px' }}>
                                        {!isProjectReviewFinished ? (
                                            <TextField
                                                sx={{
                                                    width: '100%',
                                                    height: '160px',
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        height: '100%',
                                                        padding: '12px',

                                                        [`& > textarea`]: {
                                                            height: '100% !important',
                                                        },
                                                    },
                                                }}
                                                multiline
                                                value={review.comment}
                                                onChange={(e) => onCommentChange(e.target.value)}
                                                variant="filled"
                                                placeholder="Enter message"
                                            />
                                        ) : (
                                            <Typography>{review.comment}</Typography>
                                        )}
                                    </Box>
                                </>

                                {!readOnly && (
                                    <>
                                        {!isProjectReviewFinished ? (
                                            <Button
                                                onClick={() => onSubmitReview()}
                                                color="primary"
                                                variant="contained"
                                                disabled={isSubmitDisabled}
                                            >
                                                {COPY.submitBtnText}
                                            </Button>
                                        ) : (
                                            <Link
                                                fontSize={'16px'}
                                                component="button"
                                                onClick={() =>
                                                    onStatusChange(ProjectReviewStatus.Edited)
                                                }
                                            >
                                                {COPY.editBtnText}
                                            </Link>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </DialogContent>
                </Dialog>
            </Mode>
        </>
    );
};
