import { MeasuredQuantityRecord, measuredQuantityFragment } from './measuredQuantities';
import { frogmint } from '../frog';

export enum TakeoffType {
    Count = 'COUNT',
    Linear = 'LINEAR',
    Area = 'AREA',
}

export interface TakeoffRecord {
    id: number;
    takeoffType: TakeoffType;
    measuredQuantityId: number;
    measuredQuantity: MeasuredQuantityRecord;
}

export const takeoffFragment = frogmint`
    ${'Takeoff_takeoff'} {
        id
        takeoffType
        measuredQuantityId
        measuredQuantity {
            ${measuredQuantityFragment}
        }
    }
`;
