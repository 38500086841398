import { Point } from '@/common/types';
import { coordinateDataIs } from '@/common/utils/geometries/coordinateData';
import { RotatedPlanPageUpdatePayload } from '@/components/takeoff/sheet-viewer/helpers/pageRotation';

export type RotationOptions = Pick<RotatedPlanPageUpdatePayload, 'widthPx'>;

type Rotator<T extends Point | Point[] | Point[][]> = (target: T, options: RotationOptions) => T;

const rotatePoint: Rotator<Point> = (point, { widthPx }) => {
    return { x: point.y, y: widthPx - point.x, id: point.id };
};

const rotatePoints: Rotator<Point[]> = (points, options) => {
    return points.map((point) => rotatePoint(point, options));
};

const rotatePolygon: Rotator<Point[][]> = (polygon, options) => {
    return polygon.map((line) => rotatePoints(line, options));
};

export function toRotated<T extends Point | Point[] | Point[][]>(
    target: T,
    options: RotationOptions
): T;
export function toRotated(target: Point | Point[] | Point[][], options: RotationOptions) {
    if (coordinateDataIs.point(target)) return rotatePoint(target, options);
    if (coordinateDataIs.area(target)) return rotatePolygon(target, options);
    else return rotatePoints(target, options);
}
