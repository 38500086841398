import { UomRecord, uomFragment } from './unitOfMeasure';
import { frogmint } from '../frog';

export interface MeasuredQuantityRecord {
    id: number;
    value?: number;
    uomId: number;
    uom: UomRecord;
}

export const measuredQuantityFragment = frogmint`
    ${'MeasuredQuantity_measuredQuantity'} {
        id
        value
        uomId
        uom {
            ${uomFragment}
        }
    }
`;
