import { createContext, useContext } from 'react';

import { UomRecord } from '@/queries/unitOfMeasure';

export interface EstimateUomsContext {
    defaultUomID: string | null;
    uoms: UomRecord[];
}

export const EstimateUomsContext = createContext<EstimateUomsContext>({
    defaultUomID: null,
    uoms: [],
});

export const useEstimateUoms = (): EstimateUomsContext => {
    return useContext(EstimateUomsContext);
};
