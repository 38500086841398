import { ElementRenderer, useCellIsSelected, useCoordinates, useLeydenStatic } from 'leyden-react';
import React, { MouseEventHandler } from 'react';

import {
    ElementQuantityEditorWrapper,
    ElementQuantityEditorDropdownWrapper,
    ElementQuantityEditorInnerWrapper,
    ElementQuantityEditorValue,
    ElementQuantityEditorArrowContainer,
    ElementQuantityEditorArrowIcon,
    ElementQuantityEditorDropdownButton,
} from './styled';
import { useElementQuantityEditorEffects } from '../hooks/useElementQuantityEditorEffects';
import { Transforms } from '../../transforms';

const COPY = {
    useTakeoff: 'Use takeoff',
};

export const ElementQuantityNumericInputView: ElementRenderer<
    'ElementQuantityNumericInputView'
> = ({ attributes, children, element }) => {
    useElementQuantityEditorEffects(element);

    const coords = useCoordinates(element);
    const editor = useLeydenStatic();
    const selected = useCellIsSelected(element);

    const handleUseTakeoffMouseDown: MouseEventHandler = (e): void => {
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (!selected) {
            if (coords === null) {
                throw new Error('cannot handle takeoff click: cannot select quantity cell');
            }
            Transforms.selectCell(editor, { at: coords });
        }
        Transforms.editElementQuantityFormula(editor);
    };

    return (
        <ElementQuantityEditorWrapper {...attributes}>
            <ElementQuantityEditorInnerWrapper highlighted={true}>
                <ElementQuantityEditorDropdownWrapper contentEditable={false}>
                    <ElementQuantityEditorDropdownButton onMouseDown={handleUseTakeoffMouseDown}>
                        {COPY.useTakeoff}
                    </ElementQuantityEditorDropdownButton>
                </ElementQuantityEditorDropdownWrapper>
                <ElementQuantityEditorArrowContainer contentEditable={false}>
                    <ElementQuantityEditorArrowIcon />
                </ElementQuantityEditorArrowContainer>
                <ElementQuantityEditorValue>{children}</ElementQuantityEditorValue>
            </ElementQuantityEditorInnerWrapper>
        </ElementQuantityEditorWrapper>
    );
};
