/*
    This component renders a table with action names and keyboard shortcuts for
    a particular group name.
*/

import React from 'react';
import { modifierIcon } from '@/common/hot-keys/hotKeys';
import { keyMap } from '@/common/hot-keys/keyMap';

import './GroupKeyTable.scss';

const constantToEnglish = (toEng: string): string => {
    return toEng.split('_').join(' ').toLowerCase();
};

type GroupKeyTableProps = {
    scope: string;
};

const GroupKeyTable: React.FC<GroupKeyTableProps> = ({ scope }) => {
    const renderTableHead = (): JSX.Element => (
        <thead>
            <tr>
                <th className="th">{scope}</th>
                <th className="th simple-border">Name</th>
                <th className="th simple-border">Shortcuts</th>
            </tr>
        </thead>
    );

    const createRowsForGroup = (): JSX.Element[] => {
        /* Returns multiple table rows for a specific key group:
        <tr>
            <td/> //for consistency with table header groupName
            <td>name of action</td>
            <td>key sequence</td>
        </tr> */
        const filteredByGroupName = Object.keys(keyMap).filter(
            (key) => keyMap[key].scope === scope
        );

        return filteredByGroupName.map((actionName) => {
            const { mapping } = keyMap[actionName];

            return (
                <tr key={actionName}>
                    <td />
                    <td className="simple-border">{constantToEnglish(actionName)}</td>
                    <td className="simple-border center-text" key={actionName}>
                        {mapping.replace('modifier', modifierIcon)}
                    </td>
                </tr>
            );
        });
    };

    const renderTableBody = (): JSX.Element => {
        const keyActionTableRows = createRowsForGroup();
        return <tbody>{keyActionTableRows}</tbody>;
    };

    return (
        <div key={scope} className="table-wrapper">
            <table>
                {renderTableHead()}
                {renderTableBody()}
            </table>
        </div>
    );
};

export default GroupKeyTable;
