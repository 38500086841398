import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import { Link as RRDLink } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { Env } from '@/common/env';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { ReactComponent as GoogleIcon } from '@/assets/icons/social/google.svg';
import { TextFieldWithLabel } from '@/components/ui/inputs/TextFieldWithLabel';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';

const COPY = {
    emailLabel: 'Email',
    emailPlaceholder: 'Enter email address',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter password',
    login: 'Log in',
    forgotPassword: 'Forgot password?',
    noAccount: "Don't have an account?",
    signInGoogle: 'Sign in with Google',
    signup: 'Sign up',
};

export const SignInForm: FC = () => {
    const { addNotification } = useNotifications();

    const {
        actions: { loginUser },
        loading: { loadingUser, loginInProgress },
        errors: { errorLoggingIn },
    } = useUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (!errorLoggingIn) {
            return;
        }

        addNotification(
            {
                title: 'Error',
                content: String(errorLoggingIn),
            },
            'error'
        );
    }, [addNotification, errorLoggingIn]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code') ?? undefined;

        if (code) {
            loginUser({
                code,
            }).then(() => {
                window.location.replace('/');
            });
        }
    }, []);

    const useLegacyLoginPage = true;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const refreshToken = urlParams.get('refreshToken') ?? undefined;
        const firstName = urlParams.get('firstName') ?? undefined;
        const lastName = urlParams.get('lastName') ?? undefined;
        const company = urlParams.get('company') ?? undefined;
        const phone = urlParams.get('phone') ?? undefined;

        if (refreshToken && firstName && lastName && company && phone) {
            loginUser({
                refreshToken,
                firstName,
                lastName,
                company,
                phone,
            }).then(() => {
                window.location.replace('/');
            });
        } else if (refreshToken) {
            loginUser({
                refreshToken,
            }).then(() => {
                window.location.replace('/');
            });
        } else {
            if (!useLegacyLoginPage) {
                window.location.replace('https://app2.1build.com/public/login');
            }
        }
    }, []);

    if (!useLegacyLoginPage) {
        return <SpinnerLoader />;
    }

    const validateForm = () => {
        const items = {
            email: '',
            password: '',
        };

        if (!email) {
            items.email = 'required';
        }

        if (!password) {
            items.password = 'required';
        }

        setErrors(items);
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (email && password) {
            await loginUser({
                email,
                password,
            });

            window.location.replace('/');
        } else {
            validateForm();
        }
    };

    const handleGoogleButtonClick = () => {
        const options: string[] = [
            'response_type=code',
            `client_id=${Env.auth0ClientID}`,
            `audience=https://${Env.auth0Domain}/api/v2/`,
            `redirect_uri=${encodeURIComponent(Env.deploymentURL + '/login')}`,
            'scope=openid%20profile%20email%20offline_access',
            'connection=google-oauth2',
        ];

        const googleLink = `https://${Env.auth0Domain}/authorize?${options.join('&')}`;

        window.location.replace(googleLink);
    };

    return (
        <Paper
            sx={{
                padding: '30px',
                maxWidth: '400px',
                width: '100%',
            }}
        >
            <Stack spacing={3}>
                <Typography
                    sx={{
                        textAlign: 'center',
                    }}
                    variant="h3"
                >
                    {COPY.login}
                </Typography>
                <Stack spacing={2}>
                    <Box>
                        <form onSubmit={handleFormSubmit}>
                            <Stack spacing={3}>
                                <Stack spacing={2}>
                                    <TextFieldWithLabel
                                        id="email-input"
                                        disabled={loadingUser || loginInProgress}
                                        autoComplete="email"
                                        error={Boolean(errors.email)}
                                        helperText={errors.email}
                                        label={COPY.emailLabel}
                                        placeholder={COPY.emailPlaceholder}
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                    <TextFieldWithLabel
                                        labelComponent={
                                            <Stack direction="row" justifyContent="space-between">
                                                <FormLabel
                                                    htmlFor="password-input"
                                                    sx={{
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {COPY.passwordLabel}
                                                </FormLabel>
                                                <Typography fontWeight={600} variant="body1">
                                                    <Link
                                                        component={RRDLink}
                                                        sx={{
                                                            textDecoration: 'underline',
                                                        }}
                                                        tabIndex={-1}
                                                        to="/login/reset-password"
                                                    >
                                                        {COPY.forgotPassword}
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                        }
                                        id="password-input"
                                        disabled={loadingUser || loginInProgress}
                                        autoComplete="current-password"
                                        helperText={errors.password}
                                        type="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        error={Boolean(errors.password)}
                                        placeholder={COPY.passwordPlaceholder}
                                    />
                                </Stack>
                                <Stack spacing={2}>
                                    <LoadingButton
                                        loading={loadingUser || loginInProgress}
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                    >
                                        {COPY.login}
                                    </LoadingButton>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Divider
                                            light
                                            sx={{
                                                width: '25%',
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        color="secondary"
                                        fullWidth
                                        onClick={handleGoogleButtonClick}
                                        startIcon={
                                            <SvgIcon
                                                sx={{
                                                    height: '17px',
                                                }}
                                                viewBox="0 0 24 24"
                                            >
                                                <GoogleIcon />
                                            </SvgIcon>
                                        }
                                        variant="outlined"
                                    >
                                        {COPY.signInGoogle}
                                    </Button>
                                </Stack>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '24px',
                                }}
                            >
                                <Typography fontWeight={600} variant="body1">
                                    {COPY.noAccount}{' '}
                                    <Link
                                        component={RRDLink}
                                        sx={{
                                            textDecoration: 'underline',
                                        }}
                                        to="/signup/member"
                                    >
                                        {COPY.signup}
                                    </Link>
                                </Typography>
                            </Box>
                        </form>
                    </Box>
                </Stack>
            </Stack>
        </Paper>
    );
};
