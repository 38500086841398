/*
 * Users is an administrative user table.
 */
import React, { FC, useMemo } from 'react';

import { Search } from '@/components/mission-control/UserManager/Search';
import { useUsersState } from '@/components/mission-control/UserManager/state';
import { UserManagerContainer, InfoLabel } from '@/components/mission-control/UserManager/styled';
import { UserModal } from '@/components/mission-control/UserManager/UserModal';
import { UsersTable } from '@/components/mission-control/UserManager/UsersTable';
import { CenteredSpinnerLoader } from '@/components/ui/loaders/CenteredSpinnerLoader';

const COPY = {
    noResults: 'No results found',
    infoLabel: 'If you need to look for a specific user, please use the search box',
};

export const UserManager: FC = () => {
    const state = useUsersState();

    const searchHasNoResults = useMemo(
        () => !state.loading && state.users.length === 0 && state.searchTerm !== '',
        [state.loading, state.searchTerm, state.users]
    );

    return (
        <UserManagerContainer>
            <Search state={state} />
            <InfoLabel>{COPY.infoLabel}</InfoLabel>
            {searchHasNoResults ? (
                <p style={{ gridArea: 'table' }}>{COPY.noResults}</p>
            ) : (
                <UsersTable state={state} />
            )}
            {state.loading && (
                <CenteredSpinnerLoader style={{ gridArea: 'loader', padding: '2rem 0' }} />
            )}
            <UserModal state={state} />
        </UserManagerContainer>
    );
};
