import styled from 'styled-components';

interface IKey {
    [key: string]: string;
}
export interface IButtonVariant {
    primary: IKey;
    secondary: IKey;
    tertiary: IKey;
}
export type CSSProperties = {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    hoverBackgroundColor?: string;
    activeBackgroundColor?: string;
    hoverColor?: string;
};

const getColor = (variant: keyof IButtonVariant, color?: string): string => {
    return !color ? variantOptions[variant].color : color;
};

const getBackgroundColor = (variant: keyof IButtonVariant, bgColor?: string): string => {
    return !bgColor ? variantOptions[variant].backgroundColor : bgColor;
};

const getBorderColor = (variant: keyof IButtonVariant, borderColor?: string): string => {
    return !borderColor ? variantOptions[variant].borderColor : borderColor;
};

const getHoverBackgroundColor = (variant: keyof IButtonVariant, hoverBgColor?: string): string => {
    return !hoverBgColor ? variantOptions[variant].hoverBackgroundColor : hoverBgColor;
};

const getActiveBackgroundColor = (
    variant: keyof IButtonVariant,
    activeBgColor?: string
): string => {
    return !activeBgColor ? variantOptions[variant].activeBackgroundColor : activeBgColor;
};

const getHoverTextColor = (variant: keyof IButtonVariant, hoverTextColor?: string): string => {
    return !hoverTextColor ? variantOptions[variant].color : hoverTextColor;
};

export const variantOptions = {
    primary: {
        color: '#FFFFFF',
        backgroundColor: 'linear-gradient(180deg, #4A8BF7 0%, #3B63F6 100%)',
        borderColor: 'transparent',
        hoverBackgroundColor: `linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), 
            linear-gradient(180deg, #4A8BF7 0%, #3B63F6 100%)`,
        activeBackgroundColor: '#3666F7',
    },
    secondary: {
        color: '#1B2235',
        backgroundColor: 'transparent',
        borderColor: '#334AD7',
        hoverBackgroundColor: 'transparent',
        activeBackgroundColor: '#E6E8E9',
    },
    tertiary: {
        color: '#1B2235',
        backgroundColor: '#FFFFFF',
        borderColor: '#D7E1E5',
        hoverBackgroundColor: 'transparent',
        activeBackgroundColor: '#E6E8E9',
    },
};

export const ButtonWrapper = styled.button<{
    variant: keyof IButtonVariant;
    customCss?: CSSProperties;
}>`
    display: inline-block;

    font-style: normal;
    font-weight: bold;
    text-align: center;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    white-space: nowrap;

    padding: .4rem 1.35rem;
    font-size: 11px;
    line-height: 1.5rem;
    border-radius: 4px;

    border: 1px solid transparent;

    color: ${(props): string => getColor(props.variant, props.customCss?.color)};
    background: ${(props): string =>
        getBackgroundColor(props.variant, props.customCss?.backgroundColor)};
    border-color: ${(props): string => getBorderColor(props.variant, props.customCss?.borderColor)};

    &:hover:not(:disabled),
    &:active {
        outline: 0;
        cursor: pointer;

        ${(props): string =>
            props.variant !== 'primary'
                ? `box-shadow: inset 0px 0px 0px 3px ${getBorderColor(
                      props.variant,
                      props.customCss?.borderColor
                  )}`
                : ''};
    }

    &:hover:not(:disabled) {
        background: ${(props): string =>
            getHoverBackgroundColor(props.variant, props.customCss?.hoverBackgroundColor)};
        color: ${(props): string => getHoverTextColor(props.variant, props.customCss?.hoverColor)};
    }

    &:active:not(:disabled) {
        background: ${(props): string =>
            getActiveBackgroundColor(props.variant, props.customCss?.hoverBackgroundColor)};
    }

    &:disabled: {
        opacity: 0.6,
        filter: 'saturate(60%)';
    }

    svg{
        margin: 0 0.3125rem;
        vertical-align: text-bottom;
    }
`;
