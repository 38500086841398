import gql from 'graphql-tag';

export type AssignEstimatorResult = {
    project: {
        id: string;
    };
};

export const unassignEstimatorMutation = gql`
    mutation unassignEstimator($projectId: BigInt!, $userId: BigInt!, $ownerId: BigInt!) {
        unassignEstimator(input: { projectId: $projectId, userId: $userId, ownerId: $ownerId }) {
            project {
                id
            }
        }
    }
`;

export const assignEstimatorMutation = gql`
    mutation AssingEstimator($projectId: BigInt!, $userId: BigInt!, $ownerId: BigInt!) {
        assignEstimator(input: { projectId: $projectId, userId: $userId, ownerId: $ownerId }) {
            project {
                id
            }
        }
    }
`;
