import { Cell, Element } from 'leyden';

import { WageType } from '@/common/types';
import {
    IAssemblyFragment,
    IAssemblyType,
    IEstimateElementFragment,
    IPageInfoFragment,
    IProjectLocationAssignmentInput,
    ITradeFragment,
} from '@/graphql';

export type AssemblyWithElements = IAssemblyFragment & {
    elements: IEstimateElementFragment[];
};

export interface ElementTotal {
    val: number;
    tradeName: string | null;
    categoryID: string | null;
}

export type ElementTotals = Map<string, ElementTotal>;

export type ElementTotalsSubscriber = (totals: ElementTotals) => void;

export interface ElementTotalsSubscriberInfo {
    subscriber: ElementTotalsSubscriber;
    categoryID: string | null;
}

export type ElementRates = Pick<
    IEstimateElementFragment,
    'productionRate' | 'laborRate' | 'materialRate' | 'equipmentRate'
>;

export type ElementRateStrings = {
    costPerUnit: string;
} & {
    [K in keyof ElementRates]: string;
};

export interface EstimateCost {
    direct: number;
    generalConditions: number;
    equipment: number;
}

export type EstimateElement = IEstimateElementFragment & {
    trade: ITradeFragment | null;
};

export interface EstimateElementCategory {
    id: string;
    uuid: string;
    name: string;
    favorited: boolean;
    assemblyType: IAssemblyType;
    elements: EstimateElement[];
}

export interface EstimateElementsPage {
    categories: EstimateElementCategory[];
    pageInfo: IPageInfoFragment;
}

export type CollapsedCategoriesSubscriber = (collapsedCategories: Set<string>) => void;

export type HoveredRowSubscriber = (row: number | null) => void;

export type Unsubscriber = () => void;

export interface SubscriptionNotificationThrottler {
    cancel: () => void;
    initialized: number;
}

export enum Parenthesis {
    Open = '(',
    Close = ')',
}

export enum Operator {
    Sum = '+',
    Difference = '-',
    Product = '*',
    Quotient = '/',
    Remainder = '%',
    Exponentiation = '^',
}

export type ExpressionElement =
    | Element<'ExpressionMarkup'>
    | Element<'ExpressionMarkupGroup'>
    | Element<'ExpressionNumber'>
    | Element<'ExpressionOperator'>
    | Element<'ExpressionParenthesis'>
    | Element<'ExpressionVariable'>;

export type ElementCell =
    | Cell<'Name'>
    | Cell<'Quantity'>
    | Cell<'Rate'>
    | Cell<'Total'>
    | Cell<'UnitOfMeasure'>;

export interface PriceMarkup {
    id: string;
    description: string;
    percentage?: string;
    total?: string;
}

export interface EstimateProjectInfo {
    wageType: WageType;
    projectLocation: Omit<IProjectLocationAssignmentInput, 'projectID'> | null;
    squareFeetUnderRoof: number | null;
    squareFeetTotalLivable: number | null;
}
