import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

import { colorTertiaryLighter } from '@/variables';

const variables = {
    borderHighlight: '#417BC0',
};

const containerGrid = `
" .            " 132px
" header       " 48px
" .            " 67px
" subtitle     " 39px
" .            " 54px
" button-group " 180px
" .            " 1fr
/ 1fr`;

const mobileContainerGrid = `
" .            " 1rem
" header       " auto
" .            " 1rem
" subtitle     " auto
" .            " 2rem
" button-group " auto
" .            " 1fr
/ 100vw;
`;

export const Button = styled.div`
    cursor: pointer;

    width: 416px;
    height: 180px;

    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.4s;
    background-color: rgba(255, 255, 255, 0.08);

    border: 4px solid transparent;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.04em;

    outline: none;

    &:hover {
        background-color: rgba(255, 255, 255, 0.12);
    }

    &:active,
    &:focus {
        border: 4px solid ${variables.borderHighlight};
    }

    ${breakpoints.small`
        width: 100%;
        height: 128px;
    `}
`;

export const Text = styled.span`
    grid-area: button-text;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Disclaimer = styled.span`
    grid-area: disclaimer;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0;

    color: #ffffff;
    opacity: 0.6;
`;

export const ButtonContainer = styled.div`
    grid-area: button-group;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    ${breakpoints.small`
        grid-template-columns: 1fr;
        row-gap: 0.5rem;

        padding: 0 0.5rem;
    `}
`;

export const Header = styled.h1`
    grid-area: header;

    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: ${colorTertiaryLighter};

    max-width: 659px;

    ${breakpoints.small`
        font-size: 32px;
        line-height: 40px;

        padding: 0 1rem;
        margin: 0;
    `}
`;

export const Subtitle = styled.h2`
    grid-area: subtitle;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;

    letter-spacing: 0;

    color: #ffffff;
    opacity: 0.8;

    ${breakpoints.small`
        font-size: 20px;
        line-height: 24px;

        padding: 0 1rem;
    `}
`;

export const Container = styled.div`
    display: grid;
    grid-template: ${containerGrid};

    min-height: calc(100vh - 6rem);
    max-width: 848px;

    margin: auto;

    ${breakpoints.small`
        grid-template: ${mobileContainerGrid};
        max-width: 100vw;
    `}
`;
