/*
 * events.ts provides GraphQL queries used to fetch records from the
 * `events` table.
 */
import { frogmint, newQueries } from '../frog';
import { BaseUserRecord, baseUserFragment } from './baseUsers';
import {
    AnyEventTypeName,
    EventTypeCondition,
    EventTypeRecord,
    eventTypeFragment,
} from './eventTypes';
import {
    ProjectUploadFileRecord,
    ProjectUploadFileRecords,
    projectUploadFileFragment,
} from './projectUploadFiles';
import { UseQueryArgs, UseQueryResponse, useQuery } from 'urql';

// other operations that update events
const additionalTypenames = ['CreateUploadResult', 'DeleteProjectUploadFilePayload'];

interface AdditionalBaseUserInfo {
    team?: {
        id: string;
        teamOnboarding?: {
            accountType: string;
            created: string;
        };
    };
}

export interface EventRecord {
    created: string;
    lastModified?: string;
    id: number;
    message?: string;
    seen: boolean;
    eventTypeName: AnyEventTypeName;
    ownerId?: number;
    owner?: AdditionalBaseUserInfo;
    projectId?: number;
}

export interface EventRecords {
    events: {
        nodes: EventRecord[];
    };
}

export interface EventCondition {
    id?: number;
    eventTypeName?: AnyEventTypeName;
    ownerId?: number;
    projectId?: number;
}

export const eventFragment = frogmint`
    ${'Event_event'} {
        created
        lastModified
        id
        message
        seen
        eventTypeName
        ownerId
        owner {
            team {
                id
                teamOnboarding {
                    created
                    accountType
                }
            }
        }
        projectId
    }
`;

export const EventsQuery = eventFragment.gql`
    query Events($condition: EventCondition) {
        events(condition: $condition) {
            nodes {
                ${eventFragment}
            }
        }
    }
`;

export const [executeEventsQuery, useEventsQuery] = newQueries<EventCondition, EventRecords>(
    EventsQuery,
    { additionalTypenames }
);

//
// Events with nested ProjectUploadFiles and Owner
//

export type EventFullRecord = EventRecord &
    ProjectUploadFileRecords & {
        owner: BaseUserRecord;
    };

export interface EventFullRecords {
    events: {
        nodes: EventFullRecord[];
    };
}

export const eventFullFragment = frogmint`
    ${'Event_eventFull'} {
        ${eventFragment}
        owner {
            ${baseUserFragment}
        }
        projectUploadFiles {
            nodes {
                ${projectUploadFileFragment}
            }
        }
    }
`;

export const EventsFullQuery = eventFullFragment.gql`
    query EventsFull($condition: EventCondition) {
        events(condition: $condition) {
            nodes {
                ${eventFullFragment}
            }
        }
    }
`;

export const [executeEventsFullQuery, useEventsFullQuery] = newQueries<
    EventCondition,
    EventFullRecords
>(EventsFullQuery, { additionalTypenames });

//
// Events by Event Type
//

export interface EventsByEventTypeRecords {
    eventTypes: {
        nodes: EventTypeRecord &
            {
                eventsByEventTypeName: {
                    nodes: EventRecord[];
                };
            }[];
    };
}

export interface FullEventsByEventTypeRecords {
    eventTypes: {
        nodes: EventTypeRecord &
            {
                eventsByEventTypeName: {
                    nodes: EventFullRecord[];
                };
            }[];
    };
}

export interface EventsByEventTypeCondition {
    eventCondition?: EventCondition;
    eventTypeCondition?: EventTypeCondition;
}

const EventsByEventTypeQuery = eventTypeFragment.gql`
    query EventsByEventType(
        $eventCondition: EventCondition
        $eventTypeCondition: EventTypeCondition,
    ) {
        eventTypes(condition: $eventTypeCondition) {
            nodes {
                ${eventTypeFragment}
                eventsByEventTypeName (condition: $eventCondition) {
                    nodes {
                        ${eventFragment}
                    }
                }
            }
        }
    }

    ${eventFragment.fragment}
`;

const FullEventsByEventTypeQuery = eventFullFragment.gql`
    query EventsByEventType(
        $eventCondition: EventCondition
        $eventTypeCondition: EventTypeCondition,
    ) {
        eventTypes(condition: $eventTypeCondition) {
            nodes {
                ${eventTypeFragment}
                eventsByEventTypeName (condition: $eventCondition) {
                    nodes {
                        ${eventFullFragment}
                    }
                }
            }
        }
    }

    ${eventTypeFragment.fragment}
`;

type EventsByEventTypeOptions = Omit<
    UseQueryArgs<EventsByEventTypeRecords, EventsByEventTypeCondition>,
    'query' | 'variables'
>;

export const useEventsByEventTypeQuery = (
    condition: EventsByEventTypeCondition,
    options?: EventsByEventTypeOptions
): UseQueryResponse<EventsByEventTypeRecords> =>
    useQuery<EventsByEventTypeRecords>({
        query: EventsByEventTypeQuery,
        variables: {
            ...condition,
        },
        ...options,
    });

export const useFullEventsByEventTypeQuery = (
    condition: EventsByEventTypeCondition,
    options?: EventsByEventTypeOptions
): UseQueryResponse<FullEventsByEventTypeRecords> =>
    useQuery<FullEventsByEventTypeRecords>({
        query: FullEventsByEventTypeQuery,
        variables: {
            ...condition,
        },
        ...options,
    });

//
// Project Upload Files with a nested Event
//

export type ProjectUploadFileFullRecord = ProjectUploadFileRecord & {
    event?: EventRecord;
};

export const projectUploadFileFullFragment = frogmint`
    ${'ProjectUploadFile_projectUploadFileFull'} {
        ${projectUploadFileFragment}
        event {
            ${eventFragment}
        }
    }
`;
