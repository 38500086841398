import wkx from 'wkx';

import { CoordinateData, GeometryType, Point } from '@/common/types';

type Convert = {
    [GeometryType.AREA]: wkx.Polygon;
    [GeometryType.COUNT]: wkx.MultiPoint;
    [GeometryType.LINEAR]: wkx.LineString;
};

type Converter<T extends GeometryType> = (data: CoordinateData<T>) => Convert[T];

export const toWKX: {
    lineString: Converter<GeometryType.LINEAR>;
    multiPoint: Converter<GeometryType.COUNT>;
    polygon: Converter<GeometryType.AREA>;
    point: (point: Point) => wkx.Point;
} = {
    lineString: (line) => {
        return new wkx.LineString(line.map(toWKX.point));
    },
    multiPoint: (points) => {
        return new wkx.MultiPoint(points.map(toWKX.point));
    },
    polygon: (area) => {
        const exteriorRing = area[0].map(toWKX.point);
        const interiorRings = area.slice(1).map((ring) => ring.map(toWKX.point));
        return new wkx.Polygon(exteriorRing, interiorRings);
    },
    point: (point) => {
        return new wkx.Point(point.x, point.y);
    },
};
