/*
 * customerIO.ts provides a GraphQL mutation to track users with customer.io
 */
import gql from 'graphql-tag';
import { useMutation, UseMutationResponse } from 'urql';

export interface TrackPageViewRecord {
    trackPageView: string;
}

export interface TrackPageViewArgs {
    id: number | string;
    path: string;
}

export const TrackPageViewMutation = gql`
    mutation TrackPageView($id: BigInt!, $path: String!) {
        trackPageView(id: $id, path: $path) {
            path
        }
    }
`;

export function useTrackPageViewMutation(): UseMutationResponse<
    TrackPageViewRecord,
    TrackPageViewArgs
> {
    return useMutation<TrackPageViewRecord, TrackPageViewArgs>(TrackPageViewMutation);
}
