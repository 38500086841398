import { FormulaVariableRecord, formulaVariableFragment } from './formulaVariables';
import { frogmint } from '../frog';
import { uomFragment, UomRecord } from './unitOfMeasure';

export type FormulaRecord = {
    id: number;
    uuid: string;
    expression: string;
    formulaVariables?: {
        nodes: FormulaVariableRecord[];
    };
    takeoffUomId?: number;
    takeoffUom?: UomRecord;
};

export type FormulaRecordEdge = {
    node: FormulaRecord;
};

export type FormulaRecords = {
    edges: FormulaRecordEdge[];
};

export const formulaFragment = frogmint`
    ${'Formula_formula'} {
        id
        uuid
        expression
        formulaVariables {
            nodes {
                ${formulaVariableFragment}
            }
        }
        takeoffUomId
        takeoffUom {
            ${uomFragment}
        }
    }
`;
