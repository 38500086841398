import { PlanPage, PlanPageOrientation } from '@/common/types';

export type RotatedPlanPageUpdatePayload = Pick<
    PlanPage,
    'widthPx' | 'heightPx' | 'widthIn' | 'heightIn' | 'orientation' | 'originalOrientation'
>;

const getNewDimensions = (
    page: PlanPage
): Omit<RotatedPlanPageUpdatePayload, 'orientation' | 'originalOrientation'> => {
    return {
        widthPx: page.heightPx,
        heightPx: page.widthPx,
        widthIn: page.heightIn,
        heightIn: page.widthIn,
    };
};

const findNewOrientation = (
    orientation: PlanPageOrientation,
    originalOrientation: PlanPageOrientation
): PlanPageOrientation => {
    const orientationMap: {
        [K in PlanPageOrientation]: { [K in PlanPageOrientation]: PlanPageOrientation };
    } = {
        1: {
            1: 6,
            6: 3,
            3: 8,
            8: 1,
        },
        6: {
            1: 8,
            8: 3,
            3: 6,
            6: 1,
        },
        3: {
            1: 6,
            6: 3,
            3: 8,
            8: 1,
        },
        8: {
            1: 6,
            6: 3,
            3: 8,
            8: 1,
        },
    };
    return orientationMap[originalOrientation][orientation];
};

export const findRotatedPageParameters = (
    page: PlanPage,
    hasPreRotation = true
): RotatedPlanPageUpdatePayload => {
    return {
        orientation: findNewOrientation(
            page.orientation,
            hasPreRotation ? 1 : page.originalOrientation
        ),
        originalOrientation: page.originalOrientation,
        ...getNewDimensions(page),
    };
};
