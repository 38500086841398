import { AppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React, { FC, useState } from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { IUserFragment } from '@/graphql';
import { useUser } from '@/contexts/User';
import { Mode } from '@/theme/Mode';
import { StopImpersonatingModal } from './StopImpersonatingModal';
import { AppBar, Chip, LogoIcon, SvgIcon, Toolbar } from './styled';
import { ReactComponent as SettingsIcon } from '@/assets/icons/16/settings.svg';

interface Props extends AppBarProps {
    onHideBarMenuItemClick?: () => void;
    onStopImpersonatingModalOkButtonClick?: () => void;
}

const getFullName = (user: IUserFragment) => {
    return `${user.firstName} ${user.lastName}`;
};

export const ImpersonatorBar: FC<Props> = ({
    onHideBarMenuItemClick,
    onStopImpersonatingModalOkButtonClick,
    ...props
}) => {
    const user = useUser();

    const [anchor, setAnchor] = useState<HTMLElement>();
    const [isStopModalOpen, setIsStopModalOpen] = useState(false);

    const handleSettingsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    };

    const handleSettingsMenuClose = () => {
        setAnchor(undefined);
    };

    return (
        <AppBar position="fixed" {...props}>
            <Toolbar>
                <Stack alignItems="center" direction="row" spacing="16px">
                    <Link component={RRDLink} to="/">
                        <LogoIcon />
                    </Link>
                    <Typography variant="button">impersonate</Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing="24px">
                    <Tooltip title="Impersonated user">
                        <Chip label={getFullName(user.data.user)} variant="outlined" />
                    </Tooltip>
                    <IconButton onClick={handleSettingsButtonClick}>
                        <SvgIcon viewBox="0 0 16 16">
                            <SettingsIcon />
                        </SvgIcon>
                    </IconButton>
                    <Mode variant="light">
                        <Menu
                            anchorEl={anchor}
                            onClose={handleSettingsMenuClose}
                            open={anchor !== undefined}
                        >
                            <MenuItem onClick={() => setIsStopModalOpen(true)}>
                                Stop Impersonating
                            </MenuItem>
                            <MenuItem onClick={onHideBarMenuItemClick}>Hide Bar</MenuItem>
                        </Menu>
                        <StopImpersonatingModal
                            onCancelButtonClick={() => setIsStopModalOpen(false)}
                            onOkButtonClick={onStopImpersonatingModalOkButtonClick}
                            open={isStopModalOpen}
                        />
                    </Mode>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
