import * as yup from 'yup';

export const builderDetailsSchema = yup.object({
    rates: yup.array().of(
        yup.object().shape({
            hourlyRateUSDCents: yup
                .number()
                .required()
                .transform((value: number) => Math.floor(value * 100)),
            type: yup.string().required(),
        })
    ),
    estimateTypes: yup.array().nullable(true),
    formattingPreferences: yup.array().nullable(true),
    inclusionsExclusions: yup.string().nullable(true),
    avgProjectPriceGSF: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value * 100)
        ),
    totalProjectPrice: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value * 100)
        ),
    overHead: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value)
        ),
    profit: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value)
        ),
    material: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value)
        ),
    labor: yup
        .number()
        .nullable(true)
        .transform((value: number, originalValue: string) =>
            originalValue === '' ? null : Math.floor(value)
        ),
    specialRequestsAndNotes: yup.string().nullable(true),
    files: yup.array(),
    trades: yup.array().min(1).nullable(true),
    wage: yup.string().min(1).nullable(true),
});
