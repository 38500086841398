/*
 * TabMenu is a multi-tab menu within the top bar of the admin dashboard.
 */
import React, { FC } from 'react';

import { Tab } from './Tab';
import { TabMenuContainer } from './styled';

import { TabType } from '@/components/AdminDashboard/types';

export const TabMenu: FC = () => (
    <TabMenuContainer>
        {Object.values(TabType).map(
            (tabType: TabType, index: number): JSX.Element => (
                <Tab key={index} tabType={tabType} />
            )
        )}
    </TabMenuContainer>
);
