import styled from 'styled-components';

export const ContextActionsContainer = styled.div`
    margin-left: auto;
    position: relative;
`;

export const ContextMenu = styled.div`
    position: absolute;
    right: 0;
    top: 5px;
    width: 156px;
    padding: 8px 0;
    background: #ffffff;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`;

export const ContextMenuOption = styled.div`
    cursor: pointer;
    color: #1b2235;
    font-size: 12px;
    font-weight: normal;
    padding-left: 16px;
    height: 28px;
    line-height: 28px;

    &:hover {
        background: #3067ff;
        color: white;
    }
`;
