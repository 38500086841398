import { DefinitionNode, FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';
import { Exchange, OperationResult } from 'urql';
import { Source, map, pipe } from 'wonka';

import { completedUrqlOperationObservable } from '@/common/apollo/observables';
import { subscribeToCompletedApolloOperationObservable } from '@/interfaces/apollo';

const apolloOperationInvalidation = (opName: string): string[] | null => {
    switch (opName) {
        case 'ProjectStatusAssignment':
            return ['Project'];
        case 'ProjectPricing':
            return ['Balance', 'Project'];
        default:
            return null;
    }
};

export const definitionNodeIsFragmentDefinitionNode = (
    node: DefinitionNode
): node is FragmentDefinitionNode => node.kind === 'FragmentDefinition';

export const definitionNodeIsFragmentOrOperationDefinitionNode = (
    node: DefinitionNode
): node is FragmentDefinitionNode | OperationDefinitionNode =>
    node.kind === 'FragmentDefinition' || node.kind === 'OperationDefinition';

export const invalidationHandlingExchange: Exchange = ({ forward }) => {
    const invalidatedRecords = new Set<string>();
    subscribeToCompletedApolloOperationObservable((op) => {
        const invalidatedRecord = apolloOperationInvalidation(op.operationName);
        if (invalidatedRecord !== null) {
            invalidatedRecord.forEach((r) => invalidatedRecords.add(r));
        }
    });
    return (operations$): Source<OperationResult> =>
        forward(
            pipe(
                operations$,
                map((op) => {
                    if (op.kind !== 'query') {
                        return op;
                    }
                    const invalidatedTypes = new Set(
                        op.query.definitions
                            .filter(definitionNodeIsFragmentDefinitionNode)
                            .map((def) => def.name.value.split('_')[0])
                            .filter((typeName) => invalidatedRecords.has(typeName))
                    );

                    if (invalidatedTypes.size === 0) {
                        return op;
                    }
                    invalidatedTypes.forEach((typeName) => invalidatedRecords.delete(typeName));
                    return {
                        ...op,
                        context: {
                            ...op.context,
                            requestPolicy: 'network-only',
                        },
                    };
                })
            )
        );
};

export const invalidationTriggeringExchange: Exchange = ({ forward }) => {
    return (operations$): Source<OperationResult> =>
        forward(
            pipe(
                operations$,
                map((op) => {
                    if (op.kind !== 'mutation') {
                        return op;
                    }
                    completedUrqlOperationObservable.next(op);
                    return op;
                })
            )
        );
};
