// eslint-disable max-len
import { DivIconOptions, Point } from 'leaflet';

import { MarkerIcon, MarkerSize } from '@/common/types';

/**
 * This file makes heavy use of inline svgs. We want to keep them in code for
 * now because we want to be able to parameterize the fill properties of some
 * of their paths dynamically, based on JS variables.
 **/

const MarkerIconCircle = (color: string, size: number): DivIconOptions => ({
    html: `<svg width="${size}" height="${size}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="${color}"/>
</svg>`,
    iconSize: new Point(size, size),
    className: 'custom-marker-icon',
});

const MarkerIconSquare = (color: string, size: number): DivIconOptions => ({
    html: `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="${size}" height="${size}" fill="${color}"/>
</svg>`,
    iconSize: new Point(size, size),
    iconAnchor: new Point(size / 2, size / 2),
    className: 'custom-marker-icon',
});

const MarkerIconDiamond = (color: string, size: number): DivIconOptions => ({
    html: `<svg width="${2 * size}" height="${2 * size}"  viewBox="${0} ${0} ${2 * size} ${
        2 * size
    }" fill="none" xmlns="http://www.w3.org/2000/svg"><rect transform="rotate(45 ${size} ${size})" y="${
        size / 2
    }" x="${size / 2}" width="${size}" height="${size}" fill="${color}"/></svg>`,
    iconSize: new Point(2 * size, 2 * size),
    className: 'custom-marker-icon',
});

const MarkerIconStar = (color: string, size: number): DivIconOptions => {
    const html: { [key: number]: string } = {
        8: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0L8.65248 5.06607L14 5.06608L9.67376 8.19708L11.3262 13.2632L7 10.1322L2.67376 13.2632L4.32624 8.19708L0 5.06608L5.34752 5.06607L7 0Z" fill="${color}"/></svg>`,
        12: `<svg width="14" height="14" viewBox="0 0 14 14" transform-origin="5 5" transform="scale(1.6)" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0L8.65248 5.06607L14 5.06608L9.67376 8.19708L11.3262 13.2632L7 10.1322L2.67376 13.2632L4.32624 8.19708L0 5.06608L5.34752 5.06607L7 0Z" fill="${color}"/></svg>`,
        16: `<svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0833 0L13.6998 8.02129H22.1667L15.3168 12.9787L17.9332 21L11.0833 16.0426L4.23346 21L6.84988 12.9787L0 8.02129H8.46691L11.0833 0Z" fill="${color}"/></svg>`,
        20: `<svg width="23" height="21" viewBox="0 0 23 21" transform-origin="5 5" transform="scale(1.4)" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0833 0L13.6998 8.02129H22.1667L15.3168 12.9787L17.9332 21L11.0833 16.0426L4.23346 21L6.84988 12.9787L0 8.02129H8.46691L11.0833 0Z" fill="${color}"/></svg>`,
        32: `<svg width="34" height="32" viewBox="0 0 34 32"  fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3564 0L21.2516 12.2229L33.8564 12.2229L23.6589 19.7771L27.554 32L17.3564 24.4458L7.15889 32L11.054 19.7771L0.856445 12.2229L13.4613 12.2229L17.3564 0Z" fill="${color}"/></svg>`,
        36: `<svg width="34" height="32" viewBox="0 0 34 32" transform-origin="5 5" transform="scale(1.2)" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3564 0L21.2516 12.2229L33.8564 12.2229L23.6589 19.7771L27.554 32L17.3564 24.4458L7.15889 32L11.054 19.7771L0.856445 12.2229L13.4613 12.2229L17.3564 0Z" fill="${color}"/></svg>`,
    };

    const getOverlay = (size: number): string => {
        if (size >= 32) {
            return html[36];
        }
        if (size >= 16) {
            return html[20];
        } else {
            return html[12];
        }
    };

    return {
        html: html[size] ?? getOverlay(size),
        iconSize: new Point(size, size),
        className: 'custom-marker-icon',
    };
};

const MarkerIconTriangle = (color: string, size: number): DivIconOptions => ({
    html: `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M${
        size / 2
    } 0L${size} ${size}H0L${size / 2} 0Z" fill="${color}"/></svg>`,
    iconSize: new Point(size, size),
    className: 'custom-marker-icon',
});

const MarkerIconTriangleReversed = (color: string, size: number): DivIconOptions => ({
    html: `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M${
        size / 2
    } ${size}L0 0L${size} 0L${size / 2} ${size}Z" fill="${color}"/></svg>`,
    iconSize: new Point(size, size),
    className: 'custom-marker-icon',
});

const MarkerIconDefault = (color: string): DivIconOptions => ({
    html: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1.63611" y="9.69431" width="11.4356" height="11.7975" transform="rotate(-45 1.63611 9.69431)" fill="white"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.75L16.25 10L10 16.25L3.75 10L10 3.75ZM0 10L10 0L20 10L10 20L0 10ZM10 11.4905C10.9763 11.4905 11.7678 10.699 11.7678 9.72272C11.7678 8.74641 10.9763 7.95495 10 7.95495C9.02369 7.95495 8.23223 8.74641 8.23223 9.72272C8.23223 10.699 9.02369 11.4905 10 11.4905Z" fill="${color}"/> </svg>`,
    iconSize: new Point(20, 20),
    className: 'custom-marker-icon',
});

export const getIcon = (size: number, type: MarkerIcon, color: string): DivIconOptions => {
    switch (type) {
        case MarkerIcon.CIRCLE:
            return MarkerIconCircle(color, size);
        case MarkerIcon.SQUARE:
            return MarkerIconSquare(color, size);
        case MarkerIcon.DIAMOND:
            return MarkerIconDiamond(color, size);
        case MarkerIcon.STAR:
            return MarkerIconStar(color, size);
        case MarkerIcon.TRIANGLE:
            return MarkerIconTriangle(color, size);
        case MarkerIcon.TRIANGLE_REVERSED:
            return MarkerIconTriangleReversed(color, size);
        default:
            return MarkerIconDefault(color);
    }
};

export const getSize = (markerSize: MarkerSize): number => {
    switch (markerSize) {
        case MarkerSize.S:
            return 8;
        case MarkerSize.M:
            return 16;
        case MarkerSize.L:
            return 32;
    }
};
