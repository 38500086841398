/**
 * Uncomment code in this file when we are using a video SRC rather
 * than a loom link.
 */
import { Card, Container, Copy, LoomVideo, TextWrapper, VideoWrapper } from './styled';
import React, { FC } from 'react';

export { LoomVideo, VideoWrapper } from './styled';

const COPY = {
    walkthrough: 'Walkthrough of 1build’s takeoff and estimation features (2min)',
};

interface InstructionVideoProps {
    className?: string;
}
export const InstructionVideo: FC<InstructionVideoProps> = ({ className }) => {
    return (
        <Container className={className}>
            <Card>
                <VideoWrapper>
                    <LoomVideo
                        src="https://www.loom.com/embed/179af91c43c24c61911efae9bab8e0e6"
                        frameBorder="0"
                        allowFullScreen
                    ></LoomVideo>
                </VideoWrapper>

                <TextWrapper>
                    <Copy>{COPY.walkthrough}</Copy>
                </TextWrapper>
            </Card>
        </Container>
    );
};
