import { MutationHookOptions, useApolloClient, useMutation } from '@apollo/client';

import { Hook } from '@/common/types';
import {
    IClientPreferenceStatus,
    IScheduleAssignmentMutation,
    IScheduleAssignmentMutationVariables,
    IUserClientPreferenceAssignmentMutation,
    IUserClientPreferenceAssignmentMutationVariables,
    IUserTradeRatingAssignmentMutation,
    IUserTradeRatingAssignmentMutationVariables,
    ScheduleAssignmentDocument,
    UserClientPreferenceAssignmentDocument,
    UserTradeRatingAssignmentDocument,
} from '@/graphql';

export interface UseEstimatorActionsRes {
    trades: {
        updateTradesRating: (tradeID: string, rating: number) => void;
    };
    status: {
        updateEstimatorStatus: (paused: boolean, scheduleId?: string) => void;
        scheduleAssignmentLoading: boolean;
    };
    clients: {
        updateClientPreference: (
            teamID: string,
            status: IClientPreferenceStatus,
            clientID: string
        ) => Promise<void>;
    };
}

export interface UseEstimatorActionsProps {
    userId: string;
}

export const useEstimatorActions: Hook<UseEstimatorActionsRes, UseEstimatorActionsProps> = ({
    userId,
}): UseEstimatorActionsRes => {
    const client = useApolloClient();

    const updateTradeRatingMutationInput = (
        tradeID: string,
        rating: number
    ): MutationHookOptions<
        IUserTradeRatingAssignmentMutation,
        IUserTradeRatingAssignmentMutationVariables
    > => ({
        variables: {
            input: {
                id: userId,
                tradeID,
                rating,
            },
        },
    });

    const updateScheduleAssignmentMutationInput = (
        paused: boolean,
        scheduleId?: string
    ): MutationHookOptions<IScheduleAssignmentMutation, IScheduleAssignmentMutationVariables> => ({
        variables: {
            input: {
                userID: userId,
                paused,
                id: scheduleId,
            },
        },
    });

    const updateClientPreferenceMutationInput = (
        teamID: string,
        status: IClientPreferenceStatus
    ): MutationHookOptions<
        IUserClientPreferenceAssignmentMutation,
        IUserClientPreferenceAssignmentMutationVariables
    > => ({
        variables: {
            input: {
                id: userId,
                teamID,
                status,
            },
        },
    });

    // mutations
    const [userTradeRatingUpdate] = useMutation<
        IUserTradeRatingAssignmentMutation,
        IUserTradeRatingAssignmentMutationVariables
    >(UserTradeRatingAssignmentDocument, {
        refetchQueries: ['UsersLite'],
    });

    const [scheduleAssignmentUpdate, { loading: scheduleAssignmentLoading }] = useMutation<
        IScheduleAssignmentMutation,
        IScheduleAssignmentMutationVariables
    >(ScheduleAssignmentDocument, {
        awaitRefetchQueries: true,
        refetchQueries: ['UsersLite'],
    });

    const [userClientPreferenceUpdate] = useMutation<
        IUserClientPreferenceAssignmentMutation,
        IUserClientPreferenceAssignmentMutationVariables
    >(UserClientPreferenceAssignmentDocument);

    // other functions
    const updateTradesRating = (tradeID: string, rating: number): void => {
        userTradeRatingUpdate(updateTradeRatingMutationInput(tradeID, rating));
    };

    const updateEstimatorStatus = (paused: boolean, scheduleId?: string): void => {
        scheduleAssignmentUpdate(updateScheduleAssignmentMutationInput(paused, scheduleId));
    };

    const updateClientPreference = async (
        teamID: string,
        status: IClientPreferenceStatus,
        clientID: string
    ): Promise<void> => {
        await userClientPreferenceUpdate(updateClientPreferenceMutationInput(teamID, status));

        // this is the current solution to update cache instead of refetching whole estimators list
        // we await the mutation and after that update the cache
        // ideally the backend should send ID of updated ClientPreference in response
        // that way we can update the cache using update function in useMutation
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'ClientPreference',
                id: clientID,
            }),
            fields: {
                preference() {
                    return status;
                },
            },
        });
    };

    return {
        trades: {
            updateTradesRating,
        },
        status: {
            updateEstimatorStatus,
            scheduleAssignmentLoading,
        },
        clients: {
            updateClientPreference,
        },
    };
};
