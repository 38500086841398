/*
 * useSlateMarkdown provides SlateJS editor data backed by markdown.
 */
import { useMemo, useState } from 'react';

import { NodeTypes } from '@1build/remark-slate/src/deserialize';
import { Descendant, Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';

import {
    deserialize,
    emptySlateParagraph,
    serialize,
    withHtml,
    withShortcuts,
} from '@/common/slate/';
import { Setter } from '@/common/types';

const nodeTypes: NodeTypes = {
    paragraph: 'paragraph',
    block_quote: 'block-quote',
    code_block: 'code_block',
    link: 'link',
    ul_list: 'bulleted-list',
    ol_list: 'numbered-list',
    listItem: 'list-item',
    heading: {
        1: 'heading-one',
        2: 'heading-two',
        3: 'heading-three',
        4: 'heading-three',
        5: 'heading-three',
        6: 'heading-three',
    },
    emphasis_mark: 'italic',
    strong_mark: 'bold',
    delete_mark: 'strikeThrough',
    underline_mark: 'underline',
    thematic_break: 'thematic_break',
};

export interface UseSlateMarkdownRes {
    editor: Editor & ReactEditor;
    nodes: Descendant[];
    setNodes: Setter<Descendant[]>;
    resetValue: (init?: string | Descendant[]) => void;
    setValue: (newVal: string) => void;
    getMarkdown: () => string;
    isBlank: () => boolean;
}

export interface UseSlateMarkdownOptions {
    isStatic?: boolean;
}

export const useSlateMarkdown = (
    init: string,
    opts?: UseSlateMarkdownOptions
): UseSlateMarkdownRes => {
    const editor = useMemo(
        () =>
            opts?.isStatic
                ? withReact(createEditor())
                : withHtml(withShortcuts(withHistory(withReact(createEditor())))),
        []
    );
    const [nodes, setNodes] = useState<Descendant[]>(deserialize(init, { nodeTypes }));

    const getMarkdown = (): string => serialize(nodes, { nodeTypes });

    const resetValue = (): void => {
        // Remove all child nodes.
        for (let i = editor.children.length - 1; i >= 0; i--) {
            Transforms.removeNodes(editor, { at: [i] });
        }
        // Add an empty text node.
        Transforms.insertNodes(editor, emptySlateParagraph);
    };

    const setValue = (newVal: string): void => setNodes(deserialize(newVal, { nodeTypes }));

    const isBlank = (): boolean => {
        return Editor.string(editor, []) === '';
    };

    return {
        editor,
        nodes,
        setNodes,
        resetValue,
        setValue,
        getMarkdown,
        isBlank,
    };
};
