import loadImage from 'blueimp-load-image';
import debounce from 'lodash/debounce';

import { PlanPageOrientation, Setter } from '@/common/types';

export const getImage = debounce(
    ((): ((
        sheetURL: string,
        orientation: PlanPageOrientation,
        setter: Setter<string>,
        abortLoading?: () => void
    ) => void) => {
        return (
            sheetURL: string,
            orientation: PlanPageOrientation,
            setter: Setter<string>,
            abortLoading?: () => void
        ): void => {
            loadImage(
                sheetURL,
                (img) => {
                    const result = (img as HTMLCanvasElement).toDataURL();
                    setter((prev) => {
                        if (prev === result) {
                            abortLoading?.();
                        }
                        return result;
                    });
                },
                {
                    canvas: true,
                    orientation: orientation,
                }
            );
        };
    })(),
    500
);
