import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';
import { Geometry, GeometryType, MarkerIcon } from '@/common/types';
import { getIcon, getSize } from '@/common/utils/geometries/helpers';
import { styleObservable } from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useState } from 'react';

import { ReactComponent as CircleMarkerIcon } from '@/assets/icons/stylepanel/circle-marker.svg';
import { ReactComponent as DiamondMarkerIcon } from '@/assets/icons/stylepanel/diamond-marker.svg';
import { ReactComponent as SquareMarkerIcon } from '@/assets/icons/stylepanel/square-marker.svg';
import { ReactComponent as StarMarkerIcon } from '@/assets/icons/stylepanel/star-marker.svg';
import { ReactComponent as TriangleMarkerIcon } from '@/assets/icons/stylepanel/triangle-marker.svg';
import { ReactComponent as TriangleReversedMarkerIcon } from '@/assets/icons/stylepanel/triangle-reversed-marker.svg';

import { ReactComponent as CircleMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/circle-marker.svg';
import { ReactComponent as DiamondMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/diamond-marker.svg';
import { ReactComponent as SquareMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/square-marker.svg';
import { ReactComponent as StarMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/star-marker.svg';
import { ReactComponent as TriangleMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/triangle-marker.svg';
import { ReactComponent as TriangleReversedMarkerIcon2 } from '@/assets/icons/stylepanel/dropdown-markers/triangle-reversed-marker.svg';

export const ShapePicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const { size: markerSize, markerIcon: countMarkerIcon } = styleObservable.value;
    const geometries = geometriesObservable.value;

    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);

    const setCountMarkerIcon = (newIcon: MarkerIcon): void => {
        styleObservable.next({
            ...styleObservable.value,
            markerIcon: newIcon,
        });
    };

    const getCountMarkerIcon = (icon: MarkerIcon) => {
        const icons: Record<MarkerIcon, Svg> = {
            [MarkerIcon.CIRCLE]: CircleMarkerIcon,
            [MarkerIcon.DIAMOND]: DiamondMarkerIcon,
            [MarkerIcon.SQUARE]: SquareMarkerIcon,
            [MarkerIcon.STAR]: StarMarkerIcon,
            [MarkerIcon.TRIANGLE]: TriangleMarkerIcon,
            [MarkerIcon.TRIANGLE_REVERSED]: TriangleReversedMarkerIcon,
        };

        return icons[icon];
    };

    const getCountMarkerIcon2 = (icon: MarkerIcon) => {
        const icons: Record<MarkerIcon, Svg> = {
            [MarkerIcon.CIRCLE]: CircleMarkerIcon2,
            [MarkerIcon.DIAMOND]: DiamondMarkerIcon2,
            [MarkerIcon.SQUARE]: SquareMarkerIcon2,
            [MarkerIcon.STAR]: StarMarkerIcon2,
            [MarkerIcon.TRIANGLE]: TriangleMarkerIcon2,
            [MarkerIcon.TRIANGLE_REVERSED]: TriangleReversedMarkerIcon2,
        };

        return icons[icon];
    };

    const getCountMarkerIconViewBox = (icon: MarkerIcon) => {
        const icons: Record<MarkerIcon, string> = {
            [MarkerIcon.CIRCLE]: '0 0 17 17',
            [MarkerIcon.DIAMOND]: '0 0 22 23',
            [MarkerIcon.SQUARE]: '0 0 17 17',
            [MarkerIcon.STAR]: '0 0 23 21',
            [MarkerIcon.TRIANGLE]: '0 0 20 17',
            [MarkerIcon.TRIANGLE_REVERSED]: '0 0 20 17',
        };

        return icons[icon];
    };

    const getCountMarkerIcon2ViewBox = (icon: MarkerIcon) => {
        const icons: Record<MarkerIcon, string> = {
            [MarkerIcon.CIRCLE]: '0 0 24 24',
            [MarkerIcon.DIAMOND]: '0 0 29 29',
            [MarkerIcon.SQUARE]: '0 0 24 24',
            [MarkerIcon.STAR]: '0 0 33 32',
            [MarkerIcon.TRIANGLE]: '0 0 28 24',
            [MarkerIcon.TRIANGLE_REVERSED]: '0 0 28 24',
        };

        return icons[icon];
    };

    const updateMarkerIcon = (v: MarkerIcon) => {
        setCountMarkerIcon(v);

        const needsUpdate = (g: Geometry): boolean =>
            selectedGeometryIds.includes(g.uuid) && g.type === GeometryType.COUNT;

        if (geometries === null) {
            return;
        }

        geometriesObservable.next(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style: {
                                  ...g.style,
                                  markerIcon: v,
                                  icon: getIcon(
                                      getSize(g.style.size || markerSize),
                                      v,
                                      g.style.color
                                  ),
                              },
                          } // update marker icon
                        : g // do nothing
            )
        );
    };

    return (
        <Box {...props}>
            <IconButton
                onClick={(event) => setAnchor(event.currentTarget)}
                size="small"
                sx={{
                    height: '37px',
                    width: '37px',
                }}
            >
                <SvgIcon
                    sx={{
                        height: '20px',
                    }}
                    viewBox={getCountMarkerIconViewBox(countMarkerIcon)}
                >
                    {(() => {
                        const Icon = getCountMarkerIcon(countMarkerIcon);

                        return <Icon />;
                    })()}
                </SvgIcon>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    PaperProps={{
                        sx: {
                            padding: '16px',
                            width: '132px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Shapes
                    </Typography>
                    <Box>
                        {Object.values(MarkerIcon).map((item) => {
                            const Icon = getCountMarkerIcon2(item);

                            return (
                                <IconButton
                                    key={item}
                                    onClick={() => {
                                        updateMarkerIcon(item);

                                        setAnchor(null);
                                    }}
                                >
                                    <SvgIcon viewBox={getCountMarkerIcon2ViewBox(item)}>
                                        <Icon />
                                    </SvgIcon>
                                </IconButton>
                            );
                        })}
                    </Box>
                </Popover>
            </Mode>
        </Box>
    );
};
