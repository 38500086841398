import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';
import ConfettiImage from '@/assets/images/confetti.png';

export const DialogContent = styledByMui('div')((options) => ({
    display: 'grid',

    [options.theme.breakpoints.up('md')]: {
        gridTemplateColumns: '340px 1fr',
    },
}));

export const Heading = styledByMui(Typography)({
    color: '#1B2235',
    marginBottom: 24,
});

export const Body = styledByMui(Typography)({
    marginBottom: 40,
});

export const LeftContent = styledByMui('div')((options) => ({
    background: `url(${ConfettiImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'none',

    [options.theme.breakpoints.up('md')]: {
        display: 'block',
    },
}));

export const RightContent = styledByMui('div')((options) => ({
    padding: '60px 36px 112px',

    [options.theme.breakpoints.up('md')]: {
        padding: '88px 48px 112px',
    },
}));
