import { AssemblyLibraryCategories, LibraryCategory } from '@/components/AssemblyPanel/utils/types';

export const getVisibleLibraryCategories = (options?: { isEstimate?: boolean }) => {
    return AssemblyLibraryCategories.filter((category) => {
        if (options?.isEstimate) {
            return category !== LibraryCategory.CurrentProject;
        } else {
            return true;
        }
    });
};
