import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const Title = styledByMui('div')({
    color: '#1B2235',
});

export const Text = styledByMui(Typography)({
    color: '#65667A',
});
