/*
 * Provides a wrapper around the onClick method which will set disabled to true
 * the moment the button is clicked.
 */

import { useEffect, useState, useCallback } from 'react';

type onClickType = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

export const useDisableAfterClick = (onClick?: onClickType): [onClickType, boolean] => {
    const [disabled, setDisabled] = useState(false);

    // Only allow pressing it again after 2 seconds, which should be
    // enough for any requests to go through and will definitely be
    // enough to mitigate missclicks.
    useEffect(() => {
        let disableTimeout: ReturnType<typeof setTimeout>;
        if (disabled) {
            disableTimeout = setTimeout(() => setDisabled(false), 2000);
        }

        return (): void => {
            clearTimeout(disableTimeout);
        };
    }, [disabled]);

    const wrappedOnClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>): void => {
            if (onClick) {
                setDisabled(true);
                onClick(event);
            }
        },
        [onClick]
    );

    return [wrappedOnClick, disabled];
};
