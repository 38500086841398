import ReactGA, { EventArgs } from 'react-ga';

import { Env } from '../env';

const TRACKING_ID = Env.googleAnalyticsTrackingKey;

const init = () => {
    // Enable debug mode on the local development environment
    const isDev = !process.env.TIER || process.env.TIER === 'development';
    ReactGA.initialize(TRACKING_ID, { debug: isDev });
};

const sendEvent = (payload: EventArgs) => {
    ReactGA.event(payload);
};

const sendPageView = (path: string) => {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
};

export default {
    init,
    sendEvent,
    sendPageView,
};
