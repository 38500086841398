import React, { FC, RefObject } from 'react';
import { createPortal } from 'react-dom';

interface RefPortalProps {
    mountRef: RefObject<HTMLDivElement>;
}
export const RefPortal: FC<RefPortalProps> = ({ mountRef, children }) => {
    if (!mountRef.current) return <></>;
    return createPortal(children, mountRef.current);
};
