import { HeaderRenderer, useIsColumnSelected } from 'leyden-react';
import React from 'react';

import { HeaderContainer, HeaderText } from './styled';

export const ColumnHeader: HeaderRenderer = ({ position }) => {
    const focused = useIsColumnSelected(position);

    const headerText = (): string => {
        switch (position) {
            case 0:
                return 'Name';
            case 1:
                return 'Quantity';
            case 2:
                return 'U/M';
            case 3:
                return 'Rate';
            case 4:
                return 'Total';
            default:
                return '';
        }
    };

    return (
        <HeaderContainer position={position} focused={focused}>
            <HeaderText focused={focused}>{headerText()}</HeaderText>
        </HeaderContainer>
    );
};
