import { DuplicateProjectDialogTitle } from './styled';
import { GradientToast } from '@/components/notifications/GradientToast';
import { BasicToastCopy } from '@/components/notifications/GradientToast/BasicToastCopy';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { DuplicateProjectResult, duplicateProjectMutation } from '@/mutations/duplicateProject';
import { useCreateEvent } from '@/mutations/event';
import { EventTypeName } from '@/queries/eventTypes';
import { ProjectRecord } from '@/queries/projects';
import { Mode } from '@/theme/Mode';
import { useMutation as useApolloMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import to from 'await-to-js';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

type DuplicateProjectModalProps = DialogProps & {
    open: boolean;
    close: () => void;
    project: ProjectRecord;
};

export const DuplicateProjectModal: FC<DuplicateProjectModalProps> = ({
    close,
    project,
    ...props
}) => {
    const { addNotification } = useNotifications();
    const history = useHistory();
    const {
        data: { user },
    } = useUser();
    const [duplicateProject, { loading }] =
        useApolloMutation<DuplicateProjectResult>(duplicateProjectMutation);
    const [, createEvent] = useCreateEvent();

    const onCloneButtonClick = async () => {
        const [error] = await to(
            Promise.all([
                duplicateProject({
                    variables: {
                        UUID: project.uuid,
                    },
                }),
                createEvent({
                    eventType: EventTypeName.CreateProject,
                    message: `Project "${project.name ?? ''}" has been duplicated.`,
                    ownerId: Number(user.id),
                    projectId: Number(project.id),
                }),
            ])
        );

        if (error) {
            addNotification(
                {
                    title: '',
                    v2Content: (
                        <GradientToast>
                            <BasicToastCopy css={'padding-top: 10px;'}>
                                Error cloning “{project.name}”.
                            </BasicToastCopy>
                        </GradientToast>
                    ),
                },
                'error'
            );
        } else {
            history.push('/projects');

            // # TODO => Add snackbar
            addNotification(
                {
                    title: '',
                    v2Content: (
                        <GradientToast>
                            <BasicToastCopy css={'padding-top: 10px;'}>
                                The project “{project.name}” has been duplicated.
                            </BasicToastCopy>
                        </GradientToast>
                    ),
                },
                'success'
            );
        }

        close();
    };

    return (
        <Mode variant="light">
            <Dialog maxWidth="xs" {...props}>
                <DuplicateProjectDialogTitle>Duplicate Project</DuplicateProjectDialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to duplicate this project?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        fullWidth
                        onClick={close}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        fullWidth
                        onClick={onCloneButtonClick}
                        variant="contained"
                        color="primary"
                    >
                        Duplicate
                    </Button>
                </DialogActions>
            </Dialog>
        </Mode>
    );
};
