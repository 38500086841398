import React, { FC, useEffect, useState } from 'react';

import { FilterItem, FilterItemType } from './FilterItem';
import { NeedsAttention, ProjectsFilter } from './styled';

import { DatabaseProjectStatus } from '@/common/types';
import { useProjects } from '@/components/AdminDashboard/Projects/context';
import { filterProjects } from '@/components/AdminDashboard/Projects/sort';

const COPY = {
    needsAttention: 'Needs attention:',
};

export const AttentionFilter: FC = () => {
    const { projects, tabType, sortingMethod } = useProjects();
    const [selectedItem, setSelectedItem] = useState<FilterItemType>();

    useEffect(() => {
        deselect();
    }, [tabType]);

    // In addition to normal filter against project category, filter out cancelled projects
    const filteredProjects = [
        ...filterProjects(projects, tabType, sortingMethod, [], { filterCancelledProjects: true }),
    ];

    const deselect = (): void => {
        setSelectedItem(undefined);
    };

    const unassignedProjects = filteredProjects.filter((p) => p.projectUsers?.nodes.length === 0);
    const projectsWithUnreturnedMessages = filteredProjects.filter((p) => {
        const orderedMessages = [
            ...p.events.nodes.filter(
                (n) => n.eventTypeName === 'post_message' || n.eventTypeName === 'edit_message'
            ),
        ].sort((n1, n2) => new Date(n2.created).getTime() - new Date(n1.created).getTime());
        if (orderedMessages.length === 0) {
            return;
        }
        return orderedMessages[0]?.owner?.team?.id === p.team?.id;
    });
    const projectsWaitingForReview = filteredProjects.filter(
        (p) => p.status === DatabaseProjectStatus.ESTIMATED
    );

    const needsToShowUp =
        [...unassignedProjects, ...projectsWithUnreturnedMessages, ...projectsWaitingForReview]
            .length > 0;

    return needsToShowUp ? (
        <ProjectsFilter>
            <NeedsAttention>{COPY.needsAttention}</NeedsAttention>
            {unassignedProjects.length > 0 && (
                <FilterItem
                    projects={unassignedProjects}
                    type={FilterItemType.Unassigned}
                    selected={selectedItem === FilterItemType.Unassigned}
                    setSelected={(): void => setSelectedItem(FilterItemType.Unassigned)}
                    deselect={deselect}
                />
            )}
            {projectsWithUnreturnedMessages.length > 0 && (
                <FilterItem
                    projects={projectsWithUnreturnedMessages}
                    type={FilterItemType.Unreturned}
                    selected={selectedItem === FilterItemType.Unreturned}
                    setSelected={(): void => setSelectedItem(FilterItemType.Unreturned)}
                    deselect={deselect}
                />
            )}
            {projectsWaitingForReview.length > 0 && (
                <FilterItem
                    projects={projectsWaitingForReview}
                    type={FilterItemType.NeedingReview}
                    selected={selectedItem === FilterItemType.NeedingReview}
                    setSelected={(): void => setSelectedItem(FilterItemType.NeedingReview)}
                    deselect={deselect}
                />
            )}
        </ProjectsFilter>
    ) : (
        <></>
    );
};
