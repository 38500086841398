import React, { FC } from 'react';

import { Svg } from '@/common/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';

import { IButtonVariant, CSSProperties, ButtonWrapper } from './styled';
export interface IButtonProps {
    type?: 'button' | 'submit' | 'reset';
    position?: 'append' | 'prepend';
    variant?: keyof IButtonVariant;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    icon?: Svg;
    children: React.ReactNode;
    customCss?: CSSProperties;
}

export const Button: FC<IButtonProps> = ({
    variant = 'primary',
    position = 'prepend',
    children,
    icon,
    ...rest
}) => (
    <ButtonWrapper variant={variant} {...rest}>
        {position === 'prepend' && (icon ? <SvgIcon src={icon} /> : null)}
        {children}
        {position === 'append' && (icon ? <SvgIcon src={icon} /> : null)}
    </ButtonWrapper>
);
