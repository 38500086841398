import { EditablePolygon } from './EditablePolygon';
import { toLeaflet } from '@/common/convert/geometry';
import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import { ContextMenuDispatcherType, Geometry, GeometryType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useActiveVertices } from '@/components/takeoff/hooks/useActiveVertices';
import {
    booleanToolObservable,
    currentlyAddedCoordinatesObservable,
    subscribeToBoundary,
    subscribeToIsDrawingRectangleObservable,
} from '@/components/takeoff/observables/helpers';
import { colorPrimary, colorPrimaryLighter } from '@/variables';
import { DomEvent, LatLngBounds, LeafletMouseEvent } from 'leaflet';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FeatureGroup, Polygon } from 'react-leaflet';

export interface AreaToolProps extends TakeoffComponentProps {
    geometry: Geometry<GeometryType.AREA>;
    selected: boolean;
    editable: boolean;
}

export const AreaTool: FunctionComponent<AreaToolProps> = ({
    geometry,
    editable,
    selected,
    useTakeoff,
}: AreaToolProps) => {
    const { select, removeBoundary, addBoundary, edit, setContextMenuPayload } = useTakeoff();
    const activeVertices = useActiveVertices();

    const onContextMenu = (e: LeafletMouseEvent): void => {
        // So we basically do like a ray-traced collision detection in the point in which the
        // right-click event was triggered.
        const clickedElements = document.elementsFromPoint(e.originalEvent.x, e.originalEvent.y);
        let isEdge = false;
        // If the ray went through two paths (the first one being a geometry and the second one being the blueish
        // outline that's displayed when a geometry is selected) it means we hit the edge.
        if (
            clickedElements[0] instanceof SVGPathElement &&
            clickedElements[1] instanceof SVGPathElement
        ) {
            isEdge = true;
        }
        if (!editable && selected) {
            setContextMenuPayload({
                coordinates: e.latlng,
                isEdge,
                dispatcher: ContextMenuDispatcherType.AREA,
            });
        }
        DomEvent.stopPropagation(e);
    };

    /**
     * This component uses the generic EditablePolygon to implement the
     * area drawing tool. The logic taking place in this component is mostly
     * related to actions that take place when clicking on the geometry.
     **/
    const [selectionRef, setSelectionRef] = useState<Polygon>();
    const [selectManyGeos, setSelectManyGeos] = useState<boolean>(false);

    const [boundary, setBoundary] = useState<LatLngBounds | null>();

    useEffect(() => {
        const subscription = subscribeToBoundary(setBoundary);
        return (): void => subscription.unsubscribe();
    }, []);

    const [isDrawingRectangle, setIsDrawingRectangle] = useState<boolean>(false);

    useEffect(() => {
        const subscription = subscribeToIsDrawingRectangleObservable(setIsDrawingRectangle);
        return (): void => subscription.unsubscribe();
    }, []);

    const currentlyAddedCoordinates = currentlyAddedCoordinatesObservable.value;

    useEffect(() => {
        selectionRef?.leafletElement.bringToBack();
    }, [selectionRef]);

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            setSelectManyGeos(true);
        }
    };
    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            setSelectManyGeos(false);
        }
    };

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    const hoverable = !boundary;
    const leafletGeometry = toLeaflet(geometry);
    // Don't handle events when using boolean tools
    const shouldHandleEvents = !booleanToolObservable.value && !currentlyAddedCoordinates;

    const showSelection =
        selected &&
        !editable &&
        (!currentlyAddedCoordinates || (currentlyAddedCoordinates && booleanToolObservable.value));

    const mouseEvents = {
        onclick: (): void => select(geometry, selectManyGeos),
        ondblclick: (): void => edit(geometry.uuid),
        onmouseover: (): void => {
            !selected && !editable && hoverable && addBoundary(geometry.uuid);
        },
        onmouseout: (): void => {
            !selected && !editable && hoverable && removeBoundary();
        },
        oncontextmenu: onContextMenu,
    };

    return (
        <FeatureGroup {...(shouldHandleEvents && mouseEvents)} bubblingMouseEvents={false}>
            {showSelection && (
                <Polygon
                    positions={leafletGeometry.getLatLngs()}
                    ref={(newRef: Polygon): void => setSelectionRef(newRef)}
                    weight={5 + (geometry.style?.weight ?? 5)}
                    fill={false}
                    color={colorPrimaryLighter}
                    interactive
                />
            )}
            <EditablePolygon
                color={geometry.style.color || colorPrimary}
                geometry={geometry}
                editable={editable}
                isDrawingRectangle={isDrawingRectangle}
                activeVertices={activeVertices}
                useTakeoff={useTakeoff}
            />
        </FeatureGroup>
    );
};
