import { useEffect } from 'react';

import { StorageSetter } from '@/common/storage';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { getNodes } from '@/common/utils/helpers';
import { PaginationDirection, genPaginationArgs } from '@/common/utils/pagination';
import { ManuallySelectedLocalization, useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { ICountiesOrderBy, ICountiesQuery, ICountyFragment, useCountiesQuery } from '@/graphql';

interface UseCountiesArguments {
    localizationStateFp?: string;
}

interface UseCountiesPayload {
    counties: ICountyFragment[];
    localizationCounty: ManuallySelectedLocalization<ICountyFragment>;
    setLocalizationCounty: StorageSetter<ManuallySelectedLocalization<ICountyFragment>>;
}

const MAX_NUMBER_OF_COUNTIES = 251;

export const useCounties = ({ localizationStateFp }: UseCountiesArguments): UseCountiesPayload => {
    const estimationProject = useEstimationProject();
    const { localizationCounty, setLocalizationCounty } = useAssemblyPanel();

    const countiesQuery = useCountiesQuery({
        variables: {
            input: {
                condition: {
                    // This should be covered by the 'skip' property
                    statefp: localizationStateFp ?? '',
                },
                orderBy: ICountiesOrderBy.NameAsc,
                // TODO - Rework this
                // Not ideal, should fetch in batches instead of fetching all and capping at 250
                ...genPaginationArgs(PaginationDirection.Next, MAX_NUMBER_OF_COUNTIES)?.variables
                    .input,
            },
        },
        skip: !localizationStateFp,
    });

    const counties = getNodes<ICountiesQuery, ICountyFragment>(countiesQuery, 'counties');

    // On counties load, set first county as default option
    useEffect(() => {
        if (!counties.length) {
            return;
        }

        if (!localizationCounty.localization) {
            setLocalizationCounty({
                localization: counties[0],
                isManuallySet: localizationCounty.isManuallySet,
            });
            return;
        }

        const isStoredCountyWithinCurrentState = counties.find(
            (county) => county.id === localizationCounty.localization?.id
        );

        if (isStoredCountyWithinCurrentState) {
            return;
        }

        setLocalizationCounty({
            localization: counties[0],
            isManuallySet: localizationCounty.isManuallySet,
        });
    }, [counties, localizationCounty, setLocalizationCounty]);

    useEffect(() => {
        // If there is no current localization state, update to localization
        if (
            !localizationCounty.isManuallySet &&
            estimationProject.projectInfo.projectLocation?.county
        ) {
            const updatedCounty = counties.find(
                (county) =>
                    county.namelsad === estimationProject.projectInfo.projectLocation?.county
            );

            // Google maps and our states are not necessarily a 1:1 match.
            // If there isn't a match, don't update
            if (updatedCounty) {
                setLocalizationCounty({
                    localization: updatedCounty,
                    isManuallySet: true,
                });
            }
        }
    }, [
        estimationProject.projectInfo.projectLocation?.county,
        localizationCounty,
        setLocalizationCounty,
        counties,
    ]);

    return {
        counties,
        localizationCounty,
        setLocalizationCounty,
    };
};
