import styled from 'styled-components';

const grid = `
" search " auto
" .      " 0.5rem
" infoLabel  " auto
" table  " auto
" loader " auto
" .      " 1rem
/ auto   `;

export const UserManagerContainer = styled.div`
    display: grid;
    grid-template: ${grid};
    overflow-x: auto;
`;

export const SearchContainer = styled.div`
    grid-area: search;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    height: 2rem;
    border-radius: 0.5rem;
`;

export const SearchInput = styled.input`
    flex-grow: 2;
    background: transparent;
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.03rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 0 0 0.75rem;
    padding: 0;
    color: #f6f8f8;

    &::placeholder {
        color: #f6f8f8;
        opacity: 0.8;
    }
`;

export const InfoLabel = styled.p`
    grid-area: infoLabel;
    font-size: 13px;
`;
