import { IEstimateType, IQuoteExpiration } from '@/graphql';
import { FormIds } from './enums';
import { NameValue, QuoteFormDictionary } from './interfaces';

export const COPY = {
    subscriberMarketplace:
        'This project was requested by an active subscriber. Please contact the client to confirm the scope for this project before filling out the form below. The project will start right after the customer approves the quote.',
    subscriberNoMarketplace:
        'This project was requested by a new user. Please call the builder to discuss quote before quoting.',
    trial: 'This project was requested by a trial user. Please allow 2-3 business days for Sales to follow up with the customer before quoting.',

    header: 'Glad to have you on board ',

    readBuilderProfile: 'I have read the Customer’s Builder Profile in the Profile',
    discussedScope:
        'I have discussed scope and project details with customer prior to creating this quote.',

    estimateType: 'Estimate Type',
    estimateTypePlaceholder: 'Select estimate type',

    takeoffsOnly: 'Takeoffs Only',
    materialPricing: 'Material Pricing',
    laborPricing: 'Labor Pricing',
    billOfMaterials: 'Bill of Materials',
    fullEstimate: 'Full Estimate',

    scopeOfEstimating: 'Scope of Estimating work to be completed:',
    scopeOfEsimatingPlaceholder: `* Trades
* Specific inclusions
* Specific exclusions 
* Custom formatting
* Bid type (competitive, already won, etc.)
* Additional notes`,

    quoteExpirationLabel: 'Customer Quote Expiration:',
    quoteExpirationPlaceholder: 'Select Expiration',

    expires12h: 'Quote Expires in 12 hours',
    expires18h: 'Quote Expires in 18 hours',
    expires24h: 'Quote Expires in 24 hours',

    startDateLabel: 'When will you start this project?',
    endDateLabel: 'When will you complete this project?',
    selectDate: 'Select date',

    hoursEstimatedLabel: 'Hours estimated for this project',
    hourlyRateLabel: 'Hourly rate for this project',

    totalBillings: 'Total Billings for this Project',
    finalPrice: 'Final Price Quote to Client',

    cancel: 'Cancel',

    clickToSign: 'Click to sign',
    sendQuote: 'Send Quote',
    gotIt: 'Got it',

    quoteSigned: 'Signed by',

    button: 'Got it',
    errorMessage: 'Please fill in all the fields',
    required: 'This field is required',

    quote12H: 'Quote Expires in 12 hours',
    quote18H: 'Quote Expires in 18 hours',
    quote24H: 'Quote Expires in 24 hours',
};

const EnumToList = (enumToConvert: typeof IQuoteExpiration | typeof IEstimateType): NameValue[] =>
    Object.entries(enumToConvert).map(([_, value]: [string, string]) => {
        const tempValue = value.split('_').join(' ').toLowerCase();
        return {
            name: value,
            value: tempValue.charAt(0).toUpperCase() + tempValue.slice(1),
        };
    });

const QuoteExpirationList: NameValue[] = [
    { name: IQuoteExpiration.TwelveHours, value: COPY.quote12H },
    { name: IQuoteExpiration.EighteenHours, value: COPY.quote18H },
    { name: IQuoteExpiration.TwentyfourHours, value: COPY.quote24H },
];

export const defaultFormFields: QuoteFormDictionary = {
    [FormIds.readBuilderProfile]: {
        id: FormIds.readBuilderProfile,
        value: false,
        label: COPY.readBuilderProfile,
    },
    [FormIds.discussedScope]: {
        id: FormIds.discussedScope,
        value: false,
        label: COPY.discussedScope,
    },
    [FormIds.estimateType]: {
        id: FormIds.estimateType,
        placeholder: COPY.estimateTypePlaceholder,
        value: [],
        label: COPY.estimateType,
        items: EnumToList(IEstimateType),
    },
    [FormIds.scopeOfWork]: {
        id: FormIds.scopeOfWork,
        value: '',
        label: COPY.scopeOfEstimating,
        placeholder: COPY.scopeOfEsimatingPlaceholder,
    },
    [FormIds.startBy]: {
        id: FormIds.startBy,
        label: COPY.startDateLabel,
        placeholder: COPY.selectDate,
        variant: 'outlined',
        value: new Date(),
    },
    [FormIds.finishBy]: {
        id: FormIds.finishBy,
        label: COPY.endDateLabel,
        placeholder: COPY.selectDate,
        variant: 'outlined',
        value: new Date(),
    },
    [FormIds.quoteExpiration]: {
        id: FormIds.quoteExpiration,
        placeholder: COPY.quoteExpirationPlaceholder,
        value: '',
        label: COPY.quoteExpirationLabel,
        items: QuoteExpirationList,
    },
    [FormIds.hoursEstimated]: {
        id: FormIds.hoursEstimated,
        value: 0,
        label: COPY.hoursEstimatedLabel,
    },
    [FormIds.hourlyRate]: {
        id: FormIds.hourlyRate,
        value: 0,
        label: COPY.hourlyRateLabel,
    },
};

export const currency = '$';

export const dateTimeFormat = 'yyyy-MM-dd';
export const dateTimeMask = '____-__-__';
