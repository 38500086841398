import { IUserFragment, IUserLightFragment } from '@/graphql';

/* Payloads
 *********************************************************/
export type CloseModalPayload = null;

export interface DeletePayload {
    userID: string;
}

export interface ImpersonatePayload {
    userAuthID: string;
}

export interface OpenModalPayload {
    user: IUserLightFragment;
}

export interface SearchPayload {
    term: string;
}

/* Actions
 *********************************************************/
export enum Action {
    CloseModal,
    Delete,
    Impersonate,
    OpenModal,
    Search,
}

export type ActionPayloadMap = {
    [Action.CloseModal]: CloseModalPayload;
    [Action.Delete]: DeletePayload;
    [Action.Impersonate]: ImpersonatePayload;
    [Action.OpenModal]: OpenModalPayload;
    [Action.Search]: SearchPayload;
};

export interface ActionBlock<T extends Action> {
    payload: ActionPayloadMap[T];
    type: T;
}

export interface ActionResult<T extends Action> {
    action: ActionBlock<T>;
    error: string | null;
}

/* Action Functions
 *********************************************************/
export type Committer = <T extends Action>(action: ActionBlock<T>) => Promise<ActionResult<T>>;

export type Executor<T extends Action> = (payload: ActionPayloadMap[T]) => Promise<void> | void;

/* State
 *********************************************************/
export const loadingSelectedUser = Symbol();

export type SelectedUser = null | typeof loadingSelectedUser | IUserFragment;

export interface State {
    commit: Committer;
    loading: boolean;
    searchTerm: string;
    selectedUser: SelectedUser;
    users: IUserLightFragment[];
}

export const selectedUserIsLoaded = (user: SelectedUser): user is IUserFragment =>
    user !== null && user !== loadingSelectedUser;
