import { ElementRates, ElementRateStrings } from './types';
import { formatters, toDollarAmount, toCentsAmount } from '@/common/currency';

const formatter = formatters.usd();

/**
 * Return true if two sets of discrete rates are identical.
 */
export const ratesAreEqual = (a: ElementRates, b: ElementRates): boolean =>
    a.laborRate === b.laborRate &&
    a.materialRate === b.materialRate &&
    a.productionRate === b.productionRate;

/**
 * Calculate a set of discrete rates needed to create an aggregate cost per unit.
 */
export const ratesFromCostPerUnit = (costPerUnit: number): ElementRates => {
    return {
        productionRate: 1,
        materialRate: costPerUnit,
        laborRate: 0,
        equipmentRate: 0,
    };
};

/**
 * Return true if any of the rate strings in the set are an empty string.
 */
export const rateStringsContainsEmpty = (rates: ElementRateStrings): boolean => {
    return (
        rates.costPerUnit === '' ||
        rates.laborRate === '' ||
        rates.materialRate === '' ||
        rates.productionRate === ''
    );
};

/**
 * Calculate an aggregate cost per unit from a set of discrete rates.
 */
export const ratesToCostPerUnit = ({
    productionRate,
    laborRate,
    materialRate,
}: ElementRates): number => {
    const laborRateUSD = toDollarAmount(laborRate);
    const materialRateUSD = toDollarAmount(materialRate);

    let totalCost = materialRateUSD;
    if (productionRate !== 0) {
        totalCost += laborRateUSD / productionRate;
    }
    return totalCost;
};

// Remove commas for things like '1,204' and dollar sign for things like '$20'
export const unFormatNumber = (value: string) => value.replaceAll('$', '').replaceAll(',', '');

export const formatNumber = (value: number) => formatter.format(value);

export const isValidUnFormattedNumber = (value: string) => !isNaN(Number(unFormatNumber(value)));

/**
 * Generate a set of user-readable rate string from a set of rates.
 */
export const generateRateStrings = (rates: ElementRates): ElementRateStrings => {
    return {
        costPerUnit: formatNumber(ratesToCostPerUnit(rates)),
        laborRate: formatNumber(toDollarAmount(rates.laborRate)),
        materialRate: formatNumber(toDollarAmount(rates.materialRate)),
        equipmentRate: formatNumber(toDollarAmount(rates.equipmentRate)),
        productionRate: rates.productionRate.toFixed(2),
    };
};

/**
 * Generate a set of rates from their user-readable strings.
 */
export const parseRateValues = (rates: Omit<ElementRateStrings, 'costPerUnit'>): ElementRates => {
    const { productionRate, laborRate, materialRate, equipmentRate } = rates;

    return {
        productionRate: productionRate === '' ? 0 : parseFloat(productionRate),
        laborRate: laborRate === '' ? 0 : Math.round(toCentsAmount(unFormatNumber(laborRate))),
        materialRate:
            materialRate === '' ? 0 : Math.round(toCentsAmount(unFormatNumber(materialRate))),
        equipmentRate:
            equipmentRate === '' ? 0 : Math.round(toCentsAmount(unFormatNumber(equipmentRate))),
    };
};
