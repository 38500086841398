import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';
import { getIcon, getSize } from '@/common/utils/geometries/helpers';
import { Geometry, GeometryType, Style } from '@/common/types';
import {
    defaultStyle,
    styleObservable,
    subscribeToStyle,
} from '@/components/takeoff/observables/helpers';
import { geometryColors } from '@/common/utils/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as CircleFillIcon } from '@/assets/icons/32/circle-fill.svg';

export const ColorPicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const [style, setStyle] = useState<Style>(defaultStyle);

    const { color: drawingColor, size: markerSize, markerIcon: countMarkerIcon } = style;

    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);
    const geometries = geometriesObservable.value;

    useEffect(() => {
        const subscription = subscribeToStyle(setStyle);

        return (): void => subscription.unsubscribe();
    }, []);

    const setDrawingColor = (newColor: string) => {
        styleObservable.next({
            ...styleObservable.value,
            color: newColor,
        });
    };

    const updateDrawingColor = (color: string) => {
        setDrawingColor(color);

        const needsUpdate = (g: Geometry): boolean => selectedGeometryIds.includes(g.uuid);

        if (geometries === null) {
            return;
        }

        geometriesObservable.next(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style:
                                  g.type === GeometryType.COUNT
                                      ? {
                                            ...g.style,
                                            color,
                                            icon: getIcon(
                                                getSize(g.style.size || markerSize),
                                                g.style.markerIcon || countMarkerIcon,
                                                color
                                            ),
                                        }
                                      : {
                                            ...g.style,
                                            color,
                                        },
                          } // update color
                        : g // do nothing
            )
        );
    };

    return (
        <Box {...props}>
            <IconButton onClick={(event) => setAnchor(event.currentTarget)} size="small">
                <SvgIcon sx={{}} viewBox="0 0 16 16">
                    <CircleFillIcon color={drawingColor} />
                </SvgIcon>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    elevation={4}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    BackdropProps={{
                        invisible: true,
                    }}
                    PaperProps={{
                        sx: {
                            padding: '16px',
                            width: '190px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Colors
                    </Typography>
                    <Box>
                        {geometryColors.map((color) => {
                            return (
                                <IconButton
                                    key={color}
                                    onClick={() => {
                                        updateDrawingColor(color);
                                        setAnchor(null);
                                    }}
                                    size="small"
                                >
                                    <SvgIcon sx={{}} viewBox="0 0 16 16">
                                        <CircleFillIcon color={color} />
                                    </SvgIcon>
                                </IconButton>
                            );
                        })}
                    </Box>
                </Popover>
            </Mode>
        </Box>
    );
};
