import styled, { css } from 'styled-components';

import { Row } from '@/components/ui/controls';
import { borderCement, colorBackgroundLight } from '@/variables';

export const PanelHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.875rem 1.5rem;
`;

export const PanelHeaderTitle = styled.div`
    font-size: 28px;
    color: ${colorBackgroundLight};
`;

export const PanelDivider = styled.hr`
    border: none;
    border-top: ${borderCement};
    margin: 0;
    background: none;
`;

export const RowItem = styled(Row)`
    margin-bottom: 1rem;
`;

export const PanelContent = styled.div`
    padding: 1.5rem;
`;

const Text = css`
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #3d445c;
`;

export const Label = styled.span<{ flex?: number }>`
    font-weight: bold;
    ${Text}
`;

export const Value = styled.span<{ flex?: number }>`
    font-weight: normal;
    ${Text}
`;
