import {
    geometriesObservable,
    selectedGeometriesObservable,
    selectedIDsObservable,
    toolObservable,
} from './interface';
import { fromFlatten, toFlatten, toPointCount } from '@/common/convert/geometry';
import { geometryIs, pointOrGeometryIsGeometry } from '@/common/typeGuards';
import {
    AreaType,
    BooleanToolType,
    CoordinateData,
    CopyBuffer,
    CopyMetadata,
    Geometry,
    GeometryType,
    LeafletStyleType,
    LineType,
    MarkerIcon,
    MarkerSize,
    PasteMetadata,
    PlanPageGeometry,
    Point,
    SelectedPoint,
    Style,
    ToolTooltipPayload,
    ToolType,
} from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import { getIcon, getSize } from '@/common/utils/geometries/helpers';
import { GeometryPersistencePayload, persistGeometries } from '@/common/utils/geometries/save';
import { createSnapshot, loadSnapshot } from '@/common/utils/geometries/snapshots';
import { parseAreaVertexId } from '@/common/utils/geometries/vertices';
import { getShapeWeight } from '@/common/utils/helpers';
import {
    GeometryOperation,
    addPolygons,
    getBoundingBox,
    splitPolygons,
    subtractPolygons,
    transformGeometries,
} from '@/common/utils/leaflet';
import { colorDrawingTool4 } from '@/variables';
import Flatten from '@flatten-js/core';
import { FeatureGroup, LatLng, LatLngBounds, Marker } from 'leaflet';
import { BehaviorSubject, Subject, Subscription, combineLatest, interval } from 'rxjs';
import { debounce, map, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

const Matrix = Flatten.Matrix;
const Vector = Flatten.Vector;

export const defaultStyle = {
    weight: 2,
    color: colorDrawingTool4,
    lineType: LineType.NORMAL,
    areaType: AreaType.NORMAL,
    markerIcon: MarkerIcon.CIRCLE,
    size: MarkerSize.M,
};

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

// A lot of things shouldn't happen when you're drawing (like geometry selection) - so we have to store it.
export const isAddingCoordinatesObservable = new BehaviorSubject<boolean>(false);

// Stores the geometry that is being edited at the moment
export const editedGeometryObservable = new BehaviorSubject<Geometry | null>(null);

// This value is only defined when a multivertex geometry is being drawn.
// It's used for calculation and determining whether drawing is happening.
export const currentlyAddedCoordinatesObservable = new BehaviorSubject<LatLng | null>(null);

export const cursorPositionObservable = new Subject<LatLng>();

// When the user clicked and is dragging their mouse across the sheet, this observable will expose the initial position
// of the cursor
export const boundaryStartPositionObservable = new Subject<LatLng>();

// when one timer emits, emit the latest values from each timer as an array
export const boundaryObservable = combineLatest([
    boundaryStartPositionObservable,
    cursorPositionObservable,
]).pipe(
    map(([boundaryStartPosition, cursorPosition]) => {
        if (boundaryStartPosition && cursorPosition) {
            const boundaryEndPos = cursorPosition.clone();
            return new FeatureGroup([
                new Marker(boundaryStartPosition),
                new Marker(boundaryEndPos),
            ]).getBounds();
        }
    })
);

// The list of geometries around which a bounding box should be displayed. It happens when hovering or multiselecting
// geometries.
export const boundaryDataObservable = new BehaviorSubject<Array<CoordinateData | Point>>([]);

// Throw a geometry id into this observable and...
export const addBoundaryGeometriesByIdObservable = new Subject<{
    geometryIDs: Set<string>;
    pointIDs: Set<string>;
}>();

// It will automagically update the list of boundary geometries.
addBoundaryGeometriesByIdObservable
    .pipe(
        withLatestFrom(geometriesObservable),
        map(([{ geometryIDs, pointIDs }, geometries]) => {
            if (!geometries) {
                return;
            }
            const geometriesInBoundary = geometries.reduce<Array<CoordinateData | Point>>(
                (acc, geometry) => {
                    if (geometryIDs.has(geometry.uuid)) {
                        return acc.concat(geometry.coordinates);
                    }
                    if (geometryIs(geometry, GeometryType.COUNT)) {
                        const matchingPoints = geometry.coordinates.filter(({ id }) =>
                            pointIDs.has(id)
                        );
                        return acc.concat(matchingPoints);
                    }
                    return acc;
                },
                []
            );
            if (!geometriesInBoundary) return;
            boundaryDataObservable.next(geometriesInBoundary);
        })
    )
    .subscribe((val) => val);

// Used for storing state around boolean operations on polygons. It's gonna be the tool (subtract, add or split),
// the geometry type being used (line or polygon) and the actual boolean geometry.
export const booleanToolObservable = new BehaviorSubject<BooleanToolType | undefined>(undefined);
export const booleanGeometryToolObservable = new BehaviorSubject<ToolType | undefined>(undefined);
export const booleanGeometryObservable = new BehaviorSubject<
    Geometry<GeometryType.AREA | GeometryType.LINEAR> | undefined
>(undefined);

export const cancelBooleanOperationObservable = new Subject<void>();

cancelBooleanOperationObservable
    .pipe(
        map(() => {
            booleanToolObservable.next(undefined);
            booleanGeometryObservable.next(undefined);
            booleanGeometryToolObservable.next(undefined);
            currentlyAddedCoordinatesObservable.next(null);
            isAddingCoordinatesObservable.next(false);
        })
    )
    .subscribe((val) => val);

export const executeBooleanOperationObservable = new Subject<void>();

executeBooleanOperationObservable
    .pipe(
        withLatestFrom(
            booleanToolObservable,
            booleanGeometryObservable,
            selectedGeometriesObservable,
            geometriesObservable
        ),
        map(([_, booleanTool, booleanGeometry, selectedGeometries, geometries]) => {
            if (!geometries) {
                return;
            }
            const booleanOperation = (action: GeometryOperation): void => {
                const geometryIsArea = (
                    geometry: Geometry
                ): geometry is Geometry<GeometryType.AREA> =>
                    geometryIs(geometry, GeometryType.AREA);
                const selectedAreaGeometries = selectedGeometries.geometries.filter(geometryIsArea);
                if (selectedAreaGeometries.length !== 0 && booleanGeometry) {
                    const newPolygons = action(selectedAreaGeometries, booleanGeometry);
                    geometriesObservable.next([
                        ...geometries.filter(
                            (g) =>
                                !selectedGeometries.geometries.map((g) => g.uuid).includes(g.uuid)
                        ),
                        ...newPolygons,
                    ]);
                    selectedGeometriesObservable.next({ geometries: newPolygons, points: [] });
                    cancelBooleanOperationObservable.next();
                }
            };

            const add = (): void => {
                booleanOperation(addPolygons);
            };

            const split = (): void => {
                booleanOperation(splitPolygons);
            };

            const subtract = (): void => {
                booleanOperation(subtractPolygons);
            };

            if (booleanGeometry && toPointCount(booleanGeometry) > 2 && booleanTool) {
                const actionMap = {
                    [BooleanToolType.ADD]: add,
                    [BooleanToolType.SUBTRACT]: subtract,
                    [BooleanToolType.SPLIT]: split,
                };
                actionMap[booleanTool]();
            } else {
                cancelBooleanOperationObservable.next();
            }
        })
    )
    .subscribe((val) => val);

// This holds the current style which can be adjusted using the style panel when drawing or selecting geometries.
// Style for all three geometry types is described using one object - it's just that some geometries only choose to pick
// some values from it, while other values are globally used.
export const styleObservable = new BehaviorSubject<Style>(defaultStyle);

// We wanna reset the style when calibrating or drawing boolean geometries.
combineLatest([toolObservable, booleanToolObservable])
    .pipe(
        map(([tool, booleanTool]) => {
            const resetTools = [
                BooleanToolType.SPLIT,
                BooleanToolType.ADD,
                BooleanToolType.SUBTRACT,
                ToolType.CALIBRATION,
            ];
            if (
                (booleanTool && resetTools.includes(booleanTool)) ||
                (tool && resetTools.includes(tool))
            ) {
                styleObservable.next(defaultStyle);
            }
        })
    )
    .subscribe((val) => val);

// Copy-paste data is stored here.
export const geometryCopyObservable = new Subject<CopyBuffer | undefined>();

export const addVertexToExistingGeometryObservable = new Subject<LatLng | undefined>();

// This used to be a cast, but I can't find any overlap to test meaningfully.
const multilineShapesAreSegments = (
    shapes: Flatten.MultilineShapes
): shapes is Flatten.Segment[] => {
    return true;
};

addVertexToExistingGeometryObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable, geometriesObservable),
        map(([newVertexPosition, selectedGeometries, geometries]) => {
            if (!geometries) {
                return;
            }
            if (!newVertexPosition || selectedGeometries.geometries.length === 0) return;
            const flattenVertexPosition = new Flatten.Point(
                newVertexPosition.lng,
                newVertexPosition.lat
            );
            const flattenVertexPositionCircle = new Flatten.Circle(flattenVertexPosition, 20);

            const selectedGeometry = selectedGeometries.geometries[0];
            const otherGeometries = geometries.filter((g) => g.uuid !== selectedGeometry.uuid);

            const modifiedGeometry = newGeometryHandler<Flatten.Multiline | Flatten.Polygon | null>(
                {
                    [GeometryType.AREA]: (selectedArea) => {
                        const flattenSelectedGeometry = toFlatten(selectedArea);
                        const actualPointsOnPolygonEdge = flattenSelectedGeometry.intersect(
                            flattenVertexPositionCircle
                        );
                        if (actualPointsOnPolygonEdge.length === 0) return null;
                        const edgeToAddVertexTo = flattenSelectedGeometry.findEdgeByPoint(
                            actualPointsOnPolygonEdge[0]
                        );
                        flattenSelectedGeometry.addVertex(
                            actualPointsOnPolygonEdge[0],
                            edgeToAddVertexTo
                        );
                        return flattenSelectedGeometry;
                    },
                    [GeometryType.COUNT]: () => null,
                    [GeometryType.LINEAR]: (selectedLine) => {
                        const flattenSelectedGeometry = toFlatten(selectedLine);
                        const shapes = flattenSelectedGeometry.toShapes();
                        if (!multilineShapesAreSegments(shapes)) return null;
                        const tempPolygon = new Flatten.Polygon(shapes);
                        const actualPointsOnPolygonEdge = tempPolygon.intersect(
                            flattenVertexPositionCircle
                        );
                        if (actualPointsOnPolygonEdge.length === 0) return null;
                        const edgeToAddVertexTo = flattenSelectedGeometry.findEdgeByPoint(
                            actualPointsOnPolygonEdge[0]
                        );
                        if (!edgeToAddVertexTo) return null;
                        flattenSelectedGeometry.addVertex(
                            actualPointsOnPolygonEdge[0],
                            edgeToAddVertexTo
                        );
                        return flattenSelectedGeometry;
                    },
                }
            )(selectedGeometry);
            if (modifiedGeometry !== null) {
                geometriesObservable.next([
                    ...otherGeometries,
                    fromFlatten(modifiedGeometry, selectedGeometry),
                ]);
            }
        })
    )
    .subscribe((val) => val);

// These are used to trigger copy-paste actions. The supplied metadata is used for calculating the position and size
// of the manipulated geometries.
export const geometryCopyMetadataObservable = new Subject<CopyMetadata | undefined>();
export const geometryPasteMetadataObservable = new Subject<PasteMetadata | undefined>();

geometryCopyMetadataObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable),
        map(([copyMetadata, selectedGeometries]) => {
            if (!copyMetadata) return;
            // TODO => copy should not ignore selected points
            geometryCopyObservable.next({
                origin: copyMetadata.origin,
                scale: copyMetadata.scale,
                data: selectedGeometries.geometries,
            });
        })
    )
    .subscribe((val) => val);

geometryPasteMetadataObservable
    .pipe(
        withLatestFrom(geometryCopyObservable, geometriesObservable),
        map(([pasteMetadata, copyBuffer, currentGeometries]) => {
            if (!copyBuffer || !pasteMetadata || !currentGeometries) return;

            const { scale, origin, currentPage } = pasteMetadata;

            const scaleRatio = scale / copyBuffer.scale;

            let finalData = copyBuffer.data.map((g) => ({
                ...g,
                uuid: uuid(),
            }));

            if (scaleRatio !== 1) {
                // Geometries are stored and drawn in relation to an origin in the
                // lower left corner (it's a Cartesian coordinate system), but in
                // order to scale them properly, we have to think about the pixel
                // coordinates of the underlying sheet, which start in the upper
                // left corner. Affine transformations work in a Cartesian
                // coordinate system, so to make it look right on the sheet,
                // we have to:

                // Move the geometries one sheet height below the sheet, to make the
                // origin appear like it's in the upper left corner.
                const nonCartesianBuffer = transformGeometries(
                    copyBuffer.data,
                    1,
                    new Vector(0, -currentPage.heightPx)
                );

                // Scale the geometries and move them back to the sheet
                finalData = transformGeometries(
                    nonCartesianBuffer,
                    scaleRatio,
                    new Vector(0, currentPage.heightPx)
                );
            }

            if (origin) {
                // If pasting was triggered from the context menu, we want the
                // paste to happen at the cursor position

                // Either the coordinates of the right click when copying
                // or the upper left corner of the bounding box of the
                // copied geometries.
                let copyOrigin =
                    copyBuffer.origin || getBoundingBox(copyBuffer.data).getNorthWest();

                if (scale !== copyBuffer.scale) {
                    // If scales don't match, we have to perform the same
                    // transformations that were done on the geometries
                    let transformedCopyOrigin = new Matrix(
                        1,
                        0,
                        0,
                        1,
                        0,
                        -currentPage.heightPx
                    ).transform([copyOrigin.lng, copyOrigin.lat]);

                    transformedCopyOrigin = new Matrix(
                        scaleRatio,
                        0,
                        0,
                        scaleRatio,
                        0,
                        currentPage.heightPx
                    ).transform([transformedCopyOrigin[0], transformedCopyOrigin[1]]);

                    copyOrigin = new LatLng(transformedCopyOrigin[1], transformedCopyOrigin[0]);
                }

                // This vector will move the pasted geometries from their original
                // position to cursor position
                const moveVector = new Vector(
                    origin.lng - copyOrigin.lng,
                    origin.lat - copyOrigin.lat
                );

                finalData = transformGeometries(finalData, 1, moveVector);

                finalData.forEach((g) => (g.uuid = uuid()));
            }

            geometriesObservable.next([...currentGeometries, ...finalData]);
        })
    )
    .subscribe((val) => val);

// Useful when we've had a bunch of geometries selected and now we click just one.
export const deselectAllButOneGeometriesObservable = new Subject<string>();

deselectAllButOneGeometriesObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable),
        map(([idToKeepSelected, selectedGeometries]) => {
            selectedGeometriesObservable.next({
                geometries: selectedGeometries.geometries.filter(
                    (g: Geometry) => g.uuid === idToKeepSelected
                ),
                points: [],
            });
        })
    )
    .subscribe((val) => val);

export const selectGeometryObservable = new Subject<[Geometry | SelectedPoint, boolean]>();

selectGeometryObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable, editedGeometryObservable),
        map(([[toSelect, allowMultiSelect], selectedGeometries, editedGeometry]) => {
            const newSelection = allowMultiSelect
                ? { ...selectedGeometries }
                : { geometries: [], points: [] };
            if (pointOrGeometryIsGeometry(toSelect)) {
                newSelection.geometries.push(toSelect);
            } else {
                newSelection.points.push(toSelect);
            }
            selectedGeometriesObservable.next(newSelection);

            if (
                allowMultiSelect &&
                newSelection.geometries.length + newSelection.points.length > 1
            ) {
                addBoundaryGeometriesByIdObservable.next({
                    geometryIDs: new Set(newSelection.geometries.map(({ uuid }) => uuid)),
                    pointIDs: new Set(newSelection.points.map(({ id }) => id)),
                });
            }
            if (
                pointOrGeometryIsGeometry(toSelect) &&
                editedGeometry &&
                toSelect.uuid !== editedGeometry.uuid
            ) {
                editedGeometryObservable.next(null);
            }
        })
    )
    .subscribe((val) => val);

// Hooked up to backspace and delete
export const deleteSelectedGeometriesObservable = new Subject<void>();

deleteSelectedGeometriesObservable
    .pipe(
        withLatestFrom(geometriesObservable, selectedIDsObservable),
        map(([_, geometries, selection]) => {
            if (!geometries) {
                return;
            }
            geometriesObservable.next(
                geometries
                    .filter((g) => !selection.geometryIDs.has(g.uuid))
                    .map((g) => {
                        if (selection.pointIDs.size === 0 || !geometryIs(g, GeometryType.COUNT)) {
                            return g;
                        }
                        const coordsAfterPointDeletion = g.coordinates.filter(
                            (point) => !selection.pointIDs.has(point.id)
                        );
                        if (coordsAfterPointDeletion.length === g.coordinates.length) {
                            return g;
                        }
                        return { ...g, coordinates: coordsAfterPointDeletion };
                    })
            );
        })
    )
    .subscribe((val) => val);

export const editGeometryByIdObservable = new Subject<string>();

editGeometryByIdObservable
    .pipe(
        withLatestFrom(geometriesObservable),
        map(([idToEdit, geometries]) => {
            if (!geometries) {
                return;
            }
            const geometryToEdit = geometries.find((g: Geometry) => g.uuid === idToEdit);
            if (!geometryToEdit) return;
            editedGeometryObservable.next(geometryToEdit);
        })
    )
    .subscribe((val) => val);

// We need a way of establishing whether any vertices were selected
// so that we can differentiate between different map click/enter/escape
// actions. Different geometries can have different vertice identifiers
// (polygons, for example, can have multiple rings) so we establish
// a way of identifying them using string identifiers
// (index.toString() for lines and ringIndex.toString()-index.toString()
// for polygons)
export const activeVerticesObservable = new BehaviorSubject<string[]>([]);

export const activateVerticesObservable = new Subject<string[]>();
export const deactivateVerticesObservable = new Subject<string[]>();

activateVerticesObservable
    .pipe(
        withLatestFrom(activeVerticesObservable),
        map(([idsToActivate, activeIds]) => {
            activeVerticesObservable.next([...activeIds, ...idsToActivate]);
        })
    )
    .subscribe((val) => val);

deactivateVerticesObservable
    .pipe(
        withLatestFrom(activeVerticesObservable),
        map(([idsToActivate, activeIds]) => {
            activeVerticesObservable.next(activeIds.filter((id) => !idsToActivate.includes(id)));
        })
    )
    .subscribe((val) => val);

export const deleteVerticesObservable = new Subject<void>();

deleteVerticesObservable
    .pipe(
        withLatestFrom(editedGeometryObservable, activeVerticesObservable, geometriesObservable),
        map(([_, editedGeometry, activeVertices, geometries]) => {
            if (!editedGeometry || activeVertices.length === 0 || !geometries) return;

            let newCoordinates: CoordinateData | null = null;
            let shouldRemove = false;
            newGeometryHandler<void>({
                [GeometryType.AREA]: (editedArea) => {
                    newCoordinates = [...editedArea.coordinates];
                    // For every selected vertex
                    for (const vertex of activeVertices.sort().reverse()) {
                        const [ring, vertexIndex] = parseAreaVertexId(vertex);
                        if (!newCoordinates[ring]) continue;
                        // Remove it from the coordinate array of arrays.
                        newCoordinates[ring].splice(vertexIndex, 1);
                        // If the current sub-array (ring) is left with just two
                        // vertices, remove the whole ring
                        if (newCoordinates[ring].length <= 2) {
                            newCoordinates.splice(ring, 1);
                        }
                    }
                    // If there's just 2 coordinates left, remove the entire geometry
                    if (newCoordinates.flat(1).length <= 2) {
                        shouldRemove = true;
                    }
                },
                [GeometryType.COUNT]: () => {
                    // Counts have only one vertex, so always remove them when a vertex is deleted.
                    shouldRemove = true;
                },
                [GeometryType.LINEAR]: (editedLine) => {
                    newCoordinates = [...editedLine.coordinates];
                    // For every selected vertex
                    for (const vertex of activeVertices) {
                        const vertexIndex = parseInt(vertex);
                        // Remove it from the coordinate array.
                        newCoordinates.splice(vertexIndex, 1);
                    }
                    // If there's just 1 coordinate left, remove the entire geometry
                    if (newCoordinates.length <= 1) {
                        shouldRemove = true;
                    }
                },
            })(editedGeometry);

            if (shouldRemove) {
                geometriesObservable.next(
                    geometries.filter((g: Geometry) => g.uuid !== editedGeometry.uuid)
                );
                selectedGeometriesObservable.next({ geometries: [], points: [] });
            } else if (newCoordinates !== null) {
                geometriesObservable.next([
                    ...geometries.filter((g: Geometry) => g.uuid !== editedGeometry.uuid),
                    { ...editedGeometry, coordinates: newCoordinates },
                ]);
            }
            activeVerticesObservable.next([]);
        })
    )
    .subscribe((val) => val);

export const isDrawingRectangleObservable = new BehaviorSubject<boolean>(false);

// This is used when a drawing tool was selected and we click on the map. This determines what sort of geometry should
// be added and initializes it.
export const addVertexObservable = new Subject<Point>();

addVertexObservable
    .pipe(
        withLatestFrom(
            selectedGeometriesObservable,
            geometriesObservable,
            styleObservable,
            booleanGeometryToolObservable,
            booleanGeometryObservable,
            toolObservable
        ),
        map(
            ([
                point,
                selectedGeometries,
                geometries,
                style,
                booleanGeometryTool,
                booleanGeometry,
                tool,
            ]) => {
                if (!geometries) {
                    return;
                }
                // Only do something if no geometry is selected, or if the user is doing the boolean geometry operation,
                // handle it only if the geometry is not yet defined (first click).
                if (
                    selectedGeometries.geometries.length > 0 &&
                    (!booleanGeometryTool || booleanGeometry !== undefined)
                )
                    return;
                const { color, lineType, weight, areaType, size, markerIcon } = style;

                const newGeometries = [...geometries];
                const newGeometryUuid = uuid();
                let newGeometry: Geometry;
                if (booleanGeometryTool) {
                    switch (booleanGeometryTool) {
                        case ToolType.LINEAR:
                            booleanGeometryObservable.next({
                                uuid: newGeometryUuid,
                                style: {
                                    color: color,
                                    lineType: lineType,
                                    weight: weight,
                                    shapeWeight: getShapeWeight(weight, lineType),
                                },
                                type: GeometryType.LINEAR,
                                coordinates: [point],
                            });
                            break;
                        case ToolType.AREA:
                            booleanGeometryObservable.next({
                                uuid: newGeometryUuid,
                                style: {
                                    color: color,
                                    areaType,
                                },
                                type: GeometryType.AREA,
                                coordinates: [[point]],
                            });
                            break;
                    }
                } else {
                    switch (tool) {
                        case ToolType.LINEAR:
                            newGeometry = {
                                uuid: newGeometryUuid,
                                style: {
                                    color: color,
                                    lineType: lineType,
                                    weight: weight,
                                    shapeWeight: getShapeWeight(weight, lineType),
                                },
                                type: GeometryType.LINEAR,
                                coordinates: [point],
                            };
                            geometriesObservable.next(newGeometries.concat(newGeometry));
                            selectedGeometriesObservable.next({
                                geometries: [newGeometry],
                                points: [],
                            });
                            break;
                        case ToolType.AREA:
                            newGeometry = {
                                uuid: newGeometryUuid,
                                style: {
                                    color: color,
                                    areaType,
                                },
                                type: GeometryType.AREA,
                                coordinates: [[point]],
                            };
                            geometriesObservable.next(newGeometries.concat(newGeometry));
                            selectedGeometriesObservable.next({
                                geometries: [newGeometry],
                                points: [],
                            });
                            break;
                        case ToolType.COUNT:
                            newGeometry = {
                                uuid: newGeometryUuid,
                                type: GeometryType.COUNT,
                                coordinates: [point],
                                style: {
                                    color: color,
                                    size: size,
                                    icon: getIcon(getSize(size), markerIcon, color),
                                    markerIcon: markerIcon,
                                },
                            };
                            geometriesObservable.next(newGeometries.concat(newGeometry));
                            selectedGeometriesObservable.next({
                                geometries: [newGeometry],
                                points: [],
                            });
                            break;
                    }
                }
            }
        )
    )
    .subscribe((val) => val);

export const updateGeometryObservable = new Subject<Geometry | SelectedPoint>();

updateGeometryObservable
    .pipe(
        withLatestFrom(geometriesObservable),
        map(([updatedGeometry, geometries]) => {
            if (!geometries) {
                return;
            }
            if (pointOrGeometryIsGeometry(updatedGeometry)) {
                geometriesObservable.next([
                    ...geometries.filter((g) => g.uuid !== updatedGeometry.uuid),
                    updatedGeometry,
                ]);
            } else {
                const updatedCount = geometries.find(
                    ({ uuid }) => uuid === updatedGeometry.countID
                );
                if (!updatedCount || !geometryIs(updatedCount, GeometryType.COUNT)) return;
                const foundPoint = updatedCount.coordinates.find(
                    ({ id }) => id === updatedGeometry.id
                );
                if (!foundPoint) return;

                geometriesObservable.next([
                    ...geometries.filter(({ uuid }) => uuid !== updatedCount.uuid),
                    {
                        ...updatedCount,
                        coordinates: [
                            ...updatedCount.coordinates.filter(({ id }) => id !== foundPoint.id),
                            updatedGeometry,
                        ],
                    },
                ]);
            }
        })
    )
    .subscribe((val) => val);

// Because the geometry persistence function depends on the current scale (which is not stored in an observable yet), we
// have to make sure that we have the most up to date handle to that function available.
export const persistenceFunctionObservable = new Subject<GeometryPersistencePayload>();

// Any time the function changes or the geometries change, we call the geometry persistence, which is debounced, so
// it's not a big threat.
combineLatest([geometriesObservable, persistenceFunctionObservable])
    .pipe(
        map(([geometries, geometryPersistencePayload]) => {
            if (!geometries) {
                return;
            }
            persistGeometries(geometries, geometryPersistencePayload);
        })
    )
    .subscribe((val) => val);

// This is what happens when you commit the currently drawn geometry.
export const finishEditingGeometryObservable = new Subject<void>();

finishEditingGeometryObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable),
        map(([_, selectedGeometries]) => {
            const editedGeometry = selectedGeometries.geometries[0];
            if (
                editedGeometry === undefined ||
                (editedGeometry &&
                    (toPointCount(editedGeometry) > 1 ||
                        editedGeometry.type === GeometryType.COUNT))
            ) {
                toolObservable.next(ToolType.SELECTION);
                currentlyAddedCoordinatesObservable.next(null);
                editedGeometryObservable.next(null);
                selectedGeometriesObservable.next({ geometries: [], points: [] });
                activeVerticesObservable.next([]);
            }
        })
    )
    .subscribe((val) => val);

// And this happens when you abort it.
export const abortEditingGeometryObservable = new Subject<void>();

abortEditingGeometryObservable
    .pipe(
        withLatestFrom(isAddingCoordinatesObservable),
        map(([_, isAddingCoordinates]) => {
            if (isAddingCoordinates) {
                deleteSelectedGeometriesObservable.next();
            }
            toolObservable.next(ToolType.SELECTION);
            currentlyAddedCoordinatesObservable.next(null);
            editedGeometryObservable.next(null);
            selectedGeometriesObservable.next({ geometries: [], points: [] });
            activeVerticesObservable.next([]);
        })
    )
    .subscribe((val) => val);

// For storing the payload of the tooltip that shows up when you hover over some buttons.
export const toolTooltipObservable = new Subject<ToolTooltipPayload | null>();

// Any time you pan and zoom, the viewport changes, and we encode that in the URL so that you can copy the link and
// send it to some other person, and they'll see the same thing as you.
export const base64ViewportObservable = new BehaviorSubject<string>('');

base64ViewportObservable
    .pipe(
        map((base64Viewport) => {
            if (base64Viewport) {
                const href = new URL(window.location.href);
                href.searchParams.set('viewport', base64Viewport);
                const newUrl = href.toString();
                window.history.replaceState({ path: newUrl }, '', newUrl);
            }
        })
    )
    .subscribe((val) => val);

// When we manipulate geometries, we mutate the geometriesObservable. Which means that if we select something,
// manipulate it, and then look into the selectedGeometriesObservable, we'll see that geometry before the manipulations.
// These keeps them up to date to make stuff like copy-pasting and boolean operations cool.
geometriesObservable
    .pipe(
        withLatestFrom(selectedGeometriesObservable),
        map(([geometries, selectedGeometries]) => {
            if (!geometries) {
                return;
            }
            const newSelectedGeometries: Geometry[] = [];
            // TODO => update points
            selectedGeometries.geometries.forEach((sg) => {
                const geometry = geometries.find((g) => g.uuid === sg.uuid);
                if (geometry) {
                    newSelectedGeometries.push(geometry);
                }
            });

            selectedGeometriesObservable.next({
                geometries: newSelectedGeometries,
                points: selectedGeometries.points,
            });
        })
    )
    .subscribe((val) => val);

const undoStackObservable = new BehaviorSubject<PlanPageGeometry[][]>([]);
const redoStackObservable = new BehaviorSubject<PlanPageGeometry[][]>([]);

// Building the undo-redo stack in a debounced manner
geometriesObservable.pipe(debounce(() => interval(1000))).subscribe((newGeometries) => {
    if (!newGeometries) {
        return;
    }
    const snapshot = createSnapshot(newGeometries);
    undoStackObservable.next([...undoStackObservable.value, snapshot]);
    redoStackObservable.next([]);
});

export const undoObservable = new Subject<void>();

undoObservable
    .pipe(
        withLatestFrom(
            undoStackObservable,
            redoStackObservable,
            geometriesObservable,
            isAddingCoordinatesObservable
        ),
        map(([_, undoStates, redoStates, geometries, isDrawing]) => {
            if (undoStates.length === 0 || isDrawing || !geometries) return;
            const previousGeometries = loadSnapshot(undoStates[undoStates.length - 1]);
            const newUndoStates = undoStates.slice(0, undoStates.length - 1);

            redoStackObservable.next([createSnapshot(geometries), ...redoStates]);
            geometriesObservable.next(previousGeometries);
            selectedGeometriesObservable.next({ geometries: previousGeometries, points: [] });
            undoStackObservable.next(newUndoStates);
        })
    )
    .subscribe((val) => val);

export const redoObservable = new Subject<void>();

redoObservable
    .pipe(
        withLatestFrom(
            undoStackObservable,
            redoStackObservable,
            geometriesObservable,
            isAddingCoordinatesObservable
        ),
        map(([_, undoStates, redoStates, geometries, isDrawing]) => {
            if (redoStates.length === 0 || isDrawing || !geometries) return;
            const nextGeometries = loadSnapshot(redoStates[0]);
            const newRedoStates = redoStates.slice(1);

            undoStackObservable.next([...undoStates, createSnapshot(geometries)]);
            geometriesObservable.next(nextGeometries);
            selectedGeometriesObservable.next({ geometries: nextGeometries, points: [] });
            redoStackObservable.next(newRedoStates);
        })
    )
    .subscribe((val) => val);

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToCurrentlyAddedCoordinates = (
    subscribe: (position: LatLng | null) => void
): Subscription => currentlyAddedCoordinatesObservable.subscribe(subscribe);

export const subscribeToCursorPosition = (subscribe: (position: LatLng) => void): Subscription =>
    cursorPositionObservable.subscribe(subscribe);

export const subscribeToBoundary = (
    subscribe: (position: LatLngBounds | undefined) => void
): Subscription => boundaryObservable.subscribe(subscribe);

export const subscribeToStyle = (subscribe: (payload: Style) => void): Subscription =>
    styleObservable.subscribe(subscribe);

export const subscribeToGeometryCopy = (
    subscribe: (payload: CopyBuffer | undefined) => void
): Subscription => geometryCopyObservable.subscribe(subscribe);

export const subscribeToToolTooltip = (
    subscribe: (payload: ToolTooltipPayload | null) => void
): Subscription => toolTooltipObservable.subscribe(subscribe);

export const subscribeToIsAddingCoordinates = (
    subscribe: (payload: boolean) => void
): Subscription => isAddingCoordinatesObservable.subscribe(subscribe);

export const subscribeToBooleanGeometry = (
    subscribe: (payload: Geometry<GeometryType.AREA | GeometryType.LINEAR> | undefined) => void
): Subscription => booleanGeometryObservable.subscribe(subscribe);

export const subscribeToBooleanTool = (
    subscribe: (payload: BooleanToolType | undefined) => void
): Subscription => booleanToolObservable.subscribe(subscribe);

export const subscribeToBooleanGeometryTool = (
    subscribe: (payload: ToolType | undefined) => void
): Subscription => booleanGeometryToolObservable.subscribe(subscribe);

export const subscribeToIsDrawingRectangleObservable = (
    subscribe: (payload: boolean) => void
): Subscription => isDrawingRectangleObservable.subscribe(subscribe);

const leafletStylesAreEqual = (prev: LeafletStyleType, cur: LeafletStyleType) =>
    prev.color === cur.color &&
    prev.weight === cur.weight &&
    prev.size === cur.size &&
    prev.icon === cur.icon &&
    prev.shapeWeight === cur.shapeWeight &&
    prev.lineType === cur.lineType &&
    prev.areaType === cur.areaType &&
    prev.markerIcon === cur.markerIcon;

const pointsAreEqual = (prev: Point, cur: Point) =>
    prev.id === cur.id && prev.x === cur.x && prev.y === cur.y;

const pointArraysAreEqual = (prev: Point[], cur: Point[]) =>
    prev.length === cur.length && prev.every((prevPoint, i) => pointsAreEqual(prevPoint, cur[i]));

const areasAreEqual = (prev: Point[][], cur: Point[][]) =>
    prev.length === cur.length &&
    prev.every((prevLine, i) => pointArraysAreEqual(prevLine, cur[i]));

export const geometriesAreEqual = (prev: Geometry, cur: Geometry) =>
    prev.uuid === cur.uuid &&
    leafletStylesAreEqual(prev.style, cur.style) &&
    newGeometryHandler<boolean>({
        [GeometryType.AREA]: (prevArea) =>
            geometryIs(cur, GeometryType.AREA) &&
            areasAreEqual(prevArea.coordinates, cur.coordinates),
        [GeometryType.COUNT]: (prevCount) =>
            geometryIs(cur, GeometryType.COUNT) &&
            pointArraysAreEqual(prevCount.coordinates, cur.coordinates),
        [GeometryType.LINEAR]: (prevLine) =>
            geometryIs(cur, GeometryType.LINEAR) &&
            pointArraysAreEqual(prevLine.coordinates, cur.coordinates),
    })(prev);

export const optionalGeometriesAreEqual = (prev?: Geometry, cur?: Geometry) => {
    if (!prev || !cur) {
        return typeof prev === typeof cur;
    }
    return geometriesAreEqual(prev, cur);
};
