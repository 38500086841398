import React, { FC } from 'react';

import { BasicModal } from '@/components/ui/controls/BasicModal';

const COPY = {
    cancelCopy:
        'This will delete your submission. If you want to pick ' +
        'this project up in the future, you will need to resubmit from scratch.',
    cancelHeader: 'Are you sure you want to cancel this project?',
    cancelProject: 'Cancel Project',
    cancelProjectDecline: "Don't Cancel",
};

type CancelModalProps = {
    cancel: () => void;
    close: () => void;
};
export const CancelModal: FC<CancelModalProps> = ({ cancel, close }) => (
    <BasicModal
        header={COPY.cancelHeader}
        close={close}
        submit={cancel}
        cancelCopy={COPY.cancelProjectDecline}
        submitCopy={COPY.cancelProject}
    >
        <p>{COPY.cancelCopy}</p>
    </BasicModal>
);
