import { ApolloClient, executeQuery } from '@/common/apollo/execute';
import {
    IMaterialExcludingValues,
    IMaterialLightFragment,
    IMaterialSearchQuery,
    IMaterialSearchQueryVariables,
    MaterialSearchDocument,
} from '@/graphql';

export interface MaterialsSearchData {
    materials: IMaterialLightFragment[];
    more: boolean;
    page: number;
}

interface SearchMaterialsArgs {
    client: ApolloClient;
    term: string;
    page: number;
    state: string;
    county: string;
    exclude?: IMaterialExcludingValues;
}

export const searchMaterials = async ({
    client,
    state,
    county,
    term,
    page,
    exclude,
}: SearchMaterialsArgs): Promise<MaterialsSearchData> => {
    const materialData = await executeQuery<IMaterialSearchQuery, IMaterialSearchQueryVariables>(
        client,
        {
            query: MaterialSearchDocument,
            variables: { input: { state, county, page, term, exclude } },
        }
    );
    return {
        ...materialData.materialSearch,
        page,
    };
};
