import { frogmint } from '../frog';
import { ReviewTagRecord, reviewTagFragment } from './reviewTags';

export interface ProjectReviewRecord {
    id: string;
    projectId: number;
    ownerId: number;
    rating: number;
    comment: string;
    created?: string;
    lastModified?: string;
    projectReviewTags?: {
        nodes: {
            reviewTag: ReviewTagRecord;
        }[];
    };
}

export const projectReviewFragment = frogmint`
    ${'ProjectReview_projectReview'} {
        id
        projectId
        ownerId
        rating
        comment
        created
        lastModified
        projectReviewTags {
            nodes {
                reviewTag {
                    ${reviewTagFragment}
                }
            }
        }
    }
`;
