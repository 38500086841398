import gql from 'graphql-tag';
import { UseMutationResponse, useMutation } from 'urql';

import { PlanPage, PlanPageGeometry } from '../common/types';
import { newMutations } from '../frog';

export interface UpdateProjectPlanPageArgs {
    id: number;
    markups?: PlanPageGeometry[];
    scale?: number;
    name?: string;
    removed?: boolean;
    originalOrientation?: number;
    orientation?: number;
    widthPx?: number;
    heightPx?: number;
    widthIn?: number;
    heightIn?: number;
}

export type UpdateProjectPlanPageResult = {
    updateProjectPlanPage: {
        projectPlanPage: PlanPage;
    };
};

export type CreateProjectPlanPageResult = {
    createProjectPlanPage: {
        projectPlanPage: PlanPage;
    };
};

const UpdateProjectPlanPageMutation = gql`
    mutation UpdateProjectPlanPage(
        $id: BigInt!
        $markups: JSON
        $scale: BigFloat
        $name: String
        $removed: Boolean
        $originalOrientation: Int
        $orientation: Int
        $widthPx: Int
        $heightPx: Int
        $widthIn: Float
        $heightIn: Float
    ) {
        updateProjectPlanPage(
            input: {
                patch: {
                    markups: $markups
                    name: $name
                    scale: $scale
                    removed: $removed
                    originalOrientation: $originalOrientation
                    orientation: $orientation
                    widthPx: $widthPx
                    heightPx: $heightPx
                    widthIn: $widthIn
                    heightIn: $heightIn
                }
                id: $id
            }
        ) {
            projectPlanPage {
                id
                heightIn
                heightPx
                markups
                pageId
                name
                widthIn
                widthPx
                removed
                scale
                orientation
                originalOrientation
                projectPlan {
                    parentProjectPlanFileUuid
                    uuid
                    id
                    projectId
                }
                assets {
                    nodes {
                        extension
                        id
                        projectId
                        key
                        parentId
                        position
                        assetDimension {
                            orientation
                        }
                    }
                }
            }
        }
    }
`;

export const [executeProjectPlanPageMutation, useUpdateProjectPlanPageMutation] = newMutations<
    UpdateProjectPlanPageArgs,
    UpdateProjectPlanPageResult
>(UpdateProjectPlanPageMutation);

export interface CreateProjectPlanPageArgs {
    projectPlanPage: Partial<PlanPage> & { projectPlanId: string };
}

const CreateProjectPlanPageMutation = gql`
    mutation CreateProjectPlanPage($projectPlanPage: ProjectPlanPageInput!) {
        createProjectPlanPage(input: { projectPlanPage: $projectPlanPage }) {
            projectPlanPage {
                id
                heightIn
                heightPx
                markups
                pageId
                name
                widthIn
                widthPx
                removed
                scale
                isV2
                orientation
                originalOrientation
                projectPlan {
                    parentProjectPlanFileUuid
                    uuid
                    id
                    projectId
                }
                assets {
                    nodes {
                        extension
                        id
                        projectId
                        key
                        parentId
                        position
                        assetDimension {
                            orientation
                        }
                    }
                }
            }
        }
    }
`;

export function useCreateProjectPlanPageMutation(): UseMutationResponse<
    CreateProjectPlanPageResult,
    CreateProjectPlanPageArgs
> {
    return useMutation<CreateProjectPlanPageResult, CreateProjectPlanPageArgs>(
        CreateProjectPlanPageMutation
    );
}
