import { BaseListItem } from './BaseListItem';
import { BaseListItemModal } from './BaseListItemModal';
import { MoveToGroupModal } from './MoveToGroupModal';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { useTakeoffIcon } from './useTakeoffIcon';
import {
    getGroups,
    getJoinableGroups,
    getMarkupEntriesByIds,
    getMarkupsDeep,
    isAllSoloMarkups,
    isNameTooLong,
    isNameTooShort,
    isSameMarkupType,
    toFormattedMeasurement,
    toUom,
} from './utils';
import { IMarkupFragment, IMarkupGroupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';
import { Mode } from '@/theme/Mode';
import Box from '@mui/material/Box';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';

export interface TakeoffListItemProps extends ListItemButtonProps {
    markup?: IMarkupFragment;
    markupEntries?: IMarkupEntry[];
    mode?: string;
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onCreateGroupFromMarkup?: (markup: IMarkupFragment) => void;
    onRemoveMarkupsFromGroup?: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    selectedMarkupEntryIds?: string[];
}

export const TakeoffListItem: FC<TakeoffListItemProps> = ({
    disabled,
    markup,
    markupEntries,
    mode,
    onAddMarkupToGroup,
    onCreateGroupFromMarkup,
    onListItemClick,
    onListItemNameBlur,
    onRemoveMarkupsFromGroup,
    selectedMarkupEntryIds,
    ...props
}) => {
    const TakeoffIcon = useTakeoffIcon({ geometry: markup?.markupType });

    const nameTextFieldInputRef = useRef<HTMLInputElement | undefined>();

    const [name, setName] = useState(markup?.name);
    const [nameOnFocus, setNameOnFocus] = useState<string>();
    const [renaming, setRenaming] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const [moveToGroupModalOpen, setMoveToGroupModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setName(markup?.name);
    }, [markup?.name]);

    const checked = selectedMarkupEntryIds?.includes(markup?.id || '') === true;

    const joinableGroups = getJoinableGroups(markup, getGroups(markupEntries));

    const markups = getMarkupsDeep(markupEntries);
    const selectedMarkups = getMarkupEntriesByIds(markups, selectedMarkupEntryIds);

    const isMarkupsWithSameTypeSelected = isSameMarkupType(selectedMarkups);
    const isMarkupsWithNoParentSelected = isAllSoloMarkups(selectedMarkups);

    let statusIconTooltipTitle = '';

    if (checked) {
        if (!isMarkupsWithSameTypeSelected) {
            statusIconTooltipTitle = 'Only takeoffs of similar measurement types can be grouped.';
        } else if (markup?.markupGroupID && !isMarkupsWithNoParentSelected) {
            statusIconTooltipTitle = 'Only takeoffs that are not already in groups can be grouped.';
        }
    }

    const handleMoveToGroupMenuItemClick = () => {
        setMenuAnchor(null);
        setMoveToGroupModalOpen(true);
    };

    const handleCreateGroupFromMarkupMenuItemClick: MouseEventHandler<HTMLLIElement> = () => {
        if (!markup) {
            return;
        }

        onCreateGroupFromMarkup?.(markup);

        setMenuAnchor(null);
    };

    const handleRemoveFromGroupMenuItemClick = async () => {
        if (!markup?.markupGroupID) {
            return;
        }

        await onRemoveMarkupsFromGroup?.(markup.markupGroupID, [markup?.id]);

        setMenuAnchor(null);
    };

    const handleMoveToGroupModalOkButtonClick = async (markupGroup: IMarkupGroupFragment) => {
        if (!markup) {
            return;
        }

        await onAddMarkupToGroup?.(markup.id, markupGroup.id);

        setMoveToGroupModalOpen(false);
    };

    const isNameTextFieldPointerCursor = mode === 'select' || mode === 'insertIntoExpression';

    return (
        <ListItemButton
            divider
            disabled={disabled}
            selected={!!markup?.isSelected}
            disableGutters
            onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();

                onListItemClick?.(event, {
                    markupEntry: markup,
                    nameRef: nameTextFieldInputRef,
                });
            }}
            {...props}
            sx={{
                cursor: mode === 'default' ? 'auto' : undefined,
                padding: '8px 0',
                userSelect: mode === 'default' ? 'auto' : undefined,
                ...props.sx,
            }}
        >
            <Box sx={{ width: '100%' }}>
                <BaseListItem
                    checkbox
                    checkboxProps={{
                        checked,
                    }}
                    icon={
                        <SvgIcon
                            sx={{
                                color: markup?.color,
                                height: '14px',
                                width: '14px',
                            }}
                            viewBox="0 0 32 32"
                        >
                            <TakeoffIcon />
                        </SvgIcon>
                    }
                    mode={mode}
                    nameTextFieldProps={{
                        inputProps: {
                            disabled: !renaming,
                            sx: {
                                cursor: isNameTextFieldPointerCursor ? 'pointer' : 'auto',
                            },
                        },
                        InputProps: {
                            sx: {
                                cursor: isNameTextFieldPointerCursor ? 'pointer' : 'auto',
                            },
                        },
                        inputRef: nameTextFieldInputRef,
                        onBlur: (event) => {
                            setRenaming(false);

                            if (isNameTooShort(name) || isNameTooLong(name)) {
                                setName(nameOnFocus);
                                setNameOnFocus(undefined);

                                return;
                            }

                            setNameOnFocus(undefined);

                            onListItemNameBlur?.(event, {
                                markupEntry: markup,
                                name,
                                nameRef: nameTextFieldInputRef,
                            });
                        },
                        onFocus: () => {
                            setNameOnFocus(name);
                        },
                        onChange: (event) => {
                            setName(event.target.value);
                        },
                        value: name,
                    }}
                    overflowIconButton
                    overflowIconButtonProps={{
                        onClick: (event) => {
                            event.stopPropagation();

                            setMenuAnchor(event.currentTarget);
                        },
                    }}
                    statusIcon={false && Boolean(statusIconTooltipTitle)}
                    statusIconTooltipProps={{
                        children: <React.Fragment />,
                        title: statusIconTooltipTitle,
                    }}
                />
                <BaseListItemModal
                    leftListItemTextProps={{
                        primary: `${toFormattedMeasurement(markup)} ${toUom(markup?.markupType)}`,
                    }}
                />
                <Mode variant="light">
                    <Menu
                        anchorEl={menuAnchor}
                        MenuListProps={{
                            sx: {
                                minWidth: '130px',
                            },
                        }}
                        onClose={() => setMenuAnchor(null)}
                        open={Boolean(menuAnchor)}
                    >
                        {!markup?.markupGroupID && (
                            <MenuItem
                                dense
                                disabled={disabled || joinableGroups.length === 0}
                                onClick={handleMoveToGroupMenuItemClick}
                            >
                                Move to group
                            </MenuItem>
                        )}
                        {!markup?.markupGroupID && (
                            <MenuItem
                                dense
                                disabled={disabled}
                                onClick={handleCreateGroupFromMarkupMenuItemClick}
                            >
                                Create group
                            </MenuItem>
                        )}
                        {markup?.markupGroupID && (
                            <MenuItem
                                dense
                                disabled={disabled}
                                onClick={handleRemoveFromGroupMenuItemClick}
                            >
                                Remove from group
                            </MenuItem>
                        )}
                        <MenuItem
                            dense
                            disabled={disabled}
                            onClick={() => {
                                setMenuAnchor(null);
                                setRenaming(true);

                                setTimeout(() => {
                                    const node = nameTextFieldInputRef.current;

                                    if (node) {
                                        const selectionEnd = node.selectionEnd || 0;

                                        node.focus();
                                        node.selectionStart = selectionEnd + 1000;
                                    }
                                }, 0);
                            }}
                        >
                            Rename
                        </MenuItem>
                        <MenuItem dense disabled>
                            Delete
                        </MenuItem>
                    </Menu>
                    {markup && (
                        <MoveToGroupModal
                            disabled={disabled}
                            markup={markup}
                            markupGroups={joinableGroups}
                            onCancelButtonClick={() => setMoveToGroupModalOpen(false)}
                            onOkButtonClick={handleMoveToGroupModalOkButtonClick}
                            open={moveToGroupModalOpen}
                        />
                    )}
                </Mode>
            </Box>
        </ListItemButton>
    );
};
