import Flatten from '@flatten-js/core';

import { toFlatten as coordinateDataToFlatten } from '@/common/convert/coordinateData';
import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

type Convert = {
    [GeometryType.AREA]: Flatten.Polygon;
    [GeometryType.COUNT]: Flatten.Point[];
    [GeometryType.LINEAR]: Flatten.Multiline;
};

export const toFlatten = newGeometryHandler<Convert>({
    [GeometryType.AREA]: (area) => coordinateDataToFlatten(area.coordinates),
    [GeometryType.COUNT]: (count) => count.coordinates.map(coordinateDataToFlatten),
    [GeometryType.LINEAR]: (line) => coordinateDataToFlatten(line.coordinates),
});
