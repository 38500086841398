import { CoordinateData, GeometryType, Point } from '@/common/types';

export const coordinateDataIs = {
    point: (data: CoordinateData | Point): data is Point => {
        return !Array.isArray(data);
    },
    coordinateData: (data: CoordinateData | Point): data is CoordinateData => {
        return Array.isArray(data);
    },
    area: (data: CoordinateData | Point): data is CoordinateData<GeometryType.AREA> => {
        return coordinateDataIs.coordinateData(data) && Array.isArray(data[0]);
    },
};
