import React, { FC } from 'react';

import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';

export const GeometrySectionLoadingState: FC<ListProps> = (props) => {
    return (
        <List {...props}>
            <ListItem dense>
                <Skeleton sx={{ height: '16px', margin: '2px 0', width: '100%' }} />
            </ListItem>
        </List>
    );
};
