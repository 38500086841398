/* Spinning indicator, that is being displayed on load */

import React, { FC, useEffect } from 'react';

import { DivProps } from '@/common/types';
import { Bugsnag } from '@/components/app/Bugsnag';

import Spinner from '@/assets/images/spinner.png';

import './SpinnerLoader.scss';

export const SpinnerLoader: FC<DivProps> = (props) => {
    const initializedAt = Date.now();

    // notifyBugsnagOnLongSpinner notifies Bugsnag when called 5 seconds or
    // longer after spinner initialization.
    const notifyBugsnagOnLongSpinner = (): void => {
        const secondsElapsed = (Date.now() - initializedAt) / 1000;
        if (secondsElapsed >= 5) {
            Bugsnag?.notify(new Error('Spinning too long'), (event) =>
                event.addMetadata('duration', { secondsElapsed })
            );
        }
    };

    // Call notifyBugsnagOnLongSpinner when the page is unloaded and clean up
    // the listener before the spinner unmounts.
    useEffect((): (() => void) => {
        window.addEventListener('beforeunload', notifyBugsnagOnLongSpinner);
        return (): void => window.removeEventListener('beforeunload', notifyBugsnagOnLongSpinner);
    }, []);

    return (
        <div className="spinner-loader" {...props}>
            <img src={Spinner} alt="spinner-loader" />
        </div>
    );
};
