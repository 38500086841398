import gql from 'graphql-tag';

export type CreateProjectFormMutationResponse = {
    createProjectForm: {
        projectForm: {
            id: string;
        };
    };
};
export const createProjectFormMutation = gql`
    mutation CreateProjectForm($id: UUID!, $teamId: BigInt!) {
        createProjectForm(input: { projectForm: { id: $id, teamId: $teamId } }) {
            projectForm {
                id
            }
        }
    }
`;
