/* Multi checkbox component with icons and text as labels */
import React, { FC } from 'react';

import { Checkbox } from './Checkbox';
import { TradeCircle } from '../controls/Trade';
import { CheckboxData, Setter } from '@/common/types';

import './Multicheck.scss';

export type MultiCheckProps = {
    checkboxes: CheckboxData[];
    onChange: Setter<CheckboxData[]>;
    disabled?: boolean;
};

export const MultiCheck: FC<MultiCheckProps> = ({ checkboxes, onChange, disabled }) => {
    const check = (index: number, value: boolean): void => {
        const newCheckboxes = checkboxes.slice();
        newCheckboxes[index].isChecked = value;
        onChange(newCheckboxes);
    };

    return (
        <div className="multicheck">
            {checkboxes.map((checkbox, index) => (
                <Checkbox
                    id={checkbox.name}
                    key={checkbox.name}
                    isChecked={checkbox.isChecked}
                    onChange={(value): void => check(index, value)}
                    disabled={disabled}
                >
                    <TradeCircle v2 className="label-icon" key={checkbox.id} trade={checkbox} />
                    <span className="label-name">{checkbox.name}</span>
                </Checkbox>
            ))}
        </div>
    );
};
