import { createContext, useContext } from 'react';

export interface EstimateLayoutContext {
    isPrintView: boolean;
    panelIsOpen: boolean;
    openPanel: () => void;
    togglePanelIsOpen: () => void;
}

export const EstimateLayoutContext = createContext<EstimateLayoutContext>({
    isPrintView: false,
    panelIsOpen: true,
    openPanel: () => undefined,
    togglePanelIsOpen: () => undefined,
});

export const useEstimateLayout = (): EstimateLayoutContext => {
    return useContext(EstimateLayoutContext);
};
