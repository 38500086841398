import React, { FC } from 'react';

import { useEstimationExpressionOpenedState } from './hooks/useEstimationExpressionOpenedState';
import { AssemblyPanelProvider } from '@/components/AssemblyPanel/context';
import { useUser } from '@/contexts/User';

export const EstimationAssemblyPanelProvider: FC = ({ children }) => {
    const {
        data: { anonymousUser },
    } = useUser();
    const { expressionOpenedState, setExpressionOpenedState } =
        useEstimationExpressionOpenedState();

    if (anonymousUser) {
        return <>{children}</>;
    }
    return (
        <AssemblyPanelProvider
            initValue={{
                expressionOpenedState,
                setExpressionOpenedState,
            }}
        >
            {children}
        </AssemblyPanelProvider>
    );
};
