/* Provides experiments per team */
import gql from 'graphql-tag';

export const ExperimentTeamsQuery = gql`
    {
        experimentTeams {
            nodes {
                id
                experiment {
                    id
                    name
                    description
                }
                team {
                    id
                    name
                }
            }
        }
    }
`;
