/*
 * exportMarkupPDF.ts provides a GraphQL mutation used to generate a link to
 * download a .zip containing marked up plans.
 */
import gql from 'graphql-tag';
import { useMutation, UseMutationResponse } from 'urql';
import { OperationContext, OperationResult } from '@urql/core';
import { track, TrackEventName } from '../common/analytics';

export interface ExportMarkupPDF {
    projectUUID: string;
}

export type ExportMarkupPDFResult = {
    exportPDF: string;
};

export const ExportMarkupPDFMutation = gql`
    mutation exportPdfMutation($projectUUID: UUID!) {
        exportPDF(projectUUID: $projectUUID)
    }
`;

export function useExportMarkupPDF(): UseMutationResponse<ExportMarkupPDFResult, ExportMarkupPDF> {
    const [result, mutation] = useMutation<ExportMarkupPDFResult, ExportMarkupPDF>(
        ExportMarkupPDFMutation
    );
    const wrappedMutation = (
        variables?: ExportMarkupPDF,
        context?: Partial<OperationContext>
    ): Promise<OperationResult<ExportMarkupPDFResult, ExportMarkupPDF>> => {
        track(TrackEventName.MarkupExportRan);
        return mutation(variables, context);
    };
    return [result, wrappedMutation];
}
