/*
 * Entry is an entry within the project panel's activity log.
 */
import React, { FC, useEffect, useState } from 'react';

import { ActivityIndicator } from './ActivityIndicator';
import {
    ActivityEntry,
    ActivityInfoContainer,
    ActivityMainText,
    ActivitySubText,
    ActivityTextContainer,
} from './styled';
import { EventContent, GroupedEvents, activityDateString, mapEventGroupToContent } from './utils';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';

export interface EntryProps extends ProjectPanelComponentProps {
    eventGroup: GroupedEvents;
}

export const Entry: FC<EntryProps> = ({ eventGroup, useProjectPanel }) => {
    const [eventContent, setEventContent] = useState<EventContent | null>(null);

    useEffect(() => {
        mapEventGroupToContent(eventGroup).then(setEventContent);
    }, []);

    return (
        <ActivityEntry showDivider={eventGroup.eventIds !== undefined}>
            <ActivityInfoContainer>
                {eventContent && <SvgIcon src={eventContent.icon} />}
                <ActivityTextContainer>
                    <ActivityMainText>{eventContent?.description}</ActivityMainText>
                    <ActivitySubText>
                        {eventGroup.time && activityDateString(eventGroup.time)}
                    </ActivitySubText>
                </ActivityTextContainer>
            </ActivityInfoContainer>
            {eventGroup.eventIds !== undefined && (
                <ActivityIndicator
                    eventIds={eventGroup.eventIds}
                    useProjectPanel={useProjectPanel}
                />
            )}
        </ActivityEntry>
    );
};
