import React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { useFeatures } from '@/contexts/Features';

type ResponsiveButtonProps = ButtonProps | IconButtonProps;

type ToolbarButtonProps = {
    mobileIcon?: JSX.Element;
} & ResponsiveButtonProps;

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({ mobileIcon, ...props }) => {
    const screenSize = useScreenSize();
    const { project } = useProjectFromParams();
    const {
        features: { builderEstimateView },
    } = useFeatures();

    if (builderEstimateView(project?.created)) {
        return (
            <>
                {screenSize === ScreenSize.Desktop || !mobileIcon ? (
                    <Button {...(props as ButtonProps)} />
                ) : (
                    <IconButton
                        {...(props as IconButtonProps)}
                        sx={{ color: (theme) => theme.palette.hues.neutral[78], width: '24px' }}
                    >
                        {mobileIcon}
                    </IconButton>
                )}
            </>
        );
    }
    return (
        <>
            {screenSize === ScreenSize.Desktop ? (
                <Button {...(props as ButtonProps)} />
            ) : (
                <IconButton
                    {...(props as IconButtonProps)}
                    sx={{ color: (theme) => theme.palette.hues.neutral[78], width: '24px' }}
                >
                    {mobileIcon}
                </IconButton>
            )}
        </>
    );
};
