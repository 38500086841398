import { createContext, useContext } from 'react';

export enum ExpressionOpenedState {
    Closed,
    FormulaOpen,
    FormulaOpenWithHelper,
}

export interface ExpressionOpenedStateContext {
    expressionOpenedState: ExpressionOpenedState;
    setExpressionOpenedState: (expressionOpenedState: ExpressionOpenedState) => void;
}

export const EstimationExpressionOpenedState = createContext<ExpressionOpenedStateContext>({
    expressionOpenedState: ExpressionOpenedState.Closed,
    setExpressionOpenedState: () => undefined,
});

export const useEstimationExpressionOpenedState = (): ExpressionOpenedStateContext => {
    return useContext(EstimationExpressionOpenedState);
};
