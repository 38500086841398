import { UNCATEGORIZED_TRADE_ID } from '@/common/assemblies';
import { Hook } from '@/common/types';
import { sortAssemblyElements } from '@/common/utils/helpers';
import { PaginationArgs, PaginationDirection, genPaginationArgs } from '@/common/utils/pagination';
import { useUser } from '@/contexts/User';
import {
    AssembliesDocument,
    IAssembliesOrderBy,
    IAssembliesQuery,
    IAssembliesQueryVariables,
    IAssemblyFragment,
    IAssemblyType,
    IElementFragment,
} from '@/graphql';
import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

export type AssemblyWithElements = IAssemblyFragment & {
    elements: IElementFragment[];
};

export interface UseAssembliesRes {
    assemblies: AssemblyWithElements[];
    assembliesGroupedByTrade: [string, AssemblyWithElements[]][];
    uncategorizedAssembly: AssemblyWithElements | null;
    loading: boolean;
    refetch?: () => void;
}

export interface UseAssembliesProps {
    pageSize?: number;
    search?: string;
    projectId?: number;
    libraryId?: string | null;
    additionalQueryArgs?: Partial<IAssembliesQueryVariables>;
}

export const isUncategorizedAssembly = (assembly: AssemblyWithElements) => {
    return assembly.assemblyType === IAssemblyType.Item;
};

export const getAssembliesGroupedByTrade = (
    assemblies: AssemblyWithElements[]
): [string, AssemblyWithElements[]][] => {
    return Object.entries(
        assemblies.reduce<Record<string, AssemblyWithElements[]>>((acc, assembly) => {
            // If there is no trade name, we cannot append to object
            if (!assembly.trade?.name) return acc;

            return {
                ...acc,
                [assembly.trade.name]: acc[assembly.trade.name]
                    ? [...acc[assembly.trade.name], assembly]
                    : [assembly],
            };
        }, {})
    );
};

export const getUncategorizedAssemblyIndex = (assemblies: AssemblyWithElements[]) => {
    return assemblies.findIndex(isUncategorizedAssembly);
};

export const getTradeNamesAndIds = (assemblies: AssemblyWithElements[] = []) => {
    return assemblies.reduce<Record<string, number>>((acc, assembly) => {
        if (!assembly.trade?.name) return acc;

        return {
            ...acc,
            // Just overwrite existing value if it exists, it will be the same.
            [assembly.trade.name]: !Number.isNaN(assembly.trade.id) ? Number(assembly.trade.id) : 0,
        };
    }, {});
};

export const useAssemblies: Hook<UseAssembliesRes, UseAssembliesProps> = ({
    pageSize = 1000,
    search,
    projectId,
    libraryId,
    additionalQueryArgs,
}) => {
    const {
        data: { anonymousUser },
    } = useUser();
    const [assemblies, setAssemblies] = useState<AssemblyWithElements[]>([]);
    const [uncategorizedAssembly, setUncategorizedAssembly] = useState<AssemblyWithElements | null>(
        null
    );

    // Group assemblies by trade and sort by trade id, used for sorting against
    const tradeNamesAndIds = useMemo(() => getTradeNamesAndIds(assemblies), [assemblies]);

    // Group assemblies by trade and sort against trade name order
    // Sort to array of tuple objects representing trade name and assembly records
    const assembliesGroupedByTrade = useMemo(() => {
        return getAssembliesGroupedByTrade(assemblies).sort(([a], [b]) => {
            if (a === UNCATEGORIZED_TRADE_ID) {
                return -1;
            }

            return tradeNamesAndIds[a] - tradeNamesAndIds[b];
        });
    }, [assemblies, tradeNamesAndIds]);

    const assemblyQueryInput = (
        paginationArgs?: PaginationArgs
    ): QueryHookOptions<IAssembliesQuery, IAssembliesQueryVariables> => ({
        variables: {
            input: {
                condition: {
                    projectID: projectId?.toString(),

                    // Short circuit out falsy values to undefined, this is equivalent
                    // to posting nothing in the mutation for an empty library id or name.
                    name: search || undefined,
                    libraryID: libraryId || undefined,
                    ...additionalQueryArgs?.input?.condition,
                },
                orderBy: IAssembliesOrderBy.AlphabeticAsc,
                ...paginationArgs?.variables.input,
            },
        },
        fetchPolicy: anonymousUser ? 'cache-first' : 'network-only',
    });

    const [
        fetchAssemblies,
        { data: assemblyData, fetchMore: fetchMoreAssemblies, loading, refetch },
    ] = useLazyQuery<IAssembliesQuery, IAssembliesQueryVariables>(AssembliesDocument);

    const fetchAssembliesPage = (): void => {
        const paginationArgs = genPaginationArgs(
            PaginationDirection.Next,
            pageSize,
            assemblyData?.assemblies.pageInfo
        );
        if (paginationArgs) {
            fetchMoreAssemblies?.(assemblyQueryInput(paginationArgs));
        }
    };

    useEffect(() => {
        if (!projectId && !libraryId && !search) {
            return;
        }
        fetchAssemblies(assemblyQueryInput(genPaginationArgs(PaginationDirection.Next, pageSize)));
    }, [projectId, libraryId, search]);

    useEffect(() => {
        const newAssemblies: AssemblyWithElements[] =
            assemblyData?.assemblies?.edges?.map(({ node: assembly }) => ({
                ...assembly,
                elements: sortAssemblyElements(
                    assembly.elements?.edges?.map(({ node: element }) => ({
                        ...element,
                        previousElement: element.previousElement ?? null,
                    })) || []
                ),
            })) ?? [];
        // If the special "uncategorized elements" assembly is present, remove it from the main array and load it into
        // the dedicated `newUncategorizedAssembly` state variable.
        const uncategorizedIdx = getUncategorizedAssemblyIndex(newAssemblies);

        if (uncategorizedIdx !== -1) {
            const newUncategorizedAssembly = newAssemblies.splice(uncategorizedIdx, 1)[0];
            setUncategorizedAssembly(newUncategorizedAssembly);
        }
        // If it's a full refetch from the beginning, clear the state (to handle deletions)
        if (!assemblyData?.assemblies.pageInfo.hasPreviousPage) {
            setAssemblies(newAssemblies);
        } else {
            setAssemblies((oldAssemblies) => [
                ...oldAssemblies.filter((oA) => !newAssemblies.map((nA) => nA.id).includes(oA.id)),
                ...newAssemblies,
            ]);
        }
        if (assemblyData?.assemblies.pageInfo.hasNextPage) {
            fetchAssembliesPage();
        }
    }, [assemblyData]);

    return {
        assemblies,
        assembliesGroupedByTrade,
        loading,
        uncategorizedAssembly,
        refetch,
    };
};
