/**
 * `Client` provides singleton-style access to the urql client.
 */
import { devtoolsExchange } from '@urql/devtools';
import { Exchange, Client as UrqlClient, createClient, dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';

import { authExchange } from './authExchange';
import {
    invalidationHandlingExchange,
    invalidationTriggeringExchange,
} from './invalidationExchange';
import { Subscription } from './subscription';

import { Env, postgraphileURL } from '@/common/env';
import { PlanRecords } from '@/queries/plans';
import { UpdateProjectPlanPageArgs } from '@/mutations/projectPlanPage';
import { PlanPagesQuery } from '@/queries/planPages';
import { PlanPage } from '@/common/types';
import { ProjectPlanPageMessage } from '@/subscriptions/projectPlanPages';
import { ProjectWithEventsMessage } from '@/subscriptions/projects';
import { ProjectWithEventsRecords } from '@/queries/projects';
import { updateRecordArr } from '@/common/utils/helpers';
import { ProjectsWithEventsHelperQuery } from '@/common/urql/helperQueries';

export interface FetchBody {
    query?: string;
    operationName: string | undefined;
    variables: undefined | Record<string, unknown>;
    extensions: undefined | Record<string, unknown>;
}

export const isRequestInfoObject = (input: RequestInfo): input is Request => {
    return Object.prototype.hasOwnProperty.call(input, 'url');
};

export class Client {
    /*************************************************************************/
    /* Public                                                                */
    /*************************************************************************/
    // get provides access to the Client instance.
    public static get(): Client {
        if (!Client.instance) {
            Client.instance = new Client();
        }
        return Client.instance;
    }

    // getClient provides access to the urql Client.
    public static getClient(): UrqlClient {
        return Client.get().client;
    }

    /*************************************************************************/
    /* Private                                                               */
    /*************************************************************************/
    private static instance: Client;

    private client: UrqlClient;

    private constructor() {
        this.client = Client.createClient();
    }

    private static async queryParamFetch(
        this: void,
        input: RequestInfo,
        init?: RequestInit
    ): Promise<Response> {
        const parsedBody = JSON.parse((init?.body ?? '').toString()) as FetchBody;
        let url = '';
        if (isRequestInfoObject(input)) {
            url = input.url;
        } else {
            url = input;
        }
        return fetch(`${url}?opname=postgraphile/${parsedBody.operationName ?? 'unnamed'}`, init);
    }

    private static createClient(): UrqlClient {
        return createClient({
            url: postgraphileURL(),
            exchanges: Client.buildExchangeArray(),
            fetch: Client.queryParamFetch,
        });
    }

    private static buildExchangeArray(): Exchange[] {
        const exchanges = [];
        if (Env.tier.isDevelopment) {
            exchanges.unshift(devtoolsExchange);
        }
        const cache = cacheExchange({
            updates: {
                Subscription: {
                    ProjectPlanPage(result: ProjectPlanPageMessage, _, cache) {
                        const planPage = result.ProjectPlanPage.projectPlanPageEntry;
                        if (!planPage) {
                            return null;
                        }
                        const planFile = planPage.projectPlan;
                        if (!planFile) {
                            return null;
                        }

                        cache.updateQuery(
                            {
                                query: PlanPagesQuery,
                                variables: {
                                    projectId: Number(planFile.projectId),
                                },
                            },
                            (data: PlanRecords | null) => {
                                let currentPlanFile = data?.projectPlanFiles.nodes.find(
                                    (planFileNode) => planFileNode.id === planFile.id
                                );
                                if (!currentPlanFile) {
                                    data?.projectPlanFiles.nodes.push(planFile);
                                    currentPlanFile = data?.projectPlanFiles.nodes.find(
                                        (planFileNode) => planFileNode.id === planFile.id
                                    );
                                }
                                currentPlanFile?.projectPlanPagesByProjectPlanId?.nodes.push(
                                    planPage
                                );
                                return data;
                            }
                        );
                    },
                    ProjectChanged(result: ProjectWithEventsMessage, _, cache) {
                        const project = result.ProjectChanged.projectEntry;
                        if (!project) {
                            return null;
                        }

                        cache.updateQuery(
                            {
                                query: ProjectsWithEventsHelperQuery,
                                variables: {
                                    condition: {},
                                },
                            },
                            (data: ProjectWithEventsRecords | null) => {
                                if (!data) {
                                    return null;
                                }
                                data.projects.nodes = [
                                    ...updateRecordArr(data.projects.nodes, project),
                                ];
                                return data;
                            }
                        );
                    },
                },
            },
            optimistic: {
                updateProjectPlanPage(
                    variables: { input: { id: string; patch: UpdateProjectPlanPageArgs } },
                    cache
                ) {
                    const projectPlanFileId = cache.resolve(
                        { __typename: 'ProjectPlanPage', id: variables.input.id },
                        'projectPlanId'
                    );
                    if (typeof projectPlanFileId !== 'string') {
                        return null;
                    }

                    const projectId = cache.resolve(
                        { __typename: 'ProjectPlanFile', id: projectPlanFileId },
                        'projectId'
                    );
                    if (!projectId) {
                        return null;
                    }

                    cache.updateQuery(
                        {
                            query: PlanPagesQuery,
                            variables: {
                                projectId: Number(projectId),
                            },
                        },
                        (data: PlanRecords | null) => {
                            const currentPlanFile = data?.projectPlanFiles.nodes.find(
                                (planFileNode) => planFileNode.id === projectPlanFileId
                            );
                            if (
                                !currentPlanFile ||
                                !currentPlanFile.projectPlanPagesByProjectPlanId
                            ) {
                                return data;
                            }
                            const currentPlanPageIndex =
                                currentPlanFile.projectPlanPagesByProjectPlanId.nodes.findIndex(
                                    (planPageNode) =>
                                        planPageNode.id === variables.input.id.toString()
                                );
                            if (currentPlanPageIndex < 0) {
                                return data;
                            }

                            currentPlanFile.projectPlanPagesByProjectPlanId.nodes[
                                currentPlanPageIndex
                            ] = {
                                ...currentPlanFile.projectPlanPagesByProjectPlanId.nodes[
                                    currentPlanPageIndex
                                ],
                                ...(variables.input.patch as unknown as PlanPage),
                            };
                            return data;
                        }
                    );
                    return null;
                },
            },
        });
        exchanges.push(
            invalidationHandlingExchange,
            invalidationTriggeringExchange,
            authExchange,
            dedupExchange,
            cache,
            Subscription.getExchange(),
            fetchExchange
        );
        return exchanges;
    }
}
