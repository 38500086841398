/*
 * This context provides access to active user data.
 */
import { useNotifications } from './Notifications';
import { identify } from '@/common/analytics';
import { Env } from '@/common/env';
import { useLocalStorageUtils } from '@/common/storage';
import { Setter } from '@/common/types';
import { BearerToken } from '@/common/urql/bearerToken';
import { makeContext } from '@/common/utils/makeContext';
import { isValidPhoneNumber } from '@/common/utils/validators';
import { Bugsnag } from '@/components/app/Bugsnag';
import { GradientToast } from '@/components/notifications/GradientToast';
import { BasicToastCopy } from '@/components/notifications/GradientToast/BasicToastCopy';
import {
    IEstimatorProfileAssignmentMutation,
    IEstimatorProfileAssignmentMutationVariables,
    IForgotPasswordMutation,
    IForgotPasswordMutationVariables,
    ILoginMutation,
    ILoginMutationVariables,
    IProfileAssignmentMutation,
    IProfileAssignmentMutationVariables,
    ISignupMutation,
    ISignupMutationVariables,
    ITeamAccountType,
    ITeamAssignmentType,
    IUserCreateMutation,
    IUserCreateMutationVariables,
    IUserFragment,
    IUserQuery,
    IUserQueryVariables,
    IUserRole,
    useEstimatorProfileAssignmentMutation,
    useForgotPasswordMutation,
    useLoginMutation,
    useProfileAssignmentMutation,
    useSignupMutation,
    useUserCreateMutation,
    useImpersonateMutation,
    IImpersonateMutation,
    IImpersonateMutationVariables,
    useUserLazyQuery,
    useUserQuery,
} from '@/graphql';
import { FetchResult, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';

const COPY = {
    passwordReset: "We've just sent you an email to reset your password",
    passwordResetError: 'Please provide an email address.',
};

type UserProps = {
    data: {
        user: IUserFragment;
        fetchedUser?: IUserQuery;
        anonymousUser: boolean;
        fullUserInfo: IUserFragment;
        fetchedFullUserInfo?: IUserQuery;
    };
    actions: {
        editProfile: () => Promise<FetchResult>;
        editEstimatorProfile: () => Promise<FetchResult>;
        refetchUser: () => void;
        createUser: (firstName?: string, lastName?: string, authID?: string) => Promise<void>;
        setUser: Setter<IUserFragment>;
        fetchFullUserByID: (id: string) => void;
        loginUser: (data: {
            email?: string;
            password?: string;
            code?: string;
            refreshToken?: string;
            firstName?: string;
            lastName?: string;
            company?: string;
            phone?: string;
        }) => Promise<void>;
        impersonateUser: (data: { impersonatedAuthID?: string }) => Promise<void>;
        logoutUser: () => void;
        signUpUser: (data: {
            email?: string;
            password?: string;
            code?: string;
            name?: string;
            type: ITeamAssignmentType;
            roles: IUserRole[];
        }) => Promise<IUserFragment | void>;
        resetUserPassword: (data: { email: string }) => Promise<void>;
        setAnonymousUser: Setter<boolean>;
    };
    loading: {
        loadingUser: boolean;
        loadedUser: boolean;
        creatingUser: boolean;
        loadingFullUserInfo: boolean;
        loginInProgress: boolean;
        signUpInProgress: boolean;
        impersonationInProgress: boolean;
        forgotPasswordInProgress: boolean;
        loadingProfileUpdate: boolean;
    };
    errors: {
        errorEditingProfile: string | undefined;
        errorCreatingUser: string | undefined;
        errorFetchingFullUserInfo: string | undefined;
        errorLoggingIn: string | undefined;
        errorSigningUp: string | undefined;
        errorImpersonating: string | undefined;
        errorForgotPassword: string | undefined;
    };
    validations: {
        hasSession?: boolean;
        isUserValid: boolean;
        isTrial: boolean;
        isTrialExpired: boolean;
        isUserRoles: (roles: IUserRole | IUserRole[]) => boolean;
    };
};

const { useConsumer, Provider } = makeContext<UserProps>(() => {
    const { addNotification } = useNotifications();

    const [user, setUser] = useState<IUserFragment>({} as IUserFragment);

    const [fullUserInfo, setFullUserInfo] = useState<IUserFragment>({} as IUserFragment);
    const [anonymousUser, setAnonymousUser] = useState<boolean>(false);
    const { clearLocalStorage } = useLocalStorageUtils();

    const isUserValid = useMemo<boolean>(() => {
        const firstNameValid = !!user.firstName;
        const lastNameValid = !!user.lastName;
        const phoneValid = !!user.phone && isValidPhoneNumber(user.phone);
        const companyNameValid =
            user.roles?.includes(IUserRole.Builder) && !user.roles?.includes(IUserRole.Estimator)
                ? !!user.team?.name
                : true;

        if (user.roles?.includes(IUserRole.Estimator) && !user.roles?.includes(IUserRole.Builder)) {
            const hasAvailability = Boolean(user.schedule?.availability?.length);

            return (
                firstNameValid && lastNameValid && phoneValid && companyNameValid && hasAvailability
            );
        } else {
            return firstNameValid && lastNameValid && phoneValid && companyNameValid;
        }
    }, [user]);

    const authID = localStorage.getItem('impersonatedAuthID') || localStorage.getItem('authID');

    const isTrial =
        user.roles?.includes(IUserRole.Builder) &&
        !user.roles?.includes(IUserRole.Superadmin) &&
        user.team?.onboarding?.accountType === ITeamAccountType.Trial;
    const isTrialExpired = isTrial && user.team?.onboarding?.daysLeftInTrial === 0;

    // A hotfix for the auth transition which sometimes produced a really weird local storage state.
    // Can be removed in like 2 weeks from 9/30/2021.
    useEffect(() => {
        if (authID === 'undefined') {
            logoutUser();
        }
    }, [authID]);

    const userQueryInput = (id?: string): QueryHookOptions<IUserQuery, IUserQueryVariables> => {
        return {
            variables: {
                input: {
                    id,
                    authID: !id ? authID : undefined,
                },
            },
            skip: Env.tier.isTest ? false : !authID,
            fetchPolicy: 'cache-first',
        };
    };

    const updateProfileMutationInput = (): MutationHookOptions<
        IProfileAssignmentMutation,
        IProfileAssignmentMutationVariables
    > => ({
        variables: {
            input: {
                id: user.id,
                patch: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    teamName: user.team?.name,
                    address: user.address,
                    city: user.city,
                    state: user.state,
                    country: user.country,
                    postalCode: user.postalCode,
                    logo: user.logoLink,
                },
            },
        },
    });

    const updateEstimatorProfileMutationInput = (): MutationHookOptions<
        IEstimatorProfileAssignmentMutation,
        IEstimatorProfileAssignmentMutationVariables
    > => ({
        variables: {
            userInput: {
                id: user.id,
                patch: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    timezone: user.timezone,
                },
            },
            estimatorInput: {
                id: user.schedule?.id,
                userID: user.id,
                availability: user.schedule?.availability,
                unavailable: user.schedule?.unavailable,
            },
        },
    });

    const userCreateMutationInput = (
        firstName?: string,
        lastName?: string,
        authID?: string,
        phone = '',
        roles: IUserRole[] = []
    ): MutationHookOptions<IUserCreateMutation, IUserCreateMutationVariables> => ({
        variables: {
            input: {
                authID: authID ?? '',
                firstName: firstName ?? '',
                lastName: lastName ?? '',
                phone,
                roles,
            },
        },
        refetchQueries: ['User'],
    });

    const loginInput = (
        email?: string,
        password?: string,
        code?: string,
        refreshToken?: string,
        firstName?: string,
        lastName?: string,
        company?: string,
        phone?: string
    ): MutationHookOptions<ILoginMutation, ILoginMutationVariables> => ({
        variables: {
            input: {
                email,
                password,
                code,
                refreshToken,
                firstName,
                lastName,
                company,
                phone,
            },
        },
    });

    const impersonationInput = (
        impersonatedAuthID?: string
    ): MutationHookOptions<IImpersonateMutation, IImpersonateMutationVariables> => ({
        variables: {
            input: {
                refreshToken: localStorage.getItem('refreshToken') || '',
                impersonatedAuthID,
            },
        },
    });

    const signUpInput = (
        type: ITeamAssignmentType,
        roles: IUserRole[],
        email?: string,
        password?: string,
        name?: string,
        code?: string,
        secretID?: string
    ): MutationHookOptions<ISignupMutation, ISignupMutationVariables> => ({
        variables: {
            input: {
                email,
                password,
                code,
                name,
                secretID,
                type,
                roles,
            },
        },
    });

    const forgotPasswordInput = (
        email: string
    ): MutationHookOptions<IForgotPasswordMutation, IForgotPasswordMutationVariables> => ({
        variables: {
            input: {
                email,
            },
        },
    });

    // Queries and mutations
    const {
        data: fetchedUser,
        loading: loadingUser,
        refetch: refetchUser,
        networkStatus: userNetworkStatus,
    } = useUserQuery(userQueryInput());

    const [
        fetchUser,
        {
            data: fetchedFullUserInfo,
            error: errorFetchingFullUserInfo,
            loading: loadingFullUserInfo,
        },
    ] = useUserLazyQuery({ fetchPolicy: 'no-cache' });

    const [editProfile, { error: errorEditingProfile, loading: loadingEditingProfile }] =
        useProfileAssignmentMutation(updateProfileMutationInput());

    const [
        editEstimatorProfile,
        { error: errorEditingEstimatorProfile, loading: loadingEditingEstimatorProfile },
    ] = useEstimatorProfileAssignmentMutation(updateEstimatorProfileMutationInput());

    const [userCreate, { loading: creatingUser, error: errorCreatingUser }] =
        useUserCreateMutation();

    const [login, { loading: loginInProgress, error: errorLoggingIn }] = useLoginMutation();

    const [signUp, { loading: signUpInProgress, error: errorSigningUp }] = useSignupMutation();

    const [impersonate, { loading: impersonationInProgress, error: errorImpersonating }] =
        useImpersonateMutation();

    const [forgotPassword, { loading: forgotPasswordInProgress, error: errorForgotPassword }] =
        useForgotPasswordMutation();

    // other functions
    const createUser = async (
        firstName?: string,
        lastName?: string,
        authID?: string
    ): Promise<void> => {
        await userCreate(userCreateMutationInput(firstName, lastName, authID));
    };

    const fetchFullUserByID = (id: string): void => {
        fetchUser(userQueryInput(id));
    };

    const isUserRoles = (roles: IUserRole | IUserRole[]): boolean => {
        if (anonymousUser) {
            return true;
        }
        if (Array.isArray(roles)) {
            return roles.every((role) => user.roles.includes(role));
        } else {
            return user.roles.includes(roles);
        }
    };

    const signUpUser = async ({
        email,
        password,
        code,
        name,
        secretID,
        type,
        roles,
    }: {
        email?: string;
        password?: string;
        code?: string;
        name?: string;
        secretID?: string;
        type: ITeamAssignmentType;
        roles: IUserRole[];
    }): Promise<IUserFragment | void> => {
        const resp = await signUp(signUpInput(type, roles, email, password, name, code, secretID));

        if (resp.data) {
            localStorage.setItem('accessToken', resp.data.signup.accessToken);
            localStorage.setItem('refreshToken', resp.data.signup.refreshToken);
            localStorage.setItem('authID', resp.data.signup.user.authID);
            localStorage.setItem('impersonatedAuthID', resp.data.signup.impersonatedAuthID ?? '');
            setUser(resp.data.signup.user);

            return resp.data.signup.user;
        }
    };

    const loginUser = async ({
        email,
        password,
        code,
        refreshToken,
        firstName,
        lastName,
        company,
        phone,
    }: {
        email?: string;
        password?: string;
        code?: string;
        refreshToken?: string;
        firstName?: string;
        lastName?: string;
        company?: string;
        phone?: string;
    }): Promise<void> => {
        const resp = await login(
            loginInput(email, password, code, refreshToken, firstName, lastName, company, phone)
        );
        if (resp.data) {
            localStorage.setItem('accessToken', resp.data.login.accessToken);
            localStorage.setItem('refreshToken', resp.data.login.refreshToken);
            localStorage.setItem('authID', resp.data.login.user.authID);
            localStorage.setItem('impersonatedAuthID', resp.data.login.impersonatedAuthID ?? '');
            setUser(resp.data.login.user);
        }
    };

    const impersonateUser = async ({
        impersonatedAuthID,
    }: {
        impersonatedAuthID?: string;
    }): Promise<void> => {
        const resp = await impersonate(impersonationInput(impersonatedAuthID));
        if (resp.data) {
            localStorage.setItem('accessToken', resp.data.impersonate.accessToken);
            localStorage.setItem('refreshToken', resp.data.impersonate.refreshToken);
            localStorage.setItem('authID', resp.data.impersonate.user.authID);
            localStorage.setItem(
                'impersonatedAuthID',
                resp.data.impersonate.impersonatedAuthID ?? ''
            );
            setUser(resp.data.impersonate.user);
        }
    };

    const resetUserPassword = async ({ email }: { email: string }): Promise<void> => {
        if (email) {
            try {
                const resp = await forgotPassword(forgotPasswordInput(email));
                if (resp.data) {
                    addNotification(
                        {
                            title: '',
                            v2Content: (
                                <GradientToast>
                                    <BasicToastCopy css={'padding-top: 10px;'}>
                                        {COPY.passwordReset}
                                    </BasicToastCopy>
                                </GradientToast>
                            ),
                        },
                        'success'
                    );
                }
            } catch (e) {
                addNotification(
                    {
                        title: 'Error',
                        content: String(e),
                    },
                    'error'
                );

                throw e;
            }
        } else {
            addNotification(
                {
                    title: 'Error',
                    content: COPY.passwordResetError,
                },
                'error'
            );

            throw new Error(COPY.passwordResetError);
        }
    };

    const logoutUser = (): void => {
        clearLocalStorage();

        const redirectUrl = Env.tier.isProduction
            ? 'https://www.1build.com'
            : `${Env.deploymentURL}/login`;

        window.location.replace(redirectUrl);
    };

    useEffect(() => {
        BearerToken.setBearerTokenRefresher(
            () => {
                return loginUser({
                    refreshToken: localStorage.getItem('refreshToken') ?? undefined,
                });
            },
            () => {
                if (!authID) {
                    return;
                }

                logoutUser();
            }
        );
    }, [authID]);

    // useEffects for user manipluation
    useEffect(() => {
        if (fetchedUser?.user) {
            const userData: IUserFragment = fetchedUser.user;
            // This use effect results in a race condition where the request
            // loading state is false while hook user state is not yet set
            setUser(userData);
        }
    }, [fetchedUser?.user]);

    useEffect(() => {
        if (fetchedFullUserInfo?.user) {
            const userData: IUserFragment = fetchedFullUserInfo.user;
            setFullUserInfo(userData);
        }

        return (): void => {
            setFullUserInfo({} as IUserFragment);
        };
    }, [fetchedFullUserInfo?.user]);

    useEffect(() => {
        if (authID) {
            return;
        }

        setAnonymousUser(true);
    }, [authID]);

    useEffect(() => {
        if (Env.tier.isTest) {
            return;
        }

        if (user && user.id) {
            identify(user);
            Bugsnag?.setUser(
                String(user.id),
                user.email,
                `${user.firstName ?? ''} ${user.lastName ?? ''}`
            );
        }
    }, [user]);

    const hasSession = authID !== undefined && authID !== null && authID !== 'undefined';

    return {
        data: {
            user,
            fetchedUser,
            anonymousUser,
            fetchedFullUserInfo,
            fullUserInfo,
        },
        actions: {
            setUser,
            refetchUser,
            createUser,
            editProfile,
            editEstimatorProfile,
            fetchFullUserByID,
            signUpUser,
            loginUser,
            impersonateUser,
            logoutUser,
            resetUserPassword,
            setAnonymousUser,
        },
        errors: {
            errorEditingProfile:
                errorEditingEstimatorProfile?.message || errorEditingProfile?.message,
            errorCreatingUser: errorCreatingUser?.message,
            errorFetchingFullUserInfo: errorFetchingFullUserInfo?.message,
            errorLoggingIn: errorLoggingIn?.message,
            errorSigningUp: errorSigningUp?.message,
            errorForgotPassword: errorForgotPassword?.message,
            errorImpersonating: errorImpersonating?.message,
        },
        loading: {
            loadingUser,
            loadedUser: hasSession
                ? userNetworkStatus > 1 && user.id !== undefined
                : userNetworkStatus > 1,
            creatingUser,
            loadingFullUserInfo,
            loginInProgress,
            signUpInProgress,
            impersonationInProgress,
            forgotPasswordInProgress,
            loadingProfileUpdate: loadingEditingEstimatorProfile || loadingEditingProfile,
        },
        validations: {
            hasSession,
            isUserValid,
            isTrial,
            isTrialExpired,
            isUserRoles,
        },
    };
});

/* Context provider and consumer, exported for accessibility */
export const useUser = useConsumer;
export const UserProvider = Provider;
