import styled from 'styled-components';
import { transition } from '@/variables';

const UserTableCell = styled.div`
    min-width: fit-content;
    font-size: 0.875rem;
    white-space: break-spaces;
    box-sizing: border-box;

    a {
        text-decoration: underline;
    }
`;

export const UserTableContainer = styled.div`
    grid-area: table;
    display: grid;
    grid-template-columns: repeat(4, auto) 1.875rem;
    grid-auto-rows: 2.5rem auto;
    grid-gap: 0 0.375rem;
    align-items: center;
    justify-items: start;
    max-width: 100%;
`;

export const UserTableDivider = styled.hr`
    grid-column: 1 / 6;
    height: 1px;
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.2);
`;

export const UserTableBodyCell = styled(UserTableCell)`
    font-weight: 400;
`;

export const UserTableHeaderCell = styled(UserTableCell)`
    font-weight: 600;
`;

export const UserTableMoreButton = styled.button`
    display: grid;
    grid-template-columns: repeat(3, 0.25rem);
    grid-template-rows: 0.25rem;
    grid-gap: 0 2px;
    align-content: center;
    justify-content: center;
    height: 1.75rem;
    width: 1.75rem;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem rgba(255, 255, 255, 0.4);
    transition: all ${transition};

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const UserTableMoreButtonDot = styled.span`
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
`;
