/*
 * Header is the static top section of the projects panel.
 */
import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { ProjectPanelComponentProps } from './context';
import { PanelDueDate, PanelHeaderContainer, PanelHeaderSection } from './styled';

import { getProjectDetailsLink } from '@/common/links';
import { bidsDueDate } from '@/common/utils/bidsDueDate';
import { mapProjectStatus } from '@/components/AdminDashboard/Projects/sort';
import { AdminStatus, AdminStatusVariant } from '@/components/ui/controls/AdminStatus';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorProjectPanelText } from '@/variables';

import { ReactComponent as SortArrow } from '@/assets/icons/SortArrow.svg';
import { ReactComponent as CloseCross } from '@/assets/icons/close-cross.svg';

const COPY = {
    due: 'DUE',
};

export interface HeaderProps extends ProjectPanelComponentProps {
    closeProjectPanel: (e: React.MouseEvent) => void;
}

export const Header: FC<HeaderProps> = ({ closeProjectPanel, useProjectPanel }) => {
    const { project } = useProjectPanel();

    const formatDueDate = (): string => {
        if (project.bidsDueDate === undefined) {
            return '';
        }
        return `${COPY.due}: ${bidsDueDate(new Date(project.bidsDueDate)).toString()}`;
    };

    const renderAdminProjectStatus = (): JSX.Element => {
        const status = mapProjectStatus(project);
        if (status === undefined) {
            return <></>;
        }
        return <AdminStatus status={status} variant={AdminStatusVariant.Blocky} />;
    };

    return (
        <PanelHeaderContainer>
            <PanelHeaderSection>
                <SvgIcon
                    onClick={closeProjectPanel}
                    src={CloseCross}
                    width="0.825rem"
                    height="0.825rem"
                    style={{
                        cursor: 'pointer',
                        fill: colorProjectPanelText,
                    }}
                />
                <PanelDueDate>{formatDueDate()}</PanelDueDate>
            </PanelHeaderSection>
            <PanelHeaderSection>
                {renderAdminProjectStatus()}
                <Link to={getProjectDetailsLink(project.uuid, project.isSaas)} target="_blank">
                    <SvgIcon
                        src={SortArrow}
                        width="1rem"
                        height="1rem"
                        style={{
                            transform: 'rotate(270deg)',
                            cursor: 'pointer',
                            fill: colorProjectPanelText,
                            marginLeft: '0.5rem',
                        }}
                    />
                </Link>
            </PanelHeaderSection>
        </PanelHeaderContainer>
    );
};
