import React, { FC } from 'react';

import { Search } from '@/components/AdminDashboard/Topbar/Search';
import { UserDropdownContainer } from '@/components/AdminDashboard/Topbar/styled';
import { Title } from '@/components/AdminDashboard/styled';
import { UserDropdown } from '@/components/ui/inputs/UserDropdown';

export const TopbarContent: FC = () => (
    <>
        <Title>Estimators</Title>
        <Search placeholder="Search estimators" />
        <UserDropdownContainer>
            <UserDropdown />
        </UserDropdownContainer>
    </>
);
