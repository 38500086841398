/* eslint-disable @typescript-eslint/indent */
import Divider from '@mui/material/Divider';
import { CardProps } from '@mui/material/Card';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React, { FC, useEffect } from 'react';
import { Link as RRDLink, useLocation } from 'react-router-dom';
import {
    ICustomerPortalType,
    ITeamBillingFragment,
    useCustomerPortalLinkLazyQuery,
} from '@/graphql';
import { Plan } from '@/components/pricing/PricingPage/types';
import {
    hasMonthlySubscription,
    hasProfessionalSubscription,
    hasSelfCheckoutSubscription,
} from '@/components/pricing/PricingPage/utils';
import { Card, CardHeading, SvgIcon } from './styled';
import { ReactComponent as UpgradeIcon } from '@/assets/icons/32/upgrade.svg';
import { ReactComponent as DataViewAltIcon } from '@/assets/icons/32/data--view--alt.svg';
import { ReactComponent as WalletIcon } from '@/assets/icons/32/wallet.svg';

interface Props extends CardProps {
    plans?: Plan[];
    team?: ITeamBillingFragment;
}

export const ActionsCard: FC<Props> = ({ plans, team, ...props }) => {
    const theme = useTheme();
    const location = useLocation();
    const [getCustomerPortalLink, { data }] = useCustomerPortalLinkLazyQuery({
        variables: {
            input: {
                type: ICustomerPortalType.Default,
            },
        },
    });

    const handleCustomerPortalCreation =
        (type: ICustomerPortalType): (() => void) =>
        () =>
            getCustomerPortalLink({ variables: { input: { type } } });

    useEffect(() => {
        if (data && data.customerPortalLink !== '') {
            window.location.href = data.customerPortalLink;
        }
    }, [data]);

    return (
        <Card elevation={8} {...props}>
            <CardHeading variant="overline">Actions</CardHeading>
            <List disablePadding>
                {hasSelfCheckoutSubscription(plans, team) &&
                    (hasMonthlySubscription(plans, team) ||
                        !hasProfessionalSubscription(plans, team)) && (
                        <>
                            <Link
                                color={theme.palette.hues.neutral[21]}
                                component={RRDLink}
                                to={{
                                    pathname: '/pricing',
                                    state: {
                                        referrer: location.pathname,
                                    },
                                }}
                            >
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <SvgIcon viewBox="0 0 32 32">
                                            <UpgradeIcon />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText>Upgrade your plan</ListItemText>
                                </ListItem>
                            </Link>
                            <Divider light />
                        </>
                    )}
                <Link
                    color={theme.palette.hues.neutral[21]}
                    component="button"
                    onClick={handleCustomerPortalCreation(ICustomerPortalType.PaymentHistory)}
                >
                    <ListItem disableGutters>
                        <ListItemIcon>
                            <SvgIcon viewBox="0 0 32 32">
                                <DataViewAltIcon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText>View your payment history</ListItemText>
                    </ListItem>
                </Link>
                <Divider light />
                <Link
                    color={theme.palette.hues.neutral[21]}
                    component="button"
                    onClick={handleCustomerPortalCreation(ICustomerPortalType.PaymentManagement)}
                >
                    <ListItem disableGutters>
                        <ListItemIcon>
                            <SvgIcon viewBox="0 0 32 32">
                                <WalletIcon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText>Manage your payment</ListItemText>
                    </ListItem>
                </Link>
                <Divider light />
                <ListItem disableGutters>
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                        If you need to cancel your plan, please contact our support team at{' '}
                        <Link href="mailto:help@1build.com">help@1build.com</Link>
                    </ListItemText>
                </ListItem>
            </List>
        </Card>
    );
};
