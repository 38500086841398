/*
 * Module renders the active project panel module.
 */
import React, { FC } from 'react';

import { Activity } from './Activity';
import { Estimator } from './Estimator';
import { Files } from './Files';
import { Info } from './Info';
import { Price } from './Price';
import { ProjectPanelComponentProps } from './context';
import { PanelModuleContainer } from './styled';

import { ActionTab, InfoTab } from '@/components/AdminDashboard/types';

export const Module: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { activeTab } = useProjectPanel();

    const renderModule = (): JSX.Element => {
        switch (activeTab) {
            case ActionTab.Estimator:
                return <Estimator useProjectPanel={useProjectPanel} />;
            case ActionTab.Price:
                return <Price useProjectPanel={useProjectPanel} />;
            case InfoTab.Activity:
                return <Activity useProjectPanel={useProjectPanel} />;
            case InfoTab.Files:
                return <Files useProjectPanel={useProjectPanel} />;
            case InfoTab.Info:
                return <Info useProjectPanel={useProjectPanel} />;
            default:
                return <></>;
        }
    };

    return <PanelModuleContainer>{renderModule()}</PanelModuleContainer>;
};
