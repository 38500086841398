import { ElementRateStrings } from '../../utils/types';
import {
    Description,
    LargeDescription,
    RateBlock,
    RateInput,
    RateInputWrapper,
    RatesContainer,
    RatesHeader,
    RatesHeaderSubtitle,
    RateSymbol,
    Title,
} from './styled';
import { DivProps } from '@/common/types';
import { isNaiveNumericInput } from '@/common/utils/helpers';
import React, { FC } from 'react';
import { unFormatNumber } from '../../utils/rates';

export interface Rates extends DivProps {
    rates: ElementRateStrings;
    setRates: (rates: Partial<Omit<ElementRateStrings, 'costPerUnit'>>) => void;
    sourceDescriptionLoading: boolean;
    sourceDescription: string;
}

export const Rates: FC<Rates> = ({
    rates,
    setRates,
    sourceDescriptionLoading,
    sourceDescription,
}) => {
    return (
        <RatesContainer>
            <RatesHeader>
                <div>
                    <Title>Calculate unit rate</Title>
                    <RatesHeaderSubtitle>
                        Unit Rate = Material rate + (Labor rate / Prod.rate)
                    </RatesHeaderSubtitle>
                </div>
            </RatesHeader>
            <RateBlock>
                <div>
                    <Title>Material rate</Title>
                    <Description>Material cost of 1 unit</Description>
                </div>
                <RateInputWrapper>
                    <RateSymbol>$</RateSymbol>
                    <RateInput
                        value={rates.materialRate.replace('$', '')}
                        onChange={(e) => {
                            const value = e.target.value;

                            const parsedValue = unFormatNumber(value);

                            if (!isNaiveNumericInput(parsedValue)) {
                                return;
                            }

                            setRates({ materialRate: parsedValue });
                        }}
                    />
                </RateInputWrapper>
            </RateBlock>
            <RateBlock>
                <div>
                    <Title>Labor rate</Title>
                    <Description>Labor cost per hour</Description>
                </div>
                <RateInputWrapper>
                    <RateSymbol>$</RateSymbol>
                    <RateInput
                        value={rates.laborRate.replace('$', '')}
                        onChange={(e) => {
                            const value = e.target.value;

                            const parsedValue = unFormatNumber(value);

                            if (!isNaiveNumericInput(parsedValue)) {
                                return;
                            }

                            setRates({ laborRate: parsedValue });
                        }}
                    />
                </RateInputWrapper>
            </RateBlock>
            <RateBlock>
                <div>
                    <Title>Production rate</Title>
                    <Description>Amount of unit that can be installed per hour</Description>
                </div>
                <RateInput
                    value={rates.productionRate}
                    onChange={(e) => {
                        const value = e.target.value;

                        const parsedValue = unFormatNumber(value);

                        if (!isNaiveNumericInput(parsedValue)) {
                            return;
                        }

                        setRates({ productionRate: parsedValue });
                    }}
                />
            </RateBlock>
            {(sourceDescriptionLoading || sourceDescription) && (
                <RateBlock>
                    <div>
                        <Title>Description</Title>
                        {sourceDescriptionLoading && <Description>Loading...</Description>}
                        {sourceDescription && (
                            <LargeDescription>{sourceDescription}</LargeDescription>
                        )}
                    </div>
                </RateBlock>
            )}
        </RatesContainer>
    );
};
