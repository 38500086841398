import React, { FC } from 'react';

import { Container } from './styled';
import { DivProps } from '@/common/types';

interface GradientToastProps extends DivProps {
    className?: string;
}
export const GradientToast: FC<GradientToastProps> = ({ onClick, children, className }) => (
    <Container className={className} onClick={onClick}>
        {children}
    </Container>
);
