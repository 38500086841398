import { Cell, LeydenEditor, Table, Transforms } from 'leyden';
import { ReactEditor } from 'leyden-react';
import { Editor } from 'slate';

import { Serialize } from '../serialization';
import { EstimateEditor } from '../editor/EstimateEditor';
import { ratesFromCostPerUnit } from '../utils/rates';
import {
    assignElementRates,
    createBlankElement,
    deleteElement,
    deleteElementForV2Ordering,
    editElement,
    groupElement,
    renameAssembly,
    renameElement,
    reorderElement,
    reorderElementBeforeUUID,
    setElementUom,
} from '../utils/requests';
import { stringToElementReorderDirection } from '../utils/transforms';
import { ElementRates, EstimateElement } from '../utils/types';
import { ApolloClient } from '@/common/apollo/execute';
import { Queries } from '@/components/Estimate/queries';
import {
    IElementAssignmentInput,
    IElementAssignmentMutation,
    IElementExpressionInput,
    IElementFragment,
    IElementRatesInput,
    IUnitFragment,
} from '@/graphql';
import { LDService } from '@/contexts/LaunchDarkly';

export interface ElementTransforms {
    addNewBlankElement: (
        editor: Editor,
        options: {
            name: string;
            categoryID: string | null;
            materialID: string | null;
            rates: IElementRatesInput | null;
            expression: IElementExpressionInput | null;
            uom: string | null;
            projectID: string;
            takeoffUnitID: string;
            at?: number;
            client: ApolloClient;
        }
    ) => Promise<IElementAssignmentMutation | null>;
    addUncategorizedElements: (
        editor: Editor,
        elements: EstimateElement[],
        options?: {
            at?: number;
        }
    ) => void;
    deleteElement: (editor: Editor, elementID: string, client?: ApolloClient) => void;
    reorderElement: (
        editor: Editor,
        options: {
            at?: number;
            direction: 'up' | 'down';
            client?: ApolloClient;
        }
    ) => void;
    editElement(
        editor: Editor,
        client: ApolloClient,
        options: {
            row: number;
            elementID: string;
            input: Pick<IElementAssignmentInput, 'name' | 'rate' | 'expression' | 'unitID'>;
        }
    ): Promise<IElementFragment | null>;
    moveElementToGroup: (
        editor: Editor,
        options: {
            elementID: string;
            categoryID: string | null;
            client?: ApolloClient;
        }
    ) => void;
    setElementCostPerUnit: (
        editor: Editor,
        costPerUnit: number,
        cell: Cell<'Rate'>,
        client?: ApolloClient
    ) => void;
    setCategoryName: (
        editor: Editor,
        name: string,
        cell: Cell<'Category'>,
        client?: ApolloClient
    ) => void;
    setElementName: (
        editor: Editor,
        name: string,
        cell: Cell<'Name'>,
        client?: ApolloClient
    ) => void;
    setElementRates: (
        editor: Editor,
        rates: ElementRates,
        cell: Cell<'Rate'>,
        client?: ApolloClient
    ) => void;
    setElementQuantity: (editor: Editor, elementID: string, quantity: number) => void;
    setElementUom: (
        editor: Editor,
        uom: IUnitFragment,
        cell: Cell<'UnitOfMeasure'>,
        client?: ApolloClient
    ) => void;
}

export const ElementTransforms: ElementTransforms = {
    /**
     * Add a new blank element.
     */

    addNewBlankElement(
        editor: Editor,
        options: {
            categoryID: string | null;
            name: string;
            materialID: string | null;
            projectID: string;
            rates: IElementRatesInput | null;
            takeoffUnitID: string;
            expression: IElementExpressionInput | null;
            uom: string | null;
            at?: number;
            client: ApolloClient;
        }
    ): Promise<IElementAssignmentMutation | null> {
        let insertionRow = options.at;
        if (insertionRow === undefined) {
            const lastEmptyCategoryElement = Queries.elements(editor, {
                categoryID: options.categoryID,
                reverse: true,
            }).next().value;

            if (!lastEmptyCategoryElement) {
                if (options.categoryID) {
                    const category = Queries.category(editor, options.categoryID);
                    if (category !== null) {
                        insertionRow = category[1] + 1;
                    } else {
                        insertionRow = 0;
                    }
                } else {
                    insertionRow = 0;
                }
            } else {
                insertionRow = lastEmptyCategoryElement[1] + 1;
            }
        }
        const at = insertionRow;
        const cellAbove = Table.cell(LeydenEditor.table(editor), {
            at: { x: 0, y: at - 1 },
            type: 'Name',
        });
        let previousElementUUID: string | null = null;
        if (cellAbove && Cell.isCell(cellAbove, { type: 'Name' })) {
            previousElementUUID = cellAbove.elementUUID;
        }

        return createBlankElement(
            options.client,
            options.name,
            options.materialID,
            options.rates,
            options.uom,
            options.categoryID ?? undefined,
            options.projectID,
            options.takeoffUnitID,
            previousElementUUID,
            options.expression
        ).then((element) => {
            if (element === null) {
                return null;
            }

            const estimateElement = { ...element.elementAssignment, trade: null };
            const newCells = Serialize.Cells.rowFromElement(estimateElement, options.categoryID);
            Transforms.insertRows(editor, newCells, { at });

            return element;
        });
    },

    /**
     * Add already-saved elements to the uncategorized section.
     */

    addUncategorizedElements: (
        editor: Editor,
        elements: EstimateElement[],
        options: {
            at?: number;
        } = {}
    ): void => {
        let { at } = options;
        if (at === undefined) {
            const table = LeydenEditor.table(editor);
            const firstActionsCell = Table.column(table, {
                at: 0,
                type: 'Actions',
            }).next().value;
            if (firstActionsCell) {
                at = firstActionsCell[1].y;
            } else {
                at = 0;
            }
        }
        const estimateElements = elements.map((element) => ({ ...element, trade: null }));
        const newCells = Serialize.Cells.rowsFromElements(estimateElements, null);
        Transforms.insertRows(editor, newCells, { at });
    },

    /**
     * Delete an element. If `client` is passed, the deletion is persisted in the database.
     */

    deleteElement(editor: Editor, elementID: string, client?: ApolloClient): void {
        const groupAndItemOrderingV2Flag = LDService.variation(
            'GroupAndItemOrderingV2',
            false
        ) as boolean;
        let elementUUID: string | undefined;

        const table = LeydenEditor.table(editor);
        const rowsToDelete = new Set<number>();
        for (const [cell, coords] of Table.column(table, { at: 0, type: 'Name' })) {
            if (cell.elementID === elementID) {
                elementUUID = cell.elementUUID;
                rowsToDelete.add(coords.y);
            }
        }
        if (rowsToDelete.size === 0) {
            return;
        }

        if (client) {
            if (groupAndItemOrderingV2Flag) {
                if (elementUUID) {
                    deleteElementForV2Ordering(client, { elementUUID, elementID }).then(() => {
                        Transforms.deleteRows(editor, { at: rowsToDelete });
                    });
                }
            } else {
                deleteElement(client, elementID).then(() => {
                    Transforms.deleteRows(editor, { at: rowsToDelete });
                });
            }
        }
    },

    /**
     * Move an element up or down within its group. If `client` is passed, the move is persisted in the database.
     */

    reorderElement(
        editor: Editor,
        options: {
            at?: number;
            direction: 'up' | 'down';
            client?: ApolloClient;
        }
    ): void {
        const groupAndItemOrderingV2Flag = LDService.variation(
            'GroupAndItemOrderingV2',
            false
        ) as boolean;

        const { client, direction } = options;
        let { at } = options;
        if (at === undefined) {
            const selectedCoords = LeydenEditor.selectedCoords(editor);
            if (selectedCoords === null) {
                return;
            }
            at = selectedCoords.y;
        }
        const nameCell: Cell<'Name'> | null = Table.cell(LeydenEditor.table(editor), {
            at: { x: 0, y: at },
            type: 'Name',
        });
        if (nameCell === null) {
            return;
        }
        const to = direction === 'up' ? at - 1 : at + 1;

        let beforeNameCell: Cell<'Name'> | null = null;
        if (groupAndItemOrderingV2Flag) {
            let before = to;
            if (direction === 'down') {
                before++;
            }
            beforeNameCell = Table.cell(LeydenEditor.table(editor), {
                at: { x: 0, y: before },
                type: 'Name',
            });
        }

        Transforms.moveRow(editor, { at, to });

        if (client) {
            if (groupAndItemOrderingV2Flag) {
                reorderElementBeforeUUID(
                    client,
                    nameCell.elementUUID,
                    beforeNameCell?.elementUUID || null
                );
            } else {
                reorderElement(
                    client,
                    nameCell.elementID,
                    stringToElementReorderDirection[direction]
                );
            }
        }
    },

    /**
     * Edit an existing element.
     */

    editElement(
        editor: Editor,
        client: ApolloClient,
        options: {
            row: number;
            elementID: string;
            input: Pick<IElementAssignmentInput, 'name' | 'rate' | 'expression' | 'unitID'>;
        }
    ): Promise<IElementFragment | null> {
        const { elementID, input, row } = options;

        return editElement(client, elementID, input).then((response) => {
            if (!response?.elementAssignment) {
                return null;
            }

            const element = response.elementAssignment;

            const {
                name,
                unit,
                materialRate,
                laborRate,
                productionRate,
                equipmentRate,
                expression,
            } = element;

            for (const [cell, coords] of Table.row(LeydenEditor.table(editor), { at: row })) {
                if (Cell.isCell(cell, { type: 'Name' })) {
                    Transforms.setCell<'Name'>(editor, { name }, { at: coords });
                }
                if (Cell.isCell(cell, { type: 'Quantity' })) {
                    Transforms.setCell<'Quantity'>(
                        editor,
                        {
                            element: {
                                ...element,
                                expression,
                            },
                        },
                        { at: coords }
                    );
                }
                if (Cell.isCell(cell, { type: 'UnitOfMeasure' })) {
                    Transforms.setCell<'UnitOfMeasure'>(editor, { uom: unit }, { at: coords });
                }
                if (Cell.isCell(cell, { type: 'Rate' })) {
                    Transforms.setCell<'Rate'>(
                        editor,
                        {
                            materialRate,
                            laborRate,
                            productionRate,
                            equipmentRate,
                        },
                        { at: coords }
                    );
                }
            }

            return element;
        });
    },

    /**
     * Move an element to or out of a group.
     */

    moveElementToGroup(
        editor: Editor,
        options: {
            elementID: string;
            categoryID: string | null;
            client?: ApolloClient;
        }
    ): void {
        const { client, elementID, categoryID } = options;
        const element = Queries.element(editor, elementID);
        if (element === null || element[0].categoryID === categoryID) {
            return;
        }
        let finalPosition = 0;
        if (categoryID !== undefined) {
            const lastCategoryElement = Queries.elements(editor, {
                categoryID,
                reverse: true,
            }).next().value;
            if (!lastCategoryElement) {
                if (!categoryID) {
                    finalPosition = 0;
                } else {
                    const category = Queries.category(editor, categoryID);
                    if (!category) {
                        return;
                    }
                    finalPosition = category[1] + 1;
                }
            } else {
                finalPosition = lastCategoryElement[1] + 1;
            }
        }
        const currentTotal = EstimateEditor.getElementTotal(editor, elementID);
        if (currentTotal !== null) {
            EstimateEditor.recordElementTotal(
                editor,
                elementID,
                currentTotal.tradeName,
                currentTotal.categoryID,
                null
            );
            EstimateEditor.recordElementTotal(
                editor,
                elementID,
                currentTotal.tradeName,
                categoryID,
                currentTotal.val
            );
        }

        Editor.withoutNormalizing(editor, () => {
            for (const [, coords] of Table.row(LeydenEditor.table(editor), { at: element[1] })) {
                const path = LeydenEditor.cellPath(editor, { at: coords });
                Transforms.setNodes(editor, { categoryID }, { at: path });
            }
        });
        if (element[1] < finalPosition) {
            finalPosition--;
        }
        Transforms.moveRow(editor, {
            at: element[1],
            to: finalPosition,
        });
        if (client) {
            groupElement(client, elementID, categoryID);
        }
    },

    /**
     * Set an element's rates by passing in an aggregate cost per unit instead of discrete rates.
     */

    setElementCostPerUnit(
        editor: Editor,
        costPerUnit: number,
        cell: Cell<'Rate'>,
        client?: ApolloClient
    ): void {
        const coords = ReactEditor.cellCoords(editor, cell);
        if (!coords) {
            throw new Error(
                `Failed to set cost per unit: could not get coords: ${JSON.stringify(coords)}`
            );
        }
        const frontendRates = ratesFromCostPerUnit(costPerUnit);
        Transforms.setCell<'Rate'>(editor, frontendRates, { at: coords });
        if (client) {
            assignElementRates(client, cell.elementID, { costPerUnit }).then((res) => {
                if (res === null) {
                    return;
                }
                const backendRates = {
                    materialRate: res.elementAssignment.materialRate,
                    laborRate: res.elementAssignment.laborRate,
                    productionRate: res.elementAssignment.productionRate,
                };
                if (JSON.stringify(backendRates) !== JSON.stringify(frontendRates)) {
                    Transforms.setCell<'Rate'>(editor, backendRates, { at: coords });
                }
            });
        }
    },

    /**
     * Rename an assembly. If `client` is passed, the change is persisted to the database.
     */

    setCategoryName(
        editor: Editor,
        name: string,
        cell: Cell<'Category'>,
        client?: ApolloClient
    ): void {
        const coords = ReactEditor.cellCoords(editor, cell);
        if (!coords) {
            throw new Error(`Failed to set name: could not get coords: ${JSON.stringify(coords)}`);
        }
        Transforms.setCell<'Category'>(editor, { name }, { at: coords });
        if (client) {
            renameAssembly(client, cell.id, name);
        }
    },

    /**
     * Rename an element. If `client` is passed, the change is persisted to the database.
     */

    setElementName(editor: Editor, name: string, cell: Cell<'Name'>, client?: ApolloClient): void {
        const coords = ReactEditor.cellCoords(editor, cell);
        if (!coords) {
            throw new Error(`Failed to set name: could not get coords: ${JSON.stringify(coords)}`);
        }
        Transforms.setCell<'Name'>(editor, { name }, { at: coords });
        if (client) {
            renameElement(client, cell.elementID, name);
        }
    },

    /**
     * Set an element's rates. If `client` is passed, the change is persisted to the database.
     */

    setElementRates(
        editor: Editor,
        rates: ElementRates,
        cell: Cell<'Rate'>,
        client?: ApolloClient
    ): void {
        const coords = ReactEditor.cellCoords(editor, cell);
        if (!coords) {
            throw new Error(`Failed to set rates: could not get coords: ${JSON.stringify(coords)}`);
        }
        Transforms.setCell<'Rate'>(editor, rates, { at: coords });
        if (client) {
            assignElementRates(client, cell.elementID, rates);
        }
    },

    /**
     * Set an element's calculated quantity.
     */

    setElementQuantity(editor: Editor, elementID: string, quantity: number) {
        const table = LeydenEditor.table(editor);
        for (const [cell, coords] of Table.column(table, { at: 1, type: 'Quantity' })) {
            if (elementID === cell.elementID) {
                Transforms.setCell<'Quantity'>(
                    editor,
                    {
                        element: {
                            ...cell.element,
                            expression: {
                                ...cell.element.expression,
                                result: quantity,
                            },
                        },
                    },
                    { at: coords }
                );
                return;
            }
        }
    },

    /**
     * Set an element's unit of measure. If `client` is passed, the change is persisted to the database.
     */

    setElementUom(
        editor: Editor,
        uom: IUnitFragment,
        cell: Cell<'UnitOfMeasure'>,
        client?: ApolloClient
    ): void {
        const coords = ReactEditor.cellCoords(editor, cell);
        if (!coords) {
            throw new Error(`Failed to set uom: could not get coords: ${JSON.stringify(coords)}`);
        }
        Transforms.setCell<'UnitOfMeasure'>(editor, { uom }, { at: coords });
        if (client) {
            setElementUom(client, cell.elementID, uom.id);
        }
    },
};
