import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent } from 'react';
import { TakeoffComponentProps } from '@/components/takeoff/context';

const SCALES = {
    Architectural: {
        '1/16" = 1\' 0"': 1 / 16,
        '3/32" = 1\' 0"': 3 / 32,
        '1/8" = 1\' 0"': 1 / 8,
        '3/16" = 1\' 0"': 3 / 16,
        '1/4" = 1\' 0"': 1 / 4,
        '3/8" = 1\' 0"': 3 / 8,
        '1/2" = 1\' 0"': 1 / 2,
        '3/4" = 1\' 0"': 3 / 4,
        '1" = 1\' 0"': 1,
        '1 1/2" = 1\' 0"': 1 + 1 / 2,
        '3" = 1\' 0"': 3,
    },
    Engineering: {
        '1" = 10\' 0"': 1 / 10,
        '1" = 20\' 0"': 1 / 20,
        '1" = 30\' 0"': 1 / 30,
        '1" = 40\' 0"': 1 / 40,
        '1" = 50\' 0"': 1 / 50,
        '1" = 60\' 0"': 1 / 60,
        '1" = 70\' 0"': 1 / 70,
        '1" = 80\' 0"': 1 / 80,
        '1" = 90\' 0"': 1 / 90,
        '1" = 100\' 0"': 1 / 100,
    },
};

export interface StandardProps extends TakeoffComponentProps {
    close: () => void;
}

export const Standard: FunctionComponent<StandardProps> = ({ close, useTakeoff }) => {
    const { setScale, currentPage } = useTakeoff();

    const submitScale = (scaleValue: number) => {
        if (currentPage !== null && currentPage !== undefined) {
            setScale(scaleValue / (currentPage.heightIn / currentPage.heightPx));
            close();
        }
    };

    return (
        <Box
            sx={{
                height: '398px',
                overflowY: 'scroll',
                padding: '16px 0',
            }}
        >
            <Typography
                sx={{
                    marginBottom: '16px',
                    padding: '0 16px',
                }}
                variant="h4"
            >
                Standard Calibration
            </Typography>
            <Typography
                sx={{
                    padding: '0 16px',
                }}
                variant="h5"
            >
                Architectural
            </Typography>
            <List dense>
                {Object.entries(SCALES.Architectural).map(([scaleName, scaleValue]) => {
                    return (
                        <ListItemButton key={scaleName} onClick={() => submitScale(scaleValue)}>
                            {scaleName}
                        </ListItemButton>
                    );
                })}
            </List>
            <Typography
                sx={{
                    padding: '0 16px',
                }}
                variant="h5"
            >
                Engineering
            </Typography>
            <List dense>
                {Object.entries(SCALES.Engineering).map(([scaleName, scaleValue]) => {
                    return (
                        <ListItemButton key={scaleName} onClick={() => submitScale(scaleValue)}>
                            {scaleName}
                        </ListItemButton>
                    );
                })}
            </List>
        </Box>
    );
};
