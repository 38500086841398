import { AllowedURLSignUpRole } from '@/common/types';
import { ISignupInput, ITeamAssignmentType, IUserRole } from '@/graphql';

// Some roles are named inconsistently with their corresponding signup URLs,
// such as /signup/member creating a user with the "builder" role. In this
// instance, `urlToSignupRole` converts `member` to `builder`.
const urlToUserRole = (urlRole?: AllowedURLSignUpRole): IUserRole[] => {
    switch (urlRole) {
        case AllowedURLSignUpRole.Platform:
            return [IUserRole.Builder, IUserRole.Estimator];
        case AllowedURLSignUpRole.Builder:
            return [IUserRole.Builder];
        default:
        case AllowedURLSignUpRole.Estimator:
            return [IUserRole.Estimator];
    }
};

export const getTeamAssignmentInput = (signUpRole: AllowedURLSignUpRole): ISignupInput => {
    const onboardingID = localStorage.getItem('onboarding_id');
    const inviteID = localStorage.getItem('invite_id');

    let assignmentType: ITeamAssignmentType =
        signUpRole === AllowedURLSignUpRole.Estimator
            ? ITeamAssignmentType.Estimator
            : ITeamAssignmentType.Old;
    if (inviteID) {
        assignmentType = ITeamAssignmentType.Invite;
    } else if (onboardingID) {
        assignmentType = ITeamAssignmentType.Onboarding;
    }
    if (onboardingID || inviteID) {
        return {
            secretID: onboardingID || inviteID,
            type: assignmentType,
            roles: urlToUserRole(signUpRole),
        };
    } else {
        return { type: assignmentType, roles: urlToUserRole(signUpRole) };
    }
};
