import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import { styled as styledByMui } from '@mui/material/styles';

export const DialogTitle = styledByMui(MuiDialogTitle)((options) => ({
    color: options.theme.palette.hues.neutral[21],
}));

export const DialogContent = styledByMui(MuiDialogContent)((options) => ({
    color: options.theme.palette.hues.neutral[21],
}));
