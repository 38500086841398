import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';
import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';

import { ReactComponent as FullLogoV2 } from '@/assets/images/full-logo-v2.svg';

export const Logo = styled(FullLogoV2)`
    height: 32px;
    margin-left: -8px; // Nudge left to match designs

    & > path:first-child {
        fill: ${(props): string => props.color ?? 'currentColor'};
    }
`;

export const CallButton = styled(PrimaryButton)`
    border: 1px solid #d7e1e5;
    color: #1b2235;

    font-size: 12px;

    & > span {
        display: flex;
        align-items: center;
    }

    & svg {
        margin-right: 9.5px;
    }

    &:hover,
    &:focus,
    &:active {
        border-color: #d7e1e5;
    }
`;

export const CallLink = styled.a`
    ${breakpoints.min.small`
        display: none;
    `}
`;

export const CallCopy = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: right;

    display: none;

    & > a {
        color: #1b2235;

        display: inline-block;
        margin-left: 8px;
        font-weight: 600;
    }

    ${breakpoints.min.small`
        display: inline-block;
    `}
`;

export const Nav = styled.div`
    display: flex;

    & > ${Logo} {
        margin-right: auto;
    }
`;

export const Header = styled.h1`
    font-family: ${(props) => props.theme.v1.fontFamily.poppins};
    color: #1b2235;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    margin: 0;
`;

export const AlreadyAMemberWrapper = styled.span`
    font-size: 16px;
    line-height: 24px;
    color: #5969a1;

    & > a {
        color: #305dff;
        text-decoration: underline;
    }
`;

export const CopyrightLine = styled.span`
    display: inline-block;
    line-height: 20px;

    & > a {
        text-decoration: underline;
        color: #305dff;
    }
`;

export const Copyright = styled.div`
    display: flex;
    flex-direction: column;
    color: #5969a1;
    font-size: 14px;

    padding-top: 16px;
    border-top: 1px solid #d7e1e5;

    margin-top: auto;

    & > ${CopyrightLine} {
        margin-bottom: 4px;
    }

    ${breakpoints.min.small`
        flex-direction: row;

        & > ${CopyrightLine} {
            &:first-of-type {
                margin-right: auto;
            }
        }
    `}
`;

export const Top = styled.div`
    flex: 0;
`;
export const Center = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;
export const Bottom = styled.div`
    flex: 0;
`;

export const SignupV2Container = styled.div`
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    font-family: ${(props) => props.theme.v1.fontFamily.proximaNova};

    color: #1b2235;

    padding: 36px 24px 32px;
    overflow: hidden;

    & ${Nav} {
        margin-bottom: 36px;
    }

    & ${Header} {
        margin-bottom: 16px;
    }

    & ${AlreadyAMemberWrapper} {
        margin-bottom: 32px;
        display: inline-block;
    }

    ${breakpoints.min.medium`
        overflow: auto;
    `}
`;

export const SignupV2Section = styled.section`
    background: #ffffff;

    display: flex;
    flex-direction: column;

    ${breakpoints.min.medium`
        & > ${SignupV2Container} {
            width: 100%;
            max-width: 688px;
            margin: auto;
        }
    `}
`;

export const SignupV2Wrapper = styled.div`
    display: flex;
    min-height: 100vh;

    & > section {
        flex: 1;
    }
`;
