/*
 * A collection of helper methods meant to convert to and from the compressed
 * TWKB form of our internal Geometry type for storing in snapshots and sending
 * over the wire.
 */
import { fromPlanPageGeometry, toPlanPageGeometry } from '@/common/convert/geometry';
import { Geometry, PlanPageGeometry } from '@/common/types';
import { notNull } from '@/common/utils/helpers';

export const createSnapshot = (geometries: Geometry[]): PlanPageGeometry[] =>
    geometries.map(toPlanPageGeometry).filter(notNull);

export const loadSnapshot = (snapshotGeometries: PlanPageGeometry[]): Geometry[] =>
    snapshotGeometries.map(fromPlanPageGeometry);
