import styled from 'styled-components';

export const Row = styled.div<{ direction?: string }>`
    display: flex;
    flex-direction: ${(props): string => (props.direction ? props.direction : 'row')};
`;

export const Col = styled.div<{ flex?: number }>`
    font-weight: normal;
    flex: ${(props): number => (props.flex ? props.flex : 1)};
`;
