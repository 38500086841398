/*
 * serialize.ts provides a function which transforms a SlateJS Node array
 * into a markdown string.
 */
import { defaultNodeTypes, serialize as remarkSerialize } from '@1build/remark-slate';
import { SerializeOptions } from '@1build/remark-slate/src/serialize';
import { Descendant } from 'slate';

/**
 * @param nodes SlateJS nodes
 * @param opts remark-slate serialization options
 */
export function serialize(
    nodes: Descendant[],
    opts: SerializeOptions = { nodeTypes: defaultNodeTypes }
): string {
    // The remark-slate types are a bit off.
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return nodes.map((n: any) => remarkSerialize(n, opts)).join('');
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
