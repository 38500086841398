import React, { FC } from 'react';

import styled from 'styled-components';

import { TAB_KEYS } from './EstimatorPanel';
import { EstimatorStatus } from './EstimatorStatus';

import { TableRow } from '@/components/AdminDashboard/Table/styled';
import { Trades } from '@/components/ui/controls/Trades';
import { IClientPreferenceStatus, IUserFragment } from '@/graphql';
import { colorBackgroundSecondaryLightest } from '@/variables';

interface EstimatorProps {
    estimator: IUserFragment;
    isActive: boolean;
    onClick: (tabKey: string) => void;
}

const PREFERENCE_EMPTY = {
    PREFERRED: 'No preferred builders',
    NOT_PREFERRED: 'No blocked builders',
};

export const EstimatorTableRow: FC<EstimatorProps> = ({ estimator, onClick, isActive }) => {
    const fullName = `${estimator.firstName} ${estimator.lastName}`;
    const topTradeRatings = estimator.tradeRatings?.filter((tr) => tr.rating >= 3).slice(0, 3);
    const topTradesByRating = topTradeRatings?.map((tr) => tr.trade);

    const preferredClients = (status: IClientPreferenceStatus): string => {
        let names = '';
        let moreClients = 0;

        if (estimator.preferredClients && estimator.preferredClients.length > 0) {
            const filteredClients = estimator.preferredClients.filter(
                (value) => value.preference === status
            );
            filteredClients.forEach((value, index) => {
                if (index < 3) {
                    names =
                        filteredClients.length - 1 === index
                            ? names.concat(value.team.name)
                            : names.concat(`${value.team.name}, `);
                } else {
                    moreClients = moreClients + 1;
                }
            });
        }

        if (names === '' && status === IClientPreferenceStatus.Negative) {
            names = PREFERENCE_EMPTY.NOT_PREFERRED;
        }

        if (names === '' && status === IClientPreferenceStatus.Positive) {
            names = PREFERENCE_EMPTY.PREFERRED;
        }

        const nameToReturn = moreClients > 0 ? names.concat(` + ${moreClients} more`) : names;

        return nameToReturn;
    };

    return (
        <TableRowWrapper isActive={isActive}>
            <Cell
                clickable
                onClick={(): void => {
                    onClick(TAB_KEYS.ABOUT);
                }}
            >
                {fullName}
            </Cell>
            <Cell
                clickable
                onClick={(): void => {
                    onClick(TAB_KEYS.ABOUT);
                }}
            >
                <EstimatorStatus status={estimator.status} />
            </Cell>
            <Cell
                clickable
                onClick={(): void => {
                    onClick(TAB_KEYS.SKILLS);
                }}
            >
                {topTradesByRating && (
                    <Trades
                        trades={topTradesByRating}
                        howManyToShow={3}
                        background={colorBackgroundSecondaryLightest}
                    />
                )}
            </Cell>
            <Cell
                clickable
                empty={
                    preferredClients(IClientPreferenceStatus.Positive) ===
                    PREFERENCE_EMPTY.PREFERRED
                }
                onClick={(): void => {
                    onClick(TAB_KEYS.CLIENTS);
                }}
            >
                {preferredClients(IClientPreferenceStatus.Positive)}
            </Cell>
            <Cell
                clickable
                empty={
                    preferredClients(IClientPreferenceStatus.Negative) ===
                    PREFERENCE_EMPTY.NOT_PREFERRED
                }
                onClick={(): void => {
                    onClick(TAB_KEYS.CLIENTS);
                }}
            >
                {preferredClients(IClientPreferenceStatus.Negative)}
            </Cell>
        </TableRowWrapper>
    );
};

const TableRowWrapper = styled(TableRow)`
    &:hover {
        background: #f6faff;
    }
`;

const Cell = styled.td<{ clickable?: boolean; empty?: boolean }>`
    color: ${(props): string => (props.empty ? '#A5B3B9' : '#1B2235')};

    &:hover {
        background: ${(props): string => (props.clickable ? '#E6F2FF' : '')};
    }
`;
