import Flatten from '@flatten-js/core';

import { fromFlatten as coordinateDataFromFlatten } from '@/common/convert/coordinateData';
import { Geometry, GeometryType } from '@/common/types';

type Convert = {
    [GeometryType.AREA]: Flatten.Polygon;
    [GeometryType.COUNT]: Flatten.Point[];
    [GeometryType.LINEAR]: Flatten.Multiline;
};

export function fromFlatten<T extends GeometryType>(
    data: Convert[T],
    geometry: Omit<Geometry<T>, 'coordinates'>
): Geometry<T>;
export function fromFlatten(
    data: Flatten.Polygon | Flatten.Point | Flatten.Multiline,
    geometry: Omit<Geometry, 'coordinates'>
) {
    return { ...geometry, coordinates: coordinateDataFromFlatten(data) };
}
