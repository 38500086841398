import React, { FC } from 'react';

import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';
import { Absences } from './Absences';
import { PauseButton } from './PauseButton';
import { ResumeMatchingButton } from './ResumeMatchingButton';
import { Label, PanelDivider, RowItem, Value } from './styled';

import { formatDate } from '@/common/utils/helpers';
import { Availability, Avatar, Col, Row } from '@/components/ui/controls';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';

import { useUser } from '@/contexts/User';

import { IEstimatorStatus, IUserFragment, IWeekday } from '@/graphql';
import { colorTypographyDark } from '@/variables';

const Container = styled(Row)`
    margin: 1rem 0;
`;

const AvatarContainer = styled(Col)`
    display: flex;
    align-item: center;
    justify-content: center;
`;

const Divider = styled(PanelDivider)`
    margin: 1.5rem 0 2rem 0;
`;

const AvailabilityContainer = styled(Availability)`
    margin-left: -8px;
`;

const PauseContainer = styled(Col)`
    justify-content: center;
    display: flex;
    align-items: center;
`;

const Section = styled(Col)`
    margin: 0.5rem 0;
`;

const PausedSection = styled(Row)`
    padding: 1.5rem;
    background: #fef5f3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h3 {
        color: ${colorTypographyDark};
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
    }

    p {
        margin: 0 0 1rem 0;
    }
`;

const DEFAULT_DAYS: Record<IWeekday, boolean> = {
    [IWeekday.Monday]: false,
    [IWeekday.Tuesday]: false,
    [IWeekday.Wednesday]: false,
    [IWeekday.Thursday]: false,
    [IWeekday.Friday]: false,
    [IWeekday.Saturday]: false,
    [IWeekday.Sunday]: false,
};

interface AboutProps {
    selectedUser: IUserFragment;
    onPause: (paused: boolean) => void;
    loading: boolean;
}

export const About: FC<AboutProps> = ({ selectedUser, onPause, loading }) => {
    const {
        loading: { loadingFullUserInfo },
    } = useUser();

    const getNameInitials = (): string => {
        return `${selectedUser.firstName[0]}${selectedUser.lastName[0]}`;
    };

    const getAvailabilityDays = (): Record<IWeekday, boolean> => {
        if (selectedUser.schedule && selectedUser.schedule.availability) {
            const mutatedDefaultDays = { ...DEFAULT_DAYS };

            selectedUser.schedule.availability.map((item) => {
                const itemExists = Object.keys(DEFAULT_DAYS).some((key) => key === item);

                if (itemExists) {
                    mutatedDefaultDays[item] = true;
                }
            });

            return mutatedDefaultDays;
        } else {
            return DEFAULT_DAYS;
        }
    };

    return (
        <Container direction="column">
            <Row>
                <Col flex={9}>
                    <RowItem>
                        <Col flex={3}>
                            <Label>Email</Label>
                        </Col>
                        <Col flex={9}>
                            <Value>
                                {loadingFullUserInfo ? (
                                    <DotsLoader className="info" />
                                ) : (
                                    selectedUser.email
                                )}
                            </Value>
                        </Col>
                    </RowItem>
                    <RowItem>
                        <Col flex={3}>
                            <Label>Phone</Label>
                        </Col>
                        <Col flex={9}>
                            <Value>{selectedUser.phone}</Value>
                        </Col>
                    </RowItem>
                    <RowItem>
                        <Col flex={3}>
                            <Label>Timezone</Label>
                        </Col>
                        <Col flex={9}>
                            <Value>{selectedUser.timezone}</Value>
                        </Col>
                    </RowItem>
                    <RowItem>
                        <Col flex={3}>
                            <Label>Joined</Label>
                        </Col>
                        <Col flex={9}>
                            <Value>{formatDate(selectedUser.createdAt)}</Value>
                        </Col>
                    </RowItem>
                </Col>
                <AvatarContainer flex={3}>
                    <Avatar>{getNameInitials()}</Avatar>
                </AvatarContainer>
            </Row>
            <Divider />
            {loadingFullUserInfo || loading ? (
                <CircularProgress />
            ) : selectedUser.status !== IEstimatorStatus.Paused ? (
                <>
                    <Section>
                        <Col flex={12}>
                            <Label>Availability</Label>
                        </Col>
                        <Col flex={12}>
                            <Row>
                                <Col flex={9}>
                                    <AvailabilityContainer disabled days={getAvailabilityDays()} />
                                </Col>
                                <PauseContainer flex={3}>
                                    <PauseButton
                                        onClick={(): void => onPause(true)}
                                        disabled={!selectedUser.schedule}
                                    />
                                </PauseContainer>
                            </Row>
                        </Col>
                    </Section>
                    {selectedUser.schedule?.unavailable &&
                        selectedUser.schedule.unavailable.length > 0 && (
                            <Section>
                                <Col flex={12}>
                                    <Label>Upcoming away days:</Label>
                                </Col>
                                <Absences absences={selectedUser.schedule.unavailable} />
                            </Section>
                        )}
                </>
            ) : (
                <PausedSection>
                    <Col>
                        <h3>Matching paused for this estimator</h3>
                    </Col>
                    <Col>
                        <p>
                            The estimator will not be matched with projects while they are paused.
                            Resume matching when this estimator is once again eligible for future
                            projects.
                        </p>
                    </Col>
                    <Col>
                        <ResumeMatchingButton onClick={(): void => onPause(false)} />
                    </Col>
                </PausedSection>
            )}
        </Container>
    );
};
