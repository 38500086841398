/*
 * events.ts provides GraphQL queries used to fetch records from the
 * `events` table.
 */
import { frogmint } from '../frog';

export enum EventTypeName {
    AcceptAssignment = 'accept_assignment',
    ApproveEstimate = 'approve_estimate',
    AssignEstimator = 'assign_estimator',
    CreateProject = 'create_project',
    CreateRating = 'create_rating',
    EditMessage = 'edit_message',
    EditProject = 'edit_project_details',
    DownloadEstimate = 'download_estimate',
    DeclineAssignment = 'decline_assignment',
    PostMessage = 'post_message',
    RemoveMessage = 'remove_message',
    UnassignEstimator = 'unassign_estimator',
    UploadProjectRelatedFiles = 'upload_project_related_files',
    CancelProject = 'cancel_project',
    PublicEstimateView = 'public_estimate_view',
    CreditsRefunded = 'credits_refunded',
}

export type AnyEventTypeName = EventTypeName | string;

export interface EventTypeRecord {
    created?: string;
    lastModified?: string;
    name: AnyEventTypeName;
    description?: string;
    sendsToNotifier: boolean;
    sendsToDiscussion: boolean;
    sendsToActivityFeed: boolean;
    sendsToToast?: boolean;
    severity?: string;
}

export interface EventTypeCondition {
    name?: string;
    sendsToActivityFeed?: boolean;
}

export const eventTypeFragment = frogmint`
    ${'EventType_eventType'} {
        created
        lastModified
        name
        description
        sendsToNotifier
        sendsToDiscussion
        sendsToActivityFeed
        sendsToToast
        severity
    }
`;
