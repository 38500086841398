import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectOwnerBadge } from '@/components/projects/ProjectOwnerBadge';
import { Container, OwnProjectButton } from './styled';
import { IconButton } from '@/components/ui/buttons/IconButton';
import { ReactComponent as Cancel } from '@/assets/icons/cancel.svg';
import { CircleIcon } from '@/components/ui/icons/CircleIcon';
import { useUser } from '@/contexts/User';
import { Modal } from './Modal';
import {
    assignEstimatorMutation,
    AssignEstimatorResult,
    unassignEstimatorMutation,
} from '@/mutations/assignEstimator';

const COPY = {
    ownProject: 'Own project',
};

type ProjectOwnerManagementProps = {
    user?: BaseUserRecord;
    projectId: number;
    className?: string;
};
export const ProjectOwnerManagement: FC<ProjectOwnerManagementProps> = ({
    user,
    projectId,
    className,
}) => {
    const {
        data: { user: currentUser },
    } = useUser();
    const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

    const [, unassignEstimatorFromProject] =
        useMutation<AssignEstimatorResult>(unassignEstimatorMutation);
    const [, assignEstimatorToProject] =
        useMutation<AssignEstimatorResult>(assignEstimatorMutation);

    const unassign = (): void => {
        unassignEstimatorFromProject({
            projectId: projectId,
            ownerId: currentUser.id,
            userId: user?.id,
        }).then((_) => setIsUnassignModalOpen(false));
    };

    const assign = (): void => {
        assignEstimatorToProject({
            projectId: projectId,
            ownerId: currentUser.id,
            userId: currentUser.id,
        }).then((_) => setIsAssignModalOpen(false));
    };

    return (
        <Container className={className}>
            <ProjectOwnerBadge v2 user={user} background={false} />
            {currentUser.id === `${user?.id || ''}` && (
                <CircleIcon
                    className="unassign-owner"
                    diameter="20px"
                    background="rgba(255, 255, 255, 0.24)"
                >
                    <IconButton
                        onClick={(e): void => {
                            e.preventDefault();

                            setIsUnassignModalOpen(true);
                        }}
                        className="autosize"
                        Icon={Cancel}
                        noInherit={true}
                    />
                </CircleIcon>
            )}
            {!user && (
                <OwnProjectButton
                    onClick={(e): void => {
                        e.preventDefault();

                        setIsAssignModalOpen(true);
                    }}
                >
                    {COPY.ownProject}
                </OwnProjectButton>
            )}
            {isUnassignModalOpen && (
                <Modal
                    close={(): void => setIsUnassignModalOpen(false)}
                    submit={unassign}
                    unassign={true}
                />
            )}
            {isAssignModalOpen && (
                <Modal
                    close={(): void => setIsAssignModalOpen(false)}
                    submit={assign}
                    unassign={false}
                />
            )}
        </Container>
    );
};
