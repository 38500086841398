import to from 'await-to-js';
import Container from '@mui/material/Container';
import ScopedCssBaseLine from '@mui/material/ScopedCssBaseline';
import { writeStorage } from '@rehooks/local-storage';
import { Elements as ElementsProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import capitalize from 'lodash/capitalize';
import React, { FC, useState } from 'react';
import { Env } from '@/common/env';
import { Referrer } from '@/common/referrer';
import { StorageKey } from '@/common/storage';
import { useUser } from '@/contexts/User';
import { Mode } from '@/theme/Mode';
import { useSubscriptionStartMutation } from '@/graphql';
import { Plan, PlanName, PlanPeriod, PlanVariant } from './types';
import { customers } from './customers';
import { plans } from './plans';
import { getPlanByVariantId, getVisiblePlans, hasMonthlySubscription, hasPaidPlan } from './utils';
import {
    Cards,
    CustomersHeading,
    CustomersList,
    CustomersListItem,
    Main,
    MainHeading,
    PlanCard,
} from './styled';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNotifications } from '@/contexts/Notifications';
import { TrackEventName, useSegmentTrackEventWithUserData } from '@/common/analytics';

const COPY = {
    confirmationTitle: (planName: PlanName | '') => `Subscribe to the ${capitalize(planName)} plan`,
    confirmationDescription: (planName: PlanName | '', planFrequency: PlanPeriod | '') =>
        `Click Subscribe to confirm your purchase of the ${capitalize(
            planName
        )} plan billed ${planFrequency}. The card you provided at signup will be charged.`,
    cancel: 'Cancel',
    confirm: 'Subscribe',
    plansTitle: 'Select a plan that works for your needs',
    customersHeading: 'Used by 3,000 builders just like you',
    errorMessage: 'There was a problem subscribing you to the selected plan. Please try again',
};

type Props = React.HTMLAttributes<HTMLDivElement>;

export const PricingPage: FC<Props> = (props) => {
    const user = useUser();
    const history = useHistory();
    const flags = useFlags();
    const { addNotification } = useNotifications();
    const track = useSegmentTrackEventWithUserData();

    // Wrap loadStripe in use-state so safe across multiple renders
    // See: https://stripe.com/docs/stripe-js/react#elements-provider
    const [stripePromise] = useState(loadStripe(Env.stripePublicKey));

    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const [selectedPlanVariant, setSelectedPlanVariant] = useState<PlanVariant>();

    const [startSubscription, { loading }] = useSubscriptionStartMutation();

    const handleOpenConfirmationModal = (variant?: PlanVariant) => {
        const plan = getPlanByVariantId(plans, variant?.id);

        setSelectedPlan(plan);
        setSelectedPlanVariant(variant);
    };

    const handlePlanSelect = async (variant?: PlanVariant) => {
        setSelectedPlanVariant(undefined);

        const [stripeError, stripe] = await to(stripePromise);

        if (stripeError) {
            return;
        }

        startSubscription({
            variables: {
                input: {
                    subscriptionPlan: variant?.id,
                    url: Env.deploymentURL,
                },
            },
            onCompleted: (data) => {
                // Write referrer before redirect for stripe referrer logic on return.
                writeStorage(StorageKey.Referrer, Referrer.Stripe);

                const priceId = data.subscriptionStart.user.team?.subscription?.priceID;

                if (priceId) {
                    // Write the current price-id so on return the app can compare the
                    // user's subscription price-id to this checkout price-id.
                    writeStorage(StorageKey.CheckoutPriceId, priceId);
                }
                if (data?.subscriptionStart.sessionID !== null) {
                    stripe?.redirectToCheckout({
                        sessionId: data?.subscriptionStart.sessionID || '',
                    });
                } else {
                    history.push('/');
                }

                track(TrackEventName.NewSubscriptionStarted, {
                    plan: {
                        ...getPlanByVariantId(plans, variant?.id),
                        ...variant,
                    },
                });
            },
            onError: (error) => {
                addNotification(
                    {
                        title: 'Error',
                        content: Env.tier.isDevelopment ? String(error) : COPY.errorMessage,
                    },
                    'error'
                );
            },
        });
    };

    const visiblePlans = getVisiblePlans(plans, user.data.user.team);

    return (
        <ElementsProvider stripe={stripePromise}>
            <ScopedCssBaseLine>
                <Main {...props}>
                    <Mode variant="light">
                        <Container>
                            <MainHeading variant="h1">{COPY.plansTitle}</MainHeading>
                            <Cards styled={{ count: visiblePlans?.length }}>
                                {visiblePlans?.map((plan) => (
                                    <PlanCard
                                        key={plan.name}
                                        current={
                                            hasPaidPlan(plan, user.data.user.team) &&
                                            !hasMonthlySubscription(plans, user.data.user.team)
                                        }
                                        loading={loading}
                                        onPlanSelect={
                                            flags.collectCreditCardFlow
                                                ? handleOpenConfirmationModal
                                                : handlePlanSelect
                                        }
                                        plan={plan}
                                        styled={{ count: visiblePlans.length }}
                                    />
                                ))}
                            </Cards>
                            <div>
                                <CustomersHeading variant="h2">
                                    {COPY.customersHeading}
                                </CustomersHeading>
                                <CustomersList>
                                    {customers.map((customer) => (
                                        <CustomersListItem key={customer} src={customer} />
                                    ))}
                                </CustomersList>
                            </div>
                        </Container>
                        {flags.collectCreditCardFlow && (
                            <ConfirmationDialog
                                open={!!selectedPlanVariant}
                                title={COPY.confirmationTitle(selectedPlan?.name ?? '')}
                                description={COPY.confirmationDescription(
                                    selectedPlan?.name ?? '',
                                    selectedPlanVariant?.period ?? ''
                                )}
                                onCancel={() => {
                                    setSelectedPlanVariant(undefined);
                                }}
                                onConfirm={() => {
                                    handlePlanSelect(selectedPlanVariant);
                                }}
                                onConfirmText={COPY.confirm}
                            />
                        )}
                    </Mode>
                </Main>
            </ScopedCssBaseLine>
        </ElementsProvider>
    );
};
