/**
 * Passing background and diameter is mandatory. Background props is used t
 * to set the background of the css background property of the circle.
 **/

import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';

import './CircleIcon.scss';

export interface CircleIconProps {
    className?: string;
    diameter: string;
    background?: string;
    border?: string;
    v2?: boolean;
    color?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    style?: CSSProperties;
}

export const CircleIcon: FC<CircleIconProps> = ({
    className,
    diameter,
    background,
    border,
    onClick,
    v2,
    color,
    style,
    children,
}) => {
    return (
        <div
            className={clsx(className, 'circle-icon', v2 && 'circle-icon--v2')}
            style={{
                width: diameter,
                height: diameter,
                background:
                    v2 && color
                        ? `linear-gradient(146.31deg, #FFFFFF 13.75%, #000000 95%), ${color}`
                        : background,
                border: border,
                ...style,
            }}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
