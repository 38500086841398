import React, { FC, useEffect, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import Lottie from 'react-lottie';
import { useMutation } from 'urql';

import {
    Body,
    BodyWrapper,
    Container,
    ContentWrapper,
    DesktopImage,
    Disclaimer,
    Header,
    HeaderWrapper,
    IllustrationWrapper,
    LeftContent,
    MobileImage,
    Overlay,
    PageNumber,
    RightContent,
    SubmitButton,
    SubmitContainer,
    Warning,
} from './styled';
import { onClickAndEnterKey } from '@/common/utils/eventHandlers';
import { setTeamOnboardingWelcomed } from '@/mutations/setTeamOnboardingWelcomed';

import mainSrc from '@/assets/images/welcomeBackground.png';
import mainMobileSrc from '@/assets/images/welcomeBackgroundMobile.png';
import chatAnimation from '@/assets/animations/chat.json';
import estimateAnimation from '@/assets/animations/estimate.json';
import projectSetupAnimation from '@/assets/animations/project-setup.json';
import takeoffAnimation from '@/assets/animations/takeoff.json';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createSaasProject, useProjectSetup } from '@/common/hooks/useProjectSetup';
import { ErrorMessage } from '@/components/notifications/Notification';
import { useNotifications } from '@/contexts/Notifications';

const COPY = {
    mainStart: 'Let’s get started',
    stepNext: 'Next',
    stepComplete: 'Done',

    mainHeader: 'Welcome to your 14 day free trial!',
    mainHeaderCCFlow: 'Welcome to your first two weeks of 1build!',
    mainBody:
        'To get the most out of your trial, let’s start by walking through the tools you’ll need to build a professional estimate.',

    introHeader:
        '1build allows you to rapidly obtain detailed cost estimates for your construction projects',
    introBody:
        'Get accurate cost estimates in an interactive and easy to read format. ' +
        'Estimates are produced in one of two ways...',

    estimatorHeader: 'Rely on one of our expert estimators to complete an estimate for you*',
    estimatorBody:
        '1build has a team of experienced estimators standing by to perform your estimate for you. ' +
        'Stay up to date on their progress with our in-platform chat tool.',
    estimatorDisclaimer:
        '*Pricing varies per project and is determined shortly after project submission.',
    estimatorWarning: 'These projects are not part of the free trial.',

    takeOffHeader: 'Or complete an estimate yourself with 1build’s takeoff and estimation tools',
    takeOffBody:
        'We’ve developed tools to make the estimating process easier, faster, and more ' +
        'affordable than other tools on the market. ',

    projectTypeHeader: 'Choose which project type you’d like at project setup',
    projectTypeBody:
        'Each project has unique needs, this is why we let you choose self or full service ' +
        'at the beginning of each new project you create.',
    createProjectSuccessTitle: 'Success.',
    createProjectSuccessMsg: 'Project created successfully.',
    createProjectErrorTitle: 'Error.',
    createProjectErrorMsg: 'Unable to create project.',
};

const modalContent = (collectCreditCardFlowFlag: boolean) => [
    {
        illustration: (
            <>
                <DesktopImage src={mainSrc} css={'width: 100%;'} />
                <MobileImage src={mainMobileSrc} css={'height: 100%;'} />
            </>
        ),
        header: collectCreditCardFlowFlag ? COPY.mainHeaderCCFlow : COPY.mainHeader,
        body: COPY.mainBody,
        button: COPY.mainStart,
    },
    {
        illustration: (
            <Lottie
                options={{
                    loop: false,
                    animationData: estimateAnimation,
                }}
            />
        ),
        header: COPY.introHeader,
        body: COPY.introBody,
        button: COPY.stepNext,
    },
    {
        illustration: (
            <Lottie
                options={{
                    loop: false,
                    animationData: chatAnimation,
                }}
            />
        ),
        header: COPY.estimatorHeader,
        body: COPY.estimatorBody,
        button: COPY.stepNext,
        disclaimer: COPY.estimatorDisclaimer,
        warning: COPY.estimatorWarning,
    },
    {
        illustration: (
            <Lottie
                options={{
                    loop: false,
                    animationData: takeoffAnimation,
                }}
            />
        ),
        header: COPY.takeOffHeader,
        body: COPY.takeOffBody,
        button: COPY.stepNext,
    },
    {
        illustration: (
            <Lottie
                options={{
                    loop: false,
                    animationData: projectSetupAnimation,
                }}
            />
        ),
        header: COPY.projectTypeHeader,
        body: COPY.projectTypeBody,
        button: COPY.stepComplete,
    },
];

interface OnboardingModalProps {
    close: () => void;
    teamId: number;
}

export const OnboardingModal: FC<OnboardingModalProps> = ({ teamId }) => {
    const history = useHistory();
    const { collectCreditCardFlow } = useFlags();

    const [isMounted, setIsMounted] = useState(false);
    const [step, setStep] = useState(0);
    const [shownStep, setShownStep] = useState<number>(0);

    const [, setTeamWelcomed] = useMutation(setTeamOnboardingWelcomed);

    const currentModalContent = modalContent(collectCreditCardFlow);
    const { submitConfiguredProject } = useProjectSetup();
    const { addNotification } = useNotifications();

    // Trigger animation on modal mount
    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        const sto = setTimeout(() => {
            setShownStep(step);
        }, 50);

        return (): void => {
            clearTimeout(sto);
        };
    }, [step]);

    const handleSubmitButtonClick = () => {
        if (step < currentModalContent.length - 1) {
            setStep((lastStep) => lastStep + 1);
        } else {
            setTeamWelcomed({
                welcomed: true,
                teamId,
            });

            localStorage.setItem(`teamWelcomed--${teamId}`, 'true');

            submitConfiguredProject(createSaasProject())
                .then((res) => {
                    addNotification(
                        {
                            title: COPY.createProjectSuccessTitle,
                            content: <p>{COPY.createProjectSuccessMsg}</p>,
                        },
                        'success'
                    );

                    history.push(`/projects/${res.uuid}/estimate`);
                })
                .catch(() => {
                    addNotification(
                        {
                            title: COPY.createProjectErrorTitle,
                            content: <ErrorMessage message={COPY.createProjectErrorMsg} />,
                        },
                        'error'
                    );
                });
        }
    };

    return (
        <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
            <Overlay>
                <Container isMounted={isMounted} isMain={step === 0}>
                    <ContentWrapper isLeft>
                        {currentModalContent.map(({ illustration }, i) => (
                            <LeftContent
                                key={i}
                                isActive={shownStep === i}
                                css={`
                                    z-index: ${step === i || shownStep === i ? 1 : 0};
                                `}
                            >
                                <IllustrationWrapper isMain={step === 0}>
                                    {shownStep === i && illustration}
                                </IllustrationWrapper>
                            </LeftContent>
                        ))}
                    </ContentWrapper>

                    <ContentWrapper>
                        {currentModalContent.map(
                            ({ header, body, button, disclaimer, warning }, i) => {
                                const isMain = i === 0;

                                return (
                                    <RightContent
                                        key={header}
                                        isMain={isMain}
                                        isActive={shownStep === i}
                                        css={`
                                            z-index: ${step === i || shownStep === i ? 1 : 0};
                                        `}
                                    >
                                        <HeaderWrapper isMain={isMain}>
                                            <Header>{header}</Header>
                                        </HeaderWrapper>

                                        <BodyWrapper isMain={isMain}>
                                            <Body>
                                                <span>{body}</span>

                                                {disclaimer && (
                                                    <Disclaimer>{disclaimer}</Disclaimer>
                                                )}

                                                {warning && <Warning>{warning}</Warning>}
                                            </Body>
                                        </BodyWrapper>

                                        <SubmitContainer isMain={isMain}>
                                            <PageNumber>
                                                {i} of {currentModalContent.length - 1}
                                            </PageNumber>

                                            <SubmitButton
                                                tabIndex={step === i ? 0 : -1}
                                                isDone={i === currentModalContent.length - 1}
                                                {...onClickAndEnterKey(() => {
                                                    handleSubmitButtonClick();
                                                })}
                                            >
                                                {button}
                                            </SubmitButton>
                                        </SubmitContainer>
                                    </RightContent>
                                );
                            }
                        )}
                    </ContentWrapper>
                </Container>
            </Overlay>
        </FocusTrap>
    );
};
