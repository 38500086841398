import { Hue } from '../types';

export const neutral: Hue = {
    0: '#000000',
    21: '#1B2235',
    32: '#313952',
    48: '#65667A',
    78: '#BABFC7',
    94: '#E6ECEF',
    98: '#F0F4F9',
    100: '#FFFFFF',
};
