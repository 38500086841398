/*
 * BugsnagErrorBoundary.ts configures and exports a Bugsnag client and
 * application wrapper. Uncaught errors are automatically reported to Bugsnag
 * and additional notifications or errors can be sent manually through the
 * `Bugsnag` export. If no Bugsnag key is set in the environment, the
 * `ErrorBoundary` wrapper is a no-op and `Bugsnag` is undefined.
 */
import React, { ComponentType, FC } from 'react';
import BugsnagJS, { Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { Env } from '@/common/env';
import { Tier } from '@/common/types';

const initializeBugsnag = (): { client?: Client; errorBoundary: ComponentType } => {
    if (!Env.bugsnagAPIKeyFrontend || Env.tier.isTest || Env.tier.isDevelopment) {
        const DefaultErrorBoundary: FC = ({ children }) => <>{children}</>;

        return { client: undefined, errorBoundary: DefaultErrorBoundary };
    }

    const client = BugsnagJS.start({
        apiKey: Env.bugsnagAPIKeyFrontend,
        enabledReleaseStages: [Tier.Production, Tier.Staging],
        plugins: [new BugsnagPluginReact()],
        releaseStage: Env.tier.value || undefined,
        appVersion: Env.release,
    });

    const errorBoundary = BugsnagJS.getPlugin('react')?.createErrorBoundary(React) as ComponentType;

    return { client, errorBoundary };
};

const { client: Bugsnag, errorBoundary: ErrorBoundary } = initializeBugsnag();

export { Bugsnag, ErrorBoundary };
