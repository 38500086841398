/**
 * GeometryBarOption is a single option in the `GeometryBar` component. Through
 * the `active` property, parent components can apply a highlighted style to
 * a GeometryBarOption.
 */

import clsx from 'clsx';
import React, { FC } from 'react';

import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { Svg } from '@/common/types';

import './GeometryBarOption.scss';

export interface GeometryBarOptionProps {
    active: boolean;
    iconPath: Svg;
    onClick: () => void;
}

export const GeometryBarOption: FC<GeometryBarOptionProps> = ({ active, iconPath, onClick }) => {
    const geometryBarOptionClass = clsx('geometry-bar-option-container', {
        'geometry-bar-option-active': active,
    });

    return (
        <div className={geometryBarOptionClass}>
            <SvgIcon src={iconPath} onClick={onClick} noInherit={true} />
        </div>
    );
};
