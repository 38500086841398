import { useCallback } from 'react';
import { Env } from './env';
import { getTimestamp } from './time';

import { camelObjectToSnakeCaseObject } from '@/common/utils/helpers';
import { ITeamFragment, IUserFragment } from '@/graphql';
import { useUser } from '@/contexts/User';
import { getSavedQueryParams } from '@/common/queryParams';
import posthog from 'posthog-js';

const POSTHOG_API_KEY = 'phc_wwX1BiiGfM5Nmz2JMiDHB7guedmovd80jX6mvDG7CQr';

export const setup = () => {
    if (!Env.segmentIoWriteKey) {
        return;
    }

    posthog.init(POSTHOG_API_KEY, {
        api_host: 'https://app.posthog.com',
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true,
            },
        },
    });

    window.analytics.load(Env.segmentIoWriteKey);
};

// This function takes a property from the traits and map it into a value that HubSpot recognizes
const addHubspotMappings = (traits: Partial<IUserFragment> & Record<string, unknown>) => {
    // Check here the internal name of the properties:
    // https://app.hubspot.com/property-settings/6808098/properties?type=0-1
    const mappings = {
        bidValue: 'what_is_a_typical_project_value_for_your_business_',
    };

    return {
        ...traits,
        ...Object.entries(mappings).reduce(
            (accum, [key, value]) => ({
                ...accum,
                [value]: traits[key],
            }),
            {}
        ),
    };
};

const getUserProperties = (user: IUserFragment) => ({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    createdAt: getTimestamp(user.createdAt),
    roles: user.roles,
    accountType: user.team?.onboarding?.accountType,
    hasPaymentMethod: user.team?.hasPaymentMethod,
    bidValue: user.bidValue,
    businessType: user.businessType,
    companyRole: user.companyRole,
    portalService: user.serviceType,
});

const getTeamProperties = (team: ITeamFragment) => ({
    name: team.name,
});

export const identify = (userRecord: IUserFragment, additionalFields?: Record<string, unknown>) => {
    if (!Env.segmentIoWriteKey) {
        return;
    }

    const segmentUserTraits = addHubspotMappings({
        ...getUserProperties(userRecord),
        isBeingImpersonated: !!localStorage.getItem('impersonatedAuthID'),
        // TODO: This can be updated once the backend resolver carries the information
        projectTypes: '',
        // TODO: This can be updated once the backend resolver carries the information
        hasMarketplace: true,
        ...additionalFields,
        ...getSavedQueryParams(),
    });

    window.analytics.setAnonymousId(posthog.get_distinct_id());

    if (userRecord.authID) {
        window.analytics.identify(userRecord.id, segmentUserTraits);
    } else {
        window.analytics.identify(segmentUserTraits);
    }
    window._cio.identify({
        id: userRecord.id,
        email: segmentUserTraits.email,
        created_at: getTimestamp(userRecord.createdAt),
        ...camelObjectToSnakeCaseObject(segmentUserTraits),
    });

    if (userRecord.team) {
        window.analytics.group(userRecord.team.id, getTeamProperties(userRecord.team));
    }
};

export const page = (name?: string, routeProperties?: AnyPayload) => {
    if (!Env.segmentIoWriteKey) {
        return;
    }

    window.analytics.page(name, routeProperties);
};

export enum TrackEventName {
    AppDetailsSubmitted = 'app details submitted',
    FilesUploaded = 'files uploaded',
    GeometriesAdded = 'geomatries added',
    CostDataOpened = 'cost data opened',
    InvalidFileFormat = 'invalid file format',
    InvalidFileSize = 'invalid file size',
    MarkupExportRan = 'markup export ran',
    EstimateLinkCopied = 'estimate link copied',
    EstimateLinkViewed = 'estimate link viewed',
    PdfExportSelected = 'pdf export selected',
    ExcelExportSelected = 'excel export selected',
    PaymentSetupCompleted = 'payment setup completed',
    TrialCancelled = 'trial cancelled',
    NewSubscriptionStarted = 'new subscription started',
}

export const track = (eventName: TrackEventName, options?: Record<string, unknown>) => {
    if (!Env.segmentIoWriteKey) {
        return;
    }

    window.analytics.track(eventName.toString(), options);
};

export const useSegmentTrackEventWithUserData = () => {
    const {
        data: { user },
        loading: { loadedUser },
    } = useUser();

    // Same traits as the identify call
    const defaultTraits = addHubspotMappings({
        ...getUserProperties(user),
        isBeingImpersonated: !!localStorage.getItem('impersonatedAuthID'),
    });

    return useCallback(
        (eventName: TrackEventName, traits?: Record<string, unknown>) => {
            if (!loadedUser) {
                return;
            }

            return track(eventName, { ...defaultTraits, ...traits });
        },
        [defaultTraits, loadedUser]
    );
};
