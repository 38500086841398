/*
 * uom.ts provides uom-related data and mapping functions.
 */
import { IUnitFragment, IUnitType } from '@/graphql';
import { UomRecord, UomType } from '@/queries/unitOfMeasure';

enum Units {
    LF = 'LF',
    SF = 'SF',
    CF = 'CF',
    EA = 'EA',
}

// uom{dimension} are the default units of measure for each dimension.
export const uomOneD: UomRecord = {
    id: 3,
    definition: '1 ft',
    description: 'Linear Feet',
    name: Units.LF,
    uomType: UomType.D1,
};
export const uomTwoD: UomRecord = {
    id: 8,
    definition: '1 F^2',
    description: 'Square Feet',
    name: Units.SF,
    uomType: UomType.D2,
};
export const uomThreeD: UomRecord = {
    id: 18,
    definition: '1 F^3',
    description: 'Cubic Feet',
    name: Units.CF,
    uomType: UomType.D3,
};
export const uomEach: UomRecord = {
    id: 9,
    definition: '1',
    description: 'Each',
    name: Units.EA,
    uomType: UomType.ITEM,
};

export const UomTypeApolloToPostgraphile: Record<UomType, IUnitType> = {
    [UomType.D1]: IUnitType.D1,
    [UomType.D2]: IUnitType.D2,
    [UomType.D3]: IUnitType.D3,
    [UomType.ITEM]: IUnitType.Item,
};

export const uomPostgraphileToApollo = (uom: UomRecord): IUnitFragment => ({
    id: uom.id.toString(),
    definition: uom.definition,
    description: uom.description,
    name: uom.name,
    unitType: UomTypeApolloToPostgraphile[uom.uomType],
});
