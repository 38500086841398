import styled from 'styled-components';

export const AccordionWrapper = styled.div({
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    width: '100%',

    borderTop: '1px solid #D7E1E5',

    '&:last-child:not(first-child)': {
        borderBottom: '1px solid #D7E1E5',
    },
});

export const AccordionToggler = styled.button({
    position: 'relative',

    display: 'flex',
    alignItems: 'center',

    width: '100%',

    outline: 'none',
    border: 'none',
    background: 'transparent',
    color: '#000000',

    minHeight: '50px',
    cursor: 'default',

    '> svg': {
        marginRight: '10px',
        cursor: 'pointer',
    },
});

export const AccordionContent = styled.div({
    position: 'relative',
    padding: '0 0 15px',
    width: '100%',
});
