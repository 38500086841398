import { Cell, CellType } from 'leyden';

import { ElementCell, Operator, Parenthesis } from './types';

export const isElementCell = (cell: Cell<CellType>): cell is ElementCell =>
    Cell.isCell(cell, { type: 'Name' }) ||
    Cell.isCell(cell, { type: 'Quantity' }) ||
    Cell.isCell(cell, { type: 'Rate' }) ||
    Cell.isCell(cell, { type: 'Total' }) ||
    Cell.isCell(cell, { type: 'UnitOfMeasure' });

export const isOperator = (val: string): val is Operator => {
    return ['+', '-', '*', '/', '%', '^'].includes(val);
};

export const isParenthesis = (val: string): val is Parenthesis => {
    return ['(', ')'].includes(val);
};
