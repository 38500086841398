import MuiButton from '@mui/material/Button';
import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    background: linear-gradient(134.71deg, #32406c 1.82%, #1b2235 60.37%, #18213e 74.87%);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const SubmitButton = styled(MuiButton)`
    width: 100%;
`;
