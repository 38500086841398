
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Connection": [
      "Assemblies",
      "Categories",
      "Estimators",
      "Events",
      "Labors",
      "Materials",
      "ProjectPrices",
      "Projects",
      "RateCollections",
      "Rates",
      "Sources"
    ],
    "Edge": [
      "AssemblyEdge",
      "AssemblyLibraryEdge",
      "AssetEdge",
      "CategoryEdge",
      "ContractEdge",
      "CountyEdge",
      "ElementEdge",
      "EventEdge",
      "FeatureEdge",
      "LaborEdge",
      "MaterialEdge",
      "ProjectEdge",
      "ProjectLocationEdge",
      "ProjectMarkupEdge",
      "ProjectPriceEdge",
      "ProjectPricingEdge",
      "RateCollectionEdge",
      "RateEdge",
      "ReviewEdge",
      "ReviewTagEdge",
      "ScopeEdge",
      "SourceEdge",
      "StateEdge",
      "TeamCreditHistoryEdge",
      "TeamEdge",
      "TeamInviteEdge",
      "TeamProjectFileEdge",
      "TradeEdge",
      "UserEdge",
      "VariableEdge"
    ],
    "GeoJSONCRSProperties": [
      "GeoJSONLinkedCRSProperties",
      "GeoJSONNamedCRSProperties"
    ],
    "GeoJSONGeometryInterface": [
      "GeoJSONLineString",
      "GeoJSONMultiLineString",
      "GeoJSONMultiPoint",
      "GeoJSONMultiPolygon",
      "GeoJSONPoint",
      "GeoJSONPolygon"
    ],
    "GeoJSONInterface": [
      "GeoJSONFeature",
      "GeoJSONFeatureCollection",
      "GeoJSONGeometryCollection",
      "GeoJSONLineString",
      "GeoJSONMultiLineString",
      "GeoJSONMultiPoint",
      "GeoJSONMultiPolygon",
      "GeoJSONPoint",
      "GeoJSONPolygon"
    ],
    "MarkupEntry": [
      "Markup",
      "MarkupGroup"
    ],
    "MarkupGeometry": [
      "Coordinate",
      "MarkupLine",
      "MarkupPolygon"
    ],
    "Node": [
      "Assembly",
      "AssemblyLibrary",
      "Asset",
      "AssetDimension",
      "AssetsGraph",
      "Balance",
      "Category",
      "ClientPreference",
      "Commitment",
      "Contract",
      "County",
      "Element",
      "Event",
      "Feature",
      "Formula",
      "HourlyLaborRate",
      "Labor",
      "Location",
      "Markup",
      "MarkupGroup",
      "Material",
      "MaterialAlias",
      "MeasuredQuantity",
      "Project",
      "ProjectAcceptanceHistory",
      "ProjectArea",
      "ProjectEventsSeen",
      "ProjectLocation",
      "ProjectMarkup",
      "ProjectPlanFile",
      "ProjectPlanPage",
      "ProjectPrice",
      "ProjectPricing",
      "ProjectScope",
      "ProjectsOverview",
      "Rate",
      "RateCollection",
      "Review",
      "ReviewTag",
      "Schedule",
      "Scope",
      "Source",
      "State",
      "StripeSubscription",
      "Supplier",
      "Team",
      "TeamCreditHistory",
      "TeamInvite",
      "TeamOnboarding",
      "TeamProjectFile",
      "TeamProjectInfo",
      "Trade",
      "TradeRating",
      "Unit",
      "User",
      "Variable"
    ],
    "OldConnection": [
      "AssemblyLibraries",
      "Assets",
      "Contracts",
      "Counties",
      "Elements",
      "ProjectPricings",
      "ProjectsOld",
      "Reviews",
      "Scopes",
      "States",
      "Teams",
      "Users",
      "Variables"
    ],
    "StaticConnection": [
      "Features",
      "ProjectMarkups",
      "ReviewTags",
      "TeamCreditHistories",
      "TeamInvites",
      "TeamProjectFiles",
      "Trades"
    ],
    "UnstableConnection": [
      "ProjectLocations"
    ]
  }
};
      export default result;
    