/**
 * A button to paginate through a series of pages.
 */
import clsx from 'clsx';
import React, { FC } from 'react';

import { CaretIcon, CaretIconSize } from '@/components/ui/icons/CaretIcon';
import { ButtonProps, Direction } from '@/common/types';

import './PaginationButton.scss';

export interface PaginationButtonProps extends ButtonProps {
    direction: Direction;
}

export const PaginationButton: FC<PaginationButtonProps> = ({
    className,
    direction,
    ...shared
}) => (
    <button className={clsx(className, 'pagination-button-container')} {...shared}>
        <CaretIcon direction={direction} size={CaretIconSize.Medium} />
    </button>
);
