/**
 * Square button with icon inside.
 */

import React, { FC } from 'react';
import clsx from 'clsx';

import { ButtonProps, Svg } from '@/common/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';

import './IconButton.scss';

interface IconButtonProps extends ButtonProps {
    Icon: Svg;
    noInherit?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
    Icon,
    className,
    noInherit = false,
    ...rest
}) => (
    <button className={clsx(className, 'icon-button')} {...rest}>
        <SvgIcon src={Icon} noInherit={noInherit} />
    </button>
);
