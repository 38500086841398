/* area pattern svgs */
import * as normalArea from '../assets/icons/stylepanel/canvas-areas/normal-area.svg';
import * as woodArea from '../assets/icons/stylepanel/canvas-areas/wood-area.svg';
import * as brickArea from '../assets/icons/stylepanel/canvas-areas/brick-area.svg';
import * as stoneArea from '../assets/icons/stylepanel/canvas-areas/stone-area.svg';
import * as shinglesArea from '../assets/icons/stylepanel/canvas-areas/shingles-area.svg';
import * as insulationArea from '../assets/icons/stylepanel/canvas-areas/insulation-area.svg';
import * as concreteArea from '../assets/icons/stylepanel/canvas-areas/concrete-area.svg';
import * as hardcoreArea from '../assets/icons/stylepanel/canvas-areas/hardcore-area.svg';
import * as blockworkArea from '../assets/icons/stylepanel/canvas-areas/blockwork-area.svg';
import * as verticalLinesArea from '../assets/icons/stylepanel/canvas-areas/vertical-lines-area.svg';
import * as horizontalLinesArea from '../assets/icons/stylepanel/canvas-areas/horizontal-lines-area.svg';
import * as dotsArea from '../assets/icons/stylepanel/canvas-areas/dots-area.svg';

const { PI, sin, cos } = Math;

// Used in custom pattern geometry calculations
export const calcHelperParams = (start, end, renderer) => {
    const { x: startX, y: startY } = start;
    const { x: endX, y: endY } = end;
    // LatLng coordinates differ from canvas coordinates
    const realStart = renderer._map.layerPointToLatLng([startX, startY]);
    const realEnd = renderer._map.layerPointToLatLng([endX, endY]);
    const zoom = 2 ** renderer._map.getZoom();
    const realDx = realEnd.lng - realStart.lng;
    const realDy = realEnd.lat - realStart.lat;
    // LatLng length
    const realLength = Math.sqrt(realDx ** 2 + realDy ** 2);
    const canvasDx = endX - startX;
    const canvasDy = endY - startY;
    // Default line angle
    const alfa = Math.atan2(realDy, realDx);
    // Length of line in canvas
    const canvasLength = Math.sqrt(canvasDx ** 2 + canvasDy ** 2);
    return {
        start,
        end,
        startX,
        startY,
        endX,
        endY,
        realStart,
        realEnd,
        realLength,
        zoom,
        canvasLength,
        alfa,
    };
};

export const areaImages = {
    normalArea: normalArea,
    brickArea: brickArea,
    woodArea: woodArea,
    stoneArea: stoneArea,
    shinglesArea: shinglesArea,
    insulationArea: insulationArea,
    concreteArea: concreteArea,
    hardcoreArea: hardcoreArea,
    blockworkArea: blockworkArea,
    verticalLinesArea: verticalLinesArea,
    horizontalLinesArea: horizontalLinesArea,
    dotsArea: dotsArea,
};

export const getAreaImage = (areaType) => {
    const areaImages = {
        normalArea: normalArea,
        brickArea: brickArea,
        woodArea: woodArea,
        stoneArea: stoneArea,
        shinglesArea: shinglesArea,
        insulationArea: insulationArea,
        concreteArea: concreteArea,
        hardcoreArea: hardcoreArea,
        blockworkArea: blockworkArea,
        verticalLinesArea: verticalLinesArea,
        horizontalLinesArea: horizontalLinesArea,
        dotsArea: dotsArea,
    };
    return areaImages[areaType];
};

export const getOneLadderPath = (x, y, beta) => {
    // Order is (looking from the bottom up):
    // left half of the bottom rung
    // left vertical part
    // upper rung
    // right vertical part
    // right half of the bottom rung
    // move back to the upper right corner
    const halfX = x / 2;
    return `
    l ${halfX * cos(beta - 0.5 * PI)} ${-halfX * sin(beta - 0.5 * PI)}
    l ${-y * cos(beta)} ${y * sin(beta)}
    l ${-x * cos(beta - 0.5 * PI)} ${x * sin(beta - 0.5 * PI)}
    l ${y * cos(beta)} ${-y * sin(beta)}
    l ${halfX * cos(beta - 0.5 * PI)} ${-halfX * sin(beta - 0.5 * PI)}
    m ${-y * cos(beta)} ${y * sin(beta)} `;
};

export const getOneZigZagPath = (x, beta) => {
    return `
    l ${x * cos(beta)} ${-x * sin(beta)}
    l ${-2 * x * sin(beta)} ${-2 * x * cos(beta)}
    l ${x * cos(beta)} ${-x * sin(beta)}`;
};

export const _prepareLadderLine = (params, closed, weight) => {
    // So that even 1px wide lines are visible as ladders.
    weight = Math.max(weight, 4);
    const { startX, startY, zoom, alfa, realLength } = params;
    const beta = PI + alfa;
    const oneLadder = getOneLadderPath(weight, weight, beta);
    const L = realLength * zoom;
    const howManyLadders = L / weight;
    const howManyFullLadders = Math.floor(howManyLadders);
    const heightOfTheFractionLadder = (howManyLadders - howManyFullLadders) * weight;

    let path = ` M ${startX} ${startY}  `;
    for (let i = 0; i < howManyFullLadders; i++) {
        path += oneLadder;
    }
    if (heightOfTheFractionLadder) {
        path += getOneLadderPath(weight, heightOfTheFractionLadder, beta);
    }
    return path;
};

export const _prepareZigZagLine = (params, closed, weight) => {
    const { startX, startY, alfa, canvasLength } = params;
    const L = canvasLength;
    const beta = alfa - 0.25 * PI;
    const howManyZigZags = L / (2 * weight * Math.sqrt(2));
    const lengthOfZig = L / Math.floor(howManyZigZags);
    const oneZigZag = getOneZigZagPath(lengthOfZig / (2 * Math.sqrt(2)), beta);
    let path = `M ${startX} ${startY} `;
    for (let i = 0; i < Math.floor(howManyZigZags); i++) {
        path += oneZigZag;
    }
    return path;
};

/* eslint-disable max-len */
export const _prepareCustomMarkerActive = (center, isBackground) => {
    const background =
        `M ${center.x} ${center.y - 15.125}` +
        'l15.125,15.125l-15.125,15.125l-15.125,-15.125l15.125,-15.125z';
    if (isBackground) return background;
    const path =
        background +
        'm0,4.125l-11,11l11,11l11,-11l-11,-11zm1.944,10.695c0,1.074,-0.87,1.945,-1.944,1.945c-1.074,0,-1.945,-0.871,-1.945,-1.945c0,-1.074,0.871,-1.944,1.945,-1.944c1.074,-0.001,1.944,0.87,1.944,1.944z';
    return path;
};
export const _prepareCustomMarkerHighlighted = (center, isBackground) => {
    const background = `M ${center.x} ${center.y - 9}` + 'l-10,10l10,10l10,-10l-10,-10z';
    if (isBackground) return background;
    const path =
        background +
        'm1.768,9.723c0,0.976,-0.792,1.768,-1.768,1.768c-0.976,-0.001,-1.768,-0.792,-1.768,-1.768c0,-0.977,0.792,-1.768,1.768,-1.768c0.976,0,1.768,0.791,1.768,1.768z';
    return path;
};
export const _prepareCustomMarker = (center, isBackground) => {
    const background =
        `M ${center.x} ${center.y - 5.25}` + 'l6.25,6.25l-6.25,6.25l-6.25,-6.25l6.25,-6.25z';
    if (isBackground) return background;
    const path =
        background +
        'm-10,6.25l10,-10l10,10l-10,10l-10,-10zm10,1.491c0.976,0,1.768,-0.792,1.768,-1.768c0,-0.977,-0.792,-1.768,-1.768,-1.768c-0.976,0,-1.768,0.791,-1.768,1.768c0,0.976,0.792,1.768,1.768,1.768z';
    return path;
};
/* eslint-enable max-len */
