import React, { FC } from 'react';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { ReactComponent as OverflowMenuHorizontalIcon } from '@/assets/icons/32/overflow-menu--horizontal.svg';
import { ReactComponent as WarningAltFilledIcon } from '@/assets/icons/32/warning--alt--filled.svg';

export interface BaseListItemProps extends ListItemProps {
    checkbox?: boolean;
    checkboxProps?: CheckboxProps;
    icon?: React.ReactNode;
    mode?: string;
    nameTextFieldProps?: TextFieldProps;
    overflowIconButton?: boolean;
    overflowIconButtonProps?: IconButtonProps;
    statusIcon?: boolean;
    statusIconTooltipProps?: TooltipProps;
}

export const BaseListItem: FC<BaseListItemProps> = ({
    checkbox,
    checkboxProps,
    icon,
    mode,
    nameTextFieldProps,
    overflowIconButton,
    overflowIconButtonProps,
    statusIcon,
    statusIconTooltipProps,
    ...props
}) => {
    return (
        <ListItem dense {...props}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <Stack direction="row" flex="1" spacing="12px">
                <TextField
                    multiline
                    {...nameTextFieldProps}
                    InputProps={{
                        ...nameTextFieldProps?.InputProps,
                        sx: {
                            backgroundColor: 'transparent',
                            padding: '2px 6px',
                            ':hover': {
                                backgroundColor: nameTextFieldProps?.inputProps?.disabled
                                    ? 'transparent'
                                    : undefined,
                            },
                            ...nameTextFieldProps?.InputProps?.sx,
                        },
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();

                            const inputRef = nameTextFieldProps?.inputRef as React.MutableRefObject<
                                HTMLInputElement | undefined
                            >;

                            inputRef?.current?.blur();
                        }
                    }}
                    sx={{
                        flex: 1,
                    }}
                    variant="filled"
                />
                <Stack direction="row" spacing="12px">
                    {statusIcon && mode === 'select' && (
                        <Tooltip
                            title={statusIconTooltipProps?.title || ''}
                            {...statusIconTooltipProps}
                        >
                            <SvgIcon
                                color="warning"
                                viewBox="0 0 32 32"
                                sx={{
                                    height: 20,
                                    width: 20,
                                }}
                            >
                                <WarningAltFilledIcon />
                            </SvgIcon>
                        </Tooltip>
                    )}
                    {overflowIconButton && (mode === 'default' || mode === 'rename') && (
                        <IconButton
                            edge="end"
                            size="small"
                            sx={{
                                height: 27,
                                width: 27,
                            }}
                            {...overflowIconButtonProps}
                        >
                            <SvgIcon
                                viewBox="0 0 32 32"
                                sx={{
                                    height: 17,
                                    width: 17,
                                }}
                            >
                                <OverflowMenuHorizontalIcon />
                            </SvgIcon>
                        </IconButton>
                    )}
                    {checkbox && mode === 'select' && (
                        <Checkbox
                            size="small"
                            {...checkboxProps}
                            sx={{
                                height: 27,
                                padding: 0,
                                width: 27,
                                ...checkboxProps?.sx,
                            }}
                        />
                    )}
                </Stack>
            </Stack>
        </ListItem>
    );
};
