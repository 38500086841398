import gql from 'graphql-tag';

import { makeUseSubscription } from '../frog';
import { projectPlanFileFragment, ProjectPlanFileRecord } from '../queries/projectPlanFiles';

export type ProjectPlanFileMessage = {
    ProjectPlanFile: {
        projectPlanFileEntry?: ProjectPlanFileRecord | null;
    };
};

export const projectPlanFileSubscription = projectPlanFileFragment.gql`
    subscription {
        ProjectPlanFile {
            projectPlanFileEntry {
                ${projectPlanFileFragment}
            }
        }
    }
`;

export const useProjectPlanFileSubscription = makeUseSubscription<ProjectPlanFileMessage>(
    projectPlanFileSubscription
);

export type DeleteProjectPlanFileMessage = {
    DeleteProjectPlanFile: {
        deletedProjectPlanFileEntry?: {
            uuid: string;
        } | null;
    };
};

export const deleteProjectPlanFileSubscription = gql`
    subscription {
        DeleteProjectPlanFile {
            deletedProjectPlanFileEntry {
                uuid
            }
        }
    }
`;

export const useDeleteProjectPlanFileSubscription =
    makeUseSubscription<DeleteProjectPlanFileMessage>(deleteProjectPlanFileSubscription);
