/*
 * useProjectFromParams.ts provides the full project record for the project with a UUID matching the `projectUUID`
 * routing parameter.
 */
import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CombinedError } from 'urql';

import { Setter } from '@/common/types';
import { useUser } from '@/contexts/User';
import {
    IProjectLocationByProjectIdQuery,
    IProjectLocationByProjectIdQueryVariables,
    IProjectLocationFragment,
    ProjectLocationByProjectIdDocument,
} from '@/graphql';
import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectRecord, executeProjectsQuery } from '@/queries/projects';
import { useProjectSubscription } from '@/subscriptions/projects';

export interface UseProjectFromParamsValues {
    assignedTo?: BaseUserRecord;
    error?: CombinedError;
    fetching: boolean;
    project?: ProjectRecord;
    projectLocation: IProjectLocationFragment | null;
    projectNotFound: boolean;
    projectParamUUID: string | undefined;
    setProjectUUID: Setter<string | undefined>;
}

export interface ProjectURLParams {
    projectUUID?: string;
}

// If overrideUUID is passed, that is used to identify the project instead of the routing param.
export const useProjectFromParams = (overrideUUID?: string): UseProjectFromParamsValues => {
    const {
        data: { anonymousUser },
    } = useUser();
    const [assignedTo, setAssignedTo] = useState<BaseUserRecord | undefined>();
    const [error, setError] = useState<CombinedError | undefined>();
    const [fetching, setFetching] = useState(false);
    const [project, setProject] = useState<ProjectRecord | undefined>();
    const [projectLocation, setProjectLocation] = useState<IProjectLocationFragment | null>(null);
    const [projectNotFound, setProjectNotFound] = useState(false);
    const [projectUUID, setProjectUUID] = useState(overrideUUID);

    const { data: projectChangedData } = useProjectSubscription();
    const { projectUUID: projectParamUUID } = useParams<ProjectURLParams>();

    const [fetchProjectLocation, fetchProjectLocationResponse] = useLazyQuery<
        IProjectLocationByProjectIdQuery,
        IProjectLocationByProjectIdQueryVariables
    >(ProjectLocationByProjectIdDocument, {
        fetchPolicy: anonymousUser ? 'cache-first' : 'network-only',
    });

    useEffect(() => {
        if (overrideUUID === undefined) {
            setProjectUUID(projectParamUUID);
        }
    }, [projectParamUUID]);

    useEffect(() => {
        const updatedProject = projectChangedData?.ProjectChanged.projectEntry ?? undefined;
        if (updatedProject !== undefined && updatedProject.uuid === projectUUID) {
            setProject(updatedProject);
        }
    }, [projectChangedData]);

    const fetchProject = async (): Promise<void> => {
        setFetching(true);
        const res = await executeProjectsQuery(
            { uuid: projectUUID },
            {
                requestPolicy: 'network-only',
            }
        );

        setError(res.error);
        const newProject = res.data?.projects?.nodes[0];
        if (newProject !== undefined) {
            setProject(newProject);
            setProjectNotFound(false);
        } else if (res.error === undefined) {
            setProjectNotFound(true);
        }
        setFetching(false);
    };

    useEffect(() => {
        // When there is no projectUUID parameter, unset the project.
        if (projectUUID === undefined) {
            setProject(undefined);
            return;
        }

        // If the project is already set correctly, do nothing.
        if (project?.uuid === projectUUID) {
            return;
        }

        fetchProject();
    }, [projectUUID]);

    // This logic is a holdover from the projects context. Many parts of the application rely on `assignedTo`, which
    // is the first user linked to a project.
    useEffect(() => {
        setAssignedTo(project?.projectUsers?.nodes[0]?.user);
    }, [project]);

    // Fetch project locations once we have a project id
    useEffect(() => {
        if (project?.id) {
            fetchProjectLocation({
                variables: {
                    input: {
                        id: `${project.id}`,
                    },
                },
            });
        }
    }, [project?.id]);

    // Set project location once we have project locations response
    useEffect(() => {
        if (fetchProjectLocationResponse.data) {
            setProjectLocation(fetchProjectLocationResponse.data.projectOld.location ?? null);
        }
    }, [fetchProjectLocationResponse]);

    return {
        assignedTo,
        error,
        fetching: fetching || fetchProjectLocationResponse.loading,
        project,
        projectLocation,
        projectNotFound,
        projectParamUUID,
        setProjectUUID,
    };
};
