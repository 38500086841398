import { PaletteOptions } from '@mui/material/styles';
import { colors } from '../colors';

// See: https://mui.com/customization/dark-mode/#dark-mode-with-custom-palette
export const lightPalette: PaletteOptions = {
    mode: 'light',
    hues: colors,
    accent: {
        main: colors.orange[0],
    },
    background: {
        paper: colors.neutral[100],
        default: colors.neutral[100],
    },
    common: {
        black: colors.neutral[0],
        white: colors.neutral[100],
    },
    primary: {
        main: colors.blue[0],
    },
    secondary: {
        main: colors.neutral[21],
    },
    error: {
        main: colors.red[0],
    },
    warning: {
        main: colors.amber[0],
    },
    info: {
        main: colors.blue[0],
    },
    success: {
        main: colors.mint[0],
    },
    text: {
        primary: colors.neutral[21],
    },
};
