import { ToolType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useTool } from '@/components/takeoff/hooks/useTool';
import {
    booleanToolObservable,
    currentlyAddedCoordinatesObservable,
    isAddingCoordinatesObservable,
    subscribeToCursorPosition,
} from '@/components/takeoff/observables/helpers';
import { colorTertiary } from '@/variables';
import { LatLng, LatLngBounds } from 'leaflet';
import React, { FC, useEffect, useState } from 'react';
import { FeatureGroup, Polyline } from 'react-leaflet';

export interface DrawingCursorGuidelinesProps extends TakeoffComponentProps {
    bounds: LatLngBounds;
    canDrawBoundary: boolean;
}

export const DrawingCursorGuidelines: FC<DrawingCursorGuidelinesProps> = ({
    bounds,
    canDrawBoundary,
    useTakeoff,
}: DrawingCursorGuidelinesProps) => {
    const { isManualCalibration, calibrationLine } = useTakeoff();
    const tool = useTool();

    const isShapeDrawingTool = [ToolType.LINEAR, ToolType.AREA, ToolType.COUNT].includes(tool);
    const isCalibrationAndDrawing =
        tool === ToolType.CALIBRATION &&
        isManualCalibration &&
        calibrationLine.coordinates.length === 0;
    const isBooleanOrDetectTool = booleanToolObservable.value;
    const shouldDrawCursorGuidelines =
        (isShapeDrawingTool || isCalibrationAndDrawing || isBooleanOrDetectTool) && canDrawBoundary;

    const [cursorPosition, setCursorPosition] = useState<LatLng>();

    useEffect(() => {
        const subscription = subscribeToCursorPosition(setCursorPosition);
        return (): void => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (
            [ToolType.LINEAR, ToolType.AREA, ToolType.COUNT].includes(tool) ||
            booleanToolObservable.value
        ) {
            isAddingCoordinatesObservable.next(true);
            currentlyAddedCoordinatesObservable.next(cursorPosition || null);
        } else {
            isAddingCoordinatesObservable.next(false);
            currentlyAddedCoordinatesObservable.next(null);
        }
    }, [booleanToolObservable.value, cursorPosition, tool]);

    const renderGuideline = (positions: LatLng[]): JSX.Element => (
        <Polyline positions={positions} color={colorTertiary} weight={1} />
    );

    if (!cursorPosition) return <></>;

    return (
        <>
            {shouldDrawCursorGuidelines && (
                <FeatureGroup>
                    {renderGuideline([
                        new LatLng(bounds.getSouth(), cursorPosition.lng),
                        new LatLng(bounds.getNorth(), cursorPosition.lng),
                    ])}
                    {renderGuideline([
                        new LatLng(cursorPosition.lat, bounds.getWest()),
                        new LatLng(cursorPosition.lat, bounds.getEast()),
                    ])}
                </FeatureGroup>
            )}
        </>
    );
};
