/*
 * typeGuards.ts implements custom typeguards. For more information, see:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html
 */
import { Assembly, Geometry, GeometryType, Point } from './types';
import type { IMarkupFragment, IMarkupGroupFragment } from '@/graphql';
import type { IMarkupEntry } from '@/graphql/unions';

// isEnumMember determines if a value is member of an enum.
export const isEnumMember = <T extends string | number | symbol, K extends { [s: string]: T }>(
    member: T,
    parent: K
): member is K[keyof K] => Object.values(parent).filter((val) => val === member).length === 1;

/**
 * Determine if an element is an HTML element.
 * @param {Element | EventTarget} element DOM element
 * @returns {boolean} true if passed element is an HTML element.
 */
export const isHTMLElement = (
    element: Element | EventTarget | void | null
): element is HTMLElement => element instanceof HTMLElement;

/**
 * Determine if an element is an HTML input element.
 * @param {Element | EventTarget} element DOM element
 * @returns {boolean} true if passed element is an HTML input element.
 */
export const isHTMLInputElement = (
    element: Element | EventTarget | void | null
): element is HTMLInputElement => element instanceof HTMLInputElement;

export const markupEntryIsMarkup = (markupEntry: IMarkupEntry): markupEntry is IMarkupFragment => {
    return markupEntry.__typename === 'Markup';
};

export const markupEntryIsMarkupGroup = (
    markupEntry: IMarkupEntry
): markupEntry is IMarkupGroupFragment => {
    return markupEntry.__typename === 'MarkupGroup';
};

export const geometryIs = <T extends GeometryType>(
    geometry: Geometry,
    type: T
): geometry is Geometry<T> => {
    return geometry.type === type;
};

export const pointOrGeometryIsGeometry = (data: Point | Geometry): data is Geometry => {
    return Array.isArray(Reflect.get(data, 'coordinates'));
};

// If assemblies prop is not Array,
// we will handle it as the Expanded type of assemblies format, based on s3 response
export const isArrayOfAssemblies = (assemblies: unknown): assemblies is Assembly[] => {
    return Array.isArray(assemblies);
};
