import React from 'react';

import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { isHTMLElement } from '@/common/typeGuards';

/**
 * Hook to append basic event handlers to document for menues.
 * @param {() => void} reset reset function
 * @param {React.RefObject<HTMLElement>?} containerRef - parent object where context menu is mounted
 */
export const useSimpleMenu = (
    reset: (e?: MouseEvent | KeyboardEvent) => void,
    containerRef?: React.RefObject<HTMLElement>,
    deps: unknown[] = []
): void => {
    // Handle escape out.
    useDocumentListener(
        'keydown',
        (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Escape': {
                    reset(e);
                }
            }
        },
        [...deps]
    );

    // Handle click out of target ref
    useDocumentListener(
        'mousedown',
        (e: MouseEvent) => {
            if (
                isHTMLElement(e.target) &&
                containerRef?.current &&
                !containerRef.current.contains(e.target) &&
                !e.target.id.startsWith('context-menu-invoker-')
            ) {
                reset(e);
            }
        },
        [...deps]
    );

    // Handle context menu.
    useDocumentListener(
        'contextmenu',
        (e) => {
            if (!e.defaultPrevented) {
                reset(e);
            }
        },
        [...deps]
    );
};
