import React, { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as Checkmark } from '@/assets/icons/32/checkmark.svg';
import { ReactComponent as FolderAddIcon } from '@/assets/icons/32/folder--add.svg';

interface GeometrySectionControlsProps extends BoxProps {
    newGroupButtonProps?: ButtonProps;
    selectButtonProps?: ButtonProps;
}

export const GeometrySectionControls: FC<GeometrySectionControlsProps> = ({
    newGroupButtonProps,
    selectButtonProps,
    ...props
}) => {
    return (
        <Box {...props}>
            <Stack direction="row" spacing="8px" sx={{ padding: '8px' }}>
                <Button
                    fullWidth
                    size="small"
                    startIcon={
                        <SvgIcon viewBox="0 0 32 32">
                            <FolderAddIcon />
                        </SvgIcon>
                    }
                    variant="outlined"
                    {...newGroupButtonProps}
                >
                    Group
                </Button>
                <Button
                    fullWidth
                    size="small"
                    startIcon={
                        <SvgIcon viewBox="0 0 32 32">
                            <Checkmark />
                        </SvgIcon>
                    }
                    variant="outlined"
                    {...selectButtonProps}
                >
                    Select
                </Button>
            </Stack>
        </Box>
    );
};
