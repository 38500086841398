import { MouseEvent } from 'react';

/**
 * Small wrapper to execute callback on enter key and click.
 * @param {() => void} callback callback to be executed.
 * @returns {{ onClick: () => void; onKeyPress: (e: React.KeyboardEvent) => void }} - spread
 * object
 */
export const onClickAndEnterKey = (
    callback: (e: React.MouseEvent | React.KeyboardEvent) => void
): { onClick: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void } => ({
    onClick: callback,
    onKeyDown: (e: React.KeyboardEvent): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            callback(e);
        }
    },
});

export const onMouseDownAndEnterKey = (
    callback: (e: React.MouseEvent | React.KeyboardEvent) => void
): { onMouseDown: (e: React.MouseEvent) => void; onKeyDown: (e: React.KeyboardEvent) => void } => ({
    onMouseDown: callback,
    onKeyDown: (e: React.KeyboardEvent): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            callback(e);
        }
    },
});
