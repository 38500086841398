/* eslint-disable @typescript-eslint/indent */
import ScopedCssBaseLine from '@mui/material/ScopedCssBaseline';
import React, { FC, useEffect, useState } from 'react';
import { Env, isLocalhostDeploymentUrl } from '@/common/env';
import { Layer } from '@/theme/Layer';
import { EnvironmentBar } from './EnvironmentBar';
import { ImpersonatorBar } from './ImpersonatorBar';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const StatusBars: FC<Props> = (props) => {
    const [hasHiddenEnvironmentBar, setHasHiddenEnvironmentBar] = useState(false);
    const [hasHiddenImpersonatorBar, setHasHiddenImpersonatorBar] = useState(false);

    // Using the local-storage api directly does not re-render, future.
    const [hideVersion, setHideVersion] = useState(
        localStorage.getItem('status-bars-environment-bar-hide-version')
    );

    // Consider adding a react hook for local-storage api, future.
    // See: https://github.com/rehooks/local-storage
    const [isImpersonating, setIsImpersonating] = useState(
        Boolean(localStorage.getItem('impersonatedAuthID'))
    );

    useEffect(() => {
        if (Env.release === hideVersion) {
            return;
        }

        localStorage.removeItem('status-bars-environment-bar-hide-version');

        setHideVersion(null);
    }, []);

    const handleStopImpersonatingModalOkButtonClick = () => {
        localStorage.removeItem('impersonatedAuthID');

        setIsImpersonating(false);
    };

    const isEnvironmentBarHiddenForVersion = Env.release === hideVersion;

    return (
        <ScopedCssBaseLine>
            <Layer variant="high">
                <div {...props}>
                    {!isImpersonating &&
                        !Env.tier.isProduction &&
                        !isLocalhostDeploymentUrl() &&
                        !isEnvironmentBarHiddenForVersion &&
                        !hasHiddenEnvironmentBar && (
                            <EnvironmentBar onHide={() => setHasHiddenEnvironmentBar(true)} />
                        )}
                    {isImpersonating && !hasHiddenImpersonatorBar && (
                        <ImpersonatorBar
                            onHideBarMenuItemClick={() => setHasHiddenImpersonatorBar(true)}
                            onStopImpersonatingModalOkButtonClick={
                                handleStopImpersonatingModalOkButtonClick
                            }
                        />
                    )}
                </div>
            </Layer>
        </ScopedCssBaseLine>
    );
};
