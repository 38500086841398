import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';

import { colorTypographyDark } from '../../../variables';
import { ThumbControl } from '../../ui/controls/ThumbControl';

import { IClientPreferenceFragment, IClientPreferenceStatus } from '@/graphql';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';

interface ClientRowProps {
    client: IClientPreferenceFragment;
    updateClient: (
        teamID: string,
        rating: IClientPreferenceStatus,
        clientID: string
    ) => Promise<void>;
}

export const ClientRow: FC<ClientRowProps> = ({ client, updateClient }) => {
    const [isLoading, setIsLoading] = useState(false);

    const toggleThumbDown = async (
        teamID: string,
        status: IClientPreferenceStatus,
        clientID: string
    ): Promise<void> => {
        setIsLoading(true);
        if (status === IClientPreferenceStatus.Negative) {
            await updateClient(teamID, IClientPreferenceStatus.Neutral, clientID);
        } else {
            await updateClient(teamID, IClientPreferenceStatus.Negative, clientID);
        }
    };

    const toggleThumbUp = async (
        teamID: string,
        status: IClientPreferenceStatus,
        clientID: string
    ): Promise<void> => {
        setIsLoading(true);
        if (status === IClientPreferenceStatus.Positive) {
            await updateClient(teamID, IClientPreferenceStatus.Neutral, clientID);
        } else {
            await updateClient(teamID, IClientPreferenceStatus.Positive, clientID);
        }
    };

    useEffect(() => {
        setIsLoading(false);
    }, [client.preference]);

    return (
        <Row key={client.id}>
            <ClientItem>
                <ClientName>{client.team.name}</ClientName>
            </ClientItem>
            <ClientItem>
                {isLoading ? (
                    <DotsLoader className="info" style={{ paddingRight: 34 }} />
                ) : (
                    <>
                        <ThumbControl
                            isThumbUp={false}
                            isSelected={IClientPreferenceStatus.Negative === client.preference}
                            onClick={async (): Promise<void> =>
                                await toggleThumbDown(client.team.id, client.preference, client.id)
                            }
                        />
                        <ThumbControl
                            isThumbUp={true}
                            isSelected={IClientPreferenceStatus.Positive === client.preference}
                            onClick={async (): Promise<void> =>
                                await toggleThumbUp(client.team.id, client.preference, client.id)
                            }
                        />
                    </>
                )}
            </ClientItem>
        </Row>
    );
};

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #f6f8f8;
`;

const ClientItem = styled.div`
    display: flex;
    align-items: center;
`;

const ClientName = styled.div`
    font-weight: normal;
    font-size: 14px;
    color: ${colorTypographyDark};
`;
