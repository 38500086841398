import { ReactComponent as ChevronDownIcon } from '@/assets/icons/32/chevron--down.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/32/chevron--up.svg';
import Collapse, { CollapseProps } from '@mui/material/Collapse';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

export interface BaseListItemCollapseProps extends React.HTMLAttributes<HTMLDivElement> {
    collapseProps?: CollapseProps;
    collapseDirectionIconProps?: SvgIconProps;
    icon?: React.ReactNode;
    leftListItemTextProps?: ListItemTextProps;
    listItemButtonProps?: ListItemButtonProps;
    rightListItemTextProps?: ListItemTextProps;
}

export const BaseListItemCollapse: FC<BaseListItemCollapseProps> = ({
    children,
    collapseProps,
    collapseDirectionIconProps,
    icon,
    leftListItemTextProps,
    listItemButtonProps,
    rightListItemTextProps,
    ...props
}) => {
    return (
        <div {...props}>
            <ListItemButton dense {...listItemButtonProps}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                {leftListItemTextProps && (
                    <ListItemText
                        color="secondary"
                        {...leftListItemTextProps}
                        primaryTypographyProps={{
                            variant: 'body2',
                            ...leftListItemTextProps?.primaryTypographyProps,
                        }}
                    />
                )}
                {rightListItemTextProps && (
                    <ListItemText
                        color="secondary"
                        {...rightListItemTextProps}
                        primaryTypographyProps={{
                            align: 'right',
                            variant: 'body2',
                            ...rightListItemTextProps?.primaryTypographyProps,
                        }}
                    />
                )}
                <SvgIcon
                    color="secondary"
                    {...collapseDirectionIconProps}
                    sx={{
                        height: 'auto',
                        marginLeft: '14px',
                        width: 20,
                        ...collapseDirectionIconProps?.sx,
                    }}
                    viewBox="0 0 32 32"
                >
                    {collapseProps?.in ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </SvgIcon>
            </ListItemButton>
            <Collapse {...collapseProps}>{children}</Collapse>
        </div>
    );
};
