import React, { FC, useEffect, useState } from 'react';

import { Container, Star } from './styled';

import { ReactComponent as RatingStar } from '@/assets/icons/rating-star.svg';
import { ratingNormalizer } from '@/common/utils/ratings';

export type RatingBarProps = {
    rating: number;
    numberOfElements?: number;
    large?: boolean;
    isHover?: boolean;
    isSelected?: boolean;
    onChange?: (updatedRating: number) => void;
};
export const RatingBar: FC<RatingBarProps> = ({
    rating,
    numberOfElements = 5,
    large,
    isHover,
    isSelected,
    onChange,
}) => {
    const [updatedRating, setUpdatedRating] = useState(ratingNormalizer(rating));
    const [hoverRating, setHoverRating] = useState<number | null>(null);

    // Update stored rating on parent rating update
    useEffect(() => setUpdatedRating(ratingNormalizer(rating)), [rating]);

    return (
        <Container
            large={large}
            isInert={!onChange}
            onMouseLeave={(): void => setHoverRating(null)}
        >
            {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                [...Array(numberOfElements)].map((x, i) => {
                    const activeIndex = i + 1;

                    return (
                        <Star
                            key={i}
                            // Presentation
                            large={large}
                            isHover={isHover}
                            isSelected={isSelected}
                            isActive={activeIndex <= (hoverRating || updatedRating)}
                            // Handlers
                            onClick={(): void => {
                                // if there is no onChange handler, this is inert.
                                if (onChange) {
                                    setUpdatedRating(activeIndex);
                                    onChange(activeIndex);
                                }
                            }}
                            onMouseEnter={(): void => {
                                // if there is no onChange handler, this is inert.
                                if (onChange) {
                                    setHoverRating(activeIndex);
                                }
                            }}
                        >
                            <RatingStar />
                        </Star>
                    );
                })
            }
        </Container>
    );
};
