import { Element } from 'leyden';
import { Descendant, Text } from 'slate';

import { Serialize } from '.';
import { lex } from '../expression/lexer';
import { Operator, Parenthesis } from '../utils/types';
import { ModifierKind, TokenKind, TokenKindValueMap, TokenScalar } from '@/graphql/customScalars';

export interface SerializeToken {
    Scalar: <T extends TokenKind>(kind: T, value: TokenKindValueMap[T]) => TokenScalar<T>;
    ScalarsFromDescendant: (element: Descendant) => TokenScalar[];
}

export const SerializeToken: SerializeToken = {
    Scalar(kind, value) {
        return { kind, value };
    },

    ScalarsFromDescendant(element) {
        if (Text.isText(element)) {
            return lex(element.text).flatMap(Serialize.Token.ScalarsFromDescendant);
        }

        if (Element.isElement(element, { type: 'ExpressionNumber' })) {
            return [
                Serialize.Token.Scalar(TokenKind.Numeric, {
                    quantity: element.value,
                }),
            ];
        }

        if (Element.isElement(element, { type: 'ExpressionOperator' })) {
            return [
                Serialize.Token.Scalar(TokenKind.Modifier, {
                    symbol: operatorModifierKind[element.value],
                }),
            ];
        }

        if (Element.isElement(element, { type: 'ExpressionParenthesis' })) {
            if (element.value === Parenthesis.Open) {
                return [Serialize.Token.Scalar(TokenKind.ClauseOpen, {})];
            } else {
                return [Serialize.Token.Scalar(TokenKind.ClauseClose, {})];
            }
        }

        if (Element.isElement(element, { type: 'ExpressionMarkup' })) {
            return [Serialize.Token.Scalar(TokenKind.Geometry, { id: element.markupID })];
        }

        if (Element.isElement(element, { type: 'ExpressionMarkupGroup' })) {
            return [Serialize.Token.Scalar(TokenKind.GeometryGroup, { id: element.markupGroupID })];
        }

        return [];
    },
};

const operatorModifierKind: Record<Operator, ModifierKind> = {
    [Operator.Sum]: ModifierKind.Plus,
    [Operator.Difference]: ModifierKind.Minus,
    [Operator.Product]: ModifierKind.Multiply,
    [Operator.Quotient]: ModifierKind.Divide,
    [Operator.Remainder]: ModifierKind.Modulus,
    [Operator.Exponentiation]: ModifierKind.Exponent,
};
