import styled from 'styled-components';

import {
    breakpointMedium,
    colorAdminDashboardScrollbar,
    colorBackgroundSecondaryLightest,
} from '@/variables';

export const AdminDashboardContainer = styled.div<{ isWebkit?: boolean }>`
    grid-area: projects;
    flex-grow: 2;
    padding: 0;
    margin: 0;
    background-color: ${colorBackgroundSecondaryLightest};
    overflow: ${(props): string => (props.isWebkit ? 'overlay' : 'auto')};
    font-family: 'proxima-nova', sans-serif;
    z-index: 2;

    &::-webkit-scrollbar {
        height: 0.75rem;
        width: 0.75rem;
    }

    &::-webkit-scrollbar-button {
        height: 0.25rem;
        width: 0.25rem;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colorAdminDashboardScrollbar};
        background-clip: padding-box;
        border: 0.25rem solid transparent;
        border-radius: 0.375rem;
    }

    .spinner-loader {
        margin: 5rem 0 0;
        text-align: center;
    }
`;

export const Title = styled.h3`
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 16px;
    color: #1b2235;
    padding-left: 2.5rem;

    @media (max-width: ${breakpointMedium}) {
        position: absolute;
        color: #ffffff;
        top: 48.5px;
        right: 16px;
    }
`;
