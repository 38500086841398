import {
    IProjectWithMarkupEntriesFragment,
    IMarkupFragment,
    IMarkupGroupFragment,
} from '@/graphql';

const isMarkupGroup = (
    markup: IMarkupFragment | IMarkupGroupFragment
): markup is IMarkupGroupFragment => {
    return markup.__typename === 'MarkupGroup';
};

/**
 * Dedeupe any markups that are currently within a group.
 * This is a kludge fix to allow for generation of markups from a group.
 * Because urql does not allow manual cache invalidation, we update both urql cache and apollo cache,
 * and filter out any duplicates that are not within a group.
 *
 * @param {IProjectWithMarkupEntriesFragment['markupEntries'] | undefined} markupEntries - markup entries from project
 * @returns {IProjectWithMarkupEntriesFragment['markupEntries'] | undefined}
 */
export const useDedupedMarkupEntries = (
    markupEntries?: IProjectWithMarkupEntriesFragment['markupEntries']
) => {
    const isWithinGroupSet = new Set(
        markupEntries
            ? markupEntries
                  .filter(isMarkupGroup)
                  .flatMap((markupGroup) => markupGroup.markups.map((markup) => markup.id))
            : []
    );

    return markupEntries?.filter(
        (markup) => isMarkupGroup(markup) || !isWithinGroupSet.has(markup.id)
    );
};
