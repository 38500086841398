// Reference: https://mui.com/components/use-media-query/
// Source: https://github.com/mui/material-ui/blob/master/packages/mui-material/src/useMediaQuery/useMediaQuery.ts

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type UseBreakpointsPayload = {
    xs: boolean;
    md: boolean;
    lg: boolean;
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
};

export function useBreakpoints(): UseBreakpointsPayload {
    const theme = useTheme();

    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    return {
        xs,
        md,
        lg,
        mobile: !md && !lg,
        tablet: !lg,
        desktop: lg,
    };
}
