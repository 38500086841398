/*
 * editTeamCredits.ts provides a GraphQL mutation used to modify the credits
 * settings of a team.
 */
import gql from 'graphql-tag';
import { useMutation, UseMutationResponse } from 'urql';

import { newMutations } from '../frog';
import { eventFragment, EventRecord } from '../queries/events';

export type FlatEventRecord = EventRecord;

export interface UpdateEventArgs {
    input: {
        id: number;
        patch: Partial<FlatEventRecord>;
    };
}

export type UpdateEventResult = {
    updateEvent: {
        event: EventRecord;
    };
};

export const updateEventMutation = eventFragment.gql`
    mutation UpdateEvent(
        $input: UpdateEventInput!
    ) {
        updateEvent(input: $input) {
            event {
                ${eventFragment}
            }
        }
    }
`;

export const [executeUpdateEventMutation, useUpdateEventMutation] = newMutations<
    UpdateEventArgs,
    UpdateEventResult
>(updateEventMutation);

export interface CreateEvent {
    eventType: string;
    message?: string;
    ownerId?: number;
    projectId?: number;
}

export const CreateEventMutation = gql`
    mutation createEvent(
        $eventType: String!
        $message: String
        $ownerId: BigInt
        $projectId: BigInt
    ) {
        createEvent(
            input: {
                event: {
                    eventTypeName: $eventType
                    message: $message
                    ownerId: $ownerId
                    projectId: $projectId
                }
            }
        ) {
            clientMutationId
        }
    }
`;

export function useCreateEvent(): UseMutationResponse<never, CreateEvent> {
    return useMutation<never, CreateEvent>(CreateEventMutation);
}
