/*
 * Assign is the estimator sub-module for choosing an estimator from a list.
 */
import React, { FC, useEffect, useState } from 'react';

import { QueryHookOptions } from '@apollo/client';
import to from 'await-to-js';

import { ActionBar } from './ActionBar';
import { Estimator } from './Estimator';
import { Search } from './Search';
import {
    EstimatorAssignmentAllEstimators,
    EstimatorAssignmentContainer,
    EstimatorAssignmentContentContainer,
    EstimatorAssignmentHeader,
} from './styled';

import { DatabaseProjectStatus } from '@/common/types';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import {
    IEstimatorLightFragment,
    IEstimatorStatus,
    IUserRole,
    useAssignEstimatorMutation,
    IEstimatorsForProjectQuery,
    useEstimatorsForProjectLazyQuery,
    IEstimatorsForProjectQueryVariables,
    IEstimatorsOrderBy,
    IEstimatorsLiteQueryVariables,
    IEstimatorsLiteQuery,
    useEstimatorsLiteLazyQuery,
    IUsersOrderBy,
} from '@/graphql';
import { BaseUserRecord } from '@/queries/baseUsers';
import { useFeatures } from '@/contexts/Features';

const COPY = {
    assignEstimator: 'Assign estimator',
    assignmentErrorTitle: 'Estimator assignment failed',
    assignmentErrorBody: 'Try again',
    assignmentSuccessBody: ' has been notified of this project',
    assignmentSuccessTitle: 'Estimator assigned',
    estimatorsError: 'There was a problem getting the estimators. Please try again',
};

const DEFAULT_PAGE_SIZE = 1000;

export const Assign: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { setEstimator, project, setProject, selectedEstimator, setSelectedEstimator } =
        useProjectPanel();

    const {
        features: { marketplaceRating },
    } = useFeatures();

    const { addNotification } = useNotifications();
    const {
        data: { user },
    } = useUser();

    const [assignEstimator, { loading: loadingEstimatorAssignment }] = useAssignEstimatorMutation();

    const [estimators, setEstimators] = useState<IEstimatorLightFragment[]>([]);

    const defaultFilters = {
        arguments: {
            first: DEFAULT_PAGE_SIZE,
        },
    };

    const estimatorsLiteInput = (
        orderBy: IUsersOrderBy
    ): QueryHookOptions<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables> => ({
        variables: {
            input: {
                ...defaultFilters,
                condition: {
                    roles: [IUserRole.Estimator],
                    showPaused: false,
                },
                orderBy,
            },
        },
    });

    const estimatorsForProjectInput = (
        orderBy: IEstimatorsOrderBy
    ): QueryHookOptions<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables> => ({
        variables: {
            input: {
                ...defaultFilters,
                condition: {
                    teamID: project?.teamId?.toString(),
                    showPaused: false,
                },
                orderBy,
            },
        },
    });

    const [fetchEstimatorsLite, { loading: loadingEstimatorsLite, error: errorEstimatorsLite }] =
        useEstimatorsLiteLazyQuery({
            onCompleted: (data) => handleSetEstimatorsLite(data),
        });

    const [
        fetchEstimatorsForProject,
        { loading: loadingEstimatorsForProject, error: errorEstimatorsForProject },
    ] = useEstimatorsForProjectLazyQuery({
        onCompleted: (data) => handleSetEstimatorsForProject(data),
    });

    const handleSetEstimatorsLite = (data: IEstimatorsLiteQuery) => {
        try {
            if (errorEstimatorsLite) {
                throw new Error(errorEstimatorsLite.message);
            }

            const mappedData =
                data?.users.edges?.map(({ node: user }) => ({
                    ...user,
                })) ?? [];

            setEstimators(mappedData);
        } catch (e) {
            addNotification({ title: 'Error', content: String(e) }, 'error');
        }
    };

    const handleSetEstimatorsForProject = (data: IEstimatorsForProjectQuery) => {
        try {
            if (errorEstimatorsForProject) {
                throw new Error(errorEstimatorsForProject.message);
            }

            const mappedData =
                data?.estimatorsForProject.edges?.map(({ node: user }) => ({
                    ...user,
                })) ?? [];

            setEstimators(mappedData);
        } catch (e) {
            addNotification({ title: 'Error', content: String(e) }, 'error');
        }
    };

    const handleSetEstimators = () => {
        if (!marketplaceRating) {
            fetchEstimatorsLite(estimatorsLiteInput(IUsersOrderBy.Natural));
        } else {
            fetchEstimatorsForProject(estimatorsForProjectInput(IEstimatorsOrderBy.Natural));
        }
    };

    useEffect(() => {
        handleSetEstimators();
    }, []);

    const handleAssignEstimator = async (): Promise<void> => {
        if (!selectedEstimator) {
            throw new Error(COPY.estimatorsError);
        }

        const [error] = await to(
            assignEstimator({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        ownerID: user.id,
                        userID: selectedEstimator.id.toString(),
                    },
                },
            })
        );

        if (error) {
            addNotification(
                { title: COPY.assignmentErrorTitle, content: COPY.assignmentErrorBody },
                'error'
            );
        } else {
            setProject((project) => ({
                ...project,
                status: DatabaseProjectStatus.PENDING_ESTIMATOR,
                projectUsers: {
                    nodes: [{ user: selectedEstimator }, ...(project.projectUsers?.nodes ?? [])],
                },
            }));
            setEstimator(selectedEstimator);
            setSelectedEstimator(undefined);
            addNotification(
                {
                    title: COPY.assignmentSuccessTitle,
                    content: (
                        <p>
                            <span>{`${selectedEstimator.firstName ?? ''} ${
                                selectedEstimator.lastName ?? ''
                            }`}</span>
                            {COPY.assignmentSuccessBody}
                        </p>
                    ),
                },
                'success'
            );
        }
    };

    if (loadingEstimatorsLite || loadingEstimatorsForProject || loadingEstimatorAssignment) {
        return <SpinnerLoader />;
    }

    return (
        <EstimatorAssignmentContainer>
            <EstimatorAssignmentContentContainer>
                <EstimatorAssignmentHeader>{COPY.assignEstimator}</EstimatorAssignmentHeader>
                <Search useProjectPanel={useProjectPanel} />
                <EstimatorAssignmentAllEstimators>
                    {estimators
                        .filter((estimator) => estimator.status !== IEstimatorStatus.Paused)
                        .map((estimator, idx) => (
                            <Estimator
                                key={idx}
                                estimator={estimator as unknown as BaseUserRecord}
                                useProjectPanel={useProjectPanel}
                                showPreferredByBuilder={marketplaceRating}
                                preferredByBuilder={estimator.preferredByBuilder}
                            />
                        ))}
                </EstimatorAssignmentAllEstimators>
            </EstimatorAssignmentContentContainer>
            <ActionBar onClick={handleAssignEstimator} useProjectPanel={useProjectPanel} />
        </EstimatorAssignmentContainer>
    );
};
