import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useLocalStorage from '@rehooks/local-storage';

import { InfoContainer } from './styled';

import { Header } from './Header';
import { LocationAutocomplete } from './LocationAutocomplete';
import { renderValue, Summary } from './Summary';
import { ProjectInfo } from './ProjectInfo';
import { Markups } from './Markups';
import { useMastheadGrandTotal } from './hooks/useMastheadTotal';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { StorageKey } from '@/common/storage';
import { NavBar } from './NavBar';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/32/chevron--down.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/32/chevron--up.svg';

export const Masthead: FC<PaperProps> = ({ ...props }) => {
    const screenSize = useScreenSize();

    const [isCollapsed, setIsCollapsed] = useLocalStorage<boolean>(
        StorageKey.IsEstimateHeaderCollapsed,
        false
    );

    const { grandTotal, totalMarkups } = useMastheadGrandTotal();

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                padding: '0 24px',
            }}
            {...props}
        >
            <Stack direction="row" justifyContent="space-between">
                <Header />
                <Stack alignItems="center" direction="row" spacing="8px">
                    <Typography sx={{ whiteSpace: 'nowrap' }} variant="h3">
                        {renderValue(grandTotal)}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        size="small"
                        startIcon={
                            <SvgIcon
                                sx={{
                                    height: '18px',
                                    width: '18px',
                                }}
                                viewBox="0 0 32 32"
                            >
                                {isCollapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </SvgIcon>
                        }
                        variant="outlined"
                    >
                        Markups
                    </Button>
                </Stack>
            </Stack>
            <LocationAutocomplete />
            <Collapse
                in={isCollapsed}
                sx={{
                    paddingBottom: '8px',
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <InfoContainer>
                        {screenSize !== ScreenSize.Desktop && (
                            <Summary grandTotal={grandTotal} totalMarkups={totalMarkups} />
                        )}
                        <Markups />
                        <ProjectInfo />
                    </InfoContainer>
                    {screenSize === ScreenSize.Desktop && (
                        <Summary grandTotal={grandTotal} totalMarkups={totalMarkups} />
                    )}
                </Stack>
            </Collapse>
            <NavBar />
        </Paper>
    );
};
