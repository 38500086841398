import Stack, { StackProps } from '@mui/material/Stack';
import React, { FC } from 'react';

import { ColorPicker } from '../LinearPanel/ColorPicker';
import { ShapePicker } from './ShapePicker';
import { SizePicker } from './SizePicker';

const CountMarkerPanel: FC<StackProps> = () => {
    return (
        <Stack direction="row" spacing="8px">
            <ShapePicker />
            <SizePicker />
            <ColorPicker />
        </Stack>
    );
};

export default CountMarkerPanel;
