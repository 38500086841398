import { TrackEventName, useSegmentTrackEventWithUserData } from '@/common/analytics';
import { useSnackbar } from 'notistack';

// Check if file is greater than 200MB
const isValidFileSize = (size: number) => size / 10e5 < 200;

const allowedMimeTypes = ['application/pdf', 'application/zip', 'application/x-zip-compressed'];

// capture image/* files, pdfs
const isValidFileType = (type: string) =>
    type.startsWith('image') || allowedMimeTypes.includes(type);

const COPY = {
    errorFileType: (filename: string) =>
        `Upload of ${filename} is not supported. Please upload an image, pdf, or zip file.`,
    errorFileSize: (filename: string) =>
        `Upload of ${filename} is not supported. Please upload an image, pdf, or zip file under 200MB in size.`,
};

export const useIsUploadValidFileType = () => {
    const track = useSegmentTrackEventWithUserData();
    const { enqueueSnackbar } = useSnackbar();

    const getValidFileList = (files: File[]) => {
        const errors: string[] = [];

        const validFiles = files.filter((file) => {
            const validFileType = isValidFileType(file.type);

            if (!validFileType) {
                track(TrackEventName.InvalidFileFormat);

                enqueueSnackbar(COPY.errorFileType(file.name), {
                    variant: 'error',
                    autoHideDuration: 5000,
                });

                errors.push(`${file.name} (invalid type)`);

                return false;
            }

            const validFileSize = isValidFileSize(file.size);

            if (!validFileSize) {
                track(TrackEventName.InvalidFileSize);

                enqueueSnackbar(COPY.errorFileSize(file.name), {
                    variant: 'error',
                    autoHideDuration: 5000,
                });

                errors.push(`${file.name} (invalid size)`);

                return false;
            }

            return true;
        });

        const error =
            errors.length > 0
                ? `The following files are invalid: ${errors.join(
                      ', '
                  )}. Please upload files that are images, pdfs, or zip under 200MB in size.`
                : null;

        return {
            validFiles,
            error,
        };
    };

    return {
        getValidFileList,
    };
};
