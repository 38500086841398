import styled from 'styled-components';

import { colorBackground } from '@/variables';

export const Container = styled.div`
    cursor: ${(props): string => (props.onClick ? 'pointer' : 'default')};

    position: relative;

    display: flex;
    flex-direction: column;

    background: ${colorBackground};
    box-shadow: 0px 51px 67px rgba(0, 0, 0, 0.07), 0px 14.5916px 12.4842px rgba(0, 0, 0, 0.0503198),
        0px 7.67308px 4.42419px rgba(0, 0, 0, 0.0417275),
        0px 4.5434px 2.49313px rgba(0, 0, 0, 0.035),
        0px 2.74017px 1.89164px rgba(0, 0, 0, 0.0282725),
        0px 1.36904px 1.23029px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;

    width: 302px;
    padding: 8px 15px 12px 16px;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;

    color: #fff;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 8px;
        width: 100%;
        background: linear-gradient(90.12deg, #6891cf 5.21%, #5278bc 47.19%, #2c4d9b 89.49%);
        border-radius: 4px 4px 0px 0px;
    }
`;

export const BasicToastCopy = styled.div`
    min-height: 48px;
    display: flex;
    align-items: center;
`;
