import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

export const ActionButton = styled.div`
    cursor: pointer;
    margin: 1.5rem;
    display: flex;
`;

export const StyledDialogTitle = styled(DialogTitle)`
    color: #000;
`;
