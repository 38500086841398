/* Displays project trades ids as circular labels. Clicking on them copies to clipboard. */

import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { TradeCircle } from './Trade';
import { MoreTradesIcon } from '../icons/MoreTradesIcon';
import { Portal } from '@/components/app/Portal';
import { Trade } from '@/common/types';
import { copyToClipboard } from '@/common/utils/clipboard';
import { colorBackgroundLighter } from '@/variables';

type TradesProps = {
    trades: Trade[];
    howManyToShow: number;
    background?: string;
    v2?: boolean;
};

import './Trades.scss';

export const TooltipDiv = styled.div`
    position: absolute;
    background-color: ${colorBackgroundLighter};
    z-index: 100000;
    pointer-events: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transform: translate(-50%, 25%);

    &::before {
        background-color: ${colorBackgroundLighter};
        position: absolute;
        content: '';
        width: 0.75rem;
        height: 0.75rem;
        left: calc(50% - 0.35rem);
        top: -0.1875rem;
        transform: rotate(-45deg);
    }
`;

export const Trades: FC<TradesProps> = ({ trades, howManyToShow, background, v2 }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [copied, setCopied] = useState(false);

    const handleTradesCopy = async (): Promise<void> => {
        const copied = await copyToClipboard(trades.map((t) => t.name).join(', '));
        if (copied) {
            setCopied(true);
        }
    };

    useEffect(() => {
        let copiedTimeout: ReturnType<typeof setTimeout>;

        if (copied) {
            copiedTimeout = setTimeout(() => setCopied(false), 500);
        }

        return (): void => {
            if (copiedTimeout) {
                clearTimeout(copiedTimeout);
            }
        };
    }, [copied]);

    const renderCopiedMessage = (): JSX.Element => {
        const container = containerRef.current;
        if (!container) return <></>;
        const boundingRect = container.getBoundingClientRect();
        return (
            <Portal id="copied-tooltip">
                <TooltipDiv
                    style={{
                        left: boundingRect.left + container.offsetWidth / 2,
                        top: boundingRect.top + container.offsetHeight,
                    }}
                >
                    <span>Copied!</span>
                </TooltipDiv>
            </Portal>
        );
    };

    const renderTrades = (): JSX.Element => (
        <div ref={containerRef} className="overlapping-circles" onClick={handleTradesCopy}>
            {trades.slice(0, howManyToShow - 1).map((item) => (
                <TradeCircle
                    v2={v2}
                    renderTooltip={true}
                    className="circle"
                    key={item.id}
                    trade={item}
                    border={`2px solid ${background ?? ''}`}
                />
            ))}
            {howManyToShow === trades.length && (
                <TradeCircle
                    v2={v2}
                    renderTooltip={true}
                    className="circle"
                    key={trades.length - 1}
                    trade={trades[trades.length - 1]}
                    border={`2px solid ${background ?? ''}`}
                />
            )}
            {trades.length > howManyToShow && (
                <MoreTradesIcon trades={trades.slice(howManyToShow - 1)} background={background} />
            )}
            {copied && renderCopiedMessage()}
        </div>
    );

    return renderTrades();
};
