import React, { FC, useRef } from 'react';
import FocusTrap from 'focus-trap-react';

import {
    CommentContainer,
    CommentArea,
    Container,
    Header,
    Overlay,
    Prompt,
    RatingContainer,
    RatingHint,
    RatingPrompt,
    SubmitButton,
    SubmitContainer,
    TagBubble,
    TagBubbleWrapper,
    TagContainer,
} from './styled';
import { RatingBar } from '@/components/ui/controls/RatingBar';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { isHTMLElement } from '@/common/typeGuards';
import { useReviewTagsQuery, IReviewTagsQuery, IReviewTagFragment } from '@/graphql';
import { getNodes } from '@/common/utils/helpers';

const COPY = {
    headerTemplate: 'Thanks for the feedback on',
    ratingPrompt: 'Your rating',
    ratingHint: '(click to change)',
    commentPrompt: 'Please provide any additional details.',
    commentPlaceholder: 'Enter message...',
    submit: 'Submit',
    negativeTagPrompt: 'What could have been improved?',
    positiveTagPrompt: 'What went particularly well?',
    tagHint: '(select multiple)',
};

export type ModalProps = {
    projectName: string;
    comment: string;
    rating: number;
    reviewTags: Record<string, IReviewTagFragment>;
    setComment: (comment: string) => void;
    setRating: (value: number | null) => void;
    setReviewTags: (tag: IReviewTagFragment) => void;
    close: () => void;
    submit: () => Promise<void>;
};
export const Modal: FC<ModalProps> = ({
    projectName,
    comment,
    rating,
    reviewTags,
    setComment,
    setRating,
    setReviewTags,
    close,
    submit,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const reviewTagsQuery = useReviewTagsQuery({});

    const fetchedReviewTags = getNodes<IReviewTagsQuery, IReviewTagFragment>(
        reviewTagsQuery,
        'reviewTags'
    );

    /**
     * Toggle review tag active state.
     * @param {IReviewTagFragment} updatedReviewTag review tag to toggle in map.
     */
    const toggleReviewTag = (updatedReviewTag: IReviewTagFragment): void => {
        setReviewTags(updatedReviewTag);
    };

    // Exit on esc
    useDocumentListener(
        'keydown',
        (e) => {
            if (e.key === 'Escape') {
                close();
            }
        },
        [close]
    );

    return (
        <FocusTrap>
            <Overlay
                // Exit on click out
                onContextMenu={(e): void => {
                    if (!e.defaultPrevented) {
                        close();
                    }
                }}
                onClick={(e): void => {
                    if (!e.defaultPrevented) {
                        e.preventDefault();
                        close();
                    }
                }}
            >
                <Container
                    hasTag
                    ref={modalRef}
                    // Prevent context menu from selecting all text and close from firing
                    onClick={(e): void => e.preventDefault()}
                    onContextMenu={(e): void => e.preventDefault()}
                >
                    <Header>
                        {COPY.headerTemplate} {projectName}!
                    </Header>

                    <RatingContainer>
                        <RatingPrompt>
                            {COPY.ratingPrompt} <RatingHint>{COPY.ratingHint}</RatingHint>
                        </RatingPrompt>

                        <RatingBar large rating={rating} onChange={(value) => setRating(value)} />
                    </RatingContainer>

                    <TagContainer>
                        <Prompt>
                            {rating === 5 ? COPY.positiveTagPrompt : COPY.negativeTagPrompt}{' '}
                            {COPY.tagHint}
                        </Prompt>

                        <TagBubbleWrapper>
                            {fetchedReviewTags
                                .filter(({ isPositive }) =>
                                    rating === 5 ? isPositive : !isPositive
                                )
                                .map((fetchedReviewTag) => (
                                    <TagBubble
                                        key={fetchedReviewTag.id}
                                        isActive={!!reviewTags[fetchedReviewTag.id]}
                                        onClick={(): void => toggleReviewTag(fetchedReviewTag)}
                                    >
                                        {fetchedReviewTag.tag}
                                    </TagBubble>
                                ))}
                        </TagBubbleWrapper>
                    </TagContainer>

                    <CommentContainer>
                        <Prompt>{COPY.commentPrompt}</Prompt>
                        <CommentArea
                            tabIndex={0}
                            value={comment}
                            onChange={({ target: { value } }): void => setComment(value)}
                            placeholder={COPY.commentPlaceholder}
                            onFocus={({ target }): void => {
                                if (isHTMLElement(target)) {
                                    target.select();
                                }
                            }}
                        />
                    </CommentContainer>

                    <SubmitContainer>
                        <SubmitButton
                            tabIndex={0}
                            onClick={(): void => {
                                submit();
                            }}
                            onKeyDown={(e): void => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    submit();
                                }
                            }}
                        >
                            {COPY.submit}
                        </SubmitButton>
                    </SubmitContainer>
                </Container>
            </Overlay>
        </FocusTrap>
    );
};
