import { Cell, CellType, Coordinates, Element, LeydenEditor, Table } from 'leyden';
import { ReactEditor } from 'leyden-react';
import { Editor, Node } from 'slate';

import { Queries } from '.';
import { Serialize } from '../serialization';
import { tokenScalarIs } from '@/common/expressionToken';
import { TokenKind, TokenScalar } from '@/graphql/customScalars';

export interface ElementQueries {
    elementQuantity: (
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ) => QueriedElementCell<'Quantity'>;
    element: (editor: Editor, elementID: string) => ElementRow | null;
    elements: (
        editor: Editor,
        options?: {
            categoryID?: string | null;
            expressionFeatures?: ExpressionFeatures;
            reverse?: boolean;
        }
    ) => Generator<ElementRow, void, undefined>;
    editedElementExpression: (
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ) => TokenScalar[] | null;
}

export const ElementQueries: ElementQueries = {
    /**
     * Get an element quantity cell.
     */

    elementQuantity(editor, options = {}) {
        const { at } = options;
        if (at !== undefined) {
            const cellAt = Table.cell(LeydenEditor.table(editor), { at, type: 'Quantity' });
            if (cellAt === null) {
                throw new Error('failed to retrieve element quantity: `at` is not a quantity cell');
            }
            return [cellAt, at];
        }
        const cell = LeydenEditor.selectedCell(editor, { type: 'Quantity' });
        if (cell === null) {
            throw new Error('failed to retrieve element quantity: quantity cell not selected');
        }
        const coords = ReactEditor.cellCoords(editor, cell);
        if (coords === null) {
            throw new Error('failed to retrieve element quantity: could not get selected coords');
        }
        return [cell, coords];
    },

    /**
     * Get the row information of an element.
     */

    element(editor, elementID) {
        for (const element of Queries.elements(editor)) {
            if (element[0].elementID === elementID) {
                return element;
            }
        }
        return null;
    },

    /**
     * Iterate through element rows.
     */

    *elements(editor, options = {}) {
        const { categoryID, expressionFeatures, reverse } = options;
        for (const [cell, coords] of Table.column(LeydenEditor.table(editor), {
            at: 1,
            reverse,
            type: 'Quantity',
        })) {
            // We consider undefined to be a match for null due to Slate not allowing null as a property value
            // and our use of null as a property value for categoryID in the ITEM assembly.
            // @see https://github.com/ianstormtaylor/slate/issues/4836
            const matchesCategoryID =
                categoryID === undefined ||
                cell.categoryID === categoryID ||
                (categoryID === null && cell.categoryID === undefined);

            const matchesExpressionFeatures =
                !expressionFeatures ||
                expressionTokensHaveFeatures(cell.element.expression.tokens, expressionFeatures);
            if (matchesCategoryID && matchesExpressionFeatures) {
                yield [
                    {
                        categoryID: cell.categoryID,
                        elementID: cell.elementID,
                        elementUUID: cell.elementUUID,
                        name: cell.element.name,
                        expressionResult: cell.element.expression.result,
                        expressionTokens: cell.element.expression.tokens,
                        unitID: cell.element.unit.id,
                        rates: {
                            materialRate: cell.element.materialRate,
                            laborRate: cell.element.laborRate,
                            productionRate: cell.element.productionRate,
                            equipmentRate: cell.element.equipmentRate,
                        },
                    },
                    coords.y,
                ];
            }
        }
    },

    /**
     * Get the expression tokens of an element that is being edited.
     * Returns null if the cell is not being edited.
     * Empty expressions are interpreted as '0'.
     */

    editedElementExpression(editor, options) {
        const [cell] = ElementQueries.elementQuantity(editor, options);
        const firstChild = cell.children[0];
        if (Element.isElement(firstChild, { type: 'ElementQuantityExpressionInputView' })) {
            for (const [nodeElement] of Node.elements(firstChild)) {
                if (Element.isElement(nodeElement, { type: 'Expression' })) {
                    const res = nodeElement.children.flatMap(Serialize.Token.ScalarsFromDescendant);
                    return res;
                }
            }
            return null;
        } else if (Element.isElement(firstChild, { type: 'ElementQuantityNumericInputView' })) {
            const stringyExpression = Node.string(firstChild);
            // Allow enter of formatted numbers like '1,205'
            const parsedStringyExpression = stringyExpression.replaceAll(',', '');
            const numericExpression = parseFloat(parsedStringyExpression);
            if (isNaN(numericExpression)) {
                return null;
            }
            return [Serialize.Token.Scalar(TokenKind.Numeric, { quantity: numericExpression })];
        }
        return null;
    },
};

interface ExpressionFeatures {
    markupIDs?: Set<string>;
    markupGroupIDs?: Set<string>;
}

// expressionTokensHaveFeatures returns true if the passed expression tokens
// match any **but not necessarily all** features in the passed set.
const expressionTokensHaveFeatures = (
    tokens: TokenScalar[],
    { markupIDs, markupGroupIDs }: ExpressionFeatures
): boolean => {
    if (markupIDs && markupIDs.size > 0) {
        if (
            tokens.some(
                (token) => tokenScalarIs(token, TokenKind.Geometry) && markupIDs.has(token.value.id)
            )
        ) {
            return true;
        }
    }
    if (markupGroupIDs && markupGroupIDs.size > 0) {
        if (
            tokens.some(
                (token) =>
                    tokenScalarIs(token, TokenKind.GeometryGroup) &&
                    markupGroupIDs.has(token.value.id)
            )
        ) {
            return true;
        }
    }
    return false;
};

interface ElementData {
    categoryID: string | null;
    elementID: string;
    elementUUID: string;
    name: string;
    expressionResult: number;
    expressionTokens: TokenScalar[];
    unitID: string;
    rates: {
        materialRate: number;
        laborRate: number;
        productionRate: number;
        equipmentRate: number;
    };
}

type QueriedElementCell<T extends CellType = CellType> = [Cell<T>, Coordinates];
type ElementRow = [ElementData, number];
