import gql from 'graphql-tag';

export const requestRevisionMutation = gql`
    mutation RequestRevision(
        $projectId: BigInt!
        $approvalRequested: Boolean!
        $additionalInfo: String!
        $estimateDueDate: String!
        $ownerId: BigInt!
    ) {
        requestRevision(
            input: {
                projectId: $projectId
                approvalRequested: $approvalRequested
                additionalInfo: $additionalInfo
                estimateDueDate: $estimateDueDate
                ownerId: $ownerId
            }
        ) {
            project {
                id
            }
        }
    }
`;
