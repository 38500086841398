import { CellRenderer } from 'leyden-react';
import React from 'react';

import { useCategoryIsCollapsed } from '../../hooks/useCategoryIsCollapsed';

export const Quantity: CellRenderer<'Quantity'> = ({ attributes, children, element: cell }) => {
    const isInCollapsedCategory = useCategoryIsCollapsed(cell.categoryID ?? null);

    if (isInCollapsedCategory) {
        return <></>;
    }
    return <div {...attributes}>{children}</div>;
};
