import styled from 'styled-components';

export const RadioInputWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
});

export const RadioButtonCircle = styled.label({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    background: '#FFFFFF',
    border: '1px solid #bebebe',
    margin: 'auto',
});

export const RadioInput = styled.input({
    position: 'relative',
    opacity: 0,
    zIndex: 1,
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    margin: 0,
    cursor: 'pointer',

    [`&:hover ~ ${RadioButtonCircle}`]: {
        background: '#bebebe',
        '&::after': {
            content: '""',
            display: 'block',
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            margin: '5px',
            background: '#eeeeee',
        },
    },

    [`&:checked + ${RadioButtonCircle}`]: {
        background: '#FFFFFF',
        border: '1px solid #305DFF',

        '&::after': {
            content: '""',
            display: 'block',
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            margin: '5px',
            boxShadow: '1px 3px 3px 1px rgb(0 0 0 / 10%)',
            background: '#305DFF',
        },
    },
});

export const RadioLabel = styled.label({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
});
