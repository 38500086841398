import React, { useCallback } from 'react';

import { useNotifications } from '@/contexts/Notifications';
import {
    IAssemblyFragment,
    IAssemblyLibraryAssignmentMutation,
    IAssemblyLibraryOperation,
    IAssemblyType,
    useAssemblyLibraryAssignmentMutation,
} from '@/graphql';

const COPY = {
    favoritingSuccessSingular: 'Added to My Library',
    favoritingSuccessPluralTemplate: 'Added to My Library',
    favoritingSuccessDescriptionSingularPart1: 'is now',
    favoritingSuccessDescriptionSingularPart2: 'My Library.',
    favoritingSuccessDescriptionPluralPart1: 'The assemblies are now',
    favoritingSuccessDescriptionPluralPart2: 'My Library.',
    additionPart: 'accessible in',
    removalPart: 'removed from',
};

type UseHandleAssemblyLibraryAssignmentPayload = (
    assemblies: IAssemblyFragment[],
    operation: IAssemblyLibraryOperation
) => Promise<IAssemblyLibraryAssignmentMutation>;

export const useHandleAssemblyLibraryAssignment = (): UseHandleAssemblyLibraryAssignmentPayload => {
    const { addNotification } = useNotifications();

    const [assemblyLibraryAssignmentMutation] = useAssemblyLibraryAssignmentMutation();

    return useCallback(
        (
            assemblies: IAssemblyFragment[],
            operation: IAssemblyLibraryOperation
        ): Promise<IAssemblyLibraryAssignmentMutation> => {
            const assemblyIDs = assemblies.map((a) => a.id);

            return new Promise<IAssemblyLibraryAssignmentMutation>((resolve, reject) => {
                assemblyLibraryAssignmentMutation({
                    variables: {
                        input: {
                            assemblyIDs,
                            operation,
                            assemblyType: IAssemblyType.Group,
                        },
                    },
                    refetchQueries: [
                        'Assemblies',
                        'AssembliesLite',
                        'LibraryElements',
                        'AssemblyLibraries',
                        'LibraryElements',
                    ],
                    update: (cache) => {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: 'assemblies' });
                        cache.gc();
                    },
                }).then((result) => {
                    if (result.data) {
                        const edges = result.data.assemblyLibraryAssignment.edges;
                        if (edges && edges.length === 1) {
                            const assembly = edges[0].node;
                            addNotification(
                                {
                                    title: COPY.favoritingSuccessSingular,
                                    content: (
                                        <p>
                                            {/* eslint-disable-next-line max-len */}
                                            {`${assembly.description} ${
                                                COPY.favoritingSuccessDescriptionSingularPart1
                                            } ${
                                                operation === IAssemblyLibraryOperation.Add
                                                    ? COPY.additionPart
                                                    : COPY.removalPart
                                            } ${COPY.favoritingSuccessDescriptionSingularPart2}`}
                                        </p>
                                    ),
                                },
                                'success'
                            );
                        }
                        if (edges && edges.length > 1) {
                            addNotification(
                                {
                                    title: `${COPY.favoritingSuccessPluralTemplate}`,
                                    // eslint-disable-next-line max-len
                                    content: (
                                        <p>{`${COPY.favoritingSuccessDescriptionPluralPart1} ${
                                            operation === IAssemblyLibraryOperation.Add
                                                ? COPY.additionPart
                                                : COPY.removalPart
                                        } ${COPY.favoritingSuccessDescriptionPluralPart2}`}</p>
                                    ),
                                },
                                'success'
                            );
                        }
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
            });
        },
        []
    );
};
