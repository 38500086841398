import styled from 'styled-components';

import { focusBorder } from '@/common/styles/focusBorder';

export const Container = styled.div`
    cursor: pointer;

    min-width: 72px;
    min-height: 35px;
    box-sizing: border-box;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${(props): string => props.theme.v0.colorTypographyDark};

    opacity: 0.9;

    color: ${(props): string => props.theme.v0.colorTypographyDark};
    background: #ffffff;
    border-radius: 4px;

    transition: opacity 0.4s;

    &:hover {
        opacity: 1;
    }

    ${focusBorder}
`;
