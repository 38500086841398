import { distinctUntilChanged, map } from 'rxjs/operators';

import { useObservable } from '@/common/hooks/useObservable';
import { setsAreEqual } from '@/common/rxjs/equality';
import { GeometryType, SelectedGeometryType } from '@/common/types';
import { selectedGeometriesObservable } from '@/components/takeoff/observables/interface';

const selectedTypesObservable = selectedGeometriesObservable.pipe(
    map((selection) => {
        const selectedTypes = new Set<SelectedGeometryType>();
        if (selection.points.length > 0) {
            selectedTypes.add('point');
        }
        const remainingTypes = new Set([
            GeometryType.AREA,
            GeometryType.LINEAR,
            GeometryType.COUNT,
        ]);
        for (let i = 0; i < selection.geometries.length && remainingTypes.size > 0; i++) {
            const geometryType = selection.geometries[0].type;
            if (remainingTypes.has(geometryType)) {
                remainingTypes.delete(geometryType);
                selectedTypes.add(geometryType);
            }
        }
        return selectedTypes;
    }),
    distinctUntilChanged(setsAreEqual)
);

export const useSelectedTypes = () => useObservable(selectedTypesObservable, new Set());
