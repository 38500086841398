import React, { FC, Dispatch, SetStateAction } from 'react';

import { RadioRow } from '@/components/ui/inputs/RadioRow';

import {
    Container,
    Label,
    Modal,
    ModalTextarea,
    ModalNumericInput,
    RadioLabel,
    RadioContainer,
    Text,
    Error,
} from './styled';
import { DeclineDetails } from '@/common/types';

const COPY = {
    button: 'Got it',
    header: 'Why are you declining?',
    reason_option_one: 'Not enough hours',
    reason_option_two: 'Other reason',
    hours_number_label: 'How many hours do you think you would need to complete this project?',
    hours_number_placeholder: 'Enter a number',
    hours_number_comment_label: 'Please explain your reasoning behind the number above.',
    hours_number_comment_text: `Examples: Architectural Finishes are unclear, many sheets to review, 
    difficult details on Sheet A1.05, complex roof framing, difficult client, 
    my first time doing an all-trades job, etc`,
    hours_number_comment_placeholder: 'Enter your answer',
    other_reason_comment_label: 'Tell us why',
    other_reason_comment_placeholder: 'Enter your answer',
    error_message: 'Please fill in all the fields',
};

type DeclineModalProps = {
    close: () => void;
    submit: () => void;
    declineDetails: DeclineDetails;
    handleData: Dispatch<SetStateAction<DeclineDetails>>;
    error?: boolean;
};
export const DeclineModal: FC<DeclineModalProps> = ({
    close,
    submit,
    declineDetails,
    handleData,
    error = false,
}) => (
    <Modal
        header={COPY.header}
        close={close}
        submit={submit}
        submitCopy={COPY.button}
        className="modal-container"
    >
        <Container>
            <RadioContainer>
                <RadioRow
                    id="1"
                    key="1"
                    isChecked={!declineDetails.otherReasonSelected}
                    onChange={(): void =>
                        handleData((previousState) => ({
                            ...previousState,
                            otherReasonSelected: false,
                        }))
                    }
                >
                    <RadioLabel>{COPY.reason_option_one}</RadioLabel>
                </RadioRow>
                <RadioRow
                    id="2"
                    key="2"
                    isChecked={declineDetails.otherReasonSelected}
                    onChange={(): void =>
                        handleData((previousState) => ({
                            ...previousState,
                            otherReasonSelected: true,
                        }))
                    }
                >
                    <RadioLabel>{COPY.reason_option_two}</RadioLabel>
                </RadioRow>
            </RadioContainer>
            {declineDetails.otherReasonSelected ? (
                <div>
                    <Label>{COPY.other_reason_comment_label}</Label>
                    <ModalTextarea
                        rows={5}
                        placeholder={COPY.other_reason_comment_placeholder}
                        value={declineDetails.refusalSummary}
                        onChange={(value): void =>
                            handleData((previousState) => ({
                                ...previousState,
                                refusalSummary: value,
                            }))
                        }
                    />
                </div>
            ) : (
                <div>
                    <Label>{COPY.hours_number_label}</Label>
                    <ModalNumericInput
                        type="number"
                        placeholder={COPY.hours_number_placeholder}
                        value={declineDetails.expectedDuration}
                        onChange={(value): void =>
                            handleData((previousState) => ({
                                ...previousState,
                                expectedDuration: value,
                            }))
                        }
                    />
                    <Label>{COPY.hours_number_comment_label}</Label>
                    <Text>{COPY.hours_number_comment_text}</Text>
                    <ModalTextarea
                        rows={5}
                        placeholder={COPY.hours_number_comment_placeholder}
                        value={declineDetails.expectedDurationSummary}
                        onChange={(value): void =>
                            handleData((previousState) => ({
                                ...previousState,
                                expectedDurationSummary: value,
                            }))
                        }
                    />
                </div>
            )}
            {error && <Error>{COPY.error_message}</Error>}
        </Container>
    </Modal>
);
