import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';
import { AdminDashboard } from '@/components/AdminDashboard';
import { ProjectsProvider as ProjectsProvider1 } from '@/components/AdminDashboard/Projects/context';
import { ProjectsProvider as ProjectsProvider2 } from '@/components/projects/context';
import { Projects as ProjectComponent1 } from '@/components/AdminDashboard/Projects';
import { Projects as ProjectsComponent2 } from '@/components/projects';
import { NewProjectButton } from '@/components/projects/NewProjectButton';
import { TopbarContent } from '@/components/AdminDashboard/Projects/TopbarContent';
import { useUser } from '@/contexts/User';
import { isAdministratorUser, isBuilderUser } from '@/views/Projects/utils';
import { HeadingStack, Main, MainHeading } from './styled';
import './Projects.scss';

export const COPY = {
    title: 'Dashboard',
};

export const Projects: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const user = useUser();

    return (
        <div {...props}>
            {(() => {
                if (isAdministratorUser(user.data.user)) {
                    return (
                        <ProjectsProvider1>
                            <AdminDashboard topbarContent={<TopbarContent />}>
                                <ProjectComponent1 />
                            </AdminDashboard>
                        </ProjectsProvider1>
                    );
                } else {
                    return (
                        <ProjectsProvider2>
                            <Main>
                                <Container>
                                    <HeadingStack
                                        alignItems="center"
                                        justifyContent="space-between"
                                        direction="row"
                                    >
                                        <MainHeading variant="h1">{COPY.title}</MainHeading>
                                        {isBuilderUser(user.data.user) && (
                                            <NewProjectButton size="medium" />
                                        )}
                                    </HeadingStack>
                                    <Divider />
                                    <ProjectsComponent2
                                        sx={{
                                            marginTop: '30px',
                                        }}
                                    />
                                </Container>
                            </Main>
                        </ProjectsProvider2>
                    );
                }
            })()}
        </div>
    );
};
