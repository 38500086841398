import moment from 'moment';
import React, { CSSProperties, FC, KeyboardEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ProjectDetailsComponentProps } from '../context';
import { RichText } from '@/components/ui/inputs/RichText';
import { IconButton } from '@/components/ui/buttons/IconButton';
import { SmallButton } from '@/components/ui/buttons/SmallButton';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import { useSlateMarkdown } from '@/common/hooks/useSlateMarkdown';
import { useUser } from '@/contexts/User';
import { EventFullRecord } from '@/queries/events';
import { colorTypographyDarkTertiary } from '@/variables';

import { ReactComponent as Edit } from '@/assets/icons/edit.svg';
import { ReactComponent as Bin } from '@/assets/icons/trash-can.svg';
import { robotEventTypes } from '@/common/utils/robotUser';
import { IUserRole } from '@/graphql';

const COPY = {
    readOnlyMessageUserWasDeleted: 'User was deleted',
};

/* eslint-disable max-len */
const markdownLinkify = (input: string): string =>
    input.replace(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
        '[$1]($1)'
    );
/* eslint-enable max-len */

// `user-select` is set above `RichText` to ensure that the `user-select` property which is set manually on the entire
// form does not not propogate into the Slate.js component. This does not affect the desired behavior - all children
// are still unselectable when the form's `user-select` is `none`. This avoids a Slate.js bug:
// https://github.com/ianstormtaylor/slate/issues/3421
const UserAndMessageContainer = styled.div`
    display: flex;
    user-select: auto;
    flex-direction: column;
    flex-grow: 1;
`;

export interface MessageProps extends ProjectDetailsComponentProps {
    message: EventFullRecord;
    saveMessage: (message: string) => Promise<void>;
    removeMessage: () => Promise<void>;
}

export const Message: FC<MessageProps> = ({
    message,
    saveMessage,
    removeMessage,
    useProjectDetails,
}) => {
    // Contexts
    //---------------------------------------------------------------------
    const {
        data: { user },
    } = useUser();
    const { editedComment, setEditedComment } = useProjectDetails();

    // Hooks
    //---------------------------------------------------------------------
    const isMobile = useIsMobile();
    const { editor, nodes, setNodes, setValue, getMarkdown, isBlank } = useSlateMarkdown('');

    // State
    //---------------------------------------------------------------------
    const [isDisabled, setIsDisabled] = useState(false);

    // Memoized Values
    //---------------------------------------------------------------------
    const isEditable = useMemo(() => {
        const editable = !isMobile && editedComment !== null && editedComment.id === message.id;
        setValue(editable ? message.message ?? '' : markdownLinkify(message.message ?? ''));
        if (isDisabled && !isEditable) {
            setIsDisabled(false);
        }
        return editable;
    }, [editedComment, isMobile]);

    // isActionable represents whether a user can take action on a readonly message.
    const isActionable = useMemo(
        (): boolean =>
            !isEditable &&
            !isDisabled &&
            !robotEventTypes.includes(message.eventTypeName) &&
            (user?.id === message.owner?.id.toString() ||
                (user?.roles?.includes(IUserRole.Superadmin) &&
                    !message.owner?.roles?.includes(IUserRole.Builder))),
        [isEditable, isDisabled, message.owner]
    );

    const formStyle = useMemo(
        (): CSSProperties =>
            isDisabled
                ? {
                      opacity: 0.6,
                      pointerEvents: 'none',
                      userSelect: 'none',
                  }
                : {},
        [isDisabled]
    );

    const name = useMemo(
        (): string =>
            message.owner
                ? `${message.owner?.firstName ?? ''} ${message.owner?.lastName ?? ''}`
                : COPY.readOnlyMessageUserWasDeleted,
        [message.owner]
    );

    const time = useMemo((): string => {
        const offset = moment().format('Z');
        return moment.utc(message.created).utcOffset(offset).format('MM/DD/YY h:mm a');
    }, [message.created]);

    // Helper Functions
    //---------------------------------------------------------------------
    // saveEditedComment updates the edited comment to the current value of the
    // rich text input, then triggers the context's save method.
    const saveEditedComment = (): void => {
        if (!editedComment) {
            return;
        }
        saveMessage(getMarkdown());
        setIsDisabled(true);
    };

    /* Submit form on enter press */
    const handleKeydown = (e: KeyboardEvent<HTMLFormElement>): void => {
        if (e.key !== 'Enter' || e.getModifierState('Shift')) {
            return;
        }
        if (isBlank()) {
            e.preventDefault();
            return;
        }
        saveEditedComment();
    };

    const handleEditMessage = (): void => {
        setEditedComment(message);
    };

    const handleRemoveMessage = (): void => {
        setIsDisabled(true);
        removeMessage();
    };

    // Render Functions
    //---------------------------------------------------------------------
    const renderEditButtons = (): JSX.Element => (
        <div className="edit-buttons">
            <SmallButton
                variant="outlined"
                className="form-button"
                onClick={(): void => setEditedComment(null)}
            >
                Cancel
            </SmallButton>
            <SmallButton className="form-button" onClick={saveEditedComment}>
                Post
            </SmallButton>
        </div>
    );

    const renderReadonlyButtons = (): JSX.Element => (
        <div className="buttons">
            {!isMobile && <IconButton Icon={Edit} className="button" onClick={handleEditMessage} />}
            <IconButton Icon={Bin} className="button" onClick={handleRemoveMessage} />
        </div>
    );

    return (
        <form
            onKeyDown={handleKeydown}
            onSubmit={saveEditedComment}
            style={formStyle}
            className="message"
        >
            <UserAndMessageContainer>
                <div>
                    <span className="name">{name}</span>
                    <span className="time">{time}</span>
                </div>
                <RichText
                    editor={editor}
                    value={nodes}
                    onChange={setNodes}
                    color={colorTypographyDarkTertiary}
                    container={{
                        className: 'content',
                        style: { flexGrow: 2 },
                    }}
                    placeholder={isEditable ? 'Say something...' : undefined}
                    autoFocus={isEditable}
                    readOnly={!isEditable}
                />
            </UserAndMessageContainer>
            {isEditable && renderEditButtons()}
            {isActionable && renderReadonlyButtons()}
        </form>
    );
};
