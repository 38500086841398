/*
 * This context exposes feature flags, that are being used to toggle features
 */
import { makeContext } from '../common/utils/makeContext';
import { useRef, RefObject } from 'react';

export interface LoadingPayload {
    actionButtonsRef: RefObject<HTMLDivElement>;
    leftButtonsRef: RefObject<HTMLDivElement>;
    searchBarRef: RefObject<HTMLDivElement>;
}

/**
 * Keep track of toolbar ref
 */
const { useConsumer, Provider } = makeContext<LoadingPayload>(() => {
    const actionButtonsRef = useRef<HTMLDivElement>(null);
    const leftButtonsRef = useRef<HTMLDivElement>(null);
    const searchBarRef = useRef<HTMLDivElement>(null);

    return {
        actionButtonsRef,
        leftButtonsRef,
        searchBarRef,
    };
});

export const useToolbar = useConsumer;
export const ToolbarProvider = Provider;
