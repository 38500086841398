/*
 * A collection of helper function meant to support advanced geometry
 * manipulation such as affine transformations, splitting, subtracting, getting
 * the bounding box of geometries.
 */
import {
    toLeaflet as coordinateDataToLeaflet,
    fromFlatten as coordinateDatafromFlatten,
} from '@/common/convert/coordinateData';
import { fromFlatten, toFlatten, toLeaflet } from '@/common/convert/geometry';
import { CoordinateData, Geometry, GeometryType, Point } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import Flatten from '@flatten-js/core';
import { FeatureGroup, LatLngBounds, Marker, Polygon, Polyline } from 'leaflet';
import { v4 as uuid } from 'uuid';

export type GeometryOperation = (
    polygons: Geometry<GeometryType.AREA>[],
    booleanGeometry: Geometry<GeometryType.AREA | GeometryType.LINEAR>
) => Geometry<GeometryType.AREA>[];

const unify = Flatten.BooleanOperations.unify;
const subtract = Flatten.BooleanOperations.subtract;
const intersect = Flatten.BooleanOperations.intersect;

const boundingBoxInputIsGeometryArray = (
    data: Array<CoordinateData | Point> | Geometry[]
): data is Geometry[] => {
    return Array.isArray(Reflect.get(data[0], 'coordinates'));
};

export const getBoundingBox = (data: Array<CoordinateData | Point> | Geometry[]): LatLngBounds => {
    let leafletData;
    if (boundingBoxInputIsGeometryArray(data)) {
        leafletData = data
            .map(toLeaflet)
            .reduce<Array<Marker | Polyline | Polygon>>((acc, geometry) => {
                return acc.concat(geometry);
            }, []);
    } else {
        leafletData = data.map(coordinateDataToLeaflet);
    }
    return new FeatureGroup(leafletData).getBounds();
};

const transformPolyline = (
    polyline: Geometry<GeometryType.LINEAR>,
    scaleRatio: number,
    translateVector: Flatten.Vector
): Geometry<GeometryType.LINEAR> => {
    const scaledFlatten = toFlatten(polyline).transform(
        new Flatten.Matrix(scaleRatio, 0, 0, scaleRatio, translateVector.x, translateVector.y)
    );
    return fromFlatten(scaledFlatten, polyline);
};

const transformPolygon = (
    polygon: Geometry<GeometryType.AREA>,
    scaleRatio: number,
    translateVector: Flatten.Vector
): Geometry<GeometryType.AREA> => {
    const scaledFlatten = toFlatten(polygon).transform(
        // In order to preserve real world measurements,
        // each of the dimensions is only scaled by a
        // square root of the scaleRatio.
        new Flatten.Matrix(scaleRatio, 0, 0, scaleRatio, translateVector.x, translateVector.y)
    );
    return fromFlatten(scaledFlatten, polygon);
};

const transformPoint = (
    count: Geometry<GeometryType.COUNT>,
    scaleRatio: number,
    translateVector: Flatten.Vector
): Geometry<GeometryType.COUNT> => {
    const scaledFlatten = toFlatten(count).map((point) =>
        point.transform(
            new Flatten.Matrix(scaleRatio, 0, 0, scaleRatio, translateVector.x, translateVector.y)
        )
    );
    return fromFlatten(scaledFlatten, count);
};

// A helper function meant to simplify affine transformations of markups.
// It isn't meant for raw affine transformations - it's supposed to preserve
// the real world measurements of the input geometries.
export const transformGeometries = (
    geometries: Geometry[],
    scaleRatio: number,
    translateVector?: Flatten.Vector
): Geometry[] => {
    const finalTranslateVector = translateVector ? translateVector : new Flatten.Vector(0, 0);

    const transformGeometry = newGeometryHandler<Geometry>({
        [GeometryType.AREA]: (area) => {
            return transformPolygon(area, scaleRatio, finalTranslateVector);
        },
        [GeometryType.COUNT]: (count) => {
            return transformPoint(count, scaleRatio, finalTranslateVector);
        },
        [GeometryType.LINEAR]: (line) => {
            return transformPolyline(line, scaleRatio, finalTranslateVector);
        },
    });
    return geometries.map(transformGeometry);
};

const normalizeGeometry = (
    geometry: Geometry<GeometryType.AREA | GeometryType.LINEAR>
): [Flatten.Polygon, Geometry<GeometryType.AREA>] => {
    /*
     * This function takes a geometry. If it's a line, it converts it into
     * a polygon. If the coordinates of the polygon are counterclockwise, it
     * reverses them. It returns a tuple of the resulting flattenPolygon and
     * the input geometry (converted if it was required).
     */
    // If splittingGeometry is linear, convert to Polygon
    const { geometry: finalGeometry } = newGeometryHandler<{
        geometry: Geometry<GeometryType.AREA>;
    }>({
        [GeometryType.AREA]: (area) => {
            return { geometry: area };
        },
        [GeometryType.COUNT]: () => {
            throw new Error('unexpected count geometry in normalization function');
        },
        [GeometryType.LINEAR]: (line) => {
            return {
                geometry: {
                    ...line,
                    type: GeometryType.AREA,
                    coordinates: [line.coordinates],
                },
            };
        },
    })(geometry);

    const flattenPolygon = toFlatten(finalGeometry);

    const normalizedPolygon = new Flatten.Polygon();

    // If for whatever reason the geometry's coordinates are defined
    // counter-clockwise, we have to reverse them for the subtract and
    // intersect operations to work correctly.
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    for (const face of flattenPolygon.faces) {
        if (face.orientation() === -1) {
            face.reverse();
        }
        normalizedPolygon.addFace(face);
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */
    /* eslint-enable @typescript-eslint/no-unsafe-call */

    return [normalizedPolygon, finalGeometry];
};

export const addPolygons: GeometryOperation = (polygons, addingGeometry) => {
    const [flattenAddingPolygon] = normalizeGeometry(addingGeometry);

    const intersectingPolygons: Geometry<GeometryType.AREA>[] = [];

    // Find input polygons that intersect with the boolean geometry.
    for (const polygon of polygons) {
        const [flattenPolygon] = normalizeGeometry(polygon);

        const intersectsAdding = flattenPolygon.intersect(flattenAddingPolygon).length > 0;

        if (intersectsAdding) {
            intersectingPolygons.push(polygon);
        }
    }

    // If no input polygon intersects with the boolean geometry, return the
    // input
    if (intersectingPolygons.length === 0) {
        return polygons;
    }

    let outputPolygon: Geometry<GeometryType.AREA> = intersectingPolygons[0];

    let [flattenOutputPolygon] = normalizeGeometry(outputPolygon);

    // Add the boolean geometry to the first of the input polygons
    outputPolygon = fromFlatten(unify(flattenOutputPolygon, flattenAddingPolygon), outputPolygon);

    // Add every other input geometry that intersects with the boolean geometry
    // to the output polygon
    for (let i = 1; i < intersectingPolygons.length; i++) {
        [flattenOutputPolygon] = normalizeGeometry(outputPolygon);
        const [flattenIntersectingPolygon] = normalizeGeometry(intersectingPolygons[i]);
        outputPolygon = fromFlatten(
            unify(flattenOutputPolygon, flattenIntersectingPolygon),
            outputPolygon
        );
    }

    if (outputPolygon.coordinates.length === 0) {
        return polygons;
    }

    return [outputPolygon];
};

export const splitPolygons: GeometryOperation = (polygons, splittingGeometry) => {
    const [flattenSplittingPolygon, newSplittingGeometry] = normalizeGeometry(splittingGeometry);

    splittingGeometry = newSplittingGeometry;

    let outputPolygons: Geometry<GeometryType.AREA>[] = [];

    for (const polygon of polygons) {
        const [flattenPolygon] = normalizeGeometry(polygon);

        const intersectsSplitting = flattenPolygon.intersect(flattenSplittingPolygon).length > 0;

        const modifyGeometry = (
            modifier: (p: Flatten.Polygon, s: Flatten.Polygon) => Flatten.Polygon
        ) => coordinateDatafromFlatten(modifier(flattenPolygon, flattenSplittingPolygon));

        outputPolygons = [
            ...outputPolygons,
            { ...polygon, coordinates: modifyGeometry(subtract) },
            {
                ...polygon,
                uuid: uuid(),
                coordinates: intersectsSplitting
                    ? modifyGeometry(intersect)
                    : newSplittingGeometry.coordinates,
            },
        ];
    }

    for (const polygon of outputPolygons) {
        if (Array.isArray(polygon.coordinates) && polygon.coordinates.length === 0) {
            return polygons;
        }
    }

    return outputPolygons;
};

export const subtractPolygons: GeometryOperation = (polygons, subtrahendGeometry) => {
    const [flattenSubtrahendPolygon] = normalizeGeometry(subtrahendGeometry);

    let outputPolygons: Geometry<GeometryType.AREA>[] = [];

    for (const polygon of polygons) {
        const [flattenPolygon] = normalizeGeometry(polygon);

        const subtractionResult = fromFlatten(
            subtract(flattenPolygon, flattenSubtrahendPolygon),
            polygon
        );

        if (subtractionResult.coordinates.length === 0) {
            return polygons;
        }

        // If the result is empty, don't save it
        if (subtractionResult.coordinates[0].length !== 0) {
            outputPolygons = [...outputPolygons, subtractionResult];
        }
    }
    return outputPolygons;
};
