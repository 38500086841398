import {
    ElementRenderer,
    useCellIsSelected,
    useCoordinates,
    useLeydenStatic,
    useOwnCell,
} from 'leyden-react';
import React, { MouseEventHandler } from 'react';

import {
    ElementQuantityEditorWrapper,
    ElementQuantityEditorInnerWrapper,
    ElementQuantityEditorValue,
    ElementQuantityEditorArrowContainer,
    ElementQuantityEditorArrowIcon,
    ElementQuantityFormulaEditorWrapper,
    ElementQuantityFormulaEditorEditor,
    ElementQuantityFormulaEditorTitle,
} from './styled';
import { useElementQuantityEditorEffects } from '../hooks/useElementQuantityEditorEffects';
import { Transforms } from '../../transforms';

const COPY = {
    formula: 'Formula',
};

export const ElementQuantityExpressionInputView: ElementRenderer<
    'ElementQuantityExpressionInputView'
> = ({ attributes, children, element }) => {
    useElementQuantityEditorEffects(element);

    const coords = useCoordinates(element);
    const editor = useLeydenStatic();
    const selected = useCellIsSelected(element);

    const quantityCell = useOwnCell(element, { type: 'Quantity' });
    const quantity = quantityCell?.element.expression.result.toFixed(2) ?? '0';

    const handleQuantityMouseDown: MouseEventHandler = (e): void => {
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (!selected) {
            if (coords === null) {
                throw new Error('cannot handle takeoff click: cannot select quantity cell');
            }
            Transforms.selectCell(editor, { at: coords });
        }
        Transforms.editElementQuantityNumeric(editor);
    };

    return (
        <ElementQuantityEditorWrapper {...attributes}>
            <ElementQuantityEditorInnerWrapper highlighted={false}>
                <ElementQuantityEditorArrowContainer
                    contentEditable={false}
                    onMouseDown={handleQuantityMouseDown}
                >
                    <ElementQuantityEditorArrowIcon />
                </ElementQuantityEditorArrowContainer>
                <ElementQuantityEditorValue
                    contentEditable={false}
                    onMouseDown={handleQuantityMouseDown}
                >
                    {quantity}
                </ElementQuantityEditorValue>
                <ElementQuantityFormulaEditorWrapper>
                    <ElementQuantityFormulaEditorTitle contentEditable={false}>
                        {COPY.formula}
                    </ElementQuantityFormulaEditorTitle>
                    <ElementQuantityFormulaEditorEditor>
                        {children}
                    </ElementQuantityFormulaEditorEditor>
                </ElementQuantityFormulaEditorWrapper>
            </ElementQuantityEditorInnerWrapper>
        </ElementQuantityEditorWrapper>
    );
};
