import React, { FC, UIEventHandler, useCallback, useEffect, useRef } from 'react';

import {
    OptionsMenuContainer,
    OptionsMenuOption,
    OptionsMenuOptionContainer,
    OptionsMenuOptionContainerProps,
} from './styled';

import { DivProps, Setter } from '@/common/types';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';
import { IMaterialLightFragment } from '@/graphql';

/* eslint-disable @typescript-eslint/indent */
export type EstimateOptionsMenuProps = Partial<
    Pick<OptionsMenuOptionContainerProps, 'overrideColors'> & {
        additionalOption: {
            jsx: JSX.Element;
            onClick: () => void;
        };
    }
>;
/* eslint-enable @typescript-eslint/indent */

export interface OptionsMenuProps extends DivProps, EstimateOptionsMenuProps {
    activeOptionIdx: number | null;
    setActiveOptionIdx: Setter<number | null>;
    loading: boolean;
    options: IMaterialLightFragment[];
    onSelectOption: (opt: IMaterialLightFragment) => void;
    setPxLeftToScroll: Setter<number>;
}

export const OptionsMenu: FC<OptionsMenuProps> = ({
    activeOptionIdx,
    loading,
    options,
    setActiveOptionIdx,
    setPxLeftToScroll,
    onMouseLeave,
    onSelectOption,
    overrideColors,
    additionalOption,
    onScroll,
    ...rest
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    // run setPxLeftToScroll even when the list does not scroll by default
    useEffect(() => {
        if (
            options.length !== 0 &&
            containerRef.current &&
            containerRef.current.clientHeight === containerRef.current.scrollHeight
        ) {
            setPxLeftToScroll(0);
        }
    }, [containerRef, options]);

    const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
        (e) => {
            if (onScroll) {
                onScroll(e);
            }
            if (containerRef.current) {
                const { clientHeight, scrollHeight, scrollTop } = containerRef.current;
                setPxLeftToScroll(scrollHeight - scrollTop - clientHeight);
            }
        },
        [containerRef, onScroll]
    );

    useEffect(() => {
        if (activeOptionIdx !== null) {
            containerRef.current?.children[activeOptionIdx]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [activeOptionIdx, containerRef]);

    return (
        <OptionsMenuContainer
            {...rest}
            onScroll={handleScroll}
            onMouseLeave={(e): void => {
                if (e.defaultPrevented) {
                    return;
                }
                if (onMouseLeave) {
                    onMouseLeave(e);
                }
                setActiveOptionIdx(null);
            }}
            ref={containerRef}
        >
            {options.map((opt, optionIdx) => (
                <OptionsMenuOptionContainer
                    onClick={(e): void => {
                        e.preventDefault();
                        onSelectOption(opt);
                    }}
                    onMouseEnter={(e): void => {
                        if (!e.defaultPrevented) {
                            setActiveOptionIdx(optionIdx);
                        }
                    }}
                    isActive={activeOptionIdx === optionIdx}
                    overrideColors={overrideColors}
                    key={opt.id}
                >
                    <OptionsMenuOption>{opt.name}</OptionsMenuOption>
                </OptionsMenuOptionContainer>
            ))}

            {loading && (
                <OptionsMenuOptionContainer
                    isActive={false}
                    style={{
                        background: 'transparent',
                    }}
                >
                    <DotsLoader
                        style={{
                            alignItems: 'center',
                            cursor: 'auto',
                            display: 'flex',
                            gridArea: 'opt',
                            userSelect: 'none',
                        }}
                        dotProps={{
                            style: {
                                backgroundColor: 'rgba(91, 96, 110',
                            },
                        }}
                    />
                </OptionsMenuOptionContainer>
            )}

            {additionalOption && (
                <OptionsMenuOptionContainer
                    hasTopBorder={!!options.length}
                    onClick={(e): void => {
                        e.preventDefault();
                        additionalOption.onClick();
                    }}
                    onMouseEnter={(e): void => {
                        if (!e.defaultPrevented) {
                            setActiveOptionIdx(options.length + 1);
                        }
                    }}
                    isActive={activeOptionIdx === options.length + 1}
                    overrideColors={overrideColors}
                >
                    {additionalOption.jsx}
                </OptionsMenuOptionContainer>
            )}
        </OptionsMenuContainer>
    );
};
