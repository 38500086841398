import { Subject, Subscription } from 'rxjs';

import { ButtonProps } from './Button';

export interface OpenModalPayload {
    accentColor: string;
    title: string;
    body: string;
    buttons: ButtonProps[];
}

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

export const openedModalObservable = new Subject<OpenModalPayload>();

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToOpenedModalObservable = (
    subscribe: (payload: OpenModalPayload) => void
): Subscription => openedModalObservable.subscribe(subscribe);

/****************************************************************************/
/* External Pushing Functions                                               */
/****************************************************************************/

export const pushToOpenedModalObservable = (payload: OpenModalPayload): void =>
    openedModalObservable.next(payload);
