import { ElementRenderer, useCellIsSelected, useLeydenStatic, useOwnCell } from 'leyden-react';
import React, { useEffect } from 'react';

import { Cell } from '../components/Cell';
import { Transforms } from '../../transforms';

export const ElementQuantityDefaultView: ElementRenderer<'ElementQuantityDefaultView'> = ({
    attributes,
    children,
    element,
}) => {
    const editor = useLeydenStatic();
    const quantityCell = useOwnCell(element, { type: 'Quantity' });
    const selected = useCellIsSelected(element);
    const quantity = quantityCell?.element.expression.result.toFixed(2) ?? 0;

    useEffect(() => {
        if (!selected) {
            return;
        }
        setTimeout(() => {
            Transforms.editElementQuantityNumeric(editor);
        });
    }, [selected]);

    if (quantityCell === null) {
        return <></>;
    }

    return (
        <Cell
            attributes={attributes}
            caret="hide-inactive"
            element={quantityCell}
            hasClickAction={true}
            highlightOnHover={true}
        >
            {quantity}
            {children}
        </Cell>
    );
};
