import React, { FC, MouseEventHandler, useState } from 'react';

import { Editable } from 'leyden-react';

import { EstimateEditor } from '../editor/EstimateEditor';
import { useHandleKeyDown } from '../editor/useHandleKeyDown';
import { useEstimateEditor } from '../hooks/useEstimateEditor';
import { cellRenderers } from './cells';
import { ContextMenu } from './components/ContextMenu';
import { elementRenderers } from './elements';
import { headerRenderers } from './headers';
import { ContextMenuContext, ContextMenuData } from './hooks/useContextMenu';
import { TableContainer } from './styled';
import { textRenderers } from './text';

import { DivProps } from '@/common/types';
import { CellDialogProvider } from '@/components/Estimate/Table/contexts/useCellDialog';
import CellDialog from '@/components/Estimate/Table/components/CellDialog/CellDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TableSeeder } from '@/components/Estimate/Table/TableSeeder';
import { LeydenWrapper } from './LeydenWrapper';

export interface Table extends DivProps {
    gridArea: string;
    onElementsAdded: (callback: () => Promise<unknown>) => void;
}

const PAGE_SIZE = 5;

export const Table: FC<Table> = ({ gridArea, style, onMouseLeave, onElementsAdded, ...props }) => {
    const [resetFunction, setResetFunction] = useState<() => void>();

    const editor = useEstimateEditor();
    const handleKeyDown = useHandleKeyDown();

    const [contextMenuData, setContextMenuData] = useState<ContextMenuData | null>(null);

    const flags = useFlags();

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
        if (onMouseLeave) {
            onMouseLeave(e);
        }
        if (e.defaultPrevented) {
            return;
        }
        EstimateEditor.clearHoveredRow(editor);
    };

    return (
        <ContextMenuContext.Provider
            value={{
                data: contextMenuData,
                close: (): void => setContextMenuData(null),
                open: setContextMenuData,
            }}
        >
            <CellDialogProvider>
                <TableContainer
                    {...props}
                    onMouseLeave={handleMouseLeave}
                    style={{ ...style, gridArea }}
                >
                    <LeydenWrapper editor={editor} setResetFunction={setResetFunction}>
                        <Editable
                            cellRenderers={cellRenderers}
                            elementRenderers={elementRenderers}
                            headerRenderers={headerRenderers}
                            textRenderers={textRenderers}
                            tableOptions={{
                                cellGap: 1,
                                stickyColumnHeaders: true,
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        {flags.mobileAssemblyPanel && <CellDialog />}
                    </LeydenWrapper>
                    <TableSeeder
                        resetFunction={resetFunction}
                        pageSize={PAGE_SIZE}
                        onElementsAdded={onElementsAdded}
                    />
                </TableContainer>
                <ContextMenu />
            </CellDialogProvider>
        </ContextMenuContext.Provider>
    );
};
