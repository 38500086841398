import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { MaterialCategory } from './MaterialCategory';
import { ListWithPagination, ListWithPaginationProps } from './ListWithPagination';
import { ISourceTypeV2, useCostDataCategoriesQuery } from '@/graphql';
import isNil from 'lodash/isNil';
import { compact } from 'lodash';

interface ItemAssemblyListProps extends ListWithPaginationProps {
    currentProjectId: number;
}

export const MaterialCategoryList: FC<ItemAssemblyListProps> = ({ currentProjectId, ...props }) => {
    const { search } = useAssemblyPanel();
    const sourceType = ISourceTypeV2.Material;

    const { data: categoriesData, loading } = useCostDataCategoriesQuery({
        variables: {
            input: { sourceType: sourceType, searchTerm: search },
        },
    });
    const categories = compact(
        categoriesData?.legacyCategories?.filter((category) => !isNil(category))
    );

    if (loading) {
        return (
            <List>
                <ListItem dense>
                    <Skeleton sx={{ height: '16px', margin: '2px 0', width: '100%' }} />
                </ListItem>
            </List>
        );
    }

    return (
        <ListWithPagination hasNextPage={false} {...props}>
            {categories?.map((category) => (
                <MaterialCategory
                    key={category}
                    category={category}
                    currentProjectId={currentProjectId}
                />
            ))}
        </ListWithPagination>
    );
};
