/*
 * observables.ts initializes and provides access to RxJS observables used to
 * interface with the admin dashboard's top bar
 */
import { BehaviorSubject, Subscription } from 'rxjs';

import { TabType } from '@/components/AdminDashboard/types';

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

// Selected tab
export const selectedTabObservable = new BehaviorSubject<TabType>(TabType.Current);

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToSelectedTabObservable = (
    subscribe: (tabType: TabType) => void
): Subscription => selectedTabObservable.subscribe(subscribe);
