import { useEffect, useState } from 'react';

import { useEstimateEditor } from './useEstimateEditor';
import { EstimateEditor } from '../editor/EstimateEditor';
import { estimateCostFromElementTotals } from '../utils/transforms';
import { EstimateCost } from '../utils/types';

export interface UseEstimateCost extends EstimateCost {
    total: number;
}

export const useEstimateCost = (): UseEstimateCost => {
    const editor = useEstimateEditor();

    const [cost, setCost] = useState<EstimateCost>({
        direct: 0,
        generalConditions: 0,
        equipment: 0,
    });

    useEffect(() => {
        let canceled = false;
        setTimeout(() => {
            if (!canceled) {
                setCost(EstimateEditor.estimateCost(editor));
            }
        }, 250);
        return (): void => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;
        const unsubscribe = EstimateEditor.subscribeToElementTotals(editor, null, (totals) => {
            if (!canceled) {
                setCost(estimateCostFromElementTotals(totals));
            }
        });
        return (): void => {
            canceled = true;
            unsubscribe();
        };
    }, []);

    return {
        ...cost,
        total: cost.direct + cost.equipment + cost.generalConditions,
    };
};
