/**
 * PlusIcon is a simple, circled plus icon.
 **/
import React, { CSSProperties, FC } from 'react';

import { CircleIcon } from './CircleIcon';
import { SvgIcon } from './SvgIcon';
import { colorIconHighlight } from '@/variables';
import { ReactComponent as PlusSign } from '@/assets/icons/plus.svg';

import './PlusIcon.scss';

export interface PlusIconProps {
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    circled?: boolean;
    diameter?: string;
    style?: CSSProperties;
}

export const PlusIcon: FC<PlusIconProps> = ({
    circled = true,
    diameter = '21px',
    className,
    onClick,
    style,
}) => (
    <CircleIcon
        className={className}
        diameter={diameter}
        border={circled ? `1px solid ${colorIconHighlight}` : ''}
        onClick={onClick}
        style={style}
    >
        <SvgIcon className="plus-icon" src={PlusSign} noInherit={true} />
    </CircleIcon>
);
