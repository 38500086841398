import React, { FC } from 'react';

import { ProjectInfoInput, ProjectInfoInputProps } from '.';
import { ProjectInfoDropdown } from './styled';
import { FullDropdownProps } from '@/components/ui/inputs/Dropdown';

interface ProjectInfoDropdownInputProps extends ProjectInfoInputProps, FullDropdownProps {
    label: string;
}
export const ProjectInfoDropdownInput: FC<ProjectInfoDropdownInputProps> = ({
    className,
    label,
    value,
    options,
    onChangeOption,
}) => (
    <ProjectInfoInput className={className} label={label}>
        <ProjectInfoDropdown value={value} options={options} onChangeOption={onChangeOption} />
    </ProjectInfoInput>
);
