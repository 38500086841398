import React, { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import AreaPanel from '../AreaPanel/AreaPanel';
import CountMarkerPanel from '../CountMarkerPanel/CountMarkerPanel';
import LinearPanel from '../LinearPanel/LinearPanel';
import MixedPanel from '../MixedPanel/MixedPanel';

import { GeometryType } from '@/common/types';
import { useSelectedTypes } from '@/components/takeoff/hooks/useSelectedTypes';

const SelectionPanel: FC<BoxProps> = (props) => {
    const selectedTypes = useSelectedTypes();

    return (
        <Box {...props}>
            {(() => {
                const panels: Record<GeometryType, FC> = {
                    [GeometryType.LINEAR]: LinearPanel,
                    [GeometryType.AREA]: AreaPanel,
                    [GeometryType.COUNT]: CountMarkerPanel,
                };

                if (selectedTypes.size === 0) {
                    return null;
                }

                if (selectedTypes.size === 1) {
                    const geometryType = [...selectedTypes][0];

                    if (geometryType !== 'point') {
                        const Panel = panels[geometryType];

                        return <Panel />;
                    }
                }

                return <MixedPanel />;
            })()}
        </Box>
    );
};

export default SelectionPanel;
