import {
    colorProjectPanelConfirmPrice,
    colorProjectPanelConfirmPriceText,
    colorProjectTableDateInDanger,
    colorProjectTableDateInDangerText,
    colorProjectTableNotification,
} from '@/variables';
import styled from 'styled-components';

export const ProjectsTableAction = styled.div<{ color: string }>`
    display: flex;
    align-items: center;
    height: 1.5rem;
    width: fit-content;
    padding: 0 0.5rem;
    border-radius: 1.25rem;
    border: 1px solid ${(props): string => props.color};
    color: ${(props): string => props.color};
    font-size: 11px;
    font-weight: 700;

    .arrow-icon {
        transform: rotate(270deg);
        margin-left: 0.75rem;
    }
`;

export const ProjectsTableActionIconContainer = styled.div<{ shiftIcon: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 1.5rem;
    width: 1rem;
    margin-left: ${(props): string => (props.shiftIcon ? '-0.25rem' : '0')};
    margin-right: 0.375rem;
`;

export const TextCell = styled.td<{ hasNotification?: boolean }>`
    position: relative;
    padding-left: 2.625rem;
    max-width: 20rem;

    &:before {
        content: '';
        position: absolute;
        height: 0.5rem;
        width: 0.5rem;
        top: calc(50% - 0.25rem);
        left: 1.625rem;
        border-radius: 0.25rem;
        background-color: ${colorProjectTableNotification};
        opacity: ${(props): string => (props.hasNotification ? '1' : '0')};
    }
`;

export const BuilderNameCell = styled.td`
    max-width: 5rem;
`;

export const DateCell = styled.td<{ isInDanger?: boolean }>`
    white-space: nowrap;
    background-color: ${(props): string => (props.isInDanger ? colorProjectTableDateInDanger : '')};
    color: ${(props): string => (props.isInDanger ? colorProjectTableDateInDangerText : '')};
`;

export const EstimatorCell = styled.td`
    max-width: 7rem;
`;

export const PriceTextCell = styled.td<{
    isPriceEstimating?: boolean;
    isPendingApproval?: boolean;
}>`
    position: relative;
    background-color: ${(props): string =>
        props.isPriceEstimating ? colorProjectPanelConfirmPrice : ''};
    color: ${(props): string =>
        props.isPriceEstimating
            ? colorProjectPanelConfirmPriceText
            : props.isPendingApproval
            ? '#A4A7AE'
            : ''};
`;

export const IconWrapper = styled.div`
    display: flex;
    display: -webkit-flex;

    align-items: center;
    -webkit-align-items: center;

    position: absolute;
    right: 1.0625rem;
    top: 0;
    bottom: 0;

    z-index: -10;
`;
