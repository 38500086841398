import { Element } from 'leyden';
import { Descendant } from 'slate';

import { Serialize } from '.';
import { tokenScalarIs } from '@/common/expressionToken';
import { ExpressionElement, Operator, Parenthesis } from '../utils/types';
import { ModifierKind, TokenKind, TokenScalar } from '@/graphql/customScalars';

export interface SerializeElement {
    ElementQuantityDefaultView: () => Element<'ElementQuantityDefaultView'>;
    ElementQuantityExpressionInputView: (
        children: Array<Descendant>
    ) => Element<'ElementQuantityExpressionInputView'>;
    ElementQuantityNumericInputView: (value: number) => Element<'ElementQuantityNumericInputView'>;
    Expression: (value: TokenScalar[]) => Element<'Expression'>;
    ExpressionElement: (scalar: TokenScalar) => ExpressionElement;
    ExpressionNumber: (value: number) => Element<'ExpressionNumber'>;
    ExpressionMarkup: (markupID: string) => Element<'ExpressionMarkup'>;
    ExpressionMarkupGroup: (markupGroupID: string) => Element<'ExpressionMarkupGroup'>;
    ExpressionOperator: (value: Operator) => Element<'ExpressionOperator'>;
    ExpressionParenthesis: (value: Parenthesis) => Element<'ExpressionParenthesis'>;
    ExpressionPlaceholder: () => Element<'ExpressionPlaceholder'>;
    ExpressionVariable: (value: string) => Element<'ExpressionVariable'>;
}

export const SerializeElement: SerializeElement = {
    ElementQuantityDefaultView() {
        return Element.new('ElementQuantityDefaultView', [Serialize.Text.Empty()], {
            isVoid: true,
        });
    },

    ElementQuantityExpressionInputView(children: Array<Descendant>) {
        return Element.new('ElementQuantityExpressionInputView', children, {});
    },

    ElementQuantityNumericInputView(value: number) {
        return Element.new(
            'ElementQuantityNumericInputView',
            [Serialize.Text.ElementQuantityNumeric(value)],
            {}
        );
    },

    Expression(value: TokenScalar[]) {
        return Element.new('Expression', value.map(Serialize.Element.ExpressionElement), {});
    },

    ExpressionElement(scalar: TokenScalar) {
        if (tokenScalarIs(scalar, TokenKind.Numeric)) {
            return Serialize.Element.ExpressionNumber(scalar.value.quantity);
        } else if (tokenScalarIs(scalar, TokenKind.Modifier)) {
            return Serialize.Element.ExpressionOperator(modifierKindOperator[scalar.value.symbol]);
        } else if (tokenScalarIs(scalar, TokenKind.ClauseOpen)) {
            return Serialize.Element.ExpressionParenthesis(Parenthesis.Open);
        } else if (tokenScalarIs(scalar, TokenKind.ClauseClose)) {
            return Serialize.Element.ExpressionParenthesis(Parenthesis.Close);
        } else if (tokenScalarIs(scalar, TokenKind.Geometry)) {
            return Serialize.Element.ExpressionMarkup(scalar.value.id);
        } else if (tokenScalarIs(scalar, TokenKind.GeometryGroup)) {
            return Serialize.Element.ExpressionMarkupGroup(scalar.value.id);
        }
        throw new Error(`unrecognized token scalar kind: ${scalar.kind}`);
    },

    ExpressionMarkup(markupID: string): Element<'ExpressionMarkup'> {
        return Element.new('ExpressionMarkup', [Serialize.Text.Empty()], {
            markupID,
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionMarkupGroup(markupGroupID: string): Element<'ExpressionMarkupGroup'> {
        return Element.new('ExpressionMarkupGroup', [Serialize.Text.Empty()], {
            markupGroupID,
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionNumber(value: number) {
        return Element.new('ExpressionNumber', [Serialize.Text.Empty()], {
            value,
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionOperator(value: Operator) {
        return Element.new('ExpressionOperator', [Serialize.Text.Empty()], {
            value,
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionParenthesis(value: Parenthesis) {
        return Element.new('ExpressionParenthesis', [Serialize.Text.Empty()], {
            value,
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionPlaceholder() {
        return Element.new('ExpressionPlaceholder', [Serialize.Text.Empty()], {
            isInline: true,
            isVoid: true,
        });
    },

    ExpressionVariable(value: string) {
        return Element.new('ExpressionVariable', [Serialize.Text.Empty()], {
            value,
            isInline: true,
            isVoid: true,
        });
    },
};

const modifierKindOperator: Record<ModifierKind, Operator> = {
    [ModifierKind.Plus]: Operator.Sum,
    [ModifierKind.Minus]: Operator.Difference,
    [ModifierKind.Multiply]: Operator.Product,
    [ModifierKind.Divide]: Operator.Quotient,
    [ModifierKind.Modulus]: Operator.Remainder,
    [ModifierKind.Exponent]: Operator.Exponentiation,
};
