import { ChangeEvent } from 'react';
import { toDollarAmount } from '@/common/currency';
import useFormElement, {
    UseFormElementPayload,
} from '@/components/Estimate/Table/components/CellDialog/CellForm/useFormElement';
import { ratesFromCostPerUnit } from '@/components/Estimate/utils/rates';

const getTotalRate = (materialRate: number, laborRate: number, productionRate: number) => {
    let totalRate = materialRate;

    if (productionRate !== 0) {
        totalRate += laborRate / productionRate;
    }

    return totalRate;
};

export interface UseRatesInputsPayload {
    totalRate: UseFormElementPayload<HTMLInputElement>;
    isCalculatedRate: boolean;
    rates: {
        materialRate: UseFormElementPayload<HTMLInputElement>;
        laborRate: UseFormElementPayload<HTMLInputElement>;
        productionRate: UseFormElementPayload<HTMLInputElement>;
    };
}
interface UseRatesInputsProps {
    isAdding: boolean;
    rates?: {
        materialRate: number;
        laborRate: number;
        productionRate: number;
    };
}

const useRatesInputs = ({ isAdding, rates }: UseRatesInputsProps): UseRatesInputsPayload => {
    const defaultMaterialRate = !isAdding ? toDollarAmount(rates?.materialRate).toString() : '0';
    const materialRate = useFormElement<HTMLInputElement>(defaultMaterialRate);

    const defaultLaborRate = !isAdding ? toDollarAmount(rates?.laborRate).toString() : '0';
    const laborRate = useFormElement<HTMLInputElement>(defaultLaborRate);

    const defaultProductionRate = !isAdding ? rates?.productionRate.toString() : '0';
    const productionRate = useFormElement<HTMLInputElement>(defaultProductionRate);

    const calculatedRate = getTotalRate(
        parseFloat(materialRate.value ?? '0'),
        parseFloat(laborRate.value ?? '0'),
        parseFloat(productionRate.value ?? '0')
    ).toFixed(2);

    const totalRate = useFormElement<HTMLInputElement>(calculatedRate);

    const onTotalRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        const rates = ratesFromCostPerUnit(parseFloat(event.target.value));

        totalRate.$setValue?.(event.target.value);

        materialRate.$setValue?.(rates.materialRate.toString());
        laborRate.$setValue?.(rates.laborRate.toString());
        productionRate.$setValue?.(rates.productionRate.toString());
    };

    const onMaterialRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        totalRate.$setValue?.(event.target.value);
        materialRate.onChange?.(event);
    };

    const isCalculatedRate =
        materialRate.value !== '0' && laborRate.value !== '0' && productionRate.value !== '0';

    const validatedTotalRate = !isCalculatedRate ? totalRate.value : calculatedRate.toString();

    return {
        totalRate: {
            $hasChanged:
                materialRate.$hasChanged || laborRate.$hasChanged || productionRate.$hasChanged,
            value: validatedTotalRate,
            onChange: onTotalRateChange,
        },
        isCalculatedRate,
        rates: {
            materialRate: {
                ...materialRate,
                onChange: onMaterialRateChange,
            },
            laborRate,
            productionRate,
        },
    };
};

export default useRatesInputs;
