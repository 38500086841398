import { keyMap } from './hot-keys/keyMap';
import {
    IClientPreferenceStatus,
    ICommitmentFragment,
    IFileDirectory,
    IEstimateType,
    IFormattingPreference,
    IHourlyLaborRateInput,
    IReviewTagFragment,
    ITeamAccountType,
    IUserRole,
    ITeamProjectUploadInput,
} from '@/graphql';
import { FeatureRecord } from '@/queries/feature';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';
import { ProjectRecord } from '@/queries/projects';
import { UomRecord } from '@/queries/unitOfMeasure';
import { DivIconOptions, LatLng } from 'leaflet';
import {
    ButtonHTMLAttributes,
    ChangeEvent,
    DetailedHTMLProps,
    Dispatch,
    HTMLAttributes,
    InputHTMLAttributes,
    RefAttributes,
    RefObject,
    SVGProps,
    SetStateAction,
} from 'react';

export enum Direction {
    Up = 'UP',
    Right = 'RIGHT',
    Down = 'DOWN',
    Left = 'LEFT',
}

export enum WageType {
    NON_UNION = 'NON_UNION',
    UNION = 'UNION',
    PREVAILING_WAGE = 'PREVAILING_WAGE',
    NOT_SURE = 'NOT_SURE',
}

export type KeyHandler = (e?: KeyboardEvent) => void;

export type KeyHandlers = { [key in keyof Partial<typeof keyMap>]: KeyHandler };

export type KeyActionOption = {
    splitKey: string;
};

export type KeyAction = {
    mapping: string;
    trigger: 'keyup' | 'keydown';
    scope: string;
    option?: KeyActionOption;
};

export enum Tier {
    Test = 'test',
    Development = 'development',
    Staging = 'staging',
    Production = 'production',
}

export enum ToolType {
    CALIBRATION = 'calibration',
    SELECTION = 'selection',
    LINEAR = 'linear',
    AREA = 'area',
    COUNT = 'count',
}

export enum ContextMenuDispatcherType {
    LINE = 'line',
    AREA = 'area',
    COUNTMARKER = 'countmarker',
    COUNTGROUPMEMBER = 'countgroupmember',
    VERTEX = 'vertex',
    SHEET = 'sheet',
}

export type ContextMenuPayload = {
    coordinates: LatLng;
    isEdge?: boolean;
    dispatcher: ContextMenuDispatcherType;
};

export type ToolTooltipPayload = {
    origin: [number, number];
    name: string;
    shortcut: string;
};

export type ExportedEstimatePayload = {
    cache: Record<string, unknown>;
    project: ProjectRecord;
    uoms: UomRecord[];
    created: Date;
};

export type Hook<T, U = void> = (args: U) => T;

export type Setter<T> = Dispatch<SetStateAction<T>>;

export type Nil<T> = T | null | undefined;

// prop type helpers
export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
export type DivProps = HTMLAttributes<HTMLDivElement>;
export type SpanProps = HTMLAttributes<HTMLSpanElement>;
export type InputProps = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> &
    RefAttributes<HTMLInputElement>;
export type TextareaProps = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>;
export type SvgProps = SVGProps<SVGSVGElement>;

export type DivTableProps = HTMLAttributes<HTMLDivElement> | HTMLAttributes<HTMLTableElement>;
export type DivTheadProps =
    | HTMLAttributes<HTMLDivElement>
    | HTMLAttributes<HTMLTableSectionElement>;
export type DivTrProps = HTMLAttributes<HTMLDivElement> | HTMLAttributes<HTMLTableRowElement>;
export type DivThProps =
    | HTMLAttributes<HTMLDivElement>
    | HTMLAttributes<HTMLTableHeaderCellElement>;
export type DivBodyProps = DivTheadProps;
export type DivTdProps = HTMLAttributes<HTMLDivElement> | HTMLAttributes<HTMLTableDataCellElement>;

export type DivOrTableSection = HTMLDivElement | HTMLTableSectionElement;

export type InputEvent = ChangeEvent<HTMLInputElement>;
export type TextareaEvent = ChangeEvent<HTMLTextAreaElement>;
export type FormEvent = React.FormEvent<HTMLFormElement>;

export type InputRef =
    | string
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null
    | undefined;

export type Svg = React.StatelessComponent<React.SVGAttributes<SVGElement>>;

export enum GeometryType {
    LINEAR = 'Linear',
    AREA = 'Area',
    COUNT = 'Count',
}

export type LeafletStyleType = {
    color: string;
    // Thickness of the line.
    weight?: number;
    // Size of marker.
    size?: MarkerSize;
    // Marker icon.
    icon?: DivIconOptions;
    // Thickness of the pattern ex. width of the ladder line.
    shapeWeight?: number;
    lineType?: LineType;
    areaType?: AreaType;
    markerIcon?: MarkerIcon;
};

export type Style = {
    color: string;
    // Thickness of the line.
    weight: number;
    // Size of marker.
    size: MarkerSize;
    lineType: LineType;
    areaType: AreaType;
    markerIcon: MarkerIcon;
};

// Each point has a non-persistent uuid assigned upon creation/loading from the database.
export interface Point {
    x: number;
    y: number;
    id: string;
}

export type CoordinateData<T extends GeometryType = GeometryType> = T extends GeometryType.AREA
    ? Point[][]
    : Point[];

export interface Geometry<T extends GeometryType = GeometryType> {
    uuid: string;
    type: T;
    style: LeafletStyleType;
    coordinates: CoordinateData<T>;
}

// SelectedPoint is a point with a reference to its parent count's id.
export interface SelectedPoint extends Point {
    countID: string;
}

// SelectedGeometries is the set of currently selected geometries and points.
export interface SelectedGeometries {
    geometries: Geometry[];
    points: SelectedPoint[];
}

export type SelectedGeometryType = GeometryType | 'point';

// PlanPageGeometry is a member of an array in the `project_plan_page.markup` column.
// `coordinates` is a TWKB buffer of the markup's coordinates.
export interface PlanPageGeometry<T extends GeometryType = GeometryType> {
    uuid: string;
    type: T;
    style: LeafletStyleType;
    coordinates: Buffer;
}

export enum LineType {
    NORMAL = 'normalLine',
    ARROW = 'arrowLine',
    DOTTED = 'dottedLine',
    LADDER = 'ladderLine',
    ZIGZAG = 'zigzagLine',
}

export enum AreaType {
    NORMAL = 'normalArea',
    WOOD = 'woodArea',
    BRICK = 'brickArea',
    STONE = 'stoneArea',
    SHINGLES = 'shinglesArea',
    INSULATION = 'insulationArea',
    CONCRETE = 'concreteArea',
    HARDCORE = 'hardcoreArea',
    BLOCKWORK = 'blockworkArea',
    VERTICAL_LINES = 'verticalLinesArea',
    HORIZONTAL_LINES = 'horizontalLinesArea',
    DOTS = 'dotsArea',
}

export enum BusinessType {
    GENERAL_CONTRACTOR = 'GeneralContractor',
    SUBCONTRACTOR_MULTI_TRADE = 'SubcontractorMultiTrade',
    SUBCONTRACTOR_SINGLE_TRADE = 'SubcontractorSingleTrade',
    DEVELOPER = 'Developer',
    SUPPLIER = 'Supplier',
    DESIGN_BUILD = 'DesignBuild',
    HOMEOWNER = 'Homeowner',
    HANDYMAN = 'Handyman',
    OTHER = 'Other',
}

export enum CompanyRole {
    OWNER = 'Owner',
    PROJECT_MANAGER = 'ProjectManager',
    SUPERINTENDENT = 'Superintendent',
    FOREMAN = 'Foreman',
    ESTIMATOR = 'Estimator',
    ENGINEER = 'Engineer',
    CRAFT = 'Craft',
    OTHER = 'Other',
}

export enum ServiceType {
    ESTIMATOR = 'Estimator',
    SOFTWARE = 'Software',
    BOTH_NOT_SURE = 'BothNotSure',
}

export enum MarkerIcon {
    CIRCLE = 'circleMarker',
    SQUARE = 'squareMarker',
    DIAMOND = 'diamondMarker',
    STAR = 'starMarker',
    TRIANGLE = 'triangleMarker',
    TRIANGLE_REVERSED = 'triangleReversedMarker',
}

export enum MarkerSize {
    S = 'small',
    M = 'medium',
    L = 'large',
}

export type CopyMetadata = {
    origin?: LatLng;
    scale: number;
    cut?: boolean;
};

export type PasteMetadata = {
    origin?: LatLng;
    scale: number;
    currentPage: PlanPage;
};

export type CopyBuffer = CopyMetadata & {
    data: Geometry[];
};

export enum BooleanToolType {
    ADD = 'Add',
    SUBTRACT = 'Subtract',
    SPLIT = 'Split',
}

export enum AllowedURLSignUpRole {
    Builder = 'member',
    Estimator = 'estimator',
    Platform = 'platform',
}

export enum Auth0IdentityProvider {
    Google = 'google-oauth2',
    UsernamePassword = 'auth0',
}

export type Balance = {
    usdCents: number;
};

export type BaseUser = {
    authId: string;
    created: string;
    email?: string;
    firstName?: string;
    id: number;
    lastModified: string;
    lastName?: string;
    phone?: string;
    roles: IUserRole[];
    projectTypes?: string;
    businessType?: BusinessType;
    companyRole?: CompanyRole;
    serviceType?: ServiceType;
    team?: {
        id: number;
        name: string;
        balance: Balance;
        teamFeatures: {
            nodes: {
                feature: FeatureRecord;
            }[];
        };
        teamOnboarding?: {
            accountType: ITeamAccountType;
            welcomed: boolean;
        };
    };
};

export type FeatureFlag = {
    id: string;
    name: string;
    allowInProduction: boolean;
    isEnabled: boolean;
};

export type Trade = {
    id: string;
    name: string;
    nickname: string;
    csiDivision?: string;
    orderWeight?: number;
    isSelectableAtProjectCreation?: boolean;
};

export type Upload = {
    id: string;
    uuid: string;
    filename: string;
    created: Date;
    eventId?: string;
    event?: {
        projectId: string;
    };
    __typename?: string;
};

export type Plan = {
    id?: string;
    uuid: string;
    filename: string;
    projectId?: string;
    projectPlanPagesByProjectPlanId?: {
        nodes: PlanPage[];
    };
    __typename?: string;
};

export type PlanPageOrientation = 1 | 6 | 3 | 8;

export type PlanPage = {
    id: string;
    widthIn: number;
    widthPx: number;
    heightIn: number;
    heightPx: number;
    markups?: PlanPageGeometry[];
    pageId: number;
    name: string;
    removed: boolean;
    scale: number;
    isV2: boolean;
    orientation: PlanPageOrientation;
    originalOrientation: PlanPageOrientation;
    projectPlan: {
        uuid: string;
        parentProjectPlanFileUuid?: string;
        projectId: string;
        id: string;
        filename: string;
    };
    assets: {
        nodes: {
            id: string;
            projectId: string;
            extension: string;
            key: string;
            parentId: string;
            position: number;
            assetDimension: {
                orientation: string;
            };
        }[];
    };
};

export type Estimate = {
    id: string;
    uuid: string;
    filename: string;
    created: Date;
    projectId?: string;
    __typename?: string;
};

export type Link = {
    id: string;
    message: string;
    created: Date;
    projectId?: string;
};

export type Event = {
    id: string;
    ownerId: string;
    projectId: string;
    eventTypeName: string;
    message: string;
    created: string;
    lastModified: string;
    projectUploadFiles: {
        nodes: Upload[];
    };
    owner: BaseUser;
};

export type CheckboxData = {
    id: string;
    nickname: string;
    name: string;
    isChecked: boolean;
};

export type Experiment = {
    id: number;
    name: string;
    description: string;
    created: Date;
    lastModified: Date;
};

export type Team = {
    id: string;
    name: string;
    balance: {
        usdCents: number;
    };
    baseUsers: {
        nodes: BaseUser[];
    };
    leadUserId: number;
    leadUser: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
    teamFeatures: {
        nodes: {
            feature: FeatureRecord;
        }[];
    };
    teamOnboarding?: {
        accountType: ITeamAccountType;
        welcomed: boolean;
    };
    projectInfoId: string;
    teamProjectInfos?: {
        edges: {
            node: TeamProjectInfoRecord;
        }[];
    };
};

export type TeamProjectInfoRecord = {
    id: string;
    estimateTypes: IEstimateType[];
    formattingPreferences: IFormattingPreference[];
    inclusionsExclusions: string;
    averagePricePerSquareFoot: number;
    averagePriceTotal: number;
    preferredMarkupOverhead: number;
    preferredMarkupProfit: number;
    preferredMarkupMaterial: number;
    preferredMarkupLabor: number;
    hourlyLaborRates: {
        edges: {
            node: HourlyLaborRatesRecord;
        }[];
    };
    requestsNotes: string;
};

export type HourlyLaborRatesRecord = {
    priceUsdCents: number;
    type: {
        description: string;
    };
};

export type ExperimentTeam = {
    id: number;
    experiment: Experiment;
    team: Team;
    created: Date;
    lastModified: Date;
};

export enum ProjectStatus {
    NOT_SELECTED = 'NOT_SELECTED',
    NOT_STARTED = 'NOT_STARTED',
    PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
}

// The following project-related enums are there to properly display
// a project on the Kanban Board depending on the type of the user.

// The actual project database structure from which we are going to evaluate
// the BuilderDashboardProjectStatus, EstimatorDashboardProjectStatus and
// DetailsProjectStatus
export enum DatabaseProjectStatus {
    DRAFT = 'DRAFT',
    NEW = 'NEW',
    PENDING_ESTIMATOR = 'PENDING_ESTIMATOR',
    SIZED = 'SIZED',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    ESTIMATING = 'ESTIMATING',
    ESTIMATED = 'ESTIMATED',
    COMPLETE = 'COMPLETE',
    CANCELED = 'CANCELED',
    REVISION_REQUESTED = 'REVISION_REQUESTED',
    REVISION_SUBMITTED = 'REVISION_SUBMITTED',
}

export enum AdminDashboardProjectStatus {
    DRAFT = 'DRAFT',
    NEW_SUBMISSION = 'NEW_SUBMISSION',
    PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
    NEEDS_ESTIMATOR = 'NEEDS_ESTIMATOR',
    ESTIMATOR_DECLINED = 'ESTIMATOR_DECLINED',
    PENDING_REVISION = 'PENDING_REVISION',
    UPDATED_SUBMISSION = 'UPDATED_SUBMISSION',
    PROJECT_CANCELED = 'PROJECT_CANCELED',
    NEEDS_REVIEW = 'NEEDS_REVIEW',
    NEEDS_PRICE = 'NEEDS_PRICE',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    PRICE_DECLINED = 'PRICE_DECLINED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
}

export enum BuilderDashboardProjectStatus {
    NOT_SELECTED = 'NOT_SELECTED',
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
}

export enum EstimatorDashboardProjectStatus {
    PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
    ESTIMATING = 'IN_PROGRESS',
    N_A = 'N/A',
    COMPLETE = 'COMPLETE',
    CANCELED = 'CANCELED',
}

export enum DetailsProjectStatus {
    DRAFT = 'DRAFT',
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
}

export type ProjectUploadFileNew = Omit<ProjectUploadFileRecord, 'id'>;

export interface AcceptanceDetails {
    pickingAllTrades: boolean;
    excludedTrades: string;
    projectStartDate: string;
    projectCompletionDate: string;
}

export interface DeclineDetails {
    otherReasonSelected: boolean;
    refusalSummary: string;
    expectedDuration: number | undefined;
    expectedDurationSummary: string;
}

export enum PricingType {
    AUTOQUOTE_LOW_END_ESTIMATE = 'AUTOQUOTE_LOW_END_ESTIMATE',
    AUTOQUOTE_HIGH_END_ESTIMATE = 'AUTOQUOTE_HIGH_END_ESTIMATE',
    LOW_END_ESTIMATE = 'LOW_END_ESTIMATE',
    HIGH_END_ESTIMATE = 'HIGH_END_ESTIMATE',
    FINAL = 'FINAL',
    PREAPPROVED = 'PREAPPROVED',
    DECLINED = 'DECLINED',
}

export enum PricingStatus {
    NEW = 'NEW',
    PENDING_ESTIMATOR = 'PENDING_ESTIMATOR',
    NEEDS_PRICE = 'NEEDS_PRICE',
    PREAPPROVED = 'PREAPPROVED',
    DECLINED = 'DECLINED',
    FINAL = 'FINAL',
}

/**
 * Distribute a type over its union.
 */

export type Distribute<T> = T extends T ? T : never;

export type CommitmentType = ICommitmentFragment & {
    deleted?: boolean;
};

export interface IDownloadBase64Props {
    contentType: string;
    base64Data: string;
    fileName: string;
}

export type ContactInfo = {
    company_name: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    address?: string;
    city?: string;
    country_code?: string;
    postal_code?: string;
    state?: string;
};

export type Location = {
    formatted_address: string;
};

export type AreaInfo = {
    livable_square_feet: number;
    square_feet_under_roof: number;
};

export type AssemblyElementUOM = {
    definition: string;
    description: string;
    name: string;
    uom_type: string;
};

export type AssemblyElementFormula = {
    expression: string;
};

export type AssemblyElement = {
    name: string;
    material_rate: string;
    labor_rate: string;
    production_rate: string;
    formula: AssemblyElementFormula;
    uom: AssemblyElementUOM;
    quantity: string;
};

export type Assembly = {
    description: string;
    total: number;
    elements: AssemblyElement[];
};

export type ExpandedAssembly = {
    grouped: Assembly[];
    ungrouped: AssemblyElement[];
};

export type Markups = {
    name: string;
    percentage: number;
    total_usd_cents: number;
};

export type SharedEstimatePayload = {
    name: string;
    location: Location;
    assemblies: Assembly[] | ExpandedAssembly;
    markups: Markups[];
    contact_info: ContactInfo;
    area: AreaInfo;
    file_type: 'limited' | 'detailed';
    logo_url: string;
};

export type ShareLinkData = {
    publicLink: string;
    slug: string;
    pageViews: number;
    isPageAnalyticsVisible: boolean;
    lastViewed: undefined | string;
};

export type ProjectReview = {
    currentReviewRating?: number;
    rating: number;
    status: ProjectReviewStatus;
    comment: string;
    reviewTags: Record<string, IReviewTagFragment>;
    preferenceStatus: IClientPreferenceStatus;
    isFinished: boolean;
    lastModified?: string;
    projectInfo: ReviewProjectInfo;
};

export enum ProjectReviewStatus {
    NotStarted,
    Finished,
    Edited,
}
export interface ReviewProjectInfo {
    projectName: string;
    estimatorName: string;
    projectUUID: string;
}

export type BuilderDetailsFormFields = {
    estimateTypes: IEstimateType[];
    formattingPreferences: IFormattingPreference[];
    inclusionsExclusions: string;
    avgProjectPriceGSF: number;
    totalProjectPrice: number;
    avgOrTotalPrice: boolean;
    overHead: number;
    profit: number;
    material: number;
    labor: number;
    rates: IHourlyLaborRateInput[];
    specialRequestsAndNotes: string;
    files: ITeamProjectUploadInput[];
    trades: string[];
    wage: WageType | undefined;
};

export type BuilderDetailsCheckboxListItemType = {
    label: string;
    value: IEstimateType | IFormattingPreference;
};

export type BuilderDetailsUploadedFiles = {
    name: string;
    uuid: string;
    directory: IFileDirectory;
};

export const builderDetailsEstimateTypesList: BuilderDetailsCheckboxListItemType[] = [
    {
        label: 'Quantity Takeoffs',
        value: IEstimateType.QuantityTakeoffs,
    },
    {
        label: 'Rough Budget Pricing Only',
        value: IEstimateType.RoughBudgetPricingOnly,
    },
    {
        label: 'Material Pricing ',
        value: IEstimateType.MaterialPricing,
    },
    {
        label: 'Labor Pricing ',
        value: IEstimateType.LaborPricing,
    },
    {
        label: 'Equipment Pricing ',
        value: IEstimateType.EquipmentPricing,
    },
    {
        label: 'General Conditions Pricing ',
        value: IEstimateType.GeneralConditionsPricing,
    },
    {
        label: 'Markups, Profit, and Overhead',
        value: IEstimateType.MarkupsProfitOverhead,
    },
];

export const builderDetailsFormattingPreferencesList: BuilderDetailsCheckboxListItemType[] = [
    {
        label: 'Include Logo and Company Info',
        value: IFormattingPreference.LogoCompanyInfo,
    },
    {
        label: 'Use my Custom Template',
        value: IFormattingPreference.CustomTemplate,
    },
    {
        label: 'Apply my Cost Codes Section by Section',
        value: IFormattingPreference.CostCodesBySection,
    },
    {
        label: 'Apply my Cost Codes Line by Line',
        value: IFormattingPreference.CostCodesLineByLine,
    },
    {
        label: 'Provide Bill of Materials',
        value: IFormattingPreference.BillOfMaterials,
    },
];

export type StripeErrorMessageType = {
    message: string;
    url: string;
};
