import debounce from 'lodash/debounce';
import { useEstimationLayout } from '../../hooks/useEstimationLayout';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/32/chevron--down.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/32/search.svg';
import Box, { BoxProps } from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import React, { FC, useState } from 'react';

const COPY = {
    placeholder: 'Search libraries',
};

export const AssemblyPanelHeader: FC<BoxProps> = (props) => {
    const { setSearch } = useAssemblyPanel();

    const { toggleAssemblyPanelIsOpen } = useEstimationLayout();

    const [query, setQuery] = useState('');
    const [setQueryWithDebounce] = useState(() => debounce(setSearch, 200));

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}
            {...props}
        >
            <FilledInput
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={() => toggleAssemblyPanelIsOpen()}
                            size="small"
                        >
                            <SvgIcon
                                sx={{
                                    height: '20px',
                                    width: '20px',
                                }}
                                viewBox="0 0 32 32"
                            >
                                <ChevronDownIcon />
                            </SvgIcon>
                        </IconButton>
                    </InputAdornment>
                }
                hiddenLabel
                placeholder={COPY.placeholder}
                value={query}
                onChange={(event) => {
                    setQuery(event.target.value);
                    setQueryWithDebounce(event.target.value);
                }}
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <SvgIcon viewBox="0 0 32 32" sx={{ height: '22px' }}>
                            <SearchIcon />
                        </SvgIcon>
                    </InputAdornment>
                }
                sx={{
                    width: '100%',
                }}
            />
        </Box>
    );
};
