import React, { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getMarkupEntriesByIds, getMarkupsDeep, isAllSoloMarkups, isSameMarkupType } from './utils';

import { IMarkupEntry } from '@/graphql/unions';
import { Mode } from '@/theme/Mode';

import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';
import { ReactComponent as FolderAddIcon } from '@/assets/icons/32/folder--add.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/32/trash-can.svg';

export interface SelectModeBarProps extends BoxProps {
    disabled?: boolean;
    markupEntries?: IMarkupEntry[];
    onCloseIconClick?: () => void;
    onDeleteIconClick?: () => void;
    onCreateGroupIconClick?: (markupIDs: string[]) => void;
    selectedMarkupEntryIds?: string[];
    transitionProps?: SlideProps;
}

export const SelectModeBar: FC<SelectModeBarProps> = ({
    disabled,
    markupEntries,
    onCloseIconClick,
    onDeleteIconClick,
    onCreateGroupIconClick,
    selectedMarkupEntryIds,
    transitionProps,
    ...props
}) => {
    const markups = getMarkupsDeep(markupEntries);
    const selectedMarkups = getMarkupEntriesByIds(markups, selectedMarkupEntryIds);

    const isMarkupsWithSameTypeSelected = isSameMarkupType(selectedMarkups);
    const isMarkupsWithNoParentSelected = isAllSoloMarkups(selectedMarkups);

    let createGroupTooltipTitle = '';

    if (!isMarkupsWithSameTypeSelected) {
        createGroupTooltipTitle = 'Only takeoffs of similar measurement types can be grouped.';
    } else if (!isMarkupsWithNoParentSelected) {
        createGroupTooltipTitle = 'Only takeoffs that are not already in groups can be grouped.';
    }

    const handleCreateGroupIconClick = () => {
        if (!selectedMarkups) {
            return;
        }

        onCreateGroupIconClick?.(selectedMarkups.map((markup) => markup.id));
    };

    // For tooltip disabled state interactions, see:
    // https://mui.com/components/tooltips/#disabled-elements

    return (
        <Mode variant="light">
            <Slide direction="up" {...transitionProps}>
                <Box
                    {...props}
                    sx={{
                        bottom: 8,
                        background: (theme) => theme.palette.primary.main,
                        padding: '10px 4px',
                        position: 'sticky',
                        ...props.sx,
                    }}
                >
                    <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Stack alignItems="center" direction="row" spacing="8px">
                            <IconButton
                                disabled={disabled}
                                onClick={onCloseIconClick}
                                size="small"
                                sx={{ color: '#fff' }}
                            >
                                <SvgIcon viewBox="0 0 32 32">
                                    <CloseIcon />
                                </SvgIcon>
                            </IconButton>
                            <Typography variant="body1">Select items</Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" spacing="8px">
                            <Tooltip title={createGroupTooltipTitle}>
                                <span>
                                    <IconButton
                                        disabled={
                                            disabled ||
                                            !selectedMarkups?.length ||
                                            !isMarkupsWithSameTypeSelected ||
                                            !isMarkupsWithNoParentSelected
                                        }
                                        onClick={handleCreateGroupIconClick}
                                        size="small"
                                        sx={{
                                            color: '#fff',
                                        }}
                                    >
                                        <SvgIcon viewBox="0 0 32 32">
                                            <FolderAddIcon />
                                        </SvgIcon>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <IconButton
                                disabled
                                onClick={onDeleteIconClick}
                                size="small"
                                sx={{ color: '#fff' }}
                            >
                                <SvgIcon viewBox="0 0 32 32">
                                    <TrashCanIcon />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
            </Slide>
        </Mode>
    );
};
