import styled from 'styled-components';
import { colorStatusError } from '@/variables';

type ContainerProps = {
    isBackgroundLess: boolean;
};

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    margin-top: ${(props): string => {
        if (props.isBackgroundLess) return 'none';
        return '16px';
    }};
    padding: ${(props): string => {
        if (props.isBackgroundLess) return 'none';
        return '6px 16px';
    }};
    background-color: ${(props): string => {
        if (props.isBackgroundLess) return 'none';
        return 'RGBA(255, 255, 255, 0.06)';
    }};
    border-radius: 2px;
    box-sizing: border-box;
`;

export const Name = styled.p`
    margin-left: 8px;
    font-size: 11px;
`;

export const NameMissing = styled.p`
    margin-left: 8px;
    font-size: 11px;
    color: ${colorStatusError};
`;
