import React, { FC } from 'react';

import { Accordion } from '@/components/ui/containers/Accordion';
import { Assembly, ExpandedAssembly } from '@/common/types';
import { isArrayOfAssemblies } from '@/common/typeGuards';
import { formatters, toDollarAmount } from '@/common/currency';
import { getElementRate, getTotalPerElement, getTotalPerGroup } from '../utils';

import {
    Grid,
    GridRow,
    LimitedInfoRow,
    DetailedInfoRow,
    Text,
    AtSignText,
    textColorPrimary,
    textColorSecondary,
    CollapsibleHeader,
    CollapsibleleContent,
    InfoContent,
} from '../styled';

type ExpandedAssembliesProps = {
    assemblies: Assembly[] | ExpandedAssembly;
};

const formatter = formatters.usd({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const ExpandedAssemblies: FC<ExpandedAssembliesProps> = ({ assemblies }) => {
    const renderAssemblies = (): React.ReactElement => {
        if (!assemblies) return <></>;

        if (!isArrayOfAssemblies(assemblies)) {
            return (
                <>
                    {assemblies.ungrouped &&
                        assemblies.ungrouped.map((element, idx) => {
                            return (
                                <DetailedInfoRow key={idx}>
                                    <Text color={textColorPrimary} weight={500}>
                                        {element.name}
                                    </Text>
                                    <Text color={textColorSecondary}>
                                        {Number(element.quantity).toFixed(2) +
                                            ' ' +
                                            element.uom.name}
                                        <AtSignText>@</AtSignText>
                                    </Text>
                                    <Text color={textColorSecondary}>
                                        ${getElementRate(element)?.toFixed(2) ?? 0}
                                    </Text>
                                    <Text color={textColorSecondary} align="right">
                                        {formatter.format(getTotalPerElement(element) ?? 0)}
                                    </Text>
                                </DetailedInfoRow>
                            );
                        })}
                    {assemblies.grouped &&
                        assemblies.grouped.map((item, idx) => {
                            const assembly = assemblies.grouped[idx];

                            return (
                                <Accordion
                                    key={idx}
                                    title={
                                        <CollapsibleHeader>
                                            <Text weight={500} color={textColorPrimary}>
                                                {item.description}
                                            </Text>
                                            <Text weight={500} color={textColorPrimary}>
                                                {formatter.format(getTotalPerGroup(assembly) ?? 0)}
                                            </Text>
                                        </CollapsibleHeader>
                                    }
                                >
                                    <CollapsibleleContent>
                                        <Grid>
                                            {assembly?.elements?.map((element, index) => {
                                                return (
                                                    <GridRow className="grouped-row" key={index}>
                                                        <Text color={textColorPrimary} weight={500}>
                                                            {element.name}
                                                        </Text>
                                                        <Text color={textColorSecondary}>
                                                            {Number(element.quantity).toFixed(2) +
                                                                ' ' +
                                                                element.uom.name}
                                                            <AtSignText>@</AtSignText>
                                                        </Text>
                                                        <Text color={textColorSecondary}>
                                                            $
                                                            {getElementRate(element)?.toFixed(2) ??
                                                                0}
                                                        </Text>
                                                        <Text
                                                            color={textColorSecondary}
                                                            align="right"
                                                        >
                                                            {formatter.format(
                                                                getTotalPerElement(element) ?? 0
                                                            )}
                                                        </Text>
                                                    </GridRow>
                                                );
                                            })}
                                        </Grid>
                                    </CollapsibleleContent>
                                </Accordion>
                            );
                        })}
                </>
            );
        }

        return (
            <Grid>
                {assemblies.map((element, idx) => {
                    return (
                        <LimitedInfoRow key={idx}>
                            <Text weight={500} color={textColorPrimary}>
                                {element.description}
                            </Text>
                            <Text weight={500} color={textColorPrimary}>
                                {formatter.format(toDollarAmount(element.total)) ?? 0}
                            </Text>
                        </LimitedInfoRow>
                    );
                })}
            </Grid>
        );
    };

    return <InfoContent>{renderAssemblies()}</InfoContent>;
};
