/* Auto-resizable text field control */
import React, { FC, useRef, useEffect, useState, KeyboardEvent } from 'react';

import { TextareaEvent, TextareaProps } from '@/common/types';
import { FormControl, TextControlProps } from './helpers/FormControl';

export const Textarea: FC<TextControlProps<TextareaProps>> = ({
    label,
    required,
    value,
    onChange,
    className,
    ...rest
}) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const [rows, setRows] = useState(0);

    const handleChange = (e: TextareaEvent): void => onChange(e.target.value);

    /* Disable default new line on enter, to allow submitting form on enter */
    const handleKeydown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
        if (e.keyCode !== 13) return;
        e.preventDefault();
    };

    const updateRows = (reset: boolean) => (): void => {
        if (!ref.current) return;

        const isOverflow = ref.current.scrollHeight > ref.current.clientHeight;

        /* Increment rows if overflow */
        if (isOverflow) {
            setRows((prev) => prev + 1);
            return;
        }

        /* Reset rows to default if a reset flag is passed */
        if (reset) {
            setRows(1);
        }
    };

    /*
        ### First update run
        Vertically expand field if overflowing, or shrink to default if not.
    */
    useEffect(updateRows(true), [value]);

    /*
        ### Second update run
        If the field was expanded and is not overflowing anymore, leave.
        If the field was shrinked to default, and is now overflowing, expand it.
    */
    useEffect(updateRows(false), [rows]);

    return (
        <FormControl label={label} required={required} value={value} className={className}>
            <textarea
                ref={ref}
                value={value}
                onKeyDown={handleKeydown}
                onChange={handleChange}
                rows={rows}
                {...rest}
            />
        </FormControl>
    );
};
