/**
 * ActionBar is a bar with a submission button to the right of its child
 * components. For example usage, see `DrawingSpec.tsx` or `GeometryBar.tsx`.
 */

import clsx from 'clsx';
import React, { FC } from 'react';

import { SmallButton } from '@/components/ui/buttons/SmallButton';

import './ActionBar.scss';

export type ActionBarProps = {
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    submitText?: string;
    disabled?: boolean;
};

export const ActionBar: FC<ActionBarProps> = (props) => (
    <div className={clsx(props.className, 'action-bar-container')}>
        <span className="action-bar-content">{props.children}</span>
        {props.submitText && (
            <SmallButton onClick={props.onClick} disabled={props.disabled}>
                {props.submitText}
            </SmallButton>
        )}
    </div>
);
