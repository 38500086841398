import React, { ChangeEvent, FC, FocusEventHandler, useCallback, useRef } from 'react';

import { DivProps } from '@/common/types';
import { Action, makeAction, State } from '@/components/mission-control/UserManager/state';
import { SearchContainer, SearchInput } from '@/components/mission-control/UserManager/styled';

import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifying-glass-alt.svg';

const COPY = {
    placeholder: 'Search users',
};

export interface SearchProps extends DivProps {
    state: State;
}

export const Search: FC<SearchProps> = ({ state: { commit, searchTerm }, onFocus, ...rest }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            if (onFocus) {
                onFocus(e);
            }
            if (inputRef.current) {
                inputRef.current.select();
            }
        },
        [inputRef]
    );

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        commit(makeAction(Action.Search, { term: e.target.value }));
    };

    return (
        <SearchContainer {...rest}>
            <MagnifyingGlass
                height="1.125rem"
                width="1.125rem"
                style={{ cursor: 'pointer' }}
                onClick={(): void => inputRef.current?.focus()}
            />
            <SearchInput
                autoFocus
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleFocus}
                placeholder={COPY.placeholder}
                ref={inputRef}
            />
        </SearchContainer>
    );
};
