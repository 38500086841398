import React, { FC } from 'react';

import { Container, Item, Blocked } from './styled';
import { Setter } from '@/common/types';
import { IWeekday } from '@/graphql';

export interface AvailabilityProps {
    days: Record<IWeekday, boolean>;
    setAvailability?: Setter<Record<IWeekday, boolean>>;
    disabled?: boolean;
}

export const Availability: FC<AvailabilityProps> = ({
    days,
    setAvailability,
    disabled = false,
    ...rest
}) => {
    const onItemClick = (day: string, blocked: boolean): void => {
        const data = { ...days, [day]: blocked };

        if (setAvailability) {
            setAvailability(data);
        }
    };

    return (
        <Container {...rest}>
            {Object.entries(days).map((d) => {
                const available = d[1];
                return (
                    <Item
                        key={d[0]}
                        blocked={!available}
                        onClick={(): void => {
                            onItemClick(d[0], !available);
                        }}
                        className="availability-item"
                        disabled={disabled}
                    >
                        {!available && <Blocked disabled={disabled} />}
                        {d[0].toLowerCase().substring(0, 2)}
                    </Item>
                );
            })}
        </Container>
    );
};
