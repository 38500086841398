import { useApolloClient } from '@apollo/client';
import { Hook } from '@/common/types';
import { Transforms } from '@/components/Estimate/transforms';
import { useEstimationEditor } from '@/components/app/router/EstimationRoute/hooks/useEstimationEditor';
import {
    IElementFragment,
    IMaterialFragment,
    ISourcePreviewFragment,
    ISourceTypeV2,
} from '@/graphql';
import { findLocalizedSourceV2 } from '@/components/Estimate/utils/requests';
import { getUomId } from '@/components/AssemblyPanel/hooks/uomMapping';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { useNotifications } from '@/contexts/Notifications';

export interface UseMaterialsRes {
    importMaterialToProject: ({
        material,
        elementOptions,
    }: {
        material: IMaterialFragment;
        elementOptions?: Partial<IElementFragment>;
    }) => Promise<void>;
    importPreviewSourceToProject: (previewSource: ISourcePreviewFragment) => Promise<void>;
}

export interface UseMaterialsProps {
    search?: string;
    projectID?: string;
}

// The Categories (and materials) response(s) don't seem to contain private
// data, so it may be possible to cache on both the backend and frontend.

export const useMaterials: Hook<UseMaterialsRes, UseMaterialsProps> = ({ projectID }) => {
    const client = useApolloClient();
    const editor = useEstimationEditor();
    const { addNotification } = useNotifications();

    const { localizationCounty, localizationState } = useAssemblyPanel();
    const state = localizationState?.localization;
    const county = localizationCounty?.localization;

    const importPreviewSourceToProject = async (
        previewSource: ISourcePreviewFragment
    ): Promise<void> => {
        const { sourcesBatch } = await findLocalizedSourceV2(client, {
            items: [{ sourceId: previewSource.id }],
            state: state?.name ?? 'N/A',
            county: county?.countyfp ?? 'N/A',
        });
        if (!sourcesBatch || sourcesBatch.length !== 1) {
            const message = 'There was a problem getting the item rate. Please try again';
            addNotification(
                {
                    title: 'Error',
                    content: message,
                },
                'error',
                false
            );
            return;
        }

        const source = sourcesBatch[0];

        // For assemblies, we need to hardcode production rate=1
        const productionRate =
            source.sourceType === ISourceTypeV2.Assembly ? 1 : source.productionRate;
        await Transforms.addNewBlankElement(editor, {
            categoryID: null,
            name: source.name || '',
            materialID: null,
            projectID: projectID ?? '',
            rates: {
                productionRate: productionRate,
                laborRate: source.laborRateUsdCents,
                materialRate: source.materialRateUsdCents,
                equipmentRate: 0,
            },
            takeoffUnitID: getUomId(source.uom),
            uom: getUomId(source.uom),
            expression: null,
            client,
        });
        Transforms.deselect(editor);
    };

    const importMaterialToProject = async ({
        material,
        elementOptions,
    }: {
        material: IMaterialFragment;
        elementOptions?: Partial<IElementFragment>;
    }): Promise<void> => {
        if (!projectID) {
            return;
        }

        const options = {
            client,
            categoryID: null,
            name: material.name,
            materialID: null, // with null, the backend will create a new material
            projectID: projectID,
            takeoffUnitID: elementOptions?.unit?.id || material.takeoffUnitID,
            uom: elementOptions?.unit?.id || material.takeoffUnitID,
            rates: {
                materialRate: elementOptions?.materialRate,
                laborRate: elementOptions?.laborRate,
                productionRate: elementOptions?.productionRate,
                equipmentRate: elementOptions?.equipmentRate,
            },
            expression: null,
        };

        await Transforms.addNewBlankElement(editor, options);
    };

    return {
        importMaterialToProject,
        importPreviewSourceToProject,
    };
};
