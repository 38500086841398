import styled, { css, FlattenInterpolation, ThemedStyledProps } from 'styled-components';

import { MenuOffset } from './types';
import { colorTypographyDark } from '@/variables';

export const MENU_WIDTH = 185;

const variables = {
    background: '#FFFFFF',
    highlight: '#334AD7',
    menuWidth: MENU_WIDTH,
};

export interface ContainerProps extends MenuOffset {
    isAbsolute?: boolean;
    borderRadius?: number;
    bottomPadding?: number;
}

export const Container = styled.div.attrs<ContainerProps>((props) => ({
    style: {
        left: props.left,
        top: props.top,
        right: props.right,
    },
}))<ContainerProps>`
    z-index: 1502;

    user-select: none;
    cursor: pointer;

    position: ${(props): string => (props.isAbsolute ? 'absolute' : 'fixed')};
    width: ${variables.menuWidth}px;
    border-radius: ${(props): number => props.borderRadius || 0}px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-bottom: ${(props): number => props.bottomPadding || 0}px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: ${(props): number => props.bottomPadding || 0}px;
        background-color: ${variables.background};
        border-radius: 0 0 ${(props): string => `${props.borderRadius || 0}px `.repeat(2).trimEnd()};
    }
`;

export const RowIcon = styled.span`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

type RowDividerCssProps = { isTaller?: boolean | undefined };
const isRowDividerCss = css<RowDividerCssProps>`
    margin-bottom: ${(props): number => (props.isTaller ? 16 : 3)}px;
    padding-bottom: ${(props): number => (props.isTaller ? 16 : 3)}px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
type RowWrapperProps = { isDivider?: boolean; isTaller?: boolean };
export const RowWrapper = styled.div<RowWrapperProps>`
    ${(props): FlattenInterpolation<ThemedStyledProps<RowDividerCssProps, any>> | string =>
        props.isDivider ? isRowDividerCss : ''}
`;
/* eslint-enable @typescript-eslint/no-explicit-any */

type RowProps = {
    isActive?: boolean;
    isTaller?: boolean;
    isHeader?: boolean;
};
export const Row = styled.div<RowProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: ${(props): number => (props.isTaller ? 32 : 28)}px;
    padding: 0 20px;
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 32px;
    font-weight: normal;
    cursor: ${(props): string => (props.isHeader ? 'default' : 'inherit')};

    background-color: ${(props): string =>
        props.isActive ? variables.highlight : variables.background};
    color: ${(props): string => (props.isActive ? variables.background : colorTypographyDark)};

    &:first-of-type {
        padding-top: 2px;
    }

    & > ${RowIcon} {
        color: inherit;
    }
`;

export const ExpandIconWrapper = styled.div`
    position: absolute;
    height: 100%;
    right: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 6.5px;
        padding-right: calc(9px - 5.49px);
        padding-bottom: 0.25px;
    }
`;
