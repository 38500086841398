import React, { FC, useEffect, useRef, useState } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import { TABS } from '.';
import { GeometryList } from './GeometryList';
import { GeometryListWithFilters } from './GeometryListWithFilters';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';

import { EstimationView } from '@/components/app/router/EstimationRoute/types';
import { IMarkupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';

export interface GeometryListsProps extends BoxProps {
    disabled?: boolean;
    markupEntries?: IMarkupEntry[];
    mode?: string;
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onCreateGroupFromMarkup?: (markup: IMarkupFragment) => void;
    onDeleteMarkupGroup: (markupGroupID: string) => Promise<void>;
    onRemoveMarkupsFromGroup: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    onShowInThisProjectMarkupsChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onShowInThisProjectGroupsChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onTabChange: (tab: TABS) => void;
    planPageID?: string;
    selectedMarkupEntryIds?: string[];
    showInThisProjectMarkups: boolean;
    showInThisProjectGroups: boolean;
    tab: TABS;
    view?: EstimationView;
}

export const GeometryLists: FC<GeometryListsProps> = ({
    disabled,
    markupEntries,
    mode,
    onAddMarkupToGroup,
    onListItemClick,
    onListItemNameBlur,
    onCreateGroupFromMarkup,
    onDeleteMarkupGroup,
    onRemoveMarkupsFromGroup,
    onShowInThisProjectMarkupsChange,
    onShowInThisProjectGroupsChange,
    onTabChange,
    planPageID,
    selectedMarkupEntryIds,
    showInThisProjectMarkups,
    showInThisProjectGroups,
    tab,
    view,
    ...props
}) => {
    const boxRef = useRef<HTMLDivElement>(null);
    const [listHeight, setListHeight] = useState(0);

    useEffect(() => {
        const container = boxRef.current;
        if (container) {
            // Total vertical space minus what's above our container.
            let availableSpace = window.innerHeight - container.getBoundingClientRect().y;
            if (mode !== 'default') {
                // Minus space for the "Select items" bar
                availableSpace -= 65;
            }
            setListHeight(availableSpace);
        }
    }, [boxRef.current, view, mode]);

    return (
        <Box ref={boxRef} {...props} sx={{ flex: 1 }}>
            {(() => {
                if (view === EstimationView.Takeoff) {
                    if (!planPageID || planPageID === '-1' || planPageID === 'NaN') {
                        return null;
                    }

                    return (
                        <GeometryListWithFilters
                            height={listHeight}
                            disabled={disabled}
                            markupEntries={markupEntries}
                            mode={mode}
                            planPageID={planPageID}
                            onAddMarkupToGroup={onAddMarkupToGroup}
                            onListItemClick={onListItemClick}
                            onListItemNameBlur={onListItemNameBlur}
                            onCreateGroupFromMarkup={onCreateGroupFromMarkup}
                            onDeleteMarkupGroup={onDeleteMarkupGroup}
                            onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                            onShowInThisProjectMarkupsChange={onShowInThisProjectMarkupsChange}
                            onShowInThisProjectGroupsChange={onShowInThisProjectGroupsChange}
                            onTabChange={onTabChange}
                            selectedMarkupEntryIds={selectedMarkupEntryIds}
                            sx={{ marginBottom: '10px' }}
                            showInThisProjectMarkups={showInThisProjectMarkups}
                            showInThisProjectGroups={showInThisProjectGroups}
                            tab={tab}
                        />
                    );
                } else {
                    return (
                        <GeometryList
                            height={listHeight}
                            disabled={disabled}
                            listItems={markupEntries}
                            markupEntries={markupEntries}
                            mode={mode}
                            onAddMarkupToGroup={onAddMarkupToGroup}
                            onListItemClick={onListItemClick}
                            onListItemNameBlur={onListItemNameBlur}
                            onCreateGroupFromMarkup={onCreateGroupFromMarkup}
                            onDeleteMarkupGroup={onDeleteMarkupGroup}
                            onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                            selectedMarkupEntryIds={selectedMarkupEntryIds}
                        />
                    );
                }
            })()}
        </Box>
    );
};
