import { useEffect, useState } from 'react';

import { Hook } from '@/common/types';
import {
    IProjectAreaFragment,
    IVariableFragment,
    useProjectAreaAssignmentMutation,
    useProjectAreaByProjectIdQuery,
} from '@/graphql';
import { useUser } from '@/contexts/User';

export interface UseAreaRes {
    area?: IProjectAreaFragment;
    saveArea: (newArea: Omit<IProjectAreaFragment, 'id' | 'projectID'>) => void;
    loading: boolean;
}

export interface UseAreaProps {
    projectId?: number;
}

export const useArea: Hook<UseAreaRes, UseAreaProps> = ({ projectId }) => {
    const {
        data: { anonymousUser },
    } = useUser();
    const [area, setArea] = useState<IProjectAreaFragment>();

    const [projectAreaAssignmentMutation] = useProjectAreaAssignmentMutation();

    // Queries
    const { data, loading } = useProjectAreaByProjectIdQuery({
        variables: {
            input: {
                projectID: projectId?.toString() ?? '',
            },
        },
        skip: !projectId,
        fetchPolicy: anonymousUser ? 'cache-first' : 'cache-and-network',
    });

    useEffect(() => {
        const newProjectArea = data?.projectArea;
        if (!newProjectArea) {
            return;
        }
        setArea(newProjectArea);
    }, [data]);

    const saveArea = (area: Omit<IProjectAreaFragment, 'id' | 'projectID'>): void => {
        if (!projectId) {
            return;
        }
        projectAreaAssignmentMutation({
            variables: {
                input: {
                    projectID: projectId.toString(),
                    squareFeetTotalLivable: area.squareFeetTotalLivable,
                    squareFeetUnderRoof: area.squareFeetUnderRoof,
                },
            },
            update: (cache, response) => {
                // This is highly debatable, but it works. Meh.
                const cacheState = cache.extract() as unknown as {
                    [key: string]: IVariableFragment;
                };
                const projectID = Number(projectId);
                const measuredQuantityTotal =
                    cacheState[`Variable:${-projectID}`]?.measuredQuantityID;
                const measuredQuantityUsable =
                    cacheState[`Variable:${-projectID - 1}`]?.measuredQuantityID;
                if (measuredQuantityTotal) {
                    cache.modify({
                        id: `MeasuredQuantity:${measuredQuantityTotal}`,
                        fields: {
                            value: () => response.data?.projectAreaAssignment.squareFeetUnderRoof,
                        },
                    });
                }
                if (measuredQuantityUsable) {
                    cache.modify({
                        id: `MeasuredQuantity:${measuredQuantityUsable}`,
                        fields: {
                            value: () =>
                                response.data?.projectAreaAssignment.squareFeetTotalLivable,
                        },
                    });
                }
            },
        });
    };

    return {
        area,
        saveArea,
        loading,
    };
};
