import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import { DialogTitle, DialogContent, Message } from './styled';

interface Props extends DialogProps {
    onCancelButtonClick?: () => void;
    onOkButtonClick?: (duration?: string) => void;
}

export const HideBarModal: FC<Props> = ({ onCancelButtonClick, onOkButtonClick, ...props }) => {
    const [duration, setDuration] = useState('once');

    return (
        <Dialog maxWidth="xs" {...props}>
            <DialogTitle>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[21] }} variant="h2">
                    Confirm Hide Bar
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Message variant="body1">Are you sure you want to hide this bar?</Message>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Duration</FormLabel>
                    <RadioGroup
                        aria-label="duration"
                        name="radio-group"
                        onChange={(_, value) => setDuration(value)}
                        value={duration}
                    >
                        <FormControlLabel
                            value="release"
                            control={<Radio />}
                            label="Until next version"
                        />
                        <FormControlLabel value="once" control={<Radio />} label="Just this once" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelButtonClick} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={() => onOkButtonClick?.(duration)} variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
