/*
 * useIsMobile is a hook that detects mobile browsers by detecting if a browser
 * is being driven by a device with a touchscreen.
 *
 * This method is endorsed by MDN for the detection of mobile devices:
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Device_Detection
 */
import { useMemo } from 'react';

type LegacyNavigator =
    | Omit<Navigator, 'maxTouchPoints'>
    | Omit<Navigator, 'msMaxTouchPoints'>
    | Omit<Navigator, 'maxTouchPoints' | 'msMaxTouchPoints'>;

interface LegacyWindow extends Omit<Window, 'matchMedia'> {
    matchMedia?: typeof window.matchMedia;
}

export const useIsMobile = (): boolean =>
    useMemo((): boolean => {
        const legacyNavigator: LegacyNavigator = navigator;
        if ('maxTouchPoints' in legacyNavigator) {
            return legacyNavigator.maxTouchPoints > 0;
        }
        if ('msMaxTouchPoints' in legacyNavigator) {
            return legacyNavigator.msMaxTouchPoints > 0;
        }

        const legacyWindow: LegacyWindow = window;
        const mQ = legacyWindow.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
            return !!mQ.matches;
        }

        // deprecated, but good fallback
        if ('orientation' in legacyWindow) {
            return true;
        }

        // Only as a last resort, fall back to user agent sniffing
        const UA = legacyNavigator.userAgent;
        return (
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
    }, [navigator, window]);
