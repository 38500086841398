import { ApolloClient, executeQuery } from '@/common/apollo/execute';
import {
    IUserFragment,
    IUserLightFragment,
    IUserQuery,
    IUserQueryVariables,
    IUsersSearchQuery,
    IUsersSearchQueryVariables,
    UserDocument,
    UsersSearchDocument,
} from '@/graphql';

export const fetchFullUser = async (client: ApolloClient, id: string): Promise<IUserFragment> => {
    const res = await executeQuery<IUserQuery, IUserQueryVariables>(client, {
        query: UserDocument,
        variables: { input: { id } },
    });
    return res.user;
};

export const searchUsers = async (
    client: ApolloClient,
    term: string
): Promise<IUserLightFragment[]> => {
    const userData = await executeQuery<IUsersSearchQuery, IUsersSearchQueryVariables>(client, {
        query: UsersSearchDocument,
        variables: { input: { term } },
    });
    return userData.usersSearch.edges?.map((e) => e.node) ?? [];
};
