import React, { FC, useEffect } from 'react';
import { Signup as SignupContainer } from '@/containers/Signup';
import { withMode } from '@/theme/withMode';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';

const Component: FC = () => {
    const useLegacyLoginPage = true;

    useEffect(() => {
        if (!useLegacyLoginPage) {
            window.location.replace('https://app2.1build.com/public/register');
        }
    });

    if (!useLegacyLoginPage) {
        return <SpinnerLoader />;
    }

    return <SignupContainer />;
};

export const Signup = withMode({ variant: 'light' })(Component);
