import React, { FC, useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { Map } from 'react-leaflet';
import clsx from 'clsx';
import { LatLngBounds } from 'leaflet';

import './PanZoomControl.scss';
import { TakeoffComponentProps } from '../context';
import { modifierIcon } from '../../../common/hot-keys/hotKeys';

export interface PanZoomControlProps extends TakeoffComponentProps {
    mapRef: Map;
    originalBounds: LatLngBounds;
    loading: boolean;
    manageDragging: {
        enableDragging: () => void;
        disableDragging: () => void;
        canDrag: boolean;
    };
    rotatePage: () => void;
}

export const PanZoomControl: FC<PanZoomControlProps> = ({
    mapRef,
    originalBounds,
    loading,
    manageDragging,
    rotatePage,
    useTakeoff,
}) => {
    const { tooltipProps } = useTakeoff();

    const [isPanToolEnabled, setIsPanToolEnabled] = useState(true);

    useEffect(() => {
        setIsPanToolEnabled(manageDragging.canDrag);
    }, [manageDragging.canDrag]);

    const togglePanTool = (): void => {
        if (mapRef) {
            if (isPanToolEnabled) {
                manageDragging.disableDragging();
            } else {
                manageDragging.enableDragging();
            }
        }
    };

    const zoomIn = (): void => {
        mapRef.leafletElement.setZoom(mapRef.leafletElement.getZoom() + 1);
    };

    const zoomOut = (): void => {
        mapRef.leafletElement.setZoom(mapRef.leafletElement.getZoom() - 1);
    };

    const zoomReset = (): void => {
        mapRef.leafletElement.fitBounds(originalBounds);
    };

    const rotate = (): void => {
        rotatePage();
    };

    return (
        <Control position="bottomleft">
            <div className="pan-zoom-control">
                <button
                    disabled={loading}
                    {...tooltipProps('Pan', 'Space')}
                    className={clsx('pan', isPanToolEnabled && 'pan-enabled')}
                    onClick={togglePanTool}
                />
                <button
                    disabled={loading}
                    {...tooltipProps('Zoom out', `${modifierIcon}-`)}
                    className="zoom-out"
                    onClick={zoomOut}
                />
                <button
                    disabled={loading}
                    {...tooltipProps('Reset view', `${modifierIcon}0`)}
                    className="zoom-reset"
                    onClick={zoomReset}
                />
                <button
                    disabled={loading}
                    {...tooltipProps('Zoom in', `${modifierIcon}+`)}
                    className="zoom-in"
                    onClick={zoomIn}
                />
                <button
                    disabled={loading}
                    {...tooltipProps('Rotate', '')}
                    className="rotate"
                    onClick={rotate}
                />
            </div>
        </Control>
    );
};
