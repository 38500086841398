import { ElementRenderer } from 'leyden-react';
import React from 'react';
import { useSelected } from 'slate-react';

import { ExpressionBubbleContainer } from './styled';
import { MarkupQuantity } from '../components/MarkupQuantity';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';
import { useMarkupQuery } from '@/graphql';

export const ExpressionMarkup: ElementRenderer<'ExpressionMarkup'> = ({
    attributes,
    children,
    element,
}) => {
    const { data: markupData } = useMarkupQuery({
        variables: {
            input: { id: element.markupID },
        },
    });
    const selected = useSelected();

    return (
        <ExpressionBubbleContainer
            {...attributes}
            selected={selected}
            borderColor={markupData?.markup.color}
        >
            {markupData?.markup ? <MarkupQuantity markup={markupData.markup} /> : <DotsLoader />}
            {children}
        </ExpressionBubbleContainer>
    );
};
