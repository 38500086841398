import React, { FC } from 'react';
import { CaretIconSize, CaretIconVariant } from '@/components/ui/icons/CaretIcon';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { Direction } from '@/common/types';
import { DropdownIcon, DatePlaceholder, DatePicker, DateWrapper } from './styled';

export interface NativeDatepickerProps {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    min?: string;
    max?: string;
    lightVariant: boolean;
}

export const NativeDatepicker: FC<NativeDatepickerProps> = ({
    placeholder,
    value,
    onChange,
    min = '',
    max = '',
    lightVariant,
}) => {
    const screenSize = useScreenSize();
    const isMobile = screenSize === ScreenSize.Mobile;
    const haveValue = value !== '';

    return (
        <DateWrapper>
            {!haveValue && !isMobile && (
                <DatePlaceholder light={lightVariant}>{placeholder}</DatePlaceholder>
            )}
            <DatePicker
                name="from"
                value={value}
                onChange={onChange}
                className="date-picker"
                type="date"
                min={min}
                max={max}
                data={haveValue}
                light={lightVariant}
            />
            {!isMobile && (
                <DropdownIcon
                    direction={Direction.Down}
                    size={CaretIconSize.Small}
                    variant={CaretIconVariant.Standard}
                />
            )}
        </DateWrapper>
    );
};
