/* Two squares allowing the user to select between a yes and a no,
or a true and false */

import React, { FC } from 'react';
import clsx from 'clsx';

import './BigRadioSelect.scss';

export type BigRadioSelectProps = {
    value?: string;
    options: string[];
    disabled?: boolean;
    onChange: (value: string) => void;
};

export const BigRadioSelect: FC<BigRadioSelectProps> = ({ value, options, disabled, onChange }) => {
    const selectOption = (o: string): void => {
        !disabled && onChange(o);
    };
    return (
        <div
            className={clsx('big-radio-select', {
                disabled,
            })}
        >
            {options.map((o) => (
                <>
                    <div
                        key={o}
                        onClick={(): void => selectOption(o)}
                        className={clsx({
                            selected: value === o,
                        })}
                    >
                        <span>{o}</span>
                    </div>
                </>
            ))}
        </div>
    );
};
