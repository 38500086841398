import React, { FC, useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { QueryHookOptions } from '@apollo/client';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '@/contexts/Notifications';
import { formatRFC3339 } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useActions } from '../hooks/useActions';
import {
    ConfirmationButtons,
    DeclineFeedback,
    PricingInfoLabel,
    SidePanelV2,
    StatusWrapper,
} from '../styled';
import { EstimatorAssignment } from './Panels/EstimatorAssignment';
import { FeedbackPanel } from './Panels/FeedbackPanel';
import { RequestRevisionPanel } from './Panels/RequestRevisionPanel';

import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import {
    AcceptanceDetails,
    DatabaseProjectStatus,
    DeclineDetails,
    DetailsProjectStatus,
    PricingStatus,
} from '@/common/types';
import { formatDate, formatUSD, projectStatusToLabel } from '@/common/utils/helpers';
import { ToolbarButton } from '@/components/Estimate/Toolbar/ToolbarButton';
import { PublishModal } from '@/components/PublishModal';
import { ShareEstimateModal } from '@/components/ShareEstimateModal';
import { AcceptanceModal } from '@/components/projects/ProjectDetails/AcceptanceModal';
import { QuoteModal } from '@/components/projects/ProjectDetails/QuoteModal';
import { AcceptQuoteModal } from '@/components/projects/ProjectDetails/AcceptQuoteModal';
import { DeclineModal } from '@/components/projects/ProjectDetails/DeclineModal';
import { ProjectInfo } from '@/components/projects/ProjectDetails/ProjectInfo';
import { CancelModal } from '@/components/projects/ProjectDetails/ProjectToolbars/Modal/CancelModal';
import { RevisionModal } from '@/components/projects/ProjectDetails/ProjectToolbars/Modal/RevisionModal';
import { StripeErrorModal } from '@/components/projects/ProjectDetails/StripeErrorModal';
import { useActionButtons } from '@/components/projects/ProjectDetails/ProjectToolbars/hooks/useActionButtons';
import { ProjectDetailsComponentProps } from '@/components/projects/ProjectDetails/context';
import { Button as UIButton } from '@/components/ui/buttons/Button';
import { DetailsButton } from '@/components/ui/containers/ProjectsToolbar';
import {
    ActionButtonsPortal,
    LeftButtonsPortal,
} from '@/components/ui/containers/ProjectsToolbar/portals';
import { LocationPrompt } from '@/components/ui/controls/LocationPrompt';
import { ProjectStatusLabel } from '@/components/ui/controls/ProjectStatusLabel';
import { Textarea } from '@/components/ui/inputs/Textarea';
import { CenteredSpinnerLoader } from '@/components/ui/loaders/CenteredSpinnerLoader';
import { Modal } from '@/components/ui/modals/Modal';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import {
    IUserRole,
    useTeamLazyQuery,
    ITeamQuery,
    ITeamQueryVariables,
    IEstimatorQuoteInput,
    IUserFragment,
    useProjectsByTeamLazyQuery,
    IProjectsByTeamQuery,
    IProjectsByTeamQueryVariables,
} from '@/graphql';

import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectAcceptanceHistoryRecord } from '@/queries/projectAcceptanceHistories';
import { ReactComponent as Details } from '@/assets/icons/details.svg';
import { ReactComponent as LeftArrow } from '@/assets/icons/left-arrow.svg';
import { ReactComponent as TurquoiseCheck } from '@/assets/icons/turquoise_checkmark.svg';
import to from 'await-to-js';
import { isValid } from 'date-fns';
import { useGetQueryParams } from '@/common/queryParams';

const getFullName = (user: IUserFragment | BaseUserRecord | undefined): string =>
    user ? `${user.firstName ?? ''} ${user.lastName ?? ''}` : '';

const COPY = {
    details: 'Details',
    cancelModalTitle: 'Cancel project?',
    approveModalTitle: 'Approve Quote!',
    approveAgainModalTitle: 'Approve again to finish payment!',
    declineModalTitle: 'Decline project?',
    declineModalReason: 'Mind telling us why you’re declining?',
    dontCancelProjectBtnText: "Don't cancel",
    cancelProjectBtnText: 'Cancel project',
    declineProjectBtnText: 'Decline',
    approveProjectBtnText: 'Approve',
    goBackToApproveProjectModal: 'Back',
    goToDeclineProjectReasonModal: 'Yes, I want to decline',
    waitingForBuilderApprovalTitle:
        'Project will start as soon as we get approval from the client.',
    waitingForBuilderApprovalText: `Thank you for accepting this project so fast! 
        We are waiting for the client's approval on price/schedule/scope. 
        Please do not start work until we receive Confirmation (you will be notified by text). 
        Once confirmed, you'll be able to access Takeoff tools and Chat.`,
    waitingForBuilderApprovalBtnText: 'Got it',
    awaitingForClientConfirmation: 'Awaiting client confirmation...',
    sentQuoteMessage: 'Quote sent successfully',
    sentQuoteGenericError: 'There has been an error',
    projectPublished: 'Project published',
    greetingApprovePriceMessage: (
        builderName: string,
        estimatorName: string
    ) => `Hi ${builderName}, estimator ${estimatorName} has reviewed your
    project and provided the below price and schedule quotes.`,
    contactEstimatorMessage: (firstName: string, hasContactNumber: boolean) =>
        `If you have questions about this quote, you can send a message to ${firstName} in the chat or contact ${
            hasContactNumber ? `them directly ` : `1build Support `
        } at `,
    notEnoughCreditsMessage: (price: number) =>
        `You do not have enough credits to complete this project — your card
        on file will be billed ${formatUSD(price)}`,
    waitForPaymentMessage: 'On approval, we will process your payment and then get to work!',
};

const ACCEPTANCE_DETAILS_DEFAULT = {
    pickingAllTrades: false,
    excludedTrades: '',
    projectStartDate: '',
    projectCompletionDate: '',
};

const DECLINE_DETAILS_DEFAULT = {
    otherReasonSelected: false,
    refusalSummary: '',
    expectedDuration: 0,
    expectedDurationSummary: '',
};

export const ProjectToolbar: FC<ProjectDetailsComponentProps> = ({ useProjectDetails }) => {
    /**************************************************************************/
    /*                                 State                                  */
    /**************************************************************************/
    const {
        assignedTo,
        project,
        isInfoPanelOpen,
        closeInfoPanel,
        openInfoPanel,
        setIsLocationDropdownOpen,
        pricingStatus,
        isCancelProjectModalOpen,
        toggleCancelProjectModal,
        isApproveProjectPriceModalOpen,
        toggleApproveProjectPriceModal,
        isDeclineProjectPriceModalOpen,
        toggleDeclineProjectPriceModal,
        hasReasonForDeclinePrice,
        toggleHasReasonForDeclinePrice,
        isWaitingForBuilderApprovalModalOpen,
        setWaitingForBuilderApprovalModal,
        declinePriceFeedback,
        setDeclinePriceFeedback,
        pricingAssignment,
    } = useProjectDetails();
    const {
        features: { builderEstimateView },
    } = useFeatures();
    const { builderAcceptance } = useFlags();
    const { getQueryParamByName } = useGetQueryParams();
    const setupIntentParamExists = !!getQueryParamByName('setup_intent');

    const { addNotification } = useNotifications();

    const { id, status, uuid: projectUUID, isSaas, isPriceFinal } = project;
    const history = useHistory();
    const {
        validations: { isUserRoles },
        data: {
            user: { roles, firstName, id: userId },
        },
    } = useUser();
    const screenSize = useScreenSize();
    const isMobile = screenSize === ScreenSize.Mobile;

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isAcceptQuoteModalVisible, setIsAcceptQuoteModalVisible] = useState<boolean>(false);
    const [isAcceptanceModalVisible, setIsAcceptanceModalVisible] = useState<boolean>(false);
    const [isDeclineModalVisible, setIsDeclineModalVisible] = useState<boolean>(false);
    const [isPublishModalVisible, setIsPublishModalVisible] = useState<boolean>(false);
    const [feedbackPanelOpen, setFeedbackPanelOpen] = useState(false);
    const [revisionPanelOpen, setRevisionPanelOpen] = useState(false);
    const [isLocationPromptOpen, setIsLocationPromptOpen] = useState(false);
    const [estimatorAssignmentPanelOpen, setEstimatorAssignmentPanelOpen] = useState(false);
    const [acceptanceDetails, setAcceptanceDetails] = useState<AcceptanceDetails>(
        ACCEPTANCE_DETAILS_DEFAULT
    );
    const [quoteDetails, setQuoteDetails] = useState<IEstimatorQuoteInput>(
        {} as IEstimatorQuoteInput
    );
    const [declineDetails, setDeclineDetails] = useState<DeclineDetails>(DECLINE_DETAILS_DEFAULT);
    const [acceptanceModalError, setAcceptanceModalError] = useState<boolean>(false);
    const [declineModalError, setDeclineModalError] = useState<boolean>(false);
    const [isExportFilesModalVisible, setIsExportFilesModalVisible] = useState<boolean>(false);
    const [isShareEstimateModalVisible, setIsShareEstimateModalVisible] = useState<boolean>(false);
    const [isStripeErrorModalVisible, setIsStripeErrorModalVisible] = useState<boolean>(false);
    const [stripeErrorMessageUrl, setStripeErrorMessageUrl] = useState<string>('');

    const uploadRef = useRef<HTMLInputElement>(null);

    /**************************************************************************/
    /*                                Graphql                                 */
    /**************************************************************************/

    const fetchTeamInput = (id: string): QueryHookOptions<ITeamQuery, ITeamQueryVariables> => ({
        variables: {
            input: {
                id,
            },
        },
    });

    const fetchProjectsInput = (
        id: string
    ): QueryHookOptions<IProjectsByTeamQuery, IProjectsByTeamQueryVariables> => ({
        variables: {
            input: {
                id,
            },
        },
    });

    const [fetchTeam, { data: fullTeamData }] = useTeamLazyQuery();
    const [fetchProjects, { data: fullProjectsData }] = useProjectsByTeamLazyQuery();

    /**************************************************************************/
    /*                                Helpers                                 */
    /**************************************************************************/
    const closeEstimatorAssignmentPanel = (): void => setEstimatorAssignmentPanelOpen(false);
    const closeFeedbackPanel = (): void => setFeedbackPanelOpen(false);
    const closeRevisionPanel = (): void => setRevisionPanelOpen(false);
    const projectIs = (match: DatabaseProjectStatus): boolean => match === status;
    const reviseProject = (): void => history.push(`/projects/${projectUUID}/edit`);
    const userIs = (role: IUserRole): boolean => roles.includes(role);
    const closeLocationPrompt = (): void => setIsLocationPromptOpen(false);
    const submitLocationPrompt = (): void => {
        setIsLocationDropdownOpen(true);
        setIsLocationPromptOpen(false);
    };

    const builderHasEnoughCredits = (): boolean => {
        const projectPrice = priceUsdCents ?? 0;
        const teamBalance = project.team?.balance?.usdCents ?? 0;
        const isTeamBalanceBelowZero = teamBalance < 0;
        const balanceAfterCharge =
            ((isTeamBalanceBelowZero ? 0 : teamBalance) - projectPrice) / 100;

        return balanceAfterCharge >= 0;
    };

    const priceUsdCents = pricingAssignment?.priceUsdCents;

    const hasContactNumber = !!assignedTo?.phone;

    const showWaitingForBuilderInfo =
        !isPriceFinal &&
        builderAcceptance &&
        projectIs(DatabaseProjectStatus.ESTIMATING) &&
        userIs(IUserRole.Estimator) &&
        !userIs(IUserRole.Builder) &&
        !userIs(IUserRole.Admin);

    const showPublishedLabel =
        isUserRoles(IUserRole.Estimator) &&
        !isUserRoles(IUserRole.Admin) &&
        !isUserRoles(IUserRole.Builder) &&
        builderEstimateView(project?.created) &&
        status === DatabaseProjectStatus.COMPLETE;

    const estimatorSubmittedDate: string =
        project.projectAcceptanceHistories?.nodes.find(
            (node: ProjectAcceptanceHistoryRecord) => !!node.accepted
        )?.finishBy ?? '';

    // can be estimator's or 1Build's phone number
    const phoneNumber = assignedTo?.phone || '(240) 713-5474';

    /**************************************************************************/
    /*                        Dynamic progress buttons                        */
    /**************************************************************************/
    const actions = useActions({
        useProjectDetails,
        setEstimatorAssignmentPanelOpen,
        setFeedbackPanelOpen,
        setIsModalVisible,
        setIsAcceptanceModalVisible,
        setIsAcceptQuoteModalVisible,
        setIsDeclineModalVisible,
        setIsPublishModalVisible,
        setRevisionPanelOpen,
        setIsLocationPromptOpen,
        setIsExportFilesModalVisible,
        setIsShareEstimateModalVisible,
        setIsStripeErrorModalVisible,
        setStripeErrorMessageUrl,
        uploadRef,
    });
    const actionButtons = useActionButtons({ useProjectDetails, ...actions });

    // Destructure actions for convenience
    const {
        accept,
        sendQuote,
        publish,
        cancel,
        decline,
        toggleModal,
        toggleAcceptanceModal,
        toggleAcceptQuoteModal,
        toggleDeclineModal,
        toggleExportFilesModal,
        togglePublishModal,
        toggleShareEstimateModal,
        toggleStripeErrorModal,
        uploadEstimate,
        onPriceApprove,
        isOnPriceApproveLoading,
        onPriceDecline,
        isOnPriceDeclineLoading,
    } = actions;

    /**************************************************************************/
    /*                                 Render                                 */
    /**************************************************************************/
    const actionButtonsJSX = actionButtons.map(({ label, action, color, variant, Icon }, key) => {
        if (builderEstimateView(project?.created)) {
            return (
                <ToolbarButton
                    key={key}
                    disabled={!action}
                    onClick={action}
                    color={color || 'primary'}
                    variant={variant === 'outlined' ? 'outlined' : 'contained'}
                    mobileIcon={
                        Icon ? (
                            <SvgIcon viewBox="0 0 32 32">
                                <Icon width={32} height={32} />
                            </SvgIcon>
                        ) : undefined
                    }
                    startIcon={
                        Icon ? (
                            <SvgIcon viewBox="0 0 32 32">
                                <Icon width={32} height={32} />
                            </SvgIcon>
                        ) : undefined
                    }
                >
                    {label}
                </ToolbarButton>
            );
        }
        return (
            <Button
                key={key}
                disabled={!action}
                onClick={action}
                color={color || 'primary'}
                variant={variant === 'outlined' ? 'outlined' : 'contained'}
                startIcon={
                    Icon ? (
                        <SvgIcon viewBox="0 0 32 32">
                            <Icon width={32} height={32} />
                        </SvgIcon>
                    ) : undefined
                }
            >
                {label}
            </Button>
        );
    });

    const renderApproveProjectPriceModalMessage = (): JSX.Element => {
        return (
            <>
                <div>
                    {COPY.greetingApprovePriceMessage(firstName, assignedTo?.firstName ?? '')}
                </div>
                <br />
                <div>
                    <strong>Project:</strong>
                    {` ${project.name ?? ''}`}{' '}
                </div>
                <div>
                    <strong>Estimated Completion:</strong>
                    {` Estimate returned ${
                        isValid(new Date(estimatorSubmittedDate))
                            ? formatDate(estimatorSubmittedDate)
                            : formatDate(project.bidsDueDate)
                    } `}
                    if approved by quote expiration{' '}
                </div>
                {project.projectAcceptanceHistories?.nodes[0] && (
                    <div>
                        <strong>Quote Expiration:</strong>
                        {` Quote Expires in ${
                            project.projectAcceptanceHistories?.nodes[0]?.quoteExpiration || 0
                        } hours`}
                    </div>
                )}
                <div>
                    <strong>Price Quote:</strong>
                    {` ${formatUSD(priceUsdCents ?? 0)}`}
                </div>
                <br />
                <div>{COPY.waitForPaymentMessage}</div>
                {assignedTo && (
                    <div>
                        {COPY.contactEstimatorMessage(
                            assignedTo?.firstName ?? '',
                            hasContactNumber
                        )}
                        <a css={'color: #5F6472;'} href={`${phoneNumber}`}>
                            {phoneNumber}
                        </a>
                        . Thank you!
                    </div>
                )}
                <br />
                {!builderHasEnoughCredits() && (
                    <>
                        <div>
                            <strong>
                                {COPY.notEnoughCreditsMessage(
                                    (priceUsdCents ?? 0) -
                                        Math.max(project.team?.balance?.usdCents ?? 0, 0)
                                )}
                            </strong>
                        </div>
                        <br />
                    </>
                )}
            </>
        );
    };

    const renderDeclineProjectPriceModalMessage = (): JSX.Element => {
        return (
            <>
                Declining the project will void the quoted price and return date, which may delay
                project completion.
                <br />
                <br />
                After declining, please note any price or return date issues in the following
                screen. Please contact the assigned 1build estimator to discuss. After declining,
                this project will be quoted again by a 1build estimator.
            </>
        );
    };

    const renderCancelProjectModalMessage = (): string => {
        return `Are you sure you want to cancel ${project.name ?? ''}? 
            We will not start work, and you will not be charged.`;
    };

    /**************************************************************************/
    /*                               Effects                                  */
    /**************************************************************************/
    // Checks if a builder's project has an open revision request
    // and opens modal.
    useEffect(() => {
        if (!project) return;

        if (userIs(IUserRole.Builder) && project.estimateDueDate && project.additionalInfo) {
            toggleModal();
        }

        fetchTeam(fetchTeamInput(project.team?.id ?? ''));

        fetchProjects(fetchProjectsInput(project.team?.id ?? ''));

        // set quote modal information
        setQuoteDetails({
            acceptance: {
                projectID: project.id.toString(),
                userID: userId,
                startBy: '',
                finishBy: '',
                scopeOfWork: '',
                accepted: true,
            },
            pricing: {
                bidsDueDate: formatRFC3339(new Date(project.bidsDueDate)),
                estimatorHours: project.estimatorHours ?? 0,
                // set non zero value so the form validation passes
                price: isPriceFinal ? 1 : 0,
                id: project.id.toString(),
            },
        });
    }, [project]);

    useEffect(() => {
        if (!isAcceptanceModalVisible) {
            setAcceptanceModalError(false);
        }
        if (!isDeclineModalVisible) {
            setDeclineModalError(false);
        }
    }, [isAcceptanceModalVisible, isDeclineModalVisible]);

    useEffect(() => {
        if (pricingStatus === PricingStatus.PREAPPROVED) toggleApproveProjectPriceModal();
    }, [pricingStatus]);

    useEffect(() => {
        // If estimator accepted assignment and waiting for builder approval for price assignment
        // show info modal for estimator
        setWaitingForBuilderApprovalModal(showWaitingForBuilderInfo);
    }, [showWaitingForBuilderInfo]);

    /**************************************************************************/
    /*                               Methods                                  */
    /**************************************************************************/

    const acceptProject = (): void => {
        if (
            acceptanceDetails.projectCompletionDate === '' ||
            acceptanceDetails.projectStartDate === ''
        ) {
            setAcceptanceModalError(true);
        } else if (!acceptanceDetails.pickingAllTrades && acceptanceDetails.excludedTrades === '') {
            setAcceptanceModalError(true);
        } else {
            accept(acceptanceDetails);
            toggleAcceptanceModal();
        }
    };

    const declineProject = (): void => {
        if (declineDetails.otherReasonSelected && declineDetails.refusalSummary === '') {
            setDeclineModalError(true);
        } else if (
            !declineDetails.otherReasonSelected &&
            (declineDetails.expectedDuration === 0 || declineDetails.expectedDurationSummary === '')
        ) {
            setDeclineModalError(true);
        } else {
            decline(declineDetails);
        }
    };

    const projectLabelStatus = (): DetailsProjectStatus => {
        let projectStatus = status || DatabaseProjectStatus.NEW;

        if (builderAcceptance && !isSaas) {
            if (!isPriceFinal) {
                // PENDING_ESTIMATOR is the only status which is common for
                // NOT_STARTED and PENDING_ACCEPTANCE statuses on builder/estimator dashboard
                projectStatus = DatabaseProjectStatus.PENDING_ESTIMATOR;
            }
        }

        return projectStatusToLabel(projectStatus, 'details', roles, isSaas);
    };

    const sendQuoteDetails = async (submittedQuoteDetails: IEstimatorQuoteInput) => {
        setIsAcceptanceModalVisible(false);

        if (!submittedQuoteDetails) return;

        const [error] = await to(sendQuote(submittedQuoteDetails));
        if (error) {
            addNotification(
                { title: 'Error', content: error.message.toString() ?? COPY.sentQuoteGenericError },
                'error'
            );
            return;
        }
        addNotification({ title: 'Success', content: COPY.sentQuoteMessage }, 'success');
    };

    if (!roles) return <></>;

    return (
        <>
            <LeftButtonsPortal>
                <DetailsButton onClick={openInfoPanel} copy={COPY.details} svg={<Details />} />
            </LeftButtonsPortal>

            <ActionButtonsPortal>
                <input
                    ref={uploadRef}
                    css={'display: none;'}
                    onChange={uploadEstimate}
                    type="file"
                    multiple
                />

                {!isMobile &&
                    !!(status !== DatabaseProjectStatus.CANCELED && status !== undefined) &&
                    !showWaitingForBuilderInfo &&
                    (showPublishedLabel ? (
                        <ToolbarButton
                            disabled={true}
                            color="info"
                            variant="outlined"
                            startIcon={
                                <SvgIcon viewBox="0 0 32 32">
                                    <TurquoiseCheck width={32} height={32} />
                                </SvgIcon>
                            }
                            mobileIcon={
                                <SvgIcon viewBox="0 0 32 32">
                                    <TurquoiseCheck width={32} height={32} />
                                </SvgIcon>
                            }
                        >
                            {COPY.projectPublished}
                        </ToolbarButton>
                    ) : (
                        <StatusWrapper>
                            <ProjectStatusLabel
                                className="state-label"
                                status={projectLabelStatus()}
                            />
                        </StatusWrapper>
                    ))}

                {showWaitingForBuilderInfo && (
                    <PricingInfoLabel
                        onClick={(): void => {
                            setWaitingForBuilderApprovalModal(true);
                        }}
                    >
                        {COPY.awaitingForClientConfirmation}
                    </PricingInfoLabel>
                )}
                {actionButtonsJSX}
            </ActionButtonsPortal>

            {/** Modals */}
            {!!(
                userIs(IUserRole.Builder) &&
                projectIs(DatabaseProjectStatus.NEW) &&
                isModalVisible
            ) &&
                !builderAcceptance && <CancelModal cancel={cancel} close={toggleModal} />}
            {project &&
                userIs(IUserRole.Builder) &&
                project.estimateDueDate &&
                project.additionalInfo && (
                    <RevisionModal
                        additionalInfo={project.additionalInfo}
                        close={toggleModal}
                        estimateDueDate={project.estimateDueDate}
                        reviseProject={reviseProject}
                    />
                )}
            {isLocationPromptOpen && (
                <LocationPrompt close={closeLocationPrompt} submit={submitLocationPrompt} />
            )}

            <OutsideClickHandler onOutsideClick={closeFeedbackPanel}>
                <SidePanelV2 open={feedbackPanelOpen}>
                    <FeedbackPanel v2 projectId={id.toString()} close={closeFeedbackPanel} />
                </SidePanelV2>
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={closeRevisionPanel}>
                <SidePanelV2 open={revisionPanelOpen}>
                    <RequestRevisionPanel v2 projectId={id.toString()} close={closeRevisionPanel} />
                </SidePanelV2>
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={closeEstimatorAssignmentPanel}>
                <SidePanelV2 open={estimatorAssignmentPanelOpen}>
                    <EstimatorAssignment
                        v2
                        projectId={id.toString()}
                        close={closeEstimatorAssignmentPanel}
                    />
                </SidePanelV2>
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={closeInfoPanel}>
                <SidePanelV2
                    showShadow={screenSize !== ScreenSize.Mobile}
                    open={isInfoPanelOpen}
                    side="left"
                >
                    <ProjectInfo useProjectDetails={useProjectDetails} />
                </SidePanelV2>
            </OutsideClickHandler>
            <AcceptQuoteModal
                onClose={toggleAcceptQuoteModal}
                open={isAcceptQuoteModalVisible}
                onDeclineButtonClick={toggleAcceptQuoteModal}
                onAcceptButtonClick={() => {
                    toggleAcceptQuoteModal();
                    toggleAcceptanceModal();
                }}
            />
            {isAcceptanceModalVisible &&
                (builderAcceptance ? (
                    <QuoteModal
                        isPriceSet={isPriceFinal}
                        hasSubscription={!!fullTeamData?.team.subscription}
                        areMoreProjects={
                            (fullProjectsData?.projectsByTeam.edges &&
                                fullProjectsData?.projectsByTeam.edges?.filter(
                                    (projectByTeam: {
                                        node: { id: string; name: string | null; isSaas: boolean };
                                    }) => !projectByTeam.node.isSaas
                                ).length > 1) ??
                            false
                        }
                        open={isAcceptanceModalVisible}
                        userName={getFullName(assignedTo)}
                        quoteDetails={quoteDetails}
                        onCancelClick={toggleAcceptanceModal}
                        onClose={toggleAcceptanceModal}
                        submitQuoteDetails={sendQuoteDetails}
                    />
                ) : (
                    <AcceptanceModal
                        userName={assignedTo?.firstName ?? ''}
                        acceptanceDetails={acceptanceDetails}
                        handleData={setAcceptanceDetails}
                        close={toggleAcceptanceModal}
                        submit={acceptProject}
                        error={acceptanceModalError}
                        maxDate={String(project.bidsDueDate).split('T')[0]}
                    />
                ))}
            <StripeErrorModal
                isModalVisible={isStripeErrorModalVisible}
                close={toggleStripeErrorModal}
                stripeUrl={stripeErrorMessageUrl}
                projectName={project.name}
                projectUUID={projectUUID}
                proceedToCCForm={() => setStripeErrorMessageUrl('')}
            />
            <PublishModal
                isModalVisible={isPublishModalVisible}
                close={togglePublishModal}
                onPublish={publish}
            />
            {isDeclineModalVisible && (
                <DeclineModal
                    declineDetails={declineDetails}
                    handleData={setDeclineDetails}
                    close={toggleDeclineModal}
                    submit={declineProject}
                    error={declineModalError}
                />
            )}
            {builderEstimateView(project?.created) && (
                <ShareEstimateModal
                    close={toggleExportFilesModal}
                    isOpen={isExportFilesModalVisible}
                    isExportOnly
                    headerText="Export estimate"
                />
            )}

            {isShareEstimateModalVisible && project && (
                <ShareEstimateModal
                    isOpen={isShareEstimateModalVisible}
                    close={toggleShareEstimateModal}
                />
            )}
            {userIs(IUserRole.Builder) && builderAcceptance && (
                <>
                    {/* Cancel project price modal */}
                    <Modal
                        isOpen={isCancelProjectModalOpen}
                        close={toggleCancelProjectModal}
                        headerText={COPY.cancelModalTitle}
                        modalContent={
                            <React.Fragment>
                                {renderCancelProjectModalMessage()}
                                <ConfirmationButtons>
                                    <UIButton
                                        variant="tertiary"
                                        onClick={(): void => toggleCancelProjectModal()}
                                    >
                                        {COPY.dontCancelProjectBtnText}
                                    </UIButton>
                                    <UIButton
                                        variant="tertiary"
                                        customCss={{
                                            color: '#F35252',
                                        }}
                                        onClick={(): void => {
                                            cancel();
                                            toggleCancelProjectModal();
                                        }}
                                    >
                                        {COPY.cancelProjectBtnText}
                                    </UIButton>
                                </ConfirmationButtons>
                            </React.Fragment>
                        }
                    />

                    {/* Accept/Approve project price modal */}
                    <Modal
                        isOpen={isApproveProjectPriceModalOpen}
                        close={toggleApproveProjectPriceModal}
                        headerText={
                            setupIntentParamExists
                                ? COPY.approveAgainModalTitle
                                : COPY.approveModalTitle
                        }
                        width="36rem"
                        modalContent={
                            isOnPriceApproveLoading ? (
                                <CenteredSpinnerLoader style={{ padding: '0' }} />
                            ) : (
                                <React.Fragment>
                                    {renderApproveProjectPriceModalMessage()}
                                    <ConfirmationButtons>
                                        <UIButton
                                            variant="tertiary"
                                            onClick={(): void => {
                                                toggleApproveProjectPriceModal();
                                                toggleDeclineProjectPriceModal();
                                            }}
                                        >
                                            {COPY.declineProjectBtnText}
                                        </UIButton>
                                        <UIButton
                                            variant="primary"
                                            onClick={(): void => {
                                                onPriceApprove();
                                            }}
                                        >
                                            {COPY.approveProjectBtnText}
                                        </UIButton>
                                    </ConfirmationButtons>
                                </React.Fragment>
                            )
                        }
                    />

                    {/* Decline project price modal */}
                    <Modal
                        isOpen={isDeclineProjectPriceModalOpen}
                        close={toggleDeclineProjectPriceModal}
                        headerText={
                            !hasReasonForDeclinePrice
                                ? COPY.declineModalTitle
                                : COPY.declineModalReason
                        }
                        modalContent={
                            isOnPriceDeclineLoading ? (
                                <CenteredSpinnerLoader style={{ padding: '0' }} />
                            ) : !hasReasonForDeclinePrice ? (
                                <React.Fragment>
                                    {renderDeclineProjectPriceModalMessage()}
                                    <ConfirmationButtons>
                                        <UIButton
                                            variant="secondary"
                                            icon={LeftArrow}
                                            onClick={(): void => {
                                                toggleDeclineProjectPriceModal();
                                                toggleApproveProjectPriceModal();
                                            }}
                                        >
                                            {COPY.goBackToApproveProjectModal}
                                        </UIButton>
                                        <UIButton
                                            variant="tertiary"
                                            onClick={(): void => toggleHasReasonForDeclinePrice()}
                                        >
                                            {COPY.goToDeclineProjectReasonModal}
                                        </UIButton>
                                    </ConfirmationButtons>
                                </React.Fragment>
                            ) : (
                                <DeclineFeedback>
                                    <span>Reason for declining</span>
                                    <Textarea
                                        value={declinePriceFeedback}
                                        onChange={setDeclinePriceFeedback}
                                        placeholder="Enter message..."
                                    />
                                    <ConfirmationButtons>
                                        <UIButton variant="primary" onClick={onPriceDecline}>
                                            Submit
                                        </UIButton>
                                    </ConfirmationButtons>
                                </DeclineFeedback>
                            )
                        }
                    />
                </>
            )}

            {showWaitingForBuilderInfo && (
                <>
                    {/* Waiting for builder approval - info modal */}
                    <Modal
                        isOpen={isWaitingForBuilderApprovalModalOpen}
                        close={() => {
                            setWaitingForBuilderApprovalModal(false);
                        }}
                        headerText={COPY.waitingForBuilderApprovalTitle}
                        modalContent={
                            <React.Fragment>
                                {COPY.waitingForBuilderApprovalText}
                                <ConfirmationButtons>
                                    <UIButton
                                        variant="primary"
                                        onClick={(): void => {
                                            setWaitingForBuilderApprovalModal(false);
                                        }}
                                    >
                                        {COPY.waitingForBuilderApprovalBtnText}
                                    </UIButton>
                                </ConfirmationButtons>
                            </React.Fragment>
                        }
                    />
                </>
            )}
        </>
    );
};
