import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import {
    colorBackgroundSecondaryLighter,
    colorBackgroundSecondaryLightest,
    colorTypographyDark,
    colorTypographyDarkSecondary,
    shadowDropdown,
    transition,
} from '@/variables';

import { ReactComponent as Plus } from '@/assets/icons/plus.svg';

const innerContainerGrid = `
" .         input  .       plus  .       " 2.625rem
" opts      opts   opts    opts  opts    " auto
" load      load   load    load  load    " auto
/ 1.625rem  1fr    0.5rem  auto  0.75rem `;

const optionsMenuOptionContainerGrid = `
" .         opt  .       " 2rem
/ 1.625rem  1fr  0.75rem `;

export const NewElementIcon = styled(Plus)`
    height: 8px;
    margin-right: 8px;

    & > path {
        fill: #334ad7;
    }
`;

export const OuterContainer = styled.div`
    cursor: default;
    position: relative;
    width: 13rem;
    align-self: flex-start;
    color: ${colorTypographyDark};
    font-size: 0.875rem;
`;

export const InnerContainer = styled.div`
    position: absolute;
    top: 0;
    left: -1.625rem;
    right: 0;
    display: grid;
    grid-template: ${innerContainerGrid};
    background-color: ${colorBackgroundSecondaryLightest};
    box-shadow: ${shadowDropdown};
    border-radius: 0.125rem;
`;

export const ElementInput = styled.input`
    grid-area: input;
    border-radius: 2px;
    border: none;
    color: ${colorTypographyDark};
    caret-color: ${colorTypographyDarkSecondary};
    outline: none;
    font-size: 0.875rem;
    line-height: 286%;
    align-self: center;
    padding: 0;
`;

export const OptionsMenuContainer = styled.div`
    grid-area: opts;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: ${transition};
    padding-bottom: 1rem;
    max-height: 18rem;
    overflow-y: overlay;

    font-weight: 400;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-button {
        height: 0;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(91, 96, 110, 0.5);
        background-clip: padding-box;
        border: 0.125rem solid transparent;
        border-radius: 0.375rem;
    }
`;

/* eslint-disable @typescript-eslint/indent */
export interface OptionsMenuOptionContainerProps {
    isActive: boolean;
    hasTopBorder?: boolean;
    overrideColors?: {
        default: string;
        hover: string;
    };
}

export const OptionsMenuOptionContainer = styled.div<OptionsMenuOptionContainerProps>`
    display: grid;
    grid-template: ${optionsMenuOptionContainerGrid};
    flex: none;
    transition: ${transition};
    cursor: pointer;
    user-select: none;
    background-color: ${(props): string => props.overrideColors?.default || 'transparent'};
    border-top: ${(props): string =>
        props.hasTopBorder ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};

    ${(props): FlattenSimpleInterpolation | string =>
        props.isActive
            ? css`
                  color: ${props.overrideColors?.default || 'auto'};
                  background-color: ${props.overrideColors?.hover ||
                  colorBackgroundSecondaryLighter};

                  & svg {
                      & path {
                          fill: '#FFF';
                      }
                  }
              `
            : ''};
`;
/* eslint-enable @typescript-eslint/indent */

export const OptionsMenuOption = styled.span`
    grid-area: opt;
    align-self: center;
    text-overflow: ellipsis;
    font-size: 14px;
`;

export const AddElementOption = styled(OptionsMenuOption)`
    display: flex;
    align-items: center;
    color: #334ad7;

    font-weight: 600;

    & svg {
        margin-bottom: 2px;
        margin-right: 4px;
    }

    &:hover {
        color: #fff;

        & ${NewElementIcon} {
            & > path {
                fill: #fff;
            }
        }
    }
`;
