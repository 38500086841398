/*
 * BalanceCalculator displays project price details within the price module.
 */
import React, { FC } from 'react';

import {
    BalanceCalculatorAdditionLine,
    BalanceCalculatorContainer,
    BalanceCalculatorNumber,
    DetailLabel,
} from './styled';
import { parseChargeValues } from './utils';

import { usdFormatter } from '.';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';

const COPY = {
    balanceAfterCharge: 'BALANCE AFTER CHARGE',
};

const Figure: FC<{ cents: number; isTotal?: boolean; forceSign?: boolean }> = ({
    cents,
    isTotal = false,
    forceSign,
}) => {
    let sign: string | undefined = cents < 0 ? '-' : undefined;
    if (forceSign && sign === undefined) {
        sign = '+';
    }
    return (
        <BalanceCalculatorNumber isTotal={isTotal} sign={sign}>
            {usdFormatter.format(Math.abs(cents) / 100)}
        </BalanceCalculatorNumber>
    );
};

export const BalanceCalculator: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { modifiedPrice, project, pricingAssignment } = useProjectPanel();

    const priceUsdCents = pricingAssignment?.priceUsdCents ?? 0;

    if (modifiedPrice === undefined) {
        return <></>;
    }

    const { teamBalance, priceNetChange } = parseChargeValues(
        project,
        priceUsdCents,
        modifiedPrice
    );

    if (modifiedPrice === undefined) {
        return <></>;
    }

    return (
        <BalanceCalculatorContainer>
            <DetailLabel>{COPY.balanceAfterCharge}</DetailLabel>
            <Figure cents={teamBalance} />
            <Figure cents={priceNetChange} forceSign={true} />
            <BalanceCalculatorAdditionLine />
            <Figure cents={teamBalance + priceNetChange} isTotal={true} />
        </BalanceCalculatorContainer>
    );
};
