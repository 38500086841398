import { useNotifications } from '@/contexts/Notifications';
import { IAssembliesDuplicationMutation, useAssembliesDuplicationMutation } from '@/graphql';
import { IAssemblyFragment } from '@/graphql';
import React, { useCallback } from 'react';

const COPY = {
    importSuccessSingular: 'Added to project',
    importSuccessPluralTemplate: 'Added to project',
    importSuccessDescriptionSingular: 'is now accessible within this project.',
    importSuccessDescriptionPlural:
        'Your imported assemblies are now accessible within this project.',
};

interface UseHandleAssembliesImportedArguments {
    projectId: number;
}

type UseHandleAssembliesImportedPayload = (
    assemblies: IAssemblyFragment[]
) => Promise<IAssembliesDuplicationMutation>;

export const useHandleAssembliesImported = ({
    projectId,
}: UseHandleAssembliesImportedArguments): UseHandleAssembliesImportedPayload => {
    const { addNotification } = useNotifications();
    const [assembliesDuplicationMutation] = useAssembliesDuplicationMutation();

    return useCallback(
        (assemblies: IAssemblyFragment[]): Promise<IAssembliesDuplicationMutation> => {
            return new Promise<IAssembliesDuplicationMutation>((resolve, reject) => {
                assembliesDuplicationMutation({
                    variables: {
                        input: {
                            projectID: projectId.toString(),
                            ids: assemblies.map((a) => a.id),
                        },
                    },
                    refetchQueries: ['Assemblies', 'AssembliesLite'],
                }).then((result) => {
                    if (result.data) {
                        const edges = result.data.assembliesDuplication.edges;
                        if (edges && edges.length === 1) {
                            const assembly = edges[0].node;
                            addNotification(
                                {
                                    title: COPY.importSuccessSingular,
                                    content: (
                                        <p>
                                            {`${assembly.description} ${COPY.importSuccessDescriptionSingular}`}
                                        </p>
                                    ),
                                },
                                'success'
                            );
                        }
                        if (edges && edges.length > 1) {
                            addNotification(
                                {
                                    title: `${COPY.importSuccessPluralTemplate}`,
                                    content: <p>{COPY.importSuccessDescriptionPlural}</p>,
                                },
                                'success'
                            );
                        }
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
            });
        },
        [projectId]
    );
};
