import { IProjectLocationFragment } from '@/graphql';

type InferredAddress = Partial<Omit<IProjectLocationFragment, 'id' | 'projectID'>>;

/**
 * Convert google autocomplete place to partial of project location data
 * @param {google.maps.places.PlaceResult | null} place - google autocomplete place
 * @returns {InferredAddress} - partial of inferred address attributes
 */
export const inferAddressAttributesFromPlace = (
    place: google.maps.places.PlaceResult | null
): InferredAddress => {
    const streetNumber = place?.address_components?.find((c) =>
        c.types.includes('street_number')
    )?.short_name;
    const route = place?.address_components?.find((c) => c.types.includes('route'))?.short_name;

    const streetAddress = streetNumber && route ? `${streetNumber} ${route}` : undefined;

    const county = place?.address_components?.find((addressComponent) => {
        return addressComponent.types.includes('administrative_area_level_2');
    })?.short_name;

    const city = place?.address_components?.find((c) => c.types.includes('locality'))?.short_name;
    const state = place?.address_components?.find((c) =>
        c.types.includes('administrative_area_level_1')
    )?.short_name;
    const zip = place?.address_components?.find((c) => c.types.includes('postal_code'))?.short_name;

    const formattedAddress = place?.formatted_address;
    const coordinates = [
        place?.geometry?.location?.lng() ?? 0,
        place?.geometry?.location?.lat() ?? 0,
    ];

    return {
        coordinates,
        streetAddress,
        city,
        state,
        zip,
        formattedAddress,
        county,
    };
};
