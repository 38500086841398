import { Text } from 'leyden';

export interface SerializeText {
    BaseText: (text?: string) => Text<'BaseText'>;
    ElementQuantityNumeric: (value: number) => Text<'ElementQuantityNumeric'>;
    Empty: () => Text<'Empty'>;
}

export const SerializeText: SerializeText = {
    BaseText(text = '') {
        return Text.new('BaseText', text, {});
    },

    ElementQuantityNumeric(value: number) {
        return Text.new('ElementQuantityNumeric', value.toString(), { validator: 'numeric' });
    },

    Empty() {
        return Text.new('Empty', '', { validator: 'empty' });
    },
};
