import styled, { CSSObject, createGlobalStyle } from 'styled-components';

import { Modal } from '@/components/ui/modals/Modal';
import { breakpointExtraSmall, breakpointSmall } from '@/variables';

export const textColorPrimary = '#000000';
export const textColorSecondary = '#575757';

type TextProps = {
    color?: string;
    size?: string;
    weight?: number;
    margin?: string;
    align?: 'center' | 'right' | 'left';
};

export const SharedEstimateGlobalStyle = createGlobalStyle({
    'html, body': {
        background: '#FFFFFF',
    },
});

const headerFooterStyles: CSSObject = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const gridSharedStyles: CSSObject = {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr 1fr',
    width: '100%',
    alignItems: 'center',
};

const infoRowStyles: CSSObject = {
    position: 'relative',

    alignItems: 'center',

    width: '100%',
    minHeight: '50px',

    borderTop: '1px solid #D7E1E5',

    '&:last-child:not(first-child)': {
        borderBottom: '1px solid #D7E1E5',
    },
};

export const Container = styled.div({
    background: '#FFFFFF',
    height: '100%',
    padding: '40px 30px',
    overflowY: 'auto',
});

export const Header = styled.div({
    marginBottom: '60px',
    ...headerFooterStyles,
});

export const Footer = styled.div({
    marginTop: '20px',
    ...headerFooterStyles,
});

export const FooterLogo = styled.div({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
});

export const CompanyLogo = styled.div({
    position: 'relative',
    width: '200px',
    height: '70px',

    '> img': {
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
    },
});

export const Title = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
});

export const ProjectName = styled.div({
    fontSize: '32px',
    fontWeight: 600,
    color: '#000000',
    marginBottom: '10px',

    [`@media(max-width: ${breakpointSmall})`]: {
        fontSize: '24px',
    },

    '@media(max-width: 320px)': {
        fontSize: '20px',
    },
});

export const ProjectTotal = styled.div({
    fontSize: '32px',
    fontWeight: 600,
    color: '#000000',
    marginBottom: '10px',
    textAlign: 'right',

    [`@media(max-width: ${breakpointSmall})`]: {
        fontSize: '24px',
    },

    '@media(max-width: 320px)': {
        fontSize: '32px',
    },
});

export const Text = styled.div<TextProps>((props) => ({
    fontSize: props.size ? props.size : '16px',
    color: props.color ? props.color : '#000000',
    fontWeight: props.weight ? props.weight : 400,
    margin: props.margin ? props.margin : 0,
    textAlign: props.align || 'left',
    fontFamily: 'proxima-nova, sans-serif',
}));

export const AtSignText = styled.span({
    color: 'inherit',
    fontSize: 'inherit',
    display: 'none',
    margin: '0 5px',

    [`@media(max-width: ${breakpointExtraSmall})`]: {
        display: 'inline-block',
    },
});

export const ColumnContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',

    [`@media(max-width: ${breakpointExtraSmall})`]: {
        marginBottom: '30px',
    },
});

export const RowContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',

    [`& > ${Text}`]: {
        ['&:first-child']: {
            justifyContent: 'flex-end',
        },
    },

    [`@media(max-width: ${breakpointExtraSmall})`]: {
        [`& > ${Text}`]: {
            ['&:first-child']: {
                justifyContent: 'flex-start',
            },
        },
    },
});

export const CollapsibleHeader = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    minWidth: 0,
    width: '100%',
    padding: '10px 0',

    [`> ${Text}`]: {
        ['&:first-child']: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingRight: '10px',
        },
    },
});

export const CollapsibleleContent = styled.div({
    position: 'relative',
    borderBox: 'box-sizing',
    paddingLeft: '33px',
});

export const LimitedInfoRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    ...infoRowStyles,
});

export const DetailedInfoRow = styled.div({
    ...infoRowStyles,

    [`@media(max-width: ${breakpointExtraSmall})`]: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        padding: '10px 0',

        [`& > ${Text}`]: {
            marginBottom: '10px',

            '&:nth-child(1)': {
                order: 0,
                flex: '0 0 50%',
                maxwidth: '50%',
            },

            '&:nth-child(2)': {
                order: 2,
            },

            '&:nth-child(3)': {
                order: 3,
            },

            '&:nth-child(4)': {
                order: 1,
                flex: '0 0 50%',
                maxWidth: '50%',
            },
        },
    },
    ...gridSharedStyles,
});

export const InfoContainer = styled.div({
    position: 'relative',
    marginTop: '40px',
});

export const InfoHeader = styled.div({
    [`@media(max-width: ${breakpointExtraSmall})`]: {
        gridTemplateColumns: '1fr 1fr',

        [`& > ${Text}`]: {
            ['&:nth-child(2), &:nth-child(3)']: {
                display: 'none',
            },
        },
    },
    ...gridSharedStyles,
});

export const InfoFooter = styled.div({
    marginTop: '20px',
    ...gridSharedStyles,
});

export const InfoContent = styled.div({
    position: 'relative',
    marginTop: '20px',
});

export const Grid = styled.div({
    position: 'relative',
});

export const GridRow = styled.div({
    minHeight: '40px',

    [`@media(max-width: ${breakpointExtraSmall})`]: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',

        [`& > ${Text}`]: {
            marginBottom: '10px',

            '&:nth-child(1)': {
                order: 0,
                flex: '0 0 50%',
                maxwidth: '50%',
            },

            '&:nth-child(2)': {
                order: 2,
            },

            '&:nth-child(3)': {
                order: 3,
            },

            '&:nth-child(4)': {
                order: 1,
                flex: '0 0 50%',
                maxWidth: '50%',
            },
        },
    },
    ...gridSharedStyles,
});

export const ContactInfoModal = styled(Modal)({
    minWidth: '37.8125rem',

    [`@media (max-width: ${breakpointExtraSmall})`]: {
        minWidth: '100%',

        [`${Text}`]: {
            wordBreak: 'break-all',
            paddingRight: '10px',
        },
    },
});

export const PersonalInfoBox = styled.div({
    position: 'relative',
    marginBottom: '20px',
});

export const ContactInfoBox = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    minHeight: '50px',
    padding: '10px 0',

    '> button': {
        minWidth: '85px',
    },
});

export const ContactInfoContainer = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    [`> ${ContactInfoBox}`]: {
        '&:nth-child(even)': {
            borderBottom: '1px solid #D7E1E5',
        },
    },
});

export const InfoSection = styled.div({
    display: 'flex',
    alignItems: 'center',
});
