import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PricingPage } from '@/components/pricing/PricingPage';

export const PricingRouter: FC = () => {
    return (
        <Switch>
            <Redirect from="/pricing/*" to="/pricing" />
            <Route path="/pricing">
                <PricingPage />
            </Route>
        </Switch>
    );
};
