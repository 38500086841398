/**
 * An info pane that gives information on a tool that is currently in use. This
 * component may or may not have an action tied to it.
 */
import React, { FC } from 'react';

import { ActionBar } from './helpers/ActionBar';

import './DrawingSpec.scss';

export type DrawingSpecProps = {
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    measure: string;
    value: string;
};

export const DrawingSpec: FC<DrawingSpecProps> = (props) => (
    <ActionBar
        className={props.className}
        onClick={props.onClick}
        submitText={props.onClick && 'done'}
    >
        <span className="drawing-spec-measure">{props.measure}</span>
        <span className="drawing-spec-value">{props.value}</span>
    </ActionBar>
);
