import React, { FC, useState } from 'react';

import Dialog from '@mui/material/Dialog';

import { ACTION_FORMS } from './constants';
import { ActionButton, StyledDialogTitle } from './styled';
import { Action } from './types';

import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';

export const Actions: FC = () => {
    const {
        data: { user },
    } = useUser();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<Action>(ACTION_FORMS[0]);

    const closeModal = (): void => {
        setIsModalVisible(false);
    };

    const userIs = (allowedRoles: IUserRole[]): boolean =>
        user.roles.some((role) => allowedRoles.includes(role));

    return (
        <>
            <Dialog open={isModalVisible} onClose={closeModal} fullWidth maxWidth="xs">
                <StyledDialogTitle>{selectedAction?.text ?? ''}</StyledDialogTitle>
                <selectedAction.component onSubmit={closeModal} />
            </Dialog>

            {ACTION_FORMS.map((action: Action, id: number) =>
                userIs(action.allowedRoles) ? (
                    <ActionButton
                        key={`action-button-${id}`}
                        onClick={() => {
                            setSelectedAction(action);
                            setIsModalVisible(true);
                        }}
                    >
                        {action.text}
                    </ActionButton>
                ) : (
                    <></>
                )
            )}
        </>
    );
};
