import React, { FC, FocusEventHandler, useCallback, useRef } from 'react';

import { ProjectInfoInput, ProjectInfoInputProps } from '.';
import { ProjectInfoTextInput } from './styled';
import { stringToNumber } from '../../../utils/transforms';

interface ProjectInfoNumberInputProps extends ProjectInfoInputProps {
    placeholder: string;
    selectOnFocus?: boolean;
    value: number | null;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    updateProjectInfo: (newValue: number | null) => void;
}

export const ProjectInfoNumberInput: FC<ProjectInfoNumberInputProps> = ({
    className,
    label,
    placeholder,
    selectOnFocus = true,
    value,
    onFocus,
    updateProjectInfo,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            if (onFocus) {
                onFocus(e);
            }
            if (selectOnFocus && inputRef.current) {
                inputRef.current.select();
            }
        },
        [inputRef]
    );

    return (
        <ProjectInfoInput className={className} label={label}>
            <ProjectInfoTextInput
                placeholder={placeholder}
                value={`${value ?? ''}`}
                onChange={(e): void => {
                    const newValue = stringToNumber(e, `${value || ''}`);
                    updateProjectInfo(newValue ? Number(newValue) : null);
                }}
                onFocus={handleFocus}
                ref={inputRef}
            />
        </ProjectInfoInput>
    );
};
