import React, { useEffect } from 'react';

import {
    AlreadyAMemberWrapper,
    Bottom,
    CallButton,
    CallCopy,
    CallLink,
    Center,
    Copyright,
    CopyrightLine,
    Header,
    Logo,
    Nav,
    SignupV2Container,
    SignupV2Section,
    SignupV2Wrapper,
    Top,
} from './styled';
import { SignUpForm } from './SignUpForm';

import { ReactComponent as Phone } from '@/assets/icons/signup/phone.svg';
import { TrialMarketing } from './Marketing/trial';
import { FreemiumMarketing } from './Marketing/freemium';
import { useRouteMatch } from 'react-router';
import { AllowedURLSignUpRole } from '@/common/types';

const COPY = {
    trialHeader: 'Try 1build Free for 14 Days',
    freemiumHeader: 'Create Your Account',
    contactSales: 'Contact sales',
    alreadyAMember: 'Already a member?',
    signIn: 'Sign in',
    phone: '(628) 243 7341',
    disclaimer: 'By submitting this form you agree to our',
    privacyPolicy: 'privacy policy',
    copyright: '© 1build. All rights reserved.',
    privacyPolicyCap: 'Privacy Policy',
    termsOfService: 'Terms of Service',
};

export const Signup = () => {
    const match = useRouteMatch<{ role: AllowedURLSignUpRole }>();
    const isMemberSignup = match.params.role === AllowedURLSignUpRole.Builder;

    useEffect(() => {
        if (isMemberSignup) {
            localStorage.setItem('is_freemium', 'yes');
        } else {
            localStorage.removeItem('is_freemium');
        }
    }, [isMemberSignup]);

    return (
        <SignupV2Wrapper>
            <SignupV2Section>
                <SignupV2Container>
                    <Top>
                        <Nav>
                            <Logo color="#231F20" />
                            {!isMemberSignup && (
                                <>
                                    <CallLink href="tel:+16282437341">
                                        <CallButton v2 variant="outlined">
                                            <Phone />
                                            {COPY.contactSales}
                                        </CallButton>
                                    </CallLink>

                                    <CallCopy>
                                        {COPY.contactSales}
                                        <a href="tel:+16282437341">{COPY.phone}</a>
                                    </CallCopy>
                                </>
                            )}
                        </Nav>
                    </Top>

                    <Center>
                        <Header>{isMemberSignup ? COPY.freemiumHeader : COPY.trialHeader}</Header>

                        <AlreadyAMemberWrapper>
                            {COPY.alreadyAMember} <a href="/login">{COPY.signIn}</a>.
                        </AlreadyAMemberWrapper>

                        <SignUpForm />
                        <br />
                    </Center>
                    <AlreadyAMemberWrapper>
                        {COPY.disclaimer}{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="1build.com/privacy-policy"
                        >
                            {COPY.privacyPolicy}
                        </a>
                        .
                    </AlreadyAMemberWrapper>
                    <Bottom>
                        <Copyright>
                            <CopyrightLine>{COPY.copyright}</CopyrightLine>
                            <CopyrightLine>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://1build.com/privacy-policy"
                                >
                                    {COPY.privacyPolicyCap}
                                </a>{' '}
                                |{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="1build.com/privacy-policy"
                                >
                                    {COPY.termsOfService}
                                </a>
                            </CopyrightLine>
                        </Copyright>
                    </Bottom>
                </SignupV2Container>
            </SignupV2Section>
            {isMemberSignup ? <FreemiumMarketing /> : <TrialMarketing />}
        </SignupV2Wrapper>
    );
};
