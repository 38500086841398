import React, { FC } from 'react';

import styled from 'styled-components';

import { ReactComponent as ThumbUp } from '../../../assets/icons/thumb-up.svg';
import { ReactComponent as ThumbDown } from '../../../assets/icons/thumb-down.svg';

import { SvgIcon } from '../../ui/icons/SvgIcon';

const THUMB_COLORS = {
    upSelected: '#21A7AF',
    downSelected: '#F37952',
    neutral: '#D6D7D8',
};

interface ThumbControlProps {
    isSelected: boolean;
    isThumbUp: boolean;
    onClick?: () => void;
}

export const ThumbControl: FC<ThumbControlProps> = ({ isSelected, isThumbUp, onClick }) => {
    let backgroundColor = THUMB_COLORS.neutral;

    if (isThumbUp && isSelected) {
        backgroundColor = THUMB_COLORS.upSelected;
    }

    if (!isThumbUp && isSelected) {
        backgroundColor = THUMB_COLORS.downSelected;
    }

    return (
        <Thumb background={backgroundColor} onClick={onClick}>
            <SvgIcon src={isThumbUp ? ThumbUp : ThumbDown} />
        </Thumb>
    );
};

const Thumb = styled.div<{ background: string }>`
    width: 28px;
    height: 28px;
    background: ${(props): string => props.background};
    border-radius: 3px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        cursor: pointer;
        fill: #ffffff !important;
    }
`;
