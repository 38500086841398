import React, { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface NoAssembliesWarningProps extends BoxProps {
    header: string;
    copy: string;
}
export const NoAssembliesWarning: FC<NoAssembliesWarningProps> = ({ header, copy, ...props }) => (
    <Box
        {...props}
        sx={{
            padding: '0 12px',
            ...props.sx,
        }}
    >
        <Typography
            color="secondary"
            sx={{
                marginBottom: '8px',
            }}
            variant="h5"
        >
            {header}
        </Typography>
        <Typography color="secondary" variant="body2">
            {copy}
        </Typography>
    </Box>
);
