import { useState, ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

export interface UseFormElementPayload<T> {
    value?: string;
    onChange: (event: T extends HTMLSelectElement ? SelectChangeEvent : ChangeEvent<T>) => void;
    // Using $ so is not taken by native HTML components
    $setValue?: (value: string) => void;
    $hasChanged?: boolean;
}

const useFormElement = <T extends HTMLInputElement | HTMLSelectElement>(
    initialValue?: string
): UseFormElementPayload<T> => {
    const [value, $setValue] = useState<string | undefined>(initialValue);

    const onChange = (event: T extends HTMLSelectElement ? SelectChangeEvent : ChangeEvent<T>) => {
        $setValue(event.target.value);
    };

    return {
        value,
        $setValue,
        $hasChanged: initialValue !== value,
        onChange,
    };
};

export default useFormElement;
