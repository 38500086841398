import {
    borderCement,
    borderSection,
    colorProjectPanelBackgroundDark,
    colorProjectPanelHighlight,
    colorProjectPanelText,
    colorStatusError,
    colorTypographyDark,
    colorTypographySection,
    colorTypographybody,
} from '@/variables';
import styled from 'styled-components';

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
`;

export const PriceContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 2;
    padding: 0 1.125rem 2rem 2rem;
    overflow: hidden auto;
`;

export const PriceContentSection = styled.div`
    display: flex;
    display: -webkit-flex;

    flex-direction: column;
    -webkit-flex-direction: column;

    margin-top: 1.75rem;
`;

export const CostInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 2;
    margin-right: 3.125rem;
    padding-top: 1.75rem;
`;

export const CostInfoHeader = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    line-height: 3.625rem;
    margin: 0 0 2rem 0;
    color: ${colorTypographyDark};
`;

export const SingleDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1.5rem 0.75rem 1.5rem 1.5rem;
    border: ${borderCement};
`;

export const SelectableDetailContainer = styled(SingleDetailContainer)`
    cursor: pointer;

    &:focus-within {
        border: 1px solid ${colorProjectPanelHighlight};
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 13.75rem;
    padding-top: 1.75rem;
    border-radius: 0.25rem;
    line-height: 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;

    & ${SingleDetailContainer}:not(:first-child) {
        border-top: none;
    }

    & ${SingleDetailContainer}:first-child {
        border-radius: 0.25rem 0.25rem 0 0;
    }

    & ${SingleDetailContainer}:last-child {
        border-radius: 0 0 0.25rem 0.25rem;
    }
`;

export const DetailLabel = styled.p`
    font-size: 11px;
    font-weight: 600;
    color: ${colorTypographyDark};
    opacity: 0.6;
    letter-spacing: 0.08rem;
    margin: 0 0 0.5rem;
`;

export const ProjectPriceRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ProjectPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

export const ProjectPriceCurrency = styled.span<{ isPlaceholder: boolean }>`
    color: ${(props): string =>
        props.isPlaceholder ? colorTypographySection : colorProjectPanelText};
`;

export const ProjectPriceInput = styled.input`
    flex-grow: 2;
    background: transparent;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    color: ${colorProjectPanelText};
    border: none;
    outline: none;
    margin: -0.5rem 0;
    padding: 0;
    width: 100%;

    &::placeholder {
        color: ${colorTypographySection};
    }
`;

export const ProjectPriceEdit = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 1.625rem;
    padding: 0 0.5rem;
    margin-top: -0.625rem;
    border: ${borderCement};
    font-size: 0.875rem;
    font-weight: 600;
    color: ${colorProjectPanelHighlight};
`;

export const ProjectPriceActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.625rem 0 1.75rem;
    height: 5rem;
    background-color: ${colorProjectPanelHighlight};
    cursor: pointer;
`;

export const ProjectPriceActionMain = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ProjectPriceActionIconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 1.125rem;
    background: ${colorTypographybody};
`;

export const ProjectPriceActionText = styled.p`
    margin-left: 1.125rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: ${colorTypographybody};
`;

export const BalanceCalculatorNumber = styled.div<{ isTotal?: boolean; sign?: string }>`
    position: relative;
    margin: 0.75rem 0;
    color: ${(props): string => {
        if (!props.isTotal) {
            return 'inherit';
        }
        return props.sign === '-' ? colorStatusError : colorProjectPanelHighlight;
    }};

    &::before {
        content: ${(props): string => (props.sign ? `"${props.sign}"` : 'none')};
        position: absolute;
        width: 0;
        left: -1rem;
    }
`;

export const BalanceCalculatorAdditionLine = styled.hr`
    border: none;
    border-top: ${borderSection};
    opacity: 0.3;
    margin: 0.25rem 0;
    background: none;
`;

export const BalanceCalculatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.125rem 1.75rem;
    margin-top: 1rem;
    background-color: ${colorProjectPanelBackgroundDark};

    & ${BalanceCalculatorNumber}:nth-child(2) {
        margin-top: 0.25rem;
    }
`;

export const InfoInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    height: 4.125rem;
    padding: 0 0 1.5rem 0;
`;

export const InfoInputLabel = styled.label`
    margin-bottom: 0.325rem;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.06em;
    color: ${colorTypographyDark};
    opacity: 0.6;
`;

export const InfoInputInput = styled.input<{ hasPrefix?: boolean }>`
    height: 2.125rem;
    padding: 0.55rem 1.75rem 0.55rem
        ${(props): string => (props.hasPrefix ? '1.375rem' : '0.75rem')};
    font-family: 'proxima-nova', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.01em;
    background-color: ${colorProjectPanelBackgroundDark};
    border: none;
    border-radius: 0.25rem;
    color: ${colorProjectPanelText};
    z-index: 1;

    ::placeholder {
        color: ${colorTypographySection};
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px ${colorProjectPanelHighlight};
    }
`;

export const InfoInputPrefix = styled.span<{ isPlaceholder: boolean }>`
    width: 0;
    height: 0;
    margin-left: 0.75rem;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 35px;
    color: ${(props): string => (props.isPlaceholder ? colorTypographySection : 'inherit')};
    z-index: 2;
`;

export const InfoInputSuffix = styled.span`
    align-self: flex-end;
    width: 0;
    height: 0;
    z-index: 2;

    div.dots-loader {
        margin: 0.25rem 0 0 -1.5rem;
    }

    div.dots {
        background: ${colorProjectPanelText};
    }
`;

export const EstimationBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #EEF7FF;
    width: 13.75rem;

    .form-control{
        &.is-invalid{
            input {
                border: 0.0625rem solid #d63939;
            }
        }
    }

    .estimation-price-form-control{
        position: relative;
        padding: 1rem;
        box-sizing: border-box;

        label {
            font-size: 0.75rem;
            text-transform: uppercase;
        }

        span{
            &.caret-icon-down{
                -webkit-transform: rotate(90deg) translate(-0.2rem, -0.4rem);
                -moz-transform: rotate(90deg) translate(-0.2rem, -0.5rem);
                transform: rotate(90deg) translate(-0.2rem, -0.4rem);

                svg{
                    fill: #E08568;
                }
            }
        }

        input{
            position: relative;
            height: 2rem;
            width: 100%;
            background: #FFFFFF;
            color: #3D445C;
            font-weight: 500;
            font-size 0.875rem;
            border-radius: 4px;
            outline: none;
            border: none;
            margin-top: 0.625rem;
            padding: 0 .7rem;
            box-sizing: border-box;

            &[type="date"]{
                &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
        }
    }
`;

export const HoursPlaceholder = styled.span`
    position: absolute;
    right: 30px;
    color: #b1b4be;
    top: 3.2rem;
    font-size: 0.8125rem;
    font-weight: 600;
`;

export const EstimationBoxSubmit = styled.div`
    display: flex;
    justify-content: flex-end;

    padding: 0 1rem 1rem;

    button {
        cursor: pointer;
        color: #fff;
        background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
        border-radius: 4px;
        height: 2.25rem;
        padding: 0 1.5rem;
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.625rem;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        white-space: nowrap;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px solid #d7e1e5;
`;

export const ConfirmationButtons = styled.div`
    margin-top: 1.25rem;

    button {
        margin-right: 0.625rem;
    }
`;

export const InvalidFeedbackMessage = styled.small`
    display: block;
    font-size: 0.75rem;
    color: #d63939;
    margin-top: 0.3125rem;
`;

export const UpdatedValueText = styled.p`
    position: relative;
    margin-bottom: 1.25rem;

    span {
        margin-left: 0.3125rem;
        font-weight: 600;
    }
`;
