import { styled as styledByMui } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiChip from '@mui/material/Chip';
import MuiSvgIcon from '@mui/material/SvgIcon';
import MuiToolbar from '@mui/material/Toolbar';
import { ReactComponent as FullLogoV2Icon } from '@/assets/images/full-logo-v2.svg';

export const AppBar = styledByMui(MuiAppBar)({
    backgroundColor: 'black',
});

export const Toolbar = styledByMui(MuiToolbar)({
    display: 'flex',
    justifyContent: 'space-between',
});

export const LogoIcon = styledByMui(FullLogoV2Icon)({
    height: 30,
    verticalAlign: 'middle',
    width: 'auto',
});

export const Chip = styledByMui(MuiChip)({
    borderColor: 'white',
    color: 'white',
});

export const SvgIcon = styledByMui(MuiSvgIcon)({
    color: 'white',
});
