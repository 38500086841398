import React, { FC } from 'react';

import { ProjectInfoBody, ProjectInfoContainer, ProjectInfoTitle } from './styled';
import { ProjectInfoNumberInput } from './ProjectInfoInput/ProjectInfoNumberInput';
import { ProjectInfoDropdownInput } from './ProjectInfoInput/ProjectInfoDropdownInput';
import { ProjectInfoLocationInput } from './ProjectInfoInput/ProjectInfoLocationInput';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { WageType } from '@/common/types';
import { readableWageTypeToWageType, wageTypeToReadable } from '@/common/utils/helpers';

const COPY = {
    title: 'Project info',
    textInputPlaceholder: 'Enter total square feet',
    totalSquareFootUnderRoofTitle: 'Total SF',
    totalSquareFootLivableTitle: 'Total usable SF',
    wageTypeTitle: 'Wage type',
    locationTitle: 'Location',
};

const WAGE_TYPE_DROPDOWN_OPTIONS = Object.values(WageType).map((wt) => wageTypeToReadable(wt));

interface ProjectInfoProps {
    className?: string;
}
export const ProjectInfo: FC<ProjectInfoProps> = ({ className }) => {
    const { projectInfo, setProjectInfo } = useEstimationProject();

    return (
        <div className={className}>
            <ProjectInfoContainer
                isCollapsedByDefault={true}
                nonCollapsible={false}
                contents={
                    <ProjectInfoBody>
                        {/** Square footage inputs */}
                        <ProjectInfoNumberInput
                            label={COPY.totalSquareFootUnderRoofTitle}
                            placeholder={COPY.textInputPlaceholder}
                            value={projectInfo.squareFeetUnderRoof}
                            updateProjectInfo={(newTotalSquareUnderRoof): void => {
                                setProjectInfo((oldProjectInfo) => ({
                                    ...oldProjectInfo,
                                    squareFeetUnderRoof: newTotalSquareUnderRoof,
                                }));
                            }}
                        />
                        <ProjectInfoNumberInput
                            label={COPY.totalSquareFootLivableTitle}
                            placeholder={COPY.textInputPlaceholder}
                            value={projectInfo.squareFeetTotalLivable}
                            updateProjectInfo={(newTotalSquareFeetLivable): void => {
                                setProjectInfo((oldProjectInfo) => ({
                                    ...oldProjectInfo,
                                    squareFeetTotalLivable: newTotalSquareFeetLivable,
                                }));
                            }}
                        />

                        {/** Project info dropdowns */}
                        <ProjectInfoDropdownInput
                            label={COPY.wageTypeTitle}
                            value={wageTypeToReadable(projectInfo.wageType)}
                            options={WAGE_TYPE_DROPDOWN_OPTIONS}
                            onChangeOption={(newWageTypeReadable): void => {
                                setProjectInfo((oldProjectInfo) => ({
                                    ...oldProjectInfo,
                                    wageType:
                                        readableWageTypeToWageType(newWageTypeReadable) ??
                                        WageType.NON_UNION,
                                }));
                            }}
                        />
                        <ProjectInfoLocationInput
                            label={COPY.locationTitle}
                            value={projectInfo.projectLocation?.formattedAddress || ''}
                            initialFormattedAddress={
                                projectInfo.projectLocation?.formattedAddress ?? undefined
                            }
                            updateProjectInfo={(projectLocation): void => {
                                setProjectInfo((oldProjectInfo) => ({
                                    ...oldProjectInfo,
                                    projectLocation,
                                }));
                            }}
                        />
                    </ProjectInfoBody>
                }
            >
                <ProjectInfoTitle>{COPY.title}</ProjectInfoTitle>
            </ProjectInfoContainer>
        </div>
    );
};
