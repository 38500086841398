import React, { FC, useState } from 'react';
import { Mode } from '@/theme/Mode';
import ScopedCssBaseLine from '@mui/material/ScopedCssBaseline';
import { Env } from '@/common/env';
import { useSetupIntentQuery } from '@/graphql';
import { LoadingPage } from '@/views/LoadingPage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import to from 'await-to-js';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useNotifications } from '@/contexts/Notifications';
import TestimonialBackground from '@/assets/images/testimonial-background.png';
import TestimonialProfile from '@/assets/images/testimonial-profile.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { PaymentSetupStatusValues, QueryParams } from '@/common/queryParams';

export const COPY = {
    creditCardTitle: 'Enter Your Credit Card to Get Free Access to 1build for 14 Days',
    creditCardSubTitle:
        'Adding your card enables you to get personalized onboarding with our specialists and offers full access to our network of on-demand expert estimators.',
    creditCardSubTitle2:
        'Your card will not be charged until 14 days. After 14 days, you will be automatically enrolled in the monthly Professional plan ($299/month) with unlimited access to rapid estimates, takeoffs, and the estimator network. You can cancel anytime on your 1build profile, and you will be notified 24 hours before you are charged.',
    continueButton: 'Continue',
    testimonialsTitle: 'Why builders love 1build',
    testimonial1: '"1build takes care of my estimates, allowing me to support the whole company."',
    testimonial2:
        '"It took only a couple of days to get our first estimate developed, so we cut our estimate time down from weeks to just days."',
    testimonial3:
        '"I was able to win a $3.7M project, thanks to 1build. I could not have done it alone."',
    errorMessage: 'There was a problem submitting your payment information. Please try again',
    testimonialDescription:
        '“It took only a couple of days to get our first estimate developed, so we cut our estimate time from weeks to just days.”',
    testimonialAuthor: 'MADDY ELLITHORPE, TBP HOMES OF RALEIGH',
};

interface PaymentSetupFormProps {
    displayText?: boolean;
    returnURL?: string;
}

export const PaymentSetupForm: FC<PaymentSetupFormProps> = ({
    displayText = true,
    returnURL = `${Env.deploymentURL}?${QueryParams.PaymentSetupStatus}=${PaymentSetupStatusValues.Succeed}`,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const { addNotification } = useNotifications();
    const [loading, setLoading] = useState(false);
    const [elementsLoading, setElementsLoading] = useState(true);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        const [error, result] = await to(
            stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: returnURL,
                },
            })
        );

        if (result?.error) {
            addNotification(
                {
                    title: 'Error',
                    content: String(result.error.message),
                },
                'error'
            );
        } else if (error) {
            addNotification(
                {
                    title: 'Error',
                    content: Env.tier.isDevelopment ? String(error) : COPY.errorMessage,
                },
                'error'
            );
        }

        setLoading(false);
    };

    if (!stripe) {
        return <CircularProgress />;
    }

    return (
        <Stack spacing={5}>
            {displayText && (
                <>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="h2"
                    >
                        {COPY.creditCardTitle}
                    </Typography>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="body1"
                    >
                        {COPY.creditCardSubTitle}
                    </Typography>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="body1"
                    >
                        {COPY.creditCardSubTitle2}
                    </Typography>
                </>
            )}

            {elementsLoading && (
                <Stack direction="row" justifyContent="center">
                    <CircularProgress />
                </Stack>
            )}
            <PaymentElement
                options={{
                    readOnly: loading,
                }}
                onReady={() => setElementsLoading(false)}
            />
            <Button
                disabled={loading || elementsLoading}
                size="large"
                onClick={handleSubmit}
                sx={{
                    alignSelf: 'flex-end',
                }}
                variant="contained"
            >
                {COPY.continueButton}
            </Button>
        </Stack>
    );
};

export const PaymentSetup: FC = () => {
    const [stripePromise] = useState(loadStripe(Env.stripePublicKey));

    const { data } = useSetupIntentQuery();

    if (!data) {
        return <LoadingPage />;
    }

    return (
        <ScopedCssBaseLine>
            <Mode variant="light">
                <Grid sx={{ height: 'calc(100vh - 64px)' }} container component="main">
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={(props) => <Paper {...props} square />}
                    >
                        <Stack sx={{ padding: 5, height: '100%' }} justifyContent="center">
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    clientSecret: data.setupIntent.clientSecret,
                                    appearance: {
                                        theme: 'stripe',
                                        labels: 'floating',

                                        variables: {
                                            spacingGridRow: '40px',
                                        },
                                    },
                                }}
                            >
                                <PaymentSetupForm />
                            </Elements>
                        </Stack>
                    </Grid>
                    <Grid item xs={false} sm={4} md={7}>
                        <Mode variant="dark">
                            <Paper
                                square
                                sx={{
                                    height: '100%',
                                    backgroundImage: `url(${TestimonialBackground})`,
                                    backgroundPosition: 'right',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <Stack
                                    sx={{ padding: 10, height: '100%' }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Card sx={{ textAlign: 'center' }}>
                                        <CardContent>
                                            <Box sx={{ mx: 2 }}>
                                                <Typography variant="h2" sx={{ my: 3 }}>
                                                    {COPY.testimonialDescription}
                                                </Typography>
                                                <Typography variant="body2" sx={{ mb: 1 }}>
                                                    {COPY.testimonialAuthor}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <Box sx={{ height: '5rem' }} />
                                    </Card>
                                    <Avatar
                                        alt="testimonial profile picture"
                                        src={TestimonialProfile}
                                        sx={{ width: 150, height: 150, marginTop: '-5rem' }}
                                    />
                                </Stack>
                            </Paper>
                        </Mode>
                    </Grid>
                </Grid>
            </Mode>
        </ScopedCssBaseLine>
    );
};
