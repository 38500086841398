import React, { FC, useCallback } from 'react';
import moment from 'moment';

import { DiscussionsPanel } from './DiscussionsPanel';
import { Container, DownPointer, DueDate, Header, Name, PointerButton, Price } from './styled';
import { ProjectDetailsComponentProps } from '../context';
import { LocationDropdown } from '@/components/Project/LocationDropdown';
import {
    useUpdateProjectLocation,
    UseUpdateProjectLocationPayload,
} from '@/components/Project/LocationDropdown/hooks/useUpdateProjectLocation';
import { formatUSD } from '@/common/utils/helpers';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';

import { ReactComponent as CalendarV2 } from '@/assets/icons/calendar-v2.svg';
import { ReactComponent as TiltedDollar } from '@/assets/icons/tilted-dollar.svg';
import { useChat } from '@/common/hooks/useChat';
import { DatabaseProjectStatus } from '@/common/types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export const Discussion: FC<ProjectDetailsComponentProps> = ({ useProjectDetails }) => {
    // State
    /*-------------------*/
    const {
        project,
        projectLocation,
        setProjectLocation,
        isLocationDropdownOpen,
        setIsLocationDropdownOpen,
        isInfoPanelOpen,
        openInfoPanel,
        pricingAssignment,
    } = useProjectDetails();
    const {
        data: {
            user: { roles },
        },
    } = useUser();

    const { creditsRefundedEventsResult } = useChat(project);

    // Helpers
    /*-------------------*/
    const userIs = (role: IUserRole): boolean => roles.includes(role);

    const priceUsdCents = pricingAssignment?.priceUsdCents;

    const submitNewProjectLocation = useUpdateProjectLocation({
        projectId: project.id,
        setProjectLocation,
    });

    const submit: UseUpdateProjectLocationPayload = useCallback(
        (...args) => {
            submitNewProjectLocation(...args);
            setIsLocationDropdownOpen(false);
        },
        [submitNewProjectLocation]
    );

    return (
        <Container>
            <LocationDropdown
                isOpen={isLocationDropdownOpen}
                initialAddress={projectLocation?.formattedAddress ?? undefined}
                submit={submit}
            />

            <Header>
                <PointerButton tabIndex={0} isActive={isInfoPanelOpen} onClick={openInfoPanel}>
                    <DownPointer />
                </PointerButton>

                {!project.isSaas && (
                    <DueDate>
                        <CalendarV2 />
                        Due {moment(project.bidsDueDate).format('MMMM DD')}
                    </DueDate>
                )}

                {!project.isSaas &&
                (userIs(IUserRole.Admin) || userIs(IUserRole.Builder)) &&
                project.status === DatabaseProjectStatus.CANCELED ? (
                    <Box sx={{ gridArea: 'price', display: 'flex', alignItems: 'center' }}>
                        <Chip label={project.status} variant="outlined" color="error" />
                    </Box>
                ) : (
                    !creditsRefundedEventsResult.fetching && (
                        <Price isActive={!!priceUsdCents}>
                            <TiltedDollar />
                            {creditsRefundedEventsResult.data?.eventTypes?.nodes[0]
                                .eventsByEventTypeName?.nodes[0]?.projectId === project.id
                                ? formatUSD(0)
                                : priceUsdCents
                                ? formatUSD(priceUsdCents)
                                : 'Price pending...'}
                        </Price>
                    )
                )}

                <Name>{project.name}</Name>
            </Header>

            <DiscussionsPanel useProjectDetails={useProjectDetails} />
        </Container>
    );
};
