import React, { FC } from 'react';

import { IntersectionObserverProps } from 'react-intersection-observer';
import { ListProps } from '@mui/material/List';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { ListWithInfinitePagination } from './ListWithInfinitePagination';
import { ListWithLoadMoreButtonPagination } from './ListWithLoadMoreButtonPagination';

export interface ListWithPaginationProps extends ListProps {
    hasNextPage?: boolean;
    inViewProps?: Omit<IntersectionObserverProps, 'children'>;
    isLoadingNextPage?: boolean;
    loadMoreButtonProps?: ListItemButtonProps;
    variant: 'infinite' | 'manual';
}

export const ListWithPagination: FC<ListWithPaginationProps> = ({ variant, ...props }) => {
    return variant === 'infinite' ? (
        <ListWithInfinitePagination {...props} />
    ) : (
        <ListWithLoadMoreButtonPagination {...props} />
    );
};
