import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import { ReactComponent as ExportIcon } from '@/assets/icons/16/export.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/32/checkmark.svg';
import { ReactComponent as PublishIcon } from '@/assets/icons/cloud-upload.svg';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { useToggle } from '@/common/hooks/useToggle';
import { ExportModal } from '@/components/Estimate/ExportModal';
import { useEstimateLayout } from '@/components/Estimate/hooks/useEstimateLayout';
import { PublishModal } from '@/components/PublishModal';
import { ShareEstimateModal } from '@/components/ShareEstimateModal';
import { usePublish } from '@/components/projects/ProjectDetails/ProjectToolbars/hooks/usePublish';
import { EllipsisButton } from '@/components/ui/containers/ProjectsToolbar/EllipsisButton';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import React, { FC, useState } from 'react';
import { NavBarTabs } from './NavBarTabs';

import {
    isAdministratorUser,
    isBuilderUser,
    isEstimatorUser,
    isProjectComplete,
    isProjectPendingEstimator,
    isProjectSetupComplete,
    isSaasProject,
    isDefaultRolesUser,
} from '@/views/Projects/utils';

const COPY = {
    export: 'Export',
    share: 'Share',
    exportTakeoffSuccessTitle: 'Takeoff exported successfully',
    exportTakeoffFailureTitle: 'Takeoff export failed',
    exportTakeoffSuccessMessage: 'Click here to view the file.',
    markComplete: 'Mark complete',
    projectComplete: 'Complete',
    publishProject: 'Publish project',
    projectPublished: 'Project published',
};

export const NavBar: FC<BoxProps> = ({ ...props }) => {
    const {
        features: { shareV2, builderEstimateView },
    } = useFeatures();

    const {
        data: { user },
    } = useUser();

    const { isPrintView } = useEstimateLayout();
    const { project } = useProjectFromParams();

    const [isShareEstimateModalOpen, setIsShareEstimateModalOpen] = useToggle();
    const [isExportFilesModalOpen, setIsExportFilesModalOpen] = useToggle();
    const [isPublishModalOpen, setIsPublishModalOpen] = useToggle();
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

    const { publish, unpublish } = usePublish({ project: project ?? undefined });

    const isAdministrator = isAdministratorUser(user);
    const isEstimator = isEstimatorUser(user);
    const isBuilder = isBuilderUser(user);

    const isProjectCompleted = isProjectComplete(project);

    const hasBuilderEstimateViewFeature = builderEstimateView(project?.created);

    // https://coda.io/d/1build-Engineering_d-21lT3iH7u/Frontend-Masthead-Buttons_suS-B

    return (
        <Box
            {...props}
            sx={{
                height: '48px',
                ...props.sx,
            }}
        >
            {(() => {
                if (!project) {
                    return null;
                }

                return (
                    <Stack direction="row" justifyContent="space-between">
                        <NavBarTabs project={project} />
                        <Stack alignItems="center" direction="row" spacing="8px">
                            {(() => {
                                if (hasBuilderEstimateViewFeature) {
                                    if (
                                        (isEstimator && !isBuilder && !isAdministrator) ||
                                        (isEstimator && isAdministrator)
                                    ) {
                                        if (
                                            !isProjectSetupComplete(project) ||
                                            isProjectPendingEstimator(project)
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing="8px"
                                            >
                                                <Button
                                                    disabled={isProjectCompleted}
                                                    size="small"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (isProjectCompleted) {
                                                            unpublish();
                                                        } else {
                                                            setIsPublishModalOpen();
                                                        }
                                                    }}
                                                    startIcon={
                                                        <SvgIcon viewBox="0 0 32 32">
                                                            {isProjectCompleted ? (
                                                                <CheckmarkIcon />
                                                            ) : (
                                                                <PublishIcon
                                                                    width={32}
                                                                    height={32}
                                                                />
                                                            )}
                                                        </SvgIcon>
                                                    }
                                                >
                                                    {isProjectCompleted
                                                        ? COPY.projectPublished
                                                        : COPY.publishProject}
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={
                                                        <SvgIcon
                                                            sx={{ width: '16px', height: '16px' }}
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <ExportIcon />
                                                        </SvgIcon>
                                                    }
                                                    onClick={setIsExportFilesModalOpen}
                                                >
                                                    {COPY.export}
                                                </Button>
                                            </Stack>
                                        );
                                    }

                                    if (isBuilder || isDefaultRolesUser(user)) {
                                        return (
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing="8px"
                                            >
                                                {isDefaultRolesUser(user) &&
                                                    isSaasProject(project) && (
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={() => {
                                                                if (isProjectCompleted) {
                                                                    unpublish();
                                                                } else {
                                                                    publish();
                                                                }
                                                            }}
                                                            startIcon={
                                                                <SvgIcon viewBox="0 0 32 32">
                                                                    <CheckmarkIcon />
                                                                </SvgIcon>
                                                            }
                                                        >
                                                            {isProjectCompleted
                                                                ? COPY.projectComplete
                                                                : COPY.markComplete}
                                                        </Button>
                                                    )}
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                    startIcon={
                                                        <SvgIcon
                                                            sx={{
                                                                width: '16px',
                                                                height: '16px',
                                                            }}
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <ExportIcon />
                                                        </SvgIcon>
                                                    }
                                                    onClick={(): void => {
                                                        if (shareV2) {
                                                            setIsShareEstimateModalOpen();
                                                        } else {
                                                            setIsExportModalOpen(true);
                                                        }
                                                    }}
                                                >
                                                    {shareV2 ? COPY.share : COPY.export}
                                                </Button>
                                                <EllipsisButton />
                                            </Stack>
                                        );
                                    }
                                } else {
                                    return (
                                        <Stack alignItems="center" direction="row" spacing="8px">
                                            {isDefaultRolesUser(user) && isSaasProject(project) && (
                                                <Button
                                                    disabled={isProjectCompleted}
                                                    size="small"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (isProjectCompleted) {
                                                            unpublish();
                                                        } else {
                                                            publish();
                                                        }
                                                    }}
                                                    startIcon={
                                                        <SvgIcon viewBox="0 0 32 32">
                                                            <CheckmarkIcon />
                                                        </SvgIcon>
                                                    }
                                                >
                                                    {isProjectCompleted
                                                        ? COPY.projectComplete
                                                        : COPY.markComplete}
                                                </Button>
                                            )}
                                            <Button
                                                size="small"
                                                color="secondary"
                                                variant="outlined"
                                                startIcon={
                                                    <SvgIcon
                                                        sx={{ width: '16px', height: '16px' }}
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <ExportIcon />
                                                    </SvgIcon>
                                                }
                                                onClick={(): void => {
                                                    if (shareV2) {
                                                        setIsShareEstimateModalOpen();
                                                    } else {
                                                        setIsExportModalOpen(true);
                                                    }
                                                }}
                                            >
                                                {shareV2 ? COPY.share : COPY.export}
                                            </Button>
                                            <EllipsisButton />
                                        </Stack>
                                    );
                                }
                            })()}
                        </Stack>
                        {isShareEstimateModalOpen && !isPrintView && project && (
                            <ShareEstimateModal
                                isOpen={isShareEstimateModalOpen}
                                close={(): void => setIsShareEstimateModalOpen()}
                            />
                        )}
                        {isExportModalOpen && !isPrintView && project && (
                            <ExportModal close={(): void => setIsExportModalOpen(false)} />
                        )}
                        {hasBuilderEstimateViewFeature && (
                            <>
                                <ShareEstimateModal
                                    isOpen={isExportFilesModalOpen}
                                    close={setIsExportFilesModalOpen}
                                    isExportOnly
                                    headerText="Export estimate"
                                />
                                <PublishModal
                                    isModalVisible={isPublishModalOpen}
                                    close={setIsPublishModalOpen}
                                    onPublish={publish}
                                />
                            </>
                        )}
                    </Stack>
                );
            })()}
        </Box>
    );
};
