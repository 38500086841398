import { Editor, Element, Point, Range, Transforms } from 'slate';

import { emptySlateParagraph } from './deserialize';

const shortcuts: Map<string, Element> = new Map([
    [
        '*',
        {
            type: 'list-item',
            children: [],
        },
    ],
    [
        '-',
        {
            type: 'list-item',
            children: [],
        },
    ],
    [
        '+',
        {
            type: 'list-item',
            children: [],
        },
    ],
    [
        '1.',
        {
            type: 'list-item',
            children: [],
        },
    ],
    [
        '>',
        {
            type: 'block-quote',
            children: [emptySlateParagraph],
        },
    ],
    [
        '#',
        {
            type: 'heading-one',
            children: [],
        },
    ],
    [
        '##',
        {
            type: 'heading-two',
            children: [],
        },
    ],
    [
        '###',
        {
            type: 'heading-three',
            children: [],
        },
    ],
]);

// Adapted from the official "markdown-shortcuts" example
// https://github.com/ianstormtaylor/slate/blob/master/site/examples/markdown-shortcuts.tsx
/* eslint-disable-next-line max-len */
/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types */
export const withShortcuts = (editor: Editor) => {
    const { deleteBackward, insertText } = editor;

    editor.insertText = (text): void => {
        const { selection } = editor;

        if (text === ' ' && selection && Range.isCollapsed(selection)) {
            const { anchor } = selection;
            const block = Editor.above(editor, {
                match: (n) => Editor.isBlock(editor, n),
            });
            // Don't allow shortcuts inside of a list (disable nested lists).
            if (
                block !== undefined &&
                Element.isElement(block[0]) &&
                block[0].type === 'list-item'
            ) {
                insertText(text);
                return;
            }
            const path = block ? block[1] : [];
            const start = Editor.start(editor, path);
            const range = { anchor, focus: start };
            const beforeText = Editor.string(editor, range);

            const shortcutOpts = shortcuts.get(beforeText);
            if (shortcutOpts) {
                const { type, children } = shortcutOpts;
                Transforms.select(editor, range);
                Transforms.delete(editor);
                Transforms.setNodes(
                    editor,
                    { type },
                    {
                        match: (n) => Editor.isBlock(editor, n),
                    }
                );

                if (children.length > 0) {
                    Transforms.insertNodes(editor, children, { at: start.path });
                }

                if (type === 'list-item') {
                    Transforms.wrapNodes(
                        editor,
                        {
                            type: beforeText === '1.' ? 'numbered-list' : 'bulleted-list',
                            children: [],
                        },
                        {
                            match: (n) => Element.isElement(n) && n.type === 'list-item',
                        }
                    );
                }

                // If the shortcut was triggered inside of a block quote,
                // it will have been deleted so we want to re-wrap it.
                if (block && Element.isElement(block[0]) && block[0].type === 'block-quote') {
                    let wrapTarget = type;
                    if (type === 'list-item') {
                        wrapTarget = beforeText === '1.' ? 'numbered-list' : 'bulleted-list';
                    }
                    Transforms.wrapNodes(
                        editor,
                        {
                            type: 'block-quote',
                            children: [],
                        },
                        {
                            match: (n) => Element.isElement(n) && n.type === wrapTarget,
                        }
                    );
                }
                return;
            }
        }

        insertText(text);
    };

    editor.deleteBackward = (unit): void => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const match = Editor.above(editor, {
                match: (n) => Editor.isBlock(editor, n),
            });

            if (match) {
                const [block, blockPath] = match;
                const start = Editor.start(editor, blockPath);
                const [parent, parentPath] = Editor.parent(editor, blockPath);

                // When a paragraph node is wrapped by an empty block quote,
                // unwrap that paragraph out of the quote. If it's wrapped by a
                // non-empty block quote, lift the paragraph instead.
                if (
                    Element.isElement(parent) &&
                    parent.type === 'block-quote' &&
                    Element.isElement(block) &&
                    block.type === 'paragraph' &&
                    Point.equals(selection.anchor, start)
                ) {
                    if (Editor.string(editor, parentPath) === '') {
                        Transforms.unwrapNodes(editor, { at: parentPath });
                    } else {
                        Transforms.liftNodes(editor, { at: blockPath });
                    }
                    return;
                }

                if (
                    !Editor.isEditor(block) &&
                    Element.isElement(block) &&
                    block.type !== 'paragraph' &&
                    Point.equals(selection.anchor, start)
                ) {
                    Transforms.setNodes(editor, { type: 'paragraph' });

                    if (block.type === 'list-item') {
                        Transforms.unwrapNodes(editor, {
                            match: (n) =>
                                !Editor.isEditor(n) &&
                                Element.isElement(n) &&
                                typeof n.type === 'string' &&
                                ['bulleted-list', 'numbered-list'].includes(n.type),
                            split: true,
                        });
                    }
                    return;
                }
            }

            deleteBackward(unit);
        }
    };

    return editor;
};
