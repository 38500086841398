import { useObservable } from '@/common/hooks/useObservable';
import {
    PointSelectionState,
    newPointSelectionStateObservable,
} from '@/components/takeoff/observables/generators';

export const usePointSelectionState = (pointID: string, countID: string) =>
    useObservable(
        newPointSelectionStateObservable(pointID, countID),
        PointSelectionState.Unselected
    );
