/*
 * Modal is a modal appearing over the admin dashboard.
 */
import React, { FC, useEffect, useState } from 'react';

import { Card } from './Card';
import { OpenModalPayload, subscribeToOpenedModalObservable } from './observables';
import { ModalContainer } from './styled';

export const Modal: FC = () => {
    const [openModalPayload, setOpenModalPayload] = useState<OpenModalPayload | null>(null);

    useEffect(() => {
        const subscription = subscribeToOpenedModalObservable(setOpenModalPayload);
        return (): void => subscription.unsubscribe();
    }, []);

    if (openModalPayload === null) {
        return <></>;
    }

    return (
        <ModalContainer>
            <Card onClose={(): void => setOpenModalPayload(null)} {...openModalPayload} />
        </ModalContainer>
    );
};
