/* eslint-disable max-len */
import Typography from '@mui/material/Typography';
import { CardProps } from '@mui/material/Card';
import React, { FC } from 'react';
import { formatters, toDollarAmount } from '@/common/currency';
import {
    getPlanVariantByPriceId,
    hasSelfStarterSubscription,
} from '@/components/pricing/PricingPage/utils';
import { Plan } from '@/components/pricing/PricingPage/types';
import { ITeamBillingFragment } from '@/graphql';
import { EstimatesCardLocked } from './EstimatesCardLocked';
import { EstimatesTable } from './EstimatesTable';
import {
    Card,
    CardHeading,
    EstimatesAllowedHeading,
    EstimatesSubmittedHeading,
    CreditBalanceHeading,
    Section,
    SectionGroup,
} from './styled';

interface Props extends CardProps {
    plans?: Plan[];
    team?: ITeamBillingFragment;
}

export const EstimatesCard: FC<Props> = ({ plans, team, ...props }) => {
    if (hasSelfStarterSubscription(plans, team)) {
        return <EstimatesCardLocked />;
    }

    const planVariant = getPlanVariantByPriceId(plans, team?.subscription?.priceID);

    const projects = team?.projects?.edges?.map((edge) => edge.node);

    return (
        <Card elevation={8} {...props}>
            <Section>
                <CardHeading variant="overline">Paid expert estimates</CardHeading>
                <EstimatesAllowedHeading variant="h3">
                    {planVariant?.estimates === undefined
                        ? 'Unlimited'
                        : `${planVariant.estimates} allowed per year`}
                </EstimatesAllowedHeading>
                <Typography variant="body1">
                    {planVariant?.estimates === undefined
                        ? 'You can choose to have one of our expert estimators do the work for you.'
                        : 'For an additional fee, you can choose to have one of our expert estimators do the work for you.'}
                </Typography>
            </Section>
            <SectionGroup>
                <Section>
                    <EstimatesSubmittedHeading variant="overline">
                        Estimates submitted
                    </EstimatesSubmittedHeading>
                    <Typography variant="body1">
                        {planVariant?.estimates === undefined
                            ? projects?.length
                            : `${String(projects?.length)} of ${planVariant.estimates}`}
                    </Typography>
                </Section>
                {team?.balance?.usdCents !== undefined && team?.balance.usdCents !== 0 && (
                    <Section>
                        <CreditBalanceHeading variant="overline">
                            Credit Balance
                        </CreditBalanceHeading>
                        <Typography variant="body1">
                            {formatters.usd().format(toDollarAmount(team?.balance?.usdCents))}
                        </Typography>
                    </Section>
                )}
            </SectionGroup>
            <EstimatesTable team={team} />
        </Card>
    );
};
