/*
 * Card is the modal card appearing over the admin dashboard.
 */
import React, { FC, useEffect, useRef, useState } from 'react';

import { Button } from './Button';
import { OpenModalPayload } from './observables';
import {
    ModalBodyText,
    ModalButtonsContainer,
    ModalCard,
    ModalColorStripe,
    ModalContent,
    ModalHeader,
} from './styled';

import { eventWithinRef } from '@/common/utils/helpers';
import { Bugsnag } from '@/components/app/Bugsnag';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useNotifications } from '@/contexts/Notifications';

export interface CardProps extends OpenModalPayload {
    onClose: () => void;
}

export const Card: FC<CardProps> = ({ accentColor, title, body, buttons, onClose }) => {
    const { addNotification } = useNotifications();

    const [loading, setLoading] = useState(false);

    const cardRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent): void => {
            if (e?.defaultPrevented) {
                return;
            }
            e.preventDefault();
            if (!loading && !eventWithinRef(e, cardRef)) {
                onClose();
            }
        };
        document.addEventListener('mouseup', handleOutsideClick, true);
        return (): void => document.removeEventListener('mouseup', handleOutsideClick, true);
    }, [cardRef, loading]);

    const handleButtonClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        handler?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    ): void => {
        if (!handler) {
            onClose();
            return;
        }
        setLoading(true);
        Promise.resolve(handler(e))
            .catch((err) => {
                addNotification({ title: 'Error', content: String(err) }, 'error');
                Bugsnag?.notify(err);
            })
            .finally(onClose);
    };

    const renderModalButtons = (): JSX.Element => {
        if (loading) {
            return <SpinnerLoader />;
        }
        return (
            <ModalButtonsContainer>
                {buttons.map(
                    ({ onClick, ...button }, idx): JSX.Element => (
                        <Button
                            {...button}
                            key={idx}
                            onClick={(e): void => handleButtonClick(e, onClick)}
                        />
                    )
                )}
            </ModalButtonsContainer>
        );
    };

    return (
        <ModalCard ref={cardRef} accentColor={accentColor}>
            <ModalColorStripe />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBodyText>{body}</ModalBodyText>
                {renderModalButtons()}
            </ModalContent>
        </ModalCard>
    );
};
