import React, { FC, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormValidate } from './hooks/useFormValidate';
import { useHandleSignupUser } from './hooks/useHandleSignupUser';
import { useSetOnboardingStorage } from './hooks/useSetOnboardingStorage';
import { onClickAndEnterKey } from '@/common/utils/eventHandlers';
import { formatPhoneNumber } from '@/common/utils/helpers';
import { useUser } from '@/contexts/User';
import { IUserBusiness } from '@/graphql';
import { useGetQueryParams } from '@/common/queryParams';
import { TextFieldWithLabel } from '@/components/ui/inputs/TextFieldWithLabel';

const COPY = {
    fieldNames: {
        email: 'Work email',
        companyName: 'Company Name',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        businessType: 'What best describes you?',
        bidValue: 'What is your typical bid value?',
        password: 'Password',
    },
    placeholders: {
        email: 'name@email.com',
        companyName: 'Best Company in the World',
        firstName: 'First name',
        lastName: 'Last name',
        phone: '(555) 555-5555',
        password: '8+ characters',
        businessType: 'Enter your business type',
        bidValue: 'Your typical bid value',
    },
    chooseOne: 'Choose one',
    startTrial: 'Next Step',
};

const BID_VALUE_OPTIONS = [
    {
        label: '$0-$90K',
        value: '$0-$90K',
    },
    {
        label: '$90-$200K',
        value: '$90-200K',
    },
    {
        label: '$200-$500K',
        value: '$200-500k',
    },
    {
        label: '$500K+',
        value: '$500K+',
    },
];

const BUSINESS_TYPE_DROPDOWN_OPTIONS = {
    [IUserBusiness.GeneralContractor]: 'General Contractor',
    [IUserBusiness.SubcontractorMultiTrade]: 'Subcontractor (Multi-Trade)',
    [IUserBusiness.SubcontractorSingleTrade]: 'Subcontractor (Single-Trade)',
    [IUserBusiness.Developer]: 'Developer',
    [IUserBusiness.Supplier]: 'Supplier',
    [IUserBusiness.DesignBuild]: 'Design + Build',
    [IUserBusiness.Homeowner]: 'Homeowner',
    [IUserBusiness.Handyman]: 'Handyman',
    [IUserBusiness.Other]: 'Other',
};

export interface SignupForm {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    companyName: string;
    bidValue: string;
    businessType: string;
}

const defaultFormValues = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    companyName: '',
    bidValue: '',
    businessType: '',
};

export const SignUpForm: FC = () => {
    const {
        loading: { signUpInProgress },
    } = useUser();
    const [signupForm, setSignupForm] = useState<SignupForm>(defaultFormValues);

    const { getQueryParamByName } = useGetQueryParams();

    const { formFieldErrorMessages, updateValidationResults, validationResults } =
        useFormValidate(signupForm);

    const { handleSignupUser } = useHandleSignupUser({
        formFieldErrorMessages,
        signupForm,
        updateValidationResults,
    });

    // Set localStorage with any onboarding querystring parameter
    useSetOnboardingStorage();

    return (
        <Stack spacing={3}>
            <TextFieldWithLabel
                id="email"
                autoComplete="email"
                disabled={signUpInProgress}
                label={COPY.fieldNames.email}
                placeholder={COPY.placeholders.email}
                value={signupForm.email}
                onChange={(event) =>
                    setSignupForm((oldSignupForm) => ({
                        ...oldSignupForm,
                        email: event.target.value,
                    }))
                }
                error={!!validationResults.email}
                helperText={validationResults.email}
            />

            {!getQueryParamByName('invite_id') && (
                <TextFieldWithLabel
                    id="company-name"
                    autoComplete="organization"
                    disabled={signUpInProgress}
                    label={COPY.fieldNames.companyName}
                    placeholder={COPY.placeholders.companyName}
                    value={signupForm.companyName}
                    onChange={(event) =>
                        setSignupForm((oldSignupForm) => ({
                            ...oldSignupForm,
                            companyName: event.target.value,
                        }))
                    }
                    error={!!validationResults.companyName}
                    helperText={validationResults.companyName}
                />
            )}

            <TextFieldWithLabel
                id="phone"
                disabled={signUpInProgress}
                autoComplete="tel-national"
                label={COPY.fieldNames.phone}
                placeholder={COPY.placeholders.phone}
                value={signupForm.phone}
                onChange={(event) => {
                    const trimmedPhoneNumber = event.target.value
                        .trimLeft()
                        .replace(/[^\d]/, '')
                        .replace(/\D/g, '');

                    if (trimmedPhoneNumber.length > 10) {
                        return;
                    }

                    setSignupForm((oldSignupForm) => ({
                        ...oldSignupForm,
                        phone: formatPhoneNumber(trimmedPhoneNumber),
                    }));
                }}
                error={!!validationResults.phone}
                helperText={validationResults.phone}
            />

            <Stack direction="row" spacing={3}>
                <TextFieldWithLabel
                    id="first-name"
                    disabled={signUpInProgress}
                    autoComplete="given-name"
                    label={COPY.fieldNames.firstName}
                    placeholder={COPY.placeholders.firstName}
                    value={signupForm.firstName}
                    onChange={({ target: { value } }) =>
                        setSignupForm((oldSignupForm) => ({
                            ...oldSignupForm,
                            firstName: value,
                        }))
                    }
                    error={!!validationResults.firstName}
                    helperText={validationResults.firstName}
                />

                <TextFieldWithLabel
                    id="last-name"
                    disabled={signUpInProgress}
                    autoComplete="family-name"
                    label={COPY.fieldNames.lastName}
                    placeholder={COPY.placeholders.lastName}
                    value={signupForm.lastName}
                    onChange={(event) =>
                        setSignupForm((oldSignupForm) => ({
                            ...oldSignupForm,
                            lastName: event.target.value,
                        }))
                    }
                    error={!!validationResults.lastName}
                    helperText={validationResults.lastName}
                />
            </Stack>

            <TextFieldWithLabel
                select
                disabled={signUpInProgress}
                id="business-type"
                label={COPY.fieldNames.businessType}
                placeholder={COPY.placeholders.businessType}
                value={signupForm.businessType}
                onChange={(event) =>
                    setSignupForm((oldSignupForm) => ({
                        ...oldSignupForm,
                        businessType: event.target.value,
                    }))
                }
                error={!!validationResults.businessType}
                helperText={validationResults.businessType}
            >
                <MenuItem value="">
                    <em>{COPY.placeholders.businessType}</em>
                </MenuItem>
                {Object.entries(BUSINESS_TYPE_DROPDOWN_OPTIONS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        {value}
                    </MenuItem>
                ))}
            </TextFieldWithLabel>

            <TextFieldWithLabel
                select
                disabled={signUpInProgress}
                id="bid-value"
                label={COPY.fieldNames.bidValue}
                placeholder={COPY.placeholders.bidValue}
                value={signupForm.bidValue}
                onChange={(event) =>
                    setSignupForm((oldSignupForm) => ({
                        ...oldSignupForm,
                        bidValue: event.target.value,
                    }))
                }
                error={!!validationResults.bidValue}
                helperText={validationResults.bidValue}
            >
                <MenuItem value="">
                    <em>{COPY.placeholders.bidValue}</em>
                </MenuItem>
                {BID_VALUE_OPTIONS.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextFieldWithLabel>

            <TextFieldWithLabel
                id="password"
                disabled={signUpInProgress}
                autoComplete="new-password"
                type="password"
                label={COPY.fieldNames.password}
                placeholder={COPY.placeholders.password}
                value={signupForm.password}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSignupUser();
                    }
                }}
                onChange={(event) =>
                    setSignupForm((oldSignupForm) => ({
                        ...oldSignupForm,
                        password: event.target.value,
                    }))
                }
                error={!!validationResults.password}
                helperText={validationResults.password}
            />

            <LoadingButton
                variant="contained"
                loading={signUpInProgress}
                {...onClickAndEnterKey(() => {
                    handleSignupUser();
                })}
            >
                {COPY.startTrial}
            </LoadingButton>
        </Stack>
    );
};
