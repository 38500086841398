import React, { FC } from 'react';
import Helmet from 'react-helmet';
import Paper from '@mui/material/Paper';
import { Mode } from '@/theme/Mode';
import { PublicRouter } from './PublicRouter';
import { ToolbarRouter } from './ToolbarRouter';
import { useTrackPage } from './hooks/useTrackPage';
import { StatusBars } from '@/components/StatusBars';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { LoadingPage } from '@/views/LoadingPage';
import { LaunchDarklyProvider } from '@/contexts/LaunchDarkly';
import PrivateRouter from '@/components/app/router/PrivateRouter';
import { useHandledCheckoutReferrer } from '@/components/app/router/hooks/useHandleCheckoutReferrer';
import { useHandlePaymentSetupReferrer } from '@/components/app/router/hooks/useHandlePaymentSetupReferrer';
import { useSaveQueryParams } from '@/common/queryParams';
import { useChangeIntercomState } from '@/common/hooks/useChangeIntercomState';

export const Router: FC = () => {
    // Send tracking to analytics
    useTrackPage();

    useChangeIntercomState({
        enabled: false,
    });

    useSaveQueryParams();

    const { features, hasFeatureFlagsAccess, fetched } = useFeatures();
    const user = useUser();

    const checkoutReferrer = useHandledCheckoutReferrer({
        currentPriceId: user.data.user.team?.subscription?.priceID,
        loadedUser: user.loading.loadedUser,
        userHasSubscription: !!user.data.user.team?.subscription,
    });

    useHandlePaymentSetupReferrer();

    // The user, feature flags, and referrer are dependencies of other routes.
    if (checkoutReferrer || !user.loading.loadedUser || (hasFeatureFlagsAccess && !fetched)) {
        return <LoadingPage />;
    }

    return (
        <>
            <Helmet>
                <title>1build</title>
                <meta name="description" content="1build App" />
            </Helmet>

            {user.validations.hasSession && <ToolbarRouter />}

            {!user.validations.hasSession && (
                <Mode variant="light">
                    <Paper elevation={0} square>
                        <PublicRouter features={features} />
                    </Paper>
                </Mode>
            )}

            {user.validations.hasSession && <StatusBars />}

            {user.validations.hasSession && (
                // Setting LD only for logged users
                <LaunchDarklyProvider user={user.data.user}>
                    <Paper
                        elevation={0}
                        square
                        sx={{
                            height: '100%',
                        }}
                    >
                        <PrivateRouter />
                    </Paper>
                </LaunchDarklyProvider>
            )}
        </>
    );
};
