import React from 'react';
import { BillingRouter } from './BillingRouter';
import { PricingRouter } from './PricingRouter';
import { ProjectRouter } from './ProjectRouter';
import { ProtectedRoute } from '@/components/app/ProtectedRoute';
import { plans } from '@/components/pricing/PricingPage/plans';
import {
    hasMonthlySubscription,
    hasProfessionalSubscription,
} from '@/components/pricing/PricingPage/utils';
import { IUserRole } from '@/graphql';
import { Estimators } from '@/views/Estimators';
import { MissionControl } from '@/views/MissionControl';
import { Profile } from '@/views/Profile';
import {
    hasAvailability,
    hasBeenWelcomed,
    hasPaidSubscription,
    isAdministratorUser,
    isExpiredTrial,
    isNonsubscriber,
    isPureEstimatorUser,
    isTeamOnboardingCancelled,
    isMissingPaymentMethod,
} from '@/views/Projects/utils';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StorageKey } from '@/common/storage';
import { useLocalStorage } from '@rehooks/local-storage';
import { useUser } from '@/contexts/User';
import { useFeatureIdentifier } from '@/components/app/router/hooks/useFeatureIdentifier';
import { PaymentSetup } from '@/views/PaymentSetup/PaymentSetup';
import { BuilderDetails } from '@/views/BuilderDetails';

const generalAllowList = ['/projects', '/profile/details'];

const checkoutPathAllowList = [...generalAllowList];
const onboardingPathAllowList = [...generalAllowList];
const trialExpiredPathAllowList = [...generalAllowList, '/billing', '/pricing', '/profile'];

export const PrivateRouter = () => {
    useFeatureIdentifier();
    const [checkoutSuccessComplete] = useLocalStorage<boolean>(StorageKey.CheckoutSuccessComplete);
    const history = useHistory();
    const user = useUser();
    const flags = useFlags();

    const shouldShowPaymentSetup = isMissingPaymentMethod({ user: user.data.user, flags });

    return (
        <Switch>
            <Redirect exact from="/" to="/projects" />
            {shouldShowPaymentSetup && (
                <Route path="/payment-setup">
                    <PaymentSetup />
                </Route>
            )}
            {shouldShowPaymentSetup && <Redirect to="/payment-setup" />}
            {
                // Redirect users that have not completed the checkout
                // success flow if trying to go outside the allow list
                checkoutSuccessComplete === false &&
                    !checkoutPathAllowList.includes(history.location.pathname) && (
                        <Redirect to="/" />
                    )
            }
            {
                // Redirect users that are not administrators with
                // no subscription with expired trials if trying to
                // go outside the allow list
                !isAdministratorUser(user.data.user) &&
                    !hasPaidSubscription(user.data.user) &&
                    isNonsubscriber(user.data.user.team) &&
                    (isExpiredTrial(user.data.user.team) ||
                        isTeamOnboardingCancelled(user.data.user.team)) &&
                    !trialExpiredPathAllowList.includes(history.location.pathname) && (
                        <Redirect to="/" />
                    )
            }
            {
                // Redirect users that have not been welcomed if trying
                // to go outside the allow list, except pure estimators
                !isAdministratorUser(user.data.user) &&
                    !isPureEstimatorUser(user.data.user) &&
                    !hasBeenWelcomed(user.data.user) &&
                    !onboardingPathAllowList.includes(history.location.pathname) && (
                        <Redirect to="/" />
                    )
            }
            {
                // Redirect teams with pro subscriptions from pricing
                (hasMonthlySubscription(plans, user.data.user.team) ||
                    !hasProfessionalSubscription(plans, user.data.user.team)) && (
                    <Route path="/pricing">
                        <PricingRouter />
                    </Route>
                )
            }
            {
                // Redirect teams with no subscription from billing
                hasPaidSubscription(user.data.user) && (
                    <Route path="/billing">
                        <BillingRouter />
                    </Route>
                )
            }
            {
                // Redirect new estimators that have not filled in their availability days
                isPureEstimatorUser(user.data.user) && !hasAvailability(user.data.user) && (
                    <Redirect from="/projects*" to="/profile" />
                )
            }
            <Route path="/projects">
                <ProjectRouter />
            </Route>
            <Route path="/profile/details">
                <BuilderDetails />
            </Route>
            <Route path="/profile">
                <Profile />
            </Route>
            <Route path="/estimators">
                <Estimators />
            </Route>
            <ProtectedRoute
                path="/mission-control"
                roles={[IUserRole.Superadmin, IUserRole.Impersonator]}
            >
                <MissionControl />
            </ProtectedRoute>
            <Redirect from="*" to="/" />
        </Switch>
    );
};

export default PrivateRouter;
