import { useEffect } from 'react';
import { PaymentSetupStatusValues, QueryParams, useGetQueryParams } from '@/common/queryParams';
import { TrackEventName, useSegmentTrackEventWithUserData } from '@/common/analytics';

export function useHandlePaymentSetupReferrer() {
    const track = useSegmentTrackEventWithUserData();
    const { getQueryParamByName } = useGetQueryParams();

    const queryParam = getQueryParamByName(QueryParams.PaymentSetupStatus);

    useEffect(() => {
        if (queryParam === PaymentSetupStatusValues.Succeed) {
            track(TrackEventName.PaymentSetupCompleted);
        }
    }, [queryParam, track]);
}
