import styled from 'styled-components';
import { lighten } from 'polished';

import { BasicModal, Button, ButtonGroup, PoppinsHeader } from '../BasicModal';
import { colorTypographyDarkSecondary } from '@/variables';

const variables = {
    submit: '#305FFF',
};

export const Copy = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colorTypographyDarkSecondary};
`;

export const Container = styled(BasicModal)`
    font-family: 'proxima-nova', sans-serif;

    & ${PoppinsHeader} {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        margin: 19px 0 19px -1px; // Offset to meet copy
    }

    & ${Copy} {
        margin-bottom: 24px;
    }

    & ${ButtonGroup} {
        margin-left: 0;
        margin-right: auto;
        padding: 0;

        & > ${Button} {
            width: 137px;

            &:last-of-type {
                background: ${variables.submit};
                border-color: ${variables.submit};

                &:active,
                &:hover {
                    background: ${lighten(0.08, variables.submit)};
                    border-color: ${lighten(0.08, variables.submit)};
                }
            }
        }
    }
`;
