/*
 * Hook that disables events on passed ref element.
 * Important for counting tool and SVG renderer.
 * When counting tool is selected all geometries should not react to
 * click events. Can't pass it dynamically to props because of a leaflet bug
 * https://github.com/leaflet/leaflet/issues/2662
 * */
import { MutableRefObject, useEffect } from 'react';

import { LineString, MultiLineString } from 'geojson';
import { Polygon as LeafletPolygon, Polyline as LeafletPolyline } from 'leaflet';
import { Polygon, PolygonProps, Polyline, PolylineProps } from 'react-leaflet';

import { ToolType } from '@/common/types';
import { useTool } from '@/components/takeoff/hooks/useTool';

// Pulled from leaflet types
/* eslint-disable @typescript-eslint/no-explicit-any */
export type EditablePolygon = Polygon<PolygonProps, LeafletPolygon<any>>;
export type EditablePolyline = Polyline<
    PolylineProps,
    LeafletPolyline<LineString | MultiLineString, any>
>;
/* eslint-enable @typescript-eslint/no-explicit-any */
export type EditablePoly = EditablePolygon | EditablePolyline;
export type EditablePolyRef = MutableRefObject<EditablePoly | null>;

type LayerWithPrivate = EditablePoly & {
    _path: HTMLElement;
};

export interface UseDisableEventOnSVGProps {
    ref: EditablePolyRef;
    checkIsRendererSVG: () => boolean;
}

export const useDisableEventsOnSVG: (props: UseDisableEventOnSVGProps) => void = ({
    ref,
    checkIsRendererSVG,
}) => {
    const tool = useTool();

    useEffect(() => {
        if (ref.current && checkIsRendererSVG()) {
            if (tool === ToolType.COUNT) {
                (ref.current.leafletElement as unknown as LayerWithPrivate)._path.classList.add(
                    'disable-events'
                );
            } else {
                (ref.current.leafletElement as unknown as LayerWithPrivate)._path.classList.remove(
                    'disable-events'
                );
            }
        }
    }, [ref, tool]);
};
