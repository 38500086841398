import { Geometry } from '@/common/types';
import { markupTypeToGeometryType } from '@/common/utils/markups/maps';
import { IMarkupFragment } from '@/graphql';
import { fromMarkupGeometries } from '@/common/convert/geometry/fromMarkupGeometries';

export const fromMarkup = (markup: IMarkupFragment): Geometry => ({
    uuid: markup.id,
    style: {
        color: markup.color,
    },
    type: markupTypeToGeometryType[markup.markupType],
    coordinates: fromMarkupGeometries(markup),
});
