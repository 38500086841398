import { css } from '@mui/material/styles';

import { Caret, Container } from '@/components/ui/containers/Collapsible';

// # TODO => TYPE THIS CORRECTLY.
export const collapsibleCss = css`
    border-radius: 0.5rem;
    background-color: #313952;

    ${Container} {
        ${Caret} {
            path {
                fill: #e08568;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }
`;

export const projectCollapsibleCss = css`
    ${Caret} {
        top: 0.5rem;
        right: 0;
    }

    & svg {
        & > path {
            fill: #babfc7 !important;
        }
    }

    padding: 12px 16px;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const inputCss = css`
    border: none;
    font-size: 14px;
    font-weight: 400;

    position: relative;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    padding-left: 14px;
    height: 36px;
    width: 100%;

    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};

    color: #d7e1e5;
    background-color: #344371;

    border-radius: 2px;

    &:focus-visible {
        outline: 2px solid #3a99bf;
        outline-offset: -2px;
    }

    &::placeholder {
        color: rgba(215, 225, 229, 0.4);
    }
`;
