import gql from 'graphql-tag';

export const createEstimateMutation = gql`
    mutation CreateEstimate($filename: String!, $uuid: UUID!, $projectId: BigInt!) {
        createProjectEstimateFile(
            input: {
                projectEstimateFile: { filename: $filename, uuid: $uuid, projectId: $projectId }
            }
        ) {
            clientMutationId
        }
    }
`;
