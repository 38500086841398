import React, { FC } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import List, { ListProps } from '@mui/material/List';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

interface ListWithLoadMoreButtonPaginationProps extends ListProps {
    hasNextPage?: boolean;
    isLoadingNextPage?: boolean;
    loadMoreButtonProps?: ListItemButtonProps;
}

export const ListWithLoadMoreButtonPagination: FC<ListWithLoadMoreButtonPaginationProps> = ({
    children,
    hasNextPage,
    isLoadingNextPage,
    loadMoreButtonProps,
    ...props
}) => {
    return (
        <List {...props}>
            {children}
            {hasNextPage && (
                <ListItemButton
                    disabled={isLoadingNextPage}
                    sx={{ justifyContent: 'center' }}
                    {...loadMoreButtonProps}
                >
                    {isLoadingNextPage && <CircularProgress size={20} />}
                    {!isLoadingNextPage && (
                        <ListItemText
                            primaryTypographyProps={{
                                color: 'primary',
                                textAlign: 'center',
                                variant: 'body2',
                            }}
                        >
                            Load more
                        </ListItemText>
                    )}
                </ListItemButton>
            )}
        </List>
    );
};
