import { CSSObject } from 'styled-components';

export const hideScrollbarCssObject: CSSObject = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    ['::-webkit-scrollbar']: {
        display: 'none',
    },
};
