/*
 * Action is a clickable action tab within the projects panel's navbar.
 */
import React, { FC, useEffect, useState } from 'react';

import clsx from 'clsx';

import { usdFormatter } from '../Price';
import { PanelAssignTab, PanelAssignTabEstimatorCircle } from './styled';

import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { isPriceEstimating } from '@/components/AdminDashboard/ProjectPanel/utils';
import { ActionTab } from '@/components/AdminDashboard/types';
import { Avatar } from '@/components/ui/icons/Avatar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { executeEventsQuery } from '@/queries/events';
import { ProjectRecord } from '@/queries/projects';
import { colorStatusSuccess, colorTypographySection } from '@/variables';

import { ReactComponent as EstimatorLight } from '@/assets/icons/EstimatorLight.svg';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const COPY = {
    assign: 'ASSIGN',
    assigned: 'ASSIGNED',
    estimator: 'Estimator',
    price: 'Price',
    requested: 'REQUESTED',
};

export const mapActionTabToText = (tab: ActionTab): string => {
    switch (tab) {
        case ActionTab.Estimator:
            return COPY.estimator;
        default:
        case ActionTab.Price:
            return COPY.price;
    }
};

const estimatorHasAcceptedProject = (project: ProjectRecord): Promise<boolean> =>
    executeEventsQuery({ projectId: project.id }).then(
        (result) =>
            (result.data?.events.nodes.filter(
                (event) => event.eventTypeName === 'accept_assignment'
            ).length || 0) > 0
    );

export interface AssignProps extends ProjectPanelComponentProps {
    type: ActionTab;
}

export const Action: FC<AssignProps> = ({ useProjectPanel, type }) => {
    const { activeTab, setActiveTab, estimator, project, pricingAssignment } = useProjectPanel();

    const [assignString, setAssignString] = useState(COPY.assign);
    const { builderAcceptance } = useFlags();

    const priceUsdCents = pricingAssignment?.priceUsdCents;
    const estimatorAssignString = async (): Promise<string> => {
        if (estimator === undefined) {
            return COPY.assign;
        }
        const estimatorAccepted = await estimatorHasAcceptedProject(project);
        return estimatorAccepted ? COPY.assigned : COPY.requested;
    };

    useEffect(() => {
        switch (type) {
            case ActionTab.Estimator:
                estimatorAssignString().then(setAssignString);
                break;
            default:
                setAssignString(COPY.assign);
        }
    }, [type, estimator]);

    const renderEstimatorAvatar = (): JSX.Element => {
        if (estimator === undefined) {
            return <EstimatorLight />;
        }
        return <Avatar diameter="1.5rem" user={mapBaseUserToIUserFragment(estimator)} />;
    };

    const renderText = (): JSX.Element => {
        if (type === ActionTab.Price && priceUsdCents) {
            return (
                <Typography
                    sx={{
                        color: colorStatusSuccess,
                        fontFamily: 'Poppins, sans-serif',
                        letterSpacing: '-0.03em',
                        fontSize: '1.25rem',
                        alignSelf: 'center',
                    }}
                >
                    {usdFormatter.format(priceUsdCents / 100)}
                </Typography>
            );
        }
        return (
            <Stack spacing={0}>
                <Typography
                    sx={{
                        color: colorTypographySection,
                        fontWeight: 600,
                        fontSize: '11px',
                        lineHeight: '11px',
                    }}
                >
                    {assignString}
                </Typography>
                <Typography>{mapActionTabToText(type)}</Typography>
            </Stack>
        );
    };

    const showConfirmPriceTab = (): boolean => {
        return type === ActionTab.Price && builderAcceptance && isPriceEstimating(project);
    };

    return (
        <PanelAssignTab
            active={activeTab === type}
            onClick={(): void => setActiveTab(type)}
            className={clsx({
                'active-tab': activeTab === type,
                'confirm-price-tab': showConfirmPriceTab(),
            })}
        >
            {renderText()}
            {type === ActionTab.Estimator && (
                <PanelAssignTabEstimatorCircle>
                    {renderEstimatorAvatar()}
                </PanelAssignTabEstimatorCircle>
            )}
        </PanelAssignTab>
    );
};
