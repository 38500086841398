import { ElementRenderer } from 'leyden-react';
import React from 'react';
import { useSelected } from 'slate-react';

import { ExpressionBubbleContainer } from './styled';

export const ExpressionNumber: ElementRenderer<'ExpressionNumber'> = ({
    attributes,
    children,
    element,
}) => {
    const selected = useSelected();

    return (
        <ExpressionBubbleContainer {...attributes} selected={selected}>
            {element.value}
            {children}
        </ExpressionBubbleContainer>
    );
};
