/**
 * The FileUpload component is responsible for adding an invisible
 * input[type='file'] to the page. It exposes a forwardRef that can be used
 * to access the native input in order to trigger it (using ref.current.click())
 * or to get files from it (ref.current.files). It also supports any standard
 * input props, like onChange.
 */

import React from 'react';
import { InputProps } from '@/common/types';

export const FileUpload = React.forwardRef(
    (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
        return <input ref={ref} type="file" className="hidden" {...props} />;
    }
);

FileUpload.displayName = 'FileUpload';
