import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { DomEvent, LatLng, LatLngBounds, LeafletMouseEvent } from 'leaflet';
import { FeatureGroup, Polygon, Polyline, Rectangle } from 'react-leaflet';

import { Vertex } from '../common/Vertex';

import { fromCoordinate, fromLeaflet } from '@/common/convert/coordinateData';
import { toLeaflet } from '@/common/convert/geometry';
import { useDisableEventsOnSVG } from '@/common/hooks/useDisableEventsOnSVG';
import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import { Geometry, GeometryType, LineType, Point, ToolType } from '@/common/types';
import { parseAreaVertexId } from '@/common/utils/geometries/vertices';
import { useNilState } from '@/common/utils/helpers';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import {
    currentlyAddedCoordinatesObservable,
    isAddingCoordinatesObservable,
    isDrawingRectangleObservable,
    styleObservable,
    subscribeToIsAddingCoordinates,
} from '@/components/takeoff/observables/helpers';
import { toolObservable } from '@/components/takeoff/observables/interface';
import { colorPrimary } from '@/variables';

export interface EditablePolygonProps extends TakeoffComponentProps {
    color: string;
    geometry: Geometry<GeometryType.AREA>;
    commit?: (geometry: Geometry<GeometryType.AREA>) => void;
    onFinish?: () => void;
    editable: boolean;
    isDrawingRectangle?: boolean;
    activeVertices: string[];
}

export const EditablePolygon: FunctionComponent<EditablePolygonProps> = ({
    geometry,
    editable,
    commit,
    onFinish,
    isDrawingRectangle = true,
    activeVertices,
    useTakeoff,
}: EditablePolygonProps) => {
    const {
        checkIsRendererSVG,
        setTool,
        mapRef,
        activateVertices,
        deactivateVertices,
        deactivateOtherVertices,
        deselect,
        mouseEvents,
        pointerEventsDisabled,
    } = useTakeoff();

    const { commit: setGeometry } = useTakeoff();

    const style = styleObservable.value;

    const [isAddingCoordinates, setIsAddingCoordinates] = useState<boolean>();

    useEffect(() => {
        const subscription = subscribeToIsAddingCoordinates(setIsAddingCoordinates);
        return (): void => subscription.unsubscribe();
    }, []);

    const tool = toolObservable.value;

    const { color: currentColor } = style;

    /**
     * This is a generic component that allows to first draw and then edit
     * a polygon. It also takes care of selection and multiselection of
     * its vertices.
     *
     * Vertices are indexed as {polygon_ring}-{vertex_index_in_the_ring}
     **/

    const commitPolygon = commit
        ? commit
        : (geometry: Geometry<GeometryType.AREA>) => setGeometry(geometry);

    const polygonRef = useRef<Polygon | null>(null);

    const [newVertexEnd, setNewVertexEnd] = useNilState<LatLng>();

    const [addingNewVertex, setAddingNewVertex] = useState<boolean>(
        geometry.coordinates[0]?.length === 1
    );

    const [selectMultipleVertices, setSelectMultipleVertices] = useState(false);

    const currentPointCount = geometry.coordinates[0].length;

    const leafletPolygon = toLeaflet(geometry).getLatLngs();

    const finishDrawing = (): void => {
        setAddingNewVertex(false);
        currentlyAddedCoordinatesObservable.next(null);
        isAddingCoordinatesObservable.next(false);
        onFinish?.();
        deselect(geometry.uuid);
        tool === ToolType.AREA && setTool(ToolType.SELECTION);
        isDrawingRectangleObservable.next(false);
    };

    const getEdgeCenterpoints = (): [number, LatLng][] => {
        const centerPoints: [number, LatLng][] = [];
        const findCenterpoint = (start: Point, end: Point): LatLng => {
            return new LatLng((end.x + start.x) / 2, (end.y + start.y) / 2);
        };
        geometry.coordinates.map((ring, ringIndex) => {
            ring.reduce((prev, current) => {
                centerPoints.push([ringIndex, findCenterpoint(prev, current)]);
                return current;
            });
            centerPoints.push([ringIndex, findCenterpoint(ring[ring.length - 1], ring[0])]);
        });

        return centerPoints;
    };

    const addVertex = (newPoint: Point): void => {
        const newCoordinates = [...geometry.coordinates.map((points) => [...points])];
        if (currentPointCount === 0 || !isDrawingRectangle) {
            newCoordinates[0].push(newPoint);
        } else {
            const firstPoint = newCoordinates[0][0];
            newCoordinates[0].push(fromCoordinate({ x: firstPoint.x, y: newPoint.y }));
            newCoordinates[0].push(newPoint);
            newCoordinates[0].push(fromCoordinate({ x: newPoint.x, y: firstPoint.y }));
        }
        commitPolygon({ ...geometry, coordinates: newCoordinates });
    };

    const moveActiveVertices = (
        mouseCoordinates: Point,
        localActiveVertices: string[],
        movedVertexId: string
    ): void => {
        const newCoordinates = [...geometry.coordinates];
        const otherActiveVertices = localActiveVertices.filter(
            (id: string): boolean => id !== movedVertexId
        );
        const [ring, vertexIndex] = parseAreaVertexId(movedVertexId);

        // Calculate the drag vector.
        const moveOrigin = newCoordinates[ring][vertexIndex];
        newCoordinates[ring] = [...newCoordinates[ring]];
        newCoordinates[ring][vertexIndex] = mouseCoordinates;
        const moveVector = [mouseCoordinates.x - moveOrigin.x, mouseCoordinates.y - moveOrigin.y];

        // Apply the drag vector to all the other vertices that should be
        // dragged.
        for (const activeVertexId of otherActiveVertices) {
            const [ring, vertexIndex] = parseAreaVertexId(activeVertexId);
            const currentVertexCoordinates = newCoordinates[ring][vertexIndex];
            newCoordinates[ring][vertexIndex] = {
                x: currentVertexCoordinates.x + moveVector[0],
                y: currentVertexCoordinates.y + moveVector[1],
                id: currentVertexCoordinates.id,
            };
        }
        commitPolygon({ ...geometry, coordinates: newCoordinates });
    };

    const handleVertexClick = useCallback(
        (e: LeafletMouseEvent, vertexId: string): void => {
            if (addingNewVertex) return;

            if (!mapRef) return;

            const map = mapRef.leafletElement;

            let localActiveVertices = activeVertices;

            // If the user is holding shift to select multiple vertices,
            // they can select and deselect them freely.
            if (selectMultipleVertices) {
                activeVertices.includes(vertexId)
                    ? deactivateVertices([vertexId])
                    : activateVertices([vertexId]);
                // Once they let go of shift, clicking on a vertex will deactivate
                // all other vertices.
            } else if (!activeVertices.includes(vertexId)) {
                deactivateOtherVertices(vertexId);
                localActiveVertices = [vertexId];
            }

            // Once a vertex is pressed, we override the default mousemove
            // event on the underlying map to handle the dragging.
            map.dragging.disable();
            map.on('mousemove', function (e: LeafletMouseEvent) {
                moveActiveVertices(fromLeaflet(e.latlng), localActiveVertices, vertexId);
                DomEvent.stopPropagation(e);
            });
            DomEvent.stopPropagation(e);
        },
        [
            mapRef,
            addingNewVertex,
            activeVertices,
            geometry,
            commitPolygon,
            selectMultipleVertices,
            deactivateVertices,
        ]
    );

    const handleCenterpointClick = useCallback(
        (e: LeafletMouseEvent, vertexId: string): void => {
            const [ringIndex, vertexIndex] = parseAreaVertexId(vertexId);

            if (!addingNewVertex) {
                const newCoordinates = [...geometry.coordinates];
                // Clicking on a centerpoint adds a new vertex to the geometry.
                newCoordinates[ringIndex].splice(vertexIndex + 1, 0, fromLeaflet(e.latlng));
                // It also deselects all the currently selected vertices to
                // avoid confusion.
                deactivateOtherVertices('');
                commitPolygon({ ...geometry, coordinates: newCoordinates });
            }
            DomEvent.stopPropagation(e);
        },
        [addingNewVertex, geometry, commitPolygon]
    );

    const handleVertexRelease = useCallback(
        (e: LeafletMouseEvent, _: string): void => {
            if (!mapRef || addingNewVertex) return;

            const map = mapRef.leafletElement;

            // We disable the overriden mousemove
            map.off('mousemove');

            DomEvent.stopPropagation(e);
        },
        [mapRef, addingNewVertex]
    );

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            setSelectMultipleVertices(true);
        }
    };
    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            setSelectMultipleVertices(false);
        }
    };

    const renderVertices = (): JSX.Element[][] =>
        geometry.coordinates.map((ring: Point[], ringIndex: number) => {
            return ring.map((coordinates: Point, index: number) => {
                const id = `${ringIndex}-${index}`;
                return (
                    <Vertex
                        coordinates={new LatLng(coordinates.x, coordinates.y)}
                        key={index}
                        color={(editable && geometry.style.color) || currentColor}
                        active={activeVertices.includes(id)}
                        onMouseDown={handleVertexClick}
                        onMouseUp={handleVertexRelease}
                        interactive
                        id={id}
                        useTakeoff={useTakeoff}
                    />
                );
            });
        });

    const renderEdgeCenterPoints = (): JSX.Element[] =>
        getEdgeCenterpoints().map(([ringIndex, coordinates], index: number) => {
            const id = `${ringIndex}-${index}-centerpoint`;
            return (
                <Vertex
                    coordinates={coordinates}
                    key={index}
                    color={(editable && geometry.style.color) || currentColor}
                    onMouseDown={handleCenterpointClick}
                    onMouseUp={handleVertexRelease}
                    id={id}
                    interactive
                    opacity={0.4}
                    useTakeoff={useTakeoff}
                />
            );
        });

    const renderDrawingHelpers = (): JSX.Element => (
        <>
            {currentPointCount > 0 && addingNewVertex && newVertexEnd && isDrawingRectangle && (
                <Rectangle
                    bounds={
                        new LatLngBounds(
                            new LatLng(
                                geometry.coordinates[0][currentPointCount - 1].x,
                                geometry.coordinates[0][currentPointCount - 1].y
                            ),
                            newVertexEnd
                        )
                    }
                    weight={2}
                    dashArray={[10, 10]}
                    opacity={0.5}
                    fill={false}
                    color={currentColor}
                />
            )}
            {currentPointCount > 0 && addingNewVertex && newVertexEnd && !isDrawingRectangle && (
                <Polyline
                    positions={[
                        // Draw a line from the beginning to the cursor by default
                        new LatLng(geometry.coordinates[0][0].x, geometry.coordinates[0][0].y),
                        newVertexEnd,
                        // If there are more coordinates, draw a line from the cursor to the end too
                        ...(currentPointCount <= 1
                            ? []
                            : [
                                  new LatLng(
                                      geometry.coordinates[0][currentPointCount - 1].x,
                                      geometry.coordinates[0][currentPointCount - 1].y
                                  ),
                              ]),
                    ]}
                    weight={2}
                    opacity={0.5}
                    color={currentColor}
                    dashArray={[10, 10]}
                    lineType={LineType.NORMAL}
                    shapeWeight={2}
                />
            )}
        </>
    );

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    useEffect(() => {
        if (!mapRef || !addingNewVertex) return;
        const map = mapRef.leafletElement;

        const handleMove = (e: LeafletMouseEvent): void => {
            setNewVertexEnd(e.latlng);
        };
        const handleMouseDown = (e: LeafletMouseEvent): void => {
            addVertex(fromLeaflet(e.latlng));
        };
        const handleDoubleClick = (e: LeafletMouseEvent): void => {
            // A double click registers as click-click-doubleclick, so we
            // have to take into consideration the last two points that
            // were added without the user's consent and remove them before
            // closing the geometry.
            if (currentPointCount > 3) {
                commitPolygon({ ...geometry, coordinates: [geometry.coordinates[0].slice(0, -1)] });
                finishDrawing();
            }
            DomEvent.stopPropagation(e);
        };

        map.on('click', handleMouseDown);
        map.on('dblclick', handleDoubleClick);
        map.on('mousemove', handleMove);

        return function cleanup(): void {
            map.off('click', handleMouseDown);
            map.off('dblclick', handleDoubleClick);
            map.off('mousemove', handleMove);
        };
    }, [
        mapRef,
        geometry,
        addingNewVertex,
        geometry.coordinates,
        currentPointCount,
        isDrawingRectangle,
    ]);

    useEffect(() => {
        if (
            currentPointCount > 1 &&
            addingNewVertex &&
            (isDrawingRectangle || !isAddingCoordinates)
        ) {
            finishDrawing();
        }
    }, [currentPointCount, isDrawingRectangle, isAddingCoordinates, addingNewVertex]);

    useDisableEventsOnSVG({ ref: polygonRef, checkIsRendererSVG });

    return (
        <FeatureGroup>
            {renderDrawingHelpers()}
            {currentPointCount > 0 && (
                <Polygon
                    positions={leafletPolygon}
                    weight={2}
                    color={geometry.style.color || colorPrimary}
                    interactive={!pointerEventsDisabled}
                    ref={polygonRef}
                    areaType={geometry.style.areaType}
                    {...mouseEvents(geometry)}
                />
            )}
            {(addingNewVertex || editable) && renderVertices()}
            {editable && currentPointCount > 1 && renderEdgeCenterPoints()}
        </FeatureGroup>
    );
};
