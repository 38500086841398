import { toTWKB } from '@/common/convert/geometry';
import { GeometryType, PlanPageGeometry } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

type Convert = {
    [GeometryType.AREA]: PlanPageGeometry<GeometryType.AREA> | null;
    [GeometryType.COUNT]: PlanPageGeometry<GeometryType.COUNT> | null;
    [GeometryType.LINEAR]: PlanPageGeometry<GeometryType.LINEAR> | null;
};

export const toPlanPageGeometry = newGeometryHandler<Convert>({
    [GeometryType.AREA]: (area) => {
        const coordinates = toTWKB(area);
        if (coordinates === null) {
            return null;
        }
        return { ...area, coordinates };
    },
    [GeometryType.COUNT]: (count) => {
        const coordinates = toTWKB(count);
        if (coordinates === null) {
            return null;
        }
        return { ...count, coordinates };
    },
    [GeometryType.LINEAR]: (line) => {
        const coordinates = toTWKB(line);
        if (coordinates === null) {
            return null;
        }
        return { ...line, coordinates };
    },
});
