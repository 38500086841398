import { useApolloClient } from '@apollo/client';
import { Element } from 'leyden';
import { useCellIsSelected, useLeydenStatic, useCoordinates } from 'leyden-react';
import { useEffect, useMemo, useState } from 'react';

import { Queries } from '../../queries';
import { Transforms } from '../../transforms';
import {
    ExpressionOpenedState,
    useEstimationExpressionOpenedState,
} from '@/components/app/router/EstimationRoute/hooks/useEstimationExpressionOpenedState';

type ElementQuantityEditorView =
    | Element<'ElementQuantityExpressionInputView'>
    | Element<'ElementQuantityNumericInputView'>;

export const useElementQuantityEditorEffects = (view: ElementQuantityEditorView): void => {
    const [editCancelled, setEditCancelled] = useState(false);

    const client = useApolloClient();
    const selected = useCellIsSelected(view);
    const coords = useCoordinates(view);
    const editor = useLeydenStatic();
    const { setExpressionOpenedState } = useEstimationExpressionOpenedState();

    const initialExpressionTokens = useMemo(() => {
        if (coords === null) {
            return null;
        }
        return Queries.editedElementExpression(editor, { at: coords });
    }, [coords]);

    useEffect(() => {
        if (selected || coords === null) {
            return;
        }
        if (!editCancelled) {
            const editedExpressionTokens = Queries.editedElementExpression(editor, { at: coords });
            if (
                editedExpressionTokens !== null &&
                JSON.stringify(editedExpressionTokens) !== JSON.stringify(initialExpressionTokens)
            ) {
                setTimeout(() => {
                    Transforms.setElementQuantityExpression(editor, editedExpressionTokens, {
                        at: coords,
                        client,
                    });
                });
            }
        }
        setTimeout(() => {
            // Trigger context update to assembly panel
            setExpressionOpenedState(ExpressionOpenedState.Closed);

            Transforms.closeElementQuantityEditor(editor, { at: coords });
        });
    }, [selected]);

    useEffect(() => {
        if (selected) {
            setEditCancelled(false);
            const handleKeyDown = (e: KeyboardEvent): void => {
                if (e.defaultPrevented) {
                    return;
                }
                if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditCancelled(true);
                    Transforms.deselect(editor);
                    return;
                }
            };
            document.addEventListener('keydown', handleKeyDown);
            return (): void => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [selected]);
};
