/*
 * types.ts provides types and typeguards.
 */
import { AssemblyRecord } from '@/queries/assemblies';
import { ElementRecord } from '@/queries/elements';
import { FormulaVariableRecord } from '@/queries/formulaVariables';
import { FormulaRecord } from '@/queries/formulas';
import { MaterialRecord } from '@/queries/materials';
import { MeasuredQuantityRecord } from '@/queries/measuredQuantities';
import { UomRecord } from '@/queries/unitOfMeasure';

/*****************************************************************************/
/* Assembly library categories                                               */
/*****************************************************************************/
export enum LibraryCategory {
    OneBuild = '1build library',
    Favorites = 'Favorites',
    CurrentProject = 'Current project',
    PastProjects = 'Past projects',
    Geometries = 'Geometries',
}

export const AssemblyLibraryCategories = [
    LibraryCategory.OneBuild,
    LibraryCategory.Favorites,
    LibraryCategory.CurrentProject,
    LibraryCategory.PastProjects,
    LibraryCategory.Geometries,
];

/*****************************************************************************/
/* Material                                                                  */
/*****************************************************************************/
export type MaterialDataRecord = MaterialRecord;
export type MaterialDataNew = Omit<MaterialDataRecord, 'id' | 'projectId' | 'ownerId'>;
export type MaterialData = MaterialDataRecord | MaterialDataNew;

export const materialDataIsRecord = (material: MaterialData): material is MaterialRecord =>
    'id' in material;

/*****************************************************************************/
/* Measured Quantity                                                         */
/*****************************************************************************/
export type MeasuredQuantityDataRecord = MeasuredQuantityRecord;
export type MeasuredQuantityDataNew = Omit<MeasuredQuantityDataRecord, 'id'>;
export type MeasuredQuantityData = MeasuredQuantityDataRecord | MeasuredQuantityDataNew;

/*****************************************************************************/
/* Formula                                                                   */
/*****************************************************************************/
export type FormulaDataRecord = FormulaRecord;
export type FormulaDataNew = Omit<FormulaDataRecord, 'id' | 'formulaVariables'> & {
    formulaVariables?: {
        nodes: FormulaVariableData[];
    };
};
export type FormulaData = FormulaDataRecord | FormulaDataNew;

/*****************************************************************************/
/* Formula Variable                                                          */
/*****************************************************************************/
export type FormulaVariableDataRecord = FormulaVariableRecord;
export type FormulaVariableDataNew = Omit<
    FormulaVariableDataRecord,
    'id' | 'formulaId' | 'measuredQuantity' | 'measuredQuantityId'
> & {
    measuredQuantity: MeasuredQuantityData;
};
export type FormulaVariableData = FormulaVariableDataRecord | FormulaVariableDataNew;

/*****************************************************************************/
/* Assembly                                                                  */
/*****************************************************************************/
export type AssemblyDataRecord = AssemblyRecord & {
    elements?: ElementData[];
};
export type AssemblyDataNew = Omit<AssemblyDataRecord, 'id' | 'ownerId'>;
export type AssemblyData = AssemblyDataRecord | AssemblyDataNew;

/*****************************************************************************/
/* Element                                                                   */
/*****************************************************************************/
export type ElementDataRecord = ElementRecord;
export type ElementDataNew = Omit<
    ElementDataRecord,
    'id' | 'assembly' | 'assemblyId' | 'formula' | 'formulaId' | 'material' | 'materialId'
> & {
    assembly: AssemblyData;
    material: MaterialData;
    formula: FormulaData;
    uom: UomRecord;
};
export type ElementData = ElementDataRecord | ElementDataNew;
