import React, { FC } from 'react';

import { UserTableHeaderCell } from './styled';

const COPY = {
    name: 'Name',
    team: 'Team',
    phone: 'Phone',
    signupMethod: 'Signup Method',
};

export const Header: FC = () => (
    <>
        <UserTableHeaderCell>{COPY.name}</UserTableHeaderCell>
        <UserTableHeaderCell>{COPY.team}</UserTableHeaderCell>
        <UserTableHeaderCell>{COPY.phone}</UserTableHeaderCell>
        <UserTableHeaderCell>{COPY.signupMethod}</UserTableHeaderCell>
        <UserTableHeaderCell></UserTableHeaderCell>
    </>
);
