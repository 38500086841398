/* Stores all the drawing state and returns state variables and setters */
import { fromLeaflet } from '@/common/convert/coordinateData';
import {
    BooleanToolType,
    Geometry,
    GeometryType,
    Nil,
    PlanPage,
    SelectedPoint,
    Setter,
    ToolType,
} from '@/common/types';
import { toUnique, useNilState } from '@/common/utils/helpers';
import {
    activateVerticesObservable,
    activeVerticesObservable,
    addBoundaryGeometriesByIdObservable,
    addVertexObservable,
    addVertexToExistingGeometryObservable,
    booleanGeometryObservable,
    booleanGeometryToolObservable,
    booleanToolObservable,
    boundaryDataObservable,
    deactivateVerticesObservable,
    deleteSelectedGeometriesObservable,
    deleteVerticesObservable,
    deselectAllButOneGeometriesObservable,
    editGeometryByIdObservable,
    geometryCopyMetadataObservable,
    geometryPasteMetadataObservable,
    persistenceFunctionObservable,
    redoObservable,
    selectGeometryObservable,
    undoObservable,
    updateGeometryObservable,
} from '@/components/takeoff/observables/helpers';
import { selectedGeometriesObservable } from '@/components/takeoff/observables/interface';
import { executeProjectPlanPageMutation } from '@/mutations/projectPlanPage';
import { ProjectRecord } from '@/queries/projects';
import { LatLng } from 'leaflet';
import { useCallback, useEffect, useState } from 'react';
import { useSaveGeometryPayload } from './useSaveGeometryPayload';

export type GeometriesHookProps = {
    /* General */
    currentPage: Nil<PlanPage>;
    setCurrentPage: Setter<Nil<PlanPage>>;
    scale: number | null;
    setScale: Setter<number | null>;

    /* Boundaries */
    removeBoundary: () => void;
    addBoundary: (uuid: string) => void;

    /* Manipulation */
    startBooleanOperation: (type: BooleanToolType) => void;
    switchBooleanGeometryToPolygon: () => void;
    switchBooleanGeometryToPolyline: () => void;
    addVertex: (point?: LatLng) => void;
    copy: (origin?: LatLng) => void;
    cut: (origin?: LatLng) => void;
    paste: (origin?: LatLng) => void;
    deleteGeometries: () => void;
    deleteVertices: () => void;

    /* Drawing */
    addGeometryVertex: (point: LatLng) => void;
    undo: () => void;
    redo: () => void;
    activateVertices: (ids: string[]) => void;
    deactivateVertices: (ids: string[]) => void;
    deactivateOtherVertices: (id: string) => void;
    commit: (geometry: Geometry | SelectedPoint) => void;
    deselect: (uuid: string) => void;
    select: (toSelect: Geometry | SelectedPoint, multiple: boolean) => void;
    edit: (uuid: string) => void;
};

export const useGeometries = (project: ProjectRecord): GeometriesHookProps => {
    /**************************************************************************/
    /*                                General                                 */
    /**************************************************************************/
    const [currentPage, setCurrentPage] = useNilState<PlanPage>();

    // Map unit to foot in real world ratio. e.g. 25px = 1ft
    // Storing it this way instead of the other way round will
    // mitigate the need to store floats with many decimal places.
    // The scale DOESN'T have to be an integer, pixel coordinates are, but map
    // units allow for fractions.

    // For the sake of easy testing, this is hardcoded to 25 pixels per 1ft
    // (so 1/4" = 1' 0" for the example plans we ship with the code)
    // The initial state should be null if we want to simulate an uncalibrated
    // plan
    const [scale, setScale] = useState<number | null>(null);

    const sectionGeneral = {
        currentPage,
        setCurrentPage,
        scale,
        setScale,
    };

    /**************************************************************************/
    /*                               Boundaries                               */
    /**************************************************************************/

    // When hovering over geometries or when selecting a group of geometries
    // their bounding box should be displayed
    const removeBoundary = useCallback((): void => {
        boundaryDataObservable.next([]);
    }, []);

    const addBoundary = useCallback((uuid: string): void => {
        addBoundaryGeometriesByIdObservable.next({
            geometryIDs: new Set([uuid]),
            pointIDs: new Set(),
        });
    }, []);

    const sectionBoundary = {
        removeBoundary,
        addBoundary,
    };

    /**************************************************************************/
    /*                              Manipulation                              */
    /**************************************************************************/

    const startBooleanOperation = useCallback((type: BooleanToolType): void => {
        const geometryTypes = toUnique(
            selectedGeometriesObservable.value.geometries.map((g) => g.type)
        );
        if (geometryTypes.length === 1 && geometryTypes[0] === GeometryType.AREA) {
            booleanToolObservable.next(type);
            booleanGeometryToolObservable.next(ToolType.LINEAR);
        }
    }, []);

    const switchBooleanGeometryToPolygon = useCallback((): void => {
        booleanGeometryObservable.next(undefined);
        booleanGeometryToolObservable.next(ToolType.AREA);
    }, []);

    const switchBooleanGeometryToPolyline = useCallback((): void => {
        booleanGeometryObservable.next(undefined);
        booleanGeometryToolObservable.next(ToolType.LINEAR);
    }, []);

    const addVertex = useCallback(
        (coordinates?: LatLng): void => {
            addVertexToExistingGeometryObservable.next(coordinates);
        },
        [scale]
    );

    const copy = useCallback<(o?: LatLng, cut?: boolean) => void>(
        (origin, cutInner = false): void => {
            if (!scale) return;
            geometryCopyMetadataObservable.next({
                origin: origin,
                scale: scale,
                cut: cutInner,
            });
        },
        [scale]
    );

    const cut = useCallback(
        (origin?: LatLng): void => {
            if (!scale) return;
            copy(origin, true);
            selectedGeometriesObservable.next({ geometries: [], points: [] });
            boundaryDataObservable.next([]);
        },
        [scale]
    );

    const paste = useCallback(
        (origin?: LatLng): void => {
            if (!scale || !currentPage) return;

            geometryPasteMetadataObservable.next({
                origin,
                scale,
                currentPage,
            });
        },
        [scale, currentPage]
    );

    const deleteGeometries = useCallback((): void => {
        deleteSelectedGeometriesObservable.next();
        selectedGeometriesObservable.next({ geometries: [], points: [] });
        boundaryDataObservable.next([]);
    }, []);

    const deleteVertices = useCallback((): void => {
        deleteVerticesObservable.next();
    }, []);

    const sectionManipulation = {
        startBooleanOperation,
        switchBooleanGeometryToPolygon,
        switchBooleanGeometryToPolyline,
        addVertex,
        copy,
        cut,
        paste,
        deleteGeometries,
        deleteVertices,
    };

    /**************************************************************************/
    /*                                Drawing                                 */
    /**************************************************************************/

    const addGeometryVertex = useCallback((latlng: LatLng): void => {
        addVertexObservable.next(fromLeaflet(latlng));
    }, []);

    const activateVertices = useCallback((ids: string[]): void => {
        activateVerticesObservable.next(ids);
    }, []);

    const deactivateVertices = useCallback((ids: string[]): void => {
        deactivateVerticesObservable.next(ids);
    }, []);

    const deactivateOtherVertices = useCallback((id: string): void => {
        activeVerticesObservable.next([id]);
    }, []);

    const deselect = useCallback((): void => {
        selectedGeometriesObservable.next({ geometries: [], points: [] });
    }, []);

    const deselectAllButOne = useCallback((uuid: string): void => {
        deselectAllButOneGeometriesObservable.next(uuid);
    }, []);

    const select = useCallback(
        (toSelect: Geometry | SelectedPoint, allowMultiSelect = true): void => {
            selectGeometryObservable.next([toSelect, allowMultiSelect]);
        },
        []
    );

    const edit = useCallback((uuid: string): void => {
        deselectAllButOne(uuid);
        editGeometryByIdObservable.next(uuid);
    }, []);

    const commit = useCallback((geometry: Geometry | SelectedPoint): void => {
        updateGeometryObservable.next(geometry);
    }, []);

    const undo = useCallback((): void => {
        undoObservable.next();
    }, []);

    const redo = useCallback((): void => {
        redoObservable.next();
    }, []);

    const sectionDrawing = {
        addGeometryVertex,
        undo,
        redo,
        activateVertices,
        deactivateVertices,
        deactivateOtherVertices,
        commit,
        deselect,
        select,
        edit,
    };

    /**************************************************************************/
    /*                              Persistence                               */
    /**************************************************************************/
    const saveGeometryPayload = useSaveGeometryPayload({
        currentPage,
        scale,
        projectID: project.id,
        projectUUID: project.uuid,
    });

    const saveScale = useCallback((): void => {
        if (!currentPage || !scale) return;
        executeProjectPlanPageMutation({
            id: parseInt(currentPage.id),
            scale: scale,
        });
    }, [currentPage, scale]);

    useEffect(() => {
        persistenceFunctionObservable.next(saveGeometryPayload);
    }, [saveGeometryPayload]);

    useEffect(() => {
        saveScale();
    }, [scale]);

    /**************************************************************************/
    /*                              Side effects                              */
    /**************************************************************************/

    useEffect(() => {
        if (currentPage?.scale) {
            setScale(currentPage.scale);
        } else {
            setScale(null);
        }
    }, [currentPage]);

    useEffect(() => {
        if (
            selectedGeometriesObservable.value.geometries.length > 0 ||
            selectedGeometriesObservable.value.points.length > 0
        ) {
            removeBoundary();
        }
    }, [selectedGeometriesObservable.value]);

    /**************************************************************************/
    /*                           Combined sections                            */
    /**************************************************************************/
    return {
        ...sectionGeneral,
        ...sectionBoundary,
        ...sectionManipulation,
        ...sectionDrawing,
    };
};
