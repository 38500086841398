import React, { FC } from 'react';

import { IUserFragment } from '@/graphql';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const COPY = {
    welcomeAboardTitle: (company_name: string) => `Welcome aboard ${company_name}`,
    welcomeAboardText: `You’ve just submitted your first project to 1build’s Estimator Network.
    The questions below will be saved to your profile to ensure every project is completed to your standards.`,
    welcomeBackTitle: (builder_name: string) => `Welcome back ${builder_name}`,
    welcomeBackText: `Feel free to make any changes to your profile below.
    These updates will be saved and shared with the 1build team to ensure every project is completed to your standards.`,
    projectDetails: (company_name: string) => `${company_name}’s Project Details`,
};

type TeamLeadUser = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
};

interface WelcomeTextProps {
    user: IUserFragment | TeamLeadUser | undefined;
    isProjectScreen: boolean;
    isAdminOrEstimator: boolean;
    isEmptyBuilderProfile: boolean;
    isPrefilledWithDefaults: boolean;
}

export const WelcomeText: FC<WelcomeTextProps> = ({
    user,
    isProjectScreen,
    isAdminOrEstimator,
    isEmptyBuilderProfile,
    isPrefilledWithDefaults,
}) => {
    const getFullName = (user: IUserFragment | TeamLeadUser | undefined) => {
        if (!user) return '';
        return `${user.firstName || ''} ${user.lastName || ''}`;
    };

    const generateTitle = () => {
        if (isAdminOrEstimator) return COPY.projectDetails(getFullName(user));
        if (isEmptyBuilderProfile) return COPY.welcomeAboardTitle(getFullName(user));
        return COPY.welcomeBackTitle(getFullName(user));
    };

    const generateText = () => {
        if (isEmptyBuilderProfile) return COPY.welcomeAboardText;
        return COPY.welcomeBackText;
    };

    return (
        <Stack direction="column" spacing={4}>
            <Typography variant="h2" align={isProjectScreen ? 'left' : 'center'}>
                {generateTitle()}
            </Typography>
            {isAdminOrEstimator && isPrefilledWithDefaults && (
                <Typography sx={{ fontStyle: 'italic' }}>
                    *Information is based on previous project(s) and has not been updated by
                    builder. Please confirm with builder prior to completing new project.
                </Typography>
            )}
            {!isAdminOrEstimator && (
                <Typography align={isProjectScreen ? 'left' : 'center'}>
                    {generateText()}
                </Typography>
            )}
        </Stack>
    );
};
