import React, { FC } from 'react';

import styled from 'styled-components';

import { ButtonProps } from '@/common/types';
import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';
import { borderCement } from '@/variables';

import { ReactComponent as Rectangle } from '@/assets/icons/Rectangle.svg';

const Container = styled(PrimaryButton)`
    border: ${borderCement};
    box-sizing: border-box;
    border-radius: 4px;
    color: #3d445c;
    background: #ffffff;
    padding: 0 0.8rem;
    border-color: transparent;

    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        text-transform: none;
    }

    svg {
        margin-right: 7px;
        margin-bottom: 2px;
    }
`;

export const ResumeMatchingButton: FC<ButtonProps> = ({ onClick }) => {
    return (
        <Container v2={true} variant="outlined" onClick={onClick}>
            <Rectangle /> Resume matching
        </Container>
    );
};
