/* Provides screen size type updated dynamically on resize */
import { useEffect, useState } from 'react';

import { breakpointMedium, breakpointSmall } from '@/variables';

export enum ScreenSize {
    Mobile,
    Tablet,
    Desktop,
}

const pixelsToNumeric = (pixels: string): number => +pixels.replace('px', '');

const getScreenSize = (windowWidth: number): ScreenSize => {
    const s = pixelsToNumeric(breakpointSmall);
    const m = pixelsToNumeric(breakpointMedium);
    if (windowWidth <= s) return ScreenSize.Mobile;
    if (windowWidth <= m) return ScreenSize.Tablet;
    return ScreenSize.Desktop;
};

export const useScreenSize = (): ScreenSize => {
    const [screen, setScreen] = useState(getScreenSize(window.innerWidth));

    useEffect(() => {
        const handleResize = (): void => {
            setScreen(() => {
                return getScreenSize(window.innerWidth);
            });
        };

        /* Add resize listener to trigger the size change on window resize */
        window.addEventListener('resize', handleResize);

        /* Clean up the listener on ref destroy */
        return (): void => window.removeEventListener('resize', handleResize);
    }, [setScreen]);

    return screen;
};
