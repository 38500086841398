/*
    Manages internal toast notifications, allows for triggering a pop-up message
    with given content.
*/
import { ReactNode, useCallback } from 'react';
import { useToasts, AppearanceTypes } from 'react-toast-notifications';

import { makeContext } from '@/common/utils/makeContext';

export interface Notification {
    title: string | JSX.Element;
    content?: string | JSX.Element;
    link?: string;
}

export interface V2Notification {
    v2Content: JSX.Element;
}

export type NotificationMessage = Notification | string | V2Notification;

export const notificationMessageIsV1 = (m: NotificationMessage | ReactNode): m is Notification =>
    !!m && typeof m === 'object' && Reflect.has(m, 'title');

export const notificationMessageIsV2 = (m: NotificationMessage | ReactNode): m is V2Notification =>
    !!m && typeof m === 'object' && Reflect.has(m, 'v2Content');

export type AddNotification = (
    message: NotificationMessage,
    appearance: AppearanceTypes,
    autoDismiss?: boolean,
    callback?: (id: string) => void
) => void;

export interface NotificationsProps {
    addNotification: AddNotification;
}

const { useConsumer, Provider } = makeContext<NotificationsProps>(() => {
    const { addToast } = useToasts();

    return {
        addNotification: useCallback(
            (
                message: NotificationMessage,
                appearance: AppearanceTypes = 'info',
                autoDismiss = true,
                callback?: (id: string) => void
            ) => addToast(message, { appearance, autoDismiss }, callback),
            [addToast]
        ),
    };
});

export const useNotifications = useConsumer;
export const NotificationsProvider = Provider;
