import { Exchange, Operation, OperationResult } from 'urql';
import { filter, fromPromise, map, merge, mergeMap, pipe, share } from 'wonka';
import { sourceT } from 'wonka/dist/types/src/Wonka_types.gen';

import { BearerToken } from './bearerToken';

export const addTokenToOperation = (operation: Operation, token: string): Operation => {
    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

    return {
        ...operation,
        context: {
            ...operation.context,
            fetchOptions: {
                ...fetchOptions,
                headers: {
                    ...fetchOptions.headers,
                    Authorization: `Bearer ${token}`,
                },
            },
        },
    };
};

// The below exchange is a ported to TS and adjusted to work with Auth0
// version of this gist by an urql contributor:
// https://gist.github.com/kitten/6050e4f447cb29724546dd2e0e68b470
// The premise here is that every request goes through all the exchanges,
// which execute, to put it simply, a chain of operations. This custom
// exchange provisions the fetchOptions that are later used by the
// fetchExchange with an Authorization header.
export const authExchange: Exchange = ({ forward }) => {
    // This object is global in the scope of all exchanges, which allows
    // us to share the auth0 promise between requests - only one will have
    // to be executed at a time.
    let refreshTokenPromise: Promise<string> | null;
    let initialRefreshToken: string | null;

    return (ops$): sourceT<OperationResult> => {
        // We share the operations stream
        const sharedOps$ = pipe(ops$, share);

        const withToken$ = pipe(
            sharedOps$, // Filter by non-teardowns
            // Teardowns are operations that cancel different operations
            // they don't need to have headers because they don't make
            // actual requests.
            filter((operation) => operation.kind !== 'teardown'),
            mergeMap((operation) => {
                // We need to check this for the initial query - if the promise was created when the refresh token
                // was still messed up (or empty), that will never complete, so we need to start a new promise.
                const refreshToken = localStorage.getItem('refreshToken');
                if (initialRefreshToken !== refreshToken) {
                    refreshTokenPromise = null;
                }
                // We only want to get the token if we aren't getting it
                // already.
                if (!refreshTokenPromise) {
                    refreshTokenPromise = BearerToken.getToken();
                    initialRefreshToken = refreshToken;
                }
                return pipe(
                    fromPromise(refreshTokenPromise),
                    map((token) => {
                        // Once we've managed to get the token, we clear
                        // the "global" promise object so that next time
                        // we need a refresh, it works fine.
                        refreshTokenPromise = null;
                        return addTokenToOperation(operation, token);
                    })
                );
            })
        );

        // We don't need to do anything for teardown operations
        const withoutToken$ = pipe(
            sharedOps$,
            filter((operation) => operation.kind === 'teardown')
        );

        return pipe(merge([withToken$, withoutToken$]), forward);
    };
};
