import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { ProjectReview } from '../ProjectReview';
import { useProjects } from '../context';
import {
    getProjectById,
    getProjectsWithNewMessages,
    isBuilderUser,
    isDefaultRolesUser,
    isEstimatorUser,
    isProjectComplete,
    isSaasProject,
} from '@/views/Projects/utils';
import { ReactComponent as TimeIcon } from '@/assets/icons/32/time.svg';
import { ReactComponent as CircleFillIcon } from '@/assets/icons/32/circle-fill.svg';
import { ReactComponent as IconLogoV2Icon } from '@/assets/icons/icon-logo-v2.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/32/copy.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/32/trash-can.svg';
import { PricingType } from '@/common/types';
import { bidsDueDate } from '@/common/utils/bidsDueDate';
import { useUser } from '@/contexts/User';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';
import moment from 'moment';
import { Mode } from '@/theme/Mode';
import React, { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    dueSoon: 'due soon',
    estimatorTag: 'ESTIMATOR',
    hour: 'HOUR',
    hours: 'HOURS',
    newMessage: 'NEW MESSAGE',
    open: 'Open',
    delete: 'Delete',
    publish: 'Complete project',
    unpublish: 'Unpublish project',
    duplicate: 'Duplicate',
};

const getFormattedEstimatorHours = (project: ProjectRecord) => {
    return Number(project.estimatorHours.toFixed(1));
};

const isProjectDueSoon = (project: ProjectRecord) => {
    const dueDate = bidsDueDate(project.bidsDueDate);

    return !isSaasProject(project) && !isProjectComplete(project) && dueDate.isDueSoon();
};

interface ProjectTicketProps {
    project: ProjectWithEventsRecord;
    projects: ProjectWithEventsRecord[];
}

export const ProjectTicket: FC<ProjectTicketProps> = ({ project, projects }) => {
    const { builderAcceptance } = useFlags();

    const {
        data: { user },
    } = useUser();

    const { setProjectToDelete, setProjectToDuplicate } = useProjects();

    const priceType =
        project.pricingAssignment?.projectPricingsByPricingAssignmentId?.nodes[0].pricingType;

    const hasReview = isProjectComplete(project) && isBuilderUser(user) && !isSaasProject(project);
    const hasNewMessage = Boolean(getProjectById(getProjectsWithNewMessages(projects), project.id));

    return (
        <Mode variant="light">
            <Card
                sx={{
                    background: (theme) => theme.palette.hues.neutral[98],
                    minHeight: '88px',
                    padding: '16px',
                }}
            >
                <Stack spacing="8px">
                    {(() => {
                        if (builderAcceptance && priceType === PricingType.DECLINED) {
                            return (
                                <Typography
                                    color="error"
                                    sx={{
                                        textTransform: 'uppercase',
                                    }}
                                    variant="body3"
                                >
                                    Declined
                                </Typography>
                            );
                        }

                        return (
                            <Stack justifyContent="space-between" direction="row">
                                <Stack alignItems="center" direction="row" spacing="8px">
                                    <Typography
                                        color="primary"
                                        sx={{
                                            textTransform: 'uppercase',
                                        }}
                                        variant="body3"
                                    >
                                        {isSaasProject(project)
                                            ? `Created ${moment
                                                  .utc(project.created)
                                                  .local()
                                                  .format('MMM DD')}`
                                            : bidsDueDate(project.bidsDueDate).toDueDateString()}
                                    </Typography>
                                    {isEstimatorUser(user) && isProjectDueSoon(project) && (
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                height: '65%',
                                            }}
                                            variant="middle"
                                        />
                                    )}
                                    {isEstimatorUser(user) && isProjectDueSoon(project) && (
                                        <Stack alignItems="center" direction="row" spacing="8px">
                                            <Typography
                                                color="error"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                }}
                                                variant="body3"
                                            >
                                                {COPY.dueSoon}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                                {!project.isSaas && isDefaultRolesUser(user) && (
                                    <Chip
                                        avatar={
                                            <SvgIcon
                                                sx={{
                                                    marginTop: '-1px',
                                                }}
                                                viewBox="0 0 10 14"
                                            >
                                                <IconLogoV2Icon />
                                            </SvgIcon>
                                        }
                                        label={COPY.estimatorTag}
                                        size="small"
                                        sx={{
                                            cursor: 'pointer',
                                            fontWeight: 600,
                                        }}
                                        variant="filled"
                                    />
                                )}
                            </Stack>
                        );
                    })()}
                    <Typography variant="h4">{project.name}</Typography>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack alignItems="center" direction="row" flex="4" spacing="8px">
                            {!isBuilderUser(user) && (
                                <Stack alignItems="center" direction="row" spacing="8px">
                                    <SvgIcon
                                        sx={{
                                            height: '14px',
                                            width: '14px',
                                        }}
                                        viewBox="0 0 32 32"
                                    >
                                        <TimeIcon />
                                    </SvgIcon>
                                    <Typography variant="body3">
                                        {`${getFormattedEstimatorHours(project)} ${
                                            getFormattedEstimatorHours(project) === 1
                                                ? COPY.hour
                                                : COPY.hours
                                        }`}
                                    </Typography>
                                </Stack>
                            )}
                            {!isBuilderUser(user) && !isProjectComplete(project) && hasNewMessage && (
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        height: '65%',
                                    }}
                                    variant="middle"
                                />
                            )}
                            {!isProjectComplete(project) && hasNewMessage && (
                                <Stack alignItems="center" direction="row" spacing="8px">
                                    <Typography variant="body3">{COPY.newMessage}</Typography>
                                    <SvgIcon
                                        sx={{
                                            color: (theme) => theme.palette.error.main,
                                            height: '10px',
                                            width: '10px',
                                        }}
                                        viewBox="0 0 16 16"
                                    >
                                        <CircleFillIcon />
                                    </SvgIcon>
                                </Stack>
                            )}
                        </Stack>
                        <Stack direction="row" flex="1" justifyContent="flex-end" spacing="2px">
                            {/*We only want platform users to have the option to duplicate projects*/}
                            {isBuilderUser(user) && isEstimatorUser(user) && (
                                <IconButton
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        setProjectToDuplicate(project);
                                    }}
                                >
                                    <SvgIcon
                                        sx={{
                                            height: '14px',
                                            width: '14px',
                                        }}
                                        viewBox="0 0 32 32"
                                    >
                                        <CopyIcon />
                                    </SvgIcon>
                                </IconButton>
                            )}
                            {isSaasProject(project) && (
                                <IconButton
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        setProjectToDelete(project);
                                    }}
                                >
                                    <SvgIcon
                                        sx={{
                                            height: '14px',
                                            width: '14px',
                                        }}
                                        viewBox="0 0 32 32"
                                    >
                                        <TrashCanIcon />
                                    </SvgIcon>
                                </IconButton>
                            )}
                        </Stack>
                    </Stack>
                    {hasReview && <ProjectReview project={project} />}
                </Stack>
            </Card>
        </Mode>
    );
};
