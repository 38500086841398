/*
 * Info is a simple clickable tab within the projects panel's navbar.
 */
import React, { FC } from 'react';

import { PanelInfoTab, PanelInfoTabText, PanelInfoTabUnderline } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { InfoTab } from '@/components/AdminDashboard/types';

const COPY = {
    activity: 'Activity',
    files: 'Files',
    info: 'Info',
};

export const mapInfoTabToText = (tab: InfoTab): string => {
    switch (tab) {
        case InfoTab.Activity:
            return COPY.activity;
        case InfoTab.Files:
            return COPY.files;
        default:
        case InfoTab.Info:
            return COPY.info;
    }
};

export interface TabProps extends ProjectPanelComponentProps {
    type: InfoTab;
}

export const Info: FC<TabProps> = ({ useProjectPanel, type }) => {
    const { activeTab, setActiveTab } = useProjectPanel();

    return (
        <PanelInfoTab onClick={(): void => setActiveTab(type)}>
            <PanelInfoTabText>{mapInfoTabToText(type)}</PanelInfoTabText>
            <PanelInfoTabUnderline active={activeTab === type} />
        </PanelInfoTab>
    );
};
