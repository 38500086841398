// To align with urql's types, we have to use the `extends object = {}` pattern.
/* eslint-disable @typescript-eslint/ban-types */
/*
 * requests.ts provides second-order functions for creating gql request helpers
 */
import { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import {
    OperationContext,
    useMutation,
    UseMutationResponse,
    useQuery,
    UseQueryResponse,
} from 'urql';

import {
    FrogCondition,
    FrogExecutor,
    FrogMutationHook,
    FrogQueryCondition,
    FrogQueryHook,
    FrogResult,
} from './types';
import { Client } from '../common/urql/client';
import { pipe, take, toPromise } from 'wonka';

/*****************************************************************************/
/* Mutations                                                                 */
/*****************************************************************************/

export const newMutationExecutor =
    <Args extends object, Result extends object>(
        mutation: DocumentNode
    ): FrogExecutor<Args, Result> =>
    (input: Args): FrogResult<Result> =>
        // This code used to be much simpler:
        // Client.getClient().mutation<Result, Args>(
        //     mutation,
        //     input
        // ).toPromise()
        //
        // But because we sometimes make multiple mutations with the exact same
        // parameters - like creating a bunch of MeasuredQuantities at the same time,
        // the automatically generated `key` of the GraphQLRequest was always the same,
        // meaning that N mutations with the same parameters made at the same time would
        // all return the same result of the first mutation from the batch that went through.
        // So, the code below is a rewrite of pretty much exactly what that original shortcut
        // function did, but with a custom, fully random `key`.
        pipe(
            Client.getClient().executeMutation<Result, Args>({
                key: Math.floor(Math.random() * Number.MAX_VALUE),
                query: mutation,
                variables: input,
            }),
            take(1),
            toPromise
        );

export const newMutationHook =
    <Args extends object, Result extends object>(
        mutation: DocumentNode
    ): FrogMutationHook<Args, Result> =>
    (): UseMutationResponse<Result, Args> =>
        useMutation<Result, Args>(mutation);

export const newMutations = <Args extends object, Result extends object>(
    mutation: DocumentNode
): [FrogExecutor<Args, Result>, FrogMutationHook<Args, Result>] => [
    newMutationExecutor<Args, Result>(mutation),
    newMutationHook<Args, Result>(mutation),
];

/*****************************************************************************/
/* Queries                                                                   */
/*****************************************************************************/

export const newQueryExecutor =
    <Condition extends object, Result extends object>(
        query: DocumentNode,
        rootOptions?: Partial<OperationContext>
    ): FrogExecutor<Condition, Result> =>
    (condition: Condition, options?: Partial<OperationContext>): FrogResult<Result> =>
        Client.getClient()
            .query<Result, FrogCondition<Condition>>(
                query,
                { condition },
                {
                    ...options,
                    ...rootOptions,
                }
            )
            .toPromise();

// Using `context` in `useQuery` results in an infinite loop without `useMemo`.
// https://github.com/FormidableLabs/urql/issues/507
export const newQueryHook =
    <Condition extends object, Result extends object>(
        query: DocumentNode,
        rootContextOptions?: Partial<OperationContext>
    ): FrogQueryHook<Condition, Result> =>
    (condition: Condition, options?: FrogQueryCondition<Condition>): UseQueryResponse<Result> =>
        useQuery<Result>({
            ...options,
            query,
            variables: { condition },
            context: useMemo(
                () => ({
                    ...options?.context,
                    ...rootContextOptions,
                }),
                []
            ),
        });

export const newQueries = <Condition extends object, Result extends object>(
    query: DocumentNode,
    options?: Partial<OperationContext>
): [FrogExecutor<Condition, Result>, FrogQueryHook<Condition, Result>] => [
    newQueryExecutor<Condition, Result>(query, options),
    newQueryHook<Condition, Result>(query, options),
];
