/// <reference types="google.maps" />

import React, { FC, FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

import { CustomPacGlobalStyle } from './styled';
import { Env } from '@/common/env';
import { InputProps } from '@/common/types';
import { Input } from '../Input';

export { CustomPacGlobalStyle } from './styled';

const COPY = {
    projectLocationPlaceholder: 'Street address, city, state, or zip',
};

export type LocationInputProps = InputProps & {
    address?: string;
    className?: string;
    menuOffsetTop?: number;
    overrideWidth?: number;
    placeholder?: string;
    selectOnFocus?: boolean;
    onEnter?: (newAddress: google.maps.places.PlaceResult | null, lat: number, lng: number) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    setAddress?: (newAddress: google.maps.places.PlaceResult | null) => void;
    setCoordinates?: (lat: number, lng: number) => void;
};

export const LocationInput: FC<LocationInputProps> = ({
    address,
    autoFocus,
    className,
    menuOffsetTop,
    overrideWidth,
    placeholder = COPY.projectLocationPlaceholder,
    selectOnFocus = true,
    onEnter,
    onFocus,
    setAddress,
    setCoordinates,
}) => {
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [value, setValue] = useState<string>('');
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [libraries] = useState<Libraries>(['places']);

    const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            if (onFocus) {
                onFocus(e);
            }
            if (selectOnFocus && autoCompleteRef.current) {
                autoCompleteRef.current.select();
            }
        },
        [autoCompleteRef]
    );

    // When the component is initialized with the address prop, we want this value to be visible to the user.
    // It's useful for editing an existing value.
    useEffect(() => {
        if (address) {
            setValue(address);
        }
    }, [address]);

    // Any time the user selects a place from the dropdown, we trigger the setters to indicate that a choice has been
    // made.
    useEffect(() => {
        if (place?.formatted_address && place.geometry?.location) {
            setCoordinates?.(place.geometry.location.lat(), place.geometry.location.lng());
            setAddress?.(place);
            setValue(place.formatted_address);
            onEnter?.(place, place.geometry.location.lat(), place.geometry.location.lng());
        }
    }, [place]);

    // The google script is lazily loaded, so we need to make sure it's done before doing anything else.
    const handleScriptLoad = (): void => {
        setScriptLoaded(true);
    };

    // Reset on blur if a valid address is not selected
    const resetOnBlur = (): void => setValue(address || '');

    // Once the script is loaded and we have a ref on the input, we initialize the autocomplete on it, configure it
    // and start tracking changes.
    useEffect(() => {
        if (!scriptLoaded || !autoCompleteRef || !autoCompleteRef.current) {
            return;
        }
        const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
            componentRestrictions: { country: 'us' },
        });
        autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
        autoComplete.addListener('place_changed', (): void => setPlace(autoComplete.getPlace()));
    }, [scriptLoaded, autoCompleteRef, autoCompleteRef.current]);

    return (
        <>
            <CustomPacGlobalStyle overrideWidth={overrideWidth} menuOffsetTop={menuOffsetTop} />
            <LoadScript
                googleMapsApiKey={Env.googlePlacesApiKey}
                libraries={libraries}
                onLoad={handleScriptLoad}
                preventGoogleFontsLoading
            >
                <div className={clsx('search-location-input', className)}>
                    <Input
                        autoFocus={autoFocus}
                        ref={autoCompleteRef}
                        onChange={(newValue): void => {
                            setAddress?.(null);
                            setPlace(null);
                            setValue(newValue);
                        }}
                        placeholder={placeholder}
                        value={value}
                        onBlur={resetOnBlur}
                        onFocus={handleFocus}
                    />
                </div>
            </LoadScript>
        </>
    );
};
