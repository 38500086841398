/* Allows for messaging to the project’s other members via the Event Log. */
import { ProjectDetailsComponentProps } from '../context';
import { Comment } from './Comment';
import './DiscussionsPanel.scss';
import { UploadIndicator } from './UploadIndicator';
import { UploadedFiles } from './UploadedFiles';
import { ReactComponent as PlusSign } from '@/assets/icons/plus.svg';
import { ReactComponent as Greeting } from '@/assets/images/greeting.svg';
import { useChat } from '@/common/hooks/useChat';
import { useIsMobile } from '@/common/hooks/useIsMobile';
import { useSlateMarkdown } from '@/common/hooks/useSlateMarkdown';
import { emptySlateParagraph } from '@/common/slate';
import { DatabaseProjectStatus, ProjectUploadFileNew } from '@/common/types';
import { SmallButton } from '@/components/ui/buttons/SmallButton';
import { FileUpload } from '@/components/ui/inputs/FileUpload';
import { RichText } from '@/components/ui/inputs/RichText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PutOutput, useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import { EventFullRecord } from '@/queries/events';
import isEqual from 'lodash/isEqual';
import React, { FC, FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

const COPY = {
    emptyChatTitle: 'Say hello!',
    emptyChatMessage: `Use this space to leave comments about the project, and
        to share project related materials.`,
};

const MobileInput = styled.input`
    border: none;
    width: 100%;
    padding: 0;

    &:focus {
        outline: none;
    }
`;

interface BlankMobileInputProps {
    setValue: (newVal: string) => void;
}

const BlankMobileInput: FC<BlankMobileInputProps> = ({ setValue }) => {
    const [value, setInnerValue] = useState('');

    return (
        <MobileInput
            onChange={(e): void => {
                setInnerValue(e.target.value);
                setValue(e.target.value);
            }}
            value={value}
            type="text"
        />
    );
};

export const DiscussionsPanel: FC<ProjectDetailsComponentProps> = ({ useProjectDetails }) => {
    const { project } = useProjectDetails();
    const { editor, nodes, setNodes, setValue, resetValue, getMarkdown, isBlank } =
        useSlateMarkdown('');
    const { builderAcceptance } = useFlags();

    const { messages, addMessage, removeMessage, updateMessage } = useChat(project);

    const isMobile = useIsMobile();
    const {
        data: {
            user: { id: ownerId, roles },
        },
    } = useUser();
    const { upload, remove } = useStorage();
    const [files, setFiles] = useState<ProjectUploadFileNew[]>([]);
    const [uploading, setUploading] = useState(false);
    const panelRef = useRef<HTMLDivElement>(null);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const userIs = (role: IUserRole): boolean => roles.includes(role);
    const projectIs = (match: DatabaseProjectStatus): boolean => match === project.status;

    const disableChat = (): boolean => {
        let status = false;

        if (builderAcceptance) {
            if (userIs(IUserRole.Builder) && !userIs(IUserRole.Admin)) {
                status = false;
            }
        }

        // chat is disabled for any user if project is in CANCELED status
        if (projectIs(DatabaseProjectStatus.CANCELED)) {
            status = true;
        }

        return status;
    };

    // Changing the key of a child resets its state. We use this to reset the mobile message on
    // submit by incrementing its key.
    const [mobileInputKey, setMobileInputKey] = useState(0);
    const resetMessage = (): void => {
        resetValue();
        setMobileInputKey((k) => ++k);
    };

    const scrollToPanelBottom = (): void => {
        if (!panelRef.current) return;
        panelRef.current.scrollTop = panelRef.current.scrollHeight;
    };

    const handleSubmit = (e?: FormEvent<HTMLFormElement>): void => {
        e?.preventDefault();
        if (
            (nodes.length === 0 ||
                (nodes.length === 1 && isEqual(nodes[0], emptySlateParagraph))) &&
            files.length === 0
        )
            return;

        addMessage(ownerId.toString(), project.id.toString(), getMarkdown(), files);
        resetMessage();
        setFiles([]);
    };

    /* Submit form on enter press */
    const handleKeydown = (e: KeyboardEvent<HTMLFormElement>): void => {
        if (e.key !== 'Enter' || e.getModifierState('Shift')) {
            return;
        }
        if (isBlank()) {
            e.preventDefault();
            return;
        }
        handleSubmit(e);
    };

    const handleUploadFilePress = (): void => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const handleRemoveFile = (file: ProjectUploadFileNew): void => {
        if (file.filename === undefined) {
            return;
        }
        remove(file.filename, project.uuid, file.uuid, 'uploads');
        setFiles(files.filter((f) => f.filename !== file.filename));
    };

    const handleFileInputChange = (): void => {
        if (!fileUploadRef.current) return;
        // Get the list from files from input
        const fileList = Array.from(fileUploadRef?.current?.files || []);
        if (fileList && fileList.length > 0) {
            const uploads: Promise<PutOutput>[] = [];
            const uploadMap: { [key: string]: string } = {};
            setUploading(true);

            // For every new file, upload it and save the promise in a list
            const newFiles = fileList.filter(
                (sf) => !files.map((f) => f.filename).includes(sf.name)
            );
            newFiles.forEach((file) => {
                const fileUuid = uuid();
                uploads.push(upload(file.name, project.uuid, fileUuid, 'uploads', file));
                uploadMap[fileUuid] = file.name;
                uploadMap[file.name] = fileUuid;
            });

            Promise.all(uploads)
                // Once all the promises have finished, setUploading to false
                // and save the files in state for rendering.
                .then((outputs: PutOutput[]) => {
                    const newUploads = outputs.map<ProjectUploadFileNew>((o) => {
                        const lastPart = o.Location?.split('/').pop() as string;
                        return {
                            filename: uploadMap[lastPart],
                            uuid: lastPart,
                            __typename: 'ProjectUploadFile',
                        };
                    });
                    setFiles([...files, ...newUploads]);
                    setUploading(false);
                })
                // If something went wrong, go through the files that managed
                // to get uploaded and remove them, to keep things clean.
                // Also ignore any failed ones, as they are the ones that were
                // aborted.
                .catch((_) => {
                    uploads.forEach((u) => {
                        u.then((output) => {
                            const [uuid, filename] = output.Location?.split('/').slice(
                                -2
                            ) as string[];
                            handleRemoveFile({
                                filename,
                                uuid,
                                __typename: 'ProjectUploadFile',
                            });
                        }).catch((_) => {
                            // do nothing
                        });
                    });
                });
        }
        // Reset the input value so that onChange is called even if the user
        // selects the same file again (useful if they deleted the last file
        // from the list and want to add it again).
        fileUploadRef.current.value = '';
    };

    const cancelUploading = (): void => {
        setUploading(false);
    };

    /* Scroll to panel bottom if comments changed */
    useEffect(scrollToPanelBottom, [panelRef, messages, files]);

    const renderEmptyInfo = (): JSX.Element => (
        <div className="empty-chat">
            <Greeting />
            <h2>{COPY.emptyChatTitle}</h2>
            <p className="body-l">{COPY.emptyChatMessage}</p>
        </div>
    );

    const renderMessage = (message: EventFullRecord): JSX.Element => (
        <Comment
            key={message.id}
            message={message}
            removeMessage={(): Promise<void> => removeMessage(message.id)}
            saveMessage={(newMessage: string): Promise<void> =>
                updateMessage({
                    ...message,
                    message: newMessage,
                })
            }
            useProjectDetails={useProjectDetails}
        />
    );

    return (
        <>
            <div className="discussions-panel-v2" ref={panelRef}>
                {messages.length === 0 ? renderEmptyInfo() : messages.map(renderMessage)}
            </div>
            {!disableChat() && (
                <form
                    className="discussions-form-v2"
                    onKeyDown={handleKeydown}
                    onSubmit={handleSubmit}
                >
                    {uploading && <UploadIndicator onCancel={cancelUploading} />}
                    <div className="input-container">
                        {isMobile ? (
                            <BlankMobileInput setValue={setValue} key={mobileInputKey} />
                        ) : (
                            <RichText
                                editor={editor}
                                value={nodes}
                                onChange={setNodes}
                                placeholder="Say something..."
                            />
                        )}
                        {files.length > 0 && (
                            <UploadedFiles
                                files={files}
                                onFileRemove={handleRemoveFile}
                                projectUuid={project.uuid}
                            />
                        )}
                    </div>
                    <FileUpload onChange={handleFileInputChange} ref={fileUploadRef} multiple />
                    <div className="discussions-buttons-wrapper">
                        <div className="discussions-buttons">
                            <SmallButton
                                variant="outlined"
                                className="form-control"
                                onClick={handleUploadFilePress}
                            >
                                <PlusSign />
                                <span>FILE</span>
                            </SmallButton>
                            <SmallButton className="form-control discussions-buttons__post">
                                Post
                            </SmallButton>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};
