/**
 * A button to commit actions related to the primary purpose of a screen.
 */
import clsx from 'clsx';
import React, { FC } from 'react';

import { Container } from './styled';
import { useDisableAfterClick } from '../helpers/useDisableAfterClick';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { ButtonProps, Svg } from '@/common/types';

import './PrimaryButton.scss';

export * from './styled';

export type ButtonVariant = 'solid' | 'outlined' | 'bulky';

export type PrimaryButtonProps = ButtonProps & {
    variant?: ButtonVariant;
    color?: 'primary' | 'green' | 'gray' | 'red';
    Icon?: Svg;
    v2?: boolean;
};

export const PrimaryButton: FC<PrimaryButtonProps> = ({
    className,
    variant = 'solid',
    color = 'primary',
    children,
    Icon,
    onClick,
    disabled,
    v2,
    ...rest
}) => {
    const [wrappedOnClick, wrappedDisabled] = useDisableAfterClick(onClick);

    return v2 ? (
        <Container
            hasIcon={!!Icon}
            className={className}
            tabIndex={0}
            variant={variant}
            disabled={disabled}
            onClick={wrappedOnClick}
        >
            {Icon && <Icon />}
            <span>{children}</span>
        </Container>
    ) : (
        <button
            className={clsx(className, 'primary-button', {
                solid: variant === 'solid',
                outlined: variant === 'outlined',
                bulky: variant === 'bulky',
                primary: color === 'primary',
                green: color === 'green',
                gray: color === 'gray',
                red: color === 'red',
            })}
            onClick={wrappedOnClick}
            disabled={disabled || wrappedDisabled}
            {...rest}
        >
            {Icon && <SvgIcon src={Icon} noInherit={true} />}
            <span>{children}</span>
        </button>
    );
};
