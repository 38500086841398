/*
 * Projects is the segment of the admin dashboard where projects are listed.
 */
import React, { FC } from 'react';

import { Container } from './Container';

import { ProjectPanel } from '@/components/AdminDashboard/ProjectPanel';

export const Projects: FC = () => (
    <>
        <Container />
        <ProjectPanel />
    </>
);
