import gql from 'graphql-tag';

export type ToggleFeatureFlagResult = {
    toggleFeatureFlag: {
        featureFlag: {
            id: string;
            isEnabled: boolean;
            message: string;
        };
    };
};

export const toggleFeatureFlagMutation = gql`
    mutation ToggleFeatureFlag(
        $id: BigInt!
        $isEnabled: Boolean!
        $userId: BigInt!
        $tier: String!
    ) {
        toggleFeatureFlag(input: { id: $id, isEnabled: $isEnabled, userId: $userId, tier: $tier }) {
            featureFlag {
                id
                isEnabled
                message
            }
        }
    }
`;
