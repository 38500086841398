/**
 * sort.ts provides helpers for sorting records
 */

interface CreatedRecord {
    created?: string | Date;
}

/**
 * compareRecordCreation sorts records with (optional) `created` props.
 * It is intended to be used as an array's `sort` function.
 * @param a record with a `created` prop
 * @param b record with a `created` prop
 */
export const compareRecordCreation = (a: CreatedRecord, b: CreatedRecord): number =>
    new Date(a.created ?? '').getTime() - new Date(b.created ?? '').getTime();
