import Box from '@mui/material/Box';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useNotifications } from '@/contexts/Notifications';
import { ErrorMessage } from '@/components/notifications/Notification';
import { SubmitButton } from './styled';
import { createSaasProject, useProjectSetup } from '@/common/hooks/useProjectSetup';
import { DIY } from '@/components/projects/ProjectCreation/DIY';
import { ProjectConfigurationForm } from '@/components/projects/ProjectCreation/ProjectConfigurationForm';
import { useUser } from '@/contexts/User';
import { IProjectLocationAssignmentInput, IUserRole } from '@/graphql';
import { ProjectRecord } from '@/queries/projects';
import { useStorage } from '@/contexts/Storage';

const COPY = {
    submitProject: 'Submit',
    cancelProjectSetup: 'Cancel',
    projectSetup: 'Project setup',
    createProjectSuccessTitle: 'Success.',
    createProjectSuccessMsg: 'Project created successfully.',
    createProjectErrorTitle: 'Error.',
    createProjectErrorMsg: 'Unable to create project.',
};

interface ProjectCreationProps {
    initProject?: ProjectRecord;
    initProjectLocation?: Omit<IProjectLocationAssignmentInput, 'projectID'> | null;
}
export const ProjectCreation: FC<ProjectCreationProps> = ({ initProject, initProjectLocation }) => {
    const history = useHistory();
    // Contexts
    /*-------------------*/
    const {
        data: { user },
    } = useUser();
    const { currentUploads } = useStorage();
    const {
        configuredProject,
        setConfiguredProject,
        projectLocation,
        setProjectLocation,
        plans,
        setPlans,
        projectSubmissionLoading,
        showErrors,
        submitConfiguredProject,
        handleSubmitButtonClick,
        projectSubmitLoading,
    } = useProjectSetup(initProject, initProjectLocation ?? null);

    const { addNotification } = useNotifications();

    // State
    /*-------------------*/
    const saasByDefault = false;

    const [isDIYScreenShowing, setIsDIYScreenShowing] = useState<boolean>(
        !initProject &&
            !saasByDefault &&
            user.roles.includes(IUserRole.Estimator) &&
            user.roles.includes(IUserRole.Builder)
    );

    const onSelectDIY = () => {
        submitConfiguredProject(createSaasProject())
            .then((res) => {
                addNotification(
                    {
                        title: COPY.createProjectSuccessTitle,
                        content: <p>{COPY.createProjectSuccessMsg}</p>,
                    },
                    'success'
                );

                history.push(`/projects/${res.uuid}/estimate`);
            })
            .catch(() => {
                addNotification(
                    {
                        title: COPY.createProjectErrorTitle,
                        content: <ErrorMessage message={COPY.createProjectErrorMsg} />,
                    },
                    'error'
                );
            });
    };

    const onSelectStandard = () => {
        setIsDIYScreenShowing(false);
    };

    return (
        <Box>
            <div
                className={clsx(
                    isDIYScreenShowing &&
                        'project-configuration-container project-configuration-container--diy',
                    !isDIYScreenShowing &&
                        'container slim-container project-configuration-container'
                )}
            >
                {isDIYScreenShowing ? (
                    <DIY onSelectDIY={onSelectDIY} onSelectStandard={onSelectStandard} />
                ) : (
                    <>
                        <ProjectConfigurationForm
                            isNew={!initProject}
                            project={configuredProject}
                            showErrors={showErrors}
                            plans={plans}
                            setPlans={setPlans}
                            updateProject={setConfiguredProject}
                            projectLocation={projectLocation}
                            setProjectLocation={setProjectLocation}
                            loading={projectSubmissionLoading}
                        />
                        <SubmitButton
                            key="submit-project"
                            id="project-creation-submit-project"
                            variant="contained"
                            disabled={projectSubmitLoading || currentUploads.length > 0}
                            onClick={handleSubmitButtonClick}
                        >
                            {COPY.submitProject}
                        </SubmitButton>
                    </>
                )}
            </div>
        </Box>
    );
};
