import React, { FC, useState } from 'react';
import Collapse, { CollapseProps } from '@mui/material/Collapse';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as CaretDownIcon } from '@/assets/icons/32/caret--down.svg';
import { ReactComponent as CaretRightIcon } from '@/assets/icons/32/caret--right.svg';
import SvgIcon from '@mui/material/SvgIcon';

interface ListItemButtonWithCollapseProps extends ListItemButtonProps {
    collapseProps?: CollapseProps;
    collapseInStart?: boolean;
    label?: string;
}

export const ListItemButtonWithCollapse: FC<ListItemButtonWithCollapseProps> = ({
    children,
    collapseProps,
    collapseInStart,
    label,
    ...props
}) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(collapseInStart || false);

    return (
        <div>
            <ListItemButton
                {...props}
                onClick={(event) => {
                    setIsCollapseOpen(!isCollapseOpen);

                    props.onClick?.(event);
                }}
            >
                <ListItemIcon sx={{ marginRight: '8px' }}>
                    <SvgIcon
                        color="secondary"
                        sx={{
                            height: 'auto',
                            width: 16,
                        }}
                        viewBox="0 0 32 32"
                    >
                        {isCollapseOpen ? <CaretDownIcon /> : <CaretRightIcon />}
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    color="secondary"
                    primary={label}
                    primaryTypographyProps={{
                        variant: 'body2',
                        sx: {
                            fontWeight: 'bold',
                        },
                    }}
                />
            </ListItemButton>
            <Collapse in={isCollapseOpen} mountOnEnter {...collapseProps}>
                {children}
            </Collapse>
        </div>
    );
};
