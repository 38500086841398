/*
 * Sidebar is the side bar of the admin dashboard.
 */
import React, { FC, useCallback } from 'react';

import styled from 'styled-components';

import { Links } from './Links';
import { MissionControl } from './MissionControl';

import { DivProps } from '@/common/types';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import {
    adminDashboardMobileSidebarShadow,
    breakpointMedium,
    colorTypography,
    gradientAdminDashboardMobileSidebar,
} from '@/variables';

import { ReactComponent as CloseCross } from '@/assets/icons/close-cross-thin.svg';
import { ReactComponent as LogoAdminDashboard } from '@/assets/images/full-logo-v2.svg';

const SidebarContainer = styled.div`
    grid-area: sidebar;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 0 0 17.5rem;
    padding: 0;
    margin: 0;
    z-index: 3;

    @media (max-width: ${breakpointMedium}) {
        position: fixed;
        top: 0;
        left: -20rem;
        width: 17.5rem;
        background: ${gradientAdminDashboardMobileSidebar};
        box-shadow: ${adminDashboardMobileSidebarShadow};
    }
`;

const SidebarTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
`;

const CloseCrossContainer = styled.div`
    margin: 1.75rem 0 2.375rem 0.875rem;
    cursor: pointer;
`;

const LogoContainer = styled.div`
    margin: 1.875rem 0 2rem 1.375rem;
`;

export const SidebarClickInterceptor = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 2;
`;

export interface SidebarProps extends DivProps {
    onClose?: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ onClose, ...rest }) => {
    const {
        data: { user },
    } = useUser();

    const renderIcon = useCallback(() => {
        if (onClose) {
            return (
                <CloseCrossContainer onClick={onClose}>
                    <CloseCross fill={colorTypography} />
                </CloseCrossContainer>
            );
        }
        return (
            <LogoContainer>
                <LogoAdminDashboard />
            </LogoContainer>
        );
    }, [onClose]);

    return (
        <>
            <SidebarContainer {...rest}>
                <SidebarTopContainer>
                    {renderIcon()}
                    <Links />
                </SidebarTopContainer>
                {user.roles.includes(IUserRole.Superadmin) && <MissionControl />}
            </SidebarContainer>
            {onClose && <SidebarClickInterceptor onClick={onClose} />}
        </>
    );
};
