/* Displays user's avatar and name */

import React, { FC } from 'react';
import styled from 'styled-components';

import { Avatar } from '../icons/Avatar';
import { BaseUser } from '@/common/types';
import { ReactComponent as Clock } from '@/assets/icons/clock-full.svg';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';

interface UserProps {
    user: BaseUser;
    isGhosted?: boolean;
}

export const User: FC<UserProps> = ({ user, isGhosted = false }) => (
    <Container className="user">
        <AvatarContainer>
            {isGhosted && (
                <SvgIcon
                    src={Clock}
                    style={{
                        position: 'absolute',
                        top: '13px',
                        left: '-1px',
                        zIndex: 1,
                    }}
                />
            )}
            <AvatarWrapper
                diameter="1.5rem"
                user={mapBaseUserToIUserFragment(user)}
                $ghosted={isGhosted}
            />
        </AvatarContainer>
        <Name ghosted={isGhosted}>
            {user.firstName} {user.lastName}
        </Name>
    </Container>
);

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const AvatarContainer = styled.div`
    position: relative;
`;

const AvatarWrapper = styled(Avatar)<{ $ghosted: boolean }>`
    margin-right: 0.5rem;
    font-size: 0.75rem;
    filter: ${(props): string => (props.$ghosted ? 'grayscale(70%)' : 'grayscale(70%)')};
    opacity: ${(props): string => (props.$ghosted ? '0.6' : '1')};
`;

const Name = styled.span<{ ghosted: boolean }>`
    line-height: 1;
    color: ${(props): string => (props.ghosted ? '#A4A7AE' : '#4C5C6C')};
`;
