import { useEffect, useState } from 'react';

export const useDebouncedValue = <T>(value: T, timeout: number): T => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const sto = setTimeout(() => setDebouncedValue(value), timeout);
        return (): void => clearTimeout(sto);
    }, [value, timeout]);

    return debouncedValue;
};
