import { useEffect } from 'react';
import { useEstimateEditor } from '@/components/Estimate/hooks/useEstimateEditor';
import { useEstimateUoms } from '@/components/Estimate/hooks/useEstimateUoms';
import { Queries } from '@/components/Estimate/queries';
import { Serialize } from '@/components/Estimate/serialization';
import { TokenKind } from '@/graphql/customScalars';
import { Transforms } from '@/components/Estimate/transforms';
import { useCoordinates } from 'leyden-react';
import { useApolloClient } from '@apollo/client';
import { Cell, CellType } from 'leyden';
import { UomRecord } from '@/queries/unitOfMeasure';
import { CellDialogOptions } from '@/components/Estimate/Table/contexts/useCellDialog';
import useFormElement, {
    UseFormElementPayload,
} from '@/components/Estimate/Table/components/CellDialog/CellForm/useFormElement';
import useRatesInputs, {
    UseRatesInputsPayload,
} from '@/components/Estimate/Table/components/CellDialog/CellForm/useRatesInputs';

interface useCellReducerPayload {
    inputs: {
        name: UseFormElementPayload<HTMLInputElement>;
        quantity: UseFormElementPayload<HTMLInputElement>;
        totalRate: UseFormElementPayload<HTMLInputElement>;
        unit: UseFormElementPayload<HTMLSelectElement>;
    } & UseRatesInputsPayload;
    total: number;
    uoms: UomRecord[];
    handleSubmitItem: () => Promise<void>;
    handleSubmitGroup: () => Promise<void>;
    isAdding: boolean;
    isGroup: boolean;
}

interface UseCellFormStateProps {
    cell: Cell<CellType>;
    projectID: string;
    explicitCellType: CellDialogOptions['explicitCellType'];
}

const useCellFormState = (props: UseCellFormStateProps): useCellReducerPayload => {
    const { cell, projectID, explicitCellType } = props;

    const client = useApolloClient();
    const coords = useCoordinates(cell);
    const editor = useEstimateEditor();
    const { uoms, defaultUomID } = useEstimateUoms();

    const isAdding = !!explicitCellType;
    const isGroup = Cell.isCell(cell, { type: 'Category' }) || explicitCellType === 'group';

    let element;
    let category;

    if (isGroup) {
        const categoriesQueryResult = Queries.category(editor, cell.id as string);
        category = categoriesQueryResult ? categoriesQueryResult[0] : undefined;
    } else {
        const elementQueryResult = Queries.element(editor, cell.elementID as string);
        element = elementQueryResult ? elementQueryResult[0] : undefined;
    }

    const elementID = !isGroup ? element?.elementID : undefined;

    const cellName = isGroup ? category?.name : element?.name;

    const defaultName = !isAdding ? cellName : '';
    const name = useFormElement<HTMLInputElement>(defaultName);

    const defaultQuantity = !isAdding ? element?.expressionResult.toString() : '0';
    const quantity = useFormElement<HTMLInputElement>(defaultQuantity);

    const defaultUnit = !isAdding ? element?.unitID : defaultUomID ?? undefined;
    const unit = useFormElement<HTMLSelectElement>(defaultUnit);

    const { totalRate, isCalculatedRate, rates } = useRatesInputs({
        isAdding,
        rates: element?.rates,
    });

    const uom = uoms.find((uom) => uom.id.toString() === unit?.value);
    const uomId = uom?.id.toString() ?? null;

    const parsedRate = Number(totalRate.value);
    const parsedQuantity = Number(quantity.value);

    const total = parsedQuantity * parsedRate;
    const validatedTotal = Number.isNaN(total) ? 0 : total;

    const quantityToken = Serialize.Token.Scalar(TokenKind.Numeric, {
        quantity: parsedQuantity,
    });

    const handleAddItem = async () => {
        await Transforms.addNewBlankElement(editor, {
            name: name.value ?? '',
            categoryID: cell.categoryID,
            materialID: null,
            rates: {
                // Save as cents
                materialRate: Number(rates.materialRate?.value) * 100,
                laborRate: Number(rates.laborRate?.value) * 100,
                productionRate: Number(rates.productionRate?.value),
            },
            uom: uomId,
            projectID,
            takeoffUnitID: uomId ?? '',
            expression: {
                tokens: [quantityToken],
            },
            client,
        });
    };

    const handleEditItem = async () => {
        if (!coords || !elementID) {
            return;
        }

        const input = {
            name: name.value ?? '',
            rate: {
                // Save as cents
                materialRate: Number(rates.materialRate?.value) * 100,
                laborRate: Number(rates.laborRate?.value) * 100,
                productionRate: Number(rates.productionRate?.value),
            },
            unitID: uomId,
            expression: {
                tokens: [quantityToken],
            },
        };

        await Transforms.editElement(editor, client, {
            elementID,
            row: coords.y,
            input,
        });
    };

    const handleSubmitItem = async () => {
        if (isAdding) {
            await handleAddItem();
        } else {
            await handleEditItem();
        }
    };

    const handleAddGroup = async () => {
        if (!Cell.isCell(cell, { type: 'Actions' })) {
            return;
        }
        await Transforms.createCategory(editor, name.value ?? '', projectID, cell, client);
    };

    const handleEditGroup = () => {
        if (!Cell.isCell(cell, { type: 'Category' })) {
            return;
        }
        Transforms.setCategoryName(editor, name.value ?? '', cell, client);
    };

    const handleSubmitGroup = async () => {
        if (isAdding) {
            await handleAddGroup();
        } else {
            handleEditGroup();
        }
    };

    // Deselect the editor when "unmounting" the ItemForm
    useEffect(() => {
        return () => {
            Transforms.deselect(editor);
        };
    }, [editor]);

    return {
        inputs: {
            name,
            quantity,
            totalRate,
            rates,
            isCalculatedRate,
            unit,
        },
        total: validatedTotal,
        uoms,
        handleSubmitItem,
        handleSubmitGroup,
        isAdding,
        isGroup,
    };
};

export default useCellFormState;
