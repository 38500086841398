import { Coordinates, LeydenEditor } from 'leyden';
import { Node, Editor } from 'slate';

import { Transforms } from '.';
import { Queries } from '../queries';
import { Serialize } from '../serialization';
import { assignElementExpressionTokens } from '../utils/requests';
import { ApolloClient } from '@/common/apollo/execute';
import { TokenScalar } from '@/graphql/customScalars';

export interface ElementQuantityTransforms {
    closeElementQuantityEditor: (
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ) => void;
    editElementQuantityFormula: (
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ) => void;
    editElementQuantityNumeric: (
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ) => void;
    insertMarkupIntoExpression: (editor: Editor, markupID: string) => void;
    insertMarkupGroupIntoExpression: (editor: Editor, markupGroupID: string) => void;
    setElementQuantityExpression: (
        editor: Editor,
        expressionTokens: TokenScalar[],
        options?: {
            at?: Coordinates;
            client?: ApolloClient;
        }
    ) => void;
}

export const ElementQuantityTransforms: ElementQuantityTransforms = {
    /**
     * Close an element quantity editor.
     */

    closeElementQuantityEditor(
        editor: Editor,
        options?: {
            at?: Coordinates;
        }
    ): void {
        Transforms.setCellChildren<'Quantity'>(
            editor,
            [Serialize.Element.ElementQuantityDefaultView()],
            options
        );
    },

    /**
     * Open an element quantity formula editor.
     */

    editElementQuantityFormula(
        editor: Editor,
        options: {
            at?: Coordinates;
        } = {}
    ): void {
        const [quantityCell, quantityCellCoords] = Queries.elementQuantity(editor, options);
        const expressionSource = quantityCell.element.expression.tokens;
        const expression = Serialize.Element.Expression(expressionSource);
        const expressionIsEmpty = expression.children.length === 0;
        if (expressionIsEmpty) {
            expression.children = [Serialize.Element.ExpressionPlaceholder()];
        }
        const view = Serialize.Element.ElementQuantityExpressionInputView([expression]);
        Transforms.setCellChildren<'Quantity'>(editor, [view], options);

        const quantityCellPath = LeydenEditor.cellPath(editor, { at: quantityCellCoords });
        if (quantityCellPath === null) {
            return;
        }
        const nodeToSelect = Node.texts(editor, {
            from: quantityCellPath,
            reverse: !expressionIsEmpty,
        }).next().value;
        if (!nodeToSelect) {
            return;
        }
        setTimeout(() => {
            Transforms.select(editor, nodeToSelect[1]);
        });
    },

    /**
     * Open a numeric element quantity editor.
     */

    editElementQuantityNumeric(
        editor: Editor,
        options: {
            at?: Coordinates;
        } = {}
    ): void {
        const [quantityCell] = Queries.elementQuantity(editor, options);
        const quantityString = quantityCell.element.expression.result.toFixed(2);
        const quantity = parseFloat(quantityString);
        const view = Serialize.Element.ElementQuantityNumericInputView(quantity);
        Transforms.setCellChildren<'Quantity'>(editor, [view], options);
    },

    /**
     * Insert a markup into the expression which is currently being edited.
     *
     * TODO => add an `options.at` argument to explicitly position the insertion
     * TODO => guard against insertion outside of expressions
     */

    insertMarkupIntoExpression(editor, markupID) {
        const element = Serialize.Element.ExpressionMarkup(markupID);
        editor.insertNode(element);
    },

    /**
     * Insert a markup group into the expression which is currently being edited.
     *
     * TODO => add an `options.at` argument to explicitly position the insertion
     * TODO => guard against insertion outside of expressions
     */

    insertMarkupGroupIntoExpression(editor, markupGroupID) {
        const element = Serialize.Element.ExpressionMarkupGroup(markupGroupID);
        editor.insertNode(element);
    },

    setElementQuantityExpression(
        editor: Editor,
        expressionTokens: TokenScalar[],
        options: {
            at?: Coordinates;
            client?: ApolloClient;
        } = {}
    ) {
        const { at, client } = options;
        const [cell] = Queries.elementQuantity(editor, { at });
        if (JSON.stringify(cell.element.expression.tokens) === JSON.stringify(expressionTokens)) {
            return;
        }
        Transforms.setCell<'Quantity'>(
            editor,
            {
                element: {
                    ...cell.element,
                    expression: {
                        ...cell.element.expression,
                        tokens: expressionTokens,
                    },
                },
            },
            { at }
        );
        if (client) {
            assignElementExpressionTokens(client, cell.element.id, expressionTokens).then((res) => {
                if (res === null) {
                    return;
                }
                const {
                    id,
                    expression: { result },
                } = res.elementUpdateExpression;
                Transforms.setElementQuantity(editor, id, result);
            });
        }
    },
};
