import React, { FC } from 'react';

import { InView } from 'react-intersection-observer';

import { Setter } from '@/common/types';
import { ProjectTableRow } from '@/components/AdminDashboard/Projects/ProjectTableRow';
import { SortingDirection, Table } from '@/components/AdminDashboard/Table';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';

const columns = [
    {
        name: 'project',
        label: 'project',
        sortBy: 'project',
        sortable: false,
        sortOrder: SortingDirection.Ascending,
    },
    { name: 'builder', label: 'builder', sortable: false },
    { name: 'status', label: 'status', sortable: false },
    { name: 'dueDate', label: 'due', sortable: false },
    { name: 'estimator', label: 'estimator', sortable: false },
    { name: 'price', label: 'price', sortable: false },
    { name: 'trade', label: 'trades', sortable: false },
    { name: 'submittedDate', label: 'submitted', sortable: false },
];

interface ProjectTableProps {
    projects: ProjectWithEventsRecord[];
    activeProject: ProjectRecord | null;
    setActiveProject: Setter<ProjectRecord | null>;
    fetchMoreProjects: () => void;
    isFetching: boolean;
}

export const ProjectTable: FC<ProjectTableProps> = ({
    projects,
    activeProject,
    setActiveProject,
    fetchMoreProjects,
    isFetching,
}) => {
    /**
     * Fetch more projects on component InView being in view 👁
     * @param {boolean} inView if in view.
     */
    const onBottomReached = (inView: boolean) => {
        if (!inView) {
            return;
        }

        fetchMoreProjects();
    };

    return (
        <>
            <Table columns={columns}>
                {projects.map(
                    (project): JSX.Element => (
                        <ProjectTableRow
                            key={project.uuid}
                            project={project}
                            isActive={!!(activeProject && project.uuid === activeProject.uuid)}
                            setActiveProject={setActiveProject}
                        />
                    )
                )}
            </Table>
            <InView
                onChange={onBottomReached}
                threshold={0.8}
                style={{
                    textAlign: 'center',
                    background: '#FFFFFF',
                    height: '6rem',
                }}
            >
                {isFetching && <SpinnerLoader />}
            </InView>
        </>
    );
};
