import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

const variables = {
    iconColor: '#B8B6CF',
    detailColor: '#DADEEC',
    highlightColor: '#305FFF',
    warningColor: '#FFC4C4',
};

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface ContentProps {
    isError?: boolean;
    isTrades?: boolean;
    disabled?: boolean;
}
export const Content = styled.div<ContentProps>`
    display: grid;
    grid-template-columns: 30px 1fr;

    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;

    padding: ${(props): string => (props.isTrades ? '11px 10px 6px 0' : '6px 10px 6px 0')};
    border-radius: 3px;

    color: ${(props): string => (props.isError ? variables.warningColor : variables.detailColor)};

    transition: opacity 400ms, color 400ms;
    opacity: ${(props): number => (props.disabled ? 0.5 : 1)};

    & > a {
        color: ${(props): string =>
            props.isError ? variables.warningColor : variables.detailColor};
    }

    & svg {
        color: ${(props): string => (props.isError ? variables.warningColor : variables.iconColor)};
    }

    ${(props): FlattenSimpleInterpolation | string =>
        props.onClick && !props.disabled
            ? css`
                  cursor: pointer;

                  &:hover {
                      background: rgba(255, 255, 255, 0.08);
                  }
              `
            : ''}

    ${breakpoints.small`
        width: max-content;
    `}
`;

interface ContainerProps {
    isHighlighted?: boolean;
}
export const Container = styled.div<ContainerProps>`
    padding: 0 44px 0 16px;
    overflow-wrap: anywhere;
    transition: background 400ms;

    ${(props): FlattenSimpleInterpolation | string =>
        props.isHighlighted
            ? css`
                  background: ${variables.highlightColor};

                  & > ${Content} {
                      color: #fff;
                  }
              `
            : ''};

    ${breakpoints.small`
        padding: 0 8px;
    `}
`;
