import { IUserFragment } from '@/graphql';
import { DatabaseProjectStatus } from '@/common/types';
import { ColorOption } from '@/theme/types';
import { isBuilderUser } from '@/views/Projects/utils';

export enum KanbanColumnLabel {
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
}

export const toLabelText = (status?: KanbanColumnLabel) => {
    return status?.replace(/_/g, ' ');
};

export const getKanbanColumnLabels = (user: IUserFragment) => {
    if (isBuilderUser(user)) {
        return [
            KanbanColumnLabel.NOT_STARTED,
            KanbanColumnLabel.IN_PROGRESS,
            KanbanColumnLabel.COMPLETED,
        ];
    } else {
        return [
            KanbanColumnLabel.PENDING_ACCEPTANCE,
            KanbanColumnLabel.IN_PROGRESS,
            KanbanColumnLabel.COMPLETED,
        ];
    }
};

export const getStatusesByColumnLabel = (label: KanbanColumnLabel) => {
    const items: Record<KanbanColumnLabel, DatabaseProjectStatus[]> = {
        [KanbanColumnLabel.COMPLETED]: [DatabaseProjectStatus.COMPLETE],
        [KanbanColumnLabel.IN_PROGRESS]: [
            DatabaseProjectStatus.APPROVED,
            DatabaseProjectStatus.DRAFT,
            DatabaseProjectStatus.ESTIMATING,
            DatabaseProjectStatus.ESTIMATED,
        ],
        [KanbanColumnLabel.NOT_STARTED]: [
            DatabaseProjectStatus.NEW,
            DatabaseProjectStatus.REVISION_REQUESTED,
            DatabaseProjectStatus.REVISION_SUBMITTED,
            DatabaseProjectStatus.PENDING_ESTIMATOR,
            DatabaseProjectStatus.SIZED,
        ],
        [KanbanColumnLabel.PENDING_ACCEPTANCE]: [DatabaseProjectStatus.PENDING_ESTIMATOR],
    };

    return items[label];
};

export const getColumnColorByColumnLabel = (label: KanbanColumnLabel) => {
    const items: Record<KanbanColumnLabel, ColorOption> = {
        [KanbanColumnLabel.COMPLETED]: 'success',
        [KanbanColumnLabel.IN_PROGRESS]: 'warning',
        [KanbanColumnLabel.NOT_STARTED]: 'error',
        [KanbanColumnLabel.PENDING_ACCEPTANCE]: 'error',
    };

    return items[label];
};
