import { toMarkupGeometry } from '@/common/convert/coordinateData';
import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

import { IMarkupGeometryFragment } from '@/graphql';

export const toMarkupGeometries = newGeometryHandler<IMarkupGeometryFragment[]>({
    [GeometryType.AREA]: (area) => [toMarkupGeometry(area.coordinates)],
    [GeometryType.COUNT]: (count) => count.coordinates.map(toMarkupGeometry),
    [GeometryType.LINEAR]: (line) => [toMarkupGeometry(line.coordinates)],
});
