import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';
import { colorStatusError, colorTypographyDark } from '@/variables';

export { Button } from '../styled';

export const Header = styled.div`
    grid-area: header;
`;

interface HeaderContentProps {
    isHighlight?: boolean;
}
export const HeaderContent = styled.h1<HeaderContentProps>`
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    margin: 0;

    color: ${(props): string => (props.isHighlight ? colorStatusError : colorTypographyDark)};
`;

export const Copy = styled.div`
    grid-area: copy;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: ${colorTypographyDark};
    opacity: 0.7;
`;

const gridTemplate = `
    " header " min-content
    " .      " 32px
    " copy   " min-content
    " .      " 1fr
    " button " min-content
    / 1fr
`;

const gridTemplateMobile = `
    " header " min-content
    " .      " 16px
    " copy   " min-content
    " .      " 1fr
    " button " min-content
    / 1fr
`;

export const Container = styled.div`
    display: grid;
    grid-template: ${gridTemplate};

    width: 413px;
    height: 500px;
    border-radius: 10px;
    padding: 48px 48px 32px;
    box-sizing: border-box;

    background: #ffffff;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    ${breakpoints.small`
        grid-template: ${gridTemplateMobile};

        min-height: 454px;
        width: 100vw;
        border-radius: 0;

        top: auto;
    `}
`;
