import { FC } from 'react';
import { useEffect } from 'react';

import { useLoading } from '@/contexts/Loading';

/**
 * Loading page component, the gist is that this increments
 * a counter of loading pages within the <LoadingProvider />,
 * which in turn controls mounting <GlobalLoader /> within App.tsx
 * @see {LoadingProvider}
 * @see {GlobalLoader}
 */
export const LoadingPage: FC = () => {
    const { setIsLoading } = useLoading();

    useEffect(() => {
        setIsLoading(true);

        return (): void => {
            setIsLoading(false);
        };
    }, [setIsLoading]);

    return null;
};
