import React from 'react';

import { CellRenderer } from 'leyden-react';

import { useCategoryTotal } from '../../hooks/useCategoryTotal';
import { Cell } from '../components/Cell';

import { formatters } from '@/common/currency';

const formatter = formatters.usd();

export const CategoryTotal: CellRenderer<'CategoryTotal'> = ({ attributes, children, element }) => {
    const categoryTotal = useCategoryTotal(element.id);

    return (
        <Cell
            attributes={attributes}
            caret="never"
            element={element}
            highlightOnHover={false}
            preventSelection={true}
        >
            <b>{formatter.format(categoryTotal)}</b>
            {children}
        </Cell>
    );
};
