import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 1rem 0;
`;

export const Blocked = styled.div<{ disabled: boolean }>`
    width: 36px;
    height: 1px;
    background: ${(props): string => (props.disabled ? '#D7E1E5' : '#555E77')};
    position: absolute;
    transform: rotate(135deg);
`;

type ItemProps = {
    blocked: boolean;
    disabled: boolean;
    onClick: () => void;
};

const itemColor = (disabled: boolean, blocked: boolean): string => {
    if (disabled && blocked) {
        return '#D7E1E5';
    }
    if (disabled && !blocked) {
        return '#3D445C';
    }
    if (!disabled && blocked) {
        return '#555E77';
    }

    return '#ffffff';
};

const hoverItemColor = (disabled: boolean, blocked: boolean): string => {
    if (disabled && blocked) {
        return '#D7E1E5';
    }
    if (disabled && !blocked) {
        return '#3D445C';
    }

    return '#ffffff';
};

export const Item = styled.div<ItemProps>`
    border: 1px solid
        ${(props): string => (props.disabled ? '#D7E1E5' : props.blocked ? '#555E77' : '#3D4D81')};
    color: ${(props): string => itemColor(props.disabled, props.blocked)};
    box-sizing: border-box;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
    text-transform: capitalize;
    user-select: none;

    &:hover {
        background: ${(props): string =>
            props.disabled ? 'transparent' : props.blocked ? '#4274F7' : '#F35252'};
        border: 1px solid ${(props): string => (props.disabled ? '#D7E1E5' : '0px')};
        color: ${(props): string => hoverItemColor(props.disabled, props.blocked)};

        ${Blocked} {
            background: ${(props): string => (props.disabled ? '#D7E1E5' : '#ffffff')};
        }
    }
`;
