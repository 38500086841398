import wkx from 'wkx';

import { fromCoordinate } from '@/common/convert/coordinateData';
import { Point } from '@/common/types';

type WKXData = wkx.LineString | wkx.MultiPoint | wkx.Point | wkx.Polygon;

type Convert<T extends WKXData> = T extends wkx.LineString
    ? Point[]
    : T extends wkx.MultiPoint
    ? Point[]
    : T extends wkx.Point
    ? Point
    : T extends wkx.Polygon
    ? Point[][]
    : never;

type Converter<T extends WKXData> = (data: T) => Convert<T>;

const toArea: Converter<wkx.Polygon> = (polygon) => {
    return [
        polygon.exteriorRing.map(toPoint),
        ...polygon.interiorRings.map((ring) => ring.map(toPoint)),
    ];
};

const toCount: Converter<wkx.MultiPoint> = (multiPoint) => {
    return multiPoint.points.map(toPoint);
};

const toLinear: Converter<wkx.LineString> = (lineString) => {
    return lineString.points.map(toPoint);
};

const toPoint: Converter<wkx.Point> = (point) => {
    return fromCoordinate(point);
};

export function fromWKX<T extends WKXData>(data: T): Convert<T>;
export function fromWKX(data: WKXData) {
    if (data instanceof wkx.Polygon) return toArea(data);
    if (data instanceof wkx.MultiPoint) return toCount(data);
    if (data instanceof wkx.LineString) return toLinear(data);
    else return toPoint(data);
}
