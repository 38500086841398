/* Single checkbox component with a children as label */
import clsx from 'clsx';
import React, { FC } from 'react';

export type CheckboxProps = {
    id: string;
    className?: string;
    isChecked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({
    id,
    className,
    isChecked,
    onChange,
    disabled,
    children,
}) => {
    return (
        <div className={clsx('checkbox-wrapper', isChecked && 'checkbox-wrapper--checked')}>
            <label htmlFor={id} className={className}>
                {children}
            </label>
            <div
                className={clsx('checkbox', isChecked && 'checkbox--checked')}
                defaultChecked={isChecked}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={isChecked}
                    disabled={disabled}
                    onChange={(_): void => onChange(!isChecked)}
                />
                <label htmlFor={id} />
            </div>
        </div>
    );
};
