import React, { FC } from 'react';
import { ListItemButtonWithCollapse } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/ListItemButtonWithCollapse';
import { ItemList } from './ItemList';
import { IProjectLightFragment } from '@/graphql';

interface ProjectListItemProps {
    project: IProjectLightFragment;
}

export const ProjectListItem: FC<ProjectListItemProps> = ({ project }) => {
    return (
        <ListItemButtonWithCollapse dense label={project.name || 'Untitled Project'}>
            <ItemList project={project} />
        </ListItemButtonWithCollapse>
    );
};
