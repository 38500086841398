import { CellRenderers } from 'leyden-react';

import { Actions } from './Actions';
import { Category } from './Category';
import { CategoryTotal } from './CategoryTotal';
import { Empty } from './Empty';
import { Name } from './Name';
import { Quantity } from './Quantity';
import { Rate } from './Rate';
import { UnitOfMeasure } from './UnitOfMeasure';
import { Total } from './Total';

export const cellRenderers: CellRenderers = {
    Actions,
    Category,
    CategoryTotal,
    Empty,
    Name,
    Quantity,
    Rate,
    Total,
    UnitOfMeasure,
};
