import React, { memo, useEffect, useState } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';

import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { GroupListItem } from './GroupListItem';
import { TakeoffListItem } from './TakeoffListItem';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { isMarkup, isMarkupGroup } from './utils';

import { IMarkupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';

export interface GeometryListProps {
    disabled?: boolean;
    emptyComponent?: React.ReactElement;
    listItems?: IMarkupEntry[];
    listItemProps?: ListItemButtonProps;
    markupEntries?: IMarkupEntry[];
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onCreateGroupFromMarkup?: (markup: IMarkupFragment) => void;
    onDeleteMarkupGroup: (markupGroupID: string) => Promise<void>;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onRemoveMarkupsFromGroup: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    mode?: string;
    selectedMarkupEntryIds?: string[];
    setSize: (index: number, size: number) => void;
    height: number;
}

interface AdditionalListChildProps {
    setSize: (index: number, size: number) => void;
}

export const GeometryListItem = memo<
    ListChildComponentProps<GeometryListProps & AdditionalListChildProps>
>(({ data, index, style }) => {
    const {
        disabled,
        emptyComponent,
        listItems,
        markupEntries,
        mode,
        onAddMarkupToGroup,
        onCreateGroupFromMarkup,
        onDeleteMarkupGroup,
        onListItemClick,
        onListItemNameBlur,
        onRemoveMarkupsFromGroup,
        selectedMarkupEntryIds,
        setSize,
    } = data;

    const markupEntry = listItems?.[index];

    const [isGroupCollapseOpen, setIsGroupCollapseOpen] = useState(false);

    useEffect(() => {
        if (isMarkupGroup(markupEntry)) {
            let newSize = 80;
            if (isGroupCollapseOpen) {
                if (markupEntry.markups.length > 0) {
                    // The constant part of the element plus N child elements
                    newSize = 126 + markupEntry.markups.length * 80;
                } else {
                    // The constant for an empty group, with the buttons to add to it.
                    newSize = 204;
                }
            }
            setSize(index, newSize);
        }
    }, [markupEntry, isGroupCollapseOpen]);

    const empty = emptyComponent || <></>;
    if (!listItems?.length) {
        return empty;
    }

    if (isMarkupGroup(markupEntry)) {
        return (
            <GroupListItem
                style={style}
                disabled={disabled}
                itemLabel="takeoff"
                key={markupEntry.id}
                markupGroup={markupEntry}
                mode={mode}
                onAddMarkupToGroup={onAddMarkupToGroup}
                onListItemClick={onListItemClick}
                onListItemNameBlur={onListItemNameBlur}
                onDeleteMarkupGroup={onDeleteMarkupGroup}
                onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                selectedMarkupEntryIds={selectedMarkupEntryIds}
                isCollapseOpen={isGroupCollapseOpen}
                setIsCollapseOpen={setIsGroupCollapseOpen}
            />
        );
    } else if (isMarkup(markupEntry)) {
        return (
            <TakeoffListItem
                style={style}
                disabled={disabled}
                key={markupEntry.id}
                markup={markupEntry}
                markupEntries={markupEntries}
                mode={mode}
                onAddMarkupToGroup={onAddMarkupToGroup}
                onListItemClick={onListItemClick}
                onListItemNameBlur={onListItemNameBlur}
                onCreateGroupFromMarkup={onCreateGroupFromMarkup}
                onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                selectedMarkupEntryIds={selectedMarkupEntryIds}
            />
        );
    } else {
        return empty;
    }
}, areEqual);
