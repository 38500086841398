import { COPY, InputProps } from '../constants';
import { Field, FieldDictionary, FormProps } from '../types';
import { changeDictionaryField, handleRequest, isFormValid } from '../utils';
import { useNotifications } from '@/contexts/Notifications';
import { useTeamCreditAssignmentMutation } from '@/graphql';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { FC } from 'react';

export interface TeamCreditAssignmentDictionary extends FieldDictionary {
    teamId: Field & {
        value: string;
    };
    creditValue: Field & {
        value: string;
    };
}

const Fields: TeamCreditAssignmentDictionary = {
    teamId: {
        value: '',
        touched: false,
    },
    creditValue: {
        value: '',
        touched: false,
    },
};

export const ApplyCredit: FC<FormProps> = ({ onSubmit }) => {
    const [currentFields, setCurrentFields] = useState<TeamCreditAssignmentDictionary>({
        ...Fields,
    });
    const [open, setOpen] = useState(false);

    const { addNotification } = useNotifications();

    const [teamCreditAssignmentMutation] = useTeamCreditAssignmentMutation();

    const handleSubmit = async () => {
        const { teamId, creditValue } = currentFields;
        setOpen(true);

        try {
            if (!teamId || !creditValue) {
                throw new Error('Please fill required fields.');
            } else if (Number(teamId.value) <= 0) {
                throw new Error('Team ID cannot be negative or zero.');
            }

            await handleRequest(
                teamCreditAssignmentMutation({
                    variables: {
                        input: {
                            teamID: String(teamId.value),
                            usdCents: Number(creditValue.value) * 100,
                        },
                    },
                }),
                addNotification
            );
            // return inputs to initial state
            setCurrentFields(Fields);
            setOpen(false);
            onSubmit();
        } catch (e) {
            addNotification({ title: 'Error', content: String(e) }, 'error');
        }
    };

    return (
        <>
            <Backdrop open={open}>
                <CircularProgress color="primary" />
            </Backdrop>
            <DialogContent>
                <TextField
                    {...InputProps}
                    required
                    value={currentFields['teamId'].value}
                    label={COPY.teamId}
                    type="number"
                    error={currentFields['teamId'].touched && currentFields['teamId'].value === ''}
                    helperText={
                        currentFields['teamId'].touched &&
                        currentFields['teamId'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'teamId',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'teamId',
                            'touched',
                            true
                        );
                    }}
                />
                <TextField
                    {...InputProps}
                    required
                    value={currentFields['creditValue'].value}
                    label={COPY.creditValue}
                    type="number"
                    error={
                        currentFields['creditValue'].touched &&
                        currentFields['creditValue'].value === ''
                    }
                    helperText={
                        currentFields['creditValue'].touched &&
                        currentFields['creditValue'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'creditValue',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'creditValue',
                            'touched',
                            true
                        );
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!isFormValid(currentFields)}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {COPY.submit}
                </Button>
            </DialogActions>
        </>
    );
};
