/**
 * Calendar control. Allows for picking a date.
 */

import React, { FC, useState } from 'react';
import moment, { Moment } from 'moment';
import clsx from 'clsx';

import { Direction, DivProps, Nil } from '@/common/types';
import {
    getNextMonth,
    getPrevMonth,
    getDaysForMonth,
    isToday,
    getWeekdays,
    getMonthDayOffset,
    isBeforeDate,
    areEqual,
    isBusinessDay,
} from '@/common/utils/moment';
import { PaginationButton } from '../buttons/PaginationButton';
import { CircleIcon } from '../icons/CircleIcon';

import './Calendar.scss';

type CalendarProps = Omit<DivProps, 'onChange'> & {
    bypassDisabledDates: boolean;
    value: Nil<Date>;
    onChange: (value: Date) => void;
    min: Moment;
};

export const Calendar: FC<CalendarProps> = ({
    bypassDisabledDates,
    value,
    min,
    onChange,
    className,
}) => {
    const [month, setMonth] = useState(min);

    /* State helper functions */
    const setNextMonth = (): void => setMonth(getNextMonth);
    const setPrevMonth = (): void => setMonth(getPrevMonth);

    /* On date selected event emitter */
    const selectDate = (day: Moment) => (): void => onChange(day.toDate());

    /* Calendar cells - column labels for days of the week */
    const renderWeekdayLabels = (): JSX.Element[] =>
        getWeekdays().map((day) => (
            <div key={day} className={clsx('cell', 'label')}>
                {day}
            </div>
        ));

    /* Calendar cells - column labels for days of the week */
    const renderOffsetBeforeFirstMonthDay = (): JSX.Element[] =>
        getMonthDayOffset(month).map((item) => <div key={item} className="cell" />);

    /* Calendar cells - column labels for days of the week */
    const renderMonthDays = (): JSX.Element[] =>
        getDaysForMonth(month).map((day, index) => (
            <CircleIcon
                key={index}
                className={clsx('cell', 'day', {
                    disabled:
                        isBeforeDate(day, min) || (!bypassDisabledDates && !isBusinessDay(day)),
                    today: isToday(day),
                    selected: areEqual(day, moment(value)),
                })}
                diameter="2.5rem"
                background="auto"
                onClick={selectDate(day)}
            >
                <div>{day.format('D')}</div>
            </CircleIcon>
        ));

    return (
        <div className={clsx(className, 'calendar-control')}>
            <div className="header">
                <PaginationButton
                    className="button"
                    direction={Direction.Left}
                    onClick={setPrevMonth}
                />
                <div className="current-month">{month.format('MMMM YYYY')}</div>
                <PaginationButton
                    className="button"
                    direction={Direction.Right}
                    onClick={setNextMonth}
                />
            </div>
            <div className="days">
                {renderWeekdayLabels()}
                {renderOffsetBeforeFirstMonthDay()}
                {renderMonthDays()}
            </div>
        </div>
    );
};
