/* eslint-disable @typescript-eslint/indent, max-len */
import React, { useEffect, useState } from 'react';

import { useMutation as useApolloMutation } from '@apollo/client';
import isNil from 'lodash/isNil';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';
import { v4 as uuid } from 'uuid';

import { DatabaseProjectStatus } from '@/common/types';
import { Setter, WageType } from '@/common/types';
import { bidsDueDate } from '@/common/utils/bidsDueDate';
import { ErrorMessage } from '@/components/notifications/Notification';
import { useNotifications } from '@/contexts/Notifications';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import {
    IProjectLocationAssignmentInput,
    IProjectLocationAssignmentMutation,
    IProjectLocationAssignmentMutationVariables,
    IUserFragment,
    IUserRole,
    ProjectLocationAssignmentDocument,
    useProjectMutation,
} from '@/graphql';
import { EditProjectMutationResponse, editProjectMutation } from '@/mutations/editProject';
import {
    CreateProjectFormMutationResponse,
    createProjectFormMutation,
} from '@/mutations/projectForm';
import { SubmitRevisionMutationResponse, submitRevisionMutation } from '@/mutations/submitRevision';
import { ProjectPlanFileRecord, executeProjectPlanFilesQuery } from '@/queries/projectPlanFiles';
import { ProjectRecord } from '@/queries/projects';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    submissionProjectNameError: 'name project (max 200 characters)',
    submissionDateError: 'select date',
    submissionDateTooEarlyError: 'must be at least 4 business days from now',
    submissionDateNotBusinessDay: 'must be a business day',
    submissionTradesError: 'select trades',
    submissionWageTypeError: 'select a wage type',
    submissionProjectTypeError: 'select a project type',
    submissionProjectSubTypeError: 'select a project sub-type',
    submissionFilesError: 'upload files',
    failedToEdit: 'Failed to edit project.',
    createProjectSuccessTitle: 'Success.',
    createProjectSuccessMsg: 'Project created successfully.',
    createProjectErrorTitle: 'Error.',
    createProjectErrorMsg: 'Unable to create project.',
    missingFields: 'Complete the missing fields to proceed:',
    missingProjectName: 'project name',
    missingDueDate: 'bid due date',
    missingTrades: 'trades',
    missingWageType: 'wage type',
    missingProjectType: 'project type',
    missingProjectSubType: 'project sub-type',
    missingFiles: 'files',
};

/**
 * Generate a new project or use existing data
 * @param {ProjectRecord?} project - initial project supplied to hook.
 * @returns {ProjectRecord} initial project supplied to project or mock project.
 */
const genNewProjectRecord = (project?: ProjectRecord): ProjectRecord =>
    project ?? {
        id: 0,
        uuid: uuid(),
        bidsDueDate: new Date().toISOString(),
        isSetupCompleted: false,
        estimatorCostUsdCents: 0,
        estimatorHours: 0,
        wageType: undefined,
        isSaas: false,
        isPriceFinal: false,
        publicEstimationNotify: true,
        pricingAssignment: {
            id: uuid(),
            approvedBy1Build: false,
            projectPricingsByPricingAssignmentId: {
                nodes: [],
            },
        },
    };

export const createSaasProject = (project?: Partial<ProjectRecord>): ProjectRecord => {
    return {
        id: 0,
        uuid: uuid(),
        bidsDueDate: new Date().toISOString(),
        isSetupCompleted: true,
        estimatorCostUsdCents: 0,
        estimatorHours: 0,
        wageType: undefined,
        name: 'Untitled Project',
        isSaas: true,
        isPriceFinal: false,
        pricingAssignment: {
            id: uuid(),
            approvedBy1Build: false,
            projectPricingsByPricingAssignmentId: {
                nodes: [],
            },
        },
        projectTrades: {
            nodes: [],
        },
        publicEstimationNotify: true,
        status: DatabaseProjectStatus.NEW,
        ...project,
    };
};

type SubmitErrors = Partial<{
    projectName: string;
    date: string;
    trades: string;
    wageType: string;
    files: string;
    type: string;
    subtype: string;
}>;

const projectTypesWithoutSubtypes = ['Energy', 'Military Facility', 'Mixed Use', 'Office'];

interface ValidationResult {
    submitErrors: SubmitErrors;
    valid: boolean;
}

export type ProjectSetupHookProps = {
    plans: ProjectPlanFileRecord[];
    setPlans: Setter<ProjectPlanFileRecord[]>;
    projectLocation: Omit<IProjectLocationAssignmentInput, 'projectID'>;
    setProjectLocation: Setter<Omit<IProjectLocationAssignmentInput, 'projectID'>>;
    configuredProject: ProjectRecord;
    setConfiguredProject: Setter<ProjectRecord>;
    projectSubmissionLoading: boolean;
    showErrors: boolean;
    submitErrors?: ValidationResult['submitErrors'];
    isProjectValid: ValidationResult['valid'];
    submitConfiguredProject: (project?: ProjectRecord) => Promise<{ uuid: string; newId?: string }>;
    handleSubmitButtonClick: () => void;
    projectSubmitLoading: boolean;
};

export const isBidsDueDateValid = (project?: ProjectRecord): boolean => {
    const minimumBusinessDays = 4;
    const dueDate = bidsDueDate(project?.bidsDueDate);

    return dueDate.getBusinessDaysLeft() >= minimumBusinessDays && dueDate.isBusinessDay();
};

export const isNameValid = (project?: ProjectRecord) => {
    return project?.name?.trim() !== '';
};

export const isTradesValid = (project?: ProjectRecord) => {
    return Boolean(project?.projectTrades?.nodes?.length);
};

export const isWageTypeValid = (project?: ProjectRecord) => {
    return !isNil(project?.wageType);
};

export const isProjectTypeValid = (project?: ProjectRecord) => {
    return project?.type !== '';
};

export const isProjectSubTypeValid = (project?: ProjectRecord) => {
    if (projectTypesWithoutSubtypes.includes(project?.type || '')) return true;
    return project?.subtype !== '';
};

export const isAdminUser = (user?: IUserFragment) => {
    return user?.roles?.includes(IUserRole.Admin) || user?.roles?.includes(IUserRole.Superadmin);
};

export const isValidSaasProject = (project?: ProjectRecord): ValidationResult => {
    const submitErrors: SubmitErrors = {};

    if (isNameValid(project)) {
        submitErrors.projectName = COPY.submissionProjectNameError;
    }

    return {
        submitErrors,
        valid: Object.keys(submitErrors).length === 0,
    };
};

const isValidMarketplaceProject = (project?: ProjectRecord): ValidationResult => {
    const submitErrors: SubmitErrors = {};

    if (!isNameValid(project)) {
        submitErrors.projectName = COPY.submissionProjectNameError;
    }
    if (!isBidsDueDateValid(project)) {
        const dueDate = bidsDueDate(project?.bidsDueDate);

        if (dueDate.getBusinessDaysLeft() === 0) {
            submitErrors.date = COPY.submissionDateError;
        } else if (!dueDate.isBusinessDay()) {
            submitErrors.date = COPY.submissionDateNotBusinessDay;
        } else {
            submitErrors.date = COPY.submissionDateTooEarlyError;
        }
    }
    if (!isTradesValid(project)) {
        submitErrors.trades = COPY.submissionTradesError;
    }
    if (!isWageTypeValid(project)) {
        submitErrors.wageType = COPY.submissionWageTypeError;
    }

    if (!isProjectTypeValid(project)) {
        submitErrors.type = COPY.submissionProjectTypeError;
    }

    if (!isProjectSubTypeValid(project)) {
        submitErrors.subtype = COPY.submissionProjectSubTypeError;
    }

    return {
        submitErrors,
        valid: Object.keys(submitErrors).length === 0,
    };
};

export const useProjectSetup = (
    initProject?: ProjectRecord,
    initProjectLocation?: Omit<IProjectLocationAssignmentInput, 'projectID'> | null
): ProjectSetupHookProps => {
    /* Values from context */
    const history = useHistory();
    const flags = useFlags();

    const { currentUploads } = useStorage();
    const {
        data: { user },
    } = useUser();
    const { addNotification } = useNotifications();

    /* GraphQL mutations */
    const [submitNewProject, { loading: projectSubmitLoading }] = useProjectMutation();
    const [, submitEditedProject] = useMutation<EditProjectMutationResponse>(editProjectMutation);
    const [, submitProjectRevision] =
        useMutation<SubmitRevisionMutationResponse>(submitRevisionMutation);
    const [projectFormData, createProjectForm] =
        useMutation<CreateProjectFormMutationResponse>(createProjectFormMutation);
    const [updateProjectLocation] = useApolloMutation<
        IProjectLocationAssignmentMutation,
        IProjectLocationAssignmentMutationVariables
    >(ProjectLocationAssignmentDocument);

    /* Configured project state */
    const [configuredProject, setConfiguredProject] = useState<ProjectRecord>(
        genNewProjectRecord(initProject)
    );
    const [projectLocation, setProjectLocation] = useState<
        Omit<IProjectLocationAssignmentInput, 'projectID'>
    >(initProjectLocation ?? {});
    const [plans, setPlans] = useState<ProjectPlanFileRecord[]>([]);
    const [projectSubmissionLoading, setProjectSubmissionLoading] = useState(false);

    const [validationResult, setValidationResult] = useState<ValidationResult>();
    const [showErrors, setShowErrors] = useState(false);

    const validate = (project?: ProjectRecord, user?: IUserFragment): ValidationResult => {
        if (project?.isSaas) {
            return isValidSaasProject(project);
        } else {
            const result = isValidMarketplaceProject(project);

            if (currentUploads.length || plans.length === 0) {
                result.submitErrors.files = COPY.submissionFilesError;
            }

            if (bidsDueDate(configuredProject?.bidsDueDate) === bidsDueDate(project?.bidsDueDate)) {
                delete result.submitErrors.date;
            }

            if (isAdminUser(user)) {
                delete result.submitErrors.date;
            }

            result.valid = Object.keys(result.submitErrors).length === 0;

            return result;
        }
    };

    useEffect(() => {
        setValidationResult(validate(configuredProject, user));
    }, [configuredProject, currentUploads, plans]);

    // Update project location if initial info comes in async
    useEffect(() => {
        if (initProjectLocation) {
            setProjectLocation(initProjectLocation);
        }
    }, [initProjectLocation]);

    useEffect(() => {
        if (
            configuredProject.id === 0 &&
            !projectFormData.fetching &&
            !projectFormData.error &&
            projectFormData.data === undefined
        ) {
            createProjectForm({
                id: configuredProject.uuid,
                teamId: user.team?.id,
            });
        }
    }, [configuredProject, projectFormData]);

    useEffect(() => {
        if (initProject !== undefined && configuredProject.id !== 0) {
            const fetchProjectPlanFiles = async (): Promise<void> => {
                const projectPlansQueryResult = await executeProjectPlanFilesQuery({
                    projectId: initProject.id,
                });
                setPlans(
                    projectPlansQueryResult.data?.projectPlanFiles.nodes.filter(
                        (p) => p.filename !== 'PLACEHOLDER'
                    ) ?? []
                );
            };

            fetchProjectPlanFiles();
        }
    }, [initProject]);

    /* Submission logic */
    const submitProjectEdit = async (
        configuredProject: ProjectRecord,
        newLinks: string[],
        adjustedBidsDueDate: Moment
    ): Promise<string> => {
        await submitEditedProject({
            projectId: configuredProject.id,
            name: configuredProject.name,
            bidsDueDate: adjustedBidsDueDate,
            uuid: configuredProject.uuid,
            links: newLinks,
            isSetupCompleted: configuredProject.isSetupCompleted,
            files: plans.map((p) => ({
                filename: p.filename,
                uuid: p.uuid,
            })),
            trades: configuredProject.projectTrades?.nodes.map((n) => n.trade.id) ?? [],
            userId: user.id,
            wageType: configuredProject.wageType,
        });

        await updateProjectLocation({
            variables: {
                input: {
                    projectID: `${configuredProject.id}`,
                    formattedAddress: projectLocation.formattedAddress,
                    streetAddress: projectLocation.streetAddress,
                    city: projectLocation.city,
                    state: projectLocation.state,
                    zip: projectLocation.zip,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    coordinates: projectLocation.coordinates,
                },
            },
        });

        setProjectSubmissionLoading(false);
        return configuredProject.uuid;
    };

    const submitConfiguredProject = async (
        configuredProject?: ProjectRecord
    ): Promise<{ uuid: string; newId?: string }> => {
        if (!configuredProject) {
            throw new Error();
        }
        setProjectSubmissionLoading(true);

        const isEditing = configuredProject.id !== 0;

        const isRevision =
            configuredProject.estimateDueDate !== null && configuredProject.additionalInfo !== null;

        const adjustedBidsDueDate = bidsDueDate(configuredProject.bidsDueDate).getDate();

        if (!isEditing) {
            const newProject = await submitNewProject({
                variables: {
                    input: {
                        name: configuredProject.name ?? '',
                        bidsDueDate: configuredProject.bidsDueDate,
                        uuid: configuredProject.uuid,
                        files: plans.map((p) => ({
                            filename: p.filename,
                            uuid: p.uuid,
                        })),
                        trades: configuredProject.projectTrades?.nodes.map((n) => n.trade.id) ?? [],
                        userId: user.id,
                        wageType: configuredProject.wageType ?? WageType.NOT_SURE,
                        isSaas: configuredProject.isSaas,
                        type: configuredProject.type,
                        subtype: configuredProject.subtype,
                    },
                },
            });

            if (newProject.data) {
                await updateProjectLocation({
                    variables: {
                        input: {
                            projectID: newProject.data.projectSubmission.id,
                            formattedAddress: projectLocation.formattedAddress,
                            streetAddress: projectLocation.streetAddress,
                            city: projectLocation.city,
                            state: projectLocation.state,
                            zip: projectLocation.zip,
                            // coordinates: projectLocation.coordinates,
                        },
                    },
                });
            }

            setProjectSubmissionLoading(false);
            return { uuid: configuredProject.uuid, newId: newProject.data?.projectSubmission.id };
        } else {
            if (isRevision) {
                return submitProjectRevision({
                    projectId: configuredProject.id,
                    newDate: adjustedBidsDueDate,
                    ownerId: user.id,
                }).then((_) => {
                    return submitProjectEdit(configuredProject, [], adjustedBidsDueDate).then(
                        (res) => {
                            return { uuid: res };
                        }
                    );
                });
            }
            return submitProjectEdit(configuredProject, [], adjustedBidsDueDate).then((res) => {
                return { uuid: res };
            });
        }
    };

    const handleSubmitButtonClick = () => {
        setShowErrors(false);

        submitConfiguredProject(configuredProject)
            .then((res) => {
                addNotification(
                    {
                        title: COPY.createProjectSuccessTitle,
                        content: <p>{COPY.createProjectSuccessMsg}</p>,
                    },
                    'success'
                );

                if (typeof res !== 'string')
                    if (flags.improvedMarketplaceSubmission)
                        history.push(`/profile/details`, {
                            from: `/projects/${res.uuid}/`,
                            submittedProjectId: res.newId,
                        });
                    else history.push(`/projects/${res.uuid}/`);
            })
            .catch(() => {
                addNotification(
                    {
                        title: COPY.createProjectErrorTitle,
                        content: (
                            <ErrorMessage
                                message={COPY.createProjectErrorMsg}
                                handleClick={handleSubmitButtonClick}
                            />
                        ),
                    },
                    'error'
                );
            });
    };

    return {
        configuredProject,
        setConfiguredProject,
        projectLocation,
        setProjectLocation,
        plans,
        setPlans,
        showErrors,
        projectSubmissionLoading,
        submitErrors: validationResult?.submitErrors,
        isProjectValid: Boolean(validationResult?.valid),
        submitConfiguredProject,
        handleSubmitButtonClick,
        projectSubmitLoading,
    };
};
