import styled from 'styled-components';

import { borderCement, colorProjectPanelDueDate, colorProjectPanelHeader } from '@/variables';

export const PanelClickInterceptor = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 9;
`;

export const PanelHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3.125rem;
    min-height: 3.125rem;
    padding: 0 1.5rem;
`;

export const PanelHeaderSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const PanelDueDate = styled.div`
    margin-left: 0.75rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    color: ${colorProjectPanelDueDate};
`;

export const PanelProjectName = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: ${colorProjectPanelHeader};
    margin: 1.25rem 1.5rem 1rem;
`;

export const PanelModuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 2;
    width: 100%;
    overflow: hidden auto;
`;

export const PanelDivider = styled.hr`
    border: none;
    border-top: ${borderCement};
    margin: 0;
    background: none;
`;
