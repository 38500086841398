import React, { FC, useState, MutableRefObject } from 'react';
import { Cell, CellType } from 'leyden';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useContextMenuOptions } from '@/components/Estimate/Table/hooks/useContextMenuOptions';
import { MenuRowOption } from '@/components/ui/inputs/Menu';
import MoreOptionsDialog from '@/components/Estimate/Table/components/CellDialog/CellContextMenu/MoreOptionsDialog';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';

interface CellDialogContextMenuProps {
    cell: Cell<CellType>;
    open: boolean;
    onClose: () => void;
    onCloseParent: () => void;
    parentRef: MutableRefObject<HTMLDivElement | null>;
}

const COPY = {
    cancel: 'Cancel',
    confirm: 'Confirm',
};

const CellContextMenu: FC<CellDialogContextMenuProps> = ({
    cell,
    open,
    onClose,
    onCloseParent,
    parentRef,
}) => {
    const options = useContextMenuOptions({ cell });
    const [confirmationParams, setConfirmationParams] = useState<Pick<
        MenuRowOption,
        'confirmationText' | 'onClick'
    > | null>(null);
    const [moreOptions, setMoreOptions] = useState<MenuRowOption[] | undefined>(undefined);

    const onMoreOptionsClick = (options: MenuRowOption[]) => {
        setMoreOptions(options);
    };

    const handleOnClose = () => {
        onClose();
        setMoreOptions(undefined);
    };

    return (
        <>
            <Drawer
                ModalProps={{
                    // Since the modal is rendered at the body level by default
                    // We need this to force the modal to be rendered inside the parent
                    // So it can be below the AppBar component
                    container: parentRef.current,
                }}
                anchor="top"
                open={open}
                onClose={handleOnClose}
            >
                <Toolbar />
                <Box sx={{ width: 'auto' }} role="presentation">
                    <List>
                        {options.map(({ text, options, icon: Icon, onClick, confirmationText }) => {
                            return (
                                <ListItem
                                    button
                                    key={text}
                                    onClick={() => {
                                        if (!onClick && options) {
                                            onMoreOptionsClick(options);
                                            return;
                                        }

                                        if (!onClick) {
                                            return;
                                        }

                                        if (confirmationText) {
                                            setConfirmationParams({
                                                confirmationText,
                                                onClick,
                                            });
                                            return;
                                        }

                                        onClick();
                                        handleOnClose();
                                        onCloseParent();
                                    }}
                                >
                                    <ListItemText primary={text} />
                                    <ListItemIcon>
                                        {Icon && <Icon width={20} height={20} />}
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </List>
                    <MoreOptionsDialog
                        open={!!moreOptions}
                        options={moreOptions}
                        onClose={handleOnClose}
                        onCloseParent={() => {
                            handleOnClose();
                            onCloseParent();
                        }}
                    />
                </Box>
            </Drawer>
            <ConfirmationDialog
                open={!!confirmationParams}
                title={confirmationParams?.confirmationText?.title}
                description={confirmationParams?.confirmationText?.description}
                onCancel={() => {
                    setConfirmationParams(null);
                    handleOnClose();
                }}
                onCancelText={confirmationParams?.confirmationText?.cancel ?? COPY.cancel}
                onConfirm={() => {
                    confirmationParams?.onClick?.();
                    handleOnClose();
                    onCloseParent();
                }}
                onConfirmText={confirmationParams?.confirmationText?.confirm ?? COPY.confirm}
            />
        </>
    );
};

export default CellContextMenu;
