import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import {
    IProjectLocationAssignmentInput,
    IProjectLocationAssignmentMutation,
    IProjectLocationAssignmentMutationVariables,
    ProjectLocationAssignmentDocument,
} from '@/graphql';
import { Setter } from '@/common/types';
import { inferAddressAttributesFromPlace } from '@/common/utils/googleLocationHelpers';

interface UseUpdateProjectLocationArgs {
    projectId: number;
    setProjectLocation?: Setter<Omit<IProjectLocationAssignmentInput, 'projectID'> | undefined>;
}

export type UseUpdateProjectLocationPayload = (
    address?: google.maps.places.PlaceResult | null,
    location?: [number, number]
) => void;

/**
 * Update project location on server and locally.
 * @param {UseUpdateProjectLocationArgs} args - project and optional setter to update location
 * @returns {UseUpdateProjectLocationPayload} wrapped callback that posts to server
 */
export const useUpdateProjectLocation = ({
    projectId,
    setProjectLocation,
}: UseUpdateProjectLocationArgs): UseUpdateProjectLocationPayload => {
    // Mutations
    /*-------------------*/
    const [updateProjectLocation] = useMutation<
        IProjectLocationAssignmentMutation,
        IProjectLocationAssignmentMutationVariables
    >(ProjectLocationAssignmentDocument);

    /**
     * Update a project location and post mutation to server
     * @param {{
     * address?: google.maps.places.PlaceResult | null,
     * location?: string
     * }} - address object provided by @see Location.tsx
     */
    const submitNewProjectLocation = useCallback(
        (address?: google.maps.places.PlaceResult | null, location?: [number, number]): void => {
            // Overwrite location if address is set to null or changed.  Otherwise, spread nothing
            // to keep previous address data.
            if (address && location) {
                const locationOverwrite =
                    address !== undefined ? inferAddressAttributesFromPlace(address) : {};

                // Update local state
                setProjectLocation?.({
                    ...locationOverwrite,
                    coordinates: location,
                });

                // Post to server
                updateProjectLocation({
                    variables: {
                        input: {
                            projectID: `${projectId}`,
                            coordinates: location,
                            ...locationOverwrite,
                        },
                    },
                });
            }
        },
        [projectId]
    );

    return submitNewProjectLocation;
};
