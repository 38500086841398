import { writeStorage } from '@rehooks/local-storage';

export type StorageSetter<T extends unknown> = (newValue: T) => void;

export enum StorageKey {
    CheckoutPriceId = 'checkout-price-id',
    CheckoutResult = 'checkout-result',
    CheckoutSuccessComplete = 'checkout-success-complete',
    Referrer = 'referrer',
    IsEstimateHeaderCollapsed = 'is-estimate-header-collapsed',
    State = 'state',
    County = 'county',
    HasUploadedFiles = 'has-uploaded-files',
    QueryParams = 'query-params',
}

export const getStorageKeyByProjectUUID = (projectUUID: string, key: StorageKey) => {
    return `${key}--${projectUUID ?? ''}`;
};

export const useLocalStorageUtils = () => {
    const clearLocalStorage = ({ exclude }: { exclude?: StorageKey[] } = {}) => {
        const excludedKeysAndValues = exclude?.map((key) => ({
            key,
            value: localStorage.getItem(key),
        }));

        // TODO - Figure out a way to make this work
        // The issue with this approach is that, since is trigger a re-render of some components
        // the values related with the authentication are being written in those renders hence
        // the user can't logout directly, this happens if you're in a project and try to logout
        // from there

        // for (let i = 0; i < localStorage.length; i++) {
        //     const key = localStorage.key(i);
        //
        //     if (key) {
        //         deleteFromStorage(key);
        //     }
        // }

        localStorage.clear();

        excludedKeysAndValues?.forEach(({ key, value }) => {
            writeStorage(key, value);
        });
    };

    return {
        clearLocalStorage,
    };
};
