/*
 * Action is a clickable action button in the projects table.
 */
import React, { FC } from 'react';

import { openedProjectObservable } from './observables';
import { ProjectsTableAction, ProjectsTableActionIconContainer } from './styled';

import {
    ActionTab,
    InitialAction,
    ProjectAction,
    ProjectPanelTab,
} from '@/components/AdminDashboard/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { ProjectRecord } from '@/queries/projects';
import { colorProjectAssignEstimator, colorStatusSuccess } from '@/variables';

import { ReactComponent as Dollar } from '@/assets/icons/Dollar.svg';
import { ReactComponent as Person } from '@/assets/icons/Person.svg';
import { ReactComponent as SortArrow } from '@/assets/icons/SortArrow.svg';

const COPY = {
    assign: 'ASSIGN',
};

const mapActionToColor = (action: ProjectAction): string => {
    switch (action) {
        case ProjectAction.AssignEstimator:
            return colorProjectAssignEstimator;
        default:
        case ProjectAction.AssignPrice:
            return colorStatusSuccess;
    }
};

const mapActionToInitialAction = (action: ProjectAction): InitialAction | undefined => {
    switch (action) {
        case ProjectAction.AssignPrice:
            return InitialAction.FocusPrice;
        default:
            return undefined;
    }
};

const mapActionToProjectPanelTab = (action: ProjectAction): ProjectPanelTab | undefined => {
    switch (action) {
        case ProjectAction.AssignEstimator:
            return ActionTab.Estimator;
        case ProjectAction.AssignPrice:
            return ActionTab.Price;
        default:
            return undefined;
    }
};

export interface ActionProps {
    action: ProjectAction;
    project: ProjectRecord;
}

export const Action: FC<ActionProps> = ({ action, project }) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.stopPropagation();
        openedProjectObservable.next({
            project,
            initialAction: mapActionToInitialAction(action),
            tab: mapActionToProjectPanelTab(action),
        });
    };

    const renderActionIcon = (): JSX.Element => {
        switch (action) {
            case ProjectAction.AssignEstimator:
                return <SvgIcon src={Person} width="0.75rem" height="0.75rem" noInherit={true} />;
            default:
            case ProjectAction.AssignPrice:
                return (
                    <SvgIcon
                        src={Dollar}
                        width="1.25rem"
                        height="1.25rem"
                        style={{ fill: mapActionToColor(action) }}
                    />
                );
        }
    };

    return (
        <ProjectsTableAction color={mapActionToColor(action)} onClick={handleClick}>
            <ProjectsTableActionIconContainer shiftIcon={action === ProjectAction.AssignPrice}>
                {renderActionIcon()}
            </ProjectsTableActionIconContainer>
            {COPY.assign}
            <SvgIcon
                className="arrow-icon"
                src={SortArrow}
                width="0.75rem"
                height="0.75rem"
                style={{ fill: mapActionToColor(action) }}
            />
        </ProjectsTableAction>
    );
};
