import React, { FC } from 'react';

import { ProjectInfoInputContainer, ProjectInfoInputLabel } from './styled';

export interface ProjectInfoInputProps {
    className?: string;
    label: string;
}
export const ProjectInfoInput: FC<ProjectInfoInputProps> = ({ className, label, children }) => (
    <ProjectInfoInputContainer className={className}>
        <ProjectInfoInputLabel>{label}</ProjectInfoInputLabel>
        {children}
    </ProjectInfoInputContainer>
);
