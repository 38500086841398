import React, { FC } from 'react';

import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ProjectCreation } from '@/views/ProjectCreation';

/**
 * Wrapper component that provides project to ProjectCreation
 */
export const ProjectEditing: FC = () => {
    const { project, projectLocation } = useProjectFromParams();

    return project && projectLocation !== undefined ? (
        <ProjectCreation initProject={project} initProjectLocation={projectLocation} />
    ) : (
        <SpinnerLoader />
    );
};
