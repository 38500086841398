import React, { FC } from 'react';
import clsx from 'clsx';

import { DivProps } from '@/common/types';

import './DotsLoader.scss';

export interface DotsLoaderProps extends DivProps {
    dotProps?: DivProps;
}

export const DotsLoader: FC<DotsLoaderProps> = ({ className, dotProps, ...rest }) => (
    <div {...rest} className={clsx('dots-loader', className)}>
        <div {...dotProps} className="dots" />
        <div {...dotProps} className="dot2 dots" />
        <div {...dotProps} className="dot3 dots" />
    </div>
);
