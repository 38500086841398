/*
 * InfoInput is an input field that displays a numeric value.
 */
import React, { FC, useEffect, useState } from 'react';

import {
    InfoInputContainer,
    InfoInputInput,
    InfoInputLabel,
    InfoInputPrefix,
    InfoInputSuffix,
} from './styled';

import { isNaiveNumericInput, isNumericInput } from '@/common/utils/helpers';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';

const COPY = {
    currency: '$',
};

export interface InfoInputProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type'> {
    label: string;
    value: number;
    isCents?: boolean;
    persistValue?: (val: number) => Promise<void>;
}

export const InfoInput: FC<InfoInputProps> = ({
    disabled,
    label,
    value,
    isCents,
    persistValue,
    onBlur,
    onChange,
    onFocus,
    ...rest
}) => {
    const inputValFromRawVal = (raw: number): number => (isCents ? raw / 100 : raw);

    const rawValFromInputVal = (input: number): number =>
        isCents ? Math.round(input * 100) : input;

    const [inputValue, setInputValue] = useState<number | string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => setInputValue(inputValFromRawVal(value)), [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange !== undefined) {
            onChange(e);
        }

        const newVal = e.currentTarget.value;
        if (newVal === '') {
            setInputValue('');
            return;
        }

        if (isNumericInput(newVal)) {
            setInputValue(Number(newVal));
        } else if (isNaiveNumericInput(newVal)) {
            setInputValue(newVal);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (onBlur !== undefined) {
            onBlur(e);
        }

        if (!persistValue || inputValue === '') {
            setInputValue(inputValFromRawVal(value));
            return;
        }
        let rawVal = inputValue;
        if (typeof inputValue === 'number') {
            rawVal = rawValFromInputVal(inputValue);
        }
        if (rawVal === value) {
            return;
        }
        if (!isNumericInput(inputValue.toString())) {
            return;
        }
        setIsLoading(true);
        persistValue(Number(rawVal)).then(() => setIsLoading(false));
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (onFocus !== undefined) {
            onFocus(e);
        }
        if (inputValue === 0) {
            setInputValue('');
        }
    };

    return (
        <InfoInputContainer>
            <InfoInputLabel>{label}</InfoInputLabel>
            {isCents && (
                <InfoInputPrefix isPlaceholder={inputValue === ''}>{COPY.currency}</InfoInputPrefix>
            )}
            {isLoading && (
                <InfoInputSuffix>
                    <DotsLoader />
                </InfoInputSuffix>
            )}
            <InfoInputInput
                type="number"
                hasPrefix={isCents}
                value={inputValue}
                disabled={disabled || isLoading}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                {...rest}
            />
        </InfoInputContainer>
    );
};
