import { SignupForm } from '..';
import { useUser } from '@/contexts/User';
import {
    IUserBusiness,
    IUserCompanyRole,
    IUserFragment,
    IUserService,
    useTeamLeaderOnboardingAssignmentMutation,
} from '@/graphql';
import { useGetQueryParams } from '@/common/queryParams';
import { Env } from '@/common/env';

const COPY = {
    businessTypeError: 'No business type selected',
    teamIDError: 'No team ID assigned',
    updateOnboardingError: 'Error updating onboarding information',
};

export const useAddWorkDetailsOnSignup = (signupForm: SignupForm) => {
    const {
        actions: { setUser, setAnonymousUser },
    } = useUser();
    const [teamLeaderOnboardingAssignmentMutation] = useTeamLeaderOnboardingAssignmentMutation();

    const { getQueryParamByName } = useGetQueryParams();

    const inviteIdQueryParamExists = !!getQueryParamByName('invite_id');

    return async (user: IUserFragment) => {
        if (!signupForm.businessType) {
            throw new Error(COPY.businessTypeError);
        }

        if (!user.teamID) {
            throw new Error(COPY.teamIDError);
        }

        try {
            // For invited users we don't alter the team name
            // and we add them as other instead of the owner
            await teamLeaderOnboardingAssignmentMutation({
                variables: {
                    input: {
                        id: user.id,
                        phone: signupForm.phone,
                        businessType: signupForm.businessType as IUserBusiness,
                        bidValue: signupForm.bidValue,
                        teamName: inviteIdQueryParamExists ? '' : signupForm.companyName,
                        projectTypes: '',
                        service: IUserService.Estimator,
                        // TODO - Figure out a better way for this
                        teamID: user.team?.id ?? '',
                        companyRole: inviteIdQueryParamExists
                            ? IUserCompanyRole.Other
                            : IUserCompanyRole.Owner,
                    },
                },
                refetchQueries: ['User'],
            });

            setUser((oldUser) => ({
                ...oldUser,
                phone: signupForm.phone,
            }));

            setAnonymousUser(false);
        } catch (e) {
            throw Error(Env.tier.isDevelopment ? String(e) : COPY.updateOnboardingError);
        }
    };
};
