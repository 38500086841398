import React, { FC } from 'react';

import { DivProps } from '@/common/types';
import { State } from '@/components/mission-control/UserManager/state';
import { Header } from '@/components/mission-control/UserManager/UsersTable/Header';
import { Row } from '@/components/mission-control/UserManager/UsersTable/Row';
import { UserTableContainer } from '@/components/mission-control/UserManager/UsersTable/styled';

export interface UsersTableProps extends DivProps {
    state: State;
}

export const UsersTable: FC<UsersTableProps> = ({ state, ...rest }) => (
    <UserTableContainer {...rest}>
        <Header />
        {state.users.map((user) => (
            <Row key={user.id} user={user} state={state} />
        ))}
    </UserTableContainer>
);
