import { Distribute } from '@/common/types';

export enum TokenKind {
    Numeric = 2,
    Modifier = 12,
    ClauseOpen = 13,
    ClauseClose = 14,
    Geometry = 100,
    GeometryGroup = 101,
}

export enum ModifierKind {
    Plus = 14,
    Minus = 15,
    Multiply = 21,
    Divide = 22,
    Modulus = 23,
    Exponent = 24,
}

export type TokenKindValueMap = {
    [TokenKind.Numeric]: { quantity: number };
    [TokenKind.Modifier]: { symbol: ModifierKind };
    [TokenKind.ClauseOpen]: Record<string, never>;
    [TokenKind.ClauseClose]: Record<string, never>;
    [TokenKind.Geometry]: { id: string };
    [TokenKind.GeometryGroup]: { id: string };
};

export interface TokenScalar<T extends TokenKind = Distribute<TokenKind>> {
    kind: T;
    value: TokenKindValueMap[T];
}

export interface CustomScalars {
    Token: TokenScalar;
}
