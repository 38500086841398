/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Path as LeafletPath, Circle as LeafletCircle } from 'leaflet';
import { PathOptions, CircleMarkerOptions } from 'leaflet';
import { Path } from 'react-leaflet';

// Adds lineType and shapeWeight parameters to Path options
const originalPathOptions = LeafletPath.prototype.options;
LeafletPath.prototype.options = {
    ...originalPathOptions,
    lineType: '',
    areaType: '',
    shapeWeight: undefined,
} as PathOptions;

// Adds lineType and shapeWeight parameters to Path options
const originalCircleOptions = LeafletCircle.prototype.options;
LeafletCircle.prototype.options = {
    ...originalCircleOptions,
    customMarkerType: undefined,
} as CircleMarkerOptions;

// Extends Leaflet Path prototype. Function pick is a part
// of a leaflet js package so no all types are specified.
// eslint-disable-next-line
Path.prototype.getPathOptions = function (props: any): PathOptions {
    function pick(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        object: Record<string, any>,
        keys: Array<string>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Record<string, any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return keys.reduce((obj: any, key) => {
            if (typeof object[key] !== 'undefined') {
                obj[key] = object[key];
            }
            return obj;
        }, {});
    }

    const OPTIONS = [
        'stroke',
        'color',
        'weight',
        'opacity',
        'lineCap',
        'lineJoin',
        'dashArray',
        'dashOffset',
        'fill',
        'fillColor',
        'fillOpacity',
        'fillRule',
        'bubblingMouseEvents',
        'renderer',
        'className',
        // Interactive Layer
        'interactive',
        // Layer
        'pane',
        'attribution',
        'lineType',
        'areaType',
        'shapeWeight',
        'customMarkerType',
    ];
    return pick(props, OPTIONS);
};
