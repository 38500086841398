import { Body, DialogContent, Heading, LeftContent, RightContent } from './styled';
import { StorageKey } from '@/common/storage';
import { plans } from '@/components/pricing/PricingPage/plans';
import {
    hasEssentialSubscription,
    hasProfessionalSubscription,
    hasSelfStarterSubscription,
} from '@/components/pricing/PricingPage/utils';
import { useUser } from '@/contexts/User';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { useLocalStorage } from '@rehooks/local-storage';
import React, { FC } from 'react';
import { Mode } from '@/theme/Mode';

interface Props extends DialogProps {
    onConfirmButtonClick?: () => void;
}

export const SubscribeSuccessModal: FC<Props> = ({ onConfirmButtonClick, ...props }) => {
    const user = useUser();

    const [checkoutResult] = useLocalStorage(StorageKey.CheckoutResult);

    let title;
    let description;

    if (checkoutResult === 'update') {
        if (hasEssentialSubscription(plans, user.data.user?.team)) {
            title = "You've upgraded to the Essential plan!";
            description =
                'With Essential, you can easily create estimates with accurate local cost data and access unlimited expert estimates (at an additional cost).';
        } else if (hasProfessionalSubscription(plans, user.data.user?.team)) {
            title = "You've upgraded to the Professional plan!";
            description =
                'With Professional, you can now upload plans for more accurate estimates and access an unlimited number of expert estimates (at an additional cost).';
        } else {
            title = "You've upgraded to a subscription plan!";
            description =
                'You can now upload plans for more accurate estimates and access expert estimates.';
        }
    } else if (checkoutResult === 'create') {
        if (hasSelfStarterSubscription(plans, user.data.user?.team)) {
            title = "You've subscribed to the Self-Starter plan!";
            description =
                'With Self-Starter, you can easily create unlimited estimates with accurate local cost data and win more business.';
        } else if (hasEssentialSubscription(plans, user.data.user?.team)) {
            title = "You've subscribed to the Essential plan!";
            description =
                'With Essential, you can easily create estimates with accurate local cost data and access unlimited expert estimates (at an additional cost).';
        } else if (hasProfessionalSubscription(plans, user.data.user?.team)) {
            title = "You've subscribed to the Professional plan!";
            description =
                'With Professional, you can easily create unlimited estimates with accurate local cost data, upload plans, and access an unlimited number of expert estimates (at an additional cost).';
        } else {
            title = "You've subscribed to a subscription plan!";
            description =
                'You can easily create unlimited estimates with accurate local cost data and win more business.';
        }
    }

    return (
        <ScopedCssBaseline>
            <Mode variant="light">
                <Dialog fullWidth maxWidth="md" {...props}>
                    <DialogContent>
                        <LeftContent />
                        <RightContent>
                            <Heading variant="h2">{title}</Heading>
                            <Body variant="body1">{description}</Body>
                            <Button fullWidth onClick={onConfirmButtonClick} variant="contained">
                                Get Started
                            </Button>
                        </RightContent>
                    </DialogContent>
                </Dialog>
            </Mode>
        </ScopedCssBaseline>
    );
};
