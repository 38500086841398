/*
    Displays content as full page size modal with translucent background. Also
    adds 'overflow: hidden' to body, to prevent scrolling through content that
    is behind the modal.
*/

import React, { FC, useEffect, MouseEvent } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { DivProps } from '@/common/types';

import './Modal.scss';

export type ModalProps = DivProps & {
    open: boolean;
    onClose?: () => void;
};

const setBodyOverflow = (value: string): string => (document.body.style.overflow = value);

const clearBodyStyles = (): void => document.body.removeAttribute('style');

export const Modal: FC<ModalProps> = ({ open, onClose, children, className }) => {
    const id = uuid();

    useEffect(() => {
        if (open) setBodyOverflow('hidden');
        else clearBodyStyles();

        return clearBodyStyles;
    }, [open]);

    /* Emit close event if clicked on background */
    const handleClose = (e: MouseEvent): void | false =>
        (e.target as HTMLDivElement).id === id && onClose && onClose();

    return (
        <div id={id} className={clsx('modal', { open }, className)} onClick={handleClose}>
            {children}
        </div>
    );
};
