import styled from 'styled-components';

import {
    borderCement,
    colorBackgroundPlaceholder,
    colorBackgroundSecondaryLightest,
    colorProjectPanelHighlight,
} from '@/variables';

export const PanelAssignTab = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border: ${borderCement};
    padding: 0.25rem 1rem 0.4rem 0.75rem;
    border-radius: ${(props): string => (props.active ? '3px 3px 0 0' : '3px')};
    background-color: ${colorBackgroundSecondaryLightest};
    cursor: pointer;
    z-index: 15;

    &.active-tab {
        box-shadow: inset 0 -5px 0 #128afa;
    }

    &.confirm-price-tab {
        background-color: #eef7ff;
        span {
            color: #128afa;
        }
    }
`;

export const PanelAssignTabEstimatorCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.25rem -0.25rem 0 1.5rem;
    border-radius: 0.75rem;
    background-color: ${colorBackgroundPlaceholder};
`;

export const PanelInfoTab = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
`;

export const PanelInfoTabText = styled.div`
    padding: 0.5rem 1rem;
`;

export const PanelInfoTabUnderline = styled.div<{ active?: boolean }>`
    background-color: ${(props): string =>
        props.active ? colorProjectPanelHighlight : 'transparent'};
    height: 0.25rem;
    border-radius: 3px 3px 0 0;
`;

export const PanelNavbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0.875rem;
    font-weight: 700;
    font-size: 0.875rem;
    user-select: none;
    overflow-x: auto;
    min-height: 54px;

    &::-webkit-scrollbar {
        display: none;
    }
`;
