import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { Mode } from '@/theme/Mode';

import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/32/chevron--right.svg';
import { ReactComponent as AlertIcon } from '@/assets/icons/32/warning--alt--filled.svg';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { PaymentSetupForm } from '@/views/PaymentSetup/PaymentSetup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Env } from '@/common/env';
import { useSetupIntentLazyQuery } from '@/graphql';
import { LoadingPage } from '@/views/LoadingPage';

const COPY = {
    title: 'There was a problem processing this charge.',
    cc_info_title: 'Before you can approve, please provide your credit card information.',
    text: `We were unable to process this charge. Reenter your credit card details,
    or contact your credit card provider. If you feel that this issue is not related to your credit card, 
    contact 1build support.`,
    contact_support: 'Contact support',
    correct_billing: 'Enter credit card info',
};

type StripeErrorModalProps = {
    isModalVisible: boolean;
    close: () => void;
    proceedToCCForm: () => void;
    projectName?: string;
    projectUUID: string;
    stripeUrl: string;
};

export const StripeErrorModal: FC<StripeErrorModalProps> = ({
    isModalVisible,
    close,
    projectName,
    projectUUID,
    stripeUrl,
    proceedToCCForm,
}) => {
    const theme = useTheme();
    const [stripePromise] = useState(loadStripe(Env.stripePublicKey));
    const [loadSetupIntent, { data, loading }] = useSetupIntentLazyQuery();

    // If stripeUrl wasn't set, that means the client doesn't have CC info at hand, so it's more productive to just
    // show them a CC info form instead of informing them there was a failure.
    const shouldRenderCCForm = stripeUrl === '';

    useEffect(() => {
        if (!data && !loading && shouldRenderCCForm) {
            loadSetupIntent();
        }
    }, [loadSetupIntent, data, loading, shouldRenderCCForm]);

    return (
        <Mode variant="light">
            <Dialog open={isModalVisible} onClose={close} maxWidth={'xs'}>
                {shouldRenderCCForm ? (
                    data ? (
                        <>
                            <DialogTitle>
                                <Typography
                                    sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                                    variant="h2"
                                >
                                    {COPY.cc_info_title}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Elements
                                    stripe={stripePromise}
                                    options={{
                                        clientSecret: data.setupIntent.clientSecret,
                                        appearance: {
                                            theme: 'stripe',
                                            labels: 'floating',

                                            variables: {
                                                spacingGridRow: '40px',
                                            },
                                        },
                                    }}
                                >
                                    <PaymentSetupForm
                                        displayText={false}
                                        returnURL={`${Env.deploymentURL}/projects/${projectUUID}`}
                                    />
                                </Elements>
                            </DialogContent>
                        </>
                    ) : (
                        <LoadingPage />
                    )
                ) : (
                    <>
                        <DialogTitle>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <AlertIcon
                                    width={32}
                                    height={32}
                                    style={{ fill: theme.palette.hues.amber[0] }}
                                />
                                <IconButton onClick={close}>
                                    <CloseIcon width={30} height={30} fill="#65667A" />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                                    variant="h2"
                                >
                                    {COPY.title}
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Typography sx={{ color: (theme) => theme.palette.hues.neutral[48] }}>
                                {COPY.text}
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'flex-start' }}>
                            <Link
                                href={`mailto:help@1build.com?subject=Failed charge for Marketplace project - ${
                                    projectName || ''
                                }`}
                            >
                                <Button variant="text">{COPY.contact_support}</Button>
                            </Link>
                            <Button
                                variant="contained"
                                endIcon={
                                    <ArrowIcon
                                        width={20}
                                        height={15}
                                        style={{ fill: theme.palette.hues.neutral[100] }}
                                    />
                                }
                                onClick={proceedToCCForm}
                            >
                                {COPY.correct_billing}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Mode>
    );
};
