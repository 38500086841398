import {
    Backdrop,
    Button,
    ButtonGroup,
    Container,
    HeaderSection,
    Modal,
    PoppinsHeader,
    variables,
} from './styled';
import { onClickAndEnterKey } from '@/common/utils/eventHandlers';
import React, { FC } from 'react';

export { Button, ButtonGroup, PoppinsHeader } from './styled';

export interface BasicModalProps {
    className?: string;
    header: JSX.Element | string;
    cancelCopy?: string;
    submitCopy?: string;
    close: () => void;
    submit: () => void;
    preventDefault?: boolean;
}
export const BasicModal: FC<BasicModalProps> = ({
    className,
    header,
    cancelCopy,
    submitCopy,
    close,
    submit,
    preventDefault = true,
    children,
}) => (
    <Container className={className}>
        {/** Opacity overlay */}
        <Backdrop onClick={(): void => close()} />

        {/** Modal */}
        <Modal onClick={preventDefault ? (e): void => e.preventDefault() : undefined}>
            {/** Calculate unique selected and active items */}
            <HeaderSection noBorder>
                <PoppinsHeader>{header}</PoppinsHeader>
                {children}
            </HeaderSection>

            <ButtonGroup marginTop>
                {cancelCopy && (
                    <Button
                        tabIndex={0}
                        color={variables.colorTypographyDark}
                        borderColor={variables.buttonPrimaryBorder}
                        backgroundColor={variables.buttonLightBackground}
                        type="secondary"
                        {...onClickAndEnterKey(close)}
                    >
                        {cancelCopy}
                    </Button>
                )}
                {submitCopy && (
                    <Button
                        tabIndex={0}
                        color={variables.buttonLightBackground}
                        borderColor={variables.safety}
                        backgroundColor={variables.safety}
                        type="secondary"
                        {...onClickAndEnterKey(submit)}
                    >
                        {submitCopy}
                    </Button>
                )}
            </ButtonGroup>
        </Modal>
    </Container>
);
