import { breakpoints } from '@/common/styles/helpers/breakpoints';
import styled from 'styled-components';

export const Content = styled.div`
    display: grid;
    grid-template: ' . ' 1fr / 262px 1fr auto;
    overflow-y: auto;
    overflow-x: hidden;

    width: 100vw;
    max-height: calc(100vh - 4rem);

    // the left sidebar should scroll independently if it's too tall.
    & > div:first-child {
        overflow-y: auto;
    }

    ${breakpoints.medium`
        grid-template: " . " 1fr / 1fr auto;
    `}
`;

export const Container = styled.div`
    display: flex;
    flex: 1;
`;
