import { toMarkup } from '@/common/convert/geometry';
import { markupEntryIsMarkup } from '@/common/typeGuards';
import { Geometry, GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import { geometryTypeToMarkupType } from '@/common/utils/geometries/maps';
import { ICoordinateFragment, IMarkupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';

// extractMarkupsFromMarkupEntries returns all markups in a markup entries array, including those nested within groups.
export const extractMarkupsFromMarkupEntries = (
    markupEntries: IMarkupEntry[]
): IMarkupFragment[] => {
    return markupEntries.reduce<IMarkupFragment[]>((acc, cur) => {
        if (markupEntryIsMarkup(cur)) {
            return [...acc, cur];
        }
        if (cur.markups && cur.markups.length > 0) {
            return [...acc, ...cur.markups];
        }
        return acc;
    }, []);
};

// pointsAreEqual returns true if the x and y values of the passed points are within 0.01 of each other.
const pointsAreEqual = (a: ICoordinateFragment, b: ICoordinateFragment): boolean => {
    return Math.abs(a.x - b.x) <= 0.01 && Math.abs(a.y - b.y) <= 0.01;
};

// markupDataEqualsGeometry returns true if the geometry data in both passed objects is equivalent.
const markupDataEqualsGeometry = (markup: IMarkupFragment, geometry: Geometry): boolean => {
    const markupGeometries = markup.geometries;
    if (geometry.coordinates.length === 0 || markupGeometries === null) {
        return geometry.coordinates.length === 0 && !markupGeometries;
    }
    return newGeometryHandler({
        [GeometryType.AREA]: (area) => {
            const markupArea = markupGeometries[0];
            return (
                markupGeometries.length === 1 &&
                markupArea.__typename === 'MarkupPolygon' &&
                markupArea.rings.length === area.coordinates.length &&
                markupArea.rings.every(
                    (ring, i) =>
                        ring.points.length === area.coordinates[i].length &&
                        ring.points.every((point, j) =>
                            pointsAreEqual(point, area.coordinates[i][j])
                        )
                )
            );
        },
        [GeometryType.COUNT]: (count) => {
            return (
                markupGeometries.length === count.coordinates.length &&
                markupGeometries.every(
                    (markupCoord, i) =>
                        markupCoord.__typename === 'Coordinate' &&
                        pointsAreEqual(markupCoord, count.coordinates[i])
                )
            );
        },
        [GeometryType.LINEAR]: (line) => {
            const markupLine = markupGeometries[0];
            return (
                markupGeometries.length === 1 &&
                markupLine.__typename === 'MarkupLine' &&
                markupLine.points.length === line.coordinates.length &&
                markupLine.points.every((point, i) => pointsAreEqual(point, line.coordinates[i]))
            );
        },
    })(geometry);
};

interface MarkupDiff {
    colorModified: IMarkupFragment[];
    created: IMarkupFragment[];
    deleted: IMarkupFragment[];
    geometryModified: IMarkupFragment[];
}

// markupsChangedBySnapshot returns a diff between a new geometry snapshot an an old set of
// fetched markups. The returned markup fragments reflect updated markup fragments, populated
// with post-snapshot data.
// If `scaleModified` is true, all values have changed and the returned diff will include all
// `snapshot` geometries within `geometryModified` and `created`.
export const markupsChangedBySnapshot = (
    old: IMarkupFragment[],
    snapshot: Geometry[],
    projectPlanPageID: number,
    scale: number | null,
    scaleModified: boolean
): MarkupDiff => {
    const diff: Omit<MarkupDiff, 'deleted'> = {
        created: [],
        colorModified: [],
        geometryModified: [],
    };
    // Filter out any old markups that aren't on the page we're diffing.
    const oldMarkupMap = new Map(
        old
            .filter((markup) => markup.projectPlanPageID === String(projectPlanPageID))
            .map((markup) => [markup.id, markup])
    );
    snapshot.forEach((updatedGeometry) => {
        const oldMarkup = oldMarkupMap.get(updatedGeometry.uuid);
        const getUpdatedMarkup = () => {
            return toMarkup(
                updatedGeometry,
                projectPlanPageID,
                scale,
                oldMarkup?.markupGroupID ?? null
            );
        };
        // If there's no old record with this geometry's uuid, it has been created.
        if (!oldMarkup) {
            diff.created.push(getUpdatedMarkup());
            return;
        }
        // Delete the compared geometry from the old map.
        // This leaves only deleted geometries in the map after this reducer runs.
        oldMarkupMap.delete(updatedGeometry.uuid);
        // If the scale changed or the geometry's old record is outdated, add it to the diff.
        if (
            scaleModified ||
            oldMarkup.markupType !== geometryTypeToMarkupType[updatedGeometry.type] ||
            !markupDataEqualsGeometry(oldMarkup, updatedGeometry)
        ) {
            diff.geometryModified.push(getUpdatedMarkup());
        }
        if (oldMarkup.color !== updatedGeometry.style.color) {
            diff.colorModified.push(getUpdatedMarkup());
        }
    });
    return { ...diff, deleted: Array.from(oldMarkupMap.values()) };
};
