import { Transforms as LeydenTransforms } from 'leyden';

import { CategoryTransforms } from './category';
import { ElementTransforms } from './element';
import { ElementQuantityTransforms } from './elementQuantity';

export type Transforms = CategoryTransforms &
    ElementTransforms &
    ElementQuantityTransforms &
    LeydenTransforms;

export const Transforms = {
    ...CategoryTransforms,
    ...ElementTransforms,
    ...ElementQuantityTransforms,
    ...LeydenTransforms,
};
