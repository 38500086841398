import { Element, Text as LeydenText } from 'leyden';
import { Editor, Range, Text } from 'slate';

import { EstimateEditor } from './EstimateEditor';

const protectedElementTypes = ['ElementQuantityExpressionInputView'];

const protectedTextTypes = ['ElementQuantityNumeric'];

export const withProtectedNodes = <T extends EstimateEditor>(editor: T): T => {
    const { deleteBackward, deleteForward } = editor;

    editor.deleteBackward = (unit) => {
        const { selection } = editor;
        if (selection !== null && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
            let leafLoop = true;
            for (const [node, path] of Editor.levels(editor, {
                reverse: true,
                match: (_, path) => path.length > 2,
            })) {
                if (leafLoop) {
                    if (
                        Text.isText(node) &&
                        LeydenText.isText(node) &&
                        protectedTextTypes.includes(node.type)
                    ) {
                        return;
                    }
                    leafLoop = false;
                } else {
                    if (Element.isElement(node) && protectedElementTypes.includes(node.type)) {
                        return;
                    }
                }
                // If this node is not the first in its parent, its deletion will not cascade to
                // any of its ancestors.
                if (path.slice(-1)[0] !== 0) {
                    break;
                }
            }
        }
        deleteBackward(unit);
    };

    editor.deleteForward = (unit) => {
        const { selection } = editor;
        if (selection !== null && Range.isCollapsed(selection)) {
            let previousElementIndex: null | number = null;
            let leafLoop = true;
            for (const [node, path] of Editor.levels(editor, {
                reverse: true,
                match: (_, path) => path.length > 2,
            })) {
                if (leafLoop) {
                    if (Text.isText(node)) {
                        // If deleting forward but not at end of text, proceed with deletion.
                        if (node.text.length !== selection.anchor.offset) {
                            break;
                        }
                        if (LeydenText.isText(node) && protectedTextTypes.includes(node.type)) {
                            return;
                        }
                    }
                    leafLoop = false;
                } else {
                    if (Element.isElement(node)) {
                        // If the previous node is not the last in this one, its deletion will not
                        // cascade to this one of any of its ancestors.
                        if (
                            previousElementIndex !== null &&
                            node.children.length - 1 > previousElementIndex
                        ) {
                            break;
                        }
                        if (protectedElementTypes.includes(node.type)) {
                            return;
                        }
                    }
                }
                previousElementIndex = path.slice(-1)[0];
            }
        }
        deleteForward(unit);
    };

    return editor;
};
