import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, CenteredContainer, Header, Content, Button } from './styled';
import { ArrowButton } from '@/components/ui/buttons/ArrowButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    projectCanceled: 'This project was canceled.',
    projectDashboard: 'Go to dashboard',
    projectDoesNotExist: 'Project no longer exists',
};

interface CancelledProps {
    projectName?: string;
}
export const Cancelled: FC<CancelledProps> = ({ projectName }) => {
    const history = useHistory();
    const { builderAcceptance } = useFlags();

    const returnToDashboard = (): void => {
        history.push('/projects');
    };

    if (builderAcceptance) {
        return (
            <CenteredContainer>
                <Header>{`${projectName || 'Project'} has been cancelled.`}</Header>
                <Content>
                    Declining the project will remove it from our queue and from your dashboard. If
                    you decide to pursue this project in the future, you will need to recreate the
                    project and resubmit project files.
                </Content>
                <Button onClick={returnToDashboard}>Go to dashboard</Button>
            </CenteredContainer>
        );
    }

    return (
        <Container>
            <h1>{COPY.projectDoesNotExist}</h1>
            <p>{COPY.projectCanceled}</p>
            <ArrowButton
                className="submit-button continue"
                text={COPY.projectDashboard}
                onClick={returnToDashboard}
                variant="outlined"
            />
        </Container>
    );
};
