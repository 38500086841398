import { BusinessType, CompanyRole, ServiceType } from '../common/types';
import { frogmint, newQueries } from '../frog';
import { IUserRole } from '@/graphql';

export interface BaseUserRecord {
    created?: string;
    lastModified?: string;
    id: number;
    firstName?: string;
    lastName?: string;
    authId: string;
    phone?: string;
    email?: string;
    roles?: IUserRole[];
    projectTypes?: string;
    businessType?: BusinessType;
    companyRole?: CompanyRole;
    serviceType?: ServiceType;
    teamId?: number;
}

export interface BaseUserAuth0LessRecord {
    created?: string;
    lastModified?: string;
    id: number;
    firstName?: string;
    lastName?: string;
    authId: string;
    phone?: string;
    businessType?: BusinessType;
    companyRole?: CompanyRole;
    serviceType?: ServiceType;
    teamId?: number;
}

export interface BaseUserAuth0LessRecords {
    baseUsers: {
        nodes: BaseUserAuth0LessRecord[];
    };
}

export interface BaseUserCondition {
    id?: number;
    teamId?: number;
    authId?: string;
}

export const baseUserFragment = frogmint`
    ${'BaseUser_baseUser'} {
        created
        lastModified
        id
        firstName
        lastName
        authId
        phone
        email
        roles
        teamId
        projectTypes
        businessType
        serviceType
        companyRole
    }
`;

export const baseUserAuth0LessFragment = frogmint`
    ${'BaseUser_baseUserAuth0Less'} {
        created
        lastModified
        id
        firstName
        lastName
        authId
        phone
        teamId
        projectTypes
        businessType
        serviceType
        companyRole
    }
`;

export const BaseUsersAuth0LessQuery = baseUserAuth0LessFragment.gql`
    query BaseUsers($condition: BaseUserCondition) {
        baseUsers(condition: $condition) {
            nodes {
                ${baseUserAuth0LessFragment}
            }
        }
    }
`;

export const [executeBaseUsersAuth0LessQuery, useBaseUsersAuth0LessQuery] = newQueries<
    BaseUserCondition,
    BaseUserAuth0LessRecords
>(BaseUsersAuth0LessQuery);
