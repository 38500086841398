import React, { FC, useEffect, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { useEstimatePriceMarkups } from '../../hooks/useEstimatePriceMarkups';
import { PriceMarkup } from '../../utils/types';
import { MarkupRow } from './MarkupRow';
import {
    MarkupBlankInput,
    MarkupsBody,
    MarkupsBodyHeader,
    MarkupsBodyRow,
    MarkupsContainer,
    MarkupsDisclaimer,
    MarkupsTitle,
} from './styled';

import { isHTMLInputElement } from '@/common/typeGuards';

const COPY = {
    title: 'Markups',
    description: 'Description',
    percentage: 'Percentage',
    total: 'Total',
    empty: '---',
    add: 'Add markup',
    disclaimer:
        'Markups will be added to the total price. Individual markups won’t be visible to clients.',
};

interface MarkupsProps {
    className?: string;
}
export const Markups: FC<MarkupsProps> = ({ className }) => {
    const markupsBodyRef = useRef<HTMLDivElement>(null);
    const [addedBlankRow, setAddedBlankRow] = useState<boolean>(false);
    const { priceMarkups, setPriceMarkups, savePriceMarkup } = useEstimatePriceMarkups();

    // When a new row is added, focus on the newly added row's description input
    useEffect(() => {
        const rows = markupsBodyRef.current?.children;
        if (!addedBlankRow || !rows || rows.length === 0) {
            return;
        }
        const lastRow = rows[rows.length - 1];
        const wrapper = lastRow.children[0];
        const input = wrapper.children[0];
        if (isHTMLInputElement(input)) {
            input.focus();
        }
    }, [addedBlankRow, markupsBodyRef.current?.children]);

    // When focus is lost on the input, remove it if there's nothing inside and reset the new row addition flow.
    const blurNameInput = (newValue: PriceMarkup): void => {
        if (
            newValue.description === '' &&
            priceMarkups[priceMarkups.length - 1].id === newValue.id &&
            addedBlankRow
        ) {
            removeLastRow();
        }
        setAddedBlankRow(false);
    };

    const addNewBlankRow = (): void => {
        setPriceMarkups((oldMarkups) => [
            ...oldMarkups,
            { id: uuid(), description: '', percentage: '', total: '' },
        ]);
        setAddedBlankRow(true);
    };

    const removeLastRow = (): void => {
        setPriceMarkups((oldMarkups) => [...oldMarkups.slice(0, -1)]);
    };

    const updateRow = (index: number, newValue: PriceMarkup): void => {
        const existingMarkup = priceMarkups.find((m) => m.id === newValue.id);

        const noChange = !!(
            existingMarkup &&
            existingMarkup.percentage === newValue.percentage &&
            existingMarkup.description === newValue.description &&
            existingMarkup.total === newValue.total
        );

        setPriceMarkups((oldMarkups) =>
            oldMarkups.map((m, i) => {
                if (i === index) {
                    return newValue;
                } else {
                    return m;
                }
            })
        );
        if (!noChange) {
            savePriceMarkup(newValue);
        }
    };

    return (
        <div className={className}>
            <MarkupsContainer
                isCollapsedByDefault={true}
                nonCollapsible={false}
                contents={
                    <>
                        <MarkupsBody ref={markupsBodyRef}>
                            <MarkupsBodyRow>
                                <MarkupsBodyHeader>{COPY.description}</MarkupsBodyHeader>
                                <MarkupsBodyHeader>{COPY.percentage}</MarkupsBodyHeader>
                                <MarkupsBodyHeader>{COPY.total}</MarkupsBodyHeader>
                            </MarkupsBodyRow>

                            {priceMarkups.map((markup, index) => (
                                <MarkupRow
                                    key={markup.id}
                                    markup={markup}
                                    updateMarkup={(newValue: PriceMarkup): void =>
                                        updateRow(index, newValue)
                                    }
                                    blurNameInput={blurNameInput}
                                />
                            ))}
                            {!addedBlankRow && (
                                <MarkupsBodyRow onClick={addNewBlankRow}>
                                    <MarkupBlankInput isAdd={true}>
                                        +&nbsp;&nbsp;{COPY.add}
                                    </MarkupBlankInput>
                                    <MarkupBlankInput>{COPY.empty}</MarkupBlankInput>
                                    <MarkupBlankInput>{COPY.empty}</MarkupBlankInput>
                                </MarkupsBodyRow>
                            )}
                        </MarkupsBody>
                        <MarkupsDisclaimer>{COPY.disclaimer}</MarkupsDisclaimer>
                    </>
                }
            >
                <MarkupsTitle>{COPY.title}</MarkupsTitle>
            </MarkupsContainer>
        </div>
    );
};
