import React, { useEffect, useState } from 'react';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import {
    BuilderDetailsFormFields,
    builderDetailsEstimateTypesList,
    builderDetailsFormattingPreferencesList,
    WageType,
    DatabaseProjectStatus,
    TeamProjectInfoRecord,
} from '@/common/types';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { wageTypeToReadable } from '@/common/utils/helpers';
import { useUser } from '@/contexts/User';
import { useStorage } from '@/contexts/Storage';

import { BuilderDetailsCheckboxList } from './BuilderDetailsCheckboxList';
import { BuilderDetailsTradeList } from './BuilderDetailsTradeList';
import { BuilderDetailsWageList } from './BuilderDetailsWageList';
import { DetailsFileUpload } from './Form/DetailsFileUpload';
import { WelcomeText } from './WelcomeText';
import { LoadingPage } from '@/views/LoadingPage';
import { isAdministratorUser, isBuilderUser, isPureEstimatorUser } from '@/views/Projects/utils';
import { builderDetailsSchema } from './Form/ValidationSchema';

import { useTradesQuery } from '@/queries/trades';
import {
    IFileDirectory,
    ITeamProjectInfoFragment,
    useLaborRateTypeQuery,
    useTeamProjectInfoAssignMutation,
    useTeamProjectInfoFilesQuery,
} from '@/graphql';

const COPY = {
    projectDetails: 'Project Details',
    averageAndTotalProjectPriceSectionTitle: 'Average Project Price',
    inclusionsExclusionsSectionTitle: 'Inclusions and Exclusions',
    prefferedMarkupsSectionTitle: 'Preferred Markups',
    inclusionsExclusionsPlaceholder: `Please include all wood framing, and install of doors/windows. Please exclude all finish carpentry, cabinetry, and purchase of doors/windows.`,
    specialRequestsAndNotesSectionTitle: 'Special Requests and Notes',
    specialRequestsAndNotesPlaceholder:
        'Let the 1build estimators know any other preferences or requirements not captured above. You can provide additional info about your company here as well.',
    ratesSectionTitle: 'Rates',
    addRateBtnText: 'Add rate',
    saveBtnText: 'Save',
    dollarSign: '$',
    percentageSign: '%',
    exampleEstimates: 'Example Estimates or Custom Templates',
    costCodes: 'Cost Codes and Rate Sheets',
    builderDetailsSuccessMessage: 'Builder details info has been updated',
};

export const BuilderDetails = () => {
    const { project, fetching: loadingProject } = useProjectFromParams();
    const {
        data: { user },
    } = useUser();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const breakpoints = useBreakpoints();
    const { copyBuilderDetailsFile } = useStorage();
    const history = useHistory<{ from: string; submittedProjectId: string }>();

    const redirectedFrom: string = history?.location?.state?.from;
    const redirectedProjectId: string = history.location.state?.submittedProjectId;

    const [fetchedTrades] = useTradesQuery({});
    const { data: ratesTypes } = useLaborRateTypeQuery();

    const tradesList =
        fetchedTrades.fetching || !fetchedTrades.data
            ? []
            : fetchedTrades.data.trades.nodes.filter(
                  (trade) => trade.isSelectableAtProjectCreation
              );

    // If a project has a filled out team_project_info, we can use it.
    const latestTeamProjectInfo = project?.team?.teamProjectInfos?.edges.find(
        (item) => item.node.id === project.projectInfoId
    )?.node;

    // If the project doesn't have a filled out team_project_info and the user is not a member of the builder's team,
    // meaning they are an admin or an estimator, we can still show them the defaults of this team through this.
    const nonBuilderLatestTeamProjectInfo = project?.team?.teamProjectInfos?.edges.find(
        (item) => item.node.id === project?.team?.projectInfoId
    )?.node;

    const form = useForm<BuilderDetailsFormFields>({
        defaultValues: {
            rates: [],
            estimateTypes: [],
            formattingPreferences: [],
            inclusionsExclusions: undefined,
            avgProjectPriceGSF: undefined,
            totalProjectPrice: undefined,
            avgOrTotalPrice: undefined,
            overHead: undefined,
            profit: undefined,
            material: undefined,
            labor: undefined,
            specialRequestsAndNotes: undefined,
            files: [],
            trades: undefined,
            wage: undefined,
        },
        resolver: yupResolver(builderDetailsSchema),
        mode: 'all',
    });

    const { handleSubmit, control, setValue, register, formState, getValues, reset } = form;

    const { fields, append } = useFieldArray({
        control,
        name: 'rates',
    });

    // TODO: get teamProjectInfoID from query
    const { data: fetchedFiles, loading: loadingFiles } = useTeamProjectInfoFilesQuery({
        variables: {
            input: {
                teamProjectInfoID:
                    (latestTeamProjectInfo?.id ?? user?.team?.teamProjectInfo?.id) || '',
            },
        },
    });

    const exampleEstimatesCustomTemplatesFiles = fetchedFiles?.teamProjectInfoFiles.edges
        ?.filter((edge) => edge.node.directory === IFileDirectory.ExampleEstimatesCustomTemplates)
        .map((edge) => ({
            filename: edge.node.filename,
            uuid: edge.node.uuid,
            directory: edge.node.directory,
        }));

    const costCodesAndRateSheetsFiles = fetchedFiles?.teamProjectInfoFiles.edges
        ?.filter((edge) => edge.node.directory === IFileDirectory.CostCodesAndRateSheets)
        .map((edge) => ({
            filename: edge.node.filename,
            uuid: edge.node.uuid,
            directory: edge.node.directory,
        }));

    const fieldsDisabled =
        isBuilderUser(user) && project?.status === DatabaseProjectStatus.CANCELED;

    const renderSaveButton = () =>
        !fieldsDisabled && (
            <Button type="submit" variant="contained" fullWidth disabled={formSubmitting}>
                {COPY.saveBtnText}
            </Button>
        );

    const resetProjectForm = (projectInfo: TeamProjectInfoRecord) => {
        reset({
            rates:
                projectInfo?.hourlyLaborRates?.edges.map((el) => {
                    return {
                        hourlyRateUSDCents: el?.node.priceUsdCents && el.node.priceUsdCents / 100,
                        type: el?.node.type.description,
                    };
                }) ?? [],
            estimateTypes: projectInfo?.estimateTypes ?? [],
            formattingPreferences: projectInfo?.formattingPreferences ?? [],
            inclusionsExclusions: projectInfo?.inclusionsExclusions ?? undefined,
            avgProjectPriceGSF:
                projectInfo?.averagePricePerSquareFoot &&
                projectInfo.averagePricePerSquareFoot / 100,
            totalProjectPrice:
                projectInfo?.averagePriceTotal && projectInfo.averagePriceTotal / 100,
            overHead: projectInfo?.preferredMarkupOverhead ?? undefined,
            profit: projectInfo?.preferredMarkupProfit ?? undefined,
            material: projectInfo?.preferredMarkupMaterial ?? undefined,
            labor: projectInfo?.preferredMarkupLabor ?? undefined,
            specialRequestsAndNotes: projectInfo?.requestsNotes ?? undefined,
            files: [
                ...(exampleEstimatesCustomTemplatesFiles || []),
                ...(costCodesAndRateSheetsFiles || []),
            ],
            trades: project?.projectTrades?.nodes.map((trade) => trade.trade.id),
            wage: project?.wageType,
        });
    };

    const resetBuilderForm = (builderInfo: ITeamProjectInfoFragment) => {
        reset({
            rates:
                builderInfo?.hourlyLaborRates?.map((el) => {
                    return {
                        hourlyRateUSDCents: el?.rateUSDCents && el.rateUSDCents / 100,
                        type: el?.type,
                    };
                }) ?? [],
            estimateTypes: builderInfo?.estimateTypes ?? [],
            formattingPreferences: builderInfo?.formattingPreferences ?? [],
            inclusionsExclusions: builderInfo?.inclusionsExclusions ?? undefined,
            avgProjectPriceGSF:
                builderInfo?.averageProjectPrice?.pricePerSquareFoot &&
                builderInfo.averageProjectPrice.pricePerSquareFoot / 100,
            totalProjectPrice:
                builderInfo?.averageProjectPrice?.priceTotal &&
                builderInfo?.averageProjectPrice?.priceTotal / 100,
            overHead: builderInfo?.preferredMarkups?.overhead ?? undefined,
            profit: builderInfo?.preferredMarkups?.profit ?? undefined,
            material: builderInfo?.preferredMarkups?.material ?? undefined,
            labor: builderInfo?.preferredMarkups?.labor ?? undefined,
            specialRequestsAndNotes: builderInfo?.requestsAndNotes ?? undefined,
            files: [
                ...(exampleEstimatesCustomTemplatesFiles || []),
                ...(costCodesAndRateSheetsFiles || []),
            ],
            trades: project?.projectTrades?.nodes.map((trade) => trade.trade.id),
            wage: project?.wageType,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (formState.isSubmitting) return;

        if (latestTeamProjectInfo) {
            resetProjectForm(latestTeamProjectInfo);
            return;
        }
        if (user?.team?.teamProjectInfo) {
            resetBuilderForm(user.team.teamProjectInfo);
            return;
        }
        if (nonBuilderLatestTeamProjectInfo) {
            resetProjectForm(nonBuilderLatestTeamProjectInfo);
            return;
        }
    }, [project, user, fetchedFiles]);

    const { enqueueSnackbar } = useSnackbar();

    const [teamProjectInfoAssignMutation] = useTeamProjectInfoAssignMutation();

    const onSubmit = async (data: BuilderDetailsFormFields) => {
        setFormSubmitting(true);
        try {
            const shouldUpdateBuilderProfile =
                !!(project || redirectedFrom) &&
                !isAdministratorUser(user) &&
                !isPureEstimatorUser(user);

            const newFiles = data.files.filter(
                (file) =>
                    !fetchedFiles?.teamProjectInfoFiles?.edges?.find(
                        (el) => el.node.uuid === file.uuid
                    )
            );

            const oldFiles = data.files.filter((file) =>
                fetchedFiles?.teamProjectInfoFiles?.edges?.find((el) => el.node.uuid === file.uuid)
            );

            const copyNewFiles =
                shouldUpdateBuilderProfile && newFiles.length > 0
                    ? await Promise.all(
                          newFiles.map(async (file) => {
                              const copyUuid = uuid();
                              const [Error] = await to(
                                  copyBuilderDetailsFile(
                                      file.filename,
                                      file.uuid,
                                      copyUuid,
                                      file.directory,
                                      project ? project.teamId?.toString() : user.teamID || ''
                                  )
                              );

                              if (Error) throw Error;

                              return {
                                  uuid: copyUuid,
                                  filename: file.filename,
                                  directory: file.directory,
                              };
                          })
                      )
                    : [];

            const copyOldFiles =
                shouldUpdateBuilderProfile && oldFiles.length > 0
                    ? await Promise.all(
                          oldFiles.map(async (file) => {
                              const copyUuid = uuid();
                              const [Error] = await to(
                                  copyBuilderDetailsFile(
                                      file.filename,
                                      file.uuid,
                                      copyUuid,
                                      file.directory,
                                      project ? project.teamId?.toString() : user.teamID || ''
                                  )
                              );

                              if (Error) throw Error;

                              return {
                                  uuid: copyUuid,
                                  filename: file.filename,
                                  directory: file.directory,
                              };
                          })
                      )
                    : [];
            let id: string | undefined = undefined;
            // If we're coming straight from project creation or the bug has already affected this project and its project
            // details are directly linked to the global ones of this team.
            if (redirectedFrom || latestTeamProjectInfo?.id === user?.team?.teamProjectInfo?.id) {
                id = undefined;
                // But if we're not redirected and this is coming from a project we already saved the details for, we can do
                // this.
            } else if (!redirectedFrom && latestTeamProjectInfo) {
                id = latestTeamProjectInfo?.id;
                // Otherwise, we're editing the global team profile and we can use this.
            } else if (!redirectedFrom) {
                id = user?.team?.teamProjectInfo?.id;
            }
            const [error] = await to(
                teamProjectInfoAssignMutation({
                    variables: {
                        input: {
                            id,
                            teamID: project ? project.teamId?.toString() : user.teamID,
                            estimateTypes: data.estimateTypes,
                            formattingPreferences: data.formattingPreferences,
                            inclusionsExclusions: data.inclusionsExclusions,
                            averageProjectPrice: {
                                pricePerSquareFoot: data.avgProjectPriceGSF,
                                priceTotal: data.totalProjectPrice,
                            },
                            hourlyLaborRates: data.rates,
                            preferredMarkups: {
                                overhead: data.overHead,
                                profit: data.profit,
                                material: data.material,
                                labor: data.labor,
                            },
                            projectUpdate:
                                redirectedFrom && user.team?.teamProjectInfo
                                    ? {
                                          projectID: redirectedProjectId,
                                      }
                                    : project && {
                                          projectID: String(project.id),
                                          wageType: data.wage,
                                          trades: data.trades,
                                      },
                            requestsAndNotes: data.specialRequestsAndNotes,
                            files: redirectedFrom ? [...copyOldFiles, ...newFiles] : data.files,
                        },
                    },
                    refetchQueries: ['User', 'TeamProjectInfoFiles'],
                })
            );

            // update builder profile
            if (shouldUpdateBuilderProfile) {
                const [profileError] = await to(
                    teamProjectInfoAssignMutation({
                        variables: {
                            input: {
                                id: user?.team?.teamProjectInfo?.id,
                                teamID: user.teamID,
                                estimateTypes: data.estimateTypes,
                                formattingPreferences: data.formattingPreferences,
                                inclusionsExclusions: data.inclusionsExclusions,
                                averageProjectPrice: {
                                    pricePerSquareFoot: data.avgProjectPriceGSF,
                                    priceTotal: data.totalProjectPrice,
                                },
                                hourlyLaborRates: data.rates,
                                preferredMarkups: {
                                    overhead: data.overHead,
                                    profit: data.profit,
                                    material: data.material,
                                    labor: data.labor,
                                },
                                projectUpdate: !user.team?.teamProjectInfo
                                    ? {
                                          projectID: redirectedProjectId,
                                      }
                                    : undefined,
                                requestsAndNotes: data.specialRequestsAndNotes,
                                files: redirectedFrom
                                    ? [...oldFiles, ...copyNewFiles]
                                    : [...copyOldFiles, ...copyNewFiles],
                            },
                        },
                    })
                );

                if (profileError) throw profileError;
            }

            if (error) throw error;

            enqueueSnackbar(COPY.builderDetailsSuccessMessage, {
                variant: 'success',
                autoHideDuration: 5000,
            });

            if (history.location.state) {
                history.push(redirectedFrom);
            } else if (project) {
                history.push(`/projects/${project.uuid}`);
            } else if (!project) {
                history.push('/profile');
            }
        } catch (e) {
            enqueueSnackbar(String(e), {
                variant: 'error',
                autoHideDuration: 5000,
            });
        }
        setFormSubmitting(false);
    };

    const renderRateTypes = () => {
        if (!ratesTypes || !ratesTypes.laborRateType) return;

        return ratesTypes?.laborRateType.map(
            (rateType, idx) =>
                rateType && (
                    <MenuItem value={`${rateType?.description}`} key={idx}>
                        {rateType?.description}
                    </MenuItem>
                )
        );
    };

    if (loadingProject || loadingFiles) {
        return <LoadingPage />;
    }

    return (
        <Container
            maxWidth={breakpoints.mobile || project ? 'lg' : 'md'}
            sx={{
                p: '48px 15px',
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Stack direction="row" justifyContent="space-between" spacing={10}>
                        <WelcomeText
                            user={project ? project?.team?.leadUser : user}
                            isProjectScreen={!!project}
                            isAdminOrEstimator={
                                isAdministratorUser(user) || isPureEstimatorUser(user)
                            }
                            isPrefilledWithDefaults={
                                !latestTeamProjectInfo && !!nonBuilderLatestTeamProjectInfo
                            }
                            isEmptyBuilderProfile={!user?.team?.teamProjectInfo}
                        />
                        {project && <Box>{renderSaveButton()}</Box>}
                    </Stack>
                </Box>

                <Divider sx={{ m: '40px 0' }} />

                <Grid container columnSpacing={4}>
                    <Grid item md={!project ? 12 : 5} sm={12} xs={12}>
                        <Stack direction="row" justifyContent="space-between" width="100%">
                            <BuilderDetailsCheckboxList
                                disabled={fieldsDisabled}
                                control={control}
                                setValue={setValue}
                                label="Estimate Types"
                                options={builderDetailsEstimateTypesList}
                                name="estimateTypes"
                                defaultSelected={getValues('estimateTypes')}
                                error={Boolean(formState.errors.estimateTypes)}
                            />
                            <BuilderDetailsCheckboxList
                                disabled={fieldsDisabled}
                                control={control}
                                setValue={setValue}
                                label="Formatting Preferences"
                                options={builderDetailsFormattingPreferencesList}
                                name="formattingPreferences"
                                defaultSelected={getValues('formattingPreferences')}
                                error={Boolean(formState.errors.formattingPreferences)}
                            />
                        </Stack>

                        <Stack sx={{ m: '45px 0' }}>
                            <Typography sx={{ mb: '20px' }} variant="subtitle1">
                                {COPY.inclusionsExclusionsSectionTitle}
                            </Typography>

                            <TextField
                                {...register('inclusionsExclusions')}
                                multiline
                                minRows={6}
                                disabled={fieldsDisabled}
                                error={Boolean(formState.errors.inclusionsExclusions)}
                                placeholder={
                                    !isPureEstimatorUser(user)
                                        ? COPY.inclusionsExclusionsPlaceholder
                                        : ''
                                }
                            />
                        </Stack>

                        <Divider />

                        <Box sx={{ m: '45px 0' }}>
                            <Typography sx={{ mb: '20px' }} variant="subtitle1">
                                {COPY.averageAndTotalProjectPriceSectionTitle}
                            </Typography>
                            <Grid container spacing={2} alignItems="center" columns={15}>
                                <Grid item xs={15} md={7}>
                                    <TextField
                                        fullWidth
                                        {...register('avgProjectPriceGSF')}
                                        type="number"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.avgOrTotalPrice)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {COPY.dollarSign}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">/GSF</InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={15} md={1} textAlign="center">
                                    or
                                </Grid>

                                <Grid item xs={15} md={7}>
                                    <TextField
                                        fullWidth
                                        {...register('totalProjectPrice')}
                                        type="number"
                                        placeholder="Total Project Value"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.avgOrTotalPrice)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {COPY.dollarSign}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Stack sx={{ m: '45px 0' }}>
                            <Typography sx={{ mb: '20px' }} variant="subtitle1">
                                {COPY.prefferedMarkupsSectionTitle}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={6} md>
                                    <TextField
                                        {...register('overHead')}
                                        fullWidth
                                        type="number"
                                        label="Overhead"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.overHead)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {COPY.percentageSign}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md>
                                    <TextField
                                        {...register('profit')}
                                        fullWidth
                                        type="number"
                                        label="Profit"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.profit)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {COPY.percentageSign}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md>
                                    <TextField
                                        {...register('material')}
                                        fullWidth
                                        type="number"
                                        label="Material"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.material)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {COPY.percentageSign}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md>
                                    <TextField
                                        {...register('labor')}
                                        fullWidth
                                        type="number"
                                        label="Labor"
                                        disabled={fieldsDisabled}
                                        error={Boolean(formState.errors.labor)}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {COPY.percentageSign}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>

                        <Stack sx={{ m: '45px 0' }}>
                            <Typography sx={{ mb: '20px' }} variant="subtitle1">
                                {COPY.ratesSectionTitle}
                            </Typography>
                            {fields.map((item, idx) => {
                                return (
                                    <Grid
                                        container
                                        key={item.id}
                                        columnSpacing={2}
                                        mb="20px"
                                        alignItems="center"
                                    >
                                        <Grid item xs={4}>
                                            <TextField
                                                {...register(`rates.${idx}.hourlyRateUSDCents`)}
                                                type="number"
                                                disabled={fieldsDisabled}
                                                error={Boolean(formState.errors.rates)}
                                                inputProps={{
                                                    step: 'any',
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {COPY.dollarSign}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            /HR
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Controller
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        disabled={fieldsDisabled}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={Boolean(formState.errors.rates)}
                                                        fullWidth
                                                    >
                                                        {renderRateTypes()}
                                                    </Select>
                                                )}
                                                control={control}
                                                name={`rates.${idx}.type`}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            <Button
                                disabled={fieldsDisabled}
                                variant="text"
                                onClick={() => append({ hourlyRateUSDCents: 0, type: '' })}
                            >
                                {COPY.addRateBtnText}
                            </Button>
                        </Stack>

                        <Stack sx={{ m: '45px 0' }} spacing={2}>
                            <DetailsFileUpload
                                directory={IFileDirectory.ExampleEstimatesCustomTemplates}
                                text={COPY.exampleEstimates}
                                disableDownload={!project}
                                viewOnly={fieldsDisabled}
                                isSubmitted={formState.isSubmitted}
                                setValue={setValue}
                                getValues={getValues}
                                files={
                                    getValues('files').filter(
                                        (file) =>
                                            file.directory ===
                                            IFileDirectory.ExampleEstimatesCustomTemplates
                                    ) ?? []
                                }
                                teamID={project ? project.teamId?.toString() : user.teamID || ''}
                            />
                            <DetailsFileUpload
                                directory={IFileDirectory.CostCodesAndRateSheets}
                                text={COPY.costCodes}
                                disableDownload={!project}
                                viewOnly={fieldsDisabled}
                                isSubmitted={formState.isSubmitted}
                                setValue={setValue}
                                getValues={getValues}
                                files={
                                    getValues('files').filter(
                                        (file) =>
                                            file.directory === IFileDirectory.CostCodesAndRateSheets
                                    ) ?? []
                                }
                                teamID={project ? project.teamId?.toString() : user.teamID || ''}
                            />
                        </Stack>

                        <Stack sx={{ m: '45px 0' }}>
                            <Typography sx={{ mb: '20px' }} variant="subtitle1">
                                {COPY.specialRequestsAndNotesSectionTitle}
                            </Typography>
                            <TextField
                                {...register('specialRequestsAndNotes')}
                                multiline
                                minRows={6}
                                disabled={fieldsDisabled}
                                error={Boolean(formState.errors.specialRequestsAndNotes)}
                                placeholder={
                                    !isPureEstimatorUser(user)
                                        ? COPY.specialRequestsAndNotesPlaceholder
                                        : ''
                                }
                            />
                        </Stack>

                        {!project && renderSaveButton()}
                    </Grid>
                    {project && (
                        <Grid item xs={12} sm={12} md={7}>
                            <BuilderDetailsTradeList
                                control={control}
                                setValue={setValue}
                                label="Trades Associated with Project"
                                options={tradesList}
                                defaultSelected={getValues('trades')}
                                disabled={fieldsDisabled}
                            />
                            <BuilderDetailsWageList
                                control={control}
                                setValue={setValue}
                                label="Wage Type Associated with Project"
                                options={Object.values(WageType).map((wt) =>
                                    wageTypeToReadable(wt)
                                )}
                                defaultSelected={wageTypeToReadable(getValues('wage'))}
                                disabled={fieldsDisabled}
                            />
                        </Grid>
                    )}
                </Grid>
            </form>
        </Container>
    );
};
