import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

import { ReactComponent as Pointer } from '@/assets/icons/pointer-currentColor.svg';

const variables = {
    brand: '#1B2235',
    dollar: '#211791',
    inactivePrice: '#9CABCE',
    headerBorder: '#D7E1E5',
};

export const DownPointer = styled(Pointer)`
    grid-area: pointer;
    color: inherit;
`;

/* eslint-disable @typescript-eslint/indent */
interface PointerButtonProps {
    isActive: boolean;
}
export const PointerButton = styled.div<PointerButtonProps>`
    grid-area: pointer;

    height: 23px;
    width: 23px;
    margin: auto;
    padding-bottom: 4px;

    border-radius: 4px;

    display: none;
    justify-content: center;
    align-items: center;

    transition: opacity 0.4s, transform 0.1s cubic-bezier(0.3, 0, 0.45, 1);

    &:active,
    &:focus {
        background: rgba(0, 0, 0, 0.08);
    }

    ${(props): FlattenSimpleInterpolation | string =>
        props.isActive
            ? css`
                  transform: scaleY(-1);
                  padding-bottom: 0;
              `
            : ''}

    ${breakpoints.small`
        display: flex;
    `}
`;
/* eslint-enable @typescript-eslint/indent */

interface PriceProps {
    isActive: boolean;
}
export const Price = styled.span<PriceProps>`
    grid-area: price;
    color: ${(props): string => (!props.isActive ? variables.inactivePrice : 'inherit')};

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;

    display: flex;
    align-items: center;

    ${breakpoints.medium`display: none;`}

    & > svg {
        color: ${variables.dollar};
        margin-right: 7px;
    }
`;

export const Name = styled.h2`
    grid-area: name;
    color: inherit;

    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    margin: 0;

    ${breakpoints.small`
        letter-spacing: -0.0625rem;
        font-size: 16px;
        line-height: 20px;
    `}
`;

export const DueDate = styled.time`
    grid-area: due-date;
    color: inherit;

    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: ${variables.brand};

    ${breakpoints.medium`display: none;`}

    & > svg {
        fill: inherit;
        margin-right: 7px;
        margin-bottom: 2px;
    }
`;

const headerGridTemplate = `
" .     name  .    price  .     due-date  .    " 4rem
/ 23px  auto  1fr  auto   20px  auto      31px `;

const headerGridTemplateMobile = `
" .     pointer  name  .    price  .     due-date  .    " 3.5rem
/ 10px  28px     auto  1fr  auto   20px  auto      31px `;

export const Header = styled.div`
    display: grid;
    grid-template: ${headerGridTemplate};

    color: ${variables.brand};
    border-bottom: 1px solid ${variables.headerBorder};

    ${breakpoints.small`grid-template: ${headerGridTemplateMobile};`}
`;

const containerGridTemplate = `
" header     " 4rem
" chat       " 1fr
" chat-input " auto
/ auto       `;

const mobileContainerGridTemplate = `
" header " 3.5rem
" chat " 1fr
" chat-input " auto
/ auto
`;

export const Container = styled.div`
    display: grid;
    grid-template: ${containerGridTemplate};
    height: 100%;
    overflow-y: hidden;

    position: relative;

    z-index: 2;

    background: linear-gradient(180deg, #ffffff 0%, #f4f6fa 100%);

    ${breakpoints.small`
        height: 100%;
        border-radius: 0;

        grid-template: ${mobileContainerGridTemplate};
    `}
`;
