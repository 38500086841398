import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Tab, TabProps } from './Tab';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 0.875rem;
    font-weight: 700;
    font-size: 0.875rem;
    user-select: none;
    overflow-x: auto;
    min-height: 50px;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
`;

interface TabsProps {
    activeKey: string;
    onSelect?: (tab: string) => void;
    children: React.ReactElement<TabProps>[];
}

export const Tabs: FC<TabsProps> = ({ children, activeKey, onSelect }) => {
    const [activeTab, setActiveTab] = useState(activeKey);

    useEffect(() => {
        setActiveTab(activeKey);
    }, [activeKey]);

    const handleSelect = (tab: string): void => {
        setActiveTab(tab);

        if (onSelect) {
            onSelect(tab);
        }
    };

    const activeTabContent = children.find((child: React.ReactElement<TabProps>) => {
        const eventKey = child.props?.eventKey;
        const tabChildren = child.props?.children;

        return activeTab === eventKey && tabChildren;
    });

    return (
        <>
            <Container>
                {children.map((child: React.ReactElement<TabProps>): React.ReactElement => {
                    const { eventKey, title } = child.props;
                    return (
                        <Section key={eventKey}>
                            <Tab
                                onClick={handleSelect}
                                active={activeTab === eventKey}
                                eventKey={eventKey}
                                title={title}
                            />
                        </Section>
                    );
                })}
            </Container>
            <>{activeTabContent?.props.children}</>
        </>
    );
};
