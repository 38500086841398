import React, { FC } from 'react';

import styled from 'styled-components';

import { useToggle } from '@/common/hooks/useToggle';
import { IconButton } from '@/components/ui/buttons/IconButton';
import { colorTypographyDark } from '@/variables';

import { ReactComponent as Checkmark } from '@/assets/icons/Check.svg';
import { ReactComponent as Edit } from '@/assets/icons/pen.svg';

const Container = styled.div`
    display: flex;
    position: relative;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    right: -5px;
    top: -12px;
    position: absolute;
`;

const EditButton = styled(IconButton)`
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    svg {
        width: 13px;
        height: 13px;
    }
`;

const ConfirmButton = styled(IconButton)`
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    margin-left: 3px;

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: ${colorTypographyDark} !important;
        }
    }

    &:hover {
        background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);

        svg {
            path {
                fill: #ffffff !important;
            }
        }
    }
`;

interface PriceEditProps {
    onEdit: () => void;
    onConfirm: () => void;
    showConfirm: boolean;
}

export const PriceEdit: FC<PriceEditProps> = ({ children, onEdit, onConfirm, showConfirm }) => {
    const [show, toggleShow] = useToggle();

    return (
        <Container onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
            {children}
            {show && (
                <Buttons>
                    <EditButton Icon={Edit} onClick={onEdit} />
                    {showConfirm && <ConfirmButton Icon={Checkmark} onClick={onConfirm} />}
                </Buttons>
            )}
        </Container>
    );
};
