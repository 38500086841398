import styled from 'styled-components';

import { ButtonVariant } from './Button';

import {
    colorAdminDashboardModalButtonText,
    colorAdminDashboardModalText,
    colorBackgroundSecondaryLightest,
    colorOverlayLight,
    colorTertiaryLighter,
    colorTypographyDark,
} from '@/variables';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colorOverlayLight};
    z-index: 100;
`;

export const ModalHeader = styled.h2`
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.03em;
    color: ${colorTypographyDark};
`;

export const ModalBodyText = styled.p`
    margin: 1rem 0 3rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: ${colorAdminDashboardModalText};
`;

export const ModalButtonText = styled.p`
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.04em;
`;

export const ModalButton = styled.div<{ variant: ButtonVariant }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 2.5rem;
    cursor: pointer;
    background-color: ${(props): string =>
        props.variant === ButtonVariant.Cancel ? colorTertiaryLighter : 'transparent'};
    transition: border ease 0.3s;

    ${ModalButtonText} {
        color: ${(props): string =>
            props.variant === ButtonVariant.Action
                ? 'inherit'
                : colorAdminDashboardModalButtonText};
    }
`;

export const ModalColorStripe = styled.div`
    height: 1rem;
`;

export const ModalContent = styled.div`
    padding: 2.875rem 4.375rem 4.5rem 5.875rem;
    background: ${colorBackgroundSecondaryLightest};

    .spinner-loader {
        padding-right: 2.125rem;
        display: flex;
        justify-content: center;
    }
`;

export const ModalCard = styled.div<{ accentColor: string }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 33.875rem;
    border-radius: 1rem;
    overflow: hidden;
    font-family: 'proxima-nova', sans-serif;

    ${ModalColorStripe} {
        background-color: ${(props): string => props.accentColor};
    }

    ${ModalButton} {
        color: ${(props): string => props.accentColor};

        &:hover {
            border: 1px solid ${(props): string => props.accentColor};
        }
    }
`;

export const ModalButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${ModalButton}:not(:first-child) {
        margin-left: 0.5rem;
    }
`;
