import React, { FC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from '@/components/app/ProtectedRoute';
import { FeaturesProps } from '@/contexts/Features';
import { Login } from '@/views/Login';
import { SharedEstimate } from '@/views/SharedEstimate';
import { Signup } from '@/views/Signup';
import { Unsubscribe } from '@/views/Unsubscribe';

type PublicRouterProps = {
    features: FeaturesProps['features'];
};

export const PublicRouter: FC<PublicRouterProps> = ({ features }) => {
    const { shareV2 } = features;
    return (
        <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/signup/:role">
                <Signup />
            </Route>
            <ProtectedRoute
                exact
                path="/projects/:puuid/public/:uuid"
                allowAnonymous={true}
                disabled={!shareV2}
            >
                <SharedEstimate />
            </ProtectedRoute>
            <ProtectedRoute
                exact
                allowAnonymous={true}
                path="/projects/:projectUUID/unsubscribe/:hash"
            >
                <Unsubscribe />
            </ProtectedRoute>
            <Redirect from="*" to="/" />
        </Switch>
    );
};
