import { isEnumMember } from '@/common/typeGuards';
import {
    Auth0IdentityProvider,
    BuilderDashboardProjectStatus,
    DatabaseProjectStatus,
    DetailsProjectStatus,
    EstimatorDashboardProjectStatus,
    IDownloadBase64Props,
    KeyAction,
    LineType,
    Nil,
    PricingStatus,
    Setter,
    WageType,
} from '@/common/types';
import { IUserRole } from '@/graphql';

/* canvas colors */
import {
    colorDrawingTool1,
    colorDrawingTool2,
    colorDrawingTool3,
    colorDrawingTool4,
    colorDrawingTool5,
    colorDrawingTool6,
    colorDrawingTool7,
    colorDrawingTool8,
    colorDrawingTool9,
    colorDrawingTool10,
    colorDrawingTool11,
    colorDrawingTool12,
    colorDrawingTool13,
    colorDrawingTool14,
    colorDrawingTool15,
    colorDrawingTool16,
    colorDrawingTool17,
    colorDrawingTool18,
    colorDrawingTool19,
    colorDrawingTool20,
} from '@/variables';
import { QueryResult } from '@apollo/client';
import moment, { Moment } from 'moment';
import React, { MutableRefObject, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { LDService } from '@/contexts/LaunchDarkly';

const COPY = {
    estimateFileName: 'Estimate.zip',
    filesFileName: 'Files.zip',
};

export const useNilState = <T>(arg?: Nil<T>): [Nil<T>, Setter<Nil<T>>] =>
    useState<T | null | undefined>(arg);

export const camelToDashCase = (camel: string): string =>
    camel.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());

export const withoutPropagation =
    (fn: () => void) =>
    (event: Event | MouseEvent | React.MouseEvent): void => {
        event.stopPropagation();
        fn();
    };

export const trimLeft =
    (fn: (value: string) => void) =>
    (value: string): void => {
        const trimmedValue = value.trimLeft();
        fn(trimmedValue);
    };

export const trim =
    (fn: (value: string) => void) =>
    (value: string): void => {
        const trimmedValue = value.trim();
        fn(trimmedValue);
    };

export const formatDate = (date: Date | Moment | string): string =>
    moment(date).format('MMM DD, YYYY');

/**
 * This function returns the correct label for the project, depending on the
 * place where we want to display it and on the user type.
 * There is a 'project details' view, and the 'Kanban view', for which we have
 * two actual ways of displaying it: one for builder, and one for estimator.
 * This is the table for Project Statuses which this function emulates:
 *
 *
 * name         Project                     Builder         	  Estimator
 *              Details                     Dashboard             Dashboard
 *
 * draft        In Review                   In Progress (DIY)     Estimating
 * new	        In Review	                Not Started	          N/A
 * sized	    In Review	                Not Started	          N/A
 * approved	    In Review	                Estimating	          N/A
 * declined	    In Review	                Not Selected	      N/A
 * estimating   Estimating	                Estimating	          Estimating
 * estimated	In Review	                Estimating	          Estimating
 * complete	    Complete	                Complete	          Complete
 */
export const projectStatusToLabel = <
    T extends 'details' | 'dashboard',
    U extends 'BUILDER' | 'ESTIMATOR'
>(
    status: DatabaseProjectStatus,
    view: T,
    roles?: IUserRole[],
    isSaaS?: boolean
): T extends 'details'
    ? DetailsProjectStatus
    : U extends 'BUILDER'
    ? BuilderDashboardProjectStatus
    : EstimatorDashboardProjectStatus => {
    const role =
        roles && roles.includes(IUserRole.Builder) ? IUserRole.Builder : IUserRole.Estimator;

    if (view === 'details') {
        // 🚨 🚨 🚨
        // For builder-estimator, provide an early exit if this project is a draft
        // 🚨 🚨 🚨
        if (
            role === IUserRole.Builder &&
            roles?.includes(IUserRole.Estimator) &&
            status === DatabaseProjectStatus.DRAFT &&
            isSaaS
        ) {
            return DetailsProjectStatus.IN_PROGRESS as T extends 'details'
                ? DetailsProjectStatus
                : U extends 'BUILDER'
                ? BuilderDashboardProjectStatus
                : EstimatorDashboardProjectStatus;
        }

        switch (role) {
            case IUserRole.Builder:
                switch (status) {
                    case DatabaseProjectStatus.DRAFT:
                        return DetailsProjectStatus.DRAFT as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.NEW:
                    case DatabaseProjectStatus.SIZED:
                    case DatabaseProjectStatus.APPROVED:
                    case DatabaseProjectStatus.DECLINED:
                    case DatabaseProjectStatus.REVISION_REQUESTED:
                    case DatabaseProjectStatus.REVISION_SUBMITTED:
                    case DatabaseProjectStatus.ESTIMATED:
                    case DatabaseProjectStatus.PENDING_ESTIMATOR:
                        return DetailsProjectStatus.NOT_STARTED as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.ESTIMATING:
                        return DetailsProjectStatus.IN_PROGRESS as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                }
                break;
            case IUserRole.Estimator:
                switch (status) {
                    case DatabaseProjectStatus.DRAFT:
                    case DatabaseProjectStatus.NEW:
                    case DatabaseProjectStatus.SIZED:
                    case DatabaseProjectStatus.APPROVED:
                    case DatabaseProjectStatus.DECLINED:
                    case DatabaseProjectStatus.PENDING_ESTIMATOR:
                    case DatabaseProjectStatus.REVISION_REQUESTED:
                    case DatabaseProjectStatus.REVISION_SUBMITTED:
                    case DatabaseProjectStatus.ESTIMATED:
                        return DetailsProjectStatus.NOT_STARTED as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.ESTIMATING:
                        return DetailsProjectStatus.IN_PROGRESS as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                }
        }
    } else if (view === 'dashboard') {
        switch (role) {
            case IUserRole.Builder:
                switch (status) {
                    case DatabaseProjectStatus.NEW:
                    case DatabaseProjectStatus.REVISION_REQUESTED:
                    case DatabaseProjectStatus.REVISION_SUBMITTED:
                    case DatabaseProjectStatus.PENDING_ESTIMATOR:
                    case DatabaseProjectStatus.SIZED:
                        return BuilderDashboardProjectStatus.NOT_STARTED as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.DECLINED:
                        return BuilderDashboardProjectStatus.NOT_SELECTED as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.DRAFT:
                    case DatabaseProjectStatus.APPROVED:
                    case DatabaseProjectStatus.ESTIMATING:
                    case DatabaseProjectStatus.ESTIMATED:
                        return BuilderDashboardProjectStatus.IN_PROGRESS as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                }
                break;
            case IUserRole.Estimator:
                switch (status) {
                    case DatabaseProjectStatus.DRAFT:
                    case DatabaseProjectStatus.NEW:
                    case DatabaseProjectStatus.REVISION_REQUESTED:
                    case DatabaseProjectStatus.REVISION_SUBMITTED:
                    case DatabaseProjectStatus.SIZED:
                    case DatabaseProjectStatus.APPROVED:
                    case DatabaseProjectStatus.DECLINED:
                        return EstimatorDashboardProjectStatus.N_A as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.PENDING_ESTIMATOR:
                        return EstimatorDashboardProjectStatus.PENDING_ACCEPTANCE as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                    case DatabaseProjectStatus.ESTIMATING:
                    case DatabaseProjectStatus.ESTIMATED:
                        return EstimatorDashboardProjectStatus.ESTIMATING as T extends 'details'
                            ? DetailsProjectStatus
                            : U extends 'BUILDER'
                            ? BuilderDashboardProjectStatus
                            : EstimatorDashboardProjectStatus;
                }
        }
    }
    return DetailsProjectStatus.COMPLETED as T extends 'details'
        ? DetailsProjectStatus
        : U extends 'BUILDER'
        ? BuilderDashboardProjectStatus
        : EstimatorDashboardProjectStatus;
};

export const getTradeColor = (name: string): string => {
    // We normalize the trade name by taking just the part before the first
    // non-letter character, replacing spaces with dashes and keeping it
    // lowercase
    return name
        .split(/[^A-Za-z\s]/)[0]
        .trim()
        .replace(/\s/g, '-')
        .toLowerCase();
};

export const determineAWSSubdirectory = (
    filetype?: string
): 'plans' | 'estimates' | 'uploads' | undefined => {
    switch (filetype) {
        case 'ProjectPlanFile': {
            return 'plans';
        }
        case 'ProjectEstimateFile': {
            return 'estimates';
        }
        case 'ProjectUploadFile': {
            return 'uploads';
        }
    }
};

export const getFileExtension = (name: string): string | undefined => {
    let ext = null;
    const sourcePartRegex = /\.[^.]*$/;
    let match: RegExpExecArray | null = null;
    match = sourcePartRegex.exec(name);
    if (match) {
        const part = match.pop();
        ext = part?.replace('.', '');
    } else {
        ext = 'default';
    }
    return ext;
};

export const createZipFileName = (estimateSection: boolean, projectName: string): string => {
    // remove all characters that aren't letters or digits from project name
    const project = projectName.replace('/[^a-zA-Z0-9]', '');
    const type = estimateSection ? COPY.estimateFileName : COPY.filesFileName;
    return project + ' ' + type;
};

const vendor = window.navigator.vendor;
// test for Apple-sourced webkit implementation (all ios browsers, safari, & epiphany)
export const vendorIsApple = vendor.includes('Apple');

// Method used to calculate shapeWeight of custom patterns based
// on line width. It is used to describe width of the ladder etc.
export const getShapeWeight = (lineWidth: number, lineType: LineType): number => {
    switch (lineType) {
        case LineType.ARROW:
            return 7 * lineWidth;
        case LineType.LADDER:
            return lineWidth;
        case LineType.ZIGZAG:
            return lineWidth;
        case LineType.DOTTED:
            return 5 * lineWidth;
        default:
            return lineWidth;
    }
};

export const geometryColors = [
    colorDrawingTool1,
    colorDrawingTool2,
    colorDrawingTool3,
    colorDrawingTool4,
    colorDrawingTool5,
    colorDrawingTool6,
    colorDrawingTool7,
    colorDrawingTool8,
    colorDrawingTool9,
    colorDrawingTool10,
    colorDrawingTool11,
    colorDrawingTool12,
    colorDrawingTool13,
    colorDrawingTool14,
    colorDrawingTool15,
    colorDrawingTool16,
    colorDrawingTool17,
    colorDrawingTool18,
    colorDrawingTool19,
    colorDrawingTool20,
];

/* Flatten key map to a structure with { mapping, trigger, scope } */
export const makeKeyMap = (
    obj: Record<string, Record<string, unknown>>
): { [key: string]: KeyAction } =>
    Object.entries(obj).reduce(
        (map, [scope, mappings]) => ({
            ...map,
            ...Object.entries(mappings).reduce(
                (actions, [action, mapping]) => ({
                    ...actions,
                    [action]: {
                        ...(typeof mapping === 'string'
                            ? { mapping, trigger: 'keydown' }
                            : (mapping as Record<string, unknown>)),
                        scope,
                    },
                }),
                {}
            ),
        }),
        {}
    );

export const toUnique = <T>(arr: T[]): T[] => {
    return [...new Set<T>(arr)];
};

export const notNull = <T>(val: T | null): val is T => val !== null;

export const isNull = <T>(val: T | null): val is null => val === null;

export const notUndefined = <T>(val: T | undefined): val is T => val !== undefined;

export const isUndefined = <T>(val?: T): val is undefined => val === undefined;

export const notNullOrUndefined = <T>(val: T | undefined | null): val is T =>
    !isNull(val) && !isUndefined(val);

// Determine if user input is numeric (numbers, '.', ',')
export const isNaiveNumericInput = (input: string): boolean => /^(\d?)+\.?\d*$/.test(input);

// Determine if user input is numeric (numbers, '.', ',')
export const isNumericInput = (input: string): boolean => /^\d*(\.\d*)?$/.test(input);

// Determine if user input is numeric and whole
export const isWholeNumericInput = (input: string): boolean => /^(\d)*$/.test(input);

// Determine if a mouse event occured within a specific ref.
export const eventWithinRef = (e: MouseEvent, ref: MutableRefObject<null | Node>): boolean => {
    if (!(e.target instanceof Node) || !ref.current) {
        return false;
    }
    return ref.current.contains(e.target);
};

// toEnumMember transforms a possible enum member into a true member of that enum.
// If `member` is not a unique entry in `parent`, null is returned.
export const toEnumMember = <T extends string | number | symbol, K extends { [s: string]: T }>(
    member: T,
    parent: K
): K[keyof K] | null => (isEnumMember(member, parent) ? member : null);

// getIdentityProvider extracts an identity provider from an auth0 `user_id`.
// If the provider is not recognized, null is returned.
export const getIdentityProvider = (authId: string): Auth0IdentityProvider | null =>
    toEnumMember(authId.split('|')[0], Auth0IdentityProvider);

// formatIdentityProvider maps identity providers to formatted provider names.
export const formatIdentityProvider = (provider: Auth0IdentityProvider): string => {
    switch (provider) {
        case Auth0IdentityProvider.Google:
            return 'Google';
        case Auth0IdentityProvider.UsernamePassword:
            return 'Username/Password';
    }
};

// getFormattedIdentityProvider returns the formatted name of the provider of a given auth0 `user_id`.
// If there is an error detecting the provider, 'Unknown' is returned instead.
export const getFormattedIdentityProvider = (authId: string): string => {
    const provider = getIdentityProvider(authId);
    if (provider === null) {
        return 'Unknown';
    }
    return formatIdentityProvider(provider);
};

// If the updated record appears in the array, update it. If not, prepend the record to the array.
export const updateRecordArr = <T extends { id: number | string }>(
    recordArr: T[],
    updatedRecord: T
): T[] => {
    const updatedRecordIdx = recordArr.findIndex((record) => record.id === updatedRecord.id);
    if (updatedRecordIdx !== -1) {
        return [
            ...recordArr.slice(0, updatedRecordIdx),
            { ...updatedRecord },
            ...recordArr.slice(updatedRecordIdx + 1),
        ];
    }
    return [updatedRecord, ...recordArr];
};

// If the updated records appears in the array, update them. If not, prepend the record to the array.
export const updateRecordArrMulti = <T extends { id: number | string }>(
    recordArr: T[],
    updatedRecords: T[]
): T[] => {
    let newRecordArr = [...recordArr];
    for (let i = 0; i < updatedRecords.length; i++) {
        newRecordArr = updateRecordArr(newRecordArr, updatedRecords[i]);
    }
    return newRecordArr;
};

export interface SortableAssemblyElement {
    uuid?: string | null;
    previousElement?: string | null;
    assembly?: { childOrder?: string[] } | null;
}
export const sortAssemblyElements = <T extends SortableAssemblyElement>(elements: T[]): T[] => {
    const flag = LDService.variation('GroupAndItemOrderingV2', false) as boolean;
    let sortedElements: T[] = [];
    if (flag) {
        // v2 ordering using assembly.childOrder
        const order = elements[0]?.assembly?.childOrder;
        if (!order?.length) {
            return elements;
        }
        sortedElements = [...elements];
        sortedElements.sort((a, b) => order.indexOf(a?.uuid || '') - order.indexOf(b?.uuid || ''));
    } else {
        const firstElement = elements.find((e) => !e.previousElement);
        if (!firstElement) return elements;
        sortedElements.push(firstElement);
        for (let i = 0; i < elements.length - 1; i++) {
            const nextElement = elements.find((e) => e.previousElement === sortedElements[i].uuid);
            if (!nextElement) return elements;
            sortedElements.push(nextElement);
        }
    }
    return sortedElements;
};

export const wageTypeToReadable = (wageType: WageType | undefined): string => {
    switch (wageType) {
        case WageType.NON_UNION:
            return 'Non-union';
        case WageType.UNION:
            return 'Union';
        case WageType.PREVAILING_WAGE:
            return 'Prevailing wage';
        case WageType.NOT_SURE:
            return 'Not sure';
        default:
            return '';
    }
};

export const readableWageTypeToWageType = (wageType: string): WageType | undefined => {
    switch (wageType) {
        case 'Non-union':
            return WageType.NON_UNION;
        case 'Union':
            return WageType.UNION;
        case 'Prevailing wage':
            return WageType.PREVAILING_WAGE;
        case 'Not sure':
            return WageType.NOT_SURE;
    }
};

export const formatNumber = (num: number): string => {
    return num.toLocaleString('en-US');
};

export const formatUSD = (num: number, cents = true): string => {
    return (num / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        ...((num % 100 === 0 || !cents) && { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
    });
};

export const formatPhoneNumber = (phone: string): string => {
    const usFormatRegex = /^.?(\d{3}).?(\d{3}).?(\d{4}).?/;
    const match = usFormatRegex.exec(phone);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phone;
};

export const formatDollarsToCents = (value: string): number => {
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
        value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
};

export const camelToSnakeCase = (input: string): string =>
    input.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`);

export const mapPricingStatus = (status: DatabaseProjectStatus | undefined): PricingStatus => {
    const isProjectStatus = (match: DatabaseProjectStatus): boolean => status === match;

    if (isProjectStatus(DatabaseProjectStatus.NEW)) return PricingStatus.NEW;
    if (isProjectStatus(DatabaseProjectStatus.PENDING_ESTIMATOR))
        return PricingStatus.PENDING_ESTIMATOR;

    return PricingStatus.FINAL;
};

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
export const camelObjectToSnakeCaseObject = (input: AnyPayload): AnyPayload =>
    Object.keys(input)
        .map((key): [string, any] => [camelToSnakeCase(key), input[key]])
        .reduce((acc: AnyPayload, curr: [string, any]) => ({ ...acc, [curr[0]]: curr[1] }), {});
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

// Read file as URL
export const readFile = (file: File): Promise<ArrayBuffer | string | null> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
};

export const createImageElement = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.crossOrigin = 'Anonymous';
        image.src = url;
    });

export const getBase64Image = async (
    imageSource: string,
    pixelCrop?: Area,
    isFullImage = false,
    outputFormat = 'image/jpeg'
) => {
    const image = await createImageElement(imageSource);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx) {
        canvas.width = 400;
        canvas.height = 140;

        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fill();

        if (!isFullImage && pixelCrop) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                canvas.width,
                canvas.height
            );
        } else {
            const hRatio = canvas.width / image.width;
            const vRatio = canvas.height / image.height;

            const ratio = Math.min(hRatio, vRatio);

            const centerShiftX = (canvas.width - image.width * ratio) / 2;
            const centerShifty = (canvas.height - image.height * ratio) / 2;

            ctx.drawImage(
                image,
                0,
                0,
                image.width,
                image.height,
                centerShiftX,
                centerShifty,
                image.width * ratio,
                image.height * ratio
            );
        }
    }

    // As a blob
    // return new Promise((resolve) => {
    //     canvas.toBlob((file) => {
    //         console.log(file);
    //         resolve(URL.createObjectURL(file));
    //     }, 'image/png');
    // });

    // As Base64 string
    return canvas.toDataURL(outputFormat);
};
/* eslint-enable*/

export const downloadBase64File = (props: IDownloadBase64Props) => {
    const linkSource = `data:${props.contentType};base64,${props.base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = props.fileName;
    downloadLink.click();
};

interface Edge<Record> {
    cursor: string;
    node: Record;
}

interface Data<T extends unknown> {
    [x: string]: {
        edges: Edge<T>[];
    };
}

export const getNodes = <T extends unknown, U>(query: QueryResult<T>, property: string): U[] => {
    const data = query.data as Data<U>;
    return getNodesFromEdges<U>(data?.[property]?.edges) ?? [];
};

export const getNodesFromEdges = <T extends unknown>(edges: Edge<T>[] | undefined): T[] => {
    return edges?.map((e) => e.node) ?? [];
};
