import { DaysLeft } from './styled';
import { scheduleCall } from '@/common/sales';
import { UpgradeButton } from '@/components/ui/buttons/UpgradeButton';
import { useUser } from '@/contexts/User';
import { fontWeight } from '@/theme/fonts/weight';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import React, { FC } from 'react';

const getDaysLeftInTrialCopy = (daysLeft: number) => {
    switch (daysLeft) {
        case 1:
            return '1 day left in trial';
        case 0:
            return 'No days left in trial';
        default:
            return `${daysLeft} days left in trial`;
    }
};

interface TrialActionButtonsProps extends StackProps {
    hasUpgradeButton?: boolean;
}

export const TrialActionButtons: FC<TrialActionButtonsProps> = ({ hasUpgradeButton, ...props }) => {
    const user = useUser();

    const handleScheduleDemoButtonClick = () => {
        scheduleCall();
    };

    return (
        <Stack alignItems="center" direction="row" spacing={1} {...props}>
            <DaysLeft fontWeight={fontWeight.bold} variant="body2">
                {getDaysLeftInTrialCopy(user.data.user.team?.onboarding?.daysLeftInTrial ?? 0)}
            </DaysLeft>
            {hasUpgradeButton && <UpgradeButton size="small" color="accent" />}
            <Button
                size="small"
                onClick={handleScheduleDemoButtonClick}
                color="secondary"
                variant="outlined"
            >
                Talk to sales
            </Button>
        </Stack>
    );
};
