import Container from '@mui/material/Container';
import ScopedCssBaseLine from '@mui/material/ScopedCssBaseline';
import React, { FC } from 'react';
import { plans } from '@/components/pricing/PricingPage/plans';
import { useUser } from '@/contexts/User';
import { useTeamBillingQuery } from '@/graphql';
import { Mode } from '@/theme/Mode';
import { LoadingPage } from '@/views/LoadingPage';
import { ActionsCard } from './ActionsCard';
import { EstimatesCard } from './EstimatesCard';
import { PlanCard } from './PlanCard';
import { Main, MainHeading, CardGroup } from './styled';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const BillingPage: FC<Props> = (props) => {
    const user = useUser();

    const teamBillingResult = useTeamBillingQuery({
        variables: {
            input: {
                id: String(user.data.user.team?.id),
            },
        },
    });

    if (teamBillingResult.loading) {
        return <LoadingPage />;
    }

    return (
        <ScopedCssBaseLine>
            <Main {...props}>
                <Mode variant="light">
                    <Container>
                        <MainHeading variant="h1">Billing</MainHeading>
                        <CardGroup>
                            <PlanCard plans={plans} team={teamBillingResult.data?.team} />
                            <ActionsCard plans={plans} team={teamBillingResult.data?.team} />
                            <EstimatesCard plans={plans} team={teamBillingResult.data?.team} />
                        </CardGroup>
                    </Container>
                </Mode>
            </Main>
        </ScopedCssBaseLine>
    );
};
