/* Moment.js custom helper functions */
import { Nil } from '../types';
import moment, { Moment } from 'moment-business-days';

/* Updates moment.js locale with holidays ('us' by default) */
import 'moment-holiday';
import momentTZ from 'moment-timezone';

// We filter out all the holidays defined by moment-holiday that aren't Federal Holidays.
moment.modifyHolidays.remove([
    'Martin Luther King Jr. Day',
    "Valentine's Day",
    "Washington's Birthday",
    "Saint Patrick's Day",
    'Good Friday',
    'Easter Sunday',
    "Mother's Day",
    "Father's Day",
    'Columbus Day',
    'Halloween',
    "Veteran's Day",
]);

moment.modifyHolidays.add({
    'End-of-year holiday #1': {
        date: '12/24',
    },
    'End-of-year holiday #2': {
        date: '12/25',
    },
    'End-of-year holiday #3': {
        date: '12/31',
    },
    'End-of-year holiday #4': {
        date: '1/1',
    },
    'National holiday #1': {
        date: '7/5',
    },
});

export const getNextMonth = (date: Moment): Moment => date.clone().add(1, 'months');

export const getPrevMonth = (date: Moment): Moment => date.clone().subtract(1, 'months');

export const getDaysForMonth = (date: Moment): Moment[] =>
    Array(date.daysInMonth())
        .fill(date.startOf('month'))
        .map((_, index) => date.clone().add(index, 'days'));

export const isBeforeDate = (date: Moment, otherDate: Moment): boolean =>
    date.startOf('day').isBefore(otherDate.startOf('day'));

export const isToday = (date: Moment): boolean =>
    date.startOf('day').isSame(moment().startOf('day'));

export const isBusinessDay = (date: Moment): boolean => date.startOf('day').isBusinessDay();

export const areEqual = (date: Moment, otherDate: Moment): boolean =>
    date.startOf('day').isSame(otherDate.startOf('day'));

export const getWeekdays = (): string[] =>
    Array<Moment>(7)
        .fill(moment().startOf('week'))
        .map((day, index) => day.clone().add(index, 'days').format('dd'));

export const getMonthDayOffset = (date: Moment): number[] =>
    Array(date.startOf('month').weekday())
        .fill(0)
        .map((_, index) => index);

export const toDateString = (date: Nil<Date>): string =>
    (moment(date) || moment()).format('YYYY-MM-DD');

export const momentUtc = (date: string | Moment | Date = moment()): Moment =>
    moment(date).clone().startOf('day').utc(true);

export const v2ToDateString = (date: Nil<Date>): string =>
    (moment(date) || moment()).format('MM-DD-YY');

export const timezoneNames: string[] = momentTZ.tz.names();

export const currentTimezoneIndex: number = timezoneNames.findIndex(
    (item) => item === momentTZ.tz.guess()
);

export const getBusinessDaysRemaining = (
    startDate: string | Moment | Date = moment(),
    endDate: string | Moment | Date = moment()
): number => {
    let calcBusinessDays =
        1 +
        (moment(endDate).diff(startDate, 'days') * 5 -
            (moment(startDate).day() - moment(endDate).day()) * 2) /
            7;

    if (moment(endDate).day() === 6) calcBusinessDays--;
    if (moment(startDate).day() === 0) calcBusinessDays--;

    return calcBusinessDays;
};
