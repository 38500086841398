import React, { FC, useMemo } from 'react';

import { genPlusPolygon } from '@/common/css/polygon';
import { SpanProps } from '@/common/types';

export interface AddIconProps extends SpanProps {
    color: string;
    sideLengthPx: number;
    strokeWidthPx: number;
}

export const AddIcon: FC<AddIconProps> = ({
    color,
    sideLengthPx,
    strokeWidthPx,
    style,
    ...rest
}) => {
    const clipPath = useMemo(
        () => genPlusPolygon(sideLengthPx, strokeWidthPx),
        [sideLengthPx, strokeWidthPx]
    );

    return (
        <span
            style={{
                backgroundColor: color,
                clipPath,
                height: `${sideLengthPx}px`,
                width: `${sideLengthPx}px`,
                ...style,
            }}
            {...rest}
        />
    );
};
