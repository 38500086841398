import bolden from '@/assets/svgs/customers/bolden.svg';
import crux from '@/assets/svgs/customers/crux.svg';
import hhh from '@/assets/svgs/customers/hidden-hill-hospitality.svg';
import retiz from '@/assets/svgs/customers/retiz-electric.svg';
import rock from '@/assets/svgs/customers/rock-contracting.svg';
import ruff from '@/assets/svgs/customers/ruff-framing.svg';
import so2 from '@/assets/svgs/customers/so2.svg';
import sod from '@/assets/svgs/customers/sod-builders.svg';
import tinyhome from '@/assets/svgs/customers/tinyhome.svg';

export const customers = [sod, rock, retiz, hhh, ruff, tinyhome, bolden, so2, crux];
