/**
 * urql.tsx provides an authenticated urql context. Since the provider wraps the
 * entire application, this context can be used anywhere by importing hooks from
 * the urql library.
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Provider } from 'urql';

import { Client } from '../common/urql/client';
import { Subscription } from '../common/urql/subscription';
import { Env } from '@/common/env';

export const UrqlProvider: FC = ({ children }) => {
    const [, updateState] = useState<Record<never, never>>();

    const forceRerender = useCallback(() => updateState({}), []);

    // When the subscription client reconnects, force a re-render. This will
    // cause downstream release version checks between the bundle and
    // Postgraphile to re-run; if the disconnection occured because of an
    // update to Postgraphile, this re-render will result in a modal prompting
    // the user to update the app (refresh the page). See link below.
    // https://stackoverflow.com/questions/53215285/how-can-i-force-component-to-re-render-with-hooks-in-react
    useEffect(() => {
        if (Env.tier.isTest) {
            return;
        }

        const subscriptionClient = Subscription.getClient();
        // Each on[event] method returns a function to cancel the
        // subscription. To clean up the subscriptions, we call each of these
        // cancel methods before the context unmounts.
        const cancelSubscriptions = [
            subscriptionClient.onReconnected((): void => {
                forceRerender();
            }),
        ];
        return (): void =>
            cancelSubscriptions.forEach((sub) => {
                sub();
            });
    }, []);

    return <Provider value={Client.getClient()}>{children}</Provider>;
};
