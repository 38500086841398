/* Dispatcher for comment message, displays editable, or readonly version */

import React, { FC, useMemo } from 'react';
import clsx from 'clsx';

import { UploadedFiles } from './UploadedFiles';
import { ProjectDetailsComponentProps } from '../context';
import { Message } from './Message';
import { Avatar } from '@/components/ui/icons/Avatar';
import { robotEventTypes } from '@/common/utils/robotUser';
import { EventFullRecord } from '@/queries/events';
import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';

import './Comment.scss';

export interface CommentProps extends ProjectDetailsComponentProps {
    message: EventFullRecord;
    removeMessage: () => Promise<void>;
    saveMessage: (message: string) => Promise<void>;
}

export const Comment: FC<CommentProps> = ({
    message,
    removeMessage,
    saveMessage,
    useProjectDetails,
}) => {
    const { project, editedComment } = useProjectDetails();

    const isRobot = robotEventTypes.includes(message.eventTypeName);

    const isEditing = useMemo(
        (): boolean => editedComment?.id === message.id,
        [editedComment, message]
    );

    return (
        <div
            className={clsx('discussions-comment', {
                edit: isEditing,
            })}
        >
            <Avatar user={mapBaseUserToIUserFragment(message.owner)} isRobot={isRobot} />
            <div className="comment-container">
                <Message
                    message={message}
                    useProjectDetails={useProjectDetails}
                    removeMessage={removeMessage}
                    saveMessage={saveMessage}
                />
                {message.projectUploadFiles.nodes.length > 0 && (
                    <UploadedFiles
                        files={message.projectUploadFiles.nodes}
                        projectUuid={project.uuid}
                    />
                )}
            </div>
        </div>
    );
};
