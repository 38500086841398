import { useQuery, UseQueryState } from 'urql';
import { PlanPagesQuery } from '@/queries/planPages';
import { PlanRecords } from '@/queries/plans';
import { ProjectRecord } from '@/queries/projects';
import { PlanPage } from '@/common/types';

interface UseProjectHasFilesArguments {
    project?: ProjectRecord;
}

interface UseProjectHasFilesPayload extends Pick<UseQueryState, 'error' | 'fetching'> {
    hasFiles: boolean;
}

const nonUndefinedPlanPages = (value: unknown): value is PlanPage =>
    (value as PlanPage) !== undefined;

export const getValidPlanPages = (pages?: PlanRecords) => {
    if (!pages) {
        return [];
    }

    const projectPlanFiles = pages?.projectPlanFiles?.nodes;

    if (!projectPlanFiles.length) {
        return [];
    }

    const planPages = projectPlanFiles
        .flatMap((projectPlanFile) => projectPlanFile.projectPlanPagesByProjectPlanId?.nodes)
        .filter(nonUndefinedPlanPages);

    if (!planPages.length) {
        return [];
    }

    return planPages.filter((page) => !page.removed);
};

export const useProjectHasFiles = ({
    project,
}: UseProjectHasFilesArguments): UseProjectHasFilesPayload => {
    const [result] = useQuery<PlanRecords>({
        pause: !project?.id,
        query: PlanPagesQuery,
        variables: { projectId: Number(project?.id) },
    });

    const { data, fetching, error } = result;

    return {
        hasFiles: getValidPlanPages(data).length > 0,
        fetching,
        error,
    };
};
