import { ExpressionBubbleContainer } from './styled';
import { markupUnitNameMap } from '@/common/utils/markups/maps';
import { useGroupIcon } from '@/components/AssemblyPanel/AssemblyPanelContent/AssemblySection/AssemblyList/GeometrySection/useGroupIcon';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';
import { IMarkupGroupFragment, useMarkupGroupQuery } from '@/graphql';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ElementRenderer } from 'leyden-react';
import React from 'react';
import { useSelected } from 'slate-react';

const toUom = (markupGroup: IMarkupGroupFragment) => {
    return markupGroup.markupGroupType ? markupUnitNameMap[markupGroup.markupGroupType] : 'X';
};

export const ExpressionMarkupGroup: ElementRenderer<'ExpressionMarkupGroup'> = ({
    attributes,
    children,
    element,
}) => {
    const theme = useTheme();

    const { data: markupGroupData } = useMarkupGroupQuery({
        variables: {
            input: { id: element.markupGroupID },
        },
    });
    const GroupIcon = useGroupIcon({ geometry: markupGroupData?.markupGroup.markupGroupType });

    const selected = useSelected();

    const renderMarkupGroupData = () => {
        if (!markupGroupData) {
            return <DotsLoader />;
        }
        const markupTextSegments: string[] = [
            markupGroupData.markupGroup.name,
            markupGroupData.markupGroup.measurement.toFixed(1),
        ];
        if (markupGroupData.markupGroup.markupGroupType) {
            markupTextSegments.push(markupUnitNameMap[markupGroupData.markupGroup.markupGroupType]);
        }
        return (
            <Tooltip title={markupGroupData.markupGroup.name}>
                <Stack alignItems="center" direction="row" spacing="6px">
                    <SvgIcon
                        color="primary"
                        sx={{
                            height: '14px',
                            width: '14px',
                        }}
                        viewBox="0 0 32 32"
                    >
                        <GroupIcon />
                    </SvgIcon>
                    <Typography variant="body1">
                        <span>{markupGroupData.markupGroup.measurement.toFixed(2)}</span>
                        <span>{toUom(markupGroupData.markupGroup)}</span>
                    </Typography>
                </Stack>
            </Tooltip>
        );
    };

    return (
        <ExpressionBubbleContainer
            {...attributes}
            selected={selected}
            borderColor={theme.palette.primary.main}
        >
            {renderMarkupGroupData()}
            {children}
        </ExpressionBubbleContainer>
    );
};
