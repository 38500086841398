import React, { FC } from 'react';
import { MenuRowOption } from '@/components/ui/inputs/Menu';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface MoreOptionsDialogProps {
    open: boolean;
    onClose: () => void;
    onCloseParent: () => void;
    options: MenuRowOption[] | undefined;
}

const MoreOptionsDialog: FC<MoreOptionsDialogProps> = ({
    open,
    onClose,
    onCloseParent,
    options,
}) => {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    // Override default
                    borderRadius: 0,
                },
            }}
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <List disablePadding>
                {options?.map(({ id, text, onClick }) => (
                    <ListItem
                        button
                        key={id}
                        onClick={() => {
                            onClick?.();
                            onClose();
                            onCloseParent();
                        }}
                    >
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default MoreOptionsDialog;
