import { Action } from './Action';
import { PriceEdit } from './PriceEdit';
import { ProjectPricingStatusIcon } from './ProjectPricingStatusIcon';
import { useProjects } from './context';
import { openedProjectObservable } from './observables';
import { mapProjectStatus } from './sort';
import { BuilderNameCell, DateCell, EstimatorCell, PriceTextCell, TextCell } from './styled';
import { getProjectDetailsLink } from '@/common/links';
import { AdminDashboardProjectStatus, PricingType, Setter } from '@/common/types';
import { DatabaseProjectStatus } from '@/common/types';
import { vendorIsApple } from '@/common/utils/helpers';
import { getBusinessDaysRemaining } from '@/common/utils/moment';
import { TableRow } from '@/components/AdminDashboard/Table/styled';
import { ProjectAction } from '@/components/AdminDashboard/types';
import { ActionTab, InitialAction } from '@/components/AdminDashboard/types';
import { AdminStatus, AdminStatusVariant } from '@/components/ui/controls/AdminStatus';
import { Trades } from '@/components/ui/controls/Trades';
import { User } from '@/components/ui/controls/User';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ITeamAccountType, IUserRole } from '@/graphql';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';
import { TradeRecord } from '@/queries/trades';
import { colorBackgroundSecondaryLightest } from '@/variables';
import moment from 'moment';
import React, { FC } from 'react';

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const importantTeamTypes = [ITeamAccountType.Trial, ITeamAccountType.Cancelled];

interface ProjectItemProps {
    project: ProjectWithEventsRecord;
    setActiveProject: Setter<ProjectRecord | null>;
    isActive: boolean;
}

export const ProjectTableRow: FC<ProjectItemProps> = ({ project, isActive, setActiveProject }) => {
    const { builderAcceptance } = useFlags();
    const { setConfirmPriceProject } = useProjects();

    const pricingAssignment =
        project.pricingAssignment &&
        project.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0];

    const priceUsdCents = pricingAssignment && pricingAssignment.priceUsdCents;

    // TODO: Connect with the autoquote price
    const autoQuotePrice = false;

    const isShowingPricingIcon = builderAcceptance && pricingAssignment;

    const handleClick = (e: React.MouseEvent): void => {
        if (!e.defaultPrevented) {
            if (e.ctrlKey) {
                const win = window.open(
                    getProjectDetailsLink(project.uuid, project.isSaas),
                    '_blank'
                );
                if (win !== null) {
                    win.focus();
                }
                return;
            }
            setActiveProject(project);

            // TODO: once refactoring Projects, remove this line
            openedProjectObservable.next({ project });
        }
    };

    const formatDateString = (dateString?: string): string =>
        dateString ? moment(dateString).format('MMM D') : '';

    const renderEstimator = (project: ProjectRecord): JSX.Element => {
        const projectUser = project.projectUsers?.nodes[0]?.user;

        if (!projectUser) {
            return <Action action={ProjectAction.AssignEstimator} project={project} />;
        }
        // We have to augment the user with some extra fields we do not yet query for.
        return (
            <User
                user={{
                    roles: [IUserRole.Estimator],
                    ...projectUser,
                    created: formatDateString(projectUser.created),
                    lastModified: formatDateString(projectUser.lastModified),
                }}
                isGhosted={project.status === DatabaseProjectStatus.PENDING_ESTIMATOR}
            />
        );
    };

    const onEditPrice = (): void => {
        openedProjectObservable.next({
            project,
            tab: ActionTab.Price,
            initialAction: InitialAction.FocusPrice,
        });
    };

    const onConfirmPrice = (): void => {
        setConfirmPriceProject(project);
    };

    const renderPrice = (): JSX.Element => {
        if (!autoQuotePrice && !priceUsdCents) {
            return <Action project={project} action={ProjectAction.AssignPrice} />;
        }

        const value = usdFormatter.format(priceUsdCents / 100);
        return builderAcceptance ? (
            <PriceEdit onEdit={onEditPrice} showConfirm={autoQuotePrice} onConfirm={onConfirmPrice}>
                {value}
            </PriceEdit>
        ) : (
            <>{value}</>
        );
    };

    const generateStatusBasedOnPricingType = (
        status: AdminDashboardProjectStatus
    ): AdminDashboardProjectStatus => {
        // If project is not cancelled, return pricing stage as status
        if (status !== AdminDashboardProjectStatus.PROJECT_CANCELED && builderAcceptance) {
            if (!pricingAssignment) return AdminDashboardProjectStatus.NEEDS_PRICE;

            if (pricingAssignment.pricingType === PricingType.DECLINED)
                return AdminDashboardProjectStatus.PRICE_DECLINED;

            if (pricingAssignment.pricingType === PricingType.PREAPPROVED)
                return AdminDashboardProjectStatus.PENDING_APPROVAL;

            if (
                pricingAssignment.pricingType === PricingType.FINAL &&
                status === AdminDashboardProjectStatus.NEW_SUBMISSION
            )
                return AdminDashboardProjectStatus.NEEDS_ESTIMATOR;
        }

        return status;
    };

    const renderAdminProjectStatus = (project: ProjectRecord): JSX.Element => {
        const status = mapProjectStatus(project);
        if (status === undefined) {
            return <></>;
        }

        return (
            <AdminStatus
                status={generateStatusBasedOnPricingType(status)}
                variant={AdminStatusVariant.Blocky}
            />
        );
    };

    const renderAdminProjectStatusIcon = (): JSX.Element => {
        if (!pricingAssignment) {
            return <></>;
        }

        return <ProjectPricingStatusIcon status={pricingAssignment.pricingType} />;
    };

    return (
        <TableRow isActive={isActive}>
            <TextCell
                onClick={handleClick}
                hasNotification={
                    !vendorIsApple &&
                    project.status !== DatabaseProjectStatus.CANCELED &&
                    project.events.nodes.filter((n) => !n.seen).length > 0
                }
            >
                {project.name}
            </TextCell>
            <BuilderNameCell onClick={handleClick}>
                {project.team?.name}
                {importantTeamTypes.includes(
                    project.team?.teamOnboarding?.accountType ?? ITeamAccountType.Subscriber
                )
                    ? ` (${(project.team?.teamOnboarding?.accountType ?? '').toLowerCase()})`
                    : ''}
            </BuilderNameCell>
            <td onClick={handleClick}>{renderAdminProjectStatus(project)}</td>
            <DateCell
                onClick={handleClick}
                isInDanger={
                    builderAcceptance &&
                    getBusinessDaysRemaining(new Date(), project.bidsDueDate) === 2
                }
            >
                {formatDateString(project.bidsDueDate)}
            </DateCell>
            <EstimatorCell onClick={handleClick}>{renderEstimator(project)}</EstimatorCell>
            <PriceTextCell
                isPriceEstimating={builderAcceptance && autoQuotePrice}
                isPendingApproval={
                    builderAcceptance &&
                    pricingAssignment &&
                    pricingAssignment.pricingType === PricingType.PREAPPROVED
                }
            >
                {renderPrice()}
                {isShowingPricingIcon && renderAdminProjectStatusIcon()}
            </PriceTextCell>
            <td onClick={handleClick}>
                {project.projectTrades?.nodes && (
                    <Trades
                        trades={project.projectTrades?.nodes.map(
                            (node: { trade: TradeRecord }) => node.trade
                        )}
                        howManyToShow={3}
                        background={colorBackgroundSecondaryLightest}
                    />
                )}
            </td>
            <DateCell onClick={handleClick}>{formatDateString(project.created)}</DateCell>
        </TableRow>
    );
};
