import styled from 'styled-components';

import { colorBackgroundLighter, colorTypographyDark } from '@/variables';

export const ProjectsFilter = styled.div`
    background-color: rgba(70, 126, 247, 0.07);
    height: 2.75rem;
    display: grid;
    padding-left: 2.625rem;
    align-items: center;
    grid-template-columns: 6.875rem max-content max-content max-content;
    gap: 1rem;
`;

export const NeedsAttention = styled.span`
    color: ${colorTypographyDark};
    font-size: 14px;
    letter-spacing: 0.01em;
    font-weight: 600;
`;

export const FilterItemContainer = styled.div<{ selected?: boolean }>`
    color: ${(props): string => (props.selected ? 'white' : colorTypographyDark)};
    background-color: ${(props): string => (props.selected ? colorBackgroundLighter : '#FDFDFE')};
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 2.625rem;
    cursor: pointer;

    svg {
        margin-right: 0.5rem;
        filter: ${(props): string => (props.selected ? 'brightness(0) invert(1)' : 'none')};
    }
`;

export const ProjectNumber = styled.div`
    font-weight: bold;
    margin-right: 0.25rem;
`;
