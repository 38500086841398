/*
 * Search is a search input in the top bar of the admin dashboard.
 */
import React, { ChangeEvent, FC, useRef } from 'react';

import { useProjects } from '../Projects/context';
import { SearchContainer, SearchInput } from './styled';

import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifying-glass-alt.svg';

interface SearchProps {
    placeholder?: string;
}

export const Search: FC<SearchProps> = ({ placeholder = 'Search' }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { search, setSearch } = useProjects();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value);

    return (
        <SearchContainer>
            <MagnifyingGlass
                height="1.125rem"
                width="1.125rem"
                style={{ cursor: 'pointer' }}
                onClick={(): void => inputRef.current?.focus()}
            />
            <SearchInput
                type="text"
                value={search}
                onChange={handleInputChange}
                placeholder={placeholder}
                ref={inputRef}
            />
        </SearchContainer>
    );
};
