/*
 * SingleDetail displays a single labeled detail within the price module.
 */
import React, { FC, ReactNode } from 'react';

import { DetailLabel, SelectableDetailContainer, SingleDetailContainer } from './styled';

export enum SingleDetailVariant {
    Standard,
    Selectable,
}

export interface SingleDetailProps {
    label: string;
    children?: ReactNode;
    variant?: SingleDetailVariant;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SingleDetail: FC<SingleDetailProps> = ({
    children,
    label,
    variant = SingleDetailVariant.Standard,
    onClick,
}) => {
    if (children === undefined) {
        return <></>;
    }

    const Container =
        variant === SingleDetailVariant.Selectable
            ? SelectableDetailContainer
            : SingleDetailContainer;

    return (
        <Container onClick={onClick}>
            <DetailLabel>{label}</DetailLabel>
            {children}
        </Container>
    );
};
