import { MutationOptions, OperationVariables, QueryOptions, useApolloClient } from '@apollo/client';

export type ApolloClient = ReturnType<typeof useApolloClient>;

export const executeMutation = async <T, TVariables = OperationVariables>(
    client: ApolloClient,
    options: MutationOptions<T, TVariables>
): Promise<T | null> => {
    const res = await client.mutate<T, TVariables>(options);
    if (res.errors) {
        throw res.errors;
    }
    return res.data ?? null;
};

export const executeQuery = async <T, TVariables = OperationVariables>(
    client: ApolloClient,
    options: QueryOptions<TVariables, T>
): Promise<T> => {
    const res = await client.query<T, TVariables>(options);
    if (res.error) {
        throw res.error;
    }
    if (res.errors) {
        throw res.errors;
    }
    return res.data;
};
