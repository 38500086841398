/*
 * assemblies.ts provides GraphQL queries used to fetch records from the
 * `assembly` table.
 */
import { frogmint } from '../frog';
import { ProjectRecord, projectFragment } from './projects';
import { TradeRecord, tradeFragment } from './trades';
import { UomRecord, uomFragment } from './unitOfMeasure';

export interface AssemblyRecord {
    id: number;
    description: string;
    popularity: number;
    uomId: number;
    uom: UomRecord;
    ownerId?: number;
    tradeId?: number;
    trade?: TradeRecord;
    projectId?: number;
    project?: ProjectRecord;
    // This field is not produced by postgraphile. It will get pumped into the type via the new, backend-based assembly
    // panel;
    favorited?: boolean;
}

export const assemblyFragment = frogmint`
    ${'Assembly_assembly'} {
        id
        description
        popularity
        ownerId
        tradeId
        trade {
            ${tradeFragment}
        }
        uomId
        uom {
            ${uomFragment}
        }
        projectId
        project {
            ${projectFragment}
        }
    }
`;
