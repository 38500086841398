import { AsYouType } from 'libphonenumber-js/max';

/**
 * I stole this from 👇 👇 👇
 * @see {https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript/48800#48800}
 * @param {string} email - email
 * @returns {boolean} if email is valid
 */
export const isValidEmail = (email: string): boolean => {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(String(email).toLowerCase());
};

export const isValidPhoneNumber = (string: string): boolean => {
    // We assume the default phone number country is US and use the library
    // to try and parse the number as it's type. If the format matches
    // a possible US phone number format, we mark the phone as valid.
    const asYouType = new AsYouType('US');
    asYouType.input(string);
    const number = asYouType.getNumber();
    return !!number && number.isPossible();
};
