import React, { FC, Dispatch, SetStateAction } from 'react';

import { RadioRow } from '@/components/ui/inputs/RadioRow';
import { NativeDatepicker } from '@/components/ui/inputs/NativeDatepicker';

import {
    Container,
    DateLabel,
    Label,
    Modal,
    ModalInput,
    RadioLabel,
    RadioContainer,
    Text,
    Error,
} from './styled';
import { AcceptanceDetails } from '@/common/types';

const COPY = {
    button: 'Got it',
    header: 'Glad to have you on board ',
    option_label: 'Are you planning on picking up all trades listed for this project?',
    picking_trades_option: 'Yes',
    not_picking_trades_option: 'No',
    not_picking_trades_label: 'Which trades are you planning to exlude?',
    not_picking_trades_text: `Partial acceptance is subject to 1build review due to max hours revision &
    the need to assign 2 estimators to 1 project`,
    not_picking_trades_placeholder: 'Enter your answer',
    start_date_label: 'When will you start this project?',
    end_date_label: 'By what date can you complete this project?',
    date_placeholder: 'Select date',
    error_message: 'Please fill in all the fields',
};

type AcceptanceModalProps = {
    close: () => void;
    submit: () => void;
    userName: string;
    acceptanceDetails: AcceptanceDetails;
    handleData: Dispatch<SetStateAction<AcceptanceDetails>>;
    error: boolean;
    maxDate: string;
};
export const AcceptanceModal: FC<AcceptanceModalProps> = ({
    close,
    submit,
    userName,
    acceptanceDetails,
    handleData,
    maxDate,
    error = false,
}) => (
    <Modal
        header={COPY.header + userName + '!'}
        close={close}
        submit={submit}
        submitCopy={COPY.button}
        preventDefault={false}
        className="modal-container"
    >
        <Container>
            <Label>{COPY.option_label}</Label>
            <RadioContainer>
                <RadioRow
                    id="1"
                    key="1"
                    isChecked={acceptanceDetails.pickingAllTrades}
                    onChange={(): void =>
                        handleData((previousState) => ({
                            ...previousState,
                            pickingAllTrades: true,
                        }))
                    }
                >
                    <RadioLabel>{COPY.picking_trades_option}</RadioLabel>
                </RadioRow>
                <RadioRow
                    id="2"
                    key="2"
                    isChecked={!acceptanceDetails.pickingAllTrades}
                    onChange={(): void =>
                        handleData((previousState) => ({
                            ...previousState,
                            pickingAllTrades: false,
                        }))
                    }
                >
                    <RadioLabel>{COPY.not_picking_trades_option}</RadioLabel>
                </RadioRow>
            </RadioContainer>
            {!acceptanceDetails.pickingAllTrades && (
                <div>
                    <Label>{COPY.not_picking_trades_label}</Label>
                    <Text>{COPY.not_picking_trades_text}</Text>
                    <ModalInput
                        type="text"
                        placeholder={COPY.not_picking_trades_placeholder}
                        value={acceptanceDetails.excludedTrades}
                        onChange={(value): void =>
                            handleData((previousState) => ({
                                ...previousState,
                                excludedTrades: value,
                            }))
                        }
                    />
                </div>
            )}
            <DateLabel>{COPY.start_date_label}</DateLabel>
            <NativeDatepicker
                placeholder={COPY.date_placeholder}
                value={acceptanceDetails.projectStartDate}
                onChange={(value): void =>
                    handleData((previousState) => ({
                        ...previousState,
                        projectStartDate: value,
                    }))
                }
                min={new Date().toISOString().split('T')[0]}
                max={acceptanceDetails.projectCompletionDate || maxDate}
                lightVariant
            />
            <DateLabel>{COPY.end_date_label}</DateLabel>
            <NativeDatepicker
                placeholder={COPY.date_placeholder}
                value={acceptanceDetails.projectCompletionDate}
                onChange={(value): void =>
                    handleData((previousState) => ({
                        ...previousState,
                        projectCompletionDate: value,
                    }))
                }
                min={acceptanceDetails.projectStartDate || new Date().toISOString().split('T')[0]}
                max={maxDate}
                lightVariant
            />
            {error && <Error>{COPY.error_message}</Error>}
        </Container>
    </Modal>
);
