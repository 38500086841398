import { createContext, useContext } from 'react';

import { PriceMarkup } from '../utils/types';
import { Setter } from '@/common/types';

export interface EstimatePriceMarkupsContext {
    priceMarkups: PriceMarkup[];
    savePriceMarkup: (newValue: PriceMarkup) => void;
    setPriceMarkups: Setter<PriceMarkup[]>;
}

export const EstimatePriceMarkupsContext = createContext<EstimatePriceMarkupsContext>({
    priceMarkups: [],
    savePriceMarkup: () => undefined,
    setPriceMarkups: () => undefined,
});

export const useEstimatePriceMarkups = (): EstimatePriceMarkupsContext => {
    return useContext(EstimatePriceMarkupsContext);
};
