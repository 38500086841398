import React, { FC, useState, useEffect } from 'react';
import { useMutation } from 'urql';

import { Checkbox } from '@/components/ui/inputs/Checkbox';
import { SmallButton } from '@/components/ui/buttons/SmallButton';
import { Experiment, ExperimentTeam, Setter, Team } from '@/common/types';
import { AddExperimentTeamResult, addExperimentTeamMutation } from '@/mutations/addExperimentTeam';
import {
    RemoveExperimentTeamResult,
    removeExperimentTeamMutation,
} from '@/mutations/removeExperimentTeam';

type TeamExperimentsProps = {
    experiments: Experiment[];
    experimentTeams: ExperimentTeam[];
    setExperimentTeams: Setter<ExperimentTeam[]>;
    team: Team;
};

/* Checks if given team and experiment matches given experimentTeam entry */
const doesTeamHaveExperiment = (
    { team, experiment }: ExperimentTeam,
    { id: experimentId }: Experiment,
    { id: teamId }: Team
): boolean => team.id === teamId && experiment.id === experimentId;

/* Checks if there is a experimentTeam entry for given experiment and team */
const isExperimentEnabled = (
    experimentTeams: ExperimentTeam[],
    experiment: Experiment,
    team: Team
): boolean => experimentTeams.some((entry) => doesTeamHaveExperiment(entry, experiment, team));

export const TeamExperiments: FC<TeamExperimentsProps> = ({
    experiments,
    experimentTeams,
    setExperimentTeams,
    team,
}) => {
    const [checkboxes, setCheckboxes] = useState<{ [key: number]: boolean }>();
    const [, addExperimentTeam] = useMutation<AddExperimentTeamResult>(addExperimentTeamMutation);
    const [, removeExperimentTeam] = useMutation<RemoveExperimentTeamResult>(
        removeExperimentTeamMutation
    );

    /* Build checkboxes object with 'id' as keys and 'isEnabled' as values */
    const buildCheckboxes = (): void => {
        experiments.length > 0 &&
            setCheckboxes(
                experiments.reduce(
                    (obj, experiment) => ({
                        ...obj,
                        [experiment.id]: isExperimentEnabled(experimentTeams, experiment, team),
                    }),
                    {}
                )
            );
    };

    /* Update checkboxes object with given value for given experiment id */
    const setCheckbox =
        (id: number) =>
        (value: boolean): void => {
            setCheckboxes((current) => ({
                ...current,
                [id]: value,
            }));
        };

    /* Tracks if checkboxes are synced with experiments */
    const isUpToDate = (): boolean =>
        checkboxes !== undefined &&
        experiments.every(
            (experiment) =>
                checkboxes[experiment.id] === isExperimentEnabled(experimentTeams, experiment, team)
        );

    const updateExperiments = (): void => {
        /* Exclude experiments that not changed */
        const experimentsToUpdate = experiments.filter(
            (experiment) =>
                checkboxes === undefined ||
                isExperimentEnabled(experimentTeams, experiment, team) !== checkboxes[experiment.id]
        );

        experimentsToUpdate.forEach((experiment) => {
            const isEnabled = isExperimentEnabled(experimentTeams, experiment, team);

            /* Update entries to immediatly reflect changes on checkboxes */
            setExperimentTeams((items) => {
                /* Remove entry if enabled */
                if (isEnabled) {
                    return items.filter((item) => !doesTeamHaveExperiment(item, experiment, team));
                }

                /* Add entry if disabled */
                return [...items, { team, experiment } as ExperimentTeam];
            });

            /* Perform mutation to either add or remove entry */
            if (isEnabled) {
                const entryToRemove = experimentTeams.find((entry) =>
                    doesTeamHaveExperiment(entry, experiment, team)
                );
                if (entryToRemove !== undefined) {
                    removeExperimentTeam({
                        id: entryToRemove.id,
                    });
                }
            } else {
                addExperimentTeam({
                    teamId: team.id,
                    experimentId: experiment.id,
                });
            }
        });
    };

    useEffect(buildCheckboxes, [experiments, experimentTeams]);

    return (
        <>
            <h2>{team.name}</h2>
            {checkboxes &&
                experiments.map(({ id, name }) => (
                    <div key={`${team.id}${id}`} className="flag">
                        <Checkbox
                            id={`${team.id}${id}`}
                            isChecked={checkboxes[id]}
                            onChange={setCheckbox(id)}
                        >
                            {name}
                        </Checkbox>
                    </div>
                ))}
            {!isUpToDate() && <SmallButton onClick={updateExperiments}>Save</SmallButton>}
        </>
    );
};
