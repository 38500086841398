import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { styled as styledByMui } from '@mui/material/styles';
import { transparentize } from 'polished';

export const ErrorSvgIcon = styledByMui(SvgIcon)((options) => ({
    color: options.theme.palette.hues.red[0],
    width: '16px',
    height: '16px',
}));

export const ErrorMenuItem = styledByMui(MenuItem)((options) => ({
    color: options.theme.palette.hues.red[0],

    '&:hover': {
        backgroundColor: transparentize(0.96, options.theme.palette.hues.red[0]),
    },
}));
