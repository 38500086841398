import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link as RRDLink } from 'react-router-dom';
import { useUser } from '@/contexts/User';
import { TextFieldWithLabel } from '@/components/ui/inputs/TextFieldWithLabel';

const COPY = {
    emailLabel: 'Email',
    emailPlaceholder: 'Enter email address',
    passwordReset: 'Reset password',
    login: 'Back to log in',
};

export const ResetPassword: FC = () => {
    const history = useHistory();

    const {
        actions: { resetUserPassword },
    } = useUser();

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({
        email: '',
    });
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);
        if (email) {
            await resetUserPassword({ email });

            history.push('/login');
        } else {
            setErrors({
                email: 'required',
            });
        }
        setLoading(false);
    };

    return (
        <Paper sx={{ padding: '30px', maxWidth: '400px', width: '100%' }}>
            <form onSubmit={handleFormSubmit}>
                <Stack spacing={3}>
                    <Typography sx={{ textAlign: 'center' }} variant="h3">
                        {COPY.passwordReset}
                    </Typography>
                    <TextFieldWithLabel
                        disabled={loading}
                        autoComplete="email"
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        label={COPY.emailLabel}
                        placeholder={COPY.emailPlaceholder}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <LoadingButton loading={loading} fullWidth variant="contained" type="submit">
                        {COPY.passwordReset}
                    </LoadingButton>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                            <Link
                                component={RRDLink}
                                sx={{
                                    textDecoration: 'underline',
                                }}
                                to="/login"
                            >
                                {COPY.login}
                            </Link>
                        </Typography>
                    </Box>
                </Stack>
            </form>
        </Paper>
    );
};
