/* Displays project status for admin */

import React, { FC } from 'react';
import clsx from 'clsx';

import { DivProps, AdminDashboardProjectStatus } from '@/common/types';

import './AdminStatus.scss';

export enum AdminStatusVariant {
    Standard,
    Blocky,
}

type AdminStatusProps = DivProps & {
    status: AdminDashboardProjectStatus;
    variant?: AdminStatusVariant;
};

const {
    DRAFT,
    NEW_SUBMISSION,
    PENDING_REVISION,
    UPDATED_SUBMISSION,
    PROJECT_CANCELED,
    NEEDS_REVIEW,
    IN_PROGRESS,
    COMPLETE,
    NEEDS_PRICE,
    PRICE_DECLINED,
    PENDING_ACCEPTANCE,
    ESTIMATOR_DECLINED,
    PENDING_APPROVAL,
    NEEDS_ESTIMATOR,
} = AdminDashboardProjectStatus;

export const AdminStatus: FC<AdminStatusProps> = ({
    status,
    variant = AdminStatusVariant.Standard,
    className,
}) => {
    const isAny = (...statuses: AdminDashboardProjectStatus[]): boolean =>
        statuses.some((item) => item === status);

    return (
        <div
            className={clsx(className, 'admin-status', {
                'blocky-status': variant === AdminStatusVariant.Blocky,
                orange: isAny(DRAFT),
                blue: isAny(
                    NEEDS_REVIEW,
                    NEW_SUBMISSION,
                    UPDATED_SUBMISSION,
                    NEEDS_PRICE,
                    NEEDS_ESTIMATOR
                ),
                safety: isAny(PROJECT_CANCELED, PRICE_DECLINED, ESTIMATOR_DECLINED),
                steel: isAny(COMPLETE, PENDING_REVISION, PENDING_ACCEPTANCE, PENDING_APPROVAL),
                caterpillar: isAny(IN_PROGRESS),
            })}
        >
            {variant === AdminStatusVariant.Blocky && <span />}
            {status.replace(/_/g, ' ')}
        </div>
    );
};
