import React, { FC, useEffect, useState } from 'react';

import { Modal } from './Modal';
import { RatingBar, RatingBarProps } from '../../ui/controls/RatingBar';
import { Container, Copy, CopyEdit, RatingBarWrapper, Underline } from './styled';
import { v2ToDateString } from '@/common/utils/moment';
import { useFeatures } from '@/contexts/Features';
import { useProjectReview } from '@/common/hooks/useProjectReview';
import { ProjectRecord } from '@/queries/projects';
import { ProjectReviewStatus } from '@/common/types';
import { ProjectReviewModal } from '../ProjectDetails/ProjectInfo/ProjectReviewModal';
import { useToggle } from '@/common/hooks/useToggle';

const COPY = {
    notStartedPrompt: 'How was your experience?',
    finishedPrompt: 'Thanks for the feedback!',
};

type ProjectReviewProps = {
    project: ProjectRecord;
};
export const ProjectReview: FC<ProjectReviewProps> = ({ project }) => {
    const {
        features: { marketplaceRating },
    } = useFeatures();
    const {
        review,
        handlePreferenceStatusChange,
        handleReviewCommentChange,
        handleReviewStarChange,
        handleReviewStatusChange,
        handleReviewTagChange,
        handleSubmitReview,
        setRating,
        allFieldsFilled,
    } = useProjectReview({ project, estimator: project?.projectUsers?.nodes[0]?.user });

    // State
    /*-------------------*/

    //////////////
    // UI State //
    //////////////
    const [isModalOpen, toggleModalOpen] = useToggle();
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isAnimated, setIsAnimated] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isNewReview, setIsNewReview] = useState(!review.lastModified || review.rating === 0);

    // Effects
    /*-------------------*/

    // Keep active state for a second after modal closes
    useEffect(() => {
        let highlightedSto: ReturnType<typeof setTimeout>;
        let submittedSto: ReturnType<typeof setTimeout>;
        let reviewSto: ReturnType<typeof setTimeout>;

        if (isSubmitted) {
            setIsAnimated(true);
            setIsHighlighted(true);

            highlightedSto = setTimeout(() => setIsHighlighted(false), 2000);
            submittedSto = setTimeout(() => {
                setIsAnimated(false);
            }, 2500);
            reviewSto = setTimeout(() => setIsSubmitted(false), 3000);
        }

        return (): void => {
            clearTimeout(highlightedSto);
            clearTimeout(submittedSto);
            clearTimeout(reviewSto);
        };
    }, [isSubmitted]);

    // Handlers
    /*-------------------*/

    /**
     * Generate copy per state
     * @returns {string | JSX.Element} content to be interpolated in <Copy> tag
     */
    const getReviewCopy = (): string | JSX.Element => {
        if (isSubmitted) {
            return COPY.finishedPrompt;
        } else if (review.status === ProjectReviewStatus.NotStarted) {
            return COPY.notStartedPrompt;
        }
        return (
            <CopyEdit>
                <Underline>Edit</Underline>{' '}
                {review?.lastModified
                    ? v2ToDateString(new Date(review.lastModified))
                    : v2ToDateString(new Date())}
            </CopyEdit>
        );
    };

    /**
     * Update a rating
     * @param {number} updatedRating - new rating
     */
    const updateRating: RatingBarProps['onChange'] = (updatedRating): void => {
        handleReviewStatusChange(ProjectReviewStatus.Edited);
        setRating(updatedRating);
        toggleModalOpen();
        setIsHover(false);
    };

    const handleOpenProjectReviewModal = () => {
        handleReviewStatusChange(ProjectReviewStatus.Edited);
        if (!isModalOpen) toggleModalOpen();
    };

    const handleCloseProjectReviewModal = () => {
        toggleModalOpen();
        setRating(review.currentReviewRating || 0);
    };

    const submitReview = async () => {
        await handleSubmitReview();
        setIsSubmitted(true);
        setIsNewReview(false);
        toggleModalOpen();
    };

    return (
        <Container
            className="project-ticket__review"
            isSelected={isModalOpen || isHighlighted}
            isAnimated={isAnimated}
            isNewReview={isNewReview}
            onClick={(e): void => {
                // Check if modal close setState has been triggered downstream
                if (!e.defaultPrevented && review.rating > 0) {
                    handleOpenProjectReviewModal();
                }

                // Stop link from redirecting
                e.preventDefault();
            }}
        >
            <Copy>{getReviewCopy()}</Copy>

            <RatingBarWrapper
                // Prevent propagation up to container
                onClick={(e): void => e.preventDefault()}
                onMouseEnter={(): void => setIsHover(true)}
                onMouseLeave={(): void => setIsHover(false)}
            >
                <RatingBar
                    rating={review.rating}
                    isHover={isHover}
                    isSelected={isModalOpen || isHighlighted}
                    onChange={updateRating}
                />
            </RatingBarWrapper>

            {isModalOpen &&
                (marketplaceRating ? (
                    <ProjectReviewModal
                        isSubmitDisabled={!allFieldsFilled}
                        review={review}
                        onStatusChange={handleReviewStatusChange}
                        onStarChange={handleReviewStarChange}
                        onReviewTagChange={handleReviewTagChange}
                        onCommentChange={handleReviewCommentChange}
                        onPreferenceStatusChange={handlePreferenceStatusChange}
                        onSubmitReview={submitReview}
                        close={handleCloseProjectReviewModal}
                    />
                ) : (
                    <Modal
                        projectName={review.projectInfo.projectName}
                        comment={review.comment}
                        rating={review.rating}
                        reviewTags={review.reviewTags}
                        setComment={handleReviewCommentChange}
                        setRating={handleReviewStarChange}
                        setReviewTags={handleReviewTagChange}
                        close={handleCloseProjectReviewModal}
                        submit={submitReview}
                    />
                ))}
        </Container>
    );
};
