import { ContextMenu } from '../../ContextMenu';
import { useAssemblyPanel } from '../../context';
import { useHandleFavoriting } from '../../hooks/useHandleFavoriting';
import { AssemblyList } from './AssemblyList';
import { AssemblySectionWrapper } from './styled';
import { IAssemblyLibraryOperation } from '@/graphql';
import { useDeleteAssemblyMutation } from '@/mutations/assembly';
import Box, { BoxProps } from '@mui/material/Box';
import React, { FC } from 'react';

interface AssemblySectionProps extends BoxProps {
    projectId: number;
}

export const AssemblySection: FC<AssemblySectionProps> = ({ projectId, ...props }) => {
    const {
        contextMenuOpen,
        setContextMenuOpen,
        contextMenuAssemblies,
        contextMenuPosition,
        contextMenuType,
    } = useAssemblyPanel();

    const [, deleteAssemblyMutation] = useDeleteAssemblyMutation();

    const handleFavoriting = useHandleFavoriting({ projectId });

    const canFavorite = (): boolean => {
        return new Set(contextMenuAssemblies.map((a) => a.favorited)).size === 1;
    };

    const deleteAssembly = async (id: number): Promise<void> => {
        const res = await deleteAssemblyMutation({ input: { id } });
        if (res.error) {
            throw res.error;
        }
    };

    return (
        <Box {...props}>
            <AssemblySectionWrapper>
                <AssemblyList currentProjectId={projectId} />
                {contextMenuOpen &&
                    contextMenuPosition &&
                    contextMenuAssemblies.length > 0 &&
                    contextMenuType && (
                        <ContextMenu
                            position={contextMenuPosition}
                            onClose={(): void => setContextMenuOpen(false)}
                            onDelete={(): Promise<void> =>
                                deleteAssembly(Number(contextMenuAssemblies[0].id))
                            }
                            onFavorite={
                                canFavorite()
                                    ? async (): Promise<void> => {
                                          await handleFavoriting(
                                              contextMenuAssemblies,
                                              contextMenuAssemblies[0]?.favorited
                                                  ? IAssemblyLibraryOperation.Remove
                                                  : IAssemblyLibraryOperation.Add
                                          );
                                      }
                                    : undefined
                            }
                            type={contextMenuType}
                            favorited={contextMenuAssemblies[0]?.favorited}
                        />
                    )}
            </AssemblySectionWrapper>
        </Box>
    );
};
