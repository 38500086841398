import React, { FC, useState } from 'react';

import { ColumnProps, HeaderCell } from './HeaderCell';
import { TableBody, Table as TableComponent, TableRow } from './styled';

export enum SortingDirection {
    Ascending,
    Descending,
}

type TableProps = {
    columns: Array<ColumnProps>;
    onSort?: (sortBy: string, order: SortingDirection) => void;
};

export const Table: FC<TableProps> = ({ columns, onSort, children }) => {
    const [cols, setCols] = useState(columns);

    const sort = (column: ColumnProps): void => {
        if (!column.sortable) {
            return;
        }

        const sortOrder =
            column.sortOrder === null
                ? SortingDirection.Ascending
                : column.sortOrder === SortingDirection.Descending
                ? SortingDirection.Ascending
                : SortingDirection.Descending;

        const newCols = cols.map((c) => {
            if (column.name === c.name) {
                c.active = true;
                c.sortOrder = sortOrder;
            } else {
                c.active = false;
            }
            return c;
        });

        setCols([...newCols]);

        if (onSort) {
            onSort(column.sortBy as string, sortOrder);
        }
    };

    return (
        <TableComponent>
            <thead>
                <TableRow>
                    {cols.map((c, i) => {
                        return <HeaderCell key={i} column={c} onSort={sort} />;
                    })}
                </TableRow>
            </thead>
            <TableBody>{children}</TableBody>
        </TableComponent>
    );
};
