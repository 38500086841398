import styled from 'styled-components';

import { CaretIcon } from '@/components/ui/icons/CaretIcon';
import { Input } from '../Input';
import {
    breakpointExtraSmall,
    breakpointSmall,
    colorBackgroundLight,
    colorTypographyAlt,
    colorTertiary,
    colorTertiaryLighter,
} from '@/variables';

export const DateWrapper = styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;

    &:first-child {
        margin-right: 1rem;
    }

    &.caret-icon {
        fill: #e08568;
        margin-left: -30px;
        z-index: 0;
    }
`;

export const DatePlaceholder = styled.div<{ light: boolean }>`
    position: absolute;
    top: ${(props): string => (props.light ? '13px' : '17px')};
    color: ${(props): string => (props.light ? colorTertiary : '#8f9CA7')};
    font-weight: ${(props): string => (props.light ? 'normal' : '600')};
    font-size: ${(props): string => (props.light ? '16px' : '12px')};
    width: 30%;
    padding: 10px 0 0 10px;
    z-index: 1;

    @media (max-width: ${breakpointExtraSmall}) {
        width: 50%;
    }
`;

export const DatePicker = styled(Input)<{ data: boolean; light: boolean }>`
    width: 100%;

    & > input {
        position: unset !important;
        opacity: unset !important;
        width: unset !important;
        height: 2.1rem !important;
        margin: 1rem 0 1rem 0;
        background: ${(props): string => (props.light ? colorTertiaryLighter : '')};

        &::-webkit-datetime-edit {
            color: ${(props): string => (props.light ? colorBackgroundLight : colorTypographyAlt)};

            @media (min-width: ${breakpointSmall}) {
                visibility: ${(props): string => (props.data ? 'visible' : 'hidden')};
            }
        }

        &::-webkit-calendar-picker-indicator {
            filter: ${(props): string => (props.light ? 'unset' : 'invert(1)')};

            @media (min-width: ${breakpointSmall}) {
                z-index: 1;
                opacity: 0;
                cursor: pointer;
                width: ${(props): string => (props.data ? '50%' : '100%')};
            }
        }
    }
`;

export const DropdownIcon = styled(CaretIcon)`
    margin-left: -30px;
    z-index: 0;
`;
