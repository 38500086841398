export interface Plan {
    name: PlanName;
    description?: string;
    variants: PlanVariant[];
    highlights?: string[];
}

export enum PlanName {
    SelfStarter = 'self-starter',
    Essential = 'essential',
    Professional = 'professional',
    Unknown = 'estimator network plan',
}

export enum PlanPeriod {
    Annually = 'annually',
    Monthly = 'monthly',
}

export interface PlanVariant {
    estimates?: number;
    id: string;
    price?: string;
    period?: PlanPeriod;
    display?: boolean;
}
