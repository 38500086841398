import { makeUseSubscription } from '../frog';
import { EventFullRecord, EventRecord, eventFragment, eventFullFragment } from '../queries/events';

export type EventChangedFullMessage = {
    EventChanged: {
        eventLog?: EventFullRecord | null;
    };
};

export const eventChangedSubscriptionFull = eventFullFragment.gql`
    subscription {
        EventChanged {
            eventLog {
                ${eventFullFragment}
            }
        }
    }
`;

export const useEventChangedFullSubscription = makeUseSubscription<EventChangedFullMessage>(
    eventChangedSubscriptionFull
);

export type EventCreatedMessage = {
    EventCreated: {
        eventLog?: EventRecord | null;
    };
};

export const eventCreatedSubscription = eventFragment.gql`
    subscription {
        EventCreated {
            eventLog {
                ${eventFragment}
            }
        }
    }
`;

export const useEventCreatedSubscription =
    makeUseSubscription<EventCreatedMessage>(eventCreatedSubscription);

export type EventCreatedFullMessage = {
    EventCreated: {
        eventLog?: EventFullRecord | null;
    };
};

export const eventCreatedSubscriptionFull = eventFullFragment.gql`
    subscription {
        EventCreated {
            eventLog {
                ${eventFullFragment}
            }
        }
    }
`;

export const useEventCreatedFullSubscription = makeUseSubscription<EventCreatedFullMessage>(
    eventCreatedSubscriptionFull
);
