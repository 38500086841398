import styled from 'styled-components';

import { Name } from '@/components/projects/ProjectOwnerBadge/styled';

export const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:hover) {
        .unassign-owner {
            display: none;
        }
    }

    & ${Name} {
        font-size: 12px;
    }

    svg {
        fill: white;
        transform: scale(0.7);
    }
`;

export const OwnProjectButton = styled.button`
    background-color: #3c4a7a;
    color: #56ccf2;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
`;
