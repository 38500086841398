import styled from 'styled-components';

import {
    borderCement,
    colorProjectPanelHighlight,
    colorTertiaryLighter,
    colorTypographyDark,
    colorTypographybody,
} from '@/variables';

export const EstimatorAssignmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
`;

export const EstimatorAssignmentContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 2;
    padding: 2rem 0;
    overflow: hidden auto;
`;

export const EstimatorAssignmentHeader = styled.h2`
    margin: 1.125rem 0 1rem 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: ${colorTypographyDark};
`;

export const EstimatorAssignmentSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18.75rem;
    height: 2.25rem;
    min-height: 2.25rem;
    margin: 1rem 2rem;
    padding: 0 0.75rem;
    border: ${borderCement};
    border-radius: 3.5rem;
`;

export const EstimatorAssignmentSearchInput = styled.input`
    flex-grow: 2;
    background: transparent;
    font-family: 'proxima-nova', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.03rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 0 0 0.75rem;
    padding: 0;
    color: ${colorTypographyDark};

    &::placeholder {
        opacity: 0.7;
    }
`;

export const EstimatorAssignmentAllEstimators = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-grow: 2;
`;

export const EstimatorAssignmentEstimator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding: 0 2.5rem 0 2rem;
    cursor: pointer;

    &:hover {
        background-color: ${colorTertiaryLighter};
    }
`;

export const EstimatorAssignmentRadioOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;

    .user span {
        font-weight: 600;
        font-size: 0.875rem;
        margin-top: 0.125rem;
    }
`;

export const EstimatorAssignmentRadioInput = styled.input`
    height: 3rem;
    padding: 0 2.5rem 0 2rem;
    margin-right: 0.875rem;
    cursor: pointer;

    &:hover {
        background-color: ${colorTertiaryLighter};
    }
`;

export const EstimatorAssignmentAssignEstimator = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.625rem 0 1.75rem;
    height: 5rem;
    background-color: ${colorProjectPanelHighlight};
    cursor: pointer;
`;

export const EstimatorAssignmentAssignmentProfile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const EstimatorAssignmentAssignmentText = styled.p`
    margin-left: 1.125rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: ${colorTypographybody};
`;

export const EstimatorActions = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const EstimatorThumbsContainer = styled.div`
    position: relative;
    display: flex;
`;
