import { SVG } from 'leaflet';
import {
    calcHelperParams,
    _prepareLadderLine,
    _prepareZigZagLine,
    _prepareCustomMarker,
    _prepareCustomMarkerHighlighted,
    _prepareCustomMarkerActive,
} from './helpers';
import { AreaType } from '../common/types';

import { areaImages } from './helpers';
import { colorDrawingTool1 } from '../variables';
import { colorDrawingTool2 } from '../variables';
import { colorDrawingTool3 } from '../variables';
import { colorDrawingTool4 } from '../variables';
import { colorDrawingTool5 } from '../variables';
import { colorDrawingTool6 } from '../variables';
import { colorDrawingTool7 } from '../variables';
import { colorDrawingTool8 } from '../variables';
import { colorDrawingTool9 } from '../variables';
import { colorDrawingTool10 } from '../variables';
import { colorDrawingTool11 } from '../variables';
import { colorDrawingTool12 } from '../variables';
import { colorDrawingTool13 } from '../variables';
import { colorDrawingTool14 } from '../variables';
import { colorDrawingTool15 } from '../variables';
import { colorDrawingTool16 } from '../variables';
import { colorDrawingTool17 } from '../variables';
import { colorDrawingTool18 } from '../variables';
import { colorDrawingTool19 } from '../variables';
import { colorDrawingTool20 } from '../variables';

const { cos, sin, PI } = Math;

// Overrides Leaflet SVG renderer allowing to draw custom lines
export const customPatternSVG = SVG.extend({
    _initContainer: function () {
        this._container = SVG.create('svg');
        // Makes it possible to click through svg root; we'll reset
        // it back in individual paths
        this._container.setAttribute('pointer-events', 'none');
        this._prepareAreaColors();
        this._prepareAreaPatterns();
        this._rootGroup = SVG.create('g');
        this._container.appendChild(this._rootGroup);
    },

    _prepareAreaColors: function () {
        this._areaColors = [
            colorDrawingTool1,
            colorDrawingTool2,
            colorDrawingTool3,
            colorDrawingTool4,
            colorDrawingTool5,
            colorDrawingTool6,
            colorDrawingTool7,
            colorDrawingTool8,
            colorDrawingTool9,
            colorDrawingTool10,
            colorDrawingTool11,
            colorDrawingTool12,
            colorDrawingTool13,
            colorDrawingTool14,
            colorDrawingTool15,
            colorDrawingTool16,
            colorDrawingTool17,
            colorDrawingTool18,
            colorDrawingTool19,
            colorDrawingTool20,
        ];
    },

    // Before using patterns we have to create DOM pattern elements
    // corresponding to all pattern x color combinations.
    _prepareAreaPatterns: function () {
        this._patternRect = [];
        const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        for (const [areaName, areaUrl] of Object.entries(areaImages)) {
            for (const color of this._areaColors) {
                const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
                pattern.setAttribute('id', areaName + '-' + color);
                pattern.setAttribute('width', '50');
                pattern.setAttribute('height', '50');
                pattern.setAttribute('patternUnits', 'userSpaceOnUse');
                pattern.setAttribute('x', 0);
                pattern.setAttribute('y', 0);
                pattern.setAttribute('fill-opacity', '1');
                const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                image.setAttribute('href', areaUrl.default);
                image.setAttribute('height', '100');
                image.setAttribute('width', '100');
                pattern.appendChild(this._createPatternRect(color));
                pattern.appendChild(image);
                defs.appendChild(pattern);
            }
        }
        this._container.append(defs);
    },
    // Creates color backgrounds for patterns
    _createPatternRect: function (color) {
        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('fill', color);
        rect.setAttribute('width', '10000');
        rect.setAttribute('height', '10000');
        rect.setAttribute('patternUnits', 'userSpaceOnUse');
        rect.setAttribute('fill-opacity', '0.4');
        rect.setAttribute('x', '0');
        rect.setAttribute('y', '0');
        this._patternRect.push(rect);
        return rect;
    },
    _prepareArrowLine: function (params, layer, closed, weight) {
        const { endX, endY } = params;
        const { zoom, alfa } = params;
        let path = SVG.pointsToPath(layer._parts, closed);
        const x = weight * zoom;
        const beta = (30 / 180) * PI + alfa;
        path += `
        M ${endX} ${endY}
        L ${endX + -x * cos(beta)} ${endY + x * sin(beta)}
        l ${-x * cos(beta - 0.5 * PI - 0.166 * PI)} 
        ${x * sin(beta - 0.5 * PI - 0.166 * PI)}
        z `;
        return path;
    },
    _updatePoly: function (layer, closed) {
        let i, j, len2;
        const parts = layer._parts;
        const len = parts.length;

        if (!len) {
            // When zooming outside of the path the _parts get set to empty
            // array and we have to update the view by setting the path to M0 0.
            // Setting Path to '' is not possible because of the
            // SVG specification
            return this._setPath(layer, 'M0 0 ');
        }
        if (layer.options.lineType === 'normalLine' || layer.options.lineType === undefined) {
            return this._setPath(layer, SVG.pointsToPath(layer._parts, closed));
        }
        if (layer.options.lineType === 'dottedLine') {
            layer.options._dashArray = [layer.options.shapeWeight, layer.options.shapeWeight];
            layer.stroke = true;
            layer._path.setAttribute('stroke-opacity', 1);
            layer._path.setAttribute('stroke-dasharray', layer.options._dashArray);
            return this._setPath(layer, SVG.pointsToPath(layer._parts, closed));
        }
        let path = '';
        for (i = 0; i < len; i++) {
            for (j = 0, len2 = parts[i].length; j + 1 < len2; j++) {
                // Help params like angle, length, start, end etc.
                const params = calcHelperParams(parts[i][j], parts[i][j + 1], this, layer);
                const shapeWeight = layer.options.shapeWeight;
                const { start, end } = params;
                switch (layer.options.lineType) {
                    case 'ladderLine':
                        path += _prepareLadderLine(params, closed, shapeWeight);
                        break;
                    case 'zigzagLine':
                        path += _prepareZigZagLine(params, closed, shapeWeight);
                        break;
                    case 'arrowLine':
                        // Draw Arrow only on last part of a line
                        if (j + 2 === len2) {
                            path += this._prepareArrowLine(params, layer, closed, shapeWeight);
                        } else {
                            path += ` M ${start.x} ${start.y} 
                                L ${end.x} ${end.y} `;
                        }
                        break;
                }
            }
        }
        return this._setPath(layer, path);
    },
    _updateStyle: function (layer) {
        const path = layer._path,
            options = layer.options;
        if (!path) {
            return;
        }

        if (options.stroke) {
            path.setAttribute('stroke', options.color);
            path.setAttribute('stroke-opacity', options.opacity);
            path.setAttribute('stroke-width', options.weight);
            path.setAttribute('stroke-linecap', options.lineCap);
            path.setAttribute('stroke-linejoin', options.lineJoin);

            if (options.dashArray) {
                path.setAttribute('stroke-dasharray', options.dashArray);
            } else {
                path.removeAttribute('stroke-dasharray');
            }

            if (options.dashOffset) {
                path.setAttribute('stroke-dashoffset', options.dashOffset);
            } else {
                path.removeAttribute('stroke-dashoffset');
            }

            // We're making rungs on the ladder and zigzags
            // as wide as the weight of the
            // underlying line, so we have to lower the weight if they are
            // to be visible.
            if (
                layer.options.lineType === 'ladderLine' ||
                layer.options.lineType === 'zigzagLine'
            ) {
                path.setAttribute('stroke-width', options.weight / 4);
            }
        } else {
            path.setAttribute('stroke', 'none');
        }

        if (options.fill) {
            path.setAttribute('fill', options.fillColor || options.color);
            path.setAttribute('fill-opacity', options.fillOpacity);
            path.setAttribute('fill-rule', options.fillRule || 'evenodd');
        } else {
            path.setAttribute('fill', 'none');
        }
        if (options.customMarkerType) {
            path.setAttribute('stroke', 'none');
        }
        // Sets chosen area pattern on update
        if (options.areaType && options.areaType !== AreaType.NORMAL) {
            path.setAttribute('fill', `url(#${options.areaType + '-' + options.color})`);
        }
    },

    _updateCircle: function (layer) {
        const p = layer._point,
            r = Math.max(Math.round(layer._radius), 1),
            r2 = Math.max(Math.round(layer._radiusY), 1) || r,
            arc = 'a' + r + ',' + r2 + ' 0 1,0 ';

        if (!layer.options.customMarkerType) {
            // drawing a circle with two half-arcs
            const d = layer._empty()
                ? 'M0 0'
                : 'M' + (p.x - r) + ',' + p.y + arc + r * 2 + ',0 ' + arc + -r * 2 + ',0 ';
            this._setPath(layer, d);
        } else {
            layer._path.style['pointer-events'] = 'bounding-box';
            const isBackground = layer.options.color === 'white';
            switch (layer.options.customMarkerType) {
                case 'normal':
                    this._setPath(layer, _prepareCustomMarker(p, isBackground));
                    break;
                case 'hovered':
                    this._setPath(layer, _prepareCustomMarkerHighlighted(p, isBackground));
                    break;
                case 'active':
                    this._setPath(layer, _prepareCustomMarkerActive(p, isBackground));
            }
            this._;
            this._bringToFront(layer);
        }
    },
});
