/**
 * A bar with options related to the geometry of a shape in takeoff.
 */
import React, { FC } from 'react';

import { ActionBar } from './helpers/ActionBar';
import { GeometryBarOption, GeometryBarOptionProps } from './helpers/GeometryBarOption';

import './GeometryBar.scss';

export type GeometryBarProps = {
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    text: string;
    options: GeometryBarOptionProps[];
};

export const GeometryBar: FC<GeometryBarProps> = (props) => {
    const optionElements = props.options.map(
        (option: GeometryBarOptionProps, index: number): JSX.Element => (
            <GeometryBarOption
                active={option.active}
                onClick={option.onClick}
                iconPath={option.iconPath}
                key={index}
            />
        )
    );

    return (
        <ActionBar
            className={props.className}
            onClick={props.onClick}
            submitText={props.onClick && 'done'}
        >
            <span className="geometry-bar-text">{props.text}</span>
            <span className="geometry-bar-multi-option-container">{optionElements}</span>
        </ActionBar>
    );
};
