import { Setter } from '@/common/types';
import { makeContext } from '@/common/utils/makeContext';
import {
    UseContextMenuObservablePayload,
    useContextMenuObservable,
} from '@/components/ui/inputs/ContextMenu/hooks/useContextMenuObservable';
import { ProjectRecord } from '@/queries/projects';
import { useState } from 'react';

interface ProjectsProps extends UseContextMenuObservablePayload {
    projectToDelete: ProjectRecord | null;
    projectToDuplicate: ProjectRecord | null;
    setProjectToDelete: Setter<ProjectRecord | null>;
    setProjectToDuplicate: Setter<ProjectRecord | null>;
}
const { useConsumer, Provider } = makeContext<ProjectsProps>(() => {
    // State
    /*---------------*/
    const [projectToDelete, setProjectToDelete] = useState<ProjectRecord | null>(null);
    const [projectToDuplicate, setProjectToDuplicate] = useState<ProjectRecord | null>(null);
    const contextMenuObservableSection = useContextMenuObservable();

    return {
        projectToDelete,
        setProjectToDelete,
        projectToDuplicate,
        setProjectToDuplicate,
        ...contextMenuObservableSection,
    };
});

/* Context provider and consumer, exported for accessibility */
export const ProjectsProvider = Provider;
export const useProjects = useConsumer;
