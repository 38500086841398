interface FormatterOptions extends Intl.NumberFormatOptions {
    locale?: string | string[];
}

const usd = ({ locale, ...options }: FormatterOptions = {}) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD',
        ...options,
    });
};

export const toDollarAmount = (cents?: number | string) => {
    return Number(cents) / 100;
};

export const toCentsAmount = (dollars?: number | string) => {
    return Number(dollars) * 100;
};

export const formatters = {
    usd,
};
