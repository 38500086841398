import { AssemblyPanelHeader } from '@/components/app/router/EstimationRoute/components/AssemblyPanelHeader';
import { useEstimationView } from '@/components/app/router/EstimationRoute/hooks/useEstimationView';
import { EstimationView } from '@/components/app/router/EstimationRoute/types';
import { AssemblySection } from './AssemblySection';
import { LibrarySection } from './LibrarySection';
import { LocalizationSection } from './LocalizationSection';
import Box, { BoxProps } from '@mui/material/Box';
import React, { FC } from 'react';

interface AssemblyPanelContentProps extends BoxProps {
    projectId: number;
}

export const AssemblyPanelContent: FC<AssemblyPanelContentProps> = ({ projectId, ...props }) => {
    const { view } = useEstimationView();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'grid',
                gridGap: '12px',
                gridTemplateRows: 'auto auto 1fr',
            }}
            {...props}
        >
            <AssemblyPanelHeader sx={{ padding: '12px 8px 0' }} />
            {view === EstimationView.Estimate && <LocalizationSection sx={{ padding: '0 8px' }} />}
            <Box
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                    display: 'grid',
                    gridTemplateColumns: view === EstimationView.Estimate ? '160px 1fr' : '1fr',
                }}
            >
                {view === EstimationView.Estimate && (
                    <LibrarySection
                        sx={{
                            borderRight: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                        }}
                    />
                )}
                <AssemblySection projectId={projectId} />
            </Box>
        </Box>
    );
};
