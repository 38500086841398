// To align with urql's types, we have to use the `extends object = {}` pattern.

/* eslint-disable @typescript-eslint/ban-types */
import { DocumentNode } from 'graphql';
import { OperatorFunction } from 'rxjs';
import {
    OperationContext,
    OperationResult,
    UseMutationResponse,
    UseQueryArgs,
    UseQueryResponse,
} from 'urql';

// lifted directed from the graphql-tag typedefs
export type GqlTag = (
    literals: ReadonlyArray<string> | Readonly<string>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...placeholders: Array<Record<string, unknown> | Frogmint | DocumentNode>
) => DocumentNode;

export interface Frogmint {
    dependencies: Frogmint[];
    fragmentName: string;
    fragment: DocumentNode;
    fragmentType: string;
    gql: GqlTag;
}

export type FrogResult<Result> = Promise<OperationResult<Result>>;
export type FrogExecutor<Args, Result> = (
    input: Args,
    options?: Partial<OperationContext>
) => FrogResult<Result>;

export type FrogCondition<Condition> = { condition: Condition };
export type FrogQueryCondition<Condition> = Omit<
    UseQueryArgs<FrogCondition<Condition>>,
    'query' | 'variables'
>;

export type FrogMutationHook<Args, Result> = () => UseMutationResponse<Result, Args>;
export type FrogQueryHook<Condition, Result> = (
    condition: Condition,
    options?: FrogQueryCondition<Condition>
) => UseQueryResponse<Result>;

export const isDocumentNode = (
    d: Record<string, unknown> | Frogmint | DocumentNode
): d is DocumentNode =>
    'kind' in d && d.kind === 'Document' && 'definitions' in d && Array.isArray(d.definitions);

// Since we're trying to differentiate between `gql`'s `any` placeholders and
// Frogmints, we have to use `any` in the signature.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFrogmint = (
    froggy: Record<string, unknown> | Frogmint | DocumentNode
): froggy is Frogmint =>
    !isDocumentNode(froggy) &&
    Array.isArray(froggy.dependencies) &&
    typeof froggy.fragmentName === 'string' &&
    typeof froggy.fragment !== 'undefined' &&
    typeof froggy.gql === 'function';

//
// Subscription
//

export type FrogSubscriptionOptions<T, Variables extends object = {}> = Partial<{
    variables: Variables;
    context: Partial<OperationContext>;
    operator: OperatorFunction<OperationResult<T>, OperationResult<T>>;
}>;

export type FrogSubscriptionState<T> = Omit<OperationResult<T>, 'operation'>;

export type FrogSubscriptionHook<T, Variables extends object = {}> = (
    options?: FrogSubscriptionOptions<T, Variables>
) => FrogSubscriptionState<T>;
