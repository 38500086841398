import React, { FC } from 'react';
import moment from 'moment';

import { Revision } from './styled';
import { BasicModal } from '@/components/ui/controls/BasicModal';

const COPY = {
    button: 'Project setup',
    dateChanged: 'Estimate return date revised:',
    header: 'The following is needed before we can proceed with this project:',
    informationNeeded: 'Information needed:',
};

type RevisionModalProps = {
    additionalInfo: string;
    close: () => void;
    estimateDueDate: string;
    reviseProject: () => void;
};
export const RevisionModal: FC<RevisionModalProps> = ({
    additionalInfo,
    close,
    estimateDueDate,
    reviseProject,
}) => (
    <BasicModal
        header={COPY.header}
        close={close}
        submit={(): void => {
            close();
            reviseProject();
        }}
        submitCopy={COPY.button}
    >
        <Revision>
            <h2>{COPY.dateChanged}</h2>
            <p>{moment(estimateDueDate).format('MMM DD, YYYY')}</p>

            <h2>{COPY.informationNeeded}</h2>
            <p>{additionalInfo}</p>
        </Revision>
    </BasicModal>
);
