import React, { FC } from 'react';

import { TabSelector } from './TabSelector';
import { OnebuildLibrarySectionWrapper } from './styled';
import { AssemblyListTab, useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { MaterialCategoryList } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/MaterialCategoryList';
import { SourcesList } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/SourcesList';
import { ISourceTypeV2 } from '@/graphql';

interface OnebuildLibrarySectionProps {
    projectId: number;
}

export const OnebuildLibrarySection: FC<OnebuildLibrarySectionProps> = ({ projectId }) => {
    const { tab } = useAssemblyPanel();
    return (
        <OnebuildLibrarySectionWrapper>
            <TabSelector itemsLabel="Materials" groupsLabel="Assemblies" />

            {tab === AssemblyListTab.ITEMS && (
                <MaterialCategoryList currentProjectId={projectId} variant="infinite" />
            )}
            {tab === AssemblyListTab.GROUPS && (
                <SourcesList
                    currentProjectId={projectId}
                    pageSize={200}
                    sourceType={ISourceTypeV2.Assembly}
                />
            )}
        </OnebuildLibrarySectionWrapper>
    );
};
