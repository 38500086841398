import { useEstimateEditor } from '../hooks/useEstimateEditor';
import { useEffect, useRef, useState, useCallback } from 'react';
import { Editor } from 'slate';

import { useEstimateProject } from './useEstimateProject';
import { fetchGroupAssembliesPage, fetchItemAssemblies } from '../utils/requests';
import { EstimateElementsPage, EstimateElement, EstimateElementCategory } from '../utils/types';
import { useApolloClient } from '@apollo/client';
import { genPaginationArgs, PaginationDirection } from '@/common/utils/pagination';
import { IPageInfoFragment } from '@/graphql';
import { Transforms } from '../transforms';

export interface UseSeedEstimateTable {
    pageSize?: number;
    resetTable?: () => void;
    fetchMore?: boolean;
}

export interface UseSeedEstimateTablePayload {
    loading: boolean;
    hasMoreElements: boolean;
    fetchGroupAssemblies: () => Promise<void>;
}

export const useSeedEstimateTable = ({
    pageSize = 5,
    resetTable,
}: UseSeedEstimateTable): UseSeedEstimateTablePayload => {
    const client = useApolloClient();
    const editor = useEstimateEditor();
    const { projectID } = useEstimateProject();

    const [previousPageInfo, setPreviousPageInfo] = useState<IPageInfoFragment>();
    const [loading, setLoading] = useState(false);

    const pageInfoProjectID = useRef(projectID);

    // We will only need to fetch item assemblies on first call.
    // For now, we can handle this with a ref.
    const isInit = useRef(false);

    const processAssemblies = useCallback(
        (
            categories: EstimateElementCategory[],
            uncategorizedElements?: EstimateElement[]
        ): void => {
            const addCategorized = categories.length > 0;
            const addUncategorized = uncategorizedElements && uncategorizedElements?.length > 0;

            if (addCategorized || addUncategorized) {
                Editor.withoutNormalizing(editor, () => {
                    if (addCategorized) {
                        Transforms.addCategories(editor, categories, {
                            comesFromBackend: true,
                        });
                    }

                    if (uncategorizedElements && addUncategorized) {
                        Transforms.addUncategorizedElements(editor, uncategorizedElements);
                    }
                });
            }
        },
        []
    );

    const fetchGroupAssemblies = useCallback(async (): Promise<void> => {
        let pageInfo: IPageInfoFragment | undefined;

        setLoading(true);

        // Reset project id and ref
        if (pageInfoProjectID.current !== projectID) {
            pageInfoProjectID.current = projectID;
            isInit.current = false;
            resetTable?.();
        } else if (previousPageInfo) {
            pageInfo = { ...previousPageInfo };
        }
        const paginationArgs = genPaginationArgs(PaginationDirection.Next, pageSize, pageInfo);

        let groupAssemblies: EstimateElementsPage | undefined = undefined;
        let itemAssemblies: EstimateElement[] | undefined = undefined;

        // We will fetch items on initial call.
        // For all subsequent calls, all assemblies will be groups.
        // The only ITEM assembly should be the placeholder assembly.
        // Going forward, this will be chunked up into separate assemblies
        if (!isInit.current && paginationArgs) {
            [groupAssemblies, itemAssemblies] = await Promise.all([
                fetchGroupAssembliesPage(client, String(projectID), paginationArgs),
                fetchItemAssemblies(client, String(projectID)),
            ]);
            isInit.current = true;
        } else if (paginationArgs) {
            groupAssemblies = await fetchGroupAssembliesPage(
                client,
                String(projectID),
                paginationArgs
            );
        }

        if (groupAssemblies) {
            setPreviousPageInfo(groupAssemblies.pageInfo);
        } else {
            return;
        }

        processAssemblies(groupAssemblies.categories, itemAssemblies);

        setLoading(false);
    }, [projectID, previousPageInfo, pageSize, processAssemblies, resetTable, client]);

    const hasMoreElements = previousPageInfo ? previousPageInfo?.hasNextPage : true;

    useEffect(() => {
        fetchGroupAssemblies();
    }, []);

    return {
        loading,
        hasMoreElements,
        fetchGroupAssemblies,
    };
};
