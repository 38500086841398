import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';
import { AreaType, Geometry, GeometryType, Svg } from '@/common/types';
import { styleObservable } from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useState } from 'react';

import { ReactComponent as BlockworkAreaIcon } from '@/assets/icons/stylepanel/blockwork-area.svg';
import { ReactComponent as BrickAreaIcon } from '@/assets/icons/stylepanel/brick-area.svg';
import { ReactComponent as ConcreteAreaIcon } from '@/assets/icons/stylepanel/concrete-area.svg';
import { ReactComponent as DotsAreaIcon } from '@/assets/icons/stylepanel/dots-area.svg';
import { ReactComponent as HardcoreAreaIcon } from '@/assets/icons/stylepanel/hardcore-area.svg';
import { ReactComponent as HorizontalLinesAreaIcon } from '@/assets/icons/stylepanel/horizontal-lines-area.svg';
import { ReactComponent as InsulationAreaIcon } from '@/assets/icons/stylepanel/insulation-area.svg';
import { ReactComponent as NormalAreaIcon } from '@/assets/icons/stylepanel/normal-area.svg';
import { ReactComponent as ShinglesAreaIcon } from '@/assets/icons/stylepanel/shingles-area.svg';
import { ReactComponent as StoneAreaIcon } from '@/assets/icons/stylepanel/stone-area.svg';
import { ReactComponent as VerticalLinesAreaIcon } from '@/assets/icons/stylepanel/vertical-lines-area.svg';
import { ReactComponent as WoodAreaIcon } from '@/assets/icons/stylepanel/wood-area.svg';

import { ReactComponent as BlockworkAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/blockwork-area.svg';
import { ReactComponent as BrickAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/brick-area.svg';
import { ReactComponent as ConcreteAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/concrete-area.svg';
import { ReactComponent as DotsAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/dots-area.svg';
import { ReactComponent as HardcoreAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/hardcore-area.svg';
import { ReactComponent as HorizontalLinesAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/horizontal-lines-area.svg';
import { ReactComponent as InsulationAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/insulation-area.svg';
import { ReactComponent as NormalAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/normal-area.svg';
import { ReactComponent as ShinglesAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/shingles-area.svg';
import { ReactComponent as StoneAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/stone-area.svg';
import { ReactComponent as VerticalLinesAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/vertical-lines-area.svg';
import { ReactComponent as WoodAreaIcon2 } from '@/assets/icons/stylepanel/dropdown-areas/wood-area.svg';

export const PatternPicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const { areaType } = styleObservable.value;
    const geometries = geometriesObservable.value;

    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);

    const setAreaType = (newAreaType: AreaType): void => {
        styleObservable.next({
            ...styleObservable.value,
            areaType: newAreaType,
        });
    };

    const setGeometries = (newGeometries: Geometry[]): void => {
        geometriesObservable.next(newGeometries);
    };

    const getAreaTypeIcon = (areaType: AreaType) => {
        const icons: Record<AreaType, Svg> = {
            [AreaType.NORMAL]: NormalAreaIcon,
            [AreaType.WOOD]: WoodAreaIcon,
            [AreaType.BRICK]: BrickAreaIcon,
            [AreaType.STONE]: StoneAreaIcon,
            [AreaType.SHINGLES]: ShinglesAreaIcon,
            [AreaType.INSULATION]: InsulationAreaIcon,
            [AreaType.CONCRETE]: ConcreteAreaIcon,
            [AreaType.HARDCORE]: HardcoreAreaIcon,
            [AreaType.BLOCKWORK]: BlockworkAreaIcon,
            [AreaType.VERTICAL_LINES]: VerticalLinesAreaIcon,
            [AreaType.HORIZONTAL_LINES]: HorizontalLinesAreaIcon,
            [AreaType.DOTS]: DotsAreaIcon,
        };

        return icons[areaType];
    };

    const getAreaTypeIcon2 = (areaType: AreaType) => {
        const icons: Record<AreaType, Svg> = {
            [AreaType.NORMAL]: NormalAreaIcon2,
            [AreaType.WOOD]: WoodAreaIcon2,
            [AreaType.BRICK]: BrickAreaIcon2,
            [AreaType.STONE]: StoneAreaIcon2,
            [AreaType.SHINGLES]: ShinglesAreaIcon2,
            [AreaType.INSULATION]: InsulationAreaIcon2,
            [AreaType.CONCRETE]: ConcreteAreaIcon2,
            [AreaType.HARDCORE]: HardcoreAreaIcon2,
            [AreaType.BLOCKWORK]: BlockworkAreaIcon2,
            [AreaType.VERTICAL_LINES]: VerticalLinesAreaIcon2,
            [AreaType.HORIZONTAL_LINES]: HorizontalLinesAreaIcon2,
            [AreaType.DOTS]: DotsAreaIcon2,
        };

        return icons[areaType];
    };

    const updateAreaType = (v: AreaType) => {
        setAreaType(v);

        const needsUpdate = (g: Geometry): boolean =>
            selectedGeometryIds.includes(g.uuid) && g.type === GeometryType.AREA;

        if (geometries === null) {
            return;
        }

        setGeometries(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style: {
                                  ...g.style,
                                  areaType: v,
                              },
                          } // update areaType
                        : g // do nothing
            )
        );
    };

    return (
        <Box {...props}>
            <IconButton
                onClick={(event) => setAnchor(event.currentTarget)}
                sx={{
                    height: '37px',
                    width: '37px',
                }}
                size="small"
            >
                <SvgIcon
                    sx={{
                        height: '20px',
                    }}
                    viewBox="0 0 16 16"
                >
                    {(() => {
                        const Icon = getAreaTypeIcon(areaType);

                        return <Icon />;
                    })()}
                </SvgIcon>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    BackdropProps={{
                        invisible: true,
                    }}
                    PaperProps={{
                        sx: {
                            padding: '16px',
                            width: '175px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Patterns
                    </Typography>
                    <Box>
                        {Object.values(AreaType).map((item) => {
                            const Icon = getAreaTypeIcon2(item);

                            return (
                                <IconButton
                                    key={item}
                                    onClick={() => {
                                        updateAreaType(item);

                                        setAnchor(null);
                                    }}
                                >
                                    <SvgIcon viewBox="0 0 32 32">
                                        <Icon />
                                    </SvgIcon>
                                </IconButton>
                            );
                        })}
                    </Box>
                </Popover>
            </Mode>
        </Box>
    );
};
