import styled from 'styled-components';

import { inputCss } from '../../../../utils/styles';

import { MarkupInputVariant } from '.';

type MarkupInputWrapperProps = {
    variant: MarkupInputVariant;
};

const markupInputVariantToIcon = (variant: MarkupInputVariant): string => {
    switch (variant) {
        case MarkupInputVariant.Normal:
            return '';
        case MarkupInputVariant.Percentage:
            return '%';
        case MarkupInputVariant.Dollars:
            return '';
    }
};

const markupInputVariantToIconSide = (variant: MarkupInputVariant): string => {
    switch (variant) {
        case MarkupInputVariant.Percentage:
            return 'right';
        default:
            return 'left';
    }
};

const markupInputVariantToIconDistance = (variant: MarkupInputVariant): string => {
    switch (variant) {
        case MarkupInputVariant.Percentage:
            return '8px';
        default:
            return '5px';
    }
};

export const MarkupInputWrapper = styled.div<MarkupInputWrapperProps>`
    position: relative;

    overflow: hidden;
    border-radius: 0;

    &:first-of-type {
        border-radius: 2px 0px 0px 2px;
    }

    &:last-of-type {
        border-radius: 0px 2px 2px 0px;
    }

    &::before {
        color: ${(props): string =>
            props.variant === MarkupInputVariant.Percentage ? '#CDD9EA' : '#D7E1E5'};
        content: '${(props): string => markupInputVariantToIcon(props.variant)}';
        position: absolute;
        font-size: ${(props): string =>
            props.variant === MarkupInputVariant.Percentage ? '16px' : 'inherit'};
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        ${(p): string =>
            `${markupInputVariantToIconSide(p.variant)}: ${markupInputVariantToIconDistance(
                p.variant
            )}`}
    }
`;

type MarkupInputStyledProps = {
    hasPlaceholder: boolean;
};
export const MarkupInputStyled = styled.input<MarkupInputStyledProps>`
    ${inputCss}

    &::placeholder {
        color: rgba(215, 225, 229, ${(props): string => (props.hasPlaceholder ? '0.4' : '1')});
    }

    border-radius: 0;
`;
