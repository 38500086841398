// Helper to do fetch with timeout
// @see https://dmitripavlutin.com/timeout-fetch-request/
export const fetchWithTimeout = async (
    resource: string,
    init: RequestInit = {},
    timeout = 30000
): Promise<Response> => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...init,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
};
