import styled, { css } from 'styled-components';

export const Container = styled.div({
    position: 'relative',
});

export const CropperWrapper = styled.div({
    position: 'relative',
    height: '275px',
    marginBottom: '20px',

    '& > div': {
        height: '100%',
    },
});

export const ConfirmationButtons = styled.div({
    display: 'flex',
    justifyContent: 'space-between',

    width: '100%',

    button: {
        '&:first-child': {
            marginRight: '0.625rem',
        },
    },
});

export const CheckMark = styled.span(
    {
        position: 'relative',
        height: '22px',
        width: '22px',
        backgroundColor: '#eee',

        borderRadius: '4px',

        '&:after': {
            content: '""',
            position: 'absolute',
            display: 'none',

            left: '8px',
            top: '4px',
            width: '5px',
            height: '9px',

            border: 'solid #FFFFFF',
            borderWidth: '0 2px 2px 0',
        },
    },
    css`
        &:after {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    `
);

export const CheckboxWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
});

export const CheckboxLabel = styled.label({
    position: 'relative',

    display: 'flex',
    alignItems: 'center',

    '> span': {
        marginRight: '10px',
    },

    '> input': {
        position: 'absolute',
        opacity: '0',
        cursor: 'pointer',
        height: '0',
        width: '0',
        left: '0',
    },

    '&:hover': {
        [`input ~ ${CheckMark}`]: {
            backgroundColor: '#ccc',
        },
    },

    [`input:checked ~ ${CheckMark}`]: {
        backgroundColor: '#128AFA',
    },

    [`input:checked ~ ${CheckMark}:after`]: {
        display: 'block',
    },
});
