import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';

export const DaysLeft = styled(MuiTypography)((options) => ({
    color: options.theme.palette.hues.neutral[100],
    marginRight: '8px',

    ...breakpointsCSSObject.small({
        marginLeft: '8px',
    }),
}));
