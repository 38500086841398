import styled from 'styled-components';

import { colorBackground } from '@/variables';

export const Revision = styled.div`
    color: inherit;

    & > h2 {
        font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
        font-style: normal;
        font-weight: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;

        margin: 0;
    }

    & > p {
        margin-bottom: 1rem;
    }

    & > h2,
    & > p {
        color: ${colorBackground};
    }
`;
