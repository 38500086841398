import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';

const COPY = {
    noProjectName: 'Untitled Project',
};

interface HeaderProps {
    className?: string;
}
export const Header: FC<HeaderProps> = () => {
    const theme = useTheme();
    const { isProjectFetching, projectName, setProjectName } = useEstimationProject();
    const location = useLocation<{ isNewDIYProject?: boolean }>();
    const [editedProjectName, setEditedProjectName] = useState<string | undefined>(
        projectName ?? undefined
    );
    const [savingProjectName, setSavingProjectName] = useState(false);

    useEffect(() => {
        setEditedProjectName(projectName);
    }, [projectName]);

    useEffect(() => {
        setSavingProjectName(false);

        if (location.state?.isNewDIYProject) {
            setEditedProjectName(projectName);
        }
    }, [projectName, location.state?.isNewDIYProject]);

    const updateProjectAndClose = (): void => {
        if (editedProjectName === undefined) {
            return;
        }

        const newProjectName = editedProjectName.trim() ?? '';
        if (newProjectName === projectName) {
            setEditedProjectName(undefined);
            return;
        }

        setSavingProjectName(true);
        setProjectName(newProjectName).catch(() => {
            setEditedProjectName(undefined);
            setSavingProjectName(false);
        });
    };

    const handleHeaderTextInputKeyDown: KeyboardEventHandler = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        switch (e.key) {
            case 'Enter': {
                updateProjectAndClose();
                break;
            }
            case 'Escape': {
                setEditedProjectName(undefined);
                break;
            }
        }
    };

    return (
        <>
            {isProjectFetching ? (
                <Skeleton sx={{ height: 34.75, width: 400 }} />
            ) : (
                <TextField
                    disabled={savingProjectName}
                    fullWidth
                    hiddenLabel
                    InputProps={{
                        style: {
                            fontSize: theme.typography.h3.fontSize,
                            fontWeight: Number(theme.typography.h3.fontWeight),
                            lineHeight: theme.typography.h3.lineHeight,
                        },
                    }}
                    onBlur={updateProjectAndClose}
                    onChange={({ target: { value } }): void => {
                        setEditedProjectName(value);
                    }}
                    onKeyDown={handleHeaderTextInputKeyDown}
                    placeholder={COPY.noProjectName}
                    size="small"
                    sx={{
                        marginBottom: -5,
                    }}
                    value={editedProjectName}
                    variant="standard"
                />
            )}
        </>
    );
};
