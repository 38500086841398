import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ProtectedRoute } from '@/components/app/ProtectedRoute';
import { EstimationRoute } from '@/components/app/router/EstimationRoute';
import { ProjectEditing } from '@/components/projects/ProjectEditing';
import { ProjectsToolbar } from '@/components/ui/containers/ProjectsToolbar';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import { BuilderDetails } from '@/views/BuilderDetails';
import { Project } from '@/views/Project';
import {
    hasEstimateFeature,
    hasProjectFeature,
    hasTakeoffFeature,
    isDefaultRolesUser,
    isSaasProject,
} from '@/views/Projects/utils';
import { SharedEstimate } from '@/views/SharedEstimate';
import { Unsubscribe } from '@/views/Unsubscribe';
import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

export const ProjectDetailsRouter: FC = () => {
    const { features } = useFeatures();

    const { path, url } = useRouteMatch();
    const { project, error } = useProjectFromParams();

    const {
        data: { user, anonymousUser },
    } = useUser();

    // If there is an error fetching the project, redirect back to main view
    if (error) {
        return <Redirect to="/" />;
    }

    if (!project) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{project.name}</title>
                <meta name="description" content={project.name} />
            </Helmet>
            <Switch>
                <ProtectedRoute
                    path={`${path}/edit`}
                    roles={[IUserRole.Estimator, IUserRole.Admin]}
                >
                    {isSaasProject(project) && isDefaultRolesUser(user) ? (
                        <Redirect to={`${url}/estimate`} />
                    ) : (
                        <ProjectEditing />
                    )}
                </ProtectedRoute>
                <ProtectedRoute
                    path={`${path}/public`}
                    allowAnonymous={true}
                    disabled={!features.shareV2}
                >
                    <SharedEstimate />
                </ProtectedRoute>
                <ProtectedRoute path={`${path}/unsubscribe`} allowAnonymous={true}>
                    <Unsubscribe />
                </ProtectedRoute>
                <ProtectedRoute path={`${path}/details`}>
                    <BuilderDetails />
                </ProtectedRoute>
                {hasEstimateFeature({ features, project, user }) && (
                    <Route path={`${path}/estimate`}>
                        <EstimationRoute />
                    </Route>
                )}
                {hasTakeoffFeature({ features, project, user }) && (
                    <Route path={`${path}/takeoff`}>
                        <EstimationRoute />
                    </Route>
                )}
                {hasProjectFeature({ project, user }) && (
                    <Route path={`${path}/`}>
                        {anonymousUser ? null : <ProjectsToolbar />}
                        <Project />
                    </Route>
                )}
                <Redirect from="*" to="/" />
            </Switch>
        </>
    );
};
