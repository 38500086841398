/**
 * Displays icon with a number on how many trades left
 */

import React, { FC } from 'react';

import { DivProps, Trade } from '@/common/types';
import { CircleIcon } from './CircleIcon';

import './MoreTradesIcon.scss';

interface MoreTradesIconProps extends DivProps {
    trades: Trade[];
    background?: string;
}

export const MoreTradesIcon: FC<MoreTradesIconProps> = ({ trades, background }) => (
    <div className="more-trades-wrapper">
        <CircleIcon className="more-trades-circle" background={background} diameter="1.75rem">
            +{trades.length}
        </CircleIcon>
        <div className="trades-tooltip">
            {trades.map((trade) => (
                <div key={trade.id}>{trade.name}</div>
            ))}
        </div>
    </div>
);
