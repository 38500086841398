import { InputProps } from '@/common/types';
import { ISourcePreviewFragment, ISourceTypeV2, useCostDataSourcesPreviewQuery } from '@/graphql';
import { UomType } from '@/queries/unitOfMeasure';
import React, { FC, useCallback, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Mode } from '@/theme/Mode';
import { compact, debounce, isString } from 'lodash';

export {
    InnerContainer,
    OptionsMenuContainer,
    OptionsMenuOptionContainer,
    OuterContainer,
} from './styled';

const COPY = {
    newElement: 'New element',
    error: 'Error',
    onSelectMaterialError: 'Failed to get information for the selected material',
    noOptions: 'There are no materials matching your search',
};

type SimpleInput = Omit<InputProps, 'onChange' | 'onSelect' | 'onBlur' | 'value'>;

export type ElementSearchV2Props = {
    uomType: UomType;
    onSelect: (value: ISourcePreviewFragment) => void;
} & SimpleInput;

const genNewMaterial = (name: string): ISourcePreviewFragment => ({
    id: undefined as unknown as string,
    name: name,
    sourceType: ISourceTypeV2.Material,
});

export const ElementSearchV2: FC<ElementSearchV2Props> = ({ placeholder, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [deboncedSearchTerm, setDeboncedSearchTerm] = useState('');

    const debounceSetDeboncedSearchTerm = useCallback(
        debounce((inputValue: string) => setDeboncedSearchTerm(inputValue), 300),
        [setDeboncedSearchTerm]
    );

    const handleInputChange = (_: unknown, newInputValue: string) => {
        setSearchTerm(newInputValue);
        debounceSetDeboncedSearchTerm(newInputValue);
    };

    const { data, loading } = useCostDataSourcesPreviewQuery({
        skip: searchTerm !== deboncedSearchTerm,
        variables: {
            input: {
                sourceType: ISourceTypeV2.Material,
                state: 'N/A',
                county: 'N/A',
                page: { limit: 20 },
                searchTerm: deboncedSearchTerm,
            },
        },
    });
    const options: Array<{ id: string; name: string; sourceType: ISourceTypeV2 }> = compact(
        data?.legacySources?.nodes
    );

    // Select an existing option.
    const onSelectOption = useCallback(
        (opt: ISourcePreviewFragment | string) => {
            if (isString(opt)) return;

            onSelect(opt);
        },
        [onSelect]
    );

    const onSelectNewOption = useCallback(
        (value: string) => onSelect(genNewMaterial(value)),
        [searchTerm, onSelect]
    );

    const open = Boolean(searchTerm || options?.length);

    return (
        <Mode variant="light">
            <Autocomplete
                fullWidth
                autoComplete
                includeInputInList
                freeSolo
                id="material-search"
                filterOptions={(x) => x}
                open={open}
                noOptionsText={COPY.noOptions}
                onChange={(_, newValue) => {
                    if (!newValue) {
                        return;
                    }

                    if (typeof newValue === 'string') {
                        onSelectNewOption(newValue);
                        return;
                    }

                    onSelectOption(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => (isString(option) ? searchTerm : option.name)}
                options={options}
                loading={loading}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus
                        placeholder={placeholder}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            componentsProps: {
                                input: {
                                    spellCheck: true,
                                },
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </Mode>
    );
};
