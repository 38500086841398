import { gql } from 'urql';

export const ProjectsWithEventsHelperQuery = gql`
    query Projects($condition: ProjectCondition) {
        projects(condition: $condition) {
            nodes {
                created
                lastModified
                id
                name
                parentProjectUuid
                uuid
                bidsDueDate
                status
                additionalInfo
                feedback
                laborPriceMarkup
                materialPriceMarkup
                equipmentPriceMarkup
                contingency
                overheadAndProfit
                estimateDueDate
                isApprovalRequired
                wageType
                subtype
                type
                isSetupCompleted
                isPriceFinal
                estimatorCostUsdCents
                estimatorHours
                teamId
                team {
                    created
                    lastModified
                    id
                    name
                    leadUserId
                    leadUser {
                        created
                        lastModified
                        id
                        firstName
                        lastName
                        authId
                        phone
                        teamId
                        projectTypes
                        businessType
                        serviceType
                        companyRole
                    }
                    balance {
                        created
                        lastModified
                        id
                        usdCents
                        teamId
                    }
                    teamFeatures {
                        nodes {
                            feature {
                                id
                                name
                                description
                            }
                        }
                    }
                    teamOnboarding {
                        accountType
                        welcomed
                    }
                }
                pricingAssignment {
                    projectPricingsByPricingAssignmentId {
                        nodes {
                            priceUsdCents
                            pricingType
                            id
                        }
                    }
                    approvedBy1Build
                    id
                }
                projectReviews {
                    nodes {
                        id
                        projectId
                        ownerId
                        rating
                        comment
                        created
                        lastModified
                        projectReviewTags {
                            nodes {
                                reviewTag {
                                    id
                                    tag
                                    isPositive
                                }
                            }
                        }
                    }
                }
                projectTrades {
                    nodes {
                        trade {
                            id
                            name
                            nickname
                            csiDivision
                            orderWeight
                            isSelectableAtProjectCreation
                        }
                    }
                }
                projectUsers {
                    nodes {
                        user {
                            created
                            lastModified
                            id
                            firstName
                            lastName
                            authId
                            phone
                            teamId
                            projectTypes
                            businessType
                            serviceType
                            companyRole
                        }
                    }
                }
                isSaas
                publicEstimationNotify
                demoProject {
                    created
                }
                events {
                    nodes {
                        created
                        lastModified
                        id
                        message
                        seen
                        eventTypeName
                        ownerId
                        owner {
                            team {
                                id
                                teamOnboarding {
                                    created
                                    accountType
                                }
                            }
                        }
                        projectId
                    }
                }
            }
        }
    }
`;
