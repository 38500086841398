/*
 * Button is a button within an admin dashboard modal.
 */
import React, { FC } from 'react';

import { ModalButton, ModalButtonText } from './styled';

import { DivProps } from '@/common/types';

export enum ButtonVariant {
    Action,
    Cancel,
}

export interface ButtonProps extends DivProps {
    text: string;
    variant: ButtonVariant;
}

export const Button: FC<ButtonProps> = ({ text, ...rest }) => (
    <ModalButton {...rest}>
        <ModalButtonText>{text}</ModalButtonText>
    </ModalButton>
);
