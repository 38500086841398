/*
    Expandable side panel that take whole page height, and can be placed on
    left, or right side of the screen. Accepts any content via children props.
*/

import React, { FC } from 'react';
import clsx from 'clsx';

import { DivProps } from '@/common/types';

import './SidePanel.scss';

type SidePanelProps = DivProps & {
    side?: 'left' | 'right';
    open: boolean | null;
    showShadow?: boolean;
};

export const SidePanel: FC<SidePanelProps> = ({
    side = 'right',
    showShadow = true,
    className,
    open,
    children,
}) => {
    const classes = clsx('side-panel', side, className, { open });

    return (
        <>
            {showShadow && <div className={clsx(classes, 'shadow')} />}
            <div className={classes}>{children}</div>
        </>
    );
};
