import React, { FC, useEffect, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import to from 'await-to-js';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Mode } from '@/theme/Mode';
import {
    ButtonContainer,
    DetailsItem,
    DetailsRow,
    ExportButton,
    LabelOption,
    LinkContainer,
    LinkCopy,
    LinkStats,
    LinkText,
    MiniSpinner,
    Section,
    SectionTitle,
    Subtitle,
} from './styled';

import { TrackEventName, track } from '@/common/analytics';
import { Env } from '@/common/env';
import { useGoogleAnalytics } from '@/common/hooks/useGoogleAnalytics';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { useShareEstimate } from '@/common/hooks/useShareEstimate';
import { ShareLinkData } from '@/common/types';
import { copyToClipboard } from '@/common/utils/clipboard';
import { RadioButton } from '@/components/ui/inputs/RadioButton';
import { useNotifications } from '@/contexts/Notifications';
import {
    ILinkType,
    useProjectUploadS3Mutation,
    useSubscribeToPublicEstimateMutation,
} from '@/graphql';

import { ReactComponent as CsvIcon } from '@/assets/icons/estimate/csv.svg';
import { ReactComponent as PdfIcon } from '@/assets/icons/estimate/pdf.svg';

const COPY = {
    header: 'Share estimate',
    subtitle:
        'Copy the public URL or download the file format of your choice. For a personalized touch,',
    subtitle_link_text: 'add your business logo and address.',
    details_title: 'How much cost detail would you like to share?',
    details_option_one_label: 'Only show totals for estimate groups.',
    details_option_two_label: 'Share all estimate details.',
    pdf: 'Download PDF',
    excel: 'Download Excel',
    public_url: 'Public URL',
    link_placeholder: 'Click "Copy" to generate the link.',
    copy: 'Copy',
    views_count_label: 'URL views',
    last_viewed_label: 'Last viewed',
    export_title: 'Export',
    upload_project_error_message: 'Error while uploading project',
    estimate_link_error_message: 'Error while generating estimate link',
    estimate_link_success_message: 'Link has been successfully generated',
    page_views_error_message: 'Error while fetching page views',
    download_file_error_message: 'Error while generating and downloading file',
    download_file_success_message: 'File has been successfully downloaded',
    download_filename: 'shared-estimate',
    xlsx_content_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf_content_type: 'application/pdf',
    send_email_notification_label: 'Send me an email when this link is viewed.',
};

const DETAILS_OPTIONS = {
    LIMITED: {
        label: 'Summary',
        value: ILinkType.Limited,
    },
    DETAILED: {
        label: 'Detailed',
        value: ILinkType.Detailed,
    },
};

export type ShareEstimateModalProps = {
    isOpen: boolean;
    close: () => void;
    isExportOnly?: boolean;
    headerText?: string;
};

export const ShareEstimateModal: FC<ShareEstimateModalProps> = ({
    isOpen,
    close,
    isExportOnly = false,
    headerText = COPY.header,
}) => {
    const { project } = useProjectFromParams();
    const { addNotification } = useNotifications();
    const {
        fetchLimitedLinkData,
        fetchDetailedLinkData,
        limitedLinkData,
        detailedLinkData,
        downloadPdf,
        downloadXslx,
        isDownloadingPdf,
        isDownloadingExcel,
    } = useShareEstimate();

    const [
        mutateProjectUploadS3Mutation,
        { loading: uploadingProject, error: errorProjectUploadingToS3 },
    ] = useProjectUploadS3Mutation();

    const [subscribeToPublicEstimation] = useSubscribeToPublicEstimateMutation();

    const [hasSubscription, setHasSubscription] = useState(false);
    const [selectedDetailsOption, setSelectedDetailsOption] = useState(
        isExportOnly ? DETAILS_OPTIONS.DETAILED : DETAILS_OPTIONS.LIMITED
    );

    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [isCopyLinkClicked, setCopyLinkClicked] = useState(false);
    const { fetchPageViewsData, viewAnalyticsData, errorFetchingPageViews } = useGoogleAnalytics();

    const projectId = project?.id.toString() || '';

    const [shareLinkData, setShareLinkData] = useState<ShareLinkData>({
        publicLink: '',
        slug: '',
        pageViews: 0,
        isPageAnalyticsVisible: false,
        lastViewed: undefined,
    });

    const showPageAnalyticsData =
        shareLinkData.isPageAnalyticsVisible && shareLinkData.publicLink !== '';

    const renderLimitedOption = (): JSX.Element => (
        <LabelOption>
            <span>{DETAILS_OPTIONS.LIMITED.label}. </span>
            {COPY.details_option_one_label}
        </LabelOption>
    );

    const renderDetailedOption = (): JSX.Element => (
        <LabelOption>
            <span>{DETAILS_OPTIONS.DETAILED.label}. </span>
            {COPY.details_option_two_label}
        </LabelOption>
    );

    const handleSetShareLinkData = () => {
        const { value: selectedOption } = selectedDetailsOption;

        const generatedData = generateLinkData(selectedOption);

        setShareLinkData({
            publicLink: generatedData?.generatedPublicLink ?? '',
            isPageAnalyticsVisible: true,
            slug: generatedData?.slug ?? '',
            pageViews: viewAnalyticsData?.viewAnalytics?.views ?? 0,
            lastViewed: (viewAnalyticsData?.viewAnalytics?.lastViewed as string) ?? undefined,
        });

        handleFetchPageViews(generatedData?.slug);
    };

    const generateLinkData = (
        selectedType: ILinkType
    ): { slug: string; generatedPublicLink: string } | undefined => {
        if (!project) return;

        const linkData = selectedType === ILinkType.Limited ? limitedLinkData : detailedLinkData;

        if (!linkData) return;

        const generatedLink = `${Env.deploymentURL}/projects/${project.uuid}/public/${linkData}`;
        const generatedSlug = `/projects/${project.uuid}/public/${linkData}`;

        return {
            slug: generatedSlug,
            generatedPublicLink: generatedLink,
        };
    };

    const handleOnCopyLinkClick = (selectedOption: ILinkType) => {
        const { publicLink, slug } = shareLinkData;
        if (publicLink && slug !== '') {
            copyToClipboard(publicLink);
            setIsLinkCopied(true);
            track(TrackEventName.EstimateLinkCopied);
        } else {
            handleUploadProject()
                .then((isUploaded: boolean) => {
                    if (!isUploaded) {
                        return;
                    } else {
                        if (!project) return;

                        const fetchShareLinkData =
                            selectedOption === ILinkType.Limited
                                ? fetchLimitedLinkData
                                : fetchDetailedLinkData;

                        fetchShareLinkData(projectId);
                        setCopyLinkClicked(true);
                    }
                })
                .catch(() => {
                    addNotification(
                        {
                            title: 'Error',
                            content: <p>{COPY.estimate_link_error_message}</p>,
                        },
                        'error'
                    );
                });
        }
    };

    const handleUploadProject = async (): Promise<boolean> => {
        try {
            const response = await mutateProjectUploadS3Mutation({
                variables: {
                    input: {
                        id: projectId,
                        type: selectedDetailsOption.value,
                    },
                },
            });

            if (errorProjectUploadingToS3) {
                addNotification(
                    {
                        title: 'Error',
                        content: <p>{COPY.upload_project_error_message}</p>,
                    },
                    'error'
                );
            }

            return response.data?.projectUploadS3 || false;
        } catch {
            addNotification(
                {
                    title: 'Error',
                    content: <p>{COPY.upload_project_error_message}</p>,
                },
                'error'
            );
        }

        return false;
    };

    const handleFetchPageViews = (slug: string | undefined) => {
        try {
            if (!project || !slug) return;

            fetchPageViewsData({
                slug,
            });

            if (errorFetchingPageViews) {
                addNotification(
                    {
                        title: 'Error',
                        content: <p>{errorFetchingPageViews?.message.toString()}</p>,
                    },
                    'error'
                );
            }
        } catch {
            addNotification(
                {
                    title: 'Error',
                    content: <p>{COPY.page_views_error_message}</p>,
                },
                'error'
            );
        }
    };

    const handleDownloadPdf = async () => {
        if (!project) return;

        try {
            await downloadPdf(project.id.toString(), selectedDetailsOption.value);

            addNotification(
                { title: 'Success', content: COPY.download_file_success_message },
                'success'
            );
            track(TrackEventName.PdfExportSelected);
        } catch {
            addNotification({ title: 'Error', content: COPY.download_file_error_message }, 'error');
        }
    };

    const handleDownloadXlsx = async () => {
        if (!project) return;

        try {
            await downloadXslx(project.id.toString(), selectedDetailsOption.value);

            addNotification(
                { title: 'Success', content: COPY.download_file_success_message },
                'success'
            );
            track(TrackEventName.ExcelExportSelected);
        } catch {
            addNotification({ title: 'Error', content: COPY.download_file_error_message }, 'error');
        }
    };

    const handleSubscriptionToPublicEstimate = async (isSubscribed: boolean): Promise<void> => {
        if (!project) return;

        const [error] = await to(
            subscribeToPublicEstimation({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        subscribe: isSubscribed,
                    },
                },
            })
        );

        if (error) {
            addNotification({ title: 'Error', content: error.message.toString() }, 'error');
        }
    };

    const renderCopyButtonText = (): JSX.Element | string => {
        return uploadingProject ? <MiniSpinner /> : isLinkCopied ? 'Copied' : COPY.copy;
    };

    const onRadioButtonChange = (options: { label: string; value: ILinkType }): void => {
        setSelectedDetailsOption(options);
        setIsLinkCopied(false);
        setCopyLinkClicked(false);
    };

    const onCopyClick = () => {
        handleOnCopyLinkClick(selectedDetailsOption.value);
    };

    const handleOnCheckSubscription = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasSubscription(event.target.checked);
        await handleSubscriptionToPublicEstimate(!hasSubscription);
    };

    const renderLastViewInfo = (date: string): string =>
        `${date ? ` ${moment().diff(date, 'days').toString()}` : 0} days ago`;

    // Initial data fetch if it exists
    useEffect(() => {
        if (!project || isExportOnly || projectId === '-1') return;

        fetchLimitedLinkData(projectId);
        fetchDetailedLinkData(projectId);
        setHasSubscription(project.publicEstimationNotify);
    }, [project]);

    // Fetch and set share link data
    useEffect(() => {
        if (!project || isExportOnly) return;

        handleSetShareLinkData();
    }, [limitedLinkData, detailedLinkData, selectedDetailsOption.value]);

    useEffect(() => {
        if (shareLinkData.publicLink !== '' && isCopyLinkClicked) {
            copyToClipboard(shareLinkData.publicLink);
            setIsLinkCopied(true);
            setCopyLinkClicked(false);
        }
    }, [isCopyLinkClicked, shareLinkData.publicLink]);

    useEffect(() => {
        setTimeout((): void => setIsLinkCopied(false), 2000);

        return (): void => clearTimeout();
    }, [isLinkCopied]);

    return (
        <Mode variant="light">
            <Dialog fullWidth open={isOpen} onClose={close} maxWidth="xs">
                <DialogTitle>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="h3"
                    >
                        {headerText}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {!isExportOnly && (
                        <>
                            <Subtitle>
                                {COPY.subtitle}{' '}
                                <Link to={'/profile'}>{COPY.subtitle_link_text}</Link>
                            </Subtitle>

                            <Section>
                                <SectionTitle>{COPY.details_title}</SectionTitle>
                                <DetailsRow>
                                    <DetailsItem>
                                        <RadioButton
                                            label={renderLimitedOption()}
                                            name="detailsOption"
                                            value={DETAILS_OPTIONS.LIMITED.value}
                                            checked={
                                                selectedDetailsOption === DETAILS_OPTIONS.LIMITED
                                            }
                                            onChange={() =>
                                                onRadioButtonChange(DETAILS_OPTIONS.LIMITED)
                                            }
                                        />
                                    </DetailsItem>
                                    <DetailsItem>
                                        <RadioButton
                                            label={renderDetailedOption()}
                                            name="detailsOption"
                                            value={DETAILS_OPTIONS.DETAILED.value}
                                            checked={
                                                selectedDetailsOption === DETAILS_OPTIONS.DETAILED
                                            }
                                            onChange={() =>
                                                onRadioButtonChange(DETAILS_OPTIONS.DETAILED)
                                            }
                                        />
                                    </DetailsItem>
                                </DetailsRow>
                            </Section>

                            <Section>
                                <SectionTitle>{COPY.public_url}</SectionTitle>
                                <LinkContainer>
                                    <LinkText>
                                        <p>{shareLinkData.publicLink || COPY.link_placeholder}</p>
                                    </LinkText>
                                    <LinkCopy onClick={onCopyClick}>
                                        {renderCopyButtonText()}
                                    </LinkCopy>
                                </LinkContainer>

                                <LinkStats visible={showPageAnalyticsData}>
                                    <span>
                                        URL views:{' '}
                                        <strong>
                                            {viewAnalyticsData?.viewAnalytics?.views || 0}
                                        </strong>
                                    </span>
                                    <span>
                                        Last viewed:
                                        <strong>
                                            {' '}
                                            {renderLastViewInfo(
                                                viewAnalyticsData?.viewAnalytics?.lastViewed
                                            )}
                                        </strong>
                                    </span>
                                </LinkStats>
                            </Section>
                        </>
                    )}

                    <Section>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasSubscription}
                                        onChange={handleOnCheckSubscription}
                                        inputProps={{ 'aria-label': 'subscription-checkpoint' }}
                                        color="info"
                                    />
                                }
                                label={COPY.send_email_notification_label}
                            />
                        </FormGroup>
                    </Section>
                    <Section>
                        {!isExportOnly && <SectionTitle>{COPY.export_title}</SectionTitle>}
                        <ButtonContainer>
                            <ExportButton
                                onClick={() => handleDownloadPdf()}
                                loading={isDownloadingPdf}
                            >
                                {!isDownloadingPdf ? (
                                    <>
                                        <PdfIcon />
                                        <span>{COPY.pdf}</span>
                                    </>
                                ) : (
                                    <MiniSpinner />
                                )}
                            </ExportButton>
                            <ExportButton
                                onClick={() => handleDownloadXlsx()}
                                loading={isDownloadingExcel}
                            >
                                {!isDownloadingExcel ? (
                                    <>
                                        <CsvIcon />
                                        <span>{COPY.excel}</span>
                                    </>
                                ) : (
                                    <MiniSpinner />
                                )}
                            </ExportButton>
                        </ButtonContainer>
                    </Section>
                </DialogContent>
            </Dialog>
        </Mode>
    );
};
