import {
    editedGeometryObservable,
    optionalGeometriesAreEqual,
} from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometryIdsObservable,
    selectedPointIDsObservable,
} from '@/components/takeoff/observables/interface';
import { asyncScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

export enum PointSelectionState {
    Unselected,
    ParentSelected,
    PointSelected,
}

export const newGeometryObservable = (geometryID: string) =>
    geometriesObservable.pipe(
        throttleTime(33, asyncScheduler, { leading: true, trailing: true }),
        map((geometries) => {
            return geometries?.find((geometry) => geometry.uuid === geometryID);
        }),
        distinctUntilChanged(optionalGeometriesAreEqual)
    );

export const newGeometryIsEditableObservable = (geometryID: string) =>
    editedGeometryObservable.pipe(
        map((editedGeometry) => editedGeometry !== null && editedGeometry.uuid === geometryID),
        distinctUntilChanged()
    );

export const newGeometryIsSelectedObservable = (geometryID: string) =>
    selectedGeometryIdsObservable.pipe(
        map((selectedGeometryIDs) => selectedGeometryIDs.has(geometryID)),
        distinctUntilChanged()
    );

export const newPointIsSelectedObservable = (pointID: string) =>
    selectedPointIDsObservable.pipe(
        map((selectedPointIDs) => selectedPointIDs.has(pointID)),
        distinctUntilChanged()
    );

export const newPointSelectionStateObservable = (pointID: string, countID: string) =>
    combineLatest([
        newGeometryIsSelectedObservable(countID),
        newPointIsSelectedObservable(pointID),
    ]).pipe(
        map(([countIsSelected, pointIsSelected]) => {
            if (countIsSelected) return PointSelectionState.ParentSelected;
            if (pointIsSelected) return PointSelectionState.PointSelected;
            return PointSelectionState.Unselected;
        }),
        distinctUntilChanged()
    );
