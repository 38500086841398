import React, { FC } from 'react';

import { NameContainer, NameContentContainer, Title, Name } from './styled';

import { Avatar } from '@/components/ui/icons/Avatar';
import { IUserFragment } from '@/graphql';

type NameCardProps = {
    user?: IUserFragment;
    title?: string;
};

export const NameCard: FC<NameCardProps> = ({ user, title }) => {
    const name = user && [user.firstName, user.lastName].join(' ');

    return (
        <NameContainer>
            <Avatar diameter="1.5rem" user={user} />
            <NameContentContainer>
                <Title>{title}</Title>
                <Name>{name}</Name>
            </NameContentContainer>
        </NameContainer>
    );
};
