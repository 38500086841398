import { useEffect, useState } from 'react';

import { Hook } from '@/common/types';
import { PriceMarkup } from '@/components/Estimate/utils/types';
import {
    IProjectMarkupFragment,
    useProjectMarkupAssignmentMutation,
    useProjectMarkupsByProjectIdQuery,
} from '@/graphql';

export interface UseMarkupsRes {
    markups: IProjectMarkupFragment[];
    saveMarkup: (markup: PriceMarkup) => void;
    loading: boolean;
}

export interface UseMarkupsProps {
    projectId?: number;
}

export const useMarkups: Hook<UseMarkupsRes, UseMarkupsProps> = ({ projectId }) => {
    const [markups, setMarkups] = useState<IProjectMarkupFragment[]>([]);

    // Queries
    const { data, loading } = useProjectMarkupsByProjectIdQuery({
        variables: {
            input: {
                id: projectId?.toString() ?? '',
            },
        },
        skip: !projectId || projectId <= 0,
    });

    const [projectMarkupAssignmentMutation] = useProjectMarkupAssignmentMutation();

    useEffect(() => {
        const newProjectMarkups = data?.projectOld.markups?.edges?.map((e) => e.node) ?? [];
        setMarkups(newProjectMarkups);
    }, [data]);

    const saveMarkup = (markup: PriceMarkup): void => {
        if (!projectId) {
            return;
        }
        projectMarkupAssignmentMutation({
            variables: {
                input: {
                    id: markup.id,
                    projectID: projectId.toString(),
                    description: markup.description,
                    percentage: Number(markup.percentage),
                    total: Number(markup.total),
                },
            },
        });
    };

    return {
        markups,
        saveMarkup,
        loading,
    };
};
