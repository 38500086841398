import { usePublish } from './usePublish';
import {
    AcceptanceDetails,
    DatabaseProjectStatus,
    DeclineDetails,
    PricingStatus,
    Setter,
    StripeErrorMessageType,
} from '@/common/types';
import { formatUSD, mapPricingStatus } from '@/common/utils/helpers';
import { Bugsnag } from '@/components/app/Bugsnag';
import { ErrorMessage } from '@/components/notifications/Notification';
import { ProjectDetailsComponentProps } from '@/components/projects/ProjectDetails/context';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import {
    IRefusalReasons,
    useAcceptProjectPricingMutation,
    useProjectAcceptanceHistoryAssignmentMutation,
    useProjectEstimateSubmissionMutation,
    useProjectAcceptanceWithQuoteMutation,
    IEstimatorQuoteInput,
    IProjectAcceptanceWithQuoteMutation,
} from '@/graphql';
import { AssignEstimatorResult, unassignEstimatorMutation } from '@/mutations/assignEstimator';
import { useCreateEvent } from '@/mutations/event';
import {
    UpdateProjectStatusResult,
    updateProjectStatusMutation,
} from '@/mutations/updateProjectStatus';
import { EventTypeName } from '@/queries/eventTypes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';
import { v4 as uuid } from 'uuid';
import to from 'await-to-js';
import { FetchResult } from '@apollo/client/core';

const COPY = {
    details: 'Details',
    submitProjectErrorTitle: 'Project submission failed',
    submitProjectErrorMessage: 'try again',
    submitProjectSuccessTitle: 'Project submitted for review',
    submitProjectSuccessMessage:
        'We’ll review the submission and let you know if we have any feedback',
    unassignmentErrorTitle: 'Error unassigning estimator',
    unassignmentSuccessTitle: 'Successfully unassigned estimator',
    declineProjectSuccessTitle: 'Project declined.',
    approveProjectPriceMessage: 'Project price has been successfully approved.',
    declineProjectPriceMessage: 'Project price has been successfully declined.',
    projectPriceErrorMessage: 'Something went wrong. Please try again.',
};

type UseActionsArguments = ProjectDetailsComponentProps & {
    setEstimatorAssignmentPanelOpen?: Setter<boolean>;
    setFeedbackPanelOpen?: Setter<boolean>;
    setIsModalVisible?: Setter<boolean>;
    setRevisionPanelOpen?: Setter<boolean>;
    setIsLocationPromptOpen?: Setter<boolean>;
    setIsAcceptanceModalVisible?: Setter<boolean>;
    setIsAcceptQuoteModalVisible?: Setter<boolean>;
    setIsDeclineModalVisible?: Setter<boolean>;
    setIsExportFilesModalVisible?: Setter<boolean>;
    setIsPublishModalVisible?: Setter<boolean>;
    setIsShareEstimateModalVisible?: Setter<boolean>;
    setIsStripeErrorModalVisible?: Setter<boolean>;
    setStripeErrorMessageUrl?: Setter<string>;
    // # TODO => this should be replaced with a callback
    uploadRef: React.RefObject<HTMLInputElement>;
};
export type UseActionsPayload = {
    accept: (acceptDetails?: AcceptanceDetails) => void;
    sendQuote: (
        quoteDetails: IEstimatorQuoteInput
    ) => Promise<FetchResult<IProjectAcceptanceWithQuoteMutation, Record<string, string>>>;
    addFeedback: () => void;
    assignEstimator: () => void;
    cancel: () => void;
    decline: (declineDetails?: DeclineDetails) => void;
    downloadEstimate: () => Promise<void>;
    navigateToTakeoff: () => void;
    navigateToEstimate: () => void;
    promptUpload: () => void;
    publish: () => void;
    requestRevision: () => void;
    submit: () => void;
    toggleModal: () => void;
    toggleAcceptanceModal: () => void;
    toggleAcceptQuoteModal: () => void;
    togglePublishModal: () => void;
    toggleDeclineModal: () => void;
    toggleExportFilesModal: () => void;
    toggleShareEstimateModal: () => void;
    toggleStripeErrorModal: () => void;
    handleSetStripeErrorMessageUrl: (message: string) => void;
    unpublish: () => void;
    uploadEstimate: () => void;
    unassignEstimator: () => void;
    onPriceApprove: () => Promise<void>;
    isOnPriceApproveLoading: boolean;
    onPriceDecline: () => Promise<void>;
    isOnPriceDeclineLoading: boolean;
};

export const useActions = ({
    useProjectDetails,

    setEstimatorAssignmentPanelOpen,
    setFeedbackPanelOpen,
    setIsModalVisible,
    setRevisionPanelOpen,
    setIsLocationPromptOpen,
    setIsAcceptanceModalVisible,
    setIsAcceptQuoteModalVisible,
    setIsPublishModalVisible,
    setIsDeclineModalVisible,
    setIsExportFilesModalVisible,
    setIsShareEstimateModalVisible,
    setIsStripeErrorModalVisible,
    setStripeErrorMessageUrl,

    uploadRef,
}: UseActionsArguments): UseActionsPayload => {
    //////////////
    // Contexts //
    //////////////
    const {
        assignedTo,
        createEstimate,
        handleDownloadAll,
        project,
        projectLocation,
        setPricingStatus,
        declinePriceFeedback,
        toggleApproveProjectPriceModal,
        toggleDeclineProjectPriceModal,
        pricingAssignment,
    } = useProjectDetails();
    const history = useHistory();
    const { addNotification } = useNotifications();
    const {
        data: {
            user: { id: userId, firstName, lastName },
        },
        actions: { refetchUser },
    } = useUser();
    const { publish, unpublish } = usePublish({ project });

    const priceUsdCents = pricingAssignment?.priceUsdCents;
    const userFullName = `${firstName} ${lastName}`;

    ///////////////
    // Mutations //
    ///////////////
    const [, createEvent] = useCreateEvent();
    const [projectAcceptanceHistoryAssignment] = useProjectAcceptanceHistoryAssignmentMutation();
    const [projectAcceptanceWithQuote] = useProjectAcceptanceWithQuoteMutation();
    const [, unassignEstimatorToProject] =
        useMutation<AssignEstimatorResult>(unassignEstimatorMutation);
    const [projectEstimateSubmission] = useProjectEstimateSubmissionMutation();
    const [, updateProjectStatus] = useMutation<UpdateProjectStatusResult>(
        updateProjectStatusMutation
    );
    const [mutateAcceptProjectPricingMutation, { loading }] = useAcceptProjectPricingMutation();

    ////////////////
    // One-liners //
    ////////////////
    const addFeedback = (): void => setFeedbackPanelOpen?.(true);
    const navigateToTakeoff = (): void => history.push(`/projects/${project.uuid}/takeoff`);
    const navigateToEstimate = (): void => history.push(`/projects/${project.uuid}/estimate`);
    const promptUpload = (): void => uploadRef.current?.click();
    const requestRevision = (): void => setRevisionPanelOpen?.(true);
    const toggleModal = (): void => setIsModalVisible?.((isModalVisible) => !isModalVisible);
    const toggleAcceptQuoteModal = (): void =>
        setIsAcceptQuoteModalVisible?.((isAcceptQuoteModalVisible) => !isAcceptQuoteModalVisible);
    const toggleAcceptanceModal = (): void =>
        setIsAcceptanceModalVisible?.((isAcceptanceModalVisible) => !isAcceptanceModalVisible);
    const togglePublishModal = (): void =>
        setIsPublishModalVisible?.((isPublishModalVisible) => !isPublishModalVisible);
    const toggleDeclineModal = (): void =>
        setIsDeclineModalVisible?.((isDeclineModalVisible) => !isDeclineModalVisible);
    const toggleExportFilesModal = (): void =>
        setIsExportFilesModalVisible?.((isExportFilesModalVisible) => !isExportFilesModalVisible);
    const toggleShareEstimateModal = (): void =>
        setIsShareEstimateModalVisible?.(
            (isShareEstimateModalVisible) => !isShareEstimateModalVisible
        );
    const toggleStripeErrorModal = (): void =>
        setIsStripeErrorModalVisible?.((isModalVisible) => !isModalVisible);
    const handleSetStripeErrorMessageUrl = (url: string): void => {
        setStripeErrorMessageUrl?.(url);
    };
    /////////////////
    // Big boys 😳 //
    /////////////////
    const assignEstimator = async (): Promise<void> => {
        if (assignedTo) {
            try {
                await unassignEstimator();
                setEstimatorAssignmentPanelOpen?.(true);
            } catch (e) {
                Bugsnag?.notify(String(e));
                addNotification(
                    {
                        title: COPY.unassignmentErrorTitle,
                        content: JSON.stringify(e),
                    },
                    'error'
                );
            }
        } else {
            setEstimatorAssignmentPanelOpen?.(true);
        }
    };

    const cancel = async (): Promise<void> => {
        await updateProjectStatus({
            projectId: project.id,
            status: DatabaseProjectStatus.CANCELED,
        });
        await createEvent({
            eventType: EventTypeName.CancelProject,
            message: `${userFullName} canceled "${project.name ?? ''}"`,
            ownerId: Number(userId),
            projectId: Number(project.id),
        });
        toggleModal();
    };

    const accept = (acceptanceDetails?: AcceptanceDetails): void => {
        if (acceptanceDetails) {
            projectAcceptanceHistoryAssignment({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        userID: userId,
                        accepted: true,
                        excludedTrades: acceptanceDetails.excludedTrades,
                        startBy: acceptanceDetails.projectStartDate.concat('T00:00:00Z'),
                        finishBy: acceptanceDetails.projectCompletionDate.concat('T00:00:00Z'),
                    },
                },
            });
        }
    };

    const sendQuote = (
        quoteDetails: IEstimatorQuoteInput
    ): Promise<FetchResult<IProjectAcceptanceWithQuoteMutation, Record<string, string>>> =>
        projectAcceptanceWithQuote({ variables: { input: quoteDetails } });

    const decline = async (declineDetails?: DeclineDetails): Promise<void> => {
        if (declineDetails) {
            await projectAcceptanceHistoryAssignment({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        userID: userId,
                        accepted: false,
                        expectedDuration: declineDetails.expectedDuration,
                        expectedDurationSummary: declineDetails.expectedDurationSummary,
                        refusalSummary: declineDetails.refusalSummary,
                        refusalReason: declineDetails.otherReasonSelected
                            ? IRefusalReasons.Other
                            : IRefusalReasons.NotEnoughHours,
                    },
                },
            });
        }
        history.push('/projects');

        addNotification(
            {
                title: COPY.declineProjectSuccessTitle,
                content: <p>{`The project "${project.name ?? ''}" has been declined.`}</p>,
            },
            'success'
        );
    };

    const downloadEstimate = async (): Promise<void> => {
        handleDownloadAll(project, true);
        await createEvent({
            message: 'Download Estimate',
            eventType: 'download_estimate',
            ownerId: Number(userId),
            projectId: project.id,
        });
    };

    const submit = async (): Promise<void> => {
        if (project.isSaas || projectLocation?.formattedAddress) {
            try {
                const response = await projectEstimateSubmission({
                    variables: {
                        input: { projectID: project.id.toString(), ownerID: userId },
                    },
                });
                if (response.errors) {
                    addNotification(
                        {
                            title: COPY.submitProjectErrorTitle,
                            content: (
                                <ErrorMessage
                                    message={COPY.submitProjectErrorMessage}
                                    handleClick={submit}
                                />
                            ),
                        },
                        'error'
                    );
                } else {
                    addNotification(
                        {
                            title: COPY.submitProjectSuccessTitle,
                            content: <p>{COPY.submitProjectSuccessMessage}</p>,
                        },
                        'success'
                    );
                }
            } catch {
                addNotification(
                    {
                        title: COPY.submitProjectErrorTitle,
                        content: (
                            <ErrorMessage
                                message={COPY.submitProjectErrorMessage}
                                handleClick={submit}
                            />
                        ),
                    },
                    'error'
                );
            }
        } else {
            setIsLocationPromptOpen?.(true);
        }
    };

    const unassignEstimator = async (): Promise<void> => {
        const estimatorId = assignedTo?.id;
        const estimatorName = `${assignedTo?.firstName ?? ''} ${assignedTo?.lastName ?? ''}`;
        if (estimatorId === undefined) {
            throw new Error(
                `Failed to unassign estimator from project with id ${project.id}: no assigned estimator`
            );
        }

        const response = await unassignEstimatorToProject({
            projectId: project.id,
            ownerId: userId,
            userId: estimatorId,
        });

        if (response.error) {
            throw response.error.message;
        }
        addNotification({ title: `${COPY.unassignmentSuccessTitle}: ${estimatorName}` }, 'success');
    };

    const uploadEstimate = (): void => {
        const files = Array.from(uploadRef?.current?.files || []);
        files.forEach((file) => {
            createEstimate(userId, project, file, uuid());
        });
    };

    const mapStripeErrorMessage = (message: string): StripeErrorMessageType | undefined => {
        try {
            const errorMessageObject = JSON.parse(message) as StripeErrorMessageType;
            return errorMessageObject;
        } catch (e) {
            addNotification(
                { title: 'Error', content: COPY.projectPriceErrorMessage },
                'error',
                true
            );
        }
    };

    const onPriceApprove = async (): Promise<void> => {
        const status = mapPricingStatus(project.status);

        const [error] = await to(
            mutateAcceptProjectPricingMutation({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        accepted: true,
                    },
                },
            })
        );

        if (error) {
            const stripeErrorMessage = mapStripeErrorMessage(error.message);

            if (stripeErrorMessage) {
                toggleApproveProjectPriceModal();
                toggleStripeErrorModal();
                if (
                    stripeErrorMessage.message !==
                    'Cannot charge a customer that has no active card'
                ) {
                    handleSetStripeErrorMessageUrl(stripeErrorMessage.url);
                }
            }
        } else {
            toggleApproveProjectPriceModal();
            setPricingStatus(status || PricingStatus.FINAL);

            addNotification(
                { title: 'Success', content: COPY.approveProjectPriceMessage },
                'success',
                true
            );

            createEvent({
                eventType: EventTypeName.EditProject,
                message: `${userFullName} approved the project price of ${formatUSD(
                    priceUsdCents ?? 0
                )}`,
                ownerId: Number(userId),
                projectId: Number(project.id),
            });

            refetchUser();
        }
    };

    const onPriceDecline = async (): Promise<void> => {
        try {
            await mutateAcceptProjectPricingMutation({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        accepted: false,
                        summary: declinePriceFeedback,
                    },
                },
            });

            toggleDeclineProjectPriceModal();
            setPricingStatus(PricingStatus.DECLINED);

            addNotification(
                { title: 'Success', content: COPY.declineProjectPriceMessage },
                'success',
                true
            );

            createEvent({
                eventType: EventTypeName.EditProject,
                message: `${userFullName} declined the project price of ${formatUSD(
                    priceUsdCents ?? 0
                )}
                for the following reason: ${declinePriceFeedback}`,
                ownerId: Number(userId),
                projectId: Number(project.id),
            });

            refetchUser();
        } catch {
            addNotification(
                { title: 'Error', content: COPY.projectPriceErrorMessage },
                'error',
                true
            );
        }
    };

    return {
        accept,
        sendQuote,
        addFeedback,
        assignEstimator,
        cancel,
        decline,
        downloadEstimate,
        navigateToTakeoff,
        navigateToEstimate,
        promptUpload,
        publish,
        requestRevision,
        submit,
        toggleModal,
        toggleAcceptanceModal,
        toggleAcceptQuoteModal,
        togglePublishModal,
        toggleDeclineModal,
        toggleExportFilesModal,
        toggleShareEstimateModal,
        toggleStripeErrorModal,
        handleSetStripeErrorMessageUrl,
        unpublish,
        uploadEstimate,
        unassignEstimator,
        onPriceApprove,
        isOnPriceApproveLoading: loading,
        onPriceDecline,
        isOnPriceDeclineLoading: loading,
    };
};
