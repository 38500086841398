import { fixedGradientBackground } from '@/common/styles/fixedGradientBackground';
import { breakpoints } from '@/common/styles/helpers/breakpoints';
import { SidePanel } from '@/components/ui/controls/SidePanel';
import styled from 'styled-components';

export const variables = {
    containerBackground: '#060F3E',
    containerBackgroundMobile:
        'linear-gradient(134.71deg, #32406C 1.82%, #1B2235 60.37%, #18213E 74.87%)',
    mobileSidePanelBackground: '#212a4c',
};

export const SidePanelV2 = styled(SidePanel)`
    ${fixedGradientBackground}

    overflow-x: hidden;

    ${breakpoints.small`
        background: ${variables.mobileSidePanelBackground};

        &.left {
            box-sizing: border-box;
            padding-top: 4rem;

            transform: translateY(-100%) translateX(0);
            z-index: 4;
        }

        &.open {
            transform: translateY(0) translateX(0);
        }
    `}
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ConfirmationButtons = styled.div`
    margin-top: 1.25rem;

    button {
        margin-right: 0.625rem;
    }
`;

export const DeclineFeedback = styled.div`
    span {
        display: block;
        margin-bottom: 0.625rem;
    }

    textarea {
        min-height: 8.125rem;
        background: #f6f8f8;
        color: #5f6472;
        margin-bottom: 1.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        border: none;

        &:focus {
            border: 2px solid #3d445c;
        }
    }
`;

export const PricingInfoLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;

    font-size: 0.875rem;
    font-weight: bold;
    white-space: nowrap;

    border-radius: 0.25rem;
    box-sizing: border-box;

    background-color: #2b365e;

    padding: 0 0.9375rem;

    cursor: pointer;
`;
