/*
 * CostInfo manages estimator hours and cost within the price module.
 */
import React, { FC } from 'react';

import { InfoInput } from './InfoInput';
import { CostInfoContainer, CostInfoHeader } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { FlatProjectRecord, executeUpdateProjectMutation } from '@/mutations/project';

const COPY = {
    costInfo: 'Cost info',
    estimatorCostLabel: 'ESTIMATOR COST',
    estimatorCostPlaceholder: 'Enter cost',
    estimatorHoursLabel: 'ESTIMATOR HOURS',
    estimatorHoursPlaceholder: 'Enter hours',
};

export const CostInfo: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { project } = useProjectPanel();

    const updateProject = async (patch: Partial<FlatProjectRecord>): Promise<void> => {
        const { error } = await executeUpdateProjectMutation({
            input: {
                id: project.id,
                patch,
            },
        });
        if (error) {
            throw error;
        }
    };

    const updateCost = (estimatorCostUsdCents: number): Promise<void> =>
        updateProject({ estimatorCostUsdCents });

    const updateHours = (estimatorHours: number): Promise<void> =>
        updateProject({ estimatorHours });

    return (
        <CostInfoContainer>
            <CostInfoHeader>{COPY.costInfo}</CostInfoHeader>
            <InfoInput
                label={COPY.estimatorHoursLabel}
                value={project.estimatorHours}
                placeholder={COPY.estimatorHoursPlaceholder}
                persistValue={updateHours}
            />
            <InfoInput
                label={COPY.estimatorCostLabel}
                value={project.estimatorCostUsdCents}
                placeholder={COPY.estimatorCostPlaceholder}
                persistValue={updateCost}
                isCents={true}
            />
        </CostInfoContainer>
    );
};
