import { useApolloClient } from '@apollo/client';
import { LeydenEditor, Table } from 'leyden';
import { CellRenderer } from 'leyden-react';
import React, {
    ChangeEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelected, useSlateStatic } from 'slate-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
    ActionButton,
    ActionButtonsContainer,
    ActionTextInput,
    ActionTextInputWrapper,
    ElementInput,
    ElementInputWrapper,
} from './styled';
import { Cell } from '../components/Cell';
import { useEstimateBuffer } from '../../hooks/useEstimateBuffer';
import { useEstimateUoms } from '../../hooks/useEstimateUoms';
import { useEstimateProject } from '../../hooks/useEstimateProject';
import { Transforms } from '../../transforms';
import { UomType } from '@/queries/unitOfMeasure';
import { useUser } from '@/contexts/User';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { useCellDialog } from '@/components/Estimate/Table/contexts/useCellDialog';
import { ElementSearchV2 } from '@/components/Estimate/ElementSearch/ElementSearchV2';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { IMaterialLightFragment, ISourcePreviewFragment } from '@/graphql';
import { findLocalizedSourceV2 } from '@/components/Estimate/utils/requests';
import { getUomId } from '@/components/AssemblyPanel/hooks/uomMapping';
import { useNotifications } from '@/contexts/Notifications';
import { isNil } from 'lodash';

type AddingType = 'group' | 'item';

interface Adding {
    type: 'group' | 'item';
    value: string;
}

export const Actions: CellRenderer<'Actions'> = ({ attributes, children, element: cell }) => {
    const { bufferHovered } = useEstimateBuffer();
    const { projectID } = useEstimateProject();
    const breakpoints = useBreakpoints();
    const { openCellDialog } = useCellDialog();
    const { addNotification } = useNotifications();

    const { localizationCounty, localizationState } = useAssemblyPanel();
    const state = localizationState?.localization;
    const county = localizationCounty?.localization;

    const {
        data: { anonymousUser },
    } = useUser();
    const client = useApolloClient();
    const { defaultUomID } = useEstimateUoms();
    const editor = useSlateStatic();
    const selected = useSelected();
    const flags = useFlags();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [cellHovered, setCellHovered] = useState(false);
    const [adding, setAdding] = useState<Adding | null>(null);

    useEffect(() => {
        if (!selected) {
            stopAdding();
        }
    }, [selected]);

    const isOnlyRow = Table.dimensions(LeydenEditor.table(editor)).rows === 1;

    type StartAdding = (type: AddingType) => MouseEventHandler<HTMLButtonElement>;
    const startAdding: StartAdding = (type: AddingType) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (breakpoints.mobile && flags.mobileAssemblyPanel) {
            openCellDialog(cell, {
                explicitCellType: type,
            });
        } else {
            setAdding({ type, value: '' });
        }
    };

    const stopAdding = (e?: React.FocusEvent): void => {
        if (e?.defaultPrevented) return;
        setAdding(null);
    };

    const handleInputTextBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        if (e.defaultPrevented || adding === null || adding.value === '') {
            return;
        }
        if (adding.type === 'group') {
            Transforms.createCategory(editor, adding.value, projectID.toString(), cell, client);
        }

        setAdding(null);
        Transforms.deselect(editor);
    };

    const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAdding((oldAdding) => {
            if (oldAdding === null) {
                return null;
            }
            return {
                ...oldAdding,
                value: e.target.value,
            };
        });
    };

    const handleTextKeyDownGroup: KeyboardEventHandler = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        if (e.key === 'Escape') {
            stopAdding();
            Transforms.deselect(editor);
            return;
        }
        if (adding === null || adding.value === '') {
            return;
        }
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();

            setAdding(null);
            Transforms.createCategory(editor, adding.value, projectID.toString(), cell, client);
            Transforms.deselect(editor);
            return;
        }
    };

    const handleTextKeyDownElement: KeyboardEventHandler = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        if (e.key === 'Escape') {
            stopAdding();
            Transforms.deselect(editor);
            return;
        }
        if (adding === null || adding.value === '') {
            return;
        }
    };

    const addElement = async <T extends ISourcePreviewFragment | Partial<IMaterialLightFragment>>(
        material: T
    ): Promise<void> => {
        if (isNil(material.id)) {
            await Transforms.addNewBlankElement(editor, {
                categoryID: cell.categoryID,
                name: material.name || '',
                materialID: null,
                projectID: projectID.toString(),
                rates: {
                    productionRate: 0,
                    laborRate: 0,
                    materialRate: 0,
                    equipmentRate: 0,
                },
                takeoffUnitID: defaultUomID ?? '',
                uom: getUomId(),
                expression: null,
                client,
            });
            Transforms.deselect(editor);
            return;
        }

        setAdding(null);

        const { sourcesBatch } = await findLocalizedSourceV2(client, {
            items: [{ sourceId: material.id }],
            state: state?.name ?? 'N/A',
            county: county?.countyfp ?? 'N/A',
        });
        if (!sourcesBatch || sourcesBatch.length !== 1) {
            const message = 'There was a problem getting the item rate. Please try again';
            addNotification(
                {
                    title: 'Error',
                    content: message,
                },
                'error',
                false
            );
            return;
        }

        const source = sourcesBatch[0];

        await Transforms.addNewBlankElement(editor, {
            categoryID: cell.categoryID,
            name: material.name || '',
            materialID: null,
            projectID: projectID.toString(),
            rates: {
                productionRate: source.productionRate,
                laborRate: source.laborRateUsdCents,
                materialRate: source.materialRateUsdCents,
                equipmentRate: 0,
            },
            takeoffUnitID: defaultUomID ?? getUomId(source.uom),
            uom: getUomId(source.uom),
            expression: null,
            client,
        });
        Transforms.deselect(editor);
    };

    const renderAutoCompleteInput = () => {
        if (flags.addItemAutocompleteV2) {
            return (
                <ElementSearchV2
                    placeholder="Item name"
                    uomType={UomType.ITEM}
                    onSelect={addElement}
                />
            );
        }

        return (
            <ElementInputWrapper>
                <ElementInput
                    placeholder="Item name"
                    uomType={UomType.ITEM}
                    onSelect={addElement}
                    isEstimate
                    onKeyDown={handleTextKeyDownElement}
                    ref={inputRef}
                />
            </ElementInputWrapper>
        );
    };

    const renderContent = (): JSX.Element => {
        if (adding) {
            return (
                <ActionTextInputWrapper>
                    {adding.type === 'group' ? (
                        <ActionTextInput
                            spellCheck={true}
                            value={adding.value}
                            onBlur={handleInputTextBlur}
                            onChange={handleTextInputChange}
                            onKeyDown={handleTextKeyDownGroup}
                            placeholder="Group name"
                            ref={inputRef}
                        />
                    ) : (
                        renderAutoCompleteInput()
                    )}
                </ActionTextInputWrapper>
            );
        }
        const buttonsAreVisible =
            (breakpoints.mobile && flags.mobileAssemblyPanel) ||
            isOnlyRow ||
            bufferHovered ||
            cellHovered;

        return (
            <ActionButtonsContainer
                style={{ visibility: buttonsAreVisible ? 'visible' : 'hidden' }}
            >
                <ActionButton onClick={startAdding('item')}>Add item</ActionButton>
                {cell.categoryID === null && (
                    <ActionButton onClick={startAdding('group')}>Add group</ActionButton>
                )}
            </ActionButtonsContainer>
        );
    };

    return (
        <Cell
            attributes={attributes}
            caret="never"
            element={cell}
            preventSelection={true}
            onMouseEnter={(): void => setCellHovered(true)}
            onMouseLeave={(): void => setCellHovered(false)}
            style={{
                paddingLeft: cell.categoryID === null ? '1.25rem' : '2.25rem',
            }}
        >
            {!anonymousUser && renderContent()}
            {children}
        </Cell>
    );
};
