import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IUserFragment } from '@/graphql';
import { useUser } from '@/contexts/User';
import { DialogTitle, DialogContent } from './styled';

interface Props extends DialogProps {
    onCancelButtonClick?: () => void;
    onOkButtonClick?: () => void;
}

const getFullName = (user: IUserFragment) => {
    return `${user.firstName} ${user.lastName}`;
};

export const StopImpersonatingModal: FC<Props> = ({
    onCancelButtonClick,
    onOkButtonClick,
    ...props
}) => {
    const history = useHistory();
    const user = useUser();

    const handleOkButtonClick = async () => {
        await user.actions.impersonateUser({});

        history.replace('/mission-control');

        onOkButtonClick?.();
    };

    return (
        <Dialog maxWidth="xs" {...props}>
            <DialogTitle>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[21] }} variant="h2">
                    Confirm Exit
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">{`Are you sure you want to stop impersonating ${getFullName(
                    user.data.user
                )}?`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelButtonClick} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleOkButtonClick} variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
