import { createGlobalStyle, css, FlattenSimpleInterpolation } from 'styled-components';

import locationSrc from '@/assets/icons/location.svg';
import locationWhiteSrc from '@/assets/icons/location-white.svg';

// Google styling override
/* eslint-disable @typescript-eslint/indent */
interface CustomPacGlobalStyleProps {
    overrideWidth?: number;
    menuOffsetTop?: number;
}
export const CustomPacGlobalStyle = createGlobalStyle<CustomPacGlobalStyleProps>`
    .pac-target-input {
        border-radius: unset !important;
        height: 39px !important;
    }

    .pac-container {
        padding: 13px 0 21px;

        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 4px 4px;

        max-width: 447px;

        // Hide google logo
        &::after {
            display: none;
        }

        & > .pac-item {
            color: #A5B3B9;
        }

        margin-top: ${(props): number => props.menuOffsetTop || 0}px;

        ${(props): FlattenSimpleInterpolation | string =>
            props.overrideWidth
                ? // Important tag to override element styles
                  css`
                      width: ${props.overrideWidth}px !important;
                      max-width: ${props.overrideWidth}px;
                  `
                : ''}
    }

    .pac-item {
        height: 36px;
        left: 0px;
        top: 13px;

        display: flex;
        align-items: center;

        font-family: 'proxima-nova', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 36px;

        border: none;

        cursor: pointer;

        & * {
            font-weight: bold;
        }

        & > .pac-icon {
            flex: 0 0 20px;
            margin: 0 9px 2px 10px;

            display: inline-block;
            vertical-align: top;
            background-image: url(${locationSrc});
            background-repeat:no-repeat;
            background-position: center center;
            background-size: 9px;
        }

        & > .pac-item-query {
            color: #1B2235;
        }

        &:active,
        &:hover {
            background: #305FFF;

            & > .pac-icon {
                background-image: url(${locationWhiteSrc});
            }

            & * {
                color: #FFFFFF;
            }
        }
    }
`;
/* eslint-enable @typescript-eslint/indent */
