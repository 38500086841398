import React, { FC } from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { EllipsisButton } from './EllipsisButton';
import {
    ActionButtonsContainer,
    ActionButtonsWrapper,
    FullLogo,
    FullLogoWrapper,
    LeftButtonContainer,
    ArrowLeft,
    ArrowLeftLink,
    NavTabsContainer,
    ToolbarContainer,
    ToolbarContent,
    ToolbarGrid,
    UtilityButtonsWrapper,
} from './styled';
import { DivProps } from '@/common/types';
import { UserDropdown } from '@/components/ui/inputs/UserDropdown';
import { NavBarTabs } from '@/components/Estimate/Masthead/NavBarTabs';
import { useToolbar } from '@/contexts/Toolbar';

export { DetailsButton } from './styled';

import { ReactComponent as HelpIcon } from '@/assets/icons/32/help.svg';

interface ProjectsToolbarProps extends DivProps {
    hasBackButton?: boolean;
    StartIcon?: React.ReactNode;
}

export const ProjectsToolbar: FC<ProjectsToolbarProps> = ({
    hasBackButton,
    StartIcon,
    ...props
}) => {
    const { project } = useProjectFromParams();
    const { actionButtonsRef, leftButtonsRef, searchBarRef } = useToolbar();

    if (!project) {
        return null;
    }

    return (
        <ToolbarContainer {...props}>
            <div ref={searchBarRef} />
            <ToolbarContent>
                <ToolbarGrid isActive>
                    {StartIcon}
                    {hasBackButton && (
                        <ArrowLeftLink to="/projects">
                            <ArrowLeft />
                        </ArrowLeftLink>
                    )}
                    <LeftButtonContainer>
                        <div ref={leftButtonsRef} />
                    </LeftButtonContainer>
                    <FullLogoWrapper to="/projects">
                        <FullLogo />
                    </FullLogoWrapper>
                    <NavTabsContainer>
                        <NavBarTabs project={project} />
                    </NavTabsContainer>
                    <ActionButtonsContainer>
                        <ActionButtonsWrapper ref={actionButtonsRef} />
                        <UtilityButtonsWrapper direction="row" spacing={2}>
                            <EllipsisButton />
                            {Boolean(window.Intercom) && (
                                <Button
                                    color="secondary"
                                    endIcon={
                                        <SvgIcon
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                            }}
                                            viewBox="0 0 32 32"
                                        >
                                            <HelpIcon />
                                        </SvgIcon>
                                    }
                                    onClick={() => {
                                        window.Intercom('show');
                                    }}
                                    size="small"
                                    variant="outlined"
                                >
                                    Get Help
                                </Button>
                            )}
                            <UserDropdown />
                        </UtilityButtonsWrapper>
                    </ActionButtonsContainer>
                </ToolbarGrid>
            </ToolbarContent>
        </ToolbarContainer>
    );
};
