import { useState } from 'react';

export type ModalPayload = {
    isOpen: boolean;
    toggle: () => void;
};

export const useModal = (): ModalPayload => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggle = (): void => setIsOpen(!isOpen);
    return {
        isOpen,
        toggle,
    };
};
