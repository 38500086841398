/*
 * Topbar is the top bar of the admin dashboard.
 */
import React, { FC, useMemo } from 'react';

import { TopbarContainer } from './styled';

import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';

import { ReactComponent as Hamburger } from '@/assets/icons/Hamburger.svg';
import { ReactComponent as LogoAdminDashboard } from '@/assets/images/full-logo-v2.svg';

export interface TopbarProps {
    onClickHamburger: () => void;
}

export const Topbar: FC<TopbarProps> = ({ onClickHamburger, children }) => {
    const screenSize = useScreenSize();

    const isDesktop = useMemo(() => screenSize === ScreenSize.Desktop, [screenSize]);

    return (
        <TopbarContainer isDesktop={isDesktop}>
            {!isDesktop && (
                <>
                    <SvgIcon
                        src={Hamburger}
                        style={{
                            cursor: 'pointer',
                            gridArea: 'hamburger',
                            opacity: 0.7,
                        }}
                        onClick={onClickHamburger}
                    />
                    <span style={{ gridArea: 'logo' }}>
                        <LogoAdminDashboard
                            style={{
                                height: '1.875rem',
                                width: '4rem',
                            }}
                        />
                    </span>
                </>
            )}
            {children}
        </TopbarContainer>
    );
};
