import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

export const variables = {
    backButtonBackground: 'rgba(255, 255, 255, 0.17)',
};

const iconButtonCss = css`
    cursor: pointer;

    height: 36px;
    width: 126px;
    box-sizing: border-box;
    padding: 0 15px;

    background: ${variables.backButtonBackground};
    border-radius: 4px;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;

    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    & > svg {
        color: #fff;
        opacity: 0.7;
        margin-right: 15px;
        transform: matrix(1, 0, 0, -1, 0, 0);
    }

    transition: background-color 0.4s;

    &:hover {
        background-color: ${darken(0.08, variables.backButtonBackground)};
    }

    ${breakpoints.small`
        width: auto;

        & > span {
            display: none;
        }

        & > svg {
            margin-right: 0;
        }
    `}
`;

export const ContainerLink = styled(Link)`
    ${iconButtonCss}
`;
export const Container = styled.div`
    ${iconButtonCss}
`;
