import React, { FC, useState } from 'react';

import { Close, CloseIcon, Container, Input, MockInput, Prompt, Submit } from './styled';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { useFormattedAddress } from '@/components/ui/inputs/Location/hooks/useFormattedAddress';

import { ReactComponent as Location } from '@/assets/icons/location.svg';

const COPY = {
    placeholder: 'Address, City, State, or Zip',
    prompt: 'What is the location of this project?',
    submit: 'Done',
};

interface LocationDropdownProps {
    className?: string;
    isOpen: boolean;
    submit: (address?: google.maps.places.PlaceResult | null, location?: [number, number]) => void;
    initialAddress?: string;
}

export const LocationDropdown: FC<LocationDropdownProps> = ({
    className,
    isOpen,
    submit,
    initialAddress,
}) => {
    const { address, formattedAddress, setAddress } = useFormattedAddress(initialAddress);
    const [location, setLocation] = useState<[number, number] | undefined>();

    const tabIndex = isOpen ? 0 : -1;

    useDocumentListener(
        'keydown',
        (e) => {
            if (e.key === 'Escape' && isOpen) {
                submit();
            }
        },
        [isOpen, submit]
    );

    return (
        <Container className={className} isOpen={isOpen}>
            <Prompt>
                <Location />
                {COPY.prompt}
            </Prompt>
            <Close tabIndex={tabIndex} onClick={(): void => submit()}>
                <CloseIcon />
            </Close>

            {isOpen ? (
                <Input
                    autoFocus={isOpen}
                    tabIndex={tabIndex}
                    address={formattedAddress}
                    placeholder={COPY.placeholder}
                    onEnter={(newAddress, lat, lng): void => {
                        if (isOpen) {
                            submit(newAddress, [lng, lat]);
                        }
                    }}
                    setAddress={setAddress}
                    setCoordinates={(lat, lng): void => setLocation([lng, lat])}
                />
            ) : (
                <MockInput readOnly value={formattedAddress ?? ''} type="text" />
            )}

            <Submit tabIndex={tabIndex} onClick={(): void => submit(address, location)}>
                {COPY.submit}
            </Submit>
        </Container>
    );
};
