export interface MenuDimensions {
    height: number;
    width: number;
}

// Dynamic position info for the menu (usually click-based)
export interface MenuOrigin {
    x: number;
    y: number;
}

// Static position info for the menu (usually opened relative to another component)
export interface MenuOffset {
    top: number;
    left?: number;
    right?: number;
}

export type MenuPosition = MenuOrigin | MenuOffset;

export interface MenuRowOption {
    id?: string;
    text: string;
    onClick?: (e?: React.MouseEvent) => void;
    onMouseDown?: (e?: React.MouseEvent) => void;
    icon?: React.SFC<React.SVGProps<SVGSVGElement>>;
    activeIcon?: React.SFC<React.SVGProps<SVGSVGElement>>;
    isTaller?: boolean;
    isDivider?: boolean;
    options?: MenuRowOption[];
    confirmationText?: {
        title?: string;
        description?: string;
        confirm?: string;
        cancel?: string;
    };
}

/* Type Guards
 *****************************************************************************/
export const menuPositionIsOffset = (p: MenuPosition): p is MenuOffset => Reflect.has(p, 'top');
