import { IOldPageInfoFragment, IPageArguments, IPageInfoFragment } from '@/graphql';

// Types
export interface PaginationArgs {
    variables: {
        input: {
            arguments: IPageArguments;
        };
    };
}

export enum PaginationDirection {
    Next,
    Previous,
}

// Utility functions
export const pageArgsToPaginationArgs = (a: IPageArguments): PaginationArgs => ({
    variables: { input: { arguments: a } },
});

export const genPaginationArgs = (
    direction: PaginationDirection,
    count: number,
    info?: IOldPageInfoFragment | IPageInfoFragment
): PaginationArgs | undefined => {
    switch (direction) {
        case PaginationDirection.Next:
            if (info?.hasNextPage === false) {
                return undefined;
            }
            return pageArgsToPaginationArgs({
                first: count,
                after: info?.endCursor,
            });
        case PaginationDirection.Previous:
            if (info?.hasPreviousPage === false) {
                return undefined;
            }
            return pageArgsToPaginationArgs({
                last: count,
                before: info?.startCursor,
            });
        default:
            return undefined;
    }
};
