import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import Drawer, { DrawerProps } from '@mui/material/Drawer';

import { DrawerHeader, DrawerContentList } from './styled';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { Mode } from '@/theme/Mode';

type MenuDrawerProps = MenuProps & DrawerProps & { mobileMenuTitle?: string };
export const MenuDrawer: React.FC<MenuDrawerProps> = ({ mobileMenuTitle, children, ...rest }) => {
    const screenSize = useScreenSize();

    return (
        <Mode variant="light">
            {screenSize === ScreenSize.Mobile ? (
                <Drawer {...rest} anchor="bottom">
                    {mobileMenuTitle && <DrawerHeader variant="h3">{mobileMenuTitle}</DrawerHeader>}
                    <DrawerContentList>{children}</DrawerContentList>
                </Drawer>
            ) : (
                <Menu {...rest}>{children}</Menu>
            )}
        </Mode>
    );
};
