import React, { FC } from 'react';

import { IconWrapper } from './styled';

import { DivProps, PricingType } from '@/common/types';
import { Svg } from '@/common/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorStatusError, colorStatusSuccess, colorTypographySection } from '@/variables';

import { ReactComponent as CancelIcon } from '@/assets/icons/cancel.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/checkmark-light.svg';
import { ReactComponent as ClockIcon } from '@/assets/icons/clock.svg';

type ProjectPricingStatusIconProps = DivProps & {
    status: PricingType;
};

const { PREAPPROVED, DECLINED } = PricingType;

export const ProjectPricingStatusIcon: FC<ProjectPricingStatusIconProps> = ({ status }) => {
    const checkStatuses = (...statuses: PricingType[]): boolean =>
        statuses.some((item) => item === status);

    const source = (): Svg => {
        return checkStatuses(PREAPPROVED)
            ? ClockIcon
            : checkStatuses(DECLINED)
            ? CancelIcon
            : CheckmarkIcon;
    };

    const color = (): string => {
        return checkStatuses(PREAPPROVED)
            ? colorTypographySection
            : checkStatuses(DECLINED)
            ? colorStatusError
            : colorStatusSuccess;
    };

    return (
        <IconWrapper>
            <SvgIcon src={source()} fill={color()} noInherit={true} />
        </IconWrapper>
    );
};
