import React, { FC } from 'react';
import styled from 'styled-components';

import { colorProjectPanelHighlight } from '@/variables';

const TabItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
`;

const Text = styled.div`
    padding: 0.5rem 1rem;
`;

const TabUnderline = styled.div<{ active?: boolean }>`
    background-color: ${(props): string =>
        props.active ? colorProjectPanelHighlight : 'transparent'};
    height: 0.25rem;
    border-radius: 3px 3px 0 0;
`;

export interface TabProps {
    onClick?: (eventKey: string) => void;
    active?: boolean;
    eventKey: string;
    title: string;
    children?: React.ReactNode;
}

export const Tab: FC<TabProps> = ({ onClick, active = false, eventKey, title }) => {
    const handleClick = (): void => {
        if (onClick) {
            onClick(eventKey);
        }
    };

    return (
        <TabItem onClick={handleClick}>
            <Text>{title}</Text>
            <TabUnderline active={active} />
        </TabItem>
    );
};
