import React, { FC } from 'react';
import FocusTrap from 'focus-trap-react';
import {
    BodyContainer,
    BodyHeader,
    BodyPoint,
    CancelButton,
    Container,
    Header,
    Overlay,
    SubmitButton,
    SubmitContainer,
} from './styled';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';

const COPY = {
    headerUnassign: 'Are you sure you want to remove yourself as owner of this project?',
    headerAssign: 'There is no owner for this project. Would you like to assign youself as owner?',
    bodyHeaderUnassign: 'Removing yourself as owner will:',
    bodyPointOneUnassign: '- Allow any other contributors in this project to become the owner',
    bodyPointTwoUnassign: '- Remove your ability to perform takeoff and edit project details',
    bodyPointAssign:
        'There can be only one owner per project. Making yourself the owner assumes you will be ' +
        'carrying out takeoff and estimation duties.',
    submitUnassign: 'Remove myself as owner',
    submitAssign: 'Make myself owner',
    cancelUnassign: 'Remain as owner',
    cancelAssign: 'Remain as viewer',
};

export type ModalProps = {
    unassign: boolean;
    close: () => void;
    submit: () => void;
};
export const Modal: FC<ModalProps> = ({ close, submit, unassign }) => {
    // Exit on esc
    useDocumentListener(
        'keydown',
        (e) => {
            if (e.key === 'Escape') {
                close();
            }
        },
        [close]
    );

    return (
        <FocusTrap>
            <Overlay
                // Exit on click out
                onContextMenu={(e): void => {
                    if (!e.defaultPrevented) {
                        close();
                    }
                }}
                onClick={(e): void => {
                    if (!e.defaultPrevented) {
                        e.preventDefault();
                        close();
                    }
                }}
            >
                <Container
                    // Prevent context menu from selecting all text and close from firing
                    onClick={(e): void => e.preventDefault()}
                    onContextMenu={(e): void => e.preventDefault()}
                >
                    <Header>{unassign ? COPY.headerUnassign : COPY.headerAssign}</Header>
                    <BodyContainer>
                        {unassign ? (
                            <>
                                <BodyHeader>{COPY.bodyHeaderUnassign}</BodyHeader>
                                <BodyPoint>{COPY.bodyPointOneUnassign}</BodyPoint>
                                <BodyPoint>{COPY.bodyPointTwoUnassign}</BodyPoint>
                            </>
                        ) : (
                            <BodyPoint>{COPY.bodyPointAssign}</BodyPoint>
                        )}
                    </BodyContainer>

                    <SubmitContainer>
                        <CancelButton tabIndex={0} onClick={(): void => close()}>
                            {unassign ? COPY.cancelUnassign : COPY.cancelAssign}
                        </CancelButton>
                        <SubmitButton
                            tabIndex={0}
                            onClick={(): void => submit()}
                            onKeyDown={(e): void => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    submit();
                                }
                            }}
                        >
                            {unassign ? COPY.submitUnassign : COPY.submitAssign}
                        </SubmitButton>
                    </SubmitContainer>
                </Container>
            </Overlay>
        </FocusTrap>
    );
};
