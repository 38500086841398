import { amber } from '../colors/amber';
import { blue } from '../colors/blue';
import { mint } from '../colors/mint';
import { neutral } from '../colors/neutral';
import { orange } from '../colors/orange';
import { purple } from '../colors/purple';
import { red } from '../colors/red';

// See: https://www.figma.com/file/ZbNjtKfXnnn2Fl0kg7xuvG/Toolbox?node-id=2%3A216
export const colors = {
    amber,
    blue,
    mint,
    neutral,
    orange,
    purple,
    red,
};
