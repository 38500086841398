/*
 * LabeledDivider is a labeled divider between files in the file module.
 */
import React, { FC } from 'react';

import { LabeledDividerContainer, LabeledDividerLine, LabeledDividerText } from './styled';

export interface LabeledDividerProps {
    label: string;
}

export const LabeledDivider: FC<LabeledDividerProps> = ({ label }) => (
    <LabeledDividerContainer>
        <LabeledDividerLine />
        <LabeledDividerText>{label}</LabeledDividerText>
    </LabeledDividerContainer>
);
