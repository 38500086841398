import { markupEntryIsMarkup, markupEntryIsMarkupGroup } from '@/common/typeGuards';
import {
    IMarkupFragment,
    IMarkupGeometryFragment,
    IMarkupGroupFragment,
    IMarkupType,
    IProjectWithMarkupEntriesQuery,
    IProjectWithMarkupEntriesQueryVariables,
    MarkupFragmentDoc,
    MarkupGroupFragmentDoc,
    ProjectWithMarkupEntriesDocument,
} from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';
import { useApolloClient } from '@apollo/client';
import { selectedMarkupIDs } from '@/common/apollo';

type CacheModifier<T> = (prev: T) => T;

export interface UseMarkupCachePayload {
    // addMarkupsToProject adds markups to a project's cached `project.markupEntries` array.
    addMarkupsToProject: (markups: IMarkupFragment[], projectUUID: string) => void;
    // removeMarkups removes markups from the cache.
    // Groups that the removed markups belong to will be updated in the cache.
    // If `projectID` is passed, the markups will additionally be removed from that project's
    // `project.markupEntries` array.
    removeMarkups: (markups: RemovedMarkup[], projectID?: number) => void;
    removeMarkupsFromMarkupGroup: (
        markupGroupID: string,
        markupIDs: Set<string>,
        projectID: number
    ) => void;
    // removeMarkupGroup removes a markup group from the cache.
    // If `projectID` is passed, the markup group will additionally be removed from that project's
    // `project.markupEntries` array and its members will become base-level entries.
    removeMarkupGroup: (markupGroupID: string, projectID?: number) => void;
    // updateMarkupColors updates cached markup colors.
    updateMarkupColors: (markups: ModifiedColorMarkup[]) => void;
    // updateMarkupGeometries updates markups coordinates and measurements in the cache.
    // If the markups are part of a group, the group's measurement is also updated.
    updateMarkupGeometries: (markups: ModifiedGeometryMarkup[]) => void;
    // selectMarkup sets the local (client-only) value of the isSelected field of the markup pointed to by markupID
    // and deselects any markups that were selected before it.
    selectMarkup: (markupID: string) => void;
}

type ModifiedColorMarkup = Pick<IMarkupFragment, 'id' | 'color'>;
type ModifiedGeometryMarkup = Pick<
    IMarkupFragment,
    'id' | 'geometries' | 'measurement' | 'markupGroupID'
>;
type ModifiedGeometryMarkupInGroup = Omit<ModifiedGeometryMarkup, 'markupGroupID'> & {
    markupGroupID: string;
};
const modifiedGeometryMarkupIsInGroup = (
    markup: ModifiedGeometryMarkup
): markup is ModifiedGeometryMarkupInGroup => typeof markup.markupGroupID === 'string';
type RemovedMarkup = Pick<IMarkupFragment, 'id' | 'markupGroupID'>;
type RemovedMarkupInGroup = Omit<RemovedMarkup, 'markupGroupID'> & { markupGroupID: string };
const removedMarkupIsInGroup = (markup: RemovedMarkup): markup is RemovedMarkupInGroup =>
    typeof markup.markupGroupID === 'string';

export const useMarkupCache = () => {
    const client = useApolloClient();

    const cacheID = {
        markup: (markupID: string) =>
            client.cache.identify({
                __typename: 'Markup',
                id: markupID,
            }),
        markupGroup: (markupGroupID: string) =>
            client.cache.identify({
                __typename: 'MarkupGroup',
                id: markupGroupID,
            }),
        project: (projectID: number) =>
            client.cache.identify({
                __typename: 'Project',
                id: String(projectID),
            }),
    };

    const evict = {
        markup: (markupID: string) =>
            client.cache.evict({
                id: cacheID.markup(markupID),
            }),
        markupGroup: (markupGroupID: string) =>
            client.cache.evict({
                id: cacheID.markupGroup(markupGroupID),
            }),
    };

    const modify = {
        markup: (markupID: string, modify: { markupGroupID: CacheModifier<string | null> }) => {
            client.cache.modify({
                id: cacheID.markup(markupID),
                fields: {
                    markupGroupID: modify.markupGroupID,
                },
            });
        },
        markupColor: (markupID: string, modify: CacheModifier<string>) => {
            client.cache.modify({
                id: cacheID.markup(markupID),
                fields: { color: modify },
            });
        },
        selectMarkup: (markupID: string) => {
            selectedMarkupIDs([markupID]);
        },
        markupGeometry: (
            markupID: string,
            modify: {
                geometries: CacheModifier<IMarkupGeometryFragment[] | null>;
                measurement: CacheModifier<number>;
            }
        ) => {
            client.cache.modify({
                id: cacheID.markup(markupID),
                fields: {
                    geometries: modify.geometries,
                    measurement: modify.measurement,
                },
            });
        },
        markupGroup: (
            markupGroupID: string,
            modify: {
                markupGroupType: CacheModifier<IMarkupType | null>;
                markups: CacheModifier<IMarkupFragment[]>;
                measurement: CacheModifier<number>;
            }
        ) => {
            client.cache.modify({
                id: cacheID.markupGroup(markupGroupID),
                fields: {
                    markupGroupType: modify.markupGroupType,
                    markups: modify.markups,
                    measurement: modify.measurement,
                },
            });
        },
        markupGroupMeasurement: (markupGroupID: string, modify: CacheModifier<number>) => {
            client.cache.modify({
                id: cacheID.markupGroup(markupGroupID),
                fields: { measurement: modify },
            });
        },
        projectMarkupEntries: (projectID: number, modify: CacheModifier<IMarkupEntry[]>) => {
            client.cache.modify({
                id: cacheID.project(projectID),
                fields: { markupEntries: modify },
            });
        },
    };

    const read = {
        markup: (markupID: string): IMarkupFragment | null =>
            client.cache.readFragment<IMarkupFragment>({
                id: cacheID.markup(markupID),
                fragment: MarkupFragmentDoc,
                fragmentName: 'markup',
            }),
        markupGroup: (markupGroupID: string): IMarkupGroupFragment | null =>
            client.cache.readFragment<IMarkupGroupFragment>({
                id: cacheID.markupGroup(markupGroupID),
                fragment: MarkupGroupFragmentDoc,
                fragmentName: 'markupGroup',
            }),
    };

    const readQuery = {
        projectWithMarkupEntries: (projectUUID: string) =>
            client.cache.readQuery<
                IProjectWithMarkupEntriesQuery,
                IProjectWithMarkupEntriesQueryVariables
            >({
                query: ProjectWithMarkupEntriesDocument,
                variables: { uuid: projectUUID },
            }),
    };

    const writeQuery = {
        projectWithMarkupEntries: (projectUUID: string, data: IProjectWithMarkupEntriesQuery) =>
            client.cache.writeQuery<
                IProjectWithMarkupEntriesQuery,
                IProjectWithMarkupEntriesQueryVariables
            >({
                query: ProjectWithMarkupEntriesDocument,
                variables: { uuid: projectUUID },
                data,
            }),
    };

    const operate = {
        // recalculateMarkupGroupMeasurement recalculates a markup group's measurement and updates
        // the cache if there has been a change.
        recalculateMarkupGroupMeasurement: (markupGroupID: string) => {
            const markupGroup = read.markupGroup(markupGroupID);
            if (markupGroup === null) {
                return;
            }
            const newMeasurement = markupGroup.markups.reduce<number>(
                (cur, { measurement }) => cur + measurement,
                0
            );
            if (newMeasurement !== markupGroup.measurement) {
                modify.markupGroupMeasurement(markupGroupID, () => newMeasurement);
            }
        },
        // removeMarkupsFromMarkupGroup removes markups with passed ids from the markup group's state.
        // The markup group's measurement and markup group type are also updated.
        removeMarkupsFromMarkupGroup: (
            markupGroupID: string,
            markupIDs: Set<string>,
            projectID: number
        ) => {
            if (markupIDs.size === 0) {
                return;
            }
            const prevMarkupGroup = read.markupGroup(markupGroupID);
            if (prevMarkupGroup === null) {
                return;
            }
            const newMarkups = prevMarkupGroup.markups.filter(
                (markup) => !markupIDs.has(markup.id)
            );

            markupIDs.forEach((markupID) => {
                modify.markup(markupID, {
                    markupGroupID: () => null,
                });
            });

            modify.markupGroup(markupGroupID, {
                markupGroupType: () => (newMarkups.length === 0 ? null : newMarkups[0].markupType),
                markups: () => newMarkups,
                measurement: () =>
                    newMarkups.reduce<number>((cur, { measurement }) => cur + measurement, 0),
            });

            modify.projectMarkupEntries(projectID, (prevMarkupEntries) => [
                ...prevMarkupGroup.markups.map((markup) => ({
                    ...markup,
                    markupGroupID: null,
                })),
                ...prevMarkupEntries,
            ]);
        },
    };

    const payload: UseMarkupCachePayload = {
        selectMarkup: (markupID: string) => {
            modify.selectMarkup(markupID);
        },
        addMarkupsToProject: (markups, projectUUID) => {
            if (markups.length === 0) {
                return;
            }
            const record = readQuery.projectWithMarkupEntries(projectUUID);
            if (record !== null) {
                writeQuery.projectWithMarkupEntries(projectUUID, {
                    project: {
                        ...record.project,
                        markupEntries: [...markups, ...record.project.markupEntries],
                    },
                });
            }
        },
        removeMarkupGroup: (markupGroupID, projectID) => {
            if (projectID) {
                const prevMarkupGroup = read.markupGroup(markupGroupID);
                if (prevMarkupGroup !== null) {
                    prevMarkupGroup.markups.forEach((markup) => {
                        modify.markup(markup.id, {
                            markupGroupID: () => null,
                        });
                    });
                    modify.projectMarkupEntries(projectID, (prevMarkupEntries) => [
                        ...prevMarkupGroup.markups.map((markup) => ({
                            ...markup,
                            markupGroupID: null,
                        })),
                        ...prevMarkupEntries.filter(
                            (markupEntry) =>
                                markupEntryIsMarkup(markupEntry) || markupEntry.id !== markupGroupID
                        ),
                    ]);
                }
            }
            evict.markupGroup(markupGroupID);
        },
        removeMarkups: (markups, projectID) => {
            if (markups.length === 0) {
                return;
            }
            const markupIDs = new Set(markups.map(({ id }) => id));
            if (projectID) {
                modify.projectMarkupEntries(projectID, (prevMarkupEntries) =>
                    prevMarkupEntries.filter(
                        (entry) => markupEntryIsMarkupGroup(entry) || !markupIDs.has(entry.id)
                    )
                );
            }
            markupIDs.forEach(evict.markup);
            const markupGroupIDs = new Set(
                markups.filter(removedMarkupIsInGroup).map(({ markupGroupID }) => markupGroupID)
            );
            if (markupGroupIDs.size > 0 && projectID) {
                markupGroupIDs.forEach((markupGroupID) => {
                    operate.removeMarkupsFromMarkupGroup(markupGroupID, markupIDs, projectID);
                });
            }
        },
        removeMarkupsFromMarkupGroup: operate.removeMarkupsFromMarkupGroup,
        updateMarkupColors: (markups) => {
            markups.forEach((markup) => {
                modify.markupColor(markup.id, () => markup.color);
            });
        },
        updateMarkupGeometries: (markups) => {
            if (markups.length === 0) {
                return;
            }
            markups.forEach((markup) => {
                modify.markupGeometry(markup.id, {
                    geometries: () => markup.geometries,
                    measurement: () => markup.measurement,
                });
            });
            const markupGroupIDs = new Set(
                markups
                    .filter(modifiedGeometryMarkupIsInGroup)
                    .map(({ markupGroupID }) => markupGroupID)
            );
            if (markupGroupIDs.size > 0) {
                markupGroupIDs.forEach(operate.recalculateMarkupGroupMeasurement);
            }
        },
    };

    return payload;
};
