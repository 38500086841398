import { useEffect, useState } from 'react';

import { useEstimateEditor } from './useEstimateEditor';
import { EstimateEditor } from '../editor/EstimateEditor';
import { categoryTotalFromElementTotals } from '../utils/transforms';

export const useCategoryTotal = (categoryID: string): number => {
    const editor = useEstimateEditor();

    const [categoryTotal, setCategoryTotal] = useState(0);

    useEffect(() => {
        let canceled = false;
        setTimeout(() => {
            if (!canceled) {
                const newCategoryTotal = EstimateEditor.categoryTotal(editor, categoryID);
                setCategoryTotal(newCategoryTotal);
            }
        }, 250);
        return (): void => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;
        const unsubscribe = EstimateEditor.subscribeToElementTotals(
            editor,
            categoryID,
            (totals) => {
                if (canceled) {
                    return;
                }
                const newCategoryTotal = categoryTotalFromElementTotals(categoryID, totals);
                setCategoryTotal(newCategoryTotal);
            }
        );
        return (): void => {
            canceled = true;
            unsubscribe();
        };
    }, [categoryID]);

    return categoryTotal;
};
