import moment from 'moment';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FC } from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { formatters, toDollarAmount } from '@/common/currency';
import { ITeamBillingFragment } from '@/graphql';
import { getProjectSubtotal, getProjectCredits, getProjectTotal } from '../../utils';
import { StatusTag } from '../StatusTag';
import { ColumnHeading, LinkBox, TableCell, TableCellWide, TableCellRegular } from './styled';

interface Props extends TableContainerProps {
    team?: ITeamBillingFragment;
}

export const EstimatesTable: FC<Props> = ({ team, ...props }) => {
    const projects = team?.projects?.edges?.map((edge) => edge.node);

    return (
        <TableContainer {...props}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWide size="small">
                            <ColumnHeading variant="overline">Estimate</ColumnHeading>
                        </TableCellWide>
                        <TableCellWide size="small">
                            <ColumnHeading variant="overline">Status</ColumnHeading>
                        </TableCellWide>
                        <TableCellRegular size="small">
                            <ColumnHeading variant="overline">Due Date</ColumnHeading>
                        </TableCellRegular>
                        <TableCellRegular size="small">
                            <ColumnHeading variant="overline">Subtotal</ColumnHeading>
                        </TableCellRegular>
                        <TableCellRegular size="small">
                            <ColumnHeading variant="overline">Credits</ColumnHeading>
                        </TableCellRegular>
                        <TableCellRegular size="small">
                            <ColumnHeading variant="overline">Total</ColumnHeading>
                        </TableCellRegular>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects?.map((project) => (
                        <TableRow key={project.uuid}>
                            <TableCell>
                                <LinkBox>
                                    <Link
                                        component={RRDLink}
                                        to={`/projects/${project.uuid}`}
                                        target="_blank"
                                    >
                                        {project.name}
                                    </Link>
                                </LinkBox>
                            </TableCell>
                            <TableCell>
                                <StatusTag status={project.status} />
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {moment(project.bidsDueDate).format('D/M/YYYY')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {formatters
                                        .usd()
                                        .format(toDollarAmount(getProjectSubtotal(project)))}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {formatters
                                        .usd()
                                        .format(toDollarAmount(getProjectCredits(team, project)))}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">
                                    {formatters
                                        .usd()
                                        .format(toDollarAmount(getProjectTotal(team, project)))}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
