import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import React, { FC, useState } from 'react';
import { ListItemButtonWithCollapse } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/ListItemButtonWithCollapse';
import { NoAssembliesWarning } from '../NoAssembliesWarning';
import { ItemListItem } from './ItemListItem';
import { UNCATEGORIZED_TRADE_ID } from '@/common/assemblies';
import { genPaginationArgs, PaginationDirection } from '@/common/utils/pagination';
import { AssemblyListTab, useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { useAssemblies } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { IAssemblyType, IElementFragment, useAssemblyLibrariesQuery } from '@/graphql';
import { OnebuildLibrarySectionWrapper } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/styled';
import { TabSelector } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/TabSelector';
import { useLibraryElements } from '@/components/AssemblyPanel/hooks/useElements';
import { ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useMaterials } from '@/components/AssemblyPanel/hooks/useMaterials';
import { useNotifications } from '@/contexts/Notifications';

const GROUP_COPY = {
    emptyTitle: 'Add some groups to get started.',
    emptyMessage:
        'Groups allow you to quickly populate an estimate with multiple items or assemblies. Groups can also be used to organize your estimate by phase, page number, or trade category. Import groups you need from the 1build library or create your own.',
};

const ITEM_COPY = {
    emptyTitle: 'Add some items to get started.',
    emptyMessage:
        'Items are the building blocks of any project and allow you to create an estimate with material, labor, and/or equipment. Import items you need from the 1build library or create your own.',
};

interface MyLibrarySectionProps extends BoxProps {
    projectId: number;
}

export const MyLibrarySection: FC<MyLibrarySectionProps> = ({ projectId, ...props }) => {
    const { search, tab } = useAssemblyPanel();

    const { data: assemblyLibrariesData } = useAssemblyLibrariesQuery({
        variables: {
            input: {
                condition: {
                    is1b: false,
                    assemblyType: IAssemblyType.Group,
                },
                ...genPaginationArgs(PaginationDirection.Next, 1000)?.variables.input,
            },
        },
    });

    const personalLibrary =
        (assemblyLibrariesData?.assemblyLibraries.edges || []).length > 0
            ? (assemblyLibrariesData?.assemblyLibraries.edges || [])[0].node
            : undefined;

    const libraryId = personalLibrary?.id || null;

    const { libraryElements, loading: elementsLoading } = useLibraryElements({
        libraryId: libraryId!,
    });

    const {
        assemblies,
        assembliesGroupedByTrade,
        loading: assembliesLoading,
    } = useAssemblies({
        libraryId: libraryId,
        pageSize: 100,
        search,
    });

    const { addNotification } = useNotifications();
    const { importMaterialToProject } = useMaterials({
        projectID: `${projectId}`,
    });

    const showAssemblyErrorNotification = () =>
        addNotification(
            {
                title: 'Error',
                content: 'There was a problem adding the assembly. Please try again',
            },
            'error',
            true
        );

    const [addingElementInProgress, setAddingElementInProgress] = useState(false);

    const addMaterialItem = async (element: IElementFragment) => {
        try {
            if (!element) return;

            setAddingElementInProgress(true);
            if (element.material?.id) {
                await importMaterialToProject({
                    material: element.material,
                    elementOptions: element,
                });
            }
        } catch {
            showAssemblyErrorNotification();
        } finally {
            setAddingElementInProgress(false);
        }
    };

    const skeletonList = (
        <List>
            <ListItem dense>
                <Skeleton
                    sx={{
                        height: '16px',
                        margin: '2px 0',
                        width: '100%',
                    }}
                />
            </ListItem>
        </List>
    );

    const MyLibraryAssemblies = () => (
        <Box {...props}>
            {(() => {
                if (assembliesLoading && !assemblies.length) {
                    return skeletonList;
                }

                if (!assemblies.length) {
                    return (
                        <NoAssembliesWarning
                            copy={GROUP_COPY.emptyMessage}
                            header={GROUP_COPY.emptyTitle}
                            sx={{ paddingTop: '12px' }}
                        />
                    );
                }

                return (
                    <List>
                        {assembliesGroupedByTrade.map(([tradeName, assemblies], index) => {
                            if (tradeName === UNCATEGORIZED_TRADE_ID) {
                                return (
                                    <Box key={index}>
                                        {assemblies.map((assembly) => {
                                            return (
                                                <ItemListItem
                                                    assembly={assembly}
                                                    key={assembly.id}
                                                    projectId={projectId}
                                                />
                                            );
                                        })}
                                    </Box>
                                );
                            } else {
                                return (
                                    <ListItemButtonWithCollapse
                                        dense
                                        key={index}
                                        label={tradeName}
                                        sx={{
                                            paddingLeft: '35px',
                                        }}
                                    >
                                        {assemblies.map((assembly) => {
                                            return (
                                                <ItemListItem
                                                    assembly={assembly}
                                                    key={assembly.id}
                                                    projectId={projectId}
                                                    sx={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                            );
                                        })}
                                    </ListItemButtonWithCollapse>
                                );
                            }
                        })}
                    </List>
                );
            })()}
        </Box>
    );

    const MyLibraryElements = () => (
        <Box {...props}>
            {(() => {
                if (elementsLoading && !libraryElements?.length) {
                    return skeletonList;
                }
                if (!libraryElements?.length) {
                    return (
                        <NoAssembliesWarning
                            copy={ITEM_COPY.emptyMessage}
                            header={ITEM_COPY.emptyTitle}
                            sx={{ paddingTop: '12px' }}
                        />
                    );
                }
                return (
                    <List>
                        {libraryElements.map((e) => (
                            <ListItemButton
                                dense
                                disabled={addingElementInProgress}
                                key={e.id}
                                onClick={() => addMaterialItem(e)}
                            >
                                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                                    {e.name}
                                </ListItemText>
                            </ListItemButton>
                        ))}
                    </List>
                );
            })()}
        </Box>
    );

    return (
        <OnebuildLibrarySectionWrapper>
            <TabSelector />
            {tab === AssemblyListTab.ITEMS && <MyLibraryElements />}
            {tab === AssemblyListTab.GROUPS && <MyLibraryAssemblies />}
        </OnebuildLibrarySectionWrapper>
    );
};
