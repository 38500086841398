import { Hook } from '@/common/types';
import { useUser } from '@/contexts/User';
import {
    Exact,
    IElementFragment,
    ILibraryElementsInput,
    ILibraryElementsQuery,
    ILibraryElementsQueryVariables,
    LibraryElementsDocument,
} from '@/graphql';
import { QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export type LibraryElements = IElementFragment[];

export interface UseLibraryElementsRes {
    libraryElements: LibraryElements;
    loading: boolean;
    refetch?: () => void;
}

export interface UseLibraryElementsProps {
    libraryId?: string;
}

export const useLibraryElements: Hook<UseLibraryElementsRes, UseLibraryElementsProps> = ({
    libraryId,
}) => {
    const {
        data: { anonymousUser },
    } = useUser();
    const [libraryElements, setLibraryElements] = useState<LibraryElements>([]);

    const [
        fetchLibraryElements,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { data: libraryElementsData, loading, refetch },
    ] = useLazyQuery<ILibraryElementsQuery, ILibraryElementsQueryVariables>(
        LibraryElementsDocument
    );

    const assemblyQueryInput = {
        variables: {
            input: {
                libraryId: libraryId,
            },
        },
        fetchPolicy: anonymousUser ? 'cache-first' : 'network-only',
    } as QueryLazyOptions<Exact<{ input: ILibraryElementsInput }>>;

    useEffect(() => {
        if (!libraryId) return;

        fetchLibraryElements(assemblyQueryInput);
    }, [libraryId]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const libraryElements = libraryElementsData?.libraryElements as LibraryElements;
        if (!libraryElements) return;

        setLibraryElements(libraryElements);
    }, [libraryElementsData]);

    return {
        libraryElements,
        loading,
        refetch,
    };
};
