import { useCounties } from './hooks/useCounties';
import { useStates } from './hooks/useStates';
import { ICountyFragment, IStateFragment } from '@/graphql';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack, { StackProps } from '@mui/material/Stack';
import React, { FC } from 'react';
import { SelectMenu } from './SelectMenu';

/**
 * Return LA and AK county names
 * @param {string} localizationState — current state
 * @returns {string} how state refers to county.
 */
const getCountyName = (localizationState?: string): string => {
    switch (localizationState) {
        case 'Louisiana':
            return 'Parishes';
        case 'Alaska':
            return 'Boroughs';
        default:
            return 'Counties';
    }
};

const getCountyById = (states: ICountyFragment[], id: string) => {
    return states.find((state) => state.id === id);
};

const getStateById = (states: IStateFragment[], id: string) => {
    return states.find((state) => state.id === id);
};

export const LocalizationSection: FC<StackProps> = (props) => {
    const { states, localizationState, setLocalizationState } = useStates();
    const { counties, localizationCounty, setLocalizationCounty } = useCounties({
        localizationStateFp: localizationState.localization?.statefp,
    });

    return (
        <Stack direction="row" spacing="8px" {...props}>
            <FormControl sx={{ width: '50%' }}>
                <InputLabel id="state-select" size="small">
                    State
                </InputLabel>
                <Select
                    disabled={!states.length}
                    label="State"
                    labelId="state-select"
                    MenuProps={{
                        component: SelectMenu,
                        sx: {
                            maxHeight: '370px',
                        },
                    }}
                    value={
                        states.length && localizationState.localization
                            ? localizationState.localization?.id
                            : ''
                    }
                    onChange={(event) => {
                        const state = getStateById(states, event.target.value);

                        if (!state) {
                            return;
                        }

                        setLocalizationState({ localization: state, isManuallySet: true });
                    }}
                    size="small"
                >
                    {states.map((state) => (
                        <MenuItem dense key={state.id} value={state.id}>
                            {state.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width: '50%' }}>
                <InputLabel id="county-select" size="small">
                    {getCountyName(localizationState.localization?.name)}
                </InputLabel>
                <Select
                    disabled={!counties.length}
                    label={getCountyName(localizationState.localization?.name)}
                    labelId="county-select"
                    MenuProps={{
                        component: SelectMenu,
                        sx: {
                            maxHeight: '370px',
                        },
                    }}
                    value={
                        counties.length && localizationCounty.localization
                            ? localizationCounty.localization?.id
                            : ''
                    }
                    onChange={(event) => {
                        const county = getCountyById(counties, event.target.value);

                        if (!county) {
                            return;
                        }

                        setLocalizationCounty({
                            localization: county,
                            isManuallySet: true,
                        });
                    }}
                    size="small"
                >
                    {counties.map((county) => (
                        <MenuItem dense key={county.id} value={county.id}>
                            {county.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};
