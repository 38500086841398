import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { BuilderDetailsFormFields } from '@/common/types';
import { TradeCircle } from '@/components/ui/controls/Trade';
import { TradeRecord } from '@/queries/trades';

interface BuilderDetailsTradeListProps {
    control: Control<BuilderDetailsFormFields, unknown>;
    label: string;
    setValue: UseFormSetValue<BuilderDetailsFormFields>;
    options: TradeRecord[];
    defaultSelected: string[];
    disabled?: boolean;
}

export const BuilderDetailsTradeList: React.FC<BuilderDetailsTradeListProps> = ({
    control,
    label,
    setValue,
    options,
    defaultSelected,
    disabled = false,
}) => {
    const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelected ?? []);

    const handleSelect = (value: string) => {
        const isPresent = selectedItems.some((el) => el === value);
        if (isPresent) {
            const remaining = selectedItems.filter((item: string) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems: string[]) => [...prevItems, value]);
        }
    };

    const handleCheckAll = () => {
        if (selectedItems.length === options.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(options.map((option) => option.id));
        }
    };

    useEffect(() => {
        setValue('trades', selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        defaultSelected && setSelectedItems(defaultSelected);
    }, [defaultSelected]);

    return (
        <FormControl variant="filled" sx={{ mb: '40px' }}>
            <FormLabel component="legend">
                <Typography sx={{ mb: 2 }} variant="subtitle1">
                    {label}
                </Typography>
            </FormLabel>
            <Box
                sx={{
                    p: '2rem 1.5rem 1.5rem',
                    backgroundColor: (theme) => theme.palette.hues.neutral[32],
                }}
            >
                <Grid container ml={0} mb={2}>
                    {options.map((option, idx) => {
                        return (
                            <Grid item xs={12} md={6} pl={1} pr={1} key={idx}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        onClick={() => handleSelect(option.id)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TradeCircle
                                            v2
                                            className="label-icon"
                                            key={idx}
                                            trade={{
                                                name: option.name,
                                                nickname: option.nickname,
                                            }}
                                        />
                                        <Typography variant="caption">{option.name}</Typography>
                                    </Stack>

                                    <Controller
                                        name="trades"
                                        render={() => (
                                            <Checkbox
                                                disabled={disabled}
                                                checked={selectedItems.includes(option.id)}
                                                onChange={() => handleSelect(option.id)}
                                            />
                                        )}
                                        control={control}
                                    />
                                </Stack>
                            </Grid>
                        );
                    })}
                </Grid>
                <Divider />
                <FormControlLabel
                    labelPlacement="end"
                    sx={{
                        marginTop: '16px',
                    }}
                    control={
                        <Controller
                            name="trades"
                            render={() => (
                                <Checkbox
                                    disabled={disabled}
                                    checked={selectedItems.length === options.length}
                                    onChange={() => handleCheckAll()}
                                />
                            )}
                            control={control}
                        />
                    }
                    label="Select all trades"
                />
            </Box>
        </FormControl>
    );
};
