import React, { FC, useState, useEffect } from 'react';

import { Tier } from '@/common/types';
import { Checkbox } from '@/components/ui/inputs/Checkbox';
import { useMutation } from 'urql';
import { ToggleFeatureFlagResult, toggleFeatureFlagMutation } from '@/mutations/toggleFeatureFlag';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { Env } from '@/common/env';
import isNil from 'lodash/isNil';
import { SmallButton } from '@/components/ui/buttons/SmallButton';

type Checkboxes = {
    [key: string]: boolean;
};

export const FeatureFlags: FC = () => {
    const [, toggleFeatureFlag] = useMutation<ToggleFeatureFlagResult>(toggleFeatureFlagMutation);
    const { flags, setFlags } = useFeatures();

    const [checkboxes, setCheckboxes] = useState<Checkboxes>({});
    const {
        data: { user },
    } = useUser();
    const isProd = Env.tier.value === Tier.Production;

    /*
        Get flags that are allowed in production, and build checkboxes object
        with 'id' as keys and 'isEnabled' as values
    */
    const buildCheckboxes = (): void =>
        setCheckboxes(
            flags
                .filter((flag) => (isProd ? flag.allowInProduction : true))
                .reduce(
                    (obj, flag) => ({
                        ...obj,
                        [flag.id]: flag.isEnabled,
                    }),
                    {}
                )
        );

    /* Update checkboxes object with given value for given flag id */
    const setCheckbox =
        (id: string) =>
        (value: boolean): void => {
            setCheckboxes((current) => ({
                ...current,
                [id]: value,
            }));
        };

    /* Tracks if checkboxes are synced with flags */
    const isUpToDate = (): boolean =>
        flags
            .filter((flag) => (isProd ? flag.allowInProduction : true))
            .every((flag) => checkboxes[flag.id] === flag.isEnabled);

    const updateFlags = (): void => {
        const flagsToUpdate = flags
            /* Exclude flags that: */
            .filter(
                (flag) =>
                    /* Have no checkbox */
                    !isNil(checkboxes[flag.id]) &&
                    /* Have not changed */
                    flag.isEnabled !== checkboxes[flag.id]
            );

        flagsToUpdate.forEach(({ id }) => {
            setFlags((flags) =>
                flags.map((flag) =>
                    flag.id === id ? { ...flag, isEnabled: checkboxes[id] } : flag
                )
            );

            toggleFeatureFlag({
                id,
                isEnabled: checkboxes[id],
                userId: user.id,
                tier: Env.tier.value,
            });
        });
    };

    useEffect(buildCheckboxes, [flags]);

    return (
        <>
            {flags.map(({ id, name }) => (
                <div key={id} className="flag">
                    {isNil(checkboxes[id]) ? (
                        <span>{name}</span>
                    ) : (
                        <Checkbox id={id} isChecked={checkboxes[id]} onChange={setCheckbox(id)}>
                            {name}
                        </Checkbox>
                    )}
                </div>
            ))}
            {!isUpToDate() && <SmallButton onClick={updateFlags}>Save</SmallButton>}
        </>
    );
};
