import { useApolloClient } from '@apollo/client';
import { CellRenderer } from 'leyden-react';
import React, { KeyboardEventHandler } from 'react';
import { useSelected, useSlateStatic } from 'slate-react';

import { Cell } from '../components/Cell';
import { UomSelector } from '../components/UomSelector';
import { useEstimateUoms } from '../../hooks/useEstimateUoms';
import { Transforms } from '../../transforms';
import { uomPostgraphileToApollo } from '@/components/AssemblyPanel/utils/uom';
import { UomRecord } from '@/queries/unitOfMeasure';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const UnitOfMeasure: CellRenderer<'UnitOfMeasure'> = ({ attributes, children, element }) => {
    const client = useApolloClient();
    const { uoms } = useEstimateUoms();
    const editor = useSlateStatic();
    const selected = useSelected();
    const breakpoints = useBreakpoints();
    const flags = useFlags();

    const handleUomSelected = (uom: UomRecord): void => {
        Transforms.deselect(editor);
        const apolloUom = uomPostgraphileToApollo(uom);
        if (apolloUom.id !== element.uom.id) {
            Transforms.setElementUom(editor, apolloUom, element, client);
        }
    };

    const onUomsKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            Transforms.deselect(editor);
        }
    };

    const shouldHideSelector = breakpoints.mobile && flags.mobileAssemblyPanel;

    return (
        <Cell attributes={attributes} caret="hide-inactive" element={element} hasClickAction={true}>
            {element.uom.name}
            {selected && !shouldHideSelector && (
                <UomSelector
                    uoms={uoms}
                    onKeyDown={onUomsKeyDown}
                    onSelectUom={handleUomSelected}
                />
            )}
            {children}
        </Cell>
    );
};
