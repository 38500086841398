/*
 * Information renders all project information within the info module.
 */
import { Entry } from './Entry';
import { DatabaseProjectStatus } from '@/common/types';
import { wageTypeToReadable } from '@/common/utils/helpers';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { Trades } from '@/components/ui/controls/Trades';
import { executeEventsQuery } from '@/queries/events';
import { ProjectRecord } from '@/queries/projects';
import { TradeRecord } from '@/queries/trades';
import { colorBackgroundSecondaryLightest } from '@/variables';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

const COPY = {
    client: 'CLIENT',
    completed: 'COMPLETED',
    due: 'DUE',
    nonUnion: 'Non-Union',
    submitted: 'SUBMITTED',
    trades: 'TRADES',
    union: 'Union',
    wageType: 'WAGE TYPE',
};

// Return the raw UTC date string of the event indicating project completion, if present.
const projectCompletedAt = (project: ProjectRecord): Promise<string | undefined> => {
    if (project.status !== DatabaseProjectStatus.COMPLETE) {
        return Promise.resolve(undefined);
    }
    return executeEventsQuery({ projectId: project.id }).then(
        (result) =>
            result.data?.events.nodes.filter(
                (event) => event.eventTypeName === 'approve_estimate'
            )[0]?.created
    );
};

const formatInfoDateString = (dateString: string | undefined): string | undefined => {
    if (dateString === undefined) {
        return undefined;
    }
    return moment.utc(dateString).format('MMM D, YYYY');
};

const formatTrades = (project: ProjectRecord): JSX.Element => (
    <>
        {project.projectTrades?.nodes && (
            <Trades
                trades={project.projectTrades?.nodes.map(
                    (node: { trade: TradeRecord }) => node.trade
                )}
                howManyToShow={3}
                background={colorBackgroundSecondaryLightest}
            />
        )}
    </>
);

export const Information: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { project } = useProjectPanel();

    const [completedDateString, setCompletedDateString] = useState<string | undefined>();

    useEffect(() => {
        projectCompletedAt(project).then(setCompletedDateString);
    }, [project]);

    return (
        <>
            <Entry label={COPY.client}>{project.team?.name}</Entry>
            <Entry label={COPY.trades}>{formatTrades(project)}</Entry>
            <Entry label={COPY.wageType}>
                {project?.wageType && wageTypeToReadable(project.wageType)}
            </Entry>
            <Entry label={COPY.submitted}>{formatInfoDateString(project.created)}</Entry>
            <Entry label={COPY.due}>{formatInfoDateString(project.bidsDueDate)}</Entry>
            <Entry label={COPY.completed}>{formatInfoDateString(completedDateString)}</Entry>
        </>
    );
};
