import Stack from '@mui/material/Stack';
import { styled as styledByMui } from '@mui/material/styles';

import { breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';

export const InfoContainer = styledByMui('div')({
    display: 'flex',
    flex: 1,

    marginTop: '8px',

    ['& > div']: {
        flex: 1,
        marginRight: '32px',

        ['&:first-child']: {
            marginRight: '16px',
        },
    },

    ...breakpointsCSSObject.medium({
        flexDirection: 'column',
        marginTop: '24px',

        ['& > div']: {
            flex: 1,
            marginRight: '0px',
            marginBottom: '8px',

            ['&:first-of-type']: {
                marginRight: '0px',
                marginBottom: '32px',
            },

            ['&:last-of-type']: {
                marginBottom: '0px',
                marginRight: '0px',
            },
        },
    }),
});

interface CollapsibleSecondaryInfoProps {
    isCollapsed: boolean;
}
export const CollapsibleSecondaryInfo = styledByMui(Stack)<CollapsibleSecondaryInfoProps>(
    (options) => ({
        display: options.isCollapsed ? 'none' : 'initial',
    })
);
