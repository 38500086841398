import { v4 as uuidv4 } from 'uuid';
import { Point } from '@/common/types';
import { IMarkupPointFragment, IMarkupLineFragment, IMarkupPolygonFragment } from '@/graphql';

type Convert<T extends IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment> =
    T extends IMarkupPointFragment
        ? Point
        : T extends IMarkupLineFragment
        ? Point[]
        : T extends IMarkupPolygonFragment
        ? Point[][]
        : never;

type Converter<T extends IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment> = (
    data: T
) => Convert<T>;

const fromMarkupPointFragment: Converter<IMarkupPointFragment> = (point) => {
    return {
        id: uuidv4(),
        x: point.x,
        y: point.y,
    };
};

const fromMarkupLineFragment: Converter<IMarkupLineFragment> = (line) => {
    return line.points.map(fromMarkupPointFragment);
};

const fromMarkupPolygonFragment: Converter<IMarkupPolygonFragment> = (area) => {
    return area.rings.map(fromMarkupLineFragment);
};

export function fromMarkupGeometry<
    T extends IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment
>(data: T): Convert<T>;
export function fromMarkupGeometry(
    data: IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment
) {
    if (data.__typename === 'Coordinate') return fromMarkupPointFragment(data);
    if (data.__typename === 'MarkupPolygon') return fromMarkupPolygonFragment(data);
    else return fromMarkupLineFragment(data);
}
