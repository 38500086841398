import { useAssemblyPanel } from '../../context';
import { ReactComponent as OneBuildIcon } from '@/assets/icons/assemblyLibrary/1b-library.svg';
import { ReactComponent as CurrentIcon } from '@/assets/icons/assemblyLibrary/current.svg';
import { ReactComponent as FavoritesIcon } from '@/assets/icons/assemblyLibrary/favorites.svg';
import { ReactComponent as GeometriesIcon } from '@/assets/icons/assemblyLibrary/geometries.svg';
import { ReactComponent as PastIcon } from '@/assets/icons/assemblyLibrary/past.svg';
import { LibraryCategory } from '@/components/AssemblyPanel/utils/types';
import { fontWeight } from '@/theme/fonts/weight';
import List, { ListProps } from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { FC } from 'react';
import { getVisibleLibraryCategories } from '@/components/AssemblyPanel/AssemblyPanelContent/LibrarySection/utils';

const COPY = {
    oneBuildLibrary: '1build Library',
    favorites: 'My Library',
    currentProject: 'Current Project',
    pastProjects: 'My Projects',
    newAssembly: 'New Assembly',
    geometries: 'Takeoffs',
};

const categoryToContent = (category: LibraryCategory): [Svg, string] => {
    switch (category) {
        case LibraryCategory.OneBuild:
            return [OneBuildIcon, COPY.oneBuildLibrary];
        case LibraryCategory.Favorites:
            return [FavoritesIcon, COPY.favorites];
        case LibraryCategory.CurrentProject:
            return [CurrentIcon, COPY.currentProject];
        case LibraryCategory.PastProjects:
            return [PastIcon, COPY.pastProjects];
        case LibraryCategory.Geometries:
            return [GeometriesIcon, COPY.geometries];
    }
};

export const LibrarySection: FC<ListProps> = (props) => {
    const { isEstimate, openedCategory, setOpenedCategory } = useAssemblyPanel();

    const categories = getVisibleLibraryCategories({ isEstimate });

    return (
        <List
            disablePadding
            sx={{
                borderRight: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
            }}
            {...props}
        >
            {categories.map((category) => {
                const [SvgIcon, text] = categoryToContent(category);

                return (
                    <ListItemButton
                        key={text}
                        selected={openedCategory === category}
                        onClick={(event) => {
                            if (event.defaultPrevented) {
                                return;
                            }

                            setOpenedCategory(category);
                        }}
                        sx={{
                            padding: '8px 16px',
                        }}
                    >
                        <ListItemIcon>
                            <SvgIcon />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                sx: {
                                    fontWeight: fontWeight.bold,
                                },
                            }}
                        >
                            {text}
                        </ListItemText>
                    </ListItemButton>
                );
            })}
        </List>
    );
};
