import React, { FC, useRef, useState } from 'react';
import { Mode } from '@/theme/Mode';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { TransitionProps } from '@mui/material/transitions';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import CellForm from '@/components/Estimate/Table/components/CellDialog/CellForm/CellForm';
import { useEstimateProject } from '@/components/Estimate/hooks/useEstimateProject';
import CellContextMenu from '@/components/Estimate/Table/components/CellDialog/CellContextMenu/CellContextMenu';
import { useCellDialog } from '@/components/Estimate/Table/contexts/useCellDialog';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CellDialog: FC = () => {
    const breakpoints = useBreakpoints();
    const { projectID } = useEstimateProject();
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
    const { options, cellDialogOpen, selectedCell, closeCellDialog } = useCellDialog();
    const ref = useRef<HTMLDivElement>(null);

    if (!breakpoints.mobile) {
        return null;
    }

    const handleCloseDialog = () => {
        closeCellDialog();
        setContextMenuOpen(false);
    };

    return (
        <ScopedCssBaseline>
            <Mode variant="light">
                <Dialog
                    PaperProps={{
                        sx: {
                            // Override default
                            borderRadius: 0,
                        },
                    }}
                    ref={ref}
                    fullScreen
                    open={cellDialogOpen}
                    onClose={handleCloseDialog}
                    TransitionComponent={Transition}
                >
                    {selectedCell && (
                        <>
                            <CellForm
                                projectID={projectID.toString()}
                                onClose={handleCloseDialog}
                                onContextMenuClick={() => setContextMenuOpen((value) => !value)}
                                cell={selectedCell}
                                explicitCellType={options?.explicitCellType}
                            />
                            <CellContextMenu
                                parentRef={ref}
                                cell={selectedCell}
                                open={contextMenuOpen}
                                onClose={() => setContextMenuOpen(false)}
                                onCloseParent={handleCloseDialog}
                            />
                        </>
                    )}
                </Dialog>
            </Mode>
        </ScopedCssBaseline>
    );
};

export default CellDialog;
