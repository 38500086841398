/*
 * Entry is a single project information item within the info module.
 */
import React, { FC } from 'react';

import { InfoEntryContainer, InfoLabel, InfoValue } from './styled';

export interface EntryProps {
    label: string;
}

export const Entry: FC<EntryProps> = ({ label, children }) => (
    <>
        {children !== undefined && (
            <InfoEntryContainer>
                <InfoLabel>{label}</InfoLabel>
                <InfoValue>{children}</InfoValue>
            </InfoEntryContainer>
        )}
    </>
);
