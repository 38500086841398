/*
 * CenteredSpinnerLoader is the spinner loader centered inside of a full-width container.
 */
import React, { FC } from 'react';
import styled from 'styled-components';

import { SpinnerLoader } from './SpinnerLoader';
import { DivProps } from '@/common/types';

const SpinnerLoaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
`;

export const CenteredSpinnerLoader: FC<DivProps> = (props) => (
    <SpinnerLoaderContainer {...props}>
        <SpinnerLoader />
    </SpinnerLoaderContainer>
);
