import { useState } from 'react';

import { Hook, Setter } from '@/common/types';
import { ContextType, makeContext } from '@/common/utils/makeContext';
import { InfoTab, InitialAction, ProjectPanelTab } from '@/components/AdminDashboard/types';
import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectPricing, ProjectRecord } from '@/queries/projects';

export interface FileInProgress {
    filename: string;
    uuid: string;
}

export interface ProjectPanelContextProps {
    activeTab: ProjectPanelTab;
    setActiveTab: Setter<ProjectPanelTab>;
    clickedOutside: boolean;
    setClickedOutside: Setter<boolean>;
    entryIdsMarkedSeen: Set<number>;
    setEntryIdsMarkedSeen: Setter<Set<number>>;
    entryIdsMarkedUnseen: Set<number>;
    setEntryIdsMarkedUnseen: Setter<Set<number>>;
    estimator: BaseUserRecord | undefined;
    setEstimator: Setter<BaseUserRecord | undefined>;
    fileInProgress: FileInProgress | undefined;
    setFileInProgress: Setter<FileInProgress | undefined>;
    fileSearchString: string;
    setFileSearchString: Setter<string>;
    initialAction: InitialAction | undefined;
    setInitialAction: Setter<InitialAction | undefined>;
    modifiedPrice: number | undefined;
    setModifiedPrice: Setter<number | undefined>;
    project: ProjectRecord;
    setProject: Setter<ProjectRecord>;
    selectedEstimator: BaseUserRecord | undefined;
    setSelectedEstimator: Setter<BaseUserRecord | undefined>;
    pricingAssignment: ProjectPricing | undefined;
}

export type ProjectPanelContextType = ContextType<ProjectPanelContextProps>;

export interface ProjectPanelComponentProps {
    useProjectPanel: Hook<ProjectPanelContextProps, void>;
}

export interface OpenedProjectPayload {
    project: ProjectRecord;
    initialAction?: InitialAction;
    tab?: ProjectPanelTab;
}

export const makeProjectContext = (payload: OpenedProjectPayload): ProjectPanelContextType => {
    const {
        project: projectPayload,
        initialAction: initialActionPayload,
        tab: tabPayload,
    } = payload;

    return makeContext<ProjectPanelContextProps>(() => {
        // Active Tab
        // ------------------------------------------------------------------------------------------------------------
        const [activeTab, setActiveTab] = useState<ProjectPanelTab>(tabPayload || InfoTab.Activity);

        const projectPanelActiveTab = { activeTab, setActiveTab };

        // Clicked Outside - true if a click has been detected outside of the panel
        // ------------------------------------------------------------------------------------------------------------
        const [clickedOutside, setClickedOutside] = useState(false);

        const projectPanelClickedOutside = { clickedOutside, setClickedOutside };

        // Entry Ids flagged to be marked seen upon exiting the activity module
        // ------------------------------------------------------------------------------------------------------------
        const [entryIdsMarkedSeen, setEntryIdsMarkedSeen] = useState<Set<number>>(new Set());

        const projectPanelEntryIdsMarkedSeen = { entryIdsMarkedSeen, setEntryIdsMarkedSeen };

        // Entry Ids flagged to be marked unseen upon exiting the activity module
        // ------------------------------------------------------------------------------------------------------------
        const [entryIdsMarkedUnseen, setEntryIdsMarkedUnseen] = useState<Set<number>>(new Set());

        const projectPanelEntryIdsMarkedUnseen = { entryIdsMarkedUnseen, setEntryIdsMarkedUnseen };

        // Estimator
        // ------------------------------------------------------------------------------------------------------------
        const [estimator, setEstimator] = useState<BaseUserRecord | undefined>(
            projectPayload.projectUsers?.nodes[0]?.user
        );

        const projectPanelEstimator = { estimator, setEstimator };

        // Initial Action (taken when panel opens)
        // ------------------------------------------------------------------------------------------------------------
        const [initialAction, setInitialAction] = useState<InitialAction | undefined>(
            initialActionPayload
        );

        const projectPanelInitialAction = { initialAction, setInitialAction };

        // File in Progress (being uploaded)
        // ------------------------------------------------------------------------------------------------------------
        const [fileInProgress, setFileInProgress] = useState<FileInProgress | undefined>();

        const projectPanelFileInProgress = { fileInProgress, setFileInProgress };

        // File Search String
        // ------------------------------------------------------------------------------------------------------------
        const [fileSearchString, setFileSearchString] = useState('');

        const projectPanelFileSearchString = { fileSearchString, setFileSearchString };

        // Modified Price (undefined unless user is adjusting project price)
        // ------------------------------------------------------------------------------------------------------------
        const [modifiedPrice, setModifiedPrice] = useState<number | undefined>();

        const projectPanelModifiedPrice = { modifiedPrice, setModifiedPrice };

        // Project
        // ------------------------------------------------------------------------------------------------------------
        const [project, setProject] = useState<ProjectRecord>(projectPayload);

        const projectPanelProject = { project, setProject };

        // Selected Estimator (for estimator assignment)
        // ------------------------------------------------------------------------------------------------------------
        const [selectedEstimator, setSelectedEstimator] = useState<BaseUserRecord | undefined>();

        const projectPanelSelectedEstimator = { selectedEstimator, setSelectedEstimator };

        // Project price
        // ------------------------------------------------------------------------------------------------------------
        const [pricingAssignment, setPricingAssignment] = useState<ProjectPricing | undefined>(
            projectPayload &&
                projectPayload.pricingAssignment?.projectPricingsByPricingAssignmentId &&
                projectPayload.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0]
        );

        const projectPricingAssignment = { pricingAssignment, setPricingAssignment };

        // Combined Project Panel
        // ------------------------------------------------------------------------------------------------------------
        return {
            ...projectPanelActiveTab,
            ...projectPanelClickedOutside,
            ...projectPanelEntryIdsMarkedSeen,
            ...projectPanelEntryIdsMarkedUnseen,
            ...projectPanelEstimator,
            ...projectPanelFileInProgress,
            ...projectPanelFileSearchString,
            ...projectPanelInitialAction,
            ...projectPanelModifiedPrice,
            ...projectPanelProject,
            ...projectPanelSelectedEstimator,
            ...projectPricingAssignment,
        };
    });
};
