import styled from 'styled-components';

import { Collapsible } from '@/components/ui/containers/Collapsible';
import { collapsibleCss, inputCss, projectCollapsibleCss } from '../../utils/styles';

export const MarkupsContainer = styled(Collapsible)`
    ${collapsibleCss}
    ${projectCollapsibleCss}

    min-width: 320px;

    @media print {
        display: none;
    }
`;

export const MarkupsTitle = styled.h1`
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    color: #fff;
`;

export const MarkupsBody = styled.div`
    display: grid;
    gap: 13px;
    padding-top: 32px;
`;

export const MarkupsBodyHeader = styled.span`
    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};
    font-size: 16px;
    font-weight: 400;
    color: #cdd9ea;
`;

interface MarkupsBodyRowProps {
    hasDeleteButton?: boolean;
}
export const MarkupsBodyRow = styled.span<MarkupsBodyRowProps>`
    display: grid;
    gap: 3px;
    border-radius: 2px;
    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};
    font-size: 14px;
    font-weight: 400;

    grid-template-columns:
        [description] max(calc(35% - 8px), 120px) [percentage] calc(32% - 8px) [total] calc(
            32% - 8px
        )
        [cancel] 36px;
`;

type MarkupBlankInputProps = {
    isAdd?: boolean;
};

export const MarkupBlankInput = styled.span<MarkupBlankInputProps>`
    ${inputCss};

    color: ${(props): string => (props.isAdd ? '#45D8FA' : '#D7E1E5')};

    overflow: hidden;
    border-radius: 0;
    cursor: pointer;

    &:first-of-type {
        border-radius: 2px 0px 0px 2px;
    }

    &:last-of-type {
        border-radius: 0px 2px 2px 0px;
    }
`;

export const MarkupsDisclaimer = styled.p`
    font-size: 12px;
    color: #a5b3b9;
    margin-top: 20px;
`;
