import { LeydenEditor, Table } from 'leyden';
import { useLeyden } from 'leyden-react';
import React, { FC, MouseEventHandler, useMemo } from 'react';

import { BufferCell } from './styled';
import { useEstimateBuffer } from '../hooks/useEstimateBuffer';

export interface BottomBuffer {
    minTableRows?: number;
    minBufferRows?: number;
}

export const BottomBuffer: FC<BottomBuffer> = ({ minTableRows = 15, minBufferRows = 2 }) => {
    const { recordBufferCellMouseEnter, recordBufferCellMouseLeave } = useEstimateBuffer();
    const editor = useLeyden();

    const { columns, rows } = Table.dimensions(LeydenEditor.table(editor));

    const bufferRowCount = useMemo(() => {
        return Math.max(minBufferRows, minTableRows - rows);
    }, [minBufferRows, rows]);

    const getTableRow = (rowInBuffer: number) => rowInBuffer + rows;

    // Prevent unpredictable selection actions when buffer cells are clicked.
    const onBufferCellMouseDown: MouseEventHandler = (event) => {
        if (!event.defaultPrevented) {
            event.preventDefault();
        }
    };

    return (
        <>
            {Array.from({ length: bufferRowCount }).map((_, rowInBuffer) =>
                Array.from({ length: columns }).map((_, column) => (
                    <BufferCell
                        contentEditable={false}
                        key={`${rowInBuffer}-${column}`}
                        column={column}
                        row={getTableRow(rowInBuffer)}
                        onMouseDown={onBufferCellMouseDown}
                        onMouseEnter={recordBufferCellMouseEnter}
                        onMouseLeave={recordBufferCellMouseLeave}
                    />
                ))
            )}
        </>
    );
};
