import { EstimateOptionsMenuProps, OptionsMenu } from './OptionsMenu';
import {
    AddElementOption,
    ElementInput,
    InnerContainer,
    NewElementIcon,
    OuterContainer,
} from './styled';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { useMaterialData } from '@/common/hooks/useMaterialData';
import { InputEvent, InputProps } from '@/common/types';
import { PlusIcon } from '@/components/ui/icons/PlusIcon';
import {
    IMaterialExcludingValues,
    IMaterialLightFragment,
    ISourcePreviewFragment,
    ISourceTypeV2,
} from '@/graphql';
import { UomType } from '@/queries/unitOfMeasure';
import React, {
    FC,
    forwardRef,
    MouseEventHandler,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export {
    InnerContainer,
    OptionsMenuContainer,
    OptionsMenuOptionContainer,
    OuterContainer,
} from './styled';

const COPY = {
    newElement: 'New element',
};

type SimpleInput = Omit<InputProps, 'onChange' | 'onSelect' | 'onBlur' | 'value'>;

export type ElementSearchProps = {
    uomType: UomType;
    onBlur?: () => void;
    onSelect: (value: Partial<IMaterialLightFragment>) => void;
    isEstimate?: boolean;
} & SimpleInput;

const genNewMaterial = (name: string): ISourcePreviewFragment => ({
    id: '',
    name: name,
    sourceType: ISourceTypeV2.Material,
});

export const ElementSearch: FC<ElementSearchProps> = forwardRef<
    HTMLInputElement,
    ElementSearchProps
>(({ uomType, onBlur, onSelect, isEstimate, ...rest }, inputRef) => {
    const [activeOptionIdx, setActiveOptionIdx] = useState<number | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFocusedOut, setIsFocusedOut] = useState(false);
    const [optionsMenuPxLeftToScroll, setOptionsMenuPxLeftToScroll] = useState(-1);
    const [fetchingNextPage, setFetchingNextPage] = useState(false);

    const {
        loading,
        materials: options,
        searchTerm,
        fetchNextPage,
        setSearchTerm,
    } = useMaterialData({
        exclude: IMaterialExcludingValues.User,
    });

    useEffect(() => {
        if (
            !fetchNextPage ||
            fetchingNextPage ||
            options.length === 0 ||
            optionsMenuPxLeftToScroll === -1 ||
            optionsMenuPxLeftToScroll > 500
        ) {
            return;
        }
        setFetchingNextPage(true);
        fetchNextPage().finally(() => {
            setFetchingNextPage(false);
        });
    }, [fetchNextPage, fetchingNextPage, optionsMenuPxLeftToScroll, options]);

    // Select an existing option.
    const onSelectOption = useCallback(
        (opt: IMaterialLightFragment) => {
            onSelect(opt);
        },
        [onSelect]
    );

    // Select a new option generated from the current search term.
    const onSelectNewOption = useCallback(
        () => onSelect(genNewMaterial(searchTerm)),
        [searchTerm, uomType, onSelect]
    );

    const optionsLength = useMemo(
        () => (isEstimate ? options.length + 1 : options.length),
        [options, isEstimate]
    );

    const keyDownActions = useMemo(
        () =>
            new Map<string, () => void>([
                [
                    'ArrowDown',
                    (): void => {
                        if (options.length === 0) {
                            return;
                        }
                        setActiveOptionIdx((oldActiveOptionIdx) => {
                            if (oldActiveOptionIdx === null) {
                                return 0;
                            }
                            return (oldActiveOptionIdx + 1) % optionsLength;
                        });
                    },
                ],
                [
                    'ArrowUp',
                    (): void => {
                        if (options.length === 0) {
                            return;
                        }
                        setActiveOptionIdx((oldActiveOptionIdx) => {
                            if (oldActiveOptionIdx === null) {
                                return options.length - 1;
                            }
                            return (oldActiveOptionIdx + optionsLength - 1) % optionsLength;
                        });
                    },
                ],
                [
                    'Enter',
                    (): void => {
                        if (activeOptionIdx === null) {
                            onSelectNewOption();
                            return;
                        }
                        const activeOption = options[activeOptionIdx];
                        if (activeOption) {
                            onSelectOption(activeOption);
                        }
                    },
                ],
                [
                    'Escape',
                    (): void => {
                        if (onBlur) {
                            onBlur();
                        }
                    },
                ],
                [
                    'PageDown',
                    (): void => {
                        if (options.length === 0) {
                            return;
                        }
                        setActiveOptionIdx(optionsLength - 1);
                    },
                ],
                [
                    'PageUp',
                    (): void => {
                        if (options.length === 0) {
                            return;
                        }
                        setActiveOptionIdx(0);
                    },
                ],
            ]),
        [activeOptionIdx, options, optionsLength, onBlur, onSelectNewOption]
    );

    useDocumentListener(
        'keydown',
        (e) => {
            // It seems that another component is calling `preventDefault()` on the `Escape` keydown
            // event, but it could not be isolated even after extensive investigating and testing.
            if (e.defaultPrevented && e.key !== 'Escape') {
                return;
            }
            const action = keyDownActions.get(e.key);
            if (action) {
                e.preventDefault();
                action();
            }
        },
        [keyDownActions]
    );

    // When the material list changes, reset selection.
    useEffect(() => {
        setActiveOptionIdx(null);
    }, [options]);

    // When focus state changes, apply latency so that onBlur event
    // does not prevent nested onClick.
    useEffect(() => {
        let sto: ReturnType<typeof setTimeout>;

        if (isFocusedOut) {
            setIsFocused(true);
        } else {
            sto = setTimeout(() => {
                setIsFocused(false);
            }, 200);
        }

        return (): void => {
            clearTimeout(sto);
        };
    }, [isFocusedOut]);

    const handleInnerContainerClick: MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.isDefaultPrevented()) {
            return;
        }
        (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
    };

    const estimateOptionsMenuProps: EstimateOptionsMenuProps = isEstimate
        ? {
              additionalOption: {
                  jsx: (
                      <AddElementOption>
                          <NewElementIcon />
                          {COPY.newElement}
                      </AddElementOption>
                  ),
                  onClick: onSelectNewOption,
              },
              overrideColors: {
                  default: '#FFF',
                  hover: '#334AD7',
              },
          }
        : {};

    return (
        <OutsideClickHandler disabled={!onBlur} onOutsideClick={(): void => onBlur?.()}>
            <OuterContainer>
                <InnerContainer
                    onClick={handleInnerContainerClick}
                    onFocus={(): void => setIsFocusedOut(true)}
                    onBlur={(): void => setIsFocusedOut(false)}
                >
                    <ElementInput
                        autoFocus
                        value={searchTerm}
                        onChange={(e: InputEvent): void => {
                            setSearchTerm(e.target.value);
                        }}
                        ref={inputRef}
                        {...rest}
                    />
                    {/** If there is no additional option mechanism */}
                    {searchTerm !== '' && !isEstimate && (
                        <PlusIcon
                            className="icon"
                            diameter="1.25rem"
                            style={{
                                alignSelf: 'center',
                                cursor: 'pointer',
                                justifySelf: 'center',
                                gridArea: 'plus',
                            }}
                            onClick={onSelectNewOption}
                        />
                    )}
                    {!!(isFocused && (searchTerm || options.length)) && (
                        <OptionsMenu
                            activeOptionIdx={activeOptionIdx}
                            setActiveOptionIdx={setActiveOptionIdx}
                            loading={loading}
                            options={options}
                            onSelectOption={onSelectOption}
                            setPxLeftToScroll={setOptionsMenuPxLeftToScroll}
                            {...estimateOptionsMenuProps}
                        />
                    )}
                </InnerContainer>
            </OuterContainer>
        </OutsideClickHandler>
    );
});
