import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { NoFilesModal } from '@/components/NoFilesModal';
import { SetupPanel } from '@/components/projects/ProjectDetails/ProjectToolbars/Details/Panels/SetupPanel';
import { TakeoffRoot } from '@/components/takeoff';
import { TakeoffContextType, makeTakeoffContext } from '@/components/takeoff/context';
import { useProjectHasFiles } from '@/components/takeoff/hooks/useProjectHasFiles';
import { useTakeoffSetup } from '@/components/takeoff/hooks/useTakeoffSetup';
import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Masthead } from './Masthead';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import { useUser } from '@/contexts/User';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isWaitingForForClientApproval } from './Projects/utils';
import { useSnackbar } from 'notistack';
import { useLocalStorage } from '@rehooks/local-storage';
import { getStorageKeyByProjectUUID, StorageKey } from '@/common/storage';

const COPY = {
    waitingForClientApprovalMessage:
        'Project is still awaiting approval from builder. Work should not begin until client approves and pays for the project.',
};

export const Takeoff: FC = () => {
    const { project, projectLocation, projectNotFound, projectParamUUID } = useProjectFromParams();
    const { enqueueSnackbar } = useSnackbar();

    const [takeoffContext, setTakeoffContext] = useState<TakeoffContextType | null>(null);

    const [filesIngesting, setFilesIngesting] = useLocalStorage(
        getStorageKeyByProjectUUID(projectParamUUID ?? '', StorageKey.HasUploadedFiles),
        false
    );

    const {
        data: { user },
    } = useUser();

    const flags = useFlags();

    const {
        showProjectSetup,
        setShowProjectSetup,
        setupErrors,
        setupInfo,
        handleProjectSetupSubmit,
        setSetupInfo,
        setSetupErrors,
    } = useTakeoffSetup({ project, projectLocation });

    const { hasFiles, fetching: pagesFetching } = useProjectHasFiles({ project });

    useEffect(() => {
        if (!project) return;

        if (takeoffContext === null) {
            setTakeoffContext(makeTakeoffContext(project));
        }

        if (isWaitingForForClientApproval(flags, project, user)) {
            enqueueSnackbar(COPY.waitingForClientApprovalMessage, {
                variant: 'warning',
                autoHideDuration: 5000,
            });
        }
    }, [enqueueSnackbar, flags, project, takeoffContext, user]);

    useEffect(() => {
        if (filesIngesting && hasFiles) {
            setFilesIngesting(false);
        }
    }, [filesIngesting, hasFiles, setFilesIngesting]);

    if (projectNotFound) {
        return <Redirect to="/projects" />;
    }

    return (
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                height: 'calc(100vh - 64px)',
            }}
        >
            {(() => {
                if (takeoffContext === null || projectLocation === undefined || pagesFetching) {
                    return (
                        <Box
                            sx={{
                                flex: 1,
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    );
                }

                if (project && !(hasFiles || filesIngesting)) {
                    return (
                        <NoFilesModal
                            open
                            project={project}
                            onFilesUploaded={() => setFilesIngesting(true)}
                        />
                    );
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1',
                        }}
                    >
                        <Masthead onSetupProjectButtonClick={() => setShowProjectSetup(true)} />
                        <takeoffContext.Provider>
                            <TakeoffRoot
                                useTakeoff={takeoffContext.useConsumer}
                                filesLoading={filesIngesting}
                            />
                        </takeoffContext.Provider>
                        <Drawer
                            anchor="right"
                            open={showProjectSetup && Boolean(setupInfo)}
                            PaperProps={{
                                elevation: 0,
                                square: true,
                                sx: {
                                    borderRadius: 0,
                                    padding: 0,
                                    width: '400px',
                                },
                            }}
                        >
                            <SetupPanel
                                onClose={() => setShowProjectSetup(false)}
                                onSubmit={handleProjectSetupSubmit}
                                setupInfo={setupInfo}
                                setSetupInfo={setSetupInfo}
                                setupErrors={setupErrors}
                                setSetupErrors={setSetupErrors}
                            />
                        </Drawer>
                    </Box>
                );
            })()}
        </Paper>
    );
};
