import { Cell, CellType } from 'leyden';

import { Serialize } from '.';
import { categoryFromFragments } from '../utils/transforms';
import { EstimateElement, EstimateElementCategory } from '../utils/types';
import { IAssemblyFragment, IElementFragment } from '@/graphql';

export interface SerializeCells {
    empty: (length: number, categoryID: string | null) => Cell<'Empty'>[];
    rowFromElement: (element: EstimateElement, categoryID: string | null) => Cell<CellType>[];
    rowsFromAssembly: (
        assembly: IAssemblyFragment,
        elements?: IElementFragment[]
    ) => Cell<CellType>[];
    rowsFromCategory: (category: EstimateElementCategory) => Cell<CellType>[];
    rowsFromCategories: (categories: EstimateElementCategory[]) => Cell<CellType>[];
    rowsFromElements: (element: EstimateElement[], categoryID: string | null) => Cell<CellType>[];
}

export const SerializeCells: SerializeCells = {
    empty(length: number, categoryID: string | null): Cell<'Empty'>[] {
        return Array.from({ length }, () => Serialize.Cell.Empty({ categoryID }));
    },

    rowFromElement(element: EstimateElement, categoryID: string | null): Cell<CellType>[] {
        return [
            Serialize.Cell.Name({
                name: element.name,
                elementID: element.id,
                elementUUID: element.uuid,
                categoryID,
                favorited: !!element.favorited,
            }),
            Serialize.Cell.Quantity({ element, categoryID }),
            Serialize.Cell.UnitOfMeasure({
                uom: element.unit,
                elementID: element.id,
                elementUUID: element.uuid,
                categoryID,
            }),
            Serialize.Cell.Rate({
                laborRate: element.laborRate,
                materialRate: element.materialRate,
                productionRate: element.productionRate,
                equipmentRate: element.equipmentRate,
                elementID: element.id,
                elementUUID: element.uuid,
                categoryID,
                name: element.name,
            }),
            Serialize.Cell.Total({
                categoryID,
                elementID: element.id,
                elementUUID: element.uuid,
                tradeName: element.trade?.name ?? null,
            }),
        ];
    },

    rowsFromAssembly(
        assembly: IAssemblyFragment,
        elements: IElementFragment[] = []
    ): Cell<CellType>[] {
        const category = categoryFromFragments(assembly, elements);
        return Serialize.Cells.rowsFromCategory(category);
    },

    rowsFromCategory(category: EstimateElementCategory): Cell<CellType>[] {
        const categoryRow = [
            Serialize.Cell.Category({
                name: category.name,
                id: category.id,
                uuid: category.uuid,
                favorited: category.favorited,
            }),
            ...Serialize.Cells.empty(Serialize.Constants.COLUMN_COUNT - 2, null),
            Serialize.Cell.CategoryTotal({ id: category.id }),
        ];
        return [
            ...categoryRow,
            ...Serialize.Cells.rowsFromElements(category.elements, category.id),
            Serialize.Cell.Actions({ categoryID: category.id }),
            ...Serialize.Cells.empty(Serialize.Constants.COLUMN_COUNT - 1, category.id),
        ];
    },

    rowsFromCategories(categories: EstimateElementCategory[]): Cell<CellType>[] {
        return categories.flatMap(Serialize.Cells.rowsFromCategory);
    },

    rowsFromElements(elements: EstimateElement[], categoryID: string | null): Cell<CellType>[] {
        return elements.flatMap((element) => Serialize.Cells.rowFromElement(element, categoryID));
    },
};
