import React, { FC, useEffect, useState } from 'react';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';

import { ISourceTypeV2, useCostDataSourcesPreviewQuery } from '@/graphql';
import { ListItemButtonSourceNode } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/ListItemButtonSourceNode';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import { useMaterials } from '@/components/AssemblyPanel/hooks/useMaterials';
import { isNil } from 'lodash';

interface SourcesListProps extends ListProps {
    sourceType: ISourceTypeV2;
    currentProjectId: number;
    category?: string;
    pageSize?: number;
}

const defaultPageSize = 20;

export const SourcesList: FC<SourcesListProps> = ({
    category,
    currentProjectId,
    pageSize,
    sourceType,
}) => {
    const { localizationCounty, localizationState, search } = useAssemblyPanel();

    const { importPreviewSourceToProject } = useMaterials({
        projectID: currentProjectId.toString(),
    });

    const state = localizationState.localization?.name ?? 'N/A';
    const county = localizationCounty.localization?.namelsad ?? 'N/A';

    const limit = pageSize ?? defaultPageSize;
    const input = {
        sourceType,
        state,
        county,
        searchTerm: search,
        filter: category,
        page: {
            limit: limit,
            offset: 0,
        },
    };
    const {
        data,
        loading: loadingSources,
        fetchMore,
    } = useCostDataSourcesPreviewQuery({
        variables: { input },
    });

    const [sourcesData, setSourcesData] = useState(data?.legacySources);
    useEffect(() => setSourcesData(data?.legacySources), [data]);

    const loadMore = async () => {
        const currNodes = sourcesData?.nodes ?? [];
        const offset = currNodes?.length ?? 0;
        const nextData = await fetchMore({
            variables: {
                input: {
                    ...input,
                    page: { limit, offset },
                },
            },
        });

        const nextSources = nextData.data.legacySources;
        if (nextSources) {
            const nextNodes = nextSources.nodes ?? [];
            nextSources.nodes = [...currNodes, ...nextNodes];
            setSourcesData({ ...nextSources });
            return;
        }

        if (!isNil(sourcesData)) {
            setSourcesData({ ...sourcesData, pageInfo: { hasNextPage: false } });
            return;
        }
    };

    if (loadingSources) {
        return (
            <List disablePadding>
                {Array(2)
                    .fill(1)
                    .map((_, index) => (
                        <ListItem dense key={index} sx={{ paddingLeft: '35px' }}>
                            <Skeleton sx={{ height: '16px', margin: '2px 0', width: '100%' }} />
                        </ListItem>
                    ))}
            </List>
        );
    }

    return (
        <>
            {sourcesData?.nodes?.map((source) => (
                <ListItemButtonSourceNode
                    key={source.id}
                    disabled={false}
                    onClick={() => importPreviewSourceToProject(source)}
                    sx={{ paddingLeft: '35px' }}
                >
                    {source.name}
                </ListItemButtonSourceNode>
            ))}
            {sourcesData?.pageInfo?.hasNextPage && (
                <ListItemButton
                    disabled={loadingSources}
                    onClick={loadMore}
                    sx={{ justifyContent: 'center' }}
                >
                    {loadingSources && <CircularProgress size={20} />}
                    {!loadingSources && (
                        <ListItemText
                            primaryTypographyProps={{
                                color: 'primary',
                                textAlign: 'center',
                                variant: 'body2',
                            }}
                        >
                            Load more
                        </ListItemText>
                    )}
                </ListItemButton>
            )}
        </>
    );
};
