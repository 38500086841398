import {
    colorBackgroundSecondaryLightest,
    colorTertiaryLighter,
    colorTypographyDark,
    transition,
} from '@/variables';
import { darken, lighten } from 'polished';
import styled, { FlattenInterpolation, ThemedStyledProps, css } from 'styled-components';

export const variables = {
    buttonLightBackground: colorBackgroundSecondaryLightest,
    buttonPrimaryBorder: '#D7E1E5',
    buttonHighlight: '#334AD7',
    colorTypographyDark,
    colorTertiaryLighter,
    iconCancel: '#4D5866',
    safety: '#ED7054',
    shingle: '#5B606E',
};

export const Backdrop = styled.div`
    display: block;
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1101;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.5;
`;

type HeaderSectionProps = { noBorder?: boolean };
export const HeaderSection = styled.div<HeaderSectionProps>`
    position: relative;
    box-sizing: border-box;
    padding-left: 15px;
    width: 100%;
    border-bottom: ${(props): string =>
        props.noBorder ? 'none' : `1px solid ${colorTertiaryLighter}`};
`;

export const PoppinsHeader = styled.h1`
    color: ${colorTypographyDark};
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
`;

type ButtonProps = {
    type?: 'primary' | 'secondary';
    color: string;
    backgroundColor: string;
    borderColor: string;
    disabled?: boolean;
};
const isActiveButtonCss = css<ButtonProps>`
    &:active,
    &:hover {
        background-color: ${(props): string =>
            props.type === 'primary'
                ? props.backgroundColor
                : lighten(0.08, props.backgroundColor)};
        border-color: ${(props): string =>
            props.type === 'primary' ? props.borderColor : lighten(0.08, props.borderColor)};
    }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const Button = styled.div<ButtonProps>`
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    cursor: pointer;
    transition: ${transition};
    border-radius: ${(props): number => (props.type === 'primary' ? 8 : 38)}px;
    width: ${(props): number => (props.type === 'primary' ? 121 : 125)}px;
    font-weight: ${(props): number => (props.type === 'primary' ? 400 : 700)};
    text-transform: ${(props): string => (props.type === 'primary' ? 'inherit' : 'uppercase')};

    color: ${(props): string => props.color};
    background-color: ${(props): string =>
        props.disabled ? darken(0.08, props.backgroundColor) : props.backgroundColor};
    border: ${(props): string => `1px solid ${props.borderColor}`};

    ${(props): FlattenInterpolation<ThemedStyledProps<ButtonProps, any>> | string =>
        !props.disabled ? isActiveButtonCss : ''}
`;
/* eslint-enable @typescript-eslint/no-explicit-any */

type ButtonGroupProps = { type?: 'primary' | 'secondary'; marginTop?: boolean };
export const ButtonGroup = styled.div<ButtonGroupProps>`
    display: flex;
    padding: 0 0 6px 0;
    margin-top: ${(props): string => (props.marginTop ? 'auto' : '0px')};

    & > ${Button} {
        margin-right: ${(props): number => (props.type === 'secondary' ? 14.75 : 13)}px;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const Modal = styled.div`
    max-height: fit-content;
    padding: 7px 30px 17px;

    width: 574px;

    background-color: ${colorBackgroundSecondaryLightest};
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 1102;
    display: flex;
    flex-direction: column;

    & > p {
        color: ${variables.shingle};
        font-style: normal;
        line-height: 24px;

        font-weight: 500;
        font-size: 1rem;

        margin: 1rem 0 3rem;
    }

    & > ${ButtonGroup} {
        margin-left: auto;
    }
`;

export const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`;
