import { TextFieldProps } from '@mui/material/TextField';

import { ApplyCredit } from './Forms/ApplyCredit';
import { ConnectUserToAccount } from './Forms/ConnectUserToAccount';
import { ConnectToSubscription } from './Forms/ConnectoToSubscription';
import { ResetTrial } from './Forms/ResetTrial';
import { Action } from './types';

import { IUserRole } from '@/graphql';

export type RoleSelect = {
    label: string;
    name: IUserRole[];
};

export const COPY = {
    resetTrial: 'Reset Trial',
    connectTrialToSub: 'Connect Trial to Subscription',
    connectUserToAccount: 'Connect User to Account',
    applyCredit: 'Apply Credit to Account',
    submit: 'Submit',

    emailAddress: 'Email Address',
    trialUserId: 'Trial User ID',
    oldTrialTeamId: 'Trial (old) Team ID',
    newTargetTeamId: 'Target (New) Team ID',
    teamId: 'Team ID',
    role: 'Role',
    creditValue: 'Credit value (USD)',

    apiCallSuccess: 'Updated successfully',
    apiCallError: 'There has been an error',

    required: 'This field is required',
};

export const ACTION_FORMS: Action[] = [
    {
        text: COPY.resetTrial,
        component: ResetTrial,
        allowedRoles: [IUserRole.Admin, IUserRole.Superadmin],
    },
    {
        text: COPY.connectTrialToSub,
        component: ConnectToSubscription,
        allowedRoles: [IUserRole.Admin, IUserRole.Superadmin],
    },
    {
        text: COPY.connectUserToAccount,
        component: ConnectUserToAccount,
        allowedRoles: [IUserRole.Admin, IUserRole.Superadmin],
    },
    {
        text: COPY.applyCredit,
        component: ApplyCredit,
        allowedRoles: [IUserRole.Superadmin],
    },
];

// will be modified when BE is ready
export const ROLES: RoleSelect[] = [
    { label: 'Builder', name: [IUserRole.Builder] },
    { label: 'Builder+Estimator', name: [IUserRole.Builder, IUserRole.Estimator] },
];

export const InputProps: TextFieldProps = {
    margin: 'dense',
    fullWidth: true,
};
