import React, { FC } from 'react';
import { Dot, DotsContainer } from './styled';

type DotsProps = {
    onClick: () => void;
};

export const Dots: FC<DotsProps> = ({ onClick }) => {
    return (
        <DotsContainer onClick={onClick}>
            <Dot />
            <Dot />
            <Dot />
        </DotsContainer>
    );
};
