import styled from 'styled-components';

import { MarkupsBodyRow } from '../styled';

import { ReactComponent as Close } from '@/assets/icons/close-cross.svg';

export const Delete = styled(Close)`
    & > path {
        fill: #cdd9ea;
    }
`;

export const DeleteWrapper = styled.div`
    width: 24px;
    height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
`;

export const Row = styled(MarkupsBodyRow)`
    & ${Delete} {
        opacity: 0;
    }

    &:hover {
        & ${Delete} {
            opacity: 1;
        }
    }
`;
