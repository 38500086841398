import { newMutations } from '../frog';
import { ProjectAssemblyRecord, projectAssemblyFragment } from '../queries/projectAssemblies';

export type FlatProjectAssemblyRecord = Omit<ProjectAssemblyRecord, 'assembly' | 'takeoff'>;

export type CreateProjectAssemblyArgs = {
    input: {
        projectAssembly: FlatProjectAssemblyRecord;
    };
};

export type CreateProjectAssemblyResult = {
    createProjectAssembly: {
        projectAssembly: ProjectAssemblyRecord;
    };
};

export const createProjectAssemblyMutation = projectAssemblyFragment.gql`
    mutation CreateProjectAssembly(
        $input: CreateProjectAssemblyInput!
    ) {
        createProjectAssembly(input: $input) {
            projectAssembly {
                ${projectAssemblyFragment}
            }
        }
    }
`;

export const [executeCreateProjectAssemblyMutation, useCreateProjectAssemblyMutation] =
    newMutations<CreateProjectAssemblyArgs, CreateProjectAssemblyResult>(
        createProjectAssemblyMutation
    );

export type DeleteProjectAssemblyArgs = {
    input: {
        id: string;
    };
};

export type DeleteProjectAssemblyResult = {
    deleteProjectAssembly: {
        projectAssembly: ProjectAssemblyRecord;
    };
};

export const deleteProjectAssemblyMutation = projectAssemblyFragment.gql`
    mutation DeleteProjectAssembly(
        $input: DeleteProjectAssemblyInput!
    ) {
        deleteProjectAssembly(input: $input) {
            projectAssembly {
                ${projectAssemblyFragment}
            }
        }
    }
`;

export const [executeDeleteProjectAssemblyMutation, useDeleteProjectAssemblyMutation] =
    newMutations<DeleteProjectAssemblyArgs, DeleteProjectAssemblyResult>(
        deleteProjectAssemblyMutation
    );
