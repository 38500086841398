/* Render SVG Icon with given src, width and height */

import React, { FC } from 'react';
import clsx from 'clsx';

import { SvgProps, Svg } from '@/common/types';

import './SvgIcon.scss';

export type SvgIconProps = SvgProps & {
    src: Svg;
    noInherit?: boolean;
};

export const SvgIcon: FC<SvgIconProps> = ({ src: Icon, noInherit = false, className, ...rest }) => (
    <Icon className={clsx('svg-icon', className, { 'no-inherit': noInherit })} {...rest} />
);
