import { ElementRenderers } from 'leyden-react';

import { ElementQuantityDefaultView } from './ElementQuantityDefaultView';
import { ElementQuantityExpressionInputView } from './ElementQuantityExpressionInputView';
import { ElementQuantityNumericInputView } from './ElementQuantityNumericInputView';
import { Expression } from './Expression';
import { ExpressionMarkup } from './ExpressionMarkup';
import { ExpressionMarkupGroup } from './ExpressionMarkupGroup';
import { ExpressionNumber } from './ExpressionNumber';
import { ExpressionOperator } from './ExpressionOperator';
import { ExpressionParenthesis } from './ExpressionParenthesis';
import { ExpressionPlaceholder } from './ExpressionPlaceholder';
import { ExpressionVariable } from './ExpressionVariable';

export const elementRenderers: ElementRenderers = {
    ElementQuantityDefaultView,
    ElementQuantityExpressionInputView,
    ElementQuantityNumericInputView,
    Expression,
    ExpressionMarkup,
    ExpressionMarkupGroup,
    ExpressionNumber,
    ExpressionOperator,
    ExpressionParenthesis,
    ExpressionPlaceholder,
    ExpressionVariable,
};
