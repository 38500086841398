import { ElementRenderer } from 'leyden-react';
import React, { useEffect } from 'react';

import { ExpressionWrapper } from './styled';
import {
    ExpressionOpenedState,
    useEstimationExpressionOpenedState,
} from '@/components/app/router/EstimationRoute/hooks/useEstimationExpressionOpenedState';

export const Expression: ElementRenderer<'Expression'> = ({ attributes, children, element }) => {
    const { setExpressionOpenedState } = useEstimationExpressionOpenedState();

    useEffect(() => {
        setExpressionOpenedState(
            element.children.some((child) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ['ExpressionMarkup', 'ExpressionMarkupGroup'].includes(child.type)
            )
                ? ExpressionOpenedState.FormulaOpen
                : ExpressionOpenedState.FormulaOpenWithHelper
        );
        return () => setExpressionOpenedState(ExpressionOpenedState.Closed);
    }, [element]);

    return <ExpressionWrapper {...attributes}>{children}</ExpressionWrapper>;
};
