import { ElementRenderer, useLeyden } from 'leyden-react';
import React, { useEffect } from 'react';
import { Path } from 'slate';
import { ReactEditor } from 'slate-react';

import { ExpressionPlaceholderContainer } from './styled';
import { Transforms } from '../../transforms';

const COPY = {
    placeholder: 'Select a takeoff...',
};

export const ExpressionPlaceholder: ElementRenderer<'ExpressionPlaceholder'> = ({
    attributes,
    children,
    element,
}) => {
    const editor = useLeyden();

    // Ensure that, when placeholders are present, the selection stays on its left side.
    useEffect(() => {
        const { selection } = editor;
        if (selection === null) {
            return;
        }
        const {
            anchor: { path: selectionPath },
        } = selection;
        const ownPath = ReactEditor.findPath(editor, element);
        if (
            ownPath.slice(-1)[0] === 0 || // no elements to the left
            !Path.isSibling(ownPath, selectionPath) || // selection not adjacent
            Path.compare(ownPath, selectionPath) === 1 // selection already to the left
        ) {
            return;
        }
        Transforms.select(editor, Path.previous(ownPath));
    }, [editor.selection]);

    return (
        <ExpressionPlaceholderContainer {...attributes}>
            {COPY.placeholder}
            {children}
        </ExpressionPlaceholderContainer>
    );
};
