import React, { FC } from 'react';

import { Button, Container, Copy, Header, Instructions, Video } from './styled';
import { PopoverProps } from '..';

const COPY = {
    header: 'Looks like you’re on a mobile device',
    copy:
        'Some features will be available, but estimation tools are not available on screen sizes this small. ' +
        'To take advantage of takeoff and estimation tools, revisit this project on a desktop computer.',
    button: 'Got it',
};

export const DesktopOnly: FC<PopoverProps> = ({ reset }) => (
    <Container>
        <Video />

        <Instructions>
            <Header>{COPY.header}</Header>

            <Copy>{COPY.copy}</Copy>

            <Button onClick={reset}>{COPY.button}</Button>
        </Instructions>
    </Container>
);
