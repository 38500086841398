/**
 * This component is a dispatcher of different geometries that can be
 * drawn on a sheet. The GeometryType is used to establish which component
 * should be used to render the geometry.
 **/
import React, { FC } from 'react';

import { CountTool } from './count/CountTool';
import { AreaTool } from './polygon/AreaTool';
import { LinearTool } from './polyline/LinearTool';

import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useGeometry } from '@/components/takeoff/hooks/useGeometry';
import { useGeometryIsEditable } from '@/components/takeoff/hooks/useGeometryIsEditable';
import { useGeometryIsSelected } from '@/components/takeoff/hooks/useGeometryIsSelected';

export interface GeometryToolProps extends TakeoffComponentProps {
    geometryID: string;
}

export const GeometryTool: FC<GeometryToolProps> = ({ geometryID, useTakeoff }) => {
    const geometry = useGeometry(geometryID);
    const editable = useGeometryIsEditable(geometryID);
    const selected = useGeometryIsSelected(geometryID);

    if (!geometry) {
        return null;
    }

    return newGeometryHandler<JSX.Element>({
        [GeometryType.AREA]: (area) => {
            return (
                <AreaTool
                    editable={editable}
                    selected={selected}
                    geometry={area}
                    useTakeoff={useTakeoff}
                />
            );
        },
        [GeometryType.COUNT]: (count) => {
            return (
                <CountTool
                    editable={editable}
                    selected={selected}
                    count={count}
                    useTakeoff={useTakeoff}
                />
            );
        },
        [GeometryType.LINEAR]: (line) => {
            return (
                <LinearTool
                    editable={editable}
                    selected={selected}
                    geometry={line}
                    useTakeoff={useTakeoff}
                />
            );
        },
    })(geometry);
};
