import { Keyframes, css, keyframes } from 'styled-components';

const pulsateAnimation: Keyframes = keyframes`
0%{
    background-position: -468px 0
}
100%{
    background-position: 468px 0
}
`;

export const pulsate = css`
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation: ${pulsateAnimation} 1.8s linear infinite;
    animation-timing-function: linear;
    background: linear-gradient(
        103.77deg,
        rgba(255, 255, 255, 0.07) 32.18%,
        rgba(255, 255, 255, 0.3) 49.17%,
        rgba(255, 255, 255, 0.07) 70.55%
    );
    background-size: 1000px 640px;
    position: relative;
`;
