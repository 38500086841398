import {
    borderAdminDashboardTable,
    colorBackgroundSecondaryLightest,
    colorProjectAssignEstimator,
    colorProjectTableText,
    colorTypographySection,
} from '@/variables';
import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    overflow-x: scroll;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 0;
    border: ${borderAdminDashboardTable};
    border-collapse: collapse;
    border-spacing: 0;

    th {
        white-space: nowrap;
        text-transform: uppercase;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -1px;
            left: -1px;
            border: ${borderAdminDashboardTable};
        }
    }

    th,
    td {
        border: ${borderAdminDashboardTable};

        padding-left: 1rem;
        padding-right: 0.75rem;
        &:first-of-type {
            padding-left: 2.625rem;
        }
    }

    .more-trades-circle {
        color: ${colorProjectTableText};
    }
`;

export const TableBody = styled.tbody`
    color: ${colorProjectTableText};

    tr {
        cursor: pointer;
    }
`;

// When the header is inactive, add padding on the right instead of a sorting arrow.
export const TableHeaderCell = styled.th<{ active?: boolean; sortable: boolean }>`
    position: sticky;
    top: 0;
    z-index: 5;
    color: ${(props): string => (props.active ? colorProjectTableText : colorTypographySection)};
    background-color: ${colorBackgroundSecondaryLightest};
    cursor: ${(props): string => (props.sortable ? 'pointer' : 'auto')};
    user-select: none;
    font-size: 11px;
`;

export const TableHeaderText = styled.span`
    margin-right: 0.5rem;
`;

// Object style syntax so we can easily map out the 4 cell borders.
export const TableRow = styled.tr((props: { isActive?: boolean }) => {
    const activeBorder = props.isActive
        ? `3px solid ${colorProjectAssignEstimator}`
        : borderAdminDashboardTable;

    return {
        height: '2.875rem',

        '& > td, th': {
            // Account for height change when adding border
            paddingTop: props.isActive ? 9 : 10,
            paddingBottom: props.isActive ? 9 : 10,
        },

        '& > td': {
            borderTop: activeBorder,
            borderBottom: activeBorder,
            ':first-of-type': {
                borderLeft: activeBorder,
                paddingLeft: props.isActive ? 'calc(2.625rem - 1px)' : '2.625rem',
            },
        },
    };
});
