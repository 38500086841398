import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardProps } from '@mui/material/Card';
import React, { FC } from 'react';
import { Card, CardHeading, Section, UpgradeHeading } from './EstimatesCardLocked.styled';

type Props = CardProps;

export const EstimatesCardLocked: FC<Props> = ({ ...props }) => {
    return (
        <Card elevation={8} {...props}>
            <Section>
                <CardHeading variant="overline">Paid expert estimates</CardHeading>
                <UpgradeHeading variant="h3">Upgrade for access</UpgradeHeading>
                <Typography variant="body1">
                    For $1,999 a year, you’ll get access to 2 paid expert estimates per year, plus
                    our professional takeoff tools.
                </Typography>
            </Section>
            <Button variant="outlined">Learn more</Button>
        </Card>
    );
};
