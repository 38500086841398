import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatters } from '@/common/currency';
import ButtonBase from '@mui/material/ButtonBase';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Cell, CellType } from 'leyden';
import { CellDialogOptions } from '@/components/Estimate/Table/contexts/useCellDialog';
import useCellFormState from './useCellFormState';
import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';
import { ReactComponent as OverflowMenuHorizontalIcon } from '@/assets/icons/16/overflow-menu--horizontal.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { useToggle } from '@/common/hooks/useToggle';
import RatesInput from '@/components/Estimate/Table/components/CellDialog/CellForm/RatesInput';

const formatter = formatters.usd();

const COPY = {
    itemTitle: 'Item info',
    groupTitle: 'Group info',
    done: 'Done',
    name: 'Name',
    groupName: 'Group name',
    quantity: 'Quantity',
    uom: 'Unit of measure',
    rate: 'Rate',
    total: 'Total',
};

interface ItemFormProps {
    projectID: string;
    cell: Cell<CellType>;
    onClose: () => void;
    onContextMenuClick: () => void;
    explicitCellType?: CellDialogOptions['explicitCellType'];
}

const CellForm: FC<ItemFormProps> = ({
    projectID,
    cell,
    onClose,
    onContextMenuClick,
    explicitCellType,
}) => {
    const [loading, setLoading] = useState(false);
    const [ratesOpen, toggleRatesOpen] = useToggle(false);

    const { isAdding, isGroup, inputs, total, uoms, handleSubmitItem, handleSubmitGroup } =
        useCellFormState({
            cell,
            projectID: projectID.toString(),
            explicitCellType,
        });

    const { name, quantity, totalRate, isCalculatedRate, rates, unit } = inputs;

    const valuesHaveChanged = Object.values(inputs).some(({ $hasChanged }) => $hasChanged);

    const handleSubmit = async () => {
        setLoading(true);

        if (isGroup) {
            await handleSubmitGroup();
        } else {
            await handleSubmitItem();
        }

        onClose();

        setLoading(false);
    };

    return (
        <>
            <AppBar
                sx={(theme) => ({
                    // Allow the app to be displayed on top of the context menu
                    zIndex: theme.zIndex.drawer + 1,
                    backgroundColor: theme.palette.hues.neutral[100],
                })}
            >
                <Toolbar>
                    <IconButton edge="start" onClick={onClose} aria-label="close">
                        <CloseIcon width={25} height={25} />
                    </IconButton>
                    <Typography sx={{ color: 'text.primary', flex: 1 }} variant="h4">
                        {isGroup ? COPY.groupTitle : COPY.itemTitle}
                    </Typography>
                    {!isAdding && (
                        <IconButton onClick={onContextMenuClick}>
                            <OverflowMenuHorizontalIcon color="black" />
                        </IconButton>
                    )}
                    <ButtonBase onClick={handleSubmit} disabled={!valuesHaveChanged}>
                        <Typography
                            sx={{
                                color: 'primary.main',
                                paddingLeft: '8px',
                                paddingRight: '8px',
                            }}
                            variant="h4"
                        >
                            {COPY.done}
                        </Typography>
                    </ButtonBase>
                </Toolbar>
                <Divider flexItem />
            </AppBar>
            <Box sx={{ padding: '16px' }}>
                <Stack spacing={2} divider={<Divider flexItem />}>
                    <TextField
                        id="name"
                        label={isGroup ? COPY.groupName : COPY.name}
                        variant="filled"
                        {...name}
                        disabled={loading}
                    />
                    {!isGroup && (
                        <Stack spacing={2} divider={<Divider flexItem />}>
                            <TextField
                                id="quantity"
                                label={COPY.quantity}
                                variant="filled"
                                type="number"
                                {...quantity}
                                disabled={loading}
                            />
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="unit-label">{COPY.uom}</InputLabel>
                                <Select labelId="unit-label" id="unit" {...unit} disabled={loading}>
                                    {uoms.map((uom) => (
                                        <MenuItem key={uom.id} value={uom.id.toString()}>
                                            {uom.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="rate"
                                label={COPY.rate}
                                variant="filled"
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {isCalculatedRate && '= '}$
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="open rates"
                                                onClick={toggleRatesOpen}
                                                edge="end"
                                            >
                                                {/* This is to copy the caret style from MUI Select component*/}
                                                <SvgIcon
                                                    sx={{
                                                        width: '1em',
                                                        height: '1em',
                                                        transform: ratesOpen
                                                            ? 'rotate(-180deg)'
                                                            : '',
                                                        color: (theme) =>
                                                            theme.palette.hues.neutral[48],
                                                    }}
                                                >
                                                    <path d="M7 10l5 5 5-5z" />
                                                </SvgIcon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...totalRate}
                                disabled={loading}
                            />
                            {ratesOpen && <RatesInput {...rates} disabled={loading} />}
                            <div>
                                <Typography sx={{ color: 'black' }} variant="body1">
                                    {COPY.total}
                                </Typography>
                                <Typography sx={{ color: 'black' }} variant="h2">
                                    {formatter.format(total)}
                                </Typography>
                            </div>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </>
    );
};

export default CellForm;
