import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { ProjectTicket } from '../ProjectTicket';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';
import { getProjectDetailsLink } from '@/common/links';
import { useUser } from '@/contexts/User';
import { hasPPUSubscription, isBuilderUser } from '@/views/Projects/utils';
import { isProjectComplete } from '@/views/Projects/utils';

const columnSorter = (left: ProjectRecord, right: ProjectRecord): number => {
    return new Date(right.bidsDueDate).getTime() - new Date(left.bidsDueDate).getTime();
};

interface KanbanColumn extends BoxProps {
    loading: boolean;
    projects: ProjectWithEventsRecord[];
}

export const KanbanColumn: FC<KanbanColumn> = ({ loading, projects }) => {
    projects.sort(columnSorter);

    const {
        data: { user },
    } = useUser();

    return (
        <Box>
            {(() => {
                if (loading) {
                    return <Skeleton />;
                }

                if (projects.length === 0) {
                    return (
                        <Typography
                            component="p"
                            sx={{
                                color: (theme) => theme.palette.hues.neutral[78],
                                marginTop: '16px',
                                textAlign: 'center',
                            }}
                            variant="body3"
                        >
                            No projects in column
                        </Typography>
                    );
                }

                return (
                    <Stack spacing="16px">
                        {projects.map((project) => (
                            <Link
                                component={RRDLink}
                                key={project.id}
                                to={{
                                    pathname: getProjectDetailsLink(
                                        project.uuid,
                                        !!(project.isSaas && project.projectUsers?.nodes.length) ||
                                            (!project.isSaas &&
                                                isBuilderUser(user) &&
                                                !hasPPUSubscription(user) &&
                                                isProjectComplete(project))
                                    ),
                                }}
                            >
                                <ProjectTicket project={project} projects={projects} />
                            </Link>
                        ))}
                    </Stack>
                );
            })()}
        </Box>
    );
};
