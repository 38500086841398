import React, { FC, MouseEventHandler, useEffect } from 'react';

import { DivProps } from '@/common/types';
import { Portal } from '@/components/app/Portal';
import { Action, makeAction, State } from '@/components/mission-control/UserManager/state';
import { selectedUserIsLoaded } from '@/components/mission-control/UserManager/state/types';
import {
    UserModalBackground,
    UserModalCard,
    UserModalCloseButton,
    UserModalTitle,
} from '@/components/mission-control/UserManager/UserModal/styled';
import { UserActions } from '@/components/mission-control/UserManager/UserModal/UserActions';
import { UserContent } from '@/components/mission-control/UserManager/UserModal/UserContent';
import { fullName } from '@/components/mission-control/UserManager/utils';
import { XIcon } from '@/components/ui/icons/XIcon';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { IUserFragment } from '@/graphql';

export interface UserModalProps extends DivProps {
    state: State;
}

export const UserModal: FC<UserModalProps> = ({ onClick, state, ...rest }) => {
    // Remove the scrollbar when the modal pops up and restore the original state on close.
    useEffect(() => {
        // If the modal is not visible or it has already fixed the body position, do nothing.
        if (!state.selectedUser || document.body.style.position === 'fixed') {
            return;
        }
        const initialClientWidth = document.body.clientWidth;
        const initialScrollY = window.scrollY;

        const initialPosition = document.body.style.position;
        const initialTop = document.body.style.top;
        const initialWidth = document.body.style.width;

        document.body.style.position = 'fixed';
        document.body.style.top = `-${initialScrollY}px`;
        document.body.style.width = `${initialClientWidth}px`;
        return (): void => {
            document.body.style.position = initialPosition;
            document.body.style.top = initialTop;
            document.body.style.width = initialWidth;
            window.scrollTo(0, initialScrollY);
        };
    }, [state.selectedUser]);

    // Close modal on an appropriate click.
    const handleCloseClick: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        state.commit(makeAction(Action.CloseModal, null));
    };

    // Ensure that clicks on the card do not trigger the background click handler.
    const handleCardClick: MouseEventHandler<HTMLDivElement> = (e) => {
        if (onClick) {
            onClick(e);
        }
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
    };

    // Once we've determined that there is a selected user, render all info.
    const renderUserInfo = (user: IUserFragment): JSX.Element => (
        <>
            <UserModalTitle>{fullName(user)}</UserModalTitle>
            <UserContent user={user} />
            <UserActions user={user} state={state} />
        </>
    );

    // Render nothing if there's no selected user.
    if (state.selectedUser === null) {
        return <></>;
    }

    return (
        <Portal id="user-modal">
            <UserModalBackground onClick={handleCloseClick}>
                <UserModalCard onClick={handleCardClick} {...rest}>
                    <UserModalCloseButton onClick={handleCloseClick}>
                        <XIcon color="rgba(77, 88, 102, 0.4)" sideLengthPx={18} strokeWidthPx={2} />
                    </UserModalCloseButton>
                    {selectedUserIsLoaded(state.selectedUser) ? (
                        renderUserInfo(state.selectedUser)
                    ) : (
                        <SpinnerLoader style={{ gridArea: 'content' }} />
                    )}
                </UserModalCard>
            </UserModalBackground>
        </Portal>
    );
};
