import MuiCard from '@mui/material/Card';
import MuiSvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const Card = styledByMui(MuiCard)((options) => ({
    borderTop: `2px solid ${options.theme.palette.hues.orange['0']}`,
    borderRadius: 0,
    padding: '24px',
}));

export const CardHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const SvgIcon = styledByMui(MuiSvgIcon)((options) => ({
    color: options.theme.palette.hues.neutral[48],
}));
