import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const Tag = styledByMui('div')((options) => ({
    border: `1px solid ${options.theme.palette.hues.neutral[94]}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: '4px 1fr',
    minWidth: 165,
    overflow: 'auto',
}));

export const Line = styledByMui('div')((options) => ({
    background: options.color,
}));

export const Text = styledByMui(Typography)({
    padding: '0 12px',
    textAlign: 'center',
    textTransform: 'uppercase',
});
