import React, { FC } from 'react';

import {
    UserModalPropertyName,
    UserModalPropertyValue,
} from '@/components/mission-control/UserManager/UserModal/styled';

export interface UserPropertyProps {
    name: string;
    val: string;
}

export const UserProperty: FC<UserPropertyProps> = ({ name, val }) => (
    <>
        <UserModalPropertyName>{name}</UserModalPropertyName>
        <UserModalPropertyValue>{val}</UserModalPropertyValue>
    </>
);
