/*
 * projectUploadFiles.ts provides GraphQL queries for the project_upload_file table.
 */
import { frogmint, newQueries } from '../frog';

export interface ProjectUploadFileRecord {
    created?: string;
    lastModified?: string;
    id: number;
    filename?: string;
    uuid: string;
    eventId?: number;
    __typename: 'ProjectUploadFile';
}

export interface ProjectUploadFileRecords {
    projectUploadFiles: {
        nodes: ProjectUploadFileRecord[];
    };
}

export interface ProjectUploadFileCondition {
    id?: number;
    eventId?: number;
    uuid?: string;
}

export const projectUploadFileFragment = frogmint`
    ${'ProjectUploadFile_projectUploadFile'} {
        created
        lastModified
        id
        filename
        uuid
        eventId
        __typename
    }
`;

export const ProjectUploadFilesQuery = projectUploadFileFragment.gql`
    query ProjectUploadFiles($condition: ProjectUploadFileCondition) {
        projectUploadFiles(condition: $condition) {
            nodes {
                ${projectUploadFileFragment}
            }
        }
    }
`;

export const [executeProjectUploadFilesQuery, useProjectUploadFilesQuery] = newQueries<
    ProjectUploadFileCondition,
    ProjectUploadFileRecords
>(ProjectUploadFilesQuery);
