import Flatten from '@flatten-js/core';

import { Point } from '@/common/types';
import { coordinateDataIs } from '@/common/utils/geometries/coordinateData';

type Convert<T extends Point | Point[] | Point[][]> = T extends Point
    ? Flatten.Point
    : T extends Point[]
    ? Flatten.Multiline
    : T extends Point[][]
    ? Flatten.Polygon
    : never;

type Converter<T extends Point | Point[] | Point[][]> = (data: T) => Convert<T>;

const toPoint: Converter<Point> = (point) => {
    return new Flatten.Point(point.y, point.x);
};

const toMultiline: Converter<Point[]> = (line) => {
    let previousPoint: Point | null = null;
    const segments: Flatten.Segment[] = [];
    line.forEach((point) => {
        if (!previousPoint) {
            previousPoint = point;
        } else {
            segments.push(new Flatten.Segment(toPoint(previousPoint), toPoint(point)));
            previousPoint = point;
        }
    });
    return new Flatten.Multiline(segments);
};

const toPolygon: Converter<Point[][]> = (polygon) => {
    const rings: Flatten.Segment[][] = [];
    polygon.forEach((ring) => {
        let previousPoint = null;
        const segments: Flatten.Segment[] = [];
        for (const point of ring) {
            if (!previousPoint) {
                previousPoint = point;
            } else {
                segments.push(new Flatten.Segment(toPoint(previousPoint), toPoint(point)));
                previousPoint = point;
            }
        }
        if (ring.length > 0) {
            segments.push(new Flatten.Segment(toPoint(ring[ring.length - 1]), toPoint(ring[0])));
        }
        rings.push(segments);
    });
    return new Flatten.Polygon(rings);
};

export function toFlatten<T extends Point | Point[] | Point[][]>(data: T): Convert<T>;
export function toFlatten(data: Point | Point[] | Point[][]) {
    if (coordinateDataIs.point(data)) return toPoint(data);
    if (coordinateDataIs.area(data)) return toPolygon(data);
    else return toMultiline(data);
}
