import { SerializeCell } from './cell';
import { SerializeCells } from './cells';
import { SerializeConstants } from './constants';
import { SerializeElement } from './element';
import { SerializeTable } from './table';
import { SerializeText } from './text';
import { SerializeToken } from './token';

export interface Serialize {
    Cell: SerializeCell;
    Cells: SerializeCells;
    Constants: SerializeConstants;
    Element: SerializeElement;
    Table: SerializeTable;
    Text: SerializeText;
    Token: SerializeToken;
}

export const Serialize = {
    Cell: SerializeCell,
    Cells: SerializeCells,
    Constants: SerializeConstants,
    Element: SerializeElement,
    Table: SerializeTable,
    Text: SerializeText,
    Token: SerializeToken,
};
