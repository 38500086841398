import React, { ComponentType } from 'react';
import { Mode } from '@/theme/Mode';

type Options = {
    variant?: 'light' | 'dark';
};

export function withMode<T>(options: Options) {
    return (WrappedComponent: ComponentType<T>) => {
        return (props: T) => (
            <Mode variant={options.variant}>
                <WrappedComponent {...props} />
            </Mode>
        );
    };
}
