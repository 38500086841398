// change if more columns are added - will be replaced once leyden matures
export interface SerializeConstants {
    COLUMN_COUNT: number;
    MAX_EMPTY_ROWS_COUNT: number;
}

export const SerializeConstants: SerializeConstants = {
    COLUMN_COUNT: 5,
    MAX_EMPTY_ROWS_COUNT: 20,
};
