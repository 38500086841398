/*
 * AddFile is a clickable link to add a file to a project.
 */
import React, { FC, useRef } from 'react';

import { useMutation } from 'urql';
import { v4 as uuid } from 'uuid';

import { AddFileContainer } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { createUploadMutation } from '@/mutations/newUpload';

import { ReactComponent as Plus } from '@/assets/icons/plus.svg';

const COPY = {
    addFile: 'Add file',
};

export const AddFile: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { fileInProgress, setFileInProgress, project } = useProjectPanel();
    const { upload } = useStorage();
    const {
        data: { user },
    } = useUser();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [, createProjectUploadFile] = useMutation(createUploadMutation);

    const uploadFile = async (file: File): Promise<void> => {
        const newUuid = uuid();
        setFileInProgress({ filename: file.name, uuid: newUuid });
        await upload(file.name, project.uuid, newUuid, 'uploads', file);
        await createProjectUploadFile({
            filename: file.name,
            uuid: newUuid,
            ownerId: user.id,
            projectId: project.id,
        });
    };

    const handleFileInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void[]> => {
        const files = event.target.files ?? [];
        const allPromises: Promise<void>[] = [];
        for (let i = 0; i < files.length; i++) {
            allPromises.push(uploadFile(files[i]));
        }
        return Promise.all(allPromises);
    };

    return (
        <AddFileContainer
            disabled={fileInProgress !== undefined}
            onClick={(): void => fileInputRef.current?.click()}
        >
            <SvgIcon src={Plus} width="0.875rem" height="0.875rem" noInherit={true} />
            <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                onChange={handleFileInputChange}
                multiple={false}
            />
            {COPY.addFile}
        </AddFileContainer>
    );
};
