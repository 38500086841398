import Box, { BoxProps } from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React, { FC, useState } from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { StorageKey } from '@/common/storage';
import { KanbanColumn } from './KanbanColumn';
import { OnboardingModal } from '../Dashboard/OnboardingModal';
import { WelcomeModal } from '../Dashboard/WelcomeModal';
import { TrialExpiredModal } from '@/components/projects/Dashboard/TrialExpiredModal';
import { SubscribeSuccessModal } from '@/components/projects/Dashboard/SubscribeSuccessModal';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import {
    getActiveProjects,
    getProjectsByStatuses,
    hasBeenWelcomed,
    isBuilderUser,
    isEstimatorUser,
    isExpiredTrial,
    isNonsubscriber,
    isPureEstimatorUser,
    isTeamOnboardingCancelled,
} from '@/views/Projects/utils';
import { useKanbanProjects } from './useKanbanProjects';
import { useUser } from '@/contexts/User';
import {
    getColumnColorByColumnLabel,
    getKanbanColumnLabels,
    getStatusesByColumnLabel,
    toLabelText,
} from './utils';

export const ProjectKanban: FC<BoxProps> = (props) => {
    const screenSize = useScreenSize();

    const user = useUser();
    const { fetching, projects } = useKanbanProjects();

    const [checkoutSuccessComplete] = useLocalStorage<boolean>(StorageKey.CheckoutSuccessComplete);

    const hasOnboardingID =
        !!localStorage.getItem('onboarding_id') || !!localStorage.getItem('is_freemium');

    const isTrialExpiredModalOpen =
        isNonsubscriber(user?.data.user.team) &&
        (isTeamOnboardingCancelled(user?.data.user.team) || isExpiredTrial(user?.data.user.team));

    const columns = getKanbanColumnLabels(user.data.user);

    const [activeColumn, setActiveColumn] = useState(columns[1]);

    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
        !isTrialExpiredModalOpen &&
            !isPureEstimatorUser(user.data.user) &&
            !hasBeenWelcomed(user.data.user)
    );

    const activeProjects = getActiveProjects(projects);

    return (
        <Box {...props}>
            {(() => {
                if (screenSize === ScreenSize.Mobile) {
                    return (
                        <Box>
                            <Stack direction="row" spacing="12px">
                                {columns.map((column) => (
                                    <Chip
                                        clickable
                                        color={getColumnColorByColumnLabel(column)}
                                        key={status}
                                        label={toLabelText(column)}
                                        onClick={() => setActiveColumn(column)}
                                        size="small"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                        variant="outlined"
                                    />
                                ))}
                            </Stack>
                            <Box
                                sx={{
                                    marginTop: '24px',
                                }}
                            >
                                <KanbanColumn
                                    loading={fetching}
                                    projects={getProjectsByStatuses(
                                        getStatusesByColumnLabel(activeColumn),
                                        activeProjects
                                    )}
                                />
                            </Box>
                        </Box>
                    );
                } else {
                    return (
                        <Stack direction="row" spacing="24px">
                            {columns.map((column) => (
                                <Box flex="1" key={column}>
                                    <Chip
                                        color={getColumnColorByColumnLabel(column)}
                                        label={toLabelText(column)}
                                        size="small"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                        variant="outlined"
                                    />
                                    <Box
                                        sx={{
                                            marginTop: '24px',
                                        }}
                                    >
                                        <KanbanColumn
                                            key={status}
                                            loading={fetching}
                                            projects={getProjectsByStatuses(
                                                getStatusesByColumnLabel(column),
                                                activeProjects
                                            )}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Stack>
                    );
                }
            })()}
            {isWelcomeModalOpen && (
                <>
                    {isBuilderUser(user.data.user) &&
                    isEstimatorUser(user.data.user) &&
                    !hasOnboardingID ? (
                        <OnboardingModal
                            close={() => setIsWelcomeModalOpen(false)}
                            teamId={Number(user.data.user?.team?.id)}
                        />
                    ) : (
                        <WelcomeModal
                            close={() => setIsWelcomeModalOpen(false)}
                            teamId={Number(user.data.user?.team?.id)}
                        />
                    )}
                </>
            )}
            <SubscribeSuccessModal
                onConfirmButtonClick={() => writeStorage(StorageKey.CheckoutSuccessComplete, true)}
                open={checkoutSuccessComplete === false}
            />
            <TrialExpiredModal open={isTrialExpiredModalOpen} />
        </Box>
    );
};
