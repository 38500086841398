/**
 * User State Manager
 *
 * This hook contains all user state. Instead of exposing setters, it defines actions which can be
 * committed via the returned `commit` function. The returned `State` value is intended to be
 * passed in full through the dependent component tree.
 *
 * Help, I need to change some state!
 *
 * **NOTE**: Please insert code according to existing alphabetical order.
 *
 * 1. Determine a name for the action you want to do to the state. For this example, let's say we
 *    need to add a role to a user; our action will be `AddRole`.
 * 2. In `./types`, add your action and payload, then add them to `ActionPayloadMap`.
 *    ```ts
 *    export enum Action {
 *        ...
 *        AddRole,
 *        ...
 *    }
 *    ...
 *    export interface AddRolePayload {
 *        role: IUserRole;
 *        userID: string;
 *    }
 *    ...
 *    export type ActionPayloadMap = {
 *        ...
 *        [Action.AddRole]: AddRolePayload;
 *        ...
 *    }
 *    ```
 * 3. In this file, add a handler for your action. If it's asynchronous, return a promise!
 *    ```ts
 *    const addRole: Executor<Action.AddRole> = async ({ role, userID }) => {
 *        // Perform logic to add a role.
 *    };
 *    ```
 * 4. In this file, add a conditional branch for your action type to `executeAction`.
 *    ```ts
 *    const executeAction: (
 *    ...
 *    } else if (actionIs(action, Action.AddRole)) {
 *        return addRole(action.payload);
 *    } else if ...
 *    ```
 * 5. Execute your action from anywhere in the user manager with access to `state.commit`.
 *    ```ts
 *    commit(makeAction(Action.AddRole, {
 *        userID: user.id,
 *        role: IUserRole.Builder
 *    }));
 *    ```
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { fetchFullUser, searchUsers } from '@/components/mission-control/UserManager/requests';
import { actionIs } from '@/components/mission-control/UserManager/state/actions';
import {
    Action,
    ActionBlock,
    Committer,
    Executor,
    loadingSelectedUser,
    SelectedUser,
    State,
} from '@/components/mission-control/UserManager/state/types';
import { useUser } from '@/contexts/User';
import { IUserLightFragment } from '@/graphql';
import { useDeleteUserMutation } from '@/mutations/deleteUser';

const searchTriggerInterval = 500;

export const useUsersState = (): State => {
    /* State
     *********************************************************************************************/
    // Exposed to caller
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<SelectedUser>(null);

    // Internal
    const [deletedUserIDs, setDeletedUserIDs] = useState<Set<string>>(new Set());
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [usersSearched, setUsersSearched] = useState<IUserLightFragment[]>([]);

    /* Requests
     *********************************************************************************************/
    const [, deleteUserMutation] = useDeleteUserMutation();
    /* Misc Hooks
     *********************************************************************************************/
    const client = useApolloClient();
    const {
        actions: { impersonateUser: impersonate },
    } = useUser();

    /* Action Executors
     *********************************************************************************************/
    const closeModal: Executor<Action.CloseModal> = () => setSelectedUser(null);

    const deleteUser: Executor<Action.Delete> = async ({ userID }) => {
        await deleteUserMutation({ id: userID });
        setDeletedUserIDs((oldDeletedUserIDs) => {
            const newDeletedUserIDs = new Set(oldDeletedUserIDs);
            return newDeletedUserIDs.add(userID);
        });
    };

    const impersonateUser: Executor<Action.Impersonate> = async ({ userAuthID }) => {
        await impersonate({
            impersonatedAuthID: userAuthID,
        });
        window.location.replace('/projects');
    };

    const openModal: Executor<Action.OpenModal> = async ({ user: { id } }) => {
        setSelectedUser(loadingSelectedUser);
        return fetchFullUser(client, id)
            .then(setSelectedUser)
            .catch((err) => {
                setSelectedUser(null);
                throw err;
            });
    };

    const search: Executor<Action.Search> = ({ term }) => {
        setSearchTerm(term);
        setLoadingSearch(true);
        const newSearchTriggerTimeout = setTimeout(() => {
            if (term === '') {
                setSearchTimeout(null);
                setLoadingSearch(false);
                setUsersSearched([]);
                return;
            }
            searchUsers(client, term).then((searchedUsers) => {
                setSearchTimeout(null);
                setUsersSearched(searchedUsers);
                setLoadingSearch(false);
            });
        }, searchTriggerInterval);

        setSearchTimeout((oldSearchTriggerTimeout): typeof newSearchTriggerTimeout => {
            if (oldSearchTriggerTimeout) {
                clearTimeout(oldSearchTriggerTimeout);
            }
            return newSearchTriggerTimeout;
        });
    };

    /* Action Committer
     *****************************************************************************/
    const executeAction: (action: ActionBlock<Action>) => Promise<void> | void = (action) => {
        if (actionIs(action, Action.CloseModal)) {
            return closeModal(action.payload);
        } else if (actionIs(action, Action.Delete)) {
            return deleteUser(action.payload);
        } else if (actionIs(action, Action.Impersonate)) {
            return impersonateUser(action.payload);
        } else if (actionIs(action, Action.OpenModal)) {
            return openModal(action.payload);
        } else if (actionIs(action, Action.Search)) {
            return search(action.payload);
        }
        throw new Error(`unknown user action: ${action.type}`);
    };

    const commit = useCallback<Committer>(async (action) => {
        let error: string | null = null;
        try {
            await executeAction(action);
        } catch (err) {
            if (typeof err === 'string') {
                error = err;
            } else {
                error = JSON.stringify(err);
            }
        }
        return { action, error };
    }, []);

    /* Memoized Values
     *********************************************************************************************/
    const displaySearchResults = useMemo(() => searchTerm !== '', [searchTerm]);

    const loading = useMemo(() => {
        if (displaySearchResults) {
            return loadingSearch;
        }
        return false; // when pagination is fixed we should return here loadingPaginated
    }, [displaySearchResults, loadingSearch]);

    const users = useMemo<IUserLightFragment[]>(() => {
        const usersUnfiltered = displaySearchResults ? usersSearched : []; // when pagination is fixed we should return here usersPaginated
        return usersUnfiltered.filter((user) => !deletedUserIDs.has(user.id));
    }, [deletedUserIDs, displaySearchResults, usersSearched]);

    /* Cleanup
     * Do not add miscellaneous effects here; this is for state cleanup only.
     *********************************************************************************************/
    useEffect(
        () => (): void => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        },
        []
    );

    return {
        commit,
        loading,
        searchTerm,
        selectedUser,
        users,
    };
};
