/**
 * A button to commit actions related to the primary purpose of a UI component.
 */
import clsx from 'clsx';
import React, { FC } from 'react';

import { useDisableAfterClick } from './helpers/useDisableAfterClick';
import { ButtonProps } from '@/common/types';

import './SmallButton.scss';

type SmallButtonProps = ButtonProps & {
    variant?: 'solid' | 'outlined';
    color?: 'light' | 'dark';
};

export const SmallButton: FC<SmallButtonProps> = ({
    className,
    children,
    variant = 'solid',
    color = 'light',
    onClick,
    disabled,
    ...shared
}) => {
    const [wrappedOnClick, wrappedDisabled] = useDisableAfterClick(onClick);

    return (
        <button
            className={clsx(className, 'small-button', {
                solid: variant === 'solid',
                outlined: variant === 'outlined',
                light: color === 'light',
                dark: color === 'dark',
            })}
            onClick={wrappedOnClick}
            disabled={disabled || wrappedDisabled}
            {...shared}
        >
            {children}
        </button>
    );
};
