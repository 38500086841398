/* Displays project trade as a circular icon with its nickname inside */

import React, { FC } from 'react';
import clsx from 'clsx';

import { Trade } from '@/common/types';
import { CircleIcon } from '../icons/CircleIcon';
import { getTradeColor } from '@/common/utils/helpers';

type TradeCircleProp = {
    className?: string;
    trade: Pick<Trade, 'name' | 'nickname'>;
    renderTooltip?: boolean;
    border?: string;
    v2?: boolean;
};

export const tradeColorMap: Record<string, string> = {
    demolition: '#F35252',
    'exterior-siding': '#73DDD7',
    concrete: '#8E9AAC',
    'structural-steel': '#A3C6D5',
    'wood-framing': '#D49364',
    'metal-framing': '#577773',
    drywall: '#AB9DCE',
    insulation: '#E68F8F',
    'acoustical-ceiling': '#D67CE5',
    painting: '#8D0DF8',
    flooring: '#A3AC6A',
    tile: '#21A7AF',
    'finish-carpentry': '#D49364',
    millwork: '#B37923',
    doors: '#0B8E7E',
    windows: '#334AD7',
    'storefront-glazing-system': '#3E85AC',
    roofing: '#A0B925',
    plumbing: '#649ED4',
    mechanical: '#F37952',
    electrical: '#00A7BE',
    earthwork: '#B85005',
    flatwork: '#599687',
    'underground-utilities': '#006BA8',
    'general-conditions': '#00A843',
    equipment: '#5633A2',
    'low-voltage': '#406EB4',
};

export const TradeCircle: FC<TradeCircleProp> = ({
    trade,
    className,
    renderTooltip,
    border,
    v2,
}) => {
    const renderCircle = (): JSX.Element => (
        <CircleIcon
            v2={v2}
            className={clsx(className, `color-${getTradeColor(trade.name)}`)}
            color={v2 ? tradeColorMap[getTradeColor(trade.name)] : undefined}
            diameter="1.5rem"
            border={border}
        >
            {trade.nickname}
        </CircleIcon>
    );

    const renderWithTooltip = (): JSX.Element => (
        <div className="single-trade-circle">
            {renderCircle()}
            <div className="trades-tooltip">{trade.name}</div>
        </div>
    );

    return renderTooltip ? renderWithTooltip() : renderCircle();
};
