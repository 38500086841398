import { IMarkupFragment } from '@/graphql';
import { Point } from '@/common/types';
import { fromMarkupGeometry } from '@/common/convert/coordinateData/fromMarkupGeometry';
import { geometryDataIs } from '@/common/utils/markups/geometryData';

export const fromMarkupGeometries = (markup: IMarkupFragment): Point[] | Point[][] => {
    if (!markup.geometries || markup.geometries.length === 0) {
        return [];
    }
    let returnValue: Point[] | Point[][] = [];
    if (markup.geometries[0].__typename === 'Coordinate') {
        returnValue = markup.geometries.filter(geometryDataIs.point).map(fromMarkupGeometry);
    } else {
        returnValue = fromMarkupGeometry(markup.geometries[0]);
    }

    return returnValue;
};
