import React from 'react';

import { InputEvent, InputProps } from '@/common/types';
import { FormControl, TextControlProps } from './helpers/FormControl';

export type InputRef = HTMLInputElement;

export const Input = React.forwardRef<InputRef, TextControlProps<InputProps>>(
    ({ label, required, value, onChange, className, ...rest }, ref) => {
        const handleChange = (e: InputEvent): void => onChange(e.target.value);

        return (
            <FormControl label={label} required={required} value={value} className={className}>
                <input ref={ref} value={value} onChange={handleChange} {...rest} />
            </FormControl>
        );
    }
);
