/* eslint-disable max-len */
import to from 'await-to-js';
import Button from '@mui/material/Button';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { FC, useState } from 'react';
import {
    Heading,
    Body,
    ErrorBox,
    ErrorTypography,
    Controls,
    DialogContent,
    RightContent,
    LeftContent,
} from './styled';
import { UpgradeButton } from '@/components/ui/buttons/UpgradeButton';
import { scheduleCall } from '@/common/sales';
import { useUser } from '@/contexts/User';
import { Mode } from '@/theme/Mode';

const COPY = {
    title: 'You’ve reached the end of your free trial.',
    description:
        'However, the journey doesn’t have to end here. Upgrade your account to continue creating accurate estimates and winning new jobs.',
    contact: 'Talk to Sales',
    error: 'Something went wrong, please try again later',
};

type Props = DialogProps;

export const TrialExpiredModal: FC<Props> = (props) => {
    const user = useUser();

    const [error, setError] = useState<string>();

    const onScheduleCallButtonClick = async () => {
        const [error] = await to(scheduleCall());

        if (error) {
            setError(COPY.error);
        } else {
            setError(undefined);
        }
    };

    return (
        <ScopedCssBaseline>
            <Mode variant="light">
                <Dialog fullWidth maxWidth="md" {...props}>
                    <DialogContent>
                        <LeftContent />
                        <RightContent>
                            <Heading variant="h1">{COPY.title}</Heading>
                            <Body variant="body1">{COPY.description}</Body>
                            <Controls>
                                {user.data.user.team?.onboarding?.stripeCustomerID && (
                                    <UpgradeButton />
                                )}
                                <Button onClick={onScheduleCallButtonClick} variant="outlined">
                                    {COPY.contact}
                                </Button>
                            </Controls>
                            {error && (
                                <ErrorBox>
                                    <ErrorTypography variant="body1">{error}</ErrorTypography>
                                </ErrorBox>
                            )}
                        </RightContent>
                    </DialogContent>
                </Dialog>
            </Mode>
        </ScopedCssBaseline>
    );
};
