import styled from 'styled-components';

export const Wrapper = styled.div<{ isMobile: boolean; width: string }>`
    position: fixed;
    left: 50%;
    top: ${(props): string => (props.isMobile ? 'auto' : '50%')};
    bottom: ${(props): string => (props.isMobile ? '0' : 'auto')};
    transform: translate(-50%, ${(props): string => (props.isMobile ? '0' : '-50%')});
    width: ${(props): string => (props.isMobile ? '100%' : props.width)};
    outline: 0;
    z-index: 2220;
    border-radius: 8px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 12px 64px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`;

export const Backdrop = styled.div`
    display: block;
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2210;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.5;
`;

export const StyledModal = styled.div`
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    border-radius: 8px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
`;

export const HeaderText = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: #1b2235;
    letter-spacing: 0.009rem;
`;

export const CloseButton = styled.button`
    font-size: 0.8rem;
    border: none;
    border-radius: 3px;
    margin-left: 0.5rem;
    background: none;
    :hover {
        cursor: pointer;
    }
`;

export const Content = styled.div`
    padding: 0 1.5rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #5f6472;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
`;
