// Apollo Provider

// -----------------------------------------------------------------------------
// Imports

/* NPM */
import React, { FC } from 'react';

import { ApolloProvider as Apollo } from '@apollo/client';

/* Local */
import { createClient } from '../common/apollo';

export const client = createClient();

export const ApolloProvider: FC = ({ children }) => {
    return <Apollo client={client}>{children}</Apollo>;
};
