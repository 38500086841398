import { CustomScalars } from './customScalars';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GeoJSONCoordinates: any;
  JSONObject: any;
  Time: any;
  Token: CustomScalars['Token'];
}

export interface IAcceptProjectPricingInput {
  accepted: Scalars['Boolean'];
  projectID: Scalars['ID'];
  summary?: Maybe<Scalars['String']>;
}

export enum IAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

export interface IApproveAutoQuoteProjectPricingInput {
  projectID: Scalars['ID'];
}


export interface IAssembliesCopyInput {
  beforeUUID?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  projectID: Scalars['ID'];
  trade?: Maybe<Scalars['String']>;
}

export interface IAssembliesDeletionForV2OrderingInput {
  uuids: Array<Scalars['ID']>;
}

export interface IAssembliesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IAssemblyCondition>;
  orderBy?: Maybe<IAssembliesOrderBy>;
}

export enum IAssembliesOrderBy {
  AlphabeticAsc = 'ALPHABETIC_ASC',
  AlphabeticDesc = 'ALPHABETIC_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  Natural = 'NATURAL',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}



export interface IAssemblyAssignmentInput {
  assemblyType: IAssemblyType;
  description?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<IElementAssignmentInput>>>;
  id?: Maybe<Scalars['ID']>;
  projectID?: Maybe<Scalars['ID']>;
  tradeID?: Maybe<Scalars['ID']>;
  unitID?: Maybe<Scalars['ID']>;
}

export interface IAssemblyCondition {
  assemblyType?: Maybe<IAssemblyType>;
  assetID?: Maybe<Scalars['ID']>;
  libraryID?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  projectID?: Maybe<Scalars['ID']>;
  usedInProject?: Maybe<Scalars['Boolean']>;
}

export interface IAssemblyDeletionInput {
  ids: Array<Scalars['ID']>;
}



export interface IAssemblyLibrariesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IAssemblyLibraryCondition>;
}



export interface IAssemblyLibraryAssignmentInput {
  assemblyIDs?: Maybe<Array<Scalars['ID']>>;
  assemblyType: IAssemblyType;
  elementIDs?: Maybe<Array<Scalars['ID']>>;
  id?: Maybe<Scalars['ID']>;
  is1b?: Maybe<Scalars['Boolean']>;
  operation: IAssemblyLibraryOperation;
}

export interface IAssemblyLibraryCondition {
  assemblyType?: Maybe<IAssemblyType>;
  is1b: Scalars['Boolean'];
}


export enum IAssemblyLibraryOperation {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export interface IAssemblyReceivedInput {
  ownerID?: Maybe<Scalars['String']>;
  projectID?: Maybe<Scalars['String']>;
}

export interface IAssemblyReorderBeforeUuidInput {
  beforeUUID?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
}

export enum IAssemblyReorderDirection {
  Down = 'DOWN',
  Up = 'UP'
}

export interface IAssemblyReorderInput {
  direction?: Maybe<IAssemblyReorderDirection>;
  id: Scalars['ID'];
}

export enum IAssemblyType {
  Group = 'GROUP',
  Item = 'ITEM'
}


export interface IAssetArchivalInput {
  archive: Scalars['Boolean'];
  id: Scalars['ID'];
}

export interface IAssetAssignmentsInput {
  archival?: Maybe<Array<Maybe<IAssetArchivalInput>>>;
  grouping?: Maybe<Array<Maybe<IAssetGroupingInput>>>;
  naming?: Maybe<Array<Maybe<IAssetNamingInput>>>;
  ordering?: Maybe<Array<Maybe<IAssetOrderingInput>>>;
}

export interface IAssetBackfillInput {
  projectIDs: Array<Scalars['ID']>;
}

export interface IAssetCondition {
  assetID?: Maybe<Scalars['ID']>;
  assetType?: Maybe<IAssetType>;
  name?: Maybe<Scalars['String']>;
  projectID?: Maybe<Scalars['ID']>;
}




export interface IAssetExportInput {
  ids: Array<Scalars['ID']>;
  variant: IAssetExportVariant;
}

export interface IAssetExportRecievedInput {
  exportID: Scalars['ID'];
}

export enum IAssetExportVariant {
  Markup = 'MARKUP',
  Normal = 'NORMAL'
}

export interface IAssetGroupingInput {
  childrenIDs: Array<Maybe<Scalars['ID']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentID?: Maybe<Scalars['ID']>;
  position: Scalars['Int'];
  projectID: Scalars['ID'];
}

export interface IAssetInput {
  id: Scalars['ID'];
}

export interface IAssetNamingInput {
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface IAssetOrderingInput {
  id: Scalars['ID'];
  path: Scalars['String'];
}

export enum IAssetOrientation {
  R0 = 'R0',
  R90 = 'R90',
  R180 = 'R180',
  R270 = 'R270'
}

export interface IAssetPositioningInput {
  assetID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
}

export interface IAssetRecievedInput {
  projectID: Scalars['ID'];
}

export interface IAssetSearchInput {
  projectID: Scalars['ID'];
  term: Scalars['String'];
}

export enum IAssetType {
  Avatar = 'AVATAR',
  CompanyLogo = 'COMPANY_LOGO',
  Directory = 'DIRECTORY',
  Estimate = 'ESTIMATE',
  File = 'FILE',
  Group = 'GROUP',
  Page = 'PAGE'
}



export interface IAssetsDeletionInput {
  ids: Array<Scalars['ID']>;
}

export interface IAssetsFlatFileInput {
  ids: Array<Scalars['ID']>;
}


export interface IAssetsGroupingInput {
  groupings?: Maybe<Array<IAssetGroupingInput>>;
}

export interface IAssetsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IAssetCondition>;
  orderBy?: Maybe<IAssetsOrderBy>;
}

export enum IAssetsOrderBy {
  AlphabeticAsc = 'ALPHABETIC_ASC',
  AlphabeticDesc = 'ALPHABETIC_DESC',
  AssembliesAsc = 'ASSEMBLIES_ASC',
  AssembliesDesc = 'ASSEMBLIES_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  Natural = 'NATURAL',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export interface IAssetsOrientationInput {
  ids: Array<Scalars['ID']>;
}

export interface IAssetsPositioningInput {
  positions?: Maybe<Array<IAssetPositioningInput>>;
  projectID: Scalars['ID'];
}

export interface IAssignEstimatorInput {
  ownerID: Scalars['ID'];
  projectID: Scalars['ID'];
  userID: Scalars['ID'];
}



export interface IAverageProjectPriceInput {
  pricePerSquareFoot?: Maybe<Scalars['Int']>;
  priceTotal?: Maybe<Scalars['Int']>;
}


export enum ICsiDivision {
  Division01 = 'DIVISION01',
  Division02 = 'DIVISION02',
  Division03 = 'DIVISION03',
  Division04 = 'DIVISION04',
  Division05 = 'DIVISION05',
  Division06 = 'DIVISION06',
  Division07 = 'DIVISION07',
  Division08 = 'DIVISION08',
  Division09 = 'DIVISION09',
  Division10 = 'DIVISION10',
  Division11 = 'DIVISION11',
  Division12 = 'DIVISION12',
  Division13 = 'DIVISION13',
  Division14 = 'DIVISION14',
  Division21 = 'DIVISION21',
  Division22 = 'DIVISION22',
  Division23 = 'DIVISION23',
  Division25 = 'DIVISION25',
  Division26 = 'DIVISION26',
  Division27 = 'DIVISION27',
  Division28 = 'DIVISION28',
  Division31 = 'DIVISION31',
  Division32 = 'DIVISION32',
  Division33 = 'DIVISION33'
}


export interface ICategoriesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<ICategoryCondition>;
  orderBy?: Maybe<ICategoriesOrderBy>;
}

export enum ICategoriesOrderBy {
  Alphabetic = 'ALPHABETIC',
  Natural = 'NATURAL'
}


export interface ICategoryCondition {
  categoryType: ICategoryType;
  contains?: Maybe<Array<ISourceType>>;
  countyNamelsad?: Maybe<Scalars['String']>;
  countyfp?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  statefp?: Maybe<Scalars['String']>;
}


export enum ICategoryType {
  Equipment = 'EQUIPMENT',
  Group = 'GROUP',
  Item = 'ITEM',
  Labor = 'LABOR',
  Material = 'MATERIAL',
  Scope = 'SCOPE'
}


export enum IClientPreferenceStatus {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}


export interface ICommitmentInput {
  deleted?: Maybe<Scalars['Boolean']>;
  from: Scalars['Time'];
  id: Scalars['ID'];
  projectID?: Maybe<Scalars['ID']>;
  status: ICommitmentStatus;
  to: Scalars['Time'];
}

export enum ICommitmentStatus {
  Engaged = 'ENGAGED',
  Paused = 'PAUSED',
  Unavailable = 'UNAVAILABLE'
}

export interface IConnectTrialToSubscriptionInput {
  targetTeamID: Scalars['ID'];
  trialTeamID: Scalars['ID'];
  userID: Scalars['ID'];
}




export interface IContractInput {
  cancellationGracePeriod: Scalars['Int'];
  commitmentLength: Scalars['Int'];
  creditVolumeUpgrade: Scalars['Int'];
  ended?: Maybe<Scalars['Time']>;
  isSaas: Scalars['Boolean'];
  pandaDocID: Scalars['String'];
  subscriptionDiscount: Scalars['Int'];
  subscriptionFreeCredits: Scalars['Int'];
  subscriptionMonthlyFee: Scalars['Int'];
  teamOnboardingID: Scalars['ID'];
  trialOfferExpirationDate?: Maybe<Scalars['Time']>;
}




export interface ICountiesCondition {
  statefp: Scalars['String'];
}

export interface ICountiesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<ICountiesCondition>;
  orderBy?: Maybe<ICountiesOrderBy>;
}

export enum ICountiesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL'
}



export interface ICustomerPortalLinkInput {
  type: ICustomerPortalType;
}

export enum ICustomerPortalType {
  Default = 'DEFAULT',
  PaymentHistory = 'PAYMENT_HISTORY',
  PaymentManagement = 'PAYMENT_MANAGEMENT'
}

export enum IDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}



export interface IElementAssignmentInput {
  assemblyID?: Maybe<Scalars['ID']>;
  csiDivision?: Maybe<ICsiDivision>;
  expression?: Maybe<IElementExpressionInput>;
  id?: Maybe<Scalars['ID']>;
  material?: Maybe<IMaterialInput>;
  materialID?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  previousElement?: Maybe<Scalars['ID']>;
  projectID?: Maybe<Scalars['ID']>;
  quantity?: Maybe<IElementQuantityInput>;
  rate?: Maybe<IElementRatesInput>;
  tradeID?: Maybe<Scalars['ID']>;
  unitID?: Maybe<Scalars['ID']>;
}


export interface IElementExpressionInput {
  tokens: Array<Scalars['Token']>;
}

export interface IElementGroupingInput {
  assemblyID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
}

export interface IElementInput {
  assemblyID?: Maybe<Scalars['ID']>;
  equipmentRate: Scalars['Float'];
  formulaID: Scalars['ID'];
  id: Scalars['ID'];
  laborRate: Scalars['Float'];
  materialID: Scalars['ID'];
  materialRate: Scalars['Float'];
  name: Scalars['String'];
  ownerID?: Maybe<Scalars['ID']>;
  previousElement?: Maybe<Scalars['ID']>;
  productionRate: Scalars['Float'];
  supplierID?: Maybe<Scalars['ID']>;
  unitID: Scalars['ID'];
  uuid?: Maybe<Scalars['ID']>;
}

export interface IElementQuantityInput {
  formula?: Maybe<IFormulaInput>;
  formulaID?: Maybe<Scalars['ID']>;
  variables?: Maybe<Array<Maybe<IVariableInput>>>;
}

export interface IElementRatesInput {
  costPerUnit?: Maybe<Scalars['Float']>;
  equipmentRate?: Maybe<Scalars['Float']>;
  laborRate?: Maybe<Scalars['Float']>;
  materialRate?: Maybe<Scalars['Float']>;
  productionRate?: Maybe<Scalars['Float']>;
}

export interface IElementReorderBeforeUuidInput {
  beforeUUID?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
}

export enum IElementReorderDirection {
  Down = 'DOWN',
  Up = 'UP'
}

export interface IElementReorderInput {
  direction?: Maybe<IElementReorderDirection>;
  id: Scalars['ID'];
}

export enum IElementType {
  Equipment = 'EQUIPMENT',
  Labor = 'LABOR',
  Material = 'MATERIAL',
  Scope = 'SCOPE'
}

export interface IElementUpdateExpressionInput {
  id: Scalars['ID'];
  tokens: Array<Scalars['Token']>;
}


export interface IElementsDeletionForV2OrderingInput {
  uuids: Array<Scalars['ID']>;
}

export interface IElementsDeletionInput {
  ids: Array<Scalars['ID']>;
}

export interface IElementsInput {
  uuids: Array<Scalars['ID']>;
}

export interface IEstimateLinkInput {
  projectId: Scalars['ID'];
  type: ILinkType;
}


export enum IEstimateType {
  EquipmentPricing = 'EQUIPMENT_PRICING',
  GeneralConditionsPricing = 'GENERAL_CONDITIONS_PRICING',
  LaborPricing = 'LABOR_PRICING',
  MarkupsProfitOverhead = 'MARKUPS_PROFIT_OVERHEAD',
  MaterialPricing = 'MATERIAL_PRICING',
  QuantityTakeoffs = 'QUANTITY_TAKEOFFS',
  RoughBudgetPricingOnly = 'ROUGH_BUDGET_PRICING_ONLY'
}

export interface IEstimatorQuoteInput {
  acceptance: IProjectAcceptanceHistoryAssignmentInput;
  pricing?: Maybe<IProjectPriceEstimationHistoryInput>;
}

export enum IEstimatorStatus {
  Available = 'AVAILABLE',
  Away = 'AWAY',
  Busy = 'BUSY',
  Paused = 'PAUSED',
  PendingApproval = 'PENDING_APPROVAL'
}


export enum IEstimatorsOrderBy {
  Natural = 'NATURAL'
}

export interface IEstimatorsProjectInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IUserCondition>;
  orderBy?: Maybe<IEstimatorsOrderBy>;
}


export interface IEventCondition {
  eventTypeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ownerID?: Maybe<Scalars['ID']>;
  projectID?: Maybe<Scalars['ID']>;
  seen?: Maybe<Scalars['Boolean']>;
}


export interface IEventInput {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  condition?: Maybe<IEventCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  projectID?: Maybe<Scalars['ID']>;
  tradeID?: Maybe<Scalars['ID']>;
}


export interface IEventsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IEventCondition>;
  orderBy?: Maybe<IEventsOrderBy>;
}

export enum IEventsOrderBy {
  Natural = 'NATURAL'
}


export interface IExtendTrialInput {
  email: Scalars['String'];
}


export interface IFeatureCondition {
  userID: Scalars['ID'];
}


export enum IFeatureType {
  CostData = 'COST_DATA',
  FileManagementV2 = 'FILE_MANAGEMENT_V2',
  IngestionV2 = 'INGESTION_V2',
  Marketplace = 'MARKETPLACE',
  Quickbid = 'QUICKBID',
  Takeoff = 'TAKEOFF',
  TakeoffV2 = 'TAKEOFF_V2'
}


export enum IFileDirectory {
  CostCodesAndRateSheets = 'COST_CODES_AND_RATE_SHEETS',
  ExampleEstimatesCustomTemplates = 'EXAMPLE_ESTIMATES_CUSTOM_TEMPLATES'
}


export interface IFileExportInput {
  projectId: Scalars['ID'];
  type: ILinkType;
}

export interface IForgotPasswordInput {
  email: Scalars['String'];
}

export enum IFormattingPreference {
  BillOfMaterials = 'BILL_OF_MATERIALS',
  CostCodesBySection = 'COST_CODES_BY_SECTION',
  CostCodesLineByLine = 'COST_CODES_LINE_BY_LINE',
  CustomTemplate = 'CUSTOM_TEMPLATE',
  LogoCompanyInfo = 'LOGO_COMPANY_INFO'
}


export interface IFormulaInput {
  elementID?: Maybe<Scalars['ID']>;
  expression: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  takeoffUnitID?: Maybe<Scalars['ID']>;
}


export enum IGeoJsoncrsType {
  Link = 'link',
  Name = 'name'
}
















export enum IGeoJsonType {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}



export interface IHourlyLaborRateInput {
  hourlyRateUSDCents: Scalars['Int'];
  type: Scalars['String'];
}



export interface ILaborCondition {
  categoryID?: Maybe<Scalars['ID']>;
  countyfp?: Maybe<Scalars['String']>;
  statefp?: Maybe<Scalars['String']>;
}




export interface ILaborsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<ILaborCondition>;
  orderBy?: Maybe<ILaborsOrderBy>;
}

export enum ILaborsOrderBy {
  Natural = 'NATURAL'
}

export interface ILegacyCategoriesInput {
  searchTerm?: Maybe<Scalars['String']>;
  sourceType: ISourceTypeV2;
}

export interface ILegacySourcesInput {
  county: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  page?: Maybe<IPageInfoInputV2>;
  searchTerm?: Maybe<Scalars['String']>;
  sourceType: ISourceTypeV2;
  state: Scalars['String'];
}

export interface ILibraryElementsInput {
  libraryId: Scalars['ID'];
}

export enum ILinkType {
  Detailed = 'DETAILED',
  Limited = 'LIMITED'
}


export interface ILoginInput {
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
}





export interface IMarkupGroupAddMarkupsInput {
  markupGroupID: Scalars['ID'];
  markupIDs: Array<Scalars['ID']>;
}

export interface IMarkupGroupCreateInput {
  id?: Maybe<Scalars['ID']>;
  markupIDs?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  projectID: Scalars['ID'];
}

export interface IMarkupGroupDeleteInput {
  id: Scalars['ID'];
}

export interface IMarkupGroupInput {
  id: Scalars['ID'];
}

export interface IMarkupGroupRemoveMarkupsInput {
  markupIDs: Array<Scalars['ID']>;
}

export interface IMarkupGroupUpdateFields {
  name?: Maybe<Scalars['String']>;
}

export interface IMarkupGroupUpdateInput {
  fields: IMarkupGroupUpdateFields;
  id: Scalars['ID'];
}

export interface IMarkupInput {
  id: Scalars['ID'];
}



export enum IMarkupType {
  Area = 'AREA',
  Count = 'COUNT',
  Linear = 'LINEAR'
}

export interface IMarkupUpdateFields {
  name?: Maybe<Scalars['String']>;
}

export interface IMarkupUpdateInput {
  fields: IMarkupUpdateFields;
  id: Scalars['ID'];
}



export interface IMaterialByIdInput {
  id: Scalars['ID'];
}

export interface IMaterialCondition {
  categoryID?: Maybe<Scalars['ID']>;
  countyfp?: Maybe<Scalars['String']>;
  statefp?: Maybe<Scalars['String']>;
}


export enum IMaterialExcludingValues {
  Library = 'LIBRARY',
  User = 'USER'
}

export interface IMaterialInput {
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  ownerID?: Maybe<Scalars['ID']>;
  takeoffUnitID: Scalars['ID'];
}

export enum IMaterialPropertyType {
  Base = 'BASE',
  Variable = 'VARIABLE'
}

export interface IMaterialSearchInput {
  county: Scalars['String'];
  exclude?: Maybe<IMaterialExcludingValues>;
  isLocalizedData?: Maybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  state: Scalars['String'];
  term: Scalars['String'];
}


export interface IMaterialsByIDsInput {
  ids: Array<Scalars['ID']>;
}

export interface IMaterialsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IMaterialCondition>;
  orderBy?: Maybe<IMaterialsOrderBy>;
}

export enum IMaterialsOrderBy {
  Natural = 'NATURAL'
}


export interface IMeasuredQuantityInput {
  unitID: Scalars['ID'];
  value: Scalars['Float'];
}







export interface IPageArguments {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface IPageInfoInputV2 {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface IPersonationInput {
  impersonatedAuthID?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
}

export interface IPreferredByClientInput {
  id: Scalars['ID'];
  teamID: Scalars['ID'];
}


export interface IPreferredMarkupsInput {
  labor?: Maybe<Scalars['Float']>;
  material?: Maybe<Scalars['Float']>;
  overhead?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
}

export enum IPricingStatus {
  Approved = 'APPROVED',
  Na = 'NA',
  Pending = 'PENDING'
}



export interface IProjectAcceptanceHistoryAssignmentInput {
  ID?: Maybe<Scalars['String']>;
  accepted: Scalars['Boolean'];
  estimateTypes?: Maybe<Array<IEstimateType>>;
  excludedTrades?: Maybe<Scalars['String']>;
  expectedDuration?: Maybe<Scalars['Float']>;
  expectedDurationSummary?: Maybe<Scalars['String']>;
  finishBy?: Maybe<Scalars['Time']>;
  projectID: Scalars['ID'];
  quoteExpiration?: Maybe<IQuoteExpiration>;
  refusalReason?: Maybe<IRefusalReasons>;
  refusalSummary?: Maybe<Scalars['String']>;
  scopeOfWork?: Maybe<Scalars['String']>;
  startBy?: Maybe<Scalars['Time']>;
  userID: Scalars['ID'];
}


export interface IProjectAreaAssignmentInput {
  projectID: Scalars['ID'];
  squareFeetTotalLivable?: Maybe<Scalars['Float']>;
  squareFeetUnderRoof?: Maybe<Scalars['Float']>;
}

export interface IProjectAreaInput {
  projectID: Scalars['ID'];
}

export interface IProjectCloneInput {
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
}

export interface IProjectCondition {
  completed?: Maybe<Scalars['Boolean']>;
  isSaas?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectIDs?: Maybe<Array<Maybe<Scalars['ID']>>>;
  teamID?: Maybe<Scalars['ID']>;
  unAssigned?: Maybe<Scalars['Boolean']>;
  unResponded?: Maybe<Scalars['Boolean']>;
  unReviewed?: Maybe<Scalars['Boolean']>;
}


export interface IProjectEstimatorAssignmentInput {
  assign: Scalars['Boolean'];
  estimatorID: Scalars['ID'];
  projectID: Scalars['ID'];
}

export interface IProjectEstimatorHoursInput {
  estimatorHours: Scalars['Float'];
  uuid: Scalars['ID'];
}


export interface IProjectInput {
  id: Scalars['ID'];
}



export interface IProjectLocationActionInput {
  projectID: Scalars['ID'];
}

export interface IProjectLocationAssignmentInput {
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  projectID: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface IProjectLocationCondition {
  point?: Maybe<Scalars['GeoJSONCoordinates']>;
  projectID?: Maybe<Scalars['ID']>;
  radius?: Maybe<Scalars['Float']>;
}


export interface IProjectLocationInput {
  id: Scalars['ID'];
}


export interface IProjectLocationsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IProjectLocationCondition>;
  orderBy?: Maybe<IProjectLocationsOrderBy>;
}

export enum IProjectLocationsOrderBy {
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  Natural = 'NATURAL',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipAsc = 'ZIP_ASC',
  ZipDesc = 'ZIP_DESC'
}


export interface IProjectMarkupAssignmentInput {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  percentage?: Maybe<Scalars['Float']>;
  projectID: Scalars['ID'];
  total?: Maybe<Scalars['Float']>;
}



export interface IProjectMarkupsDeletionInput {
  ids: Array<Scalars['ID']>;
}


export interface IProjectPlanFileCreationInput {
  filename: Scalars['String'];
  ownerID: Scalars['ID'];
  projectUUID: Scalars['ID'];
  uuid: Scalars['ID'];
}

export interface IProjectPlanFilePresignedUrlInput {
  contentSize: Scalars['Int'];
  contentType: Scalars['String'];
  filename: Scalars['String'];
  projectUUID: Scalars['ID'];
  uuid: Scalars['ID'];
}


export interface IProjectPlanPageInput {
  heightIn?: Maybe<Scalars['Float']>;
  heightPx?: Maybe<Scalars['Int']>;
  isV2?: Maybe<Scalars['Boolean']>;
  markups?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orientation?: Maybe<Scalars['Int']>;
  originalOrientation?: Maybe<Scalars['Int']>;
  pageID?: Maybe<Scalars['Int']>;
  projectPlanID: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  scale?: Maybe<Scalars['Float']>;
  widthIn?: Maybe<Scalars['Float']>;
  widthPx?: Maybe<Scalars['Int']>;
}



export interface IProjectPriceEstimationHistoryInput {
  bidsDueDate?: Maybe<Scalars['Time']>;
  estimatorHours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  price: Scalars['Int'];
}



export interface IProjectPricingCondition {
  projectID?: Maybe<Scalars['ID']>;
}


export interface IProjectPricingInput {
  id: Scalars['ID'];
  overrideBuilderAcceptance?: Maybe<Scalars['Boolean']>;
  price: Scalars['Int'];
}


export interface IProjectPricingsInput {
  condition?: Maybe<IProjectPricingCondition>;
}


export interface IProjectReviewTagWithoutProjectIdInput {
  id: Scalars['ID'];
  tag: Scalars['String'];
}


export interface IProjectReviewWithReviewTagsInput {
  comment: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  ownerID: Scalars['ID'];
  preference?: Maybe<IUserClientPreferenceAssignmentInput>;
  projectID: Scalars['ID'];
  rating: Scalars['Float'];
  reviewTags: Array<IProjectReviewTagWithoutProjectIdInput>;
}


export interface IProjectScopeAssignmentInput {
  additionalInfo?: Maybe<Scalars['String']>;
  bidsDueDate: Scalars['Time'];
  estimateDueDate?: Maybe<Scalars['Time']>;
  estimatedEstimatorHours?: Maybe<Scalars['Float']>;
  estimatorHours: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  pricing?: Maybe<IProjectPricingCondition>;
  projectID: Scalars['ID'];
}

export enum IProjectStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Estimated = 'ESTIMATED',
  Estimating = 'ESTIMATING',
  New = 'NEW',
  PendingEstimator = 'PENDING_ESTIMATOR',
  Published = 'PUBLISHED',
  RevisionRequested = 'REVISION_REQUESTED',
  RevisionSubmitted = 'REVISION_SUBMITTED',
  Sized = 'SIZED'
}

export interface IProjectStatusAssignmentInput {
  description?: Maybe<Scalars['String']>;
  status: IProjectStatus;
  uuid: Scalars['ID'];
}

export interface IProjectSubmissionInput {
  bidsDueDate: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  files?: Maybe<Array<IProjectUploadInput>>;
  formattedAddress?: Maybe<Scalars['String']>;
  isSaas: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  trades: Array<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  uuid: Scalars['String'];
  wageType: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
}

export interface IProjectTradesUpdateInput {
  id: Scalars['ID'];
  trades: Array<Scalars['ID']>;
}

export interface IProjectUpdateInput {
  projectID: Scalars['ID'];
  trades?: Maybe<Array<Scalars['ID']>>;
  wageType?: Maybe<Scalars['String']>;
}

export interface IProjectUploadInput {
  filename: Scalars['String'];
  uuid: Scalars['String'];
}


export interface IProjectsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IProjectCondition>;
  orderBy?: Maybe<IProjectsOrderBy>;
}


export enum IProjectsOrderBy {
  DateDueAsc = 'DATE_DUE_ASC',
  DateDueDesc = 'DATE_DUE_DESC',
  DateSubmittedAsc = 'DATE_SUBMITTED_ASC',
  DateSubmittedDesc = 'DATE_SUBMITTED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TeamAsc = 'TEAM_ASC',
  TeamDesc = 'TEAM_DESC'
}


export interface IPublicEventInput {
  eventTypeName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  projectUUID: Scalars['String'];
  publicType: IPublicEventType;
  seen?: Maybe<Scalars['Boolean']>;
}

export enum IPublicEventType {
  EstimateView = 'ESTIMATE_VIEW',
  Subscribe = 'SUBSCRIBE'
}

export interface IPublishEstimateInput {
  ProjectID: Scalars['ID'];
}


export enum IQuoteExpiration {
  EighteenHours = 'EIGHTEEN_HOURS',
  TwelveHours = 'TWELVE_HOURS',
  TwentyfourHours = 'TWENTYFOUR_HOURS'
}



export interface IRateCollectionCondition {
  id?: Maybe<Scalars['ID']>;
  sourceID?: Maybe<Scalars['ID']>;
}



export interface IRateCollectionsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IRateCollectionCondition>;
  orderBy?: Maybe<IRateCollectionsOrderBy>;
}

export enum IRateCollectionsOrderBy {
  Natural = 'NATURAL'
}


export enum IRateType {
  Equipment = 'EQUIPMENT',
  Labor = 'LABOR',
  Material = 'MATERIAL',
  Production = 'PRODUCTION',
  Scope = 'SCOPE'
}


export enum IRefusalReasons {
  NotEnoughHours = 'NOT_ENOUGH_HOURS',
  Other = 'OTHER'
}


export interface IReviewCondition {
  projectID?: Maybe<Scalars['ID']>;
  reviewID?: Maybe<Scalars['ID']>;
}


export interface IReviewInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IReviewCondition>;
}



export interface IReviewTagInput {
  id?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
}



export interface IReviewsCondition {
  projectID: Array<Scalars['ID']>;
}

export interface IReviewsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IReviewsCondition>;
}

export interface IS3UploadInput {
  id: Scalars['ID'];
  type: ILinkType;
}


export interface IScheduleAssignmentInput {
  availability?: Maybe<Array<IWeekday>>;
  id?: Maybe<Scalars['ID']>;
  paused?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Array<Maybe<ICommitmentInput>>>;
  userID: Scalars['ID'];
}

export interface IScheduleInput {
  id: Scalars['ID'];
}


export interface IScopeCondition {
  categoryID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
}


export interface IScopeInput {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  condition?: Maybe<IScopeCondition>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}



export interface ISignupInput {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles: Array<IUserRole>;
  secretID?: Maybe<Scalars['ID']>;
  type: ITeamAssignmentType;
}




export interface ISourceBatchInput {
  county: Scalars['String'];
  items: Array<ISourceBatchInputItem>;
  state: Scalars['String'];
}

export interface ISourceBatchInputItem {
  sourceId: Scalars['ID'];
}

export interface ISourceCondition {
  categoryID?: Maybe<Scalars['ID']>;
  countyNamelsad?: Maybe<Scalars['String']>;
  countyfp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  library: Scalars['Boolean'];
  parentSourceID?: Maybe<Scalars['ID']>;
  searchTerm?: Maybe<Scalars['String']>;
  sourceType: ISourceType;
  stateName?: Maybe<Scalars['String']>;
  statefp?: Maybe<Scalars['String']>;
}


export interface ISourceDescriptionInput {
  name: Scalars['String'];
}


export interface ISourceInput {
  assemblyID?: Maybe<Scalars['ID']>;
  countyNamelsad?: Maybe<Scalars['String']>;
  countyfp?: Maybe<Scalars['String']>;
  materialID?: Maybe<Scalars['ID']>;
  stateName?: Maybe<Scalars['String']>;
  statefp?: Maybe<Scalars['String']>;
}

export enum ISourceType {
  Equipment = 'EQUIPMENT',
  GroupAssembly = 'GROUP_ASSEMBLY',
  ItemAssembly = 'ITEM_ASSEMBLY',
  Labor = 'LABOR',
  Material = 'MATERIAL',
  Scope = 'SCOPE'
}

export enum ISourceTypeV2 {
  Assembly = 'ASSEMBLY',
  Material = 'MATERIAL'
}



export interface ISourcesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<ISourceCondition>;
  orderBy?: Maybe<ISourcesOrderBy>;
}

export enum ISourcesOrderBy {
  Natural = 'NATURAL'
}





export interface IStatesConditon {
  limit: Scalars['Int'];
}


export enum IStripeStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}


export interface ISubmittedProjectInput {
  ownerID: Scalars['ID'];
  projectID: Scalars['ID'];
}

export interface ISubscribeToPublicEstimateInput {
  projectID: Scalars['ID'];
  subscribe: Scalars['Boolean'];
}


export interface ISubscriptionStartInput {
  subscriptionPlan?: Maybe<Scalars['String']>;
  url: Scalars['String'];
}



export interface ISupplierInput {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name: Scalars['String'];
}


export interface ITeamAccountSyncInput {
  correctTeamID: Scalars['ID'];
  incorrectTeamID: Scalars['ID'];
}

export enum ITeamAccountType {
  Cancelled = 'CANCELLED',
  Subscriber = 'SUBSCRIBER',
  Trial = 'TRIAL'
}

export interface ITeamAssignmentInput {
  authID: Scalars['ID'];
  roles: Array<IUserRole>;
  secretID?: Maybe<Scalars['ID']>;
  type: ITeamAssignmentType;
}

export enum ITeamAssignmentType {
  Estimator = 'ESTIMATOR',
  Invite = 'INVITE',
  Old = 'OLD',
  Onboarding = 'ONBOARDING'
}

export interface ITeamCondition {
  id?: Maybe<Scalars['ID']>;
}

export interface ITeamCreditAssignmentInput {
  teamID: Scalars['ID'];
  usdCents: Scalars['Int'];
}






export interface ITeamFilesInput {
  teamProjectInfoID: Scalars['ID'];
}

export interface ITeamFilesRemoveInput {
  uuids: Array<Scalars['String']>;
}

export interface ITeamInput {
  id: Scalars['ID'];
}




export interface ITeamLeaderOnboardingAssignmentInput {
  bidValue: Scalars['String'];
  businessType: IUserBusiness;
  companyRole?: Maybe<IUserCompanyRole>;
  id: Scalars['ID'];
  phone: Scalars['String'];
  projectTypes: Scalars['String'];
  service: IUserService;
  teamID: Scalars['ID'];
  teamName: Scalars['String'];
}


export interface ITeamOnboardingInput {
  balance?: Maybe<Scalars['Int']>;
  contract?: Maybe<IContractInput>;
  credits?: Maybe<Scalars['Int']>;
  cycleDay?: Maybe<Scalars['Int']>;
  refereeID?: Maybe<Scalars['ID']>;
  stripeCustomerID: Scalars['String'];
  teamID?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  teamUUID?: Maybe<Scalars['ID']>;
}





export interface ITeamProjectInfoInput {
  averageProjectPrice: IAverageProjectPriceInput;
  estimateTypes?: Maybe<Array<IEstimateType>>;
  files?: Maybe<Array<ITeamProjectUploadInput>>;
  formattingPreferences?: Maybe<Array<IFormattingPreference>>;
  hourlyLaborRates?: Maybe<Array<Maybe<IHourlyLaborRateInput>>>;
  id?: Maybe<Scalars['ID']>;
  inclusionsExclusions?: Maybe<Scalars['String']>;
  preferredMarkups: IPreferredMarkupsInput;
  projectUpdate?: Maybe<IProjectUpdateInput>;
  requestsAndNotes?: Maybe<Scalars['String']>;
  teamID?: Maybe<Scalars['ID']>;
}

export interface ITeamProjectUploadInput {
  directory: IFileDirectory;
  filename: Scalars['String'];
  uuid: Scalars['String'];
}


export interface ITeamsInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<ITeamCondition>;
  orderBy?: Maybe<ITeamsOrderBy>;
}

export enum ITeamsOrderBy {
  Natural = 'NATURAL'
}





export interface ITradeInput {
  projectID?: Maybe<Scalars['ID']>;
  tradeID?: Maybe<Scalars['ID']>;
}




export enum IUnitType {
  D1 = 'D1',
  D2 = 'D2',
  D3 = 'D3',
  Item = 'ITEM'
}


export interface IUnsubscribePublicEstimateInput {
  hash: Scalars['String'];
  projectUUID: Scalars['String'];
}


export enum IUserBusiness {
  DesignBuild = 'DESIGN_BUILD',
  Developer = 'DEVELOPER',
  GeneralContractor = 'GENERAL_CONTRACTOR',
  Handyman = 'HANDYMAN',
  Homeowner = 'HOMEOWNER',
  Other = 'OTHER',
  SubcontractorMultiTrade = 'SUBCONTRACTOR_MULTI_TRADE',
  SubcontractorSingleTrade = 'SUBCONTRACTOR_SINGLE_TRADE',
  Supplier = 'SUPPLIER'
}

export interface IUserClientPreferenceAssignmentInput {
  id: Scalars['ID'];
  status: IClientPreferenceStatus;
  teamID: Scalars['ID'];
}

export enum IUserCompanyRole {
  Craft = 'CRAFT',
  Engineer = 'ENGINEER',
  Estimator = 'ESTIMATOR',
  Foreman = 'FOREMAN',
  Other = 'OTHER',
  Owner = 'OWNER',
  ProjectManager = 'PROJECT_MANAGER',
  Superintendent = 'SUPERINTENDENT'
}

export interface IUserCondition {
  id?: Maybe<Scalars['ID']>;
  roles?: Maybe<Array<IUserRole>>;
  showPaused?: Maybe<Scalars['Boolean']>;
  teamID?: Maybe<Scalars['ID']>;
}

export interface IUserConnectInput {
  email: Scalars['String'];
  roles?: Maybe<Array<IUserRole>>;
  teamID: Scalars['ID'];
}

export interface IUserCreateInput {
  authID: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
  roles: Array<IUserRole>;
  teamID?: Maybe<Scalars['ID']>;
}

export interface IUserDeleteInput {
  id: Scalars['ID'];
}


export interface IUserInput {
  authID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}

export enum IUserRole {
  Admin = 'ADMIN',
  Builder = 'BUILDER',
  Estimator = 'ESTIMATOR',
  Impersonator = 'IMPERSONATOR',
  Superadmin = 'SUPERADMIN'
}

export enum IUserService {
  BothNotSure = 'BOTH_NOT_SURE',
  Estimator = 'ESTIMATOR',
  Software = 'SOFTWARE'
}

export interface IUserTradeRatingAssignmentInput {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  tradeID: Scalars['ID'];
}

export interface IUserUpdateInput {
  id: Scalars['ID'];
  patch: IUserUpdatePatch;
}

export interface IUserUpdatePatch {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
}


export interface IUsersInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IUserCondition>;
  orderBy?: Maybe<IUsersOrderBy>;
}

export interface IUsersInputSimple {
  page: Scalars['Int'];
}

export enum IUsersOrderBy {
  Natural = 'NATURAL'
}

export interface IUsersSearchInput {
  limit?: Maybe<Scalars['Int']>;
  term: Scalars['String'];
}


export interface IVariableCondition {
  formulaID?: Maybe<Scalars['ID']>;
}


export interface IVariableInput {
  measuredQuantity?: Maybe<IMeasuredQuantityInput>;
  measuredQuantityID?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: IVariableType;
}

export enum IVariableType {
  Direct = 'DIRECT',
  Prompt = 'PROMPT'
}


export interface IVariablesInput {
  arguments?: Maybe<IPageArguments>;
  condition?: Maybe<IVariableCondition>;
  orderBy?: Maybe<IVariablesOrderBy>;
}

export enum IVariablesOrderBy {
  Natural = 'NATURAL'
}


export interface IViewAnalyticsInput {
  endTime?: Maybe<Scalars['Time']>;
  slug: Scalars['String'];
  startTime?: Maybe<Scalars['Time']>;
}

export interface IWageTypeUpdateInput {
  projectID: Scalars['ID'];
  wageType: Scalars['String'];
}

export enum IWeekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}


export type IAssemblyFragment = { id: string, uuid: string, description: string, favorited: boolean, projectID: Maybe<string>, usedInProject: boolean, assemblyType: IAssemblyType, childOrder: Array<string>, trade: Maybe<ITradeFragment>, unit: IUnitFragment, project: Maybe<IProjectLightFragment> };

export type IAssemblyLightFragment = { id: string, description: string, projectID: Maybe<string>, usedInProject: boolean, unit: IUnitFragment };

export type IEstimateAssemblyFragment = { id: string, uuid: string, description: string, favorited: boolean, usedInProject: boolean, assemblyType: IAssemblyType, childOrder: Array<string>, trade: Maybe<ITradeFragment> };

export type IAssemblyLibraryFragment = { id: string, is1b: boolean, name: Maybe<string> };

export type IAssetFragment = { id: string, assetID: Maybe<string>, assetType: IAssetType, extension: Maybe<string>, ingested: Maybe<boolean>, name: string, path: string, parentID: Maybe<string>, position: number, projectID: Maybe<string>, projectPlanPageID: Maybe<string>, processed: Maybe<boolean>, createdAt: any, updatedAt: any, dimension: Maybe<IAssetDimensionFragment>, variants: Maybe<IAssetVariantsFragment> };

export type IAssetDimensionFragment = { orientation: IAssetOrientation, right: number, top: number };

export type IAssetsGraphFragment = { id: string, graph: string };

export type IAssetVariantsFragment = { ORIGINAL: Maybe<string>, THUMBNAIL_S_R0: Maybe<string>, THUMBNAIL_S_R90: Maybe<string>, THUMBNAIL_S_R180: Maybe<string>, THUMBNAIL_S_R270: Maybe<string>, THUMBNAIL_L_R0: Maybe<string>, THUMBNAIL_L_R90: Maybe<string>, THUMBNAIL_L_R180: Maybe<string>, THUMBNAIL_L_R270: Maybe<string>, THUMBNAIL_XL_R0: Maybe<string>, THUMBNAIL_XL_R90: Maybe<string>, THUMBNAIL_XL_R180: Maybe<string>, THUMBNAIL_XL_R270: Maybe<string>, R0: Maybe<string>, R90: Maybe<string>, R180: Maybe<string>, R270: Maybe<string> };

export type ICategoryFragment = { id: string, csiDivision: Maybe<ICsiDivision>, csiSection: Maybe<string>, csiTitle: Maybe<string>, occCode: Maybe<string>, occTitle: Maybe<string>, createdAt: any, updatedAt: any, materials: Maybe<{ edges: Maybe<Array<{ node: { id: string } }>> }> };

export type IClientPreferenceFragment = { id: string, preference: IClientPreferenceStatus, teamID: string, team: ITeamLightFragment };

export type IContractFragment = { id: string, cancellationGracePeriod: number, commitmentLength: number, creditVolumeUpgrade: number, pandaDocID: string, subscriptionDiscount: number, subscriptionFreeCredits: number, subscriptionMonthlyFee: number, teamOnboardingID: string, trialOfferExpirationDate: Maybe<any> };

export type ICoordinateFragment = { x: number, y: number };

export type ISourcePreviewFragment = { id: string, name: string, sourceType: ISourceTypeV2 };

export type ISourceV2Fragment = { id: string, sourceType: ISourceTypeV2, name: string, uom: string, materialRateUsdCents: Maybe<number>, laborRateUsdCents: Maybe<number>, burdenedLaborRateUsdCents: Maybe<number>, laborSourceId: Maybe<string>, laborName: Maybe<string>, productionRate: Maybe<number>, calculatedUnitRateUsdCents: number, nestedSources: Maybe<Array<Maybe<{ id: string, sourceType: ISourceTypeV2, name: string, uom: string, materialRateUsdCents: Maybe<number>, laborRateUsdCents: Maybe<number>, burdenedLaborRateUsdCents: Maybe<number>, laborSourceId: Maybe<string>, laborName: Maybe<string>, productionRate: Maybe<number>, calculatedUnitRateUsdCents: number, quantity: Maybe<number>, seqNum: Maybe<number> }>>> };

export type ICountyFragment = { id: string, statefp: string, countyfp: string, countyns: string, geoid: string, name: string, namelsad: string, lsad: string, classfp: string, mtfcc: string, csafp: string, cbsafp: string, metdivfp: string, funcstat: string, aland: number, awater: number, intptlat: string, intptlon: string, createdAt: any, updatedAt: any };

export type IElementFragment = { id: string, uuid: string, equipmentRate: number, name: string, laborRate: number, localizedLaborRate: number, materialRate: number, pendingLocalizedCostData: boolean, previousElement: Maybe<string>, productionRate: number, fromTakeoffValue: Maybe<number>, ownerID: Maybe<string>, materialID: Maybe<string>, formulaID: string, favorited: boolean, assembly: Maybe<{ uuid: string, childOrder: Array<string> }>, expression: IExpressionFragment, material: Maybe<IMaterialFragment>, formula: IFormulaFragment, unit: IUnitFragment, variables: { edges: Maybe<Array<{ cursor: string, node: IVariableFragment }>> } };

export type IElementLightFragment = { id: string, uuid: string, equipmentRate: number, name: string, laborRate: number, localizedLaborRate: number, materialRate: number, pendingLocalizedCostData: boolean, previousElement: Maybe<string>, productionRate: number, fromTakeoffValue: Maybe<number>, ownerID: Maybe<string>, materialID: Maybe<string>, formulaID: string, favorited: boolean, expression: IExpressionFragment, material: Maybe<IMaterialFragment>, unit: IUnitFragment };

export type IEstimateElementFragment = { id: string, uuid: string, equipmentRate: number, name: string, laborRate: number, materialRate: number, previousElement: Maybe<string>, productionRate: number, favorited: boolean, assembly: Maybe<{ uuid: string, childOrder: Array<string> }>, expression: IExpressionFragment, unit: IUnitFragment };

export type IEventFragment = { id: string, message: string, type: string, seen: boolean, createdAt: any, updatedAt: any, projectID: Maybe<string>, project: Maybe<IProjectFragment> };

export type IExpressionFragment = { result: number, tokens: Array<CustomScalars['Token']> };

export type IFormulaFragment = { id: string, uuid: string, expression: string, takeoffUnit: Maybe<IUnitFragment> };

export type IMarkupPointFragment = (
  { __typename: 'Coordinate' }
  & ICoordinateFragment
);

export type IMarkupLineFragment = { __typename: 'MarkupLine', points: Array<IMarkupPointFragment> };

export type IMarkupPolygonFragment = { __typename: 'MarkupPolygon', rings: Array<IMarkupLineFragment> };

type IMarkupGeometry_Coordinate_Fragment = IMarkupPointFragment;

type IMarkupGeometry_MarkupLine_Fragment = IMarkupLineFragment;

type IMarkupGeometry_MarkupPolygon_Fragment = IMarkupPolygonFragment;

export type IMarkupGeometryFragment = IMarkupGeometry_Coordinate_Fragment | IMarkupGeometry_MarkupLine_Fragment | IMarkupGeometry_MarkupPolygon_Fragment;

export type IMarkupFragment = { __typename: 'Markup', id: string, name: string, color: string, markupType: IMarkupType, markupGroupID: Maybe<string>, measurement: number, projectPlanPageID: string, isSelected: boolean, geometries: Maybe<Array<IMarkupGeometry_Coordinate_Fragment | IMarkupGeometry_MarkupLine_Fragment | IMarkupGeometry_MarkupPolygon_Fragment>> };

export type IMarkupGroupFragment = { __typename: 'MarkupGroup', id: string, name: string, markupGroupType: Maybe<IMarkupType>, measurement: number, markups: Array<IMarkupFragment> };

export type IMaterialFragment = { id: string, name: string, categoryID: string, ownerID: Maybe<string>, takeoffUnitID: string, createdAt: any, updatedAt: any };

export type IMaterialLightFragment = { id: string, name: string, categoryID: string };

export type IMeasuredQuantityFragment = { id: string, value: Maybe<number>, unitID: string, unit: IUnitFragment };

export type IPageInfoFragment = { totalCount: number, startCursor: string, endCursor: string, hasNextPage: boolean, hasPreviousPage: boolean };

export type IOldPageInfoFragment = { startCursor: string, endCursor: string, hasNextPage: boolean, hasPreviousPage: boolean };

type IConnPageInfo_Assemblies_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Categories_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Estimators_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Events_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Labors_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Materials_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_ProjectPrices_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Projects_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_RateCollections_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Rates_Fragment = { pageInfo: IPageInfoFragment };

type IConnPageInfo_Sources_Fragment = { pageInfo: IPageInfoFragment };

export type IConnPageInfoFragment = IConnPageInfo_Assemblies_Fragment | IConnPageInfo_Categories_Fragment | IConnPageInfo_Estimators_Fragment | IConnPageInfo_Events_Fragment | IConnPageInfo_Labors_Fragment | IConnPageInfo_Materials_Fragment | IConnPageInfo_ProjectPrices_Fragment | IConnPageInfo_Projects_Fragment | IConnPageInfo_RateCollections_Fragment | IConnPageInfo_Rates_Fragment | IConnPageInfo_Sources_Fragment;

type IOldConnPageInfo_AssemblyLibraries_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Assets_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Contracts_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Counties_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Elements_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_ProjectPricings_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_ProjectsOld_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Reviews_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Scopes_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_States_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Teams_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Users_Fragment = { pageInfo: IOldPageInfoFragment };

type IOldConnPageInfo_Variables_Fragment = { pageInfo: IOldPageInfoFragment };

export type IOldConnPageInfoFragment = IOldConnPageInfo_AssemblyLibraries_Fragment | IOldConnPageInfo_Assets_Fragment | IOldConnPageInfo_Contracts_Fragment | IOldConnPageInfo_Counties_Fragment | IOldConnPageInfo_Elements_Fragment | IOldConnPageInfo_ProjectPricings_Fragment | IOldConnPageInfo_ProjectsOld_Fragment | IOldConnPageInfo_Reviews_Fragment | IOldConnPageInfo_Scopes_Fragment | IOldConnPageInfo_States_Fragment | IOldConnPageInfo_Teams_Fragment | IOldConnPageInfo_Users_Fragment | IOldConnPageInfo_Variables_Fragment;

export type IProjectPlanFileFragment = { id: string, uuid: string, filename: string, projectID: string };

export type IProjectPriceFragment = { id: string, assignmentID: string, pricingType: string, priceUSDCents: number };

export type IProjectFragment = { id: string, uuid: string, additionalInfo: Maybe<string>, bidsDueDate: any, contingency: Maybe<number>, childOrder: Array<string>, equipmentPriceMarkup: Maybe<number>, estimateDueDate: Maybe<any>, estimatorCostUsdCents: number, estimatorHours: number, feedback: Maybe<string>, isApprovalRequired: Maybe<boolean>, isSaas: boolean, isSetupCompleted: boolean, laborPriceMarkup: Maybe<number>, materialPriceMarkup: Maybe<number>, name: Maybe<string>, overheadAndProfit: Maybe<number>, status: Maybe<IProjectStatus>, teamID: Maybe<string>, type: Maybe<string>, wageType: string, area: Maybe<IProjectAreaFragment>, location: Maybe<IProjectLocationFragment>, markups: Maybe<{ edges: Maybe<Array<{ cursor: string, node: IProjectMarkupFragment }>> }>, prices: Maybe<{ edges: Maybe<Array<{ node: IProjectPriceFragment }>> }>, projectScope: Maybe<{ id: string, projectID: string, bidsDueDate: any, status: string, estimateDueDate: Maybe<any>, estimatorHours: number, estimatedEstimatorHours: Maybe<number>, additionalInfo: Maybe<string>, pricing: Maybe<{ id: string, active: boolean, status: IPricingStatus, usdCents: Maybe<number>, lowEndUsdCents: Maybe<number>, highEndUsdCents: Maybe<number> }> }> };

export type IProjectLightFragment = { id: string, uuid: string, bidsDueDate: any, isSaas: boolean, name: Maybe<string>, status: Maybe<IProjectStatus>, prices: Maybe<{ edges: Maybe<Array<{ node: IProjectPriceFragment }>> }> };

export type IProjectAreaFragment = { id: string, projectID: number, squareFeetUnderRoof: Maybe<number>, squareFeetTotalLivable: Maybe<number> };

export type IProjectLocationFragment = { id: string, city: Maybe<string>, coordinates: Maybe<any>, formattedAddress: Maybe<string>, projectID: number, state: Maybe<string>, streetAddress: Maybe<string>, zip: Maybe<string>, county: Maybe<string> };

export type IProjectMarkupFragment = { id: string, description: Maybe<string>, percentage: Maybe<number>, projectID: number, total: Maybe<number> };

export type IProjectWithMarkupEntriesFragment = { id: string, uuid: string, markupEntries: Array<(
    { __typename: 'Markup' }
    & IMarkupFragment
  ) | (
    { __typename: 'MarkupGroup' }
    & IMarkupGroupFragment
  )> };

export type IRateCollectionFragment = { assignedRateID: Maybe<string>, assignedRate: Maybe<{ priceUSDCents: number }> };

export type IReviewTagFragment = { id: string, tag: string, isPositive: boolean };

export type IScheduleFragment = { id: string, availability: Maybe<Array<IWeekday>>, paused: boolean, unavailable: Maybe<Array<ICommitmentFragment>> };

export type ICommitmentFragment = { id: string, from: any, status: ICommitmentStatus, to: any };

export type ISetupIntentFragment = { clientSecret: string };

export type ISourceFragment = { id: string, assemblyID: Maybe<string>, sourceType: ISourceType, laborID: Maybe<string>, materialID: Maybe<string>, elementID: Maybe<string>, locationID: Maybe<string>, createdAt: any, updatedAt: any, assembly: Maybe<IAssemblyLightFragment>, labor: Maybe<{ id: string, categoryID: Maybe<string>, description: string }>, material: Maybe<IMaterialFragment>, element: Maybe<IElementFragment>, materialRateCollection: Maybe<IRateCollectionFragment>, laborRateCollection: Maybe<IRateCollectionFragment>, productionRateCollection: Maybe<IRateCollectionFragment>, sources: Maybe<{ edges: Maybe<Array<{ node: { id: string, assemblyID: Maybe<string>, sourceType: ISourceType, laborID: Maybe<string>, materialID: Maybe<string>, elementID: Maybe<string>, locationID: Maybe<string>, createdAt: any, updatedAt: any, assembly: Maybe<IAssemblyLightFragment>, labor: Maybe<{ id: string, categoryID: Maybe<string>, description: string }>, material: Maybe<IMaterialFragment>, element: Maybe<IElementFragment>, materialRateCollection: Maybe<IRateCollectionFragment>, laborRateCollection: Maybe<IRateCollectionFragment>, productionRateCollection: Maybe<IRateCollectionFragment> } }>> }> };

export type IStateFragment = { id: string, region: string, division: string, statefp: string, statens: string, geoid: string, stusps: string, name: string, lsad: string, mtfcc: string, funcstat: string, aland: number, awater: number, intptlat: string, intptlon: string, createdAt: any, updatedAt: any };

export type ITeamFragment = { id: string, uuid: string, name: string, leadUserID: string, hasPaymentMethod: boolean, balance: Maybe<IBalanceFragment>, creditHistory: Maybe<{ edges: Maybe<Array<{ cursor: string, node: ITeamCreditHistoryFragment }>> }>, leadUser: Maybe<IUserLightFragment>, onboarding: Maybe<ITeamOnboardingFragment>, subscription: Maybe<IStripeSubscriptionFragment>, teamProjectInfo: Maybe<ITeamProjectInfoFragment> };

export type ITeamProjectInfoFragment = { id: string, estimateTypes: Maybe<Array<IEstimateType>>, formattingPreferences: Maybe<Array<IFormattingPreference>>, inclusionsExclusions: Maybe<string>, requestsAndNotes: Maybe<string>, preferredMarkups: Maybe<{ overhead: Maybe<number>, profit: Maybe<number>, material: Maybe<number>, labor: Maybe<number> }>, averageProjectPrice: { pricePerSquareFoot: number, priceTotal: number }, hourlyLaborRates: Maybe<Array<Maybe<{ rateUSDCents: number, type: string }>>> };

export type ITeamBillingFragment = { balance: Maybe<IBalanceFragment>, creditHistory: Maybe<{ edges: Maybe<Array<{ node: { projectID: Maybe<number>, amount: number } }>> }>, projects: Maybe<{ edges: Maybe<Array<{ cursor: string, node: IProjectLightFragment }>> }>, subscription: Maybe<IStripeSubscriptionFragment> };

export type ITeamLightFragment = { id: string, uuid: string, name: string };

export type IBalanceFragment = { id: string, teamID: number, usdCents: number };

export type IStripeSubscriptionFragment = { id: string, amount: number, currentPeriodEnd: any, currentPeriodStart: any, priceID: Maybe<string>, status: IStripeStatus, wasTrial: boolean, createdAt: any };

export type ITeamOnboardingFragment = { id: string, accountType: Maybe<ITeamAccountType>, daysLeftInTrial: number, teamID: string, stripeCustomerID: string, welcomed: boolean, createdAt: any };

export type ITeamCreditHistoryFragment = { id: string, amount: number, stripeChargeID: Maybe<string>, teamID: number, projectID: Maybe<number>, project: Maybe<IProjectLightFragment> };

export type ITradeFragment = { id: string, isLinked: boolean, name: string, nickname: string, orderWeight: number };

export type ITradeRatingFragment = { id: string, rating: number, tradeID: string, trade: ITradeFragment };

export type IUnitFragment = { id: string, definition: string, description: string, name: string, unitType: IUnitType };

export type IUserFragment = { id: string, authID: string, email: string, firstName: string, lastName: string, phone: string, roles: Array<IUserRole>, status: Maybe<IEstimatorStatus>, timezone: Maybe<string>, scheduleID: Maybe<string>, teamID: Maybe<string>, bidValue: string, createdAt: any, companyRole: Maybe<IUserCompanyRole>, businessType: Maybe<IUserBusiness>, serviceType: Maybe<IUserService>, address: Maybe<string>, state: Maybe<string>, city: Maybe<string>, postalCode: Maybe<string>, country: Maybe<string>, logoLink: Maybe<string>, preferredClients: Maybe<Array<IClientPreferenceFragment>>, schedule: Maybe<IScheduleFragment>, team: Maybe<ITeamFragment>, tradeRatings: Maybe<Array<ITradeRatingFragment>> };

export type IUserLightFragment = { id: string, authID: string, firstName: string, lastName: string, phone: string, teamID: Maybe<string>, status: Maybe<IEstimatorStatus>, timezone: Maybe<string>, createdAt: any, address: Maybe<string>, state: Maybe<string>, city: Maybe<string>, postalCode: Maybe<string>, country: Maybe<string>, logoLink: Maybe<string>, preferredClients: Maybe<Array<IClientPreferenceFragment>>, team: Maybe<ITeamLightFragment>, schedule: Maybe<IScheduleFragment>, tradeRatings: Maybe<Array<ITradeRatingFragment>> };

export type IEstimatorLightFragment = { id: string, authID: string, firstName: string, lastName: string, teamID: Maybe<string>, status: Maybe<IEstimatorStatus>, preferredByBuilder: Maybe<IClientPreferenceStatus> };

export type IVariableFragment = { id: string, uuid: string, formulaID: string, name: string, type: string, measuredQuantityID: string, measuredQuantity: IMeasuredQuantityFragment };

export type IAssembliesDeletionMutationVariables = Exact<{
  input: IAssemblyDeletionInput;
}>;


export type IAssembliesDeletionMutation = { assembliesDeletion: Array<string> };

export type IAssembliesDeletionForV2OrderingMutationVariables = Exact<{
  input: IAssembliesDeletionForV2OrderingInput;
}>;


export type IAssembliesDeletionForV2OrderingMutation = { assembliesDeletionForV2Ordering: { uuid: string, childOrder: Array<string> } };

export type IAssembliesDuplicationMutationVariables = Exact<{
  input: IAssembliesCopyInput;
}>;


export type IAssembliesDuplicationMutation = { assembliesDuplication: { edges: Maybe<Array<{ cursor: string, node: (
        { elements: { edges: Maybe<Array<{ cursor: string, node: IElementFragment }>> } }
        & IAssemblyFragment
      ) }>> } };

export type IAssemblyAssignmentMutationVariables = Exact<{
  input: IAssemblyAssignmentInput;
}>;


export type IAssemblyAssignmentMutation = { assemblyAssignment: (
    { project: Maybe<{ uuid: string, childOrder: Array<string> }>, elements: { edges: Maybe<Array<{ cursor: string, node: IElementFragment }>> } }
    & IAssemblyFragment
  ) };

export type IAssemblyReorderMutationVariables = Exact<{
  input: IAssemblyReorderInput;
}>;


export type IAssemblyReorderMutation = { assemblyReorder: IAssemblyFragment };

export type IAssemblyReorderBeforeUuidMutationVariables = Exact<{
  input: IAssemblyReorderBeforeUuidInput;
}>;


export type IAssemblyReorderBeforeUuidMutation = { assemblyReorderBeforeUUID: { uuid: string, childOrder: Array<string> } };

export type IAssemblyLibraryAssignmentMutationVariables = Exact<{
  input: IAssemblyLibraryAssignmentInput;
}>;


export type IAssemblyLibraryAssignmentMutation = { assemblyLibraryAssignment: { edges: Maybe<Array<{ cursor: string, node: IAssemblyFragment }>> } };

export type IAssetAssignmentMutationVariables = Exact<{
  input: IAssetAssignmentsInput;
}>;


export type IAssetAssignmentMutation = { assetsAssignment: { edges: Maybe<Array<{ cursor: string }>> } };

export type IAssetsDuplicationMutationVariables = Exact<{
  input: IAssetsPositioningInput;
}>;


export type IAssetsDuplicationMutation = { assetsDuplication: { edges: Maybe<Array<{ cursor: string }>> } };

export type IAssetsGroupingMutationVariables = Exact<{
  input: IAssetsGroupingInput;
}>;


export type IAssetsGroupingMutation = { assetsGrouping: { edges: Maybe<Array<{ cursor: string }>> } };

export type IAssetsDeletionMutationVariables = Exact<{
  input: IAssetsDeletionInput;
}>;


export type IAssetsDeletionMutation = { assetsDeletion: { edges: Maybe<Array<{ cursor: string }>> } };

export type IAssetsExportMutationVariables = Exact<{
  input: IAssetExportInput;
}>;


export type IAssetsExportMutation = { assetsExport: string };

export type IAssetsRotationMutationVariables = Exact<{
  input: IAssetsOrientationInput;
}>;


export type IAssetsRotationMutation = { assetsRotation: { edges: Maybe<Array<{ cursor: string }>> } };

export type IForgotPasswordMutationVariables = Exact<{
  input: IForgotPasswordInput;
}>;


export type IForgotPasswordMutation = { forgotPassword: string };

export type IImpersonateMutationVariables = Exact<{
  input: IPersonationInput;
}>;


export type IImpersonateMutation = { impersonate: { accessToken: string, refreshToken: string, impersonatedAuthID: Maybe<string>, user: IUserFragment } };

export type ILoginMutationVariables = Exact<{
  input: ILoginInput;
}>;


export type ILoginMutation = { login: { accessToken: string, refreshToken: string, impersonatedAuthID: Maybe<string>, user: IUserFragment } };

export type ISignupMutationVariables = Exact<{
  input: ISignupInput;
}>;


export type ISignupMutation = { signup: { accessToken: string, refreshToken: string, impersonatedAuthID: Maybe<string>, user: IUserFragment } };

export type IElementAssignmentMutationVariables = Exact<{
  input: IElementAssignmentInput;
}>;


export type IElementAssignmentMutation = { elementAssignment: (
    { assembly: Maybe<{ uuid: string, childOrder: Array<string> }> }
    & IElementFragment
  ) };

export type IElementGroupingMutationVariables = Exact<{
  input: IElementGroupingInput;
}>;


export type IElementGroupingMutation = { elementGrouping: IElementFragment };

export type IElementReorderMutationVariables = Exact<{
  input: IElementReorderInput;
}>;


export type IElementReorderMutation = { elementReorder: Array<IElementFragment> };

export type IElementReorderBeforeUuidMutationVariables = Exact<{
  input: IElementReorderBeforeUuidInput;
}>;


export type IElementReorderBeforeUuidMutation = { elementReorderBeforeUUID: { uuid: string, childOrder: Array<string> } };

export type IElementUpdateExpressionMutationVariables = Exact<{
  input: IElementUpdateExpressionInput;
}>;


export type IElementUpdateExpressionMutation = { elementUpdateExpression: IElementFragment };

export type IElementsDeletionMutationVariables = Exact<{
  input: IElementsDeletionInput;
}>;


export type IElementsDeletionMutation = { elementsDeletion: Array<string> };

export type IElementsDeletionForV2OrderingMutationVariables = Exact<{
  input: IElementsDeletionForV2OrderingInput;
}>;


export type IElementsDeletionForV2OrderingMutation = { elementsDeletionForV2Ordering: { uuid: string, childOrder: Array<string> } };

export type IPublicEventMutationVariables = Exact<{
  input: Maybe<IPublicEventInput>;
}>;


export type IPublicEventMutation = { publicEvent: Maybe<IEventFragment> };

export type IPublishEstimateMutationVariables = Exact<{
  input: IPublishEstimateInput;
}>;


export type IPublishEstimateMutation = { publishEstimate: IProjectFragment };

export type IMarkupGroupAddMarkupsMutationVariables = Exact<{
  input: IMarkupGroupAddMarkupsInput;
}>;


export type IMarkupGroupAddMarkupsMutation = { markupGroupAddMarkups: Array<IMarkupGroupFragment> };

export type IMarkupGroupCreateMutationVariables = Exact<{
  input: IMarkupGroupCreateInput;
}>;


export type IMarkupGroupCreateMutation = { markupGroupCreate: Array<IMarkupGroupFragment> };

export type IMarkupGroupDeleteMutationVariables = Exact<{
  input: IMarkupGroupDeleteInput;
}>;


export type IMarkupGroupDeleteMutation = { markupGroupDelete: IMarkupGroupFragment };

export type IMarkupGroupRemoveMarkupsMutationVariables = Exact<{
  input: IMarkupGroupRemoveMarkupsInput;
}>;


export type IMarkupGroupRemoveMarkupsMutation = { markupGroupRemoveMarkups: Array<IMarkupGroupFragment> };

export type IMarkupGroupUpdateMutationVariables = Exact<{
  input: IMarkupGroupUpdateInput;
}>;


export type IMarkupGroupUpdateMutation = { markupGroupUpdate: IMarkupGroupFragment };

export type IMarkupUpdateMutationVariables = Exact<{
  input: IMarkupUpdateInput;
}>;


export type IMarkupUpdateMutation = { markupUpdate: IMarkupFragment };

export type ISubscriptionStartMutationVariables = Exact<{
  input: ISubscriptionStartInput;
}>;


export type ISubscriptionStartMutation = { subscriptionStart: { sessionID: Maybe<string>, user: IUserFragment } };

export type IProjectPlanFileCreationMutationVariables = Exact<{
  input: IProjectPlanFileCreationInput;
}>;


export type IProjectPlanFileCreationMutation = { projectPlanFileCreation: IProjectPlanFileFragment };

export type IProjectPlanFilePresignedUrlMutationVariables = Exact<{
  input: IProjectPlanFilePresignedUrlInput;
}>;


export type IProjectPlanFilePresignedUrlMutation = { projectPlanFilePresignedUrl: string };

export type IProjectPricingMutationVariables = Exact<{
  input: IProjectPricingInput;
}>;


export type IProjectPricingMutation = { projectPricing: IProjectFragment };

export type IProjectAreaAssignmentMutationVariables = Exact<{
  input: IProjectAreaAssignmentInput;
}>;


export type IProjectAreaAssignmentMutation = { projectAreaAssignment: IProjectAreaFragment };

export type IProjectLocationAssignmentMutationVariables = Exact<{
  input: IProjectLocationAssignmentInput;
}>;


export type IProjectLocationAssignmentMutation = { projectLocationAssignment: IProjectLocationFragment };

export type IProjectMarkupAssignmentMutationVariables = Exact<{
  input: IProjectMarkupAssignmentInput;
}>;


export type IProjectMarkupAssignmentMutation = { projectMarkupAssignment: IProjectMarkupFragment };

export type IProjectMarkupsDeletionMutationVariables = Exact<{
  input: IProjectMarkupsDeletionInput;
}>;


export type IProjectMarkupsDeletionMutation = { projectMarkupsDeletion: Array<string> };

export type IProjectAcceptanceWithQuoteMutationVariables = Exact<{
  input: IEstimatorQuoteInput;
}>;


export type IProjectAcceptanceWithQuoteMutation = { projectAcceptanceWithQuote: { id: string } };

export type IAcceptProjectPricingMutationVariables = Exact<{
  input: IAcceptProjectPricingInput;
}>;


export type IAcceptProjectPricingMutation = { acceptProjectPricing: Maybe<{ id: string }> };

export type IApproveAutoQuoteProjectPricingMutationVariables = Exact<{
  input: IApproveAutoQuoteProjectPricingInput;
}>;


export type IApproveAutoQuoteProjectPricingMutation = { approveAutoQuoteProjectPricing: { id: string } };

export type IAssignEstimatorMutationVariables = Exact<{
  input: IAssignEstimatorInput;
}>;


export type IAssignEstimatorMutation = { assignEstimator: IProjectFragment };

export type IProjectMutationVariables = Exact<{
  input: IProjectSubmissionInput;
}>;


export type IProjectMutation = { projectSubmission: IProjectFragment };

export type ILaborRateTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type ILaborRateTypeQuery = { laborRateType: Array<Maybe<{ assistant: boolean, description: string }>> };

export type IProjectAcceptanceHistoryAssignmentMutationVariables = Exact<{
  input: IProjectAcceptanceHistoryAssignmentInput;
}>;


export type IProjectAcceptanceHistoryAssignmentMutation = { projectAcceptanceHistoryAssignment: { userID: string, projectID: string } };

export type IProjectEstimateSubmissionMutationVariables = Exact<{
  input: ISubmittedProjectInput;
}>;


export type IProjectEstimateSubmissionMutation = { projectEstimateSubmission: IProjectFragment };

export type IProjectPriceEstimationHistoryMutationVariables = Exact<{
  input: IProjectPriceEstimationHistoryInput;
}>;


export type IProjectPriceEstimationHistoryMutation = { projectPriceEstimationHistory: IProjectFragment };

export type IProjectReviewWithReviewTagsCreationMutationVariables = Exact<{
  input: IProjectReviewWithReviewTagsInput;
}>;


export type IProjectReviewWithReviewTagsCreationMutation = { projectReviewWithReviewTagsCreation: { id: string } };

export type IProjectStatusAssignmentMutationVariables = Exact<{
  input: IProjectStatusAssignmentInput;
}>;


export type IProjectStatusAssignmentMutation = { projectStatusAssignment: { id: string } };

export type IProjectUploadS3MutationVariables = Exact<{
  input: IS3UploadInput;
}>;


export type IProjectUploadS3Mutation = { projectUploadS3: boolean };

export type IPublicUnsubscribeEstimateMutationVariables = Exact<{
  input: Maybe<IUnsubscribePublicEstimateInput>;
}>;


export type IPublicUnsubscribeEstimateMutation = { publicUnsubscribeEstimate: { id: string } };

export type ISubscribeToPublicEstimateMutationVariables = Exact<{
  input: ISubscribeToPublicEstimateInput;
}>;


export type ISubscribeToPublicEstimateMutation = { subscribeToPublicEstimate: IEventFragment };

export type IUnassignEstimatorMutationVariables = Exact<{
  input: IAssignEstimatorInput;
}>;


export type IUnassignEstimatorMutation = { unassignEstimator: IProjectFragment };

export type IScheduleAssignmentMutationVariables = Exact<{
  input: IScheduleAssignmentInput;
}>;


export type IScheduleAssignmentMutation = { scheduleAssignment: { id: string, userID: string, availability: Maybe<Array<IWeekday>>, paused: boolean, unavailable: Maybe<Array<{ id: string, projectID: Maybe<string>, status: ICommitmentStatus, from: any, to: any, createdAt: any, updatedAt: any }>> } };

export type ITeamProjectInfoAssignMutationVariables = Exact<{
  input: ITeamProjectInfoInput;
}>;


export type ITeamProjectInfoAssignMutation = { teamProjectInfoAssign: { id: string, teamID: string, estimateTypes: Maybe<Array<IEstimateType>>, formattingPreferences: Maybe<Array<IFormattingPreference>>, inclusionsExclusions: Maybe<string>, requestsAndNotes: Maybe<string>, averageProjectPrice: { pricePerSquareFoot: number, priceTotal: number }, hourlyLaborRates: Maybe<Array<Maybe<{ id: string, rateUSDCents: number, type: string }>>>, preferredMarkups: Maybe<{ overhead: Maybe<number>, profit: Maybe<number>, material: Maybe<number>, labor: Maybe<number> }>, files: Maybe<Array<{ filename: string, uuid: string, directory: IFileDirectory }>> } };

export type ITeamProjectInfoFilesRemoveMutationVariables = Exact<{
  input: ITeamFilesRemoveInput;
}>;


export type ITeamProjectInfoFilesRemoveMutation = { teamProjectInfoFilesRemove: boolean };

export type ICancelTrialMutationVariables = Exact<{ [key: string]: never; }>;


export type ICancelTrialMutation = { cancelTrial: { id: string, accountType: Maybe<ITeamAccountType>, daysLeftInTrial: number } };

export type IConnectTrialToSubscriptionMutationVariables = Exact<{
  input: IConnectTrialToSubscriptionInput;
}>;


export type IConnectTrialToSubscriptionMutation = { connectTrialToSubscription: boolean };

export type IExtendTrialMutationVariables = Exact<{
  input: IExtendTrialInput;
}>;


export type IExtendTrialMutation = { extendTrial: { id: string, createdAt: any, teamID: string, daysLeftInTrial: number } };

export type ITeamAssignmentMutationVariables = Exact<{
  input: ITeamAssignmentInput;
}>;


export type ITeamAssignmentMutation = { teamAssignment: { id: string } };

export type ITeamCreditAssignmentMutationVariables = Exact<{
  input: ITeamCreditAssignmentInput;
}>;


export type ITeamCreditAssignmentMutation = { teamCreditAssignment: Maybe<{ id: string }> };

export type ITeamLeaderOnboardingAssignmentMutationVariables = Exact<{
  input: ITeamLeaderOnboardingAssignmentInput;
}>;


export type ITeamLeaderOnboardingAssignmentMutation = { teamLeaderOnboardingAssignment: { id: string, name: string, leadUser: Maybe<{ id: string, firstName: string, lastName: string, phone: string, companyRole: Maybe<IUserCompanyRole>, businessType: Maybe<IUserBusiness>, serviceType: Maybe<IUserService>, projectTypes: Maybe<string> }> } };

export type IUserToTeamConnectionMutationVariables = Exact<{
  input: IUserConnectInput;
}>;


export type IUserToTeamConnectionMutation = { userToTeamConnection: { id: string } };

export type IUserTradeRatingAssignmentMutationVariables = Exact<{
  input: IUserTradeRatingAssignmentInput;
}>;


export type IUserTradeRatingAssignmentMutation = { userTradeRatingAssignment: { id: string } };

export type IEstimatorProfileAssignmentMutationVariables = Exact<{
  userInput: IUserUpdateInput;
  estimatorInput: IScheduleAssignmentInput;
}>;


export type IEstimatorProfileAssignmentMutation = { scheduleAssignment: { id: string }, userUpdate: IUserLightFragment };

export type IProfileAssignmentMutationVariables = Exact<{
  input: IUserUpdateInput;
}>;


export type IProfileAssignmentMutation = { userUpdate: IUserLightFragment };

export type IUserClientPreferenceAssignmentMutationVariables = Exact<{
  input: IUserClientPreferenceAssignmentInput;
}>;


export type IUserClientPreferenceAssignmentMutation = { userClientPreferenceAssignment: { id: string } };

export type IUserCreateMutationVariables = Exact<{
  input: IUserCreateInput;
}>;


export type IUserCreateMutation = { userCreate: IUserFragment };

export type IAssembliesQueryVariables = Exact<{
  input: IAssembliesInput;
}>;


export type IAssembliesQuery = { assemblies: { edges: Maybe<Array<{ cursor: string, node: (
        { elements: (
          { edges: Maybe<Array<{ cursor: string, node: IElementFragment }>> }
          & IOldConnPageInfo_Elements_Fragment
        ) }
        & IAssemblyFragment
      ) }>>, pageInfo: IPageInfoFragment } };

export type IAssembliesLiteQueryVariables = Exact<{
  input: IAssembliesInput;
}>;


export type IAssembliesLiteQuery = { assemblies: { edges: Maybe<Array<{ cursor: string, node: IAssemblyFragment }>>, pageInfo: IPageInfoFragment } };

export type IEstimateAssembliesQueryVariables = Exact<{
  input: IAssembliesInput;
}>;


export type IEstimateAssembliesQuery = { assemblies: { edges: Maybe<Array<{ cursor: string, node: (
        { elements: { edges: Maybe<Array<{ node: IEstimateElementFragment }>> } }
        & IEstimateAssemblyFragment
      ) }>>, pageInfo: IPageInfoFragment } };

export type IAssemblyLibrariesQueryVariables = Exact<{
  input: IAssemblyLibrariesInput;
}>;


export type IAssemblyLibrariesQuery = { assemblyLibraries: (
    { edges: Maybe<Array<{ cursor: string, node: IAssemblyLibraryFragment }>> }
    & IOldConnPageInfo_AssemblyLibraries_Fragment
  ) };

export type IAssetQueryVariables = Exact<{
  input: IAssetInput;
}>;


export type IAssetQuery = { asset: IAssetFragment };

export type IAssetVariantsQueryVariables = Exact<{
  input: IAssetInput;
}>;


export type IAssetVariantsQuery = { assetVariants: IAssetVariantsFragment };

export type IAssetsFlatFileQueryVariables = Exact<{
  input: IAssetsFlatFileInput;
}>;


export type IAssetsFlatFileQuery = { assetsFlatFile: (
    { edges: Maybe<Array<{ cursor: string, node: IAssetFragment }>> }
    & IOldConnPageInfo_Assets_Fragment
  ) };

export type IAssetsGraphQueryVariables = Exact<{
  input: IAssetsInput;
}>;


export type IAssetsGraphQuery = { assetsGraph: IAssetsGraphFragment };

export type IAssetsSearchQueryVariables = Exact<{
  input: IAssetSearchInput;
}>;


export type IAssetsSearchQuery = { assetSearch: { edges: Maybe<Array<{ cursor: string, node: { id: string } }>> } };

export type ICostDataCategoriesQueryVariables = Exact<{
  input: ILegacyCategoriesInput;
}>;


export type ICostDataCategoriesQuery = { legacyCategories: Maybe<Array<Maybe<string>>> };

export type ICostDataSourcesPreviewQueryVariables = Exact<{
  input: ILegacySourcesInput;
}>;


export type ICostDataSourcesPreviewQuery = { legacySources: Maybe<{ pageInfo: { hasNextPage: boolean }, nodes: Maybe<Array<{ id: string, name: string, sourceType: ISourceTypeV2 }>> }> };

export type ICostDataSourcesGetByIdQueryVariables = Exact<{
  input: ISourceBatchInput;
}>;


export type ICostDataSourcesGetByIdQuery = { sourcesBatch: Maybe<Array<{ id: string, sourceType: ISourceTypeV2, name: string, uom: string, materialRateUsdCents: Maybe<number>, laborRateUsdCents: Maybe<number>, burdenedLaborRateUsdCents: Maybe<number>, laborSourceId: Maybe<string>, laborName: Maybe<string>, productionRate: Maybe<number>, calculatedUnitRateUsdCents: number, nestedSources: Maybe<Array<Maybe<{ id: string, sourceType: ISourceTypeV2, name: string, uom: string, materialRateUsdCents: Maybe<number>, laborRateUsdCents: Maybe<number>, burdenedLaborRateUsdCents: Maybe<number>, productionRate: Maybe<number>, calculatedUnitRateUsdCents: number, quantity: Maybe<number>, seqNum: Maybe<number> }>>> }>> };

export type ICountiesQueryVariables = Exact<{
  input: Maybe<ICountiesInput>;
}>;


export type ICountiesQuery = { counties: { edges: Maybe<Array<{ cursor: string, node: ICountyFragment }>> } };

export type IElementsQueryVariables = Exact<{
  input: IElementsInput;
}>;


export type IElementsQuery = { elements: Array<IElementFragment> };

export type ILibraryElementsQueryVariables = Exact<{
  input: ILibraryElementsInput;
}>;


export type ILibraryElementsQuery = { libraryElements: Array<IElementFragment> };

export type IPdfQueryVariables = Exact<{
  input: IFileExportInput;
}>;


export type IPdfQuery = { pdf: { blob: string } };

export type IXlsxQueryVariables = Exact<{
  input: IFileExportInput;
}>;


export type IXlsxQuery = { xlsx: { blob: string } };

export type IMarkupQueryVariables = Exact<{
  input: IMarkupInput;
}>;


export type IMarkupQuery = { markup: IMarkupFragment };

export type IMarkupGroupQueryVariables = Exact<{
  input: IMarkupGroupInput;
}>;


export type IMarkupGroupQuery = { markupGroup: IMarkupGroupFragment };

export type IMaterialSearchQueryVariables = Exact<{
  input: IMaterialSearchInput;
}>;


export type IMaterialSearchQuery = { materialSearch: { more: boolean, materials: Array<IMaterialLightFragment> } };

export type IMaterialsByIDsQueryVariables = Exact<{
  input: IMaterialsByIDsInput;
}>;


export type IMaterialsByIDsQuery = { materialsByIDs: Array<IMaterialFragment> };

export type ICustomerPortalLinkQueryVariables = Exact<{
  input: ICustomerPortalLinkInput;
}>;


export type ICustomerPortalLinkQuery = { customerPortalLink: string };

export type ISetupIntentQueryVariables = Exact<{ [key: string]: never; }>;


export type ISetupIntentQuery = { setupIntent: ISetupIntentFragment };

export type IProjectAreaByProjectIdQueryVariables = Exact<{
  input: IProjectAreaInput;
}>;


export type IProjectAreaByProjectIdQuery = { projectArea: Maybe<IProjectAreaFragment> };

export type IEstimateLinkQueryVariables = Exact<{
  input: IEstimateLinkInput;
}>;


export type IEstimateLinkQuery = { estimateLink: { link: Maybe<string> } };

export type IOldProjectsQueryVariables = Exact<{
  input: IProjectsInput;
}>;


export type IOldProjectsQuery = { projectsOld: (
    { edges: Maybe<Array<{ cursor: string, node: IProjectLightFragment }>> }
    & IOldConnPageInfo_ProjectsOld_Fragment
  ) };

export type IProjectLocationByProjectIdQueryVariables = Exact<{
  input: IProjectInput;
}>;


export type IProjectLocationByProjectIdQuery = { projectOld: { location: Maybe<IProjectLocationFragment> } };

export type IProjectWithMarkupEntriesQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type IProjectWithMarkupEntriesQuery = { project: IProjectWithMarkupEntriesFragment };

export type IProjectMarkupsByProjectIdQueryVariables = Exact<{
  input: IProjectInput;
}>;


export type IProjectMarkupsByProjectIdQuery = { projectOld: { markups: Maybe<{ edges: Maybe<Array<{ cursor: string, node: IProjectMarkupFragment }>> }> } };

export type IProjectsByTeamQueryVariables = Exact<{
  input: ITeamInput;
}>;


export type IProjectsByTeamQuery = { projectsByTeam: { edges: Maybe<Array<{ node: { id: string, name: Maybe<string>, isSaas: boolean } }>> } };

export type IReviewTagsQueryVariables = Exact<{
  input: Maybe<IReviewTagInput>;
}>;


export type IReviewTagsQuery = { reviewTags: { edges: Maybe<Array<{ cursor: string, node: IReviewTagFragment }>> } };

export type IViewAnalyticsQueryVariables = Exact<{
  input: IViewAnalyticsInput;
}>;


export type IViewAnalyticsQuery = { viewAnalytics: Maybe<{ views: number, lastViewed: Maybe<any> }> };

export type ISourceQueryVariables = Exact<{
  input: ISourceInput;
}>;


export type ISourceQuery = { source: Maybe<ISourceFragment> };

export type ISourceDescriptionQueryVariables = Exact<{
  input: Maybe<ISourceDescriptionInput>;
}>;


export type ISourceDescriptionQuery = { sourceDescription: Maybe<{ name: string, description: string }> };

export type ISourcesQueryVariables = Exact<{
  input: ISourcesInput;
}>;


export type ISourcesQuery = { sources: Maybe<{ edges: Maybe<Array<{ cursor: string, node: ISourceFragment }>>, pageInfo: IPageInfoFragment }> };

export type IStatesQueryVariables = Exact<{
  input: Maybe<IStatesConditon>;
}>;


export type IStatesQuery = { states: { edges: Maybe<Array<{ cursor: string, node: IStateFragment }>> } };

export type ITeamProjectInfoFilesQueryVariables = Exact<{
  input: ITeamFilesInput;
}>;


export type ITeamProjectInfoFilesQuery = { teamProjectInfoFiles: { edges: Maybe<Array<{ node: { id: string, filename: string, uuid: string, directory: IFileDirectory } }>> } };

export type ITeamBillingQueryVariables = Exact<{
  input: Maybe<ITeamInput>;
}>;


export type ITeamBillingQuery = { team: ITeamBillingFragment };

export type ITeamsPendingOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type ITeamsPendingOnboardingQuery = { teamsPendingOnboarding: { edges: Maybe<Array<{ cursor: string, node: { name: string, leadUser: Maybe<IUserLightFragment>, onboarding: Maybe<ITeamOnboardingFragment> } }>> } };

export type ITeamQueryVariables = Exact<{
  input: Maybe<ITeamInput>;
}>;


export type ITeamQuery = { team: ITeamFragment };

export type IEstimatorsForProjectQueryVariables = Exact<{
  input: IEstimatorsProjectInput;
}>;


export type IEstimatorsForProjectQuery = { estimatorsForProject: { edges: Maybe<Array<{ cursor: string, node: IEstimatorLightFragment }>> } };

export type IEstimatorsLiteQueryVariables = Exact<{
  input: Maybe<IUsersInput>;
}>;


export type IEstimatorsLiteQuery = { users: (
    { edges: Maybe<Array<{ cursor: string, node: IEstimatorLightFragment }>> }
    & IOldConnPageInfo_Users_Fragment
  ) };

export type IPreferredByClientQueryVariables = Exact<{
  input: Maybe<IPreferredByClientInput>;
}>;


export type IPreferredByClientQuery = { preferredByClient: Maybe<{ id: string, teamID: string, preference: IClientPreferenceStatus }> };

export type IUserQueryVariables = Exact<{
  input: IUserInput;
}>;


export type IUserQuery = { user: IUserFragment };

export type IUserTeamByIdQueryVariables = Exact<{
  input: IUserInput;
}>;


export type IUserTeamByIdQuery = { user: { team: Maybe<{ uuid: string, subscription: Maybe<IStripeSubscriptionFragment> }> } };

export type IUsersQueryVariables = Exact<{
  input: Maybe<IUsersInput>;
}>;


export type IUsersQuery = { users: (
    { edges: Maybe<Array<{ cursor: string, node: IUserFragment }>> }
    & IOldConnPageInfo_Users_Fragment
  ) };

export type IUsersLiteQueryVariables = Exact<{
  input: Maybe<IUsersInput>;
}>;


export type IUsersLiteQuery = { users: (
    { edges: Maybe<Array<{ cursor: string, node: IUserLightFragment }>> }
    & IOldConnPageInfo_Users_Fragment
  ) };

export type IUsersSearchQueryVariables = Exact<{
  input: IUsersSearchInput;
}>;


export type IUsersSearchQuery = { usersSearch: { edges: Maybe<Array<{ cursor: string, node: IUserLightFragment }>> } };

export type IUsersSimpleQueryVariables = Exact<{
  input: IUsersInputSimple;
}>;


export type IUsersSimpleQuery = { usersSimple: { more: boolean, users: Array<IUserLightFragment> } };

export type IAssemblyReceivedSubscriptionVariables = Exact<{
  input: IAssemblyReceivedInput;
}>;


export type IAssemblyReceivedSubscription = { assemblyReceived: { actionType: IAction, assembly: { id: string, description: string, favorited: boolean, usedInProject: boolean, projectID: Maybe<string>, unit: { id: string, unitType: IUnitType, name: string }, elements: { edges: Maybe<Array<{ node: { id: string, uuid: string, name: string, previousElement: Maybe<string>, pendingLocalizedCostData: boolean, materialRate: number, laborRate: number, localizedLaborRate: number, productionRate: number, equipmentRate: number, materialID: Maybe<string>, formulaID: string, material: Maybe<{ id: string, name: string, ownerID: Maybe<string>, takeoffUnitID: string, createdAt: any, updatedAt: any }>, formula: { id: string, uuid: string, expression: string }, unit: { id: string, name: string, description: string, definition: string, unitType: IUnitType, createdAt: any, updatedAt: any }, variables: { edges: Maybe<Array<{ cursor: string, node: { id: string, uuid: string, formulaID: string, measuredQuantityID: string, name: string, type: string, createdAt: any, updatedAt: any, measuredQuantity: { id: string, value: Maybe<number>, unitID: string, unit: { id: string, name: string, description: string, definition: string, unitType: IUnitType, createdAt: any, updatedAt: any } } } }>> } } }>> }, trade: Maybe<{ id: string, name: string, nickname: string, isLinked: boolean }> } } };

export type IAssetExportsRecievedSubscriptionVariables = Exact<{
  input: IAssetExportRecievedInput;
}>;


export type IAssetExportsRecievedSubscription = { assetExportRecieved: { id: string, url: string } };

export type IAssetRecievedSubscriptionVariables = Exact<{
  input: IAssetRecievedInput;
}>;


export type IAssetRecievedSubscription = { assetReceived: IAssetFragment };

export const TradeFragmentDoc = gql`
    fragment trade on Trade {
  id
  isLinked
  name
  nickname
  orderWeight
}
    `;
export const UnitFragmentDoc = gql`
    fragment unit on Unit {
  id
  definition
  description
  name
  unitType
}
    `;
export const ProjectPriceFragmentDoc = gql`
    fragment projectPrice on ProjectPrice {
  id
  assignmentID
  pricingType
  priceUSDCents
}
    `;
export const ProjectLightFragmentDoc = gql`
    fragment projectLight on Project {
  id
  uuid
  bidsDueDate
  isSaas
  name
  status
  prices {
    edges {
      node {
        ...projectPrice
      }
    }
  }
}
    ${ProjectPriceFragmentDoc}`;
export const AssemblyFragmentDoc = gql`
    fragment assembly on Assembly {
  id
  uuid
  description
  favorited
  projectID
  usedInProject
  assemblyType
  childOrder
  trade {
    ...trade
  }
  unit {
    ...unit
  }
  project {
    ...projectLight
  }
}
    ${TradeFragmentDoc}
${UnitFragmentDoc}
${ProjectLightFragmentDoc}`;
export const EstimateAssemblyFragmentDoc = gql`
    fragment estimateAssembly on Assembly {
  id
  uuid
  description
  favorited
  usedInProject
  assemblyType
  childOrder
  trade {
    ...trade
  }
}
    ${TradeFragmentDoc}`;
export const AssemblyLibraryFragmentDoc = gql`
    fragment assemblyLibrary on AssemblyLibrary {
  id
  is1b
  name
}
    `;
export const AssetDimensionFragmentDoc = gql`
    fragment assetDimension on AssetDimension {
  orientation
  right
  top
}
    `;
export const AssetVariantsFragmentDoc = gql`
    fragment assetVariants on AssetVariants {
  ORIGINAL
  THUMBNAIL_S_R0
  THUMBNAIL_S_R90
  THUMBNAIL_S_R180
  THUMBNAIL_S_R270
  THUMBNAIL_L_R0
  THUMBNAIL_L_R90
  THUMBNAIL_L_R180
  THUMBNAIL_L_R270
  THUMBNAIL_XL_R0
  THUMBNAIL_XL_R90
  THUMBNAIL_XL_R180
  THUMBNAIL_XL_R270
  R0
  R90
  R180
  R270
}
    `;
export const AssetFragmentDoc = gql`
    fragment asset on Asset {
  id
  assetID
  assetType
  extension
  ingested
  name
  path
  parentID
  position
  projectID
  projectPlanPageID
  processed
  dimension {
    ...assetDimension
  }
  variants {
    ...assetVariants
  }
  createdAt
  updatedAt
}
    ${AssetDimensionFragmentDoc}
${AssetVariantsFragmentDoc}`;
export const AssetsGraphFragmentDoc = gql`
    fragment assetsGraph on AssetsGraph {
  id
  graph
}
    `;
export const CategoryFragmentDoc = gql`
    fragment category on Category {
  id
  csiDivision
  csiSection
  csiTitle
  occCode
  occTitle
  materials {
    edges {
      node {
        id
      }
    }
  }
  createdAt
  updatedAt
}
    `;
export const ContractFragmentDoc = gql`
    fragment contract on Contract {
  id
  cancellationGracePeriod
  commitmentLength
  creditVolumeUpgrade
  pandaDocID
  subscriptionDiscount
  subscriptionFreeCredits
  subscriptionMonthlyFee
  teamOnboardingID
  trialOfferExpirationDate
}
    `;
export const SourcePreviewFragmentDoc = gql`
    fragment sourcePreview on SourceV2 {
  id
  name
  sourceType
}
    `;
export const SourceV2FragmentDoc = gql`
    fragment sourceV2 on SourceV2 {
  id
  sourceType
  name
  uom
  materialRateUsdCents
  laborRateUsdCents
  burdenedLaborRateUsdCents
  laborSourceId
  laborName
  productionRate
  calculatedUnitRateUsdCents
  nestedSources {
    id
    sourceType
    name
    uom
    materialRateUsdCents
    laborRateUsdCents
    burdenedLaborRateUsdCents
    laborSourceId
    laborName
    productionRate
    calculatedUnitRateUsdCents
    quantity
    seqNum
  }
}
    `;
export const CountyFragmentDoc = gql`
    fragment county on County {
  id
  statefp
  countyfp
  countyns
  geoid
  name
  namelsad
  lsad
  classfp
  mtfcc
  csafp
  cbsafp
  metdivfp
  funcstat
  aland
  awater
  intptlat
  intptlon
  createdAt
  updatedAt
}
    `;
export const ExpressionFragmentDoc = gql`
    fragment expression on Expression {
  result
  tokens
}
    `;
export const MaterialFragmentDoc = gql`
    fragment material on Material {
  id
  name
  categoryID
  ownerID
  takeoffUnitID
  createdAt
  updatedAt
}
    `;
export const ElementLightFragmentDoc = gql`
    fragment elementLight on Element {
  id
  uuid
  equipmentRate
  name
  laborRate
  localizedLaborRate
  materialRate
  pendingLocalizedCostData
  previousElement
  productionRate
  fromTakeoffValue
  expression {
    ...expression
  }
  ownerID
  materialID
  material {
    ...material
  }
  formulaID
  unit {
    ...unit
  }
  favorited
}
    ${ExpressionFragmentDoc}
${MaterialFragmentDoc}
${UnitFragmentDoc}`;
export const EstimateElementFragmentDoc = gql`
    fragment estimateElement on Element {
  id
  uuid
  equipmentRate
  name
  laborRate
  materialRate
  previousElement
  productionRate
  assembly {
    uuid
    childOrder
  }
  expression {
    ...expression
  }
  unit {
    ...unit
  }
  favorited
}
    ${ExpressionFragmentDoc}
${UnitFragmentDoc}`;
export const ProjectAreaFragmentDoc = gql`
    fragment projectArea on ProjectArea {
  id
  projectID
  squareFeetUnderRoof
  squareFeetTotalLivable
}
    `;
export const ProjectLocationFragmentDoc = gql`
    fragment projectLocation on ProjectLocation {
  id
  city
  coordinates
  formattedAddress
  projectID
  state
  streetAddress
  zip
  county
}
    `;
export const ProjectMarkupFragmentDoc = gql`
    fragment projectMarkup on ProjectMarkup {
  id
  description
  percentage
  projectID
  total
}
    `;
export const ProjectFragmentDoc = gql`
    fragment project on Project {
  id
  uuid
  additionalInfo
  bidsDueDate
  contingency
  childOrder
  equipmentPriceMarkup
  estimateDueDate
  estimatorCostUsdCents
  estimatorHours
  feedback
  isApprovalRequired
  isSaas
  isSetupCompleted
  laborPriceMarkup
  materialPriceMarkup
  name
  overheadAndProfit
  status
  teamID
  type
  wageType
  area {
    ...projectArea
  }
  location {
    ...projectLocation
  }
  markups {
    edges {
      cursor
      node {
        ...projectMarkup
      }
    }
  }
  prices {
    edges {
      node {
        ...projectPrice
      }
    }
  }
  projectScope {
    id
    projectID
    bidsDueDate
    status
    estimateDueDate
    pricing {
      id
      active
      status
      usdCents
      lowEndUsdCents
      highEndUsdCents
    }
    estimatorHours
    estimatedEstimatorHours
    additionalInfo
  }
}
    ${ProjectAreaFragmentDoc}
${ProjectLocationFragmentDoc}
${ProjectMarkupFragmentDoc}
${ProjectPriceFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment event on Event {
  id
  message
  type
  seen
  createdAt
  updatedAt
  projectID
  project {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export const MaterialLightFragmentDoc = gql`
    fragment materialLight on Material {
  id
  name
  categoryID
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  totalCount
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const ConnPageInfoFragmentDoc = gql`
    fragment connPageInfo on Connection {
  pageInfo {
    ...pageInfo
  }
}
    ${PageInfoFragmentDoc}`;
export const OldPageInfoFragmentDoc = gql`
    fragment oldPageInfo on OldPageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const OldConnPageInfoFragmentDoc = gql`
    fragment oldConnPageInfo on OldConnection {
  pageInfo {
    ...oldPageInfo
  }
}
    ${OldPageInfoFragmentDoc}`;
export const ProjectPlanFileFragmentDoc = gql`
    fragment projectPlanFile on ProjectPlanFile {
  id
  uuid
  filename
  projectID
}
    `;
export const CoordinateFragmentDoc = gql`
    fragment coordinate on Coordinate {
  x
  y
}
    `;
export const MarkupPointFragmentDoc = gql`
    fragment markupPoint on Coordinate {
  __typename
  ...coordinate
}
    ${CoordinateFragmentDoc}`;
export const MarkupLineFragmentDoc = gql`
    fragment markupLine on MarkupLine {
  __typename
  points {
    ...markupPoint
  }
}
    ${MarkupPointFragmentDoc}`;
export const MarkupPolygonFragmentDoc = gql`
    fragment markupPolygon on MarkupPolygon {
  __typename
  rings {
    ...markupLine
  }
}
    ${MarkupLineFragmentDoc}`;
export const MarkupGeometryFragmentDoc = gql`
    fragment markupGeometry on MarkupGeometry {
  ... on Coordinate {
    ...markupPoint
  }
  ... on MarkupLine {
    ...markupLine
  }
  ... on MarkupPolygon {
    ...markupPolygon
  }
}
    ${MarkupPointFragmentDoc}
${MarkupLineFragmentDoc}
${MarkupPolygonFragmentDoc}`;
export const MarkupFragmentDoc = gql`
    fragment markup on Markup {
  __typename
  id
  name
  color
  geometries {
    ...markupGeometry
  }
  markupType
  markupGroupID
  measurement
  projectPlanPageID
  isSelected @client
}
    ${MarkupGeometryFragmentDoc}`;
export const MarkupGroupFragmentDoc = gql`
    fragment markupGroup on MarkupGroup {
  __typename
  id
  name
  markups {
    ...markup
  }
  markupGroupType
  measurement
}
    ${MarkupFragmentDoc}`;
export const ProjectWithMarkupEntriesFragmentDoc = gql`
    fragment projectWithMarkupEntries on Project {
  id
  markupEntries {
    __typename
    ... on Markup {
      ...markup
    }
    ... on MarkupGroup {
      ...markupGroup
    }
  }
  uuid
}
    ${MarkupFragmentDoc}
${MarkupGroupFragmentDoc}`;
export const ReviewTagFragmentDoc = gql`
    fragment reviewTag on ReviewTag {
  id
  tag
  isPositive
}
    `;
export const SetupIntentFragmentDoc = gql`
    fragment setupIntent on SetupIntent {
  clientSecret
}
    `;
export const AssemblyLightFragmentDoc = gql`
    fragment assemblyLight on Assembly {
  id
  description
  projectID
  usedInProject
  unit {
    ...unit
  }
}
    ${UnitFragmentDoc}`;
export const FormulaFragmentDoc = gql`
    fragment formula on Formula {
  id
  uuid
  expression
  takeoffUnit {
    ...unit
  }
}
    ${UnitFragmentDoc}`;
export const MeasuredQuantityFragmentDoc = gql`
    fragment measuredQuantity on MeasuredQuantity {
  id
  value
  unitID
  unit {
    ...unit
  }
}
    ${UnitFragmentDoc}`;
export const VariableFragmentDoc = gql`
    fragment variable on Variable {
  id
  uuid
  formulaID
  name
  type
  measuredQuantityID
  measuredQuantity {
    ...measuredQuantity
  }
}
    ${MeasuredQuantityFragmentDoc}`;
export const ElementFragmentDoc = gql`
    fragment element on Element {
  id
  uuid
  equipmentRate
  name
  laborRate
  localizedLaborRate
  materialRate
  pendingLocalizedCostData
  previousElement
  productionRate
  fromTakeoffValue
  assembly {
    uuid
    childOrder
  }
  expression {
    ...expression
  }
  ownerID
  materialID
  material {
    ...material
  }
  formulaID
  formula {
    ...formula
  }
  unit {
    ...unit
  }
  variables {
    edges {
      cursor
      node {
        ...variable
      }
    }
  }
  favorited
}
    ${ExpressionFragmentDoc}
${MaterialFragmentDoc}
${FormulaFragmentDoc}
${UnitFragmentDoc}
${VariableFragmentDoc}`;
export const RateCollectionFragmentDoc = gql`
    fragment rateCollection on RateCollection {
  assignedRateID
  assignedRate {
    priceUSDCents
  }
}
    `;
export const SourceFragmentDoc = gql`
    fragment source on Source {
  id
  assemblyID
  sourceType
  assembly {
    ...assemblyLight
  }
  laborID
  labor {
    id
    categoryID
    description
  }
  materialID
  material {
    ...material
  }
  elementID
  element {
    ...element
  }
  locationID
  createdAt
  updatedAt
  materialRateCollection {
    ...rateCollection
  }
  laborRateCollection {
    ...rateCollection
  }
  productionRateCollection {
    ...rateCollection
  }
  sources {
    edges {
      node {
        id
        assemblyID
        sourceType
        assembly {
          ...assemblyLight
        }
        laborID
        labor {
          id
          categoryID
          description
        }
        materialID
        material {
          ...material
        }
        elementID
        element {
          ...element
        }
        locationID
        createdAt
        updatedAt
        materialRateCollection {
          ...rateCollection
        }
        laborRateCollection {
          ...rateCollection
        }
        productionRateCollection {
          ...rateCollection
        }
      }
    }
  }
}
    ${AssemblyLightFragmentDoc}
${MaterialFragmentDoc}
${ElementFragmentDoc}
${RateCollectionFragmentDoc}`;
export const StateFragmentDoc = gql`
    fragment state on State {
  id
  region
  division
  statefp
  statens
  geoid
  stusps
  name
  lsad
  mtfcc
  funcstat
  aland
  awater
  intptlat
  intptlon
  createdAt
  updatedAt
}
    `;
export const BalanceFragmentDoc = gql`
    fragment balance on Balance {
  id
  teamID
  usdCents
}
    `;
export const StripeSubscriptionFragmentDoc = gql`
    fragment stripeSubscription on StripeSubscription {
  id
  amount
  currentPeriodEnd
  currentPeriodStart
  priceID
  status
  wasTrial
  createdAt
}
    `;
export const TeamBillingFragmentDoc = gql`
    fragment teamBilling on Team {
  balance {
    ...balance
  }
  creditHistory {
    edges {
      node {
        projectID
        amount
      }
    }
  }
  projects {
    edges {
      cursor
      node {
        ...projectLight
      }
    }
  }
  subscription {
    ...stripeSubscription
  }
}
    ${BalanceFragmentDoc}
${ProjectLightFragmentDoc}
${StripeSubscriptionFragmentDoc}`;
export const TeamLightFragmentDoc = gql`
    fragment teamLight on Team {
  id
  uuid
  name
}
    `;
export const ClientPreferenceFragmentDoc = gql`
    fragment clientPreference on ClientPreference {
  id
  preference
  teamID
  team {
    ...teamLight
  }
}
    ${TeamLightFragmentDoc}`;
export const CommitmentFragmentDoc = gql`
    fragment commitment on Commitment {
  id
  from
  status
  to
}
    `;
export const ScheduleFragmentDoc = gql`
    fragment schedule on Schedule {
  id
  availability
  paused
  unavailable {
    ...commitment
  }
}
    ${CommitmentFragmentDoc}`;
export const TeamCreditHistoryFragmentDoc = gql`
    fragment teamCreditHistory on TeamCreditHistory {
  id
  amount
  stripeChargeID
  teamID
  projectID
  project {
    ...projectLight
  }
}
    ${ProjectLightFragmentDoc}`;
export const TradeRatingFragmentDoc = gql`
    fragment tradeRating on TradeRating {
  id
  rating
  tradeID
  trade {
    ...trade
  }
}
    ${TradeFragmentDoc}`;
export const UserLightFragmentDoc = gql`
    fragment userLight on User {
  id
  authID
  firstName
  lastName
  phone
  teamID
  status
  timezone
  preferredClients {
    ...clientPreference
  }
  team {
    ...teamLight
  }
  schedule {
    ...schedule
  }
  tradeRatings {
    ...tradeRating
  }
  createdAt
  address
  state
  city
  postalCode
  country
  logoLink
}
    ${ClientPreferenceFragmentDoc}
${TeamLightFragmentDoc}
${ScheduleFragmentDoc}
${TradeRatingFragmentDoc}`;
export const TeamOnboardingFragmentDoc = gql`
    fragment teamOnboarding on TeamOnboarding {
  id
  accountType
  daysLeftInTrial
  teamID
  stripeCustomerID
  welcomed
  createdAt
}
    `;
export const TeamProjectInfoFragmentDoc = gql`
    fragment teamProjectInfo on TeamProjectInfo {
  id
  estimateTypes
  formattingPreferences
  inclusionsExclusions
  preferredMarkups {
    overhead
    profit
    material
    labor
  }
  averageProjectPrice {
    pricePerSquareFoot
    priceTotal
  }
  requestsAndNotes
  hourlyLaborRates {
    rateUSDCents
    type
  }
}
    `;
export const TeamFragmentDoc = gql`
    fragment team on Team {
  id
  uuid
  name
  balance {
    ...balance
  }
  creditHistory {
    edges {
      cursor
      node {
        ...teamCreditHistory
      }
    }
  }
  leadUserID
  leadUser {
    ...userLight
  }
  onboarding {
    ...teamOnboarding
  }
  subscription {
    ...stripeSubscription
  }
  hasPaymentMethod
  teamProjectInfo {
    ...teamProjectInfo
  }
}
    ${BalanceFragmentDoc}
${TeamCreditHistoryFragmentDoc}
${UserLightFragmentDoc}
${TeamOnboardingFragmentDoc}
${StripeSubscriptionFragmentDoc}
${TeamProjectInfoFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  authID
  email
  firstName
  lastName
  phone
  roles
  status
  timezone
  roles
  preferredClients {
    ...clientPreference
  }
  scheduleID
  schedule {
    ...schedule
  }
  teamID
  team {
    ...team
  }
  tradeRatings {
    ...tradeRating
  }
  bidValue
  createdAt
  companyRole
  businessType
  serviceType
  address
  state
  city
  postalCode
  country
  logoLink
}
    ${ClientPreferenceFragmentDoc}
${ScheduleFragmentDoc}
${TeamFragmentDoc}
${TradeRatingFragmentDoc}`;
export const EstimatorLightFragmentDoc = gql`
    fragment estimatorLight on User {
  id
  authID
  firstName
  lastName
  teamID
  status
  preferredByBuilder
}
    `;
export const AssembliesDeletionDocument = gql`
    mutation AssembliesDeletion($input: AssemblyDeletionInput!) {
  assembliesDeletion(input: $input)
}
    `;
export function useAssembliesDeletionMutation(baseOptions?: Apollo.MutationHookOptions<IAssembliesDeletionMutation, IAssembliesDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssembliesDeletionMutation, IAssembliesDeletionMutationVariables>(AssembliesDeletionDocument, options);
      }
export type AssembliesDeletionMutationHookResult = ReturnType<typeof useAssembliesDeletionMutation>;
export type AssembliesDeletionMutationResult = Apollo.MutationResult<IAssembliesDeletionMutation>;
export type AssembliesDeletionMutationOptions = Apollo.BaseMutationOptions<IAssembliesDeletionMutation, IAssembliesDeletionMutationVariables>;
export const AssembliesDeletionForV2OrderingDocument = gql`
    mutation AssembliesDeletionForV2Ordering($input: AssembliesDeletionForV2OrderingInput!) {
  assembliesDeletionForV2Ordering(input: $input) {
    uuid
    childOrder
  }
}
    `;
export function useAssembliesDeletionForV2OrderingMutation(baseOptions?: Apollo.MutationHookOptions<IAssembliesDeletionForV2OrderingMutation, IAssembliesDeletionForV2OrderingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssembliesDeletionForV2OrderingMutation, IAssembliesDeletionForV2OrderingMutationVariables>(AssembliesDeletionForV2OrderingDocument, options);
      }
export type AssembliesDeletionForV2OrderingMutationHookResult = ReturnType<typeof useAssembliesDeletionForV2OrderingMutation>;
export type AssembliesDeletionForV2OrderingMutationResult = Apollo.MutationResult<IAssembliesDeletionForV2OrderingMutation>;
export type AssembliesDeletionForV2OrderingMutationOptions = Apollo.BaseMutationOptions<IAssembliesDeletionForV2OrderingMutation, IAssembliesDeletionForV2OrderingMutationVariables>;
export const AssembliesDuplicationDocument = gql`
    mutation AssembliesDuplication($input: AssembliesCopyInput!) {
  assembliesDuplication(input: $input) {
    edges {
      cursor
      node {
        ...assembly
        elements {
          edges {
            cursor
            node {
              ...element
            }
          }
        }
      }
    }
  }
}
    ${AssemblyFragmentDoc}
${ElementFragmentDoc}`;
export function useAssembliesDuplicationMutation(baseOptions?: Apollo.MutationHookOptions<IAssembliesDuplicationMutation, IAssembliesDuplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssembliesDuplicationMutation, IAssembliesDuplicationMutationVariables>(AssembliesDuplicationDocument, options);
      }
export type AssembliesDuplicationMutationHookResult = ReturnType<typeof useAssembliesDuplicationMutation>;
export type AssembliesDuplicationMutationResult = Apollo.MutationResult<IAssembliesDuplicationMutation>;
export type AssembliesDuplicationMutationOptions = Apollo.BaseMutationOptions<IAssembliesDuplicationMutation, IAssembliesDuplicationMutationVariables>;
export const AssemblyAssignmentDocument = gql`
    mutation AssemblyAssignment($input: AssemblyAssignmentInput!) {
  assemblyAssignment(input: $input) {
    ...assembly
    project {
      uuid
      childOrder
    }
    elements {
      edges {
        cursor
        node {
          ...element
        }
      }
    }
  }
}
    ${AssemblyFragmentDoc}
${ElementFragmentDoc}`;
export function useAssemblyAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IAssemblyAssignmentMutation, IAssemblyAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssemblyAssignmentMutation, IAssemblyAssignmentMutationVariables>(AssemblyAssignmentDocument, options);
      }
export type AssemblyAssignmentMutationHookResult = ReturnType<typeof useAssemblyAssignmentMutation>;
export type AssemblyAssignmentMutationResult = Apollo.MutationResult<IAssemblyAssignmentMutation>;
export type AssemblyAssignmentMutationOptions = Apollo.BaseMutationOptions<IAssemblyAssignmentMutation, IAssemblyAssignmentMutationVariables>;
export const AssemblyReorderDocument = gql`
    mutation AssemblyReorder($input: AssemblyReorderInput!) {
  assemblyReorder(input: $input) {
    ...assembly
  }
}
    ${AssemblyFragmentDoc}`;
export function useAssemblyReorderMutation(baseOptions?: Apollo.MutationHookOptions<IAssemblyReorderMutation, IAssemblyReorderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssemblyReorderMutation, IAssemblyReorderMutationVariables>(AssemblyReorderDocument, options);
      }
export type AssemblyReorderMutationHookResult = ReturnType<typeof useAssemblyReorderMutation>;
export type AssemblyReorderMutationResult = Apollo.MutationResult<IAssemblyReorderMutation>;
export type AssemblyReorderMutationOptions = Apollo.BaseMutationOptions<IAssemblyReorderMutation, IAssemblyReorderMutationVariables>;
export const AssemblyReorderBeforeUuidDocument = gql`
    mutation AssemblyReorderBeforeUUID($input: AssemblyReorderBeforeUUIDInput!) {
  assemblyReorderBeforeUUID(input: $input) {
    uuid
    childOrder
  }
}
    `;
export function useAssemblyReorderBeforeUuidMutation(baseOptions?: Apollo.MutationHookOptions<IAssemblyReorderBeforeUuidMutation, IAssemblyReorderBeforeUuidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssemblyReorderBeforeUuidMutation, IAssemblyReorderBeforeUuidMutationVariables>(AssemblyReorderBeforeUuidDocument, options);
      }
export type AssemblyReorderBeforeUuidMutationHookResult = ReturnType<typeof useAssemblyReorderBeforeUuidMutation>;
export type AssemblyReorderBeforeUuidMutationResult = Apollo.MutationResult<IAssemblyReorderBeforeUuidMutation>;
export type AssemblyReorderBeforeUuidMutationOptions = Apollo.BaseMutationOptions<IAssemblyReorderBeforeUuidMutation, IAssemblyReorderBeforeUuidMutationVariables>;
export const AssemblyLibraryAssignmentDocument = gql`
    mutation AssemblyLibraryAssignment($input: AssemblyLibraryAssignmentInput!) {
  assemblyLibraryAssignment(input: $input) {
    edges {
      cursor
      node {
        ...assembly
      }
    }
  }
}
    ${AssemblyFragmentDoc}`;
export function useAssemblyLibraryAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IAssemblyLibraryAssignmentMutation, IAssemblyLibraryAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssemblyLibraryAssignmentMutation, IAssemblyLibraryAssignmentMutationVariables>(AssemblyLibraryAssignmentDocument, options);
      }
export type AssemblyLibraryAssignmentMutationHookResult = ReturnType<typeof useAssemblyLibraryAssignmentMutation>;
export type AssemblyLibraryAssignmentMutationResult = Apollo.MutationResult<IAssemblyLibraryAssignmentMutation>;
export type AssemblyLibraryAssignmentMutationOptions = Apollo.BaseMutationOptions<IAssemblyLibraryAssignmentMutation, IAssemblyLibraryAssignmentMutationVariables>;
export const AssetAssignmentDocument = gql`
    mutation AssetAssignment($input: AssetAssignmentsInput!) {
  assetsAssignment(input: $input) {
    edges {
      cursor
    }
  }
}
    `;
export function useAssetAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IAssetAssignmentMutation, IAssetAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetAssignmentMutation, IAssetAssignmentMutationVariables>(AssetAssignmentDocument, options);
      }
export type AssetAssignmentMutationHookResult = ReturnType<typeof useAssetAssignmentMutation>;
export type AssetAssignmentMutationResult = Apollo.MutationResult<IAssetAssignmentMutation>;
export type AssetAssignmentMutationOptions = Apollo.BaseMutationOptions<IAssetAssignmentMutation, IAssetAssignmentMutationVariables>;
export const AssetsDuplicationDocument = gql`
    mutation AssetsDuplication($input: AssetsPositioningInput!) {
  assetsDuplication(input: $input) {
    edges {
      cursor
    }
  }
}
    `;
export function useAssetsDuplicationMutation(baseOptions?: Apollo.MutationHookOptions<IAssetsDuplicationMutation, IAssetsDuplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetsDuplicationMutation, IAssetsDuplicationMutationVariables>(AssetsDuplicationDocument, options);
      }
export type AssetsDuplicationMutationHookResult = ReturnType<typeof useAssetsDuplicationMutation>;
export type AssetsDuplicationMutationResult = Apollo.MutationResult<IAssetsDuplicationMutation>;
export type AssetsDuplicationMutationOptions = Apollo.BaseMutationOptions<IAssetsDuplicationMutation, IAssetsDuplicationMutationVariables>;
export const AssetsGroupingDocument = gql`
    mutation AssetsGrouping($input: AssetsGroupingInput!) {
  assetsGrouping(input: $input) {
    edges {
      cursor
    }
  }
}
    `;
export function useAssetsGroupingMutation(baseOptions?: Apollo.MutationHookOptions<IAssetsGroupingMutation, IAssetsGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetsGroupingMutation, IAssetsGroupingMutationVariables>(AssetsGroupingDocument, options);
      }
export type AssetsGroupingMutationHookResult = ReturnType<typeof useAssetsGroupingMutation>;
export type AssetsGroupingMutationResult = Apollo.MutationResult<IAssetsGroupingMutation>;
export type AssetsGroupingMutationOptions = Apollo.BaseMutationOptions<IAssetsGroupingMutation, IAssetsGroupingMutationVariables>;
export const AssetsDeletionDocument = gql`
    mutation AssetsDeletion($input: AssetsDeletionInput!) {
  assetsDeletion(input: $input) {
    edges {
      cursor
    }
  }
}
    `;
export function useAssetsDeletionMutation(baseOptions?: Apollo.MutationHookOptions<IAssetsDeletionMutation, IAssetsDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetsDeletionMutation, IAssetsDeletionMutationVariables>(AssetsDeletionDocument, options);
      }
export type AssetsDeletionMutationHookResult = ReturnType<typeof useAssetsDeletionMutation>;
export type AssetsDeletionMutationResult = Apollo.MutationResult<IAssetsDeletionMutation>;
export type AssetsDeletionMutationOptions = Apollo.BaseMutationOptions<IAssetsDeletionMutation, IAssetsDeletionMutationVariables>;
export const AssetsExportDocument = gql`
    mutation AssetsExport($input: AssetExportInput!) {
  assetsExport(input: $input)
}
    `;
export function useAssetsExportMutation(baseOptions?: Apollo.MutationHookOptions<IAssetsExportMutation, IAssetsExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetsExportMutation, IAssetsExportMutationVariables>(AssetsExportDocument, options);
      }
export type AssetsExportMutationHookResult = ReturnType<typeof useAssetsExportMutation>;
export type AssetsExportMutationResult = Apollo.MutationResult<IAssetsExportMutation>;
export type AssetsExportMutationOptions = Apollo.BaseMutationOptions<IAssetsExportMutation, IAssetsExportMutationVariables>;
export const AssetsRotationDocument = gql`
    mutation AssetsRotation($input: AssetsOrientationInput!) {
  assetsRotation(input: $input) {
    edges {
      cursor
    }
  }
}
    `;
export function useAssetsRotationMutation(baseOptions?: Apollo.MutationHookOptions<IAssetsRotationMutation, IAssetsRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssetsRotationMutation, IAssetsRotationMutationVariables>(AssetsRotationDocument, options);
      }
export type AssetsRotationMutationHookResult = ReturnType<typeof useAssetsRotationMutation>;
export type AssetsRotationMutationResult = Apollo.MutationResult<IAssetsRotationMutation>;
export type AssetsRotationMutationOptions = Apollo.BaseMutationOptions<IAssetsRotationMutation, IAssetsRotationMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input)
}
    `;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<IForgotPasswordMutation, IForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IForgotPasswordMutation, IForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<IForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<IForgotPasswordMutation, IForgotPasswordMutationVariables>;
export const ImpersonateDocument = gql`
    mutation Impersonate($input: PersonationInput!) {
  impersonate(input: $input) {
    accessToken
    refreshToken
    impersonatedAuthID
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export function useImpersonateMutation(baseOptions?: Apollo.MutationHookOptions<IImpersonateMutation, IImpersonateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IImpersonateMutation, IImpersonateMutationVariables>(ImpersonateDocument, options);
      }
export type ImpersonateMutationHookResult = ReturnType<typeof useImpersonateMutation>;
export type ImpersonateMutationResult = Apollo.MutationResult<IImpersonateMutation>;
export type ImpersonateMutationOptions = Apollo.BaseMutationOptions<IImpersonateMutation, IImpersonateMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    accessToken
    refreshToken
    impersonatedAuthID
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    accessToken
    refreshToken
    impersonatedAuthID
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<ISignupMutation, ISignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISignupMutation, ISignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<ISignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<ISignupMutation, ISignupMutationVariables>;
export const ElementAssignmentDocument = gql`
    mutation ElementAssignment($input: ElementAssignmentInput!) {
  elementAssignment(input: $input) {
    ...element
    assembly {
      uuid
      childOrder
    }
  }
}
    ${ElementFragmentDoc}`;
export function useElementAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IElementAssignmentMutation, IElementAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementAssignmentMutation, IElementAssignmentMutationVariables>(ElementAssignmentDocument, options);
      }
export type ElementAssignmentMutationHookResult = ReturnType<typeof useElementAssignmentMutation>;
export type ElementAssignmentMutationResult = Apollo.MutationResult<IElementAssignmentMutation>;
export type ElementAssignmentMutationOptions = Apollo.BaseMutationOptions<IElementAssignmentMutation, IElementAssignmentMutationVariables>;
export const ElementGroupingDocument = gql`
    mutation ElementGrouping($input: ElementGroupingInput!) {
  elementGrouping(input: $input) {
    ...element
  }
}
    ${ElementFragmentDoc}`;
export function useElementGroupingMutation(baseOptions?: Apollo.MutationHookOptions<IElementGroupingMutation, IElementGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementGroupingMutation, IElementGroupingMutationVariables>(ElementGroupingDocument, options);
      }
export type ElementGroupingMutationHookResult = ReturnType<typeof useElementGroupingMutation>;
export type ElementGroupingMutationResult = Apollo.MutationResult<IElementGroupingMutation>;
export type ElementGroupingMutationOptions = Apollo.BaseMutationOptions<IElementGroupingMutation, IElementGroupingMutationVariables>;
export const ElementReorderDocument = gql`
    mutation ElementReorder($input: ElementReorderInput!) {
  elementReorder(input: $input) {
    ...element
  }
}
    ${ElementFragmentDoc}`;
export function useElementReorderMutation(baseOptions?: Apollo.MutationHookOptions<IElementReorderMutation, IElementReorderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementReorderMutation, IElementReorderMutationVariables>(ElementReorderDocument, options);
      }
export type ElementReorderMutationHookResult = ReturnType<typeof useElementReorderMutation>;
export type ElementReorderMutationResult = Apollo.MutationResult<IElementReorderMutation>;
export type ElementReorderMutationOptions = Apollo.BaseMutationOptions<IElementReorderMutation, IElementReorderMutationVariables>;
export const ElementReorderBeforeUuidDocument = gql`
    mutation ElementReorderBeforeUUID($input: ElementReorderBeforeUUIDInput!) {
  elementReorderBeforeUUID(input: $input) {
    uuid
    childOrder
  }
}
    `;
export function useElementReorderBeforeUuidMutation(baseOptions?: Apollo.MutationHookOptions<IElementReorderBeforeUuidMutation, IElementReorderBeforeUuidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementReorderBeforeUuidMutation, IElementReorderBeforeUuidMutationVariables>(ElementReorderBeforeUuidDocument, options);
      }
export type ElementReorderBeforeUuidMutationHookResult = ReturnType<typeof useElementReorderBeforeUuidMutation>;
export type ElementReorderBeforeUuidMutationResult = Apollo.MutationResult<IElementReorderBeforeUuidMutation>;
export type ElementReorderBeforeUuidMutationOptions = Apollo.BaseMutationOptions<IElementReorderBeforeUuidMutation, IElementReorderBeforeUuidMutationVariables>;
export const ElementUpdateExpressionDocument = gql`
    mutation ElementUpdateExpression($input: ElementUpdateExpressionInput!) {
  elementUpdateExpression(input: $input) {
    ...element
  }
}
    ${ElementFragmentDoc}`;
export function useElementUpdateExpressionMutation(baseOptions?: Apollo.MutationHookOptions<IElementUpdateExpressionMutation, IElementUpdateExpressionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementUpdateExpressionMutation, IElementUpdateExpressionMutationVariables>(ElementUpdateExpressionDocument, options);
      }
export type ElementUpdateExpressionMutationHookResult = ReturnType<typeof useElementUpdateExpressionMutation>;
export type ElementUpdateExpressionMutationResult = Apollo.MutationResult<IElementUpdateExpressionMutation>;
export type ElementUpdateExpressionMutationOptions = Apollo.BaseMutationOptions<IElementUpdateExpressionMutation, IElementUpdateExpressionMutationVariables>;
export const ElementsDeletionDocument = gql`
    mutation ElementsDeletion($input: ElementsDeletionInput!) {
  elementsDeletion(input: $input)
}
    `;
export function useElementsDeletionMutation(baseOptions?: Apollo.MutationHookOptions<IElementsDeletionMutation, IElementsDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementsDeletionMutation, IElementsDeletionMutationVariables>(ElementsDeletionDocument, options);
      }
export type ElementsDeletionMutationHookResult = ReturnType<typeof useElementsDeletionMutation>;
export type ElementsDeletionMutationResult = Apollo.MutationResult<IElementsDeletionMutation>;
export type ElementsDeletionMutationOptions = Apollo.BaseMutationOptions<IElementsDeletionMutation, IElementsDeletionMutationVariables>;
export const ElementsDeletionForV2OrderingDocument = gql`
    mutation ElementsDeletionForV2Ordering($input: ElementsDeletionForV2OrderingInput!) {
  elementsDeletionForV2Ordering(input: $input) {
    uuid
    childOrder
  }
}
    `;
export function useElementsDeletionForV2OrderingMutation(baseOptions?: Apollo.MutationHookOptions<IElementsDeletionForV2OrderingMutation, IElementsDeletionForV2OrderingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IElementsDeletionForV2OrderingMutation, IElementsDeletionForV2OrderingMutationVariables>(ElementsDeletionForV2OrderingDocument, options);
      }
export type ElementsDeletionForV2OrderingMutationHookResult = ReturnType<typeof useElementsDeletionForV2OrderingMutation>;
export type ElementsDeletionForV2OrderingMutationResult = Apollo.MutationResult<IElementsDeletionForV2OrderingMutation>;
export type ElementsDeletionForV2OrderingMutationOptions = Apollo.BaseMutationOptions<IElementsDeletionForV2OrderingMutation, IElementsDeletionForV2OrderingMutationVariables>;
export const PublicEventDocument = gql`
    mutation PublicEvent($input: PublicEventInput) {
  publicEvent(input: $input) {
    ...event
  }
}
    ${EventFragmentDoc}`;
export function usePublicEventMutation(baseOptions?: Apollo.MutationHookOptions<IPublicEventMutation, IPublicEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPublicEventMutation, IPublicEventMutationVariables>(PublicEventDocument, options);
      }
export type PublicEventMutationHookResult = ReturnType<typeof usePublicEventMutation>;
export type PublicEventMutationResult = Apollo.MutationResult<IPublicEventMutation>;
export type PublicEventMutationOptions = Apollo.BaseMutationOptions<IPublicEventMutation, IPublicEventMutationVariables>;
export const PublishEstimateDocument = gql`
    mutation PublishEstimate($input: PublishEstimateInput!) {
  publishEstimate(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function usePublishEstimateMutation(baseOptions?: Apollo.MutationHookOptions<IPublishEstimateMutation, IPublishEstimateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPublishEstimateMutation, IPublishEstimateMutationVariables>(PublishEstimateDocument, options);
      }
export type PublishEstimateMutationHookResult = ReturnType<typeof usePublishEstimateMutation>;
export type PublishEstimateMutationResult = Apollo.MutationResult<IPublishEstimateMutation>;
export type PublishEstimateMutationOptions = Apollo.BaseMutationOptions<IPublishEstimateMutation, IPublishEstimateMutationVariables>;
export const MarkupGroupAddMarkupsDocument = gql`
    mutation MarkupGroupAddMarkups($input: MarkupGroupAddMarkupsInput!) {
  markupGroupAddMarkups(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupAddMarkupsMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupGroupAddMarkupsMutation, IMarkupGroupAddMarkupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupGroupAddMarkupsMutation, IMarkupGroupAddMarkupsMutationVariables>(MarkupGroupAddMarkupsDocument, options);
      }
export type MarkupGroupAddMarkupsMutationHookResult = ReturnType<typeof useMarkupGroupAddMarkupsMutation>;
export type MarkupGroupAddMarkupsMutationResult = Apollo.MutationResult<IMarkupGroupAddMarkupsMutation>;
export type MarkupGroupAddMarkupsMutationOptions = Apollo.BaseMutationOptions<IMarkupGroupAddMarkupsMutation, IMarkupGroupAddMarkupsMutationVariables>;
export const MarkupGroupCreateDocument = gql`
    mutation MarkupGroupCreate($input: MarkupGroupCreateInput!) {
  markupGroupCreate(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupGroupCreateMutation, IMarkupGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupGroupCreateMutation, IMarkupGroupCreateMutationVariables>(MarkupGroupCreateDocument, options);
      }
export type MarkupGroupCreateMutationHookResult = ReturnType<typeof useMarkupGroupCreateMutation>;
export type MarkupGroupCreateMutationResult = Apollo.MutationResult<IMarkupGroupCreateMutation>;
export type MarkupGroupCreateMutationOptions = Apollo.BaseMutationOptions<IMarkupGroupCreateMutation, IMarkupGroupCreateMutationVariables>;
export const MarkupGroupDeleteDocument = gql`
    mutation MarkupGroupDelete($input: MarkupGroupDeleteInput!) {
  markupGroupDelete(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupGroupDeleteMutation, IMarkupGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupGroupDeleteMutation, IMarkupGroupDeleteMutationVariables>(MarkupGroupDeleteDocument, options);
      }
export type MarkupGroupDeleteMutationHookResult = ReturnType<typeof useMarkupGroupDeleteMutation>;
export type MarkupGroupDeleteMutationResult = Apollo.MutationResult<IMarkupGroupDeleteMutation>;
export type MarkupGroupDeleteMutationOptions = Apollo.BaseMutationOptions<IMarkupGroupDeleteMutation, IMarkupGroupDeleteMutationVariables>;
export const MarkupGroupRemoveMarkupsDocument = gql`
    mutation MarkupGroupRemoveMarkups($input: MarkupGroupRemoveMarkupsInput!) {
  markupGroupRemoveMarkups(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupRemoveMarkupsMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupGroupRemoveMarkupsMutation, IMarkupGroupRemoveMarkupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupGroupRemoveMarkupsMutation, IMarkupGroupRemoveMarkupsMutationVariables>(MarkupGroupRemoveMarkupsDocument, options);
      }
export type MarkupGroupRemoveMarkupsMutationHookResult = ReturnType<typeof useMarkupGroupRemoveMarkupsMutation>;
export type MarkupGroupRemoveMarkupsMutationResult = Apollo.MutationResult<IMarkupGroupRemoveMarkupsMutation>;
export type MarkupGroupRemoveMarkupsMutationOptions = Apollo.BaseMutationOptions<IMarkupGroupRemoveMarkupsMutation, IMarkupGroupRemoveMarkupsMutationVariables>;
export const MarkupGroupUpdateDocument = gql`
    mutation MarkupGroupUpdate($input: MarkupGroupUpdateInput!) {
  markupGroupUpdate(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupGroupUpdateMutation, IMarkupGroupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupGroupUpdateMutation, IMarkupGroupUpdateMutationVariables>(MarkupGroupUpdateDocument, options);
      }
export type MarkupGroupUpdateMutationHookResult = ReturnType<typeof useMarkupGroupUpdateMutation>;
export type MarkupGroupUpdateMutationResult = Apollo.MutationResult<IMarkupGroupUpdateMutation>;
export type MarkupGroupUpdateMutationOptions = Apollo.BaseMutationOptions<IMarkupGroupUpdateMutation, IMarkupGroupUpdateMutationVariables>;
export const MarkupUpdateDocument = gql`
    mutation MarkupUpdate($input: MarkupUpdateInput!) {
  markupUpdate(input: $input) {
    ...markup
  }
}
    ${MarkupFragmentDoc}`;
export function useMarkupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IMarkupUpdateMutation, IMarkupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkupUpdateMutation, IMarkupUpdateMutationVariables>(MarkupUpdateDocument, options);
      }
export type MarkupUpdateMutationHookResult = ReturnType<typeof useMarkupUpdateMutation>;
export type MarkupUpdateMutationResult = Apollo.MutationResult<IMarkupUpdateMutation>;
export type MarkupUpdateMutationOptions = Apollo.BaseMutationOptions<IMarkupUpdateMutation, IMarkupUpdateMutationVariables>;
export const SubscriptionStartDocument = gql`
    mutation SubscriptionStart($input: SubscriptionStartInput!) {
  subscriptionStart(input: $input) {
    sessionID
    user {
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export function useSubscriptionStartMutation(baseOptions?: Apollo.MutationHookOptions<ISubscriptionStartMutation, ISubscriptionStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISubscriptionStartMutation, ISubscriptionStartMutationVariables>(SubscriptionStartDocument, options);
      }
export type SubscriptionStartMutationHookResult = ReturnType<typeof useSubscriptionStartMutation>;
export type SubscriptionStartMutationResult = Apollo.MutationResult<ISubscriptionStartMutation>;
export type SubscriptionStartMutationOptions = Apollo.BaseMutationOptions<ISubscriptionStartMutation, ISubscriptionStartMutationVariables>;
export const ProjectPlanFileCreationDocument = gql`
    mutation ProjectPlanFileCreation($input: ProjectPlanFileCreationInput!) {
  projectPlanFileCreation(input: $input) {
    ...projectPlanFile
  }
}
    ${ProjectPlanFileFragmentDoc}`;
export function useProjectPlanFileCreationMutation(baseOptions?: Apollo.MutationHookOptions<IProjectPlanFileCreationMutation, IProjectPlanFileCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectPlanFileCreationMutation, IProjectPlanFileCreationMutationVariables>(ProjectPlanFileCreationDocument, options);
      }
export type ProjectPlanFileCreationMutationHookResult = ReturnType<typeof useProjectPlanFileCreationMutation>;
export type ProjectPlanFileCreationMutationResult = Apollo.MutationResult<IProjectPlanFileCreationMutation>;
export type ProjectPlanFileCreationMutationOptions = Apollo.BaseMutationOptions<IProjectPlanFileCreationMutation, IProjectPlanFileCreationMutationVariables>;
export const ProjectPlanFilePresignedUrlDocument = gql`
    mutation ProjectPlanFilePresignedURL($input: ProjectPlanFilePresignedUrlInput!) {
  projectPlanFilePresignedUrl(input: $input)
}
    `;
export function useProjectPlanFilePresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<IProjectPlanFilePresignedUrlMutation, IProjectPlanFilePresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectPlanFilePresignedUrlMutation, IProjectPlanFilePresignedUrlMutationVariables>(ProjectPlanFilePresignedUrlDocument, options);
      }
export type ProjectPlanFilePresignedUrlMutationHookResult = ReturnType<typeof useProjectPlanFilePresignedUrlMutation>;
export type ProjectPlanFilePresignedUrlMutationResult = Apollo.MutationResult<IProjectPlanFilePresignedUrlMutation>;
export type ProjectPlanFilePresignedUrlMutationOptions = Apollo.BaseMutationOptions<IProjectPlanFilePresignedUrlMutation, IProjectPlanFilePresignedUrlMutationVariables>;
export const ProjectPricingDocument = gql`
    mutation ProjectPricing($input: ProjectPricingInput!) {
  projectPricing(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useProjectPricingMutation(baseOptions?: Apollo.MutationHookOptions<IProjectPricingMutation, IProjectPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectPricingMutation, IProjectPricingMutationVariables>(ProjectPricingDocument, options);
      }
export type ProjectPricingMutationHookResult = ReturnType<typeof useProjectPricingMutation>;
export type ProjectPricingMutationResult = Apollo.MutationResult<IProjectPricingMutation>;
export type ProjectPricingMutationOptions = Apollo.BaseMutationOptions<IProjectPricingMutation, IProjectPricingMutationVariables>;
export const ProjectAreaAssignmentDocument = gql`
    mutation ProjectAreaAssignment($input: ProjectAreaAssignmentInput!) {
  projectAreaAssignment(input: $input) {
    ...projectArea
  }
}
    ${ProjectAreaFragmentDoc}`;
export function useProjectAreaAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProjectAreaAssignmentMutation, IProjectAreaAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectAreaAssignmentMutation, IProjectAreaAssignmentMutationVariables>(ProjectAreaAssignmentDocument, options);
      }
export type ProjectAreaAssignmentMutationHookResult = ReturnType<typeof useProjectAreaAssignmentMutation>;
export type ProjectAreaAssignmentMutationResult = Apollo.MutationResult<IProjectAreaAssignmentMutation>;
export type ProjectAreaAssignmentMutationOptions = Apollo.BaseMutationOptions<IProjectAreaAssignmentMutation, IProjectAreaAssignmentMutationVariables>;
export const ProjectLocationAssignmentDocument = gql`
    mutation ProjectLocationAssignment($input: ProjectLocationAssignmentInput!) {
  projectLocationAssignment(input: $input) {
    ...projectLocation
  }
}
    ${ProjectLocationFragmentDoc}`;
export function useProjectLocationAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProjectLocationAssignmentMutation, IProjectLocationAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectLocationAssignmentMutation, IProjectLocationAssignmentMutationVariables>(ProjectLocationAssignmentDocument, options);
      }
export type ProjectLocationAssignmentMutationHookResult = ReturnType<typeof useProjectLocationAssignmentMutation>;
export type ProjectLocationAssignmentMutationResult = Apollo.MutationResult<IProjectLocationAssignmentMutation>;
export type ProjectLocationAssignmentMutationOptions = Apollo.BaseMutationOptions<IProjectLocationAssignmentMutation, IProjectLocationAssignmentMutationVariables>;
export const ProjectMarkupAssignmentDocument = gql`
    mutation ProjectMarkupAssignment($input: ProjectMarkupAssignmentInput!) {
  projectMarkupAssignment(input: $input) {
    ...projectMarkup
  }
}
    ${ProjectMarkupFragmentDoc}`;
export function useProjectMarkupAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProjectMarkupAssignmentMutation, IProjectMarkupAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectMarkupAssignmentMutation, IProjectMarkupAssignmentMutationVariables>(ProjectMarkupAssignmentDocument, options);
      }
export type ProjectMarkupAssignmentMutationHookResult = ReturnType<typeof useProjectMarkupAssignmentMutation>;
export type ProjectMarkupAssignmentMutationResult = Apollo.MutationResult<IProjectMarkupAssignmentMutation>;
export type ProjectMarkupAssignmentMutationOptions = Apollo.BaseMutationOptions<IProjectMarkupAssignmentMutation, IProjectMarkupAssignmentMutationVariables>;
export const ProjectMarkupsDeletionDocument = gql`
    mutation ProjectMarkupsDeletion($input: ProjectMarkupsDeletionInput!) {
  projectMarkupsDeletion(input: $input)
}
    `;
export function useProjectMarkupsDeletionMutation(baseOptions?: Apollo.MutationHookOptions<IProjectMarkupsDeletionMutation, IProjectMarkupsDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectMarkupsDeletionMutation, IProjectMarkupsDeletionMutationVariables>(ProjectMarkupsDeletionDocument, options);
      }
export type ProjectMarkupsDeletionMutationHookResult = ReturnType<typeof useProjectMarkupsDeletionMutation>;
export type ProjectMarkupsDeletionMutationResult = Apollo.MutationResult<IProjectMarkupsDeletionMutation>;
export type ProjectMarkupsDeletionMutationOptions = Apollo.BaseMutationOptions<IProjectMarkupsDeletionMutation, IProjectMarkupsDeletionMutationVariables>;
export const ProjectAcceptanceWithQuoteDocument = gql`
    mutation ProjectAcceptanceWithQuote($input: EstimatorQuoteInput!) {
  projectAcceptanceWithQuote(input: $input) {
    id
  }
}
    `;
export function useProjectAcceptanceWithQuoteMutation(baseOptions?: Apollo.MutationHookOptions<IProjectAcceptanceWithQuoteMutation, IProjectAcceptanceWithQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectAcceptanceWithQuoteMutation, IProjectAcceptanceWithQuoteMutationVariables>(ProjectAcceptanceWithQuoteDocument, options);
      }
export type ProjectAcceptanceWithQuoteMutationHookResult = ReturnType<typeof useProjectAcceptanceWithQuoteMutation>;
export type ProjectAcceptanceWithQuoteMutationResult = Apollo.MutationResult<IProjectAcceptanceWithQuoteMutation>;
export type ProjectAcceptanceWithQuoteMutationOptions = Apollo.BaseMutationOptions<IProjectAcceptanceWithQuoteMutation, IProjectAcceptanceWithQuoteMutationVariables>;
export const AcceptProjectPricingDocument = gql`
    mutation AcceptProjectPricing($input: AcceptProjectPricingInput!) {
  acceptProjectPricing(input: $input) {
    id
  }
}
    `;
export function useAcceptProjectPricingMutation(baseOptions?: Apollo.MutationHookOptions<IAcceptProjectPricingMutation, IAcceptProjectPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAcceptProjectPricingMutation, IAcceptProjectPricingMutationVariables>(AcceptProjectPricingDocument, options);
      }
export type AcceptProjectPricingMutationHookResult = ReturnType<typeof useAcceptProjectPricingMutation>;
export type AcceptProjectPricingMutationResult = Apollo.MutationResult<IAcceptProjectPricingMutation>;
export type AcceptProjectPricingMutationOptions = Apollo.BaseMutationOptions<IAcceptProjectPricingMutation, IAcceptProjectPricingMutationVariables>;
export const ApproveAutoQuoteProjectPricingDocument = gql`
    mutation ApproveAutoQuoteProjectPricing($input: ApproveAutoQuoteProjectPricingInput!) {
  approveAutoQuoteProjectPricing(input: $input) {
    id
  }
}
    `;
export function useApproveAutoQuoteProjectPricingMutation(baseOptions?: Apollo.MutationHookOptions<IApproveAutoQuoteProjectPricingMutation, IApproveAutoQuoteProjectPricingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IApproveAutoQuoteProjectPricingMutation, IApproveAutoQuoteProjectPricingMutationVariables>(ApproveAutoQuoteProjectPricingDocument, options);
      }
export type ApproveAutoQuoteProjectPricingMutationHookResult = ReturnType<typeof useApproveAutoQuoteProjectPricingMutation>;
export type ApproveAutoQuoteProjectPricingMutationResult = Apollo.MutationResult<IApproveAutoQuoteProjectPricingMutation>;
export type ApproveAutoQuoteProjectPricingMutationOptions = Apollo.BaseMutationOptions<IApproveAutoQuoteProjectPricingMutation, IApproveAutoQuoteProjectPricingMutationVariables>;
export const AssignEstimatorDocument = gql`
    mutation AssignEstimator($input: AssignEstimatorInput!) {
  assignEstimator(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useAssignEstimatorMutation(baseOptions?: Apollo.MutationHookOptions<IAssignEstimatorMutation, IAssignEstimatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssignEstimatorMutation, IAssignEstimatorMutationVariables>(AssignEstimatorDocument, options);
      }
export type AssignEstimatorMutationHookResult = ReturnType<typeof useAssignEstimatorMutation>;
export type AssignEstimatorMutationResult = Apollo.MutationResult<IAssignEstimatorMutation>;
export type AssignEstimatorMutationOptions = Apollo.BaseMutationOptions<IAssignEstimatorMutation, IAssignEstimatorMutationVariables>;
export const ProjectDocument = gql`
    mutation Project($input: ProjectSubmissionInput!) {
  projectSubmission(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useProjectMutation(baseOptions?: Apollo.MutationHookOptions<IProjectMutation, IProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectMutation, IProjectMutationVariables>(ProjectDocument, options);
      }
export type ProjectMutationHookResult = ReturnType<typeof useProjectMutation>;
export type ProjectMutationResult = Apollo.MutationResult<IProjectMutation>;
export type ProjectMutationOptions = Apollo.BaseMutationOptions<IProjectMutation, IProjectMutationVariables>;
export const LaborRateTypeDocument = gql`
    query LaborRateType {
  laborRateType {
    assistant
    description
  }
}
    `;
export function useLaborRateTypeQuery(baseOptions?: Apollo.QueryHookOptions<ILaborRateTypeQuery, ILaborRateTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILaborRateTypeQuery, ILaborRateTypeQueryVariables>(LaborRateTypeDocument, options);
      }
export function useLaborRateTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILaborRateTypeQuery, ILaborRateTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILaborRateTypeQuery, ILaborRateTypeQueryVariables>(LaborRateTypeDocument, options);
        }
export type LaborRateTypeQueryHookResult = ReturnType<typeof useLaborRateTypeQuery>;
export type LaborRateTypeLazyQueryHookResult = ReturnType<typeof useLaborRateTypeLazyQuery>;
export type LaborRateTypeQueryResult = Apollo.QueryResult<ILaborRateTypeQuery, ILaborRateTypeQueryVariables>;
export const ProjectAcceptanceHistoryAssignmentDocument = gql`
    mutation ProjectAcceptanceHistoryAssignment($input: ProjectAcceptanceHistoryAssignmentInput!) {
  projectAcceptanceHistoryAssignment(input: $input) {
    userID
    projectID
  }
}
    `;
export function useProjectAcceptanceHistoryAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProjectAcceptanceHistoryAssignmentMutation, IProjectAcceptanceHistoryAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectAcceptanceHistoryAssignmentMutation, IProjectAcceptanceHistoryAssignmentMutationVariables>(ProjectAcceptanceHistoryAssignmentDocument, options);
      }
export type ProjectAcceptanceHistoryAssignmentMutationHookResult = ReturnType<typeof useProjectAcceptanceHistoryAssignmentMutation>;
export type ProjectAcceptanceHistoryAssignmentMutationResult = Apollo.MutationResult<IProjectAcceptanceHistoryAssignmentMutation>;
export type ProjectAcceptanceHistoryAssignmentMutationOptions = Apollo.BaseMutationOptions<IProjectAcceptanceHistoryAssignmentMutation, IProjectAcceptanceHistoryAssignmentMutationVariables>;
export const ProjectEstimateSubmissionDocument = gql`
    mutation ProjectEstimateSubmission($input: SubmittedProjectInput!) {
  projectEstimateSubmission(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useProjectEstimateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<IProjectEstimateSubmissionMutation, IProjectEstimateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectEstimateSubmissionMutation, IProjectEstimateSubmissionMutationVariables>(ProjectEstimateSubmissionDocument, options);
      }
export type ProjectEstimateSubmissionMutationHookResult = ReturnType<typeof useProjectEstimateSubmissionMutation>;
export type ProjectEstimateSubmissionMutationResult = Apollo.MutationResult<IProjectEstimateSubmissionMutation>;
export type ProjectEstimateSubmissionMutationOptions = Apollo.BaseMutationOptions<IProjectEstimateSubmissionMutation, IProjectEstimateSubmissionMutationVariables>;
export const ProjectPriceEstimationHistoryDocument = gql`
    mutation ProjectPriceEstimationHistory($input: ProjectPriceEstimationHistoryInput!) {
  projectPriceEstimationHistory(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useProjectPriceEstimationHistoryMutation(baseOptions?: Apollo.MutationHookOptions<IProjectPriceEstimationHistoryMutation, IProjectPriceEstimationHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectPriceEstimationHistoryMutation, IProjectPriceEstimationHistoryMutationVariables>(ProjectPriceEstimationHistoryDocument, options);
      }
export type ProjectPriceEstimationHistoryMutationHookResult = ReturnType<typeof useProjectPriceEstimationHistoryMutation>;
export type ProjectPriceEstimationHistoryMutationResult = Apollo.MutationResult<IProjectPriceEstimationHistoryMutation>;
export type ProjectPriceEstimationHistoryMutationOptions = Apollo.BaseMutationOptions<IProjectPriceEstimationHistoryMutation, IProjectPriceEstimationHistoryMutationVariables>;
export const ProjectReviewWithReviewTagsCreationDocument = gql`
    mutation ProjectReviewWithReviewTagsCreation($input: ProjectReviewWithReviewTagsInput!) {
  projectReviewWithReviewTagsCreation(input: $input) {
    id
  }
}
    `;
export function useProjectReviewWithReviewTagsCreationMutation(baseOptions?: Apollo.MutationHookOptions<IProjectReviewWithReviewTagsCreationMutation, IProjectReviewWithReviewTagsCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectReviewWithReviewTagsCreationMutation, IProjectReviewWithReviewTagsCreationMutationVariables>(ProjectReviewWithReviewTagsCreationDocument, options);
      }
export type ProjectReviewWithReviewTagsCreationMutationHookResult = ReturnType<typeof useProjectReviewWithReviewTagsCreationMutation>;
export type ProjectReviewWithReviewTagsCreationMutationResult = Apollo.MutationResult<IProjectReviewWithReviewTagsCreationMutation>;
export type ProjectReviewWithReviewTagsCreationMutationOptions = Apollo.BaseMutationOptions<IProjectReviewWithReviewTagsCreationMutation, IProjectReviewWithReviewTagsCreationMutationVariables>;
export const ProjectStatusAssignmentDocument = gql`
    mutation ProjectStatusAssignment($input: ProjectStatusAssignmentInput!) {
  projectStatusAssignment(input: $input) {
    id
  }
}
    `;
export function useProjectStatusAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProjectStatusAssignmentMutation, IProjectStatusAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectStatusAssignmentMutation, IProjectStatusAssignmentMutationVariables>(ProjectStatusAssignmentDocument, options);
      }
export type ProjectStatusAssignmentMutationHookResult = ReturnType<typeof useProjectStatusAssignmentMutation>;
export type ProjectStatusAssignmentMutationResult = Apollo.MutationResult<IProjectStatusAssignmentMutation>;
export type ProjectStatusAssignmentMutationOptions = Apollo.BaseMutationOptions<IProjectStatusAssignmentMutation, IProjectStatusAssignmentMutationVariables>;
export const ProjectUploadS3Document = gql`
    mutation ProjectUploadS3($input: S3UploadInput!) {
  projectUploadS3(input: $input)
}
    `;
export function useProjectUploadS3Mutation(baseOptions?: Apollo.MutationHookOptions<IProjectUploadS3Mutation, IProjectUploadS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProjectUploadS3Mutation, IProjectUploadS3MutationVariables>(ProjectUploadS3Document, options);
      }
export type ProjectUploadS3MutationHookResult = ReturnType<typeof useProjectUploadS3Mutation>;
export type ProjectUploadS3MutationResult = Apollo.MutationResult<IProjectUploadS3Mutation>;
export type ProjectUploadS3MutationOptions = Apollo.BaseMutationOptions<IProjectUploadS3Mutation, IProjectUploadS3MutationVariables>;
export const PublicUnsubscribeEstimateDocument = gql`
    mutation PublicUnsubscribeEstimate($input: UnsubscribePublicEstimateInput) {
  publicUnsubscribeEstimate(input: $input) {
    id
  }
}
    `;
export function usePublicUnsubscribeEstimateMutation(baseOptions?: Apollo.MutationHookOptions<IPublicUnsubscribeEstimateMutation, IPublicUnsubscribeEstimateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPublicUnsubscribeEstimateMutation, IPublicUnsubscribeEstimateMutationVariables>(PublicUnsubscribeEstimateDocument, options);
      }
export type PublicUnsubscribeEstimateMutationHookResult = ReturnType<typeof usePublicUnsubscribeEstimateMutation>;
export type PublicUnsubscribeEstimateMutationResult = Apollo.MutationResult<IPublicUnsubscribeEstimateMutation>;
export type PublicUnsubscribeEstimateMutationOptions = Apollo.BaseMutationOptions<IPublicUnsubscribeEstimateMutation, IPublicUnsubscribeEstimateMutationVariables>;
export const SubscribeToPublicEstimateDocument = gql`
    mutation SubscribeToPublicEstimate($input: SubscribeToPublicEstimateInput!) {
  subscribeToPublicEstimate(input: $input) {
    ...event
  }
}
    ${EventFragmentDoc}`;
export function useSubscribeToPublicEstimateMutation(baseOptions?: Apollo.MutationHookOptions<ISubscribeToPublicEstimateMutation, ISubscribeToPublicEstimateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISubscribeToPublicEstimateMutation, ISubscribeToPublicEstimateMutationVariables>(SubscribeToPublicEstimateDocument, options);
      }
export type SubscribeToPublicEstimateMutationHookResult = ReturnType<typeof useSubscribeToPublicEstimateMutation>;
export type SubscribeToPublicEstimateMutationResult = Apollo.MutationResult<ISubscribeToPublicEstimateMutation>;
export type SubscribeToPublicEstimateMutationOptions = Apollo.BaseMutationOptions<ISubscribeToPublicEstimateMutation, ISubscribeToPublicEstimateMutationVariables>;
export const UnassignEstimatorDocument = gql`
    mutation UnassignEstimator($input: AssignEstimatorInput!) {
  unassignEstimator(input: $input) {
    ...project
  }
}
    ${ProjectFragmentDoc}`;
export function useUnassignEstimatorMutation(baseOptions?: Apollo.MutationHookOptions<IUnassignEstimatorMutation, IUnassignEstimatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUnassignEstimatorMutation, IUnassignEstimatorMutationVariables>(UnassignEstimatorDocument, options);
      }
export type UnassignEstimatorMutationHookResult = ReturnType<typeof useUnassignEstimatorMutation>;
export type UnassignEstimatorMutationResult = Apollo.MutationResult<IUnassignEstimatorMutation>;
export type UnassignEstimatorMutationOptions = Apollo.BaseMutationOptions<IUnassignEstimatorMutation, IUnassignEstimatorMutationVariables>;
export const ScheduleAssignmentDocument = gql`
    mutation ScheduleAssignment($input: ScheduleAssignmentInput!) {
  scheduleAssignment(input: $input) {
    id
    userID
    availability
    paused
    unavailable {
      id
      projectID
      status
      from
      to
      createdAt
      updatedAt
    }
  }
}
    `;
export function useScheduleAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IScheduleAssignmentMutation, IScheduleAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IScheduleAssignmentMutation, IScheduleAssignmentMutationVariables>(ScheduleAssignmentDocument, options);
      }
export type ScheduleAssignmentMutationHookResult = ReturnType<typeof useScheduleAssignmentMutation>;
export type ScheduleAssignmentMutationResult = Apollo.MutationResult<IScheduleAssignmentMutation>;
export type ScheduleAssignmentMutationOptions = Apollo.BaseMutationOptions<IScheduleAssignmentMutation, IScheduleAssignmentMutationVariables>;
export const TeamProjectInfoAssignDocument = gql`
    mutation TeamProjectInfoAssign($input: TeamProjectInfoInput!) {
  teamProjectInfoAssign(input: $input) {
    id
    teamID
    estimateTypes
    formattingPreferences
    inclusionsExclusions
    averageProjectPrice {
      pricePerSquareFoot
      priceTotal
    }
    hourlyLaborRates {
      id
      rateUSDCents
      type
    }
    preferredMarkups {
      overhead
      profit
      material
      labor
    }
    requestsAndNotes
    files {
      filename
      uuid
      directory
    }
  }
}
    `;
export function useTeamProjectInfoAssignMutation(baseOptions?: Apollo.MutationHookOptions<ITeamProjectInfoAssignMutation, ITeamProjectInfoAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITeamProjectInfoAssignMutation, ITeamProjectInfoAssignMutationVariables>(TeamProjectInfoAssignDocument, options);
      }
export type TeamProjectInfoAssignMutationHookResult = ReturnType<typeof useTeamProjectInfoAssignMutation>;
export type TeamProjectInfoAssignMutationResult = Apollo.MutationResult<ITeamProjectInfoAssignMutation>;
export type TeamProjectInfoAssignMutationOptions = Apollo.BaseMutationOptions<ITeamProjectInfoAssignMutation, ITeamProjectInfoAssignMutationVariables>;
export const TeamProjectInfoFilesRemoveDocument = gql`
    mutation TeamProjectInfoFilesRemove($input: TeamFilesRemoveInput!) {
  teamProjectInfoFilesRemove(input: $input)
}
    `;
export function useTeamProjectInfoFilesRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ITeamProjectInfoFilesRemoveMutation, ITeamProjectInfoFilesRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITeamProjectInfoFilesRemoveMutation, ITeamProjectInfoFilesRemoveMutationVariables>(TeamProjectInfoFilesRemoveDocument, options);
      }
export type TeamProjectInfoFilesRemoveMutationHookResult = ReturnType<typeof useTeamProjectInfoFilesRemoveMutation>;
export type TeamProjectInfoFilesRemoveMutationResult = Apollo.MutationResult<ITeamProjectInfoFilesRemoveMutation>;
export type TeamProjectInfoFilesRemoveMutationOptions = Apollo.BaseMutationOptions<ITeamProjectInfoFilesRemoveMutation, ITeamProjectInfoFilesRemoveMutationVariables>;
export const CancelTrialDocument = gql`
    mutation cancelTrial {
  cancelTrial {
    id
    accountType
    daysLeftInTrial
  }
}
    `;
export function useCancelTrialMutation(baseOptions?: Apollo.MutationHookOptions<ICancelTrialMutation, ICancelTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICancelTrialMutation, ICancelTrialMutationVariables>(CancelTrialDocument, options);
      }
export type CancelTrialMutationHookResult = ReturnType<typeof useCancelTrialMutation>;
export type CancelTrialMutationResult = Apollo.MutationResult<ICancelTrialMutation>;
export type CancelTrialMutationOptions = Apollo.BaseMutationOptions<ICancelTrialMutation, ICancelTrialMutationVariables>;
export const ConnectTrialToSubscriptionDocument = gql`
    mutation connectTrialToSubscription($input: ConnectTrialToSubscriptionInput!) {
  connectTrialToSubscription(input: $input)
}
    `;
export function useConnectTrialToSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<IConnectTrialToSubscriptionMutation, IConnectTrialToSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConnectTrialToSubscriptionMutation, IConnectTrialToSubscriptionMutationVariables>(ConnectTrialToSubscriptionDocument, options);
      }
export type ConnectTrialToSubscriptionMutationHookResult = ReturnType<typeof useConnectTrialToSubscriptionMutation>;
export type ConnectTrialToSubscriptionMutationResult = Apollo.MutationResult<IConnectTrialToSubscriptionMutation>;
export type ConnectTrialToSubscriptionMutationOptions = Apollo.BaseMutationOptions<IConnectTrialToSubscriptionMutation, IConnectTrialToSubscriptionMutationVariables>;
export const ExtendTrialDocument = gql`
    mutation extendTrial($input: ExtendTrialInput!) {
  extendTrial(input: $input) {
    id
    createdAt
    teamID
    daysLeftInTrial
  }
}
    `;
export function useExtendTrialMutation(baseOptions?: Apollo.MutationHookOptions<IExtendTrialMutation, IExtendTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IExtendTrialMutation, IExtendTrialMutationVariables>(ExtendTrialDocument, options);
      }
export type ExtendTrialMutationHookResult = ReturnType<typeof useExtendTrialMutation>;
export type ExtendTrialMutationResult = Apollo.MutationResult<IExtendTrialMutation>;
export type ExtendTrialMutationOptions = Apollo.BaseMutationOptions<IExtendTrialMutation, IExtendTrialMutationVariables>;
export const TeamAssignmentDocument = gql`
    mutation TeamAssignment($input: TeamAssignmentInput!) {
  teamAssignment(input: $input) {
    id
  }
}
    `;
export function useTeamAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<ITeamAssignmentMutation, ITeamAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITeamAssignmentMutation, ITeamAssignmentMutationVariables>(TeamAssignmentDocument, options);
      }
export type TeamAssignmentMutationHookResult = ReturnType<typeof useTeamAssignmentMutation>;
export type TeamAssignmentMutationResult = Apollo.MutationResult<ITeamAssignmentMutation>;
export type TeamAssignmentMutationOptions = Apollo.BaseMutationOptions<ITeamAssignmentMutation, ITeamAssignmentMutationVariables>;
export const TeamCreditAssignmentDocument = gql`
    mutation TeamCreditAssignment($input: TeamCreditAssignmentInput!) {
  teamCreditAssignment(input: $input) {
    id
  }
}
    `;
export function useTeamCreditAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<ITeamCreditAssignmentMutation, ITeamCreditAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITeamCreditAssignmentMutation, ITeamCreditAssignmentMutationVariables>(TeamCreditAssignmentDocument, options);
      }
export type TeamCreditAssignmentMutationHookResult = ReturnType<typeof useTeamCreditAssignmentMutation>;
export type TeamCreditAssignmentMutationResult = Apollo.MutationResult<ITeamCreditAssignmentMutation>;
export type TeamCreditAssignmentMutationOptions = Apollo.BaseMutationOptions<ITeamCreditAssignmentMutation, ITeamCreditAssignmentMutationVariables>;
export const TeamLeaderOnboardingAssignmentDocument = gql`
    mutation TeamLeaderOnboardingAssignment($input: TeamLeaderOnboardingAssignmentInput!) {
  teamLeaderOnboardingAssignment(input: $input) {
    id
    name
    leadUser {
      id
      firstName
      lastName
      phone
      companyRole
      businessType
      serviceType
      projectTypes
    }
  }
}
    `;
export function useTeamLeaderOnboardingAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<ITeamLeaderOnboardingAssignmentMutation, ITeamLeaderOnboardingAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITeamLeaderOnboardingAssignmentMutation, ITeamLeaderOnboardingAssignmentMutationVariables>(TeamLeaderOnboardingAssignmentDocument, options);
      }
export type TeamLeaderOnboardingAssignmentMutationHookResult = ReturnType<typeof useTeamLeaderOnboardingAssignmentMutation>;
export type TeamLeaderOnboardingAssignmentMutationResult = Apollo.MutationResult<ITeamLeaderOnboardingAssignmentMutation>;
export type TeamLeaderOnboardingAssignmentMutationOptions = Apollo.BaseMutationOptions<ITeamLeaderOnboardingAssignmentMutation, ITeamLeaderOnboardingAssignmentMutationVariables>;
export const UserToTeamConnectionDocument = gql`
    mutation userToTeamConnection($input: UserConnectInput!) {
  userToTeamConnection(input: $input) {
    id
  }
}
    `;
export function useUserToTeamConnectionMutation(baseOptions?: Apollo.MutationHookOptions<IUserToTeamConnectionMutation, IUserToTeamConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUserToTeamConnectionMutation, IUserToTeamConnectionMutationVariables>(UserToTeamConnectionDocument, options);
      }
export type UserToTeamConnectionMutationHookResult = ReturnType<typeof useUserToTeamConnectionMutation>;
export type UserToTeamConnectionMutationResult = Apollo.MutationResult<IUserToTeamConnectionMutation>;
export type UserToTeamConnectionMutationOptions = Apollo.BaseMutationOptions<IUserToTeamConnectionMutation, IUserToTeamConnectionMutationVariables>;
export const UserTradeRatingAssignmentDocument = gql`
    mutation UserTradeRatingAssignment($input: UserTradeRatingAssignmentInput!) {
  userTradeRatingAssignment(input: $input) {
    id
  }
}
    `;
export function useUserTradeRatingAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IUserTradeRatingAssignmentMutation, IUserTradeRatingAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUserTradeRatingAssignmentMutation, IUserTradeRatingAssignmentMutationVariables>(UserTradeRatingAssignmentDocument, options);
      }
export type UserTradeRatingAssignmentMutationHookResult = ReturnType<typeof useUserTradeRatingAssignmentMutation>;
export type UserTradeRatingAssignmentMutationResult = Apollo.MutationResult<IUserTradeRatingAssignmentMutation>;
export type UserTradeRatingAssignmentMutationOptions = Apollo.BaseMutationOptions<IUserTradeRatingAssignmentMutation, IUserTradeRatingAssignmentMutationVariables>;
export const EstimatorProfileAssignmentDocument = gql`
    mutation EstimatorProfileAssignment($userInput: UserUpdateInput!, $estimatorInput: ScheduleAssignmentInput!) {
  scheduleAssignment(input: $estimatorInput) {
    id
  }
  userUpdate(input: $userInput) {
    ...userLight
  }
}
    ${UserLightFragmentDoc}`;
export function useEstimatorProfileAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IEstimatorProfileAssignmentMutation, IEstimatorProfileAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IEstimatorProfileAssignmentMutation, IEstimatorProfileAssignmentMutationVariables>(EstimatorProfileAssignmentDocument, options);
      }
export type EstimatorProfileAssignmentMutationHookResult = ReturnType<typeof useEstimatorProfileAssignmentMutation>;
export type EstimatorProfileAssignmentMutationResult = Apollo.MutationResult<IEstimatorProfileAssignmentMutation>;
export type EstimatorProfileAssignmentMutationOptions = Apollo.BaseMutationOptions<IEstimatorProfileAssignmentMutation, IEstimatorProfileAssignmentMutationVariables>;
export const ProfileAssignmentDocument = gql`
    mutation ProfileAssignment($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    ...userLight
  }
}
    ${UserLightFragmentDoc}`;
export function useProfileAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IProfileAssignmentMutation, IProfileAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IProfileAssignmentMutation, IProfileAssignmentMutationVariables>(ProfileAssignmentDocument, options);
      }
export type ProfileAssignmentMutationHookResult = ReturnType<typeof useProfileAssignmentMutation>;
export type ProfileAssignmentMutationResult = Apollo.MutationResult<IProfileAssignmentMutation>;
export type ProfileAssignmentMutationOptions = Apollo.BaseMutationOptions<IProfileAssignmentMutation, IProfileAssignmentMutationVariables>;
export const UserClientPreferenceAssignmentDocument = gql`
    mutation UserClientPreferenceAssignment($input: UserClientPreferenceAssignmentInput!) {
  userClientPreferenceAssignment(input: $input) {
    id
  }
}
    `;
export function useUserClientPreferenceAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<IUserClientPreferenceAssignmentMutation, IUserClientPreferenceAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUserClientPreferenceAssignmentMutation, IUserClientPreferenceAssignmentMutationVariables>(UserClientPreferenceAssignmentDocument, options);
      }
export type UserClientPreferenceAssignmentMutationHookResult = ReturnType<typeof useUserClientPreferenceAssignmentMutation>;
export type UserClientPreferenceAssignmentMutationResult = Apollo.MutationResult<IUserClientPreferenceAssignmentMutation>;
export type UserClientPreferenceAssignmentMutationOptions = Apollo.BaseMutationOptions<IUserClientPreferenceAssignmentMutation, IUserClientPreferenceAssignmentMutationVariables>;
export const UserCreateDocument = gql`
    mutation UserCreate($input: UserCreateInput!) {
  userCreate(input: $input) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<IUserCreateMutation, IUserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUserCreateMutation, IUserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<IUserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<IUserCreateMutation, IUserCreateMutationVariables>;
export const AssembliesDocument = gql`
    query Assemblies($input: AssembliesInput!) {
  assemblies(input: $input) {
    edges {
      cursor
      node {
        ...assembly
        elements {
          edges {
            cursor
            node {
              ...element
            }
          }
          ...oldConnPageInfo
        }
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${AssemblyFragmentDoc}
${ElementFragmentDoc}
${OldConnPageInfoFragmentDoc}
${PageInfoFragmentDoc}`;
export function useAssembliesQuery(baseOptions: Apollo.QueryHookOptions<IAssembliesQuery, IAssembliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssembliesQuery, IAssembliesQueryVariables>(AssembliesDocument, options);
      }
export function useAssembliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssembliesQuery, IAssembliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssembliesQuery, IAssembliesQueryVariables>(AssembliesDocument, options);
        }
export type AssembliesQueryHookResult = ReturnType<typeof useAssembliesQuery>;
export type AssembliesLazyQueryHookResult = ReturnType<typeof useAssembliesLazyQuery>;
export type AssembliesQueryResult = Apollo.QueryResult<IAssembliesQuery, IAssembliesQueryVariables>;
export const AssembliesLiteDocument = gql`
    query AssembliesLite($input: AssembliesInput!) {
  assemblies(input: $input) {
    edges {
      cursor
      node {
        ...assembly
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${AssemblyFragmentDoc}
${PageInfoFragmentDoc}`;
export function useAssembliesLiteQuery(baseOptions: Apollo.QueryHookOptions<IAssembliesLiteQuery, IAssembliesLiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssembliesLiteQuery, IAssembliesLiteQueryVariables>(AssembliesLiteDocument, options);
      }
export function useAssembliesLiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssembliesLiteQuery, IAssembliesLiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssembliesLiteQuery, IAssembliesLiteQueryVariables>(AssembliesLiteDocument, options);
        }
export type AssembliesLiteQueryHookResult = ReturnType<typeof useAssembliesLiteQuery>;
export type AssembliesLiteLazyQueryHookResult = ReturnType<typeof useAssembliesLiteLazyQuery>;
export type AssembliesLiteQueryResult = Apollo.QueryResult<IAssembliesLiteQuery, IAssembliesLiteQueryVariables>;
export const EstimateAssembliesDocument = gql`
    query EstimateAssemblies($input: AssembliesInput!) {
  assemblies(input: $input) {
    edges {
      cursor
      node {
        ...estimateAssembly
        elements {
          edges {
            node {
              ...estimateElement
            }
          }
        }
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${EstimateAssemblyFragmentDoc}
${EstimateElementFragmentDoc}
${PageInfoFragmentDoc}`;
export function useEstimateAssembliesQuery(baseOptions: Apollo.QueryHookOptions<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>(EstimateAssembliesDocument, options);
      }
export function useEstimateAssembliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>(EstimateAssembliesDocument, options);
        }
export type EstimateAssembliesQueryHookResult = ReturnType<typeof useEstimateAssembliesQuery>;
export type EstimateAssembliesLazyQueryHookResult = ReturnType<typeof useEstimateAssembliesLazyQuery>;
export type EstimateAssembliesQueryResult = Apollo.QueryResult<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>;
export const AssemblyLibrariesDocument = gql`
    query AssemblyLibraries($input: AssemblyLibrariesInput!) {
  assemblyLibraries(input: $input) {
    edges {
      cursor
      node {
        ...assemblyLibrary
      }
    }
    ...oldConnPageInfo
  }
}
    ${AssemblyLibraryFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useAssemblyLibrariesQuery(baseOptions: Apollo.QueryHookOptions<IAssemblyLibrariesQuery, IAssemblyLibrariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssemblyLibrariesQuery, IAssemblyLibrariesQueryVariables>(AssemblyLibrariesDocument, options);
      }
export function useAssemblyLibrariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssemblyLibrariesQuery, IAssemblyLibrariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssemblyLibrariesQuery, IAssemblyLibrariesQueryVariables>(AssemblyLibrariesDocument, options);
        }
export type AssemblyLibrariesQueryHookResult = ReturnType<typeof useAssemblyLibrariesQuery>;
export type AssemblyLibrariesLazyQueryHookResult = ReturnType<typeof useAssemblyLibrariesLazyQuery>;
export type AssemblyLibrariesQueryResult = Apollo.QueryResult<IAssemblyLibrariesQuery, IAssemblyLibrariesQueryVariables>;
export const AssetDocument = gql`
    query Asset($input: AssetInput!) {
  asset(input: $input) {
    ...asset
  }
}
    ${AssetFragmentDoc}`;
export function useAssetQuery(baseOptions: Apollo.QueryHookOptions<IAssetQuery, IAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssetQuery, IAssetQueryVariables>(AssetDocument, options);
      }
export function useAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetQuery, IAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssetQuery, IAssetQueryVariables>(AssetDocument, options);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = Apollo.QueryResult<IAssetQuery, IAssetQueryVariables>;
export const AssetVariantsDocument = gql`
    query AssetVariants($input: AssetInput!) {
  assetVariants(input: $input) {
    ...assetVariants
  }
}
    ${AssetVariantsFragmentDoc}`;
export function useAssetVariantsQuery(baseOptions: Apollo.QueryHookOptions<IAssetVariantsQuery, IAssetVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssetVariantsQuery, IAssetVariantsQueryVariables>(AssetVariantsDocument, options);
      }
export function useAssetVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetVariantsQuery, IAssetVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssetVariantsQuery, IAssetVariantsQueryVariables>(AssetVariantsDocument, options);
        }
export type AssetVariantsQueryHookResult = ReturnType<typeof useAssetVariantsQuery>;
export type AssetVariantsLazyQueryHookResult = ReturnType<typeof useAssetVariantsLazyQuery>;
export type AssetVariantsQueryResult = Apollo.QueryResult<IAssetVariantsQuery, IAssetVariantsQueryVariables>;
export const AssetsFlatFileDocument = gql`
    query AssetsFlatFile($input: AssetsFlatFileInput!) {
  assetsFlatFile(input: $input) {
    edges {
      cursor
      node {
        ...asset
      }
    }
    ...oldConnPageInfo
  }
}
    ${AssetFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useAssetsFlatFileQuery(baseOptions: Apollo.QueryHookOptions<IAssetsFlatFileQuery, IAssetsFlatFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssetsFlatFileQuery, IAssetsFlatFileQueryVariables>(AssetsFlatFileDocument, options);
      }
export function useAssetsFlatFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetsFlatFileQuery, IAssetsFlatFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssetsFlatFileQuery, IAssetsFlatFileQueryVariables>(AssetsFlatFileDocument, options);
        }
export type AssetsFlatFileQueryHookResult = ReturnType<typeof useAssetsFlatFileQuery>;
export type AssetsFlatFileLazyQueryHookResult = ReturnType<typeof useAssetsFlatFileLazyQuery>;
export type AssetsFlatFileQueryResult = Apollo.QueryResult<IAssetsFlatFileQuery, IAssetsFlatFileQueryVariables>;
export const AssetsGraphDocument = gql`
    query AssetsGraph($input: AssetsInput!) {
  assetsGraph(input: $input) {
    ...assetsGraph
  }
}
    ${AssetsGraphFragmentDoc}`;
export function useAssetsGraphQuery(baseOptions: Apollo.QueryHookOptions<IAssetsGraphQuery, IAssetsGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssetsGraphQuery, IAssetsGraphQueryVariables>(AssetsGraphDocument, options);
      }
export function useAssetsGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetsGraphQuery, IAssetsGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssetsGraphQuery, IAssetsGraphQueryVariables>(AssetsGraphDocument, options);
        }
export type AssetsGraphQueryHookResult = ReturnType<typeof useAssetsGraphQuery>;
export type AssetsGraphLazyQueryHookResult = ReturnType<typeof useAssetsGraphLazyQuery>;
export type AssetsGraphQueryResult = Apollo.QueryResult<IAssetsGraphQuery, IAssetsGraphQueryVariables>;
export const AssetsSearchDocument = gql`
    query AssetsSearch($input: AssetSearchInput!) {
  assetSearch(input: $input) {
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;
export function useAssetsSearchQuery(baseOptions: Apollo.QueryHookOptions<IAssetsSearchQuery, IAssetsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAssetsSearchQuery, IAssetsSearchQueryVariables>(AssetsSearchDocument, options);
      }
export function useAssetsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAssetsSearchQuery, IAssetsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAssetsSearchQuery, IAssetsSearchQueryVariables>(AssetsSearchDocument, options);
        }
export type AssetsSearchQueryHookResult = ReturnType<typeof useAssetsSearchQuery>;
export type AssetsSearchLazyQueryHookResult = ReturnType<typeof useAssetsSearchLazyQuery>;
export type AssetsSearchQueryResult = Apollo.QueryResult<IAssetsSearchQuery, IAssetsSearchQueryVariables>;
export const CostDataCategoriesDocument = gql`
    query CostDataCategories($input: LegacyCategoriesInput!) {
  legacyCategories(input: $input)
}
    `;
export function useCostDataCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ICostDataCategoriesQuery, ICostDataCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICostDataCategoriesQuery, ICostDataCategoriesQueryVariables>(CostDataCategoriesDocument, options);
      }
export function useCostDataCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICostDataCategoriesQuery, ICostDataCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICostDataCategoriesQuery, ICostDataCategoriesQueryVariables>(CostDataCategoriesDocument, options);
        }
export type CostDataCategoriesQueryHookResult = ReturnType<typeof useCostDataCategoriesQuery>;
export type CostDataCategoriesLazyQueryHookResult = ReturnType<typeof useCostDataCategoriesLazyQuery>;
export type CostDataCategoriesQueryResult = Apollo.QueryResult<ICostDataCategoriesQuery, ICostDataCategoriesQueryVariables>;
export const CostDataSourcesPreviewDocument = gql`
    query CostDataSourcesPreview($input: LegacySourcesInput!) {
  legacySources(input: $input) {
    pageInfo {
      hasNextPage
    }
    nodes {
      id
      name
      sourceType
    }
  }
}
    `;
export function useCostDataSourcesPreviewQuery(baseOptions: Apollo.QueryHookOptions<ICostDataSourcesPreviewQuery, ICostDataSourcesPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICostDataSourcesPreviewQuery, ICostDataSourcesPreviewQueryVariables>(CostDataSourcesPreviewDocument, options);
      }
export function useCostDataSourcesPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICostDataSourcesPreviewQuery, ICostDataSourcesPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICostDataSourcesPreviewQuery, ICostDataSourcesPreviewQueryVariables>(CostDataSourcesPreviewDocument, options);
        }
export type CostDataSourcesPreviewQueryHookResult = ReturnType<typeof useCostDataSourcesPreviewQuery>;
export type CostDataSourcesPreviewLazyQueryHookResult = ReturnType<typeof useCostDataSourcesPreviewLazyQuery>;
export type CostDataSourcesPreviewQueryResult = Apollo.QueryResult<ICostDataSourcesPreviewQuery, ICostDataSourcesPreviewQueryVariables>;
export const CostDataSourcesGetByIdDocument = gql`
    query CostDataSourcesGetById($input: SourceBatchInput!) {
  sourcesBatch(input: $input) {
    id
    sourceType
    name
    uom
    materialRateUsdCents
    laborRateUsdCents
    burdenedLaborRateUsdCents
    laborSourceId
    laborName
    productionRate
    calculatedUnitRateUsdCents
    nestedSources {
      id
      sourceType
      name
      uom
      materialRateUsdCents
      laborRateUsdCents
      burdenedLaborRateUsdCents
      productionRate
      calculatedUnitRateUsdCents
      quantity
      seqNum
    }
  }
}
    `;
export function useCostDataSourcesGetByIdQuery(baseOptions: Apollo.QueryHookOptions<ICostDataSourcesGetByIdQuery, ICostDataSourcesGetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICostDataSourcesGetByIdQuery, ICostDataSourcesGetByIdQueryVariables>(CostDataSourcesGetByIdDocument, options);
      }
export function useCostDataSourcesGetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICostDataSourcesGetByIdQuery, ICostDataSourcesGetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICostDataSourcesGetByIdQuery, ICostDataSourcesGetByIdQueryVariables>(CostDataSourcesGetByIdDocument, options);
        }
export type CostDataSourcesGetByIdQueryHookResult = ReturnType<typeof useCostDataSourcesGetByIdQuery>;
export type CostDataSourcesGetByIdLazyQueryHookResult = ReturnType<typeof useCostDataSourcesGetByIdLazyQuery>;
export type CostDataSourcesGetByIdQueryResult = Apollo.QueryResult<ICostDataSourcesGetByIdQuery, ICostDataSourcesGetByIdQueryVariables>;
export const CountiesDocument = gql`
    query Counties($input: CountiesInput) {
  counties(input: $input) {
    edges {
      cursor
      node {
        ...county
      }
    }
  }
}
    ${CountyFragmentDoc}`;
export function useCountiesQuery(baseOptions?: Apollo.QueryHookOptions<ICountiesQuery, ICountiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICountiesQuery, ICountiesQueryVariables>(CountiesDocument, options);
      }
export function useCountiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICountiesQuery, ICountiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICountiesQuery, ICountiesQueryVariables>(CountiesDocument, options);
        }
export type CountiesQueryHookResult = ReturnType<typeof useCountiesQuery>;
export type CountiesLazyQueryHookResult = ReturnType<typeof useCountiesLazyQuery>;
export type CountiesQueryResult = Apollo.QueryResult<ICountiesQuery, ICountiesQueryVariables>;
export const ElementsDocument = gql`
    query Elements($input: ElementsInput!) {
  elements(input: $input) {
    ...element
  }
}
    ${ElementFragmentDoc}`;
export function useElementsQuery(baseOptions: Apollo.QueryHookOptions<IElementsQuery, IElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IElementsQuery, IElementsQueryVariables>(ElementsDocument, options);
      }
export function useElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IElementsQuery, IElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IElementsQuery, IElementsQueryVariables>(ElementsDocument, options);
        }
export type ElementsQueryHookResult = ReturnType<typeof useElementsQuery>;
export type ElementsLazyQueryHookResult = ReturnType<typeof useElementsLazyQuery>;
export type ElementsQueryResult = Apollo.QueryResult<IElementsQuery, IElementsQueryVariables>;
export const LibraryElementsDocument = gql`
    query LibraryElements($input: LibraryElementsInput!) {
  libraryElements(input: $input) {
    ...element
  }
}
    ${ElementFragmentDoc}`;
export function useLibraryElementsQuery(baseOptions: Apollo.QueryHookOptions<ILibraryElementsQuery, ILibraryElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILibraryElementsQuery, ILibraryElementsQueryVariables>(LibraryElementsDocument, options);
      }
export function useLibraryElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILibraryElementsQuery, ILibraryElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILibraryElementsQuery, ILibraryElementsQueryVariables>(LibraryElementsDocument, options);
        }
export type LibraryElementsQueryHookResult = ReturnType<typeof useLibraryElementsQuery>;
export type LibraryElementsLazyQueryHookResult = ReturnType<typeof useLibraryElementsLazyQuery>;
export type LibraryElementsQueryResult = Apollo.QueryResult<ILibraryElementsQuery, ILibraryElementsQueryVariables>;
export const PdfDocument = gql`
    query Pdf($input: FileExportInput!) {
  pdf(input: $input) {
    blob
  }
}
    `;
export function usePdfQuery(baseOptions: Apollo.QueryHookOptions<IPdfQuery, IPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPdfQuery, IPdfQueryVariables>(PdfDocument, options);
      }
export function usePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPdfQuery, IPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPdfQuery, IPdfQueryVariables>(PdfDocument, options);
        }
export type PdfQueryHookResult = ReturnType<typeof usePdfQuery>;
export type PdfLazyQueryHookResult = ReturnType<typeof usePdfLazyQuery>;
export type PdfQueryResult = Apollo.QueryResult<IPdfQuery, IPdfQueryVariables>;
export const XlsxDocument = gql`
    query Xlsx($input: FileExportInput!) {
  xlsx(input: $input) {
    blob
  }
}
    `;
export function useXlsxQuery(baseOptions: Apollo.QueryHookOptions<IXlsxQuery, IXlsxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IXlsxQuery, IXlsxQueryVariables>(XlsxDocument, options);
      }
export function useXlsxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IXlsxQuery, IXlsxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IXlsxQuery, IXlsxQueryVariables>(XlsxDocument, options);
        }
export type XlsxQueryHookResult = ReturnType<typeof useXlsxQuery>;
export type XlsxLazyQueryHookResult = ReturnType<typeof useXlsxLazyQuery>;
export type XlsxQueryResult = Apollo.QueryResult<IXlsxQuery, IXlsxQueryVariables>;
export const MarkupDocument = gql`
    query Markup($input: MarkupInput!) {
  markup(input: $input) {
    ...markup
  }
}
    ${MarkupFragmentDoc}`;
export function useMarkupQuery(baseOptions: Apollo.QueryHookOptions<IMarkupQuery, IMarkupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMarkupQuery, IMarkupQueryVariables>(MarkupDocument, options);
      }
export function useMarkupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMarkupQuery, IMarkupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMarkupQuery, IMarkupQueryVariables>(MarkupDocument, options);
        }
export type MarkupQueryHookResult = ReturnType<typeof useMarkupQuery>;
export type MarkupLazyQueryHookResult = ReturnType<typeof useMarkupLazyQuery>;
export type MarkupQueryResult = Apollo.QueryResult<IMarkupQuery, IMarkupQueryVariables>;
export const MarkupGroupDocument = gql`
    query MarkupGroup($input: MarkupGroupInput!) {
  markupGroup(input: $input) {
    ...markupGroup
  }
}
    ${MarkupGroupFragmentDoc}`;
export function useMarkupGroupQuery(baseOptions: Apollo.QueryHookOptions<IMarkupGroupQuery, IMarkupGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMarkupGroupQuery, IMarkupGroupQueryVariables>(MarkupGroupDocument, options);
      }
export function useMarkupGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMarkupGroupQuery, IMarkupGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMarkupGroupQuery, IMarkupGroupQueryVariables>(MarkupGroupDocument, options);
        }
export type MarkupGroupQueryHookResult = ReturnType<typeof useMarkupGroupQuery>;
export type MarkupGroupLazyQueryHookResult = ReturnType<typeof useMarkupGroupLazyQuery>;
export type MarkupGroupQueryResult = Apollo.QueryResult<IMarkupGroupQuery, IMarkupGroupQueryVariables>;
export const MaterialSearchDocument = gql`
    query MaterialSearch($input: MaterialSearchInput!) {
  materialSearch(input: $input) {
    materials {
      ...materialLight
    }
    more
  }
}
    ${MaterialLightFragmentDoc}`;
export function useMaterialSearchQuery(baseOptions: Apollo.QueryHookOptions<IMaterialSearchQuery, IMaterialSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMaterialSearchQuery, IMaterialSearchQueryVariables>(MaterialSearchDocument, options);
      }
export function useMaterialSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMaterialSearchQuery, IMaterialSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMaterialSearchQuery, IMaterialSearchQueryVariables>(MaterialSearchDocument, options);
        }
export type MaterialSearchQueryHookResult = ReturnType<typeof useMaterialSearchQuery>;
export type MaterialSearchLazyQueryHookResult = ReturnType<typeof useMaterialSearchLazyQuery>;
export type MaterialSearchQueryResult = Apollo.QueryResult<IMaterialSearchQuery, IMaterialSearchQueryVariables>;
export const MaterialsByIDsDocument = gql`
    query MaterialsByIDs($input: MaterialsByIDsInput!) {
  materialsByIDs(input: $input) {
    ...material
  }
}
    ${MaterialFragmentDoc}`;
export function useMaterialsByIDsQuery(baseOptions: Apollo.QueryHookOptions<IMaterialsByIDsQuery, IMaterialsByIDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMaterialsByIDsQuery, IMaterialsByIDsQueryVariables>(MaterialsByIDsDocument, options);
      }
export function useMaterialsByIDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMaterialsByIDsQuery, IMaterialsByIDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMaterialsByIDsQuery, IMaterialsByIDsQueryVariables>(MaterialsByIDsDocument, options);
        }
export type MaterialsByIDsQueryHookResult = ReturnType<typeof useMaterialsByIDsQuery>;
export type MaterialsByIDsLazyQueryHookResult = ReturnType<typeof useMaterialsByIDsLazyQuery>;
export type MaterialsByIDsQueryResult = Apollo.QueryResult<IMaterialsByIDsQuery, IMaterialsByIDsQueryVariables>;
export const CustomerPortalLinkDocument = gql`
    query CustomerPortalLink($input: CustomerPortalLinkInput!) {
  customerPortalLink(input: $input)
}
    `;
export function useCustomerPortalLinkQuery(baseOptions: Apollo.QueryHookOptions<ICustomerPortalLinkQuery, ICustomerPortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICustomerPortalLinkQuery, ICustomerPortalLinkQueryVariables>(CustomerPortalLinkDocument, options);
      }
export function useCustomerPortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICustomerPortalLinkQuery, ICustomerPortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICustomerPortalLinkQuery, ICustomerPortalLinkQueryVariables>(CustomerPortalLinkDocument, options);
        }
export type CustomerPortalLinkQueryHookResult = ReturnType<typeof useCustomerPortalLinkQuery>;
export type CustomerPortalLinkLazyQueryHookResult = ReturnType<typeof useCustomerPortalLinkLazyQuery>;
export type CustomerPortalLinkQueryResult = Apollo.QueryResult<ICustomerPortalLinkQuery, ICustomerPortalLinkQueryVariables>;
export const SetupIntentDocument = gql`
    query SetupIntent {
  setupIntent {
    ...setupIntent
  }
}
    ${SetupIntentFragmentDoc}`;
export function useSetupIntentQuery(baseOptions?: Apollo.QueryHookOptions<ISetupIntentQuery, ISetupIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISetupIntentQuery, ISetupIntentQueryVariables>(SetupIntentDocument, options);
      }
export function useSetupIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISetupIntentQuery, ISetupIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISetupIntentQuery, ISetupIntentQueryVariables>(SetupIntentDocument, options);
        }
export type SetupIntentQueryHookResult = ReturnType<typeof useSetupIntentQuery>;
export type SetupIntentLazyQueryHookResult = ReturnType<typeof useSetupIntentLazyQuery>;
export type SetupIntentQueryResult = Apollo.QueryResult<ISetupIntentQuery, ISetupIntentQueryVariables>;
export const ProjectAreaByProjectIdDocument = gql`
    query ProjectAreaByProjectID($input: ProjectAreaInput!) {
  projectArea(input: $input) {
    ...projectArea
  }
}
    ${ProjectAreaFragmentDoc}`;
export function useProjectAreaByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<IProjectAreaByProjectIdQuery, IProjectAreaByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectAreaByProjectIdQuery, IProjectAreaByProjectIdQueryVariables>(ProjectAreaByProjectIdDocument, options);
      }
export function useProjectAreaByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectAreaByProjectIdQuery, IProjectAreaByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectAreaByProjectIdQuery, IProjectAreaByProjectIdQueryVariables>(ProjectAreaByProjectIdDocument, options);
        }
export type ProjectAreaByProjectIdQueryHookResult = ReturnType<typeof useProjectAreaByProjectIdQuery>;
export type ProjectAreaByProjectIdLazyQueryHookResult = ReturnType<typeof useProjectAreaByProjectIdLazyQuery>;
export type ProjectAreaByProjectIdQueryResult = Apollo.QueryResult<IProjectAreaByProjectIdQuery, IProjectAreaByProjectIdQueryVariables>;
export const EstimateLinkDocument = gql`
    query EstimateLink($input: EstimateLinkInput!) {
  estimateLink(input: $input) {
    link
  }
}
    `;
export function useEstimateLinkQuery(baseOptions: Apollo.QueryHookOptions<IEstimateLinkQuery, IEstimateLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEstimateLinkQuery, IEstimateLinkQueryVariables>(EstimateLinkDocument, options);
      }
export function useEstimateLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEstimateLinkQuery, IEstimateLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEstimateLinkQuery, IEstimateLinkQueryVariables>(EstimateLinkDocument, options);
        }
export type EstimateLinkQueryHookResult = ReturnType<typeof useEstimateLinkQuery>;
export type EstimateLinkLazyQueryHookResult = ReturnType<typeof useEstimateLinkLazyQuery>;
export type EstimateLinkQueryResult = Apollo.QueryResult<IEstimateLinkQuery, IEstimateLinkQueryVariables>;
export const OldProjectsDocument = gql`
    query OldProjects($input: ProjectsInput!) {
  projectsOld(input: $input) {
    edges {
      cursor
      node {
        ...projectLight
      }
    }
    ...oldConnPageInfo
  }
}
    ${ProjectLightFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useOldProjectsQuery(baseOptions: Apollo.QueryHookOptions<IOldProjectsQuery, IOldProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IOldProjectsQuery, IOldProjectsQueryVariables>(OldProjectsDocument, options);
      }
export function useOldProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOldProjectsQuery, IOldProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IOldProjectsQuery, IOldProjectsQueryVariables>(OldProjectsDocument, options);
        }
export type OldProjectsQueryHookResult = ReturnType<typeof useOldProjectsQuery>;
export type OldProjectsLazyQueryHookResult = ReturnType<typeof useOldProjectsLazyQuery>;
export type OldProjectsQueryResult = Apollo.QueryResult<IOldProjectsQuery, IOldProjectsQueryVariables>;
export const ProjectLocationByProjectIdDocument = gql`
    query ProjectLocationByProjectID($input: ProjectInput!) {
  projectOld(input: $input) {
    location {
      ...projectLocation
    }
  }
}
    ${ProjectLocationFragmentDoc}`;
export function useProjectLocationByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<IProjectLocationByProjectIdQuery, IProjectLocationByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectLocationByProjectIdQuery, IProjectLocationByProjectIdQueryVariables>(ProjectLocationByProjectIdDocument, options);
      }
export function useProjectLocationByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectLocationByProjectIdQuery, IProjectLocationByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectLocationByProjectIdQuery, IProjectLocationByProjectIdQueryVariables>(ProjectLocationByProjectIdDocument, options);
        }
export type ProjectLocationByProjectIdQueryHookResult = ReturnType<typeof useProjectLocationByProjectIdQuery>;
export type ProjectLocationByProjectIdLazyQueryHookResult = ReturnType<typeof useProjectLocationByProjectIdLazyQuery>;
export type ProjectLocationByProjectIdQueryResult = Apollo.QueryResult<IProjectLocationByProjectIdQuery, IProjectLocationByProjectIdQueryVariables>;
export const ProjectWithMarkupEntriesDocument = gql`
    query ProjectWithMarkupEntries($uuid: String!) {
  project(uuid: $uuid) {
    ...projectWithMarkupEntries
  }
}
    ${ProjectWithMarkupEntriesFragmentDoc}`;
export function useProjectWithMarkupEntriesQuery(baseOptions: Apollo.QueryHookOptions<IProjectWithMarkupEntriesQuery, IProjectWithMarkupEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectWithMarkupEntriesQuery, IProjectWithMarkupEntriesQueryVariables>(ProjectWithMarkupEntriesDocument, options);
      }
export function useProjectWithMarkupEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectWithMarkupEntriesQuery, IProjectWithMarkupEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectWithMarkupEntriesQuery, IProjectWithMarkupEntriesQueryVariables>(ProjectWithMarkupEntriesDocument, options);
        }
export type ProjectWithMarkupEntriesQueryHookResult = ReturnType<typeof useProjectWithMarkupEntriesQuery>;
export type ProjectWithMarkupEntriesLazyQueryHookResult = ReturnType<typeof useProjectWithMarkupEntriesLazyQuery>;
export type ProjectWithMarkupEntriesQueryResult = Apollo.QueryResult<IProjectWithMarkupEntriesQuery, IProjectWithMarkupEntriesQueryVariables>;
export const ProjectMarkupsByProjectIdDocument = gql`
    query ProjectMarkupsByProjectID($input: ProjectInput!) {
  projectOld(input: $input) {
    markups {
      edges {
        cursor
        node {
          ...projectMarkup
        }
      }
    }
  }
}
    ${ProjectMarkupFragmentDoc}`;
export function useProjectMarkupsByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<IProjectMarkupsByProjectIdQuery, IProjectMarkupsByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectMarkupsByProjectIdQuery, IProjectMarkupsByProjectIdQueryVariables>(ProjectMarkupsByProjectIdDocument, options);
      }
export function useProjectMarkupsByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectMarkupsByProjectIdQuery, IProjectMarkupsByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectMarkupsByProjectIdQuery, IProjectMarkupsByProjectIdQueryVariables>(ProjectMarkupsByProjectIdDocument, options);
        }
export type ProjectMarkupsByProjectIdQueryHookResult = ReturnType<typeof useProjectMarkupsByProjectIdQuery>;
export type ProjectMarkupsByProjectIdLazyQueryHookResult = ReturnType<typeof useProjectMarkupsByProjectIdLazyQuery>;
export type ProjectMarkupsByProjectIdQueryResult = Apollo.QueryResult<IProjectMarkupsByProjectIdQuery, IProjectMarkupsByProjectIdQueryVariables>;
export const ProjectsByTeamDocument = gql`
    query ProjectsByTeam($input: TeamInput!) {
  projectsByTeam(input: $input) {
    edges {
      node {
        id
        name
        isSaas
      }
    }
  }
}
    `;
export function useProjectsByTeamQuery(baseOptions: Apollo.QueryHookOptions<IProjectsByTeamQuery, IProjectsByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProjectsByTeamQuery, IProjectsByTeamQueryVariables>(ProjectsByTeamDocument, options);
      }
export function useProjectsByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProjectsByTeamQuery, IProjectsByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProjectsByTeamQuery, IProjectsByTeamQueryVariables>(ProjectsByTeamDocument, options);
        }
export type ProjectsByTeamQueryHookResult = ReturnType<typeof useProjectsByTeamQuery>;
export type ProjectsByTeamLazyQueryHookResult = ReturnType<typeof useProjectsByTeamLazyQuery>;
export type ProjectsByTeamQueryResult = Apollo.QueryResult<IProjectsByTeamQuery, IProjectsByTeamQueryVariables>;
export const ReviewTagsDocument = gql`
    query ReviewTags($input: ReviewTagInput) {
  reviewTags(input: $input) {
    edges {
      cursor
      node {
        ...reviewTag
      }
    }
  }
}
    ${ReviewTagFragmentDoc}`;
export function useReviewTagsQuery(baseOptions?: Apollo.QueryHookOptions<IReviewTagsQuery, IReviewTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IReviewTagsQuery, IReviewTagsQueryVariables>(ReviewTagsDocument, options);
      }
export function useReviewTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IReviewTagsQuery, IReviewTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IReviewTagsQuery, IReviewTagsQueryVariables>(ReviewTagsDocument, options);
        }
export type ReviewTagsQueryHookResult = ReturnType<typeof useReviewTagsQuery>;
export type ReviewTagsLazyQueryHookResult = ReturnType<typeof useReviewTagsLazyQuery>;
export type ReviewTagsQueryResult = Apollo.QueryResult<IReviewTagsQuery, IReviewTagsQueryVariables>;
export const ViewAnalyticsDocument = gql`
    query ViewAnalytics($input: ViewAnalyticsInput!) {
  viewAnalytics(input: $input) {
    views
    lastViewed
  }
}
    `;
export function useViewAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>(ViewAnalyticsDocument, options);
      }
export function useViewAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>(ViewAnalyticsDocument, options);
        }
export type ViewAnalyticsQueryHookResult = ReturnType<typeof useViewAnalyticsQuery>;
export type ViewAnalyticsLazyQueryHookResult = ReturnType<typeof useViewAnalyticsLazyQuery>;
export type ViewAnalyticsQueryResult = Apollo.QueryResult<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>;
export const SourceDocument = gql`
    query Source($input: SourceInput!) {
  source(input: $input) {
    ...source
  }
}
    ${SourceFragmentDoc}`;
export function useSourceQuery(baseOptions: Apollo.QueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, options);
      }
export function useSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, options);
        }
export type SourceQueryHookResult = ReturnType<typeof useSourceQuery>;
export type SourceLazyQueryHookResult = ReturnType<typeof useSourceLazyQuery>;
export type SourceQueryResult = Apollo.QueryResult<ISourceQuery, ISourceQueryVariables>;
export const SourceDescriptionDocument = gql`
    query SourceDescription($input: SourceDescriptionInput) {
  sourceDescription(input: $input) {
    name
    description
  }
}
    `;
export function useSourceDescriptionQuery(baseOptions?: Apollo.QueryHookOptions<ISourceDescriptionQuery, ISourceDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISourceDescriptionQuery, ISourceDescriptionQueryVariables>(SourceDescriptionDocument, options);
      }
export function useSourceDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISourceDescriptionQuery, ISourceDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISourceDescriptionQuery, ISourceDescriptionQueryVariables>(SourceDescriptionDocument, options);
        }
export type SourceDescriptionQueryHookResult = ReturnType<typeof useSourceDescriptionQuery>;
export type SourceDescriptionLazyQueryHookResult = ReturnType<typeof useSourceDescriptionLazyQuery>;
export type SourceDescriptionQueryResult = Apollo.QueryResult<ISourceDescriptionQuery, ISourceDescriptionQueryVariables>;
export const SourcesDocument = gql`
    query Sources($input: SourcesInput!) {
  sources(input: $input) {
    edges {
      cursor
      node {
        ...source
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${SourceFragmentDoc}
${PageInfoFragmentDoc}`;
export function useSourcesQuery(baseOptions: Apollo.QueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, options);
      }
export function useSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, options);
        }
export type SourcesQueryHookResult = ReturnType<typeof useSourcesQuery>;
export type SourcesLazyQueryHookResult = ReturnType<typeof useSourcesLazyQuery>;
export type SourcesQueryResult = Apollo.QueryResult<ISourcesQuery, ISourcesQueryVariables>;
export const StatesDocument = gql`
    query States($input: StatesConditon) {
  states(input: $input) {
    edges {
      cursor
      node {
        ...state
      }
    }
  }
}
    ${StateFragmentDoc}`;
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<IStatesQuery, IStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IStatesQuery, IStatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IStatesQuery, IStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IStatesQuery, IStatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<IStatesQuery, IStatesQueryVariables>;
export const TeamProjectInfoFilesDocument = gql`
    query TeamProjectInfoFiles($input: TeamFilesInput!) {
  teamProjectInfoFiles(input: $input) {
    edges {
      node {
        id
        filename
        uuid
        directory
      }
    }
  }
}
    `;
export function useTeamProjectInfoFilesQuery(baseOptions: Apollo.QueryHookOptions<ITeamProjectInfoFilesQuery, ITeamProjectInfoFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITeamProjectInfoFilesQuery, ITeamProjectInfoFilesQueryVariables>(TeamProjectInfoFilesDocument, options);
      }
export function useTeamProjectInfoFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITeamProjectInfoFilesQuery, ITeamProjectInfoFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITeamProjectInfoFilesQuery, ITeamProjectInfoFilesQueryVariables>(TeamProjectInfoFilesDocument, options);
        }
export type TeamProjectInfoFilesQueryHookResult = ReturnType<typeof useTeamProjectInfoFilesQuery>;
export type TeamProjectInfoFilesLazyQueryHookResult = ReturnType<typeof useTeamProjectInfoFilesLazyQuery>;
export type TeamProjectInfoFilesQueryResult = Apollo.QueryResult<ITeamProjectInfoFilesQuery, ITeamProjectInfoFilesQueryVariables>;
export const TeamBillingDocument = gql`
    query TeamBilling($input: TeamInput) {
  team(input: $input) {
    ...teamBilling
  }
}
    ${TeamBillingFragmentDoc}`;
export function useTeamBillingQuery(baseOptions?: Apollo.QueryHookOptions<ITeamBillingQuery, ITeamBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITeamBillingQuery, ITeamBillingQueryVariables>(TeamBillingDocument, options);
      }
export function useTeamBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITeamBillingQuery, ITeamBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITeamBillingQuery, ITeamBillingQueryVariables>(TeamBillingDocument, options);
        }
export type TeamBillingQueryHookResult = ReturnType<typeof useTeamBillingQuery>;
export type TeamBillingLazyQueryHookResult = ReturnType<typeof useTeamBillingLazyQuery>;
export type TeamBillingQueryResult = Apollo.QueryResult<ITeamBillingQuery, ITeamBillingQueryVariables>;
export const TeamsPendingOnboardingDocument = gql`
    query TeamsPendingOnboarding {
  teamsPendingOnboarding {
    edges {
      cursor
      node {
        name
        leadUser {
          ...userLight
        }
        onboarding {
          ...teamOnboarding
        }
      }
    }
  }
}
    ${UserLightFragmentDoc}
${TeamOnboardingFragmentDoc}`;
export function useTeamsPendingOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<ITeamsPendingOnboardingQuery, ITeamsPendingOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITeamsPendingOnboardingQuery, ITeamsPendingOnboardingQueryVariables>(TeamsPendingOnboardingDocument, options);
      }
export function useTeamsPendingOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITeamsPendingOnboardingQuery, ITeamsPendingOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITeamsPendingOnboardingQuery, ITeamsPendingOnboardingQueryVariables>(TeamsPendingOnboardingDocument, options);
        }
export type TeamsPendingOnboardingQueryHookResult = ReturnType<typeof useTeamsPendingOnboardingQuery>;
export type TeamsPendingOnboardingLazyQueryHookResult = ReturnType<typeof useTeamsPendingOnboardingLazyQuery>;
export type TeamsPendingOnboardingQueryResult = Apollo.QueryResult<ITeamsPendingOnboardingQuery, ITeamsPendingOnboardingQueryVariables>;
export const TeamDocument = gql`
    query Team($input: TeamInput) {
  team(input: $input) {
    ...team
  }
}
    ${TeamFragmentDoc}`;
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<ITeamQuery, ITeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITeamQuery, ITeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITeamQuery, ITeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITeamQuery, ITeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<ITeamQuery, ITeamQueryVariables>;
export const EstimatorsForProjectDocument = gql`
    query EstimatorsForProject($input: EstimatorsProjectInput!) {
  estimatorsForProject(input: $input) {
    edges {
      cursor
      node {
        ...estimatorLight
      }
    }
  }
}
    ${EstimatorLightFragmentDoc}`;
export function useEstimatorsForProjectQuery(baseOptions: Apollo.QueryHookOptions<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables>(EstimatorsForProjectDocument, options);
      }
export function useEstimatorsForProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables>(EstimatorsForProjectDocument, options);
        }
export type EstimatorsForProjectQueryHookResult = ReturnType<typeof useEstimatorsForProjectQuery>;
export type EstimatorsForProjectLazyQueryHookResult = ReturnType<typeof useEstimatorsForProjectLazyQuery>;
export type EstimatorsForProjectQueryResult = Apollo.QueryResult<IEstimatorsForProjectQuery, IEstimatorsForProjectQueryVariables>;
export const EstimatorsLiteDocument = gql`
    query EstimatorsLite($input: UsersInput) {
  users(input: $input) {
    edges {
      cursor
      node {
        ...estimatorLight
      }
    }
    ...oldConnPageInfo
  }
}
    ${EstimatorLightFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useEstimatorsLiteQuery(baseOptions?: Apollo.QueryHookOptions<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables>(EstimatorsLiteDocument, options);
      }
export function useEstimatorsLiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables>(EstimatorsLiteDocument, options);
        }
export type EstimatorsLiteQueryHookResult = ReturnType<typeof useEstimatorsLiteQuery>;
export type EstimatorsLiteLazyQueryHookResult = ReturnType<typeof useEstimatorsLiteLazyQuery>;
export type EstimatorsLiteQueryResult = Apollo.QueryResult<IEstimatorsLiteQuery, IEstimatorsLiteQueryVariables>;
export const PreferredByClientDocument = gql`
    query PreferredByClient($input: PreferredByClientInput) {
  preferredByClient(input: $input) {
    id
    teamID
    preference
  }
}
    `;
export function usePreferredByClientQuery(baseOptions?: Apollo.QueryHookOptions<IPreferredByClientQuery, IPreferredByClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPreferredByClientQuery, IPreferredByClientQueryVariables>(PreferredByClientDocument, options);
      }
export function usePreferredByClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPreferredByClientQuery, IPreferredByClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPreferredByClientQuery, IPreferredByClientQueryVariables>(PreferredByClientDocument, options);
        }
export type PreferredByClientQueryHookResult = ReturnType<typeof usePreferredByClientQuery>;
export type PreferredByClientLazyQueryHookResult = ReturnType<typeof usePreferredByClientLazyQuery>;
export type PreferredByClientQueryResult = Apollo.QueryResult<IPreferredByClientQuery, IPreferredByClientQueryVariables>;
export const UserDocument = gql`
    query User($input: UserInput!) {
  user(input: $input) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<IUserQuery, IUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserQuery, IUserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserQuery, IUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserQuery, IUserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<IUserQuery, IUserQueryVariables>;
export const UserTeamByIdDocument = gql`
    query UserTeamByID($input: UserInput!) {
  user(input: $input) {
    team {
      uuid
      subscription {
        ...stripeSubscription
      }
    }
  }
}
    ${StripeSubscriptionFragmentDoc}`;
export function useUserTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<IUserTeamByIdQuery, IUserTeamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserTeamByIdQuery, IUserTeamByIdQueryVariables>(UserTeamByIdDocument, options);
      }
export function useUserTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserTeamByIdQuery, IUserTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserTeamByIdQuery, IUserTeamByIdQueryVariables>(UserTeamByIdDocument, options);
        }
export type UserTeamByIdQueryHookResult = ReturnType<typeof useUserTeamByIdQuery>;
export type UserTeamByIdLazyQueryHookResult = ReturnType<typeof useUserTeamByIdLazyQuery>;
export type UserTeamByIdQueryResult = Apollo.QueryResult<IUserTeamByIdQuery, IUserTeamByIdQueryVariables>;
export const UsersDocument = gql`
    query Users($input: UsersInput) {
  users(input: $input) {
    edges {
      cursor
      node {
        ...user
      }
    }
    ...oldConnPageInfo
  }
}
    ${UserFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<IUsersQuery, IUsersQueryVariables>;
export const UsersLiteDocument = gql`
    query UsersLite($input: UsersInput) {
  users(input: $input) {
    edges {
      cursor
      node {
        ...userLight
      }
    }
    ...oldConnPageInfo
  }
}
    ${UserLightFragmentDoc}
${OldConnPageInfoFragmentDoc}`;
export function useUsersLiteQuery(baseOptions?: Apollo.QueryHookOptions<IUsersLiteQuery, IUsersLiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersLiteQuery, IUsersLiteQueryVariables>(UsersLiteDocument, options);
      }
export function useUsersLiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersLiteQuery, IUsersLiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersLiteQuery, IUsersLiteQueryVariables>(UsersLiteDocument, options);
        }
export type UsersLiteQueryHookResult = ReturnType<typeof useUsersLiteQuery>;
export type UsersLiteLazyQueryHookResult = ReturnType<typeof useUsersLiteLazyQuery>;
export type UsersLiteQueryResult = Apollo.QueryResult<IUsersLiteQuery, IUsersLiteQueryVariables>;
export const UsersSearchDocument = gql`
    query UsersSearch($input: UsersSearchInput!) {
  usersSearch(input: $input) {
    edges {
      cursor
      node {
        ...userLight
      }
    }
  }
}
    ${UserLightFragmentDoc}`;
export function useUsersSearchQuery(baseOptions: Apollo.QueryHookOptions<IUsersSearchQuery, IUsersSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersSearchQuery, IUsersSearchQueryVariables>(UsersSearchDocument, options);
      }
export function useUsersSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersSearchQuery, IUsersSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersSearchQuery, IUsersSearchQueryVariables>(UsersSearchDocument, options);
        }
export type UsersSearchQueryHookResult = ReturnType<typeof useUsersSearchQuery>;
export type UsersSearchLazyQueryHookResult = ReturnType<typeof useUsersSearchLazyQuery>;
export type UsersSearchQueryResult = Apollo.QueryResult<IUsersSearchQuery, IUsersSearchQueryVariables>;
export const UsersSimpleDocument = gql`
    query UsersSimple($input: UsersInputSimple!) {
  usersSimple(input: $input) {
    more
    users {
      ...userLight
    }
  }
}
    ${UserLightFragmentDoc}`;
export function useUsersSimpleQuery(baseOptions: Apollo.QueryHookOptions<IUsersSimpleQuery, IUsersSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersSimpleQuery, IUsersSimpleQueryVariables>(UsersSimpleDocument, options);
      }
export function useUsersSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersSimpleQuery, IUsersSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersSimpleQuery, IUsersSimpleQueryVariables>(UsersSimpleDocument, options);
        }
export type UsersSimpleQueryHookResult = ReturnType<typeof useUsersSimpleQuery>;
export type UsersSimpleLazyQueryHookResult = ReturnType<typeof useUsersSimpleLazyQuery>;
export type UsersSimpleQueryResult = Apollo.QueryResult<IUsersSimpleQuery, IUsersSimpleQueryVariables>;
export const AssemblyReceivedDocument = gql`
    subscription AssemblyReceived($input: AssemblyReceivedInput!) {
  assemblyReceived(input: $input) {
    assembly {
      id
      description
      unit {
        id
        unitType
        name
      }
      elements {
        edges {
          node {
            id
            uuid
            name
            previousElement
            pendingLocalizedCostData
            materialRate
            laborRate
            localizedLaborRate
            productionRate
            equipmentRate
            materialID
            material {
              id
              name
              ownerID
              takeoffUnitID
              createdAt
              updatedAt
            }
            formulaID
            formula {
              id
              uuid
              expression
            }
            unit {
              id
              name
              description
              definition
              unitType
              createdAt
              updatedAt
            }
            variables {
              edges {
                cursor
                node {
                  id
                  uuid
                  formulaID
                  measuredQuantityID
                  measuredQuantity {
                    id
                    value
                    unitID
                    unit {
                      id
                      name
                      description
                      definition
                      unitType
                      createdAt
                      updatedAt
                    }
                  }
                  name
                  type
                  createdAt
                  updatedAt
                }
              }
            }
          }
        }
      }
      trade {
        id
        name
        nickname
        isLinked
      }
      favorited
      usedInProject
      projectID
    }
    actionType
  }
}
    `;
export function useAssemblyReceivedSubscription(baseOptions: Apollo.SubscriptionHookOptions<IAssemblyReceivedSubscription, IAssemblyReceivedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IAssemblyReceivedSubscription, IAssemblyReceivedSubscriptionVariables>(AssemblyReceivedDocument, options);
      }
export type AssemblyReceivedSubscriptionHookResult = ReturnType<typeof useAssemblyReceivedSubscription>;
export type AssemblyReceivedSubscriptionResult = Apollo.SubscriptionResult<IAssemblyReceivedSubscription>;
export const AssetExportsRecievedDocument = gql`
    subscription AssetExportsRecieved($input: AssetExportRecievedInput!) {
  assetExportRecieved(input: $input) {
    id
    url
  }
}
    `;
export function useAssetExportsRecievedSubscription(baseOptions: Apollo.SubscriptionHookOptions<IAssetExportsRecievedSubscription, IAssetExportsRecievedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IAssetExportsRecievedSubscription, IAssetExportsRecievedSubscriptionVariables>(AssetExportsRecievedDocument, options);
      }
export type AssetExportsRecievedSubscriptionHookResult = ReturnType<typeof useAssetExportsRecievedSubscription>;
export type AssetExportsRecievedSubscriptionResult = Apollo.SubscriptionResult<IAssetExportsRecievedSubscription>;
export const AssetRecievedDocument = gql`
    subscription AssetRecieved($input: AssetRecievedInput!) {
  assetReceived(input: $input) {
    ...asset
  }
}
    ${AssetFragmentDoc}`;
export function useAssetRecievedSubscription(baseOptions: Apollo.SubscriptionHookOptions<IAssetRecievedSubscription, IAssetRecievedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<IAssetRecievedSubscription, IAssetRecievedSubscriptionVariables>(AssetRecievedDocument, options);
      }
export type AssetRecievedSubscriptionHookResult = ReturnType<typeof useAssetRecievedSubscription>;
export type AssetRecievedSubscriptionResult = Apollo.SubscriptionResult<IAssetRecievedSubscription>;