import { Cell, CellType } from 'leyden';
import { createContext, useContext } from 'react';

export interface ContextMenuData {
    cell: Cell<CellType>;
    position: {
        x: number;
        y: number;
    };
}

export interface ContextMenuContext {
    data: ContextMenuData | null;
    close: () => void;
    open: (data: ContextMenuData) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const ContextMenuContext = createContext<ContextMenuContext>({
    data: null,
    close: () => {},
    open: () => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

export const useContextMenu = (): ContextMenuContext => {
    return useContext(ContextMenuContext);
};
