export type Country = {
    name: string;
    code: string;
};

export const COUNTRY_CODE = {
    US: 'US',
    CA: 'CA',
};

export const countries: Country[] = [
    { name: 'United States', code: COUNTRY_CODE.US },
    { name: 'Canada', code: COUNTRY_CODE.CA },
];
