import {
    formatPhoneNumber,
    getBase64Image,
    readFile,
    trim,
    trimLeft,
} from '@/common/utils/helpers';
import { currentTimezoneIndex, timezoneNames } from '@/common/utils/moment';
import { isValidPhoneNumber } from '@/common/utils/validators';
import { Availability } from '@/components/ui/controls/Availability';
import { Modal } from '@/components/ui/controls/Modal';
import { Dropdown } from '@/components/ui/inputs/Dropdown';
import { Input } from '@/components/ui/inputs/Input';
import { NativeDatepicker } from '@/components/ui/inputs/NativeDatepicker';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import {
    ICommitmentStatus,
    IScheduleFragment,
    ITeamFragment,
    IUserRole,
    IWeekday,
} from '@/graphql';
import { LoadingPage } from '../LoadingPage';
import './Profile.scss';
import { ReactComponent as RemoveLogo } from '@/assets/icons/close-cross-round.svg';
import { ReactComponent as WarningLogo } from '@/assets/icons/exclamation.svg';
import { ReactComponent as ReplaceLogo } from '@/assets/icons/upload-up.svg';
import { COUNTRY_CODE, countries } from '@/common/countries';
import { PROVINCES } from '@/common/provinces';
import { STATES } from '@/common/states';
import { CommitmentType } from '@/common/types';
import { Button } from '@/components/ui/buttons/Button';
import { ImageCropperModal } from '@/components/ui/modals/ImageCropperModal';
import { useNotifications } from '@/contexts/Notifications';
import MuiButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { colorStatusError } from '@/variables';
import { useStorage } from '@/contexts/Storage';
import { Env } from '@/common/env';
import { CancelTrialModal } from '../../components/Profile/CancelTrialModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isAnyTrial, isBuilderUser, isExpiredTrial } from '@/views/Projects/utils';
import Link from '@mui/material/Link';

export const COPY = {
    trialDelete: 'Cancel Trial',
    profile_submit: 'Submit',
    profile_details_first_name_placeholder: 'First name',
    profile_details_last_name_placeholder: 'Last name',
    profile_details_phone_placeholder: '(555)-555-5555',
    profile_details_timezone_placeholder: 'Choose timezone',
    profile_details_company_name_placeholder: 'Company name',
    profile_details_country_placeholder: 'Choose one',
    profile_details_address_placeholder: 'Enter address',
    profile_details_city_placeholder: 'Enter city',
    profile_details_postal_code_placeholder: 'Enter postal code',
    profile_details_zip_code_placeholder: 'Enter zip code',
    profile_details_logo_placeholder: `This logo will appear on estimate links and downloads.
    Please upload a JPG or PNG file (under 1MB).`,
    profile_details_title: 'Personal details',
    profile_details_save: 'Save',
    profile_details_name_label: 'Name',
    profile_details_company_label: 'Company',
    profile_details_phone_label: 'Phone',
    profile_details_email_label: 'Email',
    profile_details_timezone_label: 'Timezone',
    profile_details_country_label: 'Country',
    profile_details_address_label: 'Address',
    profile_details_state_label: 'City/State',
    profile_details_province_label: 'City/Province',
    profile_details_postal_code_label: 'Postal Code',
    profile_details_zip_code_label: 'Zip Code',
    profile_details_logo_label: 'Logo',
    profile_details_change_email: 'Change',
    profile_details_save_email: 'Save',
    profile_details_change_email_confirmation: 'We’ve sent an email to your ' + 'inbox to verify',
    profile_details_technical_information: 'Technical information',
    profile_details_technical_content:
        'The information below may be useful ' +
        'to technical support should they need to ' +
        'diagnose any problems with your account.',
    profile_details_technical_email: 'Email:',
    profile_details_technical_user_id: 'User id:',
    profile_details_technical_team_id: 'Team id:',
    profile_details_technical_user_auth_id: 'User auth id:',
    profile_details_technical_roles: 'User roles:',
    profile_details_technical_version: 'Version:',
    profile_details_technical_user_agent: 'User Agent:',
    profile_details_availability_label: 'Availability',
    profile_details_availability_description:
        'Select which days are you available to work on projects',
    profile_details_availability_required: 'Availability is required',
    profile_details_timeOff_label: 'Time off dates',
    profile_details_date_from_label: 'From',
    profile_details_date_to_label: 'To',
    profile_details_add_dates: 'Add dates',
    profile_details_more_dates: 'More dates',
    profile_details_select_date_placeholder: 'Select date',
    profile_details_file_size_limit_exceeded: 'Maximum file size exceeded. ',
    profile_details_file_size_limit: 'Images should be less than 1MB.',
    profile_details_file_type_not_supported: 'File extension not supported.',
    notification_error_title: 'Something went wrong',
    notification_success_title: 'Success',
    notification_success_message: 'Successfully updated the user',
    profile_details_builder_profile: 'Builder Profile',
};

const DEFAULT_DAYS: Record<IWeekday, boolean> = {
    [IWeekday.Monday]: false,
    [IWeekday.Tuesday]: false,
    [IWeekday.Wednesday]: false,
    [IWeekday.Thursday]: false,
    [IWeekday.Friday]: false,
    [IWeekday.Saturday]: false,
    [IWeekday.Sunday]: false,
};

// Until we can update the email on Auth0, this is set to false
const showEmail = false;

/**
 * Wrap ProfileContents so we can insure user and userQueryResults have been set
 * or errored out.
 */
export const Profile: FC = () => {
    // TODO: replace this when sign ups are done
    const {
        data: { user },
        loading: { loadingUser },
    } = useUser();

    if (
        loadingUser ||
        !(user && user.id && user.roles.length > 0) ||
        (user.roles.includes(IUserRole.Builder) && !user.team)
    ) {
        return <LoadingPage />;
    }

    return <ProfileContent />;
};

type ProfileContentProps = {
    hasOnboardingID?: boolean;
};

const ProfileContent: FC<ProfileContentProps> = () => {
    const {
        data: { user },
        actions: { setUser, editProfile, editEstimatorProfile, refetchUser },
        validations: { isUserValid },
        errors: { errorEditingProfile },
        loading: { loadingProfileUpdate },
    } = useUser();
    const {
        features: { shareV2 },
    } = useFeatures();
    const { addNotification } = useNotifications();
    const history = useHistory();
    const { bucket } = useStorage();
    const flags = useFlags();

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [company, setCompany] = useState(user.team?.name || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [email, setEmail] = useState(user.email || '');
    const [address, setAddress] = useState(user.address || '');
    const [cityMunicipality, setCityMunicipality] = useState(user.city || '');
    const [postalCode, setPostalCode] = useState(user.postalCode || '');
    const [unavailableDates, setUnavailableDates] = useState<CommitmentType[]>(
        user.schedule?.unavailable || []
    );
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [timezoneIndex, setTimezoneIndex] = useState<number>(0);
    const [country, setCountry] = useState<number>(0);
    const [stateProvince, setStateProvince] = useState<number>(0);
    const [availability, setAvailability] = useState<Record<IWeekday, boolean>>(DEFAULT_DAYS);
    const [isCropperVisible, setIsCropperVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState<ArrayBuffer | string | null>(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [logo, setLogo] = useState<ArrayBuffer | string | null | unknown>(user.logoLink || null);
    const [fitToDefaultSize, setFitToDefaultSize] = useState(true);

    const maxFileSize = 1024 * 4;
    const allowedFileExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const [isFileSizeLimitExceeded, setIsFileSizeLimitExceeded] = useState(false);
    const [isUnsupportedFileExtension, setIsUnsupportedFileExtension] = useState(false);
    const [isCancelTrialModalOpen, setIsCancelTrialModalOpen] = useState(false);

    const uploadImageRef = useRef<HTMLInputElement>(null);
    const replaceImageRef = useRef<HTMLInputElement>(null);

    const isAvailabilitySet = Object.values(availability).some(Boolean);

    const isFormDirty =
        firstName !== '' || lastName !== '' || company !== '' || phone !== '' || isAvailabilitySet;
    const isEstimator = user.roles?.includes(IUserRole.Estimator);
    const isBuilder = user.roles?.includes(IUserRole.Builder);

    const countryOptions = ['Select country'].concat(countries.map((item) => item.name));
    const mapCountry = (): string => (country === 0 ? '' : countryOptions[country]);
    const isUSSelected =
        countries.findIndex(
            (item) => countryOptions[country] === item.name && item.code === COUNTRY_CODE.US
        ) >= 0
            ? true
            : false;

    const stateOptions = ['Select state'].concat(STATES.map((item) => item.name));
    const provinceOptions = ['Select province'].concat(PROVINCES.map((item) => item.name));

    const getAvailabilityDays = (): Record<IWeekday, boolean> => {
        if (user.schedule && user.schedule.availability) {
            const mutatedDefaultDays = { ...DEFAULT_DAYS };

            user.schedule.availability.map((item) => {
                const itemExists = Object.keys(DEFAULT_DAYS).some((key) => key === item);

                if (itemExists) {
                    mutatedDefaultDays[item] = true;
                }
            });

            return mutatedDefaultDays;
        } else {
            return DEFAULT_DAYS;
        }
    };

    const getTimezoneIndex = (): number => {
        if (user.timezone !== '') {
            const tempIndex = timezoneNames.findIndex((item) => item === user.timezone);

            return tempIndex;
        } else {
            return currentTimezoneIndex;
        }
    };

    const getCountry = (): number => {
        if (user.country) {
            const elementIndex = countryOptions.findIndex((item) => item === user.country);

            return elementIndex;
        }

        return 0;
    };

    const mapStateProvince = (): string => {
        if (stateProvince === 0) return '';

        return isUSSelected ? stateOptions[stateProvince] : provinceOptions[stateProvince];
    };

    const getStateProvince = (): number => {
        if (user.state) {
            let elementIndex = stateOptions.findIndex((item) => item === user.state);

            if (elementIndex < 0) {
                elementIndex = provinceOptions.findIndex((item) => item === user.state);
            }

            return elementIndex;
        }

        return 0;
    };

    const setAvailabilityDays = (): IWeekday[] => {
        const availabileDays = Object.keys(availability).filter((key) => {
            return availability[key as IWeekday] === true;
        });

        return availabileDays as IWeekday[];
    };

    const mappedUnavailableDates: CommitmentType[] = unavailableDates.map(
        (unavailableDate: CommitmentType) => {
            return {
                id: unavailableDate.id,
                from: String(unavailableDate.from),
                to: String(unavailableDate.to),
                status: unavailableDate.status,
                deleted: unavailableDate?.deleted,
            } as CommitmentType;
        }
    );

    useEffect(() => {
        setTimezoneIndex(getTimezoneIndex());
        setCountry(getCountry());
        setStateProvince(getStateProvince());
        setAvailability(getAvailabilityDays());
    }, []);

    useEffect(() => {
        (async () => {
            if (!user.logoLink) return;

            const url = getPublicLogoLink(user.logoLink);
            const image = await getBase64Image(url, undefined, true);
            setLogo(image);
        })();
    }, []);

    useEffect(() => {
        let currentUserData = {
            ...user,
            firstName,
            lastName,
            phone,
        };

        if (isBuilder) {
            currentUserData = {
                ...currentUserData,
                address: address,
                state: mapStateProvince(),
                postalCode: postalCode,
                country: mapCountry(),
                city: cityMunicipality,
                logoLink: !logo ? null : String(logo),
                team: {
                    ...(user.team as ITeamFragment),
                    id: user.team?.id as string,
                    name: company,
                },
            };
        } else {
            currentUserData = {
                ...currentUserData,
                timezone: timezoneNames[timezoneIndex],
                schedule: {
                    ...(user.schedule as IScheduleFragment),
                    unavailable: mappedUnavailableDates.filter(
                        (unavailableDate: CommitmentType) =>
                            unavailableDate.to !== '' && unavailableDate.from !== ''
                    ),
                    availability: setAvailabilityDays(),
                },
            };
        }

        setUser(currentUserData);
    }, [
        firstName,
        lastName,
        phone,
        company,
        timezoneIndex,
        availability,
        unavailableDates,
        address,
        stateProvince,
        postalCode,
        cityMunicipality,
        country,
        logo,
    ]);

    const openModal = (): void => setIsModalVisible(true);

    const closeModal = (): void => setIsModalVisible(false);

    const openCropperModal = (): void => setIsCropperVisible(true);

    const closeCropperModal = (): void => {
        setIsCropperVisible(false);
        setImageSrc(null);
    };

    const handleChangePhone = (value: string): void => {
        const strippedPhoneNumber = value.replace(/[^\d]/, '').replace(/\D/g, '');

        if (strippedPhoneNumber.length <= 10) {
            setPhone(formatPhoneNumber(value));
        }
    };

    const handleTimezoneChange = (index: number): void => {
        setTimezoneIndex(index);
    };

    const handleCountryChange = (index: number): void => {
        setCountry(index);
        setStateProvince(0);
    };

    const handleStateProvinceChange = (index: number): void => {
        setStateProvince(index);
    };

    const handleUserSubmit = async (): Promise<void> => {
        if (!errorEditingProfile) {
            try {
                if (isEstimator && !isBuilder) {
                    await editEstimatorProfile();
                } else {
                    await editProfile();
                    refetchUser();
                }

                history.push('/projects');

                addNotification(
                    {
                        title: COPY.notification_success_title,
                        content: <p>{COPY.notification_success_message}</p>,
                    },
                    'success'
                );
            } catch (e) {
                if (e instanceof Error) {
                    addNotification(
                        {
                            title: COPY.notification_error_title,
                            content: <p>{e.message ?? 'Error'}</p>,
                        },
                        'error'
                    );
                }
            }
        } else {
            addNotification(
                {
                    title: COPY.notification_error_title,
                    content: <p>{errorEditingProfile}</p>,
                },
                'error'
            );
        }
    };

    const handleDateFrom = (value: string, index: number): void => {
        if (mappedUnavailableDates.length > index) {
            mappedUnavailableDates[index].from = value.concat('T00:00:00Z');
            setUnavailableDates([...mappedUnavailableDates]);
        }
    };

    const handleDateTo = (value: string, index: number): void => {
        if (mappedUnavailableDates.length > index) {
            mappedUnavailableDates[index].to = value.concat('T00:00:00Z');
            setUnavailableDates([...mappedUnavailableDates]);
        }
    };

    const addMoreDates = (): void => {
        mappedUnavailableDates.push({
            id: uuid(),
            from: '',
            to: '',
            status: ICommitmentStatus.Unavailable,
            deleted: false,
        } as CommitmentType);
        setUnavailableDates([...mappedUnavailableDates] as CommitmentType[]);
    };

    const removeDate = (index: number): void => {
        if (mappedUnavailableDates.length > index) {
            mappedUnavailableDates[index].deleted = true;
            setUnavailableDates([...mappedUnavailableDates] as CommitmentType[]);
        }
    };

    const onUploadClick = (): void => {
        uploadImageRef.current?.click();
    };

    const onReplaceClick = (): void => {
        replaceImageRef.current?.click();
    };

    const resetFileUploadValidation = (): void => {
        setIsUnsupportedFileExtension(false);
        setIsFileSizeLimitExceeded(false);
    };

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files, value } = e.target;
        resetFileUploadValidation(); // set file upload validation to default state

        if (files && files.length > 0) {
            const file = files[0];
            const size = file.size / 1024;

            if (!allowedFileExtensions.exec(value)) {
                setIsUnsupportedFileExtension(true);
                return;
            }

            if (size > maxFileSize) {
                setIsFileSizeLimitExceeded(true);
                return;
            }

            const imageDataUrl = await readFile(file);

            setImageSrc(imageDataUrl);
            openCropperModal();
            e.target.value = ''; // reset input type so it can be called multiple times
        }
    };

    /* eslint-disable*/ // Because croppedArea is first parameter but we don't use it
    const onCropComplete = async (croppedArea: Area, croppedPixels: Area): Promise<void> => {
        setCroppedAreaPixels(croppedPixels);
    }; /* eslint-enable*/

    const onConfirmCrop = async (): Promise<void> => {
        if (croppedAreaPixels) {
            const cropped = await getBase64Image(
                imageSrc as string,
                croppedAreaPixels,
                fitToDefaultSize
            );
            setLogo(cropped);
        }

        closeCropperModal();
    };

    const getPublicLogoLink = (fileName: string): string => {
        if (!fileName) return '';

        return `https://${bucket}.s3.${Env.awsPlanBucketRegion}.amazonaws.com/${fileName}`;
    };

    const renderNameFields = (): JSX.Element => (
        <div className="form-row" key="name">
            <span className="label">{COPY.profile_details_name_label}</span>
            <div className="multiInputRow">
                <Input
                    name="first_name"
                    value={firstName}
                    className={clsx({ invalid: isFormDirty && firstName === '' })}
                    placeholder={COPY.profile_details_first_name_placeholder}
                    onChange={trimLeft(setFirstName)}
                />
                <Input
                    name="last_name"
                    value={lastName}
                    className={clsx({ invalid: isFormDirty && lastName === '' })}
                    placeholder={COPY.profile_details_last_name_placeholder}
                    onChange={trimLeft(setLastName)}
                />
            </div>
        </div>
    );

    const renderCompanyField = (): JSX.Element => (
        <div className="form-row" key="company">
            <span className="label">{COPY.profile_details_company_label}</span>
            <Input
                name="company"
                value={company}
                onChange={trimLeft(setCompany)}
                className={clsx({ invalid: isFormDirty && company === '' })}
                placeholder={COPY.profile_details_company_name_placeholder}
            />
        </div>
    );

    const renderPhoneField = (): JSX.Element => (
        <div className="form-row" key="phone">
            <span className="label">{COPY.profile_details_phone_label}</span>
            <Input
                name="phone"
                value={phone}
                onChange={trimLeft(handleChangePhone)}
                className={clsx({ invalid: isFormDirty && !isValidPhoneNumber(phone) })}
                placeholder={COPY.profile_details_phone_placeholder}
            />
        </div>
    );

    const renderEmailField = (): JSX.Element => (
        <div className="form-row" key="email">
            <div className="email-container">
                <span className="label">{COPY.profile_details_email_label}</span>
                {isEditingEmail ? (
                    <div className="email-writeable">
                        <Input name="email" value={email} onChange={trim(setEmail)} />
                        <a href="#" onClick={(): void => setIsEditingEmail(false)}>
                            {COPY.profile_details_save_email}
                        </a>
                    </div>
                ) : (
                    <div className="email-readonly">
                        <p>{email}</p>
                        <a href="#" onClick={(): void => setIsEditingEmail(true)}>
                            Change
                        </a>
                    </div>
                )}
            </div>
        </div>
    );

    const renderTimezoneField = (): JSX.Element => (
        <div className="form-row" key="timezone">
            <span className="label">{COPY.profile_details_timezone_label}</span>
            <Dropdown
                className="dropdown"
                options={timezoneNames}
                value={timezoneIndex}
                placeholder={COPY.profile_details_timezone_placeholder}
                onChange={handleTimezoneChange}
            />
        </div>
    );

    const renderAvailabilityField = (): JSX.Element => {
        const isAvailabilityRequired = isEstimator && !isBuilder;

        return (
            <div className="form-row availability-container" key="availability">
                <span className="label">{COPY.profile_details_availability_label}</span>
                <div className="divider"></div>
                <span className="desc">{COPY.profile_details_availability_description}</span>
                <Availability days={availability} setAvailability={setAvailability} />
                {isAvailabilityRequired && isFormDirty && !isAvailabilitySet && (
                    <Typography color={colorStatusError}>
                        {COPY.profile_details_availability_required}
                    </Typography>
                )}
            </div>
        );
    };

    const renderTimeOffPickers = (): JSX.Element => (
        <div className="form-row timeOff-container" key="timeOff">
            <span className="label">{COPY.profile_details_timeOff_label}</span>
            <div className="divider"></div>
            {unavailableDates.map((unavailableDate: CommitmentType, index: number) => (
                <div key={index} className="picker-container">
                    {!unavailableDate.deleted && (
                        <div className="form-row picker-wrapper">
                            {index === 0 && (
                                <div className="picker-labels">
                                    <span className="label time-off-label">
                                        {COPY.profile_details_date_from_label}
                                    </span>
                                    <span className="label time-off-label">
                                        {COPY.profile_details_date_to_label}
                                    </span>
                                </div>
                            )}
                            <div className="pickers">
                                <NativeDatepicker
                                    placeholder={COPY.profile_details_select_date_placeholder}
                                    value={String(unavailableDate.from).split('T')[0]}
                                    onChange={(value: string): void => handleDateFrom(value, index)}
                                    min={new Date().toISOString().split('T')[0]}
                                    max={String(unavailableDate.to).split('T')[0]}
                                    lightVariant={false}
                                />
                                <NativeDatepicker
                                    placeholder={COPY.profile_details_select_date_placeholder}
                                    value={String(unavailableDate.to).split('T')[0]}
                                    onChange={(value: string): void => handleDateTo(value, index)}
                                    min={String(unavailableDate.from).split('T')[0]}
                                    max=""
                                    lightVariant={false}
                                />
                                <div
                                    className="remove-date"
                                    onClick={(): void => removeDate(index)}
                                >
                                    <span>-</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
            <button
                key={
                    unavailableDates.filter(
                        (unavailableDate: CommitmentType) => !unavailableDate.deleted
                    ).length === 0
                        ? 'add-date'
                        : 'more-date'
                }
                className="date-button"
                onClick={addMoreDates}
            >
                <span>+</span>
                {unavailableDates.filter(
                    (unavailableDate: CommitmentType) => !unavailableDate.deleted
                ).length === 0
                    ? COPY.profile_details_add_dates
                    : COPY.profile_details_more_dates}
            </button>
        </div>
    );

    const renderCountry = (): JSX.Element => (
        <div className="form-row" key="country">
            <span className="label">{COPY.profile_details_country_label}</span>
            <Dropdown
                className="dropdown"
                options={countryOptions}
                value={country}
                placeholder={COPY.profile_details_country_placeholder}
                onChange={handleCountryChange}
            />
        </div>
    );

    const renderAddressField = (): JSX.Element => (
        <div className="form-row" key="address">
            <span className="label">{COPY.profile_details_address_label}</span>
            <Input
                name="address"
                value={address}
                onChange={trimLeft(setAddress)}
                placeholder={COPY.profile_details_address_placeholder}
                maxLength={100}
            />
        </div>
    );

    const renderStateProvinceField = (): JSX.Element => {
        return (
            <div className="form-row" key="stateOrProvinceFields">
                <span className="label">
                    {isUSSelected
                        ? COPY.profile_details_state_label
                        : COPY.profile_details_province_label}
                </span>
                <div className="multiInputRow">
                    <Input
                        name="city"
                        value={cityMunicipality}
                        placeholder={COPY.profile_details_city_placeholder}
                        onChange={trimLeft(setCityMunicipality)}
                        maxLength={100}
                    />
                    <Dropdown
                        className="dropdown"
                        options={isUSSelected ? stateOptions : provinceOptions}
                        value={stateProvince}
                        onChange={handleStateProvinceChange}
                    />
                </div>
            </div>
        );
    };

    const renderPostalCodeField = (): JSX.Element => (
        <div className="form-row" key="postalCode">
            <span className="label">
                {isUSSelected
                    ? COPY.profile_details_zip_code_label
                    : COPY.profile_details_postal_code_label}
            </span>
            <div className="multiInputRow">
                <Input
                    name="postalCode"
                    value={postalCode}
                    onChange={(value: string) => {
                        if (isUSSelected) {
                            const stripped = value
                                .trimLeft()
                                .replace(/[^\d]/, '')
                                .replace(/\D/g, '');
                            setPostalCode(stripped);
                        } else {
                            setPostalCode(value.trimLeft());
                        }
                    }}
                    placeholder={
                        isUSSelected
                            ? COPY.profile_details_zip_code_placeholder
                            : COPY.profile_details_postal_code_placeholder
                    }
                    maxLength={isUSSelected ? 5 : 6}
                />
            </div>
        </div>
    );

    const renderUploadValidationMessage = (): JSX.Element => {
        if (!isFileSizeLimitExceeded && !isUnsupportedFileExtension) return <></>;

        return (
            <div className="form-row">
                <span className="label" />
                <div className="file-upload-error-message">
                    <WarningLogo />
                    <div>
                        {isFileSizeLimitExceeded && (
                            <p>
                                <strong>{COPY.profile_details_file_size_limit_exceeded}</strong>
                                {COPY.profile_details_file_size_limit}
                            </p>
                        )}

                        {isUnsupportedFileExtension && (
                            <p>
                                <strong>{COPY.profile_details_file_type_not_supported}</strong>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderLogoSection = (): JSX.Element => (
        <>
            <div className="form-row">
                <span className="label">{COPY.profile_details_logo_label}</span>
                <div className="upload-preview-logo-section">
                    {!logo && (
                        <Button
                            variant="secondary"
                            onClick={onUploadClick}
                            customCss={{
                                color: '#FFFFFF',
                                borderColor: '#FFFFFF',
                                backgroundColor: 'transparent',
                                hoverBackgroundColor: '#FFFFFF',
                                hoverColor: '#1B2235',
                            }}
                        >
                            Upload
                        </Button>
                    )}
                    <input
                        ref={uploadImageRef}
                        onChange={onFileChange}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                    <ImageCropperModal
                        image={imageSrc}
                        openCropper={isCropperVisible}
                        closeCropper={closeCropperModal}
                        onCropComplete={onCropComplete}
                        onConfirmCrop={onConfirmCrop}
                        fitToDefaultSize={fitToDefaultSize}
                        onCheckboxChange={() => setFitToDefaultSize(!fitToDefaultSize)}
                    />
                    {logo && <img className="croppedLogo" src={logo as string} alt="Cropped" />}
                </div>
            </div>
            {renderUploadValidationMessage()}
            {!logo && (
                <div className="form-row">
                    <span className="label" />
                    <p className="upload-logo-description">
                        {COPY.profile_details_logo_placeholder}
                    </p>
                </div>
            )}
            {logo && (
                <div className="form-row">
                    <span className="label" />
                    <div className="multiInputRow">
                        <div className="logoIconsContainer" onClick={onReplaceClick}>
                            <ReplaceLogo />
                            <span className="label">Replace</span>
                            <input
                                ref={replaceImageRef}
                                onChange={onFileChange}
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                        </div>
                        <div
                            className="logoIconsContainer"
                            onClick={(): void => {
                                setLogo(null);
                            }}
                        >
                            <RemoveLogo />
                            <span className="label">Remove</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    const fieldsToRender =
        isEstimator && !isBuilder
            ? [renderNameFields, renderPhoneField]
            : [renderNameFields, renderCompanyField, renderPhoneField];

    if (isEstimator && !isBuilder) {
        fieldsToRender.push(renderTimezoneField, renderAvailabilityField, renderTimeOffPickers);
    }

    if (isEstimator && isBuilder && shareV2) {
        fieldsToRender.push(
            renderCountry,
            renderAddressField,
            renderStateProvinceField,
            renderPostalCodeField,
            renderLogoSection
        );
    }

    const renderTechnicalDetails = (): JSX.Element => (
        <div className="technical-details">
            <span className="close-cross" onClick={closeModal} />
            <h1>{COPY.profile_details_technical_information}</h1>
            <p>{COPY.profile_details_technical_content}</p>
            <div className="fields">
                <div className="section-typography">{COPY.profile_details_technical_email}</div>
                <h4>{user.email}</h4>
                <div className="section-typography">{COPY.profile_details_technical_user_id}</div>
                <h4>{user.id}</h4>
                <div className="section-typography">{COPY.profile_details_technical_team_id}</div>
                <h4>{user.teamID}</h4>
                <div className="section-typography">
                    {COPY.profile_details_technical_user_auth_id}
                </div>
                <h4>{user.authID}</h4>
                <div className="section-typography">{COPY.profile_details_technical_roles}</div>
                <h4>{user.roles?.join(' ')}</h4>
                <div className="section-typography">{COPY.profile_details_technical_version}</div>
                <h4>{process.env['RELEASE']}</h4>
                <div className="section-typography">
                    {COPY.profile_details_technical_user_agent}
                </div>
                <h4>{navigator.userAgent}</h4>
            </div>
        </div>
    );

    return (
        <div>
            <div className="profile-container-wrapper">
                <div className="container profile-container">
                    <Typography variant="h1">{COPY.profile_details_title}</Typography>
                    <hr />
                    <div className="form">
                        {fieldsToRender.map((f) => f())}
                        {showEmail && (
                            <>
                                <hr />
                                {renderEmailField()}
                                {/*The expression below can be replaced with a proper
                            {/*boolean once email changing logic is hooked up to this
                            {/*view: https://1build.atlassian.net/browse/BUILD-535*/}
                                {false && (
                                    <p className="info">
                                        {COPY.profile_details_change_email_confirmation}
                                    </p>
                                )}
                            </>
                        )}
                        <Stack spacing={1.5}>
                            <MuiButton
                                className="mt-36"
                                disabled={!isUserValid || loadingProfileUpdate}
                                fullWidth
                                onClick={handleUserSubmit}
                                variant="contained"
                            >
                                {COPY.profile_submit}
                            </MuiButton>
                            {isAnyTrial(user.team) && !isExpiredTrial(user.team) && (
                                <>
                                    <MuiButton
                                        color="error"
                                        disabled={!isUserValid || loadingProfileUpdate}
                                        fullWidth
                                        onClick={() => setIsCancelTrialModalOpen(true)}
                                        variant="outlined"
                                    >
                                        {COPY.trialDelete}
                                    </MuiButton>
                                    <CancelTrialModal
                                        open={isCancelTrialModalOpen}
                                        hideModal={() => setIsCancelTrialModalOpen(false)}
                                    />
                                </>
                            )}
                        </Stack>
                    </div>
                    <Stack
                        direction="row"
                        spacing="10px"
                        position="fixed"
                        bottom="1rem"
                        right="1.5rem"
                        sx={{
                            textDecoration: 'underline',
                            color: (theme) => theme.palette.hues.neutral[78],
                        }}
                    >
                        {flags.improvedMarketplaceSubmission && isBuilderUser(user) && (
                            <Typography fontWeight="bold">
                                <Link href="/profile/details" color="inherit">
                                    {COPY.profile_details_builder_profile}
                                </Link>
                            </Typography>
                        )}
                        <Typography
                            fontWeight="bold"
                            sx={{ cursor: 'pointer' }}
                            onClick={openModal}
                        >
                            {COPY.profile_details_technical_information}
                        </Typography>
                    </Stack>
                    <Modal open={isModalVisible} onClose={closeModal}>
                        {renderTechnicalDetails()}
                    </Modal>
                </div>
            </div>
        </div>
    );
};
