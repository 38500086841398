import React, { FC } from 'react';
import { ListItemButtonWithCollapse } from './ListItemButtonWithCollapse';
import { SourcesList } from './SourcesList';
import { ISourceTypeV2 } from '@/graphql';

interface SourcesCategoryProps {
    category: string;
    currentProjectId: number;
}

export const MaterialCategory: FC<SourcesCategoryProps> = ({ category, currentProjectId }) => {
    return (
        <ListItemButtonWithCollapse dense label={category}>
            <SourcesList
                sourceType={ISourceTypeV2.Material}
                category={category}
                currentProjectId={currentProjectId}
            />
        </ListItemButtonWithCollapse>
    );
};
