import { EstimationView } from '../types';

/* **import after takeoff or observables will not initialize** */
import { EstimationLayoutChildrenProps } from '@/components/app/router/EstimationRoute/EstimationLayout';
import { Estimate } from '@/views/Estimate';
import { Takeoff } from '@/views/Takeoff';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

interface EstimationViewPayload {
    EstimationViewComponent: FC<EstimationLayoutChildrenProps>;
    view: EstimationView;
}

const isEstimatePath = (path: string): boolean => {
    return /\/estimate$/.test(path);
};

const isTakeoffPath = (path: string): boolean => {
    return /\/takeoff$/.test(path);
};

export const useEstimationView = (): EstimationViewPayload => {
    const { path } = useRouteMatch();

    if (isEstimatePath(path)) {
        return {
            EstimationViewComponent: Estimate,
            view: EstimationView.Estimate,
        };
    }

    if (isTakeoffPath(path)) {
        return {
            EstimationViewComponent: Takeoff,
            view: EstimationView.Takeoff,
        };
    }

    throw new Error(`useEstimationView called within non-estimation path: ${path}`);
};
