import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface ConfirmDialogProps {
    open: boolean;
    title?: string;
    description?: string;
    onCancel: () => void;
    onConfirm: () => void;
    onCancelText?: string;
    onConfirmText?: string;
}

const COPY = {
    cancel: 'Cancel',
    confirm: 'Confirm',
};

const ConfirmationDialog: FC<ConfirmDialogProps> = ({
    open,
    title,
    description,
    onCancel,
    onConfirm,
    onCancelText,
    onConfirmText,
}) => (
    <Dialog open={open} maxWidth="xs">
        {title && (
            <DialogTitle sx={{ color: (theme) => theme.palette.hues.neutral[21] }}>
                {title}
            </DialogTitle>
        )}
        {description && (
            <DialogContent>
                <Typography variant="body1">{description}</Typography>
            </DialogContent>
        )}
        <DialogActions>
            <Button onClick={onCancel} variant="outlined" color="secondary">
                {onCancelText || COPY.cancel}
            </Button>
            <Button onClick={onConfirm} variant="outlined" color="primary">
                {onConfirmText || COPY.confirm}
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
