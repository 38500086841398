import React, { FC } from 'react';
import moment from 'moment';

import Table, { Data } from '@/components/ui/tables/Table';
import { Env } from '@/common/env';
import { useTeamsPendingOnboardingQuery } from '@/graphql';

export const Onboardings: FC = () => {
    const { loading, error, data } = useTeamsPendingOnboardingQuery();
    if (loading) return <p>Loading ...</p>;
    if (error) return <p>Error</p>;

    const columns = [
        { Header: 'Team Name', accessor: 'name' },
        { Header: 'Signing Date', accessor: 'date' },
        { Header: 'Stripe ID', accessor: 'stripe' },
        { Header: 'Onboarding Link', accessor: 'onboarding' },
    ];

    const rows: Data[] =
        data?.teamsPendingOnboarding?.edges?.map((edge) => ({
            name: edge?.node?.name,
            date: moment(edge?.node?.onboarding?.createdAt).format('MM/DD/YYYY'),
            stripe: edge?.node?.onboarding?.stripeCustomerID,
            onboarding: `${Env.deploymentURL}/signup/platform?onboarding=${
                edge?.node?.onboarding?.id ?? ''
            }`,
        })) || [];

    return (
        <div className="generic-table-container">
            <Table columns={columns} data={rows} />
        </div>
    );
};
