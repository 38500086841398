import { LeydenEditor, Table } from 'leyden';
import { Editor } from 'slate';
import { Queries } from '@/components/Estimate/queries/index';

export interface CategoriesQueries {
    category: (editor: Editor, categoryID: string) => CategoryRow | null;
    categories: (
        editor: Editor,
        options?: {
            reverse?: boolean;
        }
    ) => Generator<CategoryRow, void, undefined>;
}

export const CategoriesQueries: CategoriesQueries = {
    /**
     * Get the row information of a category.
     */

    category(editor: Editor, categoryID: string): CategoryRow | null {
        for (const category of Queries.categories(editor)) {
            if (category[0].categoryID === categoryID) {
                return category;
            }
        }
        return null;
    },

    /**
     * Get all categories within an editor.
     */

    *categories(
        editor: Editor,
        options: {
            reverse?: boolean;
        } = {}
    ): Generator<CategoryRow, void, undefined> {
        for (const [cell, coords] of Table.column(LeydenEditor.table(editor), {
            at: 0,
            reverse: options.reverse,
            type: 'Category',
        })) {
            const categoryData: CategoryData = {
                categoryID: cell.id,
                uuid: cell.uuid,
                name: cell.name,
            };
            yield [categoryData, coords.y];
        }
    },
};

interface CategoryData {
    categoryID: string;
    uuid: string;
    name: string;
}
export type CategoryRow = [CategoryData, number];
