import debounce from 'lodash/debounce';
import { Subject, Subscription } from 'rxjs';

import { Geometry } from '@/common/types';

export type GeometryPersistenceFunction = (
    geometries: Geometry[],
    callback?: (snapshot: Geometry[]) => void
) => void;
export interface GeometryPersistencePayload {
    persist: GeometryPersistenceFunction;
    projectPlanPageID: number;
    scale: number | null;
}

export interface PersistedGeometriesPayload {
    snapshot: Geometry[];
    projectPlanPageID: number;
    scale: number | null;
}

const persistedGeometriesObservable = new Subject<PersistedGeometriesPayload>();

export const persistGeometries = debounce(
    (geometries: Geometry[], payload?: GeometryPersistencePayload): void => {
        if (payload) {
            payload.persist(geometries, (snapshot) => {
                persistedGeometriesObservable.next({
                    snapshot,
                    projectPlanPageID: payload.projectPlanPageID,
                    scale: payload.scale,
                });
            });
        }
    },
    1000,
    { trailing: true }
);

export const subscribeToPersistedGeometries = (
    subscribe: (payload: PersistedGeometriesPayload) => void
): Subscription => persistedGeometriesObservable.subscribe(subscribe);
