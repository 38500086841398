/* Fetches graphQl query and returns mapped nodes array */
import { useEffect, useState } from 'react';

import { DocumentNode } from 'graphql';
import { useQuery } from 'urql';

import { Setter } from '@/common/types';

// the result of calling `Object.values` on data returned from a standard
// postgraphile gql request
type RequestDataVals<T> = [
    {
        nodes?: T[];
    },
    ...unknown[]
];

type RequestResult<T> = [T[], Setter<T[]>, boolean, boolean];

export const useQueryArray = <T>(query: DocumentNode, pause?: boolean): RequestResult<T> => {
    const [data, setData] = useState<T[]>([]);
    const [fetching, setFetching] = useState(true);
    const [fetched, setFetched] = useState(false);

    const [request] = useQuery<T>({ query, pause });

    useEffect(() => {
        if (request.fetching || !request.data) return;
        /* Retrieve array nodes from first entry of request's data */
        const vals = Object.values(request.data) as RequestDataVals<T> | undefined;
        setFetching(false);
        setFetched(true);
        setData(vals?.[0]?.nodes || []);
    }, [request]);

    return [data, setData, fetched, fetching];
};
