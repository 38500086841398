import { BasicModal } from '@/components/ui/controls/BasicModal';
import { NumericInput } from '@/components/ui/inputs/NumericInput';
import { Textarea } from '@/components/ui/inputs/Textarea';
import {
    borderCement,
    breakpointExtraSmall,
    colorBackgroundLight,
    colorStatusError,
    colorTertiaryLighter,
} from '@/variables';
import styled, { css } from 'styled-components';

export const Modal = styled(BasicModal)`
    & > div:nth-child(2) {
        width: 490px;
        padding: 20px 40px 30px 25px;

        @media (max-width: ${breakpointExtraSmall}) {
            margin: 1rem;
            height: 70vh;
            overflow: auto;
        }

        & > div:nth-child(2) {
            margin-left: unset;

            & > div {
                background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
                border-radius: 4px;
                width: 84px;
                border-color: unset;
                margin-left: 15px;
            }
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const Label = styled.div`
    color: ${colorBackgroundLight};
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
`;

export const RadioLabel = styled.span`
    color: ${colorBackgroundLight};
    font-size: 16px;
    font-weight: normal;
`;

export const RadioContainer = styled.div`
    margin-bottom: 20px;

    & .radio-wrapper {
        border: ${borderCement};

        & > .radio--checked {
            background-color: #4378f7;
        }
    }

    @media (max-width: ${breakpointExtraSmall}) {
        margin: 15px 0 15px 0;
    }
`;

export const Text = styled.div`
    color: ${colorBackgroundLight};
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 17px;
    margin-top: -5px;
`;

const inputCss = css`
    background: ${colorTertiaryLighter};
    border: unset;
    color: ${colorBackgroundLight};
    font-weight: 600;
    margin-bottom: 1rem;

    &::placeholder {
        font-size: 16px;
        font-weight: normal;
    }
`;

export const ModalTextarea = styled(Textarea)`
    & > textarea {
        ${inputCss}
    }
`;

export const ModalNumericInput = styled(NumericInput)`
    width: 50%;

    & > input {
        ${inputCss}
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }
`;

export const Error = styled.div`
    color: ${colorStatusError};
`;
