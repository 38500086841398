import styled from 'styled-components';

export const DotsContainer = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
`;

export const Dot = styled.div`
    height: 4px;
    width: 4px;
    background: #f6f8f8;
    opacity: 0.7;
    margin-right: 2px;
    display: inline-block;
    border-radius: 50%;
`;
