import { Point } from '@/common/types';
import { coordinateDataIs } from '@/common/utils/geometries/coordinateData';
import { IMarkupPointFragment, IMarkupLineFragment, IMarkupPolygonFragment } from '@/graphql';

type Convert<T extends Point | Point[] | Point[][]> = T extends Point
    ? IMarkupPointFragment
    : T extends Point[]
    ? IMarkupLineFragment
    : T extends Point[][]
    ? IMarkupPolygonFragment
    : never;

type Converter<T extends Point | Point[] | Point[][]> = (data: T) => Convert<T>;

const toMarkupPointFragment: Converter<Point> = (point) => {
    return {
        __typename: 'Coordinate',
        x: point.x,
        y: point.y,
    };
};

const toMarkupLineFragment: Converter<Point[]> = (line) => {
    return {
        __typename: 'MarkupLine',
        points: line.map(toMarkupPointFragment),
    };
};

const toMarkupPolygonFragment: Converter<Point[][]> = (area) => {
    return {
        __typename: 'MarkupPolygon',
        rings: area.map(toMarkupLineFragment),
    };
};

export function toMarkupGeometry<T extends Point | Point[] | Point[][]>(data: T): Convert<T>;
export function toMarkupGeometry(data: Point | Point[] | Point[][]) {
    if (coordinateDataIs.point(data)) return toMarkupPointFragment(data);
    if (coordinateDataIs.area(data)) return toMarkupPolygonFragment(data);
    else return toMarkupLineFragment(data);
}
