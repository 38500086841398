import styled from 'styled-components';
import WelcomeBackground from '@/assets/images/welcomeBackground.png';
import { breakpointSmall } from '@/variables';

export const Header = styled.h2`
    color: #ffffff;
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    color: #ffffff;
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
`;

////////////////////
// Submit section //
////////////////////
export const SubmitButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 4px;
    width: 272px;
    height: 64px;
    color: #fff;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;

    & > svg {
        margin-left: 20px;
        transform: scale(0.7);
    }

    &:focus {
        outline: none;
    }
`;

export const SubmitContainer = styled.div`
    display: flex;
    margin-top: 40px;
`;

////////////////////////
// Wrapper containers //
////////////////////////
export const Overlay = styled.div`
    cursor: default;
    z-index: 6000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    cursor: default;
    box-sizing: border-box;
    background: #2e3f73;
    box-shadow: 0px 51px 67px rgba(0, 0, 0, 0.07), 0px 14.5916px 12.4842px rgba(0, 0, 0, 0.0503198),
        0px 7.67308px 4.42419px rgba(0, 0, 0, 0.0417275),
        0px 4.5434px 2.49313px rgba(0, 0, 0, 0.035),
        0px 2.74017px 1.89164px rgba(0, 0, 0, 0.0282725),
        0px 1.36904px 1.23029px rgba(0, 0, 0, 0.0196802);
    border-radius: 16px;
    overflow: hidden;

    display: grid;
    grid-template-columns: 40% 60%;

    @media (max-width: ${breakpointSmall}) {
        display: block;
    }

    width: 847px;
    min-height: 532px;

    & > ${Header} {
        margin-top: 0;
        margin-bottom: 28px;
        max-width: 486px;
    }
`;

export const LeftContent = styled.div`
    background: url(${WelcomeBackground});
`;

export const RightContent = styled.div`
    padding: 58px 20px 16px 47px;
    position: relative;

    & > .cross {
        position: absolute;
        right: 21px;
        top: 21px;
        transform: scale(0.8);
        cursor: pointer;
    }
`;
