import React, { FC, useState } from 'react';

import styled from 'styled-components';

import { RatingBar } from '@/components/ui/controls/RatingBar';
import { TradeCircle } from '@/components/ui/controls/Trade';
import { ITradeRatingFragment } from '@/graphql';
import { colorTypographyDark } from '@/variables';

const COPY = {
    tradesHeader: 'TRADE',
    experienceHeader: 'EXPERIENCE',
};

interface SkillsProps {
    tradeRatings: ITradeRatingFragment[];
    updateRating: (tradeID: string, rating: number) => void;
}

export const Skills: FC<SkillsProps> = ({ tradeRatings, updateRating }) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const sortedRatings = [...tradeRatings].sort((a, b) =>
        a.trade.orderWeight > b.trade.orderWeight ? 1 : -1
    );

    return (
        <div>
            <SkillHeader>
                <HeaderItem>{COPY.tradesHeader}</HeaderItem>
                <HeaderItem marginr="4px">{COPY.experienceHeader}</HeaderItem>
            </SkillHeader>
            <SkillsContainer>
                {sortedRatings.map((tradeRating) => (
                    <SkillRow key={tradeRating.trade.id}>
                        <TradeItem>
                            <TradeCircle v2 className="label-icon" trade={tradeRating.trade} />
                            <TradeName>{tradeRating.trade.name}</TradeName>
                        </TradeItem>
                        <RatingBar
                            rating={tradeRating.rating || 1}
                            isHover={isHover}
                            onChange={(value: number): void => {
                                updateRating(tradeRating.trade.id, value);
                                setIsHover(false);
                            }}
                            numberOfElements={4}
                        />
                    </SkillRow>
                ))}
            </SkillsContainer>
            ;
        </div>
    );
};

const SkillHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
`;

const HeaderItem = styled.div<{ marginr?: string }>`
    text-transform: uppercase;
    font-weight: normal;
    font-size: 11px;
    color: #47637e;
    margin-right: ${(props): string => (props.marginr ? props.marginr : '')};
`;

const SkillsContainer = styled.div`
    overflow-y: auto;
    height: calc(100vh - 200px);
    &::-webkit-scrollbar {
        display: none;
    }
`;

const SkillRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #f6f8f8;
`;

const TradeItem = styled.div`
    display: flex;
    align-items: center;
`;

const TradeName = styled.div`
    font-weight: normal;
    font-size: 14px;
    margin-left: 12px;
    color: ${colorTypographyDark};
`;
