/*
 * A collection of helper function meant to support the selection operations.
 */
import Flatten from '@flatten-js/core';
import { LatLngBounds } from 'leaflet';

import { toFlatten } from '@/common/convert/coordinateData';
import { toFlatten as geometryToFlatten } from '@/common/convert/geometry';
import { geometryIs } from '@/common/typeGuards';
import { Geometry, GeometryType, Point, SelectedGeometries, SelectedPoint } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import { notUndefined } from '@/common/utils/helpers';

const convertSelectionToFlatten = (selectionBoundaries: LatLngBounds): Flatten.Polygon => {
    return new Flatten.Polygon(
        new Flatten.Box(
            selectionBoundaries.getWest(),
            selectionBoundaries.getSouth(),
            selectionBoundaries.getEast(),
            selectionBoundaries.getNorth()
            // The flatten-js type declaration is missing the following
            // method, so we're forced to override Typescripts checks.
        ).toSegments()
    );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
type FlattenMiddler = Pick<Flatten.Edge, 'middle'>;
const isFlattenMiddler = (value: any): value is FlattenMiddler => {
    return (
        typeof value === 'object' &&
        value !== null &&
        typeof Reflect.get(value, 'middle') === 'function'
    );
};
/* eslint-enable  @typescript-eslint/no-explicit-any */

export const selectGeometries = (
    selectionBoundaries: LatLngBounds,
    geometries: Geometry[]
): SelectedGeometries => {
    const selectedGeometries: SelectedGeometries = {
        geometries: [],
        points: [],
    };

    const flattenSelection = convertSelectionToFlatten(selectionBoundaries);

    geometries.forEach((geometry) => {
        if (geometryIs(geometry, GeometryType.COUNT)) {
            const pointsInSelection = geometry.coordinates.reduce<SelectedPoint[]>((acc, point) => {
                if (flattenSelection.contains(toFlatten(point))) {
                    return [...acc, { ...point, countID: geometry.uuid }];
                }
                return acc;
            }, []);
            if (pointsInSelection.length > 0) {
                // Add the whole geometry if selection includes all points.
                if (pointsInSelection.length === geometry.coordinates.length) {
                    selectedGeometries.geometries.push(geometry);
                } else {
                    selectedGeometries.points = selectedGeometries.points.concat(pointsInSelection);
                }
            }
        } else if (
            geometryIs(geometry, GeometryType.AREA) ||
            geometryIs(geometry, GeometryType.LINEAR)
        ) {
            // Workaround - the flatten-js library currently only
            // supports checking if a segment or a point is contained,
            // that's why we're finding the first edge's middle
            // and use it for geometries other than Point.
            const shape = geometryToFlatten(geometry);
            const edgeValue: unknown = shape.edges.values().next().value;
            if (isFlattenMiddler(edgeValue)) {
                const samplePoint = edgeValue.middle();
                let intersects = flattenSelection.contains(samplePoint);
                if (shape instanceof Flatten.Multiline) {
                    // for every segment in multiline, check if it intersects
                    for (const subShape of shape.toShapes()) {
                        intersects = intersects || flattenSelection.intersect(subShape).length > 0;
                    }
                } else if (shape instanceof Flatten.Polygon)
                    intersects = intersects || flattenSelection.intersect(shape).length > 0;
                if (intersects) selectedGeometries.geometries.push(geometry);
            }
        }
    });
    return selectedGeometries;
};

export const selectVertices = (
    selectionBoundaries: LatLngBounds,
    geometry?: Geometry
): string[] => {
    if (!geometry) return [];

    const flattenSelection = convertSelectionToFlatten(selectionBoundaries);
    return newGeometryHandler<string[]>({
        [GeometryType.AREA]: (area) => {
            return area.coordinates.flatMap((ring, ringIndex) =>
                ring
                    .map((coordinates, index) => {
                        if (
                            flattenSelection.contains(
                                new Flatten.Point(coordinates.y, coordinates.x)
                            )
                        ) {
                            return `${ringIndex}-${index}`;
                        }
                    })
                    .filter(notUndefined)
            );
        },
        [GeometryType.COUNT]: () => [],
        [GeometryType.LINEAR]: (line) => {
            return line.coordinates
                .map((coordinates: Point, index: number) => {
                    if (
                        flattenSelection.contains(new Flatten.Point(coordinates.y, coordinates.x))
                    ) {
                        return index.toString();
                    }
                })
                .filter(notUndefined);
        },
    })(geometry);
};
