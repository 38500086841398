import React, { FC, useMemo, useState } from 'react';

import { DivIcon, DomEvent, LatLng, LeafletMouseEvent } from 'leaflet';
import { FeatureGroup, Marker } from 'react-leaflet';

import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import {
    ContextMenuDispatcherType,
    Geometry,
    GeometryType,
    LeafletStyleType,
    MarkerSize,
    Point,
    ToolType,
} from '@/common/types';
import { getIcon, getSize } from '@/common/utils/geometries/helpers';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { usePointSelectionState } from '@/components/takeoff/hooks/usePointSelectionState';
import { useTool } from '@/components/takeoff/hooks/useTool';
import { PointSelectionState } from '@/components/takeoff/observables/generators';
import { colorPrimaryLighter } from '@/variables';

export interface PointTool extends TakeoffComponentProps {
    point: Point;
    parent: Geometry<GeometryType.COUNT>;
    leafletStyle: LeafletStyleType;
}

export const PointTool: FC<PointTool> = ({ point, parent, leafletStyle, useTakeoff }) => {
    const { addBoundary, mouseEvents, removeBoundary, select, setContextMenuPayload } =
        useTakeoff();
    const tool = useTool();
    const selectionState = usePointSelectionState(point.id, parent.uuid);
    const [selectMultipleGeo, setSelectMultipleGeo] = useState<boolean>(false);

    const pointForSelection = useMemo(
        () => ({
            ...point,
            countID: parent.uuid,
        }),
        [point, parent.uuid]
    );

    const size = getSize(leafletStyle.size ?? MarkerSize.L);

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            setSelectMultipleGeo(true);
        }
    };

    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            setSelectMultipleGeo(false);
        }
    };

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    const onContextMenu = (e: LeafletMouseEvent): void => {
        if (selectionState === PointSelectionState.ParentSelected) {
            setContextMenuPayload({
                coordinates: e.latlng,
                isEdge: false,
                dispatcher: ContextMenuDispatcherType.COUNTMARKER,
            });
        } else if (selectionState === PointSelectionState.PointSelected) {
            setContextMenuPayload({
                coordinates: e.latlng,
                isEdge: false,
                dispatcher: ContextMenuDispatcherType.COUNTGROUPMEMBER,
            });
        }
        DomEvent.stopPropagation(e);
    };

    const customMouseEvents = {
        oncontextmenu: onContextMenu,
        onclick: () => {
            if (selectionState === PointSelectionState.Unselected) {
                removeBoundary();
                select(parent, selectMultipleGeo);
            }
        },
        ondblclick: () => {
            if (selectionState !== PointSelectionState.PointSelected) {
                select(pointForSelection, selectMultipleGeo);
            }
        },
        onmouseover: (): void => {
            if (selectionState === PointSelectionState.Unselected) {
                addBoundary(parent.uuid);
            }
        },
        onmouseout: (): void => {
            if (selectionState === PointSelectionState.Unselected) {
                removeBoundary();
            }
        },
    };

    const takeoffMouseEvents =
        selectionState === PointSelectionState.PointSelected
            ? mouseEvents(pointForSelection)
            : mouseEvents(parent);

    return (
        <FeatureGroup {...customMouseEvents} {...takeoffMouseEvents} bubblingMouseEvents={false}>
            {selectionState !== PointSelectionState.Unselected &&
                leafletStyle.markerIcon !== undefined && (
                    <Marker
                        interactive
                        icon={
                            new DivIcon(
                                getIcon(size * 1.2, leafletStyle.markerIcon, colorPrimaryLighter)
                            )
                        }
                        position={new LatLng(point.x, point.y)}
                    />
                )}
            <Marker
                bubblingMouseEvents={false}
                zIndexOffset={100}
                icon={
                    new DivIcon({
                        ...leafletStyle.icon,
                        className: `${leafletStyle.icon?.className ?? ''} ${
                            tool !== ToolType.SELECTION ? 'disable-events' : ''
                        }`,
                    })
                }
                position={new LatLng(point.x, point.y)}
                interactive
            />
        </FeatureGroup>
    );
};
