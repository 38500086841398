import gql from 'graphql-tag';

import { makeUseSubscription } from '../frog';
import {
    projectEstimateFileFragment,
    ProjectEstimateFileRecord,
} from '../queries/projectEstimateFiles';

export type ProjectEstimateFileMessage = {
    ProjectEstimateFile: {
        projectEstimateFileEntry?: ProjectEstimateFileRecord | null;
    };
};

export const projectEstimateFileSubscription = projectEstimateFileFragment.gql`
    subscription {
        ProjectEstimateFile {
            projectEstimateFileEntry {
                ${projectEstimateFileFragment}
            }
        }
    }
`;

export const useProjectEstimateFileSubscription = makeUseSubscription<ProjectEstimateFileMessage>(
    projectEstimateFileSubscription
);

export type DeleteProjectEstimateFileMessage = {
    DeleteProjectEstimateFile: {
        deletedProjectEstimateFileEntry?: {
            uuid: string;
        } | null;
    };
};

export const deleteProjectEstimateFileSubscription = gql`
    subscription {
        DeleteProjectEstimateFile {
            deletedProjectEstimateFileEntry {
                uuid
            }
        }
    }
`;

export const useDeleteProjectEstimateFileSubscription =
    makeUseSubscription<DeleteProjectEstimateFileMessage>(deleteProjectEstimateFileSubscription);
