import { toQuantity } from '@/common/convert/geometry';
import { geometryIs } from '@/common/typeGuards';
import { Geometry, GeometryType } from '@/common/types';
import { geometryTypeToUnitName } from '@/common/utils/geometries/maps';

export const toQuantityFormatted = (
    geometry: Geometry,
    scale: number | null,
    options: {
        unit?: boolean;
    } = {}
) => {
    let result = '';
    const quantity = toQuantity(geometry, scale);

    if (geometryIs(geometry, GeometryType.COUNT)) {
        result = `${quantity.toFixed(0)}`;
    } else if (geometryIs(geometry, GeometryType.LINEAR)) {
        const wholeFeet = Math.floor(quantity);
        const inchesInFoot = 12;
        const inches = Math.round((quantity - wholeFeet) * inchesInFoot);
        result = `${wholeFeet}’ ${inches.toString().padStart(2, '0')}”`;
    } else {
        result = quantity.toFixed(2);
    }

    if (options.unit) {
        result += ` ${geometryTypeToUnitName[geometry.type]}`;
    }

    return result;
};
