/*
 * AdminDashboard is the administrative project management dashboard.
 */
import React, { FC, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Modal } from './Modal';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { gradientAdminDashboardBackground } from '@/variables';

import { ReactComponent as IsometricBoxes } from '@/assets/images/IsometricBoxes.svg';

const desktopGrid = `
" sidebar topbar  " 5.75rem
" sidebar projects" 1fr
/ 17.5rem 1fr     `;

const grid = `
" topbar  " 7rem
" projects" 1fr
/ auto    `;

const DashboardContainer = styled.div<{ isDesktop: boolean }>`
    display: grid;
    grid-template: ${(props): string => (props.isDesktop ? desktopGrid : grid)};
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background: ${gradientAdminDashboardBackground};
    overflow: hidden;
`;

const IsometricBoxesContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
`;

type AdminDashboardProps = {
    topbarContent: React.ReactNode;
};

export const AdminDashboard: FC<AdminDashboardProps> = ({ children, topbarContent }) => {
    const screenSize = useScreenSize();

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const isDesktop = useMemo(() => screenSize === ScreenSize.Desktop, [screenSize]);

    const handleCloseSidebar: (() => void) | undefined = useMemo(() => {
        if (!isDesktop && sidebarToggle) {
            return (): void => setSidebarToggle(false);
        }
    }, [isDesktop, sidebarToggle]);

    const sidebarProps = useMemo<React.CSSProperties>(
        () => ({
            transform: !isDesktop && sidebarToggle ? 'translateX(20rem)' : 'none',
        }),
        [isDesktop, sidebarToggle]
    );

    return (
        <DashboardContainer isDesktop={isDesktop}>
            <Modal />
            <Topbar onClickHamburger={(): void => setSidebarToggle((t) => !t)}>
                {topbarContent}
            </Topbar>
            {children}
            <Sidebar style={sidebarProps} onClose={handleCloseSidebar} />
            <IsometricBoxesContainer>
                <IsometricBoxes />
            </IsometricBoxesContainer>
        </DashboardContainer>
    );
};
