import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useLoading } from '@/contexts/Loading';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * Global loader component.  This is rendered if there are other
 * <LoadingPage /> components mounted within the app.
 * @see {LoadingPage}
 * @see {GlobalLoader}
 */
export const GlobalLoader: FC = () => {
    const { isLoading } = useLoading();

    if (!isLoading) {
        return null;
    }

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
};
