import {
    executeCreateProjectAssemblyMutation,
    executeDeleteProjectAssemblyMutation,
} from '@/mutations/projectAssembly';
import { executeProjectAssembliesQuery, ProjectAssemblyRecord } from '@/queries/projectAssemblies';
import { v4 as uuid } from 'uuid';

export const deleteProjectAssemblies = (
    projectAssemblyIds: string[]
): Promise<ProjectAssemblyRecord[]> =>
    Promise.all(
        projectAssemblyIds.map(async (id): Promise<ProjectAssemblyRecord> => {
            const result = await executeDeleteProjectAssemblyMutation({ input: { id } });
            if (result.error) {
                throw result.error;
            }
            const projectAssembly = result.data?.deleteProjectAssembly.projectAssembly;
            if (!projectAssembly) {
                throw new Error('ProjectAssembly record deletion returned malformed data.');
            }
            return projectAssembly;
        })
    );

export const linkStandaloneAssemblyToProject = async (
    assemblyId: number,
    projectId: number
): Promise<ProjectAssemblyRecord> => {
    const result = await executeCreateProjectAssemblyMutation({
        input: { projectAssembly: { assemblyId, projectId, id: uuid() } },
    });
    if (result.error) {
        throw result.error;
    }
    const newRecord = result.data?.createProjectAssembly.projectAssembly;
    if (!newRecord) {
        throw new Error('ProjectAssembly record creation returned malformed data.');
    }
    return newRecord;
};

export const persistStandaloneAssemblyInProject = async (
    link: boolean,
    assemblyId: number,
    projectId: number
): Promise<void> => {
    const queryResult = await executeProjectAssembliesQuery({ assemblyId, projectId });
    if (queryResult.error) {
        throw queryResult.error;
    }
    const projectAssemblyIds = queryResult.data?.projectAssemblies?.nodes.map((x) => x.id);
    if (projectAssemblyIds === undefined || projectAssemblyIds.length === 0) {
        if (link) {
            await linkStandaloneAssemblyToProject(assemblyId, projectId);
        }
        return;
    }
    if (!link) {
        await deleteProjectAssemblies(projectAssemblyIds);
    }
};
