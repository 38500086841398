/**
 * Displays an indicator allowing the Users to see that their file is being
 * uploaded in the Discussions Panel and giving them the option to abort the
 * upload.
 */

import React, { FC } from 'react';

import { useStorage } from '@/contexts/Storage';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';

import './UploadIndicator.scss';

const COPY = {
    discussions_upload_indicator_info: 'Processing upload…',
    discussions_upload_indicator_abort: 'CANCEL',
};

type UploadIndicatorProps = {
    onCancel: () => void;
};

export const UploadIndicator: FC<UploadIndicatorProps> = (props) => {
    const { currentUploads } = useStorage();

    const handleCancel = (): void => {
        currentUploads.forEach((managedUpload) => managedUpload.abort());
        props.onCancel();
    };

    return (
        <div className="discussions-upload-indicator">
            <div className="info">
                {COPY.discussions_upload_indicator_info}
                <span className="cancel" onClick={handleCancel}>
                    {COPY.discussions_upload_indicator_abort}
                </span>
            </div>
            <div>
                <DotsLoader />
            </div>
        </div>
    );
};
