/*
    Panel that allows for sending feedback about the project
*/

import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';
import { Textarea } from '@/components/ui/inputs/Textarea';
import {
    AddProjectFeedbackResult,
    addProjectFeedbackMutation,
} from '@/mutations/addProjectFeedback';
import { useUser } from '@/contexts/User';
import { useNotifications } from '@/contexts/Notifications';
import { ErrorMessage } from '@/components/notifications/Notification';

import './FeedbackPanel.scss';

const COPY = {
    feedback_add: 'Add feedback',
    feedback_send: 'Send',
    feedback_success_title: 'Feedback sent',
    feedback_success_msg: 'Feedback has been sent to the estimator',
    feedback_error_title: 'Feedback failed to send',
    feedback_error_msg: 'try again',
};

type FeedbackPanelProps = {
    v2?: boolean;
    projectId: string;
    close: () => void;
};

export const FeedbackPanel: FC<FeedbackPanelProps> = ({ v2, projectId, close }) => {
    const [feedback, setFeedback] = useState('');
    const {
        data: { user },
    } = useUser();
    const { addNotification } = useNotifications();

    const [, addProjectFeedback] = useMutation<AddProjectFeedbackResult>(
        addProjectFeedbackMutation
    );

    const send = (): void => {
        addProjectFeedback({ feedback, projectId, ownerId: user.id })
            .then((response) => {
                if (response.error) {
                    addNotification(
                        {
                            title: COPY.feedback_error_title,
                            content: (
                                <ErrorMessage
                                    message={COPY.feedback_error_msg}
                                    handleClick={send}
                                />
                            ),
                        },
                        'error'
                    );
                } else {
                    close();
                    addNotification(
                        {
                            title: COPY.feedback_success_title,
                            content: <p>{COPY.feedback_success_msg}</p>,
                        },
                        'success'
                    );
                }
            })
            .catch((_) => {
                addNotification(
                    {
                        title: COPY.feedback_error_title,
                        content: (
                            <ErrorMessage message={COPY.feedback_error_msg} handleClick={send} />
                        ),
                    },
                    'error'
                );
            });
    };

    return (
        <div className="feedback-panel">
            <div className="header">
                <span>{COPY.feedback_add}</span>
                <PrimaryButton v2={v2} onClick={send}>
                    {COPY.feedback_send}
                </PrimaryButton>
            </div>
            <Textarea value={feedback} onChange={setFeedback} />
        </div>
    );
};
