/*
 * AWS.ts is a context which allows direct, secure access to AWS by passing
 * the user token to AWS Cognito.
 */
import { getCredentials } from '@/common/aws';
import { Env } from '@/common/env';
import { makeContext } from '@/common/utils/makeContext';

type AWSProps = {
    getPlanBucket: () => Promise<import('aws-sdk').S3>;
};

const { useConsumer, Provider } = makeContext<AWSProps>(() => {
    const getPlanBucket = async (): Promise<import('aws-sdk').S3> => {
        const [AWS, credentials] = await Promise.all([
            import(/* webpackChunkName: 'AWS' */ 'aws-sdk'),
            getCredentials(),
        ]);

        const extraOptions =
            Env.s3AwsEndpoint !== ''
                ? {
                      endpoint: Env.s3AwsEndpoint,
                      s3ForcePathStyle: true,
                  }
                : {};

        return new AWS.S3({
            region: Env.awsPlanBucketRegion,
            httpOptions: {
                timeout: 600000,
            },
            ...extraOptions,
            ...credentials,
        });
    };

    return {
        getPlanBucket,
    };
});

export const useAWS = useConsumer;
export const AWSProvider = Provider;
