import React, { FunctionComponent, useState } from 'react';
import { DomEvent, LatLng, LeafletMouseEvent } from 'leaflet';
import { CircleMarker } from 'react-leaflet';

import { currentlyAddedCoordinatesObservable } from '@/components/takeoff/observables/helpers';
import { ContextMenuDispatcherType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';

export interface VertexProps extends TakeoffComponentProps {
    coordinates: LatLng;
    onMouseDown: (event: LeafletMouseEvent, id: string) => void;
    onMouseUp: (event: LeafletMouseEvent, id: string) => void;
    onMouseOver?: (event: LeafletMouseEvent) => void;
    onMouseOut?: (event: LeafletMouseEvent) => void;
    active?: boolean;
    opacity?: number;
    color: string;
    id: string;
    interactive: boolean;
}

const UnMemoizedVertex: FunctionComponent<VertexProps> = (props: VertexProps) => {
    /**
     * This component is rendered as a marker and is supposed to be used as
     * a vertex for geometries that have vertices. It uses MarkerIcons that are
     * defined as inline svgs to manage the state (active, hoverable) and color.
     * Because we need to change the states and colors dynamically, based on
     * JS variable values, all this cannot take place in CSS. A memoized version
     * of this component (at the bottom of the file) is meant to be used in
     * other components, to avoid event handler interruption.
     **/
    const [hovered, setHovered] = useState<boolean>(false);

    const { setContextMenuPayload } = props.useTakeoff();

    const currentlyAddedCoordinates = currentlyAddedCoordinatesObservable.value;

    let customMarkerType: 'normal' | 'hovered' | 'active' = 'normal';

    if (props.active) customMarkerType = 'active';
    else if (hovered) customMarkerType = 'hovered';

    const onContextMenu = (e: LeafletMouseEvent): void => {
        props.active &&
            !currentlyAddedCoordinates &&
            setContextMenuPayload({
                coordinates: e.latlng,
                dispatcher: ContextMenuDispatcherType.VERTEX,
            });
        DomEvent.stopPropagation(e);
    };

    const onMouseDown = (e: LeafletMouseEvent): void => {
        if (e.originalEvent.button === 0) props.onMouseDown(e, props.id);
        DomEvent.stopPropagation(e);
    };

    const onMouseUp = (e: LeafletMouseEvent): void => {
        props.onMouseUp(e, props.id);
    };

    const onMouseOver = (e: LeafletMouseEvent): void => {
        props.onMouseOver?.(e);
        setHovered(true);
    };

    const onMouseOut = (e: LeafletMouseEvent): void => {
        props.onMouseOut?.(e);
        setHovered(false);
    };

    return (
        <>
            {/*The first CircleMarker is actually going to be a custom white */}
            {/*background...*/}
            <CircleMarker
                center={props.coordinates}
                radius={5}
                customMarkerType={customMarkerType}
                color="white"
                onmousedown={onMouseDown}
                onmouseup={onMouseUp}
                onmouseover={onMouseOver}
                onmouseout={onMouseOut}
                oncontextmenu={onContextMenu}
                fillOpacity={props.opacity || 1}
                opacity={props.opacity || 1}
                interactive={props.interactive}
            />
            {/*For the custom marker displayed here*/}
            <CircleMarker
                center={props.coordinates}
                radius={5}
                customMarkerType={customMarkerType}
                color={props.color}
                onmousedown={onMouseDown}
                onmouseup={onMouseUp}
                onmouseover={onMouseOver}
                onmouseout={onMouseOut}
                oncontextmenu={onContextMenu}
                fillOpacity={props.opacity || 1}
                opacity={props.opacity || 1}
                interactive={props.interactive}
            />
        </>
    );
};

// We use memoization so that the vertex doesn't rerender every time
export const Vertex = React.memo(UnMemoizedVertex);
