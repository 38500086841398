import React, { FC } from 'react';

import { ButtonProps } from '@/common/types';
import {
    UserTableMoreButton,
    UserTableMoreButtonDot,
} from '@/components/mission-control/UserManager/UsersTable/styled';

export const MoreButton: FC<ButtonProps> = (props) => (
    <UserTableMoreButton {...props}>
        <UserTableMoreButtonDot />
        <UserTableMoreButtonDot />
        <UserTableMoreButtonDot />
    </UserTableMoreButton>
);
