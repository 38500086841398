import React, { FC, useEffect, useState } from 'react';
import { LatLngBounds } from 'leaflet';
import { Rectangle } from 'react-leaflet';

import { subscribeToBoundary } from '../observables/helpers';
import { colorPrimaryLight, colorPrimaryLighter } from '../../../variables';

type BoundaryProps = {
    color?: string;
};

export const Boundary: FC<BoundaryProps> = (props) => {
    const [boundary, setBoundary] = useState<LatLngBounds>();

    useEffect(() => {
        const subscription = subscribeToBoundary(setBoundary);
        return (): void => subscription.unsubscribe();
    }, []);

    if (!boundary) return <></>;

    return (
        <Rectangle
            bounds={boundary}
            fill={true}
            interactive={false}
            color={props.color || colorPrimaryLight}
            fillColor={props.color || colorPrimaryLighter}
        />
    );
};
