import { Bounds, Canvas } from 'leaflet';
import {
    _prepareCustomMarkerActive,
    _prepareCustomMarkerHighlighted,
    calcHelperParams,
    getAreaImage,
} from './helpers';
import { AreaType } from '../common/types';
import { _prepareLadderLine, _prepareZigZagLine, _prepareCustomMarker } from './helpers';
const { PI, sin, cos } = Math;

// Overrides Leaflet Canvas renderer allowing to draw custom lines and areas
export const customPatternCanvas = Canvas.extend({
    _extendRedrawBounds: function (layer) {
        if (layer._pxBounds) {
            const padding = (layer.options.weight || 0) + 10;
            this._redrawBounds = this._redrawBounds || new Bounds();
            this._redrawBounds.extend(layer._pxBounds.min.subtract([padding, padding]));
            this._redrawBounds.extend(layer._pxBounds.max.add([padding, padding]));
        }
    },
    _drawArrowLine: function (params, weight, ctx, layer) {
        const { alfa, startX, startY, endX, endY, zoom } = params;
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        const beta = (30 / 180) * PI + alfa;
        const x = weight * zoom;
        const arrow = `
        m ${endX} ${endY}
        l ${-x * cos(beta)} ${x * sin(beta)}
        l ${-x * cos(beta - 0.5 * PI - 0.166 * PI)}
        ${x * sin(beta - 0.5 * PI - 0.166 * PI)}
        z`;
        const pathObject = new Path2D(arrow);
        this._fillStroke(ctx, layer);
        pathObject.closePath();
        ctx.stroke(pathObject);
    },

    _drawLadderLine: function (params, weight, ctx, layer) {
        const path = _prepareLadderLine(params, closed, weight);
        const pathObject = new Path2D(path);
        this._fillStroke(ctx, layer);
        ctx.stroke(pathObject);
    },

    _drawZigZagLine: function (params, weight, ctx, layer) {
        const path = _prepareZigZagLine(params, closed, weight);
        const pathObject = new Path2D(path);
        this._fillStroke(ctx, layer);
        ctx.stroke(pathObject);
    },

    // Method called for rendering when geometries change
    _updatePoly: function (layer, closed) {
        if (!this._drawing) {
            return;
        }

        let i, j, len2;
        const parts = layer._parts,
            len = parts.length,
            ctx = this._ctx;

        // If len is 0 don't render this layer
        if (!len) {
            return;
        }

        // Start drawing path
        ctx.beginPath();
        // For each leaflet ring
        for (i = 0; i < len; i++) {
            // For coordinates in a leaflet ring
            for (j = 0, len2 = parts[i].length; j + 1 < len2; j++) {
                // Help params like angle, length, start, end etc.
                const params = calcHelperParams(parts[i][j], parts[i][j + 1], this);
                const { start, end } = params;
                const shapeWeight = layer.options.shapeWeight;
                switch (layer.options.lineType) {
                    case 'ladderLine':
                        this._drawLadderLine(params, shapeWeight, ctx, layer);
                        break;
                    case 'arrowLine':
                        // Draw Arrow only on last part of a line
                        if (j + 2 === len2) this._drawArrowLine(params, shapeWeight, ctx, layer);
                        else {
                            if (j === 0) ctx.moveTo(start.x, start.y);
                            ctx.lineTo(end.x, end.y);
                        }
                        break;
                    case 'zigzagLine':
                        this._drawZigZagLine(params, shapeWeight, ctx, layer);
                        break;
                    case 'dottedLine':
                        layer.options._dashArray = [
                            layer.options.shapeWeight,
                            layer.options.shapeWeight,
                        ];
                        layer.stroke = true;
                        if (j === 0) ctx.moveTo(start.x, start.y);
                        ctx.lineTo(end.x, end.y);
                        break;
                    default:
                        if (j === 0) ctx.moveTo(start.x, start.y);
                        ctx.lineTo(end.x, end.y);
                }
            }
            // For polygons
            if (closed) {
                ctx.closePath();
            }
        }
        // Sets styles
        this._fillStroke(ctx, layer);
    },
    _fillStroke: function (ctx, layer) {
        const options = layer.options;

        if (options.fill) {
            ctx.globalAlpha = options.fillOpacity;
            ctx.fillStyle = options.fillColor || options.color;
            ctx.fill(options.fillRule || 'evenodd');
        }
        if (options.stroke && options.weight !== 0) {
            if (ctx.setLineDash) {
                ctx.setLineDash((layer.options && layer.options._dashArray) || []);
            }
            ctx.globalAlpha = options.opacity;
            ctx.lineWidth = options.weight;

            // We're making rungs on the ladder and zigzags
            // as wide as the weight of the
            // underlying line, so we have to lower the weight if they are
            // to be visible.
            if (options.lineType === 'ladderLine' || options.lineType === 'zigzagLine') {
                ctx.lineWidth = options.weight / 4;
            }
            ctx.strokeStyle = options.color;
            ctx.lineCap = options.lineCap;
            ctx.lineJoin = options.lineJoin;
            ctx.stroke();
        }
        if (options.areaType && options.areaType !== AreaType.NORMAL) {
            const img = new Image();
            img.src = getAreaImage(options.areaType).default;
            const pattern = ctx.createPattern(img, 'repeat');
            ctx.fillStyle = pattern;
            ctx.fill();
        }
    },
    _updateCircle: function (layer) {
        if (!this._drawing || layer._empty()) {
            return;
        }
        const p = layer._point,
            ctx = this._ctx,
            r = Math.max(Math.round(layer._radius), 1),
            s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;

        if (!layer.options.customMarkerType) {
            if (s !== 1) {
                ctx.save();
                ctx.scale(1, s);
            }

            ctx.beginPath();
            ctx.arc(p.x, p.y / s, r, 0, Math.PI * 2, false);

            if (s !== 1) {
                ctx.restore();
            }
        } else {
            ctx.beginPath();
            let path = '';
            const isBackground = layer.options.color === 'white';

            switch (layer.options.customMarkerType) {
                // Hovered and normal are reversed here because of how canvas
                // renders empty spaces in shapes.
                case 'hovered':
                    path = _prepareCustomMarker(p, isBackground);
                    break;
                case 'normal':
                    path = _prepareCustomMarkerHighlighted(p, isBackground);
                    break;
                case 'active':
                    path = _prepareCustomMarkerActive(p, isBackground);
            }
            const pathObject = new Path2D(path);
            this._fillStroke(ctx, layer);
            ctx.stroke(pathObject);
        }

        this._fillStroke(ctx, layer);
    },
});
