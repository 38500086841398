import gql from 'graphql-tag';

export type AddProjectFeedbackResult = {
    project: {
        id: string;
        feedback: string;
    };
};

export const addProjectFeedbackMutation = gql`
    mutation AddProjectFeedback($feedback: String!, $projectId: BigInt!, $ownerId: BigInt!) {
        addProjectFeedback(
            input: { feedback: $feedback, projectId: $projectId, ownerId: $ownerId }
        ) {
            project {
                id
                feedback
            }
        }
    }
`;
