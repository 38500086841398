import React, { FC, FocusEventHandler, useCallback, useRef } from 'react';

import { MarkupInputStyled, MarkupInputWrapper } from './styled';

const COPY = {
    empty: '---',
};

export enum MarkupInputVariant {
    Normal,
    Percentage,
    Dollars,
}

interface MarkupInputProps {
    placeholder?: string;
    selectOnFocus?: boolean;
    value: string;
    variant: MarkupInputVariant;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
}

export const MarkupInput: FC<MarkupInputProps> = ({
    placeholder,
    variant,
    value,
    selectOnFocus = true,
    onChange,
    onBlur,
    onFocus,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            if (onFocus) {
                onFocus(e);
            }
            if (selectOnFocus && inputRef.current) {
                inputRef.current.select();
            }
        },
        [inputRef]
    );

    return (
        <MarkupInputWrapper variant={variant}>
            <MarkupInputStyled
                hasPlaceholder={!!placeholder}
                placeholder={placeholder || COPY.empty}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={handleFocus}
                ref={inputRef}
            />
        </MarkupInputWrapper>
    );
};
