import React, { FC } from 'react';

import { Overlay, Container } from './styled';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';

export interface PopoverProps {
    reset: () => void;
}
export const Popover: FC<PopoverProps> = ({ children, reset }) => {
    // Handle escape out.
    useDocumentListener(
        'keydown',
        (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Escape': {
                    reset();
                }
            }
        },
        [reset]
    );

    return (
        <>
            <Overlay onClick={reset} />
            <Container>{children}</Container>
        </>
    );
};
