import { TakeoffComponentProps } from '../context';
import {
    activeVerticesObservable,
    addBoundaryGeometriesByIdObservable,
    base64ViewportObservable,
    boundaryDataObservable,
    boundaryStartPositionObservable,
    cursorPositionObservable,
    editedGeometryObservable,
    isAddingCoordinatesObservable,
    isDrawingRectangleObservable,
    subscribeToBoundary,
} from '../observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
    toolObservable,
} from '../observables/interface';
import { Boundary } from './Boundary';
import { BoundingBox } from './BoundingBox';
import { EditableShapeOverlay } from './EditableShapeOverlay';
import { HelperOverlay } from './HelperOverlay';
import { PanZoomControl } from './PanZoomControl';
import './SheetViewer.scss';
import { findRotatedPageParameters } from './helpers/pageRotation';
import { selectGeometries, selectVertices } from './helpers/selection';
import { DrawingCursorGuidelines } from './overlay/common/cursors/DrawingCursorGuidelines';
import { TrackEventName, track } from '@/common/analytics';
import { toPlanPageGeometry, toRotated } from '@/common/convert/geometry';
import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import {
    ContextMenuDispatcherType,
    Geometry,
    GeometryType,
    PlanPage,
    PlanPageGeometry,
    ToolType,
} from '@/common/types';
import { useNilState } from '@/common/utils/helpers';
import { getImage } from '@/common/utils/loadImageFromUrl';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import { useUpdateProjectPlanPageMutation } from '@/mutations/projectPlanPage';
import { CRS, LatLng, LatLngBounds, LeafletMouseEvent, Renderer } from 'leaflet';
import isNil from 'lodash/isNil';
import React, { FC, useEffect, useState } from 'react';
import { ImageOverlay, Map, MapProps } from 'react-leaflet';
import { useTool } from '@/components/takeoff/hooks/useTool';

// The line below is a workaround. On Chrome, when double clicking a geometry
// on a sheet to edit it, some invisible part of the site would get selected.
// Starting to drag (in order to select multiple vertices or to move a vertice)
// would trigger the event below instead of the actual event planned for
// this action. It's difficult to foresee how this workaround may affect other
// parts of the application, but it does the job for now.
document.ondragstart = (): boolean => false;

type MapOptions = Omit<MapProps, 'bounds' | 'children'> & {
    bounds: LatLngBounds;
};

export const SheetViewer: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const {
        project,
        currentPage,
        setCurrentPage,
        loadingCurrentPage,
        setLoadingCurrentPage,
        mapRef,
        setMapRef,
        setContextMenuPayload,
        setTool,
        copy,
        cut,
        paste,
        addGeometryVertex,
        renderer,
        undo,
        redo,
        updateHandlers,
        handleToolChange,
    } = useTakeoff();

    const tool = useTool();

    const geometries = geometriesObservable.value;

    const {
        data: {
            user: { roles },
        },
    } = useUser();

    const [usesPrerotation, setUsesPrerotation] = useState<boolean>(true);
    const [, updateProjectPlanPage] = useUpdateProjectPlanPageMutation();

    const { getUrl } = useStorage();

    const [imageOverlayRef, setImageOverlayRef] = useNilState<ImageOverlay>();

    const [sheet, setSheet] = useState('');

    const originalBounds = new LatLngBounds(
        new LatLng(0, 0),
        new LatLng(currentPage?.heightPx || 0, currentPage?.widthPx || 0)
    );

    useEffect(() => {
        if (!currentPage) return;
        setTool(ToolType.SELECTION);
        setLoadingCurrentPage(true);
        getUrl(
            project.parentProjectUuid || project.uuid || '',
            currentPage.projectPlan.parentProjectPlanFileUuid || currentPage.projectPlan.uuid,
            currentPage.pageId,
            currentPage.orientation,
            false
        )
            .then((url) => {
                if (sheet !== url) {
                    setSheet(url);
                } else {
                    setLoadingCurrentPage(false);
                }
                setUsesPrerotation(true);
            })
            // We still need to fall back to the old in-memory preloading for older plansets that weren't ingested in
            // all rotation variants
            .catch((_) => {
                getUrl(
                    project.parentProjectUuid || project.uuid || '',
                    currentPage.projectPlan.parentProjectPlanFileUuid ||
                        currentPage.projectPlan.uuid,
                    currentPage.pageId,
                    currentPage.orientation,
                    false,
                    true
                ).then((url) =>
                    getImage(url, currentPage.orientation, setSheet, () =>
                        setLoadingCurrentPage(false)
                    )
                );
                setUsesPrerotation(false);
            });
        enableDragging();
    }, [currentPage, currentPage?.assets.nodes]);

    const mapOptions: MapOptions = {
        maxBoundsViscosity: 1,
        zoomAnimation: false,
        crs: CRS.Simple,
        bounds: originalBounds,
        minZoom: -2.5,
        zoomSnap: 0.01,
        attributionControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        zoomControl: false,
        // All geometry events will trigger even when the mouse pointer is
        // 10 pixels away from the geometry itself, so that it's easier
        // for the estimators to do stuff on higher zoom levels.
        //
        // Due to a bug (https://github.com/Leaflet/Leaflet/issues/7105)
        // in Leaflet, we are currently forced to fall back to SVG
        // for rendering. This unfortunately ignores the tolerance option but
        // we have to wait until the issue is resolved.
        renderer: renderer as unknown as Renderer | undefined,
        dragging: true,
        scrollWheelZoom: true,
    };

    const [canDrawBoundary, setCanDrawBoundary] = useState<boolean>(false);

    const [boundary, setBoundary] = useState<LatLngBounds>();

    useEffect(() => {
        const subscription = subscribeToBoundary(setBoundary);
        return (): void => subscription.unsubscribe();
    }, []);

    const rotatePage = (): void => {
        if (!currentPage || !geometries) return;
        const newParams = findRotatedPageParameters(currentPage, usesPrerotation);
        geometriesObservable.next(geometries.map((geometry) => toRotated(geometry, newParams)));
        selectedGeometriesObservable.next({ geometries, points: [] });
        updateProjectPlanPage({
            id: parseInt(currentPage.id),
            markups: geometriesObservable.value
                ? (geometriesObservable.value.map((geometry) =>
                      toPlanPageGeometry(geometry)
                  ) as PlanPageGeometry<GeometryType>[])
                : undefined,
            ...newParams,
        }).then((_) => setCurrentPage((prev) => ({ ...prev, ...newParams } as PlanPage)));
    };

    const enableDragging = (): void => {
        mapRef?.leafletElement.dragging.enable();
        setCanDrawBoundary(false);
    };

    const disableDragging = (): void => {
        mapRef?.leafletElement.dragging.disable();
        setCanDrawBoundary(true);
    };

    const canDrag = (): boolean => mapRef?.leafletElement.dragging.enabled() || false;

    const zoomIn = (): void => {
        mapRef?.leafletElement.setZoom(mapRef.leafletElement.getZoom() + 1);
    };

    const zoomOut = (): void => {
        mapRef?.leafletElement.setZoom(mapRef.leafletElement.getZoom() - 1);
    };

    const resetZoom = (): void => {
        mapRef?.leafletElement.fitBounds(originalBounds);
    };

    const handleChangeViewport = (): void => {
        if (mapRef && !loadingCurrentPage && currentPage !== null && currentPage !== undefined) {
            const json = JSON.stringify({
                ...mapRef.viewport,
                currentPageId: currentPage.id,
                projectUUID: project.uuid,
            });
            const objJsonB64 = Buffer.from(json).toString('base64');
            base64ViewportObservable.next(objJsonB64);
        }
    };

    useEffect(() => {
        if (!loadingCurrentPage) handleChangeViewport();
    }, [loadingCurrentPage]);

    useEffect(() => {
        if (tool !== ToolType.SELECTION) {
            disableDragging();
        }
    }, [tool]);

    useEffect(() => {
        updateHandlers({
            COPY: (): void => copy(),
            CUT: (): void => cut(),
            PASTE: (): void => paste(),
            UNDO: (): void => undo(),
            REDO: (): void => redo(),
            ENABLE_DRAGGING: enableDragging,
            DISABLE_DRAGGING: disableDragging,
            ZOOM_IN: zoomIn,
            ZOOM_OUT: zoomOut,
            RESET_VIEW: resetZoom,
            OPEN_CALIBRATION: handleToolChange(ToolType.CALIBRATION),
            SELECTION: handleToolChange(ToolType.SELECTION),
            LINEAR: handleToolChange(ToolType.LINEAR),
            AREA: handleToolChange(ToolType.AREA),
            COUNT: handleToolChange(ToolType.COUNT),
        });
    }, [mapRef]);

    const updateCursorPosition = (e: LeafletMouseEvent): void => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        mapRef?.leafletElement.on('mousedown', handleSheetMouseDown);
        cursorPositionObservable.next(e.latlng);
    };

    const handleSheetRightClick = (e: LeafletMouseEvent): void => {
        !isAddingCoordinatesObservable.value &&
            setContextMenuPayload({
                coordinates: e.latlng,
                dispatcher: ContextMenuDispatcherType.SHEET,
            });
    };

    const handleSheetMouseDown = (e: LeafletMouseEvent): void => {
        // Geometry editing can override default onmousemove which sets
        // cursorPosition, so we make sure that any click on the map gets
        // things back to normal.
        mapRef?.leafletElement.on('mousemove', updateCursorPosition);
        cursorPositionObservable.next(e.latlng);
        if (
            canDrawBoundary &&
            !isAddingCoordinatesObservable.value &&
            toolObservable.value === ToolType.SELECTION &&
            e.originalEvent.button === 0
        ) {
            boundaryStartPositionObservable.next(e.latlng);
        }
    };

    const handleSheetMouseUp = (): void => {
        if (!geometries) {
            return;
        }
        mapRef?.leafletElement.on('mousemove', updateCursorPosition);
        mapRef?.leafletElement.on('mousedown', handleSheetMouseDown);
        // If we have just finished drawing, go back to the selection tool
        // Unless we're calibrating or detecting, then don't do anything.
        if (
            tool !== ToolType.COUNT &&
            tool !== ToolType.SELECTION &&
            tool !== ToolType.CALIBRATION &&
            !isAddingCoordinatesObservable.value
        ) {
            setTool(ToolType.SELECTION);
            mapRef?.container?.focus();
            // otherwise, check whether any geometries were selected
        } else if (
            canDrawBoundary &&
            boundary &&
            !isAddingCoordinatesObservable.value &&
            tool === ToolType.SELECTION
        ) {
            if (isNil(editedGeometryObservable.value)) {
                const selectedGeometries = selectGeometries(boundary, geometries);
                selectedGeometriesObservable.next(selectedGeometries);
                addBoundaryGeometriesByIdObservable.next({
                    geometryIDs: new Set(selectedGeometries.geometries.map(({ uuid }) => uuid)),
                    pointIDs: new Set(selectedGeometries.points.map(({ id }) => id)),
                });
            } else {
                const selectedVertices = selectVertices(
                    boundary,
                    geometries.find(
                        (g: Geometry): boolean => g.uuid === editedGeometryObservable.value?.uuid
                    )
                );
                if (selectedVertices.length === 0 && activeVerticesObservable.value.length === 0) {
                    selectedGeometriesObservable.next({ geometries: [], points: [] });
                    editedGeometryObservable.next(null);
                    boundaryDataObservable.next([]);
                }
                activeVerticesObservable.next(selectedVertices);
            }
        }
        boundaryStartPositionObservable.next(undefined);
    };

    const handleSheetClick = (e: LeafletMouseEvent): void => {
        if (project !== null && project !== undefined) {
            if ([IUserRole.Builder, IUserRole.Estimator].every((role) => roles.includes(role))) {
                track(TrackEventName.GeometriesAdded);
            }
            addGeometryVertex(e.latlng);
        }
    };

    imageOverlayRef?.leafletElement.on('load', () => {
        setLoadingCurrentPage(false);
    });

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            if (
                selectedGeometriesObservable.value.geometries.length === 0 &&
                selectedGeometriesObservable.value.points.length === 0
            ) {
                isDrawingRectangleObservable.next(true);
            }
        }
    };
    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            if (
                selectedGeometriesObservable.value.geometries.length === 0 &&
                selectedGeometriesObservable.value.points.length === 0
            ) {
                isDrawingRectangleObservable.next(false);
            }
        }
    };

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    return currentPage ? (
        <Map
            {...mapOptions}
            ref={(newRef: Map): void => setMapRef(newRef)}
            onmousemove={updateCursorPosition}
            onmoveend={handleChangeViewport}
            onclick={handleSheetClick}
            onmousedown={handleSheetMouseDown}
            onmouseup={handleSheetMouseUp}
            oncontextmenu={handleSheetRightClick}
            className="sheet-container"
            style={
                loadingCurrentPage
                    ? {
                          zIndex: -1,
                      }
                    : {}
            }
        >
            {mapRef && (
                <PanZoomControl
                    mapRef={mapRef}
                    originalBounds={originalBounds}
                    loading={loadingCurrentPage}
                    manageDragging={{
                        enableDragging,
                        disableDragging,
                        canDrag: canDrag(),
                    }}
                    rotatePage={rotatePage}
                    useTakeoff={useTakeoff}
                />
            )}
            <ImageOverlay
                ref={(newRef: ImageOverlay): void => setImageOverlayRef(newRef)}
                url={sheet}
                bounds={mapOptions.bounds}
            />
            <DrawingCursorGuidelines
                bounds={mapOptions.bounds}
                canDrawBoundary={canDrawBoundary}
                useTakeoff={useTakeoff}
            />
            <EditableShapeOverlay useTakeoff={useTakeoff} />
            <HelperOverlay canDrawBoundary={canDrawBoundary} useTakeoff={useTakeoff} />
            <BoundingBox />
            <Boundary />
        </Map>
    ) : (
        <></>
    );
};
