import React, { useEffect } from 'react';

import { useUser } from '../contexts/User';
import { AdminDashboard } from '../components/AdminDashboard';
import { Estimators as EstimatorList } from '../components/AdminDashboard/Estimators';
import { TopbarContent } from '../components/AdminDashboard/Estimators/TopbarContent';
import { IUserRole } from '@/graphql';
import { useUsers } from '@/common/hooks/useUsers';

export const Estimators = (): React.ReactElement => {
    const {
        data: { user },
    } = useUser();
    const { users, loading, setUsers } = useUsers({
        roles: [IUserRole.Estimator],
        lightUsers: true,
        showPaused: true,
    });

    const isAdmin = user?.roles?.includes(IUserRole.Admin);

    useEffect(() => {
        return (): void => {
            setUsers([]);
        };
    }, []);

    return (
        <>
            {isAdmin && (
                <AdminDashboard topbarContent={<TopbarContent />}>
                    <EstimatorList users={users} isLoading={loading} />
                </AdminDashboard>
            )}
        </>
    );
};
