import React from 'react';

import { AppBar } from '@/components/AppBar';
import Box, { BoxProps } from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { useUser } from '@/contexts/User';
import { AssemblyPanelBody } from './components/AssemblyPanelBody';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { LibraryCategory } from '@/components/AssemblyPanel/utils/types';
import { useEstimationLayout } from './hooks/useEstimationLayout';
import { useTakeoffMarkupSync } from './hooks/useTakeoffMarkupSync';
import { ReactComponent as MenuIcon } from '@/assets/icons/32/menu.svg';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';

export type EstimationLayoutChildrenProps = {
    executeThenRestoreScroll: (callback: () => Promise<unknown>) => void;
};

interface EstimationLayoutProps extends BoxProps {
    render: (args: EstimationLayoutChildrenProps) => JSX.Element;
}

export const EstimationLayout = ({ render, ...props }: EstimationLayoutProps) => {
    useTakeoffMarkupSync();

    const user = useUser();

    const { openedCategory } = useAssemblyPanel();
    const { assemblyPanelOpen, toggleAssemblyPanelIsOpen, isPrintView } = useEstimationLayout();
    const breakpoints = useBreakpoints();

    const executeThenRestoreScroll = (callback: () => Promise<unknown>): void => {
        // Save current position of scroll
        const prevValue = window.scrollY;
        //
        // // Ensure value gets resolved even if the function does not return a promise
        Promise.resolve(callback()).finally(() => {
            // Restore scroll to previous position
            window.scrollTo(0, prevValue);
        });
    };

    return (
        <Box {...props}>
            {!breakpoints.mobile && (
                <Drawer
                    anchor="left"
                    open={assemblyPanelOpen}
                    PaperProps={{
                        square: true,
                        sx: {
                            borderRadius: 0,
                            padding: 0,
                        },
                    }}
                    sx={{
                        flexShrink: 0,
                        width: '240px',
                        '& .MuiDrawer-paper': {
                            width: '426px',
                        },
                    }}
                    variant="persistent"
                >
                    <AssemblyPanelBody
                        sx={{
                            flex: 1,
                            overflow:
                                openedCategory === LibraryCategory.Geometries
                                    ? 'hidden'
                                    : undefined,
                        }}
                    />
                </Drawer>
            )}
            <Box
                sx={{
                    transition: (theme) =>
                        theme.transitions.create(['margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    ...(assemblyPanelOpen &&
                        !breakpoints.mobile && {
                            marginLeft: '426px',
                            transition: (theme) =>
                                theme.transitions.create(['margin'], {
                                    easing: theme.transitions.easing.easeOut,
                                    duration: theme.transitions.duration.enteringScreen,
                                }),
                        }),
                }}
            >
                {!user.data.anonymousUser && !isPrintView && (
                    <AppBar
                        hasUpgradeButton
                        position="relative"
                        StartIcon={
                            !assemblyPanelOpen &&
                            !breakpoints.mobile && (
                                <IconButton onClick={() => toggleAssemblyPanelIsOpen()}>
                                    <SvgIcon
                                        sx={{
                                            height: '20px',
                                            width: '20px',
                                        }}
                                        viewBox="0 0 32 32"
                                    >
                                        <MenuIcon />
                                    </SvgIcon>
                                </IconButton>
                            )
                        }
                    />
                )}
                <Box>
                    <Box>{render({ executeThenRestoreScroll })}</Box>
                </Box>
            </Box>
        </Box>
    );
};
