/* eslint-disable @typescript-eslint/indent */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { darken } from 'polished';

import { PrimaryButtonProps } from './index';
import { focusHighlight } from '@/common/styles/focusHighlight';

type ContainerProps = Pick<PrimaryButtonProps, 'variant' | 'color' | 'disabled'> & {
    hasIcon?: boolean;
};
export const Container = styled.button<ContainerProps>`
    cursor: pointer;

    color: #fff;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);

    border-radius: 4px;
    height: 2.25rem;
    padding: ${(props): string => (props.hasIcon ? '0 1.5rem 0 1rem' : '0 1.5rem')};

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    white-space: nowrap;

    ${focusHighlight}

    & > svg {
        width: 16.25px;
        margin-right: 8px;

        & > path {
            fill: #fff;
        }
    }

    ${(props): FlattenSimpleInterpolation | string =>
        props.variant === 'outlined'
            ? css`
                  transition: border-color 0.4s;
                  background: transparent;
                  border: 1px solid rgba(255, 255, 255, 0.4);
                  box-sizing: border-box;

                  transition: border-color 0.4s;

                  &:hover {
                      border-color: ${darken(0.08, 'rgba(255, 255, 255, 0.4)')};
                  }
              `
            : ''}

    ${(props): FlattenSimpleInterpolation | string =>
        props.disabled
            ? css`
                  cursor: not-allowed;
                  opacity: 0.5;
              `
            : ''}
`;
