import React, { FC } from 'react';
import { AppBarProps as MUIAppBarProps } from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { Link as RRDLink } from 'react-router-dom';
import { useUser } from '@/contexts/User';
import { TrialActionButtons } from './TrialActionButtons';
import {
    BackSvgIcon,
    Clearfix,
    LogoIcon,
    MobileTrialButtonsWrapper,
    AppBar as MuiAppBar,
    Toolbar,
} from './styled';
import { useReferrer } from './useReferrer';
import {
    hasPaidSubscription,
    isAdministratorUser,
    isEstimatorPaused,
    isEstimatorUser,
    isNonsubscriber,
} from '@/views/Projects/utils';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { UserDropdown } from '@/components/ui/inputs/UserDropdown';
import { Layer, LayerProps } from '@/theme/Layer';
import { ReactComponent as LeftArrowIcon } from '@/assets/icons/32/arrow--left.svg';
import { ReactComponent as HelpIcon } from '@/assets/icons/32/help.svg';

const COPY = {
    paused: 'Account Paused',
    pausedMessage: `This account has been paused by a 1build admin.
        Project work can still be performed within a paused account.
        Paused accounts will not be assigned to new projects.
        Contact 1build if you feel like there has been a mistake.`,
};

interface AppBarProps extends MUIAppBarProps {
    hasBackButton?: boolean;
    hasUpgradeButton?: boolean;
    layerProps?: LayerProps;
    StartIcon?: React.ReactNode;
}

export const AppBar: FC<AppBarProps> = ({
    hasBackButton,
    hasUpgradeButton,
    layerProps,
    StartIcon,
    ...props
}) => {
    const referrer = useReferrer();
    const screenSize = useScreenSize();

    const user = useUser();

    return (
        <Layer variant="default" {...layerProps}>
            <MuiAppBar {...props}>
                {screenSize === ScreenSize.Mobile &&
                    !isAdministratorUser(user.data.user) &&
                    isNonsubscriber(user.data.user.team) &&
                    !hasPaidSubscription(user.data.user) && (
                        <MobileTrialButtonsWrapper>
                            <TrialActionButtons hasUpgradeButton={hasUpgradeButton} />
                        </MobileTrialButtonsWrapper>
                    )}

                <Toolbar>
                    <Stack direction="row" spacing="8px">
                        {StartIcon}
                        {hasBackButton && (
                            <Link component={RRDLink} replace to={referrer}>
                                <BackSvgIcon>
                                    <LeftArrowIcon />
                                </BackSvgIcon>
                            </Link>
                        )}
                        <Link component={RRDLink} to="/">
                            <LogoIcon />
                        </Link>
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing="16px">
                        {screenSize !== ScreenSize.Mobile &&
                            !isAdministratorUser(user.data.user) &&
                            isNonsubscriber(user.data.user.team) &&
                            !hasPaidSubscription(user.data.user) && (
                                <TrialActionButtons hasUpgradeButton={hasUpgradeButton} />
                            )}
                        {isEstimatorUser(user.data.user) && isEstimatorPaused(user.data.user) && (
                            <Tooltip title={COPY.pausedMessage}>
                                <Chip color="warning" label={COPY.paused} variant="outlined" />
                            </Tooltip>
                        )}
                        {Boolean(window.Intercom) && (
                            <Button
                                color="secondary"
                                endIcon={
                                    <SvgIcon
                                        sx={{
                                            width: '16px',
                                            height: '16px',
                                        }}
                                        viewBox="0 0 32 32"
                                    >
                                        <HelpIcon />
                                    </SvgIcon>
                                }
                                onClick={() => {
                                    window.Intercom('show');
                                }}
                                size="small"
                                variant="outlined"
                            >
                                Get Help
                            </Button>
                        )}
                        <UserDropdown />
                    </Stack>
                </Toolbar>
            </MuiAppBar>

            {/** If fixed, add placeholder to clearfix content below the nav. */}
            {(!props.position || props.position === 'fixed') && <Clearfix />}
        </Layer>
    );
};
