/*
 * observables.ts initializes and provides access to RxJS observables used to
 * interface with the admin dashboard's projects module
 */
import { Subject, Subscription } from 'rxjs';

import { OpenedProjectPayload } from '@/components/AdminDashboard/ProjectPanel/context';

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

// A project is opened in the project panel
export const openedProjectObservable = new Subject<OpenedProjectPayload | null>();

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToOpenedProjectObservable = (
    subscribe: (payload: OpenedProjectPayload | null) => void
): Subscription => openedProjectObservable.subscribe(subscribe);
