/*
 * context.ts provides the project details context. This code was adapted from the old
 * global projects context. It manages project details, events, files, and chat.
 */

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useEffect, useState, useMemo } from 'react';
import { OperationResult, useMutation } from 'urql';

import { Hook, PricingStatus, Setter } from '@/common/types';
import { createZipFileName } from '@/common/utils/helpers';
import { ContextType, makeContext } from '@/common/utils/makeContext';
import { useStorage } from '@/contexts/Storage';
import {
    IProjectLocationFragment,
    IProjectLocationAssignmentInput,
    IStripeSubscriptionFragment,
} from '@/graphql';
import { createEstimateMutation } from '@/mutations/newEstimate';
import { createUploadMutation } from '@/mutations/newUpload';
import { removeEstimateMutation } from '@/mutations/removeEstimate';
import { removePlanMutation } from '@/mutations/removePlan';
import { removeUploadMutation } from '@/mutations/removeUpload';
import { BaseUserRecord } from '@/queries/baseUsers';
import { useEventsFullQuery, EventFullRecord } from '@/queries/events';
import { ProjectRecord, ProjectPricing } from '@/queries/projects';
import {
    ProjectEstimateFileRecord,
    useProjectEstimateFilesQuery,
} from '@/queries/projectEstimateFiles';
import { ProjectPlanFileRecord, useProjectPlanFilesQuery } from '@/queries/projectPlanFiles';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';
import {
    useEventChangedFullSubscription,
    useEventCreatedFullSubscription,
} from '@/subscriptions/events';
import {
    useProjectPlanFileSubscription,
    useDeleteProjectPlanFileSubscription,
} from '@/subscriptions/projectPlanFiles';
import { useProjectSubscription } from '@/subscriptions/projects';
import {
    useProjectUploadFileSubscription,
    useDeleteProjectUploadFileSubscription,
} from '@/subscriptions/projectUploadFiles';
import {
    useProjectEstimateFileSubscription,
    useDeleteProjectEstimateFileSubscription,
} from '@/subscriptions/projectEstimateFiles';
import { useToggle } from '@/common/hooks/useToggle';

interface UploadOptions {
    statusAssignment?: () => void;
}

export interface ProjectDetailsContextProps {
    assignedTo?: BaseUserRecord;
    editedComment: EventFullRecord | null;
    setEditedComment: Setter<EventFullRecord | null>;
    estimateLinks: EventFullRecord[];
    estimates: ProjectEstimateFileRecord[];
    createEstimate: (
        ownerId: string | number,
        project: ProjectRecord,
        file: File,
        uuid: string
    ) => void;
    removeEstimate: (uuid: string) => Promise<OperationResult<void>>;
    handleDownloadAll: (project: ProjectRecord, estimateSection?: boolean) => void;
    isDownloading: { files: boolean; estimates: boolean };
    isInfoPanelOpen: boolean;
    links: EventFullRecord[];
    plans: ProjectPlanFileRecord[];
    closeInfoPanel: () => void;
    openInfoPanel: () => void;
    project: ProjectRecord;
    setProject: Setter<ProjectRecord>;
    projectLocation: Omit<IProjectLocationAssignmentInput, 'projectID'> | undefined;
    setProjectLocation: Setter<Omit<IProjectLocationAssignmentInput, 'projectID'> | undefined>;
    removePlan: (uuid: string) => Promise<OperationResult<void>>;
    uploads: ProjectUploadFileRecord[];
    createUpload: (
        ownerId: string | number,
        project: ProjectRecord,
        file: File,
        uuid: string,
        options?: UploadOptions
    ) => void;
    removeUpload: (uuid: string) => Promise<OperationResult<void>>;
    isLocationDropdownOpen: boolean;
    setIsLocationDropdownOpen: Setter<boolean>;
    stripeSubscription: IStripeSubscriptionFragment | null;
    pricingAssignment: ProjectPricing | undefined;
    pricingStatus: PricingStatus | null;
    setPricingStatus: Setter<PricingStatus>;
    declinePriceFeedback: string;
    setDeclinePriceFeedback: Setter<string>;
    isCancelProjectModalOpen: boolean;
    toggleCancelProjectModal: () => void;
    isApproveProjectPriceModalOpen: boolean;
    toggleApproveProjectPriceModal: () => void;
    isDeclineProjectPriceModalOpen: boolean;
    toggleDeclineProjectPriceModal: () => void;
    hasReasonForDeclinePrice: boolean;
    toggleHasReasonForDeclinePrice: () => void;
    isWaitingForBuilderApprovalModalOpen: boolean;
    setWaitingForBuilderApprovalModal: Setter<boolean>;
}

export type ProjectDetailsContextType = ContextType<ProjectDetailsContextProps>;

export interface ProjectDetailsComponentProps {
    useProjectDetails: Hook<ProjectDetailsContextProps, void>;
}

export const makeProjectDetailsContext = (
    projectInit: ProjectRecord,
    projectLocationInit: IProjectLocationFragment | null,
    stripeSubscription: IStripeSubscriptionFragment | null
): ProjectDetailsContextType => {
    return makeContext<ProjectDetailsContextProps>(() => {
        // Contexts
        //---------------------------------------------------------------------

        const { upload, getFileUrl } = useStorage();

        // State
        //---------------------------------------------------------------------

        const [links, setLinks] = useState<EventFullRecord[]>([]);
        const [plans, setPlans] = useState<ProjectPlanFileRecord[]>([]);
        const [estimateLinks, setEstimateLinks] = useState<EventFullRecord[]>([]);
        const [editedComment, setEditedComment] = useState<EventFullRecord | null>(null);
        const [estimates, setEstimates] = useState<ProjectEstimateFileRecord[]>([]);
        const [isDownloading, setIsDownloading] = useState({
            files: false,
            estimates: false,
        });
        const [isInfoPanelOpen, setInfoPanelOpen] = useState(false);
        const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
        const [project, setProject] = useState<ProjectRecord>(projectInit);
        const [uploads, setUploads] = useState<ProjectUploadFileRecord[]>([]);
        const [projectLocation, setProjectLocation] = useState<
            Omit<IProjectLocationAssignmentInput, 'projectID'> | undefined
        >(projectLocationInit ?? undefined);

        const assignedTo = useMemo(() => project?.projectUsers?.nodes[0]?.user, [project]);

        const pricingAssignment = useMemo(
            () =>
                project &&
                project.pricingAssignment?.projectPricingsByPricingAssignmentId &&
                project.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0],
            [project]
        );

        const [pricingStatus, setPricingStatus] = useState<PricingStatus>(
            PricingStatus.NEEDS_PRICE
        );
        const [declinePriceFeedback, setDeclinePriceFeedback] = useState('');

        const [isCancelProjectModalOpen, toggleCancelProjectModal] = useToggle();
        const [isApproveProjectPriceModalOpen, toggleApproveProjectPriceModal] = useToggle();
        const [isDeclineProjectPriceModalOpen, toggleDeclineProjectPriceModal] = useToggle();
        const [hasReasonForDeclinePrice, toggleHasReasonForDeclinePrice] = useToggle();
        const [isWaitingForBuilderApprovalModalOpen, setWaitingForBuilderApprovalModal] =
            useState(false);

        // Queries
        //---------------------------------------------------------------------

        const [eventsFullResult] = useEventsFullQuery(
            { projectId: project.id },
            { requestPolicy: 'cache-and-network' }
        );
        const [projectEstimateFilesResult] = useProjectEstimateFilesQuery(
            { projectId: project.id },
            { requestPolicy: 'cache-and-network' }
        );
        const [projectPlanFilesResult] = useProjectPlanFilesQuery(
            { projectId: project.id },
            { requestPolicy: 'cache-and-network' }
        );

        useEffect(() => {
            if (
                eventsFullResult.error ||
                eventsFullResult.fetching ||
                eventsFullResult.data === null ||
                eventsFullResult.data === undefined
            ) {
                return;
            }

            const linkEvents = eventsFullResult.data.events.nodes.filter(
                (node) => node.eventTypeName === 'hyperlink'
            );

            const estimateLinkEvents = eventsFullResult.data.events.nodes.filter(
                (node) => node.eventTypeName === 'deliverable_upload'
            );

            const uploadFiles = eventsFullResult.data.events.nodes
                .filter((node) =>
                    ['post_message', 'upload_project_related_files'].includes(node.eventTypeName)
                )
                .reduce<ProjectUploadFileRecord[]>(
                    (acc, e) => [...acc, ...e.projectUploadFiles.nodes],
                    []
                );

            setLinks(linkEvents);
            setEstimateLinks(estimateLinkEvents);
            setUploads(uploadFiles);
        }, [eventsFullResult]);

        useEffect(() => {
            if (
                projectPlanFilesResult.fetching ||
                !projectPlanFilesResult.data ||
                !projectPlanFilesResult.data.projectPlanFiles
            )
                return;
            setPlans(
                projectPlanFilesResult.data.projectPlanFiles.nodes.filter(
                    (p) => p.filename !== 'PLACEHOLDER'
                )
            );
        }, [projectPlanFilesResult]);

        useEffect(() => {
            if (
                projectEstimateFilesResult.fetching ||
                !projectEstimateFilesResult.data ||
                !projectEstimateFilesResult.data.projectEstimateFiles
            )
                return;
            setEstimates(projectEstimateFilesResult.data.projectEstimateFiles.nodes);
        }, [projectEstimateFilesResult]);

        // Subscriptions
        //---------------------------------------------------------------------

        const eventCreatedSubscriptionResult = useEventCreatedFullSubscription();
        const eventChangedSubscriptionResult = useEventChangedFullSubscription();
        const projectPlanFileResult = useProjectPlanFileSubscription();
        const deletedProjectPlanFileResult = useDeleteProjectPlanFileSubscription();
        const projectResult = useProjectSubscription();
        const projectUploadFileResult = useProjectUploadFileSubscription();
        const deletedProjectUploadFileResult = useDeleteProjectUploadFileSubscription();
        const projectEstimateFileResult = useProjectEstimateFileSubscription();
        const deletedProjectEstimateFileResult = useDeleteProjectEstimateFileSubscription();

        // New Events
        useEffect(() => {
            const event = eventCreatedSubscriptionResult.data?.EventCreated.eventLog;
            if (!event || event.projectId !== project.id) return;

            const isHyperlinkEvent = event.eventTypeName === 'hyperlink';
            const isEstimateHyperlinkEvent = event.eventTypeName === 'deliverable_upload';

            if (isHyperlinkEvent) {
                setLinks((prev) => [...prev, event]);
            } else if (isEstimateHyperlinkEvent) {
                setEstimateLinks((prev) => [...prev, event]);
            }
        }, [eventCreatedSubscriptionResult]);

        // Modified Events
        useEffect(() => {
            const event = eventChangedSubscriptionResult.data?.EventChanged.eventLog;

            if (!event || event.projectId !== project.id) return;

            const isRemoveHyperlinkEvent = event.eventTypeName === 'remove_hyperlink';
            const isRemoveEstimateHyperlinkEvent =
                event.eventTypeName === 'remove_deliverable_upload';

            if (isRemoveHyperlinkEvent) {
                setLinks((links) => {
                    return links.filter((link) => {
                        return link.id !== event.id;
                    });
                });
            } else if (isRemoveEstimateHyperlinkEvent) {
                setEstimateLinks((estimateLinks) => {
                    return estimateLinks.filter((estimateLink) => {
                        return estimateLink.id !== event.id;
                    });
                });
            }
        }, [eventChangedSubscriptionResult]);

        // New Project Plan Files
        useEffect(() => {
            const newProjectPlanFile =
                projectPlanFileResult?.data?.ProjectPlanFile.projectPlanFileEntry;
            if (
                !newProjectPlanFile ||
                newProjectPlanFile.projectId !== project.id ||
                newProjectPlanFile.filename === 'PLACEHOLDER'
            )
                return;
            setPlans((oldPlans) => oldPlans.concat(newProjectPlanFile));
        }, [projectPlanFileResult]);

        // Deleted Project Plan Files
        useEffect(() => {
            const deletedProjectPlanFile =
                deletedProjectPlanFileResult?.data?.DeleteProjectPlanFile
                    .deletedProjectPlanFileEntry;
            if (!deletedProjectPlanFile) return;
            setPlans((oldPlans) => oldPlans.filter((p) => p.uuid !== deletedProjectPlanFile.uuid));
        }, [deletedProjectPlanFileResult]);

        // Modified Project
        useEffect(() => {
            const updatedProject = projectResult.data?.ProjectChanged.projectEntry;
            if (!updatedProject || updatedProject.uuid !== project.uuid) return;
            setProject(updatedProject);
        }, [projectResult]);

        // New Project Upload Files
        useEffect(() => {
            const uploadedFile =
                projectUploadFileResult?.data?.ProjectUploadFile.projectUploadFileEntry;
            if (!uploadedFile || uploadedFile.event?.projectId !== project.id) return;
            setUploads((oldUploads) => [
                ...oldUploads.filter((u) => u.uuid !== uploadedFile.uuid),
                uploadedFile,
            ]);
        }, [projectUploadFileResult]);

        // Deleted Project Upload Files
        useEffect(() => {
            const deletedProjectUploadFile =
                deletedProjectUploadFileResult?.data?.DeleteProjectUploadFile
                    .deletedProjectUploadFileEntry;
            if (!deletedProjectUploadFile) return;
            setUploads((oldUploads) =>
                oldUploads.filter((u) => u.uuid !== deletedProjectUploadFile.uuid)
            );
        }, [deletedProjectUploadFileResult]);

        // New Project Estimate Files
        useEffect(() => {
            const newProjectEstimateFile =
                projectEstimateFileResult?.data?.ProjectEstimateFile.projectEstimateFileEntry;
            if (!newProjectEstimateFile || newProjectEstimateFile.projectId !== project.id) return;
            setEstimates((oldEstimates) => [
                ...oldEstimates.filter((e) => e.uuid !== newProjectEstimateFile.uuid),
                newProjectEstimateFile,
            ]);
        }, [projectEstimateFileResult]);

        // Deleted Project Estimate Files
        useEffect(() => {
            const deletedProjectEstimateFile =
                deletedProjectEstimateFileResult?.data?.DeleteProjectEstimateFile
                    .deletedProjectEstimateFileEntry;
            if (!deletedProjectEstimateFile) return;
            setEstimates((oldEstimates) =>
                oldEstimates.filter((e) => e.uuid !== deletedProjectEstimateFile.uuid)
            );
        }, [deletedProjectEstimateFileResult]);

        // Mutations
        //---------------------------------------------------------------------

        const [, createProjectEstimateFile] = useMutation<void>(createEstimateMutation);
        const [, createProjectUploadFile] = useMutation<void>(createUploadMutation);
        const [, removeEstimationFile] = useMutation<void>(removeEstimateMutation);
        const [, removeProjectPlanFile] = useMutation<void>(removePlanMutation);
        const [, removeProjectUploadFile] = useMutation<void>(removeUploadMutation);

        // Misc Functions
        //---------------------------------------------------------------------

        const closeInfoPanel = (): void => setInfoPanelOpen(false);
        const openInfoPanel = (): void => setInfoPanelOpen(true);

        const createEstimate = (
            _ownerId: string | number,
            project: ProjectRecord,
            file: File,
            uuid: string
        ): void => {
            /*
                Temporary upload placeholder, that serves the purpose of indicating
                file upload. Will be removed after the upload supbsciption fetches
                the actuall data.
            */
            setEstimates((estimates) => [
                ...estimates,
                {
                    id: 0,
                    filename: file.name,
                    uuid,
                    projectId: project.id,
                    __typename: 'ProjectEstimateFile',
                },
            ]);
            upload(file.name, project.uuid, uuid, 'estimates', file, { estimate: 'true' })
                .then(() => {
                    createProjectEstimateFile({
                        filename: file.name,
                        uuid,
                        projectId: project.id,
                    }).catch(() => {
                        setEstimates((estimates) => estimates.filter((e) => e.uuid !== uuid));
                    });
                })
                .catch(() => {
                    setEstimates((estimates) => estimates.filter((e) => e.uuid !== uuid));
                });
        };

        const createUpload = (
            ownerId: string | number,
            project: ProjectRecord,
            file: File,
            uuid: string,
            options?: UploadOptions
        ): void => {
            /*
                Temporary upload placeholder, that serves the purpose of indicating
                file upload. Will be removed after the upload supbsciption fetches
                the actual data.
            */
            setUploads((uploads) => [
                ...uploads,
                {
                    id: 0,
                    uuid,
                    filename: file.name,
                    __typename: 'ProjectUploadFile',
                },
            ]);
            upload(file.name, project.uuid, uuid, 'uploads', file)
                .then(async () => {
                    await createProjectUploadFile({
                        filename: file.name,
                        uuid,
                        ownerId,
                        projectId: project.id,
                    });

                    // Execute optional callbacks
                    options?.statusAssignment?.();
                })
                .catch((fileName) => {
                    setUploads((uploads) => uploads.filter((u) => u.filename !== fileName));
                });
        };

        const setLoaders = (estimateSection: boolean, state: boolean): void =>
            estimateSection
                ? setIsDownloading((prevState) => ({ ...prevState, estimates: state }))
                : setIsDownloading((prevState) => ({ ...prevState, files: state }));

        const handleDownloadAll = async (
            project: ProjectRecord,
            estimateSection = false
        ): Promise<void> => {
            setLoaders(estimateSection, true);
            let sectionFiles: Array<
                ProjectEstimateFileRecord | ProjectPlanFileRecord | ProjectUploadFileRecord
            > = estimateSection ? estimates : [...plans, ...uploads];
            // If it's the files section, we don't want to download .zip files because they were ingested and unpacked
            // during the process.
            if (!estimateSection) {
                sectionFiles = sectionFiles.filter(
                    (f) => !(f.filename?.includes('.zip') || f.filename?.includes('.ZIP'))
                );
            }
            const fileName = createZipFileName(estimateSection, project.name ?? 'unknown');
            if (sectionFiles.length === 0) return;
            // If it's just a single file, download it immediately.
            if (sectionFiles.length === 1) {
                getFileUrl(sectionFiles[0] as ProjectEstimateFileRecord, project.uuid).then(
                    (url) => {
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();
                    }
                );
            }
            const zip = new JSZip();
            await Promise.all(
                sectionFiles.map((file) => {
                    return getFileUrl(file, project.uuid)
                        .then((url) => {
                            return { file, signedS3Url: url };
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                })
            )
                .then((urls) =>
                    Promise.all(
                        urls.map((url) => {
                            if (!url.signedS3Url) return;
                            return fetch(url.signedS3Url)
                                .then((res) => res.blob())
                                .then((blob) => zip.file(url.file.filename ?? 'unknown', blob));
                        })
                    )
                )
                .then(() =>
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        saveAs(content, fileName);
                        setLoaders(estimateSection, false);
                    })
                )
                .catch((_) => {
                    setLoaders(estimateSection, false);
                });
        };

        const removeEstimate = (uuid: string): Promise<OperationResult<void>> =>
            removeEstimationFile({ uuid });
        const removePlan = (uuid: string): Promise<OperationResult<void>> =>
            removeProjectPlanFile({ uuid });
        const removeUpload = (uuid: string): Promise<OperationResult<void>> =>
            removeProjectUploadFile({ uuid });

        // Return values
        //---------------------------------------------------------------------

        return {
            assignedTo,
            editedComment,
            setEditedComment,
            estimateLinks,
            estimates,
            createEstimate,
            removeEstimate,
            handleDownloadAll,
            closeInfoPanel,
            openInfoPanel,
            isDownloading,
            isInfoPanelOpen,
            isLocationDropdownOpen,
            setIsLocationDropdownOpen,
            links,
            plans,
            removePlan,
            project,
            setProject,
            projectLocation,
            setProjectLocation,
            uploads,
            createUpload,
            removeUpload,
            stripeSubscription,
            pricingAssignment,
            pricingStatus,
            setPricingStatus,
            declinePriceFeedback,
            setDeclinePriceFeedback,
            isCancelProjectModalOpen,
            toggleCancelProjectModal,
            isApproveProjectPriceModalOpen,
            toggleApproveProjectPriceModal,
            isDeclineProjectPriceModalOpen,
            toggleDeclineProjectPriceModal,
            hasReasonForDeclinePrice,
            toggleHasReasonForDeclinePrice,
            isWaitingForBuilderApprovalModalOpen,
            setWaitingForBuilderApprovalModal,
        };
    });
};
