import { toWKX } from '@/common/convert/coordinateData';
import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

export const toTWKB = newGeometryHandler<Buffer | null>({
    [GeometryType.AREA]: (area) => {
        const polygon = toWKX.polygon(area.coordinates);
        const polygonIsValid =
            polygon.exteriorRing.length >= 3 &&
            polygon.interiorRings.every((interiorRing) => interiorRing.length >= 3);
        return polygonIsValid ? polygon.toTwkb() : null;
    },
    [GeometryType.COUNT]: (count) => {
        if (count.coordinates.length === 0) {
            return null;
        }
        return toWKX.multiPoint(count.coordinates).toTwkb();
    },
    [GeometryType.LINEAR]: (line) => {
        if (line.coordinates.length < 2) {
            return null;
        }
        return toWKX.lineString(line.coordinates).toTwkb();
    },
});
