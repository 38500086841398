import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled as styledByMui } from '@mui/material/styles';

export const Card = styledByMui(MuiCard)((options) => ({
    borderTop: `2px solid ${options.theme.palette.hues.orange['0']}`,
    borderRadius: 0,
    padding: '24px',
}));

export const CardHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const VariantHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
    marginBottom: 8,
    textTransform: 'capitalize',
}));

export const FeaturesHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const RenewalHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const PriceHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
    marginBottom: 8,
}));

export const Section = styledByMui('div')({
    marginBottom: 24,
});

export const SectionGroup = styledByMui('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
});

export const HighlightListItemIcon = styledByMui(ListItemIcon)((options) => ({
    color: options.theme.palette.hues.orange['0'],
}));
