import { createContext, useContext } from 'react';

import { EstimateProjectInfo } from '../utils/types';
import { Setter, WageType } from '@/common/types';

export interface EstimateProjectContext {
    projectID: number;
    projectUUID: string;
    projectInfo: EstimateProjectInfo;
    setProjectInfo: Setter<EstimateProjectInfo>;
    projectName: string;
    setProjectName: (name: string) => Promise<void>;
}

export const EstimateProjectContext = createContext<EstimateProjectContext>({
    projectID: -1,
    projectUUID: '',
    projectInfo: {
        wageType: WageType.UNION,
        projectLocation: null,
        squareFeetUnderRoof: null,
        squareFeetTotalLivable: null,
    },
    setProjectInfo: () => undefined,
    projectName: '',
    setProjectName: () => Promise.resolve(),
});

export const useEstimateProject = (): EstimateProjectContext => {
    return useContext(EstimateProjectContext);
};
