import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { useArea } from './useArea';
import { Setter, WageType } from '@/common/types';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { EstimateProjectInfo } from '@/components/Estimate/utils/types';
import {
    IProjectAreaFragment,
    IProjectLocationAssignmentMutation,
    IProjectLocationAssignmentMutationVariables,
    ProjectLocationAssignmentDocument,
} from '@/graphql';
import { useMutation } from '@apollo/client';

export const persistArea = debounce(
    (
        area: Omit<IProjectAreaFragment, 'id' | 'projectID'>,
        saveArea?: (newArea: Omit<IProjectAreaFragment, 'id' | 'projectID'>) => void
    ): void => {
        if (saveArea) {
            saveArea(area);
        }
    },
    500,
    { trailing: true }
);

interface UseProjectInfoPayload {
    fetching: boolean;
    projectInfo: EstimateProjectInfo;
    setProjectInfo: Setter<EstimateProjectInfo>;
}

export const useProjectInfo = (): UseProjectInfoPayload => {
    const { fetching, project, projectLocation } = useProjectFromParams();
    const [updateProjectLocation] = useMutation<
        IProjectLocationAssignmentMutation,
        IProjectLocationAssignmentMutationVariables
    >(ProjectLocationAssignmentDocument);
    const { area: fetchedArea, saveArea } = useArea({ projectId: project?.id });

    const [projectInfo, setProjectInfo] = useState<EstimateProjectInfo>({
        wageType: project?.wageType || WageType.NON_UNION,
        projectLocation: projectLocation ?? null,
        squareFeetTotalLivable: null,
        squareFeetUnderRoof: null,
    });

    useEffect(() => {
        setProjectInfo((oldProjectInfo) => ({
            ...oldProjectInfo,
            projectLocation: projectLocation ?? null,
        }));
    }, [projectLocation]);

    useEffect(() => {
        setProjectInfo((oldProjectInfo) => ({
            ...oldProjectInfo,
            squareFeetTotalLivable: fetchedArea?.squareFeetTotalLivable ?? null,
            squareFeetUnderRoof: fetchedArea?.squareFeetUnderRoof ?? null,
        }));
    }, [fetchedArea]);

    useEffect(() => {
        persistArea(
            {
                squareFeetUnderRoof: projectInfo.squareFeetUnderRoof,
                squareFeetTotalLivable: projectInfo.squareFeetTotalLivable,
            },
            saveArea
        );
    }, [projectInfo.squareFeetTotalLivable, projectInfo.squareFeetUnderRoof]);

    useEffect(() => {
        if (
            !projectInfo.projectLocation ||
            !project ||
            !projectInfo.projectLocation.formattedAddress
        ) {
            return;
        }
        const projectLocationAsInput = {
            ...projectInfo.projectLocation,
            id: undefined,
            __typename: undefined,
        };
        updateProjectLocation({
            variables: {
                input: {
                    ...projectLocationAsInput,
                    projectID: project.id.toString(),
                },
            },
            refetchQueries: ['Assemblies'],
        });
    }, [projectInfo.projectLocation, project]);

    return {
        fetching,
        projectInfo,
        setProjectInfo,
    };
};
