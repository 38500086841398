/*
 * Container is the master container for the projects panel.
 */
import React, { FC, useEffect, useState } from 'react';

import { markEventsSeen } from './Activity/utils';
import { Header } from './Header';
import { Module } from './Module';
import { Navbar } from './Navbar';
import { ProjectPanelComponentProps } from './context';
import { subscribeToActivityModuleClosedObservable } from './observables';
import { PanelDivider, PanelProjectName } from './styled';

import { RightSidePanel } from '@/components/ui/controls/RightSidePanel';
import { subscribeToOpenedProjectObservable } from '@/interfaces/adminDashboardProjects';
import { useProjectSubscription } from '@/subscriptions/projects';

export interface ContainerProps extends ProjectPanelComponentProps {
    closeProjectPanel: () => void;
}

export const Container: FC<ContainerProps> = ({ closeProjectPanel, useProjectPanel }) => {
    const {
        clickedOutside,
        entryIdsMarkedSeen,
        setEntryIdsMarkedSeen,
        entryIdsMarkedUnseen,
        setEntryIdsMarkedUnseen,
        project,
        setProject,
    } = useProjectPanel();

    const [activityModuleClosed, setActivityModuleClosed] = useState(false);
    const [activityModuleClosing, setActivityModuleClosing] = useState(false);
    const [isSwungOut, setIsSwungOut] = useState(true);

    const projectSubscriptionResult = useProjectSubscription();

    useEffect(() => {
        const newProject = projectSubscriptionResult.data?.ProjectChanged.projectEntry;
        if (newProject && newProject.uuid === project.uuid) {
            setProject(newProject);
        }
    }, [projectSubscriptionResult]);

    useEffect(() => {
        const subscription = subscribeToActivityModuleClosedObservable(() =>
            setActivityModuleClosed(true)
        );
        return (): void => subscription.unsubscribe();
    }, []);

    const handleActivityPanelClosed = (closeEntirePanel: boolean): void => {
        if (activityModuleClosing) {
            return;
        }
        setActivityModuleClosed(false);
        setActivityModuleClosing(true);
        Promise.all([
            markEventsSeen(true, [...entryIdsMarkedSeen]),
            markEventsSeen(false, [...entryIdsMarkedUnseen]),
        ]).then(() => !closeEntirePanel && setActivityModuleClosing(false));

        if (closeEntirePanel) {
            closeProjectPanel();
        } else {
            setEntryIdsMarkedSeen(new Set());
            setEntryIdsMarkedUnseen(new Set());
        }
    };

    const handleCloseProjectPanel = (e: React.MouseEvent): void => {
        if (e.defaultPrevented) {
            return;
        }
        handleActivityPanelClosed(true);
    };

    useEffect(() => {
        if (activityModuleClosed) {
            handleActivityPanelClosed(false);
        }
    }, [activityModuleClosed]);

    useEffect(() => {
        if (!clickedOutside) {
            return;
        }
        setIsSwungOut(false);

        // Close the panel after half a second, allowing the swing out animation to complete.
        // If a project is opened during this period, don't close the panel.
        let timeoutID: number | null = null;
        const subscription = subscribeToOpenedProjectObservable(
            () => timeoutID && clearTimeout(timeoutID)
        );
        timeoutID = window.setTimeout(() => {
            subscription.unsubscribe();
            handleActivityPanelClosed(true);
        }, 500);
    }, [clickedOutside]);

    const renderHeader = (
        <Header closeProjectPanel={handleCloseProjectPanel} useProjectPanel={useProjectPanel} />
    );

    return (
        <RightSidePanel isPanelOpened={isSwungOut} panelHeaderChildren={renderHeader}>
            <PanelDivider />
            <PanelProjectName>{project.name}</PanelProjectName>
            <Navbar useProjectPanel={useProjectPanel} />
            <PanelDivider />
            <Module useProjectPanel={useProjectPanel} />
        </RightSidePanel>
    );
};
