/*
 * useAllProjects.ts provides up-to-date data for all accessable projects.
 */
import { useEffect, useState } from 'react';

import { CombinedError } from 'urql';

import { updateRecordArr } from '@/common/utils/helpers';
import { ProjectWithEventsRecord, useProjectsWithEventsQuery } from '@/queries/projects';
import { useProjectWithEventsSubscription } from '@/subscriptions/projects';

interface UseAllProjectsValues {
    error?: CombinedError;
    fetching: boolean;
    projects: ProjectWithEventsRecord[];
}

export const useAllProjects = (): UseAllProjectsValues => {
    const [error, setError] = useState<CombinedError>();
    const [fetching, setFetching] = useState(false);
    const [projects, setProjects] = useState<ProjectWithEventsRecord[]>([]);

    const [projectsResult] = useProjectsWithEventsQuery(
        {},
        {
            requestPolicy: 'network-only',
        }
    );
    const projectsSubscriptionResult = useProjectWithEventsSubscription();

    useEffect(() => {
        setError(projectsResult.error);
        setFetching(projectsResult.fetching);
        if (projectsResult.error || projectsResult.fetching) {
            return;
        }
        setProjects(projectsResult.data?.projects.nodes.filter((n) => !n.demoProject) ?? []);
    }, [projectsResult]);

    useEffect(() => {
        const changedProject = projectsSubscriptionResult?.data?.ProjectChanged.projectEntry;
        if (changedProject === undefined || changedProject === null) {
            return;
        }
        setProjects((projects) => [...updateRecordArr(projects, changedProject)]);
    }, [projectsSubscriptionResult]);

    return {
        error,
        fetching,
        projects,
    };
};
