import { useState } from 'react';

import { formatters } from '@/common/currency';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProjectPricingMutation } from '@/graphql';
import { ProjectRecord } from '@/queries/projects';

export interface EstimationBoxForm {
    estimatedPrice: string;
    estimatedMaxHours: string;
    dueDate: string;
    formSubmitted?: boolean;
    success?: boolean;
}

export type EstimationBoxFormPayload = {
    values: EstimationBoxForm;
    errors: Record<string, string>;
    onChange: (name: string, value: string) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    isFormValid: () => boolean;
};

// useProjectPricing provides a hook to modify a project's pricing and return the updated project record.
export const useProjectPricing = (): ((
    oldProject: ProjectRecord,
    newPrice: number
) => Promise<ProjectRecord>) => {
    const [mutateProjectPricing] = useProjectPricingMutation();
    const { builderAcceptance } = useFlags();

    return async (oldProject: ProjectRecord, newPrice: number): Promise<ProjectRecord> => {
        const oldPriceUsdCents =
            oldProject &&
            oldProject.pricingAssignment &&
            oldProject.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0]
                .priceUsdCents;

        const result = await mutateProjectPricing({
            variables: {
                input: {
                    id: oldProject.id.toString(),
                    price: newPrice,
                    overrideBuilderAcceptance: !builderAcceptance,
                },
            },
        });
        if (result.errors) {
            throw result.errors.join();
        }
        if (oldProject.team === undefined || oldProject.team.balance === undefined) {
            return oldProject;
        }

        // TODO: when the pricing is added to the query we should hook it up instead of assigning the new price
        if (oldPriceUsdCents) {
            const element =
                oldProject.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0];
            element.priceUsdCents = newPrice;
        }

        return {
            ...oldProject,
            team: {
                ...oldProject.team,
                balance: {
                    ...oldProject.team?.balance,
                    usdCents:
                        (oldProject.team?.balance?.usdCents ?? 0) +
                        (oldPriceUsdCents ?? 0) -
                        newPrice,
                },
            },
        };
    };
};

export const useEstimationBoxForm = (
    callback: () => void,
    initialFormValues: EstimationBoxForm
): EstimationBoxFormPayload => {
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const validate = (fieldValues = values): void => {
        const currentErrors: Record<string, string> = { ...errors };

        if ('estimatedPrice' in fieldValues)
            currentErrors.estimatedPrice = fieldValues.estimatedPrice
                ? ''
                : 'This field is required.';

        if ('estimatedMaxHours' in fieldValues)
            currentErrors.estimatedMaxHours = fieldValues.estimatedMaxHours
                ? ''
                : 'This field is required.';

        if ('dueDate' in fieldValues)
            currentErrors.dueDate = fieldValues.dueDate ? '' : 'This field is required.';

        setErrors({
            ...currentErrors,
        });
    };

    const onChange = (name: string, value: string): void => {
        setValues({ ...values, [name]: value });
        validate({ [name]: value } as unknown as EstimationBoxForm);
    };

    const isFormValid = (fieldValues = values): boolean => {
        const isValid =
            fieldValues.estimatedPrice &&
            fieldValues.estimatedMaxHours &&
            fieldValues.dueDate &&
            Object.values(errors).every((x) => x === '');

        return isValid || false;
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!isFormValid()) {
            return;
        }

        callback();
    };

    return {
        values,
        errors,
        onChange,
        onSubmit,
        isFormValid,
    };
};

export interface IChargeValues {
    priceNetChange: number;
    teamBalance: number;
    centsToCharge: number;
    chargeString: string;
    chargedValue: string;
}

export const parseChargeValues = (
    project: ProjectRecord,
    priceUsdCents: number,
    modifiedPrice: number
): IChargeValues => {
    const priceNetChange = priceUsdCents - modifiedPrice;
    const teamBalance = project.team?.balance?.usdCents ?? 0;
    const isTeamBalanceBelowZero = teamBalance < 0;
    const centsToCharge = priceNetChange * -1;

    const formatter = formatters.usd();

    return {
        priceNetChange,
        teamBalance,
        centsToCharge,
        chargeString: formatter.format(
            (-1 * ((isTeamBalanceBelowZero ? 0 : teamBalance) - centsToCharge)) / 100
        ),
        chargedValue:
            priceNetChange > 0
                ? formatter.format(priceNetChange / 100)
                : formatter.format((-1 * priceNetChange) / 100),
    };
};
