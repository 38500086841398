/*
 * observables.ts initializes and provides access to RxJS observables used to
 * interface with the admin dashboard's project panel
 */
import { Subject, Subscription } from 'rxjs';

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

// The activity module was closed within the project panel
export const activityModuleClosedObservable = new Subject();

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToActivityModuleClosedObservable = (subscribe: () => void): Subscription =>
    activityModuleClosedObservable.subscribe(subscribe);
