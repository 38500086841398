import { useDedupedMarkupEntries } from './hooks/useDedupedMarkupEntries';
import { GeometryLists } from './GeometryLists';
import { GeometrySectionControls } from './GeometrySectionControls';
import { GeometrySectionEmptyState } from './GeometrySectionEmptyState';
import { GeometrySectionErrorState } from './GeometrySectionErrorState';
import { GeometrySectionLoadingState } from './GeometrySectionLoadingState';
import { NewGroupModal } from './NewGroupModal';
import { SelectModeBar } from './SelectModeBar';
import {
    addMarkupsToMarkupGroup,
    createNewMarkupGroup,
    deleteMarkupGroup,
    removeMarkupsFromGroup,
    updateMarkup,
    updateMarkupGroup,
} from './requests';
import { Root } from './styled';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { GeometrySectionMode } from './types';
import { isMarkup, isMarkupGroup, isNameTooLong, isNameTooShort } from './utils';
import { useMarkupCache } from '@/common/hooks/useMarkupCache';
import { Transforms } from '@/components/Estimate/transforms';
import { useEstimationEditor } from '@/components/app/router/EstimationRoute/hooks/useEstimationEditor';
import {
    ExpressionOpenedState,
    useEstimationExpressionOpenedState,
} from '@/components/app/router/EstimationRoute/hooks/useEstimationExpressionOpenedState';
import { useEstimationLayout } from '@/components/app/router/EstimationRoute/hooks/useEstimationLayout';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { useRefreshElementQuantities } from '@/components/app/router/EstimationRoute/hooks/useRefreshElementQuantities';
import { IMarkupFragment, useProjectWithMarkupEntriesQuery } from '@/graphql';
import { useApolloClient } from '@apollo/client';
import Stack from '@mui/material/Stack';
import to from 'await-to-js';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';

export enum TABS {
    ON_THIS_PAGE,
    IN_THIS_PROJECT,
}

export const GeometrySection: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const client = useApolloClient();

    const cache = useMarkupCache();

    const estimationEditor = useEstimationEditor();
    const estimationLayout = useEstimationLayout();
    const { projectID, projectUUID } = useEstimationProject();
    const { expressionOpenedState } = useEstimationExpressionOpenedState();
    const refreshElementQuantities = useRefreshElementQuantities();

    const [loading, setLoading] = useState(false);
    const [createNewGroupCallback, setCreateNewGroupCallback] = useState<
        ((name: string) => Promise<void>) | undefined
    >(undefined);
    const [tab, setTab] = useState(TABS.ON_THIS_PAGE);
    const [mode, setMode] = useState<GeometrySectionMode>('default');
    const [selectedMarkupEntryIds, setSelectedMarkupEntryIds] = useState<string[]>([]);
    const [showInThisProjectMarkups, setShowInThisProjectMarkups] = useState(false);
    const [showInThisProjectGroups, setShowInThisProjectGroups] = useState(true);

    const projectWithMarkupEntriesResult = useProjectWithMarkupEntriesQuery({
        variables: {
            uuid: projectUUID,
        },
    });

    const markupEntries = useDedupedMarkupEntries(
        projectWithMarkupEntriesResult.data?.project.markupEntries
    );

    useEffect(() => {
        const expressionIsOpen = [
            ExpressionOpenedState.FormulaOpen,
            ExpressionOpenedState.FormulaOpenWithHelper,
        ].includes(expressionOpenedState);

        // Always enter expression insertion mode when the expression is opened.
        // If we're already in expression insertion mode but the expression is
        // closed, reset to the default mode.
        if (expressionIsOpen) {
            setMode('insertIntoExpression');
            setSelectedMarkupEntryIds([]);
        } else if (mode === 'insertIntoExpression') {
            setMode('default');
        }
    }, [expressionOpenedState]);

    const handleTabChange = (tab: TABS) => setTab(tab);

    const handleNewGroupButtonClick = async (name: string, selectedMarkupEntryIds?: string[]) => {
        setLoading(true);

        const [markupGroupCreateError] = await to(
            createNewMarkupGroup(client, {
                markupIDs: selectedMarkupEntryIds ? new Set(selectedMarkupEntryIds) : undefined,
                projectID,
                name,
            })
        );

        // When a user create a new group, we want to:
        // close the modal,
        // change to "In this project tab" (as the new group will not be on page),
        // and finally make sure that we are showing groups.
        handleTabChange(TABS.IN_THIS_PROJECT);
        setShowInThisProjectGroups(true);
        setLoading(false);
        setCreateNewGroupCallback(undefined);

        if (markupGroupCreateError) {
            return;
        }
    };

    // We handle group creation modal by toggling the modal into active state when there
    // is an onClick handler present.
    // By specifying different callbacks, we can change the group creation according to the context
    // that the new group modal is invoked.
    // The () => syntax is to prevent react interpretting the callback as a (state) => newState
    // callback.
    const handleOpenModalFromNewGroupButton = () =>
        setCreateNewGroupCallback(() => handleNewGroupButtonClick);
    const handleOpenModalFromMarkup = (markup: IMarkupFragment) =>
        setCreateNewGroupCallback(
            () => (name: string) => handleNewGroupButtonClick(name, [markup.id])
        );
    const handleOpenModalFromSelectBar = (selectedMarkupIDs: string[]) =>
        setCreateNewGroupCallback(() => async (name: string) => {
            await handleNewGroupButtonClick(name, selectedMarkupIDs);
            setMode('default');
        });

    const handleShowInThisProjectMarkupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInThisProjectMarkups(event.target.checked);
    };

    const handleShowInThisProjectGroupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInThisProjectGroups(event.target.checked);
    };

    const handleSelectButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        if (mode === 'select') {
            setMode('default');
            setSelectedMarkupEntryIds([]);
        } else {
            setMode('select');
            setSelectedMarkupEntryIds([]);
        }
    };

    const handleSelectModeBarCloseIconClick = () => {
        setMode('default');
        setSelectedMarkupEntryIds([]);
    };

    const handleListItemClick: ListItemClickHandler = (event, options) => {
        if (!options?.markupEntry) {
            return;
        }

        if (mode === 'insertIntoExpression') {
            if (isMarkupGroup(options.markupEntry)) {
                Transforms.insertMarkupGroupIntoExpression(
                    estimationEditor,
                    options.markupEntry.id
                );
            } else if (isMarkup(options.markupEntry)) {
                Transforms.insertMarkupIntoExpression(estimationEditor, options.markupEntry.id);
            }
        } else if (mode === 'select') {
            const checked = selectedMarkupEntryIds?.includes(options.markupEntry.id);

            const nextSelectedMarkupEntryIds = checked
                ? selectedMarkupEntryIds.filter((value) => value !== options.markupEntry?.id)
                : selectedMarkupEntryIds.concat(options.markupEntry.id);

            setSelectedMarkupEntryIds(nextSelectedMarkupEntryIds);
        } else if (mode === 'default') {
            cache.selectMarkup(options.markupEntry.id);
        }
    };

    const handleListItemNameBlur: ListItemNameBlurHandler = async (event, options) => {
        const isNameSame = options.name === options.markupEntry?.name;

        if (isNameSame || isNameTooShort(options.name) || isNameTooLong(options.name)) {
            setMode('default');
            return;
        }

        if (isMarkup(options.markupEntry)) {
            setLoading(true);

            const [updateMarkupError] = await to(
                updateMarkup(client, {
                    markupID: options.markupEntry.id,
                    fields: {
                        name: options.name,
                    },
                    projectID,
                })
            );

            setLoading(false);

            if (updateMarkupError) {
                return;
            }
        } else if (isMarkupGroup(options?.markupEntry)) {
            setLoading(true);

            const [updateMarkupGroupError] = await to(
                updateMarkupGroup(client, {
                    markupGroupID: options.markupEntry.id,
                    fields: {
                        name: options.name,
                    },
                    projectID,
                })
            );

            setLoading(false);

            if (updateMarkupGroupError) {
                return;
            }
        }

        setMode('default');
    };

    const handleAddMarkupToGroup = async (markupID: string, markupGroupID: string) => {
        setLoading(true);

        const result = await addMarkupsToMarkupGroup(client, {
            markupGroupID,
            markupIDs: new Set([markupID]),
            projectID,
        });

        setLoading(false);

        if (result) {
            const modifiedMarkupGroupIDs = result.markupGroupAddMarkups.map(({ id }) => id);
            refreshElementQuantities({
                expressionFeatures: {
                    markupGroupIDs: new Set(modifiedMarkupGroupIDs),
                },
            });
        }
    };

    const handleDeleteMarkupGroup = async (markupGroupID: string) => {
        setLoading(true);

        const result = await deleteMarkupGroup(client, { markupGroupID, projectID });

        setLoading(false);

        if (result) {
            const deletedMarkupGroupID = result.markupGroupDelete.id;
            cache.removeMarkupGroup(deletedMarkupGroupID, projectID);
            refreshElementQuantities({
                expressionFeatures: {
                    markupGroupIDs: new Set([deletedMarkupGroupID]),
                },
            });
        }
    };

    const handleRemoveMarkupsFromGroup = async (markupGroupID: string, markupIDs: string[]) => {
        setLoading(true);

        const result = await removeMarkupsFromGroup(client, {
            projectID,
            markupGroupID,
            markupIDs,
        });

        setLoading(false);

        if (result) {
            const modifiedMarkupGroupIDs = result.markupGroupRemoveMarkups.map(({ id }) => id);

            cache.removeMarkupsFromMarkupGroup(markupGroupID, new Set(markupIDs), projectID);

            refreshElementQuantities({
                expressionFeatures: {
                    markupGroupIDs: new Set(modifiedMarkupGroupIDs),
                },
            });
        }
    };

    if (projectWithMarkupEntriesResult.loading) {
        return <GeometrySectionLoadingState />;
    }

    if (projectWithMarkupEntriesResult.error) {
        return <GeometrySectionErrorState />;
    }

    return (
        <Root {...props}>
            <Stack sx={{ height: '100%', overflow: 'hidden' }}>
                <GeometrySectionControls
                    newGroupButtonProps={{
                        disabled: loading,
                        onClick: handleOpenModalFromNewGroupButton,
                    }}
                    selectButtonProps={{
                        disabled:
                            loading || !markupEntries?.length || mode === 'insertIntoExpression',
                        onClick: handleSelectButtonClick,
                    }}
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                    }}
                />
                {(() => {
                    if (markupEntries?.length) {
                        return (
                            <>
                                <GeometryLists
                                    disabled={loading}
                                    markupEntries={markupEntries}
                                    mode={mode}
                                    planPageID={estimationLayout.planPageID}
                                    onAddMarkupToGroup={handleAddMarkupToGroup}
                                    onListItemClick={handleListItemClick}
                                    onListItemNameBlur={handleListItemNameBlur}
                                    onCreateGroupFromMarkup={handleOpenModalFromMarkup}
                                    onDeleteMarkupGroup={handleDeleteMarkupGroup}
                                    onRemoveMarkupsFromGroup={handleRemoveMarkupsFromGroup}
                                    onShowInThisProjectMarkupsChange={
                                        handleShowInThisProjectMarkupsChange
                                    }
                                    onShowInThisProjectGroupsChange={
                                        handleShowInThisProjectGroupsChange
                                    }
                                    onTabChange={handleTabChange}
                                    selectedMarkupEntryIds={selectedMarkupEntryIds}
                                    showInThisProjectMarkups={showInThisProjectMarkups}
                                    showInThisProjectGroups={showInThisProjectGroups}
                                    tab={tab}
                                    view={estimationLayout.view}
                                />
                                <SelectModeBar
                                    disabled={loading}
                                    markupEntries={markupEntries}
                                    onCreateGroupIconClick={handleOpenModalFromSelectBar}
                                    onCloseIconClick={handleSelectModeBarCloseIconClick}
                                    selectedMarkupEntryIds={selectedMarkupEntryIds}
                                    sx={{
                                        margin: '8px 8px 0',
                                    }}
                                    transitionProps={{
                                        children: <></>,
                                        in: mode === 'select',
                                    }}
                                />
                            </>
                        );
                    } else {
                        return (
                            <GeometrySectionEmptyState
                                title="You haven't drawn any takeoffs yet. Use the drawing tools to draw takeoffs on top of your plans."
                                message="Any drawn takeoffs will appear here"
                                sx={{
                                    paddingTop: '12px',
                                }}
                            />
                        );
                    }
                })()}
                <NewGroupModal
                    open={!!createNewGroupCallback}
                    disabled={loading}
                    onConfirm={createNewGroupCallback}
                    onCancel={() => setCreateNewGroupCallback(undefined)}
                />
            </Stack>
        </Root>
    );
};
