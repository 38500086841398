/*
 * deserialize.ts provides a function which transforms a string of markdown
 * into a SlateJS Node array.
 */
import slate, { defaultNodeTypes } from '@1build/remark-slate';
import { OptionType } from '@1build/remark-slate/src/deserialize';
import markdown from 'remark-parse';
import { Descendant, Editor, Element, Node, Text } from 'slate';
import unified from 'unified';
import { Node as UnistNode } from 'unist';
import visit from 'unist-util-visit';

// emptySlateParagraph is a slate parargraph object with empty text.
export const emptySlateParagraph: Element = {
    type: 'paragraph',
    children: [{ text: '' }],
};

// flattenSoloParagraphs is a unifiedjs plugin to remove 'paragraph'
// nodes that are the only children of nodes of type `nodeType`.
export const flattenSoloParagraphs =
    (opts = { nodeType: defaultNodeTypes.listItem }): ((node: UnistNode) => void) =>
    (ast): void =>
        visit(ast, opts.nodeType, (node) => {
            /* eslint-disable @typescript-eslint/no-unsafe-member-access */
            if (
                Array.isArray(node.children) &&
                node.children.length === 1 &&
                node.children[0].type === 'paragraph'
            ) {
                node.children = node.children[0].children;
            }
            /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        });

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const isDescendantList = (list: any): list is Descendant[] =>
    Node.isNodeList(list) && list.every((node) => !Editor.isEditor(node));

export const deserialize = (
    md: string,
    opts: OptionType = { nodeTypes: defaultNodeTypes }
): Descendant[] => {
    if (md === '') {
        return [emptySlateParagraph];
    }

    const result = unified()
        .use(markdown, {})
        .use(flattenSoloParagraphs, { nodeType: 'listItem' })
        .use(slate, opts)
        .processSync(md);

    const res = result.result;
    if (!isDescendantList(res)) {
        throw new Error('Parsed markdown is not a NodeList.');
    }

    const firstNode = res[0];

    if (
        result.type === 'paragraph' &&
        res.length === 1 &&
        !Text.isText(firstNode) &&
        Node.isNodeList(firstNode.children)
    ) {
        return firstNode.children;
    }

    return res;
};
