import { DependencyList, useEffect } from 'react';

/**
 * Add an event listener to document w/ cleanup.
 * @param {K | K[]} eventListenerTypes - type of event listener to append to document.
 * @param {(this: Window, ev: WindowEventMap[K]) => any} callback - event handler
 * @param {DependencyList} deps - dependencies to update useCallback, so event listener
 * does not execute stale callback.
 * @param {AddEventListenerOptions?} options - additional options to supply to callback.
 *
 * @see {useDocumentListener} for document-level equivalent
 */
export const useWindowListener = <K extends keyof WindowEventMap>(
    eventListenerTypes: K | K[],
    callback: (this: Window, ev: WindowEventMap[K]) => any, // eslint-disable-line
    deps: DependencyList,
    options?: AddEventListenerOptions
): void => {
    // On event listener types or callback change, append event listeners to document
    // and remove stale event listeners.
    useEffect(() => {
        if (Array.isArray(eventListenerTypes)) {
            eventListenerTypes.forEach((eventListenerType) => {
                window.addEventListener(eventListenerType, callback, options);
            });
        } else {
            window.addEventListener(eventListenerTypes, callback, options);
        }

        // Remove stale event listeners on unmount.
        return (): void => {
            if (Array.isArray(eventListenerTypes)) {
                eventListenerTypes.forEach((eventListenerType) => {
                    window.removeEventListener(eventListenerType, callback);
                });
            } else {
                window.removeEventListener(eventListenerTypes, callback);
            }
        };
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
    }, [eventListenerTypes, callback, ...deps]);
};
