import { useRouteMatch } from 'react-router';

import { FormValidState } from './useFormValidate';
import { useAddWorkDetailsOnSignup } from './useAddWorkDetailsOnSignup';
import { SignupForm } from '..';
import { getTeamAssignmentInput } from '../util/getTeamAssignmentInput';
import { track, TrackEventName } from '@/common/analytics';
import { AllowedURLSignUpRole } from '@/common/types';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import { useSnackbar } from 'notistack';

const COPY = {
    genericError: 'Error signing up user',
    updateError: 'Error updating onboarding information',
};

interface UseHandleSignupUserArgs {
    formFieldErrorMessages: FormValidState;
    signupForm: SignupForm;
    updateValidationResults: () => void;
}

const isPureEstimatorRoles = (roles: IUserRole[]) =>
    roles.length === 1 && roles[0] === IUserRole.Estimator;

export const useHandleSignupUser = ({
    formFieldErrorMessages,
    signupForm,
    updateValidationResults,
}: UseHandleSignupUserArgs) => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        actions: { signUpUser },
    } = useUser();
    const addWorkDetailsOnSignup = useAddWorkDetailsOnSignup(signupForm);
    const match = useRouteMatch<{ role: AllowedURLSignUpRole }>();

    const handleSignupUser = async () => {
        const isFormValid = Object.values(formFieldErrorMessages).every(
            (formFieldErrorMessage) => formFieldErrorMessage === undefined
        );

        if (isFormValid) {
            const input = getTeamAssignmentInput(
                match.params.role || AllowedURLSignUpRole.Platform
            );

            try {
                const signedUpUser = await signUpUser({
                    ...input,
                    email: signupForm.email,
                    password: signupForm.password,
                    name: `${signupForm.firstName} ${signupForm.lastName}`,
                    code: undefined,
                });

                if (!signedUpUser) {
                    enqueueSnackbar(COPY.genericError, {
                        variant: 'error',
                        autoHideDuration: 5000,
                    });
                    return;
                }

                // For estimators, push directly to /.
                // We do not need to assign team
                if (!isPureEstimatorRoles(input.roles)) {
                    await addWorkDetailsOnSignup(signedUpUser);
                }

                track(TrackEventName.AppDetailsSubmitted);

                window.location.replace('/');
            } catch (e) {
                enqueueSnackbar(String(e), {
                    variant: 'error',
                    autoHideDuration: 5000,
                });
            }
        } else {
            updateValidationResults();
        }
    };

    return {
        handleSignupUser,
    };
};
