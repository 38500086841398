import React, { FC, useState } from 'react';

import clsx from 'clsx';

import {
    ConfirmationButtons,
    Divider,
    EstimationBoxContainer,
    EstimationBoxSubmit,
    HoursPlaceholder,
    InvalidFeedbackMessage,
    UpdatedValueText,
} from './styled';

import { useChat } from '@/common/hooks/useChat';
import { useModal } from '@/common/hooks/useModal';
import { useToggle } from '@/common/hooks/useToggle';
import { formatDate, isNumericInput } from '@/common/utils/helpers';
import { formatDollarsToCents } from '@/common/utils/helpers';
import { useEstimationBoxForm } from '@/components/AdminDashboard/ProjectPanel/Price/utils';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { Button } from '@/components/ui/buttons/Button';
import { Input } from '@/components/ui/inputs/Input';
import { NativeDatepicker } from '@/components/ui/inputs/NativeDatepicker';
import { FormControl } from '@/components/ui/inputs/helpers/FormControl';
import { CenteredSpinnerLoader } from '@/components/ui/loaders/CenteredSpinnerLoader';
import { Modal } from '@/components/ui/modals/Modal';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { useProjectPriceEstimationHistoryMutation } from '@/graphql';
import { useCreateEvent } from '@/mutations/event';
import { EventTypeName } from '@/queries/eventTypes';
import { BaseUserRecord } from '@/queries/baseUsers';
import { isPureEstimatorUser } from '@/views/Projects/utils';
import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const COPY = {
    estimatedPrice: 'Estimated price',
    estimatedMaxHours: 'Estimated max hours',
    dueDate: 'Due date',
    dueDatePlaceholder: 'Select date',
    confirmationModalTitle: 'Change project information',
    updateEstimationPriceMessage: 'Project information has been successfully updated',
    updateEstimationPriceErrorMessage: 'Something went wrong. Please try again.',
    quoteWarninig:
        'Warning: Changing hours and price will override any agreement between builder and estimator.',
};

const formatCents = (cents: number | undefined): string => {
    if (cents === undefined) {
        return '0';
    }

    const decimalString = (cents / 100).toFixed(2);

    // If the number has no cents, chop 'em off.
    if (/[.]0{2}$/.test(decimalString)) {
        return decimalString.slice(0, -3);
    }
    return decimalString;
};

export const EstimationBox: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { project, pricingAssignment } = useProjectPanel();
    const [, createEvent] = useCreateEvent();
    const {
        data: { user },
    } = useUser();
    const { addMessage } = useChat(project);

    const [mutateProjectPriceEstimationHistory, { loading }] =
        useProjectPriceEstimationHistoryMutation();

    const { addNotification } = useNotifications();

    const priceUsdCents = pricingAssignment?.priceUsdCents;

    const initialFormValues = {
        estimatedPrice: formatCents(priceUsdCents),
        estimatedMaxHours: project.estimatorHours?.toString() ?? '',
        dueDate: project.estimateDueDate ?? project.bidsDueDate,
        formSubmitted: false,
        success: false,
    };

    const [updatedValues, setUpdatedValues] = useState({
        isPriceChanged: false,
        isHoursChanged: false,
        isDueDateChanged: false,
    });

    const [isEditingPrice, setIsEditingPrice] = useToggle();

    const { isOpen, toggle } = useModal();

    const { onSubmit, onChange, isFormValid, values, errors } = useEstimationBoxForm(() => {
        if (isFormValid() && hasDifferentValues()) {
            toggle();
        }
    }, initialFormValues);

    const renderUpdatedValues = (): JSX.Element => {
        return (
            <>
                {updatedValues.isPriceChanged && (
                    <UpdatedValueText>
                        <strong>{COPY.estimatedPrice}</strong> will be set to
                        <span>${values.estimatedPrice}</span>
                    </UpdatedValueText>
                )}

                {updatedValues.isHoursChanged && (
                    <UpdatedValueText>
                        <strong>{COPY.estimatedMaxHours}</strong> will be set to
                        <span>{values.estimatedMaxHours}</span>
                    </UpdatedValueText>
                )}

                {updatedValues.isDueDateChanged && (
                    <UpdatedValueText>
                        <strong>{COPY.dueDate}</strong> will be set to
                        <span>{formatDate(values.dueDate)}</span>
                    </UpdatedValueText>
                )}
            </>
        );
    };

    const hasDifferentValues = (): boolean => {
        return Object.values(updatedValues).filter((x) => !!x).length > 0;
    };

    const priceQuoteMessage = (price: string, dueDate: string): string => {
        let message = '';

        if (!priceUsdCents) {
            /* eslint-disable */
            message = `## Price Quoted!
Your estimator has reviewed the project and provided the price and schedule quote for this project.
Project: ${project.name ?? ''}
Schedule Quote: Estimate returned ${formatDate(dueDate ?? '')} if approved today
Price Quote: $${price}
Once it is approved, we will process payment and then proceed with work!
If you have questions about this quote, please send a chat message.
You can also call us at 240 713 5474.
Thank you!`;
        } else {
            message = `## New Price Quote!
Your estimator has reviewed the project and provided the price and schedule quote for this project.
Schedule Quote: Estimate returned ${formatDate(dueDate ?? '')} if approved today
Price Quote: $${price}

Once it is approved, we will process payment and then proceed with work!
If you have questions about this quote, please send a chat message.
You can also call us at 240 713 5474.
Thank you!`;
            /* eslint-enable */
        }

        return message;
    };

    const handleSaveProjectEstimationPrice = async (): Promise<void> => {
        if (!isFormValid() || !project) {
            return;
        }

        try {
            const { estimatedPrice, estimatedMaxHours, dueDate } = values;

            const dateParsed = new Date(dueDate).toISOString();

            await mutateProjectPriceEstimationHistory({
                variables: {
                    input: {
                        id: project.id.toString(),
                        price: formatDollarsToCents(estimatedPrice),
                        estimatorHours: Number(estimatedMaxHours),
                        bidsDueDate: dateParsed,
                    },
                },
                refetchQueries: ['Projects'],
            });

            addNotification(
                { title: 'Success', content: COPY.updateEstimationPriceMessage },
                'success',
                true
            );
            toggle();

            createEvent({
                eventType: EventTypeName.EditProject,
                message: `Project price changed to $${estimatedPrice}`,
                ownerId: Number(user.id),
                projectId: Number(project.id),
            });

            // show estimator name if assigned
            const assignedEstimatorId = project.projectUsers?.nodes?.find(
                (node: { user: BaseUserRecord }) => {
                    const mappedEstimator = mapBaseUserToIUserFragment(node.user);
                    return isPureEstimatorUser(mappedEstimator);
                }
            )?.user?.id;

            addMessage(
                assignedEstimatorId?.toString() ?? user.id,
                project.id.toString(),
                priceQuoteMessage(estimatedPrice, dateParsed),
                []
            );
        } catch {
            addNotification(
                { title: 'Error', content: COPY.updateEstimationPriceErrorMessage },
                'error',
                true
            );
        }
    };

    return (
        <EstimationBoxContainer>
            <form onSubmit={onSubmit}>
                <FormControl
                    className="estimation-price-form-control"
                    label={COPY.estimatedPrice}
                    value={values.estimatedPrice}
                >
                    {isEditingPrice ? (
                        <Input
                            className={clsx(
                                {
                                    'is-invalid': !!errors['estimatedPrice'],
                                },
                                'form-input'
                            )}
                            value={values.estimatedPrice}
                            onBlur={(): void => setIsEditingPrice()}
                            onChange={(value: string): void => {
                                if (!isNumericInput(value)) {
                                    return;
                                }
                                onChange('estimatedPrice', value);
                                setUpdatedValues({
                                    ...updatedValues,
                                    isPriceChanged:
                                        initialFormValues.estimatedPrice !== value ? true : false,
                                });
                            }}
                        />
                    ) : (
                        <Input
                            className="form-input"
                            value={'$' + values.estimatedPrice}
                            onChange={(): void => {
                                return;
                            }}
                            onFocus={(): void => setIsEditingPrice()}
                            readOnly
                        />
                    )}
                    <InvalidFeedbackMessage>
                        {errors['estimatedPrice'] ?? ''}
                    </InvalidFeedbackMessage>
                </FormControl>
                <Divider />
                <FormControl
                    className="estimation-price-form-control"
                    label={COPY.estimatedMaxHours}
                    value={values.estimatedMaxHours}
                >
                    <Input
                        className={clsx(
                            {
                                'is-invalid': !!errors['estimatedMaxHours'],
                            },
                            'form-input'
                        )}
                        value={values.estimatedMaxHours}
                        onChange={(value: string): void => {
                            if (!isNumericInput(value)) {
                                return;
                            }
                            onChange('estimatedMaxHours', value);
                            setUpdatedValues({
                                ...updatedValues,
                                isHoursChanged:
                                    initialFormValues.estimatedMaxHours !== value ? true : false,
                            });
                        }}
                    />
                    <HoursPlaceholder>Hours</HoursPlaceholder>
                    <InvalidFeedbackMessage>
                        {errors['estimatedMaxHours'] ?? ''}
                    </InvalidFeedbackMessage>
                </FormControl>
                <Divider />
                <FormControl
                    className="estimation-price-form-control"
                    label={COPY.dueDate}
                    value={values.dueDate}
                >
                    <NativeDatepicker
                        placeholder={COPY.dueDatePlaceholder}
                        value={String(values.dueDate).split('T')[0]}
                        onChange={(value: string): void => {
                            onChange('dueDate', value);
                            setUpdatedValues({
                                ...updatedValues,
                                isDueDateChanged:
                                    String(initialFormValues.dueDate).split('T')[0] !== value
                                        ? true
                                        : false,
                            });
                        }}
                        min={new Date().toISOString().split('T')[0]}
                        lightVariant
                    />

                    {errors['dueDate'] && (
                        <InvalidFeedbackMessage>{errors['dueDate']}</InvalidFeedbackMessage>
                    )}

                    <Box p="8px 0">
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.hues.red[65],
                                fontSize: '0.55rem',
                                lineHeight: '0.6rem',
                            }}
                        >
                            {COPY.quoteWarninig}
                        </Typography>
                    </Box>
                </FormControl>

                <EstimationBoxSubmit>
                    <Button variant="primary" type="submit" disabled={!hasDifferentValues()}>
                        Save
                    </Button>
                </EstimationBoxSubmit>
            </form>
            <Modal
                isOpen={isOpen}
                close={toggle}
                headerText={COPY.confirmationModalTitle}
                modalContent={
                    loading ? (
                        <CenteredSpinnerLoader style={{ padding: '0' }} />
                    ) : (
                        <React.Fragment>
                            {hasDifferentValues() && renderUpdatedValues()}
                            {project.team && `${project.team.name} will be notified`}
                            <ConfirmationButtons>
                                <Button variant="secondary" onClick={(): void => toggle()}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={async (): Promise<void> =>
                                        await handleSaveProjectEstimationPrice()
                                    }
                                >
                                    Confirm
                                </Button>
                            </ConfirmationButtons>
                        </React.Fragment>
                    )
                }
            />
        </EstimationBoxContainer>
    );
};
