import { ITeamBillingFragment, IProjectLightFragment } from '@/graphql';

export const getProjectSubtotal = (project?: IProjectLightFragment) => {
    if (!project?.prices?.edges) {
        return 0;
    }

    return project.prices.edges
        .map((edge) => edge.node)
        .reduce((total, price) => total + price.priceUSDCents, 0);
};

export const getProjectCredits = (team?: ITeamBillingFragment, project?: IProjectLightFragment) => {
    if (!team?.creditHistory?.edges) {
        return 0;
    }

    return team.creditHistory.edges
        .map((edge) => edge.node)
        .filter((credit) => credit.projectID === Number(project?.id))
        .reduce((total, credit) => total + credit.amount, 0);
};

export const getProjectTotal = (team?: ITeamBillingFragment, project?: IProjectLightFragment) => {
    return getProjectSubtotal(project) - getProjectCredits(team, project);
};
