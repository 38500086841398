import { Tokenizer, TokenizerResponse } from './tokenizer';
import { ExpressionElement } from '../utils/types';

export const lex = (expression: string): ExpressionElement[] => {
    const tokens: ExpressionElement[] = [];

    // Remove whitespace from expression before lexing.
    expression = expression.replace(/\s/g, '');
    // Allow things like '1,290' to be '1290'
    expression = expression.replaceAll(',', '');

    let res: TokenizerResponse | null = null;
    while (expression) {
        res = Tokenizer.parenthesis(expression);
        if (res !== null) {
            expression = expression.substring(res.raw.length);
            tokens.push(res.element);
            continue;
        }

        res = Tokenizer.operator(expression);
        if (res !== null) {
            expression = expression.substring(res.raw.length);
            tokens.push(res.element);
            continue;
        }

        res = Tokenizer.number(expression);
        if (res !== null) {
            expression = expression.substring(res.raw.length);
            tokens.push(res.element);
            continue;
        }

        // Upon failure to detect a token, remove the first character and try again.
        // We should remove this and throw an error once the system is more mature.
        if (expression) {
            expression = expression.substring(1);
        }
    }
    return tokens;
};
