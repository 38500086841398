import { css } from 'styled-components';

import { colorProjectPanelHighlight } from '@/variables';

/**
 * Create a simple outline on focus and active.
 * @returns {FlattenSimpleInterpolation} - css with outline.
 */
export const focusBorder = css`
    outline: none;
    border: 2px solid transparent;

    &:focus,
    &:active {
        opacity: 1;
        border-color: ${colorProjectPanelHighlight};
    }
`;
