import React, { FC, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { useEstimatePriceMarkups } from '../../../hooks/useEstimatePriceMarkups';
import { deletePriceMarkups } from '../../../utils/requests';
import { stringToNumber } from '../../../utils/transforms';
import { PriceMarkup } from '../../../utils/types';
import { MarkupInput, MarkupInputVariant } from './MarkupInput';
import { Delete, DeleteWrapper, Row } from './styled';
import { useMastheadGrandTotal } from '@/components/Estimate/Masthead/hooks/useMastheadTotal';

import { formatters } from '@/common/currency';

const formatter = formatters.usd();

const COPY = {
    enterName: 'Enter name of markup',
};

type MarkupRowProps = {
    markup: PriceMarkup;
    updateMarkup: (newValue: PriceMarkup) => void;
    blurNameInput: (newValue: PriceMarkup) => void;
};

export const MarkupRow: FC<MarkupRowProps> = ({
    markup: globalMarkup,
    updateMarkup,
    blurNameInput,
}) => {
    // State
    /*-------------------*/
    const client = useApolloClient();
    const { setPriceMarkups } = useEstimatePriceMarkups();
    const [markup, setMarkup] = useState<PriceMarkup>(globalMarkup);
    const { grandTotal, totalMarkups } = useMastheadGrandTotal();

    const totalCost = grandTotal - totalMarkups;

    // Helpers
    /*-------------------*/
    const updateRow = (newValue: PriceMarkup): void => {
        setMarkup(newValue);
    };

    const getTotalValue = (markup: PriceMarkup): string => {
        if (markup.total) {
            return formatter.format(Number(markup.total));
        } else {
            if (markup.percentage) {
                const total = (totalCost * Number(markup.percentage)) / 100;
                return formatter.format(total);
            } else {
                return '';
            }
        }
    };

    const normalizeNumberString = (value?: string): string => {
        if (!value) {
            return '';
        } else {
            return Number(value).toFixed(2);
        }
    };

    const propagateChanges = (): void => {
        updateMarkup({
            ...markup,
            percentage: normalizeNumberString(markup.percentage),
            total: normalizeNumberString(markup.total),
        });
    };

    const deleteProjectMarkup = (): void => {
        deletePriceMarkups(client, [markup.id]);
        setPriceMarkups((prevMarkups) =>
            prevMarkups.filter((prevMarkup) => prevMarkup.id !== markup.id)
        );
    };

    return (
        <Row hasDeleteButton>
            <MarkupInput
                placeholder={COPY.enterName}
                variant={MarkupInputVariant.Normal}
                value={markup.description}
                onChange={(event): void =>
                    updateRow({ ...markup, description: event.target.value })
                }
                onBlur={(): void => {
                    propagateChanges();
                    blurNameInput(markup);
                }}
            />
            <MarkupInput
                variant={
                    markup.percentage ? MarkupInputVariant.Percentage : MarkupInputVariant.Normal
                }
                value={markup.percentage ? markup.percentage : ''}
                onChange={(event): void => {
                    const newValue = stringToNumber(event.target.value, markup.percentage);
                    updateRow({ ...markup, percentage: newValue, total: undefined });
                }}
                onBlur={(): void => {
                    propagateChanges();
                    updateRow({ ...markup, percentage: normalizeNumberString(markup.percentage) });
                }}
            />
            <MarkupInput
                variant={
                    getTotalValue(markup) ? MarkupInputVariant.Dollars : MarkupInputVariant.Normal
                }
                value={getTotalValue(markup)}
                onChange={(event): void => {
                    const newValue = stringToNumber(event.target.value, markup.total);
                    updateRow({ ...markup, total: newValue, percentage: undefined });
                }}
                onBlur={(): void => {
                    propagateChanges();
                    updateRow({ ...markup, total: normalizeNumberString(markup.total) });
                }}
            />

            <DeleteWrapper onClick={deleteProjectMarkup}>
                <Delete />
            </DeleteWrapper>
        </Row>
    );
};
