import { useEffect, useState } from 'react';

import { OperationResult, useClient } from 'urql';

import { useDebouncedValue } from '../../../../common/hooks/useDebouncedValue';

import { DatabaseProjectStatus, Setter } from '@/common/types';
import { TabType } from '@/components/AdminDashboard/types';
import { useNotifications } from '@/contexts/Notifications';
import {
    PaginatedProjectsWithEventsQuery,
    ProjectWithEventsRecord,
    ProjectWithEventsRecords,
} from '@/queries/projects';

interface PaginatedProjectCondition {
    condition: {
        isSaas?: boolean;
    };
    options: {
        status?: DatabaseProjectStatus;
        name?: string;
    };
}

type PaginatedProjectWithEventsRecords = {
    projectsWithEvents: ProjectWithEventsRecords['projects'] & {
        pageInfo: {
            startCursor: string | undefined;
            endCursor: string | undefined;
            hasNextPage: boolean;
        };
    };
};

const generateCondition = (search: string, tabType: TabType): PaginatedProjectCondition => {
    const name = search || undefined;

    switch (tabType) {
        case TabType.SaaS: {
            return {
                condition: {
                    isSaas: true,
                },
                options: {
                    name,
                },
            };
        }

        case TabType.Completed: {
            return {
                condition: {
                    isSaas: false,
                },
                options: {
                    status: DatabaseProjectStatus.COMPLETE,
                    name,
                },
            };
        }

        default: {
            return {
                condition: {
                    isSaas: false,
                },
                options: {
                    name,
                },
            };
        }
    }
};

interface UsePaginatedProjectsWithEventsArgs {
    search: string;
    tabType: TabType;
    setProjects: Setter<ProjectWithEventsRecord[]>;
}
export const usePaginatedProjectsWithEvents = ({
    search,
    tabType,
    setProjects,
}: UsePaginatedProjectsWithEventsArgs) => {
    const client = useClient();
    const [isFetching, setIsFetching] = useState(false);
    const { addNotification } = useNotifications();
    const debouncedSearch = useDebouncedValue(search, 1000); // Prevent DDoSing ourself

    const [projectsResult, setProjectsResult] = useState<
        OperationResult<PaginatedProjectWithEventsRecords> | undefined
    >(undefined);

    /**
     * Fetch more projects.
     * @param {boolean} isOverride boolean indicating if fetch should be executed regardless.
     */
    const fetchMoreProjects = async (isOverride?: boolean) => {
        if (
            isOverride ||
            (!isFetching && projectsResult?.data?.projectsWithEvents.pageInfo.hasNextPage !== false)
        ) {
            setIsFetching(true);

            try {
                const res = await client
                    .query<PaginatedProjectWithEventsRecords>(PaginatedProjectsWithEventsQuery, {
                        // For override, we want to treat this as if this is a new
                        // query altogether, and ignore previous end cursors.
                        after: isOverride
                            ? undefined
                            : projectsResult?.data?.projectsWithEvents.pageInfo.endCursor,
                        first: 50,
                        orderBy: 'ID_DESC',
                        ...generateCondition(search, tabType),
                    })
                    .toPromise();

                const fetchedProjects = res?.data?.projectsWithEvents.nodes;

                if (res?.error) {
                    throw new Error(res.error.message);
                }

                if (!fetchedProjects) {
                    return;
                }

                setProjectsResult(res);
                setProjects((oldProjects) => [...fetchedProjects, ...oldProjects]);
            } catch (e) {
                addNotification(`Error fetching projects: ${String(e)}`, 'error');
            } finally {
                setIsFetching(false);
            }
        }
    };

    useEffect(() => {
        setProjects([]);
        fetchMoreProjects(true);
    }, [debouncedSearch, tabType]);

    return {
        fetchMoreProjects,
        isFetching,
    };
};
