import { LeydenEditor } from 'leyden';
import { ReactEditor } from 'leyden-react';
import { Editor } from 'slate';
import { HistoryEditor } from 'slate-history';

import {
    COLLAPSED_CATEGORIES,
    COLLAPSED_CATEGORIES_SUBSCRIBERS,
    ELEMENT_TOTALS,
    ELEMENT_TOTALS_THROTTLER,
    ELEMENT_TOTALS_SUBSCRIBERS,
    HOVERED_ROW,
    HOVERED_ROW_SUBSCRIBERS,
    HOVERED_ROW_THROTTLER,
} from '../editor/weakMaps';
import { categoryTotalFromElementTotals, estimateCostFromElementTotals } from '../utils/transforms';
import {
    CollapsedCategoriesSubscriber,
    ElementTotal,
    ElementTotalsSubscriber,
    EstimateCost,
    HoveredRowSubscriber,
    Unsubscriber,
} from '../utils/types';

export type EstimateEditor = LeydenEditor & ReactEditor & HistoryEditor;

export interface EstimateEditorInterface {
    categoryTotal: (editor: Editor, categoryID: string) => number;
    estimateCost: (editor: Editor) => EstimateCost;
    collapseCategory: (editor: Editor, categoryID: string) => void;
    expandCategory: (editor: Editor, categoryID: string) => void;
    toggleCategoryCollapsed: (editor: Editor, categoryID: string) => boolean;
    collapsedCategories: (editor: Editor) => Set<string>;
    isCategoryCollapsed: (editor: Editor, categoryID: string) => boolean;
    subscribeToCollapsedCategories: (
        editor: Editor,
        subscriber: CollapsedCategoriesSubscriber
    ) => Unsubscriber;
    clearHoveredRow: (editor: Editor) => void;
    setHoveredRow: (editor: Editor, row: number) => void;
    hoveredRow: (editor: Editor) => number | null;
    isRowHovered: (editor: Editor, row: number) => boolean;
    subscribeToHoveredRow: (editor: Editor, subscriber: HoveredRowSubscriber) => Unsubscriber;
    getElementTotal: (editor: Editor, elementID: string) => ElementTotal | null;
    recordElementTotal: (
        editor: Editor,
        elementID: string,
        tradeName: string | null,
        categoryID: string | null,
        val: number | null
    ) => void;
    subscribeToElementTotals: (
        editor: Editor,
        categoryID: string | null,
        subscriber: ElementTotalsSubscriber
    ) => Unsubscriber;
}

const HOVER_NOTIFICATION_THROTTLER_MS = 100;
const ELEMENT_TOTALS_NOTIFICATION_THROTTLER_MS = 100;

const notifyCollapsedCategoriesSubscribers = (editor: Editor): void => {
    const subscribers = COLLAPSED_CATEGORIES_SUBSCRIBERS.get(editor);
    if (subscribers === undefined) {
        return;
    }
    const collapsedCategories = COLLAPSED_CATEGORIES.get(editor) ?? new Set<string>();
    for (const subscriber of subscribers) {
        subscriber(collapsedCategories);
    }
};

const notifyHoverSubscribers = (editor: Editor): void => {
    const subscribers = HOVERED_ROW_SUBSCRIBERS.get(editor);
    if (subscribers === undefined) {
        return;
    }
    const placeholderThrottlerTimeout = setTimeout(() => {
        HOVERED_ROW_THROTTLER.delete(editor);
    }, HOVER_NOTIFICATION_THROTTLER_MS);
    HOVERED_ROW_THROTTLER.set(editor, {
        cancel: (): void => clearTimeout(placeholderThrottlerTimeout),
        initialized: Date.now(),
    });
    const hoveredRow = HOVERED_ROW.get(editor) ?? null;
    for (const subscriber of subscribers) {
        subscriber(hoveredRow);
    }
};

const recordHoveredRowChange = (editor: Editor): void => {
    const throttler = HOVERED_ROW_THROTTLER.get(editor);
    if (throttler === undefined) {
        notifyHoverSubscribers(editor);
        return;
    }
    throttler.cancel();
    const throttlerTimeout = setTimeout(() => {
        notifyHoverSubscribers(editor);
    }, Math.max(0, HOVER_NOTIFICATION_THROTTLER_MS - Date.now() + throttler.initialized));
    HOVERED_ROW_THROTTLER.set(editor, {
        cancel: (): void => clearTimeout(throttlerTimeout),
        initialized: throttler.initialized,
    });
};

const notifyElementTotalsSubscribers = (editor: Editor, categoryID: string | null): void => {
    const subscribers = ELEMENT_TOTALS_SUBSCRIBERS.get(editor);
    if (subscribers === undefined) {
        return;
    }
    const placeholderThrottlerTimeout = setTimeout(() => {
        ELEMENT_TOTALS_THROTTLER.delete(editor);
    }, ELEMENT_TOTALS_NOTIFICATION_THROTTLER_MS);
    ELEMENT_TOTALS_THROTTLER.set(editor, {
        cancel: (): void => clearTimeout(placeholderThrottlerTimeout),
        initialized: Date.now(),
    });
    const elementTotals = ELEMENT_TOTALS.get(editor) ?? new Map<string, ElementTotal>();
    for (const subscriber of subscribers) {
        if ([categoryID, null].includes(subscriber.categoryID)) {
            subscriber.subscriber(elementTotals);
        }
    }
};

const recordElementTotalsChange = (editor: Editor, categoryID: string | null): void => {
    const throttler = ELEMENT_TOTALS_THROTTLER.get(editor);
    if (throttler === undefined) {
        notifyElementTotalsSubscribers(editor, categoryID);
        return;
    }
    throttler.cancel();
    const throttlerTimeout = setTimeout(() => {
        notifyElementTotalsSubscribers(editor, categoryID);
    }, Math.max(0, ELEMENT_TOTALS_NOTIFICATION_THROTTLER_MS - Date.now() + throttler.initialized));
    ELEMENT_TOTALS_THROTTLER.set(editor, {
        cancel: (): void => clearTimeout(throttlerTimeout),
        initialized: throttler.initialized,
    });
};

export const EstimateEditor: EstimateEditorInterface = {
    categoryTotal(editor: Editor, categoryID: string): number {
        const totals = ELEMENT_TOTALS.get(editor);
        if (totals === undefined) {
            return 0;
        }
        return categoryTotalFromElementTotals(categoryID, totals);
    },

    estimateCost(editor: Editor): EstimateCost {
        const totals = ELEMENT_TOTALS.get(editor);
        if (totals === undefined) {
            return {
                direct: 0,
                generalConditions: 0,
                equipment: 0,
            };
        }
        return estimateCostFromElementTotals(totals);
    },

    collapsedCategories(editor: Editor): Set<string> {
        return COLLAPSED_CATEGORIES.get(editor) ?? new Set<string>();
    },

    isCategoryCollapsed(editor: Editor, categoryID: string): boolean {
        return EstimateEditor.collapsedCategories(editor).has(categoryID);
    },

    subscribeToCollapsedCategories(
        editor: Editor,
        subscriber: CollapsedCategoriesSubscriber
    ): Unsubscriber {
        const previousSubscribers = COLLAPSED_CATEGORIES_SUBSCRIBERS.get(editor);
        if (previousSubscribers) {
            previousSubscribers.add(subscriber);
        } else {
            COLLAPSED_CATEGORIES_SUBSCRIBERS.set(editor, new Set([subscriber]));
        }
        return (): void => {
            const previousSubscribers = COLLAPSED_CATEGORIES_SUBSCRIBERS.get(editor);
            if (previousSubscribers) {
                previousSubscribers.delete(subscriber);
            }
        };
    },

    collapseCategory(editor: Editor, categoryID: string): void {
        const collapsedCategories = COLLAPSED_CATEGORIES.get(editor);
        if (collapsedCategories && collapsedCategories.has(categoryID)) {
            return;
        }
        if (!collapsedCategories) {
            COLLAPSED_CATEGORIES.set(editor, new Set([categoryID]));
        } else {
            collapsedCategories.add(categoryID);
        }
        notifyCollapsedCategoriesSubscribers(editor);
    },

    expandCategory(editor: Editor, categoryID: string): void {
        const collapsedCategories = COLLAPSED_CATEGORIES.get(editor);
        if (!collapsedCategories || !collapsedCategories.has(categoryID)) {
            return;
        }
        collapsedCategories.delete(categoryID);
        notifyCollapsedCategoriesSubscribers(editor);
    },

    toggleCategoryCollapsed(editor: Editor, categoryID: string): boolean {
        const collapsedCategories = COLLAPSED_CATEGORIES.get(editor);
        if (!collapsedCategories || !collapsedCategories.has(categoryID)) {
            EstimateEditor.collapseCategory(editor, categoryID);
            return true;
        }
        EstimateEditor.expandCategory(editor, categoryID);
        return false;
    },

    clearHoveredRow(editor: Editor): void {
        const oldHoveredRow = HOVERED_ROW.get(editor);
        if (!oldHoveredRow) {
            return;
        }
        HOVERED_ROW.delete(editor);
        recordHoveredRowChange(editor);
    },

    setHoveredRow(editor: Editor, row: number): void {
        const oldHoveredRow = HOVERED_ROW.get(editor);
        if (oldHoveredRow === row) {
            return;
        }
        HOVERED_ROW.set(editor, row);
        recordHoveredRowChange(editor);
    },

    hoveredRow(editor: Editor): number | null {
        return HOVERED_ROW.get(editor) ?? null;
    },

    isRowHovered(editor: Editor, row: number): boolean {
        return EstimateEditor.hoveredRow(editor) === row;
    },

    subscribeToHoveredRow(editor: Editor, subscriber: HoveredRowSubscriber): Unsubscriber {
        const previousSubscribers = HOVERED_ROW_SUBSCRIBERS.get(editor);
        if (previousSubscribers) {
            previousSubscribers.add(subscriber);
        } else {
            HOVERED_ROW_SUBSCRIBERS.set(editor, new Set([subscriber]));
        }
        return (): void => {
            const previousSubscribers = HOVERED_ROW_SUBSCRIBERS.get(editor);
            if (previousSubscribers) {
                previousSubscribers.delete(subscriber);
            }
        };
    },

    getElementTotal(editor: Editor, elementID: string): ElementTotal | null {
        const totals = ELEMENT_TOTALS.get(editor);
        if (!totals) {
            return null;
        }
        return totals.get(elementID) ?? null;
    },

    recordElementTotal(
        editor: Editor,
        elementID: string,
        tradeName: string | null,
        categoryID: string | null,
        val: number | null
    ): void {
        const total = val !== null ? { val, tradeName, categoryID } : null;
        const totals = ELEMENT_TOTALS.get(editor);
        if (totals) {
            if (total !== null) {
                totals.set(elementID, total);
            } else {
                totals.delete(elementID);
            }
            recordElementTotalsChange(editor, categoryID);
        } else {
            if (total !== null) {
                ELEMENT_TOTALS.set(editor, new Map([[elementID, total]]));
                recordElementTotalsChange(editor, categoryID);
            }
        }
    },

    subscribeToElementTotals(
        editor: Editor,
        categoryID: string | null,
        subscriber: ElementTotalsSubscriber
    ): Unsubscriber {
        const subscriberInfo = { categoryID, subscriber };
        const previousSubscribers = ELEMENT_TOTALS_SUBSCRIBERS.get(editor);
        if (previousSubscribers) {
            previousSubscribers.add(subscriberInfo);
        } else {
            ELEMENT_TOTALS_SUBSCRIBERS.set(editor, new Set([subscriberInfo]));
        }
        return (): void => {
            const previousSubscribers = ELEMENT_TOTALS_SUBSCRIBERS.get(editor);
            if (previousSubscribers) {
                previousSubscribers.delete(subscriberInfo);
            }
        };
    },
};
