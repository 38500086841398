/* Prevents from accessing a route when not having supplied roles */

import React, { FC } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

import { IUserRole } from '@/graphql';
import { useUser } from '@/contexts/User';
import { LoadingPage } from '@/views/LoadingPage';

type ProtectedRouteProps = RouteProps & {
    roles?: IUserRole[];
    allowAnonymous?: boolean;
    disabled?: boolean;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
    roles,
    disabled = false,
    allowAnonymous = false,
    ...rest
}) => {
    const {
        data: { user, anonymousUser },
    } = useUser();

    const anonymous = allowAnonymous && anonymousUser;

    if (!user.roles && !anonymous) return <LoadingPage />;

    const hasSuppliedRoles = !roles || roles.some((role) => user.roles?.includes(role));

    return (hasSuppliedRoles && !disabled) || anonymous ? <Route {...rest} /> : <Redirect to="/" />;
};
