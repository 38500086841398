import React, { FC } from 'react';
import clsx from 'clsx';

import { Cancelled } from './Cancelled';
import { ProjectDetailsComponentProps } from './context';
import { Discussion } from './Discussion';
import { Modals } from './Modals';
import { ProjectFiles } from './ProjectFiles';
import { ProjectInfo } from './ProjectInfo';
import { Container, Content } from './styled';
import { GlobalStyle } from '@/common/styles/globalStyles';
import { DatabaseProjectStatus } from '@/common/types';
import { useUser } from '@/contexts/User';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { isAdministratorUser, isBuilderUser } from '@/views/Projects/utils';

export const ProjectDetails: FC<ProjectDetailsComponentProps> = ({ useProjectDetails }) => {
    const { project } = useProjectDetails();
    const breakpoints = useBreakpoints();
    const {
        data: { user },
    } = useUser();

    const { builderAcceptance } = useFlags();

    return (
        <>
            <GlobalStyle />
            <Container>
                {project.status !== DatabaseProjectStatus.CANCELED ||
                (builderAcceptance && (isAdministratorUser(user) || isBuilderUser(user))) ? (
                    <Content>
                        {breakpoints.md && <ProjectInfo useProjectDetails={useProjectDetails} />}

                        <Modals projectStatus={project.status} isSaasProject={project.isSaas} />

                        <Discussion useProjectDetails={useProjectDetails} />

                        {breakpoints.lg && (
                            <ProjectFiles
                                className={clsx('project-files-v2', 'column')}
                                useProjectDetails={useProjectDetails}
                            />
                        )}
                    </Content>
                ) : (
                    <Cancelled projectName={project.name} />
                )}
            </Container>
        </>
    );
};
