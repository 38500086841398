const oldStyleCopyToClipboard = (copiedText: string): boolean => {
    const textArea = document.createElement('textarea');
    textArea.value = copiedText;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        return document.execCommand('copy');
    } catch {
        return false;
    } finally {
        document.body.removeChild(textArea);
    }
};

export const copyToClipboard = async (text: string): Promise<boolean> => {
    // If the browser doesn't support the Clipboard API, we have to fall back.
    if (!navigator.clipboard) {
        return oldStyleCopyToClipboard(text);
    }
    // Otherwise we just use it.
    try {
        await navigator.clipboard.writeText(text);
        return true;
    } catch {
        return false;
    }
};
