import styled from 'styled-components';

import { FillRule, genCSSPolygon } from '@/common/css/polygon';

export const ElementQuantityEditorWrapper = styled.div({
    position: 'relative',
    backgroundColor: '#f6f8f8',
    height: '100%',
    width: '100%',
    padding: '6px',
    boxSizing: 'border-box',
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1rem',
    color: '#1B2235',
    zIndex: 5,
    '::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: -1,
        bottom: -1,
        left: -1,
        backgroundColor: 'rgba(187, 186, 217, 0.07)',
        boxSizing: 'border-box',
        border: '1px solid #D7E1E5',
        borderTop: 'none',
    },
    '::after': {
        content: "''",
        position: 'absolute',
        top: -2,
        right: -1,
        bottom: -2,
        left: -1,
        borderTop: '2px solid #166FF3',
        borderBottom: '2px solid #166FF3',
    },
});

const elementQuantityNumericInputViewGrid = `
" .    .      .    .      .   " 5px
" .    input  .    arrow  .   " 1fr
" .    .      .    .      .   " 5px
/ 9px  1fr    5px  auto   5px `;

export const ElementQuantityEditorInnerWrapper = styled.div<{ highlighted: boolean }>(
    ({ highlighted }) => ({
        display: 'grid',
        gridTemplate: elementQuantityNumericInputViewGrid,
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        border: highlighted ? '1px solid #166FF3' : 'none',
        zIndex: 2,
    })
);

export const ElementQuantityEditorValue = styled.div({
    gridArea: 'input',
});

export const ElementQuantityEditorArrowContainer = styled.div({
    gridArea: 'arrow',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: 'rgb(187, 186, 217, 0.4)',
});

const arrowPolygon = genCSSPolygon(
    [
        { x: 0, y: 0 },
        { x: 5, y: 5 },
        { x: 0, y: 5 },
    ],
    FillRule.Nonzero
);

export const ElementQuantityEditorArrowIcon = styled.span({
    clipPath: arrowPolygon,
    width: '5px',
    height: '5px',
    backgroundColor: 'rgb(27, 34, 53, 0.6)',
    transformOrigin: 'center',
    transform: 'rotate(-45deg)',
});

const elementQuantityNumericInputViewDropdownGrid = `
" .          " 4px
" usetakeoff " 28px
" .          " 4px
/ 1fr `;

export const ElementQuantityEditorDropdownWrapper = styled.div({
    display: 'grid',
    gridTemplate: elementQuantityNumericInputViewDropdownGrid,
    justifyItems: 'stretch',
    position: 'absolute',
    left: '-2px',
    right: '-2px',
    top: 'calc(100% + 1px)',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(26, 34, 64, 0.1)',
});

export const ElementQuantityEditorDropdownButton = styled.div({
    gridArea: 'usetakeoff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0.5rem',
    color: '#FFFFFF',
    backgroundColor: '#166FF3',
    cursor: 'pointer',
});

const elementQuantityFormulaEditorWrapperGrid = `
" .        .        .       .       .       .       " 1rem
" .        .        title   .       help    .       " auto
" .        .        .       .       .       .       " 0.75rem
" .        editor   editor  editor  editor  .       " 1fr
" .        .        .       .       .       .       " 0.25rem
/ 0.25rem  0.75rem  auto    1fr     auto    0.25rem `;

export const ElementQuantityFormulaEditorWrapper = styled.div({
    display: 'grid',
    gridTemplate: elementQuantityFormulaEditorWrapperGrid,
    position: 'absolute',
    left: '-1rem',
    top: '100%',
    width: '24.5rem',
    height: '9rem',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 40px -20px rgba(0, 0, 0, 0.55)',
    borderRadius: '0.25rem',
    zIndex: 5,
});

export const ElementQuantityFormulaEditorEditor = styled.div({
    gridArea: 'editor',
    placeSelf: 'stretch',
    boxSizing: 'border-box',
    border: '1px solid #166FF3',
    padding: '0.5rem 0.125rem',
    backgroundColor: '#F0F4F9',
    borderRadius: '0px 0px 2px 2px',
});

export const ElementQuantityFormulaEditorTitle = styled.span({
    gridArea: 'title',
    color: '#BBBAD9',
});

export const ExpressionChildContainer = styled.span({
    display: 'inline-block',
});

export const ExpressionCharacterContainer = styled(ExpressionChildContainer)({
    cursor: 'text',
});

export const ExpressionBubbleContainer = styled(ExpressionChildContainer)<{
    selected?: boolean;
    borderColor?: string;
}>(({ selected = false, borderColor = null }) => ({
    cursor: 'pointer',
    minWidth: '1rem',
    textAlign: 'center',
    padding: '0 0.5rem',
    backgroundColor: selected ? '#166FF3' : '#FFF',
    color: selected ? '#FFFFFF' : '#1B2235',
    borderRadius: '0.125rem',
    border: borderColor === null ? 'none' : `2px solid ${borderColor}`,
    lineHeight: borderColor === null ? 'inherit' : '1.75rem',
}));

export const ExpressionPlaceholderContainer = styled(ExpressionChildContainer)({
    opacity: 0.3,
});

export const ExpressionWrapper = styled.div({
    lineHeight: '2rem',
    color: '#1B2235',
    [`& > span:not(${ExpressionChildContainer})`]: {
        margin: '0 3px',
    },
});
