import React, { FC, useEffect } from 'react';

import isNil from 'lodash/isNil';

import { TakeoffComponentProps } from '../context';
import {
    abortEditingGeometryObservable,
    booleanToolObservable,
    boundaryDataObservable,
    cancelBooleanOperationObservable,
    editedGeometryObservable,
    executeBooleanOperationObservable,
    finishEditingGeometryObservable,
    isAddingCoordinatesObservable,
} from '../observables/helpers';
import { selectedGeometriesObservable } from '../observables/interface';
import './SheetToolbar.scss';
import { Spec } from './Spec';
import { Toolbar } from './Toolbar/Toolbar';

export const SheetToolbar: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const { mapRef, updateHandlers } = useTakeoff();

    const editedGeometry = editedGeometryObservable.value;
    const selectedGeometries = selectedGeometriesObservable.value;
    const isAddingCoordinates = isAddingCoordinatesObservable.value;

    const booleanTool = booleanToolObservable.value;

    const isBooleanToolSelected = Boolean(booleanTool);

    const finishEditingGeometry = (): void => {
        finishEditingGeometryObservable.next();
        isAddingCoordinatesObservable.next(false);
        boundaryDataObservable.next([]);
        mapRef?.container?.focus();
    };

    const abortEditingGeometry = (): void => {
        abortEditingGeometryObservable.next();
        isAddingCoordinatesObservable.next(false);
        boundaryDataObservable.next([]);
    };

    useEffect(() => {
        if (selectedGeometries.geometries.length === 0) {
            return;
        }
        updateHandlers({
            ENTER: (): void => {
                if (isBooleanToolSelected) {
                    executeBooleanOperationObservable.next();
                }
                if (
                    isNil(editedGeometry?.uuid) &&
                    selectedGeometries.geometries.length === 1 &&
                    !isAddingCoordinates
                ) {
                    editedGeometryObservable.next(selectedGeometries.geometries[0]);
                } else {
                    finishEditingGeometry();
                }
            },
            CANCEL: (): void => {
                if (isBooleanToolSelected) {
                    cancelBooleanOperationObservable.next();
                } else {
                    abortEditingGeometry();
                }
            },
        });
    }, [isBooleanToolSelected, editedGeometry, selectedGeometries, isAddingCoordinates]);

    return (
        <div className="sheet-toolbar">
            <Toolbar useTakeoff={useTakeoff}>
                <Spec finishEditingGeometry={finishEditingGeometry} useTakeoff={useTakeoff} />
            </Toolbar>
        </div>
    );
};
