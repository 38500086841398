import { geometryIs } from '@/common/typeGuards';
import { Geometry, GeometryType } from '@/common/types';

// ReturnDefault is a private type helper returned by default from geometry case functions. If the
// default were simply `Geometry`, users could not specify a generic `Geometry` return value; the
// specified value would overlap with the default, causing each case function to still require the
// type of its return value to match the type of its geometry parameter.
type ReturnDefault =
    | Geometry<GeometryType.AREA>
    | Geometry<GeometryType.COUNT>
    | Geometry<GeometryType.LINEAR>;

// ReturnValue is the value returned by a case.
// When `V` is the default return type, resolves to a geometry of type `T`.
// When `V` is a map keyed by geometry types, resolves to the type mapped to by `V` in `T`.
// Otherwise, resolves to `V`.
type ReturnValue<T extends GeometryType, V = ReturnDefault> = V extends ReturnDefault
    ? Geometry<T>
    : V extends { [K in GeometryType]: unknown }
    ? V[T]
    : V;

// Cases is a set of functions which accept a geometry of a specific type. By default, each case
// returns a geometry of the same type as its geometry parameter.
type Cases<V = ReturnDefault> = {
    [T in GeometryType]: (geometry: Geometry<T>) => ReturnValue<T, V>;
};

// GeometryHandler invokes a geometry case corresponding to the type of the passed geometry.
type GeometryHandler<V = ReturnDefault> = <T extends GeometryType>(
    geometry: Geometry<T>
) => ReturnValue<T, V>;

// invokeCase invokes the correct case function given the type of the passed geometry.
// The signature is overloaded because TypeScript lacks return type narrowing.
function invokeCase<T extends GeometryType, V = ReturnDefault>(
    cases: Cases<V>,
    geometry: Geometry<T>
): ReturnValue<T, V>;
function invokeCase(cases: Cases, geometry: Geometry) {
    if (geometryIs(geometry, GeometryType.AREA)) {
        return cases.Area(geometry);
    } else if (geometryIs(geometry, GeometryType.COUNT)) {
        return cases.Count(geometry);
    } else if (geometryIs(geometry, GeometryType.LINEAR)) {
        return cases.Linear(geometry);
    }
    throw new Error('unrecognized geometry type: ' + geometry.type);
}

// newGeometryHandler returns a function which invokes a case matching the passed geometry's type.
// By default, each case returns a geometry of the same type that it was passed. The type parameter
// can be used to alter the return type of the passed cases:
// * Map keyed by geometry types: Each case returns the keyed-to type (see `toFlatten`).
// * Any other type: All cases return this type.
export const newGeometryHandler = <V = ReturnDefault>(cases: Cases<V>): GeometryHandler<V> => {
    return (geometry) => invokeCase(cases, geometry);
};
