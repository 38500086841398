import { CellRenderer } from 'leyden-react';
import React from 'react';

import { Cell } from '../components/Cell';

export const Empty: CellRenderer<'Empty'> = ({ attributes, children, element }) => {
    return (
        <Cell
            attributes={attributes}
            caret="never"
            element={element}
            preventSelection={true}
            style={{ cursor: 'default' }}
        >
            {children}
        </Cell>
    );
};
