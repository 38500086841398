import Stack from '@mui/material/Stack';
import { styled as styledByMui } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const HeadingStack = styledByMui(Stack)({
    padding: '48px 0 24px',
    marginBottom: 4,
});

export const Main = styledByMui('main')({
    minHeight: '100vh',
    paddingBottom: 32,
});

export const MainHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.common.white,
}));
