import React, { FC } from 'react';

import { EstimatorStatus } from '../EstimatorStatus';
import { PanelHeaderContainer, PanelHeaderTitle } from './styled';

import { IEstimatorStatus, Maybe } from '@/graphql';

interface HeaderProps {
    estimatorStatus?: Maybe<IEstimatorStatus>;
    estimatorName?: string;
}

export const Header: FC<HeaderProps> = ({ estimatorStatus, estimatorName }) => {
    return (
        <PanelHeaderContainer>
            <PanelHeaderTitle>{estimatorName}</PanelHeaderTitle>
            {<EstimatorStatus status={estimatorStatus} />}
        </PanelHeaderContainer>
    );
};
