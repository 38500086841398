import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import FilledInput from '@mui/material/FilledInput';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { KeyHandlers, PlanPage } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { HotKeys } from '@/components/ui/controls/HotKeys';
import Fuse from 'fuse.js';
import { VariableSizeList } from 'react-window';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Page } from '@/components/takeoff/sheet-toolbar/PageBrowser/Page';
import { usePageNavigation } from '@/components/takeoff/sheet-toolbar/PageBrowser/usePageNavigation';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/32/chevron--down.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/32/chevron--left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/32/chevron--right.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/32/chevron--up.svg';
import { ReactComponent as CloudUploadIcon } from '@/assets/icons/32/cloud--upload.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/32/search.svg';
import { ProjectPlanFilesUploader } from '@/components/projects/ProjectPlanFileUploader';

const COPY = {
    pagesHeader: 'Pages',
    pagesSubheader: 'Select pages to use in takeoff',
    addPlan: 'Add file',
    cancelAddingPLan: 'Cancel upload',
    ingestionWait: 'Files processing',
    noResults: 'No results. Try another search.',
    searchPages: 'Search pages',
};

export const PageBrowser: FC<TakeoffComponentProps> = ({ useTakeoff }) => {
    const theme = useTheme();

    const {
        project: { uuid: projectUUID, parentProjectUuid },
        pagesBrowserOpen,
        setPagesBrowserOpen,
        searchInputRef,
        currentPageId,
        setCurrentPageId,
        rightClickedPageId,
        loadingCurrentPage,
        currentPage,
    } = useTakeoff();

    const { pickPage, pages } = usePageNavigation({ useTakeoff });

    const [filteredPages, setFilteredPages] = useState(pages);
    const [search, setSearchPhrase] = useState('');

    const [fileUploaderOpen, setFileUploaderOpen] = useState<boolean>(false);

    const pageBrowserListRef = useRef<VariableSizeList | null>(null);
    const pageBrowserAnchor = useRef<HTMLButtonElement | null>(null);

    const togglePageBrowser = (): void => setPagesBrowserOpen(!pagesBrowserOpen);

    const filter = (): void => {
        const pageFilteringFuse = new Fuse(pages, { keys: ['name'] });
        let filteredPages = pages.sort((a: PlanPage, b: PlanPage) => {
            if (a.projectPlan.id === b.projectPlan.id) {
                return a.pageId - b.pageId;
            } else {
                return parseInt(a.projectPlan.id) - parseInt(b.projectPlan.id);
            }
        });
        if (search) {
            filteredPages = pageFilteringFuse.search(search).map((res) => res.item);
        }

        if (filteredPages.length === 1) {
            setCurrentPageId(Number(filteredPages[0].id));
        }
        setFilteredPages(filteredPages);
    };

    const setNextPage = (reload?: boolean): void => {
        const index = filteredPages.findIndex(
            (page: PlanPage) => page.id.toString() === currentPageId?.toString()
        );

        if (index !== -1 && index < filteredPages.length - 1) {
            setCurrentPageId(Number(filteredPages[index + 1].id));
            if (reload) {
                pickPage(parseInt(filteredPages[index + 1].id));
            }
        }
    };

    const setPreviousPage = (reload?: boolean): void => {
        const index = filteredPages.findIndex(
            (page: PlanPage) => page.id === currentPageId?.toString()
        );

        if (index !== -1 && index > 0) {
            setCurrentPageId(Number(filteredPages[index - 1].id));
            if (reload) {
                pickPage(parseInt(filteredPages[index - 1].id));
            }
        }
    };

    useEffect(() => {
        filter();
    }, [pages, search]);

    const handlers: KeyHandlers = useMemo(
        () => ({
            SELECT_PAGE: (): void => pickPage(Number(currentPageId)),
            PAGE_UP: (): void => setPreviousPage(),
            PAGE_DOWN: (): void => setNextPage(),
            HIDE_PAGE_BROWSER: (): void => setPagesBrowserOpen((prev: boolean): boolean => !prev),
        }),
        [currentPageId, rightClickedPageId, pages, currentPage]
    );

    return (
        <HotKeys handlers={handlers} scope="pageBrowser">
            <ProjectPlanFilesUploader
                projectUUID={projectUUID}
                onFilesUploaded={() => setFileUploaderOpen(false)}
                onClose={() => setFileUploaderOpen(false)}
                open={fileUploaderOpen}
            />
            <Button
                color="secondary"
                onClick={() => togglePageBrowser()}
                variant="text"
                ref={pageBrowserAnchor}
                sx={{
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: 0,
                    display: 'block',
                    textTransform: 'none',
                    width: '311px',
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing="16px"
                >
                    <Stack alignItems="center" direction="row" spacing="16px">
                        <Stack direction="row" spacing="8px">
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();

                                    setPreviousPage(true);
                                }}
                                size="small"
                            >
                                <SvgIcon
                                    sx={{
                                        height: '18px',
                                        width: '18px',
                                    }}
                                    viewBox="0 0 32 32"
                                >
                                    <ChevronLeftIcon />
                                </SvgIcon>
                            </IconButton>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();

                                    setNextPage(true);
                                }}
                                size="small"
                            >
                                <SvgIcon
                                    sx={{
                                        height: '18px',
                                        width: '18px',
                                    }}
                                    viewBox="0 0 32 32"
                                >
                                    <ChevronRightIcon />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                        <Typography
                            sx={{
                                flex: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '130px',
                            }}
                            variant="body2"
                        >
                            {currentPage?.name}
                        </Typography>
                    </Stack>
                    <IconButton size="small">
                        <SvgIcon
                            sx={{
                                height: '18px',
                                width: '18px',
                            }}
                            viewBox="0 0 32 32"
                        >
                            {pagesBrowserOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </SvgIcon>
                    </IconButton>
                </Stack>
            </Button>
            <Popper
                anchorEl={pageBrowserAnchor.current}
                open={pagesBrowserOpen}
                style={{
                    display: 'flex',
                    zIndex: theme.zIndex.mobileStepper,
                }}
            >
                <Paper
                    square={false}
                    sx={{
                        borderRadius: 0,
                        flex: 1,
                        width: '311px',
                    }}
                >
                    <Stack direction="column" spacing="16px">
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing="16px"
                            sx={{ padding: '16px' }}
                        >
                            <FilledInput
                                hiddenLabel
                                onChange={(event) => setSearchPhrase(event.target.value)}
                                placeholder={COPY.searchPages}
                                ref={searchInputRef}
                                size="small"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SvgIcon viewBox="0 0 32 32" sx={{ height: '22px' }}>
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                }
                                value={search}
                            />
                            <Box>
                                <IconButton
                                    color="primary"
                                    onClick={() => setFileUploaderOpen(true)}
                                    size="small"
                                >
                                    <SvgIcon viewBox="0 0 32 32">
                                        <CloudUploadIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box>
                        {(() => {
                            const mastheadHeight = 156;
                            const listControlsHeight = 72;

                            const contentHeight =
                                window.innerHeight - mastheadHeight - listControlsHeight;

                            if (pages.length === 0) {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: contentHeight,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {COPY.ingestionWait}
                                        </Typography>
                                    </Box>
                                );
                            }

                            if (pages.length !== 0 && filteredPages.length === 0) {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: contentHeight,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="body1">{COPY.noResults}</Typography>
                                    </Box>
                                );
                            }

                            return (
                                <List disablePadding>
                                    <VariableSizeList
                                        height={contentHeight}
                                        itemCount={filteredPages.length}
                                        itemSize={() => 88}
                                        itemData={{
                                            pages: filteredPages,
                                            search,
                                            currentPageId,
                                            pickPage,
                                            projectUUID: parentProjectUuid || projectUUID,
                                            loadingCurrentPage,
                                        }}
                                        ref={pageBrowserListRef}
                                        width="100%"
                                        style={{
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        {Page}
                                    </VariableSizeList>
                                </List>
                            );
                        })()}
                    </Box>
                </Paper>
            </Popper>
        </HotKeys>
    );
};
