import { TakeoffComponentProps } from '../context';
import {
    booleanToolObservable,
    subscribeToBooleanGeometryTool,
    subscribeToCurrentlyAddedCoordinates,
    subscribeToIsDrawingRectangleObservable,
} from '../observables/helpers';
import { ReactComponent as dottedSquare } from '@/assets/icons/dotted-square.svg';
import { ReactComponent as polygon } from '@/assets/icons/polygon.svg';
import { fromCoordinate, fromLeaflet } from '@/common/convert/coordinateData';
import { toQuantityFormatted } from '@/common/convert/geometry';
import { BooleanToolType, Geometry, GeometryType, Nil, ToolType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';
import { useEditedOrDrawnGeometry } from '@/components/takeoff/hooks/useEditedOrDrawnGeometry';
import { useSelectedGeometryIDs } from '@/components/takeoff/hooks/useSelectedGeometryIDs';
import { DrawingSpec } from '@/components/ui/controls/DrawingSpec';
import { GeometryBar } from '@/components/ui/controls/GeometryBar';
import { ActionBar } from '@/components/ui/controls/helpers/ActionBar';
import { LatLng } from 'leaflet';
import isNil from 'lodash/isNil';
import React, { FC, useCallback, useEffect, useState } from 'react';

const COPY = {
    specBarLength: 'Length',
    specBarArea: 'Area',
    specBarCount: 'Count',
    specBarBooleanAdd: 'Add to shape',
    specBarBooleanSubtract: 'Subtract from shape',
    specBarBooleanSplit: 'Split shape',
};

export interface SpecProps extends TakeoffComponentProps {
    finishEditingGeometry: () => void;
}

export const Spec: FC<SpecProps> = ({ finishEditingGeometry, useTakeoff }) => {
    const { scale, switchBooleanGeometryToPolyline, switchBooleanGeometryToPolygon } = useTakeoff();
    const editedOrDrawnGeometry = useEditedOrDrawnGeometry();
    const selectedGeometryIDs = useSelectedGeometryIDs();

    const booleanTool = booleanToolObservable.value;

    const isBooleanToolSelected = Boolean(booleanTool);

    const [currentlyAddedCoordinates, setCurrentlyAddedCoordinates] = useState<LatLng | null>();

    useEffect(() => {
        const subscription = subscribeToCurrentlyAddedCoordinates(setCurrentlyAddedCoordinates);
        return (): void => subscription.unsubscribe();
    }, []);

    const [booleanGeometryToolType, setBooleanGeometryToolType] = useState<ToolType | null>();

    useEffect(() => {
        const subscription = subscribeToBooleanGeometryTool(setBooleanGeometryToolType);
        return (): void => subscription.unsubscribe();
    }, []);

    const [isDrawingRectangle, setIsDrawingRectangle] = useState<boolean>(false);

    useEffect(() => {
        const subscription = subscribeToIsDrawingRectangleObservable(setIsDrawingRectangle);
        return (): void => subscription.unsubscribe();
    }, []);

    const getLength = useCallback(
        (geometry: Geometry<GeometryType.LINEAR>): string => {
            let finalGeometry = geometry;
            if (currentlyAddedCoordinates) {
                finalGeometry = {
                    ...geometry,
                    coordinates: [...geometry.coordinates, fromLeaflet(currentlyAddedCoordinates)],
                };
            }
            return toQuantityFormatted(finalGeometry, scale);
        },
        [currentlyAddedCoordinates, scale]
    );

    const getArea = (geometry: Geometry<GeometryType.AREA>): string => {
        let finalGeometry = geometry;
        if (currentlyAddedCoordinates && isDrawingRectangle) {
            const geometryCoordinates = [...geometry.coordinates.map((r) => [...r])];
            const firstPoint = geometryCoordinates[0][0];
            if (firstPoint) {
                geometryCoordinates[0] = [
                    ...geometryCoordinates[0],
                    fromCoordinate({
                        x: firstPoint.x,
                        y: currentlyAddedCoordinates.lng,
                    }),
                    fromCoordinate({
                        x: currentlyAddedCoordinates.lat,
                        y: currentlyAddedCoordinates.lng,
                    }),
                    fromCoordinate({
                        x: currentlyAddedCoordinates.lat,
                        y: firstPoint.y,
                    }),
                ];
                finalGeometry = {
                    ...geometry,
                    coordinates: [geometryCoordinates[0]],
                };
            }
        } else if (currentlyAddedCoordinates) {
            finalGeometry = {
                ...geometry,
                coordinates: [
                    [
                        ...finalGeometry.coordinates[0],
                        fromCoordinate({
                            x: currentlyAddedCoordinates.lat,
                            y: currentlyAddedCoordinates.lng,
                        }),
                    ],
                ],
            };
        }
        return toQuantityFormatted(finalGeometry, scale, { unit: true });
    };

    const geometryOptions = [
        {
            onClick: (): void => switchBooleanGeometryToPolyline(),
            active: booleanGeometryToolType === ToolType.LINEAR,
            alt: 'Polygon',
            iconPath: polygon,
        },
        {
            onClick: (): void => switchBooleanGeometryToPolygon(),
            active: booleanGeometryToolType === ToolType.AREA,
            alt: 'Dotted Square',
            iconPath: dottedSquare,
        },
    ];

    const booleanToolText: Record<BooleanToolType, string> = {
        [BooleanToolType.ADD]: COPY.specBarBooleanAdd,
        [BooleanToolType.SUBTRACT]: COPY.specBarBooleanSubtract,
        [BooleanToolType.SPLIT]: COPY.specBarBooleanSplit,
    };

    const renderDrawingSpec = (): JSX.Element => {
        if (isNil(editedOrDrawnGeometry)) return <></>;

        const measure = {
            [GeometryType.LINEAR]: COPY.specBarLength,
            [GeometryType.AREA]: COPY.specBarArea,
            [GeometryType.COUNT]: COPY.specBarCount,
        }[editedOrDrawnGeometry.type];

        const valueString = newGeometryHandler<string>({
            [GeometryType.AREA]: (area) => {
                if (!scale) {
                    return '';
                }
                return getArea(area);
            },
            [GeometryType.COUNT]: (count) => `${count.coordinates.length}`.padStart(2, '0'),
            [GeometryType.LINEAR]: (line) => {
                if (!scale) {
                    return '';
                }
                return getLength(line);
            },
        })(editedOrDrawnGeometry);

        return (
            <div className="additional-display">
                <DrawingSpec
                    onClick={finishEditingGeometry}
                    measure={scale ? measure : 'Please set the scale'}
                    value={valueString}
                />
            </div>
        );
    };

    const renderGeometryBar = (): JSX.Element => {
        if (!booleanTool) return <></>;
        return (
            <div className="additional-display">
                <GeometryBar text={booleanToolText[booleanTool]} options={geometryOptions} />
            </div>
        );
    };

    const renderSelectionDisplay = (): JSX.Element => {
        return (
            <div className="additional-display">
                <ActionBar>{selectedGeometryIDs.size} objects selected</ActionBar>
            </div>
        );
    };

    const renderSpec = (): Nil<JSX.Element> => {
        if (isBooleanToolSelected) return renderGeometryBar();
        if (editedOrDrawnGeometry) return renderDrawingSpec();
        if (selectedGeometryIDs.size > 0) return renderSelectionDisplay();
    };

    return <>{renderSpec()}</>;
};
