import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import {
    IOldProjectsQuery,
    IOldProjectsQueryVariables,
    IProjectsOrderBy,
    OldProjectsDocument,
} from '@/graphql';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';

export const SAMPLE_PROJECT_NAME = 'Sample Takeoff';

interface UseSampleProjectPayload {
    sampleProjectUUID: string | null;
}

export const useSampleProject = (): UseSampleProjectPayload => {
    // Queries
    /*--------------*/
    const [fetchProjectsByTeamID, fetchProjectsByTeamIDResponse] = useLazyQuery<
        IOldProjectsQuery,
        IOldProjectsQueryVariables
    >(OldProjectsDocument, { fetchPolicy: 'network-only' });

    // State
    /*--------------*/
    const { features } = useFeatures();
    const {
        data: {
            user: { team },
        },
    } = useUser();
    const sampleProjectUUID = useMemo(() => {
        // Search list of projects for sample
        if (fetchProjectsByTeamIDResponse.data?.projectsOld) {
            const sampleProject = fetchProjectsByTeamIDResponse.data?.projectsOld.edges?.find(
                ({ node }) => node.name === SAMPLE_PROJECT_NAME
            );

            return (sampleProject?.node.uuid as string) ?? null;
        }

        return null;
    }, [fetchProjectsByTeamIDResponse.data?.projectsOld]);

    // Effects
    /*--------------*/
    // Set sample project UUID if it exists
    useEffect(() => {
        if (features.demoProject && team?.uuid) {
            fetchProjectsByTeamID({
                variables: {
                    input: {
                        condition: {
                            teamID: team.uuid,
                        },
                        orderBy: IProjectsOrderBy.Natural,
                        arguments: {
                            first: 10,
                        },
                    },
                },
            });
        }
    }, [features.demoProject, team]);

    return { sampleProjectUUID };
};
