import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { layers, zIndex } from './layers';

export interface LayerProps {
    variant: 'default' | 'middle' | 'high';
}

export const Layer: FC<LayerProps> = ({ variant, ...props }) => {
    const theme = useTheme();

    const multiplier = layers[variant];

    const nextTheme = createTheme({
        ...theme,
        zIndex: {
            mobileStepper: zIndex.mobileStepper * multiplier,
            speedDial: zIndex.speedDial * multiplier,
            appBar: zIndex.appBar * multiplier,
            drawer: zIndex.drawer * multiplier,
            modal: zIndex.modal * multiplier,
            snackbar: zIndex.snackbar * multiplier,
            tooltip: zIndex.tooltip * multiplier,
        },
    });

    return <ThemeProvider theme={nextTheme} {...props} />;
};
