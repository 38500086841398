import { Container, ContainerProps, ExpandIconWrapper, Row, RowIcon, RowWrapper } from './styled';
import { MenuDimensions, MenuPosition, MenuRowOption, menuPositionIsOffset } from './types';
import { ReactComponent as Caret } from '@/assets/icons/caret-currentColor.svg';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { CenterEllipsis } from '@/components/ui/text/CenterEllipsis';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

export type { MenuOrigin, MenuRowOption } from './types';

// Width to absolutely position sub-menus
const NESTED_MENU_WIDTH = 185;

export interface MenuProps {
    className?: string;
    initActiveIndex?: number | null;
    borderRadius?: number;
    bottomPadding?: number;
    headerRow?: Omit<MenuRowOption, 'onClick' | 'onMouseDown'>;
    options: MenuRowOption[];
    position: MenuPosition;
    depth?: number;
}

export const Menu: FC<MenuProps> = ({
    className,
    position,
    initActiveIndex = 0,
    borderRadius = 0,
    bottomPadding,
    headerRow,
    options,
    depth = 1,
}) => {
    const contextMenuContainerRef = useRef<HTMLDivElement>(null);
    const [activeOptionIndex, setActiveOptionIndex] = useState<number | null>(initActiveIndex);
    const [isSubMenuActive, setIsSubMenuActive] = useState(false);
    const [ownDimensions, setOwnDimensions] = useState<MenuDimensions | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    // If a sub menu is active
    const isSubMenuHightlighted = useMemo(
        () => !!(activeOptionIndex !== null && options[activeOptionIndex]?.options),
        [options, activeOptionIndex]
    );

    const containerProps = useMemo<ContainerProps>(() => {
        // Use explicitly set position if present
        if (menuPositionIsOffset(position)) {
            return {
                isAbsolute: true,
                ...position,
            };
        }
        // If positioning dynamically but own dimensions are unknown, render off-screen
        if (ownDimensions === null) {
            return {
                top: window.innerHeight,
                left: window.innerWidth,
            };
        }
        // Default: align menu below and to the right of cursor
        let left = position.x;
        let top = position.y;
        // If insufficient space on right, align right edge of menu with right edge of screen
        if (position.x + ownDimensions.width > window.innerWidth) {
            left = window.innerWidth - ownDimensions.width;
        }
        // If insufficient space below and sufficient space above, align menu above cursor
        if (
            position.y + ownDimensions.height > window.innerHeight &&
            window.innerHeight > ownDimensions.height
        ) {
            top = position.y - ownDimensions.height;
        }
        return { top, left };
    }, [bottomPadding, ownDimensions, position, window.innerHeight, window.innerWidth]);

    useEffect(() => {
        if (!contextMenuContainerRef.current) {
            setOwnDimensions(null);
            return;
        }
        setOwnDimensions({
            height: contextMenuContainerRef.current.clientHeight,
            width: contextMenuContainerRef.current.clientWidth,
        });
    }, [contextMenuContainerRef.current]);

    // On init index change, reset
    useEffect(() => setActiveOptionIndex(initActiveIndex), [initActiveIndex]);

    // Reset sub menu state on hover out or change options
    useEffect(() => setIsSubMenuActive(false), [activeOptionIndex]);

    // Primitive mechanism to update active index on search term update
    useEffect(() => {
        const resetSearchTerm = setTimeout(() => setSearchTerm(''), 1000);

        // If search is not ''
        if (searchTerm) {
            const searchedOptionIndex = options.findIndex((option) =>
                option.text.toLowerCase().startsWith(searchTerm)
            );

            if (searchedOptionIndex > -1) {
                setActiveOptionIndex(searchedOptionIndex);
            }
        }

        return (): void => clearTimeout(resetSearchTerm);
    }, [searchTerm, options]);

    // Stop scroll events w/ wheel while context menu is open
    useDocumentListener('wheel', (e) => e.preventDefault(), [], { passive: false });

    // Handle keyboard events
    useDocumentListener(
        'keydown',
        (e: KeyboardEvent) => {
            // Handle sub menu selection
            switch (e.key) {
                case 'ArrowLeft': {
                    if (isSubMenuHightlighted) {
                        setIsSubMenuActive(true);
                    }

                    break;
                }

                case 'ArrowRight': {
                    setIsSubMenuActive(false);
                    break;
                }
            }

            // If a sub menu is not currently active, handle keys.
            if (!isSubMenuActive) {
                switch (e.key) {
                    case 'ArrowDown': {
                        // Stop scroll
                        e.preventDefault();

                        setActiveOptionIndex((activeOptionIndex) =>
                            activeOptionIndex !== null
                                ? (activeOptionIndex + 1) % options.length
                                : 0
                        );
                        break;
                    }

                    case 'ArrowUp': {
                        // Stop scroll
                        e.preventDefault();

                        setActiveOptionIndex((activeOptionIndex) =>
                            activeOptionIndex !== null
                                ? (activeOptionIndex + options.length - 1) % options.length
                                : options.length - 1
                        );
                        break;
                    }

                    // Scroll keys should navigate menu
                    // Home/PageUp
                    case 'Home':
                    case 'PageUp': {
                        e.preventDefault();
                        setActiveOptionIndex(0);
                        break;
                    }

                    case 'End':
                    case 'PageDown': {
                        e.preventDefault();
                        setActiveOptionIndex(options.length - 1);
                        break;
                    }

                    // Execute option command on enter
                    case 'Enter': {
                        if (
                            activeOptionIndex !== null &&
                            options[activeOptionIndex] &&
                            !e.defaultPrevented
                        ) {
                            e.preventDefault();

                            options[activeOptionIndex].onClick?.();
                            options[activeOptionIndex].onMouseDown?.();
                        }
                        break;
                    }

                    // Update search term
                    default: {
                        setSearchTerm((searchTerm) => `${searchTerm}${e.key}`);
                    }
                }
            }
        },
        [isSubMenuHightlighted]
    );

    return (
        <Container
            ref={contextMenuContainerRef}
            className={className}
            borderRadius={borderRadius}
            bottomPadding={bottomPadding}
            {...containerProps}
        >
            {/** Optional header row w/ no functionality. */}
            {headerRow && (
                <Row {...headerRow} isHeader>
                    {headerRow.text}
                </Row>
            )}

            {/** Map over options */}
            {options.map(
                (
                    { text, onClick, onMouseDown, icon, activeIcon, isTaller, isDivider, options },
                    i
                ) => (
                    <RowWrapper key={text} isDivider={isDivider} isTaller={isTaller}>
                        <Row
                            // Presentation
                            isActive={activeOptionIndex === i}
                            isTaller={isTaller}
                            id={`context-menu-option-${i}`}
                            // Event handlers
                            onClick={
                                onClick
                                    ? (e): void => {
                                          if (e.button === 0) {
                                              e.preventDefault();
                                              onClick();
                                          }
                                      }
                                    : undefined
                            }
                            onMouseDown={
                                onMouseDown
                                    ? (e): void => {
                                          if (e.button === 0) {
                                              e.preventDefault();
                                              onMouseDown();
                                          }
                                      }
                                    : undefined
                            }
                            onMouseEnter={(): void => setActiveOptionIndex(i)}
                            onMouseLeave={(): void => setActiveOptionIndex(null)}
                        >
                            {options && (
                                <ExpandIconWrapper>
                                    <Caret />
                                </ExpandIconWrapper>
                            )}

                            <CenterEllipsis text={text} />

                            {((activeIcon && activeOptionIndex === i) || icon) && (
                                <RowIcon>{activeIcon || icon}</RowIcon>
                            )}

                            {options && activeOptionIndex === i && (
                                <Menu
                                    position={{
                                        left: NESTED_MENU_WIDTH * depth,
                                        top: 0,
                                    }}
                                    depth={depth + 1}
                                    options={options}
                                    initActiveIndex={isSubMenuActive ? 0 : null}
                                />
                            )}
                        </Row>
                    </RowWrapper>
                )
            )}
        </Container>
    );
};
