import { IMarkupLineFragment, IMarkupPointFragment, IMarkupPolygonFragment } from '@/graphql';

export const geometryDataIs = {
    point: (
        data: IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment
    ): data is IMarkupPointFragment => {
        return !Array.isArray(data);
    },
    coordinateData: (
        data: IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment
    ): data is IMarkupLineFragment | IMarkupPolygonFragment => {
        return Array.isArray(data);
    },
    area: (
        data: IMarkupPointFragment | IMarkupLineFragment | IMarkupPolygonFragment
    ): data is IMarkupPolygonFragment => {
        return geometryDataIs.coordinateData(data) && data.__typename === 'MarkupPolygon';
    },
};
