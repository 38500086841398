import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';
import { Geometry, GeometryType, LineType, Svg } from '@/common/types';
import { getShapeWeight } from '@/common/utils/helpers';
import { styleObservable } from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useState } from 'react';

import { ReactComponent as ArrowLineIcon } from '@/assets/icons/stylepanel/arrow-line.svg';
import { ReactComponent as DottedLineIcon } from '@/assets/icons/stylepanel/dotted-line.svg';
import { ReactComponent as LadderLineIcon } from '@/assets/icons/stylepanel/ladder-line.svg';
import { ReactComponent as NormalLineIcon } from '@/assets/icons/stylepanel/normal-line.svg';
import { ReactComponent as ZigZagLineIcon } from '@/assets/icons/stylepanel/stroked-line.svg';

import { ReactComponent as ArrowLineIcon2 } from '@/assets/icons/stylepanel/dropdown-lines/arrow-line.svg';
import { ReactComponent as DottedLineIcon2 } from '@/assets/icons/stylepanel/dropdown-lines/dotted-line.svg';
import { ReactComponent as LadderLineIcon2 } from '@/assets/icons/stylepanel/dropdown-lines/ladder-line.svg';
import { ReactComponent as NormalLineIcon2 } from '@/assets/icons/stylepanel/dropdown-lines/normal-line.svg';
import { ReactComponent as ZigZagLineIcon2 } from '@/assets/icons/stylepanel/dropdown-lines/stroked-line.svg';

export const LineTypePicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const geometries = geometriesObservable.value;
    const { lineType, weight: lineWidth } = styleObservable.value;
    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);

    const setGeometries = (newGeometries: Geometry[]): void => {
        geometriesObservable.next(newGeometries);
    };

    const setLineType = (newLineType: LineType): void => {
        styleObservable.next({
            ...styleObservable.value,
            lineType: newLineType,
        });
    };

    const getLineTypeIcon = (lineType: LineType) => {
        const icons: Record<LineType, Svg> = {
            [LineType.ARROW]: ArrowLineIcon,
            [LineType.LADDER]: LadderLineIcon,
            [LineType.NORMAL]: NormalLineIcon,
            [LineType.ZIGZAG]: ZigZagLineIcon,
            [LineType.DOTTED]: DottedLineIcon,
        };

        return icons[lineType];
    };

    const getLineTypeIcon2 = (lineType: LineType) => {
        const icons: Record<LineType, Svg> = {
            [LineType.ARROW]: ArrowLineIcon2,
            [LineType.LADDER]: LadderLineIcon2,
            [LineType.NORMAL]: NormalLineIcon2,
            [LineType.ZIGZAG]: ZigZagLineIcon2,
            [LineType.DOTTED]: DottedLineIcon2,
        };

        return icons[lineType];
    };

    const getLineTypeIconViewBox = (lineType: LineType) => {
        const icons: Record<LineType, string> = {
            [LineType.ARROW]: '0 0 18 10',
            [LineType.LADDER]: '0 0 15 4',
            [LineType.NORMAL]: '0 0 15 2',
            [LineType.ZIGZAG]: '0 0 18 9',
            [LineType.DOTTED]: '0 0 14 2',
        };

        return icons[lineType];
    };

    const getLineTypeIcon2ViewBox = (lineType: LineType) => {
        const icons: Record<LineType, string> = {
            [LineType.ARROW]: '0 0 102 15',
            [LineType.LADDER]: '0 0 105 9',
            [LineType.NORMAL]: '0 0 100 2',
            [LineType.ZIGZAG]: '0 0 102 8',
            [LineType.DOTTED]: '0 0 100 2',
        };

        return icons[lineType];
    };

    const updateLineType = (v: LineType) => {
        setLineType(v);

        const needsUpdate = (g: Geometry): boolean =>
            selectedGeometryIds.includes(g.uuid) && g.type === GeometryType.LINEAR;

        if (geometries === null) {
            return;
        }

        setGeometries(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style: {
                                  ...g.style,
                                  lineType: v,
                                  shapeWeight: getShapeWeight(g.style.weight || lineWidth, v),
                              },
                          } // update lineType
                        : g // do nothing
            )
        );
    };

    return (
        <Box {...props}>
            <IconButton
                onClick={(event) => setAnchor(event.currentTarget)}
                size="small"
                sx={{
                    height: '37px',
                    width: '37px',
                }}
            >
                <SvgIcon
                    viewBox={getLineTypeIconViewBox(lineType)}
                    sx={{
                        height: '20px',
                        width: '20px',
                    }}
                >
                    {(() => {
                        const Icon = getLineTypeIcon(lineType);

                        return <Icon />;
                    })()}
                </SvgIcon>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    PaperProps={{
                        sx: {
                            padding: '16px 0',
                            width: '200px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            padding: '0 16px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Lines
                    </Typography>
                    <List dense disablePadding>
                        {Object.values(LineType).map((item) => {
                            const Icon = getLineTypeIcon2(item);

                            return (
                                <ListItemButton
                                    key={item}
                                    onClick={() => {
                                        updateLineType(item);

                                        setAnchor(null);
                                    }}
                                    selected={lineType === item}
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            padding: '0 20px',
                                        }}
                                    >
                                        <SvgIcon
                                            sx={{
                                                height: '20px',
                                                width: '100%',
                                            }}
                                            viewBox={getLineTypeIcon2ViewBox(item)}
                                        >
                                            <Icon />
                                        </SvgIcon>
                                    </Box>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Popover>
            </Mode>
        </Box>
    );
};
