import React, { FC, MouseEventHandler, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { withMode } from '@/theme/withMode';

interface NewGroupModalProps extends DialogProps {
    disabled: boolean;
    onConfirm?: (name: string) => Promise<void>;
    onCancel: MouseEventHandler<HTMLButtonElement>;
}

const COPY = {
    dialogTitle: 'New Group',
    cancel: 'Cancel',
    ok: 'Ok',
};

const UnthemedNewGroupModal: FC<NewGroupModalProps> = ({
    disabled,
    onConfirm,
    onCancel,
    ...props
}) => {
    const [name, setName] = useState('');

    return (
        <Dialog maxWidth="md" {...props}>
            <DialogTitle>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[21] }} variant="h3">
                    {COPY.dialogTitle}
                </Typography>
            </DialogTitle>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();

                    await onConfirm?.(name);
                    setName('');
                }}
            >
                <DialogContent
                    sx={{
                        color: (theme) => theme.palette.hues.neutral[21],
                    }}
                >
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <TextField
                            autoFocus
                            label="Group name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={onCancel} variant="outlined">
                        {COPY.cancel}
                    </Button>
                    <Button type="submit" disabled={!name || disabled} variant="contained">
                        {COPY.ok}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export const NewGroupModal = withMode<NewGroupModalProps>({ variant: 'light' })(
    UnthemedNewGroupModal
);
