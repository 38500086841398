import React, { FC } from 'react';

import { SummaryContainer, SummaryLineItem, SummaryLineItems, SummaryStackItem } from './styled';
import { formatUSD } from '@/common/utils/helpers';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';

const COPY = {
    markups: 'Markups',
    costPerSF: 'Cost / SF',
    costPerLSF: 'Cost / USF',
    empty: '--',
};

export const renderValue = (value: number): string => {
    if (!value) {
        return COPY.empty;
    } else {
        return formatUSD(value * 100, value < 1);
    }
};

const renderValuePerValue = (value: number, perValue: number | null): string => {
    if (!(value && perValue)) {
        return COPY.empty;
    } else {
        return formatUSD((value / perValue) * 100, value / perValue < 1);
    }
};

interface SummaryProps {
    grandTotal: number;
    totalMarkups: number;
}
export const Summary: FC<SummaryProps> = ({ grandTotal, totalMarkups }) => {
    const { projectInfo } = useEstimationProject();

    return (
        <SummaryContainer>
            <SummaryLineItems>
                <SummaryLineItem>
                    <SummaryStackItem styled={{ isTitle: true }} variant="body1">
                        {COPY.markups}
                    </SummaryStackItem>
                    <SummaryStackItem variant="body1">{renderValue(totalMarkups)}</SummaryStackItem>
                </SummaryLineItem>
                <SummaryLineItem>
                    <SummaryStackItem styled={{ isTitle: true }} variant="body1">
                        {COPY.costPerSF}
                    </SummaryStackItem>
                    <SummaryStackItem variant="body1">
                        {renderValuePerValue(grandTotal, projectInfo.squareFeetUnderRoof ?? null)}
                    </SummaryStackItem>
                </SummaryLineItem>
                <SummaryLineItem>
                    <SummaryStackItem styled={{ isTitle: true }} variant="body1">
                        {COPY.costPerLSF}
                    </SummaryStackItem>
                    <SummaryStackItem variant="body1">
                        {renderValuePerValue(
                            grandTotal,
                            projectInfo.squareFeetTotalLivable ?? null
                        )}
                    </SummaryStackItem>
                </SummaryLineItem>
            </SummaryLineItems>
        </SummaryContainer>
    );
};
