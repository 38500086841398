import { SetStateAction, Dispatch, useState } from 'react';
import { Cell, CellType } from 'leyden';
import { makeContext } from '@/common/utils/makeContext';
import { useToggle } from '@/common/hooks/useToggle';

interface ItemDialogContextPayload {
    options: CellDialogOptions | undefined;
    selectedCell: Cell<CellType> | undefined;
    setSelectedCell: Dispatch<SetStateAction<Cell<CellType> | undefined>>;
    cellDialogOpen: boolean;
    openCellDialog: (cell: Cell<CellType>, options?: CellDialogOptions) => void;
    closeCellDialog: () => void;
}

export interface CellDialogOptions {
    explicitCellType?: 'item' | 'group';
}

const { useConsumer, Provider } = makeContext<ItemDialogContextPayload>(() => {
    const [selectedCell, setSelectedCell] = useState<Cell<CellType> | undefined>(undefined);
    const [options, setOptions] = useState<CellDialogOptions | undefined>(undefined);
    const [open, toggleCellDialog] = useToggle();

    const openCellDialog = (cell: Cell<CellType>, options?: CellDialogOptions) => {
        toggleCellDialog();
        setSelectedCell(cell);

        if (options) {
            setOptions(options);
        }
    };

    const closeCellDialog = () => {
        toggleCellDialog();
        setSelectedCell(undefined);
        setOptions(undefined);
    };

    return {
        options,
        selectedCell,
        setSelectedCell,
        cellDialogOpen: open,
        openCellDialog,
        closeCellDialog,
    };
});

export const useCellDialog = useConsumer;
export const CellDialogProvider = Provider;
