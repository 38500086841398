import { useApolloClient } from '@apollo/client';

import { fetchElements } from '../requests';
import { Queries } from '@/components/Estimate/queries';
import { Transforms } from '@/components/Estimate/transforms';
import { useEstimationEditor } from '@/components/app/router/EstimationRoute/hooks/useEstimationEditor';
import { IElementFragment } from '@/graphql';

// RefreshElementQuantities refreshes the quantities of table elements meeting the passed criteria.
type RefreshElementQuantities = (
    options: Parameters<Queries['elements']>[1]
) => Promise<IElementFragment[]>;

export const useRefreshElementQuantities = (): RefreshElementQuantities => {
    const client = useApolloClient();
    const editor = useEstimationEditor();

    const refreshElementQuantities: RefreshElementQuantities = async (options) => {
        const elementUUIDsUsingGroup: string[] = [];
        for (const [element] of Queries.elements(editor, options)) {
            elementUUIDsUsingGroup.push(element.elementUUID);
        }
        if (elementUUIDsUsingGroup.length === 0) {
            return [];
        }
        const updatedElements = await fetchElements(client, elementUUIDsUsingGroup);
        updatedElements.forEach((updatedElement) => {
            Transforms.setElementQuantity(
                editor,
                updatedElement.id,
                updatedElement.expression.result
            );
        });
        return updatedElements;
    };

    return refreshElementQuantities;
};
