/* Provides experiments */
import gql from 'graphql-tag';

export const ExperimentsQuery = gql`
    {
        experiments {
            __typename
            nodes {
                id
                name
                description
                created
                lastModified
            }
        }
    }
`;
