import React, { FC } from 'react';

import styled from 'styled-components';

import {
    backgroundActiveDashboardModule,
    colorHighlightLight,
    colorTypographyInfo,
} from '@/variables';

const Container = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.75rem;
    margin: 0;
    padding: 0 0 0 1.5rem;
    cursor: pointer;
    background: ${(props): string =>
        props.active ? backgroundActiveDashboardModule : 'transparent'};
    border-left: ${(props): string =>
        props.active ? `0.25rem solid ${colorHighlightLight}` : 'none'};
`;

const Text = styled.h4`
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: -0.03rem;
    color: ${colorTypographyInfo};
    opacity: 0.9;
    margin: 0 0 0 1.125rem;
`;

export interface LinkProps {
    selected: boolean;
    Icon: React.ReactNode;
    name: string;
    onClick: (name: string) => void;
}

export const Link: FC<LinkProps> = ({ selected, Icon, name, onClick }) => {
    return (
        <Container
            active={selected}
            onClick={(): void => {
                onClick(name);
            }}
        >
            {Icon}
            <Text>{name}</Text>
        </Container>
    );
};
