/*
 * Activity is the Activity project panel's activity log module.
 */
import React, { FC, useEffect, useState } from 'react';

import { Entry } from './Entry';
import { ActivityContainer } from './styled';
import { GroupedEvents, reduceEvents } from './utils';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { activityModuleClosedObservable } from '@/components/AdminDashboard/ProjectPanel/observables';
import { compareDateStrings } from '@/components/AdminDashboard/Projects/sort';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { EventRecord, useEventsByEventTypeQuery } from '@/queries/events';
import { useEventCreatedSubscription } from '@/subscriptions/events';

export const Activity: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { setEntryIdsMarkedSeen, setEntryIdsMarkedUnseen, project } = useProjectPanel();

    const eventCreatedSubscriptionResult = useEventCreatedSubscription();

    const [entries, setEntries] = useState<GroupedEvents[]>([]);
    const [loading, setLoading] = useState(false);
    const [eventsQueryResult] = useEventsByEventTypeQuery({
        eventCondition: { projectId: project.id },
        eventTypeCondition: {},
    });

    // Reset state entry data using a new list of events.
    const setEventsState = (events: EventRecord[]): void => {
        setEntryIdsMarkedSeen(
            events.reduce<Set<number>>(
                (acc, event) => (event.seen ? acc : acc.add(event.id)),
                new Set()
            )
        );
        setEntryIdsMarkedUnseen(new Set());
        setEntries(
            events
                .sort((a, b) => compareDateStrings(a.created, b.created))
                .reduce<GroupedEvents[]>(reduceEvents, [])
        );
    };

    // A new event (usually from a subscription) should only be added to the entries list if:
    //   a) It has the smae project id as the project active in the panel
    //   b) It is not already in the entries list
    const shouldInjectEvent = (event: EventRecord): boolean => {
        if (event.projectId !== project.id) {
            return false;
        }
        return (
            entries.findIndex(
                (entry) =>
                    entry.eventIds?.read.includes(event.id) ||
                    entry.eventIds?.unread.includes(event.id)
            ) === -1
        );
    };

    // On unmount, trigger the relevant observable and refetch events if they've changed.
    useEffect(() => (): void => activityModuleClosedObservable.next(), []);

    useEffect(() => {
        if (eventsQueryResult.fetching) {
            setLoading(true);
            return;
        }
        const events = eventsQueryResult.data?.eventTypes.nodes.reduce<EventRecord[]>(
            (acc, eventType) => [...acc, ...eventType.eventsByEventTypeName.nodes],
            []
        );
        if (events !== undefined) {
            setLoading(false);
            setEventsState(events);
        }
    }, [eventsQueryResult]);

    useEffect(() => {
        const newEvent = eventCreatedSubscriptionResult?.data?.EventCreated.eventLog;
        if (newEvent === undefined || newEvent === null || !shouldInjectEvent(newEvent)) {
            return;
        }
        setEntries((entries) => [...reduceEvents([], newEvent), ...entries]);
        if (!newEvent.seen) {
            setEntryIdsMarkedSeen((ids) => ids.add(newEvent.id));
        }
    }, [eventCreatedSubscriptionResult]);

    if (loading) {
        return <SpinnerLoader />;
    }

    return (
        <ActivityContainer>
            {entries.map((entry, idx) => (
                <Entry key={idx} useProjectPanel={useProjectPanel} eventGroup={entry} />
            ))}
        </ActivityContainer>
    );
};
