/*
 * Manage is the estimator sub-module for managing an assigned estimator.
 */
import React, { FC, useEffect, useState } from 'react';

import to from 'await-to-js';
import moment from 'moment';

import {
    EstimatorManagementContainer,
    EstimatorManagementHeaderContainer,
    EstimatorManagementHeaderText,
    EstimatorManagementRemove,
} from './styled';

import { DatabaseProjectStatus } from '@/common/types';
import { ButtonVariant } from '@/components/AdminDashboard/Modal/Button';
import { Entry } from '@/components/AdminDashboard/ProjectPanel/Activity/Entry';
import {
    GroupedEvents,
    extractUserID,
} from '@/components/AdminDashboard/ProjectPanel/Activity/utils';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { compareDateStrings } from '@/components/AdminDashboard/Projects/sort';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { useUnassignEstimatorMutation } from '@/graphql';
import { pushToOpenedModalObservable } from '@/interfaces/adminDashboardModal';
import { AnyEventTypeName, EventTypeName } from '@/queries/eventTypes';
import { useEventsQuery } from '@/queries/events';
import { colorStatusError } from '@/variables';

import { ReactComponent as RemoveEstimator } from '@/assets/icons/RemoveEstimator.svg';

const COPY = {
    cancelButton: 'CANCEL',
    fromProject: 'from this project?',
    toStopWork:
        'to stop any estimation work that has been carried out so far, and remove their access to this ' +
        'project.',
    unassign: 'Unassign',
    unassignButton: 'UNASSIGN',
    unassignSuccessTitle: 'Successfully unassigned estimator',
    unassignErrorTitle: 'Estimator unassignment failed',
    unassignErrorBody: 'Try again',
    willNotify: 'This will notify',
};

const estimatorEventTypes: AnyEventTypeName[] = [
    EventTypeName.AcceptAssignment,
    EventTypeName.ApproveEstimate,
    EventTypeName.AssignEstimator,
    EventTypeName.DeclineAssignment,
    EventTypeName.UnassignEstimator,
];

export const Manage: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { estimator, setEstimator, project, setProject } = useProjectPanel();
    const { addNotification } = useNotifications();
    const {
        data: { user },
    } = useUser();

    const [entries, setEntries] = useState<GroupedEvents[]>([]);
    const [loading, setLoading] = useState(false);

    const [unassignEstimator] = useUnassignEstimatorMutation();

    const [eventsQueryResult, refetchEventsQuery] = useEventsQuery({ projectId: project.id });

    const estimatorName = `${estimator?.firstName ?? ''} ${estimator?.lastName ?? ''}`;

    useEffect(() => refetchEventsQuery({ requestPolicy: 'network-only' }), []);

    useEffect(() => {
        if (eventsQueryResult.fetching) {
            setLoading(true);
            return;
        }
        setLoading(false);
        const events = eventsQueryResult.data?.events.nodes;
        if (events === undefined) {
            return;
        }
        setEntries(
            events
                .filter((event) => estimatorEventTypes.includes(event.eventTypeName))
                .sort((a, b) => compareDateStrings(a.created, b.created))
                .map<GroupedEvents>((event) => ({
                    count: 1,
                    time: event.created !== undefined ? moment.utc(event.created) : undefined,
                    eventType: event.eventTypeName,
                    linkedUserID: extractUserID(event),
                }))
        );
    }, [eventsQueryResult]);

    const unassignEstimatorFromProject = async (): Promise<void> => {
        if (estimator === undefined) {
            throw new Error(
                `Failed to unassign estimator from project with id ${project.id}: no assigned estimator`
            );
        }

        const [error] = await to(
            unassignEstimator({
                variables: {
                    input: {
                        projectID: project.id.toString(),
                        ownerID: user.id,
                        userID: estimator.id.toString(),
                    },
                },
            })
        );

        if (error) {
            addNotification(
                { title: COPY.unassignErrorTitle, content: COPY.unassignErrorBody },
                'error'
            );
        } else {
            setProject((project) => ({
                ...project,
                status: DatabaseProjectStatus.NEW,
                projectUsers: {
                    nodes:
                        project.projectUsers?.nodes.filter((u) => u.user.id !== estimator.id) ?? [],
                },
            }));
            setEstimator(undefined);
            addNotification(
                {
                    title: `${COPY.unassignSuccessTitle}: ${estimator.firstName ?? ''} ${
                        estimator.lastName ?? ''
                    }`,
                },
                'success'
            );
        }
    };

    const handleUnassignEstimator = (): void =>
        pushToOpenedModalObservable({
            accentColor: colorStatusError,
            title: `${COPY.unassign} ${estimatorName} ${COPY.fromProject}`,
            body: `${COPY.willNotify} ${estimatorName} ${COPY.toStopWork}`,
            buttons: [
                {
                    text: COPY.cancelButton,
                    variant: ButtonVariant.Cancel,
                },
                {
                    text: COPY.unassignButton,
                    variant: ButtonVariant.Action,
                    onClick: unassignEstimatorFromProject,
                },
            ],
        });

    if (loading) {
        return <SpinnerLoader />;
    }

    return (
        <EstimatorManagementContainer>
            <EstimatorManagementHeaderContainer>
                <EstimatorManagementHeaderText>{estimatorName}</EstimatorManagementHeaderText>
                <EstimatorManagementRemove onClick={handleUnassignEstimator}>
                    <RemoveEstimator />
                </EstimatorManagementRemove>
            </EstimatorManagementHeaderContainer>
            {entries.map((entry, idx) => (
                <Entry key={idx} useProjectPanel={useProjectPanel} eventGroup={entry} />
            ))}
        </EstimatorManagementContainer>
    );
};
