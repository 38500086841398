import { Cell, CellType, Coordinates, LeydenEditor, Table } from 'leyden';
import { ReactEditor } from 'leyden-react';
import { useMemo } from 'react';
import { useContextMenu } from './useContextMenu';
import { useEstimateEditor } from '../../hooks/useEstimateEditor';
import { Transforms } from '../../transforms';
import { isElementCell } from '../../utils/typeGuards';
import { useApolloClient } from '@apollo/client';
import { Queries } from '@/components/Estimate/queries';
import { MenuRowOption } from '@/components/ui/inputs/Menu/types';
import { notNull } from '@/common/utils/helpers';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/32/trash-can.svg';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/32/chevron--down.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/32/chevron--up.svg';
import { ReactComponent as FolderAddIcon } from '@/assets/icons/32/folder--add.svg';
import { useAssemblies } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { IAssemblyType } from '@/graphql';

const COPY = {
    noGroup: 'Ungroup',
    favorite: 'Add to My Library',
    unfavorite: 'Remove from My Library',
    delete: 'Delete',
    moveElementDown: 'Move down',
    moveElementUp: 'Move up',
    moveElementToGroup: 'Move to group',
    duplicateGroup: 'Duplicate',
    cancel: 'Cancel',
    deleteItemConfirmation: {
        title: 'Delete this item from the estimate?',
        description: 'This will remove the item and it’s data from the estimate.',
        confirm: 'Delete',
    },
    deleteGroupConfirmation: {
        title: 'Delete this group from the estimate?',
        description: 'This will remove the group and it’s items from the estimate.',
        confirm: 'Delete',
    },
};

type OverriddenData = {
    cell: Cell<CellType>;
};

export const useContextMenuOptions = (overriddenData?: OverriddenData): MenuRowOption[] => {
    const client = useApolloClient();
    const { data, close } = useContextMenu();
    const editor = useEstimateEditor();
    const { project } = useProjectFromParams();

    const { assemblies, uncategorizedAssembly } = useAssemblies({
        projectId: project?.id,
    });

    // If we get the overridden data means we're trying to get the options only from this hook
    // Otherwise this hook was called from the ContextMenu component
    const realCell = overriddenData?.cell ?? data?.cell ?? null;

    const otherCategories = useMemo(() => {
        if (realCell === null || !isElementCell(realCell)) {
            return [];
        }

        const categories = uncategorizedAssembly
            ? [...assemblies, uncategorizedAssembly]
            : assemblies;

        const parsedCategories = categories
            // Remove the weird empty category from the list
            .filter((category) => category.assemblyType !== IAssemblyType.Item)
            .map((category) => ({
                name: category.description,
                categoryID: category.id,
            }));

        return [
            ...[
                {
                    categoryID: null,
                    name: COPY.noGroup,
                },
            ],
            ...parsedCategories,
        ].filter(({ categoryID }) => categoryID !== realCell.categoryID);
    }, [realCell, uncategorizedAssembly, assemblies]);

    const coords = useMemo(() => {
        if (realCell === null) {
            return null;
        }
        return ReactEditor.cellCoords(editor, realCell);
    }, [realCell]);

    const neighborCells = useMemo(() => {
        if (coords === null) {
            return { above: null, below: null };
        }
        return {
            above: Table.cell(LeydenEditor.table(editor), { at: Coordinates.move(coords, 'up') }),
            below: Table.cell(LeydenEditor.table(editor), { at: Coordinates.move(coords, 'down') }),
        };
    }, [coords]);

    const neighborCategories = useMemo(() => {
        let above: number | null = null;
        let below: number | null = null;
        if (realCell === null || !Cell.isCell(realCell, { type: 'Category' })) {
            return { above, below };
        }
        let found = false;
        for (const category of Queries.categories(editor)) {
            if (realCell.id === category[0].categoryID) {
                found = true;
            } else if (!found) {
                above = category[1];
            } else if (found) {
                below = category[1];
                break;
            }
        }

        return {
            above: above,
            below: below,
        };
    }, [realCell]);

    const deleteCategoryOption = useMemo(() => {
        if (realCell === null) {
            return null;
        }
        const cell = realCell;
        if (!Cell.isCell(cell, { type: 'Category' })) {
            return null;
        }
        return {
            text: COPY.delete,
            icon: TrashCanIcon,
            confirmationText: {
                ...COPY.deleteGroupConfirmation,
            },
            onClick: (): void => {
                Transforms.deleteCategory(editor, cell.id, client);
                close();
            },
        };
    }, [realCell]);

    const deleteElementOption = useMemo(() => {
        if (realCell === null) {
            return null;
        }
        const cell = realCell;
        if (!isElementCell(cell)) {
            return null;
        }
        return {
            text: COPY.delete,
            icon: TrashCanIcon,
            confirmationText: {
                ...COPY.deleteItemConfirmation,
            },
            onClick: (): void => {
                Transforms.deleteElement(editor, cell.elementID, client);
                close();
            },
        };
    }, [realCell]);

    const favoriteCategoryOption = useMemo(() => {
        if (realCell === null) {
            return null;
        }
        const cell = realCell;
        if (Cell.isCell(cell, { type: 'Category' })) {
            return {
                text: cell.favorited ? COPY.unfavorite : COPY.favorite,
                onClick: (): void => {
                    Transforms.favoriteCategory(editor, cell.id, undefined, cell.favorited, {
                        at: coords ?? undefined,
                        client,
                    });
                    close();
                },
            };
        }

        if (Cell.isCell(cell, { type: 'Name' })) {
            return {
                text: cell.favorited ? COPY.unfavorite : COPY.favorite,
                onClick: (): void => {
                    Transforms.favoriteCategory(editor, undefined, cell.elementID, cell.favorited, {
                        at: coords ?? undefined,
                        client,
                    });
                    close();
                },
            };
        }

        return null;
    }, [realCell]);

    const moveElementDownOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            neighborCells.below === null ||
            !isElementCell(realCell) ||
            !isElementCell(neighborCells.below)
        ) {
            return null;
        }
        return {
            text: COPY.moveElementDown,
            icon: ChevronDownIcon,
            onClick: (): void => {
                Transforms.reorderElement(editor, {
                    at: coords.y,
                    direction: 'down',
                    client,
                });
                close();
            },
        };
    }, [coords, realCell, neighborCells.below]);

    const moveElementUpOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            neighborCells.above === null ||
            !isElementCell(realCell) ||
            !isElementCell(neighborCells.above)
        ) {
            return null;
        }
        return {
            text: COPY.moveElementUp,
            icon: ChevronUpIcon,
            onClick: (): void => {
                Transforms.reorderElement(editor, {
                    at: coords.y,
                    direction: 'up',
                    client,
                });
                close();
            },
        };
    }, [realCell, neighborCells.above]);

    const moveGroupDownOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            neighborCategories.below === null ||
            !Cell.isCell(realCell, { type: 'Category' })
        ) {
            return null;
        }

        return {
            text: COPY.moveElementDown,
            onClick: (): void => {
                if (!Cell.isCell(realCell, { type: 'Category' })) {
                    return;
                }
                Transforms.reorderCategory(editor, {
                    categoryID: realCell.id,
                    direction: 'down',
                    client,
                });
                close();
            },
        };
    }, [coords, realCell, neighborCategories.below]);

    const moveGroupUpOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            neighborCategories.above === null ||
            !Cell.isCell(realCell, { type: 'Category' })
        ) {
            return null;
        }

        return {
            text: COPY.moveElementUp,
            onClick: (): void => {
                if (!Cell.isCell(realCell, { type: 'Category' })) {
                    return;
                }
                Transforms.reorderCategory(editor, {
                    categoryID: realCell.id,
                    direction: 'up',
                    client,
                });
                close();
            },
        };
    }, [coords, realCell, neighborCategories.above]);

    const moveToGroupOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            otherCategories.length === 0 ||
            !isElementCell(realCell)
        ) {
            return null;
        }

        const { elementID } = realCell;

        const handleMoving = (targetGroupID: string | null): (() => void) => {
            return (): void => {
                Transforms.moveElementToGroup(editor, {
                    elementID,
                    categoryID: targetGroupID,
                    client,
                });
                close();
            };
        };

        const categoryMoveOptions = otherCategories.map(({ categoryID, name }) => {
            return {
                id: categoryID ?? undefined,
                text: name,
                onClick: handleMoving(categoryID),
            };
        });
        return {
            text: COPY.moveElementToGroup,
            options: categoryMoveOptions,
            icon: FolderAddIcon,
        };
    }, [realCell, otherCategories]);

    const duplicateGroupOption = useMemo(() => {
        if (
            coords === null ||
            realCell === null ||
            !Cell.isCell(realCell, { type: 'Category' }) ||
            !project?.id
        ) {
            return null;
        }

        return {
            text: COPY.duplicateGroup,
            onClick: (): void => {
                if (!Cell.isCell(realCell, { type: 'Category' })) {
                    return;
                }
                Transforms.duplicateCategory(editor, {
                    categoryID: realCell.id,
                    projectID: project.id.toString(),
                    client,
                });
                close();
            },
        };
    }, [coords, realCell, neighborCategories.above, project?.id]);

    // Options with callbacks
    return [
        moveGroupUpOption,
        moveGroupDownOption,
        duplicateGroupOption,
        moveElementUpOption,
        moveElementDownOption,
        favoriteCategoryOption,
        deleteCategoryOption,
        deleteElementOption,
        moveToGroupOption,
    ].filter(notNull);
};
