import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled as styledByMui } from '@mui/material/styles';

export const Card = styledByMui(MuiCard)((options) => ({
    borderTop: `2px solid ${options.theme.palette.hues.orange['0']}`,
    borderRadius: 0,
    padding: '24px',
}));

export const PlanHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.text.primary,
    marginBottom: 16,
    textAlign: 'center',
    textTransform: 'uppercase',
}));

export const PlanDescription = styledByMui(Typography)({
    marginBottom: 24,
    textAlign: 'center',
});

export const PlanVariants = styledByMui('div')({
    display: 'flex',
    marginBottom: 24,
});

export const PlanVariant = styledByMui('div')({
    display: 'grid',
    flex: 1,
});

export const Seperator = styledByMui('div')((options) => ({
    color: options.theme.palette.hues.neutral['48'],
    marginTop: 20,
}));

export const PlanPrice = styledByMui(Typography)((options) => ({
    color: options.theme.palette.text.primary,
    textAlign: 'center',
}));

export const PlanPeriod = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral['48'],
    marginBottom: 24,
    textAlign: 'center',
    textTransform: 'capitalize',
}));

export const HighlightListItemIcon = styledByMui(ListItemIcon)((options) => ({
    color: options.theme.palette.hues.orange['0'],
}));
