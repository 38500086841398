import { toRotated as coordinateDataToRotated } from '@/common/convert/coordinateData';
import { RotationOptions } from '@/common/convert/coordinateData/toRotated';
import { Geometry, GeometryType } from '@/common/types';

export const toRotated = <T extends GeometryType>(
    geometry: Geometry<T>,
    options: RotationOptions
): Geometry<T> => {
    return {
        ...geometry,
        coordinates: coordinateDataToRotated(geometry.coordinates, options),
    };
};
