import styled from 'styled-components';
import { transition } from '@/variables';

const backgroundGrid = `
" .    .        .     .        .   " 1fr
" .    .        .     .        .   " 0.25rem
" .    .        card  .        .   " auto
" .    .        .     .        .   " 0.25rem
" .    .        .     .        .   " 1fr
/ 1fr  0.25rem  auto  0.25rem  1fr `;

const modalCardGrid = `
" .       .        .        .      " 1.5rem
" .       .        close    .      " 1.5rem
" .       .        .        .      " 1rem
" .       title    .        .      " auto
" .       .        .        .      " 1rem
" .       content  .        .      " auto
" .       .        .        .      " 2rem
" .       actions  .        .      " auto
" .       .        .        .      " 1.5rem
/ 1.5rem  1fr      1.5rem   1.5rem `;

export const UserModalBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template: ${backgroundGrid};
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
    z-index: 99;
    background-color: transparent;
`;

export const UserModalActions = styled.div`
    grid-area: actions;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 0.75rem;
    align-items: stretch;
    justify-content: start;
    height: 2.125rem;
`;

export const UserModalActionButton = styled.button`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 1.5rem;
    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.08em;
    line-height: 11px;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 0.25rem;
    color: #ffffff;
    transition: all ${transition};

    &:hover {
        opacity: 0.8;
    }
`;

export const UserModalCard = styled.div`
    grid-area: card;
    display: grid;
    grid-template: ${modalCardGrid};
    align-items: stretch;
    justify-content: stretch;
    max-height: calc(100vh - 0.5rem);
    max-width: calc(100vw - 0.5rem);
    overflow: auto;
    background-color: #ffffff;
    border-radius: 0.25rem;
    box-shadow: 0px 12px 64px rgba(0, 0, 0, 0.25);
`;

export const UserModalCloseButton = styled.button`
    grid-area: close;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 0.125rem;
    transition: all ${transition};

    &:hover {
        background-color: rgba(27, 34, 53, 0.15);
    }
`;

export const UserModalContent = styled.div`
    grid-area: content;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-gap: 0.75rem 1rem;
    justify-content: start;
    justify-items: start;
    font-family: ${(props): string => props.theme.v1.fontFamily.proximaNova};
    font-size: 1rem;
    line-height: 1rem;
    opacity: 0.7;
    color: #1b2235;
`;

export const UserModalPropertyName = styled.span`
    font-weight: 700;
`;

export const UserModalPropertyValue = styled.span`
    font-weight: 400;
`;

export const UserModalTitle = styled.h1`
    grid-area: title;
    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-weight: 500;
    font-size: 1.75rem;
    letter-spacing: -0.01em;
    line-height: 2.25rem;
    margin: 0;
    color: #1b2235;
`;
