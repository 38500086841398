import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

export const Overlay = styled.div`
    z-index: 4;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: #000000;
    opacity: 0.5;
`;

export const Button = styled.div`
    grid-area: button;

    cursor: pointer;

    color: #fff;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 4px;
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    user-select: none;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.04em;
`;

export const Container = styled.div`
    z-index: 5;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    font-family: 'proxima-nova', sans-serif;

    ${breakpoints.small`
        top: auto;
        bottom: 0;

        width: 100vw;
    `}
`;
