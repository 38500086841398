import React, { FC } from 'react';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { Header } from './styled';
import { ReactComponent as FullLogoIcon } from '@/assets/images/full-logo-v2.svg';

export const UnsubscribeAppBar: FC<AppBarProps> = (props) => {
    return (
        <AppBar {...props}>
            <Header>
                <FullLogoIcon height="60px" width="auto" />
            </Header>
        </AppBar>
    );
};
