import { Plan, PlanName, PlanPeriod } from './types';

import { Env } from '@/common/env';

export const unknownPlan: Plan = {
    name: PlanName.Unknown,
    description:
        'For builders who want to win larger, more complex jobs with the help of expert estimators.',
    variants: [
        {
            id: 'unknown',
        },
    ],
};

export const plans: Plan[] = [
    {
        name: PlanName.Essential,
        description:
            'For builders who want to estimate with confidence using local cost data and expert estimators.',
        variants: [
            {
                id: Env.stripe.essentialMonthlyPrice,
                price: '$199',
                period: PlanPeriod.Monthly,
            },
            {
                id: Env.stripe.essentialAnnualPrice,
                price: '$1,999',
                period: PlanPeriod.Annually,
            },
        ],
        highlights: [
            'Hands-on training',
            'Rapid estimates',
            'Real-time, local cost data',
            'Unlimited paid expert estimates per year (at an additional cost)',
        ],
    },
    {
        name: PlanName.Professional,
        description:
            'For builders who want to win larger, more complex jobs with the help of local cost data, rapid digital takeoffs, and expert estimators',
        variants: [
            {
                id: Env.stripe.professionalMonthlyPrice,
                price: '$299',
                period: PlanPeriod.Monthly,
            },
            {
                id: Env.stripe.professionalAnnualPrice,
                price: '$2,999',
                period: PlanPeriod.Annually,
            },
        ],
        highlights: [
            'Hands-on training',
            'Rapid estimates',
            'Real-time, local cost data',
            'Takeoff software',
            'Unlimited paid expert estimates per year (at an additional cost)',
        ],
    },
];
