import React, { FC } from 'react';

import { InputEvent, InputProps } from '@/common/types';
import { FormControl, NumericControlProps } from './helpers/FormControl';

export const NumericInput: FC<NumericControlProps<InputProps>> = ({
    label,
    required,
    value,
    onChange,
    className,
    ...rest
}) => {
    const handleChange = (e: InputEvent): void => onChange(e.target.valueAsNumber);

    return (
        <FormControl label={label} required={required} value={value} className={className}>
            <input type="number" value={value} onChange={handleChange} {...rest} />
        </FormControl>
    );
};
