// Apollo GraphQL client
// -----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import {
    ApolloClient,
    ApolloLink,
    FieldFunctionOptions,
    HttpLink,
    InMemoryCache,
    makeVar,
    NormalizedCacheObject,
    split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ConnectionParams } from 'subscriptions-transport-ws/dist/client';

import { Bugsnag } from '@/components/app/Bugsnag';
import { Env, backendURL, backendWebsocketURL } from '../env';
import { BearerToken } from '../urql/bearerToken';
import { definitionNodeIsFragmentOrOperationDefinitionNode } from '../urql/invalidationExchange';
import { customRelayStylePagination } from './customRelayStylePagination';

/* Local */
import {
    completedApolloOperationObservable,
    completedUrqlOperationObservable,
    subscribeToCompletedUrqlOperationObservable,
} from './observables';

import possibleTypesData from '@/graphql/possibleTypes';
import { TypedTypePolicies } from '@/graphql/typePolicies';

// -----------------------------------------------------------------------------

export const selectedMarkupIDs = makeVar<string[]>([]);

const typePolicies: TypedTypePolicies = {
    AssetsGraph: {
        keyFields: false,
    },
    Query: {
        fields: {
            sources: customRelayStylePagination(['input', ['condition']]),
            users: customRelayStylePagination(['query']),
            usersLite: customRelayStylePagination(['query']),
            assembliesLite: customRelayStylePagination(['input', ['condition']]),
            categories: customRelayStylePagination(['input', ['condition']]),
            markups: customRelayStylePagination(['input', ['condition']]),
            projects: customRelayStylePagination(['query']),
        },
    },
    Markup: {
        fields: {
            isSelected: {
                read(_value: boolean, { readField }: FieldFunctionOptions) {
                    const markupID = readField('id');

                    return !!selectedMarkupIDs().find((id) => id === markupID);
                },
            },
        },
    },
};

const urqlOperationInvalidation = (opName: string): string[] => {
    switch (opName) {
        case 'Assembly':
            return ['assemblies'];
        case 'ProjectAssembly':
            return ['assemblies'];
        case 'ProjectPlanPage':
            return ['assemblies'];
        default:
            return [];
    }
};

export function createClient(): ApolloClient<NormalizedCacheObject> {
    // Create the cache next, which we'll share across Apollo tooling.
    // This is an in-memory cache. Since we'll be calling `createClient` on
    // universally, the cache will survive until the HTTP request is
    // responded to (eventually on the server) or for the whole of the user's visit (in
    // the browser)

    // We can specify different policies against data types for our cache to operate on.
    // For now though, we're going to keep things simple. For more info, refer to:
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#data-normalization
    const cache = new InMemoryCache({
        possibleTypes: possibleTypesData.possibleTypes,
        typePolicies,
    });

    // General error handler, to log errors back to the console.
    // Replace this in production with whatever makes sense in your
    // environment. Remember you can use the global `SERVER` variable to
    // determine whether you're running on the server, and record errors
    // out to third-party services, etc
    const errorLink: ApolloLink = onError(({ graphQLErrors, networkError }) => {
        if (networkError)
            Bugsnag?.notify(
                new Error(`[Network error ${networkError.name}]: ${networkError.message}`)
            );
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                Bugsnag?.notify(
                    new Error(
                        `[GraphQL error]: Message: ${message}, Location: ${
                            typeof locations === 'undefined'
                                ? 'undef'
                                : locations.map((l) => `[line: ${l.line}, col: ${l.column}]`).join()
                        }, Path: ${typeof path === 'undefined' ? 'undef' : path.join(' ')}`
                    )
                )
            );
    });

    const authLink = setContext(async (req, { headers }) => {
        if (
            req.operationName === 'Login' ||
            req.operationName === 'Signup' ||
            req.operationName === 'ForgotPassword' ||
            req.operationName === 'PublicUnsubscribeEstimate' ||
            req.operationName === 'PublicEvent'
        ) {
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            return { headers: { ...headers } };
        }
        if (req.operationName?.startsWith('CostData')) {
            const userEmail = await BearerToken.getUserEmail();
            return {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                headers: {
                    ...headers,
                    '1build-api-key': Env.costDataApiKey,
                    '1build-host-referer': window.location.href,
                    '1build-client-user-id': userEmail,
                },
            };
        }

        const token = await BearerToken.getToken();

        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } };
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: 250,
            jitter: true,
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation): boolean => !!error,
        },
    });

    const invalidationTriggeringLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
            const { variables, operationName } = operation;
            completedApolloOperationObservable.next({
                operationName,
                variables,
            });
            return data;
        });
    });

    subscribeToCompletedUrqlOperationObservable((op) => {
        // We delay this just slightly to make sure the DB has enough time to wrap up the postgraphile mutation's
        // transaction before the backend's transaction starts.
        setTimeout(() => {
            const invalidatedRecords = new Set<string>();
            if (!op) {
                return;
            }
            const invalidatedTypes = new Set(
                op.query.definitions
                    .filter(definitionNodeIsFragmentOrOperationDefinitionNode)
                    .map((def) => def.name?.value.split('_')[0] ?? '')
            );
            invalidatedTypes.forEach((t) =>
                urqlOperationInvalidation(t).forEach((o) => invalidatedRecords.add(o))
            );
            invalidatedRecords.forEach((r) => {
                const data = cache.extract();
                for (const query in data['ROOT_QUERY']) {
                    if (query.startsWith(r)) {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: query });
                    }
                }
            });
            cache.gc();
            completedUrqlOperationObservable.next(null);
        }, 500);
    });

    // This function exists to avoid sending ws requests when we are in the test env
    // Since we're using MSW library to intercept the requests and that library does not support WS yet
    // That was causing problems when trying to intercept the real GraphQL requests
    const createSplitLink = () => {
        const httpLink = new HttpLink({
            credentials: 'same-origin',
            uri: ({ operationName }) => {
                if (operationName?.startsWith('CostData')) {
                    const url = Env.costDataUrl;
                    return `${url}?opname=${operationName}`;
                }

                return `${backendURL()}?opname=${operationName}`;
            },
        });

        if (Env.tier.isTest) {
            return httpLink;
        }

        // Only temporarily blocked
        const wsLink = new WebSocketLink({
            uri: backendWebsocketURL(),
            options: {
                reconnect: true,
                connectionParams: async (): Promise<ConnectionParams> => {
                    const token = await BearerToken.getToken();
                    return { authorization: token ? `Bearer ${token}` : '' };
                },
            },
        });

        // The split function takes three parameters:
        //
        // * A function that's called for each operation to execute
        // * The Link to use for an operation if the function returns a "truthy" value
        // * The Link to use for an operation if the function returns a "falsy" value
        // This is temporarily turned off
        return split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            authLink.concat(httpLink)
        );
    };

    const link = ApolloLink.from([
        invalidationTriggeringLink,
        errorLink,
        retryLink,
        createSplitLink(),
    ]);

    const connectToDevTools = Env.tier.isDevelopment;

    // Return a new Apollo Client back, with the cache we've just created,
    // and an array of 'links' (Apollo parlance for GraphQL middleware)
    // to tell Apollo how to handle GraphQL requests
    return new ApolloClient({ cache, link, connectToDevTools });
}
