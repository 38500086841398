import { newMutations } from '../frog';
import { AssemblyRecord, assemblyFragment } from '../queries/assemblies';

export type DeleteAssemblyArgs = {
    input: {
        id: number;
    };
};

export type DeleteAssemblyResult = {
    deleteAssembly: {
        assembly: AssemblyRecord;
    };
};

export const deleteAssemblyMutation = assemblyFragment.gql`
    mutation DeleteAssembly(
        $input: DeleteAssemblyInput!
    ) {
        deleteAssembly(input: $input) {
            assembly {
                ${assemblyFragment}
            }
        }
    }
`;

export const [executeDeleteAssemblyMutation, useDeleteAssemblyMutation] = newMutations<
    DeleteAssemblyArgs,
    DeleteAssemblyResult
>(deleteAssemblyMutation);
