import React, { useEffect, useMemo } from 'react';

import { CellRenderer, useLeydenStatic, useRelativeCell } from 'leyden-react';

import { EstimateEditor } from '../../editor/EstimateEditor';
import { ratesToCostPerUnit } from '../../utils/rates';
import { Cell } from '../components/Cell';

import { formatters } from '@/common/currency';

const formatter = formatters.usd();

export const Total: CellRenderer<'Total'> = ({ attributes, children, element }) => {
    const editor = useLeydenStatic();
    const rateCell = useRelativeCell(element, {
        translation: { x: -1 },
        type: 'Rate',
    });
    const quantityCell = useRelativeCell(element, {
        translation: { x: -3 },
        type: 'Quantity',
    });

    const rate = useMemo<number | null>(() => {
        if (rateCell === null) {
            return null;
        }
        return ratesToCostPerUnit(rateCell);
    }, [rateCell?.productionRate, rateCell?.laborRate, rateCell?.materialRate]);

    const total = useMemo<number | null>(() => {
        if (quantityCell === null || rate === null || rate === 0) {
            return null;
        }
        const quantity = quantityCell.element.expression.result;
        return quantity * rate;
    }, [quantityCell, rate]);

    const totalString = useMemo(() => {
        return formatter.format(total ?? 0);
    }, [total]);

    useEffect(() => {
        EstimateEditor.recordElementTotal(
            editor,
            element.elementID,
            element.tradeName,
            element.categoryID,
            total
        );
    }, [total]);

    useEffect(() => {
        return (): void => {
            EstimateEditor.recordElementTotal(
                editor,
                element.elementID,
                element.tradeName,
                element.categoryID,
                null
            );
        };
    }, []);

    return (
        <Cell attributes={attributes} caret="never" element={element} highlightOnHover={true}>
            {totalString}
            {children}
        </Cell>
    );
};
