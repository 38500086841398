import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { useLoadScript } from '@react-google-maps/api';

import { Env } from '@/common/env';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { useUpdateProjectLocation } from '@/components/Project/LocationDropdown/hooks/useUpdateProjectLocation';
import { Mode } from '@/theme/Mode';
import { withMode } from '@/theme/withMode';

const COPY = {
    noLocation: 'No location set',
    noLocationOptionsText: 'Street address, city, state, or zip',
};

// Keep reference same to prevent reloading <LoadScript /> component
const libraries: ['places'] = ['places'];

export const LocationAutocompleteComponent: FC = () => {
    const { isLoaded: isMapsScriptLoaded } = useLoadScript({
        googleMapsApiKey: Env.googlePlacesApiKey,
        libraries: libraries,
        preventGoogleFontsLoading: true,
    });
    const { isProjectFetching, projectID, projectInfo } = useEstimationProject();
    const [selectedValue, setSelectedValue] = useState<
        google.maps.places.AutocompletePrediction | string | undefined
    >('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<(google.maps.places.AutocompletePrediction | string)[]>([
        '',
    ]);
    const theme = useTheme();
    const autoCompleteRef = useRef<google.maps.places.AutocompleteService | undefined>(undefined);
    const placesRef = useRef<google.maps.places.PlacesService | undefined>(undefined);

    // Kludge to mount new google.maps.places.PlacesService
    const placesDomRef = useRef<HTMLDivElement>(null);

    const submitNewProject = useUpdateProjectLocation({
        projectId: projectID,
    });

    /**
     * On autocomplete change, make a chained request for google places detail.
     */
    const handleAutocompleteChange = (
        event: React.SyntheticEvent,
        newValue: google.maps.places.AutocompletePrediction | string | null
    ) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSelectedValue(newValue ?? undefined);

        if (newValue && typeof newValue !== 'string') {
            placesRef.current?.getDetails(
                {
                    placeId: newValue?.place_id,
                    fields: ['address_components', 'formatted_address', 'geometry'],
                },
                (place) => {
                    if (place?.geometry?.location) {
                        submitNewProject(place, [
                            place.geometry.location.lat(),
                            place.geometry.location.lng(),
                        ]);
                    }
                }
            );
        }
    };

    /**
     * On blur, if there was originally a project location that has since been deleted,
     * revert to the original name.
     */
    const handleOnblur = () => {
        if (!selectedValue) {
            setSelectedValue(projectInfo.projectLocation?.formattedAddress ?? undefined);
        }
    };

    /**
     * Prevent sending hella requests.
     */
    const retrievePlacePredictions = useMemo(
        () =>
            debounce((request, callback) => {
                autoCompleteRef.current?.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    // On project info update, refresh options, input value, and autocomplete value.
    useEffect(() => {
        if (projectInfo.projectLocation?.formattedAddress) {
            setOptions([projectInfo.projectLocation.formattedAddress]);
            setSelectedValue(projectInfo.projectLocation.formattedAddress);
            setInputValue(projectInfo.projectLocation.formattedAddress);
        }
    }, [projectInfo]);

    // Handle google scripts loading
    useEffect(() => {
        if (isMapsScriptLoaded && placesDomRef.current) {
            placesRef.current = new window.google.maps.places.PlacesService(placesDomRef.current);
        }

        if (isMapsScriptLoaded && !autoCompleteRef.current) {
            autoCompleteRef.current = new window.google.maps.places.AutocompleteService();
        }

        if (inputValue === '') {
            setOptions(selectedValue ? [selectedValue] : []);
        } else {
            retrievePlacePredictions(
                { input: inputValue },
                (results: google.maps.places.AutocompletePrediction[]) => {
                    setOptions([...(selectedValue ? [selectedValue] : []), ...(results ?? [])]);
                }
            );
        }
    }, [isMapsScriptLoaded, selectedValue, inputValue, retrievePlacePredictions]);

    return (
        <>
            <div ref={placesDomRef}></div>
            {isProjectFetching || !isMapsScriptLoaded ? (
                <Mode variant="dark">
                    <Skeleton sx={{ height: 31, width: 500 }} />
                </Mode>
            ) : (
                <Autocomplete
                    autoComplete
                    filterOptions={(x) => x}
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.description
                    }
                    noOptionsText={COPY.noLocationOptionsText}
                    onBlur={handleOnblur}
                    onChange={handleAutocompleteChange}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    options={options}
                    renderInput={({ InputProps, ...params }) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...InputProps,
                                endAdornment: undefined,
                                style: {
                                    color: theme.palette.hues.neutral[100],
                                },
                            }}
                            multiline
                            placeholder={COPY.noLocation}
                            variant="standard"
                        />
                    )}
                    renderOption={(props, option) => (
                        <ListItem {...props} dense>
                            <Grid alignItems="center" container>
                                <Grid item xs>
                                    {typeof option === 'string' ? (
                                        option
                                    ) : (
                                        <ListItemText>
                                            {option?.structured_formatting.main_text}
                                            <Typography variant="body2" color="text.secondary">
                                                {option?.structured_formatting.secondary_text}
                                            </Typography>
                                        </ListItemText>
                                    )}
                                </Grid>
                            </Grid>
                        </ListItem>
                    )}
                    sx={{ width: 500 }}
                    value={selectedValue}
                />
            )}
        </>
    );
};

export const LocationAutocomplete = withMode({ variant: 'light' })(LocationAutocompleteComponent);
