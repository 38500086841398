import { createContext, useContext } from 'react';

import { EstimateEditor } from '../editor/EstimateEditor';

export const EstimateEditorContext = createContext<[EstimateEditor] | null>(null);

export const useEstimateEditor = (): EstimateEditor => {
    const context = useContext(EstimateEditorContext);

    if (!context) {
        // eslint-disable-next-line max-len
        throw new Error(
            "The `useEstimateEditor` hook must be used inside the <EstimateEditorContext> component's context."
        );
    }

    const [editor] = context;
    return editor;
};
