export const projectTypeOptions: Record<string, string[]> = {
    'Land Clearing': ['Commercial', 'Residential', 'Industrial', 'Mixed Use'],
    'Assembly Meeting Place': [
        'Club/Lodge',
        'Community/Recreation Center',
        'Convention Center',
        'Reception/Banquet Hall',
        'Religious Facility',
    ],
    'Commercial & Retail': [
        'Automotive',
        'Bank',
        'Convenience Store',
        'Day Care Facility',
        'Garden Center',
        'Laundromat',
        'Restaurant',
        'Showroom',
        'Street Retail',
        'Shopping Center',
        'Tavern/Bar/Nightclub',
    ],
    Energy: [],
    Healthcare: ['Urgent Care', 'Clinical Laboratory', 'Hospital/Medical Center'],
    Residential: ['Single Family', 'Multi Family', 'Senior Housing', 'Student Housing'],
    Industrial: [
        'Business Park',
        'Communication/Data Center',
        'Flex Space',
        'Manufacturing',
        'Processing',
        'Research & Development',
        'Self-Storage/Mini Storage',
        'Warehouse',
    ],
    Infrastructure: [
        'Dam',
        'Dikes & Floodgates',
        'Electrical Storage',
        'Natural Gas Storage',
        'Roads Bridges',
        'Waste Treatment',
    ],
    'Lodging & Hospitality': ['Bed & Breakfast', 'Casino', 'Hotel', 'Resort'],
    'Military Facility': [],
    'Mixed Use': [],
    Office: [],
    'Public Service': [
        'Courthouse',
        'Educational',
        'Embassy',
        'Fire House',
        'Jail/Correctional Facility',
        'Library',
        'Museum/Gallery',
        'Police Station',
        'Post Office',
        'Zoo/Aquarium',
    ],
    Recreation: [
        'Amusement Facility',
        'Cinema/Movie Theatre',
        'Equestrian Center',
        'Fitness Facility',
        'Golf Course',
        'Racetrack',
        'Shooting Range',
        'Skating Facility',
        'Ski Resort',
        'Sports Arena',
        'Performing Arts Theatre',
    ],
    Transportation: ['Airport', 'Bus/Train Terminal', 'Shipyard'],
};
