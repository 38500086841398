import { markupUnitNameMap } from '@/common/utils/markups/maps';
import { useTakeoffIcon } from '@/components/AssemblyPanel/AssemblyPanelContent/AssemblySection/AssemblyList/GeometrySection/useTakeoffIcon';
import { IMarkupFragment } from '@/graphql';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface MarkupQuantity extends React.HTMLAttributes<HTMLDivElement> {
    markup: IMarkupFragment;
}

export const MarkupQuantity: FC<MarkupQuantity> = ({ markup, ...props }) => {
    const TakeoffIcon = useTakeoffIcon({ geometry: markup.markupType });

    return (
        <div {...props}>
            <Tooltip title={markup.name} {...props}>
                <Stack alignItems="center" direction="row" spacing="6px">
                    <SvgIcon
                        sx={{
                            color: markup.color,
                            width: '14px',
                            height: '14px',
                        }}
                        viewBox="0 0 32 32"
                    >
                        <TakeoffIcon />
                    </SvgIcon>
                    <Typography variant="body1">
                        <span>{markup.measurement.toFixed(2)}</span>
                        <span>{markupUnitNameMap[markup.markupType]}</span>
                    </Typography>
                </Stack>
            </Tooltip>
        </div>
    );
};
