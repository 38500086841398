import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { page } from '@/common/analytics';
import { useUser } from '@/contexts/User';
import { useTrackPageViewMutation } from '@/mutations/customerIO';

/**
 * Hook that posts to analytics on page change
 * @returns {void}
 */
export const useTrackPage = (): void => {
    const [prevPath, setPrevPath] = useState<string>('');
    const location = useLocation();
    const {
        data: { user },
    } = useUser();

    const [, trackUserPageView] = useTrackPageViewMutation();

    // If we have a user, and location name is different from previous
    useEffect(() => {
        if (location.pathname !== prevPath) {
            setPrevPath(location.pathname);
            page();

            if (user.id) {
                trackUserPageView({
                    id: user.id,
                    path: location.pathname,
                });
            }
        }
    }, [location, user]);
};
