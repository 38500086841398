import { useEffect, useMemo, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { PlanPage } from '@/common/types';
import { GeometryPersistencePayload } from '@/common/utils/geometries/save';
import { createSnapshot } from '@/common/utils/geometries/snapshots';
import { executeProjectPlanPageMutation } from '@/mutations/projectPlanPage';
import {
    MarkupWithinGroup,
    markupWithinGroupObservable,
} from '@/components/takeoff/observables/interface';
import { addMarkupsToMarkupGroup } from '@/components/AssemblyPanel/AssemblyPanelContent/AssemblySection/AssemblyList/GeometrySection/requests';
import {
    IMarkupGroupFragment,
    IProjectWithMarkupEntriesQuery,
    ProjectWithMarkupEntriesDocument,
} from '@/graphql';
import { toMarkup } from '@/common/convert/geometry/toMarkup';
import { fromPlanPageGeometry } from '@/common/convert/geometry/fromPlanPageGeometry';

interface UseSaveGeometryPayloadArgs {
    currentPage?: PlanPage | null;
    scale: number | null;
    projectID: number;
    projectUUID: string;
    currentGeometryCount?: number;
}
export const useSaveGeometryPayload = ({
    currentPage,
    scale,
    projectID,
    projectUUID,
}: UseSaveGeometryPayloadArgs) => {
    const markupWithinGroupRef = useRef<MarkupWithinGroup | undefined>(undefined);
    const client = useApolloClient();

    useEffect(() => {
        markupWithinGroupObservable.subscribe(
            (markupWithinGroup) => (markupWithinGroupRef.current = markupWithinGroup)
        );
    }, []);

    const saveGeometryPayload = useMemo<GeometryPersistencePayload | undefined>(() => {
        if (!currentPage) {
            return undefined;
        }
        const projectPlanPageID = parseInt(currentPage.id, 10);
        if (isNaN(projectPlanPageID)) {
            return undefined;
        }
        return {
            persist: async (snapshot, callback): Promise<void> => {
                // Get the ref before async call to prevent ref reset during executeProjectPlanPageMutation
                const markupWithinGroup = markupWithinGroupRef.current;
                const currentGeometryCount = markupWithinGroup?.currentGeometryCount;
                const markupGroupID = markupWithinGroup?.markupGroupID;

                const res = await executeProjectPlanPageMutation({
                    id: projectPlanPageID,
                    markups: createSnapshot(snapshot),
                });

                // If there is a markup being created within a group,
                // add a chained request to add this into a group.
                const markups = res.data?.updateProjectPlanPage.projectPlanPage.markups;
                const projectWithMarkupEntriesQuery =
                    client.readQuery<IProjectWithMarkupEntriesQuery>({
                        query: ProjectWithMarkupEntriesDocument,
                        variables: {
                            uuid: projectUUID,
                        },
                    });

                if (
                    currentGeometryCount !== undefined &&
                    markups &&
                    markups[currentGeometryCount] &&
                    markupGroupID &&
                    projectWithMarkupEntriesQuery
                ) {
                    /**
                     * Update apollo cache optimistically.
                     * This optimistic updates prevents a FOUC where the new geometry appears
                     * underneath uncategorized geometries, then jumps to groups.
                     *
                     * The trouble with this is that for a second between the first urql mutation
                     * resolving and the subsequent apollo mutation resolving, this markup will
                     * appear BOTH within the ungrouped takeoffs and the markup group.
                     * We use a separate hook to dedupe this.
                     * @see {useMarkupEntries}
                     *
                     * This is a super nasty hack, but urql does not support manual cache updates
                     * outside of updaters.  The only way we could let the updater within the
                     * cacheExchange know that we want to bypass a cache update would be by
                     * modifiying mutation parameters.
                     * @link {https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#cache-updates-outside-updaters}
                     */
                    const markupGroupIndex =
                        projectWithMarkupEntriesQuery.project.markupEntries.findIndex(
                            (markupEntry) => {
                                return markupEntry.id === markupGroupID;
                            }
                        );
                    const cacheMarkupGroup = projectWithMarkupEntriesQuery.project.markupEntries[
                        markupGroupIndex
                    ] as IMarkupGroupFragment;

                    // Swap out the markupGroup that will be updated and patch on the new markup
                    const newMarkupEntries = [
                        ...projectWithMarkupEntriesQuery.project.markupEntries.slice(
                            0,
                            markupGroupIndex
                        ),
                        {
                            ...cacheMarkupGroup,
                            markups: [
                                // Update will be written to the first index
                                toMarkup(
                                    fromPlanPageGeometry(markups[currentGeometryCount]),
                                    projectPlanPageID,
                                    scale,
                                    markupGroupID
                                ),
                                ...cacheMarkupGroup.markups,
                            ],
                        },
                        ...projectWithMarkupEntriesQuery.project.markupEntries.slice(
                            markupGroupIndex + 1
                        ),
                    ];

                    client.writeQuery({
                        query: ProjectWithMarkupEntriesDocument,
                        data: {
                            project: {
                                ...projectWithMarkupEntriesQuery.project,
                                markupEntries: newMarkupEntries,
                            },
                        },
                    });

                    // Send network request to update backend
                    const markupID = markups?.[currentGeometryCount]?.uuid;
                    markupWithinGroupRef.current = undefined;
                    addMarkupsToMarkupGroup(client, {
                        markupGroupID: markupGroupID,
                        markupIDs: new Set([markupID]),
                        projectID,
                    });
                }

                if (callback) {
                    callback(snapshot);
                }
            },
            projectPlanPageID,
            scale,
        };
    }, [client, currentPage, projectID, scale]);

    return saveGeometryPayload;
};
