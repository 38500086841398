import { AdminDashboardProjectStatus } from '@/common/types';

export const toStatusText = (status?: AdminDashboardProjectStatus) => {
    return status?.replace(/_/g, ' ');
};

export const hasStatus = (
    statuses?: AdminDashboardProjectStatus[],
    status?: AdminDashboardProjectStatus
) => {
    return status !== undefined && statuses !== undefined && statuses.includes(status);
};

export const isDraftStatus = (status?: AdminDashboardProjectStatus) => {
    const statuses = [AdminDashboardProjectStatus.DRAFT];

    return hasStatus(statuses, status);
};

export const isInProgressStatus = (status?: AdminDashboardProjectStatus) => {
    const statuses = [AdminDashboardProjectStatus.IN_PROGRESS];

    return hasStatus(statuses, status);
};

export const isNeutralStatus = (status?: AdminDashboardProjectStatus) => {
    const statuses = [
        AdminDashboardProjectStatus.NEEDS_REVIEW,
        AdminDashboardProjectStatus.NEW_SUBMISSION,
        AdminDashboardProjectStatus.UPDATED_SUBMISSION,
        AdminDashboardProjectStatus.NEEDS_PRICE,
        AdminDashboardProjectStatus.NEEDS_ESTIMATOR,
    ];

    return hasStatus(statuses, status);
};

export const isNegativeStatus = (status?: AdminDashboardProjectStatus) => {
    const statuses = [
        AdminDashboardProjectStatus.PROJECT_CANCELED,
        AdminDashboardProjectStatus.PRICE_DECLINED,
        AdminDashboardProjectStatus.ESTIMATOR_DECLINED,
    ];

    return hasStatus(statuses, status);
};

export const isPostiveStatus = (status?: AdminDashboardProjectStatus) => {
    const statuses = [
        AdminDashboardProjectStatus.COMPLETE,
        AdminDashboardProjectStatus.PENDING_REVISION,
        AdminDashboardProjectStatus.PENDING_ACCEPTANCE,
        AdminDashboardProjectStatus.PENDING_APPROVAL,
    ];

    return hasStatus(statuses, status);
};
