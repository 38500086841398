/**
 * Avatar is a component that displays the currently logged in User as a circle
 * with either initials (for Builders and Estimators) or a 1build logo.
 * The color of the circle varies depending on the role.
 */

import React, { FC } from 'react';

import { ReactComponent as Logo } from '@/assets/images/logo.svg';
import { ReactComponent as Estimator } from '@/assets/icons/EstimatorLight.svg';
import { DivProps } from '@/common/types';
import { CircleIcon } from './CircleIcon';
import {
    colorAvatarAdmin,
    colorAvatarBuilder,
    colorAvatarEstimator,
    colorBackgroundSecondary,
} from '@/variables';
import { IUserRole, IUserFragment } from '@/graphql';

import './Avatar.scss';

type AvatarProps = DivProps & {
    diameter?: string;
    user?: IUserFragment;
    isRobot?: boolean;
    v2?: boolean;
};

export const Avatar: FC<AvatarProps> = ({ v2, diameter = '2rem', user, isRobot, ...rest }) => {
    const { firstName, lastName, roles } = user || ({} as IUserFragment);

    const userIs = (role: IUserRole): boolean => roles?.includes(role);

    let backgroundColor = '';
    let content: string | JSX.Element = '';

    // The name field contains the User's full name, so taking the first letter
    // from every word of the name will give us the initials.
    // CAUTION: This might require some more work for Users with hyphenated
    // last names or People who are Jr. or II.
    content = (firstName?.[0] || '') + (lastName?.[0] || '');

    // We evaluate the roles in specifically this order so that the strongest
    // role present on the user is always used in the presentation layer.

    if (userIs(IUserRole.Estimator)) {
        backgroundColor = colorAvatarEstimator;
    }
    if (userIs(IUserRole.Builder) || !roles?.length) {
        backgroundColor = colorAvatarBuilder;
    }
    if (userIs(IUserRole.Admin) || userIs(IUserRole.Superadmin) || isRobot) {
        backgroundColor = colorAvatarAdmin;
        content = <Logo style={{ width: '50%' }} />;
    }
    if (!user) {
        backgroundColor = colorBackgroundSecondary;
        content = <Estimator />;
    }

    return (
        <div className="avatar" {...rest}>
            <CircleIcon
                v2={v2}
                diameter={diameter}
                color={v2 ? backgroundColor : undefined}
                background={backgroundColor}
            >
                {content}
            </CircleIcon>
        </div>
    );
};
