import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';
import { PlanCard as PlanCardComponent } from './PlanCard';

export const Main = styledByMui('main')({
    background: 'linear-gradient(180deg, #F7F3F0 43.23%, #FFFFFF 43.24%)',
    height: '100%',
});

export const MainHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.text.primary,
    marginBottom: 48,
    paddingTop: 48,
    textAlign: 'center',

    [options.theme.breakpoints.up('md')]: {
        marginBottom: 72,
        paddingTop: 72,
    },
}));

export const Cards = styledByMui('div')<{ styled: { count?: number } }>((options) => ({
    display: 'grid',
    gap: 32,
    marginBottom: 48,

    [options.theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(${
            options.styled.count === 3 || options.styled.count === 2 ? 2 : 1
        }, 1fr)`,
        marginBottom: 72,
    },

    [options.theme.breakpoints.up('lg')]: {
        gridTemplateColumns: `repeat(${options.styled.count || 1}, 1fr)`,
    },
}));

export const PlanCard = styledByMui(PlanCardComponent)<{ styled: { count?: number } }>(
    (options) => ({
        [options.theme.breakpoints.up('md')]: {
            ':last-of-type': {
                gridColumn: options.styled.count === 3 ? '1 / 3' : 'auto',
            },
        },

        [options.theme.breakpoints.up('lg')]: {
            ':last-of-type': {
                gridColumn: 'auto',
            },
        },
    })
);

export const CustomersHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.text.primary,
    marginBottom: 48,
    textAlign: 'center',
}));

export const CustomersList = styledByMui('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: 900,
    paddingBottom: 92,
    width: '100%',
});

export const CustomersListItem = styledByMui('img')({
    margin: '0 36px 32px',
});
