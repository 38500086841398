import styled from 'styled-components';

import { colorTypography } from '@/variables';

////////////////////
/// VIDEO PLAYER ///
////////////////////
export const LoomVideo = styled.iframe`
    grid-area: video-wrapper;

    width: 100%;
    border: none;
`;

export const VideoWrapper = styled.div`
    background: rgba(255, 255, 255, 0.08);
    box-sizing: border-box;
    padding: 24px 53px 17px;
`;

/////////////////////////
/// TEXT PART OF CARD ///
/////////////////////////
export const PlayButton = styled.div`
    cursor: pointer;

    color: #fff;
    background: rgba(255, 255, 255, 0.12);

    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background 0.4s;

    & > svg {
        margin-left: -5px;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const Copy = styled.div`
    flex: 1;
`;

export const TextWrapper = styled.div`
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colorTypography};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    // padding: 11px 8px 13px;
    padding: 11px 24px 13px;
    box-sizing: border-box;

    & > ${PlayButton} {
        margin-right: 1rem;
    }
`;

//////////////////
/// CONTAINERS ///
//////////////////
const cardGridTemplate = `
    " video-wrapper " 1fr
    " text  " 80px
    / 1fr
`;
export const Card = styled.div`
    display: grid;
    grid-template: ${cardGridTemplate};

    border-radius: 8px;
    overflow: hidden;
`;

export const Container = styled.div`
    background: #305dff;

    padding: 44px 20px 14px;
    box-sizing: border-box;
`;
