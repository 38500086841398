import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProjectFiles } from '../ProjectFiles';
import { ProjectPricingStatusCard } from '../ProjectPricingStatusCard';
import { ProjectDetailsComponentProps } from '../context';
import { ContextActions } from './ContextActions';
import { DetailPoint } from './DetailPoint';
import {
    BackgroundTexture,
    CloseIcon,
    Container,
    ContributorsWrapper,
    DetailHeader,
    Details,
    DownPointer,
    DueDate,
    FileManagerContainer,
    Header,
    Logo,
    LogoWrapper,
    MobileStatus,
    Name,
    PointerButton,
    Section,
    RateNowContainer,
    Stars,
} from './styled';
import { ReactComponent as BuilderTeam } from '@/assets/icons/builder-team.svg';
import { ReactComponent as CalendarV2 } from '@/assets/icons/calendar-v2.svg';
import { ReactComponent as Clock } from '@/assets/icons/clock.svg';
import { ReactComponent as Email } from '@/assets/icons/email.svg';
import { ReactComponent as HollowLocation } from '@/assets/icons/location-hollow.svg';
import { ReactComponent as Person } from '@/assets/icons/person-currentColor.svg';
import { ReactComponent as Telephone } from '@/assets/icons/telephone.svg';
import { ReactComponent as TradesIcon } from '@/assets/icons/trades.svg';
import { ReactComponent as WageType } from '@/assets/icons/wage-type.svg';
import { ReactComponent as RightArrowIcon } from '@/assets/icons/arrow.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/edit-icon.svg';
import backgroundCubesSrc from '@/assets/images/project-details-background-cubes.png';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import {
    DatabaseProjectStatus,
    PricingStatus,
    PricingType,
    ProjectReviewStatus,
} from '@/common/types';
import { mapPricingStatus, projectStatusToLabel } from '@/common/utils/helpers';
import { wageTypeToReadable } from '@/common/utils/helpers';
import { ProjectOwnerManagement } from '@/components/Project/ProjectOwnerManagement';
import { NameCard } from '@/components/ui/controls/NameCard';
import { ProjectStatusLabel } from '@/components/ui/controls/ProjectStatusLabel';
import { Trades } from '@/components/ui/controls/Trades';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { IUserFragment, IUserRole } from '@/graphql';
import { useProjectSubscription } from '@/subscriptions/projects';
import { colorBackground } from '@/variables';
import clsx from 'clsx';
import moment from 'moment';
import { ProjectReviewModal } from './ProjectReviewModal';
import { useProjectReview } from '@/common/hooks/useProjectReview';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { RatingBar } from '@/components/ui/controls/RatingBar';
import { useToggle } from '@/common/hooks/useToggle';
import {
    isBuilderUser,
    isEstimatorUser,
    isAdministratorUser,
    isPureEstimatorUser,
} from '@/views/Projects/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const COPY = {
    contact: 'Builder details',
    estimatorContact: 'Estimator details',
    contributors: 'Contributors',
    details: 'Details',
    dueDatePending:
        'Due Date Requested above is PENDING. 1build will review availability, review ' +
        'the drawings, and confirm the due date.',
    filePrompt: 'Upload files to start your project',
    missingEmail: 'No email',
    missingPhoneNumber: 'No phone number',
    missingTrades: 'No trades',
    promptLocation: 'Enter location',
    userDeleted: 'User was deleted',
    wageType: 'Wage type: ',
    maxBillableHours: 'Max billable hours',
    hours: ' hours',
    projectReview: 'Project review',
    rateProject: 'Rate this project to improve future estimator matches.',
    rateNow: 'Rate now',
    seeReview: 'See review',
};

export const ProjectInfo: FC<ProjectDetailsComponentProps> = ({ useProjectDetails }) => {
    // State
    /*-------------------*/
    const {
        data: { user },
    } = useUser();
    const screenSize = useScreenSize();
    const {
        assignedTo,
        project,
        isLocationDropdownOpen,
        closeInfoPanel,
        setIsLocationDropdownOpen,
        isInfoPanelOpen,
        setProject,
        pricingStatus,
        setPricingStatus,
        toggleApproveProjectPriceModal,
        toggleCancelProjectModal,
        pricingAssignment,
    } = useProjectDetails();
    const { projectLocation } = useProjectFromParams();
    const {
        features: { marketplaceRating },
    } = useFeatures();
    const { builderAcceptance, improvedMarketplaceSubmission } = useFlags();

    const [isLocationHighlighted, setIsLocationHighlighted] = useState(false);

    const { data: projectChangedData } = useProjectSubscription();

    const {
        review,
        handleReviewStatusChange,
        handleReviewStarChange,
        handleReviewTagChange,
        handleReviewCommentChange,
        handlePreferenceStatusChange,
        handleSubmitReview,
        allFieldsFilled,
    } = useProjectReview({ project, estimator: assignedTo });

    const [isProjectReviewModalOpen, toggleProjectReviewModal] = useToggle();

    const userIs = (role: IUserRole): boolean => user.roles.includes(role);

    const projectIs = (match: DatabaseProjectStatus): boolean => match === project.status;

    const showProjectHours = !userIs(IUserRole.Builder);
    const parsedEstimatorHours = parseFloat(project.estimatorHours.toFixed(1)).toString();

    // Prevent highlight from running on first render
    const formattedAddressRefCount = useRef(0);

    // Helpers
    /*-------------------*/
    const isMobile = screenSize === ScreenSize.Mobile;
    const { team, wageType } = project;
    const trades = project.projectTrades?.nodes?.map((n) => n.trade) ?? [];
    const leadUser = team?.leadUser;

    const leadUserName = leadUser
        ? `${[leadUser.firstName ?? '', leadUser.lastName ?? ''].join(' ')}`
        : COPY.userDeleted;

    const priceType = pricingAssignment?.pricingType;

    // Effects
    /*-------------------*/
    useEffect(() => {
        let sto: ReturnType<typeof setTimeout>;

        if (projectLocation?.formattedAddress && formattedAddressRefCount.current) {
            setIsLocationHighlighted(true);
            sto = setTimeout(() => setIsLocationHighlighted(false), 2000);
        }

        formattedAddressRefCount.current = formattedAddressRefCount.current + 1;

        return (): void => clearTimeout(sto);
    }, [projectLocation?.formattedAddress]);

    useEffect(() => {
        const updatedProject = projectChangedData?.ProjectChanged.projectEntry;
        if (updatedProject && updatedProject.id === project.id) {
            setProject(updatedProject);
        }
    }, [projectChangedData]);

    useEffect(() => {
        handleSetPricingStatus();
    }, [project]);

    const isShowingProjectStatusCard =
        userIs(IUserRole.Builder) &&
        (projectIs(DatabaseProjectStatus.NEW) ||
            projectIs(DatabaseProjectStatus.PENDING_ESTIMATOR) ||
            pricingStatus !== PricingStatus.FINAL) &&
        !projectIs(DatabaseProjectStatus.CANCELED) &&
        builderAcceptance;

    const isShowingEstimatorName =
        userIs(IUserRole.Builder) &&
        !projectIs(DatabaseProjectStatus.NEW) &&
        !projectIs(DatabaseProjectStatus.PENDING_ESTIMATOR) &&
        pricingStatus === PricingStatus.FINAL;

    const isShowingBuilderName =
        (!project.isSaas && userIs(IUserRole.Estimator) && !userIs(IUserRole.Builder)) ||
        !marketplaceRating;

    const isShowingReviewSections =
        marketplaceRating && !project.isSaas && projectIs(DatabaseProjectStatus.COMPLETE);

    const isShowingRateNowSection =
        isShowingReviewSections &&
        isBuilderUser(user) &&
        !isAdministratorUser(user) &&
        review.status === ProjectReviewStatus.NotStarted;

    const isShowingPostReviewSection =
        isShowingReviewSections &&
        (isBuilderUser(user) || isEstimatorUser(user)) &&
        review.status !== ProjectReviewStatus.NotStarted;

    const isProjectReviewReadOnly = isAdministratorUser(user) || isPureEstimatorUser(user);

    const handleSetPricingStatus = (): void => {
        const status = mapPricingStatus(project.status);

        if (!priceType) return;

        if (priceType !== PricingType.FINAL) {
            setPricingStatus(priceType as string as PricingStatus);
        } else {
            setPricingStatus(status);
        }
    };

    const handleOpenProjectStatusModal = (): void => {
        if (pricingStatus === PricingStatus.PREAPPROVED) {
            toggleApproveProjectPriceModal();
        } else {
            toggleCancelProjectModal();
        }
    };

    const handleCloseProjectReviewModal = () => {
        toggleProjectReviewModal();

        if (review.status === ProjectReviewStatus.Edited) {
            handleReviewStatusChange(ProjectReviewStatus.Finished);
        }
    };

    const submitReview = async () => {
        await handleSubmitReview();
        handleCloseProjectReviewModal();
    };

    const sections: {
        title: string | JSX.Element;
        rows: JSX.Element[];
    }[] = [
        ...(isShowingEstimatorName && marketplaceRating
            ? [
                  {
                      title: COPY.estimatorContact,
                      rows: [
                          <DetailPoint key="estimator-user" icon={<Person />}>
                              {`${assignedTo?.firstName || ''} ${assignedTo?.lastName || ''}`}
                          </DetailPoint>,
                          <DetailPoint key="estimator-phone" icon={<Telephone />}>
                              {assignedTo?.phone ? (
                                  <a href={`tel:${assignedTo.phone}`}>{assignedTo.phone}</a>
                              ) : (
                                  COPY.missingPhoneNumber
                              )}
                          </DetailPoint>,
                          <DetailPoint key="estimator-email" icon={<Email />}>
                              {assignedTo?.email ? (
                                  <a href={`mailto:${assignedTo?.email ?? ''}`}>
                                      {assignedTo?.email}
                                  </a>
                              ) : (
                                  COPY.missingEmail
                              )}
                          </DetailPoint>,
                      ],
                  },
              ]
            : []),
        ...(isShowingBuilderName
            ? [
                  {
                      title: COPY.contact,
                      rows: [
                          <DetailPoint key="lead-user" icon={<Person />}>
                              {leadUserName}
                          </DetailPoint>,
                          <DetailPoint key="lead-phone" icon={<Telephone />}>
                              {leadUser?.phone ? (
                                  <a href={`tel:${leadUser.phone}`}>{leadUser.phone}</a>
                              ) : (
                                  COPY.missingPhoneNumber
                              )}
                          </DetailPoint>,
                          <DetailPoint key="lead-email" icon={<Email />}>
                              {leadUser?.email ? (
                                  <a href={`mailto:${leadUser?.email ?? ''}`}>{leadUser?.email}</a>
                              ) : (
                                  COPY.missingEmail
                              )}
                          </DetailPoint>,
                          <DetailPoint key="builder-team" icon={<BuilderTeam />}>
                              {team?.name || ''}
                          </DetailPoint>,
                      ],
                  },
              ]
            : []),
        {
            title: (
                <>
                    {improvedMarketplaceSubmission ? (
                        <Link
                            to={`/projects/${project.uuid}/details`}
                            target="_blank"
                            style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}
                        >
                            {COPY.details}
                            <EditIcon style={{ marginLeft: '8px' }} />
                        </Link>
                    ) : (
                        <>{COPY.details}</>
                    )}
                    {(userIs(IUserRole.Admin) || project.isSaas) && (
                        <ContextActions project={project} />
                    )}
                </>
            ),
            rows: [
                <DetailPoint
                    key="location"
                    icon={<HollowLocation />}
                    isHighlighted={isLocationHighlighted}
                    isError={!projectLocation?.formattedAddress}
                    disabled={isLocationDropdownOpen}
                    onDoubleClick={(e): void => e.preventDefault()}
                    onClick={(e): void => {
                        e.preventDefault();

                        closeInfoPanel();
                        setIsLocationDropdownOpen(true);
                    }}
                >
                    {projectLocation?.formattedAddress || COPY.promptLocation}
                </DetailPoint>,
                ...(wageType
                    ? [
                          <DetailPoint key="wage-type" icon={<WageType />}>
                              {COPY.wageType} {wageTypeToReadable(wageType)}
                          </DetailPoint>,
                      ]
                    : []),
                <DetailPoint isTrades key="trades" icon={<TradesIcon />}>
                    {trades.length ? (
                        <Trades v2 trades={trades} howManyToShow={6} background={colorBackground} />
                    ) : (
                        COPY.missingTrades
                    )}
                </DetailPoint>,
            ],
        },
        ...(showProjectHours
            ? [
                  {
                      title: <>{COPY.maxBillableHours}</>,
                      rows: [
                          <DetailPoint key="maxBillableHours" icon={<Clock />}>
                              {parsedEstimatorHours.concat(COPY.hours)}
                          </DetailPoint>,
                      ],
                  },
              ]
            : []),
    ];

    if (!user) return <></>;

    return (
        <Container status={isShowingProjectStatusCard}>
            <CloseIcon onClick={closeInfoPanel} />

            {/** Mobile specific */}
            <Header>
                <PointerButton tabIndex={0} isActive={isInfoPanelOpen} onClick={closeInfoPanel}>
                    <DownPointer />
                </PointerButton>
                <Name>{project.name}</Name>
            </Header>

            {/** Mobile specific */}
            <MobileStatus>
                {!project.isSaas && (
                    <DueDate>
                        <CalendarV2 />
                        Due {moment(project.bidsDueDate).format('MMMM DD')}
                    </DueDate>
                )}

                {project.status && (
                    <ProjectStatusLabel
                        className="state-label"
                        status={projectStatusToLabel(project.status, 'details', user?.roles ?? [])}
                    />
                )}
            </MobileStatus>

            <Details>
                {project.status && pricingStatus && isShowingProjectStatusCard && (
                    <>
                        <ProjectPricingStatusCard
                            status={pricingStatus}
                            onClick={handleOpenProjectStatusModal}
                            showCancelAction={!projectIs(DatabaseProjectStatus.ESTIMATING)}
                        />
                    </>
                )}

                {isShowingEstimatorName && builderAcceptance && !marketplaceRating && (
                    <NameCard
                        title="ESTIMATOR"
                        user={{ ...assignedTo, roles: ['ESTIMATOR'] } as unknown as IUserFragment}
                    />
                )}

                {project.isSaas && (
                    <ContributorsWrapper>
                        <Section css={'grid-area: contact'}>
                            <DetailHeader>{COPY.contributors}</DetailHeader>
                            <ProjectOwnerManagement
                                user={project.projectUsers?.nodes[0]?.user}
                                projectId={project.id}
                            />
                        </Section>
                    </ContributorsWrapper>
                )}

                {sections.map(({ title, rows }, sectionKey) => (
                    <Section key={sectionKey}>
                        <DetailHeader>{title}</DetailHeader>
                        {rows}
                    </Section>
                ))}

                {(isShowingRateNowSection || isShowingPostReviewSection) && (
                    <Box m="0 16px 0 24px" borderTop="1px solid #65667A">
                        <Typography pt="6px" pb="20px" fontWeight="bold">
                            {COPY.projectReview}
                        </Typography>

                        {isShowingRateNowSection && (
                            <RateNowContainer>
                                {COPY.rateProject}
                                <Stars>★★★★★</Stars>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    endIcon={<RightArrowIcon width={12} height={12} />}
                                    onClick={() => toggleProjectReviewModal()}
                                >
                                    {COPY.rateNow}
                                </Button>
                            </RateNowContainer>
                        )}

                        {isShowingPostReviewSection && (
                            <>
                                <Box>
                                    <RatingBar rating={review?.rating ?? 0} />
                                </Box>
                                <Box mt="26px">
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => toggleProjectReviewModal()}
                                        endIcon={<ArrowIcon height={12} width={12} />}
                                    >
                                        {COPY.seeReview}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                )}

                {userIs(IUserRole.Builder) && project.status === DatabaseProjectStatus.NEW && (
                    <p
                        css={`
                            grid-area: edit;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                            color: #dadeec;
                            padding: 0 18px;
                        `}
                    >
                        {COPY.dueDatePending}
                    </p>
                )}

                {isMobile && (
                    <FileManagerContainer>
                        <ProjectFiles
                            className={clsx('project-files-v2')}
                            useProjectDetails={useProjectDetails}
                        />
                    </FileManagerContainer>
                )}

                {isProjectReviewModalOpen && marketplaceRating && (
                    <ProjectReviewModal
                        readOnly={isProjectReviewReadOnly}
                        review={review}
                        onStatusChange={handleReviewStatusChange}
                        onStarChange={handleReviewStarChange}
                        onReviewTagChange={handleReviewTagChange}
                        onCommentChange={handleReviewCommentChange}
                        onPreferenceStatusChange={handlePreferenceStatusChange}
                        onSubmitReview={submitReview}
                        close={handleCloseProjectReviewModal}
                        isSubmitDisabled={!allFieldsFilled}
                    />
                )}

                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
            </Details>
            <BackgroundTexture src={backgroundCubesSrc} />
        </Container>
    );
};
