import React, { FC, useEffect, useRef, useState } from 'react';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';

import { Env } from '@/common/env';
import { IUserFragment } from '@/graphql';
import { LDFlagSet, LDFlagValue } from 'launchdarkly-js-sdk-common';
import { LoadingPage } from '@/views/LoadingPage';

export const LDService: {
    variation: (key: string, defaultValue?: LDFlagValue) => LDFlagValue;
    allFlags: () => LDFlagSet;
} = {
    variation: () => false,
    allFlags: () => ({} as LDFlagSet),
};

export const LDServiceProvider: React.FC = ({ children }) => {
    const ldClient = useLDClient();

    useEffect(() => {
        if (ldClient) {
            LDService.variation = ldClient.variation.bind(ldClient);
            LDService.allFlags = ldClient.allFlags.bind(ldClient);
        }
    }, [ldClient]);

    return <>{children}</>;
};

interface LaunchDarklyProviderProps {
    user: IUserFragment;
}

export const LaunchDarklyProvider: FC<LaunchDarklyProviderProps> = ({ user, children }) => {
    const [Provider, setProvider] = useState<FC | undefined>(undefined);

    // Ref to keep track if useEffect has already run once.
    // We are not super concerned about the edge case of a user changing and FF
    // needing to keep in phase.  This would be a super edge case, in which case the fix
    // would just be refreshing.
    const hasInitRef = useRef(false);

    useEffect(() => {
        if (!user.id || hasInitRef.current) {
            return;
        }

        const { id, firstName, lastName, email, team, roles } = user;

        /**
         * @see {https://docs.launchdarkly.com/sdk/client-side/react/react-web}
         */
        const setupLDProvidder = async () => {
            const provider = await asyncWithLDProvider({
                clientSideID: Env.launchDarklyClientsideID,
                user: {
                    key: id,
                    firstName,
                    lastName,
                    email,
                    custom: {
                        tier: Env.tier.value,
                        team: team?.name ?? '',
                        roles: [...roles.map((role) => role.toString())],
                    },
                },
                options: {
                    bootstrap: 'localStorage',
                },
                reactOptions: {
                    useCamelCaseFlagKeys: true,
                },
            });

            setProvider(() => provider);
            hasInitRef.current = true;
        };

        setupLDProvidder();
    }, [user]);

    return Provider ? (
        <Provider>
            <LDServiceProvider>{children}</LDServiceProvider>
        </Provider>
    ) : (
        <LoadingPage />
    );
};
