import React, { FC } from 'react';

import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export type ListItemButtonSourceNodeProps = ListItemButtonProps;

export const ListItemButtonSourceNode: FC<ListItemButtonSourceNodeProps> = ({
    children,
    ...props
}) => {
    return (
        <ListItemButton dense {...props}>
            <ListItemText
                primaryTypographyProps={{
                    variant: 'body2',
                }}
            >
                {children}
            </ListItemText>
        </ListItemButton>
    );
};
