/*
 * This context exposes feature flags, that are being used to toggle features
 */
import { useQueryArray } from '@/common/hooks/useQueryArray';
import { FeatureFlag, Setter } from '@/common/types';
import { makeContext } from '@/common/utils/makeContext';
import { FeatureFlagsQuery } from '@/queries/featureFlags';
import { useUser } from '@/contexts/User';
import moment from 'moment';
import { Env } from '@/common/env';

export interface Features {
    ingestionV2: boolean;
    demoProject: boolean;
    bigQueryRateLocalizer: boolean;
    shareV2: boolean;
    localizedData: boolean;
    builderEstimateView: (date: string | undefined) => boolean;
    marketplaceRating: boolean;
}

export type FeaturesProps = {
    flags: FeatureFlag[];
    hasFeatureFlagsAccess: boolean;
    setFlags: Setter<FeatureFlag[]>;
    fetched: boolean;
    fetching: boolean;
    features: Features;
};

const { useConsumer, Provider } = makeContext<FeaturesProps>(() => {
    const user = useUser();

    // The feature flags are only accessible with a session
    const hasFeatureFlagsAccess = user.validations.hasSession === true;

    const [flags, setFlags, fetched, fetching] = useQueryArray<FeatureFlag>(
        FeatureFlagsQuery,
        !hasFeatureFlagsAccess
    );

    const flagsMap = flags.reduce<Record<string, boolean>>(
        (acc, flag) => ({
            ...acc,
            [flag.name]: flag.isEnabled,
        }),
        {}
    );

    const isEnabled = (name: string): boolean => flagsMap[name];

    const builderEstimateView = (date: string | undefined) => {
        return (
            isEnabled('BuilderEstimateView') &&
            moment(date).isAfter({
                year: 2022,
                month: 1,
                day: 10,
            })
        );
    };

    return {
        flags,
        hasFeatureFlagsAccess,
        setFlags,
        // Minor hack to avoid problems with this
        // since our mocked server does not support postgraphile for now
        fetched: Env.tier.isTest || fetched,
        fetching,
        features: {
            ingestionV2: isEnabled('IngestionV2'),
            demoProject: isEnabled('DemoProject'),
            bigQueryRateLocalizer: isEnabled('BigQueryRateLocalizer'),
            shareV2: isEnabled('ShareV2'),
            localizedData: true, // TODO: remove it after backward comp. period
            builderEstimateView: builderEstimateView,
            marketplaceRating: isEnabled('MarketplaceRating'),
        },
    };
});

export const useFeatures = useConsumer;
export const FeaturesProvider = Provider;
