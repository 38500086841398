import React, { FC, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { ErrorMenuItem, ErrorSvgIcon } from './styled';

import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { DeleteProjectModal } from '@/components/projects/DeleteProjectModal';
import { MenuDrawer } from '@/components/ui/containers/MenuDrawer';
import { Mode } from '@/theme/Mode';
import { ReactComponent as OverflowMenuHorizontalIcon } from '@/assets/icons/32/overflow-menu--horizontal.svg';
import { ReactComponent as CopyFileIcon } from '@/assets/icons/32/copy--file.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/32/trash-can.svg';
import { isSaasProject } from '@/views/Projects/utils';

export const EllipsisButton: FC<BoxProps> = ({ ...props }) => {
    const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { project } = useProjectFromParams();

    const handleOpen = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const isOpen = Boolean(anchorEl);

    const hasDuplicateMenuItem = false;
    const hasDeleteMenuItem = isSaasProject(project);

    const hasMenuItems = hasDuplicateMenuItem || hasDeleteMenuItem;

    if (!hasMenuItems) {
        return null;
    }

    return (
        <Box {...props}>
            <IconButton color="secondary" onClick={handleOpen} size="small">
                <SvgIcon color="secondary" viewBox="0 0 32 32">
                    <OverflowMenuHorizontalIcon />
                </SvgIcon>
            </IconButton>
            <Mode variant="light">
                <MenuDrawer
                    mobileMenuTitle={project?.name}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isOpen}
                    onClose={handleClose}
                >
                    {hasDuplicateMenuItem && (
                        <MenuItem dense onClick={() => setAnchorEl(undefined)}>
                            <ListItemIcon>
                                <SvgIcon
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                    }}
                                    viewBox="0 0 32 32"
                                >
                                    <CopyFileIcon />
                                </SvgIcon>
                            </ListItemIcon>
                            Duplicate
                        </MenuItem>
                    )}
                    {hasDeleteMenuItem && (
                        <ErrorMenuItem
                            dense
                            onClick={() => {
                                setAnchorEl(undefined);
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            <ListItemIcon>
                                <ErrorSvgIcon viewBox="0 0 32 32">
                                    <TrashCanIcon />
                                </ErrorSvgIcon>
                            </ListItemIcon>
                            Delete
                        </ErrorMenuItem>
                    )}
                </MenuDrawer>

                {/** Modals */}
                {project && (
                    <DeleteProjectModal
                        open={isDeleteModalOpen}
                        project={project}
                        close={() => setIsDeleteModalOpen(false)}
                    />
                )}
            </Mode>
        </Box>
    );
};
