/**
 * CaretIcon is an cart icon very similar to the character >. Because this icon
 * is built using a css polygon instead of an SVG, its color can be changed
 * with css.
 */

import clsx from 'clsx';
import React, { FC } from 'react';

import { SvgIcon } from './SvgIcon';
import { Direction, SpanProps } from '@/common/types';
import { ReactComponent as Caret } from '@/assets/icons/caret.svg';
import { ReactComponent as CaretThin } from '@/assets/icons/caret_thin.svg';

import './CaretIcon.scss';

export enum CaretIconSize {
    Small = 'SMALL',
    Medium = 'MEDIUM',
}

export enum CaretIconVariant {
    Standard,
    Thin,
    CurrentColor,
}

export interface CaretIconProps extends SpanProps {
    direction: Direction;
    size: CaretIconSize;
    variant?: CaretIconVariant;
}

export const CaretIcon: FC<CaretIconProps> = ({
    className,
    direction,
    size,
    variant = CaretIconVariant.Standard,
    ...shared
}) => {
    const caretIconClass = clsx(className, 'caret-icon', {
        'caret-icon-small': size === CaretIconSize.Small,
        'caret-icon-medium': size === CaretIconSize.Medium,
        'caret-icon-up': direction === Direction.Up,
        'caret-icon-right': direction === Direction.Right,
        'caret-icon-down': direction === Direction.Down,
        'caret-icon-left': direction === Direction.Left,
    });

    const renderCaret = (): JSX.Element => {
        switch (variant) {
            case CaretIconVariant.Thin:
                return <SvgIcon src={CaretThin} className="caret-icon-thin" />;
            case CaretIconVariant.CurrentColor:
                return (
                    <SvgIcon
                        noInherit
                        src={Caret}
                        css={`
                            fill: currentColor;

                            & path {
                                fill: currentColor;
                            }
                        `}
                    />
                );
            default:
            case CaretIconVariant.Standard:
                return <SvgIcon src={Caret} />;
        }
    };

    return (
        <span className={caretIconClass} {...shared}>
            {renderCaret()}
        </span>
    );
};
