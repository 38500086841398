/*
 * Table displays a simple, static table given the provided columns and data
 * Columns are of the react-table Column type defined in:
 * https://react-table.tanstack.com/docs/api/useTable#column-options
 * And data has to be an object with keys matching the accessor values on
 * Columns.
 *
 * Depending on the supplied variant prop, the table will be laid out using the
 * table DOM elements or divs.
 *
 * The Table doesn't provide its own styles - it's up to the component that uses
 * it to style it properly.
 *
 * The Table forwards a ref to either its header or its body, depending on what
 * the whichRef prop says.
 */

import React from 'react';
import { Column, ColumnInstance, useTable } from 'react-table';
import {
    DivBodyProps,
    DivOrTableSection,
    DivTableProps,
    DivTdProps,
    DivTheadProps,
    DivThProps,
    DivTrProps,
} from '@/common/types';

/* eslint-disable @typescript-eslint/ban-types */
export type Data = object;
type TableColumn<D extends object = {}> = ColumnInstance<D>;
/* eslint-enable @typescript-eslint/ban-types */

interface TableProps {
    columns: Column<Data>[];
    data: Data[];
    variant?: 'table' | 'div';
    className?: string;
    whichRef?: 'header' | 'body';
    onClickRow?: (e: React.MouseEvent, rowIndex: number) => void;
    onContextMenuRow?: (e: React.MouseEvent, rowIndex: number) => void;
}

const Table = React.forwardRef<DivOrTableSection, TableProps>(
    ({ columns, data, variant, className, whichRef, onClickRow, onContextMenuRow }, ref) => {
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data,
        });

        const isDiv = variant === 'div';

        const WrapperElement = (props: DivTableProps): JSX.Element =>
            isDiv ? <div {...props} /> : <table {...props} />;

        const HeadElement = (props: DivTheadProps): JSX.Element =>
            isDiv ? (
                <div {...(whichRef === 'header' && { ref })} {...props} />
            ) : (
                <thead
                    {...(whichRef === 'header' && {
                        ref: ref as React.Ref<HTMLTableSectionElement>,
                    })}
                    {...props}
                />
            );

        const RowElement = (props: DivTrProps): JSX.Element =>
            isDiv ? <div {...props} /> : <tr {...props} />;

        const HeaderCellElement = (props: DivThProps): JSX.Element =>
            isDiv ? <div {...props} /> : <th {...props} />;

        const BodyElement = (props: DivBodyProps): JSX.Element =>
            isDiv ? (
                <div {...(whichRef === 'body' && { ref })} className="rowgroup-wrapper">
                    <div {...props}>{props.children}</div>
                </div>
            ) : (
                <tbody
                    {...(whichRef === 'body' && { ref: ref as React.Ref<HTMLTableSectionElement> })}
                    {...props}
                />
            );

        const DataCellElement = (props: DivTdProps): JSX.Element =>
            isDiv ? <div {...props} /> : <td {...props} />;

        const renderHead = (): JSX.Element => (
            <HeadElement role="header">
                {headerGroups.map((headerGroup) => {
                    const props = headerGroup.getHeaderGroupProps();
                    return (
                        <RowElement {...props} key={props.key}>
                            {headerGroup.headers.map((column: TableColumn<Data>) => {
                                const props = column.getHeaderProps();
                                return (
                                    <HeaderCellElement {...props} key={props.key}>
                                        {column.render('Header')}
                                    </HeaderCellElement>
                                );
                            })}
                        </RowElement>
                    );
                })}
            </HeadElement>
        );

        const renderBody = (): JSX.Element => (
            <BodyElement {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const props = row.getRowProps();
                    return (
                        <RowElement
                            onClick={(e: React.MouseEvent): void => onClickRow?.(e, rowIndex)}
                            onContextMenu={(e: React.MouseEvent): void =>
                                onContextMenuRow?.(e, rowIndex)
                            }
                            {...props}
                            key={props.key}
                        >
                            {row.cells.map((cell) => {
                                const props = cell.getCellProps();
                                return (
                                    <DataCellElement {...props} key={props.key}>
                                        {cell.render('Cell')}
                                    </DataCellElement>
                                );
                            })}
                        </RowElement>
                    );
                })}
            </BodyElement>
        );

        return (
            <WrapperElement className={className} {...getTableProps()}>
                {renderHead()}
                {renderBody()}
            </WrapperElement>
        );
    }
);

export default Table;
