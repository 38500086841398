import React, { FC, useEffect, useRef, useState } from 'react';

import { useMarkups } from './useMarkups';
import { useEstimationEditor } from '@/components/app/router/EstimationRoute/hooks/useEstimationEditor';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { EstimateBufferContext } from '@/components/Estimate/hooks/useEstimateBuffer';
import { EstimateEditorContext } from '@/components/Estimate/hooks/useEstimateEditor';
import { EstimateUomsContext } from '@/components/Estimate/hooks/useEstimateUoms';
import { EstimatePriceMarkupsContext } from '@/components/Estimate/hooks/useEstimatePriceMarkups';
import { EstimateProjectContext } from '@/components/Estimate/hooks/useEstimateProject';
import { PriceMarkup } from '@/components/Estimate/utils/types';
import { EstimateGlobalStyle, EstimateLayout, Table } from './styled';
import { useUomsQuery } from '@/queries/unitOfMeasure';
import { useUser } from '@/contexts/User';
import { EstimationLayoutChildrenProps } from '@/components/app/router/EstimationRoute/EstimationLayout';
import { useShareEstimate } from '@/common/hooks/useShareEstimate';
import { ILinkType, IUserRole, useProjectUploadS3Mutation } from '@/graphql';
import { Masthead } from '@/components/Estimate/Masthead';
import debounce from 'lodash/debounce';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { isWaitingForForClientApproval } from '../Projects/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';

const COPY = {
    waitingForClientApprovalMessage:
        'Project is still awaiting approval from builder. Work should not begin until client approves and pays for the project',
};

export const Estimate: FC<EstimationLayoutChildrenProps> = (props) => {
    const { executeThenRestoreScroll } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [bufferHovered, setBufferHovered] = useState(false);
    const [estimateUoms, setEstimateUoms] = useState<EstimateUomsContext>({
        defaultUomID: null,
        uoms: [],
    });
    const [priceMarkups, setPriceMarkups] = useState<PriceMarkup[]>([]);
    const [autoSave, setAutoSave] = useState(false);

    const unsetBufferHoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [fetchedUOMs] = useUomsQuery({});
    const {
        data: { user },
        validations: { isUserRoles },
    } = useUser();
    const editor = useEstimationEditor();
    const { project } = useProjectFromParams();
    const estimationProject = useEstimationProject();

    const flags = useFlags();

    const hasProjectInfo = estimationProject && estimationProject.projectID > 0;
    const projectID = estimationProject.projectID.toString();
    const isEstimator = isUserRoles(IUserRole.Estimator) && !isUserRoles(IUserRole.Builder);

    const { fetchLimitedLinkData, fetchDetailedLinkData, limitedLinkExists, detailedLinkExists } =
        useShareEstimate();
    const [mutateProjectUploadS3Mutation] = useProjectUploadS3Mutation();

    const { markups: fetchedPriceMarkups, saveMarkup: savePriceMarkup } = useMarkups({
        projectId: estimationProject.projectID,
    });

    useEffect(() => {
        const uoms = fetchedUOMs.data?.unitOfMeasures?.nodes;
        if (!uoms) {
            return;
        }
        const defaultUomID = (uoms.find((u) => u.name === 'SF')?.id ?? -1).toString();
        setEstimateUoms({ defaultUomID, uoms });
    }, [fetchedUOMs]);

    useEffect(() => {
        setPriceMarkups(
            fetchedPriceMarkups.map((fm) => ({
                id: fm.id,
                description: fm.description ?? '',
                percentage: fm.percentage ? fm.percentage.toFixed(2) : undefined,
                total: fm.total ? fm.total.toFixed(2) : undefined,
            }))
        );
    }, [fetchedPriceMarkups]);

    useEffect(() => {
        if ((!hasProjectInfo && isEstimator) || projectID === '-1') return;

        if (!limitedLinkExists) {
            fetchLimitedLinkData(projectID);
        }
        if (!detailedLinkExists) {
            fetchDetailedLinkData(projectID);
        }
    }, [hasProjectInfo, limitedLinkExists, detailedLinkExists]);

    useEffect(() => {
        if (!project) return;

        if (isWaitingForForClientApproval(flags, project, user)) {
            enqueueSnackbar(COPY.waitingForClientApprovalMessage, {
                variant: 'warning',
                autoHideDuration: 5000,
            });
        }
    }, [project]);

    const handleUploadProjectToS3 = () => {
        if (autoSave && hasProjectInfo) {
            if (limitedLinkExists) {
                mutateProjectUploadS3Mutation({
                    variables: {
                        input: {
                            id: projectID,
                            type: ILinkType.Limited,
                        },
                    },
                });
            }
            if (detailedLinkExists) {
                mutateProjectUploadS3Mutation({
                    variables: {
                        input: {
                            id: projectID,
                            type: ILinkType.Detailed,
                        },
                    },
                });
            }
            setAutoSave(false);
        }
    };

    useEffect(() => {
        if (isEstimator) return;

        const handleAutoSave = debounce(() => {
            setAutoSave(true);
        }, 60000);

        window.addEventListener('keydown', handleAutoSave);
        window.addEventListener('click', handleAutoSave);

        handleUploadProjectToS3();

        return () => {
            handleAutoSave.cancel();
            window.removeEventListener('keydown', handleAutoSave);
            window.removeEventListener('click', handleAutoSave);
        };
    }, [autoSave]);

    const recordBufferCellMouseEnter = () => {
        if (unsetBufferHoverTimeout.current) {
            clearTimeout(unsetBufferHoverTimeout.current);
            unsetBufferHoverTimeout.current = null;
        }
        setBufferHovered(true);
    };

    const recordBufferCellMouseLeave = () => {
        unsetBufferHoverTimeout.current = setTimeout(() => {
            setBufferHovered(false);
        }, 50);
    };

    return (
        <EstimateProjectContext.Provider value={estimationProject}>
            <EstimatePriceMarkupsContext.Provider
                value={{
                    priceMarkups,
                    setPriceMarkups,
                    savePriceMarkup,
                }}
            >
                <EstimateBufferContext.Provider
                    value={{
                        bufferHovered,
                        recordBufferCellMouseEnter,
                        recordBufferCellMouseLeave,
                    }}
                >
                    <EstimateUomsContext.Provider value={estimateUoms}>
                        <EstimateEditorContext.Provider value={[editor]}>
                            <EstimateGlobalStyle />

                            <EstimateLayout>
                                <Masthead />
                                {estimationProject.projectID !== -1 && (
                                    <Table
                                        gridArea="table"
                                        onElementsAdded={executeThenRestoreScroll}
                                    />
                                )}
                            </EstimateLayout>
                        </EstimateEditorContext.Provider>
                    </EstimateUomsContext.Provider>
                </EstimateBufferContext.Provider>
            </EstimatePriceMarkupsContext.Provider>
        </EstimateProjectContext.Provider>
    );
};
