import React, { FC } from 'react';
import { DashboardModal } from '@uppy/react';
import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { v4 as uuid } from 'uuid';
import {
    IProjectPlanFileCreationMutation,
    useProjectPlanFileCreationMutation,
    useProjectPlanFilePresignedUrlMutation,
} from '@/graphql';
import { useUser } from '@/contexts/User';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './uppyDashboardModal.scss';
import { FetchResult } from '@apollo/client';
import path from 'path';

interface ProjectPlanFileUploader {
    projectUUID: string;
    onFilesUploaded: () => void;
    open?: boolean;
    onClose?: () => void;
}

const COPY = {
    uploadNote:
        'Upload files of type JPG, PNG, ZIP or PDF. Files should each be less than 200 megabytes in size.',
};

export const ProjectPlanFilesUploader: FC<ProjectPlanFileUploader> = ({
    projectUUID,
    onFilesUploaded,
    open,
    onClose,
}) => {
    const {
        data: { user },
    } = useUser();
    const [getPresignedPutUrl] = useProjectPlanFilePresignedUrlMutation();
    const [createPlan] = useProjectPlanFileCreationMutation();

    const uppy = React.useMemo(() => {
        const getUploadParameters = async (
            file: UppyFile
        ): Promise<{ method: string; url: string; headers?: Record<string, string> }> => {
            let result;
            try {
                result = await getPresignedPutUrl({
                    variables: {
                        input: {
                            filename: `source${path.extname(file.name)}`,
                            projectUUID,
                            contentType: file?.type || '',
                            uuid: file.id,
                            contentSize: file.size,
                        },
                    },
                });
            } catch (e) {
                if (e instanceof Error) {
                    // this will be handled by Uppy and displayed in the Uppy dashboard as an error
                    throw new Error(`Cannot get presigned URL for upload: ${e.toString()}`);
                }
            }
            return {
                method: 'PUT',
                url: result?.data?.projectPlanFilePresignedUrl || '',
            };
        };
        return new Uppy({
            autoProceed: false,
            allowMultipleUploadBatches: false,
            onBeforeFileAdded: (currentFile) => {
                currentFile.id = uuid();
                return currentFile;
            },
            restrictions: {
                maxFileSize: 200 * 1024 * 1024,
                maxNumberOfFiles: 30,
                allowedFileTypes: [
                    'image/*',
                    'application/pdf',
                    'application/zip',
                    '.jpg',
                    '.png',
                    '.jpeg',
                    '',
                ],
            },
        })
            .use(AwsS3, {
                limit: 4,
                getUploadParameters,
            })
            .on('complete', (result) => {
                if (result?.successful?.length < 1) {
                    return;
                }
                const promises: Promise<FetchResult<IProjectPlanFileCreationMutation>>[] = [];
                for (const success of result?.successful) {
                    promises.push(
                        createPlan({
                            variables: {
                                input: {
                                    filename: success.name,
                                    uuid: success.id,
                                    ownerID: user.id,
                                    projectUUID,
                                },
                            },
                        })
                    );
                }
                Promise.all(promises).then(() => {
                    for (const success of result?.successful) {
                        uppy.removeFile(success.id);
                    }

                    onFilesUploaded();
                    if (onClose) {
                        onClose();
                    }
                });
            });
    }, []);

    React.useEffect(() => {
        if (uppy) {
            return () => uppy.close();
        }
    }, [uppy]);

    return (
        <DashboardModal
            uppy={uppy}
            open={open}
            onRequestClose={() => (onClose ? onClose() : null)}
            closeModalOnClickOutside={true}
            proudlyDisplayPoweredByUppy={false}
            note={COPY.uploadNote}
            showProgressDetails={true}
            hideProgressAfterFinish={true}
        />
    );
};
