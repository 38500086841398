import gql from 'graphql-tag';

export const setTeamOnboardingWelcomed = gql`
    mutation MyMutation($welcomed: Boolean!, $teamId: BigInt!) {
        updateTeamOnboardingByTeamId(input: { patch: { welcomed: $welcomed }, teamId: $teamId }) {
            teamOnboarding {
                welcomed
            }
        }
    }
`;
