import { Referrer } from '@/common/referrer';
import { StorageKey } from '@/common/storage';
import { deleteFromStorage, useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export function useHandledCheckoutReferrer({
    currentPriceId,
    loadedUser,
    userHasSubscription,
}: {
    currentPriceId?: string | null;
    loadedUser: boolean;
    userHasSubscription: boolean;
}) {
    const history = useHistory();

    const [referrer] = useLocalStorage(StorageKey.Referrer);
    const [checkoutPriceId] = useLocalStorage(StorageKey.CheckoutPriceId);

    // Consider moving handle referrers to component or hook
    useEffect(() => {
        if (referrer !== Referrer.Stripe || !loadedUser || !userHasSubscription) {
            return;
        }

        const previousPriceId = checkoutPriceId;

        const hasSubscriptionChanged = currentPriceId !== previousPriceId;

        if (hasSubscriptionChanged) {
            if (previousPriceId === undefined) {
                writeStorage(StorageKey.CheckoutResult, 'create');
            } else {
                writeStorage(StorageKey.CheckoutResult, 'update');
            }

            writeStorage(StorageKey.CheckoutSuccessComplete, false);

            history.replace('/');
        }

        deleteFromStorage(StorageKey.CheckoutPriceId);
        deleteFromStorage(StorageKey.Referrer);
    }, [checkoutPriceId, currentPriceId, history, loadedUser, referrer, userHasSubscription]);

    return referrer;
}
