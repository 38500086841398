export enum FormIds {
    readBuilderProfile = 'readBuilderProfile',
    discussedScope = 'discussedScope',

    estimateType = 'estimateType',
    scopeOfWork = 'scopeOfWork',
    startBy = 'startBy',
    finishBy = 'finishBy',
    hoursEstimated = 'hoursEstimated',
    hourlyRate = 'hourlyRate',
    quoteExpiration = 'quoteExpiration',
}
