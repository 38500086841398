import React, { FC } from 'react';

import { FilterItemContainer, ProjectNumber } from './styled';

import { useProjects } from '@/components/AdminDashboard/Projects/context';
import { ProjectWithEventsRecord } from '@/queries/projects';

import { ReactComponent as Message } from '@/assets/icons/Message.svg';
import { ReactComponent as Person } from '@/assets/icons/Person.svg';
import { ReactComponent as Approval } from '@/assets/icons/approval.svg';

const COPY = {
    unassignedProject: 'unassigned project',
    unassignedProjects: 'unassigned projects',
    projectWithUnreturnedMessages: 'project w/ unreturned messages',
    projectsWithUnreturnedMessages: 'projects w/ unreturned messages',
    projectToReview: 'project to review',
    projectsToReview: 'projects to review',
};

export enum FilterItemType {
    Unassigned,
    Unreturned,
    NeedingReview,
}

type FilterItemProps = {
    projects: ProjectWithEventsRecord[];
    type: FilterItemType;
    selected: boolean;
    setSelected: () => void;
    deselect: () => void;
};

export const FilterItem: FC<FilterItemProps> = ({
    projects,
    type,
    selected,
    setSelected,
    deselect,
}) => {
    const { setSelectedProjectIds } = useProjects();

    const getContent = (): [FC, string] => {
        let icon: FC;
        let message: string;
        switch (type) {
            case FilterItemType.Unassigned:
                icon = Person;
                if (projects.length === 1) {
                    message = COPY.unassignedProject;
                } else {
                    message = COPY.unassignedProjects;
                }
                break;
            case FilterItemType.Unreturned:
                icon = Message;
                if (projects.length === 1) {
                    message = COPY.projectWithUnreturnedMessages;
                } else {
                    message = COPY.projectsWithUnreturnedMessages;
                }
                break;
            case FilterItemType.NeedingReview:
                icon = Approval;
                if (projects.length === 1) {
                    message = COPY.projectToReview;
                } else {
                    message = COPY.projectsToReview;
                }
        }
        return [icon, message];
    };

    const onClick = (): void => {
        if (!selected) {
            setSelectedProjectIds(projects.map((project) => project.id));
            setSelected();
        } else {
            setSelectedProjectIds([]);
            deselect();
        }
    };

    const [FilterItemIcon, message] = getContent();

    return (
        <>
            <FilterItemContainer onClick={onClick} selected={selected}>
                <FilterItemIcon />
                <ProjectNumber>{projects.length}</ProjectNumber>
                {message}
            </FilterItemContainer>
        </>
    );
};
