import { useEffect, useMemo } from 'react';

import { useDebouncedValue } from '@/common/hooks/useDebouncedValue';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { getNodes } from '@/common/utils/helpers';
import { ManuallySelectedLocalization, useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { IStateFragment, IStatesQuery, useStatesQuery } from '@/graphql';
import { StorageSetter } from '@/common/storage';

interface UseStatesPayload {
    states: IStateFragment[];
    localizationState: ManuallySelectedLocalization<IStateFragment>;
    setLocalizationState: StorageSetter<ManuallySelectedLocalization<IStateFragment>>;
}

const NUMBER_OF_STATES = 56;

export const useStates = (): UseStatesPayload => {
    const estimationProject = useEstimationProject();
    const statesQuery = useStatesQuery({
        variables: {
            input: {
                limit: NUMBER_OF_STATES,
            },
        },
    });

    const { localizationState, setLocalizationState } = useAssemblyPanel();

    // Return sorted states
    const sortedStates = useMemo(() => {
        const states = getNodes<IStatesQuery, IStateFragment>(statesQuery, 'states');
        return states.sort((a, b) => a.name.localeCompare(b.name));
    }, [statesQuery.data]);

    useEffect(() => {
        if (!sortedStates.length) {
            return;
        }

        if (!localizationState.localization) {
            setLocalizationState({
                localization: sortedStates[0],
                isManuallySet: localizationState.isManuallySet,
            });
            return;
        }

        const isStoredStateWithinCurrentValues = sortedStates.find(
            (state) => state.id === localizationState.localization?.id
        );

        if (isStoredStateWithinCurrentValues) {
            return;
        }

        setLocalizationState({
            localization: sortedStates[0],
            isManuallySet: localizationState.isManuallySet,
        });
    }, [localizationState, setLocalizationState, sortedStates]);

    useEffect(() => {
        // If there is no current localization state, update to localization
        if (
            !localizationState.isManuallySet &&
            estimationProject.projectInfo.projectLocation?.state
        ) {
            const updatedState = sortedStates.find(
                (state) => state.stusps === estimationProject.projectInfo.projectLocation?.state
            );

            // Google maps and our states are not necessarily a 1:1 match.
            // If there isn't a match, don't update
            if (updatedState) {
                setLocalizationState({
                    localization: updatedState,
                    isManuallySet: true,
                });
            }
        }
    }, [
        estimationProject.projectInfo.projectLocation?.state,
        localizationState,
        setLocalizationState,
        sortedStates,
    ]);

    // Debounce state value so we don't DDoS ourselves 💯
    const debouncedLocalizedState = useDebouncedValue(localizationState, 400);

    return {
        states: sortedStates,
        localizationState: debouncedLocalizedState,
        setLocalizationState,
    };
};
