import styled from 'styled-components';

import {
    borderCement,
    colorProjectPanelHighlight,
    colorProjectPanelText,
    colorProjectTableText,
    colorTypographyDark,
    colorTypographySection,
} from '@/variables';

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem 1.125rem 2rem 2rem;
`;

export const InfoHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const InfoEdit = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 2rem;
    padding: 0 0.75rem;
    border: ${borderCement};
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: ${colorProjectPanelHighlight};
    cursor: pointer;
`;

export const InfoHeaderText = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: ${colorTypographyDark};
`;

export const InfoEntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0;
`;

export const InfoLabel = styled.span`
    color: ${colorTypographySection};
    font-size: 11px;
    font-weight: 600;
    letter-specing: 0.06rem;
    margin-bottom: 0.125rem;
`;

export const InfoValue = styled.span`
    color: ${colorProjectPanelText};
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.01em;

    .more-trades-circle {
        color: ${colorProjectTableText};
    }
`;
