/*
 * ActionBar is the button that triggers estimator assignment.
 */
import React, { FC } from 'react';

import {
    ConfirmationButtons,
    ProjectPriceActionContainer,
    ProjectPriceActionIconHolder,
    ProjectPriceActionMain,
    ProjectPriceActionText,
} from './styled';
import { parseChargeValues, useProjectPricing } from './utils';

import { useToggle } from '@/common/hooks/useToggle';
import { Setter } from '@/common/types';
import { ButtonVariant } from '@/components/AdminDashboard/Modal/Button';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { Bugsnag } from '@/components/app/Bugsnag';
import { Button } from '@/components/ui/buttons/Button';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { Modal } from '@/components/ui/modals/Modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { pushToOpenedModalObservable } from '@/interfaces/adminDashboardModal';
import { useCreateEvent } from '@/mutations/event';
import { EventTypeName } from '@/queries/eventTypes';
import { colorProjectPanelHighlight, colorStatusError, colorTypographybody } from '@/variables';

import { ReactComponent as Dollar } from '@/assets/icons/Dollar.svg';
import { ReactComponent as SortArrow } from '@/assets/icons/SortArrow.svg';

const COPY = {
    cancelButton: 'CANCEL',
    chargeButton: 'CHARGE',
    coverCost: 'to their account to cover the cost?',
    creditsFrom: 'from',
    creditsTo: 'to',
    deduct: 'Deduct',
    grant: 'Grant',
    failureTitle: 'Failed to price project',
    failureBody: 'Please try again or contact the Engineering team.',
    notEnoughCredits:
        'doesn’t have enough credits to cover the cost of this project. Would you like to charge an ' +
        'additional',
    successTitleCharge: 'charged to',
    successTitleReturn: 'returned to',
    willCharge: 'This will charge',
};

export interface ActionBarProps extends ProjectPanelComponentProps {
    setLoading: Setter<boolean>;
}

export const ActionBar: FC<ActionBarProps> = ({ setLoading, useProjectPanel }) => {
    const { modifiedPrice, setModifiedPrice, project, setProject, pricingAssignment } =
        useProjectPanel();

    const { addNotification } = useNotifications();
    const { builderAcceptance } = useFlags();
    const mutateProjectPricing = useProjectPricing();
    const [isChargeModalOpen, toggleChargeModal] = useToggle();
    const [, createEvent] = useCreateEvent();
    const {
        data: { user },
    } = useUser();

    const priceUsdCents = pricingAssignment?.priceUsdCents ?? 0;

    if (modifiedPrice === undefined) {
        return <></>;
    }

    const { priceNetChange, teamBalance, centsToCharge, chargeString, chargedValue } =
        parseChargeValues(project, priceUsdCents, modifiedPrice);

    const addSuccessNotification = (): void => {
        addNotification(
            {
                title: `${chargedValue} ${
                    priceNetChange > 0 ? COPY.successTitleReturn : COPY.successTitleCharge
                } ${project.team?.name ?? ''}`,
            },
            'success'
        );
    };

    const handleCharge = (): Promise<void> => {
        setLoading(true);
        return mutateProjectPricing(project, modifiedPrice)
            .then((project) => {
                setModifiedPrice(undefined);
                setProject(project);
                addSuccessNotification();

                createEvent({
                    eventType: EventTypeName.EditProject,
                    message: `${chargedValue} ${
                        priceNetChange > 0 ? COPY.successTitleReturn : COPY.successTitleCharge
                    } ${project.team?.name ?? ''}`,
                    ownerId: Number(user.id),
                    projectId: Number(project.id),
                });
            })
            .catch((err) => {
                Bugsnag?.notify(err);
                addNotification(
                    {
                        title: COPY.failureTitle,
                        content: <p>{String(err)}</p>,
                    },
                    'error'
                );
            })
            .finally(() => setLoading(false));
    };

    const handleClick = (): void => {
        if (centsToCharge < 0 || teamBalance - centsToCharge >= 0) {
            handleCharge();
            return;
        }

        if (builderAcceptance) {
            toggleChargeModal();
            return;
        }

        pushToOpenedModalObservable({
            accentColor: colorStatusError,
            title: `${COPY.willCharge} ${project.team?.name ?? ''} ${chargeString}`,
            body: `${project.team?.name ?? ''} ${COPY.notEnoughCredits} ${chargeString} ${
                COPY.coverCost
            }`,
            buttons: [
                {
                    text: COPY.cancelButton,
                    variant: ButtonVariant.Cancel,
                },
                {
                    text: COPY.chargeButton,
                    variant: ButtonVariant.Action,
                    onClick: handleCharge,
                },
            ],
        });
    };

    const actionText = (): string => {
        if (priceNetChange > 0) {
            return `${COPY.grant} ${chargedValue} ${COPY.creditsTo} ${project.team?.name ?? ''}`;
        }
        return `${COPY.deduct} ${chargedValue} ${COPY.creditsFrom} ${project.team?.name ?? ''}`;
    };

    return (
        <>
            <ProjectPriceActionContainer onClick={handleClick}>
                <ProjectPriceActionMain>
                    <ProjectPriceActionIconHolder>
                        <SvgIcon
                            src={Dollar}
                            width="2rem"
                            height="2rem"
                            style={{ fill: colorProjectPanelHighlight }}
                        />
                    </ProjectPriceActionIconHolder>
                    <ProjectPriceActionText>{actionText()}</ProjectPriceActionText>
                </ProjectPriceActionMain>
                <SvgIcon
                    src={SortArrow}
                    width="1rem"
                    height="1rem"
                    style={{
                        fill: colorTypographybody,
                        transform: 'rotate(270deg)',
                    }}
                />
            </ProjectPriceActionContainer>
            <Modal
                isOpen={isChargeModalOpen}
                close={toggleChargeModal}
                headerText={`${COPY.willCharge} ${project.team?.name ?? ''} ${chargeString}`}
                modalContent={
                    <React.Fragment>
                        {`${project.team?.name ?? ''} ${COPY.notEnoughCredits} ${chargeString} ${
                            COPY.coverCost
                        }`}
                        <ConfirmationButtons>
                            <Button variant="tertiary" onClick={toggleChargeModal}>
                                {COPY.cancelButton}
                            </Button>
                            <Button
                                variant="tertiary"
                                customCss={{ borderColor: '#ED7054' }}
                                onClick={handleCharge}
                            >
                                {COPY.chargeButton}
                            </Button>
                        </ConfirmationButtons>
                    </React.Fragment>
                }
            />
        </>
    );
};
