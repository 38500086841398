import { RadioLabel } from '@/components/ui/inputs/RadioButton/styled';
import { breakpointExtraSmall } from '@/variables';
import styled, { css } from 'styled-components';

export const Subtitle = styled.p({
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#666666',
    marginTop: 0,

    '& > a': {
        cursor: 'pointer',
        color: '#305DFF',
        textDecoration: 'underline',
    },
});

export const Section = styled.div({
    position: 'relative',
    margin: '26px 0px',
    fontFamily: 'proxima-nova, sans-serif',

    '&:not(last-child)': {
        marginBottom: 0,
    },
});

export const SectionTitle = styled.p({
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    marginBottom: '15px',
});

////////////////////
// Link section //
////////////////////

export const LinkContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    margin: '10px 0',

    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#666666',
});

export const LinkText = styled.div({
    display: 'flex',
    alignItems: 'center',

    background: 'rgba(37, 48, 84, 0.07)',
    boxSizing: 'border-box',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '8px',
    width: '100%',
    height: '100%',

    minWidth: 0,

    '& > p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
});

export const LinkCopy = styled.span({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '100px',
    height: '100%',

    color: '#166FF3',
    background: 'rgba(37, 48, 84, 0.07)',
    boxSizing: 'border-box',
    padding: '8px',
    cursor: 'pointer',
});

export const LinkStats = styled.p<{ visible?: boolean }>(({ visible }) => ({
    display: visible ? 'block' : 'none',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',

    '> span': {
        display: 'inline-block',
        marginRight: '10px',
    },
}));

////////////////////
// Details section //
////////////////////

export const DetailsItem = styled.div({
    position: 'relative',
    display: 'flex',
    width: '100%',

    marginBottom: '10px',
    boxSizing: 'border-box',
});

export const DetailsRow = styled.div({
    position: 'relative',
    display: 'grid',

    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '15px',

    [`& > ${DetailsItem}`]: {
        flexDirection: 'column',

        [`& > ${RadioLabel}`]: {
            flexDirection: 'column',
            textAlign: 'center',
            cursor: 'pointer',

            padding: '15px',
            border: '2px solid #eee',
            borderRadius: '4px',

            ['&.checked']: {
                borderColor: '#305DFF',
            },
        },
    },
});

export const LabelOption = styled.span({
    fontStyle: 'normal',
    fontWeight: 400,

    marginTop: '15px',
    marginLeft: 0,

    '& > span': {
        display: 'block',
        fontWeight: 500,
        color: '#000000',
    },
});

////////////////////
// Button section //
////////////////////

export const ExportButton = styled.button<{ loading?: boolean }>(({ loading }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: '155px',
    background: !loading ? 'transparent' : '#F0F1F3',
    borderRadius: '4px',
    padding: '12px',

    color: '#166FF3',
    fontFmily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    cursor: 'pointer',

    '&:hover': {
        background: '#F0F1F3',
    },

    '& > svg': {
        marginRight: '8px',
    },
}));

export const ButtonContainer = styled.div({
    display: 'flex',

    [`& > ${ExportButton}`]: {
        ['&:not(last-child)']: {
            marginRight: '16px',
        },
    },

    [`@media (max-width: ${breakpointExtraSmall})`]: {
        flexDirection: 'column',

        [`& > ${ExportButton}`]: {
            backgroundColor: '#F2F5FF',

            ['&:not(last-child)']: {
                marginRight: 0,
                borderBottom: '1px solid #eee',
            },
        },
    },
});

export const MiniSpinner = styled.div(
    {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        border: '2px solid #ccc',
        borderTopColor: '#305DFF',
    },
    css`
        @keyframes spinner {
            to {
                transform: rotate(360deg);
            }
        }
        animation: spinner 0.6s linear infinite;
    `
);
