import Button, { ButtonProps } from '@mui/material/Button';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

type Props = ButtonProps;

export const UpgradeButton: FC<Props> = ({ children, onClick, ...props }) => {
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push('/pricing');

        onClick?.(event);
    };

    return (
        <Button onClick={handleClick} variant="contained" {...props}>
            {children || 'Upgrade'}
        </Button>
    );
};
