import React, { FC, ReactNode } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';

type TextFieldWithLabel = {
    labelComponent?: ReactNode;
} & TextFieldProps;

export const TextFieldWithLabel: FC<TextFieldWithLabel> = ({
    id,
    label,
    value,
    placeholder,
    labelComponent,
    ...rest
}) => {
    const theme = useTheme();

    return (
        <Stack spacing={0.5} sx={{ width: '100%' }}>
            {labelComponent ? (
                labelComponent
            ) : (
                <FormLabel htmlFor={id} sx={{ fontWeight: 600 }}>
                    {label}
                </FormLabel>
            )}
            <TextField
                id={id}
                value={value}
                placeholder={placeholder}
                fullWidth
                hiddenLabel
                size="small"
                variant="filled"
                FormHelperTextProps={{
                    sx: {
                        ...(theme.typography.body3 as Theme['typography']),
                        marginLeft: '0',
                        textTransform: 'uppercase',
                        ...rest.FormHelperTextProps?.sx,
                    },
                    ...rest.FormHelperTextProps,
                }}
                SelectProps={{
                    ...(rest.select
                        ? {
                              displayEmpty: true,
                              renderValue: value
                                  ? undefined
                                  : () => (
                                        <Box
                                            sx={{
                                                color: (theme) => theme.palette.hues.neutral[78],
                                            }}
                                        >
                                            {placeholder}
                                        </Box>
                                    ),
                              ...rest.SelectProps,
                          }
                        : {}),
                }}
                {...rest}
            />
        </Stack>
    );
};
