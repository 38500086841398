import googleAnalytics from '@/common/utils/google-analytics';
import {
    IViewAnalyticsQuery,
    IViewAnalyticsQueryVariables,
    ViewAnalyticsDocument,
} from '@/graphql';
import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type PageViewFilter = {
    slug: string;
    startTime?: string;
    endTime?: string;
};

export const useGoogleAnalytics = () => {
    const location = useLocation();

    const [viewAnalyticsData, setViewAnalyticsData] = useState<IViewAnalyticsQuery | undefined>(
        undefined
    );

    useEffect(() => {
        googleAnalytics.init();
    }, []);

    const sendPageView = () => {
        const currentPath = location.pathname + location.search;
        googleAnalytics.sendPageView(currentPath);
    };

    const [fetchPageViews, { loading: loadingViewAnalytics, error: errorFetchingPageViews }] =
        useLazyQuery<IViewAnalyticsQuery, IViewAnalyticsQueryVariables>(ViewAnalyticsDocument, {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            onCompleted: (d) => setViewAnalyticsData(d),
        });

    const viewAnalyticsInput = (
        slug: string,
        startTime?: Date | string,
        endTime?: Date | string
    ): QueryHookOptions<IViewAnalyticsQuery, IViewAnalyticsQueryVariables> => ({
        variables: {
            input: {
                slug,
                startTime,
                endTime,
            },
        },
        fetchPolicy: 'network-only',
    });

    const fetchPageViewsData = (filter: PageViewFilter) => {
        const { slug, startTime, endTime } = filter;
        fetchPageViews(viewAnalyticsInput(slug, startTime, endTime));
    };

    return {
        viewAnalyticsData,
        fetchPageViewsData,
        sendPageView,
        loadingViewAnalytics,
        errorFetchingPageViews,
    };
};
