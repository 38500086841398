import './polyfills';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

// Setups leaflet.pattern plugin to allow drawing custom lines
import { App, HotApp } from './App';
import './leaflet.pattern/setup';
import { Env } from './common/env';
import { setup } from './common/analytics';
import { ErrorBoundary } from './components/app/Bugsnag';

setup();

ReactDOM.render(
    <StrictMode>
        <ErrorBoundary>{Env.tier.isDevelopment ? <HotApp /> : <App />}</ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);
