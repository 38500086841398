import { IMarkupType } from '@/graphql';
import { GeometryType } from '@/common/types';

export const markupUnitNameMap: Record<IMarkupType, string> = {
    [IMarkupType.Area]: 'SF',
    [IMarkupType.Count]: 'EA',
    [IMarkupType.Linear]: 'LF',
};

export const markupTypeToGeometryType: Record<IMarkupType, GeometryType> = {
    [IMarkupType.Area]: GeometryType.AREA,
    [IMarkupType.Count]: GeometryType.COUNT,
    [IMarkupType.Linear]: GeometryType.LINEAR,
};
