import { Event } from '../common/types';
import gql from 'graphql-tag';

export type CreateEventResult = {
    createEvent: {
        event: Event;
    };
};
export type CreateMessageWithUploadsResult = {
    createMessageWithUploads: {
        event: Event;
    };
};
export type EditMessage = {
    eventId: number;
    message: string;
};

export const newEventMutation = gql`
    mutation CreateEvent(
        $eventTypeName: String!
        $message: String!
        $ownerId: BigInt
        $projectId: BigInt
    ) {
        createEvent(
            input: {
                event: {
                    eventTypeName: $eventTypeName
                    message: $message
                    ownerId: $ownerId
                    projectId: $projectId
                }
            }
        ) {
            event {
                id
                eventTypeName
                message
                ownerId
                projectId
            }
        }
    }
`;

export const newEventWithUploadsMutation = gql`
    mutation CreateMessageWithUploadsMutation(
        $eventTypeName: String!
        $message: String!
        $ownerId: BigInt!
        $projectId: BigInt!
        $projectUploadFiles: [CreateMessageWithUploadsUploadInput!]!
    ) {
        createMessageWithUploads(
            input: {
                eventTypeName: $eventTypeName
                message: $message
                ownerId: $ownerId
                projectId: $projectId
                projectUploadFiles: $projectUploadFiles
            }
        ) {
            event {
                id
                created
                eventTypeName
                message
                ownerId
                projectId
            }
        }
    }
`;

/* sets status of old message to replaced message
   and inserts a new event */
export const editMessageMutation = gql`
    mutation editMessage($eventId: BigInt!, $message: String!) {
        editMessage(input: { eventId: $eventId, message: $message }) {
            newId
        }
    }
`;
