/*
 * balance.ts provides GraphQL queries used to fetch records from the
 * `balance` table.
 */

import { frogmint, newQueries } from '../frog';

export interface BalanceRecord {
    created?: string;
    lastModified?: string;
    id: number;
    usdCents: number;
    teamId?: number;
}

export interface BalanceRecords {
    balances: {
        nodes: BalanceRecord[];
    };
}

export interface BalanceCondition {
    id?: number;
    teamId?: number;
}

export const balanceFragment = frogmint`
    ${'Balance_balance'} {
        created
        lastModified
        id
        usdCents
        teamId
    }
`;

export const BalanceQuery = balanceFragment.gql`
    query Balances($condition: BalanceCondition) {
        balances(condition: $condition) {
            nodes {
                ${balanceFragment}
            }
        }
    }
`;

export const [executeBalancesQuery, useBalancesQuery] = newQueries<
    BalanceCondition,
    BalanceRecords
>(BalanceQuery);
