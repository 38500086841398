/**
 * PolygonCoord is a polygon coordinate in pixels.
 *
 * The origin ({x: 0, y: 0}) is located in the upper-left corner.
 */
interface PolygonCoord {
    x: number;
    y: number;
}

/**
 * FillRule is a CSS fill rule.
 *
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill-rule
 */
export enum FillRule {
    Nonzero = 'nonzero',
    EvenOdd = 'evenodd',
}

/**
 * polygonCoordToCSS converts a polygon coordinate to its CSS representation.
 */
const polygonCoordToCSS = (coord: PolygonCoord): string => `${coord.x}px ${coord.y}px`;

/**
 * genCSSPolygon generates a CSS polygon function.
 */
export const genCSSPolygon = (coords: PolygonCoord[], fillRule?: FillRule): string => {
    const args: string[] = [];
    if (fillRule) {
        args.push(fillRule);
    }
    args.push(...coords.map(polygonCoordToCSS));
    return `polygon(${args.join(', ')})`;
};

/**
 * genPlusPolygon generates a CSS polygon function that looks like a plus icon.
 *
 * @example
 * A white 11px*11px plus icon with a stroke of 3px:
 * ```
 * <span style={{
 *     backgroundColor: '#FFFFFF',
 *     clipPath: genPlusPolygon(11, 3),
 *     height: '11px',
 *     width: '11px',
 * }} />
 * ```
 * @param sideLengthPx - length of all sides of the square icon container in pixels
 * @param strokeWidthPx - stroke width of the icon in pixels
 */
export const genPlusPolygon = (sideLengthPx: number, strokeWidthPx: number): string => {
    // Calculate distance along edge of bounding box from a corner to the edge of a neighboring stroke.
    const startOfBoxToStroke = (sideLengthPx - strokeWidthPx) / 2;

    // Calculate 4 distances along axes where points will be placed, rounding to the nearest px.
    const startOfBox = 0;
    const startOfStroke = Math.round(startOfBoxToStroke);
    const endOfStroke = Math.round(sideLengthPx - startOfBoxToStroke);
    const endOfBox = Math.round(sideLengthPx);

    // x and y values follow the same distance rotation, adding two entries per value.
    // Generate coordinates by trailing y values a rotation and a half behind x values.
    // The coordinates begin on the top stem's left side and progress clockwise.
    const coords = [
        startOfBox,
        startOfStroke,
        endOfStroke,
        endOfBox,
        endOfStroke,
        startOfStroke,
    ].reduce<PolygonCoord[]>(
        (acc, y, idx, rotArr) => [
            ...acc,
            { x: rotArr[(idx + 1) % rotArr.length], y },
            { x: rotArr[(idx + 2) % rotArr.length], y },
        ],
        []
    );

    // Use generated coordinates to create a filled '+' shaped polygon.
    return genCSSPolygon(coords, FillRule.Nonzero);
};
