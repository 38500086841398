import { IEstimateElementFragment } from '@/graphql';

export interface ElementPricesPayload {
    quantity: number;
    productionRate: number;
    laborHours: number;
    laborCost: number;
    materialCost: number;
    totalCost: number;
}

export const calculateElementPrices = (element: IEstimateElementFragment): ElementPricesPayload => {
    const quantity = element.expression.result;
    const productionRate = element.productionRate !== 0 ? 1 / element.productionRate : 0;
    const laborHours = quantity * productionRate;
    const laborCost = laborHours * element.laborRate;
    const materialCost = quantity * element.materialRate;
    const totalCost = laborCost + materialCost;

    return {
        quantity,
        productionRate,
        laborHours,
        laborCost,
        materialCost,
        totalCost,
    };
};
