/* Takes an array of providers and nests them recursively */

import React, { FC } from 'react';

interface ProvidersProps {
    value: FC[];
}

export const Providers: FC<ProvidersProps> = ({ value, children }) => {
    const LastProvider = value[value.length - 1];

    /* If no providers passed, return only children  */
    if (!LastProvider) return <>{children}</>;

    /* If only one provider passed, wrap children in that provider */
    if (value.length <= 1) {
        return <LastProvider>{children}</LastProvider>;
    }

    /* If more providers passed, wrap them recursively */
    return (
        <Providers value={value.slice(0, value.length - 1)}>
            <LastProvider>{children}</LastProvider>
        </Providers>
    );
};
