import { useEstimatePriceMarkups } from '../../hooks/useEstimatePriceMarkups';
import { useAssemblies } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { ratesToCostPerUnit } from '@/components/Estimate/utils/rates';

export const useMastheadGrandTotal = (): { grandTotal: number; totalMarkups: number } => {
    const { priceMarkups } = useEstimatePriceMarkups();
    const estimationProject = useEstimationProject();
    const { assemblies, uncategorizedAssembly } = useAssemblies({
        projectId: estimationProject.projectID,
    });

    const assembliesWithMaterials = uncategorizedAssembly
        ? [...assemblies, uncategorizedAssembly]
        : assemblies;

    const total = assembliesWithMaterials.reduce((accumulator, assembly) => {
        return (
            accumulator +
            assembly.elements.reduce((accumulator, element) => {
                return accumulator + ratesToCostPerUnit(element) * (element.expression.result || 0);
            }, 0)
        );
    }, 0);

    const totalMarkups = priceMarkups.reduce(
        (acc, cur) =>
            acc + (cur.total ? Number(cur.total) : (total * (Number(cur.percentage) || 0)) / 100),
        0
    );
    const grandTotal = total + totalMarkups;

    return { grandTotal, totalMarkups };
};
