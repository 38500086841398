import React from 'react';
import { AccordionContent, AccordionToggler, AccordionWrapper } from './styled';
import { ReactComponent as Minus } from '@/assets/icons/minus-circle.svg';
import { ReactComponent as Plus } from '@/assets/icons/plus-circle.svg';
import { useToggle } from '@/common/hooks/useToggle';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';

export interface AccordionProps {
    title: React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ title, children, disabled = false }) => {
    const [isOpen, toggle] = useToggle();

    const handleToggle = (): void => {
        if (disabled) return;
        toggle();
    };

    return (
        <AccordionWrapper>
            <AccordionToggler>
                {!disabled && (
                    <SvgIcon
                        src={!isOpen ? Plus : Minus}
                        width="1.375rem"
                        height="1.375rem"
                        noInherit
                        onClick={handleToggle}
                    />
                )}
                {title}
            </AccordionToggler>
            {isOpen && <AccordionContent>{children}</AccordionContent>}
        </AccordionWrapper>
    );
};
