import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

import { ReactComponent as Caret } from '@/assets/icons/caret-currentColor.svg';
import onboardingSrc from '@/assets/images/trial-signup.png';

export const IsometricBoxes = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
`;

export const MarketingDemo = styled.img`
    width: 100%;
    max-width: 93.75%;

    display: block;

    ${breakpoints.min.large`
        max-width: 616px;
    `}
`;

export const MarketingDemoContainer = styled.div`
    width: 100%;
    overflow: hidden;

    z-index: 2;

    & > ${MarketingDemo} {
        margin-left: auto;
    }
`;

export const MarketingHeader = styled.h2`
    font-family: ${(props) => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1b2235;
`;

export const MarketingSubheader = styled(MarketingHeader)`
    font-size: 18px;
`;

export const BulletCaret = styled(Caret)`
    color: #305dff;
`;

export const MarketingBullet = styled.div<{ bold?: boolean }>`
    display: flex;
    align-items: center;

    font-family: ${(props) => props.theme.v1.fontFamily.proximaNova};
    font-style: normal;
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    font-size: 16px;
    line-height: 24px;
    color: #1b2235;

    flex: none;
    order: 1;
    flex-grow: 1;

    & > ${BulletCaret} {
        margin-right: 8px;
    }
`;

export const MarketingBullets = styled.div`
    & > ${MarketingBullet} {
        margin: 0px 4px 0 0;
    }
`;

export const MarketingCopy = styled.div`
    padding: 0 48px;

    & > ${MarketingHeader} {
        margin: 16px 0;
    }
`;

export const MarketingContent = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
`;

export const MarketingContainer = styled.section`
    display: none;

    background-image: url(${onboardingSrc});
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    overflow: hidden;

    ${breakpoints.min.small`
        display: flex;

        & > ${MarketingContent} {
            flex: 1;
        }
    `}

    & > ${MarketingContent} {
        margin-top: auto;
        margin-bottom: auto;
    }
`;

export const MarketingContainerFreemium = styled(MarketingContainer)`
    background-image: none;
    background-color: #f7f3f0;
`;
