// setsAreEqual returns true if the two sets have identical contents.
export const setsAreEqual = <T extends string | number | symbol>(x: Set<T>, y: Set<T>) => {
    if (x.size !== y.size) {
        return false;
    }
    for (const xVal of x) {
        if (!y.has(xVal)) return false;
    }
    return true;
};
