import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { formatRFC3339, isValid } from 'date-fns';

import { Mode } from '@/theme/Mode';
import { IEstimateType, IQuoteExpiration } from '@/graphql';

import { COPY, currency, dateTimeFormat, dateTimeMask, defaultFormFields } from './constants';
import {
    QuoteModalProps,
    Field,
    NameValue,
    QuoteFormDictionary,
    FieldDictionary,
} from './interfaces';
import { FormIds } from './enums';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';

export const QuoteModal: FC<QuoteModalProps> = ({
    onCancelClick,
    userName,
    quoteDetails,
    submitQuoteDetails,
    hasSubscription = false,
    isPriceSet = false,
    areMoreProjects = false,
    ...props
}) => {
    const [fields, setFields] = useState<QuoteFormDictionary>(defaultFormFields);
    const [isSigned, setIsSigned] = useState<boolean>(false);

    // fallback to today is needed when changing values of the date and
    // if no min date is provided
    const formatDateToToday = (date: string): Date =>
        date && date !== '' ? new Date(date) : new Date();

    // undefined is needed for placeholder to be shown and
    // if no max date is provided for user to not have limits
    const formatDateToUndefined = (date: string): Date =>
        date && date !== '' ? new Date(date) : new Date('');

    useEffect(() => {
        setFields({
            ...fields,
            [FormIds.estimateType]: {
                ...fields[FormIds.estimateType],
                value: quoteDetails.acceptance.estimateTypes ?? [],
            },
            [FormIds.scopeOfWork]: {
                ...fields[FormIds.scopeOfWork],
                value: quoteDetails.acceptance?.scopeOfWork ?? '',
            },
            [FormIds.startBy]: {
                ...fields[FormIds.startBy],
                // Scalars['Time'] is generated to any from BE so using as string
                value: formatDateToUndefined(quoteDetails.acceptance?.startBy),
                minDate: new Date(),
            },
            [FormIds.finishBy]: {
                ...fields[FormIds.finishBy],
                // Scalars['Time'] is generated to any from BE so using as string
                value: formatDateToUndefined(quoteDetails.acceptance?.finishBy),
                minDate: formatDateToToday(quoteDetails.acceptance?.startBy),
            },
            [FormIds.quoteExpiration]: {
                ...fields[FormIds.quoteExpiration],
                value: quoteDetails.acceptance.quoteExpiration ?? '',
            },
            [FormIds.hoursEstimated]: {
                ...fields[FormIds.hoursEstimated],
                value: quoteDetails.pricing?.estimatorHours ?? 0,
            },
        });
    }, [quoteDetails]);

    const isFormValid: boolean = Object.entries(fields).every(
        ([_, field]: [key: string, field: Field]) => {
            if (
                isPriceSet &&
                [
                    FormIds.quoteExpiration,
                    FormIds.hoursEstimated,
                    FormIds.hourlyRate,
                    FormIds.discussedScope,
                ].includes(field.id)
            ) {
                return true;
            }

            if (field.id === FormIds.startBy || field.id === FormIds.finishBy) {
                return typeof field.value === 'string'
                    ? isValid(new Date(field.value))
                    : isValid(field.value as Date);
            }

            return field.value && field.value !== '';
        }
    );

    const getField = (key: FormIds) => fields[key];

    const handleFieldValueChange = <
        T extends FieldDictionary,
        K extends keyof T,
        F extends keyof T[K],
        V extends T[K][F]
    >(
        fieldId: keyof QuoteFormDictionary,
        newValue: V
    ) => {
        setFields({
            ...fields,
            [fieldId]: {
                ...fields[fieldId],
                value: newValue,
            },
        });
    };

    const handleSubmit = () => {
        if (isFormValid) {
            submitQuoteDetails({
                acceptance: {
                    ...quoteDetails.acceptance,
                    startBy: getField(FormIds.startBy).value,
                    finishBy: getField(FormIds.finishBy).value,
                    scopeOfWork: getField(FormIds.scopeOfWork).value.toString(),
                    estimateTypes: getField(FormIds.estimateType).value as IEstimateType[],
                    ...(getField(FormIds.quoteExpiration).value !== '' && {
                        quoteExpiration: getField(FormIds.quoteExpiration)
                            .value as IQuoteExpiration,
                    }),
                },
                ...(quoteDetails.pricing &&
                    !isPriceSet && {
                        pricing: {
                            ...quoteDetails.pricing,
                            estimatorHours: +getField(FormIds.hoursEstimated).value,
                            price:
                                +getField(FormIds.hoursEstimated).value *
                                +getField(FormIds.hourlyRate).value *
                                100 *
                                2, // we multiply by 2 to account for the take rate
                        },
                    }),
            });
        }
    };

    return (
        <Mode variant="light">
            <Dialog fullWidth PaperProps={{ sx: { width: '30%' } }} {...props}>
                <DialogTitle>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="h3"
                    >
                        {`${COPY.header} ${userName}!`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[48] }}
                        variant="body2"
                    >
                        {hasSubscription
                            ? areMoreProjects
                                ? COPY.subscriberMarketplace
                                : COPY.subscriberNoMarketplace
                            : COPY.trial}
                    </Typography>
                    <Box
                        mt="24px"
                        mb="8px"
                        p="8px 16px"
                        sx={{
                            border: (theme) => `1px solid ${theme.palette.hues.neutral[94]}`,
                        }}
                    >
                        <Stack spacing={1}>
                            <FormControl fullWidth component="fieldset" required>
                                <FormControlLabel
                                    sx={{ justifyContent: 'space-between', marginLeft: '8px' }}
                                    control={
                                        <Checkbox
                                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                handleFieldValueChange(
                                                    FormIds.readBuilderProfile,
                                                    event.target.checked
                                                )
                                            }
                                            checked={!!getField(FormIds.readBuilderProfile)?.value}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: (theme) => theme.palette.hues.neutral[48],
                                            }}
                                        >
                                            {getField(FormIds.readBuilderProfile).label}
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                />
                            </FormControl>
                            {!isPriceSet && (
                                <FormControl fullWidth component="fieldset" required>
                                    <FormControlLabel
                                        sx={{ justifyContent: 'space-between', marginLeft: '8px' }}
                                        control={
                                            <Checkbox
                                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                    handleFieldValueChange(
                                                        FormIds.discussedScope,
                                                        event.target.checked
                                                    )
                                                }
                                                checked={!!getField(FormIds.discussedScope)?.value}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.hues.neutral[48],
                                                }}
                                            >
                                                {getField(FormIds.discussedScope).label}
                                            </Typography>
                                        }
                                        labelPlacement="start"
                                    />
                                </FormControl>
                            )}
                        </Stack>
                    </Box>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.hues.neutral[32],
                                }}
                            >
                                {getField(FormIds.estimateType).label}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    value={
                                        getField(FormIds.estimateType).value as IEstimateType[] | []
                                    }
                                    hiddenLabel
                                    variant="filled"
                                    onChange={(event: SelectChangeEvent<IEstimateType[] | []>) => {
                                        handleFieldValueChange(
                                            FormIds.estimateType,
                                            event.target.value
                                        );
                                    }}
                                    multiple
                                    input={<FilledInput />}
                                    renderValue={(selected: IEstimateType[]) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography sx={{ fontStyle: 'italic' }}>
                                                    {getField(FormIds.estimateType).placeholder}
                                                </Typography>
                                            );
                                        }
                                        return getField(FormIds.estimateType)
                                            .items?.filter(
                                                (item: NameValue) =>
                                                    selected.indexOf(item.name as IEstimateType) >=
                                                    0
                                            )
                                            .map((item: NameValue) => item.value)
                                            .join(', ');
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        {getField(FormIds.estimateType).placeholder}
                                    </MenuItem>
                                    {getField(FormIds.estimateType).items?.map(
                                        (menuItem: NameValue) => (
                                            <MenuItem
                                                key={`estimate-type-${menuItem.name}`}
                                                value={menuItem.name}
                                            >
                                                {menuItem.value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.hues.neutral[32],
                                }}
                            >
                                {getField(FormIds.scopeOfWork).label}
                            </Typography>
                            <TextField
                                hiddenLabel
                                placeholder={getField(FormIds.scopeOfWork).placeholder}
                                variant="filled"
                                fullWidth
                                required
                                multiline
                                rows={7}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleFieldValueChange(FormIds.scopeOfWork, event.target.value)
                                }
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.hues.neutral[32],
                                }}
                            >
                                {getField(FormIds.startBy).label}
                            </Typography>
                            <DatePicker
                                minDate={new Date()}
                                maxDate={
                                    isValid(new Date(getField(FormIds.finishBy).value.toString()))
                                        ? formatDateToUndefined(
                                              getField(FormIds.finishBy).value.toString()
                                          )
                                        : undefined
                                }
                                inputFormat={dateTimeFormat}
                                mask={dateTimeMask}
                                value={formatDateToToday(
                                    getField(FormIds.startBy).value.toString()
                                )}
                                onChange={(newValue: Date | null) => {
                                    handleFieldValueChange(
                                        FormIds.startBy,
                                        newValue && isValid(new Date(newValue))
                                            ? formatRFC3339(new Date(newValue))
                                            : ''
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField hiddenLabel variant="filled" required {...params} />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.hues.neutral[32],
                                }}
                            >
                                {getField(FormIds.finishBy).label}
                            </Typography>
                            <DatePicker
                                minDate={formatDateToToday(
                                    getField(FormIds.startBy).value.toString()
                                )}
                                inputFormat={dateTimeFormat}
                                mask={dateTimeMask}
                                value={formatDateToToday(
                                    getField(FormIds.finishBy).value.toString()
                                )}
                                onChange={(newValue: Date | null) => {
                                    handleFieldValueChange(
                                        FormIds.finishBy,
                                        newValue && isValid(new Date(newValue))
                                            ? formatRFC3339(new Date(newValue))
                                            : ''
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField hiddenLabel variant="filled" required {...params} />
                                )}
                            />
                        </Stack>
                        {!isPriceSet && (
                            <>
                                <Stack spacing={1}>
                                    <Typography
                                        sx={{
                                            color: (theme) => theme.palette.hues.neutral[32],
                                        }}
                                    >
                                        {getField(FormIds.quoteExpiration).label}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            displayEmpty
                                            hiddenLabel
                                            value={getField(
                                                FormIds.quoteExpiration
                                            ).value.toString()}
                                            variant="filled"
                                            onChange={(event: SelectChangeEvent) =>
                                                handleFieldValueChange(
                                                    FormIds.quoteExpiration,
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem disabled value="">
                                                {getField(FormIds.quoteExpiration).placeholder}
                                            </MenuItem>
                                            {getField(FormIds.quoteExpiration).items?.map(
                                                (menuItem: NameValue) => (
                                                    <MenuItem
                                                        key={`customer-quote-${menuItem.name}`}
                                                        value={menuItem.name}
                                                    >
                                                        {menuItem.value}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography
                                        sx={{
                                            color: (theme) => theme.palette.hues.neutral[32],
                                        }}
                                    >
                                        {getField(FormIds.hoursEstimated).label}
                                    </Typography>
                                    <TextField
                                        hiddenLabel
                                        variant="filled"
                                        required
                                        type="number"
                                        onWheel={(e) =>
                                            e.target instanceof HTMLElement && e.target.blur()
                                        }
                                        value={getField(FormIds.hoursEstimated).value}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            handleFieldValueChange(
                                                FormIds.hoursEstimated,
                                                event.target.value
                                            )
                                        }
                                        inputProps={{ min: 0 }}
                                    />
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography
                                        sx={{
                                            color: (theme) => theme.palette.hues.neutral[32],
                                        }}
                                    >
                                        {getField(FormIds.hourlyRate).label}
                                    </Typography>
                                    <FormControl>
                                        <FilledInput
                                            hiddenLabel
                                            type="number"
                                            onWheel={(e) =>
                                                e.target instanceof HTMLElement && e.target.blur()
                                            }
                                            value={getField(FormIds.hourlyRate).value}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                handleFieldValueChange(
                                                    FormIds.hourlyRate,
                                                    event.target.value
                                                )
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    {currency}
                                                </InputAdornment>
                                            }
                                            inputProps={{ min: 0 }}
                                        />
                                    </FormControl>
                                </Stack>
                                <Divider />
                                <Stack spacing={1}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Typography
                                            sx={{
                                                color: (theme) => theme.palette.hues.neutral[48],
                                            }}
                                        >
                                            {COPY.totalBillings}
                                        </Typography>
                                        <Typography variant="h4" sx={{ color: '#000' }}>
                                            {currency}
                                            {+getField(FormIds.hoursEstimated).value *
                                                +getField(FormIds.hourlyRate).value}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Typography
                                            sx={{
                                                color: (theme) => theme.palette.hues.neutral[48],
                                            }}
                                        >
                                            {COPY.finalPrice}
                                        </Typography>
                                        <Typography variant="h4" sx={{ color: '#000' }}>
                                            {currency}
                                            {+getField(FormIds.hoursEstimated).value *
                                                +getField(FormIds.hourlyRate).value *
                                                2}
                                        </Typography>
                                    </Stack>
                                    {!isFormValid && (
                                        <Typography
                                            sx={{
                                                color: (theme) => theme.palette.hues.red[0],
                                            }}
                                        >
                                            {COPY.errorMessage}
                                        </Typography>
                                    )}
                                    {isSigned && (
                                        <Typography
                                            variant="h4"
                                            sx={{ color: '#000', fontStyle: 'italic' }}
                                        >
                                            {`${COPY.quoteSigned} ${userName}`}
                                        </Typography>
                                    )}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClick} variant="outlined">
                        {COPY.cancel}
                    </Button>

                    {isSigned || isPriceSet ? (
                        <Button
                            onClick={() => handleSubmit()}
                            disabled={!isFormValid}
                            variant="contained"
                        >
                            {isPriceSet ? COPY.gotIt : COPY.sendQuote}
                        </Button>
                    ) : (
                        <Button
                            disabled={!isFormValid}
                            onClick={() => setIsSigned(true)}
                            variant="contained"
                        >
                            {COPY.clickToSign}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Mode>
    );
};
