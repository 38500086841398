import React, { FC } from 'react';

import { Accordion } from '@/components/ui/containers/Accordion';
import { Assembly } from '@/common/types';
import { ILinkType } from '@/graphql';
import { formatters } from '@/common/currency';
import { getElementRate, getTotalPerElement, getTotalPerGroup } from '../utils';

import {
    Grid,
    GridRow,
    Text,
    AtSignText,
    textColorPrimary,
    textColorSecondary,
    CollapsibleHeader,
    CollapsibleleContent,
    InfoContent,
} from '../styled';

type AssembliesProps = {
    linkType: 'limited' | 'detailed';
    assemblies: Assembly[];
};

const formatter = formatters.usd({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const Assemblies: FC<AssembliesProps> = ({ linkType, assemblies }) => (
    <InfoContent>
        {assemblies &&
            assemblies.map((item, idx) => {
                const assembly = assemblies[idx];

                return (
                    <Accordion
                        key={idx}
                        title={
                            <CollapsibleHeader>
                                <Text weight={500} color={textColorPrimary}>
                                    {!item.description ? 'Uncategorized' : item.description}
                                </Text>
                                <Text weight={500} color={textColorPrimary}>
                                    {formatter.format(getTotalPerGroup(assembly) ?? 0)}
                                </Text>
                            </CollapsibleHeader>
                        }
                        disabled={linkType.toUpperCase() === ILinkType.Limited}
                    >
                        <CollapsibleleContent>
                            <Grid>
                                {assembly?.elements?.map((element, index) => {
                                    return (
                                        <GridRow className="grouped-row" key={index}>
                                            <Text color={textColorPrimary} weight={500}>
                                                {element.name}
                                            </Text>
                                            <Text color={textColorSecondary}>
                                                {Number(element.quantity).toFixed(2) +
                                                    ' ' +
                                                    element.uom.name}
                                                <AtSignText>@</AtSignText>
                                            </Text>
                                            <Text color={textColorSecondary}>
                                                ${getElementRate(element)?.toFixed(2) ?? 0}
                                            </Text>
                                            <Text color={textColorSecondary} align="right">
                                                {formatter.format(getTotalPerElement(element) ?? 0)}
                                            </Text>
                                        </GridRow>
                                    );
                                })}
                            </Grid>
                        </CollapsibleleContent>
                    </Accordion>
                );
            })}
    </InfoContent>
);
