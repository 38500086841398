/*
 * Top Bar
 */
export enum TabType {
    Current = 'Current',
    Completed = 'Completed',
    SaaS = 'SaaS',
}

/*
 * Project Dashboard
 */
export enum ProjectColumn {
    Project,
    Builder,
    Trade,
    SubmittedDate,
    DueDate,
    Estimator,
    Price,
    Status,
}

export enum ProjectAction {
    AssignEstimator,
    AssignPrice,
}

/*
 * Project Panel
 */
export enum ActionTab {
    Price = 'Price',
    Estimator = 'Estimator',
}

export enum InfoTab {
    Activity = 'Activity',
    Files = 'Files',
    Info = 'Info',
}

export enum InitialAction {
    FocusPrice,
}

export type ProjectPanelTab = ActionTab | InfoTab;
