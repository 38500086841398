import Flatten from '@flatten-js/core';

import { toFlatten } from '@/common/convert/geometry';
import { Geometry, GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

const shapeHasLength = (
    shape: Flatten.MultilineEdgeShape
): shape is Exclude<Flatten.MultilineEdgeShape, Flatten.Ray> => {
    return !(shape instanceof Flatten.Ray);
};

const getMultilineLength = (multiline: Flatten.Multiline): number =>
    multiline.edges
        .map((edge) => edge.shape)
        .filter(shapeHasLength)
        .reduce((totalLength, shape) => totalLength + shape.length, 0);

const getArea = (geometry: Geometry<GeometryType.AREA>, scale: number | null): number => {
    if (!scale) {
        return 0;
    }
    const area = toFlatten(geometry).area();
    return area / (scale * scale);
};

const getLength = (geometry: Geometry<GeometryType.LINEAR>, scale: number | null): number => {
    if (!scale) {
        return 0;
    }
    const lineLength = getMultilineLength(toFlatten(geometry));
    return lineLength / scale;
};

export const toQuantity = (geometry: Geometry, scale: number | null): number => {
    return newGeometryHandler<number>({
        [GeometryType.AREA]: (area) => getArea(area, scale),
        [GeometryType.COUNT]: (count) => count.coordinates.length,
        [GeometryType.LINEAR]: (line) => getLength(line, scale),
    })(geometry);
};
