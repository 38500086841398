/**
 * This file contains information for a virtual "robot" user
 *
 * The robot user's AuthID is fabricated - *do not use it in queries*
 */
import moment from 'moment/moment';

import { notNull, notNullOrUndefined } from './helpers';

import { BaseUserRecord } from '@/queries/baseUsers';
import { AnyEventTypeName, EventTypeName } from '@/queries/eventTypes';
import { EventFullRecord } from '@/queries/events';

export const robotUser: BaseUserRecord = {
    id: -2,
    authId: '1build|512faa444e6a2dd71d52a901',
    firstName: '1build',
    lastName: 'Staff',
    created: moment('2020-01-01').toISOString(),
    lastModified: moment('2020-01-01').toISOString(),
};

export type RobotEventTypes =
    | EventTypeName.CreateProject
    | EventTypeName.DownloadEstimate
    | EventTypeName.EditProject
    | EventTypeName.ApproveEstimate
    | EventTypeName.CancelProject;

export const robotEventTypes: AnyEventTypeName[] = [
    EventTypeName.CreateProject,
    EventTypeName.DownloadEstimate,
    EventTypeName.EditProject,
    EventTypeName.ApproveEstimate,
    EventTypeName.CancelProject,
];

export interface RobotEventFullRecord extends Omit<EventFullRecord, 'eventType'> {
    eventType: RobotEventTypes;
}

// GeneratedRobotMessages: robot-sent messages generated by a single project event
// This tuple caps the number of messages, enabling deterministic generation of unique ids.
type GeneratedRobotMessages = [EventFullRecord | null, EventFullRecord | null];

// isRobotMessage: event should add robot messages to the chat log
export const isRobotMessage = (event?: EventFullRecord | null): event is RobotEventFullRecord =>
    notNullOrUndefined(event) && robotEventTypes.includes(event.eventTypeName);

// This function will modify certain DB events so they appear correctly when rendered in chat.
const generateRobotMessages = (
    e: EventFullRecord,
    isSaaS: boolean,
    estimator: BaseUserRecord | undefined,
    projectUuid: string,
    projectOwnerId?: number,
    builderEstimateView?: boolean
): GeneratedRobotMessages => {
    let generatedEvents: GeneratedRobotMessages = [null, null];
    // If it's a robot-type message coming from someone else than the project owner, we don't wanna display it.
    // If it is builder estimate view we want to display message only for approve estimate
    if (
        projectOwnerId !== e.ownerId &&
        robotEventTypes.includes(e.eventTypeName) &&
        !(builderEstimateView && e.eventTypeName === EventTypeName.ApproveEstimate) &&
        !(e.eventTypeName === EventTypeName.CancelProject)
    ) {
        return generatedEvents;
    }
    // We parse the message to find the project changes. They are wrapped in parentheses on the edit_project_details
    // events.
    const changesRegex = /\(([^)]+)\)/;
    const changeMatch = changesRegex.exec(e.message || '');
    let changes: string[] = [];
    if (changeMatch && changeMatch[1]) {
        changes = changeMatch[1].split(', ').map((c) => c.replace(/_/g, ' '));
    }

    switch (e.eventTypeName) {
        // This should produce two messages, so we make a little hack.
        case EventTypeName.CreateProject:
            generatedEvents = [
                {
                    ...e,
                    message: 'Project created.',
                    owner: robotUser,
                    ownerId: robotUser.id,
                },
                {
                    ...e,
                    created: moment(new Date(e.created ?? '').getTime() + 1).toISOString(),
                    message: !isSaaS
                        ? `Hello, Welcome to 1build!

If you haven't yet, Please fill-in your Builder Profile at [this link](/projects/${projectUuid}/details).

If you have any further instructions, please type them in this chat! For example, you can clarify inclusions/exclusions, clarify this project's bid schedule, or provide templates to follow.

Next steps: We've dispatched your project to a 1build estimator to review the drawings. The 1build estimator will provide a price quote and a proposed return date. Once you approve the quote, we'll process payment and start estimating work. We'll notify you here when the quote is ready for review! Thank you!`
                        : 'Your project has been created. Use this section to type notes to others or just to yourself. ' +
                          'Anything typed here will be visible to anyone invited to the project.',
                    owner: robotUser,
                    ownerId: robotUser.id,
                },
            ];
            break;
        case EventTypeName.EditProject:
            generatedEvents = [
                {
                    ...e,
                    message: `Heads up: Project ${changes.join(', ')} was changed.`,
                    owner: robotUser,
                    ownerId: robotUser.id,
                },
                {
                    ...e,
                    created: moment(new Date(e.created ?? '').getTime() + 1).toISOString(),
                    message: e.message,
                    owner: robotUser,
                    ownerId: robotUser.id,
                },
            ];
            break;
        case EventTypeName.DownloadEstimate:
            generatedEvents[0] = {
                ...e,
                message: 'Estimate downloaded by client.',
                owner: robotUser,
                ownerId: robotUser.id,
            };
            break;
        case EventTypeName.CancelProject:
            generatedEvents[0] = {
                ...e,
                message: `### Project Cancelled
Project has been cancelled per user request on . If you'd like to complete this project at a future date, you will need to create a new project from the dashboard.`,
                owner: robotUser,
                ownerId: robotUser.id,
            };
            break;
    }
    return generatedEvents;
};

// prepare all robot-sent messages for a given event, assigning unique virtual message IDs to each
export const prepareRoboticMessages = (
    e: EventFullRecord,
    isSaaS: boolean,
    estimator: BaseUserRecord | undefined,
    projectUuid: string,
    projectOwnerId?: number,
    builderEstimateView?: boolean
): EventFullRecord[] =>
    generateRobotMessages(e, isSaaS, estimator, projectUuid, projectOwnerId, builderEstimateView)
        .filter(notNull)
        .map((e, i) => ({
            ...e,
            id: Number(e.id) * -1 - i,
        }));
