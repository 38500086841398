import React, { FC, useState } from 'react';
import clsx from 'clsx';
import AnimateHeight from 'react-animate-height';

import { Caret, CaretContainer, CollapsibleTarget, Container, Contents, Wrapper } from './styled';

import { ReactComponent as BackArrow } from '@/assets/icons/back-arrow.svg';

export * from './styled';

export interface CollapsibleProps {
    contents: JSX.Element;
    className?: string;
    style?: React.CSSProperties;
    nonCollapsible: boolean;
    isCollapsedByDefault?: boolean;
    isExpandedOverride?: boolean;
}

export const Collapsible: FC<CollapsibleProps> = ({
    children,
    contents,
    className,
    style,
    nonCollapsible,
    isCollapsedByDefault = true,
    isExpandedOverride = false,
}) => {
    const [collapsed, setCollapsed] = useState(isCollapsedByDefault);

    const toggleCollapse = (): void => {
        !nonCollapsible && setCollapsed(!collapsed);
    };

    const isOpen = isExpandedOverride || !collapsed;

    return (
        // Concat classnames for compatibility with upstream scss selectors
        <Wrapper className={clsx(className, 'collapsible-table-wrapper')} style={style}>
            <Container className="card" isOpen={isOpen}>
                <CollapsibleTarget onClick={toggleCollapse}>
                    {children}
                    {!nonCollapsible && (
                        <CaretContainer>
                            <Caret className="caret" src={BackArrow} noInherit={true} />
                        </CaretContainer>
                    )}
                </CollapsibleTarget>
                <AnimateHeight
                    duration={500}
                    delay={50}
                    easing="ease-in-out"
                    height={isOpen ? 'auto' : 0}
                >
                    <Contents className="table-container">{contents}</Contents>
                </AnimateHeight>
            </Container>
        </Wrapper>
    );
};
