/*
 * TakeoffRoot is the root of the takeoff tool.
 * This component must be a child of the takeoffs context.
 */
import './Takeoff.scss';
import { TakeoffComponentProps } from './context';
import { ScreenSize, useScreenSize } from '@/common/hooks/useScreenSize';
import { BooleanToolType } from '@/common/types';
import { ContextMenu } from '@/components/takeoff/ContextMenu';
import { ToolTooltip } from '@/components/takeoff/ToolTooltip';
import { activeVerticesObservable } from '@/components/takeoff/observables/helpers';
import { SheetToolbar } from '@/components/takeoff/sheet-toolbar/SheetToolbar';
import { SheetViewer } from '@/components/takeoff/sheet-viewer/SheetViewer';
import { HotKeys } from '@/components/ui/controls/HotKeys';
import React, { FC, useEffect } from 'react';
import { findDOMNode } from 'react-dom';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const COPY = {
    pleaseWaitInfo: 'Please wait while the plans for this project are ingested…',
    windowTooSmallWarning: 'Your browser window is too small',
    windowTooSmallInfo:
        'To perform a takeoff, your browser window needs to ' +
        'be 1000px wide or higher. Resize your browser window to get back ' +
        'to your takeoff.',
    loading: 'Files processing',
};

interface TakeoffRootProps extends TakeoffComponentProps {
    filesLoading: boolean;
}

export const TakeoffRoot: FC<TakeoffRootProps> = ({ useTakeoff, filesLoading }) => {
    const screen = useScreenSize();
    const {
        pagesBrowserOpen,
        setPagesBrowserOpen,
        searchInputRef,
        deleteGeometries,
        handlers,
        updateHandlers,
        deleteVertices,
        startBooleanOperation,
    } = useTakeoff();

    const activeVertices = activeVerticesObservable.value;

    useEffect(() => {
        updateHandlers({
            TOGGLE_PAGE_BROWSER: (): void => setPagesBrowserOpen((prev: boolean): boolean => !prev),
            SEARCH_PAGE: (e: KeyboardEvent | undefined): void => {
                if (
                    searchInputRef.current &&
                    pagesBrowserOpen &&
                    document.activeElement !==
                        // eslint-disable-next-line react/no-find-dom-node
                        findDOMNode(searchInputRef.current)
                ) {
                    searchInputRef.current.focus();
                    e?.preventDefault();
                }
            },
            DELETE: (): void => (activeVertices.length > 0 ? deleteVertices() : deleteGeometries()),
            ADD: (): void => startBooleanOperation(BooleanToolType.ADD),
            SUBTRACT: (): void => startBooleanOperation(BooleanToolType.SUBTRACT),
            SPLIT: (): void => startBooleanOperation(BooleanToolType.SPLIT),
        });
    }, [activeVertices, searchInputRef, pagesBrowserOpen]);

    return (
        <>
            <ToolTooltip />
            <HotKeys className="takeoff__container" handlers={handlers} scope="takeoff">
                {screen !== ScreenSize.Desktop && (
                    <div className="whole-page-overlay background">
                        <div className="overlay-content">
                            <div className="title-container">
                                <h2 className="no-margin error-text">
                                    {COPY.windowTooSmallWarning}
                                </h2>
                            </div>
                            <p className="body-l">{COPY.windowTooSmallInfo}</p>
                        </div>
                    </div>
                )}
                <SheetToolbar useTakeoff={useTakeoff} />
                {filesLoading ? (
                    <Stack
                        direction="column"
                        spacing="16px"
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            padding: '0 311px 4rem 0',
                        }}
                    >
                        <CircularProgress />
                        <Typography>{COPY.loading}</Typography>
                    </Stack>
                ) : (
                    <SheetViewer useTakeoff={useTakeoff} />
                )}
                <ContextMenu useTakeoff={useTakeoff} />
            </HotKeys>
        </>
    );
};
