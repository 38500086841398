/**
 * Tooltip that is always on top and displays information about the currently
 * hovered tool and the shortcut that can be used to trigger it.
 */

import React, { FC, useEffect } from 'react';

import { subscribeToToolTooltip } from './observables/helpers';
import { Portal } from '../app/Portal';
import { ToolTooltipPayload } from '../../common/types';
import { useNilState } from '../../common/utils/helpers';

import './ToolTooltip.scss';

export type TooltipEvents = {
    onMouseEnter: (e: React.MouseEvent) => void;
    onMouseLeave: () => void;
};

export const ToolTooltip: FC = () => {
    const [toolTooltipState, setToolTooltipState] = useNilState<ToolTooltipPayload>();

    useEffect(() => {
        const subscription = subscribeToToolTooltip(setToolTooltipState);
        return (): void => subscription.unsubscribe();
    }, []);

    return (
        <>
            {toolTooltipState && (
                <Portal>
                    <div
                        className="tooltooltip tooltip-content"
                        style={{
                            left: toolTooltipState.origin[0],
                            top: toolTooltipState.origin[1],
                        }}
                    >
                        <span className="name">{toolTooltipState.name}</span>
                        <span className="shortcut">{toolTooltipState.shortcut}</span>
                    </div>
                </Portal>
            )}
        </>
    );
};
