import { BooleanToolType, ToolType } from '../../../common/types';
import { TakeoffComponentProps } from '../context';
import { subscribeToBooleanTool } from '../observables/helpers';
import { BooleanGeometry } from './BooleanGeometry';
import { EditablePolyline } from './overlay/polyline/EditablePolyline';
import { useTool } from '@/components/takeoff/hooks/useTool';
import { DomUtil } from 'leaflet';
import React, { FC, useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';

export interface HelperOverlayProps extends TakeoffComponentProps {
    canDrawBoundary: boolean;
}

export const HelperOverlay: FC<HelperOverlayProps> = ({ canDrawBoundary, useTakeoff }) => {
    /**
     * This component is a dispatcher of different helper geometries that
     * can be drawn on a sheet. An example of a helper geometry is a calibration
     * line. These geometries can be drawn and edited but shouldn't be selectable.
     **/

    const { mapRef, calibrationLine, setCalibrationLine, isManualCalibration } = useTakeoff();

    const tool = useTool();

    const [booleanTool, setBooleanTool] = useState<BooleanToolType>();

    useEffect(() => {
        const subscription = subscribeToBooleanTool(setBooleanTool);
        return (): void => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (tool !== ToolType.CALIBRATION && calibrationLine.coordinates.length !== 0) {
            setCalibrationLine((oldCalibrationLine) => ({
                ...oldCalibrationLine,
                coordinates: [],
            }));
        }
    }, [tool, calibrationLine.coordinates]);

    const calibrationLineExists = calibrationLine.coordinates.length > 1;

    // DomUtils are the recommended way of changing the cursor on a leaflet map
    useEffect(() => {
        const allClasses = ['selection-cursor', 'drawing-cursor'];
        if (mapRef && mapRef.container) {
            allClasses.forEach((className) =>
                DomUtil.removeClass(mapRef.container as HTMLElement, className)
            );

            if (!canDrawBoundary) return;

            if (booleanTool) {
                DomUtil.addClass(mapRef.container, 'drawing-cursor');
                return;
            }

            switch (tool) {
                case ToolType.SELECTION:
                    DomUtil.addClass(mapRef.container, 'selection-cursor');
                    break;
                case ToolType.COUNT:
                case ToolType.AREA:
                case ToolType.LINEAR:
                    DomUtil.addClass(mapRef.container, 'drawing-cursor');
                    break;
                case ToolType.CALIBRATION:
                    if (!calibrationLineExists && isManualCalibration) {
                        DomUtil.addClass(mapRef.container, 'drawing-cursor');
                    } else {
                        DomUtil.addClass(mapRef.container, 'selection-cursor');
                    }
            }
        }
    }, [mapRef, tool, calibrationLineExists, isManualCalibration, canDrawBoundary, booleanTool]);

    return (
        <FeatureGroup bubblingMouseEvents={false}>
            {tool === ToolType.CALIBRATION && (
                <EditablePolyline
                    opacity={
                        isManualCalibration && calibrationLine.coordinates.length > 0 ? 1 : 0.2
                    }
                    interactive={isManualCalibration}
                    geometry={calibrationLine}
                    commit={(geometry): void => setCalibrationLine(geometry)}
                    maxVertexCount={2}
                    editable={true}
                    activeVertices={[]}
                    useTakeoff={useTakeoff}
                />
            )}
            <BooleanGeometry useTakeoff={useTakeoff} />
        </FeatureGroup>
    );
};
