/**
 * Selectable dropdown component.
 * Displays menu on click, with options to select from.
 */
import clsx from 'clsx';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { CaretIcon, CaretIconSize, CaretIconVariant } from '../icons/CaretIcon';
import { Direction, DivProps } from '@/common/types';
import { FormControl, NumericControlProps, TextControlProps } from './helpers/FormControl';
import { CenterEllipsis } from '../text/CenterEllipsis';
import { Placeholder } from '../loaders/Placeholder';

import './Dropdown.scss';

export type DropdownProps = DivProps & {
    options: string[];
    variant?: 'small';
    icon?: JSX.Element;
    onChangeOption?: (selectedOption: string) => void;
    disabled?: boolean;
    dropdownRef?: RefObject<HTMLDivElement>;
    onSelect?: () => void;
    caretCurrentColor?: boolean;
    displayInputStyle?: React.CSSProperties;
    v2?: boolean;
    isLoading?: boolean;
};

export type FullDropdownProps = Omit<
    NumericControlProps<DropdownProps> | TextControlProps<DropdownProps>,
    'onChange'
> & {
    onChange?: (index: number) => void;
};

export const Dropdown: FC<FullDropdownProps> = ({
    id,
    icon,
    className,
    options,
    value,
    onChange,
    onChangeOption,
    onFocus,
    label,
    required,
    variant,
    placeholder,
    disabled,
    dropdownRef,
    onSelect,
    caretCurrentColor,
    displayInputStyle,
    v2,
    isLoading,
}) => {
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useState<string>('');

    const optionsRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (optionsRef.current && (value as number) >= 0) {
            optionsRef.current.children.item(value as number)?.scrollIntoView();
        }
    }, [value, optionsRef]);

    const handleSelect = (i: number, option: string) => (): void => {
        if (onSelect) {
            onSelect();
        }
        if (onChangeOption) {
            onChangeOption(option);
        }
        if (onChange) {
            onChange(i);
        }
    };

    const close = (): void => {
        if (!open) return;
        setOpen(false);
        setKeyword('');
    };

    // The wonky logic for the default case is a holdover from old code,
    // preserved in case changing it would break old dependants.
    const dropdownValue = (): string => {
        if (typeof value === 'string' && value !== '') {
            return value;
        }
        if (typeof value === 'number') {
            return options[value] ?? placeholder;
        }
        return placeholder || '';
    };

    const handleChange = (i: number): void => {
        const cycleIndex = (i + options.length) % options.length;

        if (onChangeOption) {
            onChangeOption(options[cycleIndex]);
        }
        if (onChange) onChange(cycleIndex);
    };

    // When dropdown is opened you can type a key and it will go to the next
    // value starting with that key.
    const searchByKey = (e: React.KeyboardEvent): void => {
        if (e.key === 'ArrowDown') {
            // Prevent scroll
            e.preventDefault();
            setOpen(true);
            // Account for initial null index
            handleChange(((value ?? -1) as number) + 1);
        } else if (e.key === 'ArrowUp') {
            // Prevent scroll
            e.preventDefault();
            setOpen(true);
            handleChange((value as number) - 1);
        } else if (e.key === 'Enter') {
            handleChange(value as number);
            setOpen((isOpen) => !isOpen);
        } else if (e.key === 'Backspace') {
            close();
        } else if (e.key === 'Escape') {
            close();
        } else {
            setKeyword(keyword.concat(e.key));
        }

        const optionsLowercase = options.map((o) => o.toLowerCase());
        let filtered = optionsLowercase.filter((o) => o.startsWith(e.key));

        if (keyword.length > 0) {
            filtered = optionsLowercase.filter((o) => o.includes(keyword.concat(e.key)));
        }

        // Find indexes of all options left so their index must be
        // greater than current value.
        const indexes = filtered
            .map((o) => optionsLowercase.findIndex((opt) => opt === o))
            .filter((i) => i > (value as number));
        // If nothing found try to search from start.
        if (indexes.length === 0) {
            if (filtered.length > 0) {
                const index = optionsLowercase.findIndex((o) => o === filtered[0]);
                if (onChangeOption) onChangeOption(options[index]);
                if (onChange) onChange(index);
            }
            return;
        }
        // If found take the first corresponding value
        const newValue = indexes[0];
        if (onChangeOption) onChangeOption(options[newValue]);
        if (onChange) onChange(newValue);
    };

    return (
        <FormControl label={label} required={required} value={value} className={className}>
            <OutsideClickHandler onOutsideClick={close}>
                <div
                    onKeyDown={searchByKey}
                    className={clsx('dropdown', v2 && 'dropdown--v2', {
                        'dropdown-small': variant === 'small',
                        disabled,
                    })}
                >
                    {open && (
                        <div className="options-menu" ref={optionsRef}>
                            {options.map((option, i) => (
                                <div
                                    key={i}
                                    onMouseDown={handleSelect(i, option)}
                                    className={clsx('option', i === value && 'active')}
                                >
                                    {v2 ? <CenterEllipsis text={option} /> : <>{option}</>}
                                </div>
                            ))}
                        </div>
                    )}
                    <div
                        id={id}
                        className={clsx(
                            'display-input',
                            { open },
                            { 'display-input--inactive': value === null }
                        )}
                        onFocus={(e): void => {
                            onFocus?.(e);
                            setOpen(true);
                        }}
                        onClick={(): void => setOpen(true)}
                        onBlur={close}
                        onKeyDown={(e): void => {
                            if (e.key === 'Enter') {
                                onSelect?.();
                            }
                        }}
                        tabIndex={!disabled ? 0 : -1}
                        style={displayInputStyle}
                        ref={dropdownRef}
                    >
                        {icon !== undefined && icon}
                        <span className="value">
                            {isLoading ? <Placeholder /> : <>{dropdownValue()}</>}
                        </span>
                        <CaretIcon
                            direction={Direction.Down}
                            size={CaretIconSize.Small}
                            variant={
                                caretCurrentColor
                                    ? CaretIconVariant.CurrentColor
                                    : CaretIconVariant.Standard
                            }
                        />
                    </div>
                </div>
            </OutsideClickHandler>
        </FormControl>
    );
};
