/* This component shows the available keyboard shortcuts to the user */

import React, { FC, useState } from 'react';
import GroupKeyTable from './GroupKeyTable';
import hotkeys from 'hotkeys-js';

import { keyMap } from '@/common/hot-keys/keyMap';

import './HotKeysModal.scss';
import { Modal } from '@/components/ui/controls/Modal';
import { HotKeys } from '@/components/ui/controls/HotKeys';

const HotKeysModal: FC = () => {
    const [isKeysLegendOpen, setKeysLegendOpen] = useState(false);

    const openKeysLegend = (): void => setKeysLegendOpen(true);
    const closeKeysLegend = (): void => setKeysLegendOpen(false);

    const handlers = { SHOW_KEY_MAP: openKeysLegend };

    /* Get distinct group names from keyMap object */
    const scopes = Array.from(new Set(Object.values(keyMap).map((value) => value.scope))).filter(
        (item) => item === 'all' || item === hotkeys.getScope()
    );

    if (!scopes) return <></>;

    return (
        <div className="hotkeys">
            <HotKeys scope="all" handlers={handlers}>
                <Modal open={isKeysLegendOpen} onClose={closeKeysLegend}>
                    <div className="body">
                        <h2 className="center-text">Keyboard shortcuts</h2>
                        <div className="tables">
                            {scopes.map((scope) => (
                                <GroupKeyTable key={scope} scope={scope} />
                            ))}
                        </div>
                    </div>
                </Modal>
            </HotKeys>
        </div>
    );
};

export default HotKeysModal;
