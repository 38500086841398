import React from 'react';

import to from 'await-to-js';
import { useMutation } from 'urql';

import { DatabaseProjectStatus } from '@/common/types';
import { ErrorMessage } from '@/components/notifications/Notification';
import { useNotifications } from '@/contexts/Notifications';
import { usePublishEstimateMutation } from '@/graphql';
import {
    UpdateProjectStatusResult,
    updateProjectStatusMutation,
} from '@/mutations/updateProjectStatus';
import { ProjectRecord } from '@/queries/projects';

const COPY = {
    publishProjectErrorTitle: 'Project failed to publish',
    publishProjectErrorMessage: 'try again',
    publishProjectSuccessTitle: 'Project published',
    publishProjectSuccessMessage: 'The builder has been notified that their project is complete.',
    publishProjectSuccessMessageSaas: 'Project marked as complete.',
    unpublishProjectErrorMessage: 'try again',
    unpublishProjectErrorTitle: 'Project failed to change status',
    unpublishProjectSuccessMessageSaas: 'Project marked as in progress.',
};

interface UsePublishArgs {
    project: ProjectRecord | undefined;
}

interface UsePublishPayload {
    publish: () => Promise<void>;
    unpublish: () => Promise<void>;
}

export const usePublish = ({ project }: UsePublishArgs): UsePublishPayload => {
    // State
    /*---------------*/
    const { addNotification } = useNotifications();

    // Mutations
    /*---------------*/
    const [, updateProjectStatus] = useMutation<UpdateProjectStatusResult>(
        updateProjectStatusMutation
    );
    const [publishEstimate] = usePublishEstimateMutation();

    const publish = async (): Promise<void> => {
        if (!project) {
            addNotification(
                {
                    title: COPY.publishProjectErrorTitle,
                    content: (
                        <ErrorMessage
                            message={COPY.publishProjectErrorMessage}
                            handleClick={publish}
                        />
                    ),
                },
                'error'
            );
            throw new Error('No project configured');
        }

        const [error] = await to(
            publishEstimate({
                variables: {
                    input: {
                        ProjectID: project.id.toString(),
                    },
                },
            })
        );

        if (error) {
            addNotification(
                {
                    title: COPY.publishProjectErrorTitle,
                    content: (
                        <ErrorMessage
                            message={COPY.publishProjectErrorMessage}
                            handleClick={publish}
                        />
                    ),
                },
                'error'
            );
        } else {
            addNotification(
                {
                    title: COPY.publishProjectSuccessTitle,
                    content: (
                        <p>
                            {!project.isSaas
                                ? COPY.publishProjectSuccessMessage
                                : COPY.publishProjectSuccessMessageSaas}
                        </p>
                    ),
                },
                'success'
            );
        }
    };

    const unpublish = async (): Promise<void> => {
        if (!project) {
            addNotification(
                {
                    title: COPY.unpublishProjectErrorTitle,
                    content: (
                        <ErrorMessage
                            message={COPY.unpublishProjectErrorMessage}
                            handleClick={unpublish}
                        />
                    ),
                },
                'error'
            );
            throw new Error('No project configured');
        }

        const [error] = await to(
            updateProjectStatus(
                { projectId: project.id, status: DatabaseProjectStatus.ESTIMATING },
                {
                    additionalTypenames: ['ProjectsConnection'],
                }
            )
        );

        if (error) {
            addNotification(
                {
                    title: COPY.unpublishProjectErrorTitle,
                    content: (
                        <ErrorMessage
                            message={COPY.unpublishProjectErrorMessage}
                            handleClick={unpublish}
                        />
                    ),
                },
                'error'
            );
        } else {
            addNotification(
                {
                    title: COPY.publishProjectSuccessTitle,
                    content: <p>{COPY.unpublishProjectSuccessMessageSaas}</p>,
                },
                'success'
            );
        }
    };

    return {
        publish,
        unpublish,
    };
};
