/*
 * Contexts stores all global contexts.
 */
import { Providers } from '@/components/app/Providers';
import { AWSProvider } from './AWS';
import { ApolloProvider } from './Apollo';
import { FeaturesProvider } from './Features';
import { LoadingProvider } from './Loading';
import { NotificationsProvider } from './Notifications';
import { ReleaseProvider } from './Release';
import { StorageProvider } from './Storage';
import { ToolbarProvider } from './Toolbar';
import { UrqlProvider } from './Urql';
import { UserProvider } from './User';
import React, { FC } from 'react';
import { theme } from '@/theme';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ToastProvider } from 'react-toast-notifications';
import { Notification } from '@/components/notifications/Notification';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Careful, this is fragile — this ordering is somewhat specific.
// Contexts currently do make calls to other contexts so mount
// order does matter.
const globalProviders = [
    ToolbarProvider,
    ApolloProvider,
    NotificationsProvider,
    UrqlProvider,
    UserProvider,
    FeaturesProvider,
    ReleaseProvider,
    AWSProvider,
    StorageProvider,
    LoadingProvider,
];

export const GlobalProviders: FC = (props) => (
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <SnackbarProvider
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <ToastProvider
                        autoDismiss
                        autoDismissTimeout={4000}
                        components={{ Toast: Notification }}
                        placement="bottom-right"
                    >
                        <Providers value={globalProviders} {...props} />
                    </ToastProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </MuiThemeProvider>
    </BrowserRouter>
);
