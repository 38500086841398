import { Marker, Polygon, Polyline } from 'leaflet';

import { toLeaflet as coordinateDataToLeaflet } from '@/common/convert/coordinateData';
import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

type Convert = {
    [GeometryType.AREA]: Polygon;
    [GeometryType.COUNT]: Marker[];
    [GeometryType.LINEAR]: Polyline;
};

export const toLeaflet = newGeometryHandler<Convert>({
    [GeometryType.AREA]: (area) => coordinateDataToLeaflet(area.coordinates),
    [GeometryType.COUNT]: (count) => count.coordinates.map(coordinateDataToLeaflet),
    [GeometryType.LINEAR]: (line) => coordinateDataToLeaflet(line.coordinates),
});
