import React, { FC, useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Link as RRDLink, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
    MenuBalanceDisclaimer,
    MenuBalanceHeading,
    MenuInformation,
    UserDropdownAvatar,
    UserDropdownIcon,
    UserDropdownIconButton,
} from './styled';

import { formatters, toDollarAmount } from '@/common/currency';
import { MenuDrawer } from '@/components/ui/containers/MenuDrawer';
import { useUser } from '@/contexts/User';
import { Mode } from '@/theme/Mode';
import {
    hasPaidSubscription,
    hasPPUSubscription,
    isBuilderUser,
    isImpersonatorUser,
    isMissingPaymentMethod,
    isPureBuilderUser,
    isSuperadminUser,
} from '@/views/Projects/utils';

import { ReactComponent as LoginIcon } from '@/assets/icons/16/login.svg';
import { ReactComponent as ReceiptIcon } from '@/assets/icons/16/receipt.svg';
import { ReactComponent as UserProfileIcon } from '@/assets/icons/16/user--profile.svg';
import { ReactComponent as RocketIcon } from '@/assets/icons/32/rocket.svg';

const COPY = {
    balanceDisclaimerFreemium: `If you have any questions, you can contact 1build by phone/text at (707) 606-0804`,
    balanceDisclaimerSaaS: `Balance may not include most recent account activity. For questions, contact your customer 
        success manager at (773) 945-9891`,
};

export const UserDropdown: FC = () => {
    const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
    const location = useLocation();
    const {
        data: { user },
        actions: { logoutUser },
    } = useUser();
    const flags = useFlags();

    const signOut = (): void => logoutUser();
    const handleOpen = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };
    const isOpen = Boolean(anchorEl);
    const avatarText = `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`;

    /* Close tooltip on route change */
    useEffect(handleClose, [location]);

    return (
        <Mode variant="light">
            <UserDropdownIconButton color="secondary" onClick={handleOpen}>
                <UserDropdownAvatar>
                    <Typography variant="body3">{avatarText}</Typography>
                </UserDropdownAvatar>
            </UserDropdownIconButton>
            <MenuDrawer
                mobileMenuTitle="Account"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpen}
                onClose={handleClose}
            >
                {user?.team?.subscription && isBuilderUser(user) && user.team && (
                    <MenuInformation>
                        <MenuBalanceHeading variant="h4">
                            {`Balance: ${
                                typeof user?.team?.balance?.usdCents === 'number'
                                    ? formatters
                                          .usd()
                                          .format(toDollarAmount(user.team.balance.usdCents))
                                    : '0'
                            }`}
                        </MenuBalanceHeading>
                        <MenuBalanceDisclaimer variant="body2">
                            {isPureBuilderUser(user)
                                ? COPY.balanceDisclaimerFreemium
                                : COPY.balanceDisclaimerSaaS}
                        </MenuBalanceDisclaimer>
                    </MenuInformation>
                )}
                {hasPaidSubscription(user) &&
                    isBuilderUser(user) &&
                    !hasPPUSubscription(user) &&
                    user.team && (
                        <Link component={RRDLink} to="/billing">
                            <MenuItem dense>
                                <ListItemIcon>
                                    <UserDropdownIcon viewBox="0 0 16 16">
                                        <ReceiptIcon />
                                    </UserDropdownIcon>
                                </ListItemIcon>
                                View billing
                            </MenuItem>
                        </Link>
                    )}
                {!isMissingPaymentMethod({ user, flags }) && (
                    <Link component={RRDLink} to="/profile">
                        <MenuItem dense>
                            <ListItemIcon>
                                <UserDropdownIcon viewBox="0 0 16 16">
                                    <UserProfileIcon />
                                </UserDropdownIcon>
                            </ListItemIcon>
                            Personal details
                        </MenuItem>
                    </Link>
                )}
                {(isSuperadminUser(user) || isImpersonatorUser(user)) && (
                    <Link component={RRDLink} to="/mission-control">
                        <MenuItem dense>
                            <ListItemIcon>
                                <UserDropdownIcon viewBox="0 0 16 16">
                                    <RocketIcon />
                                </UserDropdownIcon>
                            </ListItemIcon>
                            Mission control
                        </MenuItem>
                    </Link>
                )}
                <MenuItem dense onClick={signOut}>
                    <ListItemIcon>
                        <UserDropdownIcon viewBox="0 0 16 16">
                            <LoginIcon />
                        </UserDropdownIcon>
                    </ListItemIcon>
                    Sign out
                </MenuItem>
            </MenuDrawer>
        </Mode>
    );
};
