/*
 * Navbar is a clickable list of available sections in the projects panel.
 */
import React, { FC } from 'react';

import { Action } from './Action';
import { Info } from './Info';
import { PanelNavbarContainer } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { ActionTab, InfoTab } from '@/components/AdminDashboard/types';
import Stack from '@mui/material/Stack';

export const Navbar: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => (
    <PanelNavbarContainer>
        <Stack direction="row" spacing={2}>
            <Info type={InfoTab.Activity} useProjectPanel={useProjectPanel} />
            <Info type={InfoTab.Files} useProjectPanel={useProjectPanel} />
            <Info type={InfoTab.Info} useProjectPanel={useProjectPanel} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="stretch" sx={{ mb: '0.4rem' }}>
            <Action type={ActionTab.Price} useProjectPanel={useProjectPanel} />
            <Action type={ActionTab.Estimator} useProjectPanel={useProjectPanel} />
        </Stack>
    </PanelNavbarContainer>
);
