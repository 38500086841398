import styled from 'styled-components';

import { BasicModal } from '@/components/ui/controls/BasicModal';
import { Input } from '@/components/ui/inputs/Input';
import {
    breakpointExtraSmall,
    borderCement,
    colorBackgroundLight,
    colorTertiaryLighter,
    colorStatusError,
} from '@/variables';

export const Modal = styled(BasicModal)`
    & > div:nth-child(2) {
        width: 490px;
        padding: 20px 40px 30px 25px;

        @media (max-width: ${breakpointExtraSmall}) {
            margin: 1rem;
            height: 70vh;
            overflow: auto;
        }

        & > div:nth-child(2) {
            margin-left: unset;

            & > div {
                background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
                border-radius: 4px;
                width: 84px;
                border-color: unset;
                margin-left: 15px;
            }
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const Label = styled.div`
    color: ${colorBackgroundLight};
    font-size: 16px;
    font-weight: normal;
`;

export const DateLabel = styled.div`
    color: ${colorBackgroundLight};
    font-size: 16px;
    font-weight: normal;
    margin-bottom: -10px;
`;

export const RadioLabel = styled.span`
    color: ${colorBackgroundLight};
    font-size: 16px;
    font-weight: normal;
`;

export const RadioContainer = styled.div`
    margin: 20px 0 20px 0;

    & .radio-wrapper {
        border: ${borderCement};

        & > .radio--checked {
            background-color: #4378f7;
        }
    }

    @media (max-width: ${breakpointExtraSmall}) {
        margin: 15px 0 15px 0;
    }
`;

export const Text = styled.div`
    color: ${colorBackgroundLight};
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 10px;
`;

export const ModalInput = styled(Input)`
    & > input {
        background: ${colorTertiaryLighter};
        border: unset;
        color: ${colorBackgroundLight};
        font-weight: 600;
        margin-bottom: 1rem;

        &::placeholder {
            font-size: 16px;
            font-weight: normal;
        }
    }
`;

export const Error = styled.div`
    color: ${colorStatusError};
`;
