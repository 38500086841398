/*
 * Info is the project panel's information module.
 */
import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { Information } from './Information';
import { InfoContainer, InfoEdit, InfoHeaderContainer, InfoHeaderText } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';

const COPY = {
    edit: 'Edit',
    projectInfo: 'Project info',
};

export const Info: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const history = useHistory();
    const { project } = useProjectPanel();

    const redirectToEditProject = (): void => history.push(`/projects/${project.uuid}/edit`);

    return (
        <InfoContainer>
            <InfoHeaderContainer>
                <InfoHeaderText>{COPY.projectInfo}</InfoHeaderText>
                <InfoEdit onClick={redirectToEditProject}>{COPY.edit}</InfoEdit>
            </InfoHeaderContainer>
            <Information useProjectPanel={useProjectPanel} />
        </InfoContainer>
    );
};
