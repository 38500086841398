/*
 * projects.ts provides GraphQL queries used to fetch records from the
 * `project` table.
 */
import { DatabaseProjectStatus, PricingType, Team, WageType } from '../common/types';
import { frogmint, newQueries } from '../frog';
import { BaseUserRecord, baseUserAuth0LessFragment, baseUserFragment } from './baseUsers';
import { EventRecords, eventFragment } from './events';
import {
    projectAcceptanceHistoryFragment,
    ProjectAcceptanceHistoryRecord,
} from './projectAcceptanceHistories';
import { ProjectReviewRecord, projectReviewFragment } from './projectReviews';
import { teamAuth0LessFragment, teamFragment } from './teams';
import { TradeRecord, tradeFragment } from './trades';

// other queries that update projects
const additionalTypenames = ['AssignEstimatorResult', 'EditProjectResult'];

export interface ProjectRecord {
    created?: string;
    lastModified?: string;
    id: number;
    name?: string;
    uuid: string;
    parentProjectUuid?: string;
    bidsDueDate: string;
    status?: DatabaseProjectStatus;
    additionalInfo?: string;
    feedback?: string;
    laborPriceMarkup?: number;
    materialPriceMarkup?: number;
    equipmentPriceMarkup?: number;
    contingency?: number;
    overheadAndProfit?: number;
    estimateDueDate?: string;
    isApprovalRequired?: boolean;
    wageType?: WageType;
    subtype?: string;
    type?: string;
    isSetupCompleted: boolean;
    teamId?: number;
    team?: Team;
    projectAcceptanceHistories?: {
        nodes: ProjectAcceptanceHistoryRecord[];
    };
    pricingAssignment: ProjectPricingAssignment;
    isPriceFinal: boolean;
    estimatorCostUsdCents: number;
    estimatorHours: number;
    projectTrades?: {
        nodes: {
            trade: TradeRecord;
        }[];
    };
    projectUsers?: {
        nodes: {
            user: BaseUserRecord;
        }[];
    };
    projectReviews?: {
        nodes: ProjectReviewRecord[];
    };
    demoProject?: {
        created: string;
    };
    isSaas: boolean;
    publicEstimationNotify: boolean;
    projectInfoId?: string;
}

export interface ProjectPricingAssignment {
    id: string;
    approvedBy1Build: boolean;
    projectPricingsByPricingAssignmentId: {
        nodes: ProjectPricing[];
    };
}

export interface ProjectPricing {
    id: string;
    priceUsdCents: number;
    pricingType: PricingType;
}

export interface ProjectRecords {
    projects: {
        nodes: ProjectRecord[];
    };
}

export interface ProjectCondition {
    id?: number;
    uuid?: string;
    teamId?: number;
}

export const projectFragment = frogmint`
    ${'Project_project'} {
        created
        lastModified
        id
        name
        parentProjectUuid
        uuid
        bidsDueDate
        status
        additionalInfo
        feedback
        laborPriceMarkup
        materialPriceMarkup
        equipmentPriceMarkup
        contingency
        overheadAndProfit
        estimateDueDate
        isApprovalRequired
        wageType
        subtype
        type
        isSetupCompleted
        isPriceFinal
        estimatorCostUsdCents
        estimatorHours
        teamId
        childOrder
        team {
            ${teamFragment}
        }
        projectAcceptanceHistories {
            nodes{
                ${projectAcceptanceHistoryFragment}
            }
        }
        pricingAssignment {
            projectPricingsByPricingAssignmentId {
              nodes {
                priceUsdCents
                pricingType
                id
              }
            }
            approvedBy1Build
            id
        }
        projectReviews {
            nodes {
                ${projectReviewFragment}
            }
        }
        projectTrades {
            nodes {
                trade {
                    ${tradeFragment}
                }
            }
        }
        projectUsers {
            nodes {
                user {
                    ${baseUserFragment}
                }
            }
        }
        isSaas
        publicEstimationNotify
        projectInfoId
    }
`;

export const projectAuth0LessFragment = frogmint`
    ${'Project_project'} {
        created
        lastModified
        id
        name
        parentProjectUuid
        uuid
        bidsDueDate
        status
        additionalInfo
        feedback
        laborPriceMarkup
        materialPriceMarkup
        equipmentPriceMarkup
        contingency
        overheadAndProfit
        estimateDueDate
        isApprovalRequired
        wageType
        subtype
        type
        isSetupCompleted
        isPriceFinal
        estimatorCostUsdCents
        estimatorHours
        teamId
        team {
            ${teamAuth0LessFragment}
        }
        projectAcceptanceHistories {
            nodes {
                ${projectAcceptanceHistoryFragment}
            }
        }
        pricingAssignment {
            projectPricingsByPricingAssignmentId {
              nodes {
                priceUsdCents
                pricingType
                id
              }
            }
            approvedBy1Build
            id
        }
        projectReviews {
            nodes {
                ${projectReviewFragment}
            }
        }
        projectTrades {
            nodes {
                trade {
                    ${tradeFragment}
                }
            }
        }
        projectUsers {
            nodes {
                user {
                    ${baseUserAuth0LessFragment}
                }
            }
        }
        isSaas
        publicEstimationNotify
        demoProject {
            created
        }
    }
`;

export const ProjectsQuery = projectFragment.gql`
    query Projects($condition: ProjectCondition) {
        projects(condition: $condition) {
            nodes {
                ${projectFragment}
            }
        }
    }
`;

export const [executeProjectsQuery, useProjectsQuery] = newQueries<
    ProjectCondition,
    ProjectRecords
>(ProjectsQuery, { additionalTypenames });

export type ProjectWithEventsRecord = ProjectRecord & EventRecords;

export interface ProjectWithEventsRecords {
    projects: {
        nodes: Array<ProjectWithEventsRecord>;
    };
}

export const projectWithEventsFragment = frogmint`
    ${'Project_projectWithEvents'} {
        ${projectAuth0LessFragment}
        events {
            nodes {
                ${eventFragment}
            }
        }
    }
`;

export const ProjectsWithEventsQuery = projectWithEventsFragment.gql`
    query Projects($condition: ProjectCondition) {
        projects(condition: $condition) {
            nodes {
                ${projectWithEventsFragment}
            }
        }
    }
`;

export const [executeProjectsWithEventsQuery, useProjectsWithEventsQuery] = newQueries<
    ProjectCondition,
    ProjectWithEventsRecords
>(ProjectsWithEventsQuery, { additionalTypenames });

export const PaginatedProjectsWithEventsQuery = projectWithEventsFragment.gql`
    query PaginatedProjectsWithEvents(
        $condition: ProjectCondition,
        $first: Int,
        $after: Cursor,
        $orderBy: [ProjectsOrderBy!],
        $options: ProjectsWithEventsOptions!
    ) {
        projectsWithEvents(
            condition: $condition,
            first: $first,
            after: $after,
            orderBy: $orderBy,
            options: $options
        ) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
            }
            nodes {
                ${projectWithEventsFragment}
            }
        }
    }
`;
