/* eslint-disable @typescript-eslint/indent, max-len */

/**
 * ProjectConfigurationForm is a component responsible for gathering details
 * about a Project that the Builder wants to do so that it can go through the
 * whole process and receive an estimation.
 */
import { compareAsc } from 'date-fns';
import { FileManager, FileManagerFile } from './FileManager';
import './ProjectConfigurationForm.scss';
import { ReactComponent as CheckIcon } from '@/assets/icons/Check.svg';
import {
    CheckboxData,
    DatabaseProjectStatus,
    ProjectReviewStatus,
    Setter,
    WageType,
} from '@/common/types';
import { bidsDueDate as BidsDueDate } from '@/common/utils/bidsDueDate';
import { inferAddressAttributesFromPlace } from '@/common/utils/googleLocationHelpers';
import {
    readableWageTypeToWageType,
    useNilState,
    wageTypeToReadable,
} from '@/common/utils/helpers';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { BigRadioSelect } from '@/components/ui/inputs/BigRadioSelect';
import { Checkbox } from '@/components/ui/inputs/Checkbox';
import { Datepicker } from '@/components/ui/inputs/Datepicker';
import { Input } from '@/components/ui/inputs/Input';
import { LocationInput } from '@/components/ui/inputs/Location';
import { useFormattedAddress } from '@/components/ui/inputs/Location/hooks/useFormattedAddress';
import { MultiCheck } from '@/components/ui/inputs/Multicheck';
import { useNotifications } from '@/contexts/Notifications';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { IProjectLocationAssignmentInput, IUserRole } from '@/graphql';
import { ProjectPlanFileRecord } from '@/queries/projectPlanFiles';
import { ProjectRecord } from '@/queries/projects';
import { TradeRecord, useTradesQuery } from '@/queries/trades';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useFeatures } from '@/contexts/Features';
import { useProjectReview } from '@/common/hooks/useProjectReview';
import { ProjectReviewModal } from '@/components/projects/ProjectDetails/ProjectInfo/ProjectReviewModal';
import { useAllProjects } from '@/common/hooks/useAllProjects';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { colorBackgroundLight } from '@/variables';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { projectTypeOptions } from './constants';

const COPY = {
    project_creation_name_placeholder: 'Enter project name',
    project_creation_section_1: 'Details',
    project_creation_location: 'Where is this project located?',
    project_creation_bid_due_question: 'When do you need this estimate returned?',
    project_all_trades_text: 'Select all trades',
    project_creation_trades_question: 'What trades are associated with this project?',
    project_creation_wage_type_question: 'What wage type should be used for this project?',
    project_creation_destination_question:
        'Would you like to perform the estimate on your own or send to a 1build estimator?',
    project_you_do_it_text: 'You do it!',
    project_ill_do_it_text: "I'll do it!",
    project_creation_union: 'Union',
    project_creation_non_union: 'Non-union',
    project_creation_section_2: 'Upload files',
    project_creation_upload: 'Upload',
    project_creation_add_link: 'Add Link',
    project_creation_files_not_supported: 'The following file(s) are not supported:',
    project_due_date_pending:
        'Due Date Requested above is PENDING. 1build will review availability, review ' +
        'the drawings, and confirm the due date.',
    projectType: 'Project type',
    type: 'Type',
    subtype: 'Sub-Type',
};

type ProjectConfigurationFormProps = {
    isNew: boolean;
    plans: ProjectPlanFileRecord[];
    setPlans: Setter<ProjectPlanFileRecord[]>;
    project: ProjectRecord;
    updateProject: Setter<ProjectRecord>;
    projectLocation: Omit<IProjectLocationAssignmentInput, 'projectID'>;
    setProjectLocation: Setter<Omit<IProjectLocationAssignmentInput, 'projectID'>>;
    loading: boolean;
    showErrors: boolean;
    submitErrors?: { [key: string]: string };
};

const allowedFileExtensions = [
    'application/x-zip-compressed',
    'application/zip',
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
];

const typeOptions: string[] = Object.keys(projectTypeOptions);

export const ProjectConfigurationForm: FC<ProjectConfigurationFormProps> = ({
    isNew,
    plans,
    setPlans,
    project,
    updateProject,
    projectLocation,
    setProjectLocation,
    loading,
    showErrors,
    submitErrors = {},
}) => {
    const { addNotification } = useNotifications();
    const { remove, upload, getFileUrl } = useStorage();
    const breakpoints = useBreakpoints();
    const {
        data: { user },
    } = useUser();
    const [fetchedTrades] = useTradesQuery({});
    const {
        features: { marketplaceRating },
    } = useFeatures();
    const { projects, fetching } = useAllProjects();
    const [lastCompletedProject, setLastCompletedProject] = useState<ProjectRecord>();
    const [showReviewLastProject, setShowReviewLastProject] = useState(false);
    const [setupInfo, setSetupInfo] = useState<{ subtype: string; type: string }>({
        subtype: '',
        type: '',
    });

    const handleSubtypeChange = (subtype: string): void => {
        setSetupInfo((setupInfo) => ({ ...setupInfo, subtype: subtype }));
    };

    const handleTypeChange = (type: string): void => {
        setSetupInfo((setupInfo) => ({ ...setupInfo, type: type }));
        handleSubtypeChange('');
    };

    const {
        review,
        handleReviewStarChange,
        handleReviewStatusChange,
        handleReviewTagChange,
        handlePreferenceStatusChange,
        handleReviewCommentChange,
        handleSubmitReview,
        returnDate,
        allFieldsFilled,
    } = useProjectReview({
        project: lastCompletedProject,
        estimator: lastCompletedProject?.projectUsers?.nodes[0]?.user,
    });

    const handleShowReviewLastProject = () => {
        if (!projects.length || fetching) {
            return;
        }

        const completedProjects = projects
            .filter((project) => project.status === DatabaseProjectStatus.COMPLETE)
            .sort((a, b) =>
                compareAsc(
                    a.lastModified ? returnDate(a.lastModified) : 0,
                    b.lastModified ? returnDate(b.lastModified) : 0
                )
            )
            .reverse();

        if (completedProjects[0]?.projectReviews?.nodes.length === 0) {
            setLastCompletedProject(completedProjects[0]);
            setShowReviewLastProject(true);
        }
    };

    useEffect(() => {
        handleShowReviewLastProject();
    }, [fetching]);

    const [projectName, setProjectName] = useState(project?.name || '');
    const [bidsDueDate, setBidsDueDate] = useNilState<Date>(
        project?.estimateDueDate
            ? new Date(project.estimateDueDate)
            : isNew
            ? undefined
            : new Date(project.bidsDueDate)
    );
    const [tradesState, setTradesState] = useState<CheckboxData[]>([]);
    const [wageType, setWageType] = useState(project?.wageType);
    const [isTitleFocused, setIsTitleFocused] = useState(true);
    const [location, setLocation] = useState<[number, number] | undefined>();
    const { address, setAddress, formattedAddress, setFormattedAddress } = useFormattedAddress(
        projectLocation.formattedAddress ?? undefined
    );

    const allTradesChecked = useMemo(
        () => tradesState.every((trade) => trade.isChecked),
        [tradesState]
    );
    const fetchedTradesMap: Record<string, TradeRecord> | null = useMemo(
        () =>
            fetchedTrades.data
                ? fetchedTrades.data.trades.nodes.reduce(
                      (acc, fetchedTrade) => ({
                          ...acc,
                          [fetchedTrade.id]: fetchedTrade,
                      }),
                      {}
                  )
                : null,
        [fetchedTrades]
    );

    useEffect(() => {
        setFormattedAddress(projectLocation.formattedAddress ?? undefined);
    }, [projectLocation.formattedAddress]);

    const fileUploadRef = useRef<HTMLInputElement>(null);

    const isEditing = !!project?.id;

    const shouldBeDisabled = loading && !project.isSaas;

    const isAdmin =
        user?.roles?.includes(IUserRole.Admin) || user?.roles?.includes(IUserRole.Superadmin);

    useEffect(() => {
        if (fetchedTrades.fetching || !fetchedTrades.data) return;

        const trades = fetchedTrades.data.trades.nodes;
        const projectTrades = project?.projectTrades?.nodes.map((n) => n.trade) ?? [];

        setTradesState(
            trades
                .sort((t1, t2) => t1.name.localeCompare(t2.name))
                .filter((trade) => trade?.isSelectableAtProjectCreation)
                .map((trade) => ({
                    ...trade,
                    id: trade.id.toString(),
                    isChecked: projectTrades.map((pt) => Number(pt.id)).includes(Number(trade.id)),
                }))
        );
    }, [fetchedTrades]);

    const handleTitleFocus = (): void => setIsTitleFocused(true);
    const handleTitleBlur = (): void => {
        (document.activeElement as HTMLInputElement).blur();
        setIsTitleFocused(false);
    };
    const handleTitleCommit = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            handleTitleBlur();
        }
    };

    const handleFilesAdd = (newFiles: File[]): void => {
        if (!project) return;

        const notSupported = newFiles.filter((f) => !allowedFileExtensions.includes(f.type));

        if (notSupported.length > 0) {
            addNotification(
                {
                    title: COPY.project_creation_files_not_supported,
                    content: <p>{notSupported.map((nf) => nf.name).join(', ')}</p>,
                },
                'error'
            );
        }

        newFiles
            .filter((f) => allowedFileExtensions.includes(f.type))
            .forEach((f) => {
                const newUuid = uuid();
                upload(f.name, project.uuid, newUuid, 'plans', f).catch((rejectedFilename) =>
                    setPlans((oldPlans) => oldPlans.filter((p) => p.filename !== rejectedFilename))
                );
                setPlans((oldPlans) => [
                    ...oldPlans,
                    {
                        id: 0,
                        filename: f.name,
                        uuid: newUuid,
                        projectId: project.id,
                        __typename: 'ProjectPlanFile',
                    },
                ]);
            });
    };

    const handleFileDownload = (
        name: string,
        file: FileManagerFile,
        projectUuid?: string
    ): void => {
        getFileUrl(file as ProjectPlanFileRecord, projectUuid ?? '').then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.click();
        });
    };

    const handleFileRemove = (file: FileManagerFile): void => {
        if (!project || file.filename === undefined) return;
        !isEditing && remove(file.filename, project.uuid, file.uuid, 'plans');
        setPlans((oldPlans) => oldPlans.filter((p) => p.uuid !== file.uuid));
    };

    // If estimateDueDate was selected we have to add to this
    // date the number of days that passed until builder reviewed the revision.;
    const daysToAdd = BidsDueDate(project?.lastModified).businessDiff();
    const minDate = project?.estimateDueDate
        ? BidsDueDate(project?.estimateDueDate).addBusinessDays(daysToAdd).getDate()
        : undefined;

    useEffect(() => {
        if (fetchedTradesMap) {
            // Overwrite location if address is set to null or changed.  Otherwise, spread nothing
            // to keep previous address data.
            const locationOverwrite =
                address !== undefined ? inferAddressAttributesFromPlace(address) : {};

            setProjectLocation((prevLocation) => ({
                ...prevLocation,
                coordinates: location,
                ...locationOverwrite,
            }));

            updateProject((prev) => ({
                ...prev,
                name: projectName,
                bidsDueDate: bidsDueDate
                    ? bidsDueDate.toISOString()
                    : BidsDueDate(new Date().toISOString())
                          .addBusinessDays(4)
                          .getDate()
                          .toISOString(),
                status: DatabaseProjectStatus.NEW,
                wageType: wageType,
                projectTrades: {
                    nodes: tradesState
                        .filter((trade) => trade.isChecked && fetchedTradesMap[trade.id])
                        .map((trade) => ({ trade: fetchedTradesMap[trade.id] })),
                },
                location,
                type: setupInfo.type,
                subtype: setupInfo.subtype,
                ...locationOverwrite,
            }));
        }
    }, [
        projectName,
        bidsDueDate,
        tradesState,
        wageType,
        location,
        address,
        fetchedTradesMap,
        setupInfo,
    ]);

    const handleUploadFilePress = (): void => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const checkAllTrades = (): void => {
        const shouldBeChecked = allTradesChecked ? false : true;
        const newTradesState = tradesState.map((trade) => ({
            ...trade,
            isChecked: shouldBeChecked,
        }));
        setTradesState(newTradesState);
    };

    const updateLocation = useCallback(
        (lat: number, lng: number): void => setLocation([lng, lat]),
        []
    );

    const handleCloseProjectReviewModal = () => {
        setShowReviewLastProject(false);

        if (review.status === ProjectReviewStatus.Edited) {
            handleReviewStatusChange(ProjectReviewStatus.Finished);
        }
    };

    const submitReview = async () => {
        await handleSubmitReview();
        handleCloseProjectReviewModal();
    };

    const sections = [
        {
            label: COPY.project_creation_location,
            jsx: (
                <LocationInput
                    placeholder="City, state or zip"
                    address={formattedAddress}
                    setAddress={setAddress}
                    setCoordinates={updateLocation}
                />
            ),
        },
        ...(!project.isSaas
            ? [
                  {
                      errors: (
                          <div className="error date-error">
                              {(showErrors || bidsDueDate) && submitErrors['date']}
                          </div>
                      ),
                      label: COPY.project_creation_bid_due_question,
                      jsx: (
                          <>
                              <Datepicker
                                  bypassDisabledDates={isAdmin || project.isSaas}
                                  value={bidsDueDate}
                                  onChange={setBidsDueDate}
                                  disabled={shouldBeDisabled}
                                  minDate={minDate?.toDate()}
                              />
                              {user.roles.includes(IUserRole.Builder) &&
                                  project.status === DatabaseProjectStatus.NEW &&
                                  !project.isSaas && (
                                      <p className="section-typography body-m">
                                          {COPY.project_due_date_pending}
                                      </p>
                                  )}
                          </>
                      ),
                  },
              ]
            : []),
        {
            errors: <div className="error type-error">{showErrors && submitErrors['type']}</div>,
            label: COPY.projectType,
            jsx: (
                <>
                    <Stack spacing={4}>
                        <Stack spacing={1}>
                            <Typography>{COPY.type}</Typography>
                            <Select
                                size="small"
                                variant="filled"
                                hiddenLabel
                                displayEmpty
                                sx={{
                                    maxWidth: breakpoints.mobile ? '100%' : '24rem',
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        backgroundColor: colorBackgroundLight, // no color in pallete
                                    },
                                }}
                                onChange={(e) => {
                                    handleTypeChange(e.target.value);
                                }}
                                value={setupInfo.type}
                            >
                                {typeOptions.map((option, idx) => {
                                    return (
                                        <MenuItem key={idx} value={option}>
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Stack>
                        {setupInfo.type !== '' && projectTypeOptions[setupInfo.type].length !== 0 && (
                            <Stack spacing={1}>
                                <Typography>{COPY.subtype}</Typography>
                                <Select
                                    size="small"
                                    variant="filled"
                                    hiddenLabel
                                    displayEmpty
                                    sx={{
                                        maxWidth: breakpoints.mobile ? '100%' : '24rem',
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            backgroundColor: colorBackgroundLight, // no color in pallete
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleSubtypeChange(e.target.value);
                                    }}
                                    value={setupInfo.subtype}
                                >
                                    {projectTypeOptions[setupInfo.type].map((option, idx) => {
                                        return (
                                            <MenuItem key={idx} value={option}>
                                                {option}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Stack>
                        )}
                    </Stack>
                </>
            ),
        },
        {
            errors: (
                <div className="error trades-error">{showErrors && submitErrors['trades']}</div>
            ),
            label: COPY.project_creation_trades_question,
            jsx: (
                <>
                    <MultiCheck
                        checkboxes={tradesState}
                        onChange={setTradesState}
                        disabled={shouldBeDisabled}
                    />
                    <div className="all-trades-section">
                        <Checkbox
                            id="all-trades"
                            key="all-trades"
                            isChecked={allTradesChecked}
                            onChange={checkAllTrades}
                            disabled={shouldBeDisabled}
                        >
                            {COPY.project_all_trades_text}
                        </Checkbox>
                    </div>
                </>
            ),
        },
        {
            errors: (
                <div className="error trades-error">{showErrors && submitErrors['wageType']}</div>
            ),
            label: COPY.project_creation_wage_type_question,
            jsx: (
                <BigRadioSelect
                    disabled={shouldBeDisabled}
                    value={wageType && wageTypeToReadable(wageType)}
                    onChange={(wt: string): void => setWageType(readableWageTypeToWageType(wt))}
                    options={Object.values(WageType).map((wt) => wageTypeToReadable(wt))}
                />
            ),
        },
    ];

    return (
        <div className="configuration-form">
            {showReviewLastProject && marketplaceRating && (
                <ProjectReviewModal
                    review={review}
                    onStatusChange={handleReviewStatusChange}
                    onStarChange={handleReviewStarChange}
                    onReviewTagChange={handleReviewTagChange}
                    onCommentChange={handleReviewCommentChange}
                    onPreferenceStatusChange={handlePreferenceStatusChange}
                    onSubmitReview={submitReview}
                    close={() => handleCloseProjectReviewModal()}
                    showReviewLastProject={showReviewLastProject}
                    isSubmitDisabled={!allFieldsFilled}
                />
            )}
            <div className="section">
                <div className="error">{showErrors && submitErrors['projectName']}</div>
                <div className="icon-input">
                    <Input
                        autoFocus={isTitleFocused}
                        id="project_name_input"
                        className="project-name-input"
                        value={projectName}
                        onChange={setProjectName}
                        placeholder={COPY.project_creation_name_placeholder}
                        disabled={loading}
                        required={true}
                        onFocus={handleTitleFocus}
                        onBlur={handleTitleBlur}
                        onKeyUp={handleTitleCommit}
                    />
                    {projectName && isTitleFocused && (
                        <SvgIcon src={CheckIcon} onClick={handleTitleBlur} noInherit={true} />
                    )}
                </div>
                <hr
                    className={clsx('separator-name', {
                        active: isTitleFocused,
                    })}
                />
            </div>
            <div>
                {/** Show form fields for all projects but new SaaS projects */}
                {!(isNew && project.isSaas) && (
                    <>
                        <span className="body-xl">{COPY.project_creation_section_1}</span>
                        {sections.map(({ label, jsx, errors }, i) => (
                            <div key={label} className="section">
                                {errors}
                                <label className="body-l">
                                    {i + 1}. {label}
                                </label>

                                {jsx}
                            </div>
                        ))}
                    </>
                )}

                {!(project.isSaas && isNew) && (
                    <div className="section">
                        <div className="error">{showErrors && submitErrors['files']}</div>
                        <div className="project-file-upload-header">
                            <span className="body-xl">{COPY.project_creation_section_2}</span>
                        </div>
                        <FileManager
                            isSanitized
                            accepted={allowedFileExtensions.join(', ')}
                            disabled={shouldBeDisabled}
                            projectUuid={project?.uuid}
                            handleUploadFilePress={handleUploadFilePress}
                            files={plans}
                            displayTrashBin={true}
                            fileUploadRef={fileUploadRef}
                            onFilesAdd={handleFilesAdd}
                            onFileDownload={handleFileDownload}
                            onFileRemove={handleFileRemove}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
