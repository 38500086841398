/**
 * `BearerToken` provides singleton-style access to the auth bearer token.
 */
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';

export type BearerTokenClaims = {
    ['https://1build.com/info']: string;
};

export type BearerTokenPayload = {
    aud: string[];
    azp: string;
    exp: number;
    iat: number;
    iss: string;
    scope: string;
    sub: string;
} & BearerTokenClaims;

export class BearerToken {
    /*************************************************************************/
    /* Public                                                                */
    /*************************************************************************/
    // getToken provides access to the bearer token.
    public static async getToken(force = false): Promise<string> {
        const bearerToken = BearerToken.get();

        return await bearerToken.getBearerToken(force);
    }

    public static async getUserEmail(): Promise<string | undefined> {
        try {
            const token = await BearerToken.getToken();
            const decoded = jwtDecode<BearerTokenPayload>(token);
            const payload: unknown = JSON.parse(decoded['https://1build.com/info']);

            return String(get(payload, 'email', ''));
        } catch (e) {
            return undefined;
        }
    }

    // waitForToken can be used when we know authentication happened and we want to execute code right after it -
    // there's a chance the localStorage value will still be empty for a little moment there
    public static waitForToken(): void {
        while (!localStorage.getItem('accessToken') && !localStorage.getItem('refreshToken')) {
            setTimeout((): void => this.waitForToken(), 200);
        }
    }

    // setBearerTokenRefresher sets the promise used to refresh the bearer token.
    public static setBearerTokenRefresher(
        refreshBearerToken: () => Promise<void>,
        onError?: () => void
    ): void {
        const bearerToken = BearerToken.get();
        bearerToken.refreshBearerToken = refreshBearerToken;
        bearerToken.onError = onError;
    }

    /*************************************************************************/
    /* Private                                                               */
    /*************************************************************************/
    private static instance: BearerToken;

    private constructor(
        private refreshBearerToken?: () => Promise<void>,
        private onError?: () => void
    ) {}

    // This is wrapped in an IIFE to avoid multiple calls breaking the AWS token
    // That way there's no race condition as only one newCredentials promise
    // is created at a time.
    private resolveFromRefreshToken = ((): (() => Promise<void>) => {
        // We consider credentials expired if the expiry date is 5 seconds from
        // now or sooner.
        // Normally we would use moment, but AWS passes a date object and this is
        // the only operation we use it for, so native is safer and faster.
        const credentialsAreExpired = (credentials: Date): boolean =>
            credentials.getTime() - new Date().getTime() < 5000;

        const getStoredTokens = (): { accessToken?: string; refreshToken?: string } => {
            return {
                accessToken: localStorage.getItem('accessToken') ?? undefined,
                refreshToken: localStorage.getItem('refreshToken') ?? undefined,
            };
        };

        const parseTokenExpiryDate = (token: string): Date => {
            const decoded = jwtDecode<BearerTokenPayload>(token);
            return new Date(decoded.exp * 1000);
        };

        // Here we'll store the promise
        let promise: Promise<void> | undefined;

        return async (): Promise<void> => {
            // If a promise exists, we return it - the code that uses this will either have to wait for it to complete
            // or will immediately receive the result.
            if (promise) {
                return promise;
            }
            return new Promise<void>((resolve, reject) => {
                // We only create a new promise if we managed to get to this point and there the stored credentials
                // either don't exist or are expired.
                const storedTokens = getStoredTokens();
                const bearerToken = BearerToken.get();
                if (
                    (!storedTokens.accessToken ||
                        credentialsAreExpired(parseTokenExpiryDate(storedTokens.accessToken))) &&
                    bearerToken.refreshBearerToken &&
                    storedTokens.refreshToken
                ) {
                    promise = bearerToken.refreshBearerToken();
                    promise
                        .then((_) => {
                            const newAccessToken = localStorage.getItem('accessToken');
                            if (!newAccessToken) {
                                reject();
                                return;
                            }
                            // We will destroy the promise the moment the token expires. That way any code that tries to
                            // getCredentials at that point will go through the non-memoized flow again, possibly
                            // triggering a new promise. This is only gonna matter for people who keep their tab open
                            // for a long time. If they refresh, the memoized promise is gonna get lost in time and they
                            // will rely on the localStorage values.
                            const timeout =
                                parseTokenExpiryDate(newAccessToken).getTime() - Date.now();
                            setTimeout(() => {
                                promise = undefined;
                            }, timeout);
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                            promise = undefined;
                        });
                    return;
                } else if (!storedTokens.accessToken) {
                    reject();
                } else {
                    resolve();
                }
            });
        };
    })();

    private getBearerToken(force = false): Promise<string> {
        return new Promise<string>((resolve) => {
            const bearerToken = BearerToken.get();
            if (force) {
                localStorage.removeItem('accessToken');
            }
            bearerToken
                .resolveFromRefreshToken()
                .then((_) => {
                    const newAccessToken = localStorage.getItem('accessToken');
                    if (newAccessToken) {
                        resolve(newAccessToken);
                    }
                })
                .catch((_) => {
                    if (bearerToken.onError) {
                        bearerToken.onError();
                    }
                });
        });
    }

    // get provides access to the BearerToken instance.
    private static get(): BearerToken {
        if (!BearerToken.instance) {
            BearerToken.instance = new BearerToken();
        }
        return BearerToken.instance;
    }
}
