/// <reference types="google.maps" />
import { useEffect, useState } from 'react';

import { Setter } from '../../../../../common/types';

interface UseFormattedAddressPayload {
    address: google.maps.places.PlaceResult | null | undefined;
    setAddress: Setter<google.maps.places.PlaceResult | null | undefined>;
    formattedAddress: string | undefined;
    setFormattedAddress: Setter<string | undefined>;
}
export const useFormattedAddress = (
    initialAddress: string | undefined
): UseFormattedAddressPayload => {
    const [address, setAddress] = useState<google.maps.places.PlaceResult | null | undefined>();
    const [formattedAddress, setFormattedAddress] = useState<string | undefined>(initialAddress);
    // Overwrite formatted address on valid input change
    useEffect(() => {
        if (address !== undefined) {
            setFormattedAddress(address?.formatted_address);
        }
    }, [address]);

    return {
        address,
        setAddress,
        formattedAddress,
        setFormattedAddress,
    };
};
