import { useHistory } from 'react-router-dom';

interface Options {
    fallback?: string;
}

interface State {
    referrer?: string;
}

export const useReferrer = (options?: Options) => {
    const history = useHistory<State>();

    return history.location.state?.referrer || options?.fallback || '/';
};
