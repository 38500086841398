import React, { FC, useCallback, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import { VariableSizeList } from 'react-window';
import { GeometryListItem } from './GeometryListItem';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { IMarkupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';
import { extractMarkupsFromMarkupEntries } from '@/common/utils/geometries/markup';

export interface GeometryListProps {
    disabled?: boolean;
    emptyComponent?: React.ReactElement;
    listItems?: IMarkupEntry[];
    markupEntries?: IMarkupEntry[];
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onCreateGroupFromMarkup?: (markup: IMarkupFragment) => void;
    onDeleteMarkupGroup: (markupGroupID: string) => Promise<void>;
    onHeightChange?: (options: { total: number }) => void;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onRemoveMarkupsFromGroup: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    mode?: string;
    selectedMarkupEntryIds?: string[];
    height: number;
}

export const GeometryList: FC<GeometryListProps> = ({
    disabled,
    emptyComponent,
    listItems,
    markupEntries,
    mode,
    onAddMarkupToGroup,
    onCreateGroupFromMarkup,
    onDeleteMarkupGroup,
    onListItemClick,
    onListItemNameBlur,
    onRemoveMarkupsFromGroup,
    selectedMarkupEntryIds,
    height,
    ...props
}) => {
    const sizeMap = useRef<Record<number | string, number | string>>({});
    const listRef = useRef<VariableSizeList | null>(null);

    const itemCount = (listItems ?? []).length;

    const getSelectedMarkupIndex = () => {
        if (!markupEntries) {
            return;
        }
        const markups = extractMarkupsFromMarkupEntries(markupEntries);
        const markupIndex = markupEntries.findIndex(
            (markupEntry) => markupEntry.id === markups.find((markup) => markup.isSelected)?.id
        );
        return markupIndex >= 0 ? markupIndex : undefined;
    };

    const selectedMarkupIndex = getSelectedMarkupIndex();

    const getSize = (index: number) => {
        return Number(sizeMap.current[index]) || 80;
    };

    const setSize = useCallback(
        (index: number | null, size: number) => {
            if (sizeMap.current && listRef.current) {
                const initiallyEmpty = JSON.stringify(sizeMap.current) === '{}';
                // We store the pieces of info allowing us to figure out if the underlying list members have changed. If
                // they have, we can clear the sizeMap to make sure the list members popping in their positions don't
                // inherit size from what was previously in that position. If the list member that was expanded wants to -
                // it will just call `setSize` again.
                if (
                    itemCount > 0 &&
                    index === null &&
                    (sizeMap.current['firstElement'] !== listItems?.[0].id ||
                        sizeMap.current['listCount'] !== itemCount)
                ) {
                    sizeMap.current = {};
                } else if (index !== null && itemCount > 0) {
                    sizeMap.current = {
                        ...sizeMap.current,
                        firstElement: listItems?.[0].id || '',
                        listCount: itemCount,
                        [index]: size,
                    };
                }
                if (!initiallyEmpty) {
                    listRef.current.resetAfterIndex(index || 0);
                }
            }
        },
        [listItems?.[0]?.id, itemCount]
    );

    useEffect(() => {
        // When the input list changes in any way, we want to trigger the setSize so that the sizeMap is properly
        // cleaned up. We can't do it directly here because that could cause a race-condition, where the new children
        // have already called `setSize` before this `useEffect` even ran.
        setSize(null, 0);
    }, [listItems?.[0]?.id, itemCount]);

    useEffect(() => {
        if (selectedMarkupIndex === undefined) {
            return;
        }

        listRef.current?.scrollToItem(selectedMarkupIndex);
    }, [selectedMarkupIndex]);

    return (
        <List disablePadding>
            <VariableSizeList
                {...props}
                ref={listRef}
                height={height}
                itemCount={itemCount}
                itemSize={getSize}
                itemData={{
                    disabled,
                    emptyComponent,
                    listItems,
                    markupEntries,
                    mode,
                    onAddMarkupToGroup,
                    onCreateGroupFromMarkup,
                    onDeleteMarkupGroup,
                    onListItemClick,
                    onListItemNameBlur,
                    onRemoveMarkupsFromGroup,
                    selectedMarkupEntryIds,
                    height,
                    setSize,
                }}
                width="100%"
            >
                {GeometryListItem}
            </VariableSizeList>
        </List>
    );
};
