import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: linear-gradient(142.93deg, #ffffff 13.42%, #000000 97.31%), #334ad7;
    background-blend-mode: soft-light, normal;
    height: 7rem;
    border-radius: 50%;
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
`;

export const Avatar: FC = ({ children }) => {
    return <Container>{children}</Container>;
};
