import { Env } from '@/common/env';
import { BearerToken } from '@/common/urql/bearerToken';
import { Bugsnag } from '@/components/app/Bugsnag';

interface AWSCredentials {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expireTime: Date;
}

// These keys are used to index Cognito credentials in local storage.
const storageKeys = {
    accessKeyId: 'aws_cognito_access_key_id',
    secretAccessKey: 'aws_cognito_secret_access_key',
    sessionToken: 'aws_cognito_session_token',
    expireTime: 'aws_cognito_expire_time',
};

// Save Cognito credentials to local storage.
const storeCredentials = (credentials: AWSCredentials): AWSCredentials => {
    // Though the keys of `credentials` and `localStorageKeys`,
    // are the same, we can't set these in a loop because TypeScript
    // doesn't know that the key access is safe.
    localStorage.setItem(storageKeys.accessKeyId, credentials.accessKeyId);
    localStorage.setItem(storageKeys.secretAccessKey, credentials.secretAccessKey);
    localStorage.setItem(storageKeys.sessionToken, credentials.sessionToken);
    localStorage.setItem(storageKeys.expireTime, JSON.stringify(credentials.expireTime));
    return credentials;
};

// Fetch new Cognito credentials and save them to local storage.
const newCredentials = async (): Promise<AWSCredentials> => {
    const [AWS, token] = await Promise.all([
        import(/* webpackChunkName: 'AWS' */ 'aws-sdk'),
        BearerToken.getToken(),
    ]);

    AWS.config.region = Env.awsCognitoPoolID.split(':')[0];
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: Env.awsCognitoPoolID,
        Logins: {
            [Env.auth0Issuer]: token,
        },
    });

    return new Promise((resolve, reject) => {
        // AWS has poor type annotations, so we have to typecast this even
        // though we just defined it.
        //  AWS.config.credentials.* only exists inside `get` callback.
        (AWS.config.credentials as AWS.Credentials).get(() => {
            // Due to further poor type annotations, we need to cast
            // the credentials again to fetch the expiration time. This
            // is not needed for the other props, which exist on both
            // `AWS.Credentials` and `AWS.CredentialsOptions`.
            const expireTime = (AWS.config.credentials as AWS.Credentials)?.expireTime;

            if (
                !AWS.config.credentials?.accessKeyId ||
                !AWS.config.credentials?.secretAccessKey ||
                !AWS.config.credentials?.sessionToken ||
                !expireTime
            ) {
                const credentialErr = new Error('Failed to retrieve AWS credentials.');
                Bugsnag?.notify(credentialErr);
                reject(credentialErr);
                return;
            }
            resolve(
                storeCredentials({
                    accessKeyId: AWS.config.credentials.accessKeyId,
                    secretAccessKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken,
                    expireTime: expireTime,
                })
            );
        });
    });
};

// This is wrapped in an IIFE to avoid multiple calls breaking the AWS token
// That way there's no race condition as only one newCredentials promise
// is created at a time.
export const getCredentials = ((): (() => Promise<AWSCredentials>) => {
    // We consider credentials expired if the expiry date is 5 seconds from
    // now or sooner.
    // Normally we would use moment, but AWS passes a date object and this is
    // the only operation we use it for, so native is safer and faster.
    const credentialsAreExpired = (credentials: AWSCredentials): boolean =>
        credentials.expireTime.getTime() - new Date().getTime() < 5000;

    // Load Cognito credentials from local storage.
    const loadStoredCredentials = (): AWSCredentials | null => {
        const accessKeyId = localStorage.getItem(storageKeys.accessKeyId);
        const secretAccessKey = localStorage.getItem(storageKeys.secretAccessKey);
        const sessionToken = localStorage.getItem(storageKeys.sessionToken);
        const expireString = localStorage.getItem(storageKeys.expireTime);
        if (!accessKeyId || !secretAccessKey || !sessionToken || !expireString) {
            return null;
        }
        return {
            accessKeyId,
            secretAccessKey,
            sessionToken,
            expireTime: new Date(JSON.parse(expireString)),
        };
    };

    // Here we'll store the promise
    let promise: Promise<AWSCredentials> | undefined;

    return async (): Promise<AWSCredentials> => {
        // If a promise exists, we return it - the code that uses this will either have to wait for it to complete
        // or will immediately receive the result.
        if (promise) {
            return promise;
        }
        return new Promise<AWSCredentials>((resolve, reject) => {
            // We only create a new promise if we managed to get to this point and there the stored credentials
            // either don't exist or are expired.
            const storedCredentials = loadStoredCredentials();
            if (!storedCredentials || credentialsAreExpired(storedCredentials)) {
                promise = newCredentials();
                promise
                    .then((result) => {
                        // We will destroy the promise the moment the token expires. That way any code that tries to
                        // getCredentials at that point will go through the non-memoized flow again, possibly triggering
                        // a new promise. This is only gonna matter for people who keep their tab open for a long time.
                        // If they refresh, the memoized promise is gonna get lost in time and they will rely on the
                        // localStorage values.
                        const timeout = result.expireTime.getTime() - Date.now();
                        setTimeout(() => {
                            promise = undefined;
                        }, timeout);
                        resolve(result);
                    })
                    .catch((e) => {
                        reject(e);
                        promise = undefined;
                    });
                return;
            }
            resolve(storedCredentials);
        });
    };
})();
