import React, { FC, useRef, useEffect, FocusEvent } from 'react';

import { PanelContainer, PanelHeader, PanelBody, PanelWrapper } from './styled';

interface RightSidePanelProps {
    closePanel?: () => void;
    isPanelOpened: boolean;
    panelHeaderChildren?: JSX.Element;
}

export const RightSidePanel: FC<RightSidePanelProps> = ({
    closePanel,
    isPanelOpened = false,
    panelHeaderChildren,
    children,
}) => {
    const panelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isPanelOpened) {
            panelRef.current?.focus();
        }
    }, [isPanelOpened]);

    /**
     * Calls closePanel callback only if the event is not triggered by a related target.
     * Example: when a button within the panel is clicked, onBlur will be triggered,
     * but we don't want to call closePanel() in that case.
     */
    const onBlur = (e: FocusEvent<HTMLDivElement>): void => {
        if (!e.relatedTarget && closePanel) {
            closePanel();
        }
    };

    const onWrapperClick = (): void => {
        panelRef.current?.focus();
    };

    const renderPanel = (
        <PanelContainer ref={panelRef} tabIndex={0} onBlur={onBlur} open={isPanelOpened}>
            <PanelHeader>{panelHeaderChildren}</PanelHeader>
            <PanelBody>{children}</PanelBody>
        </PanelContainer>
    );

    return closePanel ? (
        <PanelWrapper show={isPanelOpened} onClick={onWrapperClick}>
            {renderPanel}
        </PanelWrapper>
    ) : (
        renderPanel
    );
};
