import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { StorageKey } from '@/common/storage';
import { useLocalStorage } from '@rehooks/local-storage';

export function useGetQueryParams() {
    const location = useLocation();

    return {
        getQueryParamByName: useCallback(
            (param: string) => queryString.parse(location.search)[param],
            [location.search]
        ),
        getAllQueryParams: useCallback(() => queryString.parse(location.search), [location.search]),
    };
}

export function useSaveQueryParams() {
    const { getAllQueryParams } = useGetQueryParams();

    const [, setQueryParams] = useLocalStorage(StorageKey.QueryParams);

    useEffect(() => {
        const queryParams = getAllQueryParams();

        setQueryParams(JSON.stringify(queryParams));
    }, [getAllQueryParams, setQueryParams]);
}

export function getSavedQueryParams() {
    const rawQueryParams = localStorage.getItem(StorageKey.QueryParams);
    return rawQueryParams ? (JSON.parse(rawQueryParams) as Record<string, string>) : {};
}

export enum QueryParams {
    PaymentSetupStatus = 'payment_setup_status',
}

export enum PaymentSetupStatusValues {
    Succeed = 'succeed',
}
