/**
 * `Subscription` provides singleton-style access to the graphql subscription.
 */
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ConnectionParams } from 'subscriptions-transport-ws/dist/client';
import { Exchange, subscriptionExchange } from 'urql';

import { BearerToken } from './bearerToken';

import { Env, postgraphileWebsocketURL } from '@/common/env';

export class Subscription {
    /*************************************************************************/
    /* Public                                                                */
    /*************************************************************************/
    // get provides access to the Subscription instance.
    public static get(): Subscription {
        if (!Subscription.instance) {
            Subscription.instance = new Subscription();
        }
        return Subscription.instance;
    }

    // getClient provides access to the Subscription client.
    public static getClient(): SubscriptionClient {
        return Subscription.get().client;
    }

    // getExchange provides access to the Subscription exchange.
    public static getExchange(): Exchange {
        return Subscription.get().exchange;
    }

    /*************************************************************************/
    /* Private                                                               */
    /*************************************************************************/
    private static instance: Subscription;

    private client: SubscriptionClient;
    private exchange: Exchange;

    private constructor() {
        this.client = Subscription.buildSubscriptionClient();
        this.exchange = subscriptionExchange({
            forwardSubscription: (operation) => this.client.request(operation),
        });
    }

    private static buildSubscriptionClient(): SubscriptionClient {
        const client = new SubscriptionClient(postgraphileWebsocketURL(), {
            reconnect: true,
            connectionParams: async (): Promise<ConnectionParams> => {
                const token = await BearerToken.getToken();
                return { Authorization: `Bearer ${token}` };
            },
        });

        if (Env.tier.isTest) {
            client.close();
        }

        return client;
    }
}
