import { FillRule, genCSSPolygon } from '@/common/css/polygon';
import styled from 'styled-components';

const trianglePolygon = genCSSPolygon(
    [
        { x: 0, y: 0 },
        { x: 5, y: 5 },
        { x: 0, y: 5 },
    ],
    FillRule.Nonzero
);

export const CaretWrapper = styled.div({
    gridArea: 'caret',
    zIndex: 15,
    width: '1vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const EllipsisWrapper = styled.div({
    color: '#bbbad9',
    position: 'absolute',
    left: '0.25rem',
    width: '2rem',
    height: '2rem',
    zIndex: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const Caret = styled.span<{ open: boolean }>(({ open }) => ({
    backgroundColor: '#1B2235',
    clipPath: trianglePolygon,
    width: '5px',
    height: '5px',
    transform: open ? 'rotate(-45deg)' : 'rotate(135deg)',
}));

const cellGrid = `
" .       content  .       caret  .       " auto
/ 0.5rem  1fr      1.5rem  auto   0.75rem `;

export interface CellContainer {
    caretVisible?: boolean;
    hasClickAction?: boolean;
    rowIsHovered?: boolean;
    rowIsSelected?: boolean;
}

export const CellContainer = styled.div<CellContainer>(
    ({ caretVisible, hasClickAction, rowIsHovered, rowIsSelected }) => ({
        display: 'grid',
        gridTemplate: cellGrid,
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#f6f8f8',
        height: '3rem',
        whiteSpace: 'nowrap',
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1rem',
        color: '#000000',
        cursor: hasClickAction ? 'pointer' : 'default',
        zIndex: 1 + (rowIsSelected ? 3 : 0),
        [CaretWrapper]: {
            visibility: caretVisible ? 'visible' : 'hidden',
        },
        '::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: -1,
            bottom: -1,
            left: -1,
            backgroundColor: rowIsHovered ? 'rgba(187, 186, 217, 0.07)' : 'transparent',
            boxSizing: 'border-box',
            border: '1px solid #D7E1E5',
            borderTop: 'none',
        },
        '::after': {
            content: "''",
            position: 'absolute',
            top: -2,
            right: -1,
            bottom: -2,
            left: -1,
            borderTop: rowIsSelected ? '2px solid #166FF3' : 'none',
            borderBottom: rowIsSelected ? '2px solid #166FF3' : 'none',
        },
        '&:hover': {
            zIndex: 2 + (rowIsSelected ? 3 : 0),
            '::before': {
                content: "''",
                position: 'absolute',
                top: -1,
                right: -1,
                bottom: -1,
                left: -1,
                boxSizing: 'border-box',
                border: '1px solid #BBBAD9',
            },
            [Caret]: {
                visibility: 'visible',
            },
        },
    })
);

export interface CellContent {
    highlighted: boolean;
}

export const CellContent = styled.div<CellContent>(({ highlighted }) => ({
    gridArea: 'content',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'stretch',
    height: '2rem',
    padding: '0 0.5rem',
    backgroundColor: highlighted ? '#FFFFFF' : 'transparent',
}));

export const RatesContainer = styled.div({
    color: '#000',
    padding: 16,
    background: '#FFFFFF',
    boxShadow: '0px 16px 36px -5px rgba(0, 0, 0, 0.25)',
    borderRadius: '0.25rem',
});

export const RatesHeader = styled.div({
    borderBottom: '1px solid rgba(187, 186, 217, 0.3)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
});

export const RatesHeaderSubtitle = styled.div({
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.6,
});

export const RateBlock = styled.div({
    borderBottom: '1px solid rgba(187, 186, 217, 0.3)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',

    ':last-of-type': {
        borderBottom: 'none',
    },
});

export const Title = styled.div({
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5,
});

export const Description = styled.div({
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.6,
    whiteSpace: 'normal',
    width: 180,
});

export const LargeDescription = styled.div({
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.6,
    whiteSpace: 'pre-line',
    width: 270,
});

export const RateInputWrapper = styled.div({
    alignItems: 'center',
    display: 'flex',
});

export const RateSymbol = styled.div({
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 16,
    fontWeight: 600,
    marginRight: 6,
    opacity: 0.4,
});

export const RateInput = styled.input({
    height: 32,
    width: 90,
    border: 'none',
    outline: 'none',
    padding: '0 0.5rem',
    margin: 0,
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    lineHeight: '2.5rem',
    color: '#000000',
    backgroundColor: '#EFF1F4',
    borderRadius: '0.125rem',
});

export const UomSelectionWrapper = styled.div({
    position: 'absolute',
    top: '3rem',
    right: '0',
});

export const UomSelectionContainer = styled.div({
    display: 'grid',
    gridTemplate: '1.5rem / 10rem',
    gap: '1px 0',
    justifyItems: 'stretch',
    maxHeight: '16rem',
    overflow: 'hidden overlay',
    border: '1px solid #166FF3',
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    backgroundColor: '#BBBAD9',
    color: '#FFFFFF',
    borderRadius: '0.25rem',
    '&::-webkit-scrollbar': {
        width: '0.5rem',
    },
    '&::-webkit-scrollbar-button': {
        height: 0,
        background: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.7)',
        backgroundClip: 'padding-box',
        border: '0.125rem solid transparent',
        borderRadius: '0.25rem',
    },
});

export const UomSelectionOption = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#384880',
    padding: '0.375rem 0.5rem',
});

const formulaEditorGrid = `
" .        .       .       " 2.625rem
" .        editor  .       " 4.5rem
" .        .       .       " 0.25rem
/ 0.25rem  24rem   0.25rem `;

export const FormulaEditorWrapper = styled.div({
    position: 'absolute',
    top: '3.125rem',
    left: '-0.375rem',
});

export const FormulaEditorContainer = styled.div({
    display: 'grid',
    gridTemplate: formulaEditorGrid,
    backgroundColor: '#FFFFFF',
    fontFamily: 'proxima-nova, sans-serif',
    borderRadius: '0.5rem',
    boxShadow: '0px 7px 24px -5px rgba(0, 0, 0, 0.25)',
});

export const FormulaEditorInput = styled.textarea({
    gridArea: 'editor',
    border: 'none',
    outline: 'none',
    margin: 0,
    padding: '0.5rem',
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#000000',
    borderRadius: '0px 0px 4px 4px',
    backgroundColor: '#F0F4F9',
});

const markupQuantityContainerGrid = `
" icon  .        value  .         unit " auto
/ auto  0.25rem  auto   0.125rem  auto `;

export const MarkupQuantityContainer = styled.div({
    position: 'relative',
    display: 'grid',
    gridTemplate: markupQuantityContainerGrid,
    alignItems: 'center',
});

export const MarkupQuantityTooltip = styled.div({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '-3rem',
    left: '0',
    height: '2.5rem',
    padding: '0 0.75rem',
    fontWeight: 400,
    fontSize: '11px',
    backgroundColor: '#17203C',
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '0.25rem',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
});

export const MarkupQuantityIcon = styled.span({
    gridArea: 'icon',
});

export const MarkupQuantityUnit = styled.span({
    gridArea: 'unit',
});

export const MarkupQuantityValue = styled.span({
    gridArea: 'value',
});
