import React, { FC, useCallback, useEffect, useState } from 'react';

import { Popover } from './Popover';
import { DesktopOnly } from './Popover/DesktopOnly';
import { NotStarted } from './Popover/NotStarted';
import { useScreenSize, ScreenSize } from '@/common/hooks/useScreenSize';
import { DatabaseProjectStatus } from '@/common/types';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';

enum ModalState {
    Marketplace,
    Mobile,
}

/**
 * Determine what modal, if any, should be presented.
 * @param {DatabaseProjectStatus | undefined} projectStatus - current project status in database
 * @param {boolean} isSaasProject - if project is a DIY SaaS project
 * @param {boolean} isBuilder - if user is a builder or builder-estimator
 * @param {boolean} isMobile - if view is mobile
 * @returns {ModalState | null} current modal state if valid, otherwise null for no modal.
 */
const getModalState = (
    projectStatus: DatabaseProjectStatus | undefined,
    isSaasProject: boolean,
    isBuilder: boolean,
    isMobile: boolean
): ModalState | null => {
    // Only builders will see modals
    if (isBuilder) {
        if (!isSaasProject && projectStatus === DatabaseProjectStatus.DRAFT) {
            return ModalState.Marketplace;
        }

        if (isSaasProject && isMobile) {
            return ModalState.Mobile;
        }
    }

    return null;
};

interface ModalsProps {
    projectStatus?: DatabaseProjectStatus;
    isSaasProject: boolean;
}
export const Modals: FC<ModalsProps> = ({ projectStatus, isSaasProject }) => {
    // State
    /*----------------*/
    const {
        data: { user },
    } = useUser();
    const screenSize = useScreenSize();

    const [modalState, setModalState] = useState<ModalState | null>(
        getModalState(
            projectStatus,
            isSaasProject,
            user.roles.includes(IUserRole.Builder),
            screenSize === ScreenSize.Mobile
        )
    );

    // Effects
    /*----------------*/
    // This effect is mainly for screen resizes, the other properties
    // will most likely not change.
    useEffect(() => {
        setModalState(
            getModalState(
                projectStatus,
                isSaasProject,
                user.roles.includes(IUserRole.Builder),
                screenSize === ScreenSize.Mobile
            )
        );
    }, [projectStatus, isSaasProject, user, screenSize]);

    // Helpers
    /*----------------*/
    // Wrap in callback to prevent re-render in popover
    const reset = useCallback(() => setModalState(null), []);

    return (
        <>
            {modalState === ModalState.Marketplace && (
                <Popover reset={reset}>
                    <NotStarted reset={reset} />
                </Popover>
            )}

            {modalState === ModalState.Mobile && (
                <Popover reset={reset}>
                    <DesktopOnly reset={reset} />
                </Popover>
            )}
        </>
    );
};
