import React, { FC } from 'react';
import styled from 'styled-components';

import { DivProps } from '@/common/types';
import { colorTypographyDark } from '@/variables';

export enum StatusVariant {
    Standard,
    Blocky,
}

type StatusProps = DivProps & {
    color?: string;
    variant?: StatusVariant;
};

export const Status: FC<StatusProps> = ({
    variant = StatusVariant.Standard,
    color = colorTypographyDark,
    children,
}) => {
    return (
        <Container color={color} variant={variant}>
            {variant === StatusVariant.Blocky && <span />}
            <p>{children}</p>
        </Container>
    );
};

const Container = styled.div<{ color: string; variant: StatusVariant }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 1.5rem;
    border: ${(props): string => (props.variant === StatusVariant.Blocky ? '1px' : '0')} solid
        #e7f1f5;
    border-radius: 0.25rem;
    padding-right: 0.875rem;
    white-space: nowrap;

    p {
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        color: ${(props): string =>
            props.variant === StatusVariant.Blocky ? colorTypographyDark : props.color};
    }

    span {
        width: 0.375rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        border-radius: 0.25rem 0 0 0.25rem;
        background-color: ${(props): string => props.color};
        border: 1px solid ${(props): string => props.color};
    }
`;
