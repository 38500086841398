import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import { loadSnapshot } from '@/common/utils/geometries/snapshots';
import {
    base64ViewportObservable,
    boundaryDataObservable,
    editedGeometryObservable,
} from '@/components/takeoff/observables/helpers';
import { LatLng, LatLngBounds, LatLngExpression } from 'leaflet';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { PlanPage } from '@/common/types';
import { useQuery } from 'urql';
import { PlanRecords } from '@/queries/plans';
import { PlanPagesQuery } from '@/queries/planPages';
import { useProjectPlanPageSubscription } from '@/subscriptions/projectPlanPages';
import { IMarkupFragment, useProjectWithMarkupEntriesQuery } from '@/graphql';
import { extractMarkupsFromMarkupEntries } from '@/common/utils/geometries/markup';
import { selectedMarkupIDs } from '@/common/apollo';
import { getValidPlanPages } from '@/components/takeoff/hooks/useProjectHasFiles';

export const usePageNavigation = ({ useTakeoff }: TakeoffComponentProps) => {
    const [pages, setPages] = useState<PlanPage[]>([]);
    const {
        project: { uuid: projectUUID, id: projectID },
        currentPage,
        setCurrentPage,
        setCurrentPageId,
        setLoadingCurrentPage,
        setRightClickedPageId,
        mapRef,
        loadingCurrentPage,
    } = useTakeoff();

    const projectWithMarkupEntriesResult = useProjectWithMarkupEntriesQuery({
        variables: {
            uuid: projectUUID,
        },
        skip: !projectUUID,
    });

    // markups contains all markups in the active project.
    // When markups is null, the markups have not loaded.
    const markups = useMemo<IMarkupFragment[] | null>(() => {
        const markupEntries = projectWithMarkupEntriesResult.data?.project.markupEntries;
        if (!markupEntries) {
            return null;
        }
        return extractMarkupsFromMarkupEntries(markupEntries);
    }, [projectWithMarkupEntriesResult]);

    const selectedMarkupsPageID = useMemo<string | undefined>(
        () => markups?.find((markup) => markup.isSelected)?.projectPlanPageID,
        [markups]
    );

    const pickPage = useCallback(
        (id: number): void => {
            const newPage = pages.find((page) => page.id === id.toString());
            if (currentPage?.id === id.toString()) return;
            setCurrentPageId(id);
            setCurrentPage(newPage);
            if (newPage && newPage.pageId !== currentPage?.pageId) {
                setLoadingCurrentPage(true);
            }
            if (!newPage) {
                geometriesObservable.next(null);
                selectedGeometriesObservable.next({ geometries: [], points: [] });
            } else if (!newPage.markups) {
                geometriesObservable.next([]);
                selectedGeometriesObservable.next({ geometries: [], points: [] });
            } else {
                const geometries = loadSnapshot(newPage.markups);
                geometriesObservable.next(geometries);
                // If there are markups, we want to use the cached selection data from markups to make sure anything
                // selected before the page switched is still selected afterwards.
                selectedGeometriesObservable.next({
                    geometries: geometries.filter((geometry) =>
                        selectedMarkupIDs().includes(geometry.uuid)
                    ),
                    points: [],
                });
            }
            editedGeometryObservable.next(null);
            boundaryDataObservable.next([]);

            setRightClickedPageId(null);
            mapRef?.leafletElement.fitBounds(
                new LatLngBounds(
                    new LatLng(0, 0),
                    new LatLng(newPage?.heightPx || 0, newPage?.widthPx || 0)
                )
            );
        },
        // It's a deliberate choice to remove `pages` from this list and replace it with just the length.
        // It's impossible from the UI standpoint to pick a page that isn't rendered, so when this is called, the
        // page will exist. By only using the length, we disregard the updates to this array that happen 30 times a
        // second when someone is drawing and only focus on the actual changes to the contents of it (additions and
        // deletions). If we insist on keeping it as a dep, there will be significant slowdowns all the time to the
        // PageBrowser, since it depends on this function.
        [pages.length, currentPage?.id, currentPage?.pageId]
    );

    useEffect(() => {
        if (selectedMarkupsPageID) {
            pickPage(Number(selectedMarkupsPageID));
        }
    }, [selectedMarkupsPageID]);

    // Sets viewport based on url object when map loads
    useEffect((): void => {
        if (mapRef) {
            let b64Viewport =
                location.search.split('?viewport=')[1] || base64ViewportObservable.value;
            if (b64Viewport) {
                b64Viewport = decodeURIComponent(b64Viewport);
                const based = atob(b64Viewport);
                try {
                    const obj = JSON.parse(based) as {
                        center: LatLngExpression;
                        currentPageId: number;
                        projectUUID?: string;
                        zoom: number;
                    };

                    // If page was removed (it isn't in the list) then do not do anything
                    if (!pages.find((page) => page.id === obj.currentPageId?.toString())) {
                        base64ViewportObservable.next('');
                        return;
                    }

                    if (obj.projectUUID === projectUUID) {
                        pickPage(obj.currentPageId);
                        base64ViewportObservable.next(b64Viewport);
                        mapRef.leafletElement.setView(obj.center, obj.zoom);
                    }
                } catch {
                    base64ViewportObservable.next('');
                }
            }
        }
    }, [mapRef]);

    const [fetchedPlanPages] = useQuery<PlanRecords>({
        query: PlanPagesQuery,
        variables: { projectId: Number(projectID) },
        requestPolicy: 'cache-only',
    });

    useEffect(() => {
        const nonRemovedPlanPages = getValidPlanPages(fetchedPlanPages.data);

        if (nonRemovedPlanPages.length > 0 && currentPage && loadingCurrentPage) {
            return;
        }

        setPages(nonRemovedPlanPages);

        const currentPageIndex = nonRemovedPlanPages.findIndex((p) => p.id === currentPage?.id);
        const index = currentPageIndex !== -1 ? currentPageIndex : 0;

        if (
            nonRemovedPlanPages.length > index &&
            currentPage?.orientation !== nonRemovedPlanPages[index].orientation
        ) {
            const newPage = nonRemovedPlanPages[index];
            setCurrentPage(newPage);
            if (!newPage.markups) {
                geometriesObservable.next([]);
            } else {
                geometriesObservable.next(loadSnapshot(newPage.markups));
            }
        }
    }, [fetchedPlanPages]);

    /* Subscription */
    useProjectPlanPageSubscription();

    return {
        pickPage,
        pages,
    };
};
