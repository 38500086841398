import { useProjectFromParams } from './useProjectFromParams';
import { ApolloClient, executeQuery } from '@/common/apollo/execute';
import { Env } from '@/common/env';
import { SharedEstimatePayload } from '@/common/types';
import { downloadBase64File } from '@/common/utils/helpers';
import { useNotifications } from '@/contexts/Notifications';
import { useStorage } from '@/contexts/Storage';
import {
    EstimateLinkDocument,
    IEstimateLinkQuery,
    IEstimateLinkQueryVariables,
    ILinkType,
    IPdfQuery,
    IPdfQueryVariables,
    IXlsxQuery,
    IXlsxQueryVariables,
    PdfDocument,
    XlsxDocument,
} from '@/graphql';
import { QueryHookOptions, useApolloClient, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const COPY = {
    download_filename: 'shared-estimate',
    xlsx_content_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf_content_type: 'application/pdf',
};

export interface UseShareEstimateRes {
    projectUUID: string | undefined;
    pUUID: string | undefined;
    sharedEstimatePayload: SharedEstimatePayload | undefined;
    fetchLimitedLinkData: (projectId: string) => void;
    fetchDetailedLinkData: (projectId: string) => void;
    downloadPdf: (projectId: string, type: ILinkType) => Promise<void>;
    downloadXslx: (projectId: string, type: ILinkType) => Promise<void>;
    limitedLinkData: string | undefined;
    detailedLinkData: string | undefined;
    limitedLinkExists: boolean;
    detailedLinkExists: boolean;
    isDownloadingPdf: boolean;
    isDownloadingExcel: boolean;
}

export const useShareEstimate = (): UseShareEstimateRes => {
    const client = useApolloClient();
    const { bucket } = useStorage();
    const { project } = useProjectFromParams();
    const { addNotification } = useNotifications();

    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

    const [sharedEstimatePayload, setSharedEstimatePayload] = useState<SharedEstimatePayload>();
    const [limitedLinkExists, setLimitedLinkExists] = useState<boolean>(false);
    const [detailedLinkExists, setDetailedLinkExists] = useState<boolean>(false);
    const [limitedLinkData, setLimitedLinkData] = useState<string>('');
    const [detailedLinkData, setDetailedLinkData] = useState<string>('');
    const projectUUID = window.location.pathname.split('/')?.[2];
    const pUUID = window.location.pathname.split('/')?.[4];

    const estimateLinkInput = (
        id: string,
        type: ILinkType
    ): QueryHookOptions<IEstimateLinkQuery, IEstimateLinkQueryVariables> => ({
        variables: {
            input: {
                projectId: id,
                type,
            },
        },
    });

    const generateDownloadFileName = (): string => {
        return !project || !project.name
            ? COPY.download_filename
            : project.name.toLowerCase().replace(/\s+/g, '-').replace(/\./g, '');
    };

    const [fetchDetailedEstimateLink] = useLazyQuery<
        IEstimateLinkQuery,
        IEstimateLinkQueryVariables
    >(EstimateLinkDocument, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setDetailedLinkData(data.estimateLink.link || '');
        },
    });

    const [fetchLimitedEstimateLink] = useLazyQuery<
        IEstimateLinkQuery,
        IEstimateLinkQueryVariables
    >(EstimateLinkDocument, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setLimitedLinkData(data.estimateLink.link || '');
        },
    });

    const handleGeneratePdf = async (
        client: ApolloClient,
        projectId: string,
        type: ILinkType
    ): Promise<string> => {
        const result = await executeQuery<IPdfQuery, IPdfQueryVariables>(client, {
            query: PdfDocument,
            variables: {
                input: {
                    projectId,
                    type,
                },
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        return result.pdf.blob;
    };

    const handleGenerateXlsx = async (
        client: ApolloClient,
        projectId: string,
        type: ILinkType
    ): Promise<string> => {
        const result = await executeQuery<IXlsxQuery, IXlsxQueryVariables>(client, {
            query: XlsxDocument,
            variables: {
                input: {
                    projectId,
                    type,
                },
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        });

        return result.xlsx.blob;
    };

    useEffect(() => {
        if (pUUID && projectUUID) {
            fetch(
                `https://${bucket}.s3.${Env.awsPlanBucketRegion}.amazonaws.com/projects/${projectUUID}/${pUUID}.json`
            )
                .then((data) => data.json())
                .then((payload: SharedEstimatePayload) => {
                    setSharedEstimatePayload({
                        ...payload,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (limitedLinkData !== '') {
            setLimitedLinkExists(true);
        }
    }, [limitedLinkData]);

    useEffect(() => {
        if (detailedLinkData !== '') {
            setDetailedLinkExists(true);
        }
    }, [detailedLinkData]);

    const fetchLimitedLinkData = (projectId: string): void => {
        fetchLimitedEstimateLink(estimateLinkInput(projectId, ILinkType.Limited));
    };

    const fetchDetailedLinkData = (projectId: string): void => {
        fetchDetailedEstimateLink(estimateLinkInput(projectId, ILinkType.Detailed));
    };

    const downloadPdf = async (projectId: string, type: ILinkType): Promise<void> => {
        try {
            setIsDownloadingPdf(true);

            const generatedBase64Data = await handleGeneratePdf(client, projectId, type);

            if (!generatedBase64Data) return;

            downloadBase64File({
                contentType: COPY.pdf_content_type,
                base64Data: generatedBase64Data,
                fileName: generateDownloadFileName(),
            });
        } catch {
            addNotification(
                {
                    title: 'Error',
                    content: 'There was a problem generating the .pdf. Please try again',
                },
                'error',
                true
            );
        } finally {
            setIsDownloadingPdf(false);
        }
    };

    const downloadXslx = async (projectId: string, type: ILinkType): Promise<void> => {
        try {
            setIsDownloadingExcel(true);

            const generatedBase64Data = await handleGenerateXlsx(client, projectId, type);

            if (!generatedBase64Data) return;

            downloadBase64File({
                contentType: COPY.xlsx_content_type,
                base64Data: generatedBase64Data,
                fileName: generateDownloadFileName(),
            });
        } catch {
            addNotification(
                {
                    title: 'Error',
                    content: 'There was a problem generating the .xlsx. Please try again',
                },
                'error',
                true
            );
        } finally {
            setIsDownloadingExcel(false);
        }
    };

    return {
        projectUUID,
        pUUID,
        sharedEstimatePayload,
        fetchLimitedLinkData,
        fetchDetailedLinkData,
        downloadPdf,
        downloadXslx,
        limitedLinkData,
        detailedLinkData,
        limitedLinkExists,
        detailedLinkExists,
        isDownloadingPdf,
        isDownloadingExcel,
    };
};
