import { useEstimateCost } from '../hooks/useEstimateCost';
import { useEstimatePriceMarkups } from '../hooks/useEstimatePriceMarkups';
import { useEstimateProject } from '../hooks/useEstimateProject';
import { useEstimateUoms } from '../hooks/useEstimateUoms';
import {
    ButtonContainer,
    Container,
    ExportButton,
    Header,
    LinkContainer,
    LinkCopy,
    LinkHeader,
    LinkText,
    Overlay,
    Subtitle,
} from './styled';
import { ReactComponent as CSV } from '@/assets/icons/estimate/csv.svg';
import { ReactComponent as PDF } from '@/assets/icons/estimate/pdf.svg';
import { TrackEventName, track } from '@/common/analytics';
import { useDocumentListener } from '@/common/hooks/useDocumentListener';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { ExportedEstimatePayload } from '@/common/types';
import { copyToClipboard } from '@/common/utils/clipboard';
import { formatUSD } from '@/common/utils/helpers';
import { useAssemblies } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { calculateElementPrices } from '@/components/Estimate/utils/expression';
import { AssemblyWithElements, EstimateProjectInfo } from '@/components/Estimate/utils/types';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { CenterEllipsis } from '@/components/ui/text/CenterEllipsis';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { IAssemblyType, IUserFragment } from '@/graphql';
import { ProjectRecord } from '@/queries/projects';
import { useApolloClient } from '@apollo/client';
import download from 'downloadjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { FC, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

const COPY = {
    header: 'Export project',
    subtitle:
        'Editing functions and individual markups won’t be visible or available in projects shared through a' +
        ' public URL.',
    pdf: 'Download PDF',
    csv: 'Download CSV',
    publicURL: 'Public URL',
    linkPlaceholder: 'Click "Copy" to generate the link.',
    copy: 'Copy',
};

export type ExportModalProps = {
    close: () => void;
};

type ExportLine = string | undefined | null;
type ExportOptions = {
    type: 'header' | 'row';
};

function prepareDataToExport(
    addLine: (row: ExportLine[], options?: ExportOptions) => void,
    project: ProjectRecord | undefined,
    projectInfo: EstimateProjectInfo,
    user: IUserFragment,
    directCosts: number,
    equipmentPrice: number,
    generalConditionsPrice: number,
    totalMarkups: number,
    totalCost: number,
    uncategorizedAssembly: AssemblyWithElements | null,
    assembliesGroupedByTrade: [string, AssemblyWithElements[]][]
) {
    // project name
    addLine([project?.name]);
    // location
    addLine([projectInfo.projectLocation?.formattedAddress]);
    // team name
    addLine([project?.team?.name]);
    // user name
    addLine([`${user.firstName} ${user.lastName}`]);
    // exported date
    addLine(['Estimate Exported:', new Date().toLocaleString('en-US')]);
    // empty row
    addLine([]);
    // labor + materials total
    addLine(['Direct costs:', formatUSD(directCosts * 100)]);
    // equipment total
    addLine(['Equipment:', formatUSD(equipmentPrice * 100)]);
    // general conditions total
    addLine(['General conditions:', formatUSD(generalConditionsPrice * 100)]);
    // markups total
    addLine(['Markups:', formatUSD(totalMarkups * 100)]);
    // total
    addLine(['Total:', formatUSD((totalCost + totalMarkups) * 100)]);

    // empty row
    addLine([]);

    // header row
    const headers = [
        'Category',
        'Assembly Name',
        'Element',
        'Quantity',
        'UoM',
        'Productivity Rate',
        'Labor Hours',
        'Material Rate',
        'Labor Rate',
        'Material Total',
        'Labor Total',
        'Total',
    ];

    addLine(headers, { type: 'header' });

    if (uncategorizedAssembly) {
        uncategorizedAssembly.elements.forEach((element) => {
            const { quantity, productionRate, laborHours, laborCost, materialCost, totalCost } =
                calculateElementPrices(element);
            const row = [
                '--',
                '--',
                element.name,
                quantity.toFixed(0),
                element.unit.name,
                productionRate.toFixed(3),
                laborHours.toFixed(1),
                formatUSD(element.materialRate),
                formatUSD(element.laborRate),
                formatUSD(materialCost),
                formatUSD(laborCost),
                formatUSD(totalCost),
            ];
            addLine(row, { type: 'row' });
        });
    }

    for (const [tradeName, assemblies] of assembliesGroupedByTrade) {
        for (const assembly of assemblies) {
            if (assembly.assemblyType === IAssemblyType.Item) {
                continue;
            }
            for (const element of assembly.elements ?? []) {
                const { quantity, productionRate, laborHours, laborCost, materialCost, totalCost } =
                    calculateElementPrices(element);
                const row = [
                    tradeName,
                    assembly.description,
                    element.name,
                    quantity.toFixed(0),
                    element.unit.name,
                    productionRate.toFixed(3),
                    laborHours.toFixed(1),
                    formatUSD(element.materialRate),
                    formatUSD(element.laborRate),
                    formatUSD(materialCost),
                    formatUSD(laborCost),
                    formatUSD(totalCost),
                ];
                addLine(row, { type: 'row' });
            }
        }
    }
}

export const ExportModal: FC<ExportModalProps> = ({ close }) => {
    const client = useApolloClient();
    const {
        data: { user },
    } = useUser();
    const [link, setLink] = useState<string>('');
    const {
        total: totalCost,
        direct: directCosts,
        equipment: equipmentPrice,
        generalConditions: generalConditionsPrice,
    } = useEstimateCost();
    const { priceMarkups } = useEstimatePriceMarkups();
    const { projectID, projectInfo } = useEstimateProject();
    const { uoms } = useEstimateUoms();

    const { assembliesGroupedByTrade, uncategorizedAssembly, loading } = useAssemblies({
        projectId: projectID,
        additionalQueryArgs: {
            input: {
                condition: {
                    usedInProject: true,
                },
            },
        },
    });

    const totalMarkups = priceMarkups.reduce(
        (acc, cur) =>
            acc +
            (cur.total ? Number(cur.total) : (totalCost * (Number(cur.percentage) || 0)) / 100),
        0
    );

    const { project } = useProjectFromParams();
    const modalRef = useRef<HTMLDivElement>(null);

    const { uploadPublic } = useStorage();

    useEffect(() => {
        if (!project) {
            return;
        }

        // Kludge removal of any user PII
        const cache = client.cache.extract() as Record<string, unknown>;
        const strippedCache = Object.entries(cache).reduce((acc, [key, value]) => {
            if (!key.includes('User')) {
                return { ...acc, [key]: value };
            }

            return acc;
        }, {});

        const strippedProject = { ...project };
        strippedProject.team = undefined;
        strippedProject.projectUsers = undefined;

        const payload: ExportedEstimatePayload = {
            cache: strippedCache,
            project: strippedProject,
            uoms: uoms,
            created: new Date(),
        };

        const currentPath = window.location.origin + window.location.pathname;
        const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
        const dumpID = uuid();
        uploadPublic(`${project.uuid}/${dumpID}.json`, blob);
        setLink(`${currentPath}?p=${dumpID}`);
    }, [client.cache, project]);

    // Exit on esc
    useDocumentListener(
        'keydown',
        (e) => {
            if (e.key === 'Escape') {
                close();
            }
        },
        [close]
    );

    const handleAndClose = (cb: () => void): (() => void) => {
        return (): void => {
            cb();
            close();
        };
    };

    const downloadPDF = (): void => {
        const generalInfo: Array<Array<string>> = [];

        const data: Array<Array<string>> = [];

        const addLine = (row: ExportLine[], options?: ExportOptions) => {
            const parsedRow = row.map((value) => value ?? '');

            // Right now we surely know that if no options means it is a normal line
            if (!options) {
                generalInfo.push(parsedRow);
                return;
            }

            // With options means is part of the table and data[0] are the headers
            data.push(parsedRow);
        };

        prepareDataToExport(
            addLine,
            project,
            projectInfo,
            user,
            directCosts,
            equipmentPrice,
            generalConditionsPrice,
            totalMarkups,
            totalCost,
            uncategorizedAssembly,
            assembliesGroupedByTrade
        );

        const doc = new jsPDF('l', 'pt', 'letter');

        doc.setFontSize(12);

        let currentYPos = 20;
        generalInfo.forEach((value) => {
            const text = value.join(' ');

            doc.text(text, 10, currentYPos);
            currentYPos += 15;
        });

        jsPDF.autoTableSetDefaults({
            headStyles: { fillColor: 0 },
        });

        autoTable(doc, {
            startY: currentYPos,
            head: [data[0]],
            body: data.slice(1),
        });

        doc.save(`${project?.name || 'export'}.pdf`);
    };

    const downloadCSV = (): void => {
        let csvContent = '';

        const addLine = (row: (string | undefined | null)[]): void => {
            csvContent += `${row
                .map((r) => {
                    if (!r) {
                        return '""';
                    }
                    let result = r
                        .replace(/"/g, '""')
                        // This part is here to make sure we produce an ASCII-only string.
                        // An exception for the letter `ł`, which does not have an ASCII variant defined
                        .replace(/\u0142/g, 'l')
                        // Then we just normalize it
                        .normalize('NFKD')
                        // This cuts off anything that isn't ASCII or special characters we expect
                        .replace(/[^\w\s.,\-_'":$/]/g, '');
                    if (/("|,|\n)/g.exec(result) !== null) result = '"' + result + '"';
                    return result;
                })
                .join(',')}\r\n`;
        };

        prepareDataToExport(
            addLine,
            project,
            projectInfo,
            user,
            directCosts,
            equipmentPrice,
            generalConditionsPrice,
            totalMarkups,
            totalCost,
            uncategorizedAssembly,
            assembliesGroupedByTrade
        );

        download(
            `data:application/csv;base64,${btoa(csvContent)}`,
            `${project?.name || 'export'}.csv`
        );
    };

    const copyShareableLink = async (): Promise<void> => {
        const copied = await copyToClipboard(link);
        if (copied) {
            track(TrackEventName.EstimateLinkCopied, { projectUUID: project?.uuid });
            setLink(link);
        }
    };

    return (
        <>
            <Overlay
                // Exit on click out
                onContextMenu={(e): void => {
                    if (!e.defaultPrevented) {
                        close();
                    }
                }}
                onClick={(e): void => {
                    if (!e.defaultPrevented) {
                        e.preventDefault();
                        close();
                    }
                }}
            >
                <Container
                    ref={modalRef}
                    // Prevent context menu from selecting all text and close from firing
                    onClick={(e): void => e.preventDefault()}
                    onContextMenu={(e): void => e.preventDefault()}
                >
                    <Header>{COPY.header}</Header>
                    <Subtitle>{COPY.subtitle}</Subtitle>
                    <LinkContainer>
                        <LinkHeader>{COPY.publicURL}</LinkHeader>
                        <LinkText>
                            <CenterEllipsis text={link || COPY.linkPlaceholder} />
                        </LinkText>
                        <LinkCopy onClick={copyShareableLink}>{COPY.copy}</LinkCopy>
                    </LinkContainer>
                    <ButtonContainer>
                        {loading ? (
                            <SpinnerLoader />
                        ) : (
                            <>
                                <ExportButton tabIndex={0} onClick={handleAndClose(downloadPDF)}>
                                    <PDF />
                                    <span>{COPY.pdf}</span>
                                </ExportButton>
                                <ExportButton tabIndex={1} onClick={handleAndClose(downloadCSV)}>
                                    <CSV />
                                    <span>{COPY.csv}</span>
                                </ExportButton>
                            </>
                        )}
                    </ButtonContainer>
                </Container>
            </Overlay>
        </>
    );
};
