import React, { FC, useState, useEffect } from 'react';

import { TeamExperiments } from './TeamExperiments';
import { CenteredSpinnerLoader } from '@/components/ui/loaders/CenteredSpinnerLoader';
import { useQueryArray } from '@/common/hooks/useQueryArray';
import { Team, Experiment, ExperimentTeam } from '@/common/types';
import { ExperimentsQuery } from '@/queries/experiments';
import { ExperimentTeamsQuery } from '@/queries/experimentTeams';
import { TeamsQuery } from '@/queries/teams';

const COPY = {
    noExperiments: 'No experiments!',
};

export const Experiments: FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [teams, , teamsFetching] = useQueryArray<Team>(TeamsQuery);
    const [experiments, , experimentsFetching] = useQueryArray<Experiment>(ExperimentsQuery);
    const [experimentTeams, setExperimentTeams, experimentTeamsFetching] =
        useQueryArray<ExperimentTeam>(ExperimentTeamsQuery);

    useEffect(
        () => setIsLoading(teamsFetching || experimentsFetching || experimentTeamsFetching),
        [teamsFetching, experimentsFetching, experimentTeamsFetching]
    );

    const renderTeams = (): JSX.Element => {
        if (experiments.length === 0) {
            return <p>{COPY.noExperiments}</p>;
        }
        return (
            <>
                {teams.map((team) => (
                    <TeamExperiments
                        key={team.id}
                        experiments={experiments}
                        experimentTeams={experimentTeams}
                        setExperimentTeams={setExperimentTeams}
                        team={team}
                    />
                ))}
            </>
        );
    };

    return isLoading ? <CenteredSpinnerLoader /> : renderTeams();
};
