import { colorTypographyDark } from '@/variables';
import styled from 'styled-components';

export const Header = styled.h2`
    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.01em;
`;

////////////////////
//      Body      //
////////////////////
export const BodyContainer = styled.div`
    font-family: 'proxima-nova', sans-serif;
    font-size: 16px;
    margin-bottom: 50px;
`;

export const BodyHeader = styled.p``;
export const BodyPoint = styled.p`
    font-weight: normal;
`;

////////////////////
// Submit section //
////////////////////
export const SubmitButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 4px;
    width: 188px;
    height: 32px;

    color: #fff;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    cursor: pointer;
`;

export const CancelButton = styled(SubmitButton)`
    width: 148px;
    border: 1px solid #4781f7;
    background: white;
    color: #3f6cf7;
`;

export const SubmitContainer = styled.div`
    display: flex;

    & > ${SubmitButton}:first-child {
        margin-left: auto;
    }

    & > ${SubmitButton} {
        margin-left: 12px;
    }
`;

////////////////////////
// Wrapper containers //
////////////////////////
export const Overlay = styled.div`
    cursor: default;

    z-index: 3;

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

type ContainerProps = { hasAspect?: boolean };
export const Container = styled.div<ContainerProps>`
    cursor: default;

    box-sizing: border-box;

    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: ${(props): string => (props.hasAspect ? '38px 20px 16px' : '58px 20px 16px')};

    background: #ffffff;
    box-shadow: 0px 51px 67px rgba(0, 0, 0, 0.07), 0px 14.5916px 12.4842px rgba(0, 0, 0, 0.0503198),
        0px 7.67308px 4.42419px rgba(0, 0, 0, 0.0417275),
        0px 4.5434px 2.49313px rgba(0, 0, 0, 0.035),
        0px 2.74017px 1.89164px rgba(0, 0, 0, 0.0282725),
        0px 1.36904px 1.23029px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    width: 604px;

    & > ${Header} {
        margin-top: 0;
        margin-bottom: 28px;
        max-width: 486px;
    }
`;
