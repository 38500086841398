import React, { useEffect, useState } from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { useMutation } from 'urql';

import { useScreenSize, ScreenSize } from '@/common/hooks/useScreenSize';
import { Setter } from '@/common/types';
import { SetupInfo } from '@/components/projects/ProjectDetails/ProjectToolbars/Details/Panels/SetupPanel';
import { useNotifications } from '@/contexts/Notifications';
import {
    IProjectLocationAssignmentMutation,
    IProjectLocationAssignmentMutationVariables,
    IProjectLocationFragment,
    ProjectLocationAssignmentDocument,
} from '@/graphql';
import { updateProjectSetupInfo } from '@/mutations/updateProjectSetupInfo';
import { ProjectRecord } from '@/queries/projects';

const COPY = {
    setupProjectSuccessTitle: 'Success.',
    setupProjectSuccessMsg: 'Setup information submitted successfully.',
    setupProjectErrorTitle: 'Error.',
    setupProjectErrorMsg: 'Something went wrong.',
};

interface UseTakeoffSetupArguments {
    project?: ProjectRecord;
    projectLocation: IProjectLocationFragment | null;
}

interface UseTakeoffSetupPayload {
    showProjectSetup: boolean;
    setShowProjectSetup: Setter<boolean>;
    setupErrors: SetupInfo;
    setupInfo: SetupInfo;
    handleProjectSetupSubmit: () => Promise<void>;
    setSetupInfo: Setter<SetupInfo>;
    setSetupErrors: Setter<SetupInfo>;
}

export const useTakeoffSetup = ({
    project,
    projectLocation,
}: UseTakeoffSetupArguments): UseTakeoffSetupPayload => {
    const { addNotification } = useNotifications();
    const screenSize = useScreenSize();

    const [showProjectSetup, setShowProjectSetup] = useState<boolean>(
        !project?.isSetupCompleted && !project?.isSaas && screenSize === ScreenSize.Desktop
    );
    const [setupErrors, setSetupErrors] = useState<SetupInfo>({} as SetupInfo);
    const [setupInfo, setSetupInfo] = useState<SetupInfo>({
        subtype: project?.subtype || '',
        type: project?.type || '',
        city: projectLocation?.city ?? null,
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        coordinates: projectLocation?.coordinates ?? null,
        formattedAddress: projectLocation?.formattedAddress ?? null,
        state: projectLocation?.state ?? null,
        streetAddress: projectLocation?.streetAddress ?? null,
        zip: projectLocation?.zip ?? null,
        ...project,
        ...projectLocation,
    });

    const [, updateSetupInfo] = useMutation(updateProjectSetupInfo);
    const [updateProjectLocation] = useApolloMutation<
        IProjectLocationAssignmentMutation,
        IProjectLocationAssignmentMutationVariables
    >(ProjectLocationAssignmentDocument);

    const validateProjectSetup = (): boolean => {
        /*
            Zip code is valid if:
            - not given
            - given and contains of digits like XXXXX or XXXXX-XXXX
        */
        const isZipValid =
            !setupInfo.zip ||
            (setupInfo.zip && /(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/.test(setupInfo.zip));

        setSetupErrors({
            zip: !isZipValid && 'Please enter valid US zip code',
        } as SetupInfo);

        return !!isZipValid;
    };

    const handleProjectSetupSubmit = async (): Promise<void> => {
        if (!validateProjectSetup() || project === null || project === undefined) return;

        setShowProjectSetup(false);

        try {
            const updatedProject = await updateSetupInfo({
                ...setupInfo,
                projectId: project.id,
            });

            const updatedLocation = await updateProjectLocation({
                variables: {
                    input: {
                        projectID: `${project.id}`,
                        streetAddress: setupInfo.streetAddress,
                        city: setupInfo.city,
                        state: setupInfo.state,
                        zip: setupInfo.zip,
                        formattedAddress: setupInfo.formattedAddress,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        coordinates: setupInfo.coordinates,
                    },
                },
            });

            if (updatedProject.data && updatedLocation.data) {
                addNotification(
                    {
                        title: COPY.setupProjectSuccessTitle,
                        content: <p>{COPY.setupProjectSuccessMsg}</p>,
                    },
                    'success'
                );
            } else {
                addNotification(
                    {
                        title: COPY.setupProjectErrorTitle,
                        content: <p>{COPY.setupProjectErrorMsg}</p>,
                    },
                    'error'
                );
            }
        } catch {
            addNotification(
                {
                    title: COPY.setupProjectErrorTitle,
                    content: <p>{COPY.setupProjectErrorMsg}</p>,
                },
                'error'
            );
        }
    };

    useEffect(() => {
        setSetupInfo((setupInfo) => ({ ...setupInfo, ...projectLocation }));
    }, [projectLocation]);

    useEffect(() => {
        setSetupInfo((setupInfo) => ({
            ...setupInfo,
            subtype: project?.subtype || '',
            type: project?.type || '',
        }));
    }, [project]);

    useEffect(() => {
        setShowProjectSetup(
            !project?.isSetupCompleted && !project?.isSaas && screenSize === ScreenSize.Desktop
        );
    }, [project?.isSetupCompleted, project?.isSaas, screenSize]);

    return {
        showProjectSetup,
        setShowProjectSetup,
        setupErrors,
        setupInfo,
        handleProjectSetupSubmit,
        setSetupInfo,
        setSetupErrors,
    };
};
