import gql from 'graphql-tag';

import { makeUseSubscription } from '../frog';
import { projectUploadFileFullFragment, ProjectUploadFileFullRecord } from '../queries/events';

export type ProjectUploadFileMessage = {
    ProjectUploadFile: {
        projectUploadFileEntry?: ProjectUploadFileFullRecord | null;
    };
};

export const projectUploadFileSubscription = projectUploadFileFullFragment.gql`
    subscription {
        ProjectUploadFile {
            projectUploadFileEntry {
                ${projectUploadFileFullFragment}
            }
        }
    }
`;

export const useProjectUploadFileSubscription = makeUseSubscription<ProjectUploadFileMessage>(
    projectUploadFileSubscription
);

export type DeleteProjectUploadFileMessage = {
    DeleteProjectUploadFile: {
        deletedProjectUploadFileEntry?: {
            uuid: string;
        } | null;
    };
};

export const deleteProjectUploadFileSubscription = gql`
    subscription {
        DeleteProjectUploadFile {
            deletedProjectUploadFileEntry {
                uuid
            }
        }
    }
`;

export const useDeleteProjectUploadFileSubscription =
    makeUseSubscription<DeleteProjectUploadFileMessage>(deleteProjectUploadFileSubscription);
