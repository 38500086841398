import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { Text, Title } from './styled';

import { Mode } from '@/theme/Mode';

import { ReactComponent as CloseIcon } from '@/assets/icons/32/close.svg';

const COPY = {
    title: 'Are you sure?',
    text: `Once published, the builder will be see this project as being completed.
    Please make sure to submit any relevant files to them via the chat (estimate file, takeoffs, etc.).`,
    cancel: 'Cancel',
    peer_review: 'Schedule peer review',
    publish: 'Publish project',
};

type PublishModalProps = {
    isModalVisible: boolean;
    close: () => void;
    onPublish: () => void;
};

export const PublishModal: FC<PublishModalProps> = ({ isModalVisible, close, onPublish }) => {
    return (
        <Mode variant="light">
            <Dialog open={isModalVisible} onClose={close} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="right">
                        <IconButton onClick={close}>
                            <CloseIcon width={30} height={30} fill="#65667A" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Title>{COPY.title}</Title>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Text>{COPY.text}</Text>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="text">
                        {COPY.cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            onPublish();
                            close();
                        }}
                        variant="contained"
                    >
                        {COPY.publish}
                    </Button>
                </DialogActions>
            </Dialog>
        </Mode>
    );
};
