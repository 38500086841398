import { DefaultTheme } from 'styled-components';

import * as __deprecatedVariables from '@/variables';

export const theme: DefaultTheme = {
    v0: {
        ...__deprecatedVariables,
    },
    v1: {
        color: {
            brand: '#1B2235',
        },
        fontFamily: {
            proximaNova: 'proxima-nova, sans-serif',
            poppins: 'Poppins, Helvetica Neue, Helvetica, Arial, sans-serif',
        },
    },
};
