import { useAssemblyPanel } from '../../../context';
import { ReactComponent as GroupsIcon } from '@/assets/icons/assemblyLibrary/groups-tab.svg';
import { ReactComponent as ItemsIcon } from '@/assets/icons/assemblyLibrary/items-tab.svg';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import React, { FC } from 'react';

const COPY = {
    items: 'Items',
    groups: 'Groups',
};

export interface TabSelectorProps {
    itemsLabel?: string;
    groupsLabel?: string;
}

export const TabSelector: FC<TabsProps & TabSelectorProps> = (props) => {
    const { setTab, tab } = useAssemblyPanel();

    return (
        <Tabs
            onChange={(event, value) => setTab(value)}
            value={tab}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                minHeight: 'auto',
            }}
            variant="fullWidth"
            {...props}
        >
            <Tab
                icon={<ItemsIcon />}
                iconPosition="start"
                label={props.itemsLabel ?? COPY.items}
                sx={{
                    minHeight: '40px',
                }}
            />
            <Tab
                icon={<GroupsIcon />}
                iconPosition="start"
                label={props.groupsLabel ?? COPY.groups}
                sx={{
                    minHeight: '40px',
                }}
            />
        </Tabs>
    );
};
