import { Table } from 'leyden';

import { Serialize } from '.';

export interface SerializeTable {
    empty: () => Table;
}

export const SerializeTable: SerializeTable = {
    empty(): Table {
        return Table.new(Serialize.Constants.COLUMN_COUNT, [
            Serialize.Cell.Actions({ categoryID: null }),
            ...Serialize.Cells.empty(Serialize.Constants.COLUMN_COUNT - 1, null),
        ]);
    },
};
