import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { fontWeight } from './fonts/weight';
import { layers, zIndex } from './layers';
import { palette } from './palette';

import { theme as v1Theme } from '@/common/styles/themes';

// See: https://mui.com/customization/theming/
// See: https://mui.com/customization/default-theme/

const defaultTheme = createTheme({
    ...v1Theme,
    // See: https://mui.com/customization/breakpoints/
    breakpoints: {
        values: {
            ...breakpoints,
        },
    },
    // See: https://mui.com/customization/z-index/
    zIndex: {
        mobileStepper: zIndex.mobileStepper * layers.default,
        speedDial: zIndex.speedDial * layers.default,
        appBar: zIndex.appBar * layers.default,
        drawer: zIndex.drawer * layers.default,
        modal: zIndex.modal * layers.default,
        snackbar: zIndex.snackbar * layers.default,
        tooltip: zIndex.tooltip * layers.default,
    },
    // See: https://mui.com/customization/theme-components/
    components: {
        // See: https://mui.com/api/app-bar/
        MuiAppBar: {
            defaultProps: {
                color: 'default',
                elevation: 0,
                position: 'static',
            },
            styleOverrides: {
                colorDefault: {
                    backgroundColor: colors.neutral[21],
                    color: '#fff',
                },
            },
        },
        // See: https://mui.com/api/backdrop/
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    background: 'rgba(0, 0, 0, 0.3)',
                },
                invisible: {
                    background: 'rgba(0, 0, 0, 00)',
                },
            },
        },
        // See: https://mui.com/api/button/
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '8px 16px',
                    whiteSpace: 'nowrap',
                },
                sizeSmall: {
                    fontSize: '0.6875rem',
                    padding: '3px 16px',
                },
            },
        },
        // See: https://mui.com/api/button-base/
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            },
        },
        // See: https://mui.com/api/checkbox/
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
            },
        },
        // See: https://mui.com/api/chip/
        MuiChip: {
            styleOverrides: {
                avatarSmall: {
                    height: '14px',
                    width: '14px',
                },
                sizeSmall: {
                    fontSize: '0.6875rem',
                    lineHeight: '1.5rem',
                },
            },
        },
        // See: https://mui.com/api/dialog/
        MuiDialog: {
            defaultProps: {
                maxWidth: 'md',
            },
            styleOverrides: {
                paper: {
                    borderRadius: 16,
                },
                paperWidthMd: {
                    maxWidth: 848,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px 32px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '32px 24px 16px',
                },
            },
        },
        // See: https://mui.com/components/drawers/
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    padding: '24px 24px 32px',
                    borderRadius: '25px 25px 0px 0px',
                    boxShadow: 'none',
                },
            },
        },
        // See: https://mui.com/api/input/
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
        },
        // See: https://mui.com/api/input-base/
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        // See: https://mui.com/api/filled-input/
        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
        },
        // See: https://mui.com/api/form-label/
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        // See: https://mui.com/api/link/
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
        // See: https://mui.com/api/list-item/
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingBottom: 16,
                    paddingTop: 16,
                },
                dense: {
                    paddingBottom: 4,
                    paddingTop: 4,
                },
            },
        },
        // See: https://mui.com/api/list-item-button/
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    paddingBottom: 16,
                    paddingTop: 16,
                },
                dense: {
                    paddingBottom: 4,
                    paddingTop: 4,
                },
            },
        },
        // See: https://mui.com/api/list-item-icon/
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: 14,
                    minWidth: 'auto',
                },
            },
        },
        // See: https://mui.com/api/list-item-text/
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    variant: 'body1',
                },
            },
            styleOverrides: {
                root: {
                    marginBottom: 0,
                    marginTop: 0,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    ['& .MuiLink-root']: {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ['& .MuiListItemIcon-root']: {
                        minWidth: 'auto',
                    },
                },
            },
        },
        // See: https://mui.com/api/tab/
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'proxima-nova, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: fontWeight.bold,
                    letterSpacing: '-0.02em',
                    lineHeight: '1.25rem',
                    textTransform: 'none',
                },
            },
        },
        // See: https://mui.com/api/tabs/
        MuiTabs: {
            defaultProps: {
                textColor: 'secondary',
            },
            styleOverrides: {
                indicator: {
                    height: '4px',
                },
            },
        },
        // See: https://mui.com/api/table-cell/
        MuiTableCell: {
            styleOverrides: {
                paddingNone: {
                    padding: 0,
                },
                sizeSmall: {
                    padding: '8px 24px',
                },
                sizeMedium: {
                    padding: '16px 24px',
                },
            },
        },
        // See: https://mui.com/api/toolbar/
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '@media (min-width: 0px)': {
                        minHeight: 64,
                    },
                },
                gutters: {
                    paddingLeft: 24,
                    paddingRight: 24,

                    [`@media (min-width: ${breakpoints.md}px)`]: {
                        paddingLeft: 35,
                        paddingRight: 35,
                    },
                },
            },
        },
        // See: https://mui.com/api/tooltip/
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: palette?.common?.black,
                    padding: '10px',
                },
            },
        },
    },
    // See: https://mui.com/customization/palette/
    palette,
    // See: https://mui.com/customization/typography/
    typography: {
        body1: {
            fontSize: '1rem',
            letterSpacing: 0,
            lineHeight: '1.5rem',
        },
        body2: {
            fontSize: '0.875rem',
            letterSpacing: 0,
            lineHeight: '1.25rem',
        },
        body3: {
            fontWeight: fontWeight.bold,
            fontSize: '0.6875rem',
            lineHeight: '1.5rem',
        },
        button: {
            fontSize: '0.6875rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '0.08em',
            lineHeight: '1.5rem',
        },
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: 16,
        fontWeightBold: fontWeight.bold,
        h1: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '2rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '-0.02em',
            lineHeight: '2.5rem',
        },
        h2: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '2rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '-0.02em',
            lineHeight: '2.5rem',
        },
        h3: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '1.25rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '-0.02em',
            lineHeight: '1.75rem',
        },
        h4: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '1rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '-0.02em',
            lineHeight: '1.5rem',
        },
        h5: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.875rem',
            fontWeight: fontWeight.bold,
            letterSpacing: '-0.02em',
            lineHeight: '1.25rem',
        },
        overline: {
            component: 'div',
            fontWeight: fontWeight.bold,
            fontSize: '0.6875rem',
            lineHeight: '1.5rem',
        },
    },
});

// https://mui.com/material-ui/customization/typography/#responsive-font-sizes
export const theme = responsiveFontSizes(defaultTheme, {
    disableAlign: true,
});
