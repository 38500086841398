/**
 * `Env` is an environment variable provider. This pattern provides a central
 * location for environment variable retrieval.
 *
 * Usage: Public methods provide environment variable access.
 */
import { Tier } from '@/common/types';

type EnvWindow = typeof window & {
    env: { [key: string]: string };
};

const getEnv = (varString: string): string | null => {
    /**
     * Env variables are loaded into window via config file.
     * {@link public/config.js}
     */
    const extractedVar =
        typeof window !== 'undefined' && typeof (window as EnvWindow).env !== 'undefined'
            ? (window as EnvWindow).env[varString]
            : process.env[varString];
    if (!extractedVar) {
        return null;
    }
    return extractedVar;
};

const getRequiredEnv = (varString: string, fallback?: string): string => {
    const envVal = getEnv(varString);
    let value = '';
    if (!envVal && !fallback) {
        throw new Error(`Required environment variable not found: ${varString}`);
    } else if (!envVal && fallback) {
        value = fallback;
    } else if (envVal) {
        value = envVal;
    }

    return value;
};

export const Env = {
    auth0ClientID: getRequiredEnv('AUTH0_CLIENT_ID', 'LEqEJMMF6SctibnwB2wFWH4sseZasUC5'),
    auth0Domain: getRequiredEnv('AUTH0_DOMAIN', '1build-stagings.auth0.com'),
    auth0Issuer: getRequiredEnv('AUTH0_ISSUER', '1build-stagings.auth0.com'),
    auth0LogoutRedirectURL: getRequiredEnv(
        'AUTH0_LOGOUT_REDIRECT_URL',
        'http://localhost:8080/login'
    ),
    awsCognitoPoolID: getRequiredEnv(
        'AWS_COGNITO_POOL_ID',
        'us-west-2:050ff68b-a81c-438c-816d-fb4612ad44cf'
    ),
    s3AwsEndpoint: getRequiredEnv('S3_AWS_ENDPOINT', 'https://s3.us-west-2.amazonaws.com'),
    awsPlanBucket: getRequiredEnv('AWS_PLAN_BUCKET', '1b-local-ingestion'),
    awsPlanBucketRegion: getRequiredEnv('AWS_PLAN_BUCKET_REGION', 'us-west-2'),
    awsPlatformRegion: getRequiredEnv('PLATFORM_AWS_REGION', 'us-west-2'),
    bugsnagAPIKeyFrontend: getRequiredEnv(
        'BUGSNAG_API_KEY_FRONTEND',
        'becb20d0cf2ef747bf8c50674d7f7818'
    ),
    deploymentURL: getRequiredEnv('DEPLOYMENT_URL', 'http://localhost:8080'),
    googleMapsApiKey: getRequiredEnv('GMAPS_API_KEY', 'AIzaSyDPMR6pMOS0nlrr-8X2BhDJnQwLWXxKMm0'),
    googlePlacesApiKey: getRequiredEnv(
        'GPLACES_API_KEY',
        'AIzaSyD_uc9qdnEV_gLSBlppgou5qB9nfiVw2yo'
    ),
    googleAnalyticsTrackingKey: getRequiredEnv('GOOGLE_TRACKING_ID', 'UA-139477285-1'),
    backendScheme: getRequiredEnv('BACKEND_SCHEME', 'http'),
    backendHost: getRequiredEnv('BACKEND_HOST', '0.0.0.0'),
    backendPort: getRequiredEnv('BACKEND_PORT', '4444'),
    backendPath: getRequiredEnv('BACKEND_GQL_PATH', '/api/graphql'),
    postgraphileScheme: getRequiredEnv('POSTGRAPHILE_SCHEME', 'http'),
    postgraphileHost: getRequiredEnv('POSTGRAPHILE_HOST', 'localhost'),
    postgraphilePort: getRequiredEnv('POSTGRAPHILE_PORT', '3000'),
    postgraphilePath: getRequiredEnv('POSTGRAPHILE_PATH', '/graphql'),
    segmentIoWriteKey: getEnv('SEGMENTIO_JS_WRITE_KEY'),
    serviceUrl: getRequiredEnv('SERVICE_URL', 'http://localhost'),
    stripePublicKey: getRequiredEnv('STRIPE_PUBLIC_KEY', 'pk_test_Pvkz9epzkEiyyn1jzipUWYQ2'),
    launchDarklyClientsideID: getRequiredEnv(
        'LAUNCH_DARKLY_CLIENTSIDE_ID',
        '60e4cad3680b360cab47e8dd'
    ),
    release: getRequiredEnv('RELEASE', 'notset'),
    stripe: {
        selfStarterMonthlyPrice: getRequiredEnv(
            'STRIPE_SELF_STARTER_MONTHLY_SUBSCRIPTION_PRICE',
            'price_1Jwa2MAiv3eFkPBGc8wZXW5b'
        ),
        selfStarterAnnualPrice: getRequiredEnv(
            'STRIPE_SELF_STARTER_ANNUAL_SUBSCRIPTION_PRICE',
            'price_1JugcWAiv3eFkPBGeMPtqhcA'
        ),
        essentialMonthlyPrice: getRequiredEnv(
            'STRIPE_ESSENTIAL_MONTHLY_SUBSCRIPTION_PRICE',
            'price_1KjnLqAiv3eFkPBGzgPvztsw'
        ),
        essentialAnnualPrice: getRequiredEnv(
            'STRIPE_ESSENTIAL_ANNUAL_SUBSCRIPTION_PRICE',
            'price_1JugdPAiv3eFkPBGqTtj7Cf1'
        ),
        professionalMonthlyPrice: getRequiredEnv(
            'STRIPE_PROFESSIONAL_MONTHLY_SUBSCRIPTION_PRICE',
            'price_1KjnNSAiv3eFkPBGCiubfttE'
        ),
        professionalAnnualPrice: getRequiredEnv(
            'STRIPE_PROFESSIONAL_ANNUAL_SUBSCRIPTION_PRICE',
            'price_1JugeQAiv3eFkPBGTSt7sXjF'
        ),
    },
    tier: {
        isTest: getRequiredEnv('TIER', 'test') === Tier.Test,
        isDevelopment: getRequiredEnv('TIER', 'development') === Tier.Development,
        isStaging: getRequiredEnv('TIER', 'staging') === Tier.Staging,
        isProduction: getRequiredEnv('TIER', 'production') === Tier.Production,
        value: getRequiredEnv('TIER', 'development'),
    },

    costDataApiKey: getEnv('COST_DATA_API_KEY') ?? '',
    costDataUrl: getEnv('COST_DATA_URL') ?? 'http://localhost:3100',
};

export const isContainerized = (): boolean => window.navigator.webdriver;

export const postgraphileURL = (): string => {
    if (Env.tier.isDevelopment || Env.tier.isTest) {
        const host = isContainerized() ? Env.postgraphileHost : 'localhost';
        return (
            `${Env.postgraphileScheme}://${host}:` +
            `${Env.postgraphilePort}${Env?.postgraphilePath}`
        );
    }
    return `${Env.serviceUrl}${Env.postgraphilePath}`;
};

export const backendURL = (): string => {
    if (Env.tier.isDevelopment || Env.tier.isTest) {
        const host = isContainerized() ? Env.backendHost : 'localhost';
        return `${Env?.backendScheme}://${host}:` + `${Env.backendPort}${Env.backendPath}`;
    }
    return `${Env?.serviceUrl}${Env.backendPath}`;
};

export const backendWebsocketURL = (): string => {
    const httpRegex = /^http(s?)\b/;
    return backendURL().replace(httpRegex, 'ws$1');
};

export const postgraphileWebsocketURL = (): string => {
    const httpRegex = /^http(s?)\b/;
    return postgraphileURL().replace(httpRegex, 'ws$1');
};

export const isLocalhostDeploymentUrl = () => {
    return Env?.deploymentURL?.includes('localhost') === true;
};
