import styled from 'styled-components';

interface OpacityProps {
    isActive: boolean;
}
export const Opacity = styled.div<OpacityProps>`
    opacity: ${(props): number => (props.isActive ? 1 : 0.5)};
    z-index: ${(props): number => (props.isActive ? 1 : -1)};
    transition: opacity 0.4s;
`;
