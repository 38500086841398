import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const DialogContent = styledByMui('div')((options) => ({
    display: 'grid',

    [options.theme.breakpoints.up('md')]: {
        gridTemplateColumns: '340px 1fr',
    },
}));

export const Title = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
    marginBottom: 24,
}));

export const Body = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
    marginBottom: 40,
}));

export const LeftContent = styledByMui('div')((options) => ({
    background: options.theme.palette.primary.main,
    display: 'none',

    [options.theme.breakpoints.up('md')]: {
        alignItems: 'center',
        display: 'flex',
    },
}));

export const RightContent = styledByMui('div')((options) => ({
    padding: '60px 36px 112px',

    [options.theme.breakpoints.up('md')]: {
        padding: '88px 48px 112px',
    },
}));
