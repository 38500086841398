import { MutableRefObject, useEffect, useState } from 'react';

import { useLocalStorage } from '@rehooks/local-storage';

import { ContextMenuType, MousePosition } from './ContextMenu';
import { LibraryCategory } from './utils/types';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { StorageKey, StorageSetter } from '@/common/storage';
import { makeContext } from '@/common/utils/makeContext';
import {
    ExpressionOpenedState,
    ExpressionOpenedStateContext,
} from '@/components/app/router/EstimationRoute/hooks/useEstimationExpressionOpenedState';
import { useEstimationLayout } from '@/components/app/router/EstimationRoute/hooks/useEstimationLayout';
import { EstimationView } from '@/components/app/router/EstimationRoute/types';
import { IAssemblyFragment, ICountyFragment, IStateFragment } from '@/graphql';

const INITIAL_WIDTH = 426;

export enum AssemblyListTab {
    ITEMS,
    GROUPS,
}

export type ManuallySelectedLocalization<T extends ICountyFragment | IStateFragment> = {
    localization: T | null;
    isManuallySet: boolean;
};

type AssemblyPanelContextProps = {
    isEstimate: boolean;

    contextMenuOpen: boolean;
    setContextMenuOpen: (open: boolean) => void;
    openedCategory: LibraryCategory;
    setOpenedCategory: (category: LibraryCategory) => void;
    contextMenuPosition: MousePosition | undefined;
    setContextMenuPosition: (position: MousePosition) => void;
    contextMenuAssemblies: IAssemblyFragment[];
    setContextMenuAssemblies: (assemblies: IAssemblyFragment[]) => void;
    contextMenuType: ContextMenuType | undefined;
    setContextMenuType: (contextMenuType: ContextMenuType) => void;
    search: string;
    setSearch: (newSearch: string) => void;
    hasLegend: boolean;
    setHasLegend: (newHasLegend: boolean) => void;
    width: number;
    setWidth: (newWidth: number) => void;
    tab: AssemblyListTab;
    setTab: (tab: AssemblyListTab) => void;
    mainBodyRef: MutableRefObject<HTMLDivElement | null> | undefined;
    setMainBodyRef: (ref: MutableRefObject<HTMLDivElement | null>) => void;
    panelPickerRef: MutableRefObject<HTMLDivElement | null> | undefined;
    setPanelPickerRef: (ref: MutableRefObject<HTMLDivElement | null>) => void;

    localizationState: ManuallySelectedLocalization<IStateFragment>;
    setLocalizationState: StorageSetter<ManuallySelectedLocalization<IStateFragment>>;
    localizationCounty: ManuallySelectedLocalization<ICountyFragment>;
    setLocalizationCounty: StorageSetter<ManuallySelectedLocalization<ICountyFragment>>;
} & Partial<ExpressionOpenedStateContext>;

type AssemblyPanelContextInitArgs = Partial<ExpressionOpenedStateContext>;

/* eslint-disable @typescript-eslint/indent */
/* Context hook, responsible for status and actions */
const { useConsumer, Provider } = makeContext<
    AssemblyPanelContextProps,
    AssemblyPanelContextInitArgs
>((initArgs) => {
    // External
    // ----------------------------------------------------------------------------------------------------------------
    const estimationProject = useEstimationProject();
    const { view } = useEstimationLayout();

    // LocalizationData
    const [localizationCounty, setLocalizationCounty] = useLocalStorage<
        ManuallySelectedLocalization<ICountyFragment>
    >(`${StorageKey.County}--${estimationProject.projectUUID}`, {
        localization: null,
        isManuallySet: false,
    });

    const [localizationState, setLocalizationState] = useLocalStorage<
        ManuallySelectedLocalization<IStateFragment>
    >(`${StorageKey.State}--${estimationProject.projectUUID}`, {
        localization: null,
        isManuallySet: false,
    });

    const assemblyLocalization = {
        localizationCounty,
        setLocalizationCounty,
        localizationState,
        setLocalizationState,
    };

    // Context Menu
    // ----------------------------------------------------------------------------------------------------------------
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<MousePosition>();
    const [contextMenuAssemblies, setContextMenuAssemblies] = useState<IAssemblyFragment[]>([]);
    const [contextMenuType, setContextMenuType] = useState<ContextMenuType>();

    const assemblyPanelContextMenu = {
        contextMenuOpen,
        setContextMenuOpen,
        contextMenuPosition,
        setContextMenuPosition,
        contextMenuAssemblies,
        setContextMenuAssemblies,
        contextMenuType,
        setContextMenuType,
    };

    // Opened Category
    // ----------------------------------------------------------------------------------------------------------------
    const [openedCategory, setOpenedCategory] = useState<LibraryCategory>(
        view === EstimationView.Estimate ? LibraryCategory.OneBuild : LibraryCategory.Geometries
    );

    const assemblyPanelOpenedCategory = { openedCategory, setOpenedCategory };

    // Search
    // ----------------------------------------------------------------------------------------------------------------
    const [search, setSearch] = useState('');

    const assemblyPanelSearch = { search, setSearch };

    // Has Legend
    // ----------------------------------------------------------------------------------------------------------------
    const [hasLegend, setHasLegend] = useState(false);

    const assemblyPanelHasLegend = { hasLegend, setHasLegend };

    // Panel width
    // ----------------------------------------------------------------------------------------------------------------
    const [width, setWidth] = useState(INITIAL_WIDTH);

    const assemblyPanelWidth = { width, setWidth };

    // Assembly List Tabs
    // ----------------------------------------------------------------------------------------------------------------
    const [tab, setTab] = useState<AssemblyListTab>(AssemblyListTab.ITEMS);

    const assemblyListTabs = { tab, setTab };

    // Refs
    // ----------------------------------------------------------------------------------------------------------------
    const [mainBodyRef, setMainBodyRef] = useState<MutableRefObject<HTMLDivElement | null>>();

    const assemblyPanelMainBodyRef = { mainBodyRef, setMainBodyRef };

    const [panelPickerRef, setPanelPickerRef] = useState<MutableRefObject<HTMLDivElement | null>>();

    const assemblyPanelPanelPickerRef = { panelPickerRef, setPanelPickerRef };

    // Effects
    // ----------------------------------------------------------------------------------------------------------------
    // On formula open, set category to geometries regardless of helper state
    useEffect(() => {
        if (
            initArgs?.expressionOpenedState !== undefined &&
            initArgs?.expressionOpenedState !== ExpressionOpenedState.Closed
        ) {
            setOpenedCategory(LibraryCategory.Geometries);
        }
    }, [initArgs?.expressionOpenedState]);

    // On navigation outside of geometry tab, close formula
    useEffect(() => {
        if (openedCategory !== LibraryCategory.Geometries) {
            initArgs?.setExpressionOpenedState?.(ExpressionOpenedState.Closed);
        }
    }, [openedCategory, initArgs?.setExpressionOpenedState]);

    // When the view is changed to the takeoff, select the geometries category.
    useEffect(() => {
        if (view === EstimationView.Takeoff) {
            setOpenedCategory(LibraryCategory.Geometries);
        }
    }, [view]);

    // Combined Assembly Panel
    // ----------------------------------------------------------------------------------------------------------------
    return {
        isEstimate: view === EstimationView.Estimate,

        ...(initArgs ?? {}),

        ...assemblyPanelContextMenu,
        ...assemblyPanelOpenedCategory,
        ...assemblyPanelSearch,
        ...assemblyPanelMainBodyRef,
        ...assemblyPanelPanelPickerRef,
        ...assemblyPanelHasLegend,
        ...assemblyPanelWidth,
        ...assemblyListTabs,
        ...assemblyLocalization,
    };
});

/* Context provider and consumer, exported for accessibility */
export const AssemblyPanelProvider = Provider;
export const useAssemblyPanel = useConsumer;
