import styled from 'styled-components';

export const AssemblySectionWrapper = styled.div`
    height: 100%;

    .assembly-context-menu {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 7.5rem;
        color: #1b2235;
        background-color: white;

        z-index: 2;

        span {
            cursor: pointer;
            padding: 0.375rem 0.75rem;

            &:hover {
                background-color: #eeeef1;
            }
        }
    }
`;
