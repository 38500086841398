import { LatLng, Marker, Polygon, Polyline } from 'leaflet';

import { Point } from '@/common/types';
import { coordinateDataIs } from '@/common/utils/geometries/coordinateData';

type Convert<T extends Point | Point[] | Point[][]> = T extends Point
    ? Marker
    : T extends Point[]
    ? Polyline
    : T extends Point[][]
    ? Polygon
    : never;

type Converter<T extends Point | Point[] | Point[][]> = (data: T) => Convert<T>;

const toLatLng = (point: Point) => {
    return new LatLng(point.x, point.y);
};

const toMarker: Converter<Point> = (point) => {
    return new Marker(toLatLng(point));
};

const toPolyline: Converter<Point[]> = (line) => {
    return new Polyline(line.map(toLatLng));
};

const toPolygon: Converter<Point[][]> = (area) => {
    const rings: LatLng[][] = [];
    area.forEach((ring) => rings.push(ring.map((point) => toLatLng(point))));
    return new Polygon(rings);
};

export function toLeaflet<T extends Point | Point[] | Point[][]>(data: T): Convert<T>;
export function toLeaflet(data: Point | Point[] | Point[][]) {
    if (coordinateDataIs.point(data)) return toMarker(data);
    if (coordinateDataIs.area(data)) return toPolygon(data);
    else return toPolyline(data);
}
