import React, { FC, useCallback, useEffect, useState } from 'react';
import { Table as LeydenTable } from 'leyden';
import { Serialize } from '@/components/Estimate/serialization';
import { Leyden } from 'leyden-react';
import { EstimateEditor } from '@/components/Estimate/editor/EstimateEditor';
import { Setter } from '@/common/types';

interface LeydenWrapperProps {
    editor: EstimateEditor;
    setResetFunction: Setter<(() => void) | undefined>;
}

export const LeydenWrapper: FC<LeydenWrapperProps> = ({ setResetFunction, editor, children }) => {
    const [value, setValue] = useState<[LeydenTable]>([Serialize.Table.empty()]);

    const resetFunction = useCallback(() => {
        setValue([Serialize.Table.empty()]);
    }, []);

    useEffect(() => {
        setResetFunction(resetFunction);
    }, [resetFunction]);

    return (
        <Leyden editor={editor} value={value} onChange={setValue}>
            {children}
        </Leyden>
    );
};
