/*
 * File is a single file within the files module of the project panel.
 */
import React, { FC, useEffect, useState } from 'react';

import moment, { Moment } from 'moment';
import { OperationResult, useMutation } from 'urql';

import {
    FileDeleteIcon,
    FileMainText,
    FileSubText,
    FileTextContainer,
    FilesFileContainer,
    FilesFileInfoContainer,
    FilesFilePlaceholderThumb,
    FilesFileThumb,
} from './styled';

import { Svg } from '@/common/types';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { removePlanMutation } from '@/mutations/removePlan';
import { removeUploadMutation } from '@/mutations/removeUpload';
import { ProjectPlanFileRecord } from '@/queries/projectPlanFiles';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';

import { ReactComponent as ComputerFile } from '@/assets/icons/ComputerFile.svg';
import { ReactComponent as ComputerLink } from '@/assets/icons/ComputerLink.svg';
import { ReactComponent as Trash } from '@/assets/icons/Trash.svg';

const COPY = {
    today: 'TODAY',
    yesterday: 'YESTERDAY',
};

export enum ProjectFileType {
    Upload = 'uploads',
    Plan = 'plan',
    Estimate = 'estimates',
}

export type TypedFile<T> = T & {
    fileType: ProjectFileType;
};

export type PanelProjectPlanFileRecord = TypedFile<ProjectPlanFileRecord> & {
    thumbUrl: string;
};

export type PanelProjectUploadFileRecord = TypedFile<ProjectUploadFileRecord>;

export type ProjectFile = PanelProjectPlanFileRecord | PanelProjectUploadFileRecord;

export type FileEntry = ProjectFile & {
    children?: ProjectFile[];
};

export const fileHasThumb = (file: FileEntry): file is PanelProjectPlanFileRecord =>
    Object.prototype.hasOwnProperty.call(file, 'thumbUrl');

export interface FileProps extends ProjectPanelComponentProps {
    file: FileEntry;
    isChild?: boolean;
    hideChildren?: boolean;
}

const fileDateString = (date: Moment): string => {
    const daysAgo = moment().diff(date, 'days');
    let dateString = date.fromNow();
    if (daysAgo === 0) {
        dateString = COPY.today;
    }
    if (daysAgo === 1) {
        dateString = COPY.yesterday;
    }
    return `${date.format('h:mm')} ${dateString}`;
};

const fileIcon = (file: ProjectPlanFileRecord | ProjectUploadFileRecord): JSX.Element => {
    const extension = file.filename && /[.][a-z]+$/.exec(file.filename)?.[0];
    let dimensions = '0.875rem';
    let icon: Svg = ComputerLink;
    switch (extension) {
        case '.zip':
            dimensions = '0.75rem';
            icon = ComputerFile;
            break;
    }
    return <SvgIcon height={dimensions} width={dimensions} src={icon} />;
};

export const File: FC<FileProps> = ({ file, isChild, hideChildren, useProjectPanel }) => {
    const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState<string | null>(null);

    const [, removeProjectPlanFile] = useMutation(removePlanMutation);
    const [, removeProjectUploadFile] = useMutation(removeUploadMutation);

    useEffect(() => {
        if (!fileHasThumb(file)) {
            setThumbnailBlobUrl(null);
            return;
        }
        fetch(file.thumbUrl).then((res) => {
            if (res.ok) {
                res.blob().then((blob) => setThumbnailBlobUrl(URL.createObjectURL(blob)));
            }
        });
    }, [file]);

    const renderFileThumb = (): JSX.Element => {
        if (thumbnailBlobUrl !== null) {
            return <FilesFileThumb src={thumbnailBlobUrl} />;
        }
        return <FilesFilePlaceholderThumb>{fileIcon(file)}</FilesFilePlaceholderThumb>;
    };

    const handleDeleteFile = async (): Promise<OperationResult | undefined> => {
        switch (file.fileType) {
            case ProjectFileType.Plan:
                return removeProjectPlanFile({ uuid: file.uuid });
            case ProjectFileType.Upload:
                return removeProjectUploadFile({ uuid: file.uuid });
        }
    };

    return (
        <>
            <FilesFileContainer isChild={isChild}>
                <FilesFileInfoContainer>
                    {renderFileThumb()}
                    <FileTextContainer>
                        <FileMainText>{file.filename}</FileMainText>
                        <FileSubText>
                            {file.created && fileDateString(moment.utc(file.created))}
                        </FileSubText>
                    </FileTextContainer>
                </FilesFileInfoContainer>
                <FileDeleteIcon onClick={handleDeleteFile}>
                    <Trash />
                </FileDeleteIcon>
            </FilesFileContainer>
            {!hideChildren &&
                file.children?.map((child, idx) => (
                    <File file={child} isChild={true} key={idx} useProjectPanel={useProjectPanel} />
                ))}
        </>
    );
};
