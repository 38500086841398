import styled from 'styled-components';

import {
    colorAdminDashboardSearchBackground,
    colorAdminDashboardSearchText,
    colorBackgroundSecondaryLightest,
    colorTypographyDark,
    colorTypographyLight,
    colorTypographybody,
} from '@/variables';

const desktopGrid = `
" .      tabs .   search .       avatar .   " auto
/ 2.5rem auto 1fr auto   0.75rem auto   1rem`;

const grid = `
" .        logo      logo logo search .       avatar .       " 1fr
" .        hamburger .    tabs tabs   tabs    tabs   .       " 1fr
/ 0.875rem auto      1rem 1fr  auto   1.25rem auto   1.125rem`;

export const SearchContainer = styled.div`
    grid-area: search;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    height: 2rem;
    border-radius: 0.5rem;
`;

export const SearchInput = styled.input`
    flex-grow: 2;
    background: transparent;
    font-family: 'proxima-nova', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.03rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 0 0 0.75rem;
    padding: 0;
    color: ${colorAdminDashboardSearchText};

    &::placeholder {
        opacity: 0.7;
    }
`;

export const TabHeader = styled.h2<{ active?: boolean }>`
    font-family: 'Poppins', sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: -0.03rem;
    margin: 0;
    cursor: pointer;
    opacity: ${(props): string => (props.active ? '1' : '0.3')};
`;

export const TopbarContainer = styled.div<{ isDesktop: boolean }>`
    grid-area: topbar;
    display: grid;
    grid-template: ${(props): string => (props.isDesktop ? desktopGrid : grid)};
    align-items: center;
    justify-content: space-between;
    background: ${(props): string =>
        props.isDesktop ? colorBackgroundSecondaryLightest : 'transparent'};

    ${SearchContainer} {
        background-color: ${(props): string =>
            props.isDesktop ? colorAdminDashboardSearchBackground : 'transparent'};
        padding: ${(props): string => (props.isDesktop ? '0 0.875rem' : '0')};

        svg path {
            fill: ${(props): string =>
                props.isDesktop ? colorAdminDashboardSearchText : colorTypographyLight};
        }
    }

    ${SearchInput} {
        display: ${(props): string => (props.isDesktop ? 'flex' : 'none')};
    }

    ${TabHeader} {
        color: ${(props): string => (props.isDesktop ? colorTypographyDark : colorTypographybody)};
    }
`;

export const TabMenuContainer = styled.div<{ light?: boolean }>`
    grid-area: tabs;
    justify-self: end;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1.875rem;
    max-width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const UserDropdownContainer = styled.span`
    grid-area: avatar;
`;
