/**
 * RequestRevisionPanel is a component that is meant to be displayed as a panel
 * that shows up above the Project Details view when the 1build estimator wants
 * to request revision from a builder.
 */

import React, { FC, useState } from 'react';
import clsx from 'clsx';

import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';
import { useUser } from '@/contexts/User';
import { useNotifications } from '@/contexts/Notifications';
import { Textarea } from '@/components/ui/inputs/Textarea';
import { Checkbox } from '@/components/ui/inputs/Checkbox';
import { Datepicker } from '@/components/ui/inputs/Datepicker';
import { Nil } from '@/common/types';

import './RequestRevisionPanel.scss';
import { useMutation } from 'urql';
import { requestRevisionMutation } from '@/mutations/requestRevision';
import { ErrorMessage } from '@/components/notifications/Notification';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { bidsDueDate } from '@/common/utils/bidsDueDate';

const COPY = {
    request_revision_title: 'Request revision',
    request_revision_submit: 'Send',
    additional_info_needed: 'Additional info needed',
    additional_info_description: 'Mention any missing info needed to proceed',
    estimate_return_date: 'Estimate return date',
    estimate_return_date_description: 'Revise estimate return date',
    request_approval_from_builder: 'Request approval from builder',
    text_area_placeholder: 'Enter message',
    request_approval_error_title: 'Something went wrong',
    request_approval_error_message: 'Request not sent',
    request_approval_success_title: 'Message sent',
    request_approval_success_message: 'Message sent to ',
    revision_no_approval_success_title: 'Success',
    revision_no_approval_success_message: 'Date successfully changed',
};

type RequestRevisionPanelProps = {
    v2?: boolean;
    className?: string;
    close: () => void;
    projectId: string;
};

export const RequestRevisionPanel: FC<RequestRevisionPanelProps> = ({ v2, className, close }) => {
    const {
        data: { user },
    } = useUser();
    const { addNotification } = useNotifications();
    const [message, setMessage] = useState('');
    const [isApprovalRequired, setIsApprovalRequired] = useState(false);
    const [date, setDate] = useState<Nil<Date>>();
    const [, sendRequestRevision] = useMutation(requestRevisionMutation);

    const { project } = useProjectFromParams();

    const handleSendRequestRevision = (): void => {
        if (!project) {
            return;
        }
        sendRequestRevision({
            projectId: project.id,
            additionalInfo: message,
            estimateDueDate: bidsDueDate(date || undefined).getDate(),
            approvalRequested: isApprovalRequired,
            ownerId: user.id,
        })
            .then((response) => {
                if (response.error) {
                    addNotification(
                        {
                            title: COPY.request_approval_error_title,
                            content: (
                                <ErrorMessage
                                    message={COPY.request_approval_error_message}
                                    handleClick={handleSendRequestRevision}
                                />
                            ),
                        },
                        'error'
                    );
                } else {
                    if (isApprovalRequired) {
                        addNotification(
                            {
                                title: COPY.request_approval_success_title,
                                content: (
                                    <p>
                                        {COPY.request_approval_success_message}
                                        <span>{project?.team?.leadUser?.email}</span>
                                    </p>
                                ),
                            },
                            'success'
                        );
                    } else {
                        addNotification(
                            {
                                title: COPY.revision_no_approval_success_title,
                                content: <p>{COPY.revision_no_approval_success_message}</p>,
                            },
                            'success'
                        );
                    }
                    close();
                }
            })
            .catch((_) => {
                addNotification(
                    {
                        title: COPY.request_approval_error_title,
                        content: (
                            <ErrorMessage
                                message={COPY.request_approval_error_message}
                                handleClick={handleSendRequestRevision}
                            />
                        ),
                    },
                    'error'
                );
            });
    };

    return (
        <div className={clsx(className, 'side-panel-content request-revision')}>
            <div className="header">
                <h4 className="revision-header">{COPY.request_revision_title}</h4>
                <PrimaryButton
                    v2={v2}
                    onClick={handleSendRequestRevision}
                    disabled={!message || !date}
                >
                    {COPY.request_revision_submit}
                </PrimaryButton>
            </div>
            <div className="body-side-panel">
                <h5>{COPY.additional_info_needed}</h5>
                <p className="info-typography body-l revision-description">
                    {COPY.additional_info_description}
                </p>
                <Textarea
                    placeholder={COPY.text_area_placeholder}
                    value={message}
                    onChange={setMessage}
                />
                <h5>{COPY.estimate_return_date}</h5>
                <p className="info-typography body-l revision-description">
                    {COPY.estimate_return_date_description}
                </p>
                <Datepicker value={date} onChange={setDate} />
                <Checkbox
                    id="request-approval"
                    isChecked={isApprovalRequired}
                    onChange={setIsApprovalRequired}
                >
                    <span className="info-typography body-l revision-description">
                        {COPY.request_approval_from_builder}
                    </span>
                </Checkbox>
            </div>
        </div>
    );
};
