import styled from 'styled-components';

import { InstructionVideo } from './InstructionVideo';
import { breakpoints } from '@/common/styles/helpers/breakpoints';
import { colorTypographyDark } from '@/variables';

export { Button } from '../styled';

export const Copy = styled.div`
    grid-area: copy;

    color: inherit;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.87;
`;

export const Header = styled.h1`
    grid-area: header;

    color: inherit;

    font-family: ${(props): string => props.theme.v1.fontFamily.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
`;

export const Video = styled(InstructionVideo)`
    grid-area: instruction-video;
`;

const instructionsGridTemplate = `
    " header " min-content
    " .      " 16px
    " copy   " min-content
    " .      " 32px
    " button " min-content
    / 1fr
`;

export const Instructions = styled.div`
    grid-area: instructions;

    display: grid;
    grid-template: ${instructionsGridTemplate};

    padding: 40px 24px 32px;
`;

const gridTemplate = `
    " instruction-video  " min-content
    " instructions       " 1fr
    / 1fr
`;

export const Container = styled.div`
    // Only show mobile view
    display: none;

    grid-template: ${gridTemplate};

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100vw;
    height: 85vh;
    min-height: 615px;
    border-radius: 10px 10px 0px 0px;
    overflow-x: hidden;
    overflow-y: scroll;

    box-sizing: border-box;

    color: ${colorTypographyDark};
    background: #fff;

    & > ${Copy} {
        padding-right: 8px;
    }

    ${breakpoints.small`
        display: grid;
    `}
`;
