import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { track, TrackEventName } from '@/common/analytics';
import { useUser } from '@/contexts/User';
import { useCancelTrialMutation } from '@/graphql';
import { Mode } from '@/theme/Mode';

const COPY = {
    title: 'Cancel Trial',
    cancel: 'Cancel Trial',
    dontCancel: "Don't Cancel",
};

interface CancelTrialModalProps extends DialogProps {
    hideModal: () => void;
}

export const CancelTrialModal: FC<CancelTrialModalProps> = ({ hideModal, ...props }) => {
    const user = useUser();
    const history = useHistory();

    const handleOkButtonClick = async () => {
        await cancelTrial();
        track(TrackEventName.TrialCancelled, {
            firstName: user.data.user.firstName,
            lastName: user.data.user.lastName,
            email: user.data.user.email,
        });
        history.push('/');
    };

    const [cancelTrial, cancelTrialMutationResult] = useCancelTrialMutation();

    return (
        <Mode variant="light">
            <Dialog maxWidth="xs" {...props}>
                <DialogTitle>
                    <Typography
                        sx={{ color: (theme) => theme.palette.hues.neutral[21] }}
                        variant="h3"
                    >
                        {COPY.title}
                    </Typography>
                </DialogTitle>

                <DialogContent
                    sx={{
                        color: (theme) => theme.palette.hues.neutral[21],
                    }}
                >
                    <Typography>
                        Are you sure you want to cancel your trial and lose access to 1build? You
                        still have {user.data.user.team?.onboarding?.daysLeftInTrial} day
                        {user.data.user.team?.onboarding?.daysLeftInTrial !== 1 && 's'} of free
                        access to local cost data and rapid digital estimates and takeoffs.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={cancelTrialMutationResult.loading}
                        onClick={hideModal}
                        variant="outlined"
                    >
                        {COPY.dontCancel}
                    </Button>
                    <Button
                        color="error"
                        disabled={cancelTrialMutationResult.loading}
                        onClick={handleOkButtonClick}
                        variant="outlined"
                    >
                        {COPY.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </Mode>
    );
};
