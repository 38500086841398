import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { BillingPage } from '@/components/billing/BillingPage';

export const BillingRouter: FC = () => {
    return (
        <Switch>
            <Redirect from="/billing/*" to="/billing" />
            <Route path="/billing">
                <BillingPage />
            </Route>
        </Switch>
    );
};
