import Box, { BoxProps } from '@mui/material/Box';
import React, { FC } from 'react';
import { ColorPicker } from '../LinearPanel/ColorPicker';

const MixedPanel: FC<BoxProps> = () => {
    return (
        <Box>
            <ColorPicker />
        </Box>
    );
};

export default MixedPanel;
