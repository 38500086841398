import gql from 'graphql-tag';
import { Event } from '../common/types';

export type UpdateEventResult = {
    updateEvent: {
        event: Event;
    };
};

export const removeEventMutation = gql`
    mutation RemoveEvent($eventId: BigInt!, $newEventTypeName: String!) {
        updateEvent(input: { patch: { eventTypeName: $newEventTypeName }, id: $eventId }) {
            event {
                eventTypeName
                message
                ownerId
                projectId
            }
        }
    }
`;
