import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { BuilderDetailsFormFields, BuilderDetailsCheckboxListItemType } from '@/common/types';
import { IEstimateType, IFormattingPreference } from '@/graphql';

interface BuilderDetailsCheckboxListProps {
    name: 'estimateTypes' | 'formattingPreferences';
    control: Control<BuilderDetailsFormFields, unknown>;
    label?: string;
    labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
    setValue: UseFormSetValue<BuilderDetailsFormFields>;
    options: BuilderDetailsCheckboxListItemType[];
    defaultSelected: IEstimateType[] | IFormattingPreference[];
    disabled?: boolean;
    error: boolean;
}

export const BuilderDetailsCheckboxList: React.FC<BuilderDetailsCheckboxListProps> = ({
    name,
    control,
    label,
    labelPlacement = 'end',
    setValue,
    options,
    defaultSelected,
    disabled = false,
    error = false,
}) => {
    const [selectedItems, setSelectedItems] =
        useState<(IEstimateType | IFormattingPreference)[]>(defaultSelected);

    const handleSelect = (value: IEstimateType | IFormattingPreference) => {
        const isPresent = selectedItems.some((el) => el === value);
        if (isPresent) {
            const remaining = selectedItems.filter(
                (item: IEstimateType | IFormattingPreference) => item !== value
            );
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems) => [...prevItems, value]);
        }
    };

    useEffect(() => {
        setValue(name, selectedItems as IEstimateType[] | IFormattingPreference[]);
    }, [selectedItems]);

    useEffect(() => {
        setSelectedItems(defaultSelected);
    }, [defaultSelected]);

    return (
        <FormControl error={error}>
            <FormLabel component="legend">
                <Typography sx={{ mb: 2 }} variant="subtitle1">
                    {label}
                </Typography>
            </FormLabel>

            <FormGroup>
                {options.map((option, idx) => (
                    <FormControlLabel
                        key={idx}
                        labelPlacement={labelPlacement}
                        control={
                            <Controller
                                name={name}
                                render={() => (
                                    <Checkbox
                                        disabled={disabled}
                                        checked={selectedItems.includes(option.value)}
                                        onChange={() => handleSelect(option.value)}
                                    />
                                )}
                                control={control}
                            />
                        }
                        label={<Typography variant="caption">{option.label}</Typography>}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};
