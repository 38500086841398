import wkx from 'wkx';

import { fromWKX } from '@/common/convert/coordinateData';
import { Geometry, GeometryType, PlanPageGeometry } from '@/common/types';

type Convert = {
    [GeometryType.AREA]: wkx.Polygon;
    [GeometryType.COUNT]: wkx.MultiPoint | wkx.Point;
    [GeometryType.LINEAR]: wkx.LineString;
};

function parseTwkb<T extends GeometryType>(geometry: PlanPageGeometry<T>): Convert[T];
function parseTwkb(geometry: PlanPageGeometry) {
    return wkx.Geometry.parseTwkb(Buffer.from(geometry.coordinates));
}

export function fromPlanPageGeometry<T extends GeometryType>(
    geometry: PlanPageGeometry<T>
): Geometry<T>;
export function fromPlanPageGeometry(geometry: PlanPageGeometry) {
    let coordinates = fromWKX(parseTwkb(geometry));
    if (geometry.type === GeometryType.COUNT && !Array.isArray(coordinates)) {
        coordinates = [coordinates];
    }
    return { ...geometry, coordinates };
}
