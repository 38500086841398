import { CellRenderer } from 'leyden-react';
import React, {
    ChangeEventHandler,
    KeyboardEventHandler,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelected, useSlateStatic } from 'slate-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Cell } from '../components/Cell';
import { EllipsisButton } from '../components/EllipsisButton';
import { useCellIsInHoveredRow } from '../../hooks/useCellIsInHoveredRow';
import { useContextMenu } from '../hooks/useContextMenu';
import { Transforms } from '../../transforms';
import { EstimateEditor } from '../../editor/EstimateEditor';
import {
    ConventionalInput,
    ConventionalInputWrapper,
} from '@/components/Estimate/Table/cells/styled';
import { useApolloClient } from '@apollo/client';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import TextField from '@mui/material/TextField';
import { Mode } from '@/theme/Mode';

export const Category: CellRenderer<'Category'> = ({ attributes, children, element }) => {
    const { open: openContextMenu, data } = useContextMenu();
    const client = useApolloClient();
    const editor = useSlateStatic();
    const selected = useSelected();
    const isInHoveredRow = useCellIsInHoveredRow(element);
    const breakpoints = useBreakpoints();
    const flags = useFlags();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [editedName, setEditedName] = useState('');

    useEffect(() => {
        if (selected && inputRef.current) {
            setEditedName(element.name);
            inputRef.current.select();
        }
    }, [selected, inputRef.current]);

    const handleNameInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.defaultPrevented) {
            setEditedName(e.target.value);
        }
    };

    const save = (): void => {
        if (editedName !== element.name) {
            Transforms.setCategoryName(editor, editedName, element, client);
        }
    };

    const handleNameInputKeyDown: KeyboardEventHandler = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            Transforms.deselect(editor);
            return;
        }
        if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            save();
            Transforms.moveCellSelection(editor, { direction: 'right' });
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            save();
            Transforms.moveCellSelection(editor, { direction: 'down' });
            return;
        }
    };

    const handleCaretClick: MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        EstimateEditor.toggleCategoryCollapsed(editor, element.id);
    };

    const renderName = (): JSX.Element => {
        if (!selected || (breakpoints.mobile && flags.mobileAssemblyPanel)) {
            return <>{element.name}</>;
        }
        if (flags.addItemAutocompleteV2) {
            return (
                <Mode variant="light">
                    <TextField
                        fullWidth
                        sx={{ zIndex: 15 }}
                        inputRef={inputRef}
                        variant="standard"
                        value={editedName}
                        onChange={handleNameInputChange}
                        onKeyDown={handleNameInputKeyDown}
                        onBlur={save}
                    />
                </Mode>
            );
        }
        return (
            <ConventionalInputWrapper>
                <ConventionalInput
                    value={editedName}
                    onChange={handleNameInputChange}
                    onKeyDown={handleNameInputKeyDown}
                    onBlur={save}
                    ref={inputRef}
                />
            </ConventionalInputWrapper>
        );
    };

    return (
        <Cell
            hasClickAction
            attributes={attributes}
            caret="always"
            element={element}
            onCaretClick={handleCaretClick}
            style={{ paddingLeft: '1.625rem', fontWeight: 700 }}
        >
            {(data?.cell.id === element.id || isInHoveredRow) && (
                <EllipsisButton
                    handleMenuOpen={({ x, y }) =>
                        openContextMenu({ cell: element, position: { x, y } })
                    }
                />
            )}
            {renderName()}
            {children}
        </Cell>
    );
};
