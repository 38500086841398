import { FileEntry, PanelProjectUploadFileRecord, ProjectFileType } from './File';

import { notUndefined } from '@/common/utils/helpers';
import { EventRecord } from '@/queries/events';
import { executeProjectUploadFilesQuery } from '@/queries/projectUploadFiles';

// Fetch all project uploads from a list of 'upload_project_related_files' events.
// It would be much more convenient if project uploads were directly related to projects with an FK...
export const fetchProjectUploads = (
    events: EventRecord[],
    excludedFileUUIDs: string[]
): Promise<PanelProjectUploadFileRecord[]> =>
    Promise.all(events.map((event) => executeProjectUploadFilesQuery({ eventId: event.id }))).then(
        (results) =>
            results
                .map((result) => result.data?.projectUploadFiles.nodes[0])
                .filter(notUndefined)
                .filter((file) => !excludedFileUUIDs.includes(file.uuid))
                .map((file) => ({ ...file, fileType: ProjectFileType.Upload }))
    );

// nestArchiveChildren relocates zip-extracted files as children of the archive.
// This is super messy - we need to rethink file uploads and storage.
export const nestArchiveChildren = (files: FileEntry[]): FileEntry[] => {
    const nestedFiles: FileEntry[] = files.map((file) => ({ ...file, children: [] }));
    const childFileIndexes: number[] = [];

    // This operation needs to be strictly linear, so we can't use a forEach.
    for (let i = 0; i < nestedFiles.length; i++) {
        const activeFile = nestedFiles[i];
        // If a file is prepended by a folder name (someFolder/myFile.pdf), look for its archive (someFolder.zip).
        const folderName = activeFile.filename?.match(/(\w*)[/]/)?.[1];
        if (folderName !== null && folderName !== undefined) {
            const parentFileIdx = nestedFiles.findIndex(
                (file) => file?.filename === `${folderName}.zip`
            );
            // If the parent archive exists, add the file to the archive and mark the file's index for removal.
            if (parentFileIdx !== -1) {
                nestedFiles[parentFileIdx].children = [
                    ...(nestedFiles[parentFileIdx]?.children ?? []),
                    activeFile,
                ];
                childFileIndexes.push(i);
            }
        }
    }

    // Return the generated array after removing the newly nested files from the array.
    return nestedFiles.filter((_file, idx) => !childFileIndexes.includes(idx));
};

// Remove a record from an array by its uuid.
export const removeRecordByUUID = <T extends { uuid: string }>(
    recordArr: T[],
    removedUUID: string
): T[] => recordArr.filter((record) => record.uuid !== removedUUID);

// If the updated record appears in the array, update it. If not, prepend the record to the array.
export const updateRecordArr = <T extends { id: number | string }>(
    recordArr: T[],
    updatedRecord: T
): T[] => {
    const updatedRecordIdx = recordArr.findIndex((record) => record.id === updatedRecord.id);
    if (updatedRecordIdx !== -1) {
        return [
            ...recordArr.slice(0, updatedRecordIdx),
            { ...updatedRecord },
            ...recordArr.slice(updatedRecordIdx + 1),
        ];
    }
    return [updatedRecord, ...recordArr];
};
