import { CSSObject, css } from 'styled-components';

import { colorProjectPanelHighlight } from '@/variables';

export const focusHighlight = css`
    outline: none;
    border: 2px solid transparent;

    &:focus,
    &:active {
        border-color: ${colorProjectPanelHighlight};
    }
`;

export const focusHighlightCssObject: CSSObject = {
    outline: 'none',
    border: '2px solid transparent',

    ['&:focus, &:active']: {
        borderColor: colorProjectPanelHighlight,
    },
};
