import { makeKeyMap } from '@/common/utils/helpers';

// If you want to use alt or option (depending on the platform), use the
// "modifier" keyword.
export const keyMap = makeKeyMap({
    all: {
        SHOW_KEY_MAP: 'shift+r',
    },
    takeoff: {
        UNDO: 'ctrl+z',
        REDO: 'ctrl+shift+z',
        COPY: 'ctrl+c',
        CUT: 'ctrl+x',
        PASTE: 'ctrl+v',
        ENABLE_DRAGGING: 'space',
        DISABLE_DRAGGING: {
            mapping: 'space',
            trigger: 'keyup',
        },
        DELETE: 'delete, backspace',
        TOGGLE_PAGE_BROWSER: 'q',
        TOGGLE_ASSEMBLY_BROWSER: '\\',
        SEARCH_PAGE: 's',
        ENTER: 'enter',
        CANCEL: 'escape',
        ZOOM_OUT: 'modifier+-',
        ZOOM_IN: 'modifier+=',
        RESET_VIEW: 'modifier+0',
        OPEN_CALIBRATION: 'modifier+s',
        SELECTION: 'v',
        LINEAR: 'l',
        AREA: 'a',
        COUNT: 'c',
        OPEN_SIZE_PANEL: 'modifier+t',
        OPEN_COLOR_PANEL: 'modifier+c',
        OPEN_PATTERN_PANEL: 'modifier+p',
        ADD: 'modifier+j',
        SUBTRACT: 'modifier+e',
        SPLIT: 'control+modifier+s',
    },
    pageBrowser: {
        PREVIEW_PAGE: 'space',
        DISABLE_PREVIEW_PAGE: {
            mapping: 'space',
            trigger: 'keyup',
        },
        SELECT_PAGE: 'enter',
        PAGE_UP: 'up',
        PAGE_DOWN: 'down',
        HIDE_PAGE_BROWSER: 'q',
        HIDE_CONTEXT_MENU: 'escape',
    },
});
