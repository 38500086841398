import React, { FC, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { Modal } from '@/components/ui/modals/Modal';
import { Button } from '@/components/ui/buttons/Button';

import {
    Container,
    CropperWrapper,
    ConfirmationButtons,
    CheckboxWrapper,
    CheckboxLabel,
    CheckMark,
} from './styled';

const COPY = {
    full_image_label: 'Fit to default size',
};

export interface ImageCropperProps {
    image: string | ArrayBuffer | null;
    openCropper: boolean;
    closeCropper: () => void;
    onConfirmCrop: () => void;
    onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => Promise<void>;
    fitToDefaultSize: boolean;
    onCheckboxChange: () => void;
}
export const ImageCropperModal: FC<ImageCropperProps> = ({
    image,
    openCropper,
    closeCropper,
    onConfirmCrop,
    onCropComplete,
    fitToDefaultSize,
    onCheckboxChange,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const getCropSize = () => {
        return {
            width:
                fitToDefaultSize && containerRef.current ? containerRef.current.clientWidth : 400,
            height:
                fitToDefaultSize && containerRef.current ? containerRef.current.clientHeight : 140,
        };
    };

    return (
        <Modal
            isOpen={openCropper}
            close={closeCropper}
            headerText="Crop image"
            width="32rem"
            modalContent={
                <Container>
                    <CropperWrapper ref={containerRef}>
                        <Cropper
                            image={image as string}
                            crop={crop}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            cropSize={getCropSize()}
                            showGrid={false}
                            restrictPosition={false}
                        />
                    </CropperWrapper>
                    <ConfirmationButtons>
                        <CheckboxWrapper>
                            <CheckboxLabel>
                                <input
                                    type="checkbox"
                                    checked={fitToDefaultSize}
                                    onChange={onCheckboxChange}
                                    onClick={(event): void => event.stopPropagation()}
                                />
                                <CheckMark />
                                <span>{COPY.full_image_label}</span>
                            </CheckboxLabel>
                        </CheckboxWrapper>
                        <div>
                            <Button variant="tertiary" onClick={closeCropper}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={onConfirmCrop}>
                                Confirm
                            </Button>
                        </div>
                    </ConfirmationButtons>
                </Container>
            }
        />
    );
};
