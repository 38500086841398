import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FunctionComponent, useState } from 'react';

import { getScaleAsFraction } from '../utils';

import { toQuantity } from '@/common/convert/geometry';
import { TakeoffComponentProps } from '@/components/takeoff/context';

const COPY = {
    manualCalibrationTitle: 'Manual Calibration',
    manualCalibrationTutorial:
        'Find a feature you know the measurement of' +
        ' and draw a line across it. Zoom in to get as accurate and as close' +
        ' to edges as possible.',
    manualCalibrationFormQuestion: 'What is the length of this object?',
};

export interface ManualProps extends TakeoffComponentProps {
    close: () => void;
}

export const Manual: FunctionComponent<ManualProps> = ({ close, useTakeoff }) => {
    const [length, setLength] = useState<number>(0);

    const [feet, setFeet] = useState<string>('');
    const [inches, setInches] = useState<string>('');

    let scale: number | null = null;

    const { currentPage, calibrationLine, setScale } = useTakeoff();

    const drawnLineLength = toQuantity(calibrationLine, 1);

    const toLength = (feet: string, inches: string) => {
        return Number(feet || 0) + Number(inches || 0) / 12;
    };

    const getSubmitButtonText = () => {
        if (calibrationLine && length && currentPage !== null && currentPage !== undefined) {
            scale = drawnLineLength / length;
            return getScaleAsFraction(scale, currentPage);
        }
        return 'Set scale';
    };

    const submitScale = () => {
        if (scale) {
            setScale(scale);
            setLength(0);
            close();
        }
    };

    return (
        <Box
            sx={{
                padding: '16px',
            }}
        >
            <Typography
                sx={{
                    marginBottom: '16px',
                }}
                variant="h4"
            >
                {COPY.manualCalibrationTitle}
            </Typography>
            <Typography
                sx={{
                    marginBottom: '16px',
                }}
                variant="body1"
            >
                {COPY.manualCalibrationTutorial}
            </Typography>
            <Stack spacing="16px">
                <Typography variant="h5">{COPY.manualCalibrationFormQuestion}</Typography>
                <Stack direction="row" spacing="8px">
                    <TextField
                        disabled={drawnLineLength === 0}
                        fullWidth
                        label="feet"
                        onChange={(event) => {
                            const isNumber = /\d+/.test(event.target.value);
                            const isEmpty = event.target.value === '';

                            if (!isNumber) {
                                if (!isEmpty) {
                                    return;
                                }
                            }

                            setFeet(event.target.value);
                            setLength(toLength(event.target.value, inches));
                        }}
                        size="small"
                        sx={{
                            flex: 1,
                        }}
                        value={feet}
                        variant="filled"
                    />
                    <TextField
                        disabled={drawnLineLength === 0}
                        inputProps={{
                            maxLength: 2,
                        }}
                        label="inches"
                        onChange={(event) => {
                            const isNumber = /\d+/.test(event.target.value);
                            const isEmpty = event.target.value === '';

                            if (!isNumber) {
                                if (!isEmpty) {
                                    return;
                                }
                            }

                            const isGreaterThanMax = Number(event.target.value) > 11;

                            if (isGreaterThanMax) {
                                return;
                            }

                            setInches(event.target.value);
                            setLength(toLength(feet, event.target.value));
                        }}
                        size="small"
                        sx={{
                            flex: 1,
                        }}
                        value={inches}
                        variant="filled"
                    />
                </Stack>
                <Button
                    disabled={!length || drawnLineLength === 0}
                    fullWidth
                    onClick={() => submitScale()}
                    variant="contained"
                >
                    {getSubmitButtonText()}
                </Button>
            </Stack>
        </Box>
    );
};
