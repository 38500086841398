/*
 * MissionControl is a link to the mission control page.
 */
import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorTypographySection } from '@/variables';

import { ReactComponent as Rocket } from '@/assets/icons/Rocket.svg';

const COPY = {
    missionControl: 'Mission control',
};

const MissionControlContainer = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.5rem;
    margin: 0;
    padding: 0 0 0 1.5rem;
    cursor: pointer;
`;

const MissionControlText = styled.h4`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin: 0 0 0 0.825rem;
`;

export const MissionControl: FC = () => {
    const history = useHistory();

    return (
        <MissionControlContainer
            onClick={(): void => history.push('/mission-control')}
            id="mission-control-link"
        >
            <SvgIcon
                src={Rocket}
                width="1.75rem"
                height="1.75rem"
                style={{ fill: colorTypographySection }}
            />
            <MissionControlText>{COPY.missionControl}</MissionControlText>
        </MissionControlContainer>
    );
};
