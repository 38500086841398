import { useEffect } from 'react';

/**
 * Perform some side effects to set onboarding links
 */
export const useSetOnboardingStorage = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const onboardingID = urlParams.get('onboarding') ?? undefined;
        const inviteID = urlParams.get('invite') ?? undefined;

        if (inviteID) {
            localStorage.setItem('invite_id', inviteID);
        } else if (onboardingID) {
            localStorage.setItem('onboarding_id', onboardingID);
        }
    }, []);
};
