import { useEstimationProject } from '../../hooks/useEstimationProject';
import { AssemblyPanelContent } from '@/components/AssemblyPanel/AssemblyPanelContent';
import Box, { BoxProps } from '@mui/material/Box';
import React, { FC } from 'react';

export const AssemblyPanelBody: FC<BoxProps> = (props) => {
    const { projectID } = useEstimationProject();

    return (
        <Box {...props}>{projectID !== -1 && <AssemblyPanelContent projectId={projectID} />}</Box>
    );
};
