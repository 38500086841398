/*
 * Price is the project panel's money management module.
 */
import React, { FC, useEffect, useState } from 'react';

import { ActionBar } from './ActionBar';
import { CostInfo } from './CostInfo';
import { Details } from './Details';
import { EstimationBox } from './EstimationBox';
import { PriceContainer, PriceContentContainer, PriceContentSection } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { isPriceEstimating } from '@/components/AdminDashboard/ProjectPanel/utils';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { executeBalancesQuery } from '@/queries/balance';

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const Price: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { modifiedPrice, project, setProject } = useProjectPanel();
    const [loading, setLoading] = useState(false);
    const { builderAcceptance } = useFlags();

    // When the panel loads, fetch the team's balance. If it's different than
    // what's in the project state, update project state with the new balance.
    useEffect(() => {
        if (project.teamId === undefined) {
            return;
        }

        executeBalancesQuery(
            {
                teamId: project.teamId,
            },
            {
                requestPolicy: 'network-only',
            }
        ).then((res) => {
            const nodes = res.data?.balances?.nodes;
            if (
                !nodes ||
                nodes.length === 0 ||
                nodes[0].usdCents === project.team?.balance?.usdCents
            ) {
                return;
            }
            setProject((p) => {
                if (!p.team) {
                    return p;
                }
                return {
                    ...p,
                    team: {
                        ...p.team,
                        balance: nodes[0],
                    },
                };
            });
        });
    }, []);

    if (loading) {
        return <SpinnerLoader />;
    }

    return (
        <PriceContainer>
            <PriceContentContainer>
                <CostInfo useProjectPanel={useProjectPanel} />
                <PriceContentSection>
                    {builderAcceptance && isPriceEstimating(project) && (
                        <EstimationBox useProjectPanel={useProjectPanel} />
                    )}
                    <Details useProjectPanel={useProjectPanel} />
                </PriceContentSection>
            </PriceContentContainer>
            {modifiedPrice !== undefined && (
                <ActionBar setLoading={setLoading} useProjectPanel={useProjectPanel} />
            )}
        </PriceContainer>
    );
};
