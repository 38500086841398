import React, { FC } from 'react';

import {
    BulletCaret,
    IsometricBoxes,
    MarketingBullet,
    MarketingBullets,
    MarketingContainerFreemium,
    MarketingCopy,
    MarketingContent,
    MarketingDemo,
    MarketingDemoContainer,
    MarketingHeader,
    MarketingSubheader,
} from './styled';

import signupSquaresSrc from '@/assets/images/signupSquares.png';
import signupDemoSrc from '@/assets/images/signupDemoFreemium.png';

const COPY = {
    header1: 'Expert cost estimates, On-Demand',
    header2:
        "1build matches you to an expert cost estimator who can help you prepare a winning bid that won't lose you money.",
    bullet1: 'Step 1: Create your account',
    bullet2: 'Step 2: Submit your Project documents',
    bullet3: 'Step 3: Get connected to an estimator and start!',
};

export const FreemiumMarketing: FC = () => {
    return (
        <MarketingContainerFreemium>
            <IsometricBoxes src={signupSquaresSrc} />
            <MarketingContent>
                <MarketingDemoContainer>
                    <MarketingDemo src={signupDemoSrc} />
                </MarketingDemoContainer>
                <MarketingCopy>
                    <MarketingHeader>{COPY.header1}</MarketingHeader>
                    <MarketingSubheader>{COPY.header2}</MarketingSubheader>

                    <MarketingBullets>
                        <MarketingBullet bold>
                            <BulletCaret />
                            {COPY.bullet1}
                        </MarketingBullet>
                        <MarketingBullet>
                            <BulletCaret />
                            {COPY.bullet2}
                        </MarketingBullet>
                        <MarketingBullet>
                            <BulletCaret />
                            {COPY.bullet3}
                        </MarketingBullet>
                    </MarketingBullets>
                </MarketingCopy>
            </MarketingContent>
        </MarketingContainerFreemium>
    );
};
