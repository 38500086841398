import { DueDate as DiscussionDueDate, Header as DiscussionHeader } from '../Discussion/styled';
import { ReactComponent as Cross } from '@/assets/icons/cross.svg';
import { ReactComponent as FullLogoV2 } from '@/assets/images/full-logo-v2.svg';
import { breakpoints } from '@/common/styles/helpers/breakpoints';
import styled from 'styled-components';

export { DownPointer, Name, PointerButton } from '../Discussion/styled';

////////////////////////////////////
// RESTYLED DISCUSSION COMPONENTS //
////////////////////////////////////
export const Header = styled(DiscussionHeader)`
    display: none;

    color: #fff;
    border: none;

    ${breakpoints.small`
        display: grid;
        margin-bottom: 0;
    `}
`;

export const DueDate = styled(DiscussionDueDate)`
    color: #fff;

    ${breakpoints.small`
        display: flex;
    `}
`;

export const MobileStatus = styled.div`
    display: none;

    padding: 4px 13px 17px 17px;
    height: 41px;

    box-sizing: border-box;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    & > ${DueDate} {
        margin-right: auto;
    }

    ${breakpoints.small`
        display: flex;
    `}
`;

export const CloseIcon = styled(Cross)`
    cursor: pointer;

    height: 9px;
    position: absolute;
    top: 16px;
    right: 16px;

    width: 0.875rem;
    height: 0.875rem;
    fill: #fff;

    display: none;

    ${breakpoints.medium`
        display: block;
    `}

    ${breakpoints.small`
        display: none;
    `}
`;

export const BackgroundTexture = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;

    ${breakpoints.small`display: none;`}
`;

export const LogoWrapper = styled.div`
    margin-top: auto;
`;

export const Logo = styled(FullLogoV2)`
    display: block;
    height: 34px;
    margin: 30px 17px;

    ${breakpoints.small`
        display: none;
    `}
`;

export const DetailHeader = styled.h4`
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    display: flex;
    align-items: center;

    padding: 0 16px 0 24px;

    margin: 0;

    ${breakpoints.small`
        padding: 0 13px 0 17px;
    `}
`;

export const ContributorsWrapper = styled.div`
    & > section {
        & > div {
            padding: 0 16px 0 20px;
        }
    }
`;

export const Section = styled.section`
    & > ${DetailHeader} {
        margin-bottom: 13px;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;

    z-index: 1;

    height: 100vh;

    & > ${ContributorsWrapper}, & > ${Section} {
        margin-bottom: 2.25rem;
    }

    ${breakpoints.small`
        & > ${ContributorsWrapper},
        & > ${Section} {
            margin-bottom: 2.5rem;

            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 1.5rem;

                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }

            &:first-of-type {
                margin-bottom: 2.5rem;
            }
        }
    `}
`;

export const FileManagerContainer = styled.div`
    padding: 20px 0;
`;

export const Container = styled.div<{ status: boolean }>`
    padding-top: ${(props): string => (props.status ? '0' : '1rem')};
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    overflow-x: hidden;

    .project-files-v2 {
        display: none;
    }

    ${breakpoints.small`
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding-top: 0;

        & .project-files-v2 {
            display: grid;
        }

        & > ${Details} {
            margin-top: 44px;
        }
    `}

    ${breakpoints.medium`
        box-sizing: border-box;
        height: 100%;
    `}
`;

export const RateNowContainer = styled.div`
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    background: #305dff;
    border-radius: 4px;
`;

export const Stars = styled.div`
    padding: 25px 0;
    letter-spacing: 3px;
`;
