/*
    Stores due date in UTC without time ([date] 00:00:00 GMT+0000) and exposes
    helper functions for due date formatting and manipulation
*/
import moment, { Moment } from 'moment';

import { formatDate } from './helpers';
import { momentUtc } from './moment';

export type BidsDueDate = {
    getDate: () => Moment;
    toString: () => string;
    toDueDateString: () => string;
    getBusinessDaysLeft: () => number;
    addBusinessDays: (days: number) => BidsDueDate;
    isBusinessDay: () => boolean;
    isOverdue: () => boolean;
    isDueSoon: () => boolean;
    businessDiff: () => number;
};

export const bidsDueDate = (init: string | Moment | Date = moment()): BidsDueDate => {
    const date = momentUtc(init);

    const getDate = (): Moment => date.clone();

    const toString = (): string => formatDate(date);

    const toDueDateString = (): string => `due ${moment(date).format('MMM DD')}`.toUpperCase();

    const getBusinessDaysLeft = (): number =>
        date.isBefore(momentUtc()) ? 0 : date.businessDiff(momentUtc());

    const addBusinessDays = (days: number): BidsDueDate =>
        bidsDueDate(date.businessAdd(days, 'days'));

    const isOverdue = (): boolean => getBusinessDaysLeft() === 0;

    const isBusinessDay = (): boolean => date.isBusinessDay();

    const isDueSoon = (): boolean => getBusinessDaysLeft() <= 2 && !isOverdue();

    const businessDiff = (): number => date.businessDiff(momentUtc());

    return {
        getDate,
        toString,
        toDueDateString,
        getBusinessDaysLeft,
        addBusinessDays,
        isBusinessDay,
        isOverdue,
        isDueSoon,
        businessDiff,
    };
};
