import styled, { createGlobalStyle } from 'styled-components';

import { fixedGradientBackground } from '@/common/styles/fixedGradientBackground';
import { breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';
import { Table } from '@/components/Estimate/Table';
import { HeaderContainer } from '@/components/Estimate/Table/headers/styled';
import { Opacity } from '@/components/ui/containers/Opacity';

export const EstimateGlobalStyle = createGlobalStyle`
    body, html {
        ${fixedGradientBackground}
    }
`;

const StyledTable = styled(Table)({ zIndex: 1 });

export const MastHead = styled(Opacity)<{ panelOpen: boolean }>(() => ({
    display: 'flex',
    padding: '24px 32px 48px',

    ...breakpointsCSSObject.medium({}),
}));

export const EstimateLayout = styled.div({
    gridArea: 'estimate',
    display: 'flex',
    flexDirection: 'column',

    overflow: 'hidden',

    height: '100%',
    minHeight: '100%',

    [`& > ${MastHead}`]: {
        flex: 1,
    },

    ['@media print']: {
        overflow: 'visible',
        padding: '0 4rem',
        width: '100vw',
        maxWidth: '100vw !important',

        [`& ${MastHead}`]: {
            flex: 0,
        },

        [`& ${StyledTable}`]: {
            [`& ${HeaderContainer}`]: {
                border: '1px solid #2c344e',

                ['& span']: {
                    color: '#1B2235',
                },

                ['&::after, &::before']: {
                    display: 'none',
                },
            },

            ['& [data-slate-node="element"]']: {
                breakAfter: 'page',
                border: '1px solid #2c344e',

                ['&::after, &::before']: {
                    display: 'none',
                },
            },
        },
    },
});

export { StyledTable as Table };
