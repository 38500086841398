import React, { FC, useEffect } from 'react';

import { DomEvent, LeafletMouseEvent } from 'leaflet';
import { FeatureGroup } from 'react-leaflet';

import { fromLeaflet } from '@/common/convert/coordinateData';
import { Geometry, GeometryType, ToolType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useTool } from '@/components/takeoff/hooks/useTool';
import { PointTool } from '@/components/takeoff/sheet-viewer/overlay/count/PointTool';

export interface CountToolProps extends TakeoffComponentProps {
    count: Geometry<GeometryType.COUNT>;
    editable: boolean;
    selected: boolean;
}

export const CountTool: FC<CountToolProps> = ({ count, selected, useTakeoff }) => {
    const { commit, mapRef, select } = useTakeoff();
    const tool = useTool();

    useEffect(() => {
        if (mapRef && selected && tool === ToolType.COUNT) {
            const map = mapRef.leafletElement;

            const handleClick = (e: LeafletMouseEvent): void => {
                select(count, false);
                commit({
                    ...count,
                    coordinates: [...count.coordinates, fromLeaflet(e.latlng)],
                });
                DomEvent.stopPropagation(e);
            };
            map.on('click', handleClick);

            return function cleanup(): void {
                map.off('click', handleClick);
            };
        }
    }, [mapRef, count, count.coordinates, selected, tool]);

    if (count.coordinates.length === 0) {
        return null;
    }

    return (
        <FeatureGroup>
            {count.coordinates.map((point) => (
                <PointTool
                    key={point.id}
                    point={point}
                    parent={count}
                    leafletStyle={count.style}
                    useTakeoff={useTakeoff}
                />
            ))}
        </FeatureGroup>
    );
};
