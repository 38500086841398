import React, { FC } from 'react';

import styled from 'styled-components';

import { IClientPreferenceFragment, IClientPreferenceStatus } from '@/graphql';
import { ClientRow } from './ClientRow';

const COPY = {
    nameHeader: 'NAME',
    preferredHeader: 'PREFERRED',
};

interface ClientsProps {
    clients: IClientPreferenceFragment[];
    updateClient: (
        teamID: string,
        rating: IClientPreferenceStatus,
        clientID: string
    ) => Promise<void>;
}

export const Clients: FC<ClientsProps> = ({ clients, updateClient }) => {
    return (
        <div>
            <ClientHeader>
                <HeaderItem>{COPY.nameHeader}</HeaderItem>
                <HeaderItem marginr="17px">{COPY.preferredHeader}</HeaderItem>
            </ClientHeader>
            <ClientsContainer>
                {clients.map((client) => (
                    <ClientRow client={client} updateClient={updateClient} key={client.id} />
                ))}
            </ClientsContainer>
        </div>
    );
};

const ClientHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
`;

const HeaderItem = styled.div<{ marginr?: string }>`
    text-transform: uppercase;
    font-weight: normal;
    font-size: 11px;
    color: #47637e;
    margin-right: ${(props): string => (props.marginr ? props.marginr : '')};
`;

const ClientsContainer = styled.div`
    overflow-y: auto;
    height: 100vh;
    &::-webkit-scrollbar {
        display: none;
    }
`;
