/* Provides feature flags that can enable/disable features in the */
import gql from 'graphql-tag';

export const FeatureFlagsQuery = gql`
    {
        featureFlags {
            nodes {
                id
                name
                allowInProduction
                isEnabled
            }
        }
    }
`;
