/**
 * ProjectStatusLabel displays provided state label with a color border
 */
import React, { FC } from 'react';
import clsx from 'clsx';

import {
    DivProps,
    ProjectStatus,
    DetailsProjectStatus,
    EstimatorDashboardProjectStatus,
    BuilderDashboardProjectStatus,
} from '@/common/types';

import './ProjectStatusLabel.scss';

export type ProjectStatusLabelProps = DivProps & {
    status:
        | BuilderDashboardProjectStatus
        | EstimatorDashboardProjectStatus
        | DetailsProjectStatus
        | ProjectStatus;
    count?: number;
    v2?: boolean;
};

export const ProjectStatusLabel: FC<ProjectStatusLabelProps> = ({
    status,
    className,
    count,
    v2,
}) => {
    return (
        <div
            className={clsx(
                'project-state-label',
                className,
                status.replace(/_/g, '-').toLowerCase(),
                v2 && 'v2'
            )}
        >
            <div>
                {status.replace(/_/g, ' ')}
                {(count || 0) > 0 && <span>{count}</span>}
            </div>
        </div>
    );
};
