import React, { FC, useEffect } from 'react';

import to from 'await-to-js';
import { Link } from 'react-router-dom';

import {
    ColumnContainer,
    CompanyLogo,
    ContactInfoBox,
    ContactInfoContainer,
    ContactInfoModal,
    Container,
    Footer,
    FooterLogo,
    Header,
    InfoContainer,
    InfoContent,
    InfoFooter,
    InfoHeader,
    InfoSection,
    PersonalInfoBox,
    ProjectName,
    ProjectTotal,
    RowContainer,
    SharedEstimateGlobalStyle,
    Text,
    textColorPrimary,
    textColorSecondary,
    Title,
} from './styled';
import { getGrandTotal, getTotalSF } from './utils';

import { formatters } from '@/common/currency';
import { Env } from '@/common/env';
import { useGoogleAnalytics } from '@/common/hooks/useGoogleAnalytics';
import { useShareEstimate } from '@/common/hooks/useShareEstimate';
import { useToggle } from '@/common/hooks/useToggle';
import { isArrayOfAssemblies } from '@/common/typeGuards';
import { Button } from '@/components/ui/buttons/Button';
import { useNotifications } from '@/contexts/Notifications';
import { IPublicEventType, usePublicEventMutation } from '@/graphql';
import { EventTypeName } from '@/queries/eventTypes';

import { Assemblies } from './Assemblies';
import { ExpandedAssemblies } from './ExpandedAssemblies';

import { ReactComponent as EmailIcon } from '@/assets/icons/email.svg';
import { ReactComponent as TelephoneIcon } from '@/assets/icons/telephone.svg';
import { ReactComponent as Logo } from '@/assets/images/footer-logo-v2.svg';
import FullDefaultDarkLogo from '@/assets/images/full-logo-dark-v2.svg';
import { useChangeIntercomState } from '@/common/hooks/useChangeIntercomState';

const COPY = {
    contact_modal_title: 'Contact information',
    contact_button_label: 'Contact',
    call_button_label: 'Call',
    email_button_label: 'Email',
    copyright_label: 'Powered by',
    public_estimate_view_title: 'Public event - Estimate view',
    public_estimate_view_message: '',
};

export const SharedEstimate: FC = () => {
    const [isContactModalOpen, toggleContactModal] = useToggle();
    const { projectUUID, pUUID, sharedEstimatePayload } = useShareEstimate();
    const [createPublicEvent] = usePublicEventMutation();
    const { addNotification } = useNotifications();

    useChangeIntercomState({
        enabled: false,
    });

    const formatter = formatters.usd({
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const getPublicEstimateLink = (): string => {
        if (!projectUUID || !pUUID) return '';

        return `${Env.deploymentURL}/projects/${projectUUID}/public/${pUUID}`;
    };

    const { sendPageView } = useGoogleAnalytics();

    useEffect(() => {
        (async () => {
            sendPageView();
            await handlePublicEstimateViewEvent();
        })();
    }, []);

    const handleRenderContactInfo = (): JSX.Element => {
        if (!sharedEstimatePayload?.contact_info) return <></>;

        const { contact_info } = sharedEstimatePayload;
        const { first_name, last_name, company_name, address, city, postal_code, state } =
            contact_info;

        return (
            <PersonalInfoBox>
                <Text weight={600} color={textColorPrimary}>
                    {first_name + ' ' + last_name}
                </Text>
                <Text weight={500} color={textColorSecondary}>
                    {company_name || ''}
                </Text>
                {address && <Text color={textColorSecondary}>{address}</Text>}
                {city && postal_code && state && (
                    <Text color={textColorSecondary}>
                        {city}, {state} {contact_info.postal_code}
                    </Text>
                )}
            </PersonalInfoBox>
        );
    };

    const handleCallContactPerson = () => {
        if (!sharedEstimatePayload?.contact_info.phone) return;

        window.location.href = `tel:${sharedEstimatePayload?.contact_info.phone}`;
    };

    const handleSendEmail = () => {
        if (!sharedEstimatePayload?.contact_info.email) return;

        window.location.href = `mailto:${sharedEstimatePayload?.contact_info.email}`;
    };

    const handleAddDefaultSrc = (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = FullDefaultDarkLogo;
    };

    const renderAssemblies = (): JSX.Element => {
        const assemblies = sharedEstimatePayload?.assemblies;
        const linkType = sharedEstimatePayload?.file_type;

        if (!assemblies || !linkType) return <></>;

        // If assemblies is not array of objects, we will handle it as Expanded type
        if (!isArrayOfAssemblies(assemblies)) {
            return <ExpandedAssemblies assemblies={assemblies} />;
        } else {
            return <Assemblies linkType={linkType} assemblies={assemblies} />;
        }
    };

    const handlePublicEstimateViewEvent = async (): Promise<void> => {
        if (!projectUUID) return;

        const [error] = await to(
            createPublicEvent({
                variables: {
                    input: {
                        projectUUID: projectUUID.toString(),
                        eventTypeName: EventTypeName.PublicEstimateView,
                        seen: true,
                        publicType: IPublicEventType.EstimateView,
                        message: getPublicEstimateLink(),
                    },
                },
            })
        );

        if (error) {
            addNotification(
                { title: COPY.public_estimate_view_title, content: error.message.toString() },
                'error'
            );
        }
    };

    return (
        <>
            <SharedEstimateGlobalStyle />
            <Container>
                <Header>
                    <CompanyLogo>
                        {!sharedEstimatePayload?.logo_url ? (
                            <img src={FullDefaultDarkLogo} alt="logo" />
                        ) : (
                            <img
                                src={sharedEstimatePayload?.logo_url}
                                onError={handleAddDefaultSrc}
                                alt="logo"
                            />
                        )}
                    </CompanyLogo>
                    <div>
                        <Button
                            variant="tertiary"
                            customCss={{ borderColor: textColorPrimary }}
                            onClick={toggleContactModal}
                        >
                            {COPY.contact_button_label}
                        </Button>
                    </div>
                </Header>
                <Title>
                    <ColumnContainer>
                        <ProjectName id={sharedEstimatePayload?.name ?? 'project-name'}>
                            {sharedEstimatePayload?.name}
                        </ProjectName>
                        <Text color={textColorSecondary}>
                            {sharedEstimatePayload?.location.formatted_address}
                        </Text>
                    </ColumnContainer>
                    <ColumnContainer id="summary-container">
                        <ProjectTotal id="project-total">
                            {formatter.format(getGrandTotal(sharedEstimatePayload) ?? 0)}
                        </ProjectTotal>
                        <RowContainer>
                            <Text color={textColorSecondary} margin="0 10px 0 0">
                                Total SF
                            </Text>
                            <Text>{sharedEstimatePayload?.area?.square_feet_under_roof ?? 0}</Text>
                        </RowContainer>
                        <RowContainer>
                            <Text color={textColorSecondary} margin="0 10px 0 0">
                                Cost/SF
                            </Text>
                            <Text>{formatter.format(getTotalSF(sharedEstimatePayload) ?? 0)}</Text>
                        </RowContainer>
                    </ColumnContainer>
                </Title>
                <InfoContainer>
                    <Text size="20px" weight={600} color={textColorPrimary} margin="0 0 25px 0">
                        Cost Breakdown
                    </Text>

                    <InfoHeader>
                        <Text color={textColorSecondary}>Description</Text>
                        <Text color={textColorSecondary}>Quantity</Text>
                        <Text color={textColorSecondary}>Rate</Text>
                        <Text color={textColorSecondary} align="right">
                            Total
                        </Text>
                    </InfoHeader>

                    <InfoContent>{renderAssemblies()}</InfoContent>

                    <InfoFooter id="footer-total-container">
                        <Text />
                        <Text />
                        <Text color={textColorPrimary} weight={600}>
                            Total
                        </Text>
                        <Text
                            id="footer-total-value"
                            color={textColorPrimary}
                            weight={600}
                            align="right"
                        >
                            {formatter.format(getGrandTotal(sharedEstimatePayload) ?? 0)}
                        </Text>
                    </InfoFooter>
                </InfoContainer>

                <Footer>
                    <FooterLogo>
                        <Text margin="0 10px 0 0" color="#757575">
                            {COPY.copyright_label}
                        </Text>
                        <Link to={{ pathname: 'https://www.1build.com/' }} target="_blank">
                            <Logo height="1.875rem" />
                        </Link>
                    </FooterLogo>
                </Footer>

                {/* Contact info modal */}
                <ContactInfoModal
                    isOpen={isContactModalOpen}
                    close={toggleContactModal}
                    headerText={COPY.contact_modal_title}
                    modalContent={
                        <ContactInfoContainer>
                            {handleRenderContactInfo()}
                            <ContactInfoBox>
                                <InfoSection>
                                    <TelephoneIcon width="18px" height="18px" />
                                    <Text margin="0 0 0 10px">
                                        {sharedEstimatePayload?.contact_info.phone}
                                    </Text>
                                </InfoSection>
                                <Button
                                    variant="tertiary"
                                    customCss={{ borderColor: textColorPrimary }}
                                    onClick={handleCallContactPerson}
                                >
                                    {COPY.call_button_label}
                                </Button>
                            </ContactInfoBox>

                            <ContactInfoBox>
                                <InfoSection>
                                    <EmailIcon width="18px" height="18px" />
                                    <Text margin="0 0 0 10px">
                                        {sharedEstimatePayload?.contact_info.email}
                                    </Text>
                                </InfoSection>
                                <Button
                                    variant="tertiary"
                                    customCss={{ borderColor: textColorPrimary }}
                                    onClick={handleSendEmail}
                                >
                                    {COPY.email_button_label}
                                </Button>
                            </ContactInfoBox>
                        </ContactInfoContainer>
                    }
                />
            </Container>
        </>
    );
};
