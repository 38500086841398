import React, { FC, MouseEventHandler } from 'react';

import { getFormattedIdentityProvider } from '@/common/utils/helpers';
import { Action, makeAction, State } from '@/components/mission-control/UserManager/state';
import { MoreButton } from '@/components/mission-control/UserManager/UsersTable/MoreButton';
import { PhoneNumber } from '@/components/mission-control/UserManager/UsersTable/PhoneNumber';
import {
    UserTableBodyCell,
    UserTableDivider,
} from '@/components/mission-control/UserManager/UsersTable/styled';
import { fullName } from '@/components/mission-control/UserManager/utils';
import { IUserLightFragment } from '@/graphql';

export interface RowProps {
    state: State;
    user: IUserLightFragment;
}

export const Row: FC<RowProps> = ({ state: { commit }, user }) => {
    const handleOnClickMore: MouseEventHandler<HTMLButtonElement> = () => {
        commit(makeAction(Action.OpenModal, { user }));
    };

    return (
        <>
            <UserTableDivider />
            <UserTableBodyCell>{fullName(user)}</UserTableBodyCell>
            <UserTableBodyCell>{user.team?.name ?? ''}</UserTableBodyCell>
            <UserTableBodyCell>
                {user.phone ? <PhoneNumber num={user.phone} /> : ''}
            </UserTableBodyCell>
            <UserTableBodyCell>{getFormattedIdentityProvider(user.authID)}</UserTableBodyCell>
            <UserTableBodyCell>
                <MoreButton onClick={handleOnClickMore} />
            </UserTableBodyCell>
        </>
    );
};
