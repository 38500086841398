/*
 * Estimator is a single estimator available for assignment.
 */
import React, { FC } from 'react';

import {
    EstimatorAssignmentEstimator,
    EstimatorAssignmentRadioInput,
    EstimatorAssignmentRadioOption,
    EstimatorActions,
    EstimatorThumbsContainer,
} from './styled';

import { IClientPreferenceStatus } from '@/graphql';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { User } from '@/components/ui/controls/User';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { BaseUserRecord } from '@/queries/baseUsers';
import { colorProjectPanelHighlight } from '@/variables';

import { ThumbControl } from '@/components/ui/controls/ThumbControl';

import { ReactComponent as Lightning } from '@/assets/icons/Lightning.svg';

export interface EstimatorProps extends ProjectPanelComponentProps {
    estimator: BaseUserRecord;
    showPreferredByBuilder: boolean;
    preferredByBuilder: IClientPreferenceStatus | null;
}

export const Estimator: FC<EstimatorProps> = ({
    estimator,
    useProjectPanel,
    showPreferredByBuilder,
    preferredByBuilder,
}) => {
    const { selectedEstimator, setSelectedEstimator } = useProjectPanel();

    const renderEstimatorAvatar = (): JSX.Element => (
        <User
            user={{
                roles: [],
                ...estimator,
                created: '',
                lastModified: '',
            }}
        />
    );

    return (
        <EstimatorAssignmentEstimator onClick={(): void => setSelectedEstimator(estimator)}>
            <EstimatorAssignmentRadioOption>
                <EstimatorAssignmentRadioInput
                    type="radio"
                    checked={
                        selectedEstimator !== undefined && selectedEstimator?.id === estimator.id
                    }
                    readOnly={true}
                />
                {renderEstimatorAvatar()}
            </EstimatorAssignmentRadioOption>
            <EstimatorActions>
                {showPreferredByBuilder && (
                    <EstimatorThumbsContainer>
                        {preferredByBuilder === IClientPreferenceStatus.Negative && (
                            <ThumbControl isThumbUp={false} isSelected />
                        )}
                        {preferredByBuilder === IClientPreferenceStatus.Positive && (
                            <ThumbControl isThumbUp={true} isSelected />
                        )}
                    </EstimatorThumbsContainer>
                )}
                <SvgIcon
                    src={Lightning}
                    height="0.875rem"
                    width="0.875rem"
                    style={{ fill: colorProjectPanelHighlight }}
                />
            </EstimatorActions>
        </EstimatorAssignmentEstimator>
    );
};
