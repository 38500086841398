import React, { FC } from 'react';

import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { IMarkupType } from '@/graphql';
import { useMarkupWithinGroupUpdate } from './hooks/useMarkupWithinGroupUpdate';

import { ReactComponent as AddIcon } from '@/assets/icons/32/add.svg';

interface AddTakeoffButtonsProps extends StackProps {
    markupGroupID?: string;
    geometry?: IMarkupType | null;
}

export const AddTakeoffButtons: FC<AddTakeoffButtonsProps> = ({
    geometry,
    markupGroupID,
    ...props
}) => {
    const geometries = !geometry
        ? [IMarkupType.Area, IMarkupType.Count, IMarkupType.Linear]
        : [geometry];
    const updateMarkupWithinGroup = useMarkupWithinGroupUpdate(markupGroupID);

    return (
        <Stack spacing="8px" {...props}>
            {geometries.map((geometry) => (
                <Button
                    fullWidth
                    key={geometry}
                    onClick={() => updateMarkupWithinGroup(geometry)}
                    size="small"
                    startIcon={
                        <SvgIcon viewBox="0 0 32 32">
                            <AddIcon />
                        </SvgIcon>
                    }
                    variant="outlined"
                >
                    {`New ${geometry || ''}`}
                </Button>
            ))}
        </Stack>
    );
};
