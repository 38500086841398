/*
 * Estimator is the project panel's estimator module.
 */
import React, { FC } from 'react';

import { Assign } from './Assign';
import { Manage } from './Manage';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';

export const Estimator: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { estimator } = useProjectPanel();

    return estimator !== undefined ? (
        <Manage useProjectPanel={useProjectPanel} />
    ) : (
        <Assign useProjectPanel={useProjectPanel} />
    );
};
