import React, { FC } from 'react';

import styled from 'styled-components';

import { ButtonProps } from '@/common/types';
import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';
import { borderCement } from '@/variables';

import { ReactComponent as HandIcon } from '@/assets/icons/hand.svg';

const Container = styled(PrimaryButton)`
    border: ${borderCement};
    box-sizing: border-box;
    border-radius: 4px;
    color: #3d445c;
`;

export const PauseButton: FC<ButtonProps> = ({ onClick, disabled }) => {
    return (
        <Container v2={true} variant="outlined" onClick={onClick} disabled={disabled}>
            <HandIcon /> Pause
        </Container>
    );
};
