import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Mode } from '@/theme/Mode';
import { Geometry, GeometryType } from '@/common/types';
import { getShapeWeight } from '@/common/utils/helpers';
import { styleObservable } from '@/components/takeoff/observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
} from '@/components/takeoff/observables/interface';
import React, { FC, useState } from 'react';

export const LineWeightPicker: FC<BoxProps> = (props) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const { lineType, weight: lineWeight } = styleObservable.value;

    const weights = [1, 2, 4, 8, 12];

    const selectedGeometryIds = selectedGeometriesObservable.value.geometries.map((g) => g.uuid);
    const geometries = geometriesObservable.value;

    const setLineWeight = (weight: number) => {
        styleObservable.next({
            ...styleObservable.value,
            weight,
        });
    };

    const updateSize = (weight: number) => {
        if (!geometries) {
            return;
        }

        setLineWeight(weight);

        const needsUpdate = (g: Geometry): boolean =>
            selectedGeometryIds.includes(g.uuid) && g.type === GeometryType.LINEAR;

        geometriesObservable.next(
            geometries.map(
                (g: Geometry): Geometry =>
                    needsUpdate(g)
                        ? {
                              ...g,
                              style: {
                                  ...g.style,
                                  weight,
                                  shapeWeight: getShapeWeight(weight, g.style.lineType || lineType),
                              },
                          } // update lineWeight
                        : g // do nothing
            )
        );
    };

    const toPixelLabel = (weight: number) => {
        return `${String(weight)}px`;
    };

    return (
        <Box {...props}>
            <IconButton
                sx={{
                    height: '37px',
                    width: '37px',
                }}
                onClick={(event) => setAnchor(event.currentTarget)}
                size="small"
            >
                <Typography
                    sx={{
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                    }}
                    variant="body1"
                >
                    {toPixelLabel(lineWeight)}
                </Typography>
            </IconButton>
            <Mode variant="light">
                <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchor(null)}
                    open={Boolean(anchor)}
                    PaperProps={{
                        sx: {
                            padding: '16px 0',
                            width: '150px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            marginBottom: '8px',
                            padding: '0 16px',
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Sizes
                    </Typography>
                    <List dense disablePadding>
                        {weights.map((weight) => {
                            return (
                                <ListItemButton
                                    key={weight}
                                    onClick={() => {
                                        updateSize(weight);
                                        setAnchor(null);
                                    }}
                                    selected={weight === lineWeight}
                                    sx={{
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                        variant="body2"
                                    >
                                        {`${weight}px`}
                                    </Typography>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Popover>
            </Mode>
        </Box>
    );
};
