import Box, { BoxProps } from '@mui/material/Box';
import { ProjectKanban } from './ProjectKanban';
import { useProjects } from './context';
import { DuplicateProjectModal } from '@/components/projects/DuplicateProjectModal';
import { DeleteProjectModal } from '@/components/projects/DeleteProjectModal';
import { ContextMenu } from '@/components/ui/inputs/ContextMenu';
import React, { FC } from 'react';

export const Projects: FC<BoxProps> = (props) => {
    const {
        contextMenuInfo,
        projectToDelete,
        setProjectToDelete,
        projectToDuplicate,
        setProjectToDuplicate,
    } = useProjects();

    return (
        <Box {...props}>
            {projectToDuplicate && (
                <DuplicateProjectModal
                    open={true}
                    project={projectToDuplicate}
                    close={() => setProjectToDuplicate(null)}
                />
            )}
            {projectToDelete && (
                <DeleteProjectModal
                    open={true}
                    project={projectToDelete}
                    close={() => setProjectToDelete(null)}
                />
            )}
            <ContextMenu contextMenuObservable={contextMenuInfo} />
            <ProjectKanban />
        </Box>
    );
};
