import { MouseEvent, useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

import { MenuRowOption } from '@/components/ui/inputs/Menu/types';
import { ContextMenuInfo } from '@/components/ui/inputs/ContextMenu';

export interface UseContextMenuObservablePayload {
    contextMenuInfo: BehaviorSubject<ContextMenuInfo | null>;
    closeContextMenu: () => void;
    openContextMenu: (e: MouseEvent, options: MenuRowOption[]) => void;
}
export const useContextMenuObservable = (): UseContextMenuObservablePayload => {
    // Observables
    /*-------------------*/
    const contextMenuInfo = new BehaviorSubject<ContextMenuInfo | null>(null);

    // Callbacks
    /*-------------------*/
    const closeContextMenu = useCallback(() => contextMenuInfo.next(null), [contextMenuInfo]);

    const openContextMenu = useCallback(
        (e: MouseEvent, options: MenuRowOption[]) =>
            contextMenuInfo.next({
                x: e.clientX,
                y: e.clientY,
                options,
            }),
        [contextMenuInfo]
    );

    return {
        contextMenuInfo,
        closeContextMenu,
        openContextMenu,
    };
};
