import React, { FC } from 'react';

import { CardBody, CardTitle, CardMessage, CardActionsGroup, CardActionButton } from './styled';

import { ReactComponent as RightArrow } from '@/assets/icons/arrow.svg';

type StatusCardProps = {
    color?: string;
    title?: string;
    message?: string;
    btnText?: string;
    btnHasBackground?: boolean;
    hasIcon?: boolean;
    showAction?: boolean;
    onClick?: () => void;
};

export const StatusCard: FC<StatusCardProps> = ({
    color,
    title,
    message,
    btnText,
    btnHasBackground,
    hasIcon,
    showAction,
    onClick,
}) => {
    return (
        <CardBody background={color || ''}>
            <CardTitle>{title}</CardTitle>
            <CardMessage>{message}</CardMessage>
            <CardActionsGroup>
                {showAction && (
                    <CardActionButton background={btnHasBackground} onClick={onClick}>
                        {btnText}
                        {hasIcon && <RightArrow />}
                    </CardActionButton>
                )}
            </CardActionsGroup>
        </CardBody>
    );
};
