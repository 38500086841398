/*
 * variables.ts
 *
 * Autogenerated from variables.yaml
 * DO NOT EDIT
 */

export const colorPrimary='#417CC0';
export const colorPrimaryLight='#68C1D6';
export const colorPrimaryLighter='#A0D1EC';
export const colorSecondary='#E0BFAD';
export const colorSecondaryLight='#EEE3CE';
export const colorSecondaryLighter='#FFFAF0';
export const colorTertiary='#A5B3B9';
export const colorTertiaryLight='#C4C4C4';
export const colorTertiaryLighter='#F6F8F8';
export const colorBackground='#1B2235';
export const colorBackgroundLight='#2B334E';
export const colorBackgroundLighter='#3D445C';
export const colorBackgroundSecondary='#5B606E';
export const colorBackgroundSecondaryLighter='#EEEEF1';
export const colorBackgroundSecondaryLightest='#FFFFFF';
export const colorBackgroundTertiary='#3C5F77';
export const colorBackgroundTertiaryLighter='#3A505F';
export const colorBackgroundPlaceholder='#D7E1E5';
export const colorFormulaError='#F35252';
export const colorFormulaSuccess='#00A7BE';
export const colorStatusInfo='#417BC0';
export const colorStatusSuccess='#0B8E7E';
export const colorStatusWarning='#F4B000';
export const colorStatusError='#ED7054';
export const colorTypography='#F6F8F8';
export const colorTypographyLight='#D7E1E5';
export const colorTypographyAlt='#F1F1EF';
export const colorTypographybody='#FFFFFF';
export const colorTypographyHeader='#F6F8F8';
export const colorTypographyHeaderAlt='#D6CAB4';
export const colorTypographyInfo='#FFFFFF';
export const colorTypographySection='#A5B3B9';
export const colorTypographyDark='#1B2235';
export const colorTypographyDarkSecondary='#5B606E';
export const colorTypographyDarkTertiary='#3A505F';
export const colorSelectedItem='#484F65';
export const colorSimpleBorder='#000000';
export const colorFigmaDemoBox='#9CA3A9';
export const colorOverlay='rgba(0, 0, 0, 0.8)';
export const colorOverlayLight='rgba(0, 0, 0, 0.73)';
export const colorOverlayLighter='rgba(165, 179, 185, 0.30)';
export const colorOverlayModal='rgba(0, 0, 0, 0.4)';
export const colorHighlight='rgba(196, 196, 196, 0.30)';
export const colorHighlightLight='#51A0FF';
export const colorHighlightTable='#D7EEF0';
export const colorOverlayFont='rgba(255, 255, 255, 0.8)';
export const colorCheckbox='#417BC0';
export const colorEstimateNoSpec='#5B606E';
export const colorEstimateMaterial='#1F57AF';
export const colorEstimateLabor='#ED6255';
export const colorEstimateEquipment='#FBC12C';
export const colorEstimateTableHighlight='#A6DFED';
export const colorHoverTranslucence='rgba(255, 255, 255, 0.07)';
export const colorHoverImpersonation='#E07512';
export const colorHoverLight='rgba(0, 0, 0, 0.25)';
export const colorProjectPendingAcceptance='#F35252';
export const colorProjectInProgress='#0B8E7E';
export const colorProjectPendingRevision='#4B5CB4';
export const colorDrawingTool1='#9B1C0F';
export const colorDrawingTool2='#E68F8F';
export const colorDrawingTool3='#FD13F9';
export const colorDrawingTool4='#FE1016';
export const colorDrawingTool5='#8271B8';
export const colorDrawingTool6='#AB9DCE';
export const colorDrawingTool7='#CF9CB4';
export const colorDrawingTool8='#8D0DF8';
export const colorDrawingTool9='#76BC7D';
export const colorDrawingTool10='#9CE9A4';
export const colorDrawingTool11='#A3AC6A';
export const colorDrawingTool12='#1AFEC7';
export const colorDrawingTool13='#417BE0';
export const colorDrawingTool14='#649ED4';
export const colorDrawingTool15='#9ABAEF';
export const colorDrawingTool16='#1AFEFE';
export const colorDrawingTool17='#E07512';
export const colorDrawingTool18='#D49364';
export const colorDrawingTool19='#FBD300';
export const colorDrawingTool20='#D8FB00';
export const colorAssemblyManagerTopbar='#0F1520';
export const colorAssemblyPanelSearchBackground='#F6F8F8';
export const colorAssemblyPanelSearchPlaceholder='#A5B3B9';
export const colorAssemblyPanelSearchText='#3D445C';
export const colorAssemblyPanelFlash='#166FF3';
export const colorTradeDemolition='#F35252';
export const colorTradeExteriorSiding='#73DDD7';
export const colorTradeConcrete='#8E9AAC';
export const colorTradeStructuralSteel='#A3C6D5';
export const colorTradeWoodFraming='#D49364';
export const colorTradeMetalFraming='#577773';
export const colorTradeDrywall='#AB9DCE';
export const colorTradeInsulation='#E68F8F';
export const colorTradeAcousticalCeiling='#D67CE5';
export const colorTradePainting='#8D0DF8';
export const colorTradeFlooring='#A3AC6A';
export const colorTradeTile='#21A7AF';
export const colorTradeFinishCarpentry='#D49364';
export const colorTradeMillwork='#B37923';
export const colorTradeDoors='#0B8E7E';
export const colorTradeWindows='#334AD7';
export const colorTradeStorefrontGlazingSystem='#3E85AC';
export const colorTradeRoofing='#A0B925';
export const colorTradePlumbing='#649ED4';
export const colorTradeMechanical='#F37952';
export const colorTradeElectrical='#00A7BE';
export const colorTradeEarthwork='#B85005';
export const colorTradeFlatwork='#599687';
export const colorTradeUndergroundUtilities='#006BA8';
export const colorTradeGeneralConditions='#00A843';
export const colorTradeEquipment='#5633A2';
export const colorTradeLowVoltage='#406EB4';
export const colorIconHighlight='#417BC0';
export const colorIconAlt='#E0BFAD';
export const backgroundActiveDashboardModule='rgba(215, 225, 229, 0.1)';
export const colorAdminDashboardSearchBackground='rgba(193, 197, 209, 0.17)';
export const colorAdminDashboardSearchText='#010F36';
export const colorAdminDashboardTextPlaceholder='#3D445C';
export const colorAdminDashboardModalText='#4C5C6C';
export const colorAdminDashboardModalButtonText='#3D445C';
export const colorAdminDashboardScrollbar='#A5B3B9';
export const colorProjectTableText='#4C5C6C';
export const colorProjectTableNotification='#F35252';
export const colorProjectAssignEstimator='#305DFF';
export const colorProjectPanelHeader='#2B334E';
export const colorProjectPanelText='#3D445C';
export const colorProjectPanelHighlight='#334AD7';
export const colorProjectPanelDueDate='#F35252';
export const colorProjectPanelBackgroundDark='#F6F8F8';
export const colorProjectPanelFileDivider='#A5B3B9';
export const colorProjectPanelFileError='#F35252';
export const colorProjectPanelActionRequired='#F35252';
export const colorProjectPanelTooltipText='#000000';
export const colorProjectTableDateInDanger='#FEF3F3';
export const colorProjectTableDateInDangerText='#F35252';
export const colorProjectPanelConfirmPrice='#EEF7FF';
export const colorProjectPanelConfirmPriceText='#128AFA';
export const colorAvatarEstimator='#21A7AF';
export const colorAvatarBuilder='#F59637';
export const colorAvatarAdmin='#FFFFFF';
export const fontPrimary='acumin-pro, Helvetica, Arial, sans-serif';
export const fontMono='IBM Plex Mono, monospace';
export const breakpointExtraSmall='634px';
export const breakpointSmall='768px';
export const breakpointMedium='1024px';
export const breakpointLarge='1280px';
export const transition='ease 0.3s';
export const borderBlueprint='1px solid #2B334E';
export const borderPrimary='1px solid #417CC0';
export const borderError='1px solid #ED7054';
export const borderInput='2px solid #3D445C';
export const borderSection='1px solid #3D445C';
export const borderVariableSection='2px solid #3D445C';
export const borderCheckbox='1px solid #5B606E';
export const borderKanbanAvailable='2px solid #4C5C6C';
export const borderKanbanNotSelected='2px solid #4C5C6C';
export const borderKanbanDraft='2px solid #F59637';
export const borderKanbanNotStarted='2px solid #ED7054';
export const borderKanbanEstimating='2px solid #E1A302';
export const borderKanbanComplete='2px solid #0B8E7E';
export const borderAllTrades='1px solid #3D445C';
export const borderAllTradesSecondary='1px solid #3D445C';
export const borderRadio='0.125rem solid #5B606E';
export const borderButton='1px solid';
export const borderCement='1px solid #D7E1E5';
export const borderQuartz='1px solid #3D445C';
export const boxShadowCement='0 0 0 1px #D7E1E5';
export const borderDiscussionsUploadedFile='1px solid #A5B3B9';
export const borderCircle='2px solid #2B334E';
export const borderCostBreakdown='1px solid rgba(255, 255, 255, 0.07)';
export const borderHeaderInput='2px solid #51A0FF';
export const borderHeaderInputError='2px solid #ED7054';
export const borderPanControl='1px solid rgba(255, 255, 255, 0.18)';
export const borderAdminDashboardSearch='1px solid #3C5F77';
export const borderAdminDashboardTable='1px solid #E7F1F5';
export const boarderAdminDashboardAdminStatus='1px solid #DEDEDE';
export const borderAdminDashboardTooltip='0.5px solid rgba(0, 0, 0, 0.05)';
export const shadowTooltip='0px 24px 32px rgba(0, 0, 0, 0.45)';
export const shadowMenu='0px 14px 24px rgba(0, 0, 0, 0.25)';
export const shadowDropdown='0px 7px 14px -6px rgba(0, 0, 0, 0.25)';
export const panelShadow='0 0.25rem 3.375rem #3D445C';
export const formShadow='0px -6px 24px rgba(0, 0, 0, 0.05)';
export const modalShadow='0px 54px 44px rgba(0, 0, 0, 0.65)';
export const adminDashboardMobileSidebarShadow='6px 0px 4px rgba(0, 0, 0, 0.43), 18px 4px 16px rgba(0, 0, 0, 0.4)';
export const adminDashboardPanelShadow='0px 100px 80px rgba(0, 0, 0, 0.3), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.215656), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.178832), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.15), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.121168), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0843437)';
export const adminDashboardTooltipShadow='0px 4px 8px rgba(0, 0, 0, 0.14)';
export const gradientEstimateBackground='linear-gradient( 139deg, #294c76, #243f62 12%, #223857 20%, #1e2b43 39%, #1b2235 53% )';
export const gradientAdminDashboardBackground='linear-gradient( 134.71deg, #32406C 1.82%, #1B2235 60.37%, #18213E 74.87% )';
export const gradientAdminDashboardMobileSidebar='linear-gradient( 151.62deg, #32406C 22.41%, #1B2235 71.53%, #18213E 79.95% )';
export const gradientAdminDashboardNotification='linear-gradient( 90.12deg, #6891CF 5.21%, #5278BC 47.19%, #2C4D9B 89.49% )';
export const minWidthProjectDetailsProjectInfo='18rem';
export const minWidthProjectDetailsProjectDiscussion='23.625rem';
