import styled from 'styled-components';
import { neutral } from '@/theme/colors/neutral';

//////////////////
// Copy section //
//////////////////
export const Underline = styled.span`
    display: inline-block;
    text-decoration: underline;
`;
export const CopyEdit = styled.span`
    display: inline-block;

    & > ${Underline} {
        margin-right: 4px;
    }
`;

export const Copy = styled.div`
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6875rem;
    line-height: 1.5rem;

    display: flex;
    align-items: center;

    color: #ffffff;

    opacity: 0.8;

    padding: 8px 0 7px 10px;
`;

/////////////
// Ratings //
/////////////
export const RatingBarWrapper = styled.div`
    height: 100%;
`;

/* eslint-disable @typescript-eslint/indent */
type ContainerProps = {
    isAnimated: boolean;
    isSelected: boolean;
    isNewReview: boolean;
};
export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    padding: 2px 3px;
    background-color: ${(props): string => {
        if (props.isSelected) return neutral[78];
        if (props.isNewReview) return neutral[78];
        return neutral[78];
    }};
    mix-blend-mode: normal;
    border-radius: 2px;
    height: 32px;
    box-sizing: border-box;

    & > ${RatingBarWrapper} {
        margin-left: auto;
    }

    transition: ${(props): string => (props.isAnimated ? 'background 0.4s' : 'none')};

    &:hover {
        & > ${Copy} {
            opacity: 1;
        }
    }
`;
/* eslint-enable @typescript-eslint/indent */
