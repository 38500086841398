import React, { FC } from 'react';
import { RenderElementProps } from 'slate-react';

export const Element: FC<RenderElementProps> = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'link':
            return (
                <a
                    {...attributes}
                    href={typeof element.link === 'string' ? element.link : undefined}
                >
                    {children}
                </a>
            );
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>;
        case 'paragraph':
            return <p {...attributes}>{children}</p>;
        default:
            return <span {...attributes}>{children}</span>;
    }
};
