import React, { FC, useEffect, useRef, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

import { Menu, MenuOrigin, MenuRowOption } from '@/components/ui/inputs/Menu';
import { useSimpleMenu } from '@/components/ui/inputs/Menu/hooks/useSimpleMenu';

export interface ContextMenuInfo extends MenuOrigin {
    options: MenuRowOption[];
}

interface ContextMenuProps {
    contextMenuObservable: BehaviorSubject<ContextMenuInfo | null>;
}
export const ContextMenu: FC<ContextMenuProps> = ({ contextMenuObservable }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    useSimpleMenu(() => contextMenuObservable.next(null), menuRef);

    const [menuInfo, setMenuInfo] = useState(contextMenuObservable.value);

    useEffect(() => {
        const subscription = contextMenuObservable.subscribe(setMenuInfo);
        return (): void => subscription.unsubscribe();
    }, [contextMenuObservable]);

    if (!menuInfo) {
        return <></>;
    }

    const { options, ...position } = menuInfo;
    return (
        <div ref={menuRef}>
            <Menu borderRadius={4} bottomPadding={8} options={options} position={position} />
        </div>
    );
};
