/* Provider that adds hot-keys handlers for given ref */

import React, { FC, useRef, useEffect, useState } from 'react';
import hotkeys from 'hotkeys-js';

import { DivProps, KeyHandlers, Nil } from '@/common/types';
import { makeHotKeys } from '@/common/hot-keys/hotKeys';

type HotKeysProps = DivProps & {
    scope: string;
    handlers: KeyHandlers;
};

const ROLE_HOTKEYS = 'hotkeys';

/*
    Recursively searches for, and returns closest parent element with 'hotkeys'
    role, or null if closest parent was not found.
*/
const getNearestHotkeysRoot = (el: Element): Nil<Element> => {
    if (el.attributes?.['role' as unknown as number]?.value === ROLE_HOTKEYS) return el;

    return el.parentElement && getNearestHotkeysRoot(el.parentElement);
};

export const HotKeys: FC<HotKeysProps> = ({ handlers, scope, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        if (!ref.current) return;
        return makeHotKeys(scope, ref.current, handlers);
    }, [ref, handlers, scope]);

    useEffect(() => {
        if (!isActive || !ref.current) return;

        hotkeys.setScope(scope);
    }, [ref, isActive]);

    useEffect(() => {
        const event = 'focusin';
        const handler = ({ target }: FocusEvent): void => {
            const nearestHotkeysRoot = getNearestHotkeysRoot(target as Element);
            setActive(ref.current === nearestHotkeysRoot);
        };

        addEventListener(event, handler);

        return (): void => removeEventListener(event, handler);
    }, []);

    return <div ref={ref} {...rest} tabIndex={-1} role={ROLE_HOTKEYS} />;
};
