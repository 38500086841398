/* Portal is a generic wrapper around elements of the page that we'd like to
 * render outside of the current DOM tree - it might be useful for panels,
 * topbars and tooltips.
 */
import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
    id?: string;
};

export const Portal: FC<PropsWithChildren<PortalProps>> = ({ id, children }) => {
    const mount = document.getElementById('portal-root');
    const element = id && document.getElementById(id);
    const el = element ? element : document.createElement('div');

    id && el.setAttribute('id', id);

    useEffect(() => {
        if (!mount) return;
        mount.appendChild(el);
        return (): void => {
            mount.removeChild(el);
        };
    }, [el, mount]);

    return createPortal(children, el);
};
