import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Button, ButtonContainer, Disclaimer, Container, Header, Subtitle, Text } from './styled';
import { onClickAndEnterKey } from '@/common/utils/eventHandlers';

const COPY = {
    header: 'How do you want to estimate this project?',
    subtitle: 'You can use our marketplace of estimators or our cloud-based software.',
    standardOption: 'Hire an Estimator',
    standardOptionDisclaimer: 'Pay per project',
    diyOption: 'Do it myself',
    diyOptionDisclaimer: 'Unlimited estimates',
};

interface DIYProps {
    onSelectDIY: () => void;
    onSelectStandard: () => void;
}
export const DIY: FC<DIYProps> = ({ onSelectDIY, onSelectStandard }) => {
    const focusRef = useRef<HTMLDivElement>(null);
    const [diyClicked, setDIYClicked] = useState(false);

    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    const handleDIY = () => {
        setDIYClicked(true);
        onSelectDIY();
    };

    const disabledStyle: CSSProperties = {
        pointerEvents: 'none',
        opacity: '50%',
        cursor: 'initial',
    };

    return (
        <Container>
            <Header>{COPY.header}</Header>
            <Subtitle>{COPY.subtitle}</Subtitle>

            <ButtonContainer>
                <Button tabIndex={0} {...onClickAndEnterKey(onSelectStandard)}>
                    <Text>{COPY.standardOption}</Text>
                    <Disclaimer>{COPY.standardOptionDisclaimer}</Disclaimer>
                </Button>
                <Button
                    style={diyClicked ? disabledStyle : {}}
                    ref={focusRef}
                    tabIndex={0}
                    {...onClickAndEnterKey(handleDIY)}
                >
                    <Text>{COPY.diyOption}</Text>
                    <Disclaimer>{COPY.diyOptionDisclaimer}</Disclaimer>
                </Button>
            </ButtonContainer>
        </Container>
    );
};
