/*
 * Files is the project panel's file listing module.
 */
import React, { FC, useEffect, useState } from 'react';

import Fuse from 'fuse.js';

import { AddFile } from './AddFile';
import {
    File,
    FileEntry,
    PanelProjectPlanFileRecord,
    PanelProjectUploadFileRecord,
    ProjectFileType,
} from './File';
import { FileInProgress } from './FileInProgress';
import { LabeledDivider } from './LabeledDivider';
import { Search } from './Search';
import { FilesActionsContainer, FilesContainer, FilesHeader } from './styled';
import {
    fetchProjectUploads,
    nestArchiveChildren,
    removeRecordByUUID,
    updateRecordArr,
} from './utils';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { compareDateStrings } from '@/components/AdminDashboard/Projects/sort';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useStorage } from '@/contexts/Storage';
import { EventTypeName } from '@/queries/eventTypes';
import { useEventsQuery } from '@/queries/events';
import { useProjectPlanFilesQuery } from '@/queries/projectPlanFiles';
import { useDeleteProjectPlanFileSubscription } from '@/subscriptions/projectPlanFiles';
import {
    useDeleteProjectUploadFileSubscription,
    useProjectUploadFileSubscription,
} from '@/subscriptions/projectUploadFiles';

const COPY = {
    projectCreated: 'Project created',
    projectFiles: 'Project files',
};

export const Files: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { fileInProgress, setFileInProgress, fileSearchString, project } = useProjectPanel();
    const { getUrl } = useStorage();

    const [deletedUploadFiles, setDeletedUploadFiles] = useState<string[]>([]);
    const [fileEntries, setFileEntries] = useState<FileEntry[]>([]);
    const [searchedFileEntries, setSearchedFileEntries] = useState<FileEntry[]>([]);
    const [fileFuse, setFileFuse] = useState<Fuse<FileEntry> | undefined>();
    const [loading, setLoading] = useState<[boolean, boolean]>([true, true]);
    const [planFiles, setPlanFiles] = useState<PanelProjectPlanFileRecord[]>([]);
    const [uploadFiles, setUploadFiles] = useState<PanelProjectUploadFileRecord[]>([]);
    const [eventsQueryResult] = useEventsQuery({
        projectId: project.id,
        eventTypeName: EventTypeName.UploadProjectRelatedFiles,
    });
    const [projectPlanFilesResult] = useProjectPlanFilesQuery({ projectId: project.id });
    const deleteProjectPlanFileSubscriptionResult = useDeleteProjectPlanFileSubscription();
    const projectUploadFileSubscriptionResult = useProjectUploadFileSubscription();
    const deleteProjectUploadFileSubscriptionResult = useDeleteProjectUploadFileSubscription();

    useEffect(() => {
        if (fileSearchString === '') {
            setSearchedFileEntries([]);
            return;
        }
        if (fileFuse !== undefined) {
            setSearchedFileEntries(fileFuse.search(fileSearchString).map((res) => res.item));
        }
    }, [fileSearchString, fileFuse]);

    useEffect(() => {
        if (projectPlanFilesResult.fetching) {
            setLoading((oldLoading) => [true, oldLoading[1]]);
            return;
        } else if (projectPlanFilesResult.data !== undefined) {
            setLoading((oldLoading) => [false, oldLoading[1]]);
        }

        // Extract file records and filter out directories created by unzipping zips.
        const newFiles = projectPlanFilesResult.data?.projectPlanFiles.nodes.filter(
            (file) => !file.filename.endsWith('/')
        );
        if (newFiles !== undefined) {
            Promise.all(
                newFiles.map(async (file) =>
                    getUrl(project.uuid, file.uuid, 1, 1, true).then((thumbUrl) => ({
                        ...file,
                        fileType: ProjectFileType.Plan,
                        thumbUrl,
                    }))
                )
            ).then(setPlanFiles);
        }
    }, [projectPlanFilesResult]);

    useEffect(() => {
        if (eventsQueryResult.fetching) {
            setLoading((oldLoading) => [oldLoading[0], true]);
            return;
        }
        if (eventsQueryResult.data?.events.nodes !== undefined) {
            fetchProjectUploads(eventsQueryResult.data.events.nodes, deletedUploadFiles).then(
                (uploads) => {
                    setUploadFiles(uploads);
                    setLoading((oldLoading) => [oldLoading[0], false]);
                }
            );
        }
    }, [eventsQueryResult]);

    // When either file list changes, sort them, set the search helper, and nest archive children.
    useEffect(() => {
        const sortedFiles: FileEntry[] = [...planFiles, ...uploadFiles].sort((a, b) =>
            compareDateStrings(a.created, b.created)
        );
        setFileFuse(new Fuse(sortedFiles, { keys: ['filename'] }));
        setFileEntries([...nestArchiveChildren(sortedFiles)]);
    }, [planFiles, uploadFiles]);

    useEffect(() => {
        const changedUploadFile =
            projectUploadFileSubscriptionResult.data?.ProjectUploadFile.projectUploadFileEntry;
        if (changedUploadFile === undefined || changedUploadFile === null) {
            return;
        }
        setUploadFiles((files) => [
            ...updateRecordArr(files, { ...changedUploadFile, fileType: ProjectFileType.Upload }),
        ]);

        // If currently-uploading project record is received, remove progress indicator.
        if (fileInProgress?.uuid === changedUploadFile.uuid) {
            setFileInProgress(undefined);
        }
    }, [projectUploadFileSubscriptionResult]);

    useEffect(() => {
        const deletedUploadFile =
            deleteProjectUploadFileSubscriptionResult.data?.DeleteProjectUploadFile
                .deletedProjectUploadFileEntry;
        if (deletedUploadFile !== undefined && deletedUploadFile !== null) {
            setDeletedUploadFiles((files) => {
                if (!files.includes(deletedUploadFile.uuid)) {
                    return [...files, deletedUploadFile.uuid];
                }
                return files;
            });
            setUploadFiles((files) => removeRecordByUUID(files, deletedUploadFile.uuid));
        }
    }, [deleteProjectUploadFileSubscriptionResult]);

    useEffect(() => {
        const deletedPlanFile =
            deleteProjectPlanFileSubscriptionResult.data?.DeleteProjectPlanFile
                .deletedProjectPlanFileEntry;
        if (deletedPlanFile !== undefined && deletedPlanFile !== null) {
            setPlanFiles((files) => removeRecordByUUID(files, deletedPlanFile.uuid));
        }
    }, [deleteProjectPlanFileSubscriptionResult]);

    // Get a list of files with dividers inserted properly.
    const getFileElements = (): JSX.Element[] => {
        if (searchedFileEntries.length > 0) {
            return searchedFileEntries.map((file, idx) => (
                <File key={idx} file={file} useProjectPanel={useProjectPanel} hideChildren={true} />
            ));
        }
        const elementList: JSX.Element[] = [];
        let dividerInserted = false;
        const creationDate: Date | null =
            project.created === undefined ? null : new Date(project.created);

        for (let i = 0; i < fileEntries.length; i++) {
            const curFileCreationString = fileEntries[i].created;
            if (
                !dividerInserted &&
                creationDate !== null &&
                curFileCreationString !== undefined &&
                new Date(curFileCreationString).getTime() <= creationDate.getTime()
            ) {
                elementList.push(<LabeledDivider key="file-divider" label={COPY.projectCreated} />);
                dividerInserted = true;
            }
            elementList.push(
                <File key={i} file={fileEntries[i]} useProjectPanel={useProjectPanel} />
            );
        }

        if (!dividerInserted && creationDate !== null) {
            elementList.push(<LabeledDivider key="file-divider" label={COPY.projectCreated} />);
        }
        return elementList;
    };

    if (loading.includes(true)) {
        return <SpinnerLoader />;
    }

    return (
        <FilesContainer>
            <FilesHeader>{COPY.projectFiles}</FilesHeader>
            <FilesActionsContainer>
                <Search useProjectPanel={useProjectPanel} />
                <AddFile useProjectPanel={useProjectPanel} />
            </FilesActionsContainer>
            {fileInProgress && <FileInProgress useProjectPanel={useProjectPanel} />}
            {getFileElements()}
        </FilesContainer>
    );
};
