import { useEffect, useState } from 'react';

import { compareDesc } from 'date-fns';
import to from 'await-to-js';
import { QueryHookOptions } from '@apollo/client';

import {
    useProjectReviewWithReviewTagsCreationMutation,
    IClientPreferenceStatus,
    IPreferredByClientQuery,
    IPreferredByClientQueryVariables,
    usePreferredByClientLazyQuery,
    IReviewTagFragment,
} from '@/graphql';

import { ProjectRecord } from '@/queries/projects';
import { BaseUserRecord } from '@/queries/baseUsers';
import { ProjectReviewStatus, ProjectReview, Hook, Setter } from '@/common/types';

import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { isAdministratorUser } from '@/views/Projects/utils';

const COPY = {
    submitReviewError: 'Team ID or Estimator ID cannot be empty',
    submitReviewTitle: 'Feedback',
    submitReviewMessage: 'Review submitted',
};

export interface UseProjectReviewProps {
    project?: ProjectRecord;
    estimator?: BaseUserRecord;
}

export interface UseProjectReviewRes {
    review: ProjectReview;
    handleReviewStarChange: (starValue: number | null) => void;
    handleReviewStatusChange: (status: ProjectReviewStatus) => void;
    handleReviewTagChange: (updatedReviewTag: IReviewTagFragment) => void;
    handlePreferenceStatusChange: (preference: IClientPreferenceStatus) => void;
    handleReviewCommentChange: (comment: string) => void;
    handleSubmitReview: () => Promise<void>;
    setRating: Setter<number>;
    returnDate: (possibleDate: Date | string) => Date;
    allFieldsFilled: boolean;
}

export const useProjectReview: Hook<UseProjectReviewRes, UseProjectReviewProps> = ({
    project,
    estimator,
}): UseProjectReviewRes => {
    const {
        data: { user },
    } = useUser();

    const { addNotification } = useNotifications();

    const [projectReviewWithReviewTagsCreationMutation] =
        useProjectReviewWithReviewTagsCreationMutation();

    const returnDate = (possibleDate: string | Date): Date =>
        typeof possibleDate === 'string' ? new Date(possibleDate) : possibleDate;

    const currentReview = [...(project?.projectReviews?.nodes || [])]
        .sort((a, b) =>
            compareDesc(
                a.lastModified ? returnDate(a.lastModified) : 0,
                b.lastModified ? returnDate(b.lastModified) : 0
            )
        )
        .find(
            (review) =>
                review.ownerId.toString() === user.id ||
                (estimator && estimator.id.toString() === user.id) ||
                isAdministratorUser(user)
        );

    const currentReviewTags =
        currentReview?.projectReviewTags?.nodes.reduce(
            (acc, { reviewTag }) => ({ ...acc, [reviewTag.id]: reviewTag }),
            {}
        ) || {};

    const [rating, setRating] = useState<number>(Math.min(currentReview?.rating || 0, 5));
    const [comment, setComment] = useState<string>(currentReview?.comment || '');
    const [reviewTags, setReviewTags] =
        useState<Record<string, IReviewTagFragment>>(currentReviewTags);
    const [preferenceStatus, setPreferenceStatus] = useState<IClientPreferenceStatus>(
        IClientPreferenceStatus.Neutral
    );
    const [status, setStatus] = useState(
        rating === 0 ? ProjectReviewStatus.NotStarted : ProjectReviewStatus.Finished
    );

    const hasReviewRating = rating > 0;
    const isFinished = status === ProjectReviewStatus.Finished && hasReviewRating;

    const projectName = project?.name ?? '';

    const estimatorId = estimator?.id?.toString();
    const estimatorName = `${estimator?.firstName ?? ''} ${estimator?.lastName ?? ''}`;

    const teamId = project?.teamId?.toString();

    const allFieldsFilled =
        rating > 0 &&
        comment !== '' &&
        Object.entries(reviewTags).length !== 0 &&
        preferenceStatus !== IClientPreferenceStatus.Neutral;

    const preferredByClientInput = (
        estimatorId: string,
        teamID: string
    ): QueryHookOptions<IPreferredByClientQuery, IPreferredByClientQueryVariables> => ({
        variables: {
            input: {
                id: estimatorId,
                teamID,
            },
        },
    });

    const handleReviewStarChange = (starValue: number | null) => {
        if (isFinished || !starValue) return;

        setRating(starValue || 0);
        setReviewTags({});
    };

    const handleReviewStatusChange = (status: ProjectReviewStatus) => {
        setStatus(status);
    };

    const handleReviewTagChange = (updatedReviewTag: IReviewTagFragment) => {
        if (isFinished) return;

        const currentReviewTags = reviewTags;

        if (currentReviewTags[updatedReviewTag.id]) {
            delete currentReviewTags[updatedReviewTag.id];
        } else {
            currentReviewTags[updatedReviewTag.id] = updatedReviewTag;
        }

        setReviewTags({ ...currentReviewTags });
    };

    const handlePreferenceStatusChange = (preference: IClientPreferenceStatus) => {
        if (isFinished) return;
        setPreferenceStatus(preference);
    };

    const handleReviewCommentChange = (comment: string) => {
        setComment(comment);
    };

    const [
        fetchPreferredByClient,
        { loading: loadingPreferredByClient, error: errorPreferredByClient },
    ] = usePreferredByClientLazyQuery({
        onCompleted: (data) => handleSetPreferredByClient(data),
        fetchPolicy: 'cache-and-network',
    });

    const handleSetPreferredByClient = (data: IPreferredByClientQuery) => {
        try {
            if (errorPreferredByClient) {
                throw new Error(errorPreferredByClient.message);
            }

            const { preferredByClient } = data;

            if (!preferredByClient || loadingPreferredByClient) return;

            setPreferenceStatus(preferredByClient.preference);
        } catch (e) {
            addNotification({ title: 'Error', content: String(e) }, 'error');
        }
    };

    const handleSubmitReview = async () => {
        try {
            const reviewTagsForRating = Object.values(reviewTags).filter(({ isPositive }) =>
                rating === 5 ? isPositive : !isPositive
            );

            if (!teamId || !estimatorId) throw new Error(COPY.submitReviewError);

            const [error] = await to(
                projectReviewWithReviewTagsCreationMutation({
                    variables: {
                        input: {
                            projectID: String(project?.id),
                            ownerID: user.id,
                            rating,
                            comment,
                            reviewTags: reviewTagsForRating.map(({ id, tag }) => ({ id, tag })),
                            preference: {
                                id: estimatorId,
                                teamID: teamId,
                                status: preferenceStatus,
                            },
                        },
                    },
                })
            );

            if (error) throw error;

            addNotification(
                {
                    title: COPY.submitReviewTitle,
                    content: COPY.submitReviewMessage,
                },
                'success'
            );
        } catch (e) {
            addNotification({ title: 'Error', content: String(e) }, 'error');
        } finally {
            setStatus(ProjectReviewStatus.Finished);
        }
    };

    useEffect(() => {
        setRating(Math.min(currentReview?.rating || 0, 5));
        setComment(currentReview?.comment || '');
        setReviewTags(currentReviewTags);
        setStatus(!currentReview ? ProjectReviewStatus.NotStarted : ProjectReviewStatus.Finished);

        if (!estimatorId || !teamId) return;
        fetchPreferredByClient(preferredByClientInput(estimatorId, teamId));
    }, [currentReview]);

    return {
        review: {
            currentReviewRating: currentReview?.rating,
            rating,
            status,
            comment,
            reviewTags,
            preferenceStatus,
            isFinished,
            lastModified: currentReview?.lastModified,
            projectInfo: {
                projectName,
                estimatorName,
                projectUUID: project?.uuid ?? '',
            },
        },
        handleReviewStarChange,
        handleReviewStatusChange,
        handleReviewTagChange,
        handlePreferenceStatusChange,
        handleReviewCommentChange,
        handleSubmitReview,
        setRating,
        returnDate,
        allFieldsFilled,
    };
};
