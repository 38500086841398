import styled from 'styled-components';

import { borderCement, colorProjectPanelHighlight, colorTypographyDark } from '@/variables';

export const EstimatorManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem 0;
`;

export const EstimatorManagementHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.125rem 0 2rem;
`;

export const EstimatorManagementRemove = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 2rem;
    padding: 0 0.75rem;
    border: ${borderCement};
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: ${colorProjectPanelHighlight};
    cursor: pointer;
`;

export const EstimatorManagementHeaderText = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: ${colorTypographyDark};
`;
