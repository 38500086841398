// References:
// - https://github.com/streamich/react-use/blob/master/src/useObservable.ts
// - https://github.com/antonioru/beautiful-react-hooks/blob/master/src/useObservable.ts
import { useEffect, useState } from 'react';

import { Observable } from 'rxjs';

export function useObservable<T>(observable$: Observable<T>): T | undefined;
export function useObservable<T>(observable$: Observable<T>, initialValue: T): T;
export function useObservable<T>(observable$: Observable<T>, initialValue?: T): T | undefined {
    const [value, update] = useState<T | undefined>(initialValue);

    useEffect(() => {
        const s = observable$.subscribe(update);
        return () => s.unsubscribe();
    }, [observable$]);

    return value;
}
