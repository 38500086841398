import { Transforms } from '../../transforms';
import {
    generateRateStrings,
    isValidUnFormattedNumber,
    parseRateValues,
    ratesAreEqual,
    ratesFromCostPerUnit,
    rateStringsContainsEmpty,
    unFormatNumber,
} from '../../utils/rates';
import { ElementRateStrings } from '../../utils/types';
import { Cell } from '../components/Cell';
import { Rates } from '../components/Rates';
import {
    ConventionalInput,
    ConventionalInputWrapper,
} from '@/components/Estimate/Table/cells/styled';
import { useApolloClient } from '@apollo/client';
import Popover from '@mui/material/Popover';
import { CellRenderer } from 'leyden-react';
import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useSelected, useSlateStatic } from 'slate-react';
import { toCentsAmount } from '@/common/currency';
import { isNaiveNumericInput } from '@/common/utils/helpers';
import { useSourceDescriptionLazyQuery } from '@/graphql';

export const Rate: CellRenderer<'Rate'> = ({ attributes, children, element }) => {
    const client = useApolloClient();
    const editor = useSlateStatic();
    const selected = useSelected();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const elementName = element.name;

    const [costPerUnitModified, setCostPerUnitModified] = useState(false);
    const [editedRateStrings, setEditedRateStrings] = useState<ElementRateStrings>(
        generateRateStrings(element)
    );
    const [editingDiscreteRates, setEditingDiscreteRates] = useState(false);
    // Prevent saves for non-selected cells on initial render and selected cells on edit cancellation.
    const [preventSave, setPreventSave] = useState(!selected);

    // MUI popover attributes
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [
        fetchSourceDescription,
        { data: sourceDescriptionData, loading: sourceDescriptionLoading },
    ] = useSourceDescriptionLazyQuery();

    const lazyLoadDescription = () =>
        fetchSourceDescription({
            variables: { input: { name: String(elementName) } },
        });

    const sourceDescription = sourceDescriptionData?.sourceDescription?.description ?? '';

    // Whenever the rate source of truth changes, reflect it in the editable rate strings.
    useEffect(() => {
        setEditedRateStrings(generateRateStrings(element));
    }, [element.laborRate, element.materialRate, element.productionRate]);

    // Focus the cost per unit input when the cell is selected.
    useEffect(() => {
        if (selected && inputRef.current) {
            inputRef.current.select();
        }
    }, [selected]);

    useEffect(() => {
        // On rate cell selection, reset the editable strings and listen for `Enter` (save)
        // or `Escape` (cancel) keystrokes.

        if (selected) {
            setEditedRateStrings(generateRateStrings(element));
            setCostPerUnitModified(false);
            setPreventSave(false);
            // key handling for rate input in table cell
            const handleKeyDown = (e: KeyboardEvent): void => {
                if (e.defaultPrevented) {
                    return;
                }
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    Transforms.moveCellSelection(editor, { direction: 'down' });
                    return;
                }
                if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    setPreventSave(true);
                    Transforms.deselect(editor);
                    return;
                }
            };
            document.addEventListener('keydown', handleKeyDown);
            return (): void => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        // On rate cell deselection, save only if `preventSave` has not been toggled to false
        // and no values have been set to the empty string.
        if (preventSave || rateStringsContainsEmpty(editedRateStrings)) {
            setEditedRateStrings(generateRateStrings(element));
            return;
        }
        if (editingDiscreteRates) {
            const newRateValues = parseRateValues(editedRateStrings);
            if (ratesAreEqual(element, newRateValues)) {
                return;
            }
            Transforms.setElementRates(editor, newRateValues, element, client);
            // always set editingDiscreteRates to false after a save to avoid weirdness (https://github.com/1build/1build/pull/28301.00)
            setEditingDiscreteRates(false);
        } else if (costPerUnitModified) {
            const newCostPerUnit = toCentsAmount(editedRateStrings.costPerUnit);

            if (!isValidUnFormattedNumber(editedRateStrings.costPerUnit)) {
                setEditedRateStrings(generateRateStrings(element));
                return;
            }

            Transforms.setElementCostPerUnit(editor, newCostPerUnit, element, client);
        }
        // cell selection and popover rate dropdown now work independently.
        // check for cell selection change or closure of popover with rates
        // (anchorEl = null means that the popover is closed)
    }, [selected, anchorEl]);

    const handleCaretClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        // caret click means that the rate popover is open, so the editing of discrete rates is possible
        // (there is a different check that discrete rates are changed and are not empty on popover closure).
        setEditingDiscreteRates(true);
        setAnchorEl(event.currentTarget);

        lazyLoadDescription();
    };

    const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;

        const parsedValue = unFormatNumber(value);

        if (!isNaiveNumericInput(parsedValue)) {
            return;
        }

        const newCostPerUnit = toCentsAmount(parsedValue);

        setCostPerUnitModified(true);

        const newRates = ratesFromCostPerUnit(newCostPerUnit);
        const rateStrings = generateRateStrings(newRates);

        setEditedRateStrings({
            ...rateStrings,
            costPerUnit: parsedValue,
        });
    };

    // If `tab` is hit while the cost per unit input is selected, move the cell selection (which
    // saves the rate). This does not happen for individual rates, enabling users to tab through them
    // without accidentally saving.
    const handleCostPerUnitKeyDown: KeyboardEventHandler<HTMLDivElement | HTMLInputElement> = (
        e
    ) => {
        if (!e.defaultPrevented && e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            Transforms.moveCellSelection(editor, { direction: 'right' });
            return;
        }
    };

    const setRateValues = (rates: Partial<Omit<ElementRateStrings, 'costPerUnit'>>): void => {
        const changedRates = new Map<keyof ElementRateStrings, string>();
        if (rates.laborRate !== undefined && isNaiveNumericInput(rates.laborRate)) {
            changedRates.set('laborRate', rates.laborRate);
        }
        if (rates.productionRate !== undefined && isNaiveNumericInput(rates.productionRate)) {
            changedRates.set('productionRate', rates.productionRate);
        }
        if (rates.materialRate !== undefined && isNaiveNumericInput(rates.materialRate)) {
            changedRates.set('materialRate', rates.materialRate);
        }
        if (changedRates.size === 0) {
            return;
        }
        setEditedRateStrings((oldEditedRateStrings) => {
            const newEditedRateStrings = { ...oldEditedRateStrings };
            changedRates.forEach((val, key) => {
                newEditedRateStrings[key] = val;
            });
            return newEditedRateStrings;
        });
    };

    const renderRate = (): JSX.Element => {
        if (!selected) {
            return <>{editedRateStrings.costPerUnit}</>;
        }
        return (
            <ConventionalInputWrapper>
                <ConventionalInput
                    value={unFormatNumber(editedRateStrings.costPerUnit)}
                    onChange={handleRateChange}
                    onKeyDown={handleCostPerUnitKeyDown}
                    ref={inputRef}
                />
            </ConventionalInputWrapper>
        );
    };

    return (
        <>
            <Cell
                attributes={attributes}
                caret="hide-inactive"
                onCaretClick={handleCaretClick}
                element={element}
                hasClickAction={true}
                highlightOnHover={true}
                style={
                    selected
                        ? {
                              zIndex: 10,
                          }
                        : {}
                }
            >
                {renderRate()}
                {children}
            </Cell>
            <Popover
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                // key handling for popover (dropdown)
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setAnchorEl(null);
                        Transforms.moveCellSelection(editor, { direction: 'down' });
                        return;
                    }
                    if (e.key === 'Escape') {
                        setPreventSave(true);
                        Transforms.deselect(editor);
                    }
                }}
            >
                <Rates
                    rates={editedRateStrings}
                    setRates={setRateValues}
                    sourceDescriptionLoading={sourceDescriptionLoading}
                    sourceDescription={String(sourceDescription)}
                />
            </Popover>
        </>
    );
};
