import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';
import { Manual } from './manual/Manual';
import { Standard } from './standard/Standard';
import { getScaleAsFraction } from './utils';
import { ReactComponent as RulerIcon } from '@/assets/icons/32/ruler.svg';
import { ToolType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { toolObservable } from '@/components/takeoff/observables/interface';
import React, { FC, useRef, useState } from 'react';

const COPY = {
    noScale: 'Set scale',
};

export interface CalibrationProps extends TakeoffComponentProps {
    onOpen?: () => void;
}

export const Calibration: FC<CalibrationProps> = ({ onOpen, useTakeoff }) => {
    const theme = useTheme();

    const [tab, setTab] = useState(0);

    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const { setTool, isManualCalibration, setIsManualCalibration, currentPage, scale } =
        useTakeoff();

    const tool = toolObservable.value;

    const isOpen = tool === ToolType.CALIBRATION;

    const getHumanReadableScale = (): string => {
        if (scale && currentPage !== null && currentPage !== undefined) {
            return getScaleAsFraction(Number(scale), currentPage);
        } else {
            return COPY.noScale;
        }
    };

    const close = (): void => setTool(ToolType.SELECTION);

    const open = (): void => {
        if (isOpen) {
            setTool(ToolType.SELECTION);
        } else {
            onOpen?.();
        }
    };

    return (
        <Box>
            <Button
                color="secondary"
                onClick={() => open()}
                ref={buttonRef}
                startIcon={
                    <SvgIcon
                        viewBox="0 0 32 32"
                        sx={{
                            height: '22px',
                        }}
                    >
                        <RulerIcon />
                    </SvgIcon>
                }
                sx={{
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: 0,
                    textTransform: 'none',
                    width: '311px',
                }}
            >
                <Typography
                    sx={{
                        padding: '4px 0',
                    }}
                    variant="body2"
                >
                    {getHumanReadableScale()}
                </Typography>
            </Button>
            <Popper
                anchorEl={buttonRef.current}
                open={isOpen}
                placement="bottom-start"
                style={{
                    zIndex: theme.zIndex.mobileStepper,
                }}
            >
                <Paper
                    square={false}
                    sx={{
                        borderRadius: 0,
                        height: '475px',
                        width: '311px',
                    }}
                >
                    <Tabs
                        onChange={(event, value) => {
                            setTab(value);

                            if (value === 0) {
                                setIsManualCalibration(true);
                            } else {
                                setIsManualCalibration(false);
                            }
                        }}
                        value={tab}
                        sx={{
                            borderBottom: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                            minHeight: 'auto',
                        }}
                        variant="fullWidth"
                    >
                        <Tab
                            label="Calibrate"
                            sx={{
                                minHeight: '40px',
                            }}
                        />
                        <Tab
                            label="Use standard"
                            sx={{
                                minHeight: '40px',
                            }}
                        />
                    </Tabs>
                    {isManualCalibration ? (
                        <Manual close={close} useTakeoff={useTakeoff} />
                    ) : (
                        <Standard close={close} useTakeoff={useTakeoff} />
                    )}
                </Paper>
            </Popper>
        </Box>
    );
};
