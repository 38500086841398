import { breakpoints } from '@/common/styles/helpers/breakpoints';
import { colorTypographyDark } from '@/variables';
import styled from 'styled-components';

export const Header = styled.h2`
    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.01em;
`;

export const Subtitle = styled.p`
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
`;

////////////////////
// Link section //
////////////////////

const gridTemplateLink = `
    " header     preview      " auto
    " .          .            " 9px
    " link       copy-button  " 40px
/ 1fr 1fr`;

export const LinkContainer = styled.div`
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    margin: 16px 0;

    display: grid;
    grid-template: ${gridTemplateLink};
    align-items: center;
`;

export const LinkHeader = styled.span`
    grid-area: header;
`;

export const LinkText = styled.span`
    grid-area: link;
    background: rgba(37, 48, 84, 0.07);
    box-sizing: border-box;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;
    width: 432px;
`;

export const LinkCopy = styled.span`
    grid-area: copy-button;
    color: #166ff3;
    background: rgba(37, 48, 84, 0.07);
    box-sizing: border-box;
    padding: 8px;
    cursor: pointer;
    text-align: center;
`;

////////////////////
// Button section //
////////////////////
export const ExportButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 4px;
    padding: 12px;
    color: #166ff3;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &:hover {
        background: #f0f1f3;
    }

    & > svg {
        margin-right: 8px;
    }
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    display: flex;
    & > ${ExportButton} {
        &:not(last-child) {
            margin-right: 16px;
        }
    }
`;

////////////////////////
// Wrapper containers //
////////////////////////
export const Overlay = styled.div`
    cursor: default;
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    @media print {
        display: none;
    }
`;

export const Container = styled.div`
    cursor: default;
    box-sizing: border-box;
    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 32px 46px 32px;
    background: #ffffff;
    box-shadow: 0px 51px 67px rgba(0, 0, 0, 0.07), 0px 14.5916px 12.4842px rgba(0, 0, 0, 0.0503198),
        0px 7.67308px 4.42419px rgba(0, 0, 0, 0.0417275),
        0px 4.5434px 2.49313px rgba(0, 0, 0, 0.035),
        0px 2.74017px 1.89164px rgba(0, 0, 0, 0.0282725),
        0px 1.36904px 1.23029px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 604px;
    & > ${Header} {
        margin-top: 0;
        margin-bottom: 0px;
        max-width: 486px;
    }
    ${breakpoints.small`
        max-width: 95vw;
        max-height: 90vh;
        overflow-y: auto;
        & > ${Header} {
            margin-bottom: 16px;
        }
    `}
`;
