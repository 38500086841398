import Stack, { StackProps } from '@mui/material/Stack';
import React, { FC } from 'react';
import { ColorPicker } from './ColorPicker';
import { LineTypePicker } from './LineTypePicker';
import { LineWeightPicker } from './LineWeightPicker';

const LinearPanel: FC<StackProps> = () => {
    return (
        <Stack direction="row" spacing="8px">
            <LineWeightPicker />
            <LineTypePicker />
            <ColorPicker />
        </Stack>
    );
};

export default LinearPanel;
