import { CardProps } from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import capitalize from 'lodash/capitalize';
import { format } from 'date-fns';
import { formatters, toDollarAmount } from '@/common/currency';
import { ITeamBillingFragment } from '@/graphql';
import { unknownPlan } from '@/components/pricing/PricingPage/plans';
import {
    getPlanByVariantId,
    getPlanVariantByPriceId,
} from '@/components/pricing/PricingPage/utils';
import { Plan } from '@/components/pricing/PricingPage/types';
import {
    Card,
    CardHeading,
    VariantHeading,
    FeaturesHeading,
    PriceHeading,
    RenewalHeading,
    Section,
    SectionGroup,
    HighlightListItemIcon,
} from './styled';
import { ReactComponent as Checkmark } from '@/assets/icons/checkmark-currentColor.svg';

interface Props extends CardProps {
    plans?: Plan[];
    team?: ITeamBillingFragment;
}

export const PlanCard: FC<Props> = ({ plans, team, ...props }) => {
    const plan = getPlanByVariantId(plans, team?.subscription?.priceID) || unknownPlan;
    const planVariant = getPlanVariantByPriceId(plans, team?.subscription?.priceID);

    return (
        <Card elevation={8} {...props}>
            <Section>
                <CardHeading variant="overline">Your plan</CardHeading>
                <VariantHeading variant="h3">{plan?.name}</VariantHeading>
                <Typography variant="body1">{plan?.description}</Typography>
            </Section>
            <SectionGroup>
                {plan?.highlights && (
                    <Section>
                        <FeaturesHeading variant="overline">Features</FeaturesHeading>
                        <List dense disablePadding>
                            {plan.highlights.map((highlight) => (
                                <ListItem dense disableGutters key={highlight}>
                                    <HighlightListItemIcon>
                                        <Checkmark />
                                    </HighlightListItemIcon>
                                    <ListItemText>{highlight}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Section>
                )}
                {planVariant?.price && (
                    <div>
                        <Section>
                            <PriceHeading variant="overline">Price</PriceHeading>
                            <Typography variant="body1">
                                {formatters
                                    .usd()
                                    .format(toDollarAmount(team?.subscription?.amount))}{' '}
                                {capitalize(planVariant?.period)}
                            </Typography>
                        </Section>
                        <Section>
                            <RenewalHeading variant="overline">Renewal date</RenewalHeading>
                            <Typography variant="body1">
                                {format(
                                    new Date(team?.subscription?.currentPeriodEnd),
                                    'MM/dd/yyyy'
                                )}
                            </Typography>
                        </Section>
                    </div>
                )}
            </SectionGroup>
        </Card>
    );
};
