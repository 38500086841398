import { useLeydenStatic } from 'leyden-react';
import { useEffect, useState } from 'react';

import { EstimateEditor } from '../editor/EstimateEditor';

export const useCategoryIsCollapsed = (categoryID: string | null): boolean => {
    const editor = useLeydenStatic();

    const [categoryIsCollapsed, setCategoryIsCollapsed] = useState(() => {
        if (categoryID === null) {
            return false;
        }
        return EstimateEditor.isCategoryCollapsed(editor, categoryID);
    });

    useEffect(() => {
        const unsubscribe = EstimateEditor.subscribeToCollapsedCategories(editor, (categories) => {
            if (categoryID === null) {
                setCategoryIsCollapsed(false);
                return;
            }
            setCategoryIsCollapsed(categories.has(categoryID));
        });
        return (): void => {
            unsubscribe();
        };
    }, [categoryID]);

    return categoryIsCollapsed;
};
