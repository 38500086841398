/**
 * Generic form control that wraps elements like 'input' and
 * 'dropdown' and adds label and validation logic.
 */

import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { DivProps, Nil } from '@/common/types';

import './FormControl.scss';

type FormComponentProps = {
    label?: string;
    required?: boolean;
    value: string | number | Nil<Date>;
};

type ControlProps<T> = FormComponentProps & Omit<T, 'onChange'>;

type FormControlProps = FormComponentProps & DivProps;

/* Props for form controls that are text based (input, textarea) */
export type TextControlProps<T> = ControlProps<T> & {
    value: string;
    onChange: (v: string) => void;
};

/* Props for form controls that are number based (select)*/
export type NumericControlProps<T> = ControlProps<T> & {
    value: number | Nil<number>;
    onChange: (i: number) => void;
};

/* Props for form controls that are date based (datepicker)*/
export type DateControlProps<T> = ControlProps<T> & {
    bypassDisabledDates?: boolean;
    value: Nil<Date>;
    onChange: (i: Nil<Date>) => void;
    businessDaysUntilSelectable?: number;
    disabled?: boolean;
    minDate?: Date;
};

export const FormControl: FC<FormControlProps> = ({
    label,
    required,
    value,
    className,
    children,
}) => {
    const [error, setError] = useState(false);

    const validate = (): void => {
        if (required) setError(value === '');
    };

    useEffect(validate, [value]);

    return (
        <div className={clsx(className, 'form-control', error && 'invalid')}>
            {label && (
                <label>
                    {label}
                    {required && <div className="dot" />}
                </label>
            )}
            {children}
        </div>
    );
};
