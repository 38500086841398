import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUser } from '@/contexts/User';
import { identify } from '@/common/analytics';

export const useFeatureIdentifier = () => {
    const flags = useFlags();
    const {
        data: { user },
    } = useUser();

    useEffect(() => {
        const flagNames = Object.keys(flags);
        const turnedOnFlags = flagNames.filter(function (flag) {
            return flags[flag];
        });
        identify(user, { featureFlags: turnedOnFlags });
    }, [user, flags]);
};
