import gql from 'graphql-tag';
import { ExperimentTeam } from '../common/types';

export type RemoveExperimentTeamResult = {
    deleteExperimentTeam: {
        experimentTeam: ExperimentTeam;
    };
};

export const removeExperimentTeamMutation = gql`
    mutation RemoveExperimentTeam($id: BigInt!) {
        deleteExperimentTeam(input: { id: $id }) {
            experimentTeam {
                id
            }
        }
    }
`;
