import { IMarkupFragment, IMarkupGroupFragment, IMarkupType } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';

export const isMarkupGroup = (item?: IMarkupEntry): item is IMarkupGroupFragment => {
    return item?.__typename === 'MarkupGroup';
};

export const isMarkup = (item?: IMarkupEntry): item is IMarkupFragment => {
    return item?.__typename === 'Markup';
};

export const getGroups = (items?: IMarkupEntry[]) => {
    return items?.filter(isMarkupGroup) || [];
};

export const getJoinableGroups = (item?: IMarkupFragment, groups?: IMarkupGroupFragment[]) => {
    if (!item || !groups?.length) {
        return [];
    }

    return groups.filter((group) => [item.markupType, null].includes(group.markupGroupType));
};

export const getMarkups = (items?: IMarkupEntry[]) => {
    return items?.filter(isMarkup) || [];
};

export const getMarkupsDeep = (items?: IMarkupEntry[]) => {
    return items?.reduce<IMarkupFragment[]>((result, item) => {
        if (isMarkup(item)) {
            return result.concat(item);
        } else if (isMarkupGroup(item)) {
            return result.concat(getMarkups(item.markups));
        } else {
            return result;
        }
    }, []);
};

export const getMarkupType = (item?: IMarkupEntry) => {
    let markupType;

    if (isMarkup(item)) {
        markupType = item?.markupType;
    } else if (isMarkupGroup(item)) {
        markupType = item?.markupGroupType;
    }

    return markupType;
};

export const isMarkupType = (type?: string | null, item?: IMarkupEntry) => {
    return getMarkupType(item) === type;
};

export const isSameMarkupType = (items: IMarkupEntry[] = []) => {
    const [head, ...rest] = items;

    const sourceMarkupType = getMarkupType(head);

    return rest.every((item) => {
        return isMarkupType(sourceMarkupType, item);
    });
};

export const isAllSoloMarkups = (items?: IMarkupFragment[]) => {
    return items?.every((item) => !item.markupGroupID) === true;
};

export const isNameTooShort = (name = '') => {
    return name.length === 0;
};

export const isNameTooLong = (name = '') => {
    return name.length > 255;
};

export const getMarkupEntryById = <T extends IMarkupEntry>(items?: T[], id?: string | null) => {
    return items?.find((item) => {
        return item.id === id;
    });
};

export const getMarkupEntriesByIds = <T extends IMarkupEntry>(items?: T[], ids?: string[]) => {
    return ids?.reduce<T[]>((result, id) => {
        const entry = getMarkupEntryById(items, id);

        return entry ? result.concat(entry) : result;
    }, []);
};

export const getMarkupsByPlanPageId = (items?: IMarkupFragment[], id?: string | null) => {
    return items?.filter((item) => {
        return item.projectPlanPageID === id;
    });
};

export const getMarkupsInThisProject = (
    items?: IMarkupEntry[],
    showMarkups?: boolean,
    showGroups?: boolean
) => {
    return items?.filter((item) => {
        return (isMarkupGroup(item) && showGroups) || (!isMarkupGroup(item) && showMarkups);
    });
};

export const toFormattedMeasurement = (item?: IMarkupEntry) => {
    if (!item) {
        return '';
    }

    const markupType = getMarkupType(item);

    if (markupType === IMarkupType.Area || markupType === IMarkupType.Linear) {
        return item.measurement.toFixed(2);
    } else {
        return String(item.measurement);
    }
};

export const toUom = (type?: IMarkupType | null) => {
    if (type === IMarkupType.Area) {
        return 'SF';
    } else if (type === IMarkupType.Count) {
        return 'EA';
    } else if (type === IMarkupType.Linear) {
        return 'LF';
    } else {
        return 'X';
    }
};
