import gql from 'graphql-tag';

export type DuplicateProjectResult = {
    uuid: string;
};

export const duplicateProjectMutation = gql`
    mutation ProjectClone($UUID: ID!) {
        projectClone(input: { uuid: $UUID }) {
            uuid
        }
    }
`;
