import gql from 'graphql-tag';
import { ExperimentTeam } from '../common/types';

export type AddExperimentTeamResult = {
    createExperimentTeam: {
        experimentTeam: ExperimentTeam;
    };
};

export const addExperimentTeamMutation = gql`
    mutation AddExperimentTeam($experimentId: BigInt!, $teamId: BigInt!) {
        createExperimentTeam(
            input: { experimentTeam: { experimentId: $experimentId, teamId: $teamId } }
        ) {
            experimentTeam {
                id
            }
        }
    }
`;
