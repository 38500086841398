/*
 * observables.ts initializes and provides access to RxJS observables used to
 * interface with the Apollo
 */
import { Subject, Subscription } from 'rxjs';
import { Operation } from 'urql';

interface ApolloOperationPayload {
    operationName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variables: Record<string, any>;
}

/****************************************************************************/
/* Internal Observables                                                     */
/****************************************************************************/

// The Apollo client has completed an operation
export const completedApolloOperationObservable = new Subject<ApolloOperationPayload>();

// The Urql client has completed an operation
export const completedUrqlOperationObservable = new Subject<Operation | null>();

/****************************************************************************/
/* External Subscription Functions                                          */
/****************************************************************************/

export const subscribeToCompletedApolloOperationObservable = (
    subscribe: (payload: ApolloOperationPayload) => void
): Subscription => completedApolloOperationObservable.subscribe(subscribe);

export const subscribeToCompletedUrqlOperationObservable = (
    subscribe: (payload: Operation | null) => void
): Subscription => completedUrqlOperationObservable.subscribe(subscribe);
