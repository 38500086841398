import { Cell } from 'leyden';

import { Serialize } from '.';
import { IEstimateElementFragment, IUnitFragment } from '@/graphql';

export interface SerializeCell {
    Actions: (options: { categoryID: string | null }) => Cell<'Actions'>;
    Category: (options: {
        id: string;
        uuid: string;
        name: string;
        favorited: boolean;
    }) => Cell<'Category'>;
    CategoryTotal: (options: { id: string }) => Cell<'CategoryTotal'>;
    Empty: (options: { categoryID: string | null }) => Cell<'Empty'>;
    Name: (options: {
        categoryID: string | null;
        elementID: string;
        elementUUID: string;
        name: string;
        favorited: boolean;
    }) => Cell<'Name'>;
    Quantity: (options: {
        categoryID: string | null;
        element: IEstimateElementFragment;
    }) => Cell<'Quantity'>;
    Rate: (options: {
        categoryID: string | null;
        elementID: string;
        elementUUID: string;
        laborRate: number;
        materialRate: number;
        productionRate: number;
        equipmentRate: number;
        name: string;
    }) => Cell<'Rate'>;
    Total: (options: {
        categoryID: string | null;
        elementID: string;
        elementUUID: string;
        tradeName: string | null;
    }) => Cell<'Total'>;
    UnitOfMeasure: (options: {
        categoryID: string | null;
        elementID: string;
        elementUUID: string;
        uom: IUnitFragment;
    }) => Cell<'UnitOfMeasure'>;
}

export const SerializeCell: SerializeCell = {
    Actions(options) {
        return Cell.new('Actions', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },

    Category(options) {
        return Cell.new('Category', [Serialize.Text.Empty()], {
            ...options,
            categoryID: null,
            isVoid: true,
        });
    },

    CategoryTotal(options) {
        return Cell.new('CategoryTotal', [Serialize.Text.Empty()], {
            ...options,
            categoryID: null,
            isVoid: true,
        });
    },

    Empty(options) {
        return Cell.new('Empty', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },

    Name(options) {
        return Cell.new('Name', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },

    Quantity(options) {
        const {
            element: { id: elementID, uuid: elementUUID },
        } = options;
        return Cell.new('Quantity', [Serialize.Element.ElementQuantityDefaultView()], {
            ...options,
            elementID,
            elementUUID,
        });
    },

    Rate(options) {
        return Cell.new('Rate', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },

    Total(options) {
        return Cell.new('Total', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },

    UnitOfMeasure(options) {
        return Cell.new('UnitOfMeasure', [Serialize.Text.Empty()], {
            ...options,
            isVoid: true,
        });
    },
};
