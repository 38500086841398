import capitalize from 'lodash/capitalize';
import { PageBrowser } from '../PageBrowser/PageBrowser';
import StylePanel from '../StylePanel/StylePanel';
import { Calibration } from '../calibration/Calibration';
import { ReactComponent as Area } from '@/assets/icons/Area.svg';
import { ReactComponent as Count } from '@/assets/icons/Count.svg';
import { ReactComponent as Linear } from '@/assets/icons/Linear.svg';
import { ReactComponent as Select } from '@/assets/icons/Select.svg';
import { GeometryType, ToolType } from '@/common/types';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useTool } from '@/components/takeoff/hooks/useTool';
import { geometriesObservable } from '@/components/takeoff/observables/interface';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconButton } from '@/components/ui/buttons/IconButton';
import clsx from 'clsx';
import React, { FC } from 'react';

const tools = [
    { icon: Select, type: ToolType.SELECTION, shortcut: 'V' },
    { icon: Linear, type: ToolType.LINEAR, shortcut: 'L' },
    { icon: Area, type: ToolType.AREA, shortcut: 'A' },
    { icon: Count, type: ToolType.COUNT, shortcut: 'C' },
];

export const Toolbar: FC<TakeoffComponentProps> = ({ useTakeoff, children }) => {
    const { scale, tooltipProps, handleToolChange } = useTakeoff();

    const tool = useTool();

    /* Helpers */
    const set = (toolType: ToolType) => (): void => {
        handleToolChange(toolType)();
    };

    const hasGeometriesOtherThanCount = geometriesObservable.value?.reduce(
        (prev, cur) => prev || cur.type !== GeometryType.COUNT,
        false
    );

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                position: 'relative',
            }}
        >
            <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Stack direction="row" flex="1" position="relative" spacing="8px">
                    <Calibration useTakeoff={useTakeoff} onOpen={set(ToolType.CALIBRATION)} />
                    {children}
                </Stack>
                <Stack direction="row" spacing="8px">
                    {tools.map(({ icon, type, shortcut }, key) => (
                        <IconButton
                            key={key}
                            Icon={icon}
                            onClick={set(type)}
                            className={clsx({ active: type === tool })}
                            {...tooltipProps(`${capitalize(type)} tool`, shortcut)}
                            noInherit={true}
                        />
                    ))}
                </Stack>
                <Stack direction="row" flex="1" justifyContent="flex-end" spacing="8px">
                    <StylePanel />
                    <PageBrowser useTakeoff={useTakeoff} />
                </Stack>
            </Stack>
            {!scale && hasGeometriesOtherThanCount && (
                <Paper
                    elevation={0}
                    onClick={set(ToolType.CALIBRATION)}
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        zIndex: (theme) => theme.zIndex.mobileStepper,
                    }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing="16px"
                        sx={{
                            background: (theme) => theme.palette.accent.main,
                            cursor: 'pointer',
                            padding: '8px 16px',
                        }}
                    >
                        <Typography variant="body1">
                            Quantities won’t be availalable until page scale is set
                        </Typography>
                    </Stack>
                </Paper>
            )}
        </Paper>
    );
};
