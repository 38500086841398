/*
 * Materials.ts provides a GraphQL query used to fetch all Materials a user has
 * access to.
 */
import { Client } from '../common/urql/client';
import { frogmint } from '../frog';
import { FrogResult } from '../frog/types';
import { UomRecord, uomFragment } from './unitOfMeasure';
import { OperationContext } from 'urql';

interface MaterialAliasRecord {
    alias: string;
}

interface MaterialAliasAssignmentRecords {
    materialAliasAssignments?: {
        nodes: MaterialAliasRecord[];
    };
}

export interface MaterialRecord {
    id: string;
    name: string;
    takeoffUomId: number;
    takeoffUom: UomRecord;
    materialAliasAssignments?: MaterialAliasAssignmentRecords;
    ownerId?: number;
}

export const materialAliasAssignmentFragment = frogmint`
    ${'MaterialAliasAssignment_material_alias_assignment'} {
        materialAlias {
            alias
        }
    }
`;

export const materialFragment = frogmint`
    ${'Material_material'} {
        id
        name
        takeoffUomId
        takeoffUom {
            ${uomFragment}
        }
        materialAliasAssignments {
            nodes {
                ${materialAliasAssignmentFragment}
            }
        }
        ownerId
    }
`;

export interface SingleMaterialRecord {
    materialById: MaterialRecord;
}

export type SingleMaterialCondition = {
    id: string;
};

export const MaterialQuery = materialFragment.gql`
    query Material($id: UUID!){
        materialById(id: $id) {
            ${materialFragment}
        }
    }
`;

export const executeMaterialQuery = (
    id: string,
    options?: Partial<OperationContext>
): FrogResult<SingleMaterialRecord> =>
    Client.getClient()
        .query<SingleMaterialRecord, SingleMaterialCondition>(MaterialQuery, { id }, options)
        .toPromise();
