import { useAssemblyPanel } from '../../../../../context';
import { AssemblyDataRecord } from '../../../../../utils/types';

import { useSelectEstimateAssembly } from '@/components/app/router/EstimationRoute/hooks/useSelectEstimateAssembly';

interface UseAssemblyHandlersPayload {
    selectAssembly: (convertedAssembly: AssemblyDataRecord) => Promise<void>;
}

/**
 * Generate assembly event handlers depending on if view is Estimate or Takeoff.
 * @param {UseAssemblyHandlersArguments} useAssemblyHandlersArguments - picked props from assembly.
 * @returns {UseAssemblyHandlersPayload} selection payloads for assemblies
 */
export const useAssemblyHandlers = (): UseAssemblyHandlersPayload => {
    const { isEstimate } = useAssemblyPanel();

    const { selectEstimateAssembly } = useSelectEstimateAssembly();

    // Estimate handlers
    /*-------------------*/
    const estimateSelectAssembly = async (convertedAssembly: AssemblyDataRecord): Promise<void> => {
        await selectEstimateAssembly?.(convertedAssembly);
    };

    /**
     * Return different handlers depending on Estimate boolean in context.
     * @see {useAssemblyPanel}
     */
    return { selectAssembly: isEstimate ? estimateSelectAssembly : () => Promise.resolve() };
};
