import React, { FC } from 'react';

import { Container, ContainerLink } from './styled';
import { DivProps } from '@/common/types';

interface TopbarButtonProps extends DivProps {
    svg?: JSX.Element;
    copy?: string;
    to?: string;
    className?: string;
}
export const TopbarButton: FC<TopbarButtonProps> = ({ svg, copy, to, className, ...rest }) =>
    to ? (
        <ContainerLink className={className} to={to}>
            {svg && svg}
            {copy && <span>{copy}</span>}
        </ContainerLink>
    ) : (
        <Container className={className} {...rest}>
            {svg && svg}
            {copy && <span>{copy}</span>}
        </Container>
    );
