import { createContext, useContext } from 'react';

export interface EstimateBufferContext {
    bufferHovered: boolean;
    recordBufferCellMouseEnter: () => void;
    recordBufferCellMouseLeave: () => void;
}

export const EstimateBufferContext = createContext<EstimateBufferContext>({
    bufferHovered: false,
    recordBufferCellMouseEnter: () => undefined,
    recordBufferCellMouseLeave: () => undefined,
});

export const useEstimateBuffer = (): EstimateBufferContext => {
    return useContext(EstimateBufferContext);
};
