// For some period of time, we allowed half-star ratings like 0.5 or 4.5.
// We then resigned from the idea, but for the 22 projects that still have the half-star ratings on them,
// we need this additional function that will adjust the way those ratings are displayed.
export const ratingNormalizer = (originalRating: number): number => {
    if (originalRating > 4 || (originalRating > 0 && originalRating < 1)) {
        return Math.round(originalRating);
    } else {
        return Math.floor(originalRating);
    }
};
