import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { SvgIcon } from '../../icons/SvgIcon';

export const Caret = styled(SvgIcon)`
    fill: ${(props): string => props.theme.v0.colorSecondary};
    margin-left: 5px;
`;

export const CollapsibleTarget = styled.div`
    cursor: pointer;
`;

interface ContentsProps {
    isCollapsed?: boolean;
}
export const Contents = styled.div<ContentsProps>`
    will-change: height;

    ${(props): FlattenSimpleInterpolation | string =>
        props.isCollapsed
            ? css`
                  height: 0;
              `
            : ''}
`;

export const CaretContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;

    top: 3px;
    right: 0px;
    position: absolute;
    transform: rotate(-90deg);
`;

interface ContainerProps {
    isOpen: boolean;
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    flex-basis: 100%;
    font-family: 'proxima-nova', sans-serif;

    ${(props): FlattenSimpleInterpolation | string =>
        props.isOpen
            ? css`
                  & ${CaretContainer} {
                      transform: rotate(90deg);
                  }
              `
            : ''}
`;

export const Wrapper = styled.div`
    width: 100%;
`;
