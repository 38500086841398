import { useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { ProjectRecord } from '@/queries/projects';
import { hasProjectFeature, hasEstimateFeature, hasTakeoffFeature } from '@/views/Projects/utils';

const COPY = {
    project: 'Project',
    estimate: 'Estimate',
    takeoff: 'Takeoff',
};

interface NavBarTabsProps extends TabsProps {
    project: ProjectRecord;
}

export const NavBarTabs: FC<NavBarTabsProps> = ({ project, ...props }) => {
    const { features } = useFeatures();

    const params = useParams<{ projectUUID: string }>();

    const {
        data: { user },
    } = useUser();

    const location = useLocation();

    const [tab, setTab] = useState<string>();

    useEffect(() => {
        if (location.pathname.includes('estimate')) {
            setTab('estimate');
        } else if (location.pathname.includes('takeoff')) {
            setTab('takeoff');
        } else {
            setTab('project');
        }
    }, [location]);

    if (!tab) {
        return null;
    }

    return (
        <Tabs
            onChange={(event, value) => {
                setTab(value);
            }}
            TabIndicatorProps={{
                style: {
                    transition: 'none',
                },
            }}
            value={tab}
            {...props}
        >
            {(() => {
                return [
                    hasProjectFeature({ project, user }) && (
                        <Tab
                            component={Link}
                            key="project"
                            to={`/projects/${params.projectUUID}`}
                            label={COPY.project}
                            value="project"
                        />
                    ),
                    hasEstimateFeature({ features, project, user }) && (
                        <Tab
                            component={Link}
                            key="estimate"
                            to={`/projects/${params.projectUUID}/estimate`}
                            label={COPY.estimate}
                            value="estimate"
                        />
                    ),
                    hasTakeoffFeature({ features, project, user }) && (
                        <Tab
                            component={Link}
                            key="takeoff"
                            to={`/projects/${params.projectUUID}/takeoff`}
                            label={COPY.takeoff}
                            value="takeoff"
                        />
                    ),
                ];
            })()}
        </Tabs>
    );
};
