import { useTheme } from '@mui/material/styles';
import { AdminDashboardProjectStatus } from '@/common/types';
import {
    isDraftStatus,
    isInProgressStatus,
    isNeutralStatus,
    isNegativeStatus,
    isPostiveStatus,
} from './utils';

interface Options {
    status?: AdminDashboardProjectStatus;
}

export const useStatusColor = (options?: Options) => {
    const theme = useTheme();

    let color;

    if (isDraftStatus(options?.status)) {
        color = theme.palette.warning.main;
    } else if (isInProgressStatus(options?.status)) {
        color = theme.palette.info.main;
    } else if (isNeutralStatus(options?.status)) {
        color = theme.palette.info.main;
    } else if (isNegativeStatus(options?.status)) {
        color = theme.palette.error.main;
    } else if (isPostiveStatus(options?.status)) {
        color = theme.palette.success.main;
    } else {
        color = theme.palette.hues.neutral[21];
    }

    return color;
};
