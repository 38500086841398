import styled from 'styled-components';

import { pulsate } from '@/common/styles/pulsate';

export const Placeholder = styled.div`
    height: 5px;
    width: 100%;

    ${pulsate}
`;
