/*
 * This context provides a heartbeat to ensure that the postgraphile release
 * matches the frontend bundle release.
 */
import { useNotifications } from './Notifications';
import { useUser } from './User';
import { makeContext } from '@/common/utils/makeContext';
import { Bugsnag } from '@/components/app/Bugsnag';
import { GradientToast } from '@/components/notifications/GradientToast';
import { BasicToastCopy } from '@/components/notifications/GradientToast/BasicToastCopy';
import {
    Container as PrimaryButtonContainer,
    PrimaryButton,
} from '@/components/ui/buttons/PrimaryButton';
import { CreateEventResult, newEventMutation } from '@/mutations/newEvent';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { Env } from '@/common/env';
import { fetchWithTimeout } from '@/common/utils/fetch';

const COPY = {
    refresh: 'Refresh',
    updateAvailable: 'A new version of 1build is available, please click refresh to upgrade',
};

interface ReleaseProps {
    isNewRelease: boolean;
}

const UpgradeFlex = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    & > ${BasicToastCopy} {
        flex: 1;
        margin-right: 32px;
    }

    & > ${PrimaryButtonContainer} {
        flex: 0;

        // forgive me @michael 👼
        & > span {
            font-weight: bold !important;
            font-size: 10px !important;
            margin-top: 0 !important;
        }
    }
`;

const { Provider } = makeContext<ReleaseProps>(() => {
    const { addNotification } = useNotifications();
    const {
        data: { user },
    } = useUser();
    const [, createEvent] = useMutation<CreateEventResult>(newEventMutation);
    const location = useLocation();
    const hasNotifiedUser = useRef<boolean>(false); // Keep track of toast ID for removal onClick.
    const [isNewRelease, setIsNewRelease] = useState(false);

    // Set a release check heartbeat with a 30 second interval.
    useEffect(() => {
        let currentEtag = '';
        const heartbeatInterval = setInterval(() => {
            /**
             * Check etag on index file to determine if a new version has been pushed to cloudfront.
             */
            const checkDeploymentURL = async (): Promise<void> => {
                try {
                    let etagChanged = false;
                    const init: RequestInit = {
                        method: 'HEAD',
                        cache: 'no-cache',
                    };
                    let res: Response;
                    try {
                        res = await fetchWithTimeout('/', init, 8000);
                    } catch (e) {
                        if (e instanceof DOMException && e.name === 'AbortError') {
                            // fetch timed out - maybe network is down, ignore
                            return;
                        }
                        throw e;
                    }
                    const etag = res.headers.get('ETag');

                    if (!etag) {
                        return;
                    }

                    if (!currentEtag) {
                        currentEtag = etag;
                        return;
                    }

                    if (currentEtag !== etag) {
                        etagChanged = true;
                    }

                    // If etag has changed and user has not already been notified on a new release,
                    // notify user of a new release.
                    if (etagChanged && !hasNotifiedUser.current) {
                        setIsNewRelease(true);
                        hasNotifiedUser.current = true;
                        addNotification(
                            {
                                title: '',
                                v2Content: (
                                    <GradientToast
                                        css={`
                                            height: 70px;
                                            width: 400px;
                                            padding-bottom: 4px;
                                        `}
                                    >
                                        <UpgradeFlex>
                                            <BasicToastCopy>{COPY.updateAvailable}</BasicToastCopy>

                                            <PrimaryButton
                                                v2
                                                onClick={async (): Promise<void> => {
                                                    try {
                                                        await createEvent({
                                                            eventTypeName: 'upgrade_executed',
                                                            message: `The user has manually upgraded their app after
                                                                 seeing an upgrade prompt.`,
                                                            ownerId: user?.id,
                                                        });
                                                    } catch (e) {
                                                        Bugsnag?.notify(String(e));
                                                    } finally {
                                                        window.location.reload();
                                                    }
                                                }}
                                            >
                                                {COPY.refresh}
                                            </PrimaryButton>
                                        </UpgradeFlex>
                                    </GradientToast>
                                ),
                            },
                            'success',
                            false
                        );
                    }
                } catch (e) {
                    Bugsnag?.notify(String(e));
                }
            };

            if (!Env.tier.isDevelopment) {
                checkDeploymentURL();
            }
        }, 30000);
        return (): void => clearInterval(heartbeatInterval);
    }, [addNotification]);

    // Reload window on route change if there is a new release
    useEffect(() => {
        if (hasNotifiedUser.current) {
            window.location.reload();
        }
    }, [location.pathname]);

    return {
        isNewRelease,
    };
});

export const ReleaseProvider = Provider;
