/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSObject, FlattenSimpleInterpolation, css } from 'styled-components';

import {
    breakpointExtraSmall,
    breakpointLarge,
    breakpointMedium,
    breakpointSmall,
} from '@/variables';

const breakpointsMap = {
    extraSmall: breakpointExtraSmall,
    small: breakpointSmall,
    medium: breakpointMedium,
    large: breakpointLarge,
};

type BreakpointsCSSMap = {
    [key in 'extraSmall' | 'small' | 'medium' | 'large']: (
        ...args: any[]
    ) => FlattenSimpleInterpolation;
} & {
    min: {
        [key in 'extraSmall' | 'small' | 'medium' | 'large']: (
            ...args: any[]
        ) => FlattenSimpleInterpolation;
    };
};

export const breakpoints: BreakpointsCSSMap = Object.entries(
    breakpointsMap
).reduce<BreakpointsCSSMap>(
    (accumulator, [key, breakpoint]) => ({
        ...accumulator,
        [key]: (...args: any[]): FlattenSimpleInterpolation =>
            css`
                @media (max-width: ${breakpoint}) {
                    ${css(args[0], ...args.slice(1))}
                }
            `,
        min: {
            ...(accumulator.min || {}),
            [key]: (...args: any[]): FlattenSimpleInterpolation =>
                css`
                    @media (min-width: ${breakpoint}) {
                        ${css(args[0], ...args.slice(1))}
                    }
                `,
        },
    }),
    {} as BreakpointsCSSMap
);

type BreakpointsCascadingStyleSheetsObjectMap = {
    [key in 'extraSmall' | 'small' | 'medium' | 'large']: (cssObject: CSSObject) => CSSObject;
} & {
    min: {
        [key in 'extraSmall' | 'small' | 'medium' | 'large']: (cssObject: CSSObject) => CSSObject;
    };
};

export const breakpointsCSSObject: BreakpointsCascadingStyleSheetsObjectMap = Object.entries(
    breakpointsMap
).reduce<BreakpointsCascadingStyleSheetsObjectMap>(
    (accumulator, [key, breakpoint]) => ({
        ...accumulator,
        [key]: (cssObject: CSSObject): CSSObject => ({
            [`@media (max-width: ${breakpoint})`]: {
                ...cssObject,
            },
        }),
        min: {
            ...(accumulator.min || {}),
            [key]: (cssObject: CSSObject): CSSObject => ({
                [`@media (min-width: ${breakpoint})`]: {
                    ...cssObject,
                },
            }),
        },
    }),
    {} as BreakpointsCascadingStyleSheetsObjectMap
);
