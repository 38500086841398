import React, { FC, useEffect, useState } from 'react';

import { EstimatorPanel } from './EstimatorPanel';
import { EstimatorTableRow } from './EstimatorTableRow';

import { useIsWebkit } from '@/common/hooks/useIsWebkit';
import { useToggle } from '@/common/hooks/useToggle';
import { SortingDirection, Table } from '@/components/AdminDashboard/Table';
import { AdminDashboardContainer } from '@/components/AdminDashboard/styled';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { useUser } from '@/contexts/User';
import { IUserFragment } from '@/graphql';

interface EstimatorsProps {
    users: IUserFragment[];
    isLoading: boolean;
}

export const Estimators: FC<EstimatorsProps> = ({ users, isLoading }) => {
    const [isPanelOpen, toggleIsPanelOpen] = useToggle();
    const [activeTab, setActiveTab] = useState('');
    const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>();
    const [selectedUser, setSelectedUser] = useState<IUserFragment>();

    const isWebkit = useIsWebkit();

    const {
        data: { fullUserInfo },
        actions: { fetchFullUserByID },
        loading: { loadingFullUserInfo },
    } = useUser();

    const closePanel = (): void => {
        toggleIsPanelOpen();
        setActiveRowIndex(undefined);
    };

    const columns = [
        {
            name: 'name',
            label: 'name',
            sortBy: 'name',
            sortable: true,
            sortOrder: SortingDirection.Ascending,
            active: true,
        },
        { name: 'status', label: 'status', sortable: true },
        { name: 'trades', label: 'trades', sortable: false },
        { name: 'preferred', label: 'preferred', sortable: false },
        { name: 'notPreffered', label: 'not-preffered', sortable: false },
    ];

    const onEstimatorRowClick = (
        estimator: IUserFragment,
        rowIndex: number,
        tabKey: string
    ): void => {
        setActiveRowIndex(rowIndex);
        handleSetSelectedUser(estimator);
        setActiveTab(tabKey);
        toggleIsPanelOpen();
    };

    const handleSetSelectedUser = (estimator: IUserFragment): void => {
        setSelectedUser(estimator);
        fetchFullUserByID(estimator.id);
    };

    useEffect(() => {
        if (activeRowIndex !== undefined && users.length > activeRowIndex) {
            handleSetSelectedUser({
                ...users[activeRowIndex],
                email: fullUserInfo.email || '',
            });
        }
    }, [users, activeRowIndex]);

    useEffect(() => {
        if (selectedUser) {
            setSelectedUser({
                ...selectedUser,
                email: fullUserInfo.email || '',
            });
        }
    }, [isPanelOpen, fullUserInfo]);

    return (
        <AdminDashboardContainer isWebkit={isWebkit}>
            <Table columns={columns}>
                {users.map(
                    (estimator, index): JSX.Element => (
                        <EstimatorTableRow
                            key={estimator.id}
                            estimator={estimator}
                            isActive={activeRowIndex === index}
                            onClick={(tabKey: string): void => {
                                onEstimatorRowClick(estimator, index, tabKey);
                            }}
                        />
                    )
                )}
            </Table>
            {selectedUser && (
                <EstimatorPanel
                    open={isPanelOpen}
                    close={closePanel}
                    activeTab={activeTab}
                    selectedUser={selectedUser}
                    isLoading={isLoading || loadingFullUserInfo}
                />
            )}
            {isLoading && <SpinnerLoader />}
        </AdminDashboardContainer>
    );
};
