import { Cell as LeydenCell, CellType, Coordinates } from 'leyden';
import { CellRenderer, useCoordinates, useIsInSelectedRow, useLeydenStatic } from 'leyden-react';
import React, { FC, MouseEventHandler } from 'react';
import { useSelected } from 'slate-react';

import { Caret, CaretWrapper, CellContainer, CellContent } from './styled';
import { BottomBuffer } from '../BottomBuffer';
import { useContextMenu } from '../hooks/useContextMenu';
import { EstimateEditor } from '../../editor/EstimateEditor';
import { useCategoryIsCollapsed } from '../../hooks/useCategoryIsCollapsed';
import { useCellIsInHoveredRow } from '../../hooks/useCellIsInHoveredRow';
import { DivProps } from '@/common/types';
import { useBreakpoints } from '@/common/hooks/useBreakpoints';
import { useCellDialog } from '@/components/Estimate/Table/contexts/useCellDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface Cell extends DivProps {
    attributes: Parameters<CellRenderer<CellType>>[0]['attributes'];
    caret: 'never' | 'hide-inactive' | 'always';
    onCaretClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    element: LeydenCell<CellType>;
    hasClickAction?: boolean;
    highlightOnHover?: boolean;
    preventSelection?: boolean;
}

export const Cell: FC<Cell> = ({
    attributes,
    caret,
    children,
    element: cell,
    hasClickAction,
    highlightOnHover,
    preventSelection,
    onCaretClick,
    onMouseEnter,
    onContextMenu,
    ...props
}) => {
    const { open: openContextMenu } = useContextMenu();
    const myCoords = useCoordinates(cell);
    const editor = useLeydenStatic();
    const isInCollapsedCategory = useCategoryIsCollapsed(cell.categoryID ?? null);
    const isCategoryCollapsed = useCategoryIsCollapsed((cell as LeydenCell<'Category'>).id ?? null);
    const isInHoveredRow = useCellIsInHoveredRow(cell);
    const isInSelectedRow = useIsInSelectedRow(cell);
    const selected = useSelected();
    const breakpoints = useBreakpoints();
    const { openCellDialog } = useCellDialog();
    const flags = useFlags();

    const isCaretVisible = (): boolean => {
        if (preventSelection || caret === 'never') {
            return false;
        }

        const mobileConditions =
            flags.mobileAssemblyPanel && breakpoints.mobile && caret !== 'hide-inactive';

        return mobileConditions || isInHoveredRow || isInSelectedRow;
    };

    const isHighlighted = (): boolean => {
        if (!highlightOnHover) {
            return false;
        }
        return isInHoveredRow || selected;
    };

    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e) => {
        if (onMouseEnter) {
            onMouseEnter(e);
        }
        if (e.defaultPrevented) {
            return;
        }

        if (LeydenCell.isCell(cell, { type: 'Empty' })) {
            EstimateEditor.clearHoveredRow(editor);
        } else if (myCoords) {
            EstimateEditor.setHoveredRow(editor, myCoords.y);
        }
    };

    const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
        if (onContextMenu) {
            onContextMenu(event);
        }
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        openContextMenu({ cell, position: { x: event.clientX, y: event.clientY } });
    };

    const handleMobileClick: MouseEventHandler<HTMLDivElement> = () => {
        // This is to avoid a weird edge case
        // If this cell is Actions it means we clicked outside the 'Add item' or the 'Add group' buttons
        // Because the click action for these type of cells is handled there
        if (
            LeydenCell.isCell(cell, { type: 'Actions' }) ||
            LeydenCell.isCell(cell, { type: 'Empty' })
        ) {
            return;
        }

        openCellDialog(cell);
    };

    if (isInCollapsedCategory) {
        return <></>;
    }

    const cellShouldHaveDifferentProps = breakpoints.mobile && flags.mobileAssemblyPanel;

    const cellProps = !cellShouldHaveDifferentProps
        ? {
              caretVisible: isCaretVisible(),
              hasClickAction: hasClickAction,
              rowIsHovered: isInHoveredRow,
              rowIsSelected: !preventSelection && isInSelectedRow,
              onMouseEnter: handleMouseEnter,
              onContextMenu: handleContextMenu,
          }
        : {
              caretVisible: isCaretVisible(),
              onClick: handleMobileClick,
          };

    return (
        <>
            <CellContainer {...attributes} {...cellProps} {...props}>
                <CellContent highlighted={isHighlighted()}>{children}</CellContent>
                {caret !== 'never' && (
                    <CaretWrapper onClick={onCaretClick}>
                        <Caret open={!isCategoryCollapsed} />
                    </CaretWrapper>
                )}
            </CellContainer>
            {/* Bad hack: render buffer cells in one cell - no other way to render inside table grid */}
            {myCoords !== null && Coordinates.equals(myCoords, { x: 0, y: 0 }) && <BottomBuffer />}
        </>
    );
};
