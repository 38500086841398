/*
 * projectAssemblies.ts GraphQL queries to fetch project assemblies.
 */
import { LeafletStyleType } from '../common/types';
import { Client } from '../common/urql/client';
import { frogmint, newQueries } from '../frog';
import { AssemblyRecord, assemblyFragment } from './assemblies';
import { ElementRecord } from './elements';
import { formulaFragment } from './formulas';
import { materialFragment } from './materials';
import { TakeoffRecord, takeoffFragment } from './takeoffs';
import { tradeFragment } from './trades';
import { uomFragment } from './unitOfMeasure';
import { OperationResult } from 'urql';

export type ProjectAssemblyRecord = {
    id: string;
    style?: LeafletStyleType;
    assemblyId: number;
    assembly: AssemblyRecord;
    projectId: number;
    takeoffId?: number;
    takeoff?: TakeoffRecord;
};

export interface ProjectAssemblyRecords {
    projectAssemblies?: {
        nodes: ProjectAssemblyRecord[];
    };
}

export const projectAssemblyFragment = frogmint`
    ${'ProjectAssembly_projectAssembly'} {
        id
        style
        assemblyId
        assembly {
            ${assemblyFragment}
        }
        projectId
        takeoffId
        takeoff {
            ${takeoffFragment}
        }
    }
`;

export const ProjectAssembliesQuery = projectAssemblyFragment.gql`
    query ProjectAssemblies($condition: ProjectAssemblyCondition) {
        projectAssemblies(condition: $condition) {
            nodes {
                ${projectAssemblyFragment}
            }
        }
    }
`;

export type ProjectAssemblyCondition = {
    id?: string;
    assemblyId?: number;
    projectId?: number;
    takeoffId?: number;
};

export const [executeProjectAssembliesQuery, useProjectAssembliesQuery] = newQueries<
    ProjectAssemblyCondition,
    ProjectAssemblyRecords
>(ProjectAssembliesQuery, { additionalTypenames: ['CreateProjectAssemblyPayload'] });

/*****************************************************************************/
/* Alt Fragments                                                             */
/*****************************************************************************/
const elementOmitAssemblyFragment = frogmint`
    ${'Element_elementOmitAssembly'} {
        id
        uuid
        previousElement
        name
        equipmentRate
        productionRate
        laborRate
        materialRate
        assemblyId
        materialId
        material {
            ${materialFragment}
        }
        formulaId
        formula {
            ${formulaFragment}
        }
        uomId
        uom {
            ${uomFragment}
        }
        libraryOwnerId
    }
`;

export type AssemblyWithElementsRecord = AssemblyRecord & {
    elements: {
        nodes: ElementRecord[];
    };
};
export const assemblyWithElementsFragment = frogmint`
    ${'Assembly_assemblyWithElements'} {
        id
        description
        popularity
        ownerId
        tradeId
        trade {
            ${tradeFragment}
        }
        uomId
        uom {
            ${uomFragment}
        }
        elements {
            nodes {
                ${elementOmitAssemblyFragment}
            }
        }
    }
`;

export type ProjectAssemblyWithAssemblyElementsRecord = Omit<ProjectAssemblyRecord, 'assembly'> & {
    assembly: AssemblyWithElementsRecord;
};
export const projectAssemblyWithAssemblyElementsFragment = frogmint`
    ${'ProjectAssembly_projectAssemblyWithAssemblyElements'} {
        id
        style
        assemblyId
        assembly {
            ${assemblyWithElementsFragment}
        }
        projectId
        takeoffId
        takeoff {
            ${takeoffFragment}
        }
    }
`;

export interface ProjectAssemblyWithAssemblyElementsRecords {
    projectAssemblies?: {
        nodes: ProjectAssemblyWithAssemblyElementsRecord[];
    };
}

export const ProjectAssembliesWithAssemblyElementsQuery = projectAssemblyWithAssemblyElementsFragment.gql`
    query AssembliesByProjectId ($condition: ProjectAssemblyCondition) {
        projectAssemblies (condition: $condition) {
            nodes {
               ${projectAssemblyWithAssemblyElementsFragment}
            }
        }
    }
`;

export const executeProjectAssembliesWithAssemblyElementsQuery = (
    condition: ProjectAssemblyCondition
): Promise<OperationResult<ProjectAssemblyWithAssemblyElementsRecords>> =>
    Client.getClient()
        .query<ProjectAssemblyWithAssemblyElementsRecords>(
            ProjectAssembliesWithAssemblyElementsQuery,
            { condition }
        )
        .toPromise();
