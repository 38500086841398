import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';

import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { readableWageTypeToWageType } from '@/common/utils/helpers';
import { BuilderDetailsFormFields } from '@/common/types';

interface BuilderDetailsCheckboxListProps {
    control: Control<BuilderDetailsFormFields, unknown>;
    label?: string;
    setValue: UseFormSetValue<BuilderDetailsFormFields>;
    options: string[];
    defaultSelected: string;
    disabled?: boolean;
}

export const BuilderDetailsWageList: React.FC<BuilderDetailsCheckboxListProps> = ({
    control,
    label,
    setValue,
    options,
    defaultSelected,
    disabled = false,
}) => {
    const [selectedItem, setSelectedItem] = useState<string>(defaultSelected);

    const handleSelect = (value: string) => {
        setSelectedItem(value);
    };

    useEffect(() => {
        setValue('wage', readableWageTypeToWageType(selectedItem));
    }, [selectedItem]);

    useEffect(() => {
        setSelectedItem(defaultSelected);
    }, [defaultSelected]);

    return (
        <FormControl variant="filled" fullWidth>
            <FormLabel component="legend">
                <Typography sx={{ mb: 2 }} variant="subtitle1">
                    {label}
                </Typography>
            </FormLabel>
            <FormGroup>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    {options.map((option, idx) => (
                        <Grid item sm={6} xs={12} md={3} key={idx}>
                            <Controller
                                name="wage"
                                render={() => (
                                    <Button
                                        disabled={disabled}
                                        variant={selectedItem === option ? 'text' : 'outlined'}
                                        color="secondary"
                                        sx={{
                                            width: '100%',
                                            height: '10rem',
                                            background: (theme) =>
                                                selectedItem === option
                                                    ? theme.palette.hues.neutral[32]
                                                    : 'inherit',
                                            opacity: selectedItem === option ? '1' : '0.6',
                                            textTransform: 'capitalize',
                                            fontSize: '14px',
                                        }}
                                        onClick={() => handleSelect(option)}
                                    >
                                        {option}
                                    </Button>
                                )}
                                control={control}
                            />
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};
