/**
 * EstimatorAssignment is a component that is meant to be displayed as a panel
 * that shows up above the Project Details view when the 1build Admin wants
 * to decide which Estimator should be assigned to the project.
 */

import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useMutation } from 'urql';

import { PrimaryButton } from '@/components/ui/buttons/PrimaryButton';
import { RadioRow } from '@/components/ui/inputs/RadioRow';
import { useUser } from '@/contexts/User';

import { useNotifications } from '@/contexts/Notifications';

import { assignEstimatorMutation, AssignEstimatorResult } from '@/mutations/assignEstimator';
import { ErrorMessage } from '@/components/notifications/Notification';
import { IUserRole } from '@/graphql';
import { useUsers } from '@/common/hooks/useUsers';

const COPY = {
    assignment_title: 'Choose estimator',
    assignment_send: 'Send',
    assignment_success_title: 'Estimator assigned',
    assignment_success_msg: ' has been notified of this project',
    assignment_error_title: 'Estimator assignment failed',
    assignment_error_msg: 'try again',
};

type EstimatorAssignmentProps = {
    v2?: boolean;
    className?: string;
    close: () => void;
    projectId: string;
};

export const EstimatorAssignment: FC<EstimatorAssignmentProps> = ({
    v2,
    className,
    close,
    projectId,
}) => {
    const {
        data: {
            user: { id: ownerId },
        },
    } = useUser();
    const [selectedEstimatorId, setSelectedEstimatorId] = useState<string>();
    const { addNotification } = useNotifications();
    const { users: availableEstimators } = useUsers({
        roles: [IUserRole.Estimator],
        lightUsers: true,
    });

    const [, assignEstimatorToProject] =
        useMutation<AssignEstimatorResult>(assignEstimatorMutation);

    const handleSelectEstimator = (id: string) => (): void => {
        setSelectedEstimatorId(id);
    };

    const handleSendOfferToEstimator = (): void => {
        assignEstimatorToProject({
            projectId,
            ownerId,
            userId: selectedEstimatorId,
        })
            .then((response) => {
                close();
                if (response.error) {
                    addNotification(
                        {
                            title: COPY.assignment_error_title,
                            content: (
                                <ErrorMessage
                                    message={COPY.assignment_error_msg}
                                    handleClick={handleSendOfferToEstimator}
                                />
                            ),
                        },
                        'error'
                    );
                } else {
                    const estimator = availableEstimators.find(
                        (est) => est.id === selectedEstimatorId
                    );
                    const estimatorFullName =
                        estimator?.firstName ?? '' + ` ${estimator?.lastName ?? ''}`;
                    addNotification(
                        {
                            title: COPY.assignment_success_title,
                            content: (
                                <p>
                                    <span>{estimatorFullName}</span>
                                    {COPY.assignment_success_msg}
                                </p>
                            ),
                        },
                        'success'
                    );
                }
            })
            .catch((_) => {
                addNotification(
                    {
                        title: COPY.assignment_error_title,
                        content: (
                            <ErrorMessage
                                message={COPY.assignment_error_msg}
                                handleClick={handleSendOfferToEstimator}
                            />
                        ),
                    },
                    'error'
                );
            });
    };

    return (
        <div className={clsx(className, 'side-panel-content')}>
            <div className="header">
                <h4>{COPY.assignment_title}</h4>
                <PrimaryButton
                    v2={v2}
                    onClick={handleSendOfferToEstimator}
                    disabled={!selectedEstimatorId}
                >
                    {COPY.assignment_send}
                </PrimaryButton>
            </div>
            <div className="body">
                {availableEstimators.map((estimator) => (
                    <RadioRow
                        id={`available-estimator-${estimator.id}`}
                        key={estimator.id}
                        isChecked={estimator.id === selectedEstimatorId}
                        onChange={handleSelectEstimator(estimator.id)}
                    >
                        {estimator.firstName} {estimator.lastName}
                    </RadioRow>
                ))}
            </div>
        </div>
    );
};
