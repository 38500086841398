export const layers = {
    default: 2,
    middle: 5,
    high: 10,
};

// See: https://mui.com/customization/z-index/
export const zIndex = {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
};
