import { styled as styledByMui } from '@mui/material/styles';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

export const DrawerHeader = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
    marginBottom: '16px',
}));

export const DrawerContentList = styledByMui(List)((options) => ({
    ['& > .MuiMenuItem-root']: {
        fontSize: '1rem',
        lineHeight: '1.5',
        padding: '20px 0',
        borderBottom: `1px solid ${options.theme.palette.hues.neutral[94]}`,

        ['& > .MuiLink-root']: {
            width: '100%',
            height: '100%',
            display: 'flex',
            color: 'inherit',
        },

        ['& .MuiSvgIcon-root']: {
            width: '24px',
            height: '24px',
            minWidth: 'auto',
        },

        ['&:last-of-type']: {
            borderBottom: 'none',
        },
    },
}));
