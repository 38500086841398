import { styled as styledByMui } from '@mui/material/styles';

export const Root = styledByMui('div')({
    height: '100%',
    overflow: 'overlay',

    '::-webkit-scrollbar': {
        background: 'transparent',
    },
});
