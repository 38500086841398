import moment, { Moment } from 'moment';

import { Svg } from '@/common/types';
import { notUndefined } from '@/common/utils/helpers';
import { executeUpdateEventMutation } from '@/mutations/event';
import { executeBaseUsersAuth0LessQuery } from '@/queries/baseUsers';
import { AnyEventTypeName, EventTypeName } from '@/queries/eventTypes';
import { EventRecord } from '@/queries/events';

import { ReactComponent as ComputerFile } from '@/assets/icons/ComputerFile.svg';
import { ReactComponent as Decline } from '@/assets/icons/Decline.svg';
import { ReactComponent as Dollar } from '@/assets/icons/Dollar.svg';
import { ReactComponent as Dot } from '@/assets/icons/Dot.svg';
import { ReactComponent as Estimator } from '@/assets/icons/Estimator.svg';
import { ReactComponent as Flag } from '@/assets/icons/Flag.svg';
import { ReactComponent as PaperPlane } from '@/assets/icons/PaperPlane.svg';
import { ReactComponent as Star } from '@/assets/icons/Star.svg';
import { ReactComponent as SpeechBubble } from '@/assets/icons/speech-bubble.svg';

const COPY = {
    assignedToProject: 'assigned to project',
    completedProject: 'Completed the project',
    estimationRequestSent: 'Estimation request sent',
    estimatorUnassigned: 'unassigned from project',
    declinedAssignment: 'declined estimation request',
    newFilesUploaded: 'new files uploaded',
    newMessages: 'new messages',
    projectSubmitted: 'Project submitted',
};

export const activityDateString = (date: Moment): string => {
    const offset = moment().format('Z');
    return date.utcOffset(offset).format('MM/DD/YY h:mm a');
};

export interface EventContent {
    icon: Svg;
    description: string;
}

const getEventGroupLinkedUserName = async (group: GroupedEvents): Promise<null | string> => {
    if (group.linkedUserID === null) {
        return null;
    }
    const { data } = await executeBaseUsersAuth0LessQuery({ id: group.linkedUserID });
    const userData = data?.baseUsers.nodes;
    if (!userData || userData.length === 0) {
        return null;
    }
    return [userData[0].firstName, userData[0].lastName].join(' ');
};

export const mapEventGroupToContent = async (group: GroupedEvents): Promise<EventContent> => {
    switch (group.eventType) {
        case EventTypeName.AcceptAssignment: {
            const uName = await getEventGroupLinkedUserName(group);
            const reason = extractEventContent(group.message ?? '');
            return {
                icon: Estimator,
                description: `${uName ?? 'Estimator'} ${COPY.assignedToProject} ${
                    reason ? `(${reason})` : ''
                }`,
            };
        }
        case EventTypeName.AssignEstimator: {
            const uName = await getEventGroupLinkedUserName(group);
            return {
                icon: PaperPlane,
                description: `${COPY.estimationRequestSent}${uName ? ` to ${uName}` : ''}`,
            };
        }
        case EventTypeName.CreateProject:
            return { icon: Star, description: COPY.projectSubmitted };
        case EventTypeName.DeclineAssignment: {
            const uName = await getEventGroupLinkedUserName(group);
            const reason = extractEventContent(group.message ?? '');
            return {
                icon: Decline,
                description: `${uName ?? 'Estimator'} ${COPY.declinedAssignment} (${reason})`,
            };
        }
        case EventTypeName.PostMessage:
            return { icon: SpeechBubble, description: `${group.count} ${COPY.newMessages}` };
        case EventTypeName.UnassignEstimator: {
            const uName = await getEventGroupLinkedUserName(group);
            return {
                icon: Decline,
                description: `${uName ?? 'Estimator'} ${COPY.estimatorUnassigned}`,
            };
        }
        case EventTypeName.UploadProjectRelatedFiles:
            return { icon: ComputerFile, description: `${group.count} ${COPY.newFilesUploaded}` };
        case EventTypeName.ApproveEstimate:
            return { icon: Flag, description: COPY.completedProject };
        case EventTypeName.EditProject: {
            return { icon: Dollar, description: group.message ?? 'Project price changed' };
        }
        case EventTypeName.CancelProject: {
            return { icon: Decline, description: group.message ?? 'Project canceled' };
        }
        default:
            return { icon: Dot, description: group.eventType };
    }
};

const reducableEvents: AnyEventTypeName[] = [
    EventTypeName.PostMessage,
    EventTypeName.UploadProjectRelatedFiles,
];

export interface EventIdsByStatus {
    read: number[];
    unread: number[];
}

export interface GroupedEvents {
    count: number;
    time?: Moment;
    eventType: string;
    eventIds?: EventIdsByStatus;
    linkedUserID: number | null;
    message?: string;
}

export const extractUserID = (event: EventRecord): number | null => {
    const uidRegRes = /<(\d+)>$/[Symbol.match](event.message ?? '')?.pop();
    if (uidRegRes !== undefined && !isNaN(Number(uidRegRes))) {
        return Number(uidRegRes);
    }
    return null;
};

export const extractEventContent = (eventMessage: string): string => {
    const uidRegRes = /\((.+)\)/[Symbol.match](eventMessage)?.pop();
    return uidRegRes ?? '';
};

// reduceEvents is an es6 reducer function which groups compatable sequential events.
export const reduceEvents = (acc: GroupedEvents[], event: EventRecord): GroupedEvents[] => {
    // If the last event was of a different type, append the event to the accumulator.
    const lastEntry = acc[acc.length - 1];
    const newReadEventIds = event.seen ? [event.id] : [];
    const newUnreadEventIds = event.seen ? [] : [event.id];
    if (
        !reducableEvents.includes(event.eventTypeName) ||
        acc.length === 0 ||
        lastEntry.eventType !== event.eventTypeName
    ) {
        return [
            ...acc,
            {
                count: 1,
                time: event.created !== undefined ? moment.utc(event.created) : undefined,
                eventType: event.eventTypeName,
                eventIds: { read: newReadEventIds, unread: newUnreadEventIds },
                linkedUserID: extractUserID(event),
                message: event.message,
            },
        ];
    }

    // Augment the lastest entry, updating the timestamp and incrementing the count.
    return [
        ...acc.slice(0, acc.length - 1),
        {
            ...lastEntry,
            time: event.created !== undefined ? moment.utc(event.created) : lastEntry.time,
            count: lastEntry.count + 1,
            eventIds: {
                read: [...(lastEntry.eventIds?.read ?? []), ...newReadEventIds],
                unread: [...(lastEntry.eventIds?.unread ?? []), ...newUnreadEventIds],
            },
        },
    ];
};

// Mutate all elements with ids in set eventIds to seen/unseen.
export const markEventsSeen = (seen: boolean, eventIds: number[]): Promise<EventRecord[]> =>
    Promise.all(
        eventIds.map((id) =>
            executeUpdateEventMutation({
                input: {
                    id,
                    patch: {
                        seen,
                    },
                },
            })
        )
    ).then((results) =>
        results.map((result) => result.data?.updateEvent.event).filter(notUndefined)
    );

export const toggleSetInclusion = <T>(set: Set<T>, item: T, include: boolean): Set<T> => {
    include ? set.add(item) : set.delete(item);
    return set;
};
