import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { darkPalette } from './palette/darkPalette';
import { lightPalette } from './palette/lightPalette';

interface Props {
    variant?: 'light' | 'dark';
}

export const Mode: FC<Props> = ({ variant, ...props }) => {
    const theme = useTheme();

    const nextTheme = createTheme({
        ...theme,
        palette: variant === 'light' ? lightPalette : darkPalette,
    });

    return <ThemeProvider theme={nextTheme} {...props} />;
};
