import { styled as styledByMui } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';

export const MenuBalanceDisclaimer = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
}));

export const MenuBalanceHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[21],
}));

export const UserDropdownAvatar = styledByMui(Avatar)((options) => ({
    height: '32px',
    width: '32px',
    backgroundColor: options.theme.palette.hues.neutral[48],
}));

export const UserDropdownIcon = styledByMui(SvgIcon)({
    height: '16px',
    width: '16px',
});

export const UserDropdownIconButton = styledByMui(IconButton)({ padding: 0 });

export const MenuInformation = styledByMui('div')((options) => ({
    color: options.theme.palette.hues.neutral[21],

    padding: '8px 16px 8px',
    width: '256px',

    [`& > ${MenuBalanceDisclaimer}`]: {
        marginTop: '8px',
        marginBottom: '0px',
    },
}));
