/*
 * Container contains the admin dashboard's projects section.
 */
import React, { FC, useEffect } from 'react';

import styled from 'styled-components';

import { ProjectTable } from './ProjectTable';
import { useProjects } from './context';
import { usePaginatedProjectsWithEvents } from './hooks/usePaginatedProjectsWithEvents';
import { subscribeToOpenedProjectObservable } from './observables';
import { filterProjects } from './sort';

import { formatters } from '@/common/currency';
import { useIsWebkit } from '@/common/hooks/useIsWebkit';
import { AttentionFilter } from '@/components/AdminDashboard/AttentionFilter';
import { updateRecordArr } from '@/components/AdminDashboard/ProjectPanel/Files/utils';
import { AdminDashboardContainer } from '@/components/AdminDashboard/styled';
import { CenteredSpinnerLoader } from '@/components/ui/loaders/CenteredSpinnerLoader';
import { Modal } from '@/components/ui/modals/Modal';
import { useNotifications } from '@/contexts/Notifications';
// import { EventRecord } from '@/queries/events';
import { useApproveAutoQuoteProjectPricingMutation } from '@/graphql';
import { subscribeToSelectedTabObservable } from '@/interfaces/adminDashboardTopbar';
// import { useEventCreatedSubscription, useEventChangedSubscription } from '@/subscriptions/events';
import { useProjectWithEventsSubscription } from '@/subscriptions/projects';

const COPY = {
    approveProjectPriceMessage: 'Project price has been successfully approved.',
    approveProjectPriceErrorMessage: 'Something went wrong. Please try again.',
};

export const Container: FC = () => {
    const {
        search,
        filteredProjects,
        setFilteredProjects,
        selectedProjectIds,
        setSelectedProjectIds,
        projects,
        setProjects,
        tabType,
        setTabType,
        sortingMethod,
        activeProject,
        setActiveProject,
        confirmPriceProject,
        setConfirmPriceProject,
    } = useProjects();

    const isWebkit = useIsWebkit();
    const { addNotification } = useNotifications();

    const projectsSubscriptionResult = useProjectWithEventsSubscription();
    // const eventCreatedSubscriptionResult = useEventCreatedSubscription();
    // const eventChangedSubscriptionResult = useEventChangedSubscription();
    const [mutateAutoQuoteProjectPricing, { loading }] =
        useApproveAutoQuoteProjectPricingMutation();

    const { fetchMoreProjects, isFetching } = usePaginatedProjectsWithEvents({
        search,
        setProjects,
        tabType,
    });

    const priceUsdCents =
        confirmPriceProject &&
        confirmPriceProject.pricingAssignment &&
        confirmPriceProject.pricingAssignment.projectPricingsByPricingAssignmentId?.nodes[0]
            .priceUsdCents;

    useEffect(() => {
        const subscription = subscribeToSelectedTabObservable(setTabType);
        return (): void => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        setSelectedProjectIds([]);
    }, [tabType]);

    useEffect(() => {
        setFilteredProjects([
            ...filterProjects(projects, tabType, sortingMethod, selectedProjectIds),
        ]);
    }, [projects, tabType, sortingMethod, selectedProjectIds]);

    // const modifyEventInProject = (event?: EventRecord | null): void => {
    //     const eventProjectId = event?.projectId;

    //     if (event === undefined || event === null || eventProjectId === undefined) {
    //         return;
    //     }
    //     setProjects((projects) => {
    //         const projectIdx = projects.findIndex((project) => project.id === eventProjectId);
    //         if (projectIdx === -1) {
    //             return projects;
    //         }
    //         return [
    //             ...updateRecordArr(projects, {
    //                 ...projects[projectIdx],
    //                 events: {
    //                     nodes: updateRecordArr(projects[projectIdx].events.nodes, event).filter(
    //                         (event) => !event.seen
    //                     ),
    //                 },
    //             }),
    //         ];
    //     });
    // };

    const onConfirmPrice = async (): Promise<void> => {
        if (!confirmPriceProject) {
            return;
        }

        const result = await mutateAutoQuoteProjectPricing({
            variables: {
                input: {
                    projectID: confirmPriceProject.id.toString(),
                },
            },
        });

        if (result.errors) {
            addNotification(
                {
                    title: 'Error',
                    content: result.errors.join() || COPY.approveProjectPriceErrorMessage,
                },
                'error',
                true
            );
        } else {
            addNotification(
                { title: 'Success', content: COPY.approveProjectPriceMessage },
                'success',
                true
            );
            setConfirmPriceProject(null);
        }
    };

    // useEffect(
    //     () => modifyEventInProject(eventCreatedSubscriptionResult?.data?.EventCreated.eventLog),
    //     [eventCreatedSubscriptionResult]
    // );

    // useEffect(
    //     () => modifyEventInProject(eventChangedSubscriptionResult?.data?.EventChanged.eventLog),
    //     [eventChangedSubscriptionResult]
    // );

    useEffect(() => {
        const changedProject = projectsSubscriptionResult?.data?.ProjectChanged.projectEntry;
        if (changedProject === undefined || changedProject === null) {
            return;
        }
        setProjects((projects) => [...updateRecordArr(projects, changedProject)]);
    }, [projectsSubscriptionResult]);

    // Subscribe to update to keep track of opened projects.
    useEffect(() => {
        const subscription = subscribeToOpenedProjectObservable((payload) => {
            setActiveProject(payload?.project ?? null);
        });
        return (): void => subscription.unsubscribe();
    }, []);

    return (
        <>
            <AdminDashboardContainer isWebkit={isWebkit}>
                <AttentionFilter />
                <ProjectTable
                    projects={filteredProjects}
                    activeProject={activeProject}
                    setActiveProject={setActiveProject}
                    fetchMoreProjects={fetchMoreProjects}
                    isFetching={isFetching}
                />
            </AdminDashboardContainer>
            {!confirmPriceProject?.isPriceFinal && priceUsdCents && (
                <Modal
                    isOpen={true}
                    close={(): void => {
                        setConfirmPriceProject(null);
                    }}
                    headerText={`
                        Set estimated project price at ${formatters
                            .usd()
                            .format(priceUsdCents / 100)}?`}
                    modalContent={
                        loading ? (
                            <CenteredSpinnerLoader style={{ padding: '0' }} />
                        ) : (
                            <React.Fragment>
                                {confirmPriceProject &&
                                    confirmPriceProject.team &&
                                    `${confirmPriceProject.team.name} will be notified`}
                                <ConfirmationButtons>
                                    <CancelButton
                                        onClick={(): void => {
                                            setConfirmPriceProject(null);
                                        }}
                                    >
                                        Cancel
                                    </CancelButton>
                                    <ConfirmButton
                                        onClick={async (): Promise<void> => await onConfirmPrice()}
                                    >
                                        Confirm
                                    </ConfirmButton>
                                </ConfirmationButtons>
                            </React.Fragment>
                        )
                    }
                />
            )}
        </>
    );
};

const ConfirmationButtons = styled.div`
    margin-top: 1.25rem;
`;

const ConfirmButton = styled.button`
    height: 2.25rem;
    width: 6.4375rem;
    padding: 0 1.5rem;

    cursor: pointer;
    color: #fff;
    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 2.25rem;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    white-space: nowrap;

    text-align: center;
    margin-left: 0.625rem;
`;

const CancelButton = styled.button`
    height: 2.25rem;
    width: 6.4375rem;
    padding: 0 1.5rem;

    background-color: transparent;
    border: 1px solid #d7e1e5;

    cursor: pointer;
    color: #1b2235;
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 2.25rem;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    white-space: nowrap;

    text-align: center;
`;
