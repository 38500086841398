import gql from 'graphql-tag';

import { PlanPage } from '../common/types';
import { makeUseSubscription } from '../frog';

export type ProjectPlanPageData = {
    projectPlanPageEntry?: PlanPage | null;
};

export type ProjectPlanPageMessage = {
    ProjectPlanPage: ProjectPlanPageData;
};

export const projectPlanPageSubscription = gql`
    subscription {
        ProjectPlanPage {
            projectPlanPageEntry {
                id
                heightIn
                heightPx
                markups
                pageId
                name
                widthIn
                widthPx
                removed
                scale
                orientation
                originalOrientation
                projectPlanId
                projectPlan {
                    parentProjectPlanFileUuid
                    uuid
                    id
                    projectId
                    filename
                }
                assets {
                    nodes {
                        extension
                        id
                        projectId
                        key
                        parentId
                        position
                        assetDimension {
                            orientation
                        }
                    }
                }
            }
        }
    }
`;

export const useProjectPlanPageSubscription = makeUseSubscription<ProjectPlanPageMessage>(
    projectPlanPageSubscription
);
