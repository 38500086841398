/*
    Context to interact with AWS S3. Content of file is expected to be a
    Blob, from which we extract the MIME type. Uses established file path
    constructed from filename, project UUID and the type of file, whether a
    project plan or a estimation.
*/
import { TrackEventName, track } from '@/common/analytics';
import { Env } from '@/common/env';
import { PlanPageOrientation } from '../common/types';
import { determineAWSSubdirectory, getFileExtension } from '@/common/utils/helpers';
import { makeContext } from '@/common/utils/makeContext';
import { ProjectEstimateFileRecord } from '@/queries/projectEstimateFiles';
import { ProjectPlanFileRecord } from '@/queries/projectPlanFiles';
import { ProjectUploadFileRecord } from '@/queries/projectUploadFiles';
import { useAWS } from './AWS';
import { S3 } from 'aws-sdk';
import tk from 'timekeeper';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import download from 'downloadjs';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import to from 'await-to-js';
import { IFileDirectory } from '@/graphql';

const COPY = {
    publicUploadErrorTitle: 'Operation failed',
    errorTitle: 'File failed to upload',
    cancelTitle: 'Upload cancelled',
    failedToDownload: 'File failed to download',
    tryAgain: 'Please try again or contact us if you need assistance.',
    removeError: 'Failed to remove file',
};

type PutRequest = S3.Types.PutObjectRequest;
type GetRequest = S3.Types.GetObjectRequest;
export type PutOutput = S3.Types.CompleteMultipartUploadOutput;
type DeleteRequest = S3.Types.DeleteObjectRequest;
type DeleteOutput = S3.Types.DeleteObjectOutput;
type CopyObjectRequest = S3.Types.CopyObjectRequest;
type CopyObjectOutput = S3.Types.CopyObjectOutput;

interface FullManagedUpload extends ManagedUpload {
    body: File;
}

interface UploadMetaData {
    progressPercent?: number;
    error?: string;
}

// Explicitly add ManagedUploadOptions, these will be in the response payload.
export type CustomManagedUpload = FullManagedUpload &
    UploadMetaData &
    ManagedUpload.ManagedUploadOptions;

export interface SignPathURLOptions {
    trimLeadingSlash: boolean;
}

type StorageProps = {
    upload: (
        filename: string,
        projectUUID: string,
        fileUUID: string,
        subdirectory: 'plans' | 'estimates' | 'uploads',
        contents: Blob,
        tags?: { [key: string]: string }
    ) => Promise<PutOutput>;
    uploadPublic: (filename: string, contents: Blob) => Promise<PutOutput>;
    builderDetailsUpload: (
        subdirectory: IFileDirectory,
        fileUUID: string,
        filename: string,
        contents: Blob,
        teamID?: string,
        tags?: { [key: string]: string }
    ) => Promise<PutOutput>;
    remove: (
        filename: string,
        projectUUID: string,
        fileUUID: string,
        subdirectory: 'plans' | 'estimates' | 'uploads'
    ) => Promise<PutOutput>;
    removeBuilderDetailsFile: (
        teamID: string,
        subdirectory: IFileDirectory,
        fileUUID: string,
        filename: string
    ) => Promise<PutOutput>;
    getUrl: (
        projectUUID: string,
        projectPlanUUID: string,
        pageID: number,
        orientation: PlanPageOrientation,
        thumbnail?: boolean,
        ignoreRotation?: boolean
    ) => Promise<string>;
    getFileUrl: (
        file: ProjectEstimateFileRecord | ProjectPlanFileRecord | ProjectUploadFileRecord,
        projectUUID: string
    ) => Promise<string>;
    getBuilderDetailsFileUrl: (
        filename: string,
        fileuuid: string,
        subdir: IFileDirectory,
        teamID?: string
    ) => Promise<string>;
    downloadPath: (
        path: string,
        downloadName: string,
        options?: SignPathURLOptions
    ) => Promise<ReturnType<typeof download>>;
    signPath: (path: string, options?: SignPathURLOptions) => Promise<string>;
    copyBuilderDetailsFile: (
        filename: string,
        originaluuid: string,
        copyuuid: string,
        subdir: IFileDirectory,
        teamID?: string
    ) => Promise<CopyObjectOutput>;
    currentUploads: CustomManagedUpload[];
    bucket: string;
};

const buildPlanPagePath = (
    projectUUID: string,
    planUUID: string,
    pageNumber: string,
    orientation: PlanPageOrientation,
    thumbnail = false,
    ignoreRotation = false
): string => {
    return (
        `projects/${projectUUID}/plans/${planUUID}/` +
        `${thumbnail ? 'thumbnail_' : ''}page_${pageNumber}` +
        `${!thumbnail && !ignoreRotation ? `_${orientation}` : ''}.png`
    );
};

function buildPlanFilePath(projectUUID: string, subdir: string, uuid: string, ext: string): string {
    let err = false;
    if (!uuid || !projectUUID || !['plans', 'uploads', 'estimates'].includes(subdir)) {
        err = true;
    }
    if (subdir === 'plans' && !ext) err = true;
    if (subdir === 'uploads' && ext) err = true;
    if (err) return '';
    let path = `projects/${projectUUID}/${subdir}/${uuid}`;
    if (ext) path = path + `/source.${ext}`;
    return path;
}

function buildBuilderDetailsFilePath(
    teamID: string | undefined,
    subdir: IFileDirectory,
    fileUUID: string,
    filename: string
): string {
    if (!teamID) return '';
    const path = `teams/${teamID}/${subdir}/${fileUUID}/${filename}`;
    return path;
}

const { useConsumer, Provider } = makeContext<StorageProps>(() => {
    const { getPlanBucket } = useAWS();
    const { enqueueSnackbar } = useSnackbar();
    const [currentUploads, setCurrentUploads] = useState<CustomManagedUpload[]>([]);

    const bucket = Env.awsPlanBucket;

    const buildFileUploadPath = (
        filename: string,
        projectUUID: string,
        fileUUID: string,
        subdirectory: string
    ): string => {
        let match: RegExpMatchArray | null = null;
        let sourcePart = '';
        if (subdirectory === 'plans') {
            const sourcePartRegex = /\.[^.]*$/;
            match = sourcePartRegex.exec(filename);
            if (match) {
                sourcePart = `/source${match.pop() ?? ''}`;
            } else {
                sourcePart = '/source.default';
            }
        }
        return `projects/${projectUUID}/${subdirectory}/${fileUUID}${sourcePart}`;
    };

    const restClient = {
        put: async (request: PutRequest): Promise<PutOutput> => {
            const fileName = (request.Body as File).name;
            let planBucket: AWS.S3;
            try {
                setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] => [
                    ...uploads,
                    { body: { name: fileName } as File } as CustomManagedUpload,
                ]);
                planBucket = await getPlanBucket();
            } catch (error) {
                setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                    uploads.map(
                        (u: CustomManagedUpload): CustomManagedUpload =>
                            u.body.name === fileName
                                ? // We want to add an additional property to an existing object while preserving
                                  // the original prototype's methods, and this seems like the easiest one-line
                                  // way of doing it.
                                  (Object.assign(Object.create(u), u, {
                                      error: error as Error,
                                  }) as CustomManagedUpload)
                                : u
                    )
                );
                Promise.reject([fileName, null]);
            }
            return new Promise<PutOutput>((resolve, reject) => {
                if (!planBucket) reject([fileName, null]);
                const upload = planBucket.upload(
                    request,
                    (error: Error, data: S3.ManagedUpload.SendData) => {
                        if (error) {
                            setCurrentUploads(
                                (uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                                    uploads.map(
                                        (u: CustomManagedUpload): CustomManagedUpload =>
                                            u.body.name === fileName
                                                ? // We want to add an additional property to an existing object while preserving
                                                  // the original prototype's methods, and this seems like the easiest one-line
                                                  // way of doing it.
                                                  (Object.assign(Object.create(u), u, {
                                                      error,
                                                  }) as CustomManagedUpload)
                                                : u
                                    )
                            );
                            reject([fileName, error]);
                        } else {
                            resolve(data);
                        }
                    }
                ) as FullManagedUpload;
                upload.on('httpUploadProgress', function (this: FullManagedUpload, progress) {
                    const percent = Math.round((progress.loaded / progress.total) * 100);
                    setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                        uploads.map(
                            (u: CustomManagedUpload): CustomManagedUpload =>
                                u.body.name === this.body.name
                                    ? // We want to add an additional property to an existing object while preserving
                                      // the original prototype's methods, and this seems like the easiest one-line way
                                      // of doing it.
                                      (Object.assign(Object.create(this), this, {
                                          progressPercent: percent,
                                      }) as CustomManagedUpload)
                                    : u
                        )
                    );

                    // If download is completed remove file from
                    // currentUploads.
                    if (percent === 100) {
                        //The code below looks silly because the
                        //aws-sdk library provides incomplete typings
                        setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                            uploads.filter(
                                (u: CustomManagedUpload): boolean => u.body.name !== this.body.name
                            )
                        );
                    }
                });
                setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] => [
                    ...uploads.filter((u) => u.body.name !== upload.body.name),
                    upload,
                ]);
            });
        },
        remove: async (request: DeleteRequest): Promise<DeleteOutput> => {
            const planBucket = await getPlanBucket();
            return new Promise((resolve, reject) => {
                planBucket.deleteObject(request, (error: Error, data: DeleteOutput) =>
                    error ? reject(error) : resolve(data)
                );
            });
        },
        signUrl: async (request: GetRequest): Promise<string> => {
            const planBucket = await getPlanBucket();
            return new Promise((resolve, reject) => {
                // As seen on https://advancedweb.hu/cacheable-s3-signed-urls/ - we generate a fresh batch of links
                // in 10 minute intervals to make sure they are always up to date but to also leverage browser caching
                // as much as possible
                const getTruncatedTime = () => {
                    const currentTime = new Date();
                    const d = new Date(currentTime);

                    d.setMinutes(Math.floor(d.getMinutes() / 10) * 10);
                    d.setSeconds(0);
                    d.setMilliseconds(0);

                    return d;
                };
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { ResponseContentDisposition: _, ...putRequest } = request;
                tk.withFreeze(getTruncatedTime(), () => {
                    try {
                        const url = planBucket.getSignedUrl('getObject', request);
                        resolve(url);
                    } catch (e) {
                        reject(e);
                    }
                });
            });
        },
        copyFile: async (request: CopyObjectRequest): Promise<CopyObjectOutput> => {
            const planBucket = await getPlanBucket();
            return new Promise((resolve, reject) => {
                planBucket.copyObject(request, (error: Error, data: CopyObjectOutput) =>
                    error ? reject(error) : resolve(data)
                );
            });
        },
    };

    const upload = async (
        filename: string,
        projectUUID: string,
        fileUUID: string,
        subdirectory: 'plans' | 'estimates' | 'uploads',
        contents: Blob,
        tags?: { [key: string]: string }
    ): Promise<PutOutput> => {
        // Accumulate any tags, if they exist.
        // Otherwise, pass undefined.
        const tagging = tags
            ? Object.entries(tags)
                  .reduce<string[]>((acc, [key, value]) => [...acc, `${key}=${value}`], [])
                  .join('&')
            : undefined;

        const promise = restClient.put({
            Key: buildFileUploadPath(filename, projectUUID, fileUUID, subdirectory),
            Bucket: bucket,
            Body: contents,
            ContentType: contents.type || 'application/octet-stream',
            Tagging: tagging,
        });
        return promise
            .then((result) => {
                track(TrackEventName.FilesUploaded);
                return result;
            })
            .catch(([fileName, error]: [string, Error]) => {
                if (error && error.name === 'RequestAbortedError') {
                    enqueueSnackbar(`${COPY.cancelTitle} - ${filename}`, {
                        variant: 'error',
                        autoHideDuration: 5000,
                    });
                } else {
                    enqueueSnackbar(`${COPY.errorTitle} - ${filename}`, {
                        variant: 'error',
                        autoHideDuration: 5000,
                    });
                }
                setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                    uploads.filter((u: CustomManagedUpload): boolean => u.body.name !== fileName)
                );
                return Promise.reject(fileName);
            });
    };

    const uploadPublic = async (filename: string, contents: Blob): Promise<PutOutput> => {
        const promise = restClient.put({
            Key: `projects/${filename}`,
            Bucket: bucket,
            Body: contents,
            ContentType: contents.type || 'application/octet-stream',
            Tagging: 'public=yes',
        });
        return promise
            .then((result) => {
                return result;
            })
            .catch(([fileName, error]: [string, Error]) => {
                if (error) {
                    enqueueSnackbar(COPY.publicUploadErrorTitle, {
                        variant: 'error',
                        autoHideDuration: 5000,
                    });
                }
                setCurrentUploads((uploads: CustomManagedUpload[]): CustomManagedUpload[] =>
                    uploads.filter((u: CustomManagedUpload): boolean => u.body.name !== fileName)
                );
                return Promise.reject(fileName);
            });
    };

    const builderDetailsUpload = async (
        subdirectory: IFileDirectory,
        fileUUID: string,
        filename: string,
        contents: Blob,
        teamID?: string,
        tags?: { [key: string]: string }
    ): Promise<PutOutput> => {
        // Accumulate any tags, if they exist.
        // Otherwise, pass undefined.
        const tagging = tags
            ? Object.entries(tags)
                  .reduce<string[]>((acc, [key, value]) => [...acc, `${key}=${value}`], [])
                  .join('&')
            : undefined;

        const [error, result] = await to(
            restClient.put({
                Key: buildBuilderDetailsFilePath(teamID, subdirectory, fileUUID, filename),
                Bucket: bucket,
                Body: contents,
                ContentType: contents.type || 'application/octet-stream',
                Tagging: tagging,
            })
        );
        if (error) {
            if (error && error.name === 'RequestAbortedError') {
                enqueueSnackbar(`${COPY.cancelTitle} - ${filename}`, {
                    variant: 'error',
                    autoHideDuration: 5000,
                });
            } else {
                enqueueSnackbar(`${COPY.errorTitle} - ${filename}`, {
                    variant: 'error',
                    autoHideDuration: 5000,
                });
            }
            setCurrentUploads((uploads) =>
                uploads.filter((u): boolean => u.body.name !== filename)
            );
            return Promise.reject(filename);
        }

        track(TrackEventName.FilesUploaded);
        return result as PutOutput;
    };

    const remove = async (
        filename: string,
        projectUUID: string,
        fileUUID: string,
        subdirectory: 'plans' | 'estimates' | 'uploads'
    ): Promise<PutOutput> =>
        await restClient.remove({
            Key: buildFileUploadPath(filename, projectUUID, fileUUID, subdirectory),
            Bucket: bucket,
        });

    const removeBuilderDetailsFile = async (
        teamID: string,
        subdirectory: IFileDirectory,
        fileUUID: string,
        filename: string
    ): Promise<DeleteOutput> => {
        const [error, result] = await to(
            restClient.remove({
                Key: buildBuilderDetailsFilePath(teamID, subdirectory, fileUUID, filename),
                Bucket: bucket,
            })
        );
        if (error) {
            enqueueSnackbar(`${COPY.removeError} - ${filename}`, {
                variant: 'error',
                autoHideDuration: 5000,
            });
            return Promise.reject(filename);
        }

        return result as DeleteOutput;
    };

    const getUrl = async (
        projectUUID: string,
        projectPlanUUID: string,
        pageID: number,
        orientation: PlanPageOrientation,
        thumbnail = false,
        ignoreRotation = false
    ): Promise<string> => {
        return await restClient.signUrl({
            Key: buildPlanPagePath(
                projectUUID,
                projectPlanUUID,
                pageID.toString(),
                orientation,
                thumbnail,
                ignoreRotation
            ),
            Bucket: bucket,
        });
    };

    const getFileUrl = async (
        file: ProjectEstimateFileRecord | ProjectPlanFileRecord | ProjectUploadFileRecord,
        projectUUID: string
    ): Promise<string> => {
        const encodedFilename = encodeURIComponent(file.filename ?? '');
        const subdir = determineAWSSubdirectory(file.__typename);
        const ext = subdir === 'plans' ? getFileExtension(file.filename ?? '') : null;
        const returnedPromise = restClient.signUrl({
            Key: buildPlanFilePath(projectUUID, subdir ?? '', file.uuid, ext ?? ''),
            Bucket: bucket,
            ResponseContentDisposition: `attachment; filename="${encodedFilename}"`,
        });
        returnedPromise.catch((_) => {
            enqueueSnackbar(COPY.failedToDownload, {
                variant: 'error',
                autoHideDuration: 5000,
            });
        });
        return await returnedPromise;
    };

    const getBuilderDetailsFileUrl = async (
        filename: string,
        fileuuid: string,
        subdir: IFileDirectory,
        teamID?: string
    ): Promise<string> => {
        const encodedFilename = encodeURIComponent(filename ?? '');
        const [error, result] = await to(
            restClient.signUrl({
                Key: buildBuilderDetailsFilePath(teamID, subdir, fileuuid, filename),
                Bucket: bucket,
                ResponseContentDisposition: `attachment; filename="${encodedFilename}"`,
            })
        );
        if (error) {
            enqueueSnackbar(COPY.failedToDownload, {
                variant: 'error',
                autoHideDuration: 5000,
            });
            return '';
        }
        return result as string;
    };

    // Get a signed url of a file at an aws path.
    const signPath = (path: string, options?: SignPathURLOptions): Promise<string> => {
        let urlKey = path;
        if (options?.trimLeadingSlash && urlKey.startsWith('/')) {
            urlKey = urlKey.slice(1);
        }
        return restClient.signUrl({
            Key: urlKey,
            Bucket: bucket,
        });
    };

    // Download a file at an aws path.
    const downloadPath = async (
        path: string,
        downloadName: string,
        options?: SignPathURLOptions
    ): Promise<ReturnType<typeof download>> => {
        const signedURL = await signPath(path, options);
        const fetchRes = await fetch(signedURL);
        const resBlob = await fetchRes.blob();
        return download(resBlob, downloadName, resBlob.type);
    };

    // Copy file from one location to another
    const copyBuilderDetailsFile = async (
        filename: string,
        originaluuid: string,
        copyuuid: string,
        subdir: IFileDirectory,
        teamID?: string
    ): Promise<CopyObjectOutput> => {
        const copySource =
            bucket + '/' + buildBuilderDetailsFilePath(teamID, subdir, originaluuid, filename);
        return restClient.copyFile({
            CopySource: copySource,
            Bucket: bucket,
            Key: buildBuilderDetailsFilePath(teamID, subdir, copyuuid, filename),
        });
    };

    return {
        currentUploads,
        upload,
        uploadPublic,
        builderDetailsUpload,
        remove,
        removeBuilderDetailsFile,
        getUrl,
        getBuilderDetailsFileUrl,
        getFileUrl,
        downloadPath,
        signPath,
        copyBuilderDetailsFile,
        bucket,
    };
});

export const useStorage = useConsumer;
export const StorageProvider = Provider;
