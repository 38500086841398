import { useHandleAssembliesImported } from './useHandleAssembliesImported';
import { useHandleAssemblyLibraryAssignment } from './useHandleAssemblyLibraryAssignment';

import { IAssemblyLibraryOperation } from '@/graphql';
import { IAssemblyFragment } from '@/graphql';

interface UseHandleFavoritingArguments {
    projectId: number;
}
type UseHandleFavoritingPayload = (
    assemblies: IAssemblyFragment[],
    operation: IAssemblyLibraryOperation
) => Promise<void>;

export const useHandleFavoriting = ({
    projectId,
}: UseHandleFavoritingArguments): UseHandleFavoritingPayload => {
    const handleAssembliesImported = useHandleAssembliesImported({ projectId });
    const handleAssemblyLibraryAssignment = useHandleAssemblyLibraryAssignment();

    return async (
        assemblies: IAssemblyFragment[],
        operation: IAssemblyLibraryOperation
    ): Promise<void> => {
        let assembliesData = assemblies;
        const assembliesRequiringCopy = assembliesData.filter(
            (a) => Number(a.projectID) !== Number(projectId)
        );
        const assembliesNotRequiringCopy = assembliesData.filter(
            (a) => Number(a.projectID) === Number(projectId)
        );
        if (assembliesRequiringCopy.length > 0 && operation === IAssemblyLibraryOperation.Add) {
            const result = await handleAssembliesImported(assembliesRequiringCopy);
            if (
                !result.assembliesDuplication.edges ||
                result.assembliesDuplication.edges.length === 0
            ) {
                return;
            }
            assembliesData = [
                ...assembliesNotRequiringCopy,
                ...result.assembliesDuplication.edges.map((e) => e.node),
            ];
        }
        await handleAssemblyLibraryAssignment(assembliesData, operation);
    };
};
