import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { ProjectDetailsRouter } from './ProjectDetailsRouter';
import { ProjectCreation } from '@/views/ProjectCreation';
import { Projects } from '@/views/Projects';
import { canCreateNewProject } from '@/views/Projects/utils';
import { useUser } from '@/contexts/User';

// Split component
// -----------------------------------------------------------------------------
// # TODO => Uncomment when we move from SCSS to styled-components
// const ProjectCreation = loadable(
//     () => import(
//         /* webpackChunkName: 'ProjectCreation' */
//         '@/views/ProjectCreation'
//     ),
//     {
//         resolveComponent: components => components.ProjectCreation,
//         fallback: <LoadingPage />
//     }
// );
// const Projects = loadable(
//     () => import(
//         /* webpackChunkName: 'Projects' */
//         /* webpackPreload: true */
//         '@/views/Projects'
//     ),
//     {
//         resolveComponent: components => components.Projects,
//         fallback: <LoadingPage />
//     }
// );
// const ProjectDetailsRouter = loadable(
//     () => import(
//         /* webpackChunkName: 'ProjectDetailsRouter' */
//         './ProjectDetailsRouter'
//     ),
//     {
//         resolveComponent: components => components.ProjectDetailsRouter,
//         fallback: <LoadingPage />
//     }
// );
// -----------------------------------------------------------------------------

export const ProjectRouter: FC = () => {
    const { path } = useRouteMatch();
    const user = useUser();

    return (
        <Switch>
            <Route exact path={path}>
                <Projects />
            </Route>
            {canCreateNewProject(user.data.user) && (
                <Route path={`${path}/new`}>
                    <ProjectCreation />
                </Route>
            )}
            <Route path={`${path}/:projectUUID`}>
                <ProjectDetailsRouter />
            </Route>
        </Switch>
    );
};
