/* Displays colum/panel with files of the project */
// #TODO => Remove this once new file manager is in place
import './ProjectFiles.scss';
import { determineAWSSubdirectory } from '@/common/utils/helpers';
import { DotsLoader } from '@/components//ui/loaders/DotsLoader';
import { FileManager, FileManagerFile } from '@/components/projects/ProjectCreation/FileManager';
import { ProjectDetailsComponentProps } from '@/components/projects/ProjectDetails/context';
import { SmallButton } from '@/components/ui/buttons/SmallButton';
import { useStorage } from '@/contexts/Storage';
import { useUser } from '@/contexts/User';
import { ProjectPlanFileRecord } from '@/queries/projectPlanFiles';
import React, { FC, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import { isPureEstimatorUser } from '@/views/Projects/utils';

const COPY = {
    project_creation_files_header: 'Files',
    project_creation_upload: 'Upload',
    project_creation_add_link: 'Add Link',
    project_creation_estimate_add_link: 'Add Link',
    project_creation_download_all: 'Download all',
};

type ProjectFilesProps = { className?: string };
export const ProjectFiles: FC<ProjectDetailsComponentProps & ProjectFilesProps> = ({
    className,
    useProjectDetails,
}) => {
    const {
        data: { user },
    } = useUser();
    const {
        createEstimate,
        removeEstimate,
        handleDownloadAll,
        isDownloading,
        links,
        plans,
        removePlan,
        project,
        createUpload,
        uploads,
        removeUpload,
    } = useProjectDetails();

    const { remove, getFileUrl } = useStorage();
    const fileUploadRef = useRef<HTMLInputElement>(null);

    const files = [...uploads, ...plans];

    if (!user.id) return <></>;

    const handleFilesAdd =
        (folder: 'uploads' | 'estimates') =>
        (files: File[]): void => {
            const isUpload = folder === 'uploads';
            const saveMethod = isUpload ? createUpload : createEstimate;

            files.forEach((f) => {
                const newUuid = uuid();
                saveMethod(user.id, project, f, newUuid);
            });
        };

    const handleFileDownload = (
        name: string,
        file: FileManagerFile,
        projectUuid?: string
    ): void => {
        getFileUrl(file as ProjectPlanFileRecord, projectUuid ?? '').then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.click();
        });
    };

    const handleFileRemove = (file: FileManagerFile): void => {
        const subdir = determineAWSSubdirectory(file.__typename);
        if (subdir === undefined || file.filename === undefined) return;
        remove(file.filename, project.uuid, file.uuid, subdir);
        switch (subdir) {
            case 'uploads':
                removeUpload(file.uuid);
                break;
            case 'plans':
                removePlan(file.uuid);
                break;
            case 'estimates':
                removeEstimate(file.uuid);
                break;
        }
    };

    const shouldDisplayTrashBin = (): boolean => {
        // Don't render the trash icon in the Files section
        return !isPureEstimatorUser(user);
    };

    const downloadAll = () => (): void => handleDownloadAll(project);

    const renderContent = (): JSX.Element => (
        <>
            <div className="header">
                <div className="header-content">
                    <Typography variant="h4">{COPY.project_creation_files_header}</Typography>
                    {isDownloading['files'] ? (
                        <DotsLoader />
                    ) : (
                        <div className="button-section">
                            {files.length > 0 && (
                                <SmallButton onClick={downloadAll()} className="download-all">
                                    {COPY.project_creation_download_all}
                                </SmallButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="content">
                <FileManager
                    className="hide-dropzone"
                    projectUuid={project.uuid}
                    displayTrashBin={shouldDisplayTrashBin()}
                    files={files}
                    links={links}
                    fileUploadRef={fileUploadRef}
                    onFilesAdd={handleFilesAdd('estimates')}
                    onFileDownload={handleFileDownload}
                    onFileRemove={handleFileRemove}
                />
            </div>
        </>
    );

    return <div className={className || 'project-files-v2'}>{renderContent()}</div>;
};
