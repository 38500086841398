import gql from 'graphql-tag';
import { Link, Upload } from '../common/types';

export type EditProjectMutationResponse = {
    editProject: {
        editedProject: {
            id: string;
            links: Link[];
            files: Upload[];
        };
    };
};

export const editProjectMutation = gql`
    mutation EditProject(
        $projectId: BigInt!
        $name: String!
        $bidsDueDate: String!
        $uuid: UUID!
        $links: [String!]!
        $files: [ProjectUploadInput!]!
        $trades: [BigInt!]!
        $userId: BigInt!
        $wageType: String!
    ) {
        editProject(
            input: {
                projectId: $projectId
                name: $name
                bidsDueDate: $bidsDueDate
                uuid: $uuid
                links: $links
                files: $files
                trades: $trades
                userId: $userId
                wageType: $wageType
            }
        ) {
            editedProject {
                id
                links {
                    id
                    message
                }
            }
        }
    }
`;
