import styled from 'styled-components';

import {
    adminDashboardPanelShadow,
    colorBackgroundSecondaryLightest,
    colorProjectPanelText,
} from '@/variables';

export const PanelWrapper = styled.div<{ show: boolean }>`
    height: 100%;
    width: calc(100% - 280px);
    z-index: 9;
    position: absolute;
    top: 0;
    visibility: ${(props): string => (props.show ? 'visible' : 'hidden')};
`;

export const PanelContainer = styled.div<{ open: boolean }>`
    position: fixed;
    top: 0;
    left: 100%;
    transform: ${(props): string => (props.open ? 'translateX(-100%)' : 'none')};
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 37.5rem;
    background-color: ${colorBackgroundSecondaryLightest};
    overflow: hidden auto;
    font-family: 'proxima-nova', sans-serif;
    color: ${colorProjectPanelText};
    box-shadow: ${adminDashboardPanelShadow};
    overflow: auto hidden;
    z-index: 10;

    &:focus {
        outline: none;
    }
`;

export const PanelHeader = styled.div``;

export const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 2;
    min-height: 0;
`;
