import styled from 'styled-components';

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
    align-items: center;
    margin: 10px 10px 30px 10px;
    padding: 7px 10px 7px 12px;
    background: #18224e;
    border-radius: 4px;
`;

export const NameContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const Name = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    color: #ffffff;
`;
