import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/32/checkmark.svg';
import { ReactComponent as PublishIcon } from '@/assets/icons/cloud-upload.svg';
import { useProjectFromParams } from '@/common/hooks/useProjectFromParams';
import { useToggle } from '@/common/hooks/useToggle';
import { PublishModal } from '@/components/PublishModal';
import { usePublish } from '@/components/projects/ProjectDetails/ProjectToolbars/hooks/usePublish';
import { EllipsisButton } from '@/components/ui/containers/ProjectsToolbar/EllipsisButton';
import { useEstimationProject } from '@/components/app/router/EstimationRoute/hooks/useEstimationProject';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { useNotifications } from '@/contexts/Notifications';
import { useExportMarkupPDF } from '@/mutations/exportMarkupPDF';
import React, { FC, useEffect } from 'react';
import { NavBarTabs } from '@/components/Estimate/Masthead/NavBarTabs';

import {
    isAdministratorUser,
    isBuilderUser,
    isEstimatorUser,
    isPureEstimatorUser,
    isProjectComplete,
    isProjectSetupComplete,
    isSaasProject,
    isProjectPendingEstimator,
} from '@/views/Projects/utils';

const COPY = {
    done: 'Done',
    projectSetup: 'Project setup',
    exportMarkedUpPdf: 'Export marked up PDF',
    exportPdfSuccessTitle: 'Marked up PDF exported successfully',
    exportPdfFailureTitle: 'Marked up PDF export failed',
    exportPdfSuccessMessage: 'Click here to view the file.',
    exportTakeoffSuccessTitle: 'Takeoff exported successfully',
    exportTakeoffFailureTitle: 'Takeoff export failed',
    exportTakeoffSuccessMessage: 'Click here to view the file.',
    setupProject: 'Project setup',
    publishProject: 'Publish project',
    projectPublished: 'Project published',
};

interface MastheadProps extends PaperProps {
    onSetupProjectButtonClick?: () => void;
}

export const Masthead: FC<MastheadProps> = ({ onSetupProjectButtonClick, ...props }) => {
    const {
        features: { builderEstimateView },
    } = useFeatures();

    const {
        data: { user },
    } = useUser();

    const { project } = useProjectFromParams();
    const { projectName } = useEstimationProject();

    const { addNotification } = useNotifications();

    const [isPublishModalOpen, setIsPublishModalOpen] = useToggle();

    const { publish, unpublish } = usePublish({ project: project ?? undefined });
    const [exportedPDFResult, exportMarkupPDF] = useExportMarkupPDF();

    const isAdministrator = isAdministratorUser(user);
    const isEstimator = isEstimatorUser(user);
    const isBuilder = isBuilderUser(user);

    const isProjectCompleted = isProjectComplete(project);

    const hasBuilderEstimateViewFeature = builderEstimateView(project?.created);

    const handleExportMarkupPDF = (): void => {
        if (project === null || project === undefined) {
            return;
        }
        exportMarkupPDF({
            projectUUID: project.uuid,
        });
    };

    useEffect(() => {
        if (!exportedPDFResult.fetching && !exportedPDFResult.error && exportedPDFResult.data) {
            addNotification(
                {
                    title: COPY.exportPdfSuccessTitle,
                    content: <p>{COPY.exportPdfSuccessMessage}</p>,
                    link: exportedPDFResult.data.exportPDF,
                },
                'success',
                false
            );
        } else if (!exportedPDFResult.fetching && exportedPDFResult.error) {
            addNotification(
                {
                    title: COPY.exportPdfFailureTitle,
                },
                'error'
            );
        }
    }, [exportedPDFResult]);

    // See: https://coda.io/d/1build-Engineering_d-21lT3iH7u/Frontend-Masthead-Buttons_suS-B

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                borderRadius: 0,
                height: '48px',
                padding: '0 24px',
            }}
            {...props}
        >
            {(() => {
                if (!project) {
                    return null;
                }

                return (
                    <Stack direction="row" justifyContent="space-between">
                        <NavBarTabs project={project} />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textAlign: 'center',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '300px',
                                }}
                                variant="h4"
                            >
                                {projectName}
                            </Typography>
                        </Box>
                        <Box>
                            {(() => {
                                return (
                                    <Stack alignItems="center" direction="row" spacing="8px">
                                        {hasBuilderEstimateViewFeature &&
                                            ((isEstimator && !isBuilder && !isAdministrator) ||
                                                (isEstimator && isAdministrator)) &&
                                            isProjectSetupComplete(project) &&
                                            !isProjectPendingEstimator(project) && (
                                                <Button
                                                    color="secondary"
                                                    disabled={isProjectCompleted}
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (isProjectCompleted) {
                                                            unpublish();
                                                        } else {
                                                            setIsPublishModalOpen();
                                                        }
                                                    }}
                                                    startIcon={
                                                        <SvgIcon viewBox="0 0 32 32">
                                                            {isProjectCompleted ? (
                                                                <CheckmarkIcon />
                                                            ) : (
                                                                <PublishIcon
                                                                    width={32}
                                                                    height={32}
                                                                />
                                                            )}
                                                        </SvgIcon>
                                                    }
                                                >
                                                    {isProjectCompleted
                                                        ? COPY.projectPublished
                                                        : COPY.publishProject}
                                                </Button>
                                            )}
                                        <Button
                                            color="secondary"
                                            disabled={exportedPDFResult.fetching}
                                            onClick={handleExportMarkupPDF}
                                            size="small"
                                            variant="outlined"
                                        >
                                            {COPY.exportMarkedUpPdf}
                                        </Button>
                                        {(isAdministrator || isPureEstimatorUser(user)) &&
                                            !isSaasProject(project) && (
                                                <Button
                                                    color="secondary"
                                                    disabled={exportedPDFResult.fetching}
                                                    onClick={onSetupProjectButtonClick}
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    {COPY.setupProject}
                                                </Button>
                                            )}
                                        <EllipsisButton />
                                    </Stack>
                                );
                            })()}
                        </Box>
                        {builderEstimateView(project?.created) && (
                            <PublishModal
                                isModalVisible={isPublishModalOpen}
                                close={setIsPublishModalOpen}
                                onPublish={publish}
                            />
                        )}
                    </Stack>
                );
            })()}
        </Paper>
    );
};
