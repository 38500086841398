import MuiTableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { styled as styledByMui } from '@mui/material/styles';

export const ColumnHeading = styledByMui(Typography)((options) => ({
    color: options.theme.palette.hues.neutral[48],
}));

export const TableCell = styledByMui(MuiTableCell)({
    paddingLeft: 0,
});

export const TableCellWide = styledByMui(MuiTableCell)({
    paddingLeft: 0,
    width: 317,
});

export const TableCellRegular = styledByMui(MuiTableCell)({
    paddingLeft: 0,
    width: 150,
});

export const LinkBox = styledByMui('div')({
    minWidth: 100,
});
