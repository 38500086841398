import { ApolloClient, executeQuery } from '@/common/apollo/execute';
import {
    ElementsDocument,
    IElementFragment,
    IElementsQuery,
    IElementsQueryVariables,
} from '@/graphql';

export const fetchElements = async (
    client: ApolloClient,
    elementUUIDs: string[]
): Promise<IElementFragment[]> => {
    const result = await executeQuery<IElementsQuery, IElementsQueryVariables>(client, {
        query: ElementsDocument,
        variables: {
            input: {
                uuids: elementUUIDs,
            },
        },
        fetchPolicy: 'network-only',
    });
    return result.elements;
};
