// To align with urql's types, we have to use the `extends object = {}` pattern.
/* eslint-disable @typescript-eslint/ban-types */
import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';
import { identity, Observable, Subject } from 'rxjs';
import { OperationContext, OperationResult } from 'urql';
import { pipe, subscribe } from 'wonka';

import { FrogSubscriptionHook, FrogSubscriptionOptions, FrogSubscriptionState } from './types';
import { Client } from '../common/urql/client';

interface SubscriptionObservable<T> {
    observable: Observable<OperationResult<T>>;
    unsubscribe: () => void;
}

interface SubscriptionObservableData<T> extends SubscriptionObservable<T> {
    subscribers: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const observables = new Map<string, SubscriptionObservableData<any>>();

// subscriptionObservable generates an RxJS observable from a graphql subscription.
const subscriptionObservable = <T, Variables extends object = {}>(
    query: DocumentNode,
    variables?: Variables,
    context?: Partial<OperationContext>
): SubscriptionObservable<T> => {
    const observable = new Subject<OperationResult<T>>();
    const { unsubscribe } = pipe(
        Client.getClient().subscription<T, Variables>(query, variables, context),
        subscribe((res) => observable.next(res))
    );
    return {
        observable,
        unsubscribe,
    };
};

// If observable entry of `key` has no subscribers, unsubscribe wonka pipe and delete entry.
const pruneObservableData = (key: string): void => {
    const observableData = observables.get(key);
    if (observableData === undefined) {
        return;
    }

    if (observableData.subscribers > 1) {
        observables.set(key, {
            ...observableData,
            subscribers: observableData.subscribers - 1,
        });
    } else {
        observableData.unsubscribe();
        observables.delete(key);
    }
};

// makeSubscription returns an observable broadcasting the result of a subscription.
// When many callers subscribe to the same subscription, the subscription will only be canceled
// after *all* subscribers have called `unsubscribe`.
export const makeSubscription = <T, Variables extends object = {}>(
    query: DocumentNode,
    options?: FrogSubscriptionOptions<T, Variables>
): SubscriptionObservable<T> => {
    const key = JSON.stringify({
        query,
        variables: options?.variables,
        context: options?.context,
    });
    let observableData: SubscriptionObservableData<T> | undefined = observables.get(key);
    if (observableData === undefined) {
        observableData = {
            ...subscriptionObservable(query, options?.variables, options?.context),
            subscribers: 0,
        };
    }
    observableData.subscribers += 1;
    observables.set(key, observableData);

    return {
        observable: observableData.observable.pipe(options?.operator ?? identity),
        unsubscribe: (): void => pruneObservableData(key),
    };
};

// useSubscription is a urql subscription hook.
export const useSubscription = <T, Variables extends object = {}>(
    query: DocumentNode,
    options?: FrogSubscriptionOptions<T, Variables>
): FrogSubscriptionState<T> => {
    const [result, setResult] = useState<FrogSubscriptionState<T>>({});

    useEffect(() => {
        const { observable, unsubscribe } = makeSubscription<T>(query, options);
        const subscription = observable.subscribe(setResult);
        return (): void => {
            subscription.unsubscribe();
            unsubscribe();
        };
    }, []);

    return result;
};

// makeUseSubscription is a helper for generating urql subscription hooks.
export const makeUseSubscription = <T, Variables extends object = {}>(
    subscription: DocumentNode
): FrogSubscriptionHook<T, Variables> => {
    return (options?: FrogSubscriptionOptions<T, Variables>): FrogSubscriptionState<T> =>
        useSubscription<T>(subscription, options);
};
