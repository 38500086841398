import React, { FC } from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { RowItem } from './styled';

import { Col } from '@/components/ui/controls';
import { ICommitmentFragment } from '@/graphql';

const Container = styled(Col)`
    div:first-child {
        margin-top: 1rem;
    }
`;

interface AbsencesProps {
    absences: Array<ICommitmentFragment>;
}

export const Absences: FC<AbsencesProps> = ({ absences }) => {
    const formatAbsenceDate = (date: Date): string => {
        return moment(date).format('MMMM DD');
    };

    return (
        <Container flex={12}>
            {absences.map((a, i) => {
                return (
                    <RowItem key={i}>
                        {`${formatAbsenceDate(a.from)} - ${formatAbsenceDate(a.to)}`}
                    </RowItem>
                );
            })}
        </Container>
    );
};
