import React from 'react';
import { MenuProps } from '@mui/material/Menu';
import { Mode } from '@/theme/Mode';

export const SelectMenu = React.forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
    return (
        <Mode variant="light">
            <div ref={ref} {...props} />
        </Mode>
    );
});
