import styled from 'styled-components';

import {
    adminDashboardTooltipShadow,
    borderAdminDashboardTooltip,
    borderCement,
    colorBackgroundSecondaryLightest,
    colorProjectPanelActionRequired,
    colorProjectPanelText,
    colorProjectPanelTooltipText,
    colorTypographySection,
} from '@/variables';

export const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1.75rem 0;
`;

export const ActivityDot = styled.div<{ markedUnseen: boolean }>`
    position: relative;
    margin: 0;
    padding: 0;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: ${colorProjectPanelActionRequired};

    ${(props): string =>
        !props.markedUnseen
            ? ''
            : `
    &:after {
        content: '';
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        bottom: -0.25rem;
        left: -0.25rem;
        border: 0.125rem solid ${colorProjectPanelActionRequired};
        border-radius: calc(9px + 0.5rem);
    }
`}
`;

export const ActivityEntry = styled.div<{ showDivider?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: ${(props): string => (props.showDivider ? borderCement : 'none')};
    padding: 1rem 1.75rem;
    z-index: 2; // This prevents safari rendering issue w/ fixed content
    min-height: 65px; // This prevents safari height rendering issue
    box-sizing: border-box;
`;

export const ActivityIndicatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 1.75rem;
`;

export const ActivityIndicatorIconContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 1.25rem;
    padding: 0.25rem;
    cursor: pointer;
`;

export const ActivityIndicatorTooltipPoint = styled.div`
    position: absolute;
    right: -0.25rem;
    height: 0.375rem;
    width: 0.375rem;
    transform: rotate(45deg);
    box-sizing: border-box;
    background-color: ${colorBackgroundSecondaryLightest};
    border-top: ${borderAdminDashboardTooltip};
    border-right: ${borderAdminDashboardTooltip};
    z-index: 2;
`;

export const ActivityIndicatorTooltipPointShadow = styled(ActivityIndicatorTooltipPoint)`
    box-shadow: ${adminDashboardTooltipShadow};
    z-index: -1;
`;

export const ActivityIndicatorTooltip = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 1.75rem;
    padding: 0 0.75rem;
    margin-right: 0.875rem;
    box-sizing: border-box;
    box-shadow: ${adminDashboardTooltipShadow};
    background-color: ${colorBackgroundSecondaryLightest};
    border: ${borderAdminDashboardTooltip};
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: ${colorProjectPanelTooltipText};
`;

export const ActivityInfoContainer = styled.div`
    display: flex;
    flex-direction: row;

    svg {
        fill: ${colorProjectPanelText};
        margin-top: 0.25rem;
    }
`;

export const ActivityTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
`;

export const ActivityMainText = styled.span`
    color: ${colorProjectPanelText};
    font-size: 0.875rem;
    font-weight: 600;
`;

export const ActivitySubText = styled.span`
    margin-top: 0.125rem;
    color: ${colorTypographySection};
    font-size: 11px;
    font-weight: 600;
    letter-specing: 0.06rem;
`;
