/*
 * planPages.ts provides GraphQL queries used to fetch records from the
 * `project_plan_file` table with nested `project_plan_page` records.
 */
import gql from 'graphql-tag';

export const PlanPagesQuery = gql`
    query PlansWithPlanPagesByProjectIdQuery($projectId: BigInt!) {
        projectPlanFiles(condition: { projectId: $projectId }) {
            nodes {
                id
                projectId
                projectPlanPagesByProjectPlanId {
                    nodes {
                        id
                        heightIn
                        heightPx
                        markups
                        pageId
                        name
                        widthIn
                        widthPx
                        removed
                        scale
                        isV2
                        orientation
                        originalOrientation
                        projectPlanId
                        projectPlan {
                            parentProjectPlanFileUuid
                            uuid
                            id
                        }
                        assets {
                            nodes {
                                extension
                                id
                                projectId
                                key
                                parentId
                                position
                                assetDimension {
                                    orientation
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
