import React, { FC } from 'react';

export interface PhoneNumberProps {
    num: string;
}

export const PhoneNumber: FC<PhoneNumberProps> = ({ num }) => (
    <a href={`tel:${num}`} target="_blank" rel="noopener noreferrer">
        {num}
    </a>
);
