import { newMutations } from '../frog';
import { projectFragment, ProjectRecord } from '../queries/projects';

export type FlatProjectRecord = Omit<ProjectRecord, 'team' | 'projectTrades' | 'projectUsers'>;

export type UpdateProjectArgs = {
    input: {
        id: number;
        patch: Partial<FlatProjectRecord>;
    };
};

export type UpdateProjectResult = {
    updateProject: {
        project: ProjectRecord;
    };
};

export const updateProjectMutation = projectFragment.gql`
    mutation UpdateProject(
        $input: UpdateProjectInput!
    ) {
        updateProject(input: $input) {
            project {
                ${projectFragment}
            }
        }
    }
`;
export const [executeUpdateProjectMutation, useUpdateProjectMutation] = newMutations<
    UpdateProjectArgs,
    UpdateProjectResult
>(updateProjectMutation);
