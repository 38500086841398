/*
 * ProjectPrice is the project price input within the price module.
 */
import React, { FC, useEffect, useRef, useState } from 'react';

import { SingleDetail, SingleDetailVariant } from './SingleDetail';
import {
    ProjectPriceContainer,
    ProjectPriceCurrency,
    ProjectPriceEdit,
    ProjectPriceInput,
    ProjectPriceRow,
} from './styled';

import { isWholeNumericInput } from '@/common/utils/helpers';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { InitialAction } from '@/components/AdminDashboard/types';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorProjectPanelHighlight } from '@/variables';

import { ReactComponent as Check } from '@/assets/icons/Check.svg';

const COPY = {
    currency: '$',
    edit: 'Edit',
    pricePlaceholder: ' Price',
    projectPrice: 'PROJECT PRICE',
};

const formatCents = (cents: number | undefined): string => {
    if (cents === undefined) {
        return '';
    }

    const decimalString = (cents / 100).toFixed(2);

    // If the number has no cents, chop 'em off.
    if (/[.]0{2}$/.test(decimalString)) {
        return decimalString.slice(0, -3);
    }
    return decimalString;
};

export const ProjectPrice: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { initialAction, modifiedPrice, setModifiedPrice, pricingAssignment } = useProjectPanel();

    const projectPriceInputRef = useRef<HTMLInputElement>(null);

    const priceUsdCents = pricingAssignment?.priceUsdCents;

    // The price is in edit mode if the user has already modified it or the project has no price.
    const [isEditable, setIsEditable] = useState(modifiedPrice !== undefined || !priceUsdCents);

    useEffect((): void => {
        if (projectPriceInputRef.current !== null && initialAction === InitialAction.FocusPrice) {
            setIsEditable(true);
            setTimeout(() => {
                projectPriceInputRef.current?.focus();
                projectPriceInputRef.current?.select();
            }, 0);
        }
    }, [projectPriceInputRef]);

    const getPriceFromContext = (): string =>
        formatCents(modifiedPrice !== undefined ? modifiedPrice : priceUsdCents);

    const [priceString, setPriceString] = useState<string>(getPriceFromContext());

    const handleClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setIsEditable(true);
        projectPriceInputRef.current?.focus();
        projectPriceInputRef.current?.select();
    };

    const handleSetProjectPrice = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newPriceString = event.target.value;
        if (!isWholeNumericInput(newPriceString)) {
            return;
        }
        setPriceString(newPriceString);
        if (newPriceString === '') {
            setModifiedPrice(undefined);
        }

        const newPrice = Number(newPriceString) * 100;
        setModifiedPrice(newPrice === priceUsdCents ? undefined : newPrice);
    };

    const handleUnfocusProjectPrice = (_event: React.FocusEvent<HTMLInputElement>): void => {
        setPriceString(getPriceFromContext());
        if (modifiedPrice !== undefined && modifiedPrice === priceUsdCents) {
            setModifiedPrice(undefined);
            setIsEditable(false);
        }
    };

    return (
        <SingleDetail
            label={COPY.projectPrice}
            onClick={handleClick}
            variant={SingleDetailVariant.Selectable}
        >
            <ProjectPriceRow>
                <ProjectPriceContainer>
                    <ProjectPriceCurrency isPlaceholder={priceString === ''}>
                        {COPY.currency}
                    </ProjectPriceCurrency>
                    <ProjectPriceInput
                        ref={projectPriceInputRef}
                        placeholder={COPY.pricePlaceholder}
                        value={priceString}
                        onChange={handleSetProjectPrice}
                        onBlur={handleUnfocusProjectPrice}
                    />
                </ProjectPriceContainer>
                {!isEditable && <ProjectPriceEdit>{COPY.edit}</ProjectPriceEdit>}
                {modifiedPrice !== undefined && (
                    <SvgIcon
                        src={Check}
                        height="1rem"
                        width="1rem"
                        style={{ fill: colorProjectPanelHighlight }}
                    />
                )}
            </ProjectPriceRow>
        </SingleDetail>
    );
};
