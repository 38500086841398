import { IMarkupType } from '@/graphql';

import { ReactComponent as CurveManualIcon } from '@/assets/icons/32/3D-curve--manual.svg';
import { ReactComponent as AreaIcon } from '@/assets/icons/32/area.svg';
import { ReactComponent as NominalIcon } from '@/assets/icons/32/nominal.svg';
import { ReactComponent as RulerIcon } from '@/assets/icons/32/ruler.svg';

interface Options {
    geometry?: string;
}

export const useTakeoffIcon = (options?: Options) => {
    let Icon;

    if (options?.geometry === IMarkupType.Linear) {
        Icon = CurveManualIcon;
    } else if (options?.geometry === IMarkupType.Area) {
        Icon = AreaIcon;
    } else if (options?.geometry === IMarkupType.Count) {
        Icon = NominalIcon;
    } else {
        Icon = RulerIcon;
    }

    return Icon;
};
