import React, { FC } from 'react';
import { IntersectionObserverProps, InView } from 'react-intersection-observer';

import CircularProgress from '@mui/material/CircularProgress';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface ListWithInfinitePaginationProps extends ListProps {
    hasNextPage?: boolean;
    inViewProps?: Omit<IntersectionObserverProps, 'children'>;
}

export const ListWithInfinitePagination: FC<ListWithInfinitePaginationProps> = ({
    children,
    hasNextPage,
    inViewProps,
    ...props
}) => {
    return (
        <List {...props}>
            {children}
            {hasNextPage && (
                <ListItem sx={{ justifyContent: 'center' }}>
                    <InView threshold={0.8} {...inViewProps}>
                        <CircularProgress size={20} />
                    </InView>
                </ListItem>
            )}
        </List>
    );
};
