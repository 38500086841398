import React, { FC } from 'react';

import { Mode } from '@/theme/Mode';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const COPY = {
    createPriceQuote: 'Create Price Quote',
    decline: 'Decline',
    accept: 'Accept',
    content:
        'By accepting and providing a quote for this project, you are agreeing to complete this project with 1build’s Online Portal tools unless specifically stated otherwise by a 1build Admin. All project’s groups and items must be built in the Estimate UI and must match any PDF/Excel version that’s completed.',
};

interface AcceptQuoteModalProps extends DialogProps {
    onDeclineButtonClick: () => void;
    onAcceptButtonClick: () => void;
}

export const AcceptQuoteModal: FC<AcceptQuoteModalProps> = ({
    onDeclineButtonClick,
    onAcceptButtonClick,
    ...props
}) => (
    <Mode variant="light">
        <Dialog fullWidth {...props}>
            <DialogTitle>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[21] }} variant="h3">
                    {COPY.createPriceQuote}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[48] }}>
                    {COPY.content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDeclineButtonClick} variant="outlined">
                    {COPY.decline}
                </Button>
                <Button onClick={onAcceptButtonClick} variant="contained">
                    {COPY.accept}
                </Button>
            </DialogActions>
        </Dialog>
    </Mode>
);
