import Box, { BoxProps } from '@mui/material/Box';
import AreaPanel from './AreaPanel/AreaPanel';
import CountMarkerPanel from './CountMarkerPanel/CountMarkerPanel';
import LinearPanel from './LinearPanel/LinearPanel';
import SelectionPanel from './SelectionPanel/SelectionPanel';
import './StylePanel.scss';
import { ToolType } from '@/common/types';
import { toolObservable } from '@/components/takeoff/observables/interface';
import React, { FC } from 'react';

const StylePanel: FC<BoxProps> = () => {
    const tool = toolObservable.value;

    return (
        <Box>
            {tool === ToolType.LINEAR && <LinearPanel />}
            {tool === ToolType.AREA && <AreaPanel />}
            {tool === ToolType.COUNT && <CountMarkerPanel />}
            {tool === ToolType.SELECTION && <SelectionPanel />}
        </Box>
    );
};

export default StylePanel;
