import { isNil } from 'lodash';

const uoms = [
    {
        id: 3,
        name: 'LF',
        description: 'Linear Feet',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D1',
        definition: '1 ft',
    },
    {
        id: 4,
        name: 'CLF',
        description: 'Hundreds of LF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D1',
        definition: '100 LF',
    },
    {
        id: 5,
        name: 'MLF',
        description: 'Thousands of LF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D1',
        definition: '1000 LF',
    },
    {
        id: 7,
        name: 'YD',
        description: 'Yard',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D1',
        definition: '1 yd',
    },
    {
        id: 9,
        name: 'EA',
        description: 'Each',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1',
    },
    {
        id: 10,
        name: 'CSF',
        description: 'Hundreds of SF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '100 SF',
    },
    {
        id: 11,
        name: 'MSF',
        description: 'Thousands of SF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '1000 SF',
    },
    {
        id: 12,
        name: 'SFCA',
        description: 'Square Feet Contact Area',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '1 SF',
    },
    {
        id: 13,
        name: 'SY',
        description: 'Square Yards',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '1 YD^2',
    },
    {
        id: 14,
        name: 'CSY',
        description: 'Hundreds of SY',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '100 SY',
    },
    {
        id: 15,
        name: 'BF',
        description: 'Board Feet',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 ft * ft * in',
    },
    {
        id: 16,
        name: 'CBF',
        description: 'Hundreds of BF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '100 BF',
    },
    {
        id: 17,
        name: 'MBF',
        description: 'Thousands of BF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1000 BF',
    },
    {
        id: 20,
        name: 'CCF',
        description: 'Hundreds of CF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '100 CF',
    },
    {
        id: 21,
        name: 'MCF',
        description: 'Thousands of CF',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1000 CF',
    },
    {
        id: 22,
        name: 'CY',
        description: 'Cubic Yards',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 YD^3',
    },
    {
        id: 24,
        name: 'BCY',
        description: 'Bank CY',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 CY',
    },
    {
        id: 25,
        name: 'LCY',
        description: 'Loose Cubic Yards',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 CY',
    },
    {
        id: 26,
        name: 'LBS',
        description: 'Pounds',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 27,
        name: 'TONS',
        description: 'Tons',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '2000 LBS',
    },
    {
        id: 28,
        name: 'PT',
        description: 'Pint',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 29,
        name: 'QT',
        description: 'Quart',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 30,
        name: 'GAL',
        description: 'Gallon',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 31,
        name: 'CT',
        description: 'Count',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 33,
        name: 'PCS',
        description: 'Pieces',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 34,
        name: 'LS',
        description: 'Lump Sum',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 35,
        name: 'BOX',
        description: 'Box',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 36,
        name: 'PAIL',
        description: 'Pail',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 37,
        name: 'ROLL',
        description: 'Roll',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 38,
        name: 'SA',
        description: 'Sack',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 39,
        name: 'HRS',
        description: 'Hours',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 40,
        name: 'MH',
        description: 'Man Hours',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 41,
        name: 'SQ',
        description: 'Square',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 42,
        name: 'ACRE',
        description: 'Acre',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '43560 SF',
    },
    {
        id: 43,
        name: 'WK',
        description: 'Week',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'TIME',
        definition: '168 HRS',
    },
    {
        id: 44,
        name: 'DY',
        description: 'Day',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'TIME',
        definition: '24 HRS',
    },
    {
        id: 45,
        name: 'MO',
        description: 'Month',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'TIME',
        definition: '5124 HRS',
    },
    {
        id: 46,
        name: 'OPNG',
        description: 'Opening',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'ITEM',
        definition: '1 EA',
    },
    {
        id: 50,
        name: 'IN',
        description: 'Inch',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D1',
        definition: '1 in',
    },
    {
        id: 51,
        name: 'SQIN',
        description: 'Square Inch',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '1 IN^2',
    },
    {
        id: 52,
        name: 'CUIN',
        description: 'Cubic Inch',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 IN^3',
    },
    {
        id: 8,
        name: 'SF',
        description: 'Square Feet',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D2',
        definition: '1 LF^2',
    },
    {
        id: 18,
        name: 'CF',
        description: 'Cubic Feet',
        created: '2022-04-19T05:09:13.590751Z',
        last_modified: '2022-04-19T05:09:13.590751Z',
        uom_type: 'D3',
        definition: '1 LF^3',
    },
];

const _m = new Map<string, number>();
uoms.forEach((uom) => _m.set(uom.name, uom.id));

export function getUomId(uom?: string | null): string {
    const id = _m.get(uom ?? 'EA');
    return isNil(id) ? '9' : String(id);
}
