import { GeometryType } from '@/common/types';
import { IMarkupType } from '@/graphql';

export const geometryTypeToMarkupName: Record<GeometryType, string> = {
    [GeometryType.AREA]: 'Area',
    [GeometryType.COUNT]: 'Count',
    [GeometryType.LINEAR]: 'Linear',
};

export const geometryTypeToMarkupType: Record<GeometryType, IMarkupType> = {
    [GeometryType.AREA]: IMarkupType.Area,
    [GeometryType.COUNT]: IMarkupType.Count,
    [GeometryType.LINEAR]: IMarkupType.Linear,
};

export const geometryTypeToUnitName: Record<GeometryType, string> = {
    [GeometryType.AREA]: 'sqft',
    [GeometryType.COUNT]: 'ea',
    [GeometryType.LINEAR]: 'ft',
};
