import { ProjectRecord } from '@/queries/projects';

// Return true if the selected project doesn't have price or price isn't FINAL
export const isPriceEstimating = (project: ProjectRecord): boolean => {
    if (!project) return false;

    const { isPriceFinal, pricingAssignment } = project;

    return !pricingAssignment || !isPriceFinal;
};
