import React, { FC, useMemo } from 'react';

import { Rectangle } from 'react-leaflet';

import { getBoundingBox } from '../../../common/utils/leaflet';
import { colorPrimaryLighter } from '../../../variables';
import { boundaryDataObservable, isAddingCoordinatesObservable } from '../observables/helpers';
import { selectedGeometriesObservable } from '../observables/interface';

import { useBoundaryGeometries } from '@/components/takeoff/hooks/useBoundaryGeometries';

export const BoundingBox: FC = () => {
    const boundaryGeometries = useBoundaryGeometries();
    const isAddingCoordinates = isAddingCoordinatesObservable.value;

    // We build a temporary FeatureGroup out of the geometries that are supposed
    // to show their boundaries to calculate the bounding box.
    const boundingBox = useMemo(() => {
        if (!isAddingCoordinates && boundaryDataObservable.value.length > 0) {
            if (boundaryGeometries.length > 0) {
                return getBoundingBox(boundaryGeometries);
            } else {
                // If there are no geometries, they were most likely undone, so
                // we deselect everything.
                selectedGeometriesObservable.next({ geometries: [], points: [] });
                boundaryDataObservable.next([]);
            }
        }
    }, [boundaryDataObservable.value, isAddingCoordinates]);
    return (
        <>
            {boundingBox && (
                <Rectangle
                    bounds={boundingBox}
                    fill={false}
                    interactive={false}
                    color={colorPrimaryLighter}
                />
            )}
        </>
    );
};
