import React, { FC, useRef } from 'react';

import { useContextMenu } from '../hooks/useContextMenu';
import { useContextMenuOptions } from '../hooks/useContextMenuOptions';
import { Menu } from '@/components/ui/inputs/Menu';
import { useSimpleMenu } from '@/components/ui/inputs/Menu/hooks/useSimpleMenu';

export const ContextMenu: FC = () => {
    const { data, close } = useContextMenu();
    const options = useContextMenuOptions();
    const menuRef = useRef<HTMLDivElement>(null);

    useSimpleMenu(close, menuRef);

    if (data === null) {
        return <></>;
    }

    return (
        <div style={{ fontFamily: 'proxima-nova, sans-serif' }} ref={menuRef}>
            <Menu options={options} position={{ x: data.position.x, y: data.position.y }} />
        </div>
    );
};
