import { ApolloClient, executeMutation } from '@/common/apollo/execute';
import {
    IMarkupGroupAddMarkupsMutation,
    IMarkupGroupAddMarkupsMutationVariables,
    IMarkupGroupCreateMutation,
    IMarkupGroupCreateMutationVariables,
    IMarkupGroupDeleteMutation,
    IMarkupGroupDeleteMutationVariables,
    IMarkupGroupRemoveMarkupsMutation,
    IMarkupGroupRemoveMarkupsMutationVariables,
    IMarkupGroupUpdateMutation,
    IMarkupGroupUpdateMutationVariables,
    IMarkupUpdateMutation,
    IMarkupUpdateMutationVariables,
    IProjectWithMarkupEntriesFragment,
    MarkupGroupAddMarkupsDocument,
    MarkupGroupCreateDocument,
    MarkupGroupDeleteDocument,
    MarkupGroupRemoveMarkupsDocument,
    MarkupGroupUpdateDocument,
    MarkupUpdateDocument,
} from '@/graphql';

interface AddMarkupsToMarkupGroupOptions {
    projectID: number | null;
    markupGroupID: string;
    markupIDs: Set<string>;
}

export const addMarkupsToMarkupGroup = (
    client: ApolloClient,
    options: AddMarkupsToMarkupGroupOptions
) => {
    return executeMutation<IMarkupGroupAddMarkupsMutation, IMarkupGroupAddMarkupsMutationVariables>(
        client,
        {
            mutation: MarkupGroupAddMarkupsDocument,
            variables: {
                input: {
                    markupIDs: [...options.markupIDs],
                    markupGroupID: options.markupGroupID,
                },
            },
            update: (cache, result) => {
                if (!options.projectID) {
                    return;
                }
                cache.modify({
                    id: client.cache.identify({
                        __typename: 'Project',
                        id: options.projectID.toString(10),
                    }),
                    fields: {
                        markupEntries: (
                            existingMarkupEntryRefs: IProjectWithMarkupEntriesFragment['markupEntries'],
                            { readField }
                        ) => {
                            // Keep all markup groups and all markups that were not just added to a group.
                            const newRefs = [];
                            for (const ref of existingMarkupEntryRefs) {
                                const typename = readField('__typename', ref);
                                const id = readField<string>('id', ref) ?? '';
                                if (typename === 'Markup' && !options.markupIDs.has(id)) {
                                    newRefs.push(ref);
                                    continue;
                                }
                                if (typename === 'MarkupGroup') {
                                    if (id === options.markupGroupID) {
                                        // we want to use the newly returned markup group which has
                                        // the correct markups on it, not the one from existingMarkupEntryRefs
                                        // which doesn't
                                        if (result?.data?.markupGroupAddMarkups) {
                                            newRefs.push(result.data.markupGroupAddMarkups[0]);
                                        }
                                    } else {
                                        newRefs.push(ref);
                                    }
                                }
                            }
                            return newRefs;
                        },
                    },
                });
            },
        }
    );
};

interface CreateNewMarkupGroupOptions {
    markupIDs?: Set<string>;
    projectID: number;
    name?: string;
}

export const createNewMarkupGroup = (
    client: ApolloClient,
    options: CreateNewMarkupGroupOptions
) => {
    return executeMutation<IMarkupGroupCreateMutation, IMarkupGroupCreateMutationVariables>(
        client,
        {
            mutation: MarkupGroupCreateDocument,
            variables: {
                input: {
                    markupIDs: options.markupIDs ? [...options.markupIDs] : undefined,
                    projectID: options.projectID.toString(10),
                    name: options.name,
                },
            },
            update: (cache, result) => {
                cache.modify({
                    id: client.cache.identify({
                        __typename: 'Project',
                        id: options.projectID.toString(10),
                    }),
                    fields: {
                        markupEntries: (
                            existingMarkupEntryRefs: IProjectWithMarkupEntriesFragment['markupEntries'],
                            { readField }
                        ) => {
                            const modifiedGroups = result.data?.markupGroupCreate;

                            if (!modifiedGroups) {
                                return existingMarkupEntryRefs;
                            }

                            const markupStopIndex = existingMarkupEntryRefs.findIndex(
                                (ref) => readField('__typename', ref) !== 'Markup'
                            );

                            const markupGroupStartIndex =
                                markupStopIndex === -1
                                    ? existingMarkupEntryRefs.length
                                    : markupStopIndex;

                            const markups = existingMarkupEntryRefs
                                .slice(0, markupGroupStartIndex)
                                .filter((ref) => {
                                    if (!options.markupIDs?.size) {
                                        return true;
                                    }
                                    const id = readField<string>('id', ref);
                                    if (id) {
                                        return !options.markupIDs.has(id);
                                    }
                                });

                            const groups = existingMarkupEntryRefs.slice(markupGroupStartIndex);

                            const newGroup = modifiedGroups.find(
                                (modifiedGroup) =>
                                    groups.findIndex((group) => group.id === modifiedGroup.id) ===
                                    -1
                            );

                            if (!newGroup) {
                                return existingMarkupEntryRefs;
                            }

                            return [...markups, newGroup, ...groups];
                        },
                    },
                });
            },
        }
    );
};

interface DeleteMarkupGroupOptions {
    markupGroupID: string;
    projectID: number;
}

export const deleteMarkupGroup = (client: ApolloClient, options: DeleteMarkupGroupOptions) => {
    return executeMutation<IMarkupGroupDeleteMutation, IMarkupGroupDeleteMutationVariables>(
        client,
        {
            mutation: MarkupGroupDeleteDocument,
            variables: {
                input: {
                    id: options.markupGroupID,
                },
            },
            // The default caching interferes with custom caching in `useMarkupCache.tx`.
            fetchPolicy: 'no-cache',
        }
    );
};

interface RemoveMarkupsFromGroupOptions {
    projectID: number | null;
    markupGroupID: string | null;
    markupIDs: string[];
}

export const removeMarkupsFromGroup = (
    client: ApolloClient,
    options: RemoveMarkupsFromGroupOptions
) => {
    return executeMutation<
        IMarkupGroupRemoveMarkupsMutation,
        IMarkupGroupRemoveMarkupsMutationVariables
    >(client, {
        mutation: MarkupGroupRemoveMarkupsDocument,
        variables: {
            input: {
                markupIDs: options?.markupIDs,
            },
        },
        // The default caching interferes with custom caching in `useMarkupCache.tx`.
        fetchPolicy: 'no-cache',
    });
};

interface UpdateMarkupOptions {
    markupID: string;
    fields: {
        name?: string;
    };
    projectID: number | null;
}

export const updateMarkup = (client: ApolloClient, options: UpdateMarkupOptions) => {
    return executeMutation<IMarkupUpdateMutation, IMarkupUpdateMutationVariables>(client, {
        mutation: MarkupUpdateDocument,
        variables: {
            input: {
                id: options.markupID,
                fields: {
                    name: options.fields.name || '',
                },
            },
        },
        update: (cache, result) => {
            const modifiedMarkup = result.data?.markupUpdate;

            const cacheId = cache.identify({
                __typename: 'Markup',
                id: modifiedMarkup?.id,
            });

            cache.modify({
                id: cacheId,
                fields: {
                    name: () => modifiedMarkup?.name,
                },
            });
        },
    });
};

interface UpdateMarkupGroupOptions {
    markupGroupID: string;
    fields: {
        name?: string;
    };
    projectID: number | null;
}

export const updateMarkupGroup = (client: ApolloClient, options: UpdateMarkupGroupOptions) => {
    return executeMutation<IMarkupGroupUpdateMutation, IMarkupGroupUpdateMutationVariables>(
        client,
        {
            mutation: MarkupGroupUpdateDocument,
            variables: {
                input: {
                    id: options.markupGroupID,
                    fields: {
                        name: options.fields.name || '',
                    },
                },
            },
            update: (cache, result) => {
                const cacheId = client.cache.identify({
                    __typename: 'Project',
                    id: options.projectID?.toString(10),
                });

                cache.modify({
                    id: cacheId,
                    fields: {
                        markupEntries: (
                            existingMarkupEntryRefs: IProjectWithMarkupEntriesFragment['markupEntries'],
                            { readField }
                        ) => {
                            const modifiedMarkupGroup = result.data?.markupGroupUpdate;

                            if (!modifiedMarkupGroup) {
                                return existingMarkupEntryRefs;
                            }

                            const replaceIndex = existingMarkupEntryRefs.findIndex(
                                (ref) => readField('id', ref) === modifiedMarkupGroup.id
                            );

                            return [
                                ...existingMarkupEntryRefs.slice(0, replaceIndex),
                                modifiedMarkupGroup,
                                ...existingMarkupEntryRefs.slice(
                                    replaceIndex + 1,
                                    existingMarkupEntryRefs.length
                                ),
                            ];
                        },
                    },
                });
            },
        }
    );
};
