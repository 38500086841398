import { useEffect } from 'react';
import { Env } from '@/common/env';

// This hook changes the state of Intercom
// only for the lifecycle of the component where this hook is used
export const useChangeIntercomState = ({ enabled = false }: { enabled: boolean }) => {
    const hideIntercomLauncher = (hidden: boolean) => {
        Env.tier.isProduction &&
            window.Intercom &&
            window.Intercom('update', {
                hide_default_launcher: hidden,
            });
    };

    useEffect(() => {
        hideIntercomLauncher(!enabled);

        return () => {
            hideIntercomLauncher(enabled);
        };
    }, [window.Intercom]);

    return {
        hideIntercomLauncher,
    };
};
