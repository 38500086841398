import React, { FC, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import to from 'await-to-js';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { FileUploadInput } from '@/components/FileUploadInput';
import { useStorage } from '@/contexts/Storage';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as FileIcon } from '@/assets/icons/File.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/icons/TrashCan.svg';

import {
    IFileDirectory,
    ITeamProjectUploadInput,
    useTeamProjectInfoFilesRemoveMutation,
} from '@/graphql';
import { BuilderDetailsFormFields } from '@/common/types';

const COPY = {
    errorUploadLimit: 'You can only upload 5 files max.',
    errorFileType: 'Can only upload CSV, PDF or XLSX files.',
    errorRemoveMutation: 'Failed to remove file.',
};

const acceptedFileTypes = [
    'application/pdf',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

interface DetailsFileUploadProps {
    text: string;
    directory: IFileDirectory;
    disableDownload: boolean;
    viewOnly: boolean;
    isSubmitted: boolean;
    setValue: UseFormSetValue<BuilderDetailsFormFields>;
    getValues: UseFormGetValues<BuilderDetailsFormFields>;
    files: ITeamProjectUploadInput[];
    teamID?: string;
}

export const DetailsFileUpload: FC<DetailsFileUploadProps> = ({
    text,
    directory,
    disableDownload,
    viewOnly,
    isSubmitted,
    setValue,
    getValues,
    files,
    teamID,
}) => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<ITeamProjectUploadInput[]>(files);
    const uploadedFilesRef = useRef<ITeamProjectUploadInput[]>();
    const filesRef = useRef<ITeamProjectUploadInput[]>();

    const { enqueueSnackbar } = useSnackbar();
    const { builderDetailsUpload, getBuilderDetailsFileUrl, removeBuilderDetailsFile } =
        useStorage();

    const [teamProjectInfoFilesRemoveMutation] = useTeamProjectInfoFilesRemoveMutation();

    const uploadFile = async (file: File): Promise<void> => {
        const newUuid = uuid();

        const [error] = await to(builderDetailsUpload(directory, newUuid, file.name, file, teamID));
        setUploading(false);
        if (error) return;

        setValue('files', [
            ...getValues('files'),
            { filename: file.name, uuid: newUuid, directory: directory },
        ]);
        setUploadedFiles((uploadedFiles) => [
            ...uploadedFiles,
            { filename: file.name, uuid: newUuid, directory: directory },
        ]);
    };

    const handleFileInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void[]> => {
        const newFiles = event.target.files;
        if (!newFiles || newFiles.length === 0) return [];
        setUploading(true);

        // if the total ammount of uploaded files would be greater than 5, cancel the upload
        if (newFiles.length > 5 - uploadedFiles.length) {
            enqueueSnackbar(COPY.errorUploadLimit, {
                variant: 'error',
                autoHideDuration: 5000,
            });
            setUploading(false);
            return [];
        }

        const allPromises: Promise<void>[] = [];
        for (let i = 0; i < newFiles.length; i++) {
            if (!acceptedFileTypes.includes(newFiles[i].type)) {
                enqueueSnackbar(COPY.errorFileType, {
                    variant: 'error',
                    autoHideDuration: 5000,
                });

                setUploading(false);
                return [];
            }
            allPromises.push(uploadFile(newFiles[i]));
        }

        event.target.value = '';
        return Promise.all(allPromises);
    };

    const handleFileDownload = async (filename: string, fileuuid: string): Promise<void> => {
        if (disableDownload) return;
        const [error, url] = await to(
            getBuilderDetailsFileUrl(filename, fileuuid, directory, teamID)
        );
        if (!error) {
            const link = document.createElement('a');
            link.href = url as string;
            link.click();
        }
    };

    const handleRemoveFile = async (filename: string, fileuuid: string): Promise<void> => {
        if (viewOnly) return;
        await to(removeBuilderDetailsFile(teamID || '', directory, fileuuid, filename));

        const [mutationError] = await to(
            teamProjectInfoFilesRemoveMutation({ variables: { input: { uuids: [fileuuid] } } })
        );
        if (mutationError) {
            enqueueSnackbar(COPY.errorRemoveMutation, {
                variant: 'error',
                autoHideDuration: 5000,
            });

            return;
        }

        setValue(
            'files',
            getValues('files').filter((file) => file.uuid !== fileuuid)
        );
        setUploadedFiles((uploadedFiles) => uploadedFiles.filter((file) => file.uuid !== fileuuid));
    };

    useEffect(() => {
        if (!files || uploading || isSubmitted) return;
        setUploadedFiles(files);
        filesRef.current = files;
    }, [files]);

    useEffect(() => {
        uploadedFilesRef.current = uploadedFiles;
    }, [uploadedFiles]);

    // delete uploaded files when a user leaves the page without submitting
    useEffect(() => {
        return () => {
            if (!isSubmitted) {
                uploadedFilesRef.current?.map(async (file) => {
                    if (
                        !filesRef.current?.some((f) => f.uuid === file.uuid) ||
                        filesRef.current?.length === 0
                    ) {
                        await removeBuilderDetailsFile(
                            teamID || '',
                            directory,
                            file.uuid,
                            file.filename
                        );
                    }
                });
            }
        };
    }, []);

    return (
        <>
            <FileUploadInput
                handleFileInputChange={handleFileInputChange}
                directory={directory}
                text={text}
                disabled={uploading || viewOnly}
                accept=".xlsx, .pdf, .csv"
                variant="subtitle1"
            />
            {uploadedFiles.map((item, idx) => (
                <Stack direction="row" alignItems="center" key={idx} justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                        <FileIcon />
                        <Typography
                            color="#BEBEBE"
                            ml={1}
                            onClick={() => handleFileDownload(item.filename, item.uuid)}
                            sx={{ cursor: disableDownload ? 'inherit' : 'pointer' }}
                        >
                            {item.filename}
                        </Typography>
                    </Stack>
                    {!viewOnly && (
                        <IconButton onClick={() => handleRemoveFile(item.filename, item.uuid)}>
                            <TrashCanIcon width={15} height={15} color="#BEBEBE" />
                        </IconButton>
                    )}
                </Stack>
            ))}
        </>
    );
};
