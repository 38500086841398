import { createContext, useContext } from 'react';

import { EstimationView } from '../types';

export interface EstimationLayoutContext {
    assemblyPanelOpen: boolean;
    openAssemblyPanel: () => void;
    planPageID?: string;
    toggleAssemblyPanelIsOpen: () => void;
    isPrintView: boolean;
    setPlanPageID: (id?: string) => void;
    view: EstimationView;
}

export const EstimationLayoutContext = createContext<EstimationLayoutContext>({
    assemblyPanelOpen: true,
    openAssemblyPanel: () => undefined,
    planPageID: undefined,
    toggleAssemblyPanelIsOpen: () => undefined,
    isPrintView: false,
    setPlanPageID: () => undefined,
    view: EstimationView.Estimate,
});

export const useEstimationLayout = (): EstimationLayoutContext => {
    return useContext(EstimationLayoutContext);
};
