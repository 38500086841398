/*
 * This context exposes feature flags, that are being used to toggle features
 */
import { makeContext } from '../common/utils/makeContext';
import { useState } from 'react';

export interface LoadingPayload {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

/**
 * Loading context, which keeps track of the active number of <LoadingPage>
 * components that are mounted within the app.
 * This count controls mounting <GlobalLoader /> within App.tsx
 * @see {LoadingPage}
 * @see {GlobalLoader}
 */
const { useConsumer, Provider } = makeContext<LoadingPayload>(() => {
    const [activeLoadersCount, setActiveLoadersCount] = useState(0);

    const setIsLoading = (isLoading: boolean): void => {
        setActiveLoadersCount((activeLoadersCount) => activeLoadersCount + (isLoading ? 1 : -1));
    };

    return {
        isLoading: activeLoadersCount > 0,
        setIsLoading,
    };
});

export const useLoading = useConsumer;
export const LoadingProvider = Provider;
