import React, { FC } from 'react';

import FocusTrap from 'focus-trap-react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import {
    Body,
    Container,
    Header,
    LeftContent,
    Overlay,
    RightContent,
    SubmitButton,
    SubmitContainer,
} from './styled';

import { setTeamOnboardingWelcomed } from '@/mutations/setTeamOnboardingWelcomed';

import { ReactComponent as Arrow } from '@/assets/icons/arrow.svg';
import { ReactComponent as Cross } from '@/assets/icons/cross.svg';

const COPY = {
    header: 'Welcome to the 1build dashboard',
    body:
        'This is where you can access your in progress and completed projects. To get going with your first ' +
        'project, tap the button below.',
    submit: 'Start my first project',
};

type WelcomeModalProps = {
    close: () => void;
    teamId: number;
};

export const WelcomeModal: FC<WelcomeModalProps> = ({ close, teamId }) => {
    const history = useHistory();

    const [, setTeamWelcomed] = useMutation(setTeamOnboardingWelcomed);

    const closeWrapped = (): void => {
        setTeamWelcomed({
            welcomed: true,
            teamId,
        }).then((_) => {
            close();
            localStorage.setItem(`teamWelcomed--${teamId}`, 'true');
        });
    };

    const submit = (): void => {
        setTeamWelcomed({
            welcomed: true,
            teamId,
        }).then(() => {
            localStorage.setItem(`teamWelcomed--${teamId}`, 'true');
            history.push('/projects/new');
        });
    };

    return (
        <FocusTrap>
            <Overlay
                // Exit on click out
                onContextMenu={(e): void => {
                    if (!e.defaultPrevented) {
                        closeWrapped();
                    }
                }}
                onClick={(e): void => {
                    if (!e.defaultPrevented) {
                        e.preventDefault();
                        closeWrapped();
                    }
                }}
            >
                <Container
                    // Prevent context menu from selecting all text and close from firing
                    onClick={(e): void => e.preventDefault()}
                    onContextMenu={(e): void => e.preventDefault()}
                >
                    <LeftContent />
                    <RightContent>
                        <Cross className="cross" onClick={closeWrapped} />
                        <Header>{COPY.header}</Header>
                        <Body>{COPY.body}</Body>

                        <SubmitContainer>
                            <SubmitButton
                                tabIndex={0}
                                onClick={submit}
                                onKeyDown={(e): void => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        submit();
                                    }
                                }}
                            >
                                {COPY.submit}
                                <Arrow />
                            </SubmitButton>
                        </SubmitContainer>
                    </RightContent>
                </Container>
            </Overlay>
        </FocusTrap>
    );
};
