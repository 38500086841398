/*
 * useChat provides chat messages.
 */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CombinedError, useMutation, UseQueryState } from 'urql';

import { useWindowListener } from './useWindowListener';

import { scheduleCall } from '@/common/sales';
import { ProjectUploadFileNew } from '@/common/types';
import { notUndefined, updateRecordArrMulti } from '@/common/utils/helpers';
import { isRobotMessage, prepareRoboticMessages } from '@/common/utils/robotUser';
import { compareRecordCreation } from '@/common/utils/sort';
import { useFeatures } from '@/contexts/Features';
import { useUser } from '@/contexts/User';
import { IUserRole } from '@/graphql';
import {
    CreateEventResult,
    CreateMessageWithUploadsResult,
    EditMessage,
    editMessageMutation,
    newEventMutation,
    newEventWithUploadsMutation,
} from '@/mutations/newEvent';
import { UpdateEventResult, removeEventMutation } from '@/mutations/removeEvent';
import { BaseUserRecord } from '@/queries/baseUsers';
import { AnyEventTypeName, EventTypeName } from '@/queries/eventTypes';
import {
    EventFullRecord,
    executeEventsFullQuery,
    FullEventsByEventTypeRecords,
    useEventsFullQuery,
    useFullEventsByEventTypeQuery,
} from '@/queries/events';
import { ProjectRecord } from '@/queries/projects';
import {
    useEventChangedFullSubscription,
    useEventCreatedFullSubscription,
} from '@/subscriptions/events';

interface MessageQueueItem {
    message: EventFullRecord;
    timeoutID: number;
}
type MessageQueue = MessageQueueItem[];

// These messages do not appear in the chat.
const hiddenMessageEventTypeNames: AnyEventTypeName[] = [
    EventTypeName.EditMessage,
    EventTypeName.RemoveMessage,
];

// the number of milliseconds to wait before a subscription event will
// be handled if its pair (edited/new) does not arrive
const queueTimeout = 500;

// datesAreSame returns true if the creation dates of a queue item and an
// event are within 1 hundreth of a second.
const datesAreSame = (a: MessageQueueItem, b: EventFullRecord): boolean => {
    const aCreated = new Date(a.message.created ?? '').getTime();
    const bCreated = new Date(b.created ?? '').getTime();
    return Math.abs(aCreated - bCreated) < 10;
};

export interface UseChatRes {
    error: CombinedError | null;
    fetching: boolean;
    messages: EventFullRecord[];
    addMessage: (
        ownerId: string,
        projectId: string,
        message: string,
        uploads: ProjectUploadFileNew[]
    ) => Promise<void>;
    removeMessage: (id: number) => Promise<void>;
    updateMessage: (message: EventFullRecord) => Promise<void>;
    creditsRefundedEventsResult: UseQueryState<FullEventsByEventTypeRecords, unknown>;
}

export const useChat = (project: ProjectRecord): UseChatRes => {
    const projectID = project.id;
    const ownerID = project.team?.leadUserId;
    // Hooks
    //---------------------------------------------------------------------
    const {
        features: { builderEstimateView },
    } = useFeatures();
    // State
    //---------------------------------------------------------------------
    const {
        data: { user },
    } = useUser();

    // roles in user.roles can appear in lowercase, so additional mapping is needed
    const isUserRoles = (user: BaseUserRecord, roles: IUserRole): boolean =>
        !!user.roles?.map((role: IUserRole) => role.toUpperCase()).includes(roles);

    const estimator: BaseUserRecord | undefined = project.projectUsers?.nodes.find(
        (node: { user: BaseUserRecord }) =>
            isUserRoles(node.user, IUserRole.Estimator) &&
            !isUserRoles(node.user, IUserRole.Admin) &&
            !isUserRoles(node.user, IUserRole.Builder)
    )?.user;

    const [error, setError] = useState<CombinedError | null>(null);
    const [fetchingMessages, setFetchingMessages] = useState(true);
    const [fetchingOther, setFetchingOther] = useState(true);
    const [userMessages, setUserMessages] = useState<EventFullRecord[]>([]);
    const [robotMessages, setRobotMessages] = useState<EventFullRecord[]>([]);
    const [modifiedMessages, setModifiedMessages] = useState<MessageQueue>([]);
    const [newMessages, setNewMessages] = useState<MessageQueue>([]);

    // Queries
    //---------------------------------------------------------------------
    const [messageEventsResult] = useEventsFullQuery(
        { projectId: project.id },
        { requestPolicy: 'cache-and-network' }
    );

    const [projectCreationEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID) },
            eventTypeCondition: { name: EventTypeName.CreateProject },
        },
        { requestPolicy: 'cache-and-network' }
    );

    const [projectEditEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID) },
            eventTypeCondition: { name: EventTypeName.EditProject },
        },
        { requestPolicy: 'cache-and-network' }
    );

    const [estimateDownloadEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID), ownerId: ownerID },
            eventTypeCondition: { name: EventTypeName.DownloadEstimate },
        },
        { requestPolicy: 'cache-and-network' }
    );

    const [approveEstimateEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID) },
            eventTypeCondition: { name: EventTypeName.ApproveEstimate },
        },
        { requestPolicy: 'cache-and-network' }
    );

    const [creditsRefundedEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID) },
            eventTypeCondition: { name: EventTypeName.CreditsRefunded },
        },
        { requestPolicy: 'cache-and-network' }
    );

    const [cancelProjectEventsResult] = useFullEventsByEventTypeQuery(
        {
            eventCondition: { projectId: Number(projectID) },
            eventTypeCondition: { name: EventTypeName.CancelProject },
        },
        { requestPolicy: 'cache-and-network' }
    );

    // Mutations
    //---------------------------------------------------------------------
    const [, createEvent] = useMutation<CreateEventResult>(newEventMutation);
    const [, createMessageWithUploads] = useMutation<CreateMessageWithUploadsResult>(
        newEventWithUploadsMutation
    );
    const [, editMessage] = useMutation<EditMessage>(editMessageMutation);
    const [, removeEvent] = useMutation<UpdateEventResult>(removeEventMutation);

    // Subscriptions
    //---------------------------------------------------------------------
    const eventCreatedSubscriptionResult = useEventCreatedFullSubscription();
    const eventChangedSubscriptionResult = useEventChangedFullSubscription();

    // Memoized Values
    //---------------------------------------------------------------------
    const fetching = useMemo(
        () => fetchingMessages || fetchingOther,
        [fetchingMessages, fetchingOther]
    );
    const messages = useMemo(
        () => [...robotMessages, ...userMessages].sort(compareRecordCreation),
        [robotMessages, userMessages]
    );

    // Event listeners
    //---------------------------------------------------------------------

    useWindowListener(
        'hashchange',
        () => {
            const clearHash = (): string => (location.hash = '');

            if (location.hash === '#schedule-chilipiper') {
                scheduleCall().finally(() => clearHash());
            }
        },
        [location.hash, user]
    );

    // Functions
    //---------------------------------------------------------------------
    const addMessage = async (
        ownerId: string,
        projectId: string,
        message: string,
        newUploads: ProjectUploadFileNew[]
    ): Promise<void> => {
        if (newUploads.length === 0) {
            await createEvent({
                eventTypeName: EventTypeName.PostMessage,
                message,
                ownerId,
                projectId,
            });
            return;
        }
        await createMessageWithUploads({
            eventTypeName: EventTypeName.PostMessage,
            message,
            ownerId,
            projectId,
            projectUploadFiles: newUploads.map((u) => ({
                filename: u.filename,
                uuid: u.uuid,
            })),
        });
    };

    const addRobotMessage = useCallback(
        (event: EventFullRecord, isSaaS: boolean): void => {
            const newMessages = prepareRoboticMessages(
                event,
                isSaaS,
                estimator,
                project.uuid,
                ownerID,
                builderEstimateView(project.created)
            );
            setRobotMessages((oldRobotMessages) =>
                updateRecordArrMulti(oldRobotMessages, newMessages)
            );
        },
        [builderEstimateView, estimator, ownerID, project.created, project.uuid]
    );

    const removeMessage = async (id: number): Promise<void> => {
        await removeEvent({
            eventId: Number(id),
            newEventTypeName: 'remove_message',
        });
    };

    const updateMessage = async (message: EventFullRecord): Promise<void> => {
        await editMessage({
            eventId: message.id,
            message: message.message,
        });
    };

    // Remove a message from the modified messages queue and stop the default handler.
    const dequeueModifiedMessage = (queueItem: MessageQueueItem): void => {
        window.clearTimeout(queueItem.timeoutID);
        setModifiedMessages((oldModifiedMessages) =>
            oldModifiedMessages.filter((m) => m.message.id !== queueItem.message.id)
        );
    };

    // Remove a message from the new messages queue and stop the default handler.
    const dequeueNewMessage = (queueItem: MessageQueueItem): void => {
        window.clearTimeout(queueItem.timeoutID);
        setNewMessages((oldNewMessages) =>
            oldNewMessages.filter((m) => m.message.id !== queueItem.message.id)
        );
    };

    const enqueueModifiedMessage = useCallback(
        (message: EventFullRecord): void => {
            // If the modified message is already queued, do nothing.
            if (modifiedMessages.findIndex((m) => m.message.id === message.id) !== -1) {
                return;
            }
            // Set timeout to remove the message as well as its queue record.
            const timeoutID = window.setTimeout(() => {
                // remove modified message from queue
                setModifiedMessages((mm) => mm.filter((m) => m.message.id !== message.id));
                // remove modified message from messages
                setUserMessages((om) => om.filter((m) => m.id !== message.id));
            }, queueTimeout);
            // Add the modified message to the queue.
            setModifiedMessages((m) => [...m, { message, timeoutID }]);
        },
        [modifiedMessages]
    );

    const enqueueNewMessage = useCallback(
        (message: EventFullRecord): void => {
            // If the new message is already queued, do nothing.
            if (newMessages.findIndex((m) => m.message.id === message.id) !== -1) {
                return;
            }
            // Set timeout to add the message and remove its queue record.
            const timeoutID = window.setTimeout(() => {
                // remove new message from queue
                setNewMessages((nm) => nm.filter((m) => m.message.id !== message.id));
                // add new message to messages (if it's not already included)
                setUserMessages((om) => {
                    if (om.findIndex((m) => m.id === message.id) !== -1) {
                        return om;
                    }
                    return [...om, message];
                });
            }, queueTimeout);
            // Add the new message to the queue.
            setNewMessages((m) => [...m, { message, timeoutID }]);
        },
        [newMessages]
    );

    const handleSetUserMessages = (nodes: EventFullRecord[] | undefined) => {
        if (!nodes || nodes.length === 0) return;

        setUserMessages(
            nodes
                .filter((node) =>
                    [EventTypeName.PostMessage.toString()].includes(node.eventTypeName)
                )
                .reduce<EventFullRecord[]>((acc, e) => [...acc, e], [])
        );
    };

    // Effects
    //---------------------------------------------------------------------

    // Event fetching
    useEffect(() => {
        if (
            messageEventsResult.error ||
            messageEventsResult.fetching ||
            messageEventsResult.data === null ||
            messageEventsResult.data === undefined
        ) {
            return;
        }

        handleSetUserMessages(messageEventsResult.data.events.nodes);
    }, [messageEventsResult]);

    // Event refetching
    const refetchEvents = useCallback(async () => {
        setFetchingMessages(true);
        const result = await executeEventsFullQuery(
            {
                projectId: project.id,
            },
            {
                requestPolicy: 'cache-and-network',
            }
        );

        if (result.error) {
            setError(result.error);
            return;
        }

        handleSetUserMessages(result.data?.events.nodes);
        setFetchingMessages(false);
    }, [project.id]);

    // Non-message Event fetching
    useEffect(() => {
        const allResults = [
            projectCreationEventsResult,
            projectEditEventsResult,
            estimateDownloadEventsResult,
            approveEstimateEventsResult,
            cancelProjectEventsResult,
        ];
        if (allResults.map((r) => r.fetching).some((f) => f)) {
            setFetchingOther(true);
            return;
        }
        setFetchingOther(false);
        if (allResults.map((r) => r.error).some((e) => e)) {
            setError(allResults.find((r) => r.error)?.error || null);
            return;
        }
        setError(null);

        const eventTypeNodes = allResults
            .map((r) => r.data?.eventTypes.nodes)
            .filter(notUndefined)
            .reduce(
                (acc: { eventsByEventTypeName: { nodes: EventFullRecord[] } }[], val) => [
                    ...acc,
                    ...val,
                ],
                []
            );
        if (!eventTypeNodes) {
            return;
        }
        setRobotMessages(
            eventTypeNodes.reduce<EventFullRecord[]>(
                (acc, node) => [
                    ...acc,
                    ...node.eventsByEventTypeName.nodes.flatMap((e) =>
                        prepareRoboticMessages(
                            e,
                            project.isSaas,
                            estimator,
                            project.uuid,
                            ownerID,
                            builderEstimateView(project.created)
                        )
                    ),
                ],
                []
            )
        );
    }, [
        projectCreationEventsResult,
        projectEditEventsResult,
        estimateDownloadEventsResult,
        approveEstimateEventsResult,
        cancelProjectEventsResult,
        builderEstimateView,
        estimator,
        ownerID,
        project.created,
        project.isSaas,
        project.uuid,
    ]);

    // New Events
    useEffect(() => {
        const event = eventCreatedSubscriptionResult.data?.EventCreated.eventLog;

        if (!event || event.projectId !== projectID) return;

        if (isRobotMessage(event)) {
            addRobotMessage(event, project.isSaas);
            return;
        }

        if (
            event.eventTypeName !== EventTypeName.PostMessage ||
            messages.findIndex((m) => m.id === event.id) !== -1
        )
            return;

        refetchEvents();

        // Find the message that this new one modified.
        const parent = modifiedMessages.find(
            (m) => m.message.ownerId === event.ownerId && datesAreSame(m, event)
        );
        // If this new message has no counterpart in the modified queue, add it to
        // the new messages queue.
        if (!parent) {
            return enqueueNewMessage(event);
        }
        dequeueModifiedMessage(parent);
        setUserMessages((oldMessages) => {
            const insertionIdx = oldMessages.findIndex((m) => m.id === parent.message.id);
            // If the index of the parent message isn't found, append the new
            // message to the existing ones.
            if (insertionIdx === -1) {
                return [...oldMessages, event];
            }
            // Insert the new message into the existing array at its parent's index.
            return [
                ...oldMessages.slice(0, insertionIdx),
                { ...event },
                ...oldMessages.slice(insertionIdx + 1),
            ];
        });
    }, [
        eventCreatedSubscriptionResult,
        eventChangedSubscriptionResult,
        projectEditEventsResult,
        approveEstimateEventsResult,
        cancelProjectEventsResult,
        project.isSaas,
        addRobotMessage,
        enqueueNewMessage,
        messages,
        modifiedMessages,
        projectID,
        refetchEvents,
    ]);

    // Modified Events
    useEffect(() => {
        const event = eventChangedSubscriptionResult.data?.EventChanged.eventLog;
        if (
            !event ||
            event.projectId !== projectID ||
            !hiddenMessageEventTypeNames.includes(event.eventTypeName)
        )
            return;
        // If this event was a removal, remove the message.
        if (event.eventTypeName === EventTypeName.RemoveMessage) {
            return setUserMessages((oldMessages) => oldMessages.filter((m) => m.id !== event.id));
        }
        // Now we know that this even was a modification.
        // Find the new message that modified this one.
        const child = newMessages.find(
            (m) => m.message.ownerId === event.ownerId && datesAreSame(m, event)
        );
        // If this message modification has no counterpart in the new message queue,
        // add it to the modified messages queue.
        if (!child) {
            return enqueueModifiedMessage(event);
        }
        dequeueNewMessage(child);
        setUserMessages((oldMessages) => {
            const insertionIdx = oldMessages.findIndex((m) => m.id === event.id);
            // If the index of the modified message isn't found, append the new
            // message to the existing ones.
            if (insertionIdx === -1) {
                return [...oldMessages, child.message];
            }
            // Insert the new message into the existing array at the modified
            // message's index.
            return [
                ...oldMessages.slice(0, insertionIdx),
                { ...child.message },
                ...oldMessages.slice(insertionIdx + 1),
            ];
        });
    }, [eventChangedSubscriptionResult, enqueueModifiedMessage, newMessages, projectID]);

    // clear all timeouts before unmount
    useEffect(
        () => (): void =>
            [...modifiedMessages, ...newMessages].forEach((m) => window.clearTimeout(m.timeoutID)),
        [modifiedMessages, newMessages]
    );

    return {
        error,
        fetching,
        messages,
        addMessage,
        removeMessage,
        updateMessage,
        creditsRefundedEventsResult,
    };
};
