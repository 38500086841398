import React, { FC } from 'react';

import { EllipsisWrapper } from './styled';
import { ReactComponent as EllipsisIcon } from '@/assets/icons/16/overflow-menu--horizontal.svg';

interface EllipsisButtonProps {
    handleMenuOpen: ({ x, y }: { x: number; y: number }) => void;
}
export const EllipsisButton: FC<EllipsisButtonProps> = ({ handleMenuOpen }) => {
    return (
        <EllipsisWrapper
            onClick={(event) => {
                const { x, y } = event.currentTarget.getBoundingClientRect();
                // Offset y by 20px so it appears below the ellipsis
                handleMenuOpen({ x, y: y + 20 });
            }}
        >
            <EllipsisIcon />
        </EllipsisWrapper>
    );
};
