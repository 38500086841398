import gql from 'graphql-tag';

export type UpdateProjectStatusResult = {
    project: {
        id: string;
    };
};

export const updateProjectStatusMutation = gql`
    mutation UpdateProject($projectId: BigInt!, $status: ProjectStatus) {
        updateProject(input: { id: $projectId, patch: { status: $status } }) {
            project {
                id
            }
        }
    }
`;
