/**
 * Default notification used to indicate if action that was performed resolved
 * with success or with an error.
 */
import React, { FC, useCallback } from 'react';
import clsx from 'clsx';

import { ToastProps, DefaultToast } from 'react-toast-notifications';
import { IconButton } from '@/components/ui/buttons/IconButton';
import { ReactComponent as Reload } from '@/assets/icons/reload.svg';
import {
    Notification as V1Notification,
    notificationMessageIsV1,
    notificationMessageIsV2,
} from '@/contexts/Notifications';
import './Notification.scss';

export interface NotificationProps extends ToastProps {
    text?: string;
    variant?: 'success' | 'error' | 'info' | 'warning' | undefined;
    handleClick?: () => void;
}

export interface ErrorMessageProps {
    message: string;
    handleClick?: () => void;
}

export const ErrorMessage: FC<ErrorMessageProps> = (props) => (
    <div onClick={props.handleClick}>
        <IconButton Icon={Reload} />
        <p>{props.message}</p>
    </div>
);

export const NotificationContent: FC<V1Notification> = ({ title, content, link }) => (
    <div
        className="notification-content"
        onClick={(): void => {
            link && window.open(link, '_blank');
        }}
    >
        <h1>{title}</h1>
        <div className="message-wrapper">{content}</div>
    </div>
);

export const Notification: FC<NotificationProps> = ({ children, ...props }) => {
    const link = notificationMessageIsV1(children) && children.link ? children.link : undefined;

    const renderContent = useCallback(() => {
        if (notificationMessageIsV2(children)) {
            return children.v2Content;
        } else if (notificationMessageIsV1(children)) {
            return (
                <NotificationContent
                    title={children?.title}
                    content={children?.content}
                    link={children.link}
                />
            );
        }
        return <NotificationContent title="" />;
    }, [children]);

    return (
        <div
            className={clsx(
                'notification-wrapper',
                { link },
                notificationMessageIsV2(children)
                    ? 'notification-wrapper--v2'
                    : 'notification-wrapper--v1'
            )}
        >
            <DefaultToast {...props}>{renderContent()}</DefaultToast>
        </div>
    );
};
