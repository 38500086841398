import React, { FC, MouseEventHandler, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { DialogContent } from './MoveToGroupModal.styled';
import { getMarkupEntryById } from './utils';

import { IMarkupFragment, IMarkupGroupFragment } from '@/graphql';

const COPY = {
    cancel: 'Cancel',
    groups: 'Groups',
    moveToGroup: 'Move to Group',
    ok: 'Ok',
};

interface MoveToGroupModalProps extends DialogProps {
    disabled?: boolean;
    markup: IMarkupFragment;
    markupGroups: IMarkupGroupFragment[];
    onCancelButtonClick?: MouseEventHandler<HTMLButtonElement>;
    onOkButtonClick?: (markupGroup: IMarkupGroupFragment) => void;
}

export const MoveToGroupModal: FC<MoveToGroupModalProps> = ({
    disabled,
    markup,
    markupGroups,
    onCancelButtonClick,
    onOkButtonClick,
    ...props
}) => {
    const [selectedMarkupGroupId, setSelectedMarkupGroupId] = useState(markupGroups[0]?.id);

    const handleOkButtonClick = () => {
        const selectedMarkupGroup = getMarkupEntryById(markupGroups, selectedMarkupGroupId);

        if (selectedMarkupGroup) {
            onOkButtonClick?.(selectedMarkupGroup);
        }
    };

    return (
        <Dialog maxWidth="md" {...props}>
            <DialogTitle>
                <Typography sx={{ color: (theme) => theme.palette.hues.neutral[21] }} variant="h3">
                    {`${COPY.moveToGroup} (${markup.name})`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel>{COPY.groups}</InputLabel>
                    <Select
                        value={selectedMarkupGroupId}
                        label="Groups"
                        onChange={(event) => setSelectedMarkupGroupId(event.target.value)}
                    >
                        {markupGroups.map((markupGroup) => (
                            <MenuItem key={markupGroup.id} value={markupGroup.id}>
                                {markupGroup.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled} onClick={onCancelButtonClick} variant="outlined">
                    {COPY.cancel}
                </Button>
                <Button disabled={disabled} onClick={handleOkButtonClick} variant="contained">
                    {COPY.ok}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
