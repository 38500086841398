import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import { StorageKey } from '@/common/storage';
import { useUser } from '@/contexts/User';
import { AppBar } from '@/components/AppBar';
import { isAdministratorUser } from '@/views/Projects/utils';

export const ToolbarRouter: FC = () => {
    const user = useUser();

    const [checkoutSuccessComplete] = useLocalStorage<boolean>(StorageKey.CheckoutSuccessComplete);

    return (
        <Switch>
            <Route path={['/projects/new', '/projects/:uuid/edit', '/projects/:uuid/details']}>
                <AppBar hasBackButton hasUpgradeButton />
            </Route>

            {/** ✨ # TODO => These will be deleted as nav work comes in. ✨ */}
            <Route path={['/projects/:uuid', '/estimators']}>
                <></>
            </Route>

            <Route path="/payment-setup">
                <AppBar hasBackButton={false} />
            </Route>

            <Route exact path="/projects">
                {!isAdministratorUser(user.data.user) && (
                    <AppBar
                        layerProps={{
                            variant: checkoutSuccessComplete === false ? 'default' : 'middle',
                        }}
                        hasBackButton={false}
                        hasUpgradeButton
                    />
                )}
            </Route>

            <Route path="/pricing">
                <AppBar hasBackButton hasUpgradeButton={false} />
            </Route>

            <Route path="*">
                <AppBar hasBackButton hasUpgradeButton />
            </Route>
        </Switch>
    );
};
