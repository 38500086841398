import { useState } from 'react';

import { SortingMethod, defaultSortingMethod } from './sort';

import { Setter } from '@/common/types';
import { makeContext } from '@/common/utils/makeContext';
import { TabType } from '@/components/AdminDashboard/types';
import { ProjectRecord, ProjectWithEventsRecord } from '@/queries/projects';

interface ProjectsContextProps {
    filteredProjects: ProjectWithEventsRecord[];
    setFilteredProjects: Setter<ProjectWithEventsRecord[]>;
    selectedProjectIds: number[];
    setSelectedProjectIds: Setter<number[]>;
    projects: ProjectWithEventsRecord[];
    setProjects: Setter<ProjectWithEventsRecord[]>;
    sortingMethod: SortingMethod;
    setSortingMethod: Setter<SortingMethod>;
    search: string;
    setSearch: Setter<string>;
    tabType: TabType;
    setTabType: Setter<TabType>;
    activeProject: ProjectRecord | null;
    setActiveProject: Setter<ProjectRecord | null>;
    confirmPriceProject: ProjectRecord | null;
    setConfirmPriceProject: Setter<ProjectRecord | null>;
}

/* Context hook, responsible for status and actions */
const { useConsumer, Provider } = makeContext<ProjectsContextProps>(() => {
    // Filtered Projects
    // ----------------------------------------------------------------------------------------------------------------
    const [filteredProjects, setFilteredProjects] = useState<ProjectWithEventsRecord[]>([]);

    const projectsFilteredProjects = { filteredProjects, setFilteredProjects };

    // Selected Project IDs (when filtering by projects needing attention)
    // ----------------------------------------------------------------------------------------------------------------
    const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);

    const projectsSelectedProjectIDs = { selectedProjectIds, setSelectedProjectIds };

    // Projects
    // ----------------------------------------------------------------------------------------------------------------
    const [projects, setProjects] = useState<ProjectWithEventsRecord[]>([]);

    const projectsProjects = { projects, setProjects };

    // Active Project
    // ----------------------------------------------------------------------------------------------------------------
    const [activeProject, setActiveProject] = useState<ProjectRecord | null>(null);

    const projectsActiveProject = { activeProject, setActiveProject };

    // Sorting Method
    // ----------------------------------------------------------------------------------------------------------------
    const [sortingMethod, setSortingMethod] = useState<SortingMethod>(defaultSortingMethod);

    const projectsSortingMethod = { sortingMethod, setSortingMethod };

    // Sorting Method
    // ----------------------------------------------------------------------------------------------------------------
    const [search, setSearch] = useState('');

    const projectsSearch = { search, setSearch };

    // Tab Type (current/completed)
    // ----------------------------------------------------------------------------------------------------------------
    const [tabType, setTabType] = useState<TabType>(TabType.Current);

    const projectsTabType = { tabType, setTabType };

    // Confirm price project
    const [confirmPriceProject, setConfirmPriceProject] = useState<ProjectRecord | null>(null);

    // Combined Projects
    // ----------------------------------------------------------------------------------------------------------------
    return {
        ...projectsFilteredProjects,
        ...projectsSelectedProjectIDs,
        ...projectsProjects,
        ...projectsSortingMethod,
        ...projectsSearch,
        ...projectsTabType,
        ...projectsActiveProject,
        confirmPriceProject,
        setConfirmPriceProject,
    };
});

/* Context provider and consumer, exported for accessibility */
export const ProjectsProvider = Provider;
export const useProjects = useConsumer;
