import { useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { searchMaterials } from '@/common/requests';
import { Hook, Setter } from '@/common/types';
import { IMaterialExcludingValues, IMaterialLightFragment } from '@/graphql';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';

export interface UseMaterialDataOptions {
    searchTriggerInterval?: number;
    exclude?: IMaterialExcludingValues;
}

export interface UseMaterialDataPayload {
    loading: boolean;
    materials: IMaterialLightFragment[];
    searchTerm: string;
    fetchNextPage: null | (() => Promise<void>);
    setSearchTerm: Setter<string>;
}

interface MaterialData {
    materials: IMaterialLightFragment[];
    nextPage: number | null;
}

interface MaterialsSearchData {
    materials: IMaterialLightFragment[];
    more: boolean;
    page: number;
}

export const useMaterialData: Hook<UseMaterialDataPayload, UseMaterialDataOptions> = ({
    searchTriggerInterval = 500,
    exclude,
}) => {
    const client = useApolloClient();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [, setSearchTriggerTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [, setPageFetchCanceler] = useState<(() => void) | null>(null);
    const [materialData, setMaterialData] = useState<MaterialData>({
        materials: [],
        nextPage: null,
    });

    const { localizationCounty, localizationState } = useAssemblyPanel();
    const stateName = localizationState?.localization?.name ?? '';
    const countyName = localizationCounty?.localization?.name ?? '';

    const addMaterialDataPage = (res: MaterialsSearchData): void =>
        setMaterialData((oldMaterialData) => ({
            materials: [...oldMaterialData.materials, ...res.materials],
            nextPage: res.more ? res.page + 1 : null,
        }));

    const [loading, setLoading] = useState(false);

    const materials = useMemo(() => materialData.materials, [materialData]);

    const fetchNextPage = useMemo((): null | (() => Promise<void>) => {
        setLoading(true);

        const nextPage = materialData.nextPage;
        if (nextPage === null) {
            setLoading(false);
            return null;
        }
        // Store a state variable allowing the storage of this query's result to be cancelled.
        let canceled = false;
        const pageFetchCancelerSetter: Parameters<typeof setPageFetchCanceler>[0] = (
            oldPageFetchCanceler
        ) => {
            if (oldPageFetchCanceler) {
                oldPageFetchCanceler();
            }
            return (): void => {
                canceled = true;
            };
        };
        setPageFetchCanceler(pageFetchCancelerSetter);

        return (): Promise<void> =>
            searchMaterials({
                client,
                term: searchTerm,
                page: nextPage,
                exclude,
                state: stateName,
                county: countyName,
            })
                .then((res) => {
                    if (canceled) {
                        return;
                    }
                    // Disable the canceler, but only if it has not been changed since we set it above.
                    setPageFetchCanceler((oldPageFetchCanceler) => {
                        if (pageFetchCancelerSetter === pageFetchCancelerSetter) {
                            return null;
                        }
                        return oldPageFetchCanceler;
                    });
                    addMaterialDataPage(res);
                })
                .finally(() => {
                    setLoading(false);
                });
    }, [materialData, searchTerm]);

    useEffect(() => {
        let canceled = false;
        const newSearchTriggerTimeout = setTimeout(() => {
            (async (): Promise<void> => {
                setLoading(true);
                if (searchTerm === '') {
                    setSearchTriggerTimeout(null);
                    setMaterialData({ materials: [], nextPage: null });
                    return;
                }
                try {
                    const res = await searchMaterials({
                        client,
                        term: searchTerm,
                        page: 0,
                        exclude,
                        state: stateName,
                        county: countyName,
                    });
                    if (!canceled) {
                        setSearchTriggerTimeout(null);
                        setMaterialData({
                            materials: res.materials,
                            nextPage: res.more ? res.page + 1 : null,
                        });
                    }
                } catch (e) {
                    if (!canceled) {
                        setMaterialData({ materials: [], nextPage: null });
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }, searchTriggerInterval);

        setSearchTriggerTimeout((oldSearchTriggerTimeout): typeof newSearchTriggerTimeout => {
            if (oldSearchTriggerTimeout) {
                clearTimeout(oldSearchTriggerTimeout);
            }
            return newSearchTriggerTimeout;
        });

        return (): void => {
            clearTimeout(newSearchTriggerTimeout);
            canceled = true;
        };
    }, [searchTerm, searchTriggerInterval]);

    return {
        loading,
        materials,
        searchTerm,
        fetchNextPage,
        setSearchTerm,
    };
};
