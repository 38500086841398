import { LatLng } from 'leaflet';

// LatLngs a latitude and longitude set in the provided dimension. The supported dimension range
// mirrors that of Leaflet, from 0 (`LatLng`) to 3 (`LatLng[][][]`). If no dimension is provided,
// the type will accept any dimension in the range. Subsets like `LatLng<0|1>` are also supported.
export type LatLngsDimension = 0 | 1 | 2 | 3;
export type LatLngs<T extends LatLngsDimension = LatLngsDimension> = T extends 0
    ? LatLng
    : T extends 1
    ? LatLng[]
    : T extends 2
    ? LatLng[][]
    : LatLng[][][];

export const latLngsIs = {
    latLng: (data: LatLngs): data is LatLngs<0> => {
        return data instanceof LatLng;
    },
    dimensional: (data: LatLngs): data is LatLngs<1 | 2 | 3> => {
        return Array.isArray(data);
    },
    multidimensional: (data: LatLngs): data is LatLngs<2 | 3> => {
        return latLngsIs.dimensional(data) && Array.isArray(data[0]);
    },
    threeDimensional: (data: LatLngs): data is LatLngs<3> => {
        return latLngsIs.multidimensional(data) && Array.isArray(data[0][0]);
    },
};
