import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as WarningAltFilledIcon } from '@/assets/icons/32/warning--alt--filled.svg';
import { GeometryType, PlanPage } from '@/common/types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import { useStorage } from '@/contexts/Storage';
import { useNilState } from '@/common/utils/helpers';
import {
    useCreateProjectPlanPageMutation,
    useUpdateProjectPlanPageMutation,
} from '@/mutations/projectPlanPage';
import { selectedGeometriesObservable } from '@/components/takeoff/observables/interface';
import {
    boundaryDataObservable,
    editedGeometryObservable,
} from '@/components/takeoff/observables/helpers';
import OutsideClickHandler from 'react-outside-click-handler';

const COPY = {
    renamePage: 'Rename',
    duplicatePage: 'Duplicate',
    removePage: 'Remove',
    copy: ' - copy',
    noScaleWarning:
        'There is no scale set for this page, geometries will only display quantities when a page ' +
        'scale is set.',
};

type PageProps = {
    pages: PlanPage[];
    search: string;
    currentPageId: number;
    projectUUID: string;
    pickPage: (id: number) => void;
    loadingCurrentPage: boolean;
};

export const Page = memo<ListChildComponentProps<PageProps>>(({ data, index, style }) => {
    const { pages, currentPageId, projectUUID, pickPage, loadingCurrentPage } = data;

    const page = pages[index];

    const hasGeometriesOtherThanCount = page.markups?.reduce(
        (prev, cur) => prev || cur.type !== GeometryType.COUNT,
        false
    );
    const shouldDisplayWarning = hasGeometriesOtherThanCount ? !page.scale : false;

    const { getUrl } = useStorage();
    const pageRef = useRef<HTMLDivElement>(null);

    const inputRef = useRef<HTMLInputElement | undefined>();

    const [thumbnailURL, setThumbnailURL] = useState('');
    const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);
    const [thumbnailLoadingRetries, setThumbnailLoadingRetries] = useState(0);
    const [renaming, setRenaming] = useState(false);
    const [newPageName, setNewPageName] = useState<string | null>(null);
    const [contextMenuCoordinates, setContextMenuCoordinates] = useNilState<[number, number]>(null);

    const [, updateProjectPlanPage] = useUpdateProjectPlanPageMutation();
    const [, createProjectPlanPage] = useCreateProjectPlanPageMutation();

    useEffect(() => {
        if (loadingCurrentPage) {
            return;
        }
        getUrl(
            projectUUID || '',
            page.projectPlan.uuid,
            page.pageId,
            page.orientation,
            true,
            true
        ).then((url) => {
            setThumbnailURL(url);
        });
    }, [
        loadingCurrentPage,
        projectUUID,
        page.projectPlan.uuid,
        page.pageId,
        page.orientation,
        thumbnailLoadingRetries,
    ]);

    const commitRenaming = (): void => {
        updateProjectPlanPage({
            id: parseInt(page.id),
            name: newPageName || undefined,
        });

        setRenaming(false);
        setNewPageName(null);
    };

    const startRenaming = (): void => {
        setRenaming(true);
        setNewPageName(page.name);

        setTimeout(() => {
            const node = inputRef.current;

            if (node) {
                const selectionEnd = node.selectionEnd || 0;

                node.focus();
                node.selectionStart = selectionEnd + 1000;
            }
        }, 0);

        hideContextMenu();
    };

    const duplicatePage = async (event: React.FormEvent) => {
        event.preventDefault();

        await createProjectPlanPage({
            projectPlanPage: {
                name: `${page.name}${COPY.copy}`,
                widthPx: page.widthPx,
                widthIn: page.widthIn,
                heightPx: page.heightPx,
                heightIn: page.heightIn,
                pageId: page.pageId,
                projectPlanId: page.projectPlan.id,
                orientation: page.orientation,
                originalOrientation: page.originalOrientation,
                scale: page.scale,
                isV2: page.isV2,
            },
        });

        hideContextMenu();
    };

    const changePageVisibility = async (event?: React.FormEvent) => {
        event?.preventDefault();

        await updateProjectPlanPage({
            id: parseInt(page.id),
            removed: true,
        });

        hideContextMenu();
    };

    const showContextMenu = (event: React.MouseEvent): void => {
        if (pageRef.current && style.top !== null) {
            event.preventDefault();

            selectedGeometriesObservable.next({ geometries: [], points: [] });
            boundaryDataObservable.next([]);
            editedGeometryObservable.next(null);

            const { x, y } = pageRef.current.getBoundingClientRect();

            setContextMenuCoordinates([
                Math.min(window.innerWidth - 176, event.clientX) - x,
                event.clientY - y + Number(style.top),
            ]);
        }
    };

    const hideContextMenu = (): void => {
        setContextMenuCoordinates(null);
    };

    const clickOut = (): void => {
        setRenaming(false);
        setNewPageName(null);
    };

    const renderContextMenu = (): JSX.Element => {
        if (!contextMenuCoordinates) return <></>;
        return (
            <OutsideClickHandler onOutsideClick={hideContextMenu}>
                <div
                    className="context-menu"
                    style={{
                        left: contextMenuCoordinates[0],
                        top: contextMenuCoordinates[1],
                    }}
                >
                    <div onClick={startRenaming}>
                        <span>{COPY.renamePage}</span>
                    </div>
                    <div onClick={duplicatePage}>
                        <span>{COPY.duplicatePage}</span>
                    </div>
                    <div onClick={changePageVisibility}>
                        <span>{COPY.removePage}</span>
                    </div>
                </div>
            </OutsideClickHandler>
        );
    };

    const markups = page.markups || [];

    return (
        <OutsideClickHandler onOutsideClick={clickOut}>
            <div style={style}>
                <ListItemButton
                    onClick={(): void => pickPage(parseInt(page.id))}
                    onContextMenu={showContextMenu}
                    ref={pageRef}
                    selected={page.id === currentPageId.toString()}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                        spacing="16px"
                    >
                        <Stack alignItems="center" direction="row" spacing="8px">
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: '56px',
                                    justifyContent: 'center',
                                    width: '56px',
                                }}
                            >
                                {thumbnailURL && (
                                    <img
                                        src={`${thumbnailURL}&retries=${thumbnailLoadingRetries}`}
                                        onLoad={() => setIsThumbnailLoaded(true)}
                                        onError={() =>
                                            setTimeout(
                                                () =>
                                                    setThumbnailLoadingRetries(
                                                        (retries) => retries + 1
                                                    ),
                                                2000
                                            )
                                        }
                                    />
                                )}
                                {!isThumbnailLoaded && <CircularProgress size={20} />}
                            </Box>
                            <Stack>
                                <TextField
                                    title={page.name}
                                    inputRef={inputRef}
                                    inputProps={{
                                        disabled: !renaming,
                                        sx: {
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            padding: '2px 6px',
                                            textOverflow: 'ellipsis',
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer',
                                            ':hover': {
                                                backgroundColor: !renaming
                                                    ? 'transparent'
                                                    : undefined,
                                            },
                                        },
                                    }}
                                    onChange={(event) => {
                                        setNewPageName(event.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();

                                            inputRef.current?.blur();

                                            commitRenaming();
                                        }
                                    }}
                                    value={renaming ? newPageName : page.name}
                                    variant="filled"
                                />
                                <ListItemText
                                    secondary={
                                        markups.length === 1
                                            ? `${markups.length} takeoff`
                                            : `${markups.length} takeoffs`
                                    }
                                    sx={{
                                        padding: '0 0 0 6px',
                                    }}
                                />
                            </Stack>
                        </Stack>
                        {shouldDisplayWarning && (
                            <Tooltip title={COPY.noScaleWarning}>
                                <SvgIcon
                                    color="warning"
                                    viewBox="0 0 32 32"
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                >
                                    <WarningAltFilledIcon />
                                </SvgIcon>
                            </Tooltip>
                        )}
                    </Stack>
                </ListItemButton>
            </div>
            {renderContextMenu()}
        </OutsideClickHandler>
    );
});
