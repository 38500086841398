import { useEffect, useState } from 'react';
import {
    geometriesObservable,
    markupWithinGroupObservable,
} from '@/components/takeoff/observables/interface';
import { ToolType } from '@/common/types';
import { IMarkupType } from '@/graphql';

const TOOL_TYPE_MARKUP_TYPE_MAP = {
    [IMarkupType.Area]: ToolType.AREA,
    [IMarkupType.Count]: ToolType.COUNT,
    [IMarkupType.Linear]: ToolType.LINEAR,
};

/**
 * Create callback that adds a markup to a group upon markup creation.
 * @param {string} markupGroupID - markup group that new markup will be added to
 * @returns {(geometry: IMarkupType) => void} callback that updates markupWithinGroupObservable
 * observable.
 */
export const useMarkupWithinGroupUpdate = (markupGroupID?: string) => {
    // Listen to the current number of geometries in order to obtain the index used to update
    // the next created geometry
    const [currentGeometryCount, setCurrentGeometryCount] = useState<number | undefined>(undefined);
    useEffect(() => {
        geometriesObservable.subscribe((geometries) => {
            setCurrentGeometryCount(geometries?.length);
        });
    }, []);

    return (geometry: IMarkupType) => {
        if (markupGroupID && currentGeometryCount !== undefined) {
            markupWithinGroupObservable.next({
                toolType: TOOL_TYPE_MARKUP_TYPE_MAP[geometry],
                markupGroupID,
                currentGeometryCount,
            });
        }
    };
};
