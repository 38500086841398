import { useSampleProject } from './hooks/useSampleProject';
import { Body, DialogContent, LeftContent, RightContent, Title } from './styled';
import {
    InstructionVideo,
    LoomVideo,
    VideoWrapper,
} from '@/components/projects/ProjectDetails/Modals/Popover/DesktopOnly/InstructionVideo';
import { ProjectRecord } from '@/queries/projects';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import React, { FC, useState } from 'react';
import { Link as RRDLink, useHistory } from 'react-router-dom';
import { Mode } from '@/theme/Mode';
import { Button, Stack } from '@mui/material';

import { ProjectPlanFilesUploader } from '@/components/projects/ProjectPlanFileUploader';

interface NoFilesModal extends DialogProps {
    project: ProjectRecord;
    onFilesUploaded: () => void;
}

const COPY = {
    title: 'Upload files to use takeoff',
    description:
        'Takeoff enables you to determine exact quantities by using our drawing and measurement tools. To get started, upload plans related to this project.',
    trySampleProject: 'Try a sample project',
    cancel: 'Cancel',
    uploadFiles: 'Upload files',
    uploadNote:
        'Upload files of type JPG, PNG, ZIP or PDF. Files should each be less than 200 megabytes in size.',
};

export const NoFilesModal: FC<NoFilesModal> = ({ project, onFilesUploaded, ...props }) => {
    const [uploaderOpen, setUploaderOpen] = useState<boolean>(false);
    const history = useHistory();
    const { sampleProjectUUID } = useSampleProject();

    return (
        <Mode variant="light">
            <ScopedCssBaseline>
                <Dialog {...props}>
                    <DialogContent>
                        <LeftContent>
                            <InstructionVideo>
                                <VideoWrapper>
                                    <LoomVideo />
                                </VideoWrapper>
                            </InstructionVideo>
                        </LeftContent>
                        <RightContent>
                            <Title variant="h1">{COPY.title}</Title>
                            <Body variant="body1">{COPY.description}</Body>
                            <Stack direction="row" spacing="16px">
                                <Button
                                    fullWidth
                                    onClick={() => history.goBack()}
                                    variant="outlined"
                                >
                                    {COPY.cancel}
                                </Button>
                                <Button
                                    fullWidth
                                    onClick={() => setUploaderOpen(true)}
                                    variant="contained"
                                >
                                    {COPY.uploadFiles}
                                </Button>
                            </Stack>

                            <ProjectPlanFilesUploader
                                open={uploaderOpen}
                                onClose={() => setUploaderOpen(false)}
                                projectUUID={project.uuid}
                                onFilesUploaded={onFilesUploaded}
                            />

                            {sampleProjectUUID && (
                                <Link
                                    align="center"
                                    component={RRDLink}
                                    display="block"
                                    to={`/projects/${sampleProjectUUID}`}
                                    sx={{ marginTop: '24px' }}
                                >
                                    {COPY.trySampleProject}
                                </Link>
                            )}
                        </RightContent>
                    </DialogContent>
                </Dialog>
            </ScopedCssBaseline>
        </Mode>
    );
};
