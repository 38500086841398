/*
 * ProjectPanel is a side panel within the admin dashboard for interacting with a project.
 */
import React, { FC, useEffect, useState } from 'react';

import { ClickInterceptor } from './ClickInterceptor';
import { Container } from './Container';
import { ProjectPanelContextType, makeProjectContext } from './context';

import {
    openedProjectObservable,
    subscribeToOpenedProjectObservable,
} from '@/interfaces/adminDashboardProjects';

export const ProjectPanel: FC = () => {
    const [projectsContext, setProjectsContext] = useState<ProjectPanelContextType | null>(null);

    useEffect(() => {
        const subscription = subscribeToOpenedProjectObservable((payload): void => {
            if (payload) {
                setProjectsContext(makeProjectContext(payload));
            }
        });
        return (): void => subscription.unsubscribe();
    }, []);

    const closeProjectPanel = (): void => {
        openedProjectObservable.next(null);
        setProjectsContext(null);
    };

    if (projectsContext === null) {
        return <></>;
    }

    return (
        <projectsContext.Provider>
            <Container
                closeProjectPanel={closeProjectPanel}
                useProjectPanel={projectsContext.useConsumer}
            />
            <ClickInterceptor useProjectPanel={projectsContext.useConsumer} />
        </projectsContext.Provider>
    );
};
