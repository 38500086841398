import React, { FC, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import to from 'await-to-js';
import { useParams } from 'react-router-dom';

import { UnsubscribeAppBar } from './AppBar';
import { Info, InfoContainer, Title, Wrapper } from './styled';

import { useNotifications } from '@/contexts/Notifications';
import { usePublicUnsubscribeEstimateMutation } from '@/graphql';
import { Mode } from '@/theme/Mode';

const COPY = {
    title: 'Are you sure you want to unsubscribe?',
    info: 'By clicking the next button you agree to unsubscribe.',
    buttonText: 'Unsubscribe',
    genericErrorMessage: 'There has been an error',
    genericSuccessMessage: 'Unsubscription was successful',
};

export const Unsubscribe: FC = () => {
    const params = useParams<{ projectUUID: string; hash: string }>();

    const [projectUUID, setProjectUUID] = useState<string>('');
    const [hash, setHash] = useState<string>('');

    const [unsubscribeToPublicEstimation] = usePublicUnsubscribeEstimateMutation();
    const { addNotification } = useNotifications();

    // get specific id
    useEffect(() => {
        setProjectUUID(params?.projectUUID ?? '');
        setHash(params?.hash ?? '');
    }, [params]);

    const handleUnsubscriptionToPublicEstimate = async () => {
        const [error] = await to(
            unsubscribeToPublicEstimation({
                variables: {
                    input: {
                        projectUUID,
                        hash,
                    },
                },
            })
        );

        if (error) {
            addNotification(
                { title: 'Error', content: error.message.toString() ?? COPY.genericErrorMessage },
                'error'
            );
        } else {
            addNotification(
                {
                    title: 'Success',
                    content: COPY.genericSuccessMessage,
                },
                'success'
            );
        }
    };

    const onUnsubscribeClick = () => {
        if (projectUUID === '' || hash === '') return;
        handleUnsubscriptionToPublicEstimate();
    };

    return (
        <Wrapper>
            <UnsubscribeAppBar />
            <Mode variant="light">
                <InfoContainer maxWidth="xs">
                    <Title variant="h2">{COPY.title}</Title>
                    <Info variant="body1">{COPY.info}</Info>
                    <Button
                        onClick={onUnsubscribeClick}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        {COPY.buttonText}
                    </Button>
                </InfoContainer>
            </Mode>
        </Wrapper>
    );
};
