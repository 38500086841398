import { useEffect, useState } from 'react';

import { QueryHookOptions, useQuery } from '@apollo/client';

import { Hook, Setter } from '@/common/types';
import { PaginationArgs, PaginationDirection, genPaginationArgs } from '@/common/utils/pagination';
import {
    IUserFragment,
    IUserRole,
    IUsersOrderBy,
    IUsersQuery,
    IUsersQueryVariables,
    UsersDocument,
    UsersLiteDocument,
} from '@/graphql';

const DEFAULT_PAGE_SIZE = 1000;

export interface UseUsersRes {
    users: IUserFragment[];
    loading: boolean;
    error: string;
    refetch?: () => void;
    setUsers: Setter<IUserFragment[]>;
}

// TODO: Expand condition with search field so we can use search functionality
export interface UseUsersProps {
    search?: string;
    roles?: IUserRole[];
    lightUsers?: boolean;
    showPaused?: boolean;
}

export const useUsers: Hook<UseUsersRes, UseUsersProps> = ({
    roles,
    lightUsers = false,
    showPaused = false,
}): UseUsersRes => {
    const [users, setUsers] = useState<IUserFragment[]>([]);

    const defaultPaginationArgs = genPaginationArgs(PaginationDirection.Next, DEFAULT_PAGE_SIZE);

    const usersQueryInput = (
        paginationArgs?: PaginationArgs
    ): QueryHookOptions<IUsersQuery, IUsersQueryVariables> => ({
        variables: {
            input: {
                arguments: paginationArgs?.variables.input.arguments,
                condition: {
                    roles,
                    showPaused,
                },
                orderBy: IUsersOrderBy.Natural,
            },
        },
    });

    // Queries
    const { data, fetchMore, loading, error, refetch } = useQuery<
        IUsersQuery,
        IUsersQueryVariables
    >(lightUsers ? UsersLiteDocument : UsersDocument, {
        variables: usersQueryInput(defaultPaginationArgs).variables,
        fetchPolicy: 'network-only',
    });

    const fetchUsersPage = (): void => {
        const paginationArgs = genPaginationArgs(
            PaginationDirection.Next,
            DEFAULT_PAGE_SIZE,
            data?.users.pageInfo
        );
        if (paginationArgs) {
            fetchMore?.(usersQueryInput(paginationArgs));
        }
    };

    const hasNextPage = (): boolean => {
        return data?.users.pageInfo.hasNextPage === true;
    };

    useEffect(() => {
        if (data && !loading) {
            const newUsers =
                data.users?.edges?.map(({ node: user }) => ({
                    ...user,
                })) ?? [];

            // If it's a full refetch from the beginning, clear the state (to handle deletions)
            if (!data.users.pageInfo.hasPreviousPage) {
                setUsers(newUsers);
            }

            if (hasNextPage()) {
                fetchUsersPage();
            }
        }
    }, [data]);

    return {
        users,
        loading,
        error: error ? error.message : '',
        refetch,
        setUsers,
    };
};
