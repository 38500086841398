import React, { FC } from 'react';
import { mapStatus } from '@/components/AdminDashboard/Projects/sort';
import { IProjectStatus } from '@/graphql';
import { useStatusColor } from './useStatusColor';
import { toStatusText } from './utils';
import { Line, Text, Tag } from './styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    status?: IProjectStatus | null;
}

export const StatusTag: FC<Props> = ({ status: statusProp, ...props }) => {
    const status = mapStatus(statusProp);
    const color = useStatusColor({ status });

    return (
        <Tag {...props}>
            <Line color={color} />
            <Text variant="button">{toStatusText(status)}</Text>
        </Tag>
    );
};
