import { Cell, Coordinates, LeydenEditor, Table } from 'leyden';
import { ReactEditor } from 'leyden-react';
import { Editor } from 'slate';

import { Queries } from '../queries';
import { Serialize } from '../serialization';
import {
    createAssembly,
    deleteAssembly,
    deleteAssemblyForV2Ordering,
    duplicateAssembly,
    favoriteAssembly,
    reorderAssembly,
    reorderAssemblyBeforeUUID,
} from '../utils/requests';
import {
    categoriesFromAssembliesQuery,
    categoryFromFragments,
    stringToAssemblyReorderDirection,
} from '../utils/transforms';
import { EstimateElementCategory } from '../utils/types';

import { Transforms } from '.';
import { ApolloClient } from '@/common/apollo/execute';
import { CategoryRow } from '@/components/Estimate/queries/categories';
import { IAssembliesQuery, IAssemblyFragment, IElementFragment } from '@/graphql';
import { LDService } from '@/contexts/LaunchDarkly';

export interface CategoryTransforms {
    addAssembly: (
        editor: Editor,
        assembly: IAssemblyFragment,
        options?: {
            at?: number;
            elements?: IElementFragment[];
        }
    ) => void;
    addCategories: (
        editor: Editor,
        categories: EstimateElementCategory[],
        options?: {
            at?: number;
            comesFromBackend?: boolean;
        }
    ) => void;
    createCategory: (
        editor: Editor,
        name: string,
        projectID: string,
        cell: Cell<'Actions'>,
        client: ApolloClient
    ) => Promise<void | undefined>;
    deleteCategory: (editor: Editor, categoryID: string, client?: ApolloClient) => void;
    favoriteCategory: (
        editor: Editor,
        assemblyID?: string,
        elementId?: string,
        favorited?: boolean,
        options?: {
            at?: Coordinates;
            client?: ApolloClient;
        }
    ) => void;
    reorderCategory: (
        editor: Editor,
        options: {
            categoryID: string;
            direction: 'up' | 'down';
            client?: ApolloClient;
        }
    ) => void;
    duplicateCategory: (
        editor: Editor,
        options: {
            categoryID: string;
            projectID: string;
            client?: ApolloClient;
        }
    ) => void;
}

const gatherCategoryRows = (editor: Editor, categoryID: string): number[] => {
    const table = LeydenEditor.table(editor);
    let startRow = -1;
    let { rows: endRow } = Table.dimensions(table);
    for (const [cell, coords] of Table.column(table, { at: 0, type: 'Category' })) {
        if (startRow !== -1) {
            endRow = coords.y;
            break;
        }
        if (cell.id === categoryID) {
            startRow = coords.y;
        }
    }
    if (startRow === -1) {
        return [];
    }

    return Array.from({ length: endRow - startRow }, (_, i) => i + startRow);
};

export const CategoryTransforms: CategoryTransforms = {
    /**
     * Add a saved assembly as a category.
     */

    addAssembly(
        editor: Editor,
        assembly: IAssemblyFragment,
        options: {
            at?: number;
            elements?: IElementFragment[];
        } = {}
    ): void {
        const { elements = [], ...categoriesOptions } = options;
        const category = categoryFromFragments(assembly, elements);
        Transforms.addCategories(editor, [category], categoriesOptions);
    },

    /**
     * Add a category.
     */

    addCategories(
        editor: Editor,
        categories: EstimateElementCategory[],
        options: {
            at?: number;
            comesFromBackend?: boolean;
        } = {}
    ): void {
        let { at } = options;
        const { comesFromBackend } = options;
        if (at === undefined) {
            if (comesFromBackend) {
                const { rows } = Table.dimensions(LeydenEditor.table(editor));
                at = rows;
            } else {
                const lastRootElement = Queries.elements(editor, {
                    categoryID: null,
                    reverse: true,
                }).next().value;
                if (!lastRootElement) {
                    at = 1;
                } else {
                    at = lastRootElement[1] + 2;
                }
            }
        }
        const cells = Serialize.Cells.rowsFromCategories(categories);
        Transforms.insertRows(editor, cells, {
            at,
        });
    },

    /**
     * Create a category directly below an action button and persist it in the database.
     */

    createCategory(
        editor: Editor,
        name: string,
        projectID: string,
        cell: Cell<'Actions'>,
        client: ApolloClient
    ): Promise<void | undefined> {
        const ownCoords = ReactEditor.cellCoords(editor, cell);
        if (ownCoords === null) {
            return Promise.resolve(undefined);
        }
        const { y: insertionRow } = Coordinates.move(ownCoords, 'down');
        return createAssembly(client, name, projectID).then((assembly) => {
            const createdAssemblyID = assembly?.assemblyAssignment.id;
            const createdAssemblyUUID = assembly?.assemblyAssignment.uuid;
            const createdAssemblyFavorited = assembly?.assemblyAssignment.favorited;

            if (createdAssemblyID === undefined || createdAssemblyFavorited === undefined) {
                return;
            }

            const categoryRow = [
                Serialize.Cell.Category({
                    name,
                    id: createdAssemblyID,
                    uuid: createdAssemblyUUID || '',
                    favorited: createdAssemblyFavorited,
                }),
                ...Serialize.Cells.empty(Serialize.Constants.COLUMN_COUNT - 2, null),
                Serialize.Cell.CategoryTotal({ id: createdAssemblyID }),
            ];

            const newCells = [
                ...categoryRow,
                Serialize.Cell.Actions({ categoryID: createdAssemblyID }),
                ...Serialize.Cells.empty(Serialize.Constants.COLUMN_COUNT - 1, createdAssemblyID),
            ];
            Transforms.insertRows(editor, newCells, { at: insertionRow });
        });
    },

    /**
     * Delete a category. If `client` is passed, the deletion is persisted in the database.
     */

    deleteCategory(editor: Editor, categoryID: string, client?: ApolloClient): void {
        const rowsToDelete = gatherCategoryRows(editor, categoryID);

        if (rowsToDelete.length === 0) {
            return;
        }

        if (client) {
            const groupAndItemOrderingV2Flag = LDService.variation(
                'GroupAndItemOrderingV2',
                false
            ) as boolean;

            if (groupAndItemOrderingV2Flag) {
                let assemblyUUID: string | undefined;

                for (const category of Queries.categories(editor)) {
                    if (category[0].categoryID === categoryID) {
                        assemblyUUID = category[0].uuid;
                        break;
                    }
                }

                if (!assemblyUUID) {
                    return;
                }

                deleteAssemblyForV2Ordering(client, { assemblyUUID, assemblyID: categoryID }).then(
                    () => {
                        Transforms.deleteRows(editor, { at: new Set(rowsToDelete) });
                    }
                );
            } else {
                deleteAssembly(client, categoryID).then(() => {
                    Transforms.deleteRows(editor, { at: new Set(rowsToDelete) });
                });
            }
        }
    },

    /**
     * Favorite a category.  If `client` is passed, the favoriting is persisted in the database.
     */

    favoriteCategory(
        editor: Editor,
        assemblyID?: string,
        elementId?: string,
        favorited?: boolean,
        options?: {
            at?: Coordinates;
            client?: ApolloClient;
        }
    ): void {
        Transforms.setCell<'Category'>(editor, { favorited: !favorited }, options);

        if (options?.client) {
            favoriteAssembly(options.client, assemblyID, elementId, !favorited);
        }
    },

    /**
     * Move a category up or down within the estimate. If `client` is passed, the move is persisted in the database.
     */

    reorderCategory(
        editor: Editor,
        options: {
            categoryID: string;
            direction: 'up' | 'down';
            client?: ApolloClient;
        }
    ): void {
        const groupAndItemOrderingV2Flag = LDService.variation(
            'GroupAndItemOrderingV2',
            false
        ) as boolean;

        const { client, direction, categoryID } = options;
        const rowsToMove = gatherCategoryRows(editor, categoryID);
        if (!rowsToMove) {
            return;
        }

        let to = 0;

        const reverse = direction === 'down';

        let neighboringCategoryRow: CategoryRow | null = null;
        let categoryToMove: CategoryRow | null = null;
        let before: CategoryRow | null = null;
        for (const category of Queries.categories(editor, { reverse })) {
            if (category[0].categoryID === categoryID) {
                categoryToMove = category;
                break;
            }
            if (reverse) {
                before = neighboringCategoryRow;
            } else {
                before = category;
            }
            neighboringCategoryRow = category;
        }

        if (categoryToMove === null) {
            return;
        }

        if (!neighboringCategoryRow) {
            return;
        }

        if (direction === 'down') {
            const lastPreviousCategoryElement = Queries.elements(editor, {
                categoryID: neighboringCategoryRow[0].categoryID,
                reverse: true,
            }).next().value;
            if (lastPreviousCategoryElement) {
                to = lastPreviousCategoryElement[1] + 1;
            } else {
                if (direction === 'down') {
                    // direction is down and lastPreviousCategoryElement is undefined so skip a row for the blank row after the category
                    to = neighboringCategoryRow[1] + 1;
                } else {
                    to = neighboringCategoryRow[1];
                }
            }
        } else {
            to = neighboringCategoryRow[1];
        }

        let rowsMoved = 0;
        for (const rowToMove of rowsToMove) {
            Transforms.moveRow(editor, { at: rowToMove - rowsMoved, to });
            // When moving down, we have to accommodate for the fact that every row moved offsets the next source row
            // by -1.
            if (direction === 'down') {
                rowsMoved++;
                // When moving down, we have to accommodate for the fact that every row moved offsets the next target
                // row by +1.
            } else {
                to++;
            }
        }

        if (client) {
            if (groupAndItemOrderingV2Flag) {
                reorderAssemblyBeforeUUID(
                    client,
                    categoryToMove[0].uuid,
                    before?.[0]?.uuid || null
                );
            } else {
                reorderAssembly(client, categoryID, stringToAssemblyReorderDirection[direction]);
            }
        }
    },

    /**
     * Create a copy of the category above the original one. If `client` is passed, the action is persisted in the
     * database.
     */

    duplicateCategory(
        editor: Editor,
        options: {
            categoryID: string;
            projectID: string;
            client?: ApolloClient;
        }
    ): void {
        const { client, categoryID, projectID } = options;

        let to = 0;
        let beforeUUID: string | undefined;
        for (const category of Queries.categories(editor)) {
            if (category[0].categoryID === categoryID) {
                beforeUUID = category[0].uuid;
                to = category[1];
                break;
            }
        }

        if (client) {
            duplicateAssembly(client, categoryID, projectID, beforeUUID).then((assemblies) => {
                if (!assemblies) {
                    return;
                }
                const assembliesQueryLike: IAssembliesQuery = {
                    assemblies: {
                        edges: assemblies.assembliesDuplication.edges,
                    },
                    // We're doing a hard-cast here to avoid having to define dummy pageInfo.
                } as IAssembliesQuery;
                const categories = categoriesFromAssembliesQuery(assembliesQueryLike);
                Transforms.addCategories(editor, categories, { at: to });
            });
        }
    },
};
