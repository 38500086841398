import React, { FC, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';

export interface BaseListItemModalProps extends React.HTMLAttributes<HTMLDivElement> {
    icon?: React.ReactNode;
    leftListItemTextProps?: ListItemTextProps;
}

export const BaseListItemModal: FC<BaseListItemModalProps> = ({
    icon,
    leftListItemTextProps,
    ...props
}) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    return (
        <div {...props}>
            <ListItem dense onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText
                    {...leftListItemTextProps}
                    primaryTypographyProps={{
                        color: (theme) => theme.palette.hues.neutral[98],
                        variant: 'body2',
                        ...leftListItemTextProps?.primaryTypographyProps,
                    }}
                />
            </ListItem>
        </div>
    );
};
