import React, { FC } from 'react';

import { DivProps } from '@/common/types';
import { UserModalContent } from '@/components/mission-control/UserManager/UserModal/styled';
import { UserProperty } from '@/components/mission-control/UserManager/UserModal/UserProperty';
import { IUserFragment } from '@/graphql';

export interface UserContentProps extends DivProps {
    user: IUserFragment;
}

export const UserContent: FC<UserContentProps> = ({ user, ...rest }) => {
    const userProperties: Record<string, string | null> = {
        ID: user.id,
        'Auth ID': user.authID,
        Email: user.email,
        Phone: user.phone,
        PreferredClients: user.preferredClients?.map((pc) => pc.team.name).join(', ') ?? null,
        Roles: user.roles.map((r) => r.toLocaleLowerCase()).join(', '),
        Status: user.status?.toLocaleLowerCase() ?? null,
        Team: user.team?.name ?? null,
        Timezone: user.timezone,
        TeamID: user.teamID,
    };

    return (
        <UserModalContent {...rest}>
            {Object.entries(userProperties)
                .filter((entry): entry is [string, string] => !!entry[1])
                .map(([name, val]) => (
                    <UserProperty key={name} name={name} val={val} />
                ))}
        </UserModalContent>
    );
};
