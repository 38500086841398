import LoadingButton from '@mui/lab/LoadingButton';
import { CardProps } from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { FC, useState } from 'react';
import {
    Card,
    HighlightListItemIcon,
    PlanDescription,
    PlanHeading,
    PlanPrice,
    PlanPeriod,
    PlanVariant,
    PlanVariants,
    Seperator,
} from './styled';
import { Plan, PlanVariant as PlanVariantType } from '../types';
import { ReactComponent as Checkmark } from '@/assets/icons/checkmark-currentColor.svg';

interface Props extends CardProps {
    current?: boolean;
    loading?: boolean;
    onPlanSelect?: (variant?: PlanVariantType) => void;
    plan?: Plan;
}

export const PlanCard: FC<Props> = ({ current, loading, onPlanSelect, plan, ...props }) => {
    const [selectedPlanVariant, setSelectedPlanVariant] = useState<PlanVariantType>();

    return (
        <Card elevation={8} {...props}>
            <PlanHeading variant="h5">{plan?.name}</PlanHeading>
            <PlanDescription variant="body1">{plan?.description}</PlanDescription>
            <PlanVariants>
                {plan?.variants
                    .filter((variant) => variant?.display !== false)
                    .map((variant, index, collection) => (
                        <React.Fragment key={variant.id}>
                            <PlanVariant>
                                <PlanPrice variant="h2">{variant.price}</PlanPrice>
                                <PlanPeriod variant="body1">{variant.period}</PlanPeriod>
                                <LoadingButton
                                    loading={loading && selectedPlanVariant?.id === variant.id}
                                    data-variant-id={variant?.id}
                                    disabled={current || loading}
                                    onClick={() => {
                                        setSelectedPlanVariant(variant);
                                        onPlanSelect?.(variant);
                                    }}
                                    variant="outlined"
                                >
                                    {current ? 'Your Plan' : 'Select'}
                                </LoadingButton>
                            </PlanVariant>
                            {index < collection.length - 1 && <Seperator>or</Seperator>}
                        </React.Fragment>
                    ))}
            </PlanVariants>
            <List dense disablePadding>
                {plan?.highlights?.map((highlight) => (
                    <ListItem dense disableGutters key={highlight}>
                        <HighlightListItemIcon>
                            <Checkmark />
                        </HighlightListItemIcon>
                        <ListItemText>{highlight}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};
