import { v4 as uuid } from 'uuid';

import { EstimationView } from '../types';
import { useEstimationEditor } from './useEstimationEditor';
import { useEstimationLayout } from './useEstimationLayout';
import { useEstimationProject } from './useEstimationProject';
import { AssemblyDataRecord } from '@/components/AssemblyPanel/utils/types';
import { useAssembliesDuplicationMutation } from '@/graphql';
import { executeCreateProjectAssemblyMutation } from '@/mutations/projectAssembly';
import { executeProjectAssembliesQuery } from '@/queries/projectAssemblies';
import { Transforms } from '@/components/Estimate/transforms';

export interface UseSelectEstimateAssemblyRes {
    selectEstimateAssembly?: (convertedAssembly: AssemblyDataRecord) => Promise<void>;
}

export const useSelectEstimateAssembly = (): UseSelectEstimateAssemblyRes => {
    const editor = useEstimationEditor();
    const { projectID } = useEstimationProject();

    const { view } = useEstimationLayout();

    const [assembliesDuplicationMutation] = useAssembliesDuplicationMutation();

    const createProjectAssembly = async (selectedAssemblyId: number): Promise<void> => {
        const res = await executeProjectAssembliesQuery({
            assemblyId: selectedAssemblyId,
            projectId: projectID,
        });
        if (res.data?.projectAssemblies?.nodes.length === 0 && selectedAssemblyId) {
            await executeCreateProjectAssemblyMutation({
                input: {
                    projectAssembly: {
                        id: uuid(),
                        assemblyId: selectedAssemblyId,
                        projectId: projectID,
                    },
                },
            });
        }
    };

    const selectEstimateAssembly = async (convertedAssembly: AssemblyDataRecord): Promise<void> => {
        if (projectID === -1 || !convertedAssembly.trade?.name) {
            return;
        }
        const result = await assembliesDuplicationMutation({
            variables: {
                input: {
                    projectID: String(projectID),
                    ids: [String(convertedAssembly.id)],
                    trade: convertedAssembly.trade.name,
                },
            },
        });
        if (
            !result.data?.assembliesDuplication.edges ||
            result.data?.assembliesDuplication.edges.length !== 1
        ) {
            return;
        }
        const { elements: nestedElements, ...processedAssembly } =
            result.data?.assembliesDuplication.edges[0].node;
        const processedElements = nestedElements?.edges?.map(({ node: element }) => ({
            ...element,
            assemblyID: result.data?.assembliesDuplication.edges?.[0].node.id ?? '',
            unitID: element.unit.id,
            variables: {
                ...element.variables,
                edges:
                    element.variables.edges?.map((variable) => ({
                        ...variable,
                    })) ?? [],
                pageInfo: {
                    startCursor: element.variables.edges?.[0]?.node.id ?? '0',
                    endCursor:
                        element.variables.edges?.[element.variables.edges.length - 1]?.node.id ??
                        '0',
                    hasNextPage: false,
                    hasPreviousPage: false,
                },
            },
        }));
        Transforms.addAssembly(editor, processedAssembly, { elements: processedElements });
        const duplicatedAssemblyID = Number(processedAssembly.id);
        await createProjectAssembly(duplicatedAssemblyID);
    };

    return {
        selectEstimateAssembly:
            view === EstimationView.Estimate ? selectEstimateAssembly : undefined,
    };
};
