import React, { useState } from 'react';
import { FC } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import { COPY, InputProps, ROLES, RoleSelect } from '../constants';
import { Field, FieldDictionary, FormProps } from '../types';
import { changeDictionaryField, handleRequest, isFormValid } from '../utils';

import { useNotifications } from '@/contexts/Notifications';
import { IUserRole, useUserToTeamConnectionMutation } from '@/graphql';

export interface ConnectUserToTeamFieldsDictionary extends FieldDictionary {
    email: Field & {
        value: string;
    };
    teamId: Field & {
        value: string;
    };
    role: Field & {
        value: string;
    };
}

export const ConnectUserToAccount: FC<FormProps> = ({ onSubmit }) => {
    const Fields: ConnectUserToTeamFieldsDictionary = {
        email: {
            value: '',
            touched: false,
        },
        teamId: {
            value: '',
            touched: false,
        },
        role: {
            value: '',
            touched: false,
        },
    };

    const [currentFields, setCurrentFields] = useState<ConnectUserToTeamFieldsDictionary>({
        ...Fields,
    });
    const [open, setOpen] = useState(false);

    const { addNotification } = useNotifications();
    const [userToTeamConnectionMutation] = useUserToTeamConnectionMutation();

    const handleSubmit = async () => {
        const { email, teamId, role } = currentFields;
        setOpen(true);
        try {
            if (!teamId || !email || !role) {
                throw new Error('Please fill required fields.');
            } else if (Number(teamId.value) <= 0) {
                throw new Error('Team ID cannot be negative or zero.');
            }

            await handleRequest(
                userToTeamConnectionMutation({
                    variables: {
                        input: {
                            email: email.value,
                            teamID: teamId.value,
                            roles: [...role.value] as IUserRole[],
                        },
                    },
                }),
                addNotification
            );

            // return inputs to initial state
            setCurrentFields(Fields);

            setOpen(false);
            onSubmit();
        } catch (e) {
            setOpen(false);
            addNotification({ title: 'Error', content: String(e) }, 'error');
        }
    };

    return (
        <>
            <Backdrop open={open}>
                <CircularProgress color="primary" />
            </Backdrop>
            <DialogContent>
                <TextField
                    {...InputProps}
                    required
                    value={currentFields['email'].value}
                    label={COPY.emailAddress}
                    error={currentFields['email'].touched && currentFields['email'].value === ''}
                    helperText={
                        currentFields['email'].touched &&
                        currentFields['email'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'email',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'email',
                            'touched',
                            true
                        );
                    }}
                />
                <TextField
                    {...InputProps}
                    required
                    value={currentFields['teamId'].value}
                    label={COPY.teamId}
                    type="number"
                    error={currentFields['teamId'].touched && currentFields['teamId'].value === ''}
                    helperText={
                        currentFields['teamId'].touched &&
                        currentFields['teamId'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'teamId',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'teamId',
                            'touched',
                            true
                        );
                    }}
                />

                <TextField
                    {...InputProps}
                    select
                    required
                    value={currentFields['role'].value}
                    label={COPY.role}
                    error={currentFields['role'].touched && currentFields['role'].value === ''}
                    helperText={
                        currentFields['role'].touched &&
                        currentFields['role'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'role',
                            'value',
                            e.target.value
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'role',
                            'touched',
                            true
                        );
                    }}
                >
                    {ROLES.map((option: RoleSelect) => (
                        <MenuItem key={option.label} value={option.name}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!isFormValid(currentFields)}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {COPY.submit}
                </Button>
            </DialogActions>
        </>
    );
};
