/*
 * projectPlanFiles.ts provides GraphQL queries for the project_plan_file table.
 */
import { frogmint, newQueries } from '../frog';

// other operations that update project plan files
const additionalTypenames = ['EditProjectResult'];

export interface ProjectPlanFileRecord {
    created?: string;
    lastModified?: string;
    id: number;
    filename: string;
    uuid: string;
    totalPages?: number;
    projectId: number;
    __typename: 'ProjectPlanFile';
}

export interface ProjectPlanFileRecords {
    projectPlanFiles: {
        nodes: ProjectPlanFileRecord[];
    };
}

export interface ProjectPlanFileCondition {
    id?: number;
    projectId?: number;
    uuid?: string;
}

export const projectPlanFileFragment = frogmint`
    ${'ProjectPlanFile_projectPlanFile'} {
        created
        lastModified
        id
        filename
        uuid
        totalPages
        projectId
        __typename
    }
`;

export const ProjectPlanFilesQuery = projectPlanFileFragment.gql`
    query ProjectPlanFiles($condition: ProjectPlanFileCondition) {
        projectPlanFiles(condition: $condition) {
            nodes {
                ${projectPlanFileFragment}
            }
        }
    }
`;

export const [executeProjectPlanFilesQuery, useProjectPlanFilesQuery] = newQueries<
    ProjectPlanFileCondition,
    ProjectPlanFileRecords
>(ProjectPlanFilesQuery, { additionalTypenames });
