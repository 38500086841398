import styled from 'styled-components';

import { hideScrollbarCssObject } from '@/common/styles/hideScrollbar';

export const TableContainer = styled.div({
    justifySelf: 'stretch',

    transition: 'max-height 0.8s',
    padding: '0 1px',
    margin: '-1px',

    color: '#1B2235',

    ...hideScrollbarCssObject,

    overflowX: 'scroll',
    overflowY: 'hidden',
});

export interface BufferCell {
    column: number;
    row: number;
}

export const BufferCell = styled.div<BufferCell>(({ column, row }) => ({
    position: 'relative',
    backgroundColor: '#f6f8f8',
    height: '3rem',
    gridColumn: `${column + 1} / ${column + 1}`,
    gridRow: `${row + 2} / ${row + 2}`,
    '::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: -1,
        bottom: -1,
        left: -1,
        boxSizing: 'border-box',
        border: '1px solid #D7E1E5',
        borderTop: 'none',
    },
}));
