import styled from 'styled-components';

import { colorTypographybody } from '@/variables';

export const CardBody = styled.div<{ background: string }>`
    background-color: ${(props): string => props.background};
    position: relative;
    padding: 1.5625rem;
    margin-bottom: 2.25rem;
`;

export const CardTitle = styled.div`
    position: relative;
    margin-bottom: 0.625rem;
    color: ${colorTypographybody};
    font-weight: 600;
    font-size: 0.875rem;
`;

export const CardMessage = styled.div`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
`;

export const CardActionsGroup = styled.div`
    position: relative;
    margin-top: 0.625rem;
`;

export const CardActionButton = styled.button<{ background?: boolean }>`
    background-color: ${(props): string => (props.background ? '#94374A' : 'transparent')};
    text-decoration: ${(props): string => (!props.background ? 'underline' : 'none')};
    padding: ${(props): string => (props.background ? '0.625rem' : '0')};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.625rem;
    border-radius: 0.25rem;
    line-height: 0.625rem;
    letter-spacing: 0.08em;
    margin-top: 0.625rem;

    svg {
        vertical-align: middle;
        height: 0.625rem;
        width: 1.125rem;
        margin-left: 5px;
    }
`;
