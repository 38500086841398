import React, { FC, useEffect, useRef, useState } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TABS } from '.';
import { GeometryList } from './GeometryList';
import { ListItemClickHandler, ListItemNameBlurHandler } from './types';
import { getMarkupsByPlanPageId, getMarkupsDeep, getMarkupsInThisProject } from './utils';

import { IMarkupFragment } from '@/graphql';
import { IMarkupEntry } from '@/graphql/unions';

export interface GeometryListWithFiltersProps extends BoxProps {
    disabled?: boolean;
    height: number;
    markupEntries?: IMarkupEntry[];
    mode?: string;
    onAddMarkupToGroup: (markupID: string, markupGroupID: string) => Promise<void>;
    onCreateGroupFromMarkup?: (markup: IMarkupFragment) => void;
    onDeleteMarkupGroup: (markupGroupID: string) => Promise<void>;
    onListItemClick?: ListItemClickHandler;
    onListItemNameBlur?: ListItemNameBlurHandler;
    onRemoveMarkupsFromGroup: (markupGroupID: string, markupIDs: string[]) => Promise<void>;
    onShowInThisProjectMarkupsChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onShowInThisProjectGroupsChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onTabChange: (tab: TABS) => void;
    planPageID?: string;
    selectedMarkupEntryIds?: string[];
    showInThisProjectMarkups: boolean;
    showInThisProjectGroups: boolean;
    tab: TABS;
}

export const GeometryListWithFilters: FC<GeometryListWithFiltersProps> = ({
    disabled,
    height,
    markupEntries,
    mode,
    onAddMarkupToGroup,
    onCreateGroupFromMarkup,
    onDeleteMarkupGroup,
    onListItemClick,
    onListItemNameBlur,
    onRemoveMarkupsFromGroup,
    onShowInThisProjectMarkupsChange,
    onShowInThisProjectGroupsChange,
    onTabChange,
    planPageID,
    selectedMarkupEntryIds,
    showInThisProjectMarkups,
    showInThisProjectGroups,
    tab,
    ...props
}) => {
    const tabsRef = useRef<HTMLButtonElement>(null);
    const checkboxesRef = useRef<HTMLFormElement>(null);
    const [listHeight, setListHeight] = useState(height);

    const markups = getMarkupsDeep(markupEntries);
    const markupsOnPage = getMarkupsByPlanPageId(markups, planPageID);
    const markupsInThisProject = getMarkupsInThisProject(
        markupEntries,
        showInThisProjectMarkups,
        showInThisProjectGroups
    );

    const handleTabsChange = (event: React.SyntheticEvent, newValue: TABS) => {
        onTabChange(newValue);
    };

    useEffect(() => {
        const tabs = tabsRef.current;
        const checkboxes = checkboxesRef.current;
        let newHeight = height;
        if (tabs) {
            // Total vertical space so far minus the tabs for selecting "On this page" vs "In this project"
            newHeight -= tabs.clientHeight;
        }
        if (checkboxes && tab === TABS.IN_THIS_PROJECT) {
            // Total vertical space so far minus the checkboxes for filtering "In this project"
            newHeight -= checkboxes.clientHeight;
        }
        setListHeight(newHeight);
    }, [tabsRef.current, checkboxesRef.current, height, tab]);

    return (
        <Box {...props}>
            <Tabs ref={tabsRef} value={tab} onChange={handleTabsChange}>
                <Tab label="On this page" value={TABS.ON_THIS_PAGE} />
                <Tab label="In this project" value={TABS.IN_THIS_PROJECT} />
            </Tabs>
            {tab === TABS.IN_THIS_PROJECT && (
                <Box
                    ref={checkboxesRef}
                    sx={{
                        borderTop: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                        padding: '8px 16px',
                    }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showInThisProjectMarkups}
                                    onChange={onShowInThisProjectMarkupsChange}
                                    size="small"
                                />
                            }
                            label="Takeoffs"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showInThisProjectGroups}
                                    onChange={onShowInThisProjectGroupsChange}
                                    size="small"
                                />
                            }
                            label="Groups"
                        />
                    </FormGroup>
                </Box>
            )}
            <Box
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.hues.neutral[32]}`,
                }}
            >
                <GeometryList
                    disabled={disabled}
                    listItems={tab === TABS.ON_THIS_PAGE ? markupsOnPage : markupsInThisProject}
                    markupEntries={markupEntries}
                    mode={mode}
                    onAddMarkupToGroup={onAddMarkupToGroup}
                    onCreateGroupFromMarkup={onCreateGroupFromMarkup}
                    onDeleteMarkupGroup={onDeleteMarkupGroup}
                    onListItemClick={onListItemClick}
                    onListItemNameBlur={onListItemNameBlur}
                    onRemoveMarkupsFromGroup={onRemoveMarkupsFromGroup}
                    selectedMarkupEntryIds={selectedMarkupEntryIds}
                    height={listHeight}
                />
            </Box>
        </Box>
    );
};
