/*
 * ActionBar is the button that triggers estimator assignment.
 */
import React, { FC } from 'react';

import {
    EstimatorAssignmentAssignEstimator,
    EstimatorAssignmentAssignmentProfile,
    EstimatorAssignmentAssignmentText,
} from './styled';

import { DivProps } from '@/common/types';
import { mapBaseUserToIUserFragment } from '@/common/utils/mappers';
import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { Avatar } from '@/components/ui/icons/Avatar';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorTypographybody } from '@/variables';

import { ReactComponent as SortArrow } from '@/assets/icons/SortArrow.svg';

const COPY = {
    assign: 'Assign',
    toThisProject: 'to this project',
};

export type ActionBarProps = ProjectPanelComponentProps & DivProps;

export const ActionBar: FC<ActionBarProps> = ({ useProjectPanel, ...rest }) => {
    const { selectedEstimator } = useProjectPanel();

    if (selectedEstimator === undefined) {
        return <></>;
    }

    return (
        <EstimatorAssignmentAssignEstimator {...rest}>
            <EstimatorAssignmentAssignmentProfile>
                <Avatar diameter="1.5rem" user={mapBaseUserToIUserFragment(selectedEstimator)} />
                <EstimatorAssignmentAssignmentText>
                    {`${COPY.assign} ${selectedEstimator.firstName ?? ''} ` +
                        `${selectedEstimator.lastName ?? ''} ${COPY.toThisProject}`}
                </EstimatorAssignmentAssignmentText>
            </EstimatorAssignmentAssignmentProfile>
            <SvgIcon
                src={SortArrow}
                width="1rem"
                height="1rem"
                style={{
                    fill: colorTypographybody,
                    transform: 'rotate(270deg)',
                }}
            />
        </EstimatorAssignmentAssignEstimator>
    );
};
