import React, { FC } from 'react';

import { AddIcon, AddIconProps } from '@/components/ui/icons/AddIcon';

export const XIcon: FC<AddIconProps> = ({ style, ...rest }) => (
    <AddIcon
        style={{
            transformOrigin: 'center',
            transform: 'rotate(45deg)',
            ...style,
        }}
        {...rest}
    />
);
