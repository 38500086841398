import { GeometryType } from '@/common/types';
import { newGeometryHandler } from '@/common/utils/geometries/handler';

export const toCopy = newGeometryHandler({
    [GeometryType.AREA]: (area) => {
        return { ...area, coordinates: [...area.coordinates.map((r) => [...r])] };
    },
    [GeometryType.COUNT]: (count) => {
        return { ...count, coordinates: [...count.coordinates] };
    },
    [GeometryType.LINEAR]: (line) => {
        return { ...line, coordinates: [...line.coordinates] };
    },
});
