import React, { FC } from 'react';
import { SpinnerLoader } from '@/components/ui/loaders/SpinnerLoader';
import { InView } from 'react-intersection-observer';
import { useSeedEstimateTable } from '@/components/Estimate/hooks/useSeedEstimateTable';

interface TableSeederProps {
    onElementsAdded: (callback: () => Promise<unknown>) => void;
    pageSize: number;
    resetFunction?: () => void;
}

export const TableSeeder: FC<TableSeederProps> = ({ onElementsAdded, pageSize, resetFunction }) => {
    const { loading, hasMoreElements, fetchGroupAssemblies } = useSeedEstimateTable({
        pageSize,
        resetTable: resetFunction,
    });

    const onBottomReached = (inView: boolean) => {
        if (!inView) {
            return;
        }

        onElementsAdded(fetchGroupAssemblies);
    };

    return (
        <>
            {hasMoreElements && !loading && (
                <InView
                    onChange={onBottomReached}
                    threshold={0.8}
                    style={{
                        textAlign: 'center',
                        background: '#F6F8F8',
                        height: '6rem',
                    }}
                >
                    <SpinnerLoader />
                </InView>
            )}
        </>
    );
};
