import React, { FC } from 'react';
import Box from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { ListItemButtonWithCollapse } from '@/components/AssemblyPanel/AssemblyPanelContent/OnebuildLibrarySection/AssemblyList/ListItemButtonWithCollapse';
import { NoAssembliesWarning } from '../NoAssembliesWarning';
import { UNCATEGORIZED_TRADE_ID } from '@/common/assemblies';
import { useAssemblyPanel } from '@/components/AssemblyPanel/context';
import { useAssemblies } from '@/components/AssemblyPanel/hooks/useAssemblies';
import { ItemListItem } from './ItemListItem';
import { IProjectLightFragment } from '@/graphql';

const COPY = {
    emptyTitle: 'No items found in project',
    emptyMessage: 'This project does not have any items that can be added.',
    searchEmptyTitle: 'No matches found in project',
    searchEmptyMessage: "We couldn't find a match in this project for this search term",
};

interface ItemListProps extends ListProps {
    project: IProjectLightFragment;
}

export const ItemList: FC<ItemListProps> = ({ project, ...props }) => {
    const { search } = useAssemblyPanel();

    const {
        assemblies,
        assembliesGroupedByTrade,
        loading: assembliesLoading,
    } = useAssemblies({
        projectId: Number(project.id),
        pageSize: 100,
        search,
    });

    return (
        <List disablePadding {...props}>
            {(() => {
                if (assembliesLoading && !assemblies.length) {
                    return (
                        <ListItem dense>
                            <Skeleton
                                sx={{
                                    height: '16px',
                                    margin: '2px 0',
                                    width: '100%',
                                }}
                            />
                        </ListItem>
                    );
                }

                if (search && !assemblies.length) {
                    return (
                        <NoAssembliesWarning
                            copy={COPY.searchEmptyMessage}
                            header={COPY.searchEmptyTitle}
                            sx={{
                                padding: '12px 35px',
                            }}
                        />
                    );
                }

                if (!assemblies.length) {
                    return (
                        <NoAssembliesWarning
                            copy={COPY.emptyMessage}
                            header={COPY.emptyTitle}
                            sx={{
                                padding: '12px 35px',
                            }}
                        />
                    );
                }

                return (
                    <Box>
                        {assembliesGroupedByTrade.map(([tradeName, assemblies], index) => {
                            if (tradeName === UNCATEGORIZED_TRADE_ID) {
                                return (
                                    <Box>
                                        {assemblies.map((assembly) => {
                                            return (
                                                <ItemListItem
                                                    assembly={assembly}
                                                    key={index}
                                                    project={project}
                                                    sx={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                );
                            } else {
                                return (
                                    <ListItemButtonWithCollapse
                                        dense
                                        key={index}
                                        label={tradeName}
                                        sx={{
                                            paddingLeft: '35px',
                                        }}
                                    >
                                        {assemblies.map((assembly) => {
                                            return (
                                                <ItemListItem
                                                    assembly={assembly}
                                                    key={assembly.id}
                                                    project={project}
                                                    sx={{
                                                        paddingLeft: '58px',
                                                    }}
                                                />
                                            );
                                        })}
                                    </ListItemButtonWithCollapse>
                                );
                            }
                        })}
                    </Box>
                );
            })()}
        </List>
    );
};
