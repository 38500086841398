import { setsAreEqual } from '@/common/rxjs/equality';
import { Geometry, SelectedGeometries, ToolType } from '@/common/types';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { selectedMarkupIDs } from '@/common/apollo';

export const defaultToolType = ToolType.SELECTION;

export const toolObservable = new BehaviorSubject<ToolType>(defaultToolType);

// This observable contains all geometries located on the current plan page.
// A value of `null` indicates that the geometries have not need loaded, and
// should therefore not be sent to the backend as the new geometries within the
// active plan page.
export const geometriesObservable = new BehaviorSubject<Geometry[] | null>(null);

export interface MarkupWithinGroup {
    currentGeometryCount: number;
    toolType: ToolType;
    markupGroupID: string;
}
export const markupWithinGroupObservable = new BehaviorSubject<MarkupWithinGroup | undefined>(
    undefined
);

export const selectedGeometriesObservable = new BehaviorSubject<SelectedGeometries>({
    geometries: [],
    points: [],
});

export const selectedGeometryIdsObservable = selectedGeometriesObservable.pipe(
    map((selection) => {
        const selectedGeometryIds = new Set<string>();
        selection.geometries.forEach(({ uuid }) => selectedGeometryIds.add(uuid));
        // Syncing takeoff selections with the graphQL cache for the Markup queries, to keep the side panel synced.
        if (selectedGeometryIds.size === 1) {
            const currentlySelectedMarkupIDs = selectedMarkupIDs();
            const newSelectionSingleGeometry = selectedGeometryIds.values().next();
            if (!currentlySelectedMarkupIDs.includes(newSelectionSingleGeometry.value)) {
                selectedMarkupIDs([newSelectionSingleGeometry.value]);
            }
        } else if (selectedMarkupIDs().length !== 0) {
            selectedMarkupIDs([]);
        }
        return selectedGeometryIds;
    }),
    distinctUntilChanged(setsAreEqual)
);

export const selectedPointIDsObservable = selectedGeometriesObservable.pipe(
    map((selection) => {
        const selectedPointIDs = new Set<string>();
        selection.points.forEach(({ id }) => selectedPointIDs.add(id));
        return selectedPointIDs;
    }),
    distinctUntilChanged(setsAreEqual)
);

export const selectedIDsObservable = combineLatest([
    selectedGeometryIdsObservable,
    selectedPointIDsObservable,
]).pipe(
    map(([selectedGeometryIds, selectedPointIDs]) => ({
        geometryIDs: selectedGeometryIds,
        pointIDs: selectedPointIDs,
    }))
);

export const geometryIDsObservable = geometriesObservable.pipe(
    map((geometries) => {
        const geometryIDs = new Set<string>();
        geometries?.forEach(({ uuid }) => geometryIDs.add(uuid));
        return geometryIDs;
    }),
    distinctUntilChanged(setsAreEqual)
);
