import React, { FC, useEffect, useState } from 'react';

import {
    ActivityDot,
    ActivityIndicatorContainer,
    ActivityIndicatorIconContainer,
    ActivityIndicatorTooltip,
    ActivityIndicatorTooltipPoint,
    ActivityIndicatorTooltipPointShadow,
} from './styled';
import { EventIdsByStatus, toggleSetInclusion } from './utils';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorTypographySection } from '@/variables';

import { ReactComponent as Checkmark } from '@/assets/icons/checkmark-light.svg';

const COPY = {
    keepAsUnseen: 'Keep as unseen',
    markAsSeen: 'Mark as seen',
    markAsUnseen: 'Mark as unseen',
};

export interface ActivityIndicatorProps extends ProjectPanelComponentProps {
    eventIds: EventIdsByStatus;
}

export const ActivityIndicator: FC<ActivityIndicatorProps> = ({ eventIds, useProjectPanel }) => {
    const { setEntryIdsMarkedSeen, setEntryIdsMarkedUnseen } = useProjectPanel();

    const [markedUnseen, setMarkedUnseen] = useState<boolean | null>(null);
    const [seen, setSeen] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => setSeen(eventIds.unread.length === 0), [eventIds]);

    const getTooltipText = (): string => {
        if (markedUnseen) {
            return COPY.markAsSeen;
        }
        if (seen) {
            return COPY.markAsUnseen;
        }
        return COPY.keepAsUnseen;
    };

    const handleActivityIndicatorClick = (): void => {
        const newMarkedUnseen = !(markedUnseen === true);
        eventIds.read.forEach((id) =>
            setEntryIdsMarkedUnseen((unseen) => toggleSetInclusion(unseen, id, newMarkedUnseen))
        );
        eventIds.unread.forEach((id) =>
            setEntryIdsMarkedSeen((seen) => toggleSetInclusion(seen, id, !newMarkedUnseen))
        );
        setMarkedUnseen(newMarkedUnseen);
    };

    // Render checkmark if
    //   a) Event has been seen and is not marked unseen
    //   b) Event has not been seen, was marked unseen, and was then re-marked as seen
    const renderCheckmark = (seen && !markedUnseen) || (!seen && markedUnseen === false);

    return (
        <ActivityIndicatorContainer>
            {showTooltip && (
                <ActivityIndicatorTooltip>
                    {getTooltipText()}
                    <ActivityIndicatorTooltipPoint />
                    <ActivityIndicatorTooltipPointShadow />
                </ActivityIndicatorTooltip>
            )}
            <ActivityIndicatorIconContainer
                onClick={handleActivityIndicatorClick}
                onMouseEnter={(): void => setShowTooltip(true)}
                onMouseLeave={(): void => setShowTooltip(false)}
            >
                {renderCheckmark ? (
                    <SvgIcon src={Checkmark} fill={colorTypographySection} noInherit={true} />
                ) : (
                    <ActivityDot markedUnseen={markedUnseen === true} />
                )}
            </ActivityIndicatorIconContainer>
        </ActivityIndicatorContainer>
    );
};
