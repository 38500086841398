/*
 * deleteUser.ts provides a GraphQL mutation used to delete users by id
 */
import gql from 'graphql-tag';
import { useMutation, UseMutationResponse } from 'urql';

export interface DeleteUserArgs {
    id: string;
}

export const DeleteUserMutation = gql`
    mutation DeleteUser($id: String!) {
        deleteUser(id: $id)
    }
`;

export function useDeleteUserMutation(): UseMutationResponse<never, DeleteUserArgs> {
    return useMutation<never, DeleteUserArgs>(DeleteUserMutation);
}
