import { styled as styledByMui } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';

interface SummaryStackItemProps {
    styled?: {
        isTitle?: boolean;
    };
}
export const SummaryStackItem = styledByMui(Typography)<SummaryStackItemProps>((options) => ({
    width: '80px',
    textAlign: 'right',
    color: options.styled?.isTitle
        ? options.theme.palette.hues.neutral[78]
        : options.theme.palette.hues.neutral[100],

    ...breakpointsCSSObject.medium({
        textAlign: 'left',
        flex: 1,
        width: 'auto',
        marginBottom: '0',
    }),
}));

export const SummaryLineItem = styledByMui('div')((options) => ({
    display: 'flex',
    flexDirection: 'row',

    ...breakpointsCSSObject.medium({
        flexDirection: 'column',
        flex: 1,

        paddingTop: '8px',
        borderTop: `1px solid ${options.theme.palette.hues.neutral[32]}`,
    }),
}));

export const SummaryLineItems = styledByMui('div')({
    display: 'flex',
    flexDirection: 'column',

    ...breakpointsCSSObject.medium({
        flexDirection: 'row',
        marginTop: '32px',

        [`& > ${SummaryLineItem}`]: {
            marginRight: '16px',

            '&:last-of-type': {
                marginRight: '0px',
            },
        },
    }),
});

export const SummaryContainer = styledByMui('div')({
    marginLeft: 'auto',
    marginTop: -24,

    ...breakpointsCSSObject.medium({
        marginLeft: 'initial',
    }),
});
