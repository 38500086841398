import clsx from 'clsx';
import React, { ChangeEvent, FC } from 'react';

import { RadioInputWrapper, RadioInput, RadioLabel, RadioButtonCircle } from './styled';

export type RadioButtonProps = {
    className?: string;
    label?: string | JSX.Element;
    value?: string | number | readonly string[] | undefined;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange: (value: string) => void;
};

export const RadioButton: FC<RadioButtonProps> = ({
    className,
    label,
    value,
    name,
    checked,
    disabled,
    readOnly,
    onChange,
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => onChange(e.target.value);

    const inputComponent = (
        <RadioInputWrapper>
            <RadioInput
                type="radio"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                readOnly={readOnly}
                onChange={handleChange}
            />
            <RadioButtonCircle />
        </RadioInputWrapper>
    );

    return label ? (
        <RadioLabel
            className={clsx({
                className,
                checked,
            })}
        >
            {inputComponent}
            {label}
        </RadioLabel>
    ) : (
        inputComponent
    );
};
