/*
 * FileInProgress displays the file that is being uploaded.
 */
import React, { FC, useEffect, useState } from 'react';

import {
    FileMainText,
    FilePlaceholderThumbStatus,
    FileSubText,
    FileTextContainer,
    FilesFileContainer,
    FilesFileInfoContainer,
    FilesFilePlaceholderThumb,
    ProgressBarContainer,
    ProgressBarProgress,
} from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { DotsLoader } from '@/components/ui/loaders/DotsLoader';
import { useStorage } from '@/contexts/Storage';
import { colorProjectPanelFileError } from '@/variables';

import { ReactComponent as Cross } from '@/assets/icons/cross.svg';

const COPY = {
    uploadFailed: 'UPLOAD FAILED',
};

const CloseCross: FC = () => (
    <SvgIcon
        src={Cross}
        width="1rem"
        height="1rem"
        style={{
            cursor: 'pointer',
            fill: colorProjectPanelFileError,
        }}
    />
);

export const FileInProgress: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const [fileError, setFileError] = useState<string | null>(null);
    const [percentDone, setPercentDone] = useState(0);

    const { currentUploads } = useStorage();
    const { fileInProgress, setFileInProgress } = useProjectPanel();

    useEffect(() => {
        const upload = currentUploads.find((u) => u.body.name === fileInProgress?.filename);
        const fileError = upload?.error;
        if (fileError !== undefined) {
            setFileError('File is corrupted');
            return;
        }
        const newPercent = upload?.progressPercent;
        if (newPercent !== undefined) {
            setPercentDone(newPercent);
        }
        if (newPercent === 100) {
            setFileInProgress(undefined);
        }
    }, [currentUploads]);

    return (
        <FilesFileContainer>
            <FilesFileInfoContainer>
                <FilesFilePlaceholderThumb
                    status={
                        fileError === null
                            ? FilePlaceholderThumbStatus.Loading
                            : FilePlaceholderThumbStatus.Error
                    }
                >
                    {fileError === null ? <DotsLoader /> : <CloseCross />}
                </FilesFilePlaceholderThumb>
                <FileTextContainer>
                    <FileMainText>{fileInProgress?.filename}</FileMainText>
                    <FileSubText error={fileError !== null}>
                        {fileError === null ? (
                            <ProgressBarContainer>
                                <ProgressBarProgress percentDone={percentDone} />
                            </ProgressBarContainer>
                        ) : (
                            `${COPY.uploadFailed}: ${fileError}`
                        )}
                    </FileSubText>
                </FileTextContainer>
            </FilesFileInfoContainer>
        </FilesFileContainer>
    );
};
