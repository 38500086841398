import { COPY } from './constants';
import { Field, FieldDictionary } from './types';
import { Setter } from '@/common/types';
import { AddNotification } from '@/contexts/Notifications';
import { FetchResult } from '@apollo/client';
import to from 'await-to-js';

export const handleRequest = async (
    request: Promise<FetchResult<unknown>>,
    addNotification: AddNotification
): Promise<void> => {
    const [error] = await to(request);
    if (error) {
        addNotification(
            { title: 'Error', content: error.message.toString() ?? COPY.apiCallError },
            'error'
        );
        return;
    }
    addNotification({ title: 'Success', content: COPY.apiCallSuccess }, 'success');
};

export const changeDictionaryField = <
    T extends FieldDictionary,
    K extends keyof T,
    F extends keyof T[K],
    V extends T[K][F]
>(
    currentFields: T,
    setCurrentFields: Setter<T>,
    key: K,
    field: F,
    value: V
) => {
    currentFields[key] = {
        ...currentFields[key],
        [field]: value,
    };
    setCurrentFields({ ...currentFields });
};

export const isFormValid = (currentFields: FieldDictionary): boolean =>
    Object.entries(currentFields).every(([_, field]: [key: string, field: Field]) => {
        return field.value && field.value !== '';
    });
