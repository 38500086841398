import {
    IEstimatorStatus,
    IUserBusiness,
    IUserCompanyRole,
    IUserFragment,
    IUserRole,
    IUserService,
} from '@/graphql';
import { BaseUserRecord } from '@/queries/baseUsers';

export const mapBaseUserToIUserFragment = (baseUser: BaseUserRecord): IUserFragment => {
    const mappedUser: IUserFragment = {
        ...baseUser,
        id: baseUser.id.toString(),
        authID: baseUser.authId,
        teamID: baseUser.teamId?.toString() || null,
        createdAt: baseUser.created,
        email: baseUser.email || '',
        firstName: baseUser.firstName || '',
        lastName: baseUser.lastName || '',
        phone: baseUser.phone || '',
        companyRole: baseUser.companyRole as unknown as IUserCompanyRole,
        businessType: baseUser.businessType as unknown as IUserBusiness,
        serviceType: baseUser.serviceType as unknown as IUserService,
        team: null,
        status: IEstimatorStatus.Available,
        timezone: '',
        scheduleID: null,
        schedule: null,
        preferredClients: null,
        tradeRatings: null,
        roles: [],
        address: '',
        state: '',
        city: '',
        postalCode: '',
        country: '',
        logoLink: '',
        bidValue: '',
    };
    const roles: Array<IUserRole> = [];

    for (const role of baseUser.roles ?? []) {
        switch (role.toUpperCase()) {
            case IUserRole.Builder:
                roles.push(IUserRole.Builder);
                break;
            case IUserRole.Estimator:
                roles.push(IUserRole.Estimator);
                break;
            case IUserRole.Admin:
                roles.push(IUserRole.Admin);
                break;
            case IUserRole.Superadmin:
                roles.push(IUserRole.Superadmin);
                break;
            case IUserRole.Impersonator:
                roles.push(IUserRole.Impersonator);
                break;
        }
    }
    mappedUser.roles = roles;
    return mappedUser;
};
