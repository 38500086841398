import { ActionBlock, ActionPayloadMap, Action } from './types';

export const actionIs = <T extends Action>(
    actionBlock: ActionBlock<Action>,
    type: T
): actionBlock is ActionBlock<T> => actionBlock.type === type;

export const makeAction = <T extends Action>(
    type: T,
    payload: ActionPayloadMap[T]
): ActionBlock<T> => ({ type, payload });
