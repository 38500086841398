import styled, { css } from 'styled-components';

import {
    ElementSearch,
    InnerContainer,
    OptionsMenuContainer,
    OptionsMenuOptionContainer,
    OuterContainer,
} from '@/components/Estimate/ElementSearch';

export const ActionButtonsContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '0 1.5rem',
});

export const ActionButton = styled.button({
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    height: '2.25rem',
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 600,
    fontSize: '1rem',
    padding: '0 0.5rem',
    background: 'transparent',
    borderRadius: '0.25rem',
    color: '#166FF3',
    zIndex: 2,
    '&:hover': {
        background: 'rgba(48, 122, 230, 0.07)',
    },
    '::before': {
        content: "'+'",
        marginRight: '0.25rem',
    },
});

export const ActionTextInputWrapper = styled.div({
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: 2,
});

export const ActionTextInput = styled.input({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: '0 0.5rem',
    margin: 0,
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#000000',
});

const inputCss = css`
    font-family: 'proxima-nova', sans-serif;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding-left: 8px;
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    color: white;
    font-weight: bold;
    background-color: #344371;
    border: none;
    border-radius: 2px;
    &::placeholder {
        opacity: 0.5;
        color: #e3e3f1;
    }
`;

const elementInputInnerContainerGrid = `
" .         input  .       plus  .       " 2rem
" opts      opts   opts    opts  opts    " auto
" load      load   load    load  load    " auto
/ 0.25rem   auto   0.25rem  auto  0.5rem `;

const elementInputOptionsMenuOptionContainerGrid = `
" .         opt  .       " 2rem
/ 0.5rem    auto 0.75rem `;

const GenericElementInputWrapper = styled.div`
    margin-bottom: 8px;
    & ${OuterContainer} {
        width: auto;
        box-sizing: border-box;
        & > ${InnerContainer} {
            ${inputCss}
            padding-left: 0;
            height: auto;
            grid-template: ${elementInputInnerContainerGrid};
            position: relative;
            left: unset;
            top: unset;
            right: unset;
            box-sizing: border-box;
            border: 2px solid #344371;
            &:focus-within {
                border-color: #64ffda;
            }
            & > .icon {
                margin-left: auto;
            }
            & ${OptionsMenuContainer} {
                position: absolute;
                background-color: #131e47;
                z-index: 2;
                width: calc(100% + 8px);
                top: 2px;
                left: -4px;
                padding-top: 4px;
                padding-bottom: 8px;
                background-color: #fff;
                & > ${OptionsMenuOptionContainer} {
                    grid-template: ${elementInputOptionsMenuOptionContainerGrid};
                    transition: none;
                    color: ${(props): string => props.theme.v0.colorTypographyDark};
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    line-height: 32px;
                    &:hover {
                        color: #fff;
                        background-color: #334ad7;
                    }
                }
            }
        }
    }
`;

const StyledElementInputWrapper = styled(GenericElementInputWrapper)`
    margin-bottom: 0;

    & ${OuterContainer} {
        & ${InnerContainer} {
            background-color: white;
            border: none;
            box-shadow: none;

            & > input {
                font-size: 1rem;
                font-weight: normal;
                background-color: white;
                border: none;
                color: black;
                &::placeholder {
                    color: rgb(117, 117, 117);
                    opacity: 1;
                }
            }
        }
    }
`;

export const ElementInput = styled(ElementSearch)`
    ${inputCss}
    background: #344371;
    border: 2px solid #344371;
    padding-left: 0;
    &:focus-visible {
        outline: none;
    }
`;

export const ConventionalInputWrapper = styled.div({
    zIndex: 15,
    position: 'relative',
    height: '100%',
    width: '100%',
});

export const ConventionalInput = styled.input({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: 0,
    margin: 0,
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#000000',
});

export { StyledElementInputWrapper as ElementInputWrapper };
