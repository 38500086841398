/* eslint-disable @typescript-eslint/indent */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';

import { breakpointSmall, colorTertiary, colorTypographyDark } from '@/variables';

const variables = {
    modalBackground: '#FFFFFF',
    disclaimer: '#767aee',
};

interface IsMainProps {
    isMain: boolean;
}

// General wrapper divs
export const HeaderWrapper = styled.div<IsMainProps>`
    background: #fff;

    display: flex;
    flex-direction: column;

    ${(props): FlattenSimpleInterpolation | string =>
        !props.isMain
            ? css`
                  margin-top: auto;

                  & ${Header} {
                      font-size: 28px;
                      line-height: 32px;
                  }
              `
            : ''}

    ${breakpoints.small`
        ${(props: IsMainProps): FlattenSimpleInterpolation | string =>
            !props.isMain
                ? css`
                      margin-top: 0;

                      & ${Header} {
                          font-size: 26px;
                      }
                  `
                : ''}
    `}
`;
export const BodyWrapper = styled.div<IsMainProps>`
    background: #fff;

    ${(props): FlattenSimpleInterpolation | string =>
        !props.isMain
            ? css`
                  margin-bottom: auto;

                  & ${Body} {
                      font-size: 16px;
                      line-height: 24px;
                  }
              `
            : ''}
`;

export const IllustrationWrapper = styled.div<IsMainProps>`
    padding: ${(props): string => (props.isMain ? '0' : '0 32px')};

    ${breakpoints.small`
        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        padding: 22px 32px 30px 32px;

        height: 100%;

        ${(props: IsMainProps): FlattenSimpleInterpolation | string =>
            props.isMain
                ? css`
                      padding: 0px;
                  `
                : ''}
    `}
`;

/////////////////////
// Content section //
/////////////////////
export const Header = styled.h2`
    color: inherit;
    font-family: 'poppins', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
`;

export const Body = styled.div`
    color: inherit;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    opacity: 0.7;
`;

export const Disclaimer = styled.span`
    display: block;
    color: ${variables.disclaimer};
`;
export const Warning = styled(Disclaimer)`
    font-weight: 600;
`;

////////////////////
// Submit section //
////////////////////
interface SubmitButtonProps {
    isDone?: boolean;
}
export const SubmitButton = styled.div<SubmitButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${(props): string =>
        props.isDone
            ? 'linear-gradient(180deg, #4AF7ED 0%, #3BD4F6 100%)'
            : 'linear-gradient(180deg, #4A8BF7 0%, #3B63F6 100%)'};
    border-radius: 4px;
    width: 100%;
    height: 44px;

    color: #fff;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;

    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const PageNumber = styled.div`
    display: none;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 10px;
    align-items: center;
    color: ${colorTertiary};
`;

export const SubmitContainer = styled.div<IsMainProps>`
    background: #fff;
    display: flex;
    width: 100%;

    ${(props): FlattenSimpleInterpolation | string =>
        !props.isMain
            ? css`
                  margin-left: auto;

                  & ${SubmitButton} {
                      width: 96px;
                      margin-left: auto;
                  }

                  & ${PageNumber} {
                      display: flex;
                  }
              `
            : ''}

    ${breakpoints.small`
        box-sizing: border-box;
        padding: 8px 8px 8px 19px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        ${(props: IsMainProps): FlattenSimpleInterpolation | string =>
            props.isMain
                ? css`
                      padding: 8px;
                  `
                : ''}
    `}
`;

////////////////////////
// Wrapper containers //
////////////////////////
export const MobileImage = styled.img`
    width: 100%;

    display: none;

    ${breakpoints.small`
        display: block;
    `}
`;

export const DesktopImage = styled.img`
    width: 100%;

    display: none;

    @media (min-width: ${breakpointSmall}) {
        display: block;
    }
`;

export const Overlay = styled.div`
    cursor: default;

    z-index: 6000;

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;

    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(114.87deg, rgba(0, 0, 0, 0.7) 5.9%, rgba(52, 98, 228, 0.7) 99.03%);
`;

interface ContainerProps extends IsMainProps {
    isMounted: boolean;
}
export const Container = styled.div<ContainerProps>`
    cursor: default;
    color: ${colorTypographyDark};
    box-sizing: border-box;
    background: ${variables.modalBackground};
    box-shadow: 0px 14px 64px rgba(0, 0, 0, 0.55);
    border-radius: 16px;
    overflow: hidden;

    display: flex;

    width: 840px;
    height: ${(props): number => (props.isMain ? 593 : 649)}px;
    transform: ${(props): string => (props.isMounted ? 'initial' : 'translateY(100vh)')};

    transition: width 0.4s ease, height 0.4s ease, transform 0.2s ease;

    & ${Header} {
        margin: ${(props): string => (props.isMain ? '0 0 31px 0' : '0 0 20px 0')};
    }

    & ${Body} {
        margin: 0;
    }

    & ${SubmitContainer} {
        margin-top: auto;
    }

    ${breakpoints.small`
        display: block;
        height: calc(100% - 36px);
        margin: 18px;

        & ${Header} {
            margin: ${(props: IsMainProps): string => (props.isMain ? '0 0 17px 0' : '0 0 20px 0')};
        }
    `}
`;

interface ContentWrapperProps {
    isLeft?: boolean;
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    position: relative;
    flex: 1;

    ${breakpoints.small`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: ${(props: ContentWrapperProps): string => (props.isLeft ? '30%' : '70%')};
    `}
`;

interface ContentProps {
    isActive?: boolean;
}

const contentCss = css<ContentProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    & ${SubmitContainer} {
        opacity: ${(props): number => (props.isActive ? 1 : 0)};
    }

    & ${BodyWrapper}, & ${HeaderWrapper}, & ${IllustrationWrapper} > img {
        transition: opacity 0.4s;
        opacity: ${(props): number => (props.isActive ? 1 : 0)};
    }
`;

export const LeftContent = styled.div<ContentProps>`
    background: linear-gradient(326.79deg, #053ddd 24.66%, #648cff 99.44%), #4783f7;

    display: flex;
    justify-content: center;
    align-items: center;

    ${contentCss}
`;

interface RightContentProps extends IsMainProps, ContentProps {}
export const RightContent = styled.div<RightContentProps>`
    padding: ${(props): string => (props.isMain ? '63px 68px 75px 78px' : '63px 19px 24px 54px')};
    display: flex;
    flex-direction: column;

    ${breakpoints.small`
        padding: 33px 0 0 0;

        & > ${BodyWrapper},
        & > ${HeaderWrapper} {
            padding: 0 25px 0 19px;
        }

        ${(props: IsMainProps): FlattenSimpleInterpolation | string =>
            props.isMain
                ? css`
                      padding: 29px 0 0 0;

                      & > ${BodyWrapper}, & > ${HeaderWrapper} {
                          padding: 0 53px 0 43px;
                      }
                  `
                : ''}
    `}

    ${contentCss}
`;
