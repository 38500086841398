import { COPY, InputProps } from '../constants';
import { Field, FieldDictionary, FormProps } from '../types';
import { changeDictionaryField, handleRequest, isFormValid } from '../utils';
import { useNotifications } from '@/contexts/Notifications';
import { IConnectTrialToSubscriptionInput, useConnectTrialToSubscriptionMutation } from '@/graphql';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { FC } from 'react';

export interface ConnectToSubscriptionFieldsDictionary extends FieldDictionary {
    userID: Field & {
        value: number | '';
    };
    trialTeamID: Field & {
        value: number | '';
    };
    targetTeamID: Field & {
        value: number | '';
    };
}

const Fields: ConnectToSubscriptionFieldsDictionary = {
    userID: {
        value: 0,
        touched: false,
    },
    trialTeamID: {
        value: 0,
        touched: false,
    },
    targetTeamID: {
        value: 0,
        touched: false,
    },
};

export const ConnectToSubscription: FC<FormProps> = ({ onSubmit }) => {
    const [currentFields, setCurrentFields] =
        useState<ConnectToSubscriptionFieldsDictionary>(Fields);
    const [open, setOpen] = useState(false);

    const { addNotification } = useNotifications();

    const [connectTrialToSubscriptionMutation] = useConnectTrialToSubscriptionMutation();

    const handleSubmit = async () => {
        setOpen(true);
        await handleRequest(
            connectTrialToSubscriptionMutation({
                variables: { input: mapDictToIConnectTrialToSubscriptionInput(currentFields) },
            }),
            addNotification
        );
        // return inputs to initial state
        setCurrentFields(Fields);
        setOpen(false);
        onSubmit();
    };

    // reformat field dictionary to object suitable for backend
    const mapDictToIConnectTrialToSubscriptionInput = (
        dict: ConnectToSubscriptionFieldsDictionary
    ): IConnectTrialToSubscriptionInput => {
        return {
            userID: dict['userID'].value.toString(),
            trialTeamID: dict['trialTeamID'].value.toString(),
            targetTeamID: dict['targetTeamID'].value.toString(),
        };
    };

    const parseInteger = (value: string): number | '' =>
        parseInt(value) === 0 ? 0 : parseInt(value) || '';

    return (
        <>
            <Backdrop open={open}>
                <CircularProgress color="primary" />
            </Backdrop>
            <DialogContent>
                <TextField
                    {...InputProps}
                    required
                    type="number"
                    inputProps={{ min: 0 }}
                    value={currentFields['userID'].value}
                    label={COPY.trialUserId}
                    error={currentFields['userID'].touched && currentFields['userID'].value === ''}
                    helperText={
                        currentFields['userID'].touched &&
                        currentFields['userID'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'userID',
                            'value',
                            parseInteger(e.target.value)
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'userID',
                            'touched',
                            true
                        );
                    }}
                />
                <TextField
                    {...InputProps}
                    required
                    type="number"
                    inputProps={{ min: 0 }}
                    value={currentFields['trialTeamID'].value}
                    label={COPY.oldTrialTeamId}
                    error={
                        currentFields['trialTeamID'].touched &&
                        currentFields['trialTeamID'].value === ''
                    }
                    helperText={
                        currentFields['trialTeamID'].touched &&
                        currentFields['trialTeamID'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'trialTeamID',
                            'value',
                            parseInteger(e.target.value)
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'trialTeamID',
                            'touched',
                            true
                        );
                    }}
                />
                <TextField
                    {...InputProps}
                    required
                    type="number"
                    inputProps={{ min: 0 }}
                    value={currentFields['targetTeamID'].value}
                    label={COPY.newTargetTeamId}
                    error={
                        currentFields['targetTeamID'].touched &&
                        currentFields['targetTeamID'].value === ''
                    }
                    helperText={
                        currentFields['targetTeamID'].touched &&
                        currentFields['targetTeamID'].value === '' &&
                        COPY.required
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'targetTeamID',
                            'value',
                            parseInteger(e.target.value)
                        );
                    }}
                    onBlur={() => {
                        changeDictionaryField(
                            currentFields,
                            setCurrentFields,
                            'targetTeamID',
                            'touched',
                            true
                        );
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!isFormValid(currentFields)}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {COPY.submit}
                </Button>
            </DialogActions>
        </>
    );
};
