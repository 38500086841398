/*
 * balance.ts provides GraphQL queries used to fetch records from the
 * `balance` table.
 */
import { frogmint } from '../frog';

export interface FeatureRecord {
    id: number;
    name: string;
    description: string;
}

export const featureFragment = frogmint`
    ${'Feature_feature'} {
       id
       name
       description
    }
`;
