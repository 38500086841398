import React, { FC } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const GeometrySectionErrorState: FC<BoxProps> = (props) => {
    return (
        <Box {...props} sx={{ padding: '8px', ...props.sx }}>
            <Typography sx={{ color: 'error.main' }} align="center" variant="body2">
                Something went wrong while loading takeoffs
            </Typography>
        </Box>
    );
};
