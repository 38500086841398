import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { ContextActionsContainer, ContextMenu, ContextMenuOption } from './styled';
import { ProjectRecord } from '@/queries/projects';

import { Dots } from '@/components/projects/ProjectDetails/ProjectInfo/ContextActions/Dots';
import { DeleteProjectModal } from '@/components/projects/DeleteProjectModal';

const COPY = {
    edit: 'Edit details',
    delete: 'Delete',
};

type ContextActionsProps = {
    project: ProjectRecord;
};

export const ContextActions: FC<ContextActionsProps> = ({ project }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const handleOpenMenu = (): void => setIsOpen(true);
    const handleCloseMenu = (): void => setIsOpen(false);
    const onEditClick = (): void => {
        window.location.href = `/projects/${project.uuid}/edit`;
    };
    const onDeleteClick = (): void => {
        handleCloseMenu();
        setDeleteModalOpen(true);
    };

    return (
        <ContextActionsContainer>
            <Dots onClick={handleOpenMenu} />
            {isOpen && (
                <OutsideClickHandler onOutsideClick={handleCloseMenu}>
                    <ContextMenu>
                        <ContextMenuOption onClick={onEditClick}>{COPY.edit}</ContextMenuOption>
                        <ContextMenuOption onClick={onDeleteClick}>{COPY.delete}</ContextMenuOption>
                    </ContextMenu>
                </OutsideClickHandler>
            )}
            <DeleteProjectModal
                open={deleteModalOpen}
                project={project}
                close={(): void => setDeleteModalOpen(false)}
            />
        </ContextActionsContainer>
    );
};
