import { frogmint } from '../frog';
import { balanceFragment } from './balance';
import { baseUserAuth0LessFragment, baseUserFragment } from './baseUsers';
import { featureFragment } from './feature';
import gql from 'graphql-tag';

export const teamFragment = frogmint`
    ${'Team_team'} {
        created
        lastModified
        id
        name
        leadUserId
        leadUser {
            ${baseUserFragment}
        }
        balance {
            ${balanceFragment}
        }
        teamFeatures {
            nodes {
                feature {
                    ${featureFragment}
                }
            }
        }
        teamOnboarding {
            accountType
            welcomed
        }
        projectInfoId
        teamProjectInfos {
            edges{
                node {
                    id
                    estimateTypes
                    formattingPreferences
                    inclusionsExclusions
                    preferredMarkupOverhead
                    preferredMarkupProfit
                    preferredMarkupMaterial
                    preferredMarkupLabor
                    averagePriceTotal
                    averagePricePerSquareFoot
                    requestsNotes
                    hourlyLaborRates {
                        edges {
                            node {
                                priceUsdCents
                                type {
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const teamAuth0LessFragment = frogmint`
    ${'Team_team'} {
        created
        lastModified
        id
        name
        leadUserId
        leadUser {
            ${baseUserAuth0LessFragment}
        }
        balance {
            ${balanceFragment}
        }
        teamFeatures {
            nodes {
                feature {
                    ${featureFragment}
                }
            }
        }
        teamOnboarding {
            accountType
            welcomed
        }
        teamProjectInfos {
            edges{
                node {
                    id
                    estimateTypes
                    formattingPreferences
                    inclusionsExclusions
                    preferredMarkupOverhead
                    preferredMarkupProfit
                    preferredMarkupMaterial
                    preferredMarkupLabor
                    averagePriceTotal
                    averagePricePerSquareFoot
                    requestsNotes
                    hourlyLaborRates {
                        edges {
                            node {
                                priceUsdCents
                                type {
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const TeamsQuery = gql`
    {
        teams {
            nodes {
                id
                name
                balance {
                    usdCents
                }
                leadUser {
                    id
                    firstName
                    lastName
                }
                baseUsers {
                    nodes {
                        id
                        firstName
                        lastName
                    }
                }
                teamFeatures {
                    nodes {
                        feature {
                            id
                            name
                            description
                        }
                    }
                }
                teamOnboarding {
                    accountType
                    welcomed
                }
                teamProjectInfos {
                    edges {
                        node {
                            id
                            estimateTypes
                            formattingPreferences
                            inclusionsExclusions
                            preferredMarkupOverhead
                            preferredMarkupProfit
                            preferredMarkupMaterial
                            preferredMarkupLabor
                            averagePriceTotal
                            averagePricePerSquareFoot
                            requestsNotes
                            hourlyLaborRates {
                                edges {
                                    node {
                                        priceUsdCents
                                        type {
                                            description
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
