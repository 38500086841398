import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { DomEvent, LatLng, LeafletMouseEvent } from 'leaflet';
import isNil from 'lodash/isNil';
import { FeatureGroup, Polyline } from 'react-leaflet';

import { Vertex } from '../common/Vertex';

import { fromCoordinate, fromLeaflet } from '@/common/convert/coordinateData';
import { toLeaflet } from '@/common/convert/geometry';
import { useDisableEventsOnSVG } from '@/common/hooks/useDisableEventsOnSVG';
import { useHandleKeyPress } from '@/common/hooks/useHandleKeyPress';
import { Geometry, GeometryType, Point, ToolType } from '@/common/types';
import { latLngsIs } from '@/common/utils/geometries/leaflet';
import { useNilState } from '@/common/utils/helpers';
import { TakeoffComponentProps } from '@/components/takeoff/context';
import { useTool } from '@/components/takeoff/hooks/useTool';
import {
    currentlyAddedCoordinatesObservable,
    isAddingCoordinatesObservable,
    styleObservable,
    subscribeToIsAddingCoordinates,
} from '@/components/takeoff/observables/helpers';

export interface EditablePolylineProps extends TakeoffComponentProps {
    maxVertexCount?: number;
    opacity?: number;
    geometry: Geometry<GeometryType.LINEAR>;
    commit?: (geometry: Geometry<GeometryType.LINEAR>) => void;
    onFinish?: () => void;
    interactive?: boolean;
    editable: boolean;
    activeVertices: string[];
}

export const EditablePolyline: FC<EditablePolylineProps> = ({
    maxVertexCount,
    opacity,
    geometry,
    editable,
    interactive,
    commit,
    onFinish,
    activeVertices,
    useTakeoff,
}: EditablePolylineProps) => {
    const {
        mapRef,
        activateVertices,
        checkIsRendererSVG,
        deactivateVertices,
        deactivateOtherVertices,
        deselect,
        mouseEvents,
        pointerEventsDisabled,
        setTool,
    } = useTakeoff();
    const tool = useTool();

    const { commit: setGeometry } = useTakeoff();

    const style = styleObservable.value;

    const [isAddingCoordinates, setIsAddingCoordinates] = useState<boolean>();

    useEffect(() => {
        const subscription = subscribeToIsAddingCoordinates(setIsAddingCoordinates);
        return (): void => subscription.unsubscribe();
    }, []);

    const { color: currentColor, lineType: currentLineType, weight: currentWeight } = style;

    /**
     * This is a generic component that allows to first draw and then edit
     * a polyline. It also takes care of selection and multiselection of
     *  its vertices.
     **/
    const commitPolyline = commit
        ? commit
        : (geometry: Geometry<GeometryType.LINEAR>) => setGeometry(geometry);

    const polylineRef = useRef<Polyline | null>(null);

    //If interactive is set to true or interactive prop wasn't supplied, this
    //geometry will have draggable vertices when edited
    const isInteractive = isNil(interactive) || interactive;
    const [newVertexEnd, setNewVertexEnd] = useNilState<LatLng>(null);
    const [disableDiagonal, setDisableDiagonal] = useState(false);
    const [selectMultipleVertices, setSelectMultipleVertices] = useState(false);

    const [addingNewVertex, setAddingNewVertex] = useState<boolean>(
        isNil(interactive) ? geometry.coordinates.length === 1 : interactive
    );

    const currentPointCount = geometry.coordinates.length;

    const leafletPolyline = (() => {
        const latLngs = toLeaflet(geometry).getLatLngs();
        // The `Polyline` component can't handle 3D geometries
        if (latLngsIs.threeDimensional(latLngs)) {
            return latLngs.flatMap((l) => l);
        }
        return latLngs;
    })();

    const finishDrawing = useCallback((): void => {
        if (currentPointCount > 1) {
            setAddingNewVertex(false);
            currentlyAddedCoordinatesObservable.next(null);
            isAddingCoordinatesObservable.next(false);
            onFinish?.();
            deselect(geometry.uuid);
            tool === ToolType.LINEAR && setTool(ToolType.SELECTION);
        }
    }, [currentPointCount, setAddingNewVertex, deselect]);

    const getLineCenterpoints = (): LatLng[] => {
        const centerPoints: LatLng[] = [];
        const findCenterpoint = (start: Point, end: Point): LatLng => {
            return new LatLng((end.x + start.x) / 2, (end.y + start.y) / 2);
        };
        geometry.coordinates.reduce((prev, current) => {
            centerPoints.push(findCenterpoint(prev, current));
            return current;
        });
        return centerPoints;
    };

    const getPosition = (end: Point): Point => {
        const start = geometry.coordinates[currentPointCount - 1];
        if (disableDiagonal && start) {
            const horizontal = Math.abs(start.x - end.x);
            const vertical = Math.abs(start.y - end.y);
            if (horizontal <= vertical) {
                end.x = start.x;
            } else {
                end.y = start.y;
            }
        }
        return fromCoordinate({
            x: end.x,
            y: end.y,
        });
    };

    const addVertex = (newPoint: Point): void => {
        commitPolyline({ ...geometry, coordinates: [...geometry.coordinates, newPoint] });
    };

    const addVertexSkippingNLast = (newPoint: Point, n: number): void => {
        commitPolyline({
            ...geometry,
            coordinates: [...geometry.coordinates.slice(0, -n), newPoint],
        });
    };

    const moveActiveVertices = (
        mouseCoordinates: Point,
        localActiveVertices: string[],
        movedVertexId: string
    ): void => {
        const newCoordinates = [...geometry.coordinates];
        const otherActiveVertices = localActiveVertices.filter(
            (id: string): boolean => id !== movedVertexId
        );
        const movedVerticeParsedIndex = parseInt(movedVertexId);
        const moveOrigin = newCoordinates[movedVerticeParsedIndex];
        newCoordinates[movedVerticeParsedIndex] = mouseCoordinates;
        const moveVector = [mouseCoordinates.x - moveOrigin.x, mouseCoordinates.y - moveOrigin.y];
        for (const activeVertexId of otherActiveVertices) {
            const parsedIndex = parseInt(activeVertexId);
            const currentVertexCoordinates = newCoordinates[parsedIndex];
            newCoordinates[parsedIndex] = {
                x: currentVertexCoordinates.x + moveVector[0],
                y: currentVertexCoordinates.y + moveVector[1],
                id: currentVertexCoordinates.id,
            };
        }
        commitPolyline({ ...geometry, coordinates: newCoordinates });
    };

    const handleVertexClick = useCallback(
        (e: LeafletMouseEvent, vertexId: string): void => {
            const parsedIndex = parseInt(vertexId);
            if (!mapRef) return;

            // When we're drawing, clicking on the first vertex should finish
            // drawing.
            if (addingNewVertex) {
                if (parsedIndex === 0 && currentPointCount > 2) {
                    addVertex(getPosition(fromLeaflet(e.latlng)));
                    finishDrawing();
                }
                // When we're editing, we override the default mousemove
                // event on the underlying map to handle the dragging.
            } else if (isInteractive) {
                // If the user is holding shift to select multiple vertices,
                // they can select and deselect them freely.
                let localActiveVertices = activeVertices;
                if (selectMultipleVertices) {
                    activeVertices.includes(vertexId)
                        ? deactivateVertices([vertexId])
                        : activateVertices([vertexId]);
                    // Once they let go of shift, clicking on
                    // a vertex will deactivate
                    // all other vertices.
                } else if (!activeVertices.includes(vertexId)) {
                    deactivateOtherVertices(vertexId);
                    localActiveVertices = [vertexId];
                }
                const map = mapRef.leafletElement;
                map.dragging.disable();
                map.on('mousemove', function (e: LeafletMouseEvent) {
                    moveActiveVertices(fromLeaflet(e.latlng), localActiveVertices, vertexId);
                    DomEvent.stopPropagation(e);
                });
            }
            DomEvent.stopPropagation(e);
        },
        [
            mapRef,
            addingNewVertex,
            currentPointCount,
            activeVertices,
            addVertex,
            finishDrawing,
            getPosition,
            isInteractive,
        ]
    );

    const handleVertexRelease = useCallback(
        (e: LeafletMouseEvent, _: string): void => {
            if (!mapRef || addingNewVertex) return;

            const map = mapRef.leafletElement;
            // We disable the overriden mousemove
            map.off('mousemove');

            DomEvent.stopPropagation(e);
        },
        [mapRef, addingNewVertex]
    );

    const handleCenterpointClick = useCallback(
        (e: LeafletMouseEvent, vertexId: string): void => {
            const parsedIndex = parseInt(vertexId);
            if (!addingNewVertex && isInteractive) {
                const newCoordinates = [...geometry.coordinates];
                // Clicking on a centerpoint adds a new vertex to the geometry.
                newCoordinates.splice(parsedIndex + 1, 0, fromLeaflet(e.latlng));
                // It also deselects all the currently selected vertices to
                // avoid confusion.
                deactivateOtherVertices('');
                commitPolyline({ ...geometry, coordinates: newCoordinates });
            }
            DomEvent.stopPropagation(e);
        },
        [addingNewVertex, geometry, commitPolyline, interactive]
    );

    const shiftDownHandler = (e: KeyboardEvent): void => {
        if (e.shiftKey) {
            if (addingNewVertex) {
                setDisableDiagonal(true);
            } else {
                setSelectMultipleVertices(true);
            }
        }
    };
    const shiftUpHandler = (e: KeyboardEvent): void => {
        if (e.key === 'Shift') {
            if (addingNewVertex) {
                setDisableDiagonal(false);
            } else {
                setSelectMultipleVertices(false);
            }
        }
    };

    const renderVertices = (): JSX.Element[] =>
        geometry.coordinates.map((coordinates: Point, index: number) => {
            const id = index.toString();
            return (
                <Vertex
                    coordinates={new LatLng(coordinates.x, coordinates.y)}
                    key={`vertex_${index}`}
                    color={(editable && geometry.style.color) || currentColor}
                    opacity={opacity}
                    active={activeVertices.includes(id)}
                    onMouseDown={handleVertexClick}
                    onMouseUp={handleVertexRelease}
                    id={id}
                    interactive={!addingNewVertex || index === 0 || !!interactive}
                    useTakeoff={useTakeoff}
                />
            );
        });

    const renderLineCenterPoints = (): JSX.Element[] | false =>
        currentPointCount > 0 &&
        (maxVertexCount ? currentPointCount < maxVertexCount : true) &&
        getLineCenterpoints().map((coordinates: LatLng, index: number) => {
            const id = `${index.toString()}-centerpoint`;
            return (
                <Vertex
                    coordinates={coordinates}
                    key={id}
                    color={(editable && geometry.style.color) || currentColor}
                    onMouseDown={handleCenterpointClick}
                    onMouseUp={handleVertexRelease}
                    id={id}
                    opacity={0.4 * (opacity || 1)}
                    interactive={true}
                    useTakeoff={useTakeoff}
                />
            );
        });

    useHandleKeyPress(shiftDownHandler, shiftUpHandler);

    useEffect(() => {
        if (currentPointCount > 0 && addingNewVertex && !isAddingCoordinates) {
            finishDrawing();
        }
    }, [currentPointCount, isAddingCoordinates, addingNewVertex]);

    useEffect(() => {
        if (!isNil(interactive) && maxVertexCount && currentPointCount < maxVertexCount) {
            setAddingNewVertex(interactive);
        }
    }, [interactive, currentPointCount, maxVertexCount]);

    useEffect(() => {
        if (mapRef && addingNewVertex) {
            const map = mapRef.leafletElement;

            const handleMove = (e: LeafletMouseEvent): void => {
                const newPosition = getPosition(fromLeaflet(e.latlng));
                setNewVertexEnd(new LatLng(newPosition.x, newPosition.y));
                DomEvent.stopPropagation(e);
            };
            const handleClick = (e: LeafletMouseEvent): void => {
                addVertex(getPosition(fromLeaflet(e.latlng)));
                DomEvent.stopPropagation(e);
            };
            const handleDoubleClick = (e: LeafletMouseEvent): void => {
                // A double click registers as click-click-doubleclick, so we
                // have to take into consideration the last two points that
                // were added without the user's consent and remove them before
                // closing the geometry.
                if (currentPointCount > 4) {
                    addVertexSkippingNLast(geometry.coordinates[0], 2);
                    finishDrawing();
                }
                DomEvent.stopPropagation(e);
            };
            map.on('click', handleClick);
            map.on('dblclick', handleDoubleClick);
            map.on('mousemove', handleMove);

            return function cleanup(): void {
                map.off('click', handleClick);
                map.off('dblclick', handleDoubleClick);
                map.off('mousemove', handleMove);
            };
        }
    }, [
        mapRef,
        geometry,
        addingNewVertex,
        geometry.coordinates,
        currentPointCount,
        disableDiagonal,
    ]);

    useEffect(() => {
        if (maxVertexCount && currentPointCount === maxVertexCount) {
            finishDrawing();
        }
    }, [geometry]);

    useDisableEventsOnSVG({ ref: polylineRef, checkIsRendererSVG });

    return (
        <FeatureGroup>
            {currentPointCount > 0 && addingNewVertex && newVertexEnd && (
                <Polyline
                    positions={[
                        new LatLng(
                            geometry.coordinates[currentPointCount - 1].x,
                            geometry.coordinates[currentPointCount - 1].y
                        ),
                        newVertexEnd,
                    ]}
                    weight={currentWeight}
                    opacity={0.5}
                    color={currentColor}
                    lineType={currentLineType}
                    shapeWeight={geometry.style.shapeWeight}
                />
            )}
            {currentPointCount > 0 && (
                <Polyline
                    positions={leafletPolyline}
                    weight={geometry.style.weight || currentWeight}
                    color={geometry.style.color}
                    lineType={geometry.style.lineType}
                    opacity={opacity}
                    interactive={!pointerEventsDisabled}
                    ref={polylineRef}
                    shapeWeight={geometry.style.shapeWeight}
                    {...mouseEvents(geometry)}
                />
            )}
            {(addingNewVertex || editable) && renderVertices()}
            {(addingNewVertex || editable) && renderLineCenterPoints()}
        </FeatureGroup>
    );
};
