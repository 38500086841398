import styled, { css } from 'styled-components';

import { LocationInput } from '@/components/ui/inputs/Location/index';
import { focusBorder } from '@/common/styles/focusBorder';
import { DropdownButton } from '@/components/ui/buttons/DropdownButton';

import { ReactComponent as Cross } from '@/assets/icons/close-cross-thin.svg';

const variables = {
    dropdownBackground: '#305FFF',
};

const containerGridTemplate = `
    " .      .      .      .      .      " 5px
    " .      prompt .      close  .      " 36px
    " .      .      .      .      .      " 7px
    " .      input  .      submit .      " 35px
    " .      .      .      .      .      " 15px
    / 8px    1fr    9px    auto    9px
`;

export const CloseIcon = styled(Cross)`
    height: 9px;
`;

export const Prompt = styled.span`
    grid-area: prompt;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    white-space: nowrap;

    & > svg {
        margin: 0 8px 2px;
    }
`;

export const Close = styled.div`
    grid-area: close;

    cursor: pointer;

    background: rgba(0, 0, 0, 0.17);
    border-radius: 4px;
    width: 24px;
    height: 24px;

    align-self: center;
    justify-self: end;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background 0.4s;

    &:active,
    &:hover {
        background: rgba(0, 0, 0, 0.25);
    }

    ${focusBorder}
`;

const inputCss = css`
    width: 100%;
    height: 100%;
    padding: 4px 14px;

    outline: none;
    border: none;

    color: #ffffff;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 4px;
    box-sizing: border-box;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 200px;

    &::placeholder {
        color: #ffffff;
        opacity: 0.8;
    }
`;

export const Input = styled(LocationInput)`
    grid-area: input;

    height: 100%;

    & > .form-control {
        width: 100%;
        height: 100%;

        & > input {
            ${inputCss}
        }
    }
`;
export const MockInput = styled.input`
    grid-area: input;

    ${inputCss}
`;

export const Submit = styled(DropdownButton)`
    grid-area: submit;
`;

interface ContainerProps {
    isOpen: boolean;
}
export const Container = styled.div<ContainerProps>`
    font-family: 'proxima-nova', sans-serif;
    z-index: 3;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background: ${variables.dropdownBackground};

    display: grid;
    grid-template: ${containerGridTemplate};

    transition: transform 0.4s;
    transform: ${(props): string => (props.isOpen ? 'translateY(0%)' : 'translateY(-100%)')};
`;
