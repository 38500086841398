import { toMarkupGeometries, toQuantity } from '@/common/convert/geometry';
import { Geometry } from '@/common/types';
import { geometryTypeToMarkupName, geometryTypeToMarkupType } from '@/common/utils/geometries/maps';
import { IMarkupFragment } from '@/graphql';

export const toMarkup = (
    geometry: Geometry,
    projectPlanPageID: number,
    scale: number | null,
    markupGroupID: string | null
): IMarkupFragment => ({
    __typename: 'Markup',
    id: geometry.uuid,
    color: geometry.style.color,
    geometries: toMarkupGeometries(geometry),
    markupType: geometryTypeToMarkupType[geometry.type],
    markupGroupID,
    measurement: toQuantity(geometry, scale),
    name: geometryTypeToMarkupName[geometry.type],
    projectPlanPageID: String(projectPlanPageID),
    isSelected: false,
});
