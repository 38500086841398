import { ElementTotals, EstimateCost, EstimateElement, EstimateElementCategory } from './types';
import { isNaiveNumericInput, sortAssemblyElements } from '@/common/utils/helpers';
import {
    IAssemblyFragment,
    IAssemblyReorderDirection,
    IAssemblyType,
    IElementReorderDirection,
    IEstimateAssembliesQuery,
    IEstimateElementFragment,
    IPageInfoFragment,
    ITradeFragment,
} from '@/graphql';

export const stringToElementReorderDirection: Record<'down' | 'up', IElementReorderDirection> = {
    down: IElementReorderDirection.Down,
    up: IElementReorderDirection.Up,
};

export const stringToAssemblyReorderDirection: Record<'down' | 'up', IAssemblyReorderDirection> = {
    down: IAssemblyReorderDirection.Down,
    up: IAssemblyReorderDirection.Up,
};

export const categoryTotalFromElementTotals = (
    categoryID: string,
    elementTotals: ElementTotals
): number => {
    let total = 0;
    for (const { categoryID: id, val } of elementTotals.values()) {
        if (categoryID === id) {
            total += val;
        }
    }
    return total;
};

export const estimateCostFromElementTotals = (elementTotals: ElementTotals): EstimateCost => {
    const estimateCost = {
        direct: 0,
        generalConditions: 0,
        equipment: 0,
    };
    for (const { tradeName, val } of elementTotals.values()) {
        if (tradeName === 'General Conditions') {
            estimateCost.generalConditions += val;
        } else if (tradeName === 'Equipment') {
            estimateCost.generalConditions += val;
        } else {
            estimateCost.direct += val;
        }
    }
    return estimateCost;
};

export const elementFromFragment = (
    element: IEstimateElementFragment,
    trade: ITradeFragment | null
): EstimateElement => {
    return { ...element, trade };
};

export const elementsFromFragments = (
    elements: IEstimateElementFragment[],
    trade: ITradeFragment | null
): EstimateElement[] => {
    return sortAssemblyElements(elements.map((element) => elementFromFragment(element, trade)));
};

export const categoriesFromAssembliesQuery = (
    res: IEstimateAssembliesQuery
): EstimateElementCategory[] => {
    return (
        res.assemblies.edges?.map(({ node: assembly }) => ({
            id: assembly.id,
            uuid: assembly.uuid,
            name: assembly.description,
            favorited: assembly.favorited,
            assemblyType: assembly.assemblyType,
            elements: elementsFromFragments(
                assembly.elements?.edges?.map((el) => el.node) ?? [],
                assembly.trade
            ),
        })) ?? []
    );
};

export const categoryFromFragments = (
    assembly: IAssemblyFragment,
    elements: IEstimateElementFragment[] = []
): EstimateElementCategory => {
    return {
        id: assembly.id,
        uuid: assembly.uuid,
        name: assembly.description,
        favorited: assembly.favorited,
        assemblyType: assembly.assemblyType,
        elements: elementsFromFragments(elements, assembly.trade),
    };
};

// Extract the "uncategorized" assembly category from a list of categories.
export const accumulateUncategorizedAssemblyElements = (
    categories: EstimateElementCategory[]
): EstimateElement[] => {
    return categories
        .filter((category) => category.assemblyType === IAssemblyType.Item)
        .flatMap((assembly) =>
            assembly.elements.map((element) => ({
                ...element,
                trade: null,
            }))
        );
};

// Extract pagination info from an assemblies query result.
export const pageInfoFromAssembliesQuery = (
    result: IEstimateAssembliesQuery
): IPageInfoFragment => {
    return result.assemblies.pageInfo;
};

export const stringToNumber = (stringNumber: string, currentValue?: string): string => {
    if (isNaiveNumericInput(stringNumber)) {
        if (stringNumber === '.') {
            stringNumber = '0.';
        }
        return stringNumber;
    } else {
        if (stringNumber.length === 0) {
            return '';
        } else {
            return currentValue ?? '';
        }
    }
};
