import styled from 'styled-components';

import { breakpoints } from '@/common/styles/helpers/breakpoints';
import {
    borderCement,
    colorBackground,
    colorTypographyDark,
    colorTypographyDarkSecondary,
} from '@/variables';

const variables = {
    hint: '#a5b3b9',
    commentAreaBackground: '#EBF0F2',
    tagBubbleHover: '#CEDADF',
};

export const Header = styled.h2`
    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.01em;
`;

export const Prompt = styled.div`
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
`;

////////////////////
// Rating section //
////////////////////
export const RatingHint = styled.span`
    color: ${variables.hint};
`;

export const RatingPrompt = styled.div`
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;

    color: ${colorTypographyDarkSecondary};
`;

export const RatingContainer = styled.div`
    padding: 17px 25px 25px;

    border: ${borderCement};
    box-sizing: border-box;
    border-radius: 4px;

    & > ${RatingPrompt} {
        margin-bottom: 11px;
    }
`;

/////////////////
// Tag section //
/////////////////
/* eslint-disable @typescript-eslint/indent */
type TagBubbleProps = { isActive: boolean };
export const TagBubble = styled.div<TagBubbleProps>`
    cursor: pointer;

    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;

    height: 32px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${(props): string => (props.isActive ? '#FFF' : 'inherit')};
    background-color: ${(props): string =>
        props.isActive ? colorBackground : 'rgba(215, 225, 229, 0.5)'};

    border-radius: 40px;
    padding: 0 32px;

    transition: color 0.4s, background-color 0.4s;

    &:hover {
        background-color: ${(props): string =>
            props.isActive ? colorBackground : variables.tagBubbleHover};
    }

    ${breakpoints.small`
        padding: 0 16px;
    `}
`;
/* eslint-enable @typescript-eslint/indent */

export const TagBubbleWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & > ${TagBubble} {
        margin: 0 9px 8px 0;

        &:first-of-type {
            margin-left: 0;
        }
    }
`;
export const TagContainer = styled.div`
    & > ${Prompt} {
        margin-bottom: 6px;
    }
`;

/////////////////////
// Comment section //
/////////////////////
export const CommentContainer = styled.div`
    & > ${Prompt} {
        margin-bottom: 8px;
    }
`;
export const CommentArea = styled.textarea`
    color: ${colorTypographyDark};
    font-family: 'proxima-nova', sans-serif;
    font-size: 16px;
    line-height: 20px;

    padding: 12px 17px;
    width: 100%;
    box-sizing: border-box;
    resize: none;

    border: none;
    background: ${variables.commentAreaBackground};
    border-radius: 4px;
    height: 124px;

    &::placeholder {
        color: ${colorTypographyDark};
        opacity: 0.4;
    }
`;

////////////////////
// Submit section //
////////////////////
export const SubmitButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(180deg, #4a8bf7 0%, #3b63f6 100%);
    border-radius: 4px;
    width: 108px;
    height: 32px;

    color: #fff;
    font-family: 'proxima-nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    cursor: pointer;
`;

export const SubmitContainer = styled.div`
    display: flex;

    & > ${SubmitButton} {
        margin-left: auto;
    }
`;

////////////////////////
// Wrapper containers //
////////////////////////
export const Overlay = styled.div`
    cursor: default;

    z-index: 2;

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

type ContainerProps = { hasTag?: boolean };
export const Container = styled.div<ContainerProps>`
    cursor: default;

    box-sizing: border-box;

    color: ${colorTypographyDark};
    font-family: 'poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: ${(props): string => (props.hasTag ? '38px 20px 16px' : '58px 20px 16px')};

    background: #ffffff;
    box-shadow: 0px 51px 67px rgba(0, 0, 0, 0.07), 0px 14.5916px 12.4842px rgba(0, 0, 0, 0.0503198),
        0px 7.67308px 4.42419px rgba(0, 0, 0, 0.0417275),
        0px 4.5434px 2.49313px rgba(0, 0, 0, 0.035),
        0px 2.74017px 1.89164px rgba(0, 0, 0, 0.0282725),
        0px 1.36904px 1.23029px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    width: 604px;

    & > ${Header} {
        margin-top: 0;
        margin-bottom: 28px;
        max-width: 486px;
    }

    & > ${RatingContainer} {
        margin-bottom: ${(props): number => (props.hasTag ? 63 : 57)}px;
    }

    & > ${TagContainer} {
        margin-bottom: 57px;
    }

    & > ${CommentContainer} {
        margin-bottom: ${(props): number => (props.hasTag ? 16 : 30)}px;
    }

    ${breakpoints.small`
        max-width: 95vw;
        max-height: 90vh;
        overflow-y: auto;

        & > ${Header} {
            margin-bottom: 16px;
        }

        & > ${TagContainer},
        & > ${RatingContainer} {
            margin-bottom: 32px;
        }
    `}
`;
