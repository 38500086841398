import styled, { css } from 'styled-components';

export const OnebuildLibrarySectionWrapper = styled.div`
    .assembly-context-menu {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 7.5rem;
        color: #1b2235;
        background-color: white;

        z-index: 2;

        span {
            cursor: pointer;
            padding: 0.375rem 0.75rem;

            &:hover {
                background-color: #eeeef1;
            }
        }
    }
`;

export const hoverCss = css`
    position: relative;
    &:focus,
    &:hover {
        &::after {
            content: ' ';
            border-radius: 2px;
            background: rgba(22, 111, 243, 0.27);
            height: 100%;
            width: calc(100% - 8px);
            position: absolute;
            top: 0;
            left: 4px;
        }
    }
`;
