import { fromCoordinate } from '@/common/convert/coordinateData';
import { Point } from '@/common/types';
import { LatLngs, latLngsIs } from '@/common/utils/geometries/leaflet';

type Convert<T extends LatLngs> = T extends LatLngs<0>
    ? Point
    : T extends LatLngs<1>
    ? Point[]
    : Point[][];

type Converter<T extends LatLngs> = (latLngs: T) => Convert<T>;

const fromLine: Converter<LatLngs<1>> = (line) => {
    return line.map(fromPoint);
};

const fromPoint: Converter<LatLngs<0>> = (point) => {
    return fromCoordinate({ x: point.lat, y: point.lng });
};

const fromPolygon: Converter<LatLngs<2>> = (polygon) => {
    return polygon.map(fromLine);
};

// Polyhedrons aren't possible in current takeoff editor, so they are flattenned to polygons.
const fromPolyhedron: Converter<LatLngs<3>> = (polyhedron) => {
    return polyhedron.flatMap(fromPolygon);
};

export function fromLeaflet<T extends LatLngs>(geometry: T): Convert<T>;
export function fromLeaflet(geometry: LatLngs) {
    if (latLngsIs.latLng(geometry)) return fromPoint(geometry);
    if (latLngsIs.threeDimensional(geometry)) return fromPolyhedron(geometry);
    if (latLngsIs.multidimensional(geometry)) return fromPolygon(geometry);
    else return fromLine(geometry);
}
