import React, { FC } from 'react';

import { Button, Container, Copy, Header, HeaderContent } from './styled';
import { PopoverProps } from '..';

const COPY = {
    headerHighlight: 'Project not started:',
    header: 'No files uploaded',
    copy:
        'In order for us to start your estimate, we need project files. ' +
        'Your project will stay here in draft mode, open it back up anytime to upload files and kick off your project. ' +
        'To do that, click +FILE below, upload your files, then click POST to send them.',
    button: 'Got it',
};

export const NotStarted: FC<PopoverProps> = ({ reset }) => (
    <Container>
        <Header>
            <HeaderContent isHighlight>{COPY.headerHighlight}</HeaderContent>
            <HeaderContent>{COPY.header}</HeaderContent>
        </Header>

        <Copy>{COPY.copy}</Copy>

        <Button onClick={reset}>{COPY.button}</Button>
    </Container>
);
