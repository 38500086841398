import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { styled as styledByMui } from '@mui/material/styles';

import { breakpoints, breakpointsCSSObject } from '@/common/styles/helpers/breakpoints';
import { TopbarButton } from '@/components/ui/buttons/TopbarButton';
import { Opacity } from '@/components/ui/containers/Opacity';

import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/32/arrow--left.svg';
import { ReactComponent as FullLogoIcon } from '@/assets/images/full-logo-v2.svg';

const gridTemplate = `
    " arrow-left    left-buttons   logo           .     nav-tabs    .      action-buttons" 1fr
    / min-content   min-content    min-content    40px  min-content auto   min-content
`;

const actionButtonsGridTemplate = `
    " status   .         buttons   .     utilitiy-buttons   " 42px
    / auto     24px      auto      16px  min-content
`;

const actionButtonsGridTemplateMobile = `
    " status   buttons   .     utilitiy-buttons   " 42px
    / auto     auto      16px  min-content
`;

export const DetailsButton = styled(TopbarButton)`
    display: none;
    width: 110px;

    & > svg {
        margin-bottom: 1px;
    }

    ${breakpoints.medium`
        display: flex;
        width: auto;
    `}

    ${breakpoints.small`
        display: none;
    `}
`;

export const LeftButtonContainer = styled.div`
    grid-area: left-buttons;

    display: flex;
    column-gap: 16px;

    align-items: center;

    & > ${DetailsButton} {
        margin-left: 16px;
    }
`;

export const UtilityButtonsWrapper = styled(Stack)({
    gridArea: 'utilitiy-buttons',
    alignItems: 'center',
});

export const ActionButtonsWrapper = styled.div`
    grid-area: buttons;

    display: flex;
    & > * {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }

    ${breakpoints.medium`
        margin-right: 0px;

        & > * {
            margin-right: 16px;
    
            &:last-child {
                margin-right: 0;
            }
        }
    `}
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledArrowLeft = styledByMui(ArrowLeftIcon)((options) => ({
    color: options.theme.palette.hues.neutral[78],
}));

export const ArrowLeftLink = styled(Link)({
    gridArea: 'arrow-left',

    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    height: '100%',
});

export const FullLogoWrapper = styled(Link)({
    gridArea: 'logo',
    display: 'flex',
});

const StyledFullLogo = styled(FullLogoIcon)({ height: '30px' });

export const NavTabsContainer = styled.div({
    gridArea: 'nav-tabs',

    height: '100%',
    display: 'flex',

    ...breakpointsCSSObject.extraSmall({
        display: 'none',
    }),
});

export const ActionButtonsContainer = styled.div`
    grid-area: action-buttons;
    justify-self: end;

    display: grid;
    grid-template: ${actionButtonsGridTemplate};

    & > ${StatusWrapper} {
        grid-area: status;
    }

    ${breakpoints.medium`
        grid-template: ${actionButtonsGridTemplateMobile};
        margin-left: auto;
    `}
`;

export const ToolbarGrid = styled(Opacity)({
    display: 'grid',
    alignItems: 'center',
    zIndex: 2,

    gridTemplate: gridTemplate,

    [`& > ${NavTabsContainer}`]: {
        flex: 1,
    },

    ...breakpointsCSSObject.medium({
        display: 'flex',
        width: '100%',
    }),
});

export const ToolbarContent = styledByMui('div')({
    display: 'flex',

    [`& > ${ToolbarGrid}`]: {
        flex: 1,
    },
});

export const ToolbarContainer = styledByMui(MuiToolbar)((options) => ({
    background: options.theme.palette.hues.neutral[21],
    display: 'flex',
    maxWidth: '100vw',
    zIndex: 2,

    height: '4rem',

    [`& > ${ToolbarContent}`]: {
        flex: 1,
        zIndex: 1100,
    },

    ['@media print']: {
        display: 'none',
    },
}));

export { StyledFullLogo as FullLogo, StyledArrowLeft as ArrowLeft };
