import {
    accumulateUncategorizedAssemblyElements,
    categoriesFromAssembliesQuery,
    pageInfoFromAssembliesQuery,
} from './transforms';
import { EstimateElement, EstimateElementsPage } from './types';
import { ApolloClient, executeMutation, executeQuery } from '@/common/apollo/execute';
import { PaginationArgs } from '@/common/utils/pagination';
import {
    AssembliesDeletionDocument,
    AssembliesDeletionForV2OrderingDocument,
    AssembliesDuplicationDocument,
    AssemblyAssignmentDocument,
    AssemblyLibraryAssignmentDocument,
    AssemblyReorderBeforeUuidDocument,
    AssemblyReorderDocument,
    CostDataSourcesGetByIdDocument,
    ElementAssignmentDocument,
    ElementGroupingDocument,
    ElementReorderBeforeUuidDocument,
    ElementReorderDocument,
    ElementsDeletionDocument,
    ElementsDeletionForV2OrderingDocument,
    ElementUpdateExpressionDocument,
    EstimateAssembliesDocument,
    IAssembliesDeletionForV2OrderingMutation,
    IAssembliesDeletionForV2OrderingMutationVariables,
    IAssembliesDeletionMutation,
    IAssembliesDeletionMutationVariables,
    IAssembliesDuplicationMutation,
    IAssembliesDuplicationMutationVariables,
    IAssembliesOrderBy,
    IAssemblyAssignmentInput,
    IAssemblyAssignmentMutation,
    IAssemblyAssignmentMutationVariables,
    IAssemblyLibraryAssignmentMutation,
    IAssemblyLibraryAssignmentMutationVariables,
    IAssemblyLibraryOperation,
    IAssemblyReorderBeforeUuidMutation,
    IAssemblyReorderBeforeUuidMutationVariables,
    IAssemblyReorderDirection,
    IAssemblyReorderMutation,
    IAssemblyReorderMutationVariables,
    IAssemblyType,
    ICostDataSourcesGetByIdQuery,
    IElementAssignmentInput,
    IElementAssignmentMutation,
    IElementAssignmentMutationVariables,
    IElementExpressionInput,
    IElementGroupingMutation,
    IElementGroupingMutationVariables,
    IElementRatesInput,
    IElementReorderBeforeUuidMutation,
    IElementReorderBeforeUuidMutationVariables,
    IElementReorderDirection,
    IElementReorderMutation,
    IElementReorderMutationVariables,
    IElementsDeletionForV2OrderingMutation,
    IElementsDeletionForV2OrderingMutationVariables,
    IElementsDeletionMutation,
    IElementsDeletionMutationVariables,
    IElementUpdateExpressionMutation,
    IElementUpdateExpressionMutationVariables,
    IEstimateAssembliesQuery,
    IEstimateAssembliesQueryVariables,
    IProjectMarkupsDeletionMutation,
    IProjectMarkupsDeletionMutationVariables,
    ISourceBatchInput,
    ProjectMarkupsDeletionDocument,
} from '@/graphql';
import { TokenScalar } from '@/graphql/customScalars';

export const findLocalizedSourceV2 = async (
    client: ApolloClient,
    sourceInput: ISourceBatchInput
): Promise<ICostDataSourcesGetByIdQuery> =>
    executeQuery(client, {
        query: CostDataSourcesGetByIdDocument,
        variables: { input: sourceInput },
    });

const assignAssembly = async (
    client: ApolloClient,
    assignmentInput: IAssemblyAssignmentInput
): Promise<IAssemblyAssignmentMutation | null> =>
    executeMutation<IAssemblyAssignmentMutation, IAssemblyAssignmentMutationVariables>(client, {
        mutation: AssemblyAssignmentDocument,
        variables: { input: assignmentInput },
        refetchQueries: ['Assemblies', 'AssembliesLite'],
    });

const assignElement = async (
    client: ApolloClient,
    assignmentInput: IElementAssignmentInput
): Promise<IElementAssignmentMutation | null> =>
    executeMutation<IElementAssignmentMutation, IElementAssignmentMutationVariables>(client, {
        mutation: ElementAssignmentDocument,
        variables: { input: assignmentInput },
        refetchQueries: ['Assemblies', 'AssembliesLite'],
    });
export const editElement = async (
    client: ApolloClient,
    elementID: string,
    input: IElementAssignmentInput
): Promise<IElementAssignmentMutation | null> =>
    assignElement(client, {
        id: elementID,
        ...input,
    });

export const assignElementRates = async (
    client: ApolloClient,
    elementID: string,
    rates: IElementRatesInput
): Promise<IElementAssignmentMutation | null> =>
    assignElement(client, {
        id: elementID,
        rate: rates,
    });

export const assignElementExpressionTokens = async (
    client: ApolloClient,
    id: string,
    tokens: TokenScalar[]
): Promise<IElementUpdateExpressionMutation | null> =>
    executeMutation<IElementUpdateExpressionMutation, IElementUpdateExpressionMutationVariables>(
        client,
        {
            mutation: ElementUpdateExpressionDocument,
            variables: { input: { id, tokens } },
        }
    );

export const createAssembly = async (
    client: ApolloClient,
    name: string,
    projectID: string
): Promise<IAssemblyAssignmentMutation | null> =>
    assignAssembly(client, {
        projectID,
        description: name,
        assemblyType: IAssemblyType.Group,
    });

export const deleteAssemblies = async (
    client: ApolloClient,
    assemblyIDs: Set<string>
): Promise<IAssembliesDeletionMutation | null> =>
    executeMutation<IAssembliesDeletionMutation, IAssembliesDeletionMutationVariables>(client, {
        mutation: AssembliesDeletionDocument,
        variables: { input: { ids: [...assemblyIDs] } },
        update: (cache) => {
            assemblyIDs.forEach((id) => cache.evict({ id: `Assembly:${id}` }));
            cache.gc();
        },
    });

export const deleteAssembly = async (
    client: ApolloClient,
    assemblyID: string
): Promise<IAssembliesDeletionMutation | null> => deleteAssemblies(client, new Set([assemblyID]));

export const deleteAssembliesForV2Ordering = async (
    client: ApolloClient,
    assemblies: Set<{ assemblyUUID: string; assemblyID: string }>
): Promise<IAssembliesDeletionForV2OrderingMutation | null> =>
    executeMutation<
        IAssembliesDeletionForV2OrderingMutation,
        IAssembliesDeletionForV2OrderingMutationVariables
    >(client, {
        mutation: AssembliesDeletionForV2OrderingDocument,
        variables: { input: { uuids: [...assemblies].map((a) => a.assemblyUUID) } },
        update: (cache) => {
            [...assemblies]
                .map((a) => a.assemblyID)
                .forEach((id) => cache.evict({ id: `Assembly:${id}` }));
            cache.gc();
        },
    });

export const deleteAssemblyForV2Ordering = async (
    client: ApolloClient,
    assembly: { assemblyUUID: string; assemblyID: string }
): Promise<IAssembliesDeletionForV2OrderingMutation | null> =>
    deleteAssembliesForV2Ordering(client, new Set([assembly]));

export const deleteElements = async (
    client: ApolloClient,
    elementIDs: Set<string>
): Promise<IElementsDeletionMutation | null> =>
    executeMutation<IElementsDeletionMutation, IElementsDeletionMutationVariables>(client, {
        mutation: ElementsDeletionDocument,
        variables: { input: { ids: [...elementIDs] } },
        update: (cache) => {
            elementIDs.forEach((id) => cache.evict({ id: `Element:${id}` }));
            cache.gc();
        },
    });

export const deleteElement = async (
    client: ApolloClient,
    elementID: string
): Promise<IElementsDeletionMutation | null> => deleteElements(client, new Set([elementID]));

export const deleteElementsForV2Ordering = async (
    client: ApolloClient,
    elements: Set<{ elementUUID: string; elementID: string }>
): Promise<IElementsDeletionForV2OrderingMutation | null> =>
    executeMutation<
        IElementsDeletionForV2OrderingMutation,
        IElementsDeletionForV2OrderingMutationVariables
    >(client, {
        mutation: ElementsDeletionForV2OrderingDocument,
        variables: { input: { uuids: [...elements].map((e) => e.elementUUID) } },
        update: (cache) => {
            [...elements]
                .map((e) => e.elementID)
                .forEach((id) => cache.evict({ id: `Element:${id}` }));
            cache.gc();
        },
    });

export const deleteElementForV2Ordering = async (
    client: ApolloClient,
    element: { elementUUID: string; elementID: string }
): Promise<IElementsDeletionForV2OrderingMutation | null> =>
    deleteElementsForV2Ordering(client, new Set([element]));

export const deletePriceMarkups = async (
    client: ApolloClient,
    priceMarkupIDs: string[]
): Promise<IProjectMarkupsDeletionMutation | null> =>
    executeMutation<IProjectMarkupsDeletionMutation, IProjectMarkupsDeletionMutationVariables>(
        client,
        {
            mutation: ProjectMarkupsDeletionDocument,
            variables: { input: { ids: priceMarkupIDs } },
        }
    );

export const fetchItemAssemblies = async (
    client: ApolloClient,
    projectID: string
): Promise<EstimateElement[]> => {
    const result = await executeQuery<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>(
        client,
        {
            query: EstimateAssembliesDocument,
            variables: {
                input: {
                    condition: {
                        projectID,
                        usedInProject: true,
                        assemblyType: IAssemblyType.Item,
                    },
                    orderBy: IAssembliesOrderBy.CreatedDesc,
                    arguments: {
                        // For right now, we can only handle ONE
                        // item assembly.  This is the ITEM assembly
                        // formerly known as the PROJECT_ELEMENTS_PLACEHOLDER.
                        first: 1,
                    },
                },
            },
        }
    );
    const allCategories = categoriesFromAssembliesQuery(result);
    return accumulateUncategorizedAssemblyElements(allCategories);
};

export const fetchGroupAssembliesPage = async (
    client: ApolloClient,
    projectID: string,
    paginationArgs: PaginationArgs
): Promise<EstimateElementsPage> => {
    const result = await executeQuery<IEstimateAssembliesQuery, IEstimateAssembliesQueryVariables>(
        client,
        {
            query: EstimateAssembliesDocument,
            variables: {
                input: {
                    condition: {
                        projectID,
                        usedInProject: true,
                        assemblyType: IAssemblyType.Group,
                    },
                    orderBy: IAssembliesOrderBy.CreatedDesc,
                    ...paginationArgs?.variables.input,
                },
            },
        }
    );
    const pageInfo = pageInfoFromAssembliesQuery(result);
    const allCategories = categoriesFromAssembliesQuery(result);
    return {
        categories: allCategories,
        pageInfo,
    };
};

export const renameAssembly = async (
    client: ApolloClient,
    categoryID: string,
    name: string
): Promise<IAssemblyAssignmentMutation | null> =>
    assignAssembly(client, {
        id: categoryID,
        description: name,
        assemblyType: IAssemblyType.Group,
    });

export const reorderAssemblyBeforeUUID = async (
    client: ApolloClient,
    assemblyUUID: string,
    beforeUUID: string | null
): Promise<IAssemblyReorderBeforeUuidMutation | null> =>
    executeMutation<
        IAssemblyReorderBeforeUuidMutation,
        IAssemblyReorderBeforeUuidMutationVariables
    >(client, {
        mutation: AssemblyReorderBeforeUuidDocument,
        variables: {
            input: {
                uuid: assemblyUUID,
                beforeUUID: beforeUUID,
            },
        },
    });

export const reorderAssembly = async (
    client: ApolloClient,
    categoryID: string,
    direction: IAssemblyReorderDirection
): Promise<IAssemblyReorderMutation | null> =>
    executeMutation<IAssemblyReorderMutation, IAssemblyReorderMutationVariables>(client, {
        mutation: AssemblyReorderDocument,
        variables: {
            input: {
                id: categoryID,
                direction,
            },
        },
    });

export const duplicateAssembly = async (
    client: ApolloClient,
    categoryID: string,
    projectID: string,
    beforeUUID?: string
): Promise<IAssembliesDuplicationMutation | null> =>
    executeMutation<IAssembliesDuplicationMutation, IAssembliesDuplicationMutationVariables>(
        client,
        {
            mutation: AssembliesDuplicationDocument,
            variables: {
                input: {
                    ids: [categoryID],
                    projectID,
                    beforeUUID,
                },
            },
        }
    );

export const createBlankElement = async (
    client: ApolloClient,
    name: string,
    materialID: string | null,
    rates: IElementRatesInput | null,
    uom: string | null,
    assemblyID: string | undefined,
    projectID: string,
    takeoffUnitID: string,
    previousElementUUID: string | null,
    expression: IElementExpressionInput | null
): Promise<IElementAssignmentMutation | null> => {
    const input: IElementAssignmentInput = {
        assemblyID,
        previousElement: previousElementUUID,
        projectID,
        name,
        unitID: uom,
        rate: rates,
        expression,
    };
    if (!materialID) {
        input.material = {
            takeoffUnitID,
            name,
        };
    } else {
        input.materialID = materialID;
    }
    return assignElement(client, input);
};

export const renameElement = async (
    client: ApolloClient,
    elementID: string,
    name: string
): Promise<IElementAssignmentMutation | null> =>
    assignElement(client, {
        id: elementID,
        name,
    });

export const reorderElementBeforeUUID = async (
    client: ApolloClient,
    elementUUID: string,
    beforeUUID: string | null
): Promise<IElementReorderBeforeUuidMutation | null> =>
    executeMutation<IElementReorderBeforeUuidMutation, IElementReorderBeforeUuidMutationVariables>(
        client,
        {
            mutation: ElementReorderBeforeUuidDocument,
            variables: {
                input: {
                    uuid: elementUUID,
                    beforeUUID: beforeUUID,
                },
            },
        }
    );

export const reorderElement = async (
    client: ApolloClient,
    elementID: string,
    direction: IElementReorderDirection
): Promise<IElementReorderMutation | null> =>
    executeMutation<IElementReorderMutation, IElementReorderMutationVariables>(client, {
        mutation: ElementReorderDocument,
        variables: {
            input: {
                id: elementID,
                direction,
            },
        },
    });

export const groupElement = async (
    client: ApolloClient,
    elementID: string,
    assemblyID: string | null
): Promise<IElementGroupingMutation | null> =>
    executeMutation<IElementGroupingMutation, IElementGroupingMutationVariables>(client, {
        mutation: ElementGroupingDocument,
        variables: {
            input: {
                id: elementID,
                assemblyID,
            },
        },
    });

export const setElementUom = async (
    client: ApolloClient,
    elementID: string,
    unitID: string
): Promise<IElementAssignmentMutation | null> =>
    assignElement(client, {
        id: elementID,
        unitID,
    });

export const favoriteAssembly = async (
    client: ApolloClient,
    assemblyID?: string,
    elementId?: string,
    favorited?: boolean
): Promise<IAssemblyLibraryAssignmentMutation | null> => {
    return executeMutation<
        IAssemblyLibraryAssignmentMutation,
        IAssemblyLibraryAssignmentMutationVariables
    >(client, {
        mutation: AssemblyLibraryAssignmentDocument,
        variables: {
            input: {
                assemblyIDs: assemblyID ? [assemblyID] : [],
                elementIDs: elementId ? [elementId] : [],
                assemblyType: IAssemblyType.Group,
                operation: favorited
                    ? IAssemblyLibraryOperation.Add
                    : IAssemblyLibraryOperation.Remove,
            },
        },
        refetchQueries: ['Assemblies', 'AssembliesLite', 'LibraryElements'],
    });
};
