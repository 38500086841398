/**
 * Datepicker input component. Allows for picking a date from calendar.
 */

import React, { FC, useState, ChangeEvent, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import moment from 'moment';

import { DivProps, Direction } from '@/common/types';
import { FormControl, DateControlProps } from './helpers/FormControl';
import { Calendar } from '../controls/Calendar';
import { CaretIcon, CaretIconSize } from '../icons/CaretIcon';
import { useScreenSize, ScreenSize } from '@/common/hooks/useScreenSize';
import { toDateString } from '@/common/utils/moment';

import './Datepicker.scss';

const COPY = {
    datepicker_select_prompt: 'Select date',
};

export const Datepicker: FC<DateControlProps<DivProps>> = ({
    className,
    value,
    label,
    onChange,
    required,
    businessDaysUntilSelectable = 5,
    minDate,
    disabled,
    bypassDisabledDates = false,
}) => {
    const nativeInputRef = useRef<HTMLInputElement>(null);
    const screenSize = useScreenSize();
    const [open, setOpen] = useState(false);

    const isDateChanged = minDate !== undefined;

    /* State helpers */
    const closeMenu = (): void => setOpen(false);
    const toggleMenu = (): void => {
        setOpen((prev) => !prev);
    };
    const isMobile = screenSize === ScreenSize.Mobile;

    // The first possible bids due date is 3 business days away from today (excluding today).
    const firstPossibleDate = bypassDisabledDates
        ? moment()
        : minDate
        ? moment(new Date(minDate))
        : moment().businessAdd(businessDaysUntilSelectable);

    /* On date selected event emitter */
    const selectDate = (day: Date): void => {
        onChange(day);
        closeMenu();
    };

    /* On native date selected event emitter */
    const selectNativeDate = (e: ChangeEvent<HTMLInputElement>): void => {
        const day = new Date(e.target.value);
        onChange(moment(day).add(day.getTimezoneOffset(), 'm').toDate());
    };

    const renderCalendar = (): JSX.Element => (
        <OutsideClickHandler onOutsideClick={closeMenu}>
            <div className="datepicker">
                {!isMobile && (
                    <Calendar
                        bypassDisabledDates={bypassDisabledDates}
                        className={clsx('calendar', { hidden: !open })}
                        value={value}
                        min={firstPossibleDate}
                        onChange={selectDate}
                    />
                )}
                <button
                    className={clsx('display-input', {
                        open,
                        'no-cursor-effects': isDateChanged,
                    })}
                    {...(!disabled && !isDateChanged && { onClick: toggleMenu })}
                >
                    <span className={clsx('value', { placeholder: !value })}>
                        {value ? value.toLocaleDateString() : COPY.datepicker_select_prompt}
                    </span>
                    {isDateChanged && <span className="date-changed">DATE CHANGED</span>}
                    {!isDateChanged && (
                        <CaretIcon direction={Direction.Down} size={CaretIconSize.Medium} />
                    )}
                </button>
            </div>
        </OutsideClickHandler>
    );

    return (
        <FormControl label={label} required={required} value={value} className={className}>
            {renderCalendar()}
            {/* NOTE: the mobile date picker does not disable weekends/holidays */}
            {isMobile && (
                <OutsideClickHandler display="flex" onOutsideClick={closeMenu}>
                    <input
                        ref={nativeInputRef}
                        type="date"
                        min={toDateString(firstPossibleDate.toDate())}
                        value={toDateString(value)}
                        onChange={selectNativeDate}
                        disabled={disabled}
                    />
                </OutsideClickHandler>
            )}
        </FormControl>
    );
};
