import { Link } from './Link';
import { ReactComponent as Dashboard } from '@/assets/icons/Dashboard.svg';
import { ReactComponent as Estimators } from '@/assets/icons/Estimators.svg';
import { SvgIcon } from '@/components/ui/icons/SvgIcon';
import { colorTypographySection } from '@/variables';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const getIcon = (component: Svg): React.ReactElement => {
    return (
        <SvgIcon
            src={component}
            width="1.25rem"
            height="1.25rem"
            style={{ fill: colorTypographySection }}
        />
    );
};

const Container = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
`;

export const Links: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [selectedModule, setSelectedModule] = useState<string>();

    const config = [
        {
            name: 'Dashboard',
            Icon: getIcon(Dashboard),
            route: '/projects',
        },
        {
            name: 'Estimators',
            Icon: getIcon(Estimators),
            route: '/estimators',
        },
    ];

    useEffect(() => {
        if (location?.pathname) {
            const item = config.find((c) => c.route === location?.pathname) || config[0];
            setSelectedModule(item.name);
        }
    }, [location.pathname]);

    const onClickLink = (name: string): void => {
        const item = config.find((c) => c.name === name) || config[0];
        history.push(item.route);
    };

    return (
        <Container>
            {config.map((c) => (
                <Link
                    key={c.name}
                    selected={selectedModule === c.name}
                    Icon={c.Icon}
                    name={c.name}
                    onClick={onClickLink}
                />
            ))}
        </Container>
    );
};
