import React, { FC } from 'react';

import {
    colorProjectInProgress,
    colorProjectPendingAcceptance,
    colorProjectPendingRevision,
} from '@/variables';

import { PricingStatus } from '@/common/types';
import { StatusCard } from '@/components/ui/controls/StatusCard';

const { NEW, PENDING_ESTIMATOR, PREAPPROVED, NEEDS_PRICE, DECLINED } = PricingStatus;

type ProjectPricingStatusCardProps = {
    status: PricingStatus;
    onClick: () => void;
    showCancelAction: boolean;
};

const STATUSES = [
    {
        status: [NEW, PENDING_ESTIMATOR],
        color: `${colorProjectInProgress}`,
        title: 'Estimator match in progress...',
        message: `We’ll notify you when we’ve found 
            an estimator for your project.`,
        btnText: 'Cancel',
        btnHasBackground: false,
        hasIcon: false,
    },
    {
        status: [PREAPPROVED],
        color: `${colorProjectPendingAcceptance}`,
        title: 'Quote Ready!',
        message: `1build prepared a price and schedule for your review and approval! 
        Work will only start after you approve. Please approve today to guarantee your deadline.`,
        btnText: 'Review Quote',
        btnHasBackground: true,
        hasIcon: true,
        showAction: true,
    },
    {
        status: [NEEDS_PRICE, DECLINED],
        color: `${colorProjectPendingRevision}`,
        title: 'Reviewing submission',
        message: `We're reviewing your submission and will 
            respond with a price and confirmation soon.`,
        btnText: 'Cancel',
        btnHasBackground: false,
        hasIcon: false,
    },
];

export const ProjectPricingStatusCard: FC<ProjectPricingStatusCardProps> = ({
    status,
    showCancelAction,
    onClick,
}) => {
    const pricingStatus = STATUSES.find((s) => s.status.includes(status));

    if (!pricingStatus) {
        return <></>;
    }

    return (
        <StatusCard
            color={pricingStatus.color}
            title={pricingStatus.title}
            message={pricingStatus.message}
            btnText={pricingStatus.btnText}
            btnHasBackground={pricingStatus.btnHasBackground}
            hasIcon={pricingStatus.hasIcon}
            showAction={pricingStatus.showAction ?? showCancelAction}
            onClick={onClick}
        />
    );
};
