import React, { FC } from 'react';

import { ProjectInfoInput, ProjectInfoInputProps } from '.';
import { ProjectLocation } from './styled';
import { inferAddressAttributesFromPlace } from '@/common/utils/googleLocationHelpers';
import { LocationInputProps } from '@/components/ui/inputs/Location';
import { useFormattedAddress } from '@/components/ui/inputs/Location/hooks/useFormattedAddress';
import { IProjectLocationAssignmentInput } from '@/graphql';

const OVERRIDE_WIDTH = 447;
const OFFSET_TOP = 8;

interface ProjectInfoLocationInputProps extends ProjectInfoInputProps, LocationInputProps {
    label: string;
    initialFormattedAddress?: string;
    updateProjectInfo: (
        projectLocation: Omit<IProjectLocationAssignmentInput, 'projectID'>
    ) => void;
}
export const ProjectInfoLocationInput: FC<ProjectInfoLocationInputProps> = ({
    className,
    label,
    initialFormattedAddress,
    updateProjectInfo,
}) => {
    const { setAddress } = useFormattedAddress(initialFormattedAddress);

    return (
        <ProjectInfoInput className={className} label={label}>
            <ProjectLocation
                address={initialFormattedAddress}
                onEnter={(value) => updateProjectInfo(inferAddressAttributesFromPlace(value))}
                setAddress={setAddress}
                overrideWidth={OVERRIDE_WIDTH}
                menuOffsetTop={OFFSET_TOP}
            />
        </ProjectInfoInput>
    );
};
