import { frogmint } from '../frog';

export interface ProjectAcceptanceHistoryRecord {
    id: string;
    projectId: string;
    userId: string;
    accepted: boolean;
    excludedTrades: string;
    startBy: string;
    finishBy: string;
    expectedDuration: number;
    expectedDurationSummary: string;
    refusalSummary: string;
    refusalReason: string;
    quoteExpiration?: number;
    estimateTypes: string[];
    scopeOfWork: string;
}

export const projectAcceptanceHistoryFragment = frogmint`
    ${'ProjectAcceptanceHistory_projectAcceptanceHistory'} {
        id
        projectId
        userId
        accepted
        excludedTrades
        startBy
        finishBy
        expectedDuration
        expectedDurationSummary
        refusalSummary
        refusalReason
        quoteExpiration
        estimateTypes
        scopeOfWork
    }
`;
