import React, { FC } from 'react';

import { Search } from '@/components/AdminDashboard/Topbar/Search';
import { TabMenu } from '@/components/AdminDashboard/Topbar/TabMenu';
import { UserDropdownContainer } from '@/components/AdminDashboard/Topbar/styled';
import { UserDropdown } from '@/components/ui/inputs/UserDropdown';

export const TopbarContent: FC = () => {
    return (
        <>
            <TabMenu />
            <Search placeholder="Search projects" />
            <UserDropdownContainer>
                <UserDropdown />
            </UserDropdownContainer>
        </>
    );
};
