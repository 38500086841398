/*
 * ClickInterceptor sits on top of the whole page, under the project panel.
 * It prevents click events from firing on elements underneath the panel,
 * allowing for a more controlled panel close event.
 */
import React, { FC } from 'react';

import { ProjectPanelComponentProps } from './context';
import { PanelClickInterceptor } from './styled';

export const ClickInterceptor: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { clickedOutside, setClickedOutside } = useProjectPanel();

    if (clickedOutside) return <></>;

    return <PanelClickInterceptor onClick={(): void => setClickedOutside(true)} />;
};
