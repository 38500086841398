import React, { FC } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { DeleteProjectDialogTitle } from './styled';
import { DatabaseProjectStatus } from '@/common/types';
import { GradientToast } from '@/components/notifications/GradientToast';
import { BasicToastCopy } from '@/components/notifications/GradientToast/BasicToastCopy';
import { useNotifications } from '@/contexts/Notifications';
import { useUser } from '@/contexts/User';
import { useCreateEvent } from '@/mutations/event';
import {
    updateProjectStatusMutation,
    UpdateProjectStatusResult,
} from '@/mutations/updateProjectStatus';
import { ProjectRecord } from '@/queries/projects';
import { EventTypeName } from '@/queries/eventTypes';
import { Mode } from '@/theme/Mode';

type DeleteProjectModalProps = DialogProps & {
    open: boolean;
    close: () => void;
    project: ProjectRecord;
};
export const DeleteProjectModal: FC<DeleteProjectModalProps> = ({ close, project, ...props }) => {
    const { addNotification } = useNotifications();
    const history = useHistory();
    const {
        data: { user },
    } = useUser();
    const [, updateProjectStatus] = useMutation<UpdateProjectStatusResult>(
        updateProjectStatusMutation
    );
    const [, createEvent] = useCreateEvent();

    const onDeleteButtonClick = async () => {
        const [error] = await to(
            Promise.all([
                updateProjectStatus({
                    projectId: project.id,
                    status: DatabaseProjectStatus.CANCELED,
                }),
                createEvent({
                    eventType: EventTypeName.CancelProject,
                    message: `Project "${project.name ?? ''}" has been canceled`,
                    ownerId: Number(user.id),
                    projectId: Number(project.id),
                }),
            ])
        );

        if (error) {
            addNotification(
                {
                    title: '',
                    v2Content: (
                        <GradientToast>
                            <BasicToastCopy css={'padding-top: 10px;'}>
                                Error deleting “{project.name}”.
                            </BasicToastCopy>
                        </GradientToast>
                    ),
                },
                'error'
            );
        } else {
            history.push('/projects');

            // # TODO => Add snackbar
            addNotification(
                {
                    title: '',
                    v2Content: (
                        <GradientToast>
                            <BasicToastCopy css={'padding-top: 10px;'}>
                                The project “{project.name}” has been deleted.
                            </BasicToastCopy>
                        </GradientToast>
                    ),
                },
                'success'
            );
        }

        close();
    };

    return (
        <Mode variant="light">
            <Dialog maxWidth="xs" {...props}>
                <DeleteProjectDialogTitle>Delete Project</DeleteProjectDialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this project? You will lose all of the
                        information it contains.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" fullWidth onClick={close} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        color="error"
                        fullWidth
                        onClick={onDeleteButtonClick}
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Mode>
    );
};
