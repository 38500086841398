/* Executes given key handlers when user holds matching keys configuration. */
import hotkeys, { KeyHandler } from 'hotkeys-js';

import { keyMap } from './keyMap';

import { KeyHandler as Handler, KeyAction, KeyActionOption, KeyHandlers } from '@/common/types';

const isMacintosh = (): boolean => {
    return navigator.platform.includes('Mac');
};

const modifier = isMacintosh() ? 'option' : 'alt';

export const modifierIcon = isMacintosh() ? '⌥' : '⎇';

export const makeHotKeys = (
    scope: string,
    element: HTMLDivElement,
    handlers: KeyHandlers
): (() => void) => {
    const hasHandler = ([name, value]: [string, KeyAction]): boolean =>
        value.scope === scope && !!handlers[name];

    const toHandlerMapping = ([name, mapping]: [string, KeyAction]): [
        string,
        Handler,
        string | undefined,
        KeyActionOption | undefined
    ] => [mapping.mapping, handlers[name], mapping.trigger, mapping.option];

    const makeHandler = (handler: Handler, trigger = 'keydown'): KeyHandler => {
        let isPressed = false;

        return (event): void => {
            event.stopImmediatePropagation();

            if (event.type === 'keydown' && !isPressed) {
                if (trigger === 'keydown') handler(event);
                isPressed = true;
            }

            if (event.type === 'keyup') {
                if (trigger === 'keyup') handler(event);
                isPressed = false;
            }
        };
    };

    const mappings = Object.entries(keyMap).filter(hasHandler).map(toHandlerMapping);

    mappings.forEach(([mapping, handler, trigger, option]) =>
        hotkeys(
            mapping.replace('modifier', modifier),
            { element, scope, keyup: true, ...option },
            makeHandler(handler, trigger)
        )
    );

    return (): void => mappings.forEach(([mapping]) => hotkeys.unbind(mapping, scope));
};
