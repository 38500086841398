/*
 * Search is a input for searching through project files.
 */
import React, { ChangeEvent, FC, useRef } from 'react';

import { FilesSearchContainer, FilesSearchInput } from './styled';

import { ProjectPanelComponentProps } from '@/components/AdminDashboard/ProjectPanel/context';
import { colorBackgroundLighter } from '@/variables';

import { ReactComponent as CloseCross } from '@/assets/icons/close-cross.svg';
import { ReactComponent as MagnifyingGlass } from '@/assets/icons/magnifying-glass-alt-dark.svg';

const COPY = {
    placeholder: 'Search files',
};

export const Search: FC<ProjectPanelComponentProps> = ({ useProjectPanel }) => {
    const { fileSearchString, setFileSearchString } = useProjectPanel();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleCloseClick = (): void => {
        inputRef.current?.blur();
        setFileSearchString('');
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setFileSearchString(e.target.value);

    const renderIcon = (): JSX.Element => {
        if (fileSearchString === '') {
            return (
                <MagnifyingGlass
                    width="1.25rem"
                    height="1.25rem"
                    onClick={(): void => inputRef.current?.focus()}
                />
            );
        }
        return (
            <CloseCross
                width="0.75rem"
                height="0.75rem"
                onClick={handleCloseClick}
                style={{ fill: colorBackgroundLighter }}
            />
        );
    };

    return (
        <FilesSearchContainer>
            {renderIcon()}
            <FilesSearchInput
                ref={inputRef}
                type="text"
                value={fileSearchString}
                onChange={handleInputChange}
                placeholder={COPY.placeholder}
            />
        </FilesSearchContainer>
    );
};
