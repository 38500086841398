import React, { FC, useCallback } from 'react';
import { Descendant, Editor } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';
import styled from 'styled-components';

import { Element } from './Element';
import { detectRichTextHotkey } from './hotkeys';
import { Leaf } from './Leaf';
import { Format } from './types';
import { DivProps, Setter } from '@/common/types';

export const RichTextContainer = styled.div<{ color?: string }>`
    overflow-wrap: anywhere;

    * {
        color: ${(props): string => props.color ?? 'inherit'};
        margin: 0;
    }

    a * {
        color: blue;
    }
    ol,
    ul {
        padding-left: 1.25em;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1em;
    }
    blockquote {
        position: relative;
        padding: 0 0 0.125em 0.875em;
        overflow: hidden;
    }
    blockquote:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        background-color: ${(props): string => props.color ?? 'gray'};
        border-radius: 3px;
        opacity: 0.3;
    }
`;

export interface RichTextProps extends Omit<EditableProps, 'value' | 'onChange'> {
    editor: Editor & ReactEditor;
    value: Descendant[];
    onChange: Setter<Descendant[]>;
    color?: string;
    container?: DivProps;
}

export const RichText: FC<RichTextProps> = ({
    editor,
    value,
    onChange,
    color,
    container: containerProps,
    ...editableProps
}) => {
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    const isMarkActive = (format: Format): boolean => {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    };

    const toggleMark = (format: Format): void => {
        if (isMarkActive(format)) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    };

    const handleKeyDown: React.KeyboardEventHandler = (
        e: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        if (editableProps?.onKeyDown) {
            editableProps.onKeyDown(e);
        }
        const format = detectRichTextHotkey(e);
        if (format !== null) {
            e.preventDefault();
            toggleMark(format);
            return;
        }

        if (e.key === 'Enter' && !e.getModifierState('Shift')) {
            e.preventDefault();
        }
    };

    return (
        <RichTextContainer color={color} {...containerProps}>
            <Slate editor={editor} value={value} onChange={onChange}>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    {...editableProps}
                    /* Override the passed onKeyDown, calling instead *
                     * in handleKeyDown if passed.                    */
                    onKeyDown={handleKeyDown}
                />
            </Slate>
        </RichTextContainer>
    );
};
