import React from 'react';

import { Format } from './types';

// detectRichTextHotkey detects whether a keyboard event triggers a formatting
// action. If so, that format will be returned.
export const detectRichTextHotkey = (e: React.KeyboardEvent): Format | null => {
    if (e.getModifierState('Control')) {
        switch (e.key) {
            case 'b':
                return 'bold';
            case 'i':
                return 'italic';
            // We want to support underline, but more work needs to be done
            // before we can. Underline is currently represented in our
            // extended markdown syntax as ++underlined text++, but
            // `remark-parse` doesn't know that syntax and therefore our
            // deserializer (common/slate/remark/deserialize) doesn't recieve
            // nodes of type 'link'. We need to extend the parser, but the
            // instructions are extremely vague:
            // https://github.com/remarkjs/remark/tree/main/packages/remark-parse#extending-the-parser
            // case 'u':
            //     return 'underline';
        }
    }
    return null;
};
