import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

const variables = {
    ratingActive: '#FFB774',
    ratingActiveLarge: '#FA9D12',
    ratingHover: '#9eb3c7',
    ratingInactive: '#D7E1E5',
};

type StarInferredProps = { starColor: string; starBackgroundColor: string };
type StarProps = {
    isActive: boolean;
    isHover?: boolean;
    isSelected?: boolean;
    large?: boolean;
};
export const Star = styled.div.attrs<StarProps>(({ isActive, isHover, isSelected, large }) => {
    let starColor = large ? variables.ratingActiveLarge : '#FFF';
    let starBackgroundColor = 'transparent';

    // For active stars
    if (isActive) {
        starBackgroundColor = variables.ratingActive;
        starColor = variables.ratingActive;
    }
    if (isActive && large) {
        starBackgroundColor = variables.ratingActiveLarge;
        starColor = variables.ratingActiveLarge;
    }
    if (isActive && isHover) {
        starBackgroundColor = variables.ratingHover;
        starColor = variables.ratingHover;
    }

    if (!isActive && !isHover) {
        starBackgroundColor = variables.ratingInactive;
        starColor = variables.ratingInactive;
    }

    // For selected rating stars
    if (isSelected) {
        starBackgroundColor = 'transparent';
    }
    if (isActive && isSelected) {
        starColor = '#FFF';
        starBackgroundColor = '#FFF';
    }

    return {
        starColor,
        starBackgroundColor,
    };
})<StarProps & Partial<StarInferredProps>>`
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${(props): string => props.starColor || ''};
    background: ${(props): string =>
        props.isActive && props.isHover && !props.isSelected
            ? 'rgba(255, 255, 255, 0.08)'
            : 'transparent'};
    height: 100%;

    & path {
        fill: ${(props): string => props.starBackgroundColor || ''};
    }

    // Add border-radius on hover
    ${(props): FlattenSimpleInterpolation | string =>
        props.isHover && props.isActive
            ? css`
                  &:first-of-type {
                      border-radius: 4px 0 0 4px;
                      padding-left: 6px;
                  }

                  &:last-of-type {
                      border-radius: 0 4px 4px 0;
                      padding-right: 6px;
                  }
              `
            : ''}

    ${(props): FlattenSimpleInterpolation | string =>
        props.large
            ? css`
                  & > svg {
                      height: 23.22px;
                      width: inherit;
                  }
              `
            : ''}
`;

/* eslint-disable @typescript-eslint/indent */
type ContainerProps = { large?: boolean; isInert: boolean };
export const Container = styled.div<ContainerProps>`
    cursor: ${(props): string => (props.isInert ? 'default' : 'pointer')};

    display: flex;
    align-items: center;
    height: 100%;

    & > ${Star} {
        padding: 0 3.5px;

        ${(props): FlattenSimpleInterpolation | string =>
            props.large
                ? css`
                      padding: 0 7px;

                      &:first-of-type {
                          padding-left: 0;
                      }
                  `
                : ''}
    }
`;
/* eslint-enable @typescript-eslint/indent */
