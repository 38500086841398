import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { NoAssembliesWarning } from '../NoAssembliesWarning';
import { PaginationArgs, PaginationDirection, genPaginationArgs } from '@/common/utils/pagination';
import {
    IOldProjectsQuery,
    IOldProjectsQueryVariables,
    IProjectLightFragment,
    IProjectsOrderBy,
    IProjectStatus,
    useOldProjectsQuery,
} from '@/graphql';
import * as Apollo from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { ProjectListItem } from './ProjectListItem';

const COPY = {
    pastWarningHeader: 'No other projects yet.',
    pastWarningCopy: `Access your other projects and any assemblies used within those projects from this panel.`,
};

const getProjectsWithoutSampleProjects = (
    projects: IProjectLightFragment[]
): IProjectLightFragment[] => {
    const SAMPLE_PROJECT_NAME = 'Sample Takeoff';
    return projects.filter((project) => project.name !== SAMPLE_PROJECT_NAME);
};

const getProjectsWithoutDeletedProjects = (
    projects: IProjectLightFragment[]
): IProjectLightFragment[] => {
    return projects.filter((project) => project.status !== IProjectStatus.Canceled);
};

const getProjectQueryVariables = (
    paginationArgs?: PaginationArgs
): Apollo.QueryHookOptions<IOldProjectsQuery, IOldProjectsQueryVariables> => ({
    variables: {
        input: {
            orderBy: IProjectsOrderBy.DateSubmittedDesc,
            ...paginationArgs?.variables.input,
        },
    },
});

interface ProjectListProps extends BoxProps {
    projectId: number;
}

export const ProjectList: FC<ProjectListProps> = ({ projectId, ...props }) => {
    const [projects, setProjects] = useState<IProjectLightFragment[]>([]);

    const {
        data: projectData,
        fetchMore: fetchMoreProjects,
        loading: oldProjectsQueryLoading,
    } = useOldProjectsQuery(
        getProjectQueryVariables(genPaginationArgs(PaginationDirection.Next, 500))
    );

    useEffect(() => {
        const parsedProjects =
            projectData?.projectsOld.edges
                ?.map((e) => e.node)
                .filter((p) => Number(p.id) !== Number(projectId)) ?? [];

        const projectsWithoutDeletedProjects = getProjectsWithoutDeletedProjects(
            getProjectsWithoutSampleProjects(parsedProjects)
        );

        // If it's a full refetch from the beginning, clear the state (to handle deletions)
        if (!projectData?.projectsOld.pageInfo.hasPreviousPage) {
            setProjects(projectsWithoutDeletedProjects);
        } else {
            setProjects((oldProjects) => [
                ...oldProjects.filter(
                    (oP) => !projectsWithoutDeletedProjects.map((nP) => nP.id).includes(oP.id)
                ),
                ...projectsWithoutDeletedProjects,
            ]);
        }

        if (projectData?.projectsOld.pageInfo.hasNextPage === true) {
            const paginationArgs = genPaginationArgs(
                PaginationDirection.Next,
                500,
                projectData?.projectsOld.pageInfo
            );

            if (paginationArgs) {
                fetchMoreProjects(getProjectQueryVariables(paginationArgs));
            }
        }
    }, [projectData]);

    return (
        <Box {...props}>
            {(() => {
                if (oldProjectsQueryLoading && !projects.length) {
                    return (
                        <List>
                            <ListItem dense>
                                <Skeleton
                                    sx={{
                                        height: '16px',
                                        margin: '2px 0',
                                        width: '100%',
                                    }}
                                />
                            </ListItem>
                        </List>
                    );
                }

                if (!projects.length) {
                    return (
                        <NoAssembliesWarning
                            copy={COPY.pastWarningHeader}
                            header={COPY.pastWarningCopy}
                            sx={{
                                paddingTop: '12px',
                            }}
                        />
                    );
                }

                return (
                    <List>
                        {projects.map((project) => (
                            <ProjectListItem key={project.id} project={project} />
                        ))}
                    </List>
                );
            })()}
        </Box>
    );
};
