/*
 * Tab is a tab within the top bar of the admin dashboard.
 */
import React, { FC, useEffect, useState } from 'react';

import { selectedTabObservable, subscribeToSelectedTabObservable } from './observables';
import { TabHeader } from './styled';

import { TabType } from '@/components/AdminDashboard/types';

const COPY = {
    current: 'Current',
    SaaS: 'SaaS',
    completed: 'Completed',
};

export interface TabProps {
    tabType: TabType;
}

const mapTabTypeToString = (tabType: TabType): string => {
    switch (tabType) {
        case TabType.Completed:
            return COPY.completed;
        case TabType.SaaS:
            return COPY.SaaS;
        default:
        case TabType.Current:
            return COPY.current;
    }
};

export const Tab: FC<TabProps> = ({ tabType }) => {
    const [selectedTab, setSelectedTab] = useState<TabType>(TabType.Current);

    useEffect(() => {
        const subscription = subscribeToSelectedTabObservable(setSelectedTab);
        return (): void => subscription.unsubscribe();
    }, []);

    return (
        <TabHeader
            active={selectedTab === tabType}
            onClick={(): void => selectedTabObservable.next(tabType)}
        >
            {mapTabTypeToString(tabType)}
        </TabHeader>
    );
};
