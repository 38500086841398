import styled from 'styled-components';

import {
    borderCement,
    colorBackground,
    colorBackgroundPlaceholder,
    colorProjectPanelFileDivider,
    colorProjectPanelFileError,
    colorProjectPanelHighlight,
    colorProjectPanelText,
    colorTertiaryLighter,
    colorTypographyDark,
    colorTypographySection,
} from '@/variables';

export const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem 0;
`;

export const AddFileContainer = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colorProjectPanelHighlight};
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.25rem 0 0.25rem 0.5rem;
    cursor: ${(props): string => (props.disabled ? 'auto' : 'pointer')};
    pointer-events: ${(props): string => (props.disabled ? 'none' : 'auto')};
    opacity: ${(props): string => (props.disabled ? '0.5' : '1')};

    svg {
        fill: ${colorProjectPanelHighlight};
        margin-right: 0.5rem;
    }
`;

export const FilesActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 2rem;
`;

export const FilesHeader = styled.h2`
    padding: 0 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: ${colorTypographyDark};
`;

export const FilesFileContainer = styled.div<{ isChild?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.375rem 2rem ${(props): string => (props.isChild ? '0.375rem 4.25rem' : '')};
    margin: 0.125rem 0;

    &:hover {
        background-color: ${colorTertiaryLighter};
    }
`;

export const FileDeleteIcon = styled.span`
    visibility: hidden;
    cursor: pointer;
    padding: 1rem 0 1rem 1rem;

    ${FilesFileContainer}:hover & {
        visibility: visible;
    }
`;

export const FilesFileInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export enum FilePlaceholderThumbStatus {
    Loading,
    Error,
}

const placeholderThumbBackgroundColor = (status?: FilePlaceholderThumbStatus): string => {
    switch (status) {
        case FilePlaceholderThumbStatus.Error:
            return 'transparent';
        case FilePlaceholderThumbStatus.Loading:
            return colorBackground;
        default:
            return colorBackgroundPlaceholder;
    }
};

export const FilesFilePlaceholderThumb = styled.div<{ status?: FilePlaceholderThumbStatus }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    width: 2.25rem;
    background-color: ${(props): string => placeholderThumbBackgroundColor(props.status)};
    box-sizing: border-box;
    border: ${(props): string =>
        props.status === FilePlaceholderThumbStatus.Error
            ? `1px solid ${colorProjectPanelFileError}`
            : 'none'};

    .dots-loader {
        display: flex;
    }
`;

export const FilesFileThumb = styled.img`
    height: 2.25rem;
    width: 2.25rem;
    border: ${borderCement};
`;

export const FilesSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18.75rem;
    height: 2.25rem;
    padding: 0 0.75rem;
    border: ${borderCement};
    border-radius: 3.5rem;

    svg {
        cursor: pointer;
    }
`;

export const FilesSearchInput = styled.input`
    flex-grow: 2;
    background: transparent;
    font-family: 'proxima-nova', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.03rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 0 0 0.75rem;
    padding: 0;
    color: ${colorTypographyDark};

    &::placeholder {
        opacity: 0.7;
    }
`;

export const FileTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.125rem;
`;

export const FileMainText = styled.span`
    color: ${colorProjectPanelText};
    font-size: 0.875rem;
    font-weight: 600;
`;

export const FileSubText = styled.span<{ error?: boolean }>`
    margin-top: 0.125rem;
    color: ${(props): string =>
        props.error ? colorProjectPanelFileError : colorTypographySection};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.06rem;
`;

export const LabeledDividerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2rem;
    margin: 0;
`;

export const LabeledDividerLine = styled.hr`
    flex-grow: 2;
    border: none;
    border-top: 1px solid ${colorProjectPanelFileDivider};
    margin: 0;
    background: none;
`;

export const LabeledDividerText = styled.span`
    width: fit-content;
    white-space: nowrap;
    margin: -0.125rem 0 0 0.75rem;
    padding: 0;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: ${colorProjectPanelFileDivider};
`;

export const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-begin;
    align-items: stretch;
    width: 15rem;
    height: 0.125rem;
    margin-top: 0.5rem;
    background-color: ${colorBackgroundPlaceholder};
`;

export const ProgressBarProgress = styled.div<{ percentDone: number }>`
    width: ${(props): string => `${props.percentDone}%`};
    background-color: ${colorProjectPanelHighlight};
`;
