/* Takeoff context, responsible for takeoff state management. */
import { GeometriesHookProps, useGeometries } from '../../common/hooks/useGeometries';
import {
    ContextMenuPayload,
    Geometry,
    GeometryType,
    Hook,
    KeyHandlers,
    Nil,
    Point,
    SelectedPoint,
    Setter,
    ToolType,
} from '../../common/types';
import { useNilState } from '../../common/utils/helpers';
import { ContextType, makeContext } from '../../common/utils/makeContext';
import { customPatternCanvas, customPatternSVG } from '../../leaflet.pattern';
import { ProjectRecord } from '../../queries/projects';
import { useProjectSubscription } from '../../subscriptions/projects';
import { colorDrawingTool4 } from '../../variables';
import { TooltipEvents } from './ToolTooltip';
import {
    activeVerticesObservable,
    boundaryDataObservable,
    currentlyAddedCoordinatesObservable,
    deleteSelectedGeometriesObservable,
    editedGeometryObservable,
    isAddingCoordinatesObservable,
    persistenceFunctionObservable,
    toolTooltipObservable,
} from './observables/helpers';
import {
    geometriesObservable,
    selectedGeometriesObservable,
    toolObservable,
    markupWithinGroupObservable,
} from './observables/interface';
import { toCopy } from '@/common/convert/geometry';
import { geometryIs, pointOrGeometryIsGeometry } from '@/common/typeGuards';
import { useEstimationLayout } from '@/components/app/router/EstimationRoute/hooks/useEstimationLayout';
import { LeafletMouseEvent } from 'leaflet';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Map } from 'react-leaflet';
import { v4 as uuid } from 'uuid';

/* Definition of state and actions that context provide */
export type TakeoffContextProps = GeometriesHookProps & {
    /* Project state */
    project: ProjectRecord;

    /* Takeoff state */
    contextMenuPayload: Nil<ContextMenuPayload>;
    setContextMenuPayload: Setter<Nil<ContextMenuPayload>>;
    setTool: (tool: ToolType) => void;
    checkIsRendererSVG: () => boolean;
    renderer: typeof customPatternSVG | typeof customPatternCanvas;
    searchInputRef: RefObject<HTMLInputElement>;
    handlers: KeyHandlers;
    updateHandlers: (handlers: KeyHandlers) => void;
    tooltipProps: (name: string, shortcut: string) => TooltipEvents;
    hideToolTooltip: () => void;
    handleToolChange: (tool: ToolType) => () => void;

    /* Flags */
    pagesBrowserOpen: boolean;
    setPagesBrowserOpen: Setter<boolean>;
    loadingCurrentPage: boolean;
    setLoadingCurrentPage: Setter<boolean>;

    /* Mouse events */
    mapRef: Nil<Map>;
    setMapRef: Setter<Nil<Map>>;
    disableDragOnMouseUp: () => void;
    mouseEvents: (geometry: Geometry | SelectedPoint) => {
        [key: string]: (e: LeafletMouseEvent) => void;
    };
    pointerEventsDisabled: boolean;

    /* Pages and viewports */
    currentPageId: number;
    setCurrentPageId: Setter<number>;
    rightClickedPageId: Nil<number>;
    setRightClickedPageId: Setter<Nil<number>>;

    /* Calibration */
    calibrationLine: Geometry<GeometryType.LINEAR>;
    setCalibrationLine: Setter<Geometry<GeometryType.LINEAR>>;
    isManualCalibration: boolean;
    setIsManualCalibration: Setter<boolean>;
};

export type TakeoffContextType = ContextType<TakeoffContextProps>;

export interface TakeoffComponentProps {
    useTakeoff: Hook<TakeoffContextProps, void>;
}

export const makeTakeoffContext = (projectInit: ProjectRecord): TakeoffContextType => {
    /* Context hook, responsible for state and actions */
    return makeContext<TakeoffContextProps>(() => {
        const estimationLayout = useEstimationLayout();
        /**************************************************************************/
        /*                             Project state                              */
        /**************************************************************************/
        const [project, setProject] = useState(projectInit);

        const { data: projectChangedData } = useProjectSubscription();

        // Subscribe to project changes
        useEffect(() => {
            const updatedProject = projectChangedData?.ProjectChanged.projectEntry ?? undefined;
            if (updatedProject?.uuid === project.uuid) {
                setProject(updatedProject);
            }
        }, [projectChangedData]);

        /**************************************************************************/
        /*                             Takeoff state                              */
        /**************************************************************************/
        const [mapRef, setMapRef] = useNilState<Map>();
        const [contextMenuPayload, setContextMenuPayload] = useNilState<ContextMenuPayload>();
        const setTool = useCallback((tool: ToolType): void => toolObservable.next(tool), []);

        const [renderer] = useState<typeof customPatternSVG | typeof customPatternCanvas>(
            new customPatternSVG()
        );

        // Used to deal with leaflet event bugs that depend on the type of renderer
        const checkIsRendererSVG = useCallback(
            (): boolean => renderer instanceof customPatternSVG,
            [renderer]
        );
        const searchInputRef = useRef<HTMLInputElement>(null);
        const [handlers, setHandlers] = useState<KeyHandlers>({});

        const updateHandlers = useCallback(
            (handlers: KeyHandlers): void => setHandlers((prev) => ({ ...prev, ...handlers })),
            [setHandlers]
        );

        const showToolTooltip = useCallback(
            (name: string, shortcut: string) =>
                (e: React.MouseEvent): void => {
                    const validTags = ['DIV', 'BUTTON'];

                    let targetNode = e.target as HTMLDivElement;
                    let x: number | null = null;
                    let y: number | null = null;
                    while (!x || !y) {
                        if (validTags.includes(targetNode.tagName)) {
                            const rect = targetNode.getBoundingClientRect();
                            x = rect.x + rect.width / 2;
                            y = rect.y + rect.height;
                        }
                        targetNode = targetNode.parentNode as HTMLDivElement;
                    }
                    toolTooltipObservable.next({
                        name,
                        shortcut,
                        origin: [x, y],
                    });
                },
            []
        );

        const hideToolTooltip = useCallback((): void => {
            toolTooltipObservable.next(null);
        }, []);

        const tooltipProps = useCallback(
            (name: string, shortcut: string): TooltipEvents => ({
                onMouseEnter: showToolTooltip(name, shortcut),
                onMouseLeave: hideToolTooltip,
            }),
            [showToolTooltip, hideToolTooltip]
        );

        /**************************************************************************/
        /*                                 Flags                                  */
        /**************************************************************************/
        const [pagesBrowserOpen, setPagesBrowserOpen] = useState(true);
        const [loadingCurrentPage, setLoadingCurrentPage] = useState(true);

        /**************************************************************************/
        /*                              Sub-contexts                              */
        /**************************************************************************/
        const geometries = useGeometries(project);

        const { commit } = geometries;

        const handleToolChange = useCallback(
            (toolType: ToolType, isCreatingMarkupWithinGroup = false) =>
                (): void => {
                    if (isAddingCoordinatesObservable.value) {
                        deleteSelectedGeometriesObservable.next();
                    }
                    activeVerticesObservable.next([]);
                    currentlyAddedCoordinatesObservable.next(null);
                    isAddingCoordinatesObservable.next(false);
                    selectedGeometriesObservable.next({ geometries: [], points: [] });
                    boundaryDataObservable.next([]);
                    editedGeometryObservable.next(null);
                    toolObservable.next(toolType);

                    // If we are creating a geometry in group, revert the markupWithinGroupObservable back
                    // to it's default state.  This will prevent creating an invalid geometry type within a group.
                    if (!isCreatingMarkupWithinGroup) {
                        markupWithinGroupObservable.next(undefined);
                    }
                },
            [geometries.scale]
        );

        // On adding a new markup to a group, select the respective tool as if
        // a user was clicking the toolbar.
        useEffect(() => {
            markupWithinGroupObservable.subscribe((markupWithinGroup) => {
                if (markupWithinGroup) {
                    handleToolChange(markupWithinGroup.toolType, true)();
                }
            });
        }, [handleToolChange]);

        /**************************************************************************/
        /*                        Pages and Viewports                             */
        /**************************************************************************/
        const [rightClickedPageId, setRightClickedPageId] = useNilState<number>(null);
        const [currentPageId, setCurrentPageId] = useState(
            geometries.currentPage?.id ? Number(geometries.currentPage?.id) : -1
        );

        useEffect(() => {
            setCurrentPageId(Number(geometries.currentPage?.id));
        }, [geometries.currentPage?.id]);

        useEffect(() => {
            estimationLayout.setPlanPageID(String(currentPageId));
        }, [currentPageId]);

        const sectionPagesAndViewports = {
            currentPageId,
            setCurrentPageId,
            rightClickedPageId,
            setRightClickedPageId,
        };

        /**************************************************************************/
        /*                              Mouse events                              */
        /**************************************************************************/

        const [pointerEventsDisabled, setPointerEventsDisabled] = useState(false);

        const moveByVector = (point: Point, moveVector: number[]): Point => ({
            x: point.x + moveVector[0],
            y: point.y + moveVector[1],
            id: point.id,
        });

        const handleDragMouseMove = (
            moveVector: number[],
            geometry: Geometry | SelectedPoint
        ): void => {
            if (!pointOrGeometryIsGeometry(geometry)) {
                const newCoords = moveByVector(geometry, moveVector);
                commit({
                    ...geometry,
                    ...newCoords,
                });
            } else if (geometryIs(geometry, GeometryType.AREA)) {
                const newCoords = geometry.coordinates.map((ring) =>
                    ring.map((coords: Point) => moveByVector(coords, moveVector))
                );
                commit({
                    ...geometry,
                    coordinates: newCoords,
                });
            } else if (
                geometryIs(geometry, GeometryType.LINEAR) ||
                geometryIs(geometry, GeometryType.COUNT)
            ) {
                const newCoords = geometry.coordinates.map((coords) =>
                    moveByVector(coords, moveVector)
                );
                commit({
                    ...geometry,
                    coordinates: newCoords,
                });
            }
        };

        const dragOnMouseMove = useCallback(
            (
                e: LeafletMouseEvent,
                startEv: LeafletMouseEvent,
                paramList: Array<Geometry | SelectedPoint>
            ) => {
                const moveVector = [
                    e.latlng.lat - startEv.latlng.lat,
                    e.latlng.lng - startEv.latlng.lng,
                ];
                for (const params of paramList || []) {
                    handleDragMouseMove(moveVector, params);
                }
            },
            [mapRef, geometries]
        );

        // Used to disable pointer events on shapes when Count Tool is selected.
        // This allows us to place count markers on them. When other
        // tool is selected remove pointer event class from shapes. This could be
        // replaced by the 'interactive' prop on Leaflet Components but
        // it doesn't update it's state due to a known bug.
        useEffect(() => {
            setPointerEventsDisabled(toolObservable.value === ToolType.COUNT);
        }, [toolObservable.value, mapRef]);

        // Method responsible for dragging selected geometries on mouse down.
        // We have to stop all other map events like selecting event with map.off()
        // Logic specific for each geometry type.
        const dragOnMouseDown = useCallback(
            (startEv: LeafletMouseEvent, geometry: Geometry | SelectedPoint) => {
                if (!mapRef || startEv.originalEvent.button !== 0) return;

                const geometries = geometriesObservable.value;
                if (!geometries) {
                    return;
                }

                // Not certain why we need to do this - guessing to prevert duplicate mousedown events?
                boundaryDataObservable.next([]);
                const map = mapRef.leafletElement;
                map.off('mousedown');

                // If nothing is selected, select the passed geometry
                if (
                    selectedGeometriesObservable.value.geometries.length === 0 &&
                    selectedGeometriesObservable.value.points.length === 0
                ) {
                    if (pointOrGeometryIsGeometry(geometry)) {
                        const foundGeometry = geometries.find((g) => g.uuid === geometry.uuid);
                        if (foundGeometry) {
                            selectedGeometriesObservable.next({
                                geometries: [foundGeometry],
                                points: [],
                            });
                        }
                    } else {
                        const parent = geometries.find((g) => g.uuid === geometry.countID);
                        if (parent && geometryIs(parent, GeometryType.COUNT)) {
                            const foundPoint = parent.coordinates.find(
                                (point) => point.id === geometry.id
                            );
                            if (foundPoint) {
                                selectedGeometriesObservable.next({
                                    geometries: [],
                                    points: [
                                        {
                                            ...foundPoint,
                                            countID: geometry.countID,
                                        },
                                    ],
                                });
                            }
                        }
                    }
                }

                // If the id we clicked on is not currently selected do nothing.
                const draggableGeometryIds = selectedGeometriesObservable.value.geometries.map(
                    (g) => g.uuid
                );
                const draggablePoints = selectedGeometriesObservable.value.points;
                if (pointOrGeometryIsGeometry(geometry)) {
                    if (!draggableGeometryIds.includes(geometry.uuid)) return;
                } else {
                    if (
                        draggablePoints.findIndex(
                            (draggablePoint) => draggablePoint.id === geometry.id
                        ) === -1
                    )
                        return;
                }

                // Get all geometry and point data from observables; according to the original comment I found here:
                // > We have to save data outside of a callback function.
                const pointsToMove = draggablePoints.reduce<SelectedPoint[]>(
                    (acc, draggablePoint) => {
                        const parent = geometries.find((g) => g.uuid === draggablePoint.countID);
                        if (parent && geometryIs(parent, GeometryType.COUNT)) {
                            const foundPoint = parent.coordinates.find(
                                (point) => point.id === draggablePoint.id
                            );
                            if (foundPoint) {
                                return acc.concat({ ...foundPoint, countID: parent.uuid });
                            }
                        }
                        return acc;
                    },
                    []
                );
                const geometriesToMove = draggableGeometryIds.reduce<Geometry[]>(
                    (acc, draggableGeometryId) => {
                        const geometry = geometries.find((g) => g.uuid === draggableGeometryId);
                        if (geometry) {
                            return acc.concat(toCopy(geometry));
                        }
                        return acc;
                    },
                    []
                );

                // Apply transformation to the dragged geometries when the mouse moves.
                map.on('mousemove', (e: LeafletMouseEvent) =>
                    dragOnMouseMove(e, startEv, [...pointsToMove, ...geometriesToMove])
                );
            },
            [mapRef]
        );

        // Method responsible for stopping dragging selected geometries on
        // releasing mouse button by setting off mouse move event.
        const disableDragOnMouseUp = useCallback(() => {
            if (mapRef) {
                const map = mapRef.leafletElement;
                const removeMouseMove = () => map.off('mousemove');
                if (
                    selectedGeometriesObservable.value.geometries.length > 0 ||
                    selectedGeometriesObservable.value.points.length > 0
                ) {
                    map.addOneTimeEventListener('mouseup', removeMouseMove);
                }
            }
        }, [mapRef]);

        const mouseEvents = useCallback(
            (
                geometry: Geometry | SelectedPoint
            ): { [key: string]: (e: LeafletMouseEvent) => void } => ({
                onmousedown: (e: LeafletMouseEvent): void => dragOnMouseDown(e, geometry),
                onmouseup: disableDragOnMouseUp,
            }),
            [dragOnMouseDown, disableDragOnMouseUp]
        );

        const sectionMouseEvents = {
            mapRef,
            setMapRef,
            disableDragOnMouseUp,
            mouseEvents,
            pointerEventsDisabled,
        };

        /**************************************************************************/
        /*                              Calibration                               */
        /**************************************************************************/

        const [calibrationLine, setCalibrationLine] = useState<Geometry<GeometryType.LINEAR>>({
            uuid: uuid(),
            style: {
                color: colorDrawingTool4,
            },
            type: GeometryType.LINEAR,
            coordinates: [],
        });

        const [isManualCalibration, setIsManualCalibration] = useState<boolean>(true);

        const sectionCalibration = {
            calibrationLine,
            setCalibrationLine,
            isManualCalibration,
            setIsManualCalibration,
        };

        /**************************************************************************/
        /*                                Effects                                 */
        /**************************************************************************/

        // Clean stuff up when leaving the takeoff.
        useEffect(() => {
            const cleanup = (): void => {
                persistenceFunctionObservable.next(undefined);
                geometriesObservable.next(null);
            };
            return cleanup;
        }, []);

        /**************************************************************************/
        /*                           Combined sections                            */
        /**************************************************************************/

        return {
            /* Project state */
            project,

            /* Takeoff state */
            contextMenuPayload,
            setContextMenuPayload,
            setTool,
            checkIsRendererSVG,
            renderer,
            searchInputRef,
            handlers,
            updateHandlers,
            tooltipProps,
            hideToolTooltip,
            handleToolChange,

            /* Flags */
            pagesBrowserOpen,
            setPagesBrowserOpen,
            loadingCurrentPage,
            setLoadingCurrentPage,

            /* Sections */
            ...sectionMouseEvents,
            ...sectionCalibration,
            ...sectionPagesAndViewports,
            ...geometries,
        };
    });
};
