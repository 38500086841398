import { AppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { Env } from '@/common/env';
import { Mode } from '@/theme/Mode';
import { HideBarModal } from './HideBarModal';
import { AppBar, Chip, LogoIcon, SvgIcon, Toolbar } from './styled';
import { ReactComponent as SettingsIcon } from '@/assets/icons/16/settings.svg';

interface Props extends AppBarProps {
    onHide?: () => void;
}

export const EnvironmentBar: FC<Props> = ({ onHide, ...props }) => {
    const [anchor, setAnchor] = useState<HTMLElement>();
    const [isHideBarModalOpen, setIsHideBarModalOpen] = useState(false);

    const handleSettingsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    };

    const handleSettingsMenuClose = () => {
        setAnchor(undefined);
    };

    const handleHideBarModalOkButtonClick = (value?: string) => {
        setIsHideBarModalOpen(false);

        if (value === 'release') {
            localStorage.setItem('status-bars-environment-bar-hide-version', Env.release);
        }

        onHide?.();
    };

    return (
        <AppBar position="fixed" {...props}>
            <Toolbar>
                <Stack alignItems="center" direction="row" spacing="16px">
                    <Link component={RRDLink} to="/">
                        <LogoIcon />
                    </Link>
                    <Typography variant="button">env</Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing="24px">
                    <Stack alignItems="center" direction="row" spacing="16px">
                        <Tooltip title="Environment version">
                            <Chip label={Env.release} variant="outlined" />
                        </Tooltip>
                        <Tooltip title="Environment tier">
                            <Chip label={Env.tier.value} variant="outlined" />
                        </Tooltip>
                    </Stack>
                    <IconButton onClick={handleSettingsButtonClick} size="large">
                        <SvgIcon viewBox="0 0 16 16">
                            <SettingsIcon />
                        </SvgIcon>
                    </IconButton>
                    <Mode variant="light">
                        <Menu
                            anchorEl={anchor}
                            onClose={handleSettingsMenuClose}
                            open={anchor !== undefined}
                        >
                            <MenuItem disabled>View all values</MenuItem>
                            <MenuItem onClick={() => setIsHideBarModalOpen(true)}>
                                Hide Bar
                            </MenuItem>
                        </Menu>
                        <HideBarModal
                            onCancelButtonClick={() => setIsHideBarModalOpen(false)}
                            onOkButtonClick={handleHideBarModalOkButtonClick}
                            open={isHideBarModalOpen}
                        />
                    </Mode>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
