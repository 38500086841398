import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAllProjects } from '@/common/hooks/useAllProjects';
import { DatabaseProjectStatus } from '@/common/types';
import { isSaasProject, isProjectPriceFinal, isProjectCancelled } from '@/views/Projects/utils';

export const useKanbanProjects = () => {
    const { builderAcceptance } = useFlags();

    const { projects, ...rest } = useAllProjects();

    // @1build-business-logic

    return {
        ...rest,
        projects: projects.map((project) => {
            if (
                builderAcceptance &&
                !isSaasProject(project) &&
                !isProjectPriceFinal(project) &&
                !isProjectCancelled(project)
            ) {
                return {
                    ...project,
                    status: DatabaseProjectStatus.PENDING_ESTIMATOR,
                };
            } else {
                return project;
            }
        }),
    };
};
