import React, { FC } from 'react';

import { Container, Content, IconWrapper } from './styled';
import { DivProps } from '@/common/types';

interface DetailPointProps extends DivProps {
    icon?: JSX.Element;
    isHighlighted?: boolean;
    isError?: boolean;
    isTrades?: boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}
export const DetailPoint: FC<DetailPointProps> = ({
    icon,
    isHighlighted,
    isError,
    isTrades,
    disabled,
    onClick,
    children,
}) => (
    <Container isHighlighted={isHighlighted}>
        <Content disabled={disabled} isError={isError} isTrades={isTrades} onClick={onClick}>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {children}
        </Content>
    </Container>
);
